import config from '../config';
import jwt from 'jsonwebtoken';

const api = {
    login,
    register,
    todasta,
    topdf,
    getProfil,
    tokenizeJWT,
    verifyEmail,
    updateProfilData,
    updateProfilDataFromLocalStorage,
    forgetPsw,
    resetPsw,
    getNextZiadankaId,
    lekar,
    getPzs,
    // getLekarByKodAndPZS,
    saveDataForLekar,
    getUserByKzID
}

export default api;

async function lekar(kod) {
    return fetch(`${config.apiUrl}/lekar/${kod}`, {
        method: 'GET',
        mode: "cors",
        headers: {
            "content-type": "application/json",
            "accept": "application/json"
        }
    })
        .then(handleResponse)
        .then(user => {
            return user;
        });

}

async function login(email, password) {
    return fetch(`${config.apiUrl}/auth/signin`, {
        method: 'POST',
        mode: "cors",
        headers: {
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({data: tokenizeJWT({email: email, password: password}, config.pwsecret)})
    })
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

async function register(dataLekar, dataUser) {
    const profil = {
        lekar: {lekar: dataLekar},
        user: tokenizeJWT({
            email: dataLekar.email,
            password: dataUser.password,
            securityQuestion: 'Kód lekára?',
            securityAnswer: dataLekar.kod_lekara
        }, config.pwsecret)
    }

    const requestOptions = {
        method: 'POST',
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(profil)
    };

    return fetch(`${config.apiUrl}/mojprofil`, requestOptions)
        .then(handleResponse)
        .then(message => {
            return message;

        });
}

function todasta(ziadanka) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(ziadanka)
    };

    return fetch(`${config.apiUrl}/ziadanky/dasta`, requestOptions)
        .then(handleResponse)
        .then(message => {
            return message;
        });
}

function topdf(ziadanka, pdfWindow = null) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(ziadanka)
    };

    return fetch(`${config.apiUrl}/ziadanky/pdf`, requestOptions)
        .then((res) => {
            console.log(res);
            return res.arrayBuffer()
        })
        .then(data => {
            var base64Str = Buffer.from(data).toString('base64');
            if (!pdfWindow) pdfWindow = window.open("")
            pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                base64Str + "'></iframe>")
            // PaB @210527 #241.. pdfWindow.document.title = "PDF Ziadanka"
        });
}

async function getProfil(token) {
    if (token) {
        return fetch(`${config.apiUrl}/mojprofil`, {
            method: "GET",
            mode: "cors",
            headers: {
                "content-type": "application/json",
                "accept": "application/json",
                "x-fs-access-token": token
            }
        })
            .then(handleResponse)
            .then(data => {
                return data;
            });
    }
}

async function forgetPsw(pw = {}) {
    return fetch(`${config.apiUrl}/auth/pswreset`, {
        method: "POST",
        mode: "cors",
        headers: {
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({data: tokenizeJWT(pw, config.pwsecret)})
    }).then(handleResponse).then(resp => {
        return resp;
    })

}

async function resetPsw(token = "", psw_new) {
    return fetch(`${config.apiUrl}/auth/pswchange`, {
        method: "POST",
        mode: "cors",
        headers: {
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({data: token, newpsw: tokenizeJWT({password_new: psw_new}, config.pwsecret)})
    }).then(handleResponse).then(resp => {
        return resp;
    })

}

function updateProfilDataFromLocalStorage() {
    const localData = window.localStorage.getItem(config.local_state_storage);
    let storedState = localData ? JSON.parse(localData) : {};
    if (localData && storedState["loggedIn"] === true) {
        try {
            updateProfilData(storedState.user.accessToken, storedState.lekar);
        } catch (e) {
            console.error(e);
        }
    }
}

async function updateProfilData(token, data) {
    if (data && data.kzOrigin){
        data = {};
    }
    if (token) {
        const storage_templates = window.localStorage.getItem(config.curolab_template_storage);
        let templates = storage_templates ? JSON.parse(storage_templates) : {};
        const profil = {
            lekar: data,
            templates: templates
        }

        const requestOptions = {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                [config.hdrtoken]: token
            },
            body: JSON.stringify(profil)
        };

        return fetch(`${config.apiUrl}/mojprofil`, requestOptions)
            .then(handleResponse)
            .then(message => {
                return message;

            });

    }
}

async function getNextZiadankaId() {
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json'
        }
    };

    return fetch(`${config.apiUrl}/ziadanky/nextid`, requestOptions)
        .then(handleResponse)
        .then((result) => {
            return result['nextId'];
        });
}

function handleResponse(response) {
    // console.log(response);
    return response.json().then(data => {

        // console.log('handleResponse\n' + JSON.stringify(data));
        if (!response.ok) {
            const errormsg = (data && data.message) || response.statusText;
            // PaB @210526 #233 po konzultacii s MKr.. const error = `${response.status} ${errormsg}`;
            throw new Error(errormsg); // PaB @210526 #233.. Error(error);
            // return Promise.reject(error);
        }
        return data;
    });
}

function tokenizeJWT(data = {}, ss) {
    const token = jwt.sign(data, ss, {
        expiresIn: config.expire
    });
    return token;
};

async function verifyEmail(token) {
    const paramToken = encodeURIComponent(token);
    return fetch(`${config.apiUrl}/auth/verify?id=${paramToken}`, {
        method: 'GET',
        mode: "cors",
        headers: {
            "content-type": "application/json",
            "accept": "application/json"
        }
    })
        .then(handleResponse)
        .then(data => {
            // console.log(data);
            return data;
        });
}

async function getPzs(inVal) {
    const filter = encodeURIComponent(inVal);
    return fetch(`${config.apiUrl}/pzs?pzs_kod=${filter}`, {
        method: 'GET',
        mode: "cors",
        headers: {
            "content-type": "application/json",
            "accept": "application/json"
        }
    })
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function getUserByKzID(temp_id) {

    let data = JSON.stringify({
        'action': 'get-doctor-patient',
        'id': temp_id
    });

    return fetch(`${config.apiUrl}/auth/bridge`, {
        method: 'POST',
        mode: "cors",
        headers: {
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: data
    })
        .then(handleResponse)
        .then(data => {
            if(data && data.error && data.error.code)
            {
                window.location.assign(`${config.appUrl}/error?id=` + data.error.code);
            }
            return data;
        })
        .catch((error) => {
            console.log('user error ' + error);
        });
}

async function saveDataForLekar(lekar) {
    const out = {'lekar': lekar};

    const requestOptions = {
        method: 'POST',
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(out)
    };
    return fetch(`${config.apiUrl}/createdata`, requestOptions)
        .then(response => response.json())
        .then(data => {
            return data;
        });
}
