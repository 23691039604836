import validateOn from './validateOnEnum'

export const validators = {
	email: [{ validateOn: validateOn.FORM, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole E-mail je prázdne" }, {
		validateOn: validateOn.BLUR, validate: (value) => {
			return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
		}, invalidMsg: "E-mail nie je platný"
	}],
	password: [{ validateOn: validateOn.FORM, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole Heslo je prázdne" },
		// {
		// 	validateOnChange: true, validate: (value) => {
		// 		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value);
		// 	}, invalidMsg: "Heslo musí obsahovať minimálne 8 znakov, aspoň 1 veľké písmeno, 1 malé písmeno a 1 číslo"
		// }
	]
}

export function isValid(state, stateErrors = {}, validationEvent = validateOn.FORM){
	var valid = true;
	for (const property in state) {
		if(!validators.hasOwnProperty(property)) continue;
		stateErrors[property] = [];
		// eslint-disable-next-line no-loop-func
		validators[property].filter(val => val.validateOn <= validationEvent).forEach(validator => {
			if (validator.validate(state[property]) === false) {
				stateErrors[property].push(validator.invalidMsg);
				valid = false;
			}
		});
	}
	return valid;
}
