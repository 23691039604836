import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Lekar from './Lekar';
import Pacient from './Pacient';
import Review from './Review';
import Ziadanka from './Ziadanka';
import Login from './Login';
import Register from './Register';
//import RegisterForm from './RegisterForm';
import Podakovanie from './Podakovanie';
import Vzorky from './Vzorky';
import ActiveUser from './ActiveUser';

import dastaService from '../services/serveApi'
import completeData from '../services/completeData'
import Modal from 'react-modal';
import SendDasta from './Components/SendingDasta';

import * as stepValidators from '../validators/index'
import { deepClone } from '../utils/cloneObj'
import actionConstants from '../actionConstants';
import moment from 'moment'

import './form-styles/Form.css';
import { checkZiadankaExists, ziadankaDelete } from '../services/ziadanka';
import FormSaveZiadanka from './FormSaveZiadanka';

const mapStateToProps = (state) => {
  return ({
    activeStep: state.activeStep,
    activeForm: state.activeForm
    , validatedSteps: state.validatedSteps //pokus spustenia validacie
    , lekar: state.lekar
    , pacient: state.pacient
    , diagnozy: state.diagnozy
    , dt_odberu: state.dt_odberu
    , ziadanka: state.data_ziadanky
    , step4_state: state.step4_state
    , tlac_state: state.tlac_state
    , review: state.review
    , ziadankaErrors: state.ziadankaErrors
    , user: state.user
    , loggedIn: state.loggedIn
    , initial: state.initial
    , dasta: state.dasta
    , idZiadanky:state.ID_ZIADANKA,
    _id: state._id,
    state,
  })
};

const steps = ['Prihlásenie', 'Lekár', 'Pacient', 'Diagnóza a vyšetrenia', 'Vzorky a tlač', 'Sumarizácia a odoslanie']; //, 'Poďakovanie' je uz iba samostatny page bez moznosti navratu

function getStepContent(step, form) {
  switch (step) {
    case 0:
      return form === 'L' ? <Login /> : <Register />;
    case 1:
      return <Lekar />;
    case 2:
      return <Pacient />;
    case 3:
      return <Ziadanka />;
  case 4:
      return <Vzorky />;
    case 5:
      return <Review />;
    case 6:
      return <Podakovanie />;
    default:
      throw new Error('Unknown step');
  }
}


function Form(props) {

  const history = useHistory();
  const [isZiadankaModalOpen, setIsZiadankaModalOpen] = useState(false);
  const [isZiadankaPatientExistsModalOpen, setIsZiadankaPatientExistsModalOpen] = useState(false);
  const [ziadankaContent, setZiadankaContent] = useState({});
  const [isUpdate] = useState(!!props.state._id);

  const updateProfilData = async () => {
    if (props.loggedIn) {
      //dastaService.updateProfilData(props.user.accessToken, props.lekar).then(resp => console.log(resp)).catch(err => console.error(err));
      try {
        if(props.initial===true) {
          let data=await dastaService.getProfil(props.user.accessToken);
          if (data.lekar) {
            data.lekar.errors = {};//TODO: treba doriesit, ci sa budu zapisovat do DB aj errors
          }
          props.dispatch({ type:'INITPROFILE', payload: { logged: { ...props.user, ...data.user }, loaded: data} } )
        }else{
          await dastaService.updateProfilData(props.user.accessToken, props.lekar);
        }
      } catch(error) {
        console.log(error.message);
        let msg = "Relácia vášho prihlásenia vypršala. Prihláste sa znova prosím."
        props.dispatch(failure(msg));  //error.toString()
      }
    }
		function failure(error) { return { type: actionConstants.TOKEN_FAILURE, payload: { ...props.user, password: '', errors: { ...props.user.errors, common: error } } } }
  }

  const validateStep = (step, stepValid, tmpZiadanka = {}) => {
    var stepErrors = {};
    if (!stepValid === true) {
      switch (step) {
        case 0: {
          if (props.activeForm === 'R') {
            //neopustim registraciu
            return false;
          } else stepValid = true;
          break;
        }
        case 1: {
          // validuj lekara
          stepErrors = { ...props.lekar.errors };
          stepValid = stepValidators.validators.lekarValidator(props.lekar, stepErrors, stepValidators.validators.validateOnEnum.FORM);
          if (stepValid === false) props.dispatch({ type: actionConstants.SET_VALIDATION_ERRORS, payload: { lekar: stepErrors } });
          break;
        }
        case 2: {
          //validuj pacienta
          stepErrors = { ...props.pacient.errors };
          stepValid = stepValidators.validators.pacientValidator(props.pacient, stepErrors, stepValidators.validators.validateOnEnum.FORM);
          if (stepValid === false) props.dispatch({ type: actionConstants.SET_VALIDATION_ERRORS, payload: { pacient: stepErrors } });
          break;
        }
        case 3: {
          stepValid = true;
          stepErrors = {};
          //1.dtodberu
          stepErrors = { ...stepErrors, dt_odberu: props.dt_odberu.errors };
          stepValid = stepValidators.validators.dtOdberuValidator(props.dt_odberu, stepErrors.dt_odberu, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          //2.diagnozy
          stepErrors = { ...stepErrors, diagnozy: props.diagnozy.errors };
          stepValid = stepValidators.validators.diagnozyValidator(props.diagnozy, stepErrors.diagnozy, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          //3.ziadanka
          stepErrors = { ...stepErrors, ziadankaErrors: props.ziadankaErrors };
          let ziadanka = { ...props.ziadanka, ...tmpZiadanka };
          stepValid = stepValidators.validators.ziadankaValidator(ziadanka, stepErrors.ziadankaErrors, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          if (stepValid === false) props.dispatch({ type: actionConstants.SET_VALIDATION_ERRORS, payload: stepErrors });
          break;
        }
        case 4: {
          //validuj vzorky - vyplnene barcode na vzorkach
          stepValid = true;
          stepErrors = {...props.step4_state.errors};
          stepErrors['allerrs'] = null;
          let ziadanka = {...{vzorky: []}, ...props.ziadanka, ...tmpZiadanka};
          stepValid = stepValidators.validators.vzorkyValidator(ziadanka, stepErrors, stepValidators.validators.validateOnEnum.FORM);
          stepErrors.allerrs = [];
          //3
          //1.dtodberu
          let err = props.dt_odberu.errors;
          stepValid = stepValidators.validators.dtOdberuValidator(props.dt_odberu, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs, err);
          //2.diagnozy
          err = props.diagnozy.errors;
          stepValid = stepValidators.validators.diagnozyValidator(props.diagnozy, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs, err);
          //3.ziadanka
          err = props.ziadankaErrors;
          stepValid = stepValidators.validators.ziadankaValidator(ziadanka, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs, err);
          //2
          err = props.pacient.errors;
          stepValid = stepValidators.validators.pacientValidator(props.pacient, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs, err);
          //1
          err = props.lekar.errors;
          stepValid = stepValidators.validators.lekarValidator(props.lekar, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs, err);

          err = props.tlac_state.errors;
          stepValid = stepValidators.validators.tlacValidator(props.tlac_state, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs, err);

          if( stepValid===false) props.dispatch({type:actionConstants.SET_VALIDATION_ERRORS,payload:stepErrors});
          break;
        }
        case 5: {
          //validuj review
          stepValid = true;
          let ziadanka = {...{vzorky:[]},...props.ziadanka,...tmpZiadanka};
          //5
          stepErrors = props.review.errors;
          stepValid = stepValidators.validators.reviewValidator(props.review, stepErrors, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          //4
          stepErrors.allerrs = []
          let err = props.step4_state.errors;
          stepValid = stepValidators.validators.vzorkyValidator(ziadanka,err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs,err);
          //3
          //1.dtodberu
          err =  props.dt_odberu.errors;
          stepValid = stepValidators.validators.dtOdberuValidator(props.dt_odberu, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs,err);
          //2.diagnozy
          err = props.diagnozy.errors;
          stepValid = stepValidators.validators.diagnozyValidator(props.diagnozy, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs,err);
          //3.ziadanka
          err = props.ziadankaErrors;
          stepValid = stepValidators.validators.ziadankaValidator(ziadanka, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs,err);
          //2
          err = props.pacient.errors;
          stepValid = stepValidators.validators.pacientValidator(props.pacient, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs,err);
          //1
          err = props.lekar.errors;
          stepValid = stepValidators.validators.lekarValidator(props.lekar, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs,err);

          err = props.tlac_state.errors;
          stepValid = stepValidators.validators.tlacValidator(props.tlac_state, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
          addErrorsFromObj(stepErrors.allerrs, err);

          if (stepValid === false) props.dispatch({ type: actionConstants.SET_VALIDATION_ERRORS, payload: stepErrors });
          break;
        }
        default:
          break;
      }
    }
    return stepValid;
    function addErrorsFromObj(addTo,obj={}){
      for(const prop in obj){
        if(obj.hasOwnProperty(prop)&&obj[prop] instanceof Array) {
          obj[prop].forEach(element => {
            addTo.push(element);
          });
        }
      }
    }
  }

  const handleThanks = (page) => {
    if (props.activeStep === 5) {
      history.push("/" + page);
    }
  }

  const materialSpecial = (ziadanka) => {
    const materials = ['M_S','M_CK_CITRAT','M_CK_EDTA','M_M']
    const vysetrenia_citrat = ['09610','09615','04762','04767','04753','31509','10858','16534','07909','07883','12290','31514','31534','31532',
                            '04667','04672','04677','04682','04687','04692','04697','04702','07816']
    const vysetrenia_edta = [['30242','30241'],['14444'],['30381','30383','30385'],['30380','30378'],['02073']]

    const material_param = {
      M_S : {vysetrenia : 'ALL', skumavky : 1 },
      M_M : {vysetrenia : 'ALL', skumavky : 1 },
      M_CK_CITRAT : {vysetrenia : vysetrenia_citrat, skumavky: 3 },
      M_CK_EDTA : {vysetrenia : vysetrenia_edta, skumavky: 1, check : (values,kod) => { return values.find(value => value.includes(kod)) } }
    }

    let vzorky = deepClone(ziadanka.vzorky);
    let poziadavky = ziadanka.poziadavky;
    let noveVzorky = [];
    console.log(poziadavky);
    console.log(vzorky);
    materials.forEach(kod => {
      if(material_param.hasOwnProperty(kod)){
        console.log(`kod:${kod}`);
        let novaVzorka;
        let odbornosti=[];
        var materialVzorky=[];
        if(noveVzorky.length > 0) ziadanka.vzorky=noveVzorky
        materialVzorky = vzorky.filter( vzorka => vzorka.material.nclpKod === kod);
        let pocet = new Set(materialVzorky.filter(item=>(item.material['imutable']===true||!item.material.hasOwnProperty('imutable')))).size;
        if(pocet > 0) {
          novaVzorka = materialVzorky[0];
          let vysetreniVKombinaciach = 0;
          if(material_param[kod].hasOwnProperty('check')) {
            material_param[kod].vysetrenia.forEach(komb => {
                let findvys=[];
                let odb;
                 poziadavky.filter(poziadavka => poziadavka.material && poziadavka.material === (parseInt(novaVzorka.material.id))).forEach(poziadavka => {
                    let vys=material_param[kod].check(komb,poziadavka.nclpKod)
                    if(vys) {
                      findvys.push(vys);
                      odb = komb.toString();
                    }
                })
                console.log(`komb: ${komb}`);
               if(findvys.length > 0 && JSON.stringify(komb.sort()) === JSON.stringify(findvys.sort())){
                  odbornosti.push(odb);
                  vysetreniVKombinaciach=vysetreniVKombinaciach+komb.length;
                }
            })
            if(odbornosti.length>0 && poziadavky.filter(poziadavka => poziadavka.material && poziadavka.material === (parseInt(novaVzorka.material.id))).length>vysetreniVKombinaciach) {console.log(`vysVKomb=${vysetreniVKombinaciach}`);odbornosti.push('@navyse@');}
          }
          else {
            poziadavky.filter(poziadavka => poziadavka.odbornost && poziadavka.material && poziadavka.material === (parseInt(novaVzorka.material.id))).forEach(poziadavka => {
            if(material_param[kod].vysetrenia==='ALL' || (material_param[kod].vysetrenia!=='ALL' && material_param[kod].vysetrenia.includes(poziadavka.nclpKod)))
              odbornosti.push(poziadavka.odbornost);
            });
          }
          if(odbornosti.length > 0) {
            console.log(`pocet:${pocet}`);
            let pocOdb = new Set(odbornosti).size;
            console.log(`pocOdb:${pocOdb}`);
            let skumavky_pocet=pocOdb * material_param[kod].skumavky;
            console.log(`skumavky_pocet:${skumavky_pocet}`);
            if(skumavky_pocet > 1 && skumavky_pocet !== pocet) {
              noveVzorky = [];
              for(let i=0;i<skumavky_pocet-1;i++) {
                noveVzorky.push( { id : "", material : { ...novaVzorka.material, imutable : true } })
              }
              if(noveVzorky.length > 0) {
                noveVzorky = noveVzorky.concat(ziadanka.vzorky)
              }
            }
          }
        }
      }
    });

    console.log("result");
    console.log(noveVzorky);

    return noveVzorky || [];
  }

  const ziadankaInvalidate4 = (x={}) => {
    if(props.ziadanka){
      let noveVzorky=[]
      let poziadavkyChanged = JSON.stringify(x.poziadavky)===JSON.stringify(props.ziadanka.poziadavky)
      if(!poziadavkyChanged) {
        let duplVzorky=x.vzorky
        noveVzorky = [...new Map(duplVzorky.filter(item => item.material.imutable===true || !item.material.hasOwnProperty('imutable'))
          .map(item => [item.material.id, item])).values()]
          .concat(duplVzorky.filter(item => item.material.imutable===false))
        //noveVzorky = [...new Map(duplVzorky.map(item => [item.material.id && item.material.imutable, item])).values()]
        x.vzorky = noveVzorky;
        noveVzorky = materialSpecial(x);
        if(noveVzorky.length > 0) {
          x.vzorky = noveVzorky;
        }
      }
      let vzorkyChanged = JSON.stringify(x.vzorky)===JSON.stringify(props.ziadanka.vzorky);
      let udajeChanged = JSON.stringify(x.udaje)===JSON.stringify(props.ziadanka.udaje);
      return (vzorkyChanged&&udajeChanged);
    }else return false;
  }
  const handleNext = async () => {
    var x;
    let stepValid = props.validatedSteps[`_${props.activeStep}`] === true;
    if (props.activeStep === 3) { // Ziadanka - treba nastavit data ziadanky do reduxu
      if (window.curoOpenLabGetData) {
        x = window.curoOpenLabGetData().object
        props.dispatch({ type: 'SETZIADANKA', payload: { data_ziadanky: x ,validatedSteps:{_3:false,_4:ziadankaInvalidate4(x)}} })
        stepValid=false;
      }
    }

    if (props.activeStep === 2) {//idem na ziadanku => ak nie je nastaveny, nastav dt_odberu
      if (props.dt_odberu.dt === null) {
        props.dispatch({ type: 'SET_DT_ODBERU', payload: { dt_odberu: { dt: moment().format() } } });
      }
    }
    /*
    if (props.activeStep === 5) {
      handleThanks("thanks");
    }
    */

    //pustim z invalidneho kroku
    // validateStep(props.activeStep);
    // props.dispatch ({ type : 'SETACTIVESTEP',  payload: { ...props, activeStep : props.activeStep + 1} } ) ;
    //nepustim dalej
    if (validateStep(props.activeStep, stepValid, x)) {
      if (props.activeStep === 2) {
        let exists = false;

        await checkZiadankaExists(props.pacient.rc)
            .then((response) => {
              exists = response;

              if (response) {
                setIsZiadankaPatientExistsModalOpen(true);
              }
            })
            .catch((error) => {
              console.log(error.message);
            })

        if (exists) {
          return;
        }
      }

      updateProfilData();
      props.dispatch({ type: 'SETACTIVESTEP', payload: { activeStep: props.activeStep + 1, validatedSteps: { ...props.validatedSteps, [`_${props.activeStep}`]: true } } });
    } else {
      props.dispatch({ type: 'SETACTIVESTEP', payload: { activeStep: props.activeStep } });
    }

  };

  const handleBack = () => {
    // let stepValid=props.validatedSteps[`_${props.activeStep}`];
    if (props.activeStep === 3) { // Ziadanka - treba nastavit data ziadanky do reduxu
      if (window.curoOpenLabGetData) {
        var x = window.curoOpenLabGetData().object
        props.dispatch({ type: 'SETZIADANKA', payload: { data_ziadanky: x ,validatedSteps:{_3:false,_4:ziadankaInvalidate4(x)}} })
        // stepValid=false;
      }
    }
    // stepValid=validateStep(props.activeStep,stepValid,x);
    props.dispatch({ type: 'SETACTIVESTEP', payload: {activeStep: props.activeStep - 1 } }); //,validatedSteps:{...props.validatedSteps,[`_${props.activeStep}`]:stepValid}
  };

  const jumpTo = novyStep => {
    let stepValid = props.validatedSteps[`_${props.activeStep}`];
    if (props.activeStep === 3) { // Ziadanka - treba nastavit data ziadanky do reduxu
      if (window.curoOpenLabGetData) {
        var x = window.curoOpenLabGetData().object
        props.dispatch({ type: 'SETZIADANKA', payload: { data_ziadanky: x, validatedSteps: { _3: false,_4:ziadankaInvalidate4(x) } } })
        stepValid = false;
      }
    }
    if(novyStep === 3) {//idem na ziadanku => ak nie je nastaveny, nastav dt_odberu
      if(props.dt_odberu.dt===null||props.dt_odberu.dt===""){
        props.dispatch({ type: 'SET_DT_ODBERU', payload: { dt_odberu: {dt:moment().format()}}});
      }
    }
    // if(props.validateSteps[props.activeStep]===1)
    if (validateStep(props.activeStep, stepValid, x)) {
      updateProfilData();
      props.dispatch({ type: 'SETACTIVESTEP', payload: { activeStep: novyStep, validatedSteps: { ...props.validatedSteps, [`_${props.activeStep}`]: true } } });
    } else {
      props.dispatch({ type: 'SETACTIVESTEP', payload: { activeStep: props.activeStep } });
    }
  }


  /* Odosielanie dat datsta*/
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#58ACFA',
      color: 'white'
    }
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true)
  }
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }

  function deleteZiadanka(event, id) {
    event.preventDefault();

    // Call Delete API
    ziadankaDelete(id)
        .catch((error) => {
            throw error;
        })
  }

  const handleSendDasta = async (event) => {
    event.preventDefault();
    let stepValid = false;
    if(validateStep(props.activeStep, stepValid, {})){

      setModalIsOpenToTrue();
      let msg = 'Chyba pri odosielaní žiadanky - '
      try {
        let cpdata = completeData.getDastaData(deepClone(props));
        let ziadanka = await dastaService.todasta(cpdata);

        // Remove saved ziadanka from mongo DB on successful send;
        if (props._id) {
          await deleteZiadanka(event, props._id);
        }

        setModalIsOpenToFalse();
        if (ziadanka.message === 'OK') {
          handleThanks("thanks");

          msg = ziadanka.message
        } else {
          msg = msg + ziadanka.message;
        }
        props.dispatch(success(msg));
      } catch (error) {
        setModalIsOpenToFalse();
        props.dispatch(success(msg + error.toString()));
      }
    }
    function success(message) {
      return { type: actionConstants.ERROR, payload: { ...props.dasta, message: message } }
    }
  }

  function openZiadankaModal(event) {
    event.preventDefault();

    const ziadankaState = { ...props.state };
    ziadankaState.diagnozy = {
      diag: [...props.state.diagnozy.diag ],
      errors: { ...props.state.diagnozy.errors }
    }
    ziadankaState.dt_odberu = { ...props.state.dt_odberu };
    delete ziadankaState.user;
    delete ziadankaState.ID_ZIADANKA;
    delete ziadankaState.initial;
    delete ziadankaState.loggedIn;
    delete ziadankaState.resetPsw;
    delete ziadankaState.registerMessage;
    delete ziadankaState.userProfil;
    delete ziadankaState.dt_odberu.dt;

    setZiadankaContent(ziadankaState)
    setIsZiadankaModalOpen(true);
  }

  function closeZiadankaModal(event) {
    event.preventDefault();

    setIsZiadankaModalOpen(false);
  }

  function closeZiadankaPatientExistsModal(event) {
    event.preventDefault();

    setIsZiadankaPatientExistsModalOpen(false);
  }

  function navigateToList() {
    history.push(`/list?birth_code=${props.pacient.rc}`);
  }

  function closeZiadankaPatientExistsModalAndContinue(event) {
    event.preventDefault();

    closeZiadankaPatientExistsModal(event);
    jumpTo(3);
  }

  function renderActionButtons() {
    switch (props.activeStep) {
    case 5:
      return <div className="col-lg-12">
        <div>
          <button className="button--dark" color="primary" onClick={handleSendDasta}>
            Odoslať žiadanku
          </button>
          <Modal style={customStyles} isOpen={modalIsOpen}>
            <SendDasta />
          </Modal>
        </div>
      </div>;

    case 4:
      return <>
        <div className="col-md-6">
          <button className="button--white" onClick={openZiadankaModal}>
            Uložiť rozpracované žiadanky
          </button>
        </div>
        <div className="col-md-6">
          <button className="button--dark" color="primary" onClick={handleNext}>
            Pokračovať
          </button>
        </div>
      </>;
    default:
      if ((props.activeStep < steps.length && props.activeStep>0)) {
        return <div className="col-lg-12">
          <button className="button--dark" color="primary" onClick={handleNext}>
            Pokračovať
          </button>
        </div>
      }
    }
  }

  return (

    <div>
      <header id="header" className="header bgcTM">
        <div className="header--menu p0 w-100">
          <div className="header--menu--left">
            {!props.lekar.kzOrigin && <div className="header--menu--left--back header--menu--spat">
              <a href="https://www.medirex.sk/vysetrenia">
                <i className="icon medirex-long-arrow-left"></i>
                <span>&nbsp;Späť&nbsp;na&nbsp;web</span>
              </a>
            </div>}
            <div className="header-cart--menu nav-main">
              <div className = "header-cart--menu--steps">
              <ul className="ul-main">
                {steps.map((x, i) =>
                  <li className={"" + (i === props.activeStep ? "active" : "") + " " + (i < props.activeStep ? "visited" : "")} key={i}>
                    <a href="/#" onClick={event => jumpTo(i)}>
                      {/* <span className={"indicator "+(i <= props.activeStep? "visited-icon" : "step-ind")}> */}
                      <span className={"indicator " + (props.validatedSteps[`_${i}`] === true ? "visited-icon" : "step-ind")}>
                        {(props.validatedSteps[`_${i}`] === true ? "" : i + 1)}
                      </span>
                      <span>{x}</span>
                    </a>
                  </li>
                )}
              </ul>
              </div>
            </div>
          </div>
          <ActiveUser/>
        </div>
        {/*<div className="space-10" styl={{width:"100%", backgroundColor:"#f4f6f8"}}/>*/}
      </header>

      <div className="checkout">
        <div className="checkout--box">
          <div className="checkout--box-inner">
            <div className="col-sm-12 col-md-12 column">
              {getStepContent(props.activeStep, props.activeForm)}
              <div className="row">
                {(props.dasta.message !== 'OK') && (props.activeStep === 5) &&
                  <div className="form-error"><center><h4>{props.dasta.message}</h4></center><p>&nbsp;</p></div>
                }
                  {renderActionButtons()}
                  <div className="row row-dummy col-sm-12"></div>
                  <div className="col-lg-12">
                  {/* <div styl={{ "float": "left" }}> */}
                    {props.activeStep !== 0 && (
                      <button className="button--border" onClick={handleBack} >
                        Späť
                      </button>
                    )}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
          className="modal__dialog"
          isOpen={isZiadankaModalOpen}
          ariaHideApp={false}
          overlayClassName="modal"
          closeTimeoutMS={200}
      >
        <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeZiadankaModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div>
          <h6 className="modal-title">Zadajte názov pre { isUpdate ? 'aktualizovanie' : 'uloženie' } aktuálnej žiadanky</h6>
        </div>

        <FormSaveZiadanka
            content={ziadankaContent}
            isUpdate={isUpdate}
            closeModal={closeZiadankaModal}
        />
      </Modal>

      <Modal
          className="modal__dialog"
          isOpen={isZiadankaPatientExistsModalOpen}
          ariaHideApp={false}
          overlayClassName="modal"
          closeTimeoutMS={200}
      >
        <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeZiadankaPatientExistsModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div>
          <h6 className="modal-title">
            Pre pacienta s rodným číslom <strong>{props.pacient.rc}</strong> evidujeme rozpracované žiadanky.
            <br/><br/>Prajete si ich zobraziť?
          </h6>
        </div>

        <div className="mt-4 d-flex justify-content-end">
          <button
              type="button"
              className="button--white mr-2"
              data-dismiss="modal"
              onClick={closeZiadankaPatientExistsModalAndContinue}
          >
            Nie
          </button>
          <button
              type="button"
              className="button--dark relative"
              onClick={navigateToList}
          >
            Áno
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps)(Form);


/*
<div >
          <div className="row">
            <div className="categories subpage">
              <div className="categories--box">
                <ul className="categories--boxes">
                  {steps.map( (x,i) =>
                    <li className={ "category-item " + (i === props.activeStep ? "active":"")} key={i}>
                      <a href="/#">
                        <span>{i === props.activeStep ? <b>{x}</b> : x} </span>
                      </a>
                    </li>
                )}
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div styl={{"float" : "left"}}>
                {props.activeStep !== 0 && (
                  <button className = "button--dark" onClick={handleBack} >
                    Späť
                  </button>
                )}
              </div>
              <div styl={{"float" : "right"}}>
                {props.activeStep < steps.length-1 && (props.activeForm !== 'R') && (
                  <button
                    className = "button--dark"
                    color="primary"
                    onClick={handleNext}
                  >
                    Ďalej
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
                {getStepContent(props.activeStep,props.activeForm)}
            </div>
          </div>
      </div>
*/
