import Modal from 'react-modal';
import React from 'react';

export default function VzorkyModal({ model, close, children }) {
    const styles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '800px',
            width: '100%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#F4F6F7',
            color: '#000000'
        },
        overlay: {
            backgroundColor: 'rgba(0,0,0, .6)'
        },
    };

    return (
        <Modal
            className="vzorky-modal"
            isOpen={model}
            ariaHideApp={false}
            onRequestClose={close}
            style={styles}
        >
            {children}
        </Modal>
    )
}
