const config={
	// apiUrl:'https://medirex.software-foundation.sk/serve',
	apiUrl: process.env.REACT_APP_API_URL,
	appUrl : process.env.REACT_APP_URL,
	hdrtoken: 'x-fs-access-token',
	pwsecret: 'zckq[ejk[tuy-02',
	expire: 120, //sekund,
	curolab_template_storage: 'curoTemplates',
	local_state_storage: 'state'
}

export default config;