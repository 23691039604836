import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import api from '../../services/serveApi';
import { isValid as lekarIsValid } from '../../validators/lekar';
import { checkData, isValid as patientIsValid } from '../../validators/pacient';
import axios from 'axios';


const mapStateToProps = (state) => {
    return ({
        lekar: state.lekar,
        kzOrigin: state.kzOrigin,
        pacient: state.pacient,
        review: state.review,
    })
};

let isValidated = false;

function Bridge(props) {
    const localQueryParams = new URLSearchParams(window.location.search);
    const [isLoaded, setIsLoaded] = useState(false);
    const [redirect] = useState(localQueryParams.get('redirect'));
    const history = useHistory();

    function queryParams() {
        if (isLoaded === false) {
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams) {
                setIsLoaded(true);
                test(queryParams);
            }
        }
    }

    useEffect(() => {
        queryParams();
    }, [])

    const test = (queryParams) => {

        if (isValidated === false) {

            isValidated = true;

            api.getUserByKzID(queryParams.get('id'))
                .then(
                    obj => {
                        let delivery = true;

                        if (obj && obj.lekar) {

                            if (!obj.lekar.OrderingAmbulance?.ResultDeliveryAddress?.City &&
                                !obj.lekar.OrderingAmbulance?.ResultDeliveryAddress?.Country &&
                                !obj.lekar.OrderingAmbulance?.ResultDeliveryAddress?.Street) {
                                delivery = false;
                            }

                            let activeStep = 1;

                            let lekar = {
                                kzOrigin: true,
                                pzs: obj.lekar.OrderingAmbulance.CompanyName ? obj.lekar.OrderingAmbulance.CompanyName : '',
                                ico: obj.lekar.OrderingAmbulance.RegNumber ? obj.lekar.OrderingAmbulance.RegNumber : '',
                                // ico: '36429724',
                                adresa_sidlo_ulica_cislo: obj.lekar.OrderingAmbulance.Address?.Street ? obj.lekar.OrderingAmbulance.Address?.Street : '',
                                adresa_sidlo_mesto: obj.lekar.OrderingAmbulance.Address?.City ? obj.lekar.OrderingAmbulance.Address?.City : '',
                                adresa_sidlo_psc: obj.lekar.OrderingAmbulance.Address?.ZipCode ? obj.lekar.OrderingAmbulance.Address?.ZipCode : '',
                                // adresa_sidlo_psc: '90201',
                                adresa_vysledky_ina: delivery,
                                adresa_vysledky_ulica_cislo: obj.lekar.OrderingAmbulance.ResultDeliveryAddress?.Street ? obj.lekar.OrderingAmbulance.ResultDeliveryAddress?.Street : '',
                                adresa_vysledky_mesto: obj.lekar.OrderingAmbulance.ResultDeliveryAddress?.City ? obj.lekar.OrderingAmbulance.ResultDeliveryAddress?.City : '',
                                adresa_vysledky_psc: obj.lekar.OrderingAmbulance.ResultDeliveryAddress?.ZipCode ? obj.lekar.OrderingAmbulance.ResultDeliveryAddress?.ZipCode : '',
                                // adresa_vysledky_psc: '90201',
                                meno: obj.lekar.OrderingDoctor.FullName ? obj.lekar.OrderingDoctor.FullName : '',
                                kod_lekara: obj.lekar.OrderingDoctor.Code ? obj.lekar.OrderingDoctor.Code : '',
                                kod_pzs: obj.lekar.OrderingAmbulance.Code ? obj.lekar.OrderingAmbulance.Code : '',
                                email: obj.lekar.OrderingAmbulance.Email ? obj.lekar.OrderingAmbulance.Email : '',
                                // email: 'test@test.sk',
                                telefon: obj.lekar.OrderingAmbulance.Phone ? obj.lekar.OrderingAmbulance.Phone : '',
                                // telefon: '+421987654321',
                                suhlas_vop: true,
                                kz_id: obj.lekar.LogedUserId,
                                errors: {
                                    meno: null,
                                    pzs: null,
                                    email: null,
                                    kod_lekara: null,
                                    kod_pzs: null,
                                    telefon: null,
                                    ico: null,
                                    adresa_sidlo_ulica: null,
                                    adresa_sidlo_cislo: null,
                                    adresa_sidlo_ulica_cislo: null,
                                    adresa_sidlo_mesto: null,
                                    adresa_sidlo_psc: null,
                                    adresa_vysledky_ulica_cislo: null,
                                    adresa_vysledky_mesto: null,
                                    adresa_vysledky_psc: null,
                                    suhlas_vop: null,
                                },
                            }

                            let patientBirth = '';
                            let gender = '';
                            let personalId = '';
                            let address = '';

                            if (obj.lekar.Patient && obj.lekar.Patient.PersonalId) {
                                personalId = obj.lekar.Patient.PersonalId.replace('/', '');
                                let year = checkData(personalId).year;
                                let month = checkData(personalId).month;
                                let day = checkData(personalId).day;


                                gender = 'm';
                                if (month > 12) {
                                    if (month > 50 && month < 63) {
                                        gender = 'z';
                                        month = month - 50;
                                    } else return false;
                                }
                                patientBirth = `${year}-` + `${month}`.padStart(2, '0') + '-' + `${day}`.padStart(2, '0');
                            }
                            let patient = {};
                            if (obj.lekar && obj.lekar.Patient) {

                                let street = obj.lekar.Patient.Address.Street ? obj.lekar.Patient.Address.Street : '';
                                let city = obj.lekar.Patient.Address.City ? obj.lekar.Patient.Address.City : '';
                                let zipCode = obj.lekar.Patient.Address.ZipCode ? obj.lekar.Patient.Address.ZipCode : '';

                                address = street + ', ' + city + ', ' + zipCode;

                                patient = {
                                    rc: obj.lekar.Patient.PersonalId ? personalId : '',
                                    priezvisko: obj.lekar.Patient.LastName ? obj.lekar.Patient.LastName : '',
                                    meno: obj.lekar.Patient.FirstName ? obj.lekar.Patient.FirstName : '',
                                    adresa: address,
                                    poisKod: obj.lekar.Patient.Insurance ? obj.lekar.Patient.Insurance : '',
                                    datNar: patientBirth,
                                    pohlavie: gender,
                                    errors: {
                                        rc: null,
                                        datNar: null,
                                        pohlavie: null,
                                        priezvisko: null,
                                        meno: null,
                                        adresa: null,
                                        poisKod: null,
                                    },
                                }

                                let patietnErrors = {};
                                patietnErrors = { ...props.pacient.error, ...patient.errors };

                                if (patientIsValid(patient, patietnErrors)) {
                                    activeStep = 2
                                }

                                patient.errors = patietnErrors;
                            }

                            let review = {
                                suhlas_B: true,
                            }

                            let lekarErrors = {};
                            lekarErrors = { ...props.lekar.errors, ...lekar.errors };

                            if (lekarIsValid(lekar, lekarErrors)) {
                                activeStep = 2
                            }

                            lekar.errors = lekarErrors;

                            axios.defaults.headers = {
                                ...axios.defaults.headers,
                                'x-fs-access-token': obj.user.accessToken,
                            }

                            props.dispatch({
                                type: 'LOGINKZ',
                                payload: {
                                    lekar: lekar,
                                    pacient: patient,
                                    activeStep: activeStep,
                                    userToken: obj.user.accessToken,
                                    user: obj.user,
                                    review: review,
                                    validatedSteps: {
                                        _0: true,
                                        _1: activeStep > 1,
                                        _2: activeStep > 2,
                                        _3: activeStep > 3,
                                    },
                                },
                            })
                        }
                    },
                )
                .finally(() => {
                    history.push(!!redirect ? redirect : '/')
                })
        }
    };

    return null;
}

export default connect(mapStateToProps)(Bridge);

