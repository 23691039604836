const completeData = {
	getPdfData,
	getDastaData
}

export default completeData;

function getPdfData(inZiadanka) {
	let birthDate = inZiadanka.pacient.datNar
	let dtNar
	if (birthDate) {
		const parts = birthDate.split('-')
		dtNar = parts[2] + '.' + parts[1] + '.' + parts[0]
	}
	let dtOdb = inZiadanka.dt_odberu.dt
	var datestring
	if (dtOdb) {
		const pst = inZiadanka.dt_odberu.dt.toString()
		const d = new Date(pst)
		datestring = d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear() + " " + d.getHours() + ":" + ('0' + d.getMinutes()).slice(-2);
	}
	inZiadanka.ziadanka["idZiadanky"] = inZiadanka.idZiadanky
	inZiadanka.ziadanka["dtOdberu"] = datestring
	inZiadanka.ziadanka["datNar"] = dtNar.toString()
	inZiadanka.ziadanka.ziadatel["adresa"] = getAdresaLekara(inZiadanka.lekar, "sidlo")
	inZiadanka.ziadanka.ziadatel["adresav"] = inZiadanka.lekar.adresa_vysledky_ina ? getAdresaLekara(inZiadanka.lekar, "vysledky") : inZiadanka.ziadanka.ziadatel.adresa
	inZiadanka.ziadanka.ziadatel["lekarKod"] = inZiadanka.lekar.kod_lekara
	inZiadanka.ziadanka.ziadatel["pzsKod"] = inZiadanka.lekar.kod_pzs
	inZiadanka.ziadanka.ziadatel["pzsNazov"] = inZiadanka.lekar.pzs
	inZiadanka.ziadanka.ziadatel["nazov"] = inZiadanka.lekar.meno
	inZiadanka.ziadanka.ziadatel["email"] = inZiadanka.lekar.email
	inZiadanka.ziadanka.ziadatel["telefon"] = inZiadanka.lekar.telefon

	inZiadanka.ziadanka.pacient["adresa"] = inZiadanka.pacient.adresa
	inZiadanka.ziadanka.pacient["pohlavie"] = inZiadanka.pacient.pohlavie.toUpperCase()
	inZiadanka.ziadanka.pacient["priezvisko"] = inZiadanka.pacient.priezvisko
	inZiadanka.ziadanka.pacient["meno"] = inZiadanka.pacient.meno
	inZiadanka.ziadanka.pacient["zs"] = inZiadanka.pacient.poisKod
	inZiadanka.ziadanka.pacient["icp"] = inZiadanka.pacient.poisKod
	inZiadanka.ziadanka.pacient["rc"] = inZiadanka.pacient.rc
	inZiadanka.ziadanka.pacient["datumNarodenia"] = dtNar.toString()
	inZiadanka.ziadanka.pacient["statDasta"] = '703'
	inZiadanka.ziadanka['uhrada'] = { poistovnou: { poistovna: inZiadanka.pacient.poisKod } }
	inZiadanka.ziadanka['diagnozy'] = getDiag(inZiadanka.diagnozy.diag)
	inZiadanka.ziadanka['poziadavky'] = getPoziadavkyPdf(inZiadanka.ziadanka.vzorky, inZiadanka.ziadanka.poziadavky)

	inZiadanka.ziadanka['textLekara'] = getWrapText(inZiadanka.ziadanka.textLekara, " ", 90)

	return inZiadanka.ziadanka
};

function getDastaData(inZiadanka) {
	let birthDate = inZiadanka.pacient.datNar
	if (birthDate) {
		const parts = birthDate.split('-')
		birthDate = { rok: parts[0], mesiac: parts[1], den: parts[2] }
	}
	inZiadanka.ziadanka["idZiadanky"] = inZiadanka.idZiadanky
	inZiadanka.ziadanka["dtOdberu"] = inZiadanka.dt_odberu
	inZiadanka.ziadanka.ziadatel["adresa"] = getAdresaLekara(inZiadanka.lekar)
	inZiadanka.ziadanka.ziadatel["lekarKod"] = inZiadanka.lekar.kod_lekara
	inZiadanka.ziadanka.ziadatel["pzsKod"] = inZiadanka.lekar.kod_pzs
	inZiadanka.ziadanka.ziadatel["nazov"] = inZiadanka.lekar.meno
	inZiadanka.ziadanka.ziadatel["nazov"] = inZiadanka.lekar.meno

	inZiadanka.ziadanka.pacient["adresa"] = inZiadanka.pacient.adresa
	inZiadanka.ziadanka.pacient["pohlavie"] = getPohlavie(inZiadanka.pacient.pohlavie.toUpperCase());
	inZiadanka.ziadanka.pacient["priezvisko"] = inZiadanka.pacient.priezvisko
	inZiadanka.ziadanka.pacient["meno"] = inZiadanka.pacient.meno
	inZiadanka.ziadanka.pacient["zs"] = inZiadanka.pacient.poisKod
	inZiadanka.ziadanka.pacient["icp"] = inZiadanka.pacient.poisKod
	inZiadanka.ziadanka.pacient["rc"] = inZiadanka.pacient.rc
	inZiadanka.ziadanka.pacient["datumNarodenia"] = birthDate
	inZiadanka.ziadanka.pacient["statDasta"] = '703'
	inZiadanka.ziadanka['uhrada'] = { poistovnou: { poistovna: inZiadanka.pacient.poisKod } }
	inZiadanka.ziadanka['diagnozy'] = getDiag(inZiadanka.diagnozy.diag)
	inZiadanka.ziadanka['diagnozy'] = getDiag(inZiadanka.diagnozy.diag)
	inZiadanka.ziadanka['poziadavky'] = getPoziadavkyPdf(inZiadanka.ziadanka.vzorky, inZiadanka.ziadanka.poziadavky)
	// docasne - typ prenasaneho suboru - podla enviromentu bude 3 - Test / 0 - Produkcia
	if (process.env.REACT_APP_ENV_NAME!=="prod") {
		inZiadanka.ziadanka.hlavicka['typPrenasanychDat'] = 3
	} else {
		inZiadanka.ziadanka.hlavicka['typPrenasanychDat'] = 0
	}
	
	return inZiadanka.ziadanka
};

function getPohlavie(pohl) {
	switch (pohl) {
		case 'M':
			return "1";
		case 'Z':
			return "2";
		default:
			return "";
	}			
}

function getDiag(jstr) {
	var retval = []
	jstr.forEach((e, i, a) => {
		retval[i] = { "kod": e.code }
	});
	return retval
}

function getPoziadavky(vzorky, poziad) {
	var retval = []
	vzorky.forEach((e, i, a) => {
		if (e.id !== "") {
			retval[i] = { [e.id]: [] }
			if (poziad) {
				poziad.forEach((ep, ip, ap) => {
					if (e.material.id === ep.material.toString()) {
//					if (e.id === ep.material.toString()) {						
						retval[i][e.id].push(ep.nclpKod)
					}
				})
			}
		}
	});
	return retval
}

function getPoziadavkyPdf(vzorky, poziad) {
	var retval = []
	vzorky.forEach((e, i, a) => {
		if (e.id !== "") {
			retval[i] = { [e.id]: [] }
			if (poziad) {
				poziad.forEach((ep, ip, ap) => {
					if (ep.material && e.material.id === ep.material.toString()) {
						if (poziad[ip]['vzorkaKod']) {
							if (poziad[ip]['vzorkaKod'] instanceof Array) {
								poziad[ip]['vzorkaKod'].push(e.id)
							} else {
								poziad[ip]['vzorkaKod'] = [poziad[ip]['vzorkaKod'], e.id]
							}
						} else {
							poziad[ip]['vzorkaKod'] = (e.id)
						}
					}
				})
			}
		}
	});
	return poziad
}


function getAdresaLekara(lekar, typ) {
	var retval
	if (lekar.adresa_vysledky_ina) {
		if (typ === "vysledky" || !typ) {
			retval = (lekar.adresa_vysledky_ulica_cislo ? lekar.adresa_vysledky_ulica_cislo + " " : "")
				//(lekar.adresa_vysledky_ulica ? lekar.adresa_vysledky_ulica + " " : "")
				//+(lekar.adresa_vysledky_cislo ? lekar.adresa_vysledky_cislo + ", " : "" )
				+ (lekar.adresa_vysledky_mesto ? lekar.adresa_vysledky_mesto + " " : "")
				+ (lekar.adresa_vysledky_psc ? lekar.adresa_vysledky_psc + " " : "")
		}
	} 
	if (typ === "sidlo" || !typ) {
		retval = (lekar.adresa_sidlo_ulica_cislo ? lekar.adresa_sidlo_ulica_cislo + " " : "")
			//(lekar.adresa_sidlo_ulica ? lekar.adresa_sidlo_ulica + " " : "")
			//+(lekar.adresa_sidlo_cislo ? lekar.adresa_sidlo_cislo + ", " : "" )
			+ (lekar.adresa_sidlo_mesto ? lekar.adresa_sidlo_mesto + " " : "")
			+ (lekar.adresa_sidlo_psc ? lekar.adresa_sidlo_psc + " " : "")
	}
	return retval
}

function getWrapText(ioStr, symbol, limit) {
	symbol = symbol ? symbol : " "
	limit = limit ? limit : 90
	if (ioStr.length > limit) {
		let outStr = '';
		for (let i = 0, count = 0; i < ioStr.length; i++) {
			if (	(count >= limit && ioStr[i] === symbol) 
				||  (count >= limit && ioStr.substring(count - limit, limit).indexOf(symbol) <= 0) ) {
					count = 0;
					outStr += '\r\n';
			} else {
				count++;
				outStr += ioStr[i];
			}
		}
		ioStr = outStr
	}
	return ioStr
}
