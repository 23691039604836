import React from 'react';
import api from '../../services/serveApi';


let isValidated = false;

function saveLekarData(props) {

    const kz_lekar = {
        kod_lekara : "A39565027",
        kod_pzs : "P38811027201",
        meno: '',
        // //optional
        telefon : '+421123456789',
        ico : '',
        pzs : '',
        email : 'test@medirex.sk',
        adresa_sidlo_ulica_cislo : '',
        adresa_sidlo_mesto : '',
        adresa_sidlo_psc : '',
        adresa_vysledky_ulica_cislo : '',
        adresa_vysledky_mesto : '',
        adresa_vysledky_psc: '',
        suhlas_vop: true,
    };

    if(isValidated === false){
        isValidated = true;
        
        api.saveDataForLekar(kz_lekar)
            .then(
                obj => {
                    return window.location.href = '/bridge?id=' + obj;
                }
            )
    };

    return (
        <div>Loading ...</div>
    );
}


export default (saveLekarData);