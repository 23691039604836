import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import "./form-styles/SelectItem.css"

const mapStateToProps = (state) => {
  return ({ diagnozy: state.diagnozy, stepValid: state.validatedSteps._3 })
};

function SelectItem(props) {

    const [indexPzs,setIndexPzs] = useState (-1);
    const options = [...props.options];

    const handleSelect = (i) =>{
        console.log("selected index=" + i);
        setIndexPzs(i);
        props.handleChange(i);
    }

  return (
            <div className="custom-dropdown" style={{display:props.show?"block":"none"}}>
                <ul className="custom-dropdown-menu" style={{display:props.show?"block":"none"}}>
                {options.map( (item, indexPzs) =>
                    <li key={indexPzs} className="custom-dropdown-item" onMouseDown={() => {handleSelect(indexPzs);}}>
                       <div>
                           <span>{item.pzs_kod} - {item.nazov_pzs}</span>
                           <p>{item.obec} - {item.psc}, {item.ulica_cislo_ou}</p>
                       </div>
                    </li>
                )}
                </ul>
            </div>
    );
}
/*
)}
*/
export default connect(mapStateToProps)(SelectItem);


