import React from 'react';
import { connect } from 'react-redux';
import { validators } from '../validators/resetPsw';
import validateOnEnum from '../validators/validateOnEnum'

import userService from '../services/serveApi'
import actionConstants from '../actionConstants'
import Header from './Header';
import Footer from './Footer';

const mapStateToProps = (state) => {
	return ({ resetPsw: state.resetPsw })
}
const ForgotPsw = (props) => {
	const touched = {
		email: !(props.resetPsw.errors.email === null),
		securityAnswer: !(props.resetPsw.errors.securityAnswer === null)
	}
	function onChange(event) {
		const propValue = event.target['value'];
		const propName = event.target['name'];
		var errVal = props.resetPsw.errors;	//existujuca hodnota 'errors' v props
		if (validators[propName].find(v => { return v.validateOn === validateOnEnum.CHANGE })) {
			errVal[propName] = [];
			validators[propName].filter(val => val.validateOn === validateOnEnum.CHANGE).forEach(validator => {
				if (validator.validate(propValue) === false) errVal[propName].push(validator.invalidMsg);
			});
		}
		props.dispatch({ type: actionConstants.RESETPSW, payload: { ...props, resetPsw: { ...props.resetPsw, msg: '', [propName]: propValue, errors: { ...props.resetPsw.errors, [propName]: errVal[propName] } } } });
	}

	async function sendResetPsw(event) {
		event.preventDefault();
		if (touched.email && touched.securityAnswer && props.resetPsw.errors.email.length === 0 && props.resetPsw.errors.securityAnswer.length === 0) {
			try {
				await userService.forgetPsw({ email: props.resetPsw.email, securityAnswer: props.resetPsw.securityAnswer });
				props.dispatch({ type: actionConstants.RESETPSW, payload: { ...props, resetPsw: { ...props.resetPsw, msg: 'SUCCESS' } } });
			} catch (err) {
				props.dispatch({ type: actionConstants.RESETPSW, payload: { ...props, resetPsw: { ...props.resetPsw, msg: err['message'] } } });
			}
		}
	}

	return (
		<div className="App1">
			<Header />
			<main id="cart-content"  >
				<div gift-codes-raw="[]">
					<header className="header header-cart">
						<div className="header-cart--menu">
							<div className="header--menu--back">
								<a href="/"><i
									className="icon medirex-long-arrow-left"></i>
                              Späť na Prihlásenie
                        </a>
							</div>
						</div>
					</header>

					<div className="col-sm-12 col-md-12 col-lg-12 column">
						<div className="password-reset__form-container mt-5">
							<h2 className="password-reset__title">Zabudli ste heslo?</h2>
							<p className="password-reset__sub-title">
								Zadajte prosím vašu emailovú adresu a kód lekára
            	</p>
							<form>
								<div className="form-group">
									<label htmlFor="email">
										<span className={"validation-icon " + (touched.email && props.resetPsw.errors.email.length > 0 ? "is-invalid" : (touched.email ? "valid" : ""))}></span>
                  				E-mail&nbsp;*
                				</label>
									<div className="input-box">
										<input className={"input " + (touched.email && props.resetPsw.errors.email.length > 0 ? "is-invalid" : "")} id="email" name="email" type="email" value={props.email} onChange={onChange} />
										<div className="form-error">
											{touched.email && props.resetPsw.errors['email'].map((value, i) =>
												<span key={i}>{value}</span>
											)}
										</div>
									</div>
									<label htmlFor="securityAnswer">
										<span className={"validation-icon " + (touched.securityAnswer && props.resetPsw.errors.securityAnswer.length > 0 ? "is-invalid" : (touched.securityAnswer ? "valid" : ""))}></span>
                  				Kód lekára&nbsp;*
		                		</label>
									<div className="input-box">
										<input id="securityAnswer" name="securityAnswer" type="text" className={"input " + (touched.securityAnswer && props.resetPsw.errors.securityAnswer.length > 0 ? "is-invalid" : "")} value={props.securityAnswer} onChange={onChange} />
										<div className="form-error">
											{touched.securityAnswer && props.resetPsw.errors['securityAnswer'].map((value, i) =>
												<span key={i}>{value}</span>
											)}
										</div>
									</div>
								</div>
								<div className="password-reset__submit">
									<button aria-label="send" className="button--dark" onClick={sendResetPsw}>
										<span>Odoslať</span>
									</button>
								</div>
								<div className="space-30"></div>

								<div className="col-12">
									<div className="form-error align-left">
										<span>
											{ props.resetPsw.msg && props.resetPsw.msg !== "SUCCESS" ?(props.resetPsw.msg.includes('Security answer does not match')||props.resetPsw.msg.includes('User not found') ?
											'Pre zadanú kombináciu emailovej adresy a kódu lekára neexistuje žiadne konto' : props.resetPsw.msg):''	}
										</span>
									</div>
								</div>
								{props.resetPsw.msg ==="SUCCESS" ? (
									<label className="registration-confirm">
										<p><h4 className="mb-0">Žiadosť o obnovu hesla bola zaregistrovaná!</h4>
											 <h4>Na adresu <b>{props.resetPsw.email}</b> bol odoslaný e-mail, ktorého súčasťou je link pre nastavenie nového hesla.</h4>
											 <h4 className="mt-3">Link je platný 24 hodín!</h4>
											 </p></label>
								) : ''}
							</form>
						</div>
					</div>
				</div>
			</main>

			<Footer />
		</div>
	)

}

export default connect(mapStateToProps)(ForgotPsw);