import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import userService from '../services/serveApi'
import userConstants from '../actionConstants'
import {isValid as lekarValid,validators as lekarValidators} from '../validators/lekar'
import {isValid as profilValid,validators as profilValidators} from '../validators/userprofil'
import validateOnEnum from '../validators/validateOnEnum'
import SelectItem from './SelectItem'
import SelectPzs from './SelectPZS'
import * as stepValidators from "../validators";

const mapStateToProps = (state) => {
	return { lekar:state.lekar, renderByErrorsChange:state.lekar.errors, userProfil:state.userProfil, regIn:state.regIn};
};

function Register(props) {

    const [options,setOptions] = useState([])
    const [index, setIndex] = useState(-1)
    const [showSelect,setShowSelect] = useState(false)

    const [optionsPzs, setOptionsPzs] = useState([])
    const [indexPzs, setIndexPzs] = useState(-1)
    const [showSelectPzs, setShowSelectPzs] = useState(false)

    const [showPwd,setShowPwd] = useState(false)
    const [showPwdC,setShowPwdC] = useState(false)


  useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const toggleShowPwd = (event) => {
        event.target.id==="eye-pwd"?setShowPwd((!showPwd)):setShowPwdC((!showPwdC)) ;
	}

    const touched = {
        meno:!(props.lekar.errors.meno===null),
        pzs : !(props.lekar.errors.pzs===null),
        email : !(props.lekar.errors.email===null),
        kod_lekara : !(props.lekar.errors.kod_lekara===null),
        kod_pzs : !(props.lekar.errors.kod_pzs===null),
        telefon : !(props.lekar.errors.telefon===null),
        ico : !(props.lekar.errors.ico===null),
        adresa_sidlo_ulica_cislo : !(props.lekar.errors.adresa_sidlo_ulica_cislo===null),
        //adresa_sidlo_ulica : !(props.lekar.errors.adresa_sidlo_ulica===null),
        //adresa_sidlo_cislo : !(props.lekar.errors.adresa_sidlo_cislo===null),
        adresa_sidlo_mesto : !(props.lekar.errors.adresa_sidlo_mesto===null),
        adresa_sidlo_psc : !(props.lekar.errors.adresa_sidlo_psc===null),
        suhlas_vop:!(props.lekar.errors.suhlas_vop===null),
        password:!(props.userProfil.errors.password===null),
        confPassword:!(props.userProfil.errors.confPassword===null),
    };
    const validate = (event) => {
		const target = event.target;
        const targetValue = target.type === 'checkbox' ? target.checked : target.value;
        const validators = {...profilValidators,...lekarValidators};
		var errVal = props.lekar.errors;	//existujuca hodnota 'errors' v props
        var errValUser = props.userProfil.errors;
        setShowSelect(false);
        setShowSelectPzs(false);
        if(validators.hasOwnProperty(target.name)){
            if(lekarValidators.hasOwnProperty(target.name)){
                errVal[target.name] = [];
                lekarValidators[target.name].filter(val => val.validateOn <= validateOnEnum.BLUR).forEach(validator => { 
                    if (validator.validate(targetValue) === false) errVal[target.name].push(validator.invalidMsg); 
                });
                props.dispatch({ type:  userConstants.PROFIL_SETPROPS, payload: { ...props, lekar:{...props.lekar, errors:{...props.lekar.errors,[target.name]:errVal[target.name]} } } });
            }else{
                errValUser[target.name] = [];
                profilValidators[target.name].filter(val => val.validateOn <= validateOnEnum.BLUR).forEach(validator => { 
                    if (validator.validate(targetValue,props.userProfil) === false) errValUser[target.name].push(validator.invalidMsg); 
                });
                props.dispatch({ type:  userConstants.PROFIL_SETPROPS, payload: { ...props, userProfil:{...props.userProfil, errors:{...props.userProfil.errors,[target.name]:errValUser[target.name]} } } });  
            }
        }
    }    

    //minimalny pocet zadanych ciselnich znakov
    const KOD_LEKARA_SEARCH_MINLENGTH = 5;
    const handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
        setShowSelect(false);
        setShowSelectPzs(false);

        if (name === 'kod_lekara') {
            //vycisti options cache ak je zadanych menej zmakov ako minimum
            if (!minLength(value)) {
                setOptions([]);
                setIndex(-1);
            } else {
                tvojLekar(value);
            }
        }

        if (name === 'kod_pzs') {
            //vycisti options cache ak je zadanych menej zmakov ako minimum
            if (!minLengthPzs(value) ) {
                setOptionsPzs([]);
                setIndexPzs(-1);
            } else {
                tvojPzs(value);
            }
        }

		var errLek = props.lekar.errors;	//existujuca hodnota 'errors' v props
		var errProf = props.userProfil.errors;	//existujuca hodnota 'errors' v props
        const validators = {...profilValidators,...lekarValidators};
        if(validators.hasOwnProperty(target.name)&&validators[target.name].find(v=>{return v.validateOn===validateOnEnum.CHANGE})){
            if(lekarValidators.hasOwnProperty(name)){
                errLek[target.name] = [];
                lekarValidators[target.name].filter(val => val.validateOn <= validateOnEnum.CHANGE).forEach(validator => { 
                    if (validator.validate(value) === false) errLek[target.name].push(validator.invalidMsg); 
                });
            }else{
                errProf[target.name] = [];
                profilValidators[target.name].filter(val => val.validateOn <= validateOnEnum.CHANGE).forEach(validator => { 
                    if (validator.validate(value,props.userProfil) === false) errProf[target.name].push(validator.invalidMsg); 
                });
            }
        }
        if (['password', 'confPassword', 'securityQuestion', 'securityAnswer'].includes(name))
        {
            props.dispatch({ type:  userConstants.PROFIL_SETPROPS, payload: { ...props, userProfil:{...props.userProfil,[name]: value,errors:{...props.userProfil.errors,...errProf} } } });
        }else{
		    props.dispatch({ type:  userConstants.PROFIL_SETPROPS, payload: { ...props, lekar:{...props.lekar,[name]: value,errors:{...props.lekar.errors,...errLek} } } });
        }
	}
     
    const formLogin = () => {
		props.dispatch({ type : 'SETACTIVESTEP', payload  : { activeStep : 0 } } );
		props.dispatch({ type : 'SETFORM', payload  : { activeForm : 'L' } } );
	}

    const handleRegister = async (event) => {
        console.log('REGISTER');
		event.preventDefault();
		var formErrors = {};
		var profilErrors = {};
        let message = 'OK';
        if(lekarValid(props.lekar,formErrors)===false){
            message = 'ERR';
        }
        // props.dispatch({ type:  userConstants.PROFIL_SETPROPS, payload: { ...props, lekar:{...props.lekar, errors:{...props.lekar.errors,...formErrors} } } });
        if(profilValid(props.userProfil,profilErrors)===false){
            message = 'ERR';
        }
        if(message === 'ERR') {
            props.dispatch({ type:  userConstants.PROFIL_SETPROPS, payload: { ...props, lekar:{...props.lekar, errors:{...props.lekar.errors,...formErrors} }, userProfil:{...props.userProfil, errors:{...props.userProfil.errors,...profilErrors} } } });  
            return;
        }
        try{
            message = await userService.register(props.lekar,props.userProfil);                
            props.dispatch(success(message));

        }catch(error){
            message = 'Chyba: ' + error.toString();                
            props.dispatch(failure(message));
        }
		function success(message) { return { type: userConstants.REGISTER_SUCCESS, payload: { ...props.userProfil,userErrors:profilErrors,lekarErrors:formErrors, msg: message }} }
    	function failure(error) { return { type: userConstants.REGISTER_FAILURE, payload: { ...props.userProfil, errors: {...props.userProfil.errors,common:error}  }} }    
    }
    
    const regValid = () => {
        for(const property in props.lekar.errors){
            if(touched[property]&&props.lekar.errors[property].length>0) return false;
        }
        for(const property in props.userProfil.errors){
            if(touched[property]&&props.userProfil.errors[property].length>0) return false;
        }
        return true;
    }

    const validateLekar = (tmpLekar) => {
        // validuj lekara
        let stepErrors = {...props.lekar.errors}
        stepValidators.validators.lekarValidator(tmpLekar, stepErrors, stepValidators.validators.validateOnEnum.FORM);
        //okrem
        stepErrors = {...stepErrors,email:props.lekar.errors.email,telefon:props.lekar.errors.telefon,suhlas_vop:props.lekar.errors.suhlas_vop};
        props.dispatch({ type: userConstants.SET_VALIDATION_ERRORS, payload: { lekar: stepErrors } });
    }

    const handleChange = (index) => {
        //console.log("handleChange index=" + index);
        setIndex(index);
        if (index > -1 && index < options.length) {
            let tmpLekar = {
                    ...props.lekar,
                    kod_pzs: options[index].kod_pzs,
                    kod_lekara: options[index].kod_lekara,
                    meno: options[index].meno,
                    pzs: options[index].pzs
            };
            props.dispatch({
                type: 'SETLEKAR',
                payload: {
                    lekar:tmpLekar,
                    validatedSteps:{_1:props.stepValid} 
                }
            });
            setShowSelect(false);
            if(tmpLekar.kod_pzs) {
                tvojPzs(tmpLekar.kod_pzs, true, tmpLekar);
            } else {
                validateLekar(tmpLekar);
            }
        }
    }

    const handleChangePzs = (index,tmpLekar={}) => {
        //console.log("handleChange index=" + index);
        setIndexPzs(index);
        if (index > -1 && index !== optionsPzs.length) {            
            tmpLekar = {
                    ...props.lekar,...tmpLekar,
                    kod_pzs: optionsPzs[index].pzs_kod,
                    pzs: optionsPzs[index].nazov_pzs,
                    ico: optionsPzs[index].ico,
                    adresa_sidlo_ulica_cislo: optionsPzs[index].ulica_cislo_ou,
                    adresa_sidlo_mesto: optionsPzs[index].obec_ou,
                    adresa_sidlo_psc: optionsPzs[index].psc_ou
            };
            props.dispatch({
                type: 'SETLEKAR',
                payload: {
                    lekar:tmpLekar,
                    validatedSteps:{_1:props.stepValid} 
                }
            });
            // setOptionsPzs([optionsPzs[index]]);
            setShowSelectPzs(false);
            validateLekar(tmpLekar);
        }
    }

    const tvojPzs = (val, s, tmpLekar={}) => {
        let kod = val;
        try {
            if (optionsPzs.length === 0 || val !== props.lekar.kod_pzs || s) {
                    try {
                        userService.getPzs(kod).then((data) => {
                            if (s) {
                                setIndexPzs(0);
                                setOptionsPzs(data);
                                optionsPzs.length = 0;//vymaze
                                optionsPzs.push(...data);
                                handleChangePzs(0,tmpLekar);
                            } else {
                                setIndexPzs(-1);
                                setOptionsPzs(data);
                                setShowSelectPzs(true);
                            }
                        }).catch(err=>{
                            setIndex(-1);
                            console.error(err.message)
                        });
                    } catch (e) {
                        console.error(e);
                    }
            } else {
                if (!s) {
                    setIndexPzs(-1);
                    setShowSelectPzs(true);
                }

            }
        } catch (err) {
            setIndexPzs(-1);
            setOptionsPzs(["Chyba."]);
            console.error(err);
        }
    }

    const tvojLekar = (val) => {
        let kod = val ;
        if (val.substring(0,1) === 'P') kod = val.substring(1) ;
        //console.log(kod);
        try {
            if(options.length===0||val.substring(1,5)!==props.lekar.kod_lekara.substring(1,5)){
                userService.lekar(kod).then((data) => {
                    if (JSON.stringify(data) !== JSON.stringify(options)) {
                        //console.log("Nove data");
                        setIndex(-1);
                        setOptions(data);
                        setShowSelect(true);
                    }
                }).catch(err=>{
                    setIndex(-1);
                    console.error(err.message)
                });
            }else{
                setIndex(-1);
                setShowSelect(true);
            }
        } catch(err) {
            setIndex(-1);
            setOptions(["Chyba."]);
            console.error(err);
        }
    }

    const minLength = (kod) => {
        return ((kod
            &&(kod.substring(0,1) === 'A' && kod.length >= KOD_LEKARA_SEARCH_MINLENGTH+1))
            ||(kod.substring(0,1).match(/d/) && kod.length >= KOD_LEKARA_SEARCH_MINLENGTH))
    }

    const minLengthPzs = (kod) => {
        return ((kod
            &&(kod.length >= KOD_LEKARA_SEARCH_MINLENGTH+1))
            ||(kod.substring(0,1).match(/d/) && kod.length >= KOD_LEKARA_SEARCH_MINLENGTH))
    }

    const handleFocus = () => {
        let kod = props.lekar.kod_lekara;
        if(kod && minLength(kod)
            && (options === null||options.length===0)) {
            tvojLekar(kod);
        }
        if(options&&options.length) setShowSelect(true);
    }

    const handleFocusPzs = () => {
        let kod = props.lekar.kod_pzs;
        if(kod && minLengthPzs(kod)
            && (optionsPzs === null||optionsPzs.length===0)) {
            tvojPzs(kod);
        }
        if(optionsPzs&&optionsPzs.length) setShowSelectPzs(true);
    }

    return ( 
        <div className="box">
            <form className="opac1">
            <h3>Registrácia lekára</h3>
                <div className="form-group d-flex justify-content-between">
                    {/* <div className="col-sm-6 col-xs-12">
                        <label htmlFor="kod_lekara"> 
                        <span className={"validation-icon " + (touched.kod_lekara&&props.lekar.errors.kod_lekara.length > 0 ? "is-invalid" : (touched.kod_lekara?"valid":""))}></span>
                            Kód lekára&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input " + (touched.kod_lekara&&props.lekar.errors.kod_lekara.length > 0 ? "is-invalid" : "")} name="kod_lekara"    type="textbox"    value={props.lekar.kod_lekara}      onChange={handleInputChange} onBlur={validate} />
							<div className="form-error">
								{touched.kod_lekara&&props.lekar.errors['kod_lekara'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
						</div>
                    </div> */}

                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor="kod_lekara">
                            <span className={"validation-icon " + (touched.kod_lekara&&props.lekar.errors.kod_lekara.length > 0 ? "is-invalid" : (touched.kod_lekara?"valid":""))}></span>
                            Kód lekára&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input " + (touched.kod_lekara&&props.lekar.errors.kod_lekara.length > 0 ? "is-invalid" : "")} name="kod_lekara"    type="textbox"    value={props.lekar.kod_lekara}
                                   onFocus={handleFocus}
                                   onChange={handleInputChange}
                                   onBlur={validate}
                                   //onClick={setShowSelect(!showSelect)}
                                   style={{autocomplete:"false"}} />
                            <div className="form-error">
                                {touched.kod_lekara&&!showSelect&&props.lekar.errors['kod_lekara'].map((value, i) =>
                                    <span key={i}>{value}</span>
                                )}
                            </div>
                        </div>
                        <div>
                            <SelectItem options={options} show={showSelect} handleChange={handleChange}/>
                        </div>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor="meno"> 
                        <span className={"validation-icon " + (touched.meno&&props.lekar.errors.meno.length > 0 ? "is-invalid" : (touched.meno?"valid":""))}></span>
                            Meno lekára&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input "+ (touched.meno&&props.lekar.errors.meno.length > 0 ? "is-invalid" : "")}  name="meno"          type="textbox"    value={props.lekar.meno}            onChange={handleInputChange} onBlur={validate}/>
                            <div className="form-error">
								{touched.meno&&props.lekar.errors['meno'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
                        </div>
                    </div>

                    {/* <div className="col-sm-6 col-xs-12">
                        <label htmlFor="kod_pzs"> 
                        <span className={"validation-icon " + (touched.kod_pzs&&props.lekar.errors.kod_pzs.length > 0 ? "is-invalid" : (touched.kod_pzs?"valid":""))}></span>
                            Kód poskytovateľa&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input " + (touched.kod_pzs&&props.lekar.errors.kod_pzs.length > 0 ? "is-invalid" : "")} name="kod_pzs"    type="textbox"    value={props.lekar.kod_pzs}      onChange={handleInputChange} onBlur={validate} />
							<div className="form-error">
								{touched.kod_pzs&&props.lekar.errors['kod_pzs'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
						</div>
                    </div> */}

                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor="kod_pzs">
                        <span className={"validation-icon " + (touched.kod_pzs&&props.lekar.errors.kod_pzs.length > 0 ? "is-invalid" : (touched.kod_pzs?"valid":""))}></span>
                            Kód poskytovateľa&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input " + (touched.kod_pzs&&props.lekar.errors.kod_pzs.length > 0 ? "is-invalid" : "")} name="kod_pzs"    type="textbox"    value={props.lekar.kod_pzs}      
                                   onFocus={handleFocusPzs}
                                   onChange={handleInputChange}
                                   onBlur={validate}
                                   style={{autocomplete:"false"}} />
							<div className="form-error">
								{touched.kod_pzs&&props.lekar.errors['kod_pzs'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
						</div>
                        <div>
                            <SelectPzs options={optionsPzs} show={showSelectPzs} handleChange={handleChangePzs}/>
                        </div>                        
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor="ico"> 
                        <span className={"validation-icon " + (touched.ico&&props.lekar.errors.ico.length > 0 ? "is-invalid" : (touched.ico?"valid":""))}></span>
                            IČO poskytovateľa&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input " + (touched.ico&&props.lekar.errors.ico.length > 0 ? "is-invalid" : "")} name="ico"    type="textbox"    value={props.lekar.ico}      onChange={handleInputChange} onBlur={validate} />
							<div className="form-error">
								{touched.ico&&props.lekar.errors['ico'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
                        </div>
                    </div>
                    
                    <div className="col-sm-12 col-xs-12">
                        <label htmlFor="pzs"> 
                        <span className={"validation-icon " + (touched.pzs&&props.lekar.errors.pzs.length > 0 ? "is-invalid" : (touched.pzs?"valid":""))}></span>
                            Názov poskytovateľa&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input " + (touched.pzs&&props.lekar.errors.pzs.length > 0 ? "is-invalid" : "")}  name="pzs"    type="textbox"    value={props.lekar.pzs}      onChange={handleInputChange} onBlur={validate} />
                            <div className="form-error">
								{touched.pzs&&props.lekar.errors['pzs'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
                        </div>
                    </div>



                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor="email"> 
                        <span className={"validation-icon " + (touched.email&&props.lekar.errors.email.length > 0 ? "is-invalid" : (touched.email?"valid":""))}></span>
                            email&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input " + (touched.email&&props.lekar.errors.email.length > 0 ? "is-invalid" : "")} name="email"    type="textbox"    value={props.lekar.email}      onChange={handleInputChange} onBlur={validate} />
							<div className="form-error">
								{touched.email&&props.lekar.errors['email'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
						</div>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor="telefon"> 
                        <span className={"validation-icon " + (touched.telefon&&props.lekar.errors.telefon.length > 0 ? "is-invalid" : (touched.telefon?"valid":"")) }></span>
                            Telefón&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input "+ (touched.telefon&&props.lekar.errors.telefon.length > 0 ? "is-invalid" : "")}  name="telefon"    type="textbox"    value={props.lekar.telefon}      onChange={handleInputChange} onBlur={validate} />
							<div className="form-error">
								{touched.telefon&&props.lekar.errors['telefon'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
						</div>
                    </div>

                    <div className="col-sm-12 col-xs-12">
                        <label htmlFor="adresa_sidlo_ulica_cislo"> 
                        <span className={"validation-icon " + (touched.adresa_sidlo_ulica_cislo&&props.lekar.errors.adresa_sidlo_ulica_cislo.length > 0 ? "is-invalid" : (touched.adresa_sidlo_ulica_cislo?"valid":""))}></span>
                            Ulica, číslo&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input "+ (touched.adresa_sidlo_ulica_cislo&&props.lekar.errors.adresa_sidlo_ulica_cislo.length > 0 ? "is-invalid" : "")}  name="adresa_sidlo_ulica_cislo"    type="textbox"    value={props.lekar.adresa_sidlo_ulica_cislo}      onChange={handleInputChange} onBlur={validate}/>
                            <div className="form-error">
								{touched.adresa_sidlo_ulica_cislo&&props.lekar.errors['adresa_sidlo_ulica_cislo'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
                        </div>
                    </div>
                    
                    {/*
                    <div className="col-sm-9 col-xs-12">
                        <label htmlFor="adresa_sidlo_ulica"> 
                        <span className={"validation-icon " + (touched.adresa_sidlo_ulica&&props.lekar.errors.adresa_sidlo_ulica.length > 0 ? "is-invalid" : (touched.adresa_sidlo_ulica?"valid":""))}></span>
                            Ulica&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input "+ (touched.adresa_sidlo_ulica&&props.lekar.errors.adresa_sidlo_ulica.length > 0 ? "is-invalid" : "")}  name="adresa_sidlo_ulica"    type="textbox"    value={props.lekar.adresa_sidlo_ulica}      onChange={handleInputChange} onBlur={validate}/>
                            <div className="form-error">
								{touched.adresa_sidlo_ulica&&props.lekar.errors['adresa_sidlo_ulica'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
                        </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                        <label htmlFor="adresa_sidlo_cislo"> 
                        <span className={"validation-icon " + (touched.adresa_sidlo_cislo&&props.lekar.errors.adresa_sidlo_cislo.length > 0 ? "is-invalid" : (touched.adresa_sidlo_cislo?"valid":""))}></span>
                            Číslo&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input "+ (touched.adresa_sidlo_cislo&&props.lekar.errors.adresa_sidlo_cislo.length > 0 ? "is-invalid" : "")}  name="adresa_sidlo_cislo"    type="textbox"    value={props.lekar.adresa_sidlo_cislo}      onChange={handleInputChange} onBlur={validate}/>
                            <div className="form-error">
								{touched.adresa_sidlo_cislo&&props.lekar.errors['adresa_sidlo_cislo'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
                        </div>
                    </div>
                    */}

                    <div className="col-sm-9 col-xs-12">
                        <label htmlFor="adresa_sidlo_mesto"> 
                        <span className={"validation-icon " + (touched.adresa_sidlo_mesto&&props.lekar.errors.adresa_sidlo_mesto.length > 0 ? "is-invalid" : (touched.adresa_sidlo_mesto?"valid":""))}></span>
                            Mesto&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input "+ (touched.adresa_sidlo_mesto&&props.lekar.errors.adresa_sidlo_mesto.length > 0 ? "is-invalid" : "")}  name="adresa_sidlo_mesto"    type="textbox"    value={props.lekar.adresa_sidlo_mesto}      onChange={handleInputChange} onBlur={validate}/>
                            <div className="form-error">
								{touched.adresa_sidlo_mesto&&props.lekar.errors['adresa_sidlo_mesto'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
                        </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                        <label htmlFor="adresa_sidlo_psc"> 
                        <span className={"validation-icon " + (touched.adresa_sidlo_psc&&props.lekar.errors.adresa_sidlo_psc.length > 0 ? "is-invalid" : (touched.adresa_sidlo_psc?"valid":""))}></span>                            PSČ&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input "+ (touched.adresa_sidlo_psc&&props.lekar.errors.adresa_sidlo_psc.length > 0 ? "is-invalid" : "")}  name="adresa_sidlo_psc"    type="textbox"    value={props.lekar.adresa_sidlo_psc}      onChange={handleInputChange} onBlur={validate}/>
                            <div className="form-error">
								{touched.adresa_sidlo_psc&&props.lekar.errors['adresa_sidlo_psc'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
							</div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor="password"> 
                            <span className={"validation-icon " + (touched.password&&props.userProfil.errors.password.length > 0 ? "is-invalid" : (touched.password?"valid":""))}></span>
                            Heslo&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input "+ (touched.password&&props.userProfil.errors.password.length > 0 ? "is-invalid" : "")}  id="password" name="password"  
                            type={showPwd===false?"password":"text"}  value={props.userProfil.password} onChange={handleInputChange} onBlur={validate}/>
                            <span id="eye-pwd" className={"icon input-action" + (showPwd===false?" medirex-eye":" medirex-eye-slash")} onClick={toggleShowPwd}></span>

                        </div>
                        <div className="form-error">
                            {touched.password&&props.userProfil.errors['password'].map((value, i) =>
                                <span key={i}>{value}</span>
                            )}
                        </div>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor="confPassword"> 
                            <span className={"validation-icon " + (touched.confPassword&&props.userProfil.errors.confPassword.length > 0 ? "is-invalid" : (touched.confPassword?"valid":""))}></span>
                            Potvrdenie hesla&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className={"input "+ (touched.confPassword&&props.userProfil.errors.confPassword.length > 0 ? "is-invalid" : "")}  name="confPassword"  
                            type={showPwdC===false?"password":"text"}  value={props.userProfil.confPassword} onChange={handleInputChange} onBlur={validate}/>
                            <span id="eye-cpwd" className={"icon input-action" + (showPwdC===false?" medirex-eye":" medirex-eye-slash")} onClick={toggleShowPwd}></span>
                        </div>
                        <div className="form-error">
                            {touched.confPassword&&props.userProfil.errors['confPassword'].map((value, i) =>
                                <span key={i}>{value}</span>
                            )}
                        </div>
                    </div>

                    <div className="col-sm-12 col-xs-12">
                        <label className="inline-checkbox"> 
                            <input  name="adresa_vysledky_ina"    type="checkbox"    checked={props.lekar.adresa_vysledky_ina}      onChange={handleInputChange} />
                            <span className="checkmark"></span>
                            <span className="text">Iná adresa pre doručenie výsledkov</span>
                        </label>                        
                    </div>

                    <div className={`col-sm-12 col-xs-12${props.lekar.adresa_vysledky_ina ? '' : ' skry'}`}>
                        <label htmlFor="adresa_vysledky_ulica_cislo"> 
                            <span className="validation-icon"></span>
                            Ulica, číslo&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_ulica_cislo"    type="textbox"    value={props.lekar.adresa_vysledky_ulica_cislo}      onChange={handleInputChange} />
                        </div>
                    </div>
                    {/*
                    <div className="col-sm-8 col-xs-12" styl={ props.lekar.adresa_vysledky_ina ? {} :{"display":"none"}}>
                        <label htmlFor="adresa_vysledky_ulica"> 
                            <span className="validation-icon"></span>
                            Ulica&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_ulica"    type="textbox"    value={props.lekar.adresa_vysledky_ulica}      onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className="col-sm-3 col-xs-12" styl={ props.lekar.adresa_vysledky_ina ? {} :{"display":"none"}}>
                        <label htmlFor="adresa_vysledky_cislo"> 
                            <span className="validation-icon"></span>
                            Číslo&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_cislo"    type="textbox"    value={props.lekar.adresa_vysledky_cislo}      onChange={handleInputChange} />
                        </div>
                    </div>
                    */}
                    <div className={`col-sm-8 col-xs-12${props.lekar.adresa_vysledky_ina ? '' : ' skry'}`}>
                        <label htmlFor="adresa_vysledky_mesto"> 
                            <span className="validation-icon"></span>
                            Mesto&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_mesto"    type="textbox"    value={props.lekar.adresa_vysledky_mesto}      onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className={`col-sm-3 col-xs-12${props.lekar.adresa_vysledky_ina ? '' : ' skry'}`}>
                        <label htmlFor="adresa_vysledky_psc"> 
                            <span className="validation-icon"></span>
                            PSČ&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_psc"    type="textbox"    value={props.lekar.adresa_vysledky_psc}      onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className="col-sm-12 col-xs-12">
                        <label className="inline-checkbox"> 
                            <input  name="suhlas_vop"    type="checkbox"    checked={props.lekar.suhlas_vop}      onChange={handleInputChange} onBlur={validate}/>
                            <span className="checkmark"></span>
                            <span className="text">Súhlasím so 
                            <a href="https://static.medirex.sk/storage/app/media/Kontent%20pre%20lekarsku%20cast/Pre%20lekarov/Legislativa/V%C5%A1eobecn%C3%A9-Obchodn%C3%A9-Podmienky-Lek%C3%A1ri.pdf" 
                            target="_blank" rel="noreferrer"> všeobecnými obchodnými podmienkami</a> 
                            &nbsp;*</span>
                        </label>
                        <div className="form-error">
								{touched.suhlas_vop&&props.lekar.errors['suhlas_vop'].map((value, i) =>
									<span key={i}>{value}</span>
								)}
                        </div>                       
                    </div>
                    <div className="form-error align-left col-sm-12">{regValid()===true?"":"Niektoré polia nie sú vyplnené alebo sú vyplnené chybným spôsobom. Prosím, skontrolujte si ich."}</div> 
					<div className="row row-dummy col-sm-12"></div>
					<div className="col-sm-12 button-box">
						<button
							className="button--dark"
							color="primary"
                            // type="submit"
							// onClick={getJwt}
							onClick={handleRegister}
						>
							Registrovať
					    </button>
                    </div>
					<div className="row row-dummy col-sm-12"></div>
					<div className="col-sm-12 button-box">
                        <button
							className="button--border"
							// onClick={getJwt}
							onClick={formLogin}
						>
							Späť
					    </button>
                    </div>
                    <div className="col-md-12">
                        <label className="inline-checkbox hide-user-acceptance" > 
                        <span className="text allign-left">Informácie o
                        <a href="https://static.medirex.sk/storage/app/media/Kontent%20pre%20lekarsku%20cast/Pre%20lekarov/Legislativa/Ochrana-Osobnych-Údajov.pdf" target="_blank" 
                        rel="noreferrer"> spracovaní a ochrane osobných údajov (GDPR)</a> *
                        </span> </label>
                    </div>
                    <div className="space-30"></div>
                    <div className="col-12">
                        <div className="align-left"> <font size="3" >{props.userProfil.msg.length>0 ? props.userProfil.msg:''} </font></div>
                        <div className="form-error align-left col-sm-12"> <span>{props.userProfil.errors['common'] ? props.userProfil.errors['common'] : ''}</span> </div>

					</div> 
                    {props.regIn===true?(
                    <label className="registration-confirm">
                         <h3 className="sub-hedline">
                        {props.regIn===true? "Registrácia prebehla úspešne! Na adresu " + props.lekar.email +" bol odoslaný aktivačný e-mail, ktorého súčasťou je aktivačný link platný 24 hodín. Potvrdením linky do 24 hodín bude Vaše konto aktivované!":""}
                    </h3></label>
                    ):''}
                </div>
            </form>
        </div>
    )
}

export default connect(mapStateToProps)(Register);
