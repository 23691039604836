import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import formatter from "../utils/formatter"
import moment from "moment";
import "moment/locale/sk";
import validateOnEnum from "../validators/validateOnEnum";
import {validators} from "../validators/review";
import {poistovne} from "../validators/pacient";
import "./form-styles/input.css"

const mapStateToProps =  ( state ) => {
    return ( {lekar : state.lekar, pacient : state.pacient, ziadanka : state.data_ziadanky, dt_odberu : state.dt_odberu, review : state.review, step4_state: state.step4_state, stepValid:state.validatedSteps._5} )
};

function Review(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

	const touched = {
		suhlas_A:!(props.review.errors.suhlas_A===null),
		suhlas_B:!(props.review.errors.suhlas_B===null),
		allerrs :!(props.review.errors.allerrs===null)
	};

	const valid = ()=>{
		for(const property in touched){
			if(touched[property]===true&&props.review.errors[property]&&props.review.errors[property].length>0) return false;
		}
		return true;
	}

	const validate = (event) => {
		//zmenil nieco
		var stepValid = props.stepValid;
		const target = event.target;
		const tval = target.type === 'checkbox' ? target.checked : target.value;
		var errVal = props.review.errors;	//existujuca hodnota 'errors' v props
		if(validators.hasOwnProperty(target.name)){
			errVal[target.name] = [];
			validators[target.name].filter(val => val.validateOn <= validateOnEnum.BLUR).forEach(validator => {
				if (validator.validate(tval) === false) errVal[target.name].push(validator.invalidMsg);
			});
			if(errVal[target.name].length>0)    stepValid=false;
			props.dispatch({ type : 'SET_REVIEW', payload : { ...props,validatedSteps:{_5:stepValid} , review:{...props.review,errors:{...props.review.errors,[target.name]:errVal[target.name]} }}} );
		}

	}
	const handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		var stepValid = props.stepValid;
		var errVal = props.review.errors;

		if(validators.hasOwnProperty(target.name)
			&& validators[target.name].find(v=>{return v.validateOn===validateOnEnum.CHANGE})){
			errVal[target.name] = [];
			validators[target.name].filter(val => val.validateOn <= validateOnEnum.CHANGE).forEach(validator => {
				if (validator.validate(value) === false) errVal[target.name].push(validator.invalidMsg);
			});
			if(errVal[target.name].length>0) stepValid=false;

			props.dispatch({ type : 'SET_REVIEW', payload : { ...props,validatedSteps:{_5:stepValid} , review:{...props.review,[name]: value,errors:{...props.review.errors,[target.name]:errVal[target.name]} }}} );
		} else {
			props.dispatch({ type : 'SET_REVIEW', payload : { ...props, review:{...props.review,[name]: value } } } )
		}
	}


	// const handleInputChange = (event) => {
	// 	const target = event.target;
	// 	const value = target.type === 'checkbox' ? target.checked : target.value;
	// 	const name = target.name;
	//
	// 	let valErrors = validate({name:name,value:value})
	//
	// 	props.dispatch({ type:  'SET_REVIEW', payload: { ...props, review:{...props.review,[name]: value, errors:valErrors } } });
	//
	// }

	const dt_odberu = formatter.formatDT_SK(moment(props.dt_odberu.dt))

	return (
		<div className="box">
			<form className="opac1">
				<h3>Zhrnutie objednávky vyšetrenia</h3>
				<div className="form-group">

					<div className="row">
						<div className="col-12">
							<label htmlFor="lekar">
								<span className="validation-icon is-valid"></span>
								<b>Údaje&nbsp;lekára</b><br/>
							</label>
						</div>
					</div>

					<div className="row">
						<div className='col-sm-6 col-xs-12'>
							<label htmlFor="kod_lekara">
								<span className="validation-icon is-valid"></span>
								Kód lekára&nbsp;*
							</label>
							<div className ="input-box">
								<input readOnly className="input"  name="props.lekar.kod_lekara"    type="textbox" value={props.lekar.kod_lekara} />
							</div>
						</div>

						<div className='col-sm-6 col-xs-12'>
							<label htmlFor="meno">
								<span className="validation-icon is-valid"></span>
								Meno lekára&nbsp;*
							</label>
							<div className ="input-box">
								<input readOnly className="input"  name="props.lekar.meno"    type="textbox" value={props.lekar.meno} />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<label htmlFor="pacient">
								<span className="validation-icon is-valid"></span>
								<b>Údaje pacienta</b><br/>
							</label>
						</div>
					</div>

					<div className="row">
						<div className="col-sm-6 col-xs-12">
							<label htmlFor="priezvisko">
								<span className="validation-icon is-valid"></span>
								Priezvisko&nbsp;*
							</label>
							<div className ="input-box">
								<input readOnly className="input"  name="props.pacient.priezvisko"    type="textbox" value={props.pacient.priezvisko} />
							</div>
						</div>

						<div className="col-sm-6 col-xs-12">
							<label htmlFor="meno">
								<span className="validation-icon is-valid"></span>
								Meno&nbsp;*
							</label>
							<div className ="input-box">
								<input readOnly className="input"  name="props.pacient.meno"    type="textbox" value={props.pacient.meno} />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-sm-6 col-xs-12">
							<label htmlFor="rc">
								<span className="validation-icon is-valid"></span>
								Rodné číslo&nbsp;*
							</label>
							<div className ="input-box">
								<input readOnly className="input"  name="props.pacient.rc" type="textbox" value={formatter.formatRC(props.pacient.rc)} />
							</div>
						</div>
						<div className="col-sm-6 col-xs-12">
							<label htmlFor="poisKod">
									<span className="validation-icon"></span>
									Zdravotná poisťovňa&nbsp;*
							</label>
							<div className="input-box">
									<input readOnly className="input" name="props.pacient.poisKod" type="textbox" value={props.pacient.poisKod&&(poistovne.find(x => x.value === props.pacient.poisKod))['label']}  />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<label htmlFor="Vysetrenia">
								<span className="validation-icon is-valid"></span>
								<b>Vyšetrenia</b><br/>
							</label>
						</div>
						<div className="col-sm-12 col-xs-12">
							<ul>
								{props.ziadanka&&(props.ziadanka['poziadavky'] instanceof Array)&&props.ziadanka.poziadavky.map( (x,index) =>
									<div key={index.toString()}>
										<li >{x.nclpKod + " - "+ x.nazov}</li>
									</div>
								)}
							</ul>
						</div>
					</div>

					<div className="row">
						{props.ziadanka&&(props.ziadanka['vzorky'] instanceof Array)&&props.ziadanka.vzorky.length>0&&
							<div className="col-12">
								<label htmlFor="Material">
									<span className="validation-icon is-valid"></span>
									<b>Materiál</b><br/>
								</label>
							</div>
						}
					</div>

					<div className="row">
						<div className="col-sm-12 col-xs-12">
							<ul className="pl-0">
								{props.ziadanka&&(props.ziadanka['vzorky'] instanceof Array)&&props.ziadanka.vzorky.length>0&&props.ziadanka.vzorky.map( (x,index) =>
									<li key={ index+ " - vzorka"}  className="row">
										<div hidden={true} className="col-sm-3 col-xs-6">
											<input hidden={true} readOnly className="input"  name={index+"-nclp" } type="textbox" value={x.material.nclpKod}/>
										</div>
										<div className="col-sm-3 col-xs-6">
											<input readOnly className="input"  name={index+"-nazov"} type="textbox" value={x.material.nazov}/>
										</div>
										{props.step4_state.bez_oznacenia &&
										<div className="col-sm-3 col-xs-6">
											<input readOnly className="input"  name={index+"-id"} type="textbox" value={x.id} />
										</div>}
										{!props.step4_state.bez_oznacenia &&
										<div className="col-sm-2 col-xs-6">
											<label className="inline-checkbox">
												<input readOnly name={index + "-check"} type="checkbox" checked={x.id&&x.id!==null&&x.id.length>0}/>
												<span className="checkmark"></span>
												<span className="text"></span>
											</label>
										</div>}
									</li>
								)}
							</ul>
						</div>
					</div>

					<div className="row">
						<div className="col-sm-12 col-xs-12">
							<label htmlFor="dt_odberu">
								<span className="validation-icon is-valid"></span>
								Dátum a čas odberu vzoriek:{dt_odberu}
							</label>
						</div>

						{props.ziadanka&&(props.ziadanka['udaje'] instanceof Array)&&props.ziadanka.udaje.length>0&&
						<div className="col-12">
							<label htmlFor="Klinicke udaje">
								<span className="validation-icon is-valid"></span>
								<b>Klinické údaje</b><br/>
							</label>
						</div>}
					</div>

					<div className="row">
						<div className="col-sm-12 col-xs-12">
							<ul className="pl-0">
								{props.ziadanka&&(props.ziadanka['udaje'] instanceof Array)&&props.ziadanka.udaje.length>0&&props.ziadanka.udaje.map( (x,index) =>
									<li key={ index+ " - vzorka"}  className="row">
										<div hidden={true} className="col-sm-3 col-xs-6">
											<input hidden={true} readOnly className="input"  name={index+"-nclp" } type="textbox" value={x.nclpKod}/>
										</div>
										<div className="col-sm-3 col-xs-6">
											<input readOnly className="input"  name={index+"-nazov"} type="textbox" value={x.nazov}/>
										</div>
										<div className="col-sm-3 col-xs-6">
											<input readOnly className="input"  name={index+"-id"} type="textbox" value={x.cislo}/>
										</div>
										<div className="col-sm-2 col-xs-4">
											<input readOnly className="input" name={index + "-nazov"} type="textbox" value={x.mj} />
										</div>
									</li>
								)}
							</ul>
						</div>
					</div>

					<div className="row">
						{props.ziadanka&&props.ziadanka.textLekara&&props.ziadanka.textLekara.length > 0 &&
							<div className="col-sm-12 col-xs-12">
								<label htmlFor="note">
									<span className="validation-icon is-valid"></span>
									<b>Poznámka lekára</b>
								</label>
								<p className="wspl">{props.ziadanka&&props.ziadanka.textLekara}</p>
							</div>
						}
						<div className="col-12">
							<label htmlFor="consent mb-0">
								<span className={"validation-icon " + ((touched.suhlas_A&&props.review.errors.suhlas_A.length > 0) || (touched.suhlas_B&&props.review.errors.suhlas_B.length > 0) ? "is-invalid": ((touched.suhlas_A&&touched.suhlas_B) ? "valid":""))}></span>
								<b>Súhlas&nbsp;*</b>
							</label>
						</div>
						<div className="col-sm-12 col-xs-12">
							<label className="inline-checkbox mt-2">
								<input  name="suhlas_A" id="suhlas_A"  type="checkbox"    checked={props.review.suhlas_A} onChange={handleInputChange} onBlur={validate} />
								<span className="checkmark"></span>
								<span className="text">Prehlasujem, že všetky údaje uvedené na žiadanke správne a že ja ako poskytovateľ zdravotnej starostlivosti mám uzatvorenú zmluvu so zdravotnou poisťovňou pacienta, ktorému budú vyšetrenia v laboratóriách Medirex realizované.</span>
							</label>
							<div className="form-error">
								{props.review.errors&&props.review.errors['suhlas_A']?.length>0&&props.review.errors['suhlas_A'].map((e,i) =>
									<span key={i}>{e}</span>)}
							</div>
						</div>
						{ !props.lekar.kzOrigin &&
						<div className="col-sm-12 col-xs-12">
							<label className="inline-checkbox mt-2">
								<input  name="suhlas_B" id="suhlas_B" type="checkbox" checked={props.review.suhlas_B} onChange={handleInputChange} onBlur={validate} />
								<span className="checkmark"></span>
								<span className="text">Beriem na vedomie, že odobraté vzorky s priloženou papierovou žiadankou musím doručiť do niektorého z laboratorií Medirex a.s. na vlastné náklady.</span>
							</label>
							<div className="form-error">
							{props.review.errors&&props.review.errors['suhlas_B']?.length>0&&props.review.errors['suhlas_B'].map((e,i) =>
								<span key={i}>{e}</span>)}
							</div>
						</div>}
						<div className="form-error align-left col-sm-12">
							<span>{ (!props.stepValid&&valid()===false ? "Niektoré polia nie sú vyplnené. Prosím, skontrolujte si ich." : "") }</span>
							<div className="form-error">
							{props.review.errors&&props.review.errors['allerrs']?.length>0&&props.review.errors['allerrs'].map((e,i) =>
								<span key={i}>{e}</span>)}
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

export default connect(mapStateToProps)(Review);
