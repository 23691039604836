import React from 'react';
import {connect} from 'react-redux';
import userService from '../services/serveApi'
import { useLocation,useHistory } from 'react-router-dom';
import userConstants from '../actionConstants'

const mapStateToProps = (state) => {
	return ({verification:state.verification})
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
  }


  
const Verify= (props) => {
	const history = useHistory();

	const login = () => {
        history.push("/");
    }

	async function verifyEmail(token){
		try{
			const retData = await userService.verifyEmail(token);
			console.log (retData);
			props.dispatch({type:userConstants.VERIFICATION,payload:{verification:{...props.verification,activationProces:'end',email:retData.email}}});
		}catch(err){
			props.dispatch({type:userConstants.VERIFICATION,payload:{verification:{...props.verification,activationProces:'end',message:err}}});
			console.log (err)
		}
	}
	const query = useQuery();
	const token = query.get('id');
	if(token&&props.verification.activationProces==='idle'){
		props.dispatch({type:userConstants.VERIFICATION,payload:{verification:{...props.verification,activationProces:'start',token:token,email:''}}});
		// query.delete('id');
		history.replace({search:""})
	} 
	if(props.verification.token&&props.verification.activationProces==='start'){
		props.verification.activationProces='run';
		props.dispatch({type:userConstants.VERIFICATION,payload:{verification:{...props.verification,activationProces:'run',email:''}}});
		verifyEmail(props.verification.token);
	}
	  
	return(		
		<div className="thank-you">
			<div className="inner">
				<h1 className="headline">Potvrdenie registrácie</h1>
				{props.verification.activationProces==='end'?(<h2>{props.verification.email ? 'Registrácia používateľa ' + props.verification.email + ' bola úspešná' : 'Nastala chyba: ' + props.verification.message}</h2>):''}
				<div className="box">
					{props.verification.activationProces==='end'&&props.verification.email?(<button className="button--dark" onClick={login} >Prihlásiť sa</button>):''}
				</div>
			</div>				
		</div>
		
	)
  }

  
  export default connect (mapStateToProps)(Verify);