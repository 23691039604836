import validateOn from './validateOnEnum'

export const poistovne = [
	{ value: '2400', label: 'DÔVERA zdravotná poisťovňa' },
	{ value: '2500', label: 'Všeobecná zdravotná poisťovňa' },
	{ value: '2700', label: 'UNION zdravotná poisťovňa, a.s.' },
	{ value: '7700', label: 'UNION - komerčný' }
]

export function checkData(rc) {
	if (rc.length < 9 || rc.length > 10) return false;
	var year = parseInt(rc.substring(0, 2));
	var day = parseInt(rc.substring(4, 6));
	var month = parseInt(rc.substring(2, 4));
	if (rc.length === 9 && year > 53)return false;
	// 4cif rok
	var rokDnes =  new Date().getFullYear().toString();
	rokDnes = rokDnes.substring(2,4);
	rokDnes = parseInt(rokDnes);
	if (rc.length === 9) year = 1900 + year;
	else if (rokDnes < year) {
		year = 1900 + year;
	} else year = 2000 + year;
	return {day, month, year};
}

export const validators = {
	rc: [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole RČ je prázdne" }, {
		validateOn: validateOn.CHANGE, validate: (rc,out={}) => {
			checkData(rc);
			var year = checkData(rc).year;
			var day = checkData(rc).day;
			var month = checkData(rc).month;
			// if (rc.length < 9 || rc.length > 10) return false;
			// var year = parseInt(rc.substring(0, 2));
			// var day = parseInt(rc.substring(4, 6));
			// var month = parseInt(rc.substring(2, 4));
			// if (rc.length === 9 && year > 53)return false;
			// // 4cif rok
			// var rokDnes =  new Date().getFullYear().toString();
			// rokDnes = rokDnes.substring(2,4);
			// rokDnes = parseInt(rokDnes);
			// if (rc.length === 9) year = 1900 + year;
			// else if (rokDnes < year) {
			// 	year = 1900 + year;
			// } else year = 2000 + year;

			// kontrola mesiaca
			out.pohlavie = 'm';
			if (month > 12) {
				if (month > 50 && month < 63) {
					month = month - 50;
					out.pohlavie = 'z';
				}
					// else if (month > 70 && month < 83 && year > 2003) month = month - 70;
				// else if (month > 20 && month < 33 && year > 2003) month = month - 20;
				else return false;
			}
			try{
				var date = new Date(year,month-1,day);
				if(date.getDate()!==day) return false;
				var numrc = parseInt(rc);
				if(year > 1953) {
					if(numrc%11!==0) return false;
				}
			}catch(e){return false}
			out.datNar = `${year}-` + `${month}`.padStart(2,'0') + "-" + `${day}`.padStart(2,'0');

			// out.datNar = `${day}/${month}/${year}`;
			return true
		}, invalidMsg: "Prosím zadajte korektné rodné číslo."
	}],
	datNar : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole dátum narodenia je prázdne" }],
	pohlavie : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole pohlavie je prázdne" }],
	priezvisko : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole priezvisko je prázdne" },
	{ validateOn: validateOn.CHANGE, validate: (value) => { return value.trim().length > 2 }, invalidMsg: "Pole priezvisko musí mať aspoň 3 znaky." }],
	meno : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole meno pacienta je prázdne" },
	{ validateOn: validateOn.CHANGE, validate: (value) => { return value.trim().length > 2 }, invalidMsg: "Pole meno pacienta musí mať aspoň 3 znaky." }],
	adresa : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole  adresa pacienta je prázdne" }],
	poisKod : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole názov poisťovne je prázdne" }],
}

export function isValid(state, stateErrors = {}, validationEvent = validateOn.CHANGE) {
	var valid = true;
	for (const property in state) {
		if (!validators.hasOwnProperty(property)) continue;
		stateErrors[property] = [];
		// eslint-disable-next-line no-loop-func
		validators[property].filter(val => val.validateOn <= validationEvent).forEach(validator => {
			if (validator.validate(state[property]) === false) {
				stateErrors[property].push(validator.invalidMsg);
				valid = false;
			}
		});
	}
	return valid;
}
