import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import "./form-styles/SelectItem.css"

const mapStateToProps = (state) => {
  return ({ diagnozy: state.diagnozy, stepValid: state.validatedSteps._3 })
};

function SelectItem(props) {

    const [index,setIndex] = useState (-1);
    const options = [...props.options];

    const handleSelect = (i) =>{
        console.log("selected index=" + i);
        setIndex(i);
        props.handleChange(i);
    }

  return (
            <div className="custom-dropdown" style={{display:props.show?"block":"none"}}>
                <ul className="custom-dropdown-menu" style={{display:props.show?"block":"none"}}>
                {options.map( (item, index) =>
                    <li key={index} className="custom-dropdown-item" onMouseDown={() => {handleSelect(index);}}>
                       <div>
                           <span>{item.kod_lekara} - {item.meno}</span>
                           <p>{item.kod_pzs} - {item.pzs}</p>
                       </div>
                    </li>
                )}
                </ul>
            </div>
    );
}
/*
)}
*/
export default connect(mapStateToProps)(SelectItem);


