import ActiveUser from '../form/ActiveUser';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return ({
        lekar: state.lekar
    })
};

function TheHeader(props) {
    return (
        <header id="header" className="header bgcTM">
            <div className="header--menu p0 w-100">
                <div className="header--menu--left">
                    {!props.lekar.kzOrigin && <div className="header--menu--left--back header--menu--spat">
                        <a href="https://www.medirex.sk/vysetrenia">
                            <i className="icon medirex-long-arrow-left"></i>
                            <span>&nbsp;Späť&nbsp;na&nbsp;web</span>
                        </a>
                    </div>}
                    <div className="header-cart--menu nav-main"></div>
                </div>

                <ActiveUser/>
            </div>
        </header>
    )
}

export default connect(mapStateToProps)(TheHeader);
