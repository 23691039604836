const formatter = {
    formatRC,
    formatDT_SK
}

export default formatter;

function formatRC(rc) {
    let retval = ''
    try{
        retval = rc.substr(0,6)+ '/' + rc.substr(6)
    } catch(e){
        retval = rc
    } finally {
        return retval
    }
}

function formatDT_SK(m) {
    let retval = ''
    try{
        retval = m.format("DD.MM.YYYY HH:mm")
    } catch(e){
        retval = m
    } finally {
        return retval
    }
}