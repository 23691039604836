import React, { useState, useEffect } from 'react';

const ScrollTopArrow = () => {

  const [showScroll, setShowScroll] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)
    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop)
    }
  })

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 20) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 20) {
      setShowScroll(false)
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {showScroll ? (
        <div className="back-to-top show moveUp">
          <i className="icon medirex-angle-up" onClick={scrollTop}></i>
        </div>
      ) : (
        <div className="back-to-top moveUp">
          <i className="icon medirex-angle-up"></i>
        </div>
      )}
    </div>
  );
}

export default ScrollTopArrow;