import validateOn from './validateOnEnum'
import validujMJ from "../utils/mjUtil";

export const validators = {
    vzorky: [{
        validateOn: validateOn.CHANGE, validate: (obj) => {
            let retval = obj instanceof Array;
            retval && obj.forEach(x => {
                // console.log("x= " + JSON.stringify(x))
                if (x.hasOwnProperty("id")) {
                    if (x.id === null || x.id.length === 0) {
                        retval = false;
                    }
                }
            })
            return retval;
        }, invalidMsg: "Vyplňte čiarové kódy vzoriek resp. potvrďte označenie vzorky"
    },
        {
            validateOn: validateOn.FORM, validate: (obj) => {
                let retval = obj instanceof Array;
                return !retval || (retval && (new Set(obj.map(item => item['id'])).size === obj.length));
            }
            , invalidMsg: "Čísla odberového materiálu musia byť jedinečné!"
        },
        {
            validateOn: validateOn.FORM, validate: (obj) => {
                let retval = obj instanceof Array;
                return retval && obj !== null && (obj.length > 0);
            }
            , invalidMsg: "Odberový materiál musí obsahovať aspoň jednu položku!"
        }],
    udaje: [{
        validateOn: validateOn.CHANGE, validate: (obj) => {
            let retval = obj instanceof Array;
            retval && obj.forEach(x => {
                if (x.hasOwnProperty("cislo")) {
                    if (x.cislo === null || x.cislo.length === 0) {
                        retval = false;
                    }
                }
            })
            return retval;
        }, invalidMsg: "Vyplňte polia s klinickými údajmi resp. potvrďte označenie"
    },{
      validateOn: validateOn.CHANGE, validate: (obj) => {
        let retval = obj instanceof Array;
        retval && obj.forEach(x => {
          if (x.hasOwnProperty("mj")) {
            if(x.cislo !== null&&x.cislo.length !== 0&&!validujMJ.validuj(x.mj,x.cislo))
              retval = false;
          }
        })
        return retval;
      }, invalidMsg: "Formát zadanej hodnoty musí súhlasiť s mernou jednotkou"
    }]
}
export function isValid(state, stateErrors = {}, validationEvent = validateOn.FORM){
    var valid = true;
    for (const property in state) {
        if(!validators.hasOwnProperty(property)) continue;
        stateErrors[property] = [];
        // eslint-disable-next-line no-loop-func
        validators[property].filter(val => val.validateOn <= validationEvent).forEach(validator => {
            if (validator.validate(state[property]) === false) {
                stateErrors[property].push(validator.invalidMsg);
                valid = false;
            }
        });
    }
    return valid;
}
