import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DiagZoznam from './DiagZoznam';
import DateTimeComp from './DateTimeComp';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './form-styles/Ziadanka.css'


const mapStateToProps = (state) => {
    return ({ isLoggedIn:state.loggedIn, lekar: state.lekar, stepValid: state.validatedSteps._3, ziadankaErrors: state.ziadankaErrors,dt_odberu:state.dt_odberu,diagnozy:state.diagnozy })
}



function Ziadanka(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
        window.load_aurelia()
        console.log('aurelia loaded')
    }, []);

    const hasErrors = () =>{
        return  (props.ziadankaErrors["poziadavky"] instanceof Array && props.ziadankaErrors["poziadavky"].length>0)||
                (props.dt_odberu.errors["dt"] instanceof Array && props.dt_odberu.errors["dt"].length>0)||
                (props.diagnozy.errors["diag"] instanceof Array && props.diagnozy.errors["diag"].length>0)
    }

    return (
            <div className="box">
            <h3>Diagnózy a zoznam vyšetrení</h3>
                <div className="row row-dummy"></div>
                <DateTimeComp />
                <div className="row row-dummy"></div>
                <DiagZoznam />
                <div className="row row-dummy"></div>
                <section aurelia-app="main" data-param={`{ "cache" : true, "show-footer" : false, "allow-toggle-view" : true, "allow-template" : ${props.isLoggedIn} }`}>
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-bg-medirex">
                                <div className="card-body">
                                    <span className="white-text">Nahrávam komponent žiadanky..</span>
                                    <div className="progress">
                                        <div className="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar" style={{ ariaValueMin: 0, ariaValueMax: 100, ariaValueNow: 75, width: 1200 }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="form-error">{props.stepValid === false && (props.ziadankaErrors.poziadavky instanceof Array) && props.ziadankaErrors.poziadavky.map((value, i) =>
                    <span key={i}>{value}</span>
                )}</div>
                <div className="row row-dummy"></div>
                <div className="form-error align-left">{props.stepValid === false&&hasErrors() ? "Niektoré polia nie sú vyplnené alebo sú vyplnené chybným spôsobom. Prosím, skontrolujte si ich." : ""}</div>
                <div className="row row-dummy"></div>
            </div>
    )

}

export default connect(mapStateToProps)(Ziadanka);
