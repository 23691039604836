import { connect } from 'react-redux';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import "bootstrap/dist/css/bootstrap.min.css"
import moment from 'moment';
import 'moment/locale/sk'; //format bude v SK netreba pouzite locale attribute
import React from "react";
import { validators } from '../validators/dtOdberu'
import validateOnEnum from '../validators/validateOnEnum'

const mapStateToProps = (state) => {
    return ({ dt_odberu: state.dt_odberu, stepValid: state.validatedSteps._3 })
};

function DateTimeComp(props) {
    const touched = {
        dt: !(props.dt_odberu.errors.dt === null)
    }

    //argument m je moment instance
    const handleChange = (m) => {
        let stepValid = props.stepValid;
        let errVal = null;
        if (validators['dt'].find(v => { return v.validateOn === validateOnEnum.CHANGE })) {
            errVal = []
            validators['dt'].filter(val => val.validateOn <= validateOnEnum.CHANGE).forEach(validator => {
                if (validator.validate(m) === false) errVal.push(validator.invalidMsg);
            });
            if (errVal.length > 0) stepValid = false;
        }
        props.dispatch({ type: 'SET_DT_ODBERU', payload: { ...props, validatedSteps: { _3: stepValid }, dt_odberu: { dt: moment.isMoment(m) ? m.format() : '', errors: { dt: errVal } } } });
        // try {
        //     m.format()
        // }
        // catch (e) {
        //     valErrors.push('Vyplňte dátum a čas odberu vo formáte DD.MM.YYYY HH:mm')
        //     m = moment()
        // }

        // if (!m) {
        //     valErrors.push('Vyplňte dátum a čas odberu')
        // } else {
        //     let now = moment()
        //     if (m.isAfter(now))
        //         valErrors.push('Dátum odberu nesmie byť v budúcnosti')
        // }
        // props.dispatch({ type: 'SET_DT_ODBERU', payload: { ...props, dt_odberu: { dt: m?m.format():'', errors: {dt:valErrors} } } });
    }

    const inputProps = {
        placeholder: "DD.MM.YYYY HH:mm",
        disabled: false,
        className: "input " + (touched.dt && props.dt_odberu.errors.dt.length > 0 ? "is-invalid" : ""),
        onBlur: () => {
            let stepValid = props.stepValid;
            let errVal = null;
            let m = props.dt_odberu.dt ? (moment.isMoment(moment(props.dt_odberu.dt)) ? moment(props.dt_odberu.dt) : props.dt_odberu.dt):'';
            if (validators['dt'].find(v => { return v.validateOn <= validateOnEnum.BLUR })) {
                errVal = []
                validators['dt'].filter(val => val.validateOn <= validateOnEnum.BLUR).forEach(validator => {
                    if (validator.validate(m) === false) errVal.push(validator.invalidMsg);
                });
                if (errVal.length > 0) {
                    stepValid = false;
                    props.dispatch({ type: 'SET_DT_ODBERU', payload: { ...props, validatedSteps: { _3: stepValid }, dt_odberu: {dt:moment.isMoment(m) ? m.format() : '', errors: { dt: errVal } } } });
                }
            }

        }
        // ,        input: false
    };

    const renderInput = (params, openCalendar, closeCalendar) => {
        /*
        function clear(){
            props.onChange({target: {value: ''}});
        }*/
        // function today() {
        //     params.onChange({ target: { value: moment().format("DD.MM.YYYY HH:mm") } });
        // }
        return (
            <div className="form-group">
                <input  {...params} name="dt_odberu.dt" />
                {/*<div className="row-dummy" />
                <button className="btn btn-primary" onClick={today}>Aktuálny dátum a čas</button>*/}
            </div>
        );
    }

    const dateValid = (current) => {
        return !current.isAfter(moment());
    }

    // let now = moment()
    //ak je datum prazdny nastav aktualny
    // let val = props.dt_odberu.dt ? moment(props.dt_odberu.dt) : now
    //console.log('Dátum zo statusu je ' + val.format())
    return (
        <div className="form-group d-flex justify-content-between">
            <div className="col-sm-3 col-xs-6">
                <label htmlFor="dt_odberu">
                    <span className={"validation-icon " + (touched.dt && props.dt_odberu.errors.dt.length > 0 ? "is-invalid" : (touched.dt ? "valid" : ""))}></span>
            Dátum a čas odberu vzoriek&nbsp;*
            </label>
                <div>
                    <Datetime isValidDate={ dateValid } inputProps={inputProps} renderInput={renderInput} name="dt_odberu" initialValue={props.dt_odberu.dt ? moment(props.dt_odberu.dt) : ""} onChange={handleChange} />
                    {/* {props.dt_odberu.errors.map(e => <div className="error">{e}</div>)} */}
                    <div className="form-error">
                        {touched.dt && props.dt_odberu.errors['dt'].map((value, i) =>
                            <span key={i}>{value}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps)(DateTimeComp)
