import moment from 'moment';
import 'moment/locale/sk';

const ciselneMJ = ["ml","hod.","kg","cm","dni"];
const positiveInt = ["dni"]
const datumMJ = ["dd.mm.rr","dd.mm.rrrr","dd.mm.yy","dd.mm.yyyy"];
const selectMJ = ["ano", "nie", "vlavo", "vpravo", "vlavo/vpravo"];

const jePositiveInt = (value) => {
  try{
    let n = Math.floor(Number(value));
    return n !== Infinity && String(n) === value && n >= 0;
  } catch(e) {
    console.log("Exception jeCeleKladneCislo");
    return false;
  }
}

const jeCislo = (value) => {
  try{
    let temp=value.replace(",",".")*1;
    return !isNaN(temp);
  } catch(e) {
    console.log("Exception jeCislo");
    return false;
  }
}

const jeDatum = (value, format) => {
  try{
    let fmt = format.replaceAll("r","Y").replaceAll("d","D").replaceAll("y","Y").replaceAll("m","M");
    return moment(value,fmt,"sk",true).isValid();
  } catch(e) {
    console.log("Exception jeDatum");
    return false;
  }
}

const jeSelect = (value) => {
  if (value.length > 0){
    return true
  }
  console.log("Exception jeSelect")
  return false;
}

const validuj = (mj, value) => {
  // console.log(`mj:>${mj}< value:${value}`);
  return datumMJ.includes(mj) ?
      jeDatum(value,mj) : positiveInt.includes(mj)?
        jePositiveInt(value) : ciselneMJ.includes(mj) ?
          jeCislo(value) : selectMJ.includes(mj) ?
            jeSelect(value) : true;
}

const validujMJ = {
  validuj
}

//Test
// {
//    let a = validujMJ.validuj('ml','100.0 ');
//    console.log(a);
//    a = validujMJ.validuj('dd.mm.yyyy','10.02.2012');
//    console.log(a);
//  }

export default validujMJ;
