import axios from 'axios';
import { getApi, API } from '../constants/api';

export async function ziadankaList(user_id) {
    const axiosObject = getApi(API.ZIADANKA.LIST, { user_id });

    try {
        const { data } = await axios(axiosObject);

        return data;
    } catch(error) {
        throw new Error(error);
    }
}

export async function ziadankaDelete(id) {
    const axiosObject = getApi(API.ZIADANKA.DELETE, { id });

    try {
        await axios(axiosObject);

        return true;
    } catch(error) {
        throw new Error(error);
    }
}

export async function ziadankaSaveUpdate(id, data, isNameChecked) {
    const parsedData = JSON.parse(data);

    if (id) {
        const axiosObject = getApi(API.ZIADANKA.UPDATE, { id });
        axiosObject.data = parsedData;

        try {
            return await axios(axiosObject);
        } catch(error) {
            throw error;
        }
    }

    if (!isNameChecked) {
        const axiosObject = getApi(
            API.ZIADANKA.CHECK_NAME,
            {
                name: parsedData.name,
                user_id: parsedData.user_id,
            }
        );
        axiosObject.data = parsedData;

        try {
            await axios(axiosObject);

            return await saveZiadanka(parsedData);
        } catch(error) {
            throw error;
        }
    }

    return await saveZiadanka(data);
}

function saveZiadanka(data) {
    const axiosObject = API.ZIADANKA.SAVE;
    axiosObject.data = data;

    return axios(axiosObject)
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            throw error;
        })
}

export function checkZiadankaExists(code) {
    const axiosObject = getApi(API.ZIADANKA.CHECK_ZIADANKA_EXISTS, { code });

    return axios(axiosObject)
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            throw error;
        })
}
