import React, { useEffect, useState } from 'react';
import TheHeader from '../components/TheHeader';
import Footer from '../form/Footer';
import TableComponent from '../components/TableComponent';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import actionConstants from '../actionConstants';
import Modal from 'react-modal';
import { ziadankaDelete, ziadankaList } from '../services/ziadanka';
import moment from 'moment'

const headerData = [
    {
        label: 'Vytvorené',
        code: 'createdAt',
    },
    {
        label: 'P/A kod',
        code: 'p_a_code',
    },
    {
        label: 'Meno žiadanky',
        code: 'name',
    },
    {
        label: 'Rodné číslo',
        code: 'patient_birth_code',
    },
    {
        code: 'actions',
    },
];

const mapStateToProps = (state) => {
    return ({
        lekar: state.lekar,
        loggedIn: state.loggedIn,
        state,
    })
};

function transformToTableData(data, headerData) {
    return data.map((item) => ({
        id: item._id,
        cols: headerData.map(headerItem => {
            if (headerItem.code === 'createdAt') {
                return {
                    label: new Intl.DateTimeFormat('sk-SK', {
                        dateStyle: 'short',
                        timeStyle: 'short',
                    })
                        .format(new Date(item[headerItem.code])),
                    code: headerItem.code,
                }
            }

            if (headerItem.code === 'p_a_code') {
                return {
                    label: `${item.p_code}/${item.a_code}`,
                    code: headerItem.code,
                }
            }

            return {
                label: item[headerItem.code],
                code: headerItem.code,
            }
        }),
    }));
}

function List(props) {
    const [tableData, setTableData] = useState();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState();
    const [selectedZiadanka, setSelectedZiadanka] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        pa_code: '',
        name: '',
        birth_code: '',
    });
    const history = useHistory();

    useEffect(() => {
        if (!props.loggedIn) {
            history.push('/');
        }

        listZiadanka().then((data) => {
            window.scrollTo({ top: 0 });

            const localFormData = {
                ...formData,
                birth_code: getQueryParamBirthCode(),
            };

            setFormData(localFormData);

            filterZiadanky(null, localFormData, data)
        });
    }, []);

    function getQueryParamBirthCode() {
        const params = new URLSearchParams(window.location.search);

        if (!params.has('birth_code')) {
            return '';
        }

        return params.get('birth_code');
    }

    function listZiadanka() {
        setIsLoading(true);
        setErrors(null);

        return ziadankaList(props.lekar.kz_id)
            .then((list) => {
                setData(list.data);
                setTableData(transformToTableData(list.data, headerData));
                setIsLoading(false);

                return list.data;
            })
            .catch((error) => {
                setErrors(error.message);
                setIsLoading(false);
            })
    }

    function editZiadanka(event, id) {
        event.preventDefault();

        const ziadanka = getZiadankaData(id);
        const content = JSON.parse(ziadanka.content);
        content._id = id;
        content.ziadanka_name = ziadanka.name;
        content.dt_odberu.dt = moment().format();

        props.dispatch({
            type: actionConstants.SET_STATE,
            payload: content,
        });
        history.push('/');
    }

    function deleteZiadanka(event, ziadanka) {
        event.preventDefault();

        setErrors(null);
        setIsLoading(true);
        // Call Delete API
        ziadankaDelete(ziadanka._id)
            .then((state) => {
                if (state) {
                    listZiadanka(props.lekar.kz_id)
                        .then(() => {
                            closeModal();
                        });

                    setIsLoading(false);
                }

            })
            .catch((error) => {
                setErrors(error.message);

                setIsLoading(false);
            })
    }

    function setZiadanka(event, id) {
        event.preventDefault();
        const item = getZiadankaData(id);

        setSelectedZiadanka(item);
        setIsModalOpen(true)
    }

    function getZiadankaData(id) {
        return data.find((item) => item._id === id);
    }

    function closeModal(event) {
        event?.preventDefault();

        setIsModalOpen(false)
    }

    function filterZiadanky(event, inputData, serverData) {
        if (event) {
            event.preventDefault();
        }

        if (!serverData) {
            return;
        }

        const localData = serverData.filter((item) => {
            const pa_code = `${item.p_code}/${item.a_code}`

            return pa_code.includes(inputData.pa_code)
                && String(item.patient_birth_code).includes(inputData.birth_code)
                && item.name.toLowerCase()
                    .normalize('NFD')
                    .replace(/\p{Diacritic}/gu, '')
                    .includes(
                        inputData.name.toLowerCase()
                            .normalize('NFD')
                            .replace(/\p{Diacritic}/gu, '')
                            .trim(),
                    )
        })

        setTableData(transformToTableData(localData, headerData));
    }

    function resetFilterZiadanky(event) {
        event.preventDefault();

        const emptyFormData = {
            pa_code: '',
            birth_code: '',
            name: '',
        }

        setFormData(emptyFormData)

        filterZiadanky(event, emptyFormData, data);
    }

    return (
        <div>
            <TheHeader/>

            <div className="list-view checkout px-4">
                <div className="checkout--box">
                    <div className="checkout--box-inner">
                        <h3 className="mb-4">Zoznam žiadaniek</h3>
                        <div className="list-view-filter mb-4">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputPAcode">P/A kód</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputPAcode"
                                        placeholder="xxxxxxx/yyyyy"
                                        value={formData.pa_code}
                                        onInput={(event) => setFormData({
                                            ...formData,
                                            pa_code: event.target.value,
                                        })}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputNameZiadanky">Meno žiadanky</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputNameZiadanky"
                                        placeholder="Meno Priezvisko"
                                        value={formData.name}
                                        onInput={(event) => setFormData({
                                            ...formData,
                                            name: event.target.value,
                                        })}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputBirthCode">Rodné číslo</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputBirthCode"
                                        placeholder="111111111"
                                        value={formData.birth_code}
                                        onInput={(event) => setFormData({
                                            ...formData,
                                            birth_code: event.target.value,
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="d-flex">
                                <button
                                    type="button"
                                    className="button--white mt-4 ml-auto"
                                    onClick={resetFilterZiadanky}
                                >
                                    Zrušiť filter
                                </button>
                                <button
                                    type="button"
                                    className="button--dark mt-4 ml-2"
                                    onClick={(event) => filterZiadanky(event, formData, data)}
                                >
                                    Hľadaj
                                </button>
                            </div>
                        </div>
                        {!isLoading
                            && data
                            &&
                            <div className="table-responsive">
                                <TableComponent
                                    headerData={headerData}
                                    data={tableData}
                                    actions={(row) => <div className="d-flex flex-end flex">
                                        <button
                                            type="button"
                                            onClick={(event) => editZiadanka(event, row.id)}
                                            className="btn button--dark text-height-0 mr-2"
                                            title="Editovať rozpracovanú žiadanku"
                                        >
                                            <i className="icon medirex-pencil"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn button--white text-height-0"
                                            onClick={(event) => setZiadanka(event, row.id)}
                                            title="Zmazať rozpracovanú žiadanku"
                                        >
                                            <i className="icon ico-bin"></i>
                                        </button>
                                    </div>}
                                    isLoading={isLoading}
                                />

                                {errors &&
                                    <div className="form-error">
                                        <center>
                                            <h4>{errors}</h4>
                                        </center>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Modal
                className="modal__dialog"
                isOpen={isModalOpen}
                ariaHideApp={false}
                overlayClassName="modal"
                closeTimeoutMS={200}
            >
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <div>
                    <h6 className="modal-title">
                        Naozaj chcete vymazať žiadanku <strong>"{selectedZiadanka?.name}"</strong>?
                    </h6>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                    <button
                        type="button"
                        className="button--white mr-2"
                        data-dismiss="modal"
                        onClick={closeModal}
                    >
                        Zatvoriť
                    </button>
                    <button
                        type="button"
                        disabled={isLoading}
                        className="button--dark relative"
                        onClick={(event) => deleteZiadanka(event, selectedZiadanka)}
                    >
                        <div className="button-spinner">
                            <span
                                className={`${!isLoading ? 'd-none' : ''} spinner-border spinner-border-sm`}
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </div>
                        <span className={isLoading ? 'opac0' : ''}>Vymazať</span>
                    </button>
                </div>
            </Modal>

            <Footer/>
        </div>
    )
}

export default connect(mapStateToProps)(List)
