import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const mapStateToProps = (state) => {
    return { state };
};

function Podakovanie(props) {

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const setForm = () => {
        props.dispatch({ type: 'SETZIADANKA', payload: { data_ziadanky: '' } });
        props.dispatch({ type: 'RESETSTATE' });
        history.push("/");
    }
    
    setTimeout(()=>{
        setForm();
        window.close();
    },5000);

    return (
        <div>
            <header className="header-cart--menu thank-you-header">
            </header>
            <div className="thank-you">
                <div className="inner">
                    <h1 className="headline">Žiadanka bola úspešne odoslaná! </h1>
                    <h2>Ďakujeme za využitie našich diagnostických služieb</h2>
                    <p>Prosím, doručte biologickú vzorku označenú štítkom do nášho laboratória. Výsledky vám doručíme poštou.</p>
                    <p>Medirex je laboratórnym partnerom lekárov po celom Slovensku.
                    Zlučuje pod jednou strechou odborníkov zo všetkých medicínskych odborov.
                    Prevádzkujeme technologicky najmodernejšie a najväčšie centrálne laboratóriá nielen na Slovensku,
                    ale aj v strednej a východnej Európe.
                    Ak máte akékoľvek otázky, spojte sa s naším medicínskym obchodným zástupcom.</p>
                    <h3>Táto stránka sa automaticky zatvorí za 5 sekúnd!</h3>
                </div>
            </div>
        </div>

    );

    // <div className="box">
    //                     <button className="button--dark" onClick={setForm} >Vytvoriť novú žiadanku</button>
    //                     <a className="col-sm-12 button--border" href="https://www.medirex.sk/lekar">Späť na web</a>
    //                 </div>
}

export default connect(mapStateToProps)(Podakovanie);

