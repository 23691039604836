import React, { useEffect } from 'react';
import ReactTags from 'react-tag-autocomplete';
import { connect } from 'react-redux';
import './form-styles/DiagZoznam.css'

import { validators } from '../validators/diagnozy'
import validateOnEnum from '../validators/validateOnEnum'

const mapStateToProps = (state) => {
  return ({ diagnozy: state.diagnozy, stepValid: state.validatedSteps._3 })
};

function DiagZoznam(props) {

  const init_diag = props.diagnozy.diag;
  const dispatch = props.dispatch;

  // netusim preco ale funguje, diagnozy sa musia raz refreshnut....

  useEffect(() => {
    dispatch({ type: 'SETDIAG', payload: { diag: init_diag } });
  }, [dispatch, init_diag]);

  const touched = {
    diag: !(props.diagnozy.errors['diag'] === null)
  }

  const setTags = (selected) => {
    let stepValid = props.stepValid;
    let errVal = null;
    let propName = 'diag';
    if (validators[propName].find(v => { return v.validateOn === validateOnEnum.CHANGE })) {
      errVal = []
      validators[propName].filter(val => val.validateOn <= validateOnEnum.CHANGE).forEach(validator => {
        if (validator.validate(selected) === false) errVal.push(validator.invalidMsg);
      });
      if (errVal.length > 0) stepValid = false;
    }
    props.dispatch({ type: 'SETDIAG', payload: { diag: selected, errors: { ...props.diagnozy.errors, diag: errVal }, validatedSteps: { _3: stepValid } } });
  }

  const validateOnBlur = () => {
    let stepValid = props.stepValid;
    let errVal = null;
    let propName = 'diag';
    if (validators[propName].find(v => { return v.validateOn <= validateOnEnum.BLUR })) {
      errVal = []
      validators[propName].filter(val => val.validateOn <= validateOnEnum.BLUR).forEach(validator => {
        if (validator.validate(props.diagnozy.diag) === false) errVal.push(validator.invalidMsg);
      });
      if (errVal.length > 0) stepValid = false;
      props.dispatch({ type: 'SETDIAG', payload: { diag: props.diagnozy.diag, errors: { ...props.diagnozy.errors, diag: errVal }, validatedSteps: { _3: stepValid } } });
    }

  }

  const handleDelete = (i) => {
    console.log('delete ', i);
    setTags(props.diagnozy.diag.filter((tag, index) => index !== i));
  }
  const handleAddition = (tag) => {
    if (props.diagnozy.mkch10.findIndex((x) => x.code === tag.id) > -1) setTags([...props.diagnozy.diag, { code: tag.id, desc: tag.name.substr(tag.id.length + 3) }]);
  }
  /*
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = props.diag.slice() ;
      newTags.splice(currPos,1) ;
      newTags.splice(newPos,0,tag) ;
      setTags(newTags)
    }
  */

  return (
    <div className="form-group d-flex justify-content-between">
      <div className="col-sm-12 col-xs-12">
        <label htmlFor="diagnoza">
          <span className={"validation-icon " + (touched.diag && props.diagnozy.errors.diag.length > 0 ? "is-invalid" : (touched.diag ? "valid" : ""))}></span>
            Diagnóza&nbsp;*
        </label>
        <ReactTags
          tags={props.diagnozy.diag.map((x) => { return { id: x.code, name: x.code + " - " + x.desc } })}
          suggestions={props.diagnozy.mkch10.map(x => { return { id: x.code, name: x.code + " - " + x.desc } })}
          placeholderText={"Zadajte hlavnú a vedľajšie diagnózy"}
          removeButtonText={"Odstráňte kliknutím na vybranú diagnózu"}
          autocomplete={true}
          inline={true}
          onDelete={handleDelete}
          onAddition={handleAddition}
          onBlur={validateOnBlur}
          suggestionsFilter={(s, x) => {
            var x1 = x.toLowerCase();
            var t = x && x.length >= 2 && s && s.name && s.name.toLowerCase().search(x1) >= 0
            //if (t) console.log(s.name,x)
            return t
          }}
          delimiters={['Enter']}  // comma and enter
        />
        <div className="form-error">
          {touched.diag && props.diagnozy.errors['diag'].map((value, i) =>
            <span key={i}>{value}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(DiagZoznam);


