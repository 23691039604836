import React from 'react'

function sendingDasta () {
        return (
            <div>
                    <h1><center>Odosielajú sa údaje</center></h1> 
                    <div><center>Čakajte prosím</center></div>    
            </div>
        )

}

export default sendingDasta
