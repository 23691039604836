const  actionConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    TOKEN_FAILURE: 'USERS_TOKEN_FAILURE',

    SENDASTA_SUCCESS: 'TODASTA_SEND_SUCCESS',

    USER_SETPROPS: 'SETUSER',
    PROFIL_SETPROPS: 'SETPROFIL',

    LAOD_DATA:'SETDATA',
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',

    VERIFICATION :'VERIFY_USER',

    RESETPSW:'RESET_PSW',

    SET_VALIDATION_ERRORS:"SET_VAL_ERR",
    SET_STATE: "SET_STATE",
}
export default actionConstants;
