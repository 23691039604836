import mkch10 from './mkch10'
import actionConstants from './actionConstants'
import config from './config'
import { deepClone } from './utils/cloneObj'
import bckndApi from './services/serveApi'
import axios from 'axios';

var initialState = {
    ID_ZIADANKA : null, //unikatne ID ziadanky v tvare EMDXYYMMDDXXXX, por.c z BE
    menuVisible : false ,
    validatedSteps:{_0:false,_1:null,_2:null,_3:null,_4:null,_5:null,_6:null},
    activeStep : 0 ,
    activeForm : 'L',
    loggedIn:false,
    initial:false,
    dasta: {message:'OK'},
    regIn : false,
    registerMessage : '',
    // kzOrigin: false,
    verification:{
        activationProces:'idle',
        message:'',
        email:'',
        token:''
    },
    user :{
        email : '',
        accessToken:'',
        password:'',
        roles:[],
        errors:{
            common:"",
            password:[],
            email:[]
        }
    },
    lekar : {
        kzOrigin: false,
        pzs : '',
        ico : '',
        //adresa_sidlo_ulica : '',
        //adresa_sidlo_cislo : '',
        adresa_sidlo_ulica_cislo : '',
        adresa_sidlo_mesto : '',
        adresa_sidlo_psc : '',
        adresa_vysledky_ina : false ,
        //adresa_vysledky_ulica : '',
        //adresa_vysledky_cislo : '',
        adresa_vysledky_ulica_cislo : '',
        adresa_vysledky_mesto : '',
        adresa_vysledky_psc : '',
        meno : '',
        kod_lekara : '',
        kod_pzs : '',
        email : '',
        telefon : '',
        suhlas_vop : '',
		errors: {
            meno:null,
             pzs : null,
             email : null,
             kod_lekara : null,
             kod_pzs : null,
             telefon : null,
             ico : null,
             //adresa_sidlo_ulica : null,
             //adresa_sidlo_cislo : null,
             adresa_sidlo_ulica_cislo : null,
             adresa_sidlo_mesto : null,
             adresa_sidlo_psc : null,
             adresa_vysledky_ulica_cislo : null,
             adresa_vysledky_mesto : null,
             adresa_vysledky_psc : null,
             suhlas_vop:null
        }
    },
    userProfil: {
        email: '',
        password: '',
        confPassword: '',
        securityQuestion: '',
        securityAnswer: '',
        errors: {common:'',
                 password : null,
                 confPassword : null},
        msg: ''
    },
    resetPsw: {
        email: '',
        password_new: '',
        confPassword: '',
        securityAnswer: '',
        errors: {password_new : null,
                 confPassword : null,
                 email:null,
                 securityAnswer:null},
        msg: '',
        token:null,
        resetprocess:'idle',
    },
    pacient : {
        rc : '',
        datNar : '',
        pohlavie : '',
        priezvisko : '',
        meno : '',
        adresa : '',
        poisKod : '',
		errors : {
             rc : null,
             datNar : null,
             pohlavie : null,
             priezvisko : null,
             meno : null,
             adresa : null,
             poisKod : null,
        }
    },
    dt_odberu : {
        dt : null,
        errors : {dt:null}
    },
    diagnozy : {
        mkch10 : [] ,
        diag : [],
        errors:{diag:null}
    },
    ziadankaErrors:{poziadavky:null},
    data_ziadanky:{},//inak exc do consoly
    step4_state : {
        bez_oznacenia : true, // PaB @210527 #238.. false,
        errors : {
            vzorky : null,
            udaje: null,
            allerrs : null
        }
    },
    tlac_state : {
        vytlacil: false,
        errors : {
            vytlacil: null,
        }
    },
    review : {
        suhlas_A: null,
        suhlas_B: null,
        errors : {
            suhlas_A : null,
            suhlas_B : null,
            allerrs : null
        }
    }
}

var data_ziadanky ;

const myStorage = window.localStorage;

const saveStorage = (localState={})=>{
    let toLocal = {...initialState, user:localState.user,loggedIn:localState.loggedIn, lekar: localState.lekar, validatedSteps: localState.validatedSteps};
    myStorage.setItem('state', JSON.stringify(toLocal));
}

window.addEventListener('curo-template-get', e => {
    console.log('event received :',e.type)
    //TODO nacitaj z profilu pomocou REST API a uloz do localStorage
})

// window.addEventListener('curo-template-set', e => {
//     console.log('event received :',e.type)
//     //TODO nacitaj z localStorage a zapis do profilu pomocou REST API
// })

// vrati posledny validovany krok loadnuty zo store
// ak nenajde ziadny validny, vrati krok 0
function getValidStep(validSteps) {
    var steps = [];
    for (var prop in validSteps) {
        if (validSteps.hasOwnProperty(prop)) {
            if (validSteps[prop] === true) {
                steps.push(parseInt(prop.replace('_','')));
            }
        }
    }
    if(steps.length > 0) {
        var maxStep;
        for(var i=0; i < steps.length - 1; i++) {
            maxStep = steps[i + 1];
            var dif = steps[i + 1] - steps[i];
            if(dif > 1) {
                maxStep = steps[i];
                return maxStep;
            }
        };
        return maxStep||0;
    }
    return 0;
}

export default function appReducer (state = initialState, action) {
    var t ;
    switch (action.type) {
        case 'LOADSTATE': {
            console.log('pridavam event listener')
            window.addEventListener('curo-open-lab-loaded', e => {
                console.log('event received :',e.type)
                if (data_ziadanky) window.curoOpenLabSetData(data_ziadanky)
            })
            window.addEventListener('curo-template-set', e => {
                console.log('event received :',e.type)
                //TODO nacitaj z localStorage a zapis do profilu pomocou REST API
                bckndApi.updateProfilDataFromLocalStorage();
            })
            try{
                var storedState = myStorage.getItem('state') ;
                if (storedState) {
                    t = {...initialState,...JSON.parse(storedState)};
                    // reload iba po krok 1
                    // if(t.data_ziadanky) {
                    //     data_ziadanky = t.data_ziadanky;
                    // }
                }

                if (t)
                {
                    var lastValidStep = t.validatedSteps ? getValidStep(t.validatedSteps) : 0;
                    t.initial=false;
                    // t.lekar.errors = initialState.lekar.errors ;
                    t.lekar.errors = {...initialState.lekar.errors,...t.lekar.errors} ;
                    t.pacient = deepClone(initialState.pacient) ;
                    t.ziadankaErrors = {...initialState.ziadankaErrors} ;
                    t.step4_state = deepClone(initialState.step4_state);
                    t.diagnozy = {...initialState.diagnozy, mkch10, diag: []};
                    t.review = deepClone(initialState.review);

                    if(lastValidStep>0) lastValidStep=1; //stop reload po krok 1 chod na prazdneho pacienta
                    t.validatedSteps = {...initialState.validatedSteps,_0:true,_1:(lastValidStep>0)}
                    if(t.loggedIn === true && t.user.accessToken) {
                        t.user.errors = deepClone(initialState.user.errors);
                        t.initial = true; //aby spravil initial getProfile pri prvom skoku
                    }
                    else {
                        t.user = deepClone(initialState.user);
                        t.loggedIn = initialState.loggedIn;
                        t.activeForm = 'L';
                    }

                    return { ...initialState, user:t.user,initial:t.initial,loggedIn:t.loggedIn, lekar: t.lekar, validatedSteps: t.validatedSteps, activeStep: lastValidStep, activeForm:t.activeForm } //, pacient: t.pacient, diagnozy: t.diagnozy, dt_odberu: t.dt_odberu, data_ziadanky: t.data_ziadanky, step4_state: t.step4_state, review: t.review, validatedSteps: t.validatedSteps, activeStep: lastValidStep }  ;
                }
            }catch(error){
                console.log('error parsing localStorage ' + error.message);
                //vymaz localStorage
                myStorage.removeItem('state') ;
            }
            return { ...state, diagnozy : {...state.diagnozy, mkch10, diag: []}} ;
        }
        case 'RESETSTATE': {
            data_ziadanky={};
            storedState = myStorage.getItem('state');
            if (storedState) {
                t = JSON.parse(storedState);
                var lekar = t.lekar;
                lekar.errors = deepClone(initialState.lekar.errors);
                if(t.loggedIn === true && t.user.accessToken) {
                    var user = t.user;
                    var logIn = t.loggedIn;
                    user.errors = deepClone(initialState.user.errors);
                } else {
                    user = deepClone(initialState.user);
                    logIn = initialState.loggedIn;
                }
            }
            myStorage.removeItem('state');  // clear storage

            t = {...initialState,
                loggedIn: logIn,
                lekar: lekar,
                user: user,
                pacient: deepClone(initialState.pacient),
                dt_odberu: initialState.dt_odberu,
                review: deepClone(initialState.review),
                diagnozy: deepClone(initialState.diagnozy),
                validatedSteps : {...initialState.validatedSteps,_0:true,_1:true},
                activeStep: 2 // PaB @210526 #243.. activeStep: 1
            };
            if (t) {
                // myStorage.setItem('state', JSON.stringify(t)); //nastav novy state do storage
                saveStorage(t); //nastav novy state do storage
            }
            return t;
        }

        case 'TOGLEMENU': {
           return { ...state, menuVisible : !state.menuVisible}
        }
        case 'SETLEKAR' : {
            // t = { ...state, lekar : {...state.lekar,...action.payload.lekar},validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps} }
            t = { ...state, lekar : action.payload.lekar,validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps} }
            try {
                // myStorage.setItem('state', JSON.stringify(t))
                saveStorage(t); //nastav novy state do storage
            } catch (e) {}
            return t ;
        }
        case 'LOGINKZ' : {
            t = { ...initialState, lekar :
                    {
                        ...initialState.lekar,
                        ...action.payload.lekar
                    },
                pacient : {
                    ...initialState.pacient,
                    ...action.payload.pacient
                },
                review : {
                    ...initialState.review,
                    ...action.payload.review
                },
                validatedSteps:{
                    ...initialState.validatedSteps,
                    ...action.payload.validatedSteps
                },
                user:{...initialState.user, ...action.payload.user},
                loggedIn: action.payload.userToken ? true : false,
                activeStep: action.payload.activeStep,
            }
            try {
                saveStorage(t); //nastav novy state do storage
            } catch (e) {}
            return t ;
        }
		case 'SETPACIENT' : {
			t = { ...state, pacient : action.payload.pacient,validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps} }
            return t ;
        }
		case 'SETACTIVESTEP' : {
            t = { ...state, ...action.payload}
            try {
                // myStorage.setItem('state', JSON.stringify(t))
                saveStorage(t); //nastav novy state do storage
            } catch (e) {}
            return t;
            //return { ...state, ...action.payload};
        }
        case 'SETFORM' : {
            t = { ...state, lekar:{...state.lekar,errors:initialState.lekar.errors},activeForm : action.payload.activeForm }
            return t;
        }
		case 'SETDIAG' : {
            t = { ...state, diagnozy : { ...state.diagnozy, mkch10, diag : action.payload.diag,errors:{...state.diagnozy.errors,...action.payload.errors} } ,validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps}}
            return t ;
        }
		case 'SETZIADANKA' : {
            data_ziadanky = action.payload.data_ziadanky ;
            //console.log('Poznamka', JSON.stringify(data_ziadanky));
            t = { ...state, data_ziadanky : action.payload.data_ziadanky,validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps} }
            if(action.payload.validatedSteps&&action.payload.validatedSteps['_4']===false){
                //invalidate step 4=>clear errors
                t.step4_state.errors=initialState.step4_state.errors;
            }
            return t ;
        }
        case 'VZORKYEMPTYID' : {
            if (!(state.data_ziadanky
                && state.data_ziadanky.vzorky
                && state.data_ziadanky.udaje)) {
                return state;
            }

            var cloneVzorky = state.data_ziadanky.vzorky.slice();
            var cloneUdaje = state.data_ziadanky.udaje.slice();

            var vzorky = cloneVzorky.map( (x, index) => {
                var v = {}
                var hasDuplicate = cloneVzorky.findIndex((item, i) => item.material.id
                    && (item.material.id === x.material.id)
                    && (index !== i)
                    && (index > i)) !== -1;

                v.material = { ...x.material }
                v.material.imutable = x.material.hasOwnProperty('imutable')
                    ? x.material.imutable
                    : true;

                v.material.imutable = !hasDuplicate;

                if (x.hasOwnProperty('id')) v.id = x.id
                else v.id = ""

                return v ;
            });

            t = {
                    ...state,
                    data_ziadanky : {
                        ...state.data_ziadanky,
                        vzorky,
                        udaje : cloneUdaje.map ( x=> {
                            if (x.hasOwnProperty('cislo') ) return x ;
                            return { ...x, cislo : "" }
                        })
                    }
            }
            data_ziadanky = t.data_ziadanky;
            return t ;
        }
        case 'SETVZORKAID' : {
            // cloneVzorky = state.data_ziadanky.vzorky.slice()
            // cloneVzorky = cloneVzorky.map( (x,i) => i !== action.payload.index ? x : { ...x, id: action.payload.value } );
            t = { ...state, ID_ZIADANKA:action.payload.ID_ZIADANKA, data_ziadanky : { ...state.data_ziadanky, vzorky : action.payload.vzorky }
            ,validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps}
            ,step4_state:{...state.step4_state,errors:{...state.step4_state.errors,...action.payload.errors}}
            }
            data_ziadanky = t.data_ziadanky;
            return t
        }
        case 'VZORKYPRIDAJ' : {
            // cloneVzorky = state.data_ziadanky.vzorky.slice(0, action.payload.index+1)
            // cloneVzorky.push( { id : "", material : { ...state.data_ziadanky.vzorky[action.payload.index].material, imutable : false } })
            // cloneVzorky = cloneVzorky.concat(state.data_ziadanky.vzorky.slice(action.payload.index+1 ,state.data_ziadanky.vzorky.length+1))
            // t = { ...state, data_ziadanky : { ...state.data_ziadanky, vzorky : cloneVzorky }}
            t = { ...state, ID_ZIADANKA:action.payload.ID_ZIADANKA, data_ziadanky : { ...state.data_ziadanky, vzorky : action.payload.vzorky }
            ,validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps}
            ,step4_state:{...state.step4_state,errors:{...state.step4_state.errors,...action.payload.errors}}
            }
            data_ziadanky = t.data_ziadanky;
            return t
        }
        case 'VZORKYUBER' : {
            // cloneVzorky = state.data_ziadanky.vzorky.slice(0, action.payload.index)
            // cloneVzorky = cloneVzorky.concat(state.data_ziadanky.vzorky.slice(action.payload.index+1 ,state.data_ziadanky.vzorky.length))
            // t = { ...state, data_ziadanky : { ...state.data_ziadanky, vzorky : cloneVzorky }}
            t = { ...state, data_ziadanky : { ...state.data_ziadanky, vzorky : action.payload.vzorky }
            ,validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps}
            ,step4_state:{...state.step4_state,errors:{...state.step4_state.errors,...action.payload.errors}}
            }
            data_ziadanky = t.data_ziadanky;
            return t

        }
        case 'SETUDAJE' : {
            // cloneUdaje = state.data_ziadanky.udaje.slice()
            // cloneUdaje = cloneUdaje.map( (x,i) => i !== action.payload.index ? x : { ...x, cislo: action.payload.value } );
            // t = { ...state, data_ziadanky : { ...state.data_ziadanky, udaje : cloneUdaje }}
            t = { ...state, data_ziadanky : { ...state.data_ziadanky, udaje : action.payload.udaje }
            ,validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps}
            ,step4_state:{...state.step4_state,errors:{...state.step4_state.errors,...action.payload.errors}}
            }
            data_ziadanky = t.data_ziadanky;
            return t
        }
        case 'SET_DT_ODBERU' : {
            // let copyErrors = action.payload.dt_odberu.errors.slice()
            // let copyDt = {dt:action.payload.dt_odberu.dt, errors: copyErrors}
            t = {...state, dt_odberu : {...state.dt_odberu,...action.payload.dt_odberu},validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps}}
            return t
        }
        case 'SET_VZORKY_STATE' : {
            // mazu sa vzorky, nema zmysel ponechat chyby
            // let copyErrors={...action.payload.step4_state.errors}
            // let copy = {bez_oznacenia: action.payload.step4_state.bez_oznacenia,errors: copyErrors}
            let copy = {bez_oznacenia: action.payload.step4_state.bez_oznacenia,errors: {...state.step4_state.errors,vzorky:initialState.step4_state.errors.vzorky}}
            t = {...state, ID_ZIADANKA:action.payload.ID_ZIADANKA, step4_state : copy, validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps }}
            return t
        }
        case 'SET_TLAC_STATE' : {
            let copy = {vytlacil: action.payload.tlac_state.vytlacil,errors: {...state.tlac_state.errors,vytlacil:initialState.tlac_state.errors.vytlacil}}
            t = {...state, tlac_state : copy, validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps }}
            return t
        }
        case 'SET_REVIEW' : {
            let copy = {suhlas_A: action.payload.review.suhlas_A,suhlas_B: action.payload.review.suhlas_B,ziadanka_odoslana:action.payload.review.ziadanka_odoslana, errors: action.payload.review.errors}
            t = {...state, review : copy , validatedSteps:{...state.validatedSteps,...action.payload.validatedSteps }}
            return t
        }
        case actionConstants.ERROR : {
            t = {...state, dasta : action.payload }
            return t
        }
        case actionConstants.USER_SETPROPS : {
            return {...state,user:action.payload}
        }
        case 'INITPROFILE':{
            t={...state,
                initial:false,
                user: action.payload.logged,
                lekar:{...state.lekar,...action.payload.loaded.lekar}
            };
            t.lekar.errors = {...initialState.lekar.errors,...t.lekar.errors} ;
            if(action.payload.loaded.hasOwnProperty("templates")){
                //prisli templaty ziadanky, vloz do local storage
                myStorage.setItem(config.curolab_template_storage, JSON.stringify(action.payload.loaded.templates));
            }
            return t;
        }
        case actionConstants.LOGIN_SUCCESS:{
            myStorage.removeItem('state') ;  // clear storage
            myStorage.removeItem(config.curolab_template_storage) ;  // clear templates
            data_ziadanky={};
            action.payload.logged.password='';
            action.payload.logged.errors=initialState.user.errors;
            if(action.payload.loaded.hasOwnProperty("templates")){
                //prisli templaty ziadanky, vloz do local storage
                myStorage.setItem(config.curolab_template_storage, JSON.stringify(action.payload.loaded.templates));
                //ak uz je curo natiahnuty urob reload templatov inak nedondu
                if(window.curoOpenLabReloadTemplates) {
                    window.curoOpenLabReloadTemplates();
                }
            }
            t={...initialState,
                loggedIn: true,
                validatedSteps:{...state.initialState,...action.payload.validatedSteps},
                activeStep: 2,
                user: action.payload.logged,
                lekar:{...state.lekar,...action.payload.loaded.lekar}
                //doplnit ostatne objekty storovane v DB
                };

            axios.defaults.headers = {
                ...axios.defaults.headers,
                'x-fs-access-token': t.user.accessToken,
            }

            try {
                // myStorage.setItem('state', JSON.stringify(t))
                saveStorage(t); //nastav novy state do storage
            } catch (e) {}
            return t;
        }
        case actionConstants.LOGIN_FAILURE:
            // alert(action.payload);
            // console.log(action.payload.errors);
            return {...state,loggedIn:false,user:action.payload};

        case actionConstants.TOKEN_FAILURE:
                myStorage.removeItem('state') ;  // clear storage
                myStorage.removeItem(config.curolab_template_storage) ;  // clear templates
                return {...state,loggedIn:false,activeStep:0,user:action.payload};

        case actionConstants.PROFIL_SETPROPS : {
            // console.log(action.payload);
            t= {...state , lekar:action.payload.lekar};
            try {
                // myStorage.setItem('state', JSON.stringify(t))
                saveStorage(t); //nastav novy state do storage
            } catch (e) {}
            t= {...t, userProfil : action.payload.userProfil };
            return t;
        }
        case actionConstants.REGISTER_SUCCESS:
            // console.log(action.payload.msg);
            return {...state,
              regIn : true,
              activeStep: 0,
            //   activeForm: 'L',
              user:{...state.user,email:state.lekar.email,accessToken:''},
              userProfil:{...state.userProfil,errors:{...initialState.userProfil.errors,...action.payload.userErrors}},
              lekar:{...state.lekar,errors:action.payload.lekarErrors}
            };
        case actionConstants.REGISTER_FAILURE:
            // console.log(action.payload.msg);
            return {...state,
                userProfil: action.payload,
                regIn : false,
                activeStep: 0,
                activeForm: 'R',
                user:{...state.user,accessToken:''}
                //userProfil: initialState.userProfil
            }
        case actionConstants.VERIFICATION:
                return {...state,verification: {...state.verification,...action.payload.verification},user:{...state.user,email:action.payload.verification.email}}

        case actionConstants.RESETPSW:
            return {...state,resetPsw:{...state.resetPsw,...action.payload.resetPsw}};
        case actionConstants.SET_VALIDATION_ERRORS:
            //vseobecne dopln chyby validacie kroku
            switch (state.activeStep){
                case 0:
                    if(state.activeForm==="R"){
                        t = {...state,lekar:{...state.lekar,errors:{ ...state.lekar.errors, ...action.payload.lekar }},validatedSteps:{...state.validatedSteps,_1:false}};
                    }
                    break;
                case 1: {
                // validuj lekara
                t = {...state,lekar:{...state.lekar,errors:{ ...state.lekar.errors, ...action.payload.lekar }},validatedSteps:{...state.validatedSteps,_1:false}};
                break;
                }
                case 2: {
                //validuj pacienta
                t = {...state,pacient:{...state.pacient,errors:{ ...state.pacient.errors, ...action.payload.pacient }},validatedSteps:{...state.validatedSteps,_2:false}};
                break;
                }
                case 3: {
                t = {...state,
                    dt_odberu:{...state.dt_odberu,errors:{ ...state.dt_odberu.errors, ...action.payload.dt_odberu }},
                    diagnozy:{...state.diagnozy,errors:{...state.diagnozy.errors,...action.payload.diagnozy}},
                    ziadankaErrors:{...state.ziadankaErrors,...action.payload.ziadankaErrors}
                    ,validatedSteps:{...state.validatedSteps,_3:false}
                    };
                break;
                }
                case 4: {
                //validuj vzorky - vyplnene barcode na vzorkach
                t = {...state,step4_state:{...state.step4_state,errors:{...action.payload}},validatedSteps:{...state.validatedSteps,_4:false}};
                break;
                }
                case 5: {
                //validuj review
                t = {...state,
                    review:{...state.review,errors:{ ...state.review.errors, ...action.payload }},
                    validatedSteps:{...state.validatedSteps,_5:false}};
                break;
                }
                default:
                t={...state};
                break;
                }
                return t;
        case actionConstants.SET_STATE : {
            t= {
                ...state,
                ...action.payload,
                lekar: state.lekar,
                diagnozy: {
                    ...state.diagnozy,
                    ...action.payload.diagnozy
                },
            };

            try {
                saveStorage(t);
            } catch (e) {
                // log error
            }

            return t;
        }
        default : {
            return state ;
        }
    }
}

