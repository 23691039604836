import validateOn from './validateOnEnum'

export const validators = {
    poziadavky: [{ validateOn: validateOn.FORM, validate: (value) => { return (value instanceof Array && value.length > 0) }, invalidMsg: "Do žiadanky musíte vybrať aspoň jedno vyšetrenie"}]
}

export function isValid(state, stateErrors = {}, validationEvent = validateOn.FORM){
    var valid = true;
    for (const property in state) {
        if(!validators.hasOwnProperty(property)) continue;
        stateErrors[property] = [];
        // eslint-disable-next-line no-loop-func
        validators[property].filter(val => val.validateOn <= validationEvent).forEach(validator => {
            if (validator.validate(state[property]) === false) {
                stateErrors[property].push(validator.invalidMsg);
                valid = false;
            }
        });
    }
    return valid;
}
