import { isValid as userValidator } from './user'
import { isValid as lekarValidator } from './lekar'
import { isValid as profilValidator } from './userprofil'
import {isValid as pacientValidator} from './pacient'
import {isValid as ziadankaValidator} from './ziadanka'
import {isValid as vzorkyValidator} from './vzorky'
import {isValid as reviewValidator} from './review'
import {isValid as dtOdberuValidator} from './dtOdberu'
import {isValid as diagnozyValidator} from './diagnozy'
import {isValid as tlacValidator} from './tlac'

import validateOn from './validateOnEnum'

export const validators = { diagnozyValidator:diagnozyValidator,dtOdberuValidator:dtOdberuValidator, userValidator: userValidator, lekarValidator: lekarValidator, pacientValidator:pacientValidator, profilValidator: profilValidator, ziadankaValidator: ziadankaValidator, vzorkyValidator: vzorkyValidator, reviewValidator: reviewValidator, tlacValidator: tlacValidator, validateOnEnum: validateOn }
