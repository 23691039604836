import React from 'react';
/*
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


const selecctMenu = state => state.menuVisible ;
*/

function Header() {
/*
  const menu_visible = useSelector(selecctMenu) ;
  const dispatch = useDispatch()
*/
  return (
      <div/>
  )
/*
  return (
      <div className="App-header">
          <header id="header" className="header up">
        <div className="header--menu">
            <div className="header--menu--left">
                <div>
                    <h1 className="header--menu--logo">
                    <img src="https://static.medirex.sk/themes/medirex-portal/assets/images/logos/medirex-white.svg" alt="medirex"/> 
                    <span>Komplexná laboratórna diagnostika</span>
                    </h1> 
                </div> 
            </div> 
            <div className="header--menu--right mobile">
            <ul className="ul-main ul-menu-actions">
                <li>
                <div className="menu-actions">
                    <div className="search-btn bg-search">
                    </div>
                </div>
                </li> 
                <li>
                <a href="https://www.medirex.sk/pacientska-zona/profil" className="header--menu--darkbt">
                    <span className="icon medirex-user"><i className="fas fa-user"></i>
                    </span>
                </a>
                </li> 
                <li>
                <div className="menu-actions open_sidecart">
                    <div className="basket-btn bg-cart">
                    </div> 
                    </div>
                </li>
            </ul> 
            <div 
                className= {`mobile-menu--open-btn ${menu_visible?"open" : ""}`}
                onClick={ () => { dispatch( { type : 'TOGLEMENU'} ) } }
            >
                <span></span> <span></span> <span></span> <span></span>
            </div> 
            <div id="mobileSubMenu" className={`submenu ${!menu_visible? "":"mega-menu-active"}`} >
                <div className="submenu--in mega-menu">
                <div className="submenu-zone">
                    <a className="active" href="/#">Som pacient</a> 
                    <a className="" href ="https://www.medirex.sk/lekar">Som lekár</a>
                </div>
                <div className="submenu-title-wrapper"><p>Vyšetrenia</p></div>


                <div className="submenu-wrapper">
                    <ul className="submenu--ul">
                    <li className="category-item">
                        <a href="https://www.medirex.sk/vysetrenia/covid-19">
                            <span className="category-icon medirex-covid-v2"></span> 
                            <span>Covid-19</span>
                        </a>
                    </li>
                    <li className="category-item">
                        <a href = "https://www.medirex.sk/vysetrenia/alergie-a-intolerancie">
                        <span className="category-icon medirex-alergie-a-intolerancie"></span> 
                        <span>Alergie<br/>a intolerancie</span>
                        </a>
                    </li>
                    <li className="category-item">
                        <a href = "https://www.medirex.sk/vysetrenia/prevencia-chorob">
                        <span className="category-icon medirex-prevencia-chorob"></span> 
                        <span>Prevencia<br/>chorôb</span>
                        </a>
                    </li>
                    <li className="category-item">
                        <a href = "https://www.medirex.sk/vysetrenia/zdravie-muza">
                        <span className="category-icon medirex-zdravie-muza"></span> 
                        <span>Zdravie<br/>muža</span>
                        </a>
                    </li>
                    <li className="category-item">
                        <a href = "https://www.medirex.sk/vysetrenia/zdravie-zeny">
                        <span className="category-icon medirex-zdravie-zeny"></span> 
                        <span>Zdravie<br/>ženy</span>
                        </a>
                    </li>
                    <li className="category-item">
                        <a href = "https://www.medirex.sk/vysetrenia/zivotny-styl">
                        <span className="category-icon medirex-zivotny-styl"></span> 
                        <span>Životný štýl</span>
                        </a>
                    </li>
                    <li className="category-item">
                        <a href = "https://www.medirex.sk/vysetrenia/konzultacie">
                        <span className="category-icon medirex-konzultacie-s-lekarom"></span> 
                        <span>Konzultácie</span>
                        </a>
                    </li>
                    <li className="category-item">
                        <a href = "https://www.medirex.sk/vysetrenia/poukazy">
                        <span className="category-icon medirex-poukazky"></span> 
                        <span>Poukazy</span>
                        </a>
                    </li>
                    </ul>
                </div>

                <div className="main-menu-wrapper">
                    <ul className="main-mobile-menu">
                    <li>
                        <a id="main-vysetrenia" href="https://www.medirex.sk/vysetrenia" className="mega-menu">Vyšetrenia
                        </a>
                    </li>
                    <li>
                        <a id="" href="https://www.medirex.sk/testovanie-na-covid-19" className="">Covid 19</a>
                    </li>
                    <li>
                        <a id="" href="https://www.medirex.sk/ako-to-funguje" className="">Ako sa objednať</a>
                    </li>
                    <li>
                        <a id="" href="https://www.medirex.sk/odberove-centra" className="">Odberové centrá</a>
                    </li>
                    <li>
                        <a id="" href="https://www.medirex.sk/o-nas" className="">O nás</a>
                    </li>
                    <li>
                        <a id="" href="https://www.medirex.sk/kontakt" className="">Kontakt</a>
                    </li>
                    <li>
                        <a id="" href="https://www.medirex.sk/blog" className="">Blog</a>
                    </li>
                    </ul> 
                    <a href="https://www.medirex.sk/pacientska-zona/dashboard" className="button--dark mobile-header-btn">Pacientská zóna</a> 
                    <div>
                    <div className="info-box two-numbers">
                        <div className="left">
                        <a href="/#" className="text-wrap">
                            <p className="title">Samoplatci Covid</p> 
                            <p className="number">02/2086 6688</p>
                        </a> 
                        <a href="/#" className="text-wrap">
                            <p className="title">Samoplatci</p> 
                            <p className="number">0800 400 800</p>
                        </a>
                        </div> 
                        <div className="icon medirex-phone infolinka"></div>
                    </div>
                    </div>
                </div>
                <div className="mobile-language-switcher">
                    <a href="/#" className="active">Sk</a> 
                    <a href="/#" className="">En</a>
                </div>
                </div>
            </div>
            </div>
        </div>
        </header>
      </div>
  );
*/
}

export default Header;
