import validateOn from './validateOnEnum'

export const validators = {
    vytlacil: [{
        validateOn: validateOn.FORM, validate: (obj) => {
            let retval = obj!==null && obj;
            console.log("validate vytlacil = " + retval);
            return retval;
        }, invalidMsg: "Pred pokračovaním na ďalší krok vytlačte žiadanku"
    }]
}
export function isValid(state, stateErrors = {}, validationEvent = validateOn.FORM){
    var valid = true;
    for (const property in state) {
        if(!validators.hasOwnProperty(property)) continue;
        stateErrors[property] = [];
        // eslint-disable-next-line no-loop-func
        validators[property].filter(val => val.validateOn <= validationEvent).forEach(validator => {
            if (validator.validate(state[property]) === false) {
                stateErrors[property].push(validator.invalidMsg);
                valid = false;
            }
        });
    }
    return valid;
}
