import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ziadankaSaveUpdate } from '../services/ziadanka';
import { useHistory } from 'react-router-dom';
import { deepClone } from '../utils/cloneObj';

const mapStateToProps = (state) => {
    return ({
        lekar: state.lekar,
        pacient: state.pacient,
    })
};

function FormSaveZiadanka(props) {
    const [menoZiadanky, setMenoZiadanky] = useState(props.content.ziadanka_name
        || `${props.pacient.meno} ${props.pacient.priezvisko}`);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const form = useRef(null);
    const [stringContent, setStringContent] = useState(JSON.stringify(props.content));
    const [isZiadankaNameChecked, setIsZiadankaNameChecked] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setStringContent(JSON.stringify(deepClone(props.content)));
    }, [props.content]);

    useEffect(() => {
        return () => setMenoZiadanky('');
    }, []);

    function submitZiadanka(event) {
        event.preventDefault();
        const formData = form.current && new FormData(form.current);
        const id = props.content._id;

        if (formData
            && !isLoading) {
            setIsLoading(true);

            ziadankaSaveUpdate(
                id,
                JSON.stringify(Object.fromEntries(formData)),
                isZiadankaNameChecked,
            )
                .then(() => {
                    history.push('/list');
                })
                .catch((error) => {
                    const errorData = error.response?.data;

                    if (errorData) {
                        if (errorData.errors) {
                            const errors = [];

                            Object.keys(errorData.errors).forEach((key) => {
                                errors.push(errorData.errors[key]);
                            })

                            setErrors(errors);

                            return;
                        }

                        if (errorData.type === 'duplicate') {
                            setIsZiadankaNameChecked(true);
                        }

                        setErrors([errorData.message]);

                        return;
                    }

                    setErrors([error.message]);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    function handleChangeMenoZiadanky(event) {
        setMenoZiadanky(event.target.value)
    }

    return (
        <form
            className="form-save-ziadanka"
            onSubmit={submitZiadanka}
            ref={form}
        >
            {
                isLoading &&
                <div className="form-save-ziadanka__overlay">
                    <div
                        className="spinner-border"
                        role="status"
                    >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            <div>
                <div className="input-box my-3">
                    <input
                        className="input w-100"
                        name="name"
                        type="textbox"
                        value={menoZiadanky}
                        onChange={handleChangeMenoZiadanky}
                        required
                    />
                    <div className="form-error">
                        {errors.map((value, i) =>
                            <span key={i}>{value}</span>
                        )}
                    </div>
                    <input
                        name="user_id"
                        type="hidden"
                        value={props.lekar.kz_id}
                    />
                    <input
                        name="a_code"
                        type="hidden"
                        value={props.lekar.kod_lekara}
                    />
                    <input
                        name="p_code"
                        type="hidden"
                        value={props.lekar.kod_pzs}
                    />
                    <input
                        name="patient_birth_code"
                        type="hidden"
                        value={props.pacient.rc}
                    />
                    <input
                        name="content"
                        type="hidden"
                        value={stringContent}
                    />
                </div>
            </div>
            <div className="mt-4 d-flex justify-content-end">
                <button
                    type="button"
                    className="button--white mr-2"
                    data-dismiss="modal"
                    onClick={props.closeModal}
                >
                    Zatvoriť
                </button>

                <button
                    type="button"
                    className="button--dark"
                    onClick={(event) => submitZiadanka(event)}
                >
                    Uložiť
                </button>
            </div>
        </form>
    )
}

export default connect(mapStateToProps)(FormSaveZiadanka);
