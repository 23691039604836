import config from '../config';
export const API_URL = config.apiUrl;

const METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
};

export const API = {
    ZIADANKA: {
        LIST: {
            url: `${API_URL}/ziadanka/:user_id`,
            method: METHODS.GET,
        },
        SAVE: {
            url: `${API_URL}/ziadanka`,
            method: METHODS.POST,
        },
        UPDATE: {
            url: `${API_URL}/ziadanka/:id`,
            method: METHODS.PUT,
        },
        DELETE: {
            url: `${API_URL}/ziadanka/:id`,
            method: METHODS.DELETE,
        },
        CHECK_NAME: {
            url: `${API_URL}/ziadanka/check-name/:name`,
            method: METHODS.GET,
        },
        CHECK_ZIADANKA_EXISTS: {
            url: `${API_URL}/ziadanka/check-ziadanka-exists/:code`,
            method: METHODS.GET,
        }
    },
};

export function getApi(
    requestConfig,
    params,
) {
    return {
        ...requestConfig,
        url: requestConfig.url?.replace(
            /\/:(\w+)/g,
            (_, key) => `/${params[key]}` || '',
        ),
    };
}

