import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { poistovne ,validators } from '../validators/pacient'
import validateOnEnum from '../validators/validateOnEnum'

const mapStateToProps = (state) => {
    return ({ pacient: state.pacient, stepValid: state.validatedSteps._2 })
};

export function Pacient(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const touched = {
        rc: !(props.pacient.errors.rc === null),
        datNar: !(props.pacient.errors.datNar === null),
        pohlavie: !(props.pacient.errors.pohlavie === null),
        priezvisko: !(props.pacient.errors.priezvisko === null),
        meno: !(props.pacient.errors.meno === null),
        adresa: !(props.pacient.errors.adresa === null),
        poisKod: !(props.pacient.errors.poisKod === null),
    }

    const valid = ()=>{
        for(const property in touched){
            if(touched[property]===true&&props.pacient.errors[property].length>0) return false;
        }
        return true;
    }

    const validate = (event) => {
        //zmenil nieco
        var stepValid = props.stepValid;
        const target = event.target;
        var errVal = {...props.pacient.errors};	//existujuca hodnota 'errors' v props
        var out = {}
        if (validators.hasOwnProperty(target.name)) {
            errVal[target.name] = [];
            validators[target.name].filter(val => val.validateOn <= validateOnEnum.BLUR).forEach(validator => {
                if (validator.validate(target.value, out) === false) errVal[target.name].push(validator.invalidMsg);
                for(const prop in out){
                    errVal[prop]=[];
                    handleInputChange({target:{value:out[prop],name:prop}})
                }                    
            });
            if (errVal[target.name].length > 0) stepValid = false;
            props.dispatch({ type: 'SETPACIENT', payload: { ...props, validatedSteps: { _2: stepValid }, pacient: { ...props.pacient, ...out, errors: { ...props.pacient.errors, ...errVal } } } });
        }
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        var out = {}
        var stepValid = props.stepValid;
        var errVal = {...props.pacient.errors};	//existujuca hodnota 'errors' v props

        if (validators.hasOwnProperty(target.name)&&validators[target.name].find(v=>{return v.validateOn===validateOnEnum.CHANGE})) {
            errVal[target.name] = [];
            validators[target.name].filter(val => val.validateOn <= validateOnEnum.CHANGE).forEach(validator => {
                if (validator.validate(target.value, out) === false) errVal[target.name].push(validator.invalidMsg);
                for(const prop in out){
                    errVal[prop]=[];
                    handleInputChange({target:{value:out[prop],name:prop}})
                }                    
            });
            if (errVal[target.name].length > 0) stepValid = false;
            props.dispatch({ type: 'SETPACIENT', payload: { ...props, validatedSteps: { _2: stepValid }, pacient: { ...props.pacient, [name]: value, ...out, errors: { ...props.pacient.errors, ...errVal } } } });
        }else
            props.dispatch({ type: 'SETPACIENT', payload: { ...props, pacient: { ...props.pacient, [name]: value } } })
    }

    return (
        <React.Fragment>
            <div className="box">
                <form className="opac1">
                    <h3>Informácie o pacientovi</h3>
                    <div className="form-group">

                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <label htmlFor="rc">
                                    <span className={"validation-icon " + (touched.rc && props.pacient.errors.rc.length > 0 ? "is-invalid" : (touched.rc ? "valid" : ""))}></span>
                                Rodné číslo&nbsp;*
                                </label>
                                <div className="input-box">
                                    <input className={"input " + (touched.rc && props.pacient.errors.rc.length > 0 ? "is-invalid" : "")}
                                           name="rc" type="number" value={props.pacient.rc} onChange={handleInputChange} onBlur={validate} placeholder="Vložte rodné číslo bez lomítka"/>
                                    <div className="form-error">
                                        {touched.rc && props.pacient.errors['rc'].map((value, i) =>
                                            <span key={i}>{value}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <label htmlFor="datNar">
                                    <span className={"validation-icon " + (touched.datNar && props.pacient.errors.datNar.length > 0 ? "is-invalid" : (touched.datNar ? "valid" : ""))}></span>
                                Dátum narodenia&nbsp;*
                            </label>
                                <div className="input-box">
                                    <input className={"input " + (touched.datNar && props.pacient.errors.datNar.length > 0 ? "is-invalid" : "")} name="datNar" type="date" value={props.pacient.datNar} onChange={handleInputChange} onBlur={validate} />
                                    <div className="form-error">
                                        {touched.datNar && props.pacient.errors['datNar'].map((value, i) =>
                                            <span key={i}>{value}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-xs-12" >
                                <label htmlFor="datNar">
                                    <span className={"validation-icon " + (touched.pohlavie && props.pacient.errors.pohlavie.length > 0 ? "is-invalid" : (touched.pohlavie ? "valid" : ""))}></span>
                                    Pohlavie&nbsp;*
                                </label>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <label className={props.pacient.pohlavie === 'z' ? "sex-item active" : "sex-item"}>
                                    <input type="radio" name="pohlavie" value="z" className="sex-type" onChange={handleInputChange} checked={props.pacient.pohlavie === 'z' ? true : false} onBlur={validate}/> 
                                    <span className="cr"><i className="cr-icon"></i></span> 
                                    <div className="content"><span className="sex-icon woman"></span> 
                                    <div className="title"><p>Žena</p></div>
                                    </div>
                                </label>
                                <div className="form-error">
                                        {touched.pohlavie && props.pacient.errors['pohlavie'].map((value, i) =>
                                            <span key={i}>{value}</span>
                                        )}
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12" >
                                <label className={props.pacient.pohlavie === 'm' ? "sex-item active" : "sex-item"}>
                                    <input type="radio" name="pohlavie" value="m" className="sex-type" onChange={handleInputChange} checked={props.pacient.pohlavie === 'm' ? true : false} onBlur={validate}/> 
                                    <span className="cr"><i className="cr-icon"></i></span> 
                                    <div className="content"><span className="sex-icon man"></span> 
                                    <div className="title"><p>Muž</p></div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <label htmlFor="priezvisko">
                                    <span className={"validation-icon " + (touched.priezvisko && props.pacient.errors.priezvisko.length > 0 ? "is-invalid" : (touched.priezvisko ? "valid" : ""))}></span>
                                Priezvisko&nbsp;*
                            </label>
                                <div className="input-box">
                                    <input className={"input " + (touched.priezvisko && props.pacient.errors.priezvisko.length > 0 ? "is-invalid" : "")} name="priezvisko" type="textbox" value={props.pacient.priezvisko} onChange={handleInputChange} onBlur={validate} />
                                    <div className="form-error">
                                        {touched.priezvisko && props.pacient.errors['priezvisko'].map((value, i) =>
                                            <span key={i}>{value}</span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-xs-12">
                                <label htmlFor="meno">
                                    <span className={"validation-icon " + (touched.meno && props.pacient.errors.meno.length > 0 ? "is-invalid" : (touched.meno ? "valid" : ""))}></span>
                                Meno&nbsp;*
                            </label>
                                <div className="input-box">
                                    <input className={"input " + (touched.meno && props.pacient.errors.meno.length > 0 ? "is-invalid" : "")} name="meno" type="textbox" value={props.pacient.meno} onChange={handleInputChange} onBlur={validate} />
                                    <div className="form-error">
                                        {touched.meno && props.pacient.errors['meno'].map((value, i) =>
                                            <span key={i}>{value}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <label htmlFor="adresa">
                                    <span className={"validation-icon " + (touched.adresa && props.pacient.errors.adresa.length > 0 ? "is-invalid" : (touched.adresa ? "valid" : ""))}></span>
                                Adresa&nbsp;*
                            </label>
                                <div className="input-box">
                                    <input className={"input " + (touched.adresa && props.pacient.errors.adresa.length > 0 ? "is-invalid" : "")} name="adresa" type="textbox" value={props.pacient.adresa} onChange={handleInputChange} onBlur={validate} />
                                    <div className="form-error">
                                        {touched.adresa && props.pacient.errors['adresa'].map((value, i) =>
                                            <span key={i}>{value}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-xs-12" >
                            <label htmlFor="poisKod">
                                <span className={"validation-icon " + (touched.poisKod && props.pacient.errors.poisKod.length > 0 ? "is-invalid" : (touched.poisKod ? "valid" : ""))}></span>
                                    Zdravotná poisťovňa&nbsp;*
                                </label>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <label className={props.pacient.poisKod === poistovne[0].value ? "sex-item active" : "sex-item"}>
                                    <input type="radio" name="poisKod" value={poistovne[0].value} className="sex-type" onChange={handleInputChange} checked={props.pacient.poisKod === poistovne[0].value ? true : false}/> 
                                    <span className="cr"><i className="cr-icon"></i></span> 
                                    <div className="content"> 
                                    <div className="title"><p>&nbsp;{poistovne[0].label}</p></div>
                                    </div>
                                </label>
                            </div>
                            <div className="col-sm-6 col-xs-12" >
                                <label className={props.pacient.poisKod === poistovne[1].value ? "sex-item active" : "sex-item"}>
                                    <input type="radio" name="poisKod" value={poistovne[1].value} className="sex-type" onChange={handleInputChange} checked={props.pacient.poisKod === poistovne[1].value ? true : false}/> 
                                    <span className="cr"><i className="cr-icon"></i></span> 
                                    <div className="content"> 
                                    <div className="title"><p>&nbsp;{poistovne[1].label}</p></div>
                                    </div>
                                </label>
                            </div>
                            <div className="col-sm-6 col-xs-12" >
                                <label className={props.pacient.poisKod === poistovne[2].value ? "sex-item active" : "sex-item"}>
                                    <input type="radio" name="poisKod" value={poistovne[2].value} className="sex-type" onChange={handleInputChange} checked={props.pacient.poisKod === poistovne[2].value ? true : false}/> 
                                    <span className="cr"><i className="cr-icon"></i></span> 
                                    <div className="content"> 
                                    <div className="title"><p>&nbsp;{poistovne[2].label}</p></div>
                                    </div>
                                </label>
                                <div className="form-error">
                                        {touched.poisKod && props.pacient.errors['poisKod'].map((value, i) =>
                                            <span key={i}>{value}</span>
                                        )}
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12" >
                                <label className={props.pacient.poisKod === poistovne[3].value ? "sex-item active" : "sex-item"}>
                                    <input type="radio" name="poisKod" value={poistovne[3].value} className="sex-type" onChange={handleInputChange} checked={props.pacient.poisKod === poistovne[3].value ? true : false}/> 
                                    <span className="cr"><i className="cr-icon"></i></span> 
                                    <div className="content">
                                    <div className="title"><p>&nbsp;{poistovne[3].label}</p></div>
                                    </div>
                                </label>
                            </div>
                            <div className="form-error align-left col-sm-12">{props.stepValid === false && valid()===false ? "Niektoré polia nie sú vyplnené alebo sú vyplnené chybným spôsobom. Prosím, skontrolujte si ich." : ""}</div>

                        </div>
                        
                        
                    </div>
                </form>

            </div>
        </React.Fragment>
    );
}

export default connect(mapStateToProps)(Pacient);
