import React,{ useState, useEffect }  from 'react';
import { connect } from 'react-redux';
import {validators} from '../validators/lekar'
import validateOnEnum from '../validators/validateOnEnum'
import SelectItem from './SelectItem'
import SelectPzs from './SelectPZS'
import api from '../services/serveApi'
import * as stepValidators from "../validators";
import actionConstants from "../actionConstants";
import LekarInput, {lekarInput} from "./Components/LekarInput";

// import './form.css';

const mapStateToProps =  ( state ) => {
    return ( {lekar: state.lekar,renderByErrorsChange:state.lekar.errors,stepValid:state.validatedSteps._1, loggedIn: state.loggedIn} )
};

function Lekar( props ) {

    const [options,setOptions] = useState([])
    const [index, setIndex] = useState(-1)
    const [showSelect,setShowSelect] = useState(false)

    const [optionsPzs, setOptionsPzs] = useState([])
    const [indexPzs, setIndexPzs] = useState(-1)
    const [showSelectPzs, setShowSelectPzs] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const touched = {
            meno:!(props.lekar.errors.meno===null),
            pzs : !(props.lekar.errors.pzs===null),
            email : !(props.lekar.errors.email===null),
            kod_lekara : !(props.lekar.errors.kod_lekara===null),
            kod_pzs : !(props.lekar.errors.kod_pzs===null),
            telefon : !(props.lekar.errors.telefon===null),
            ico : !(props.lekar.errors.ico===null),
            adresa_sidlo_ulica_cislo : !(props.lekar.errors.adresa_sidlo_ulica_cislo===null),
            //adresa_sidlo_ulica : !(props.lekar.errors.adresa_sidlo_ulica===null),
            //adresa_sidlo_cislo : !(props.lekar.errors.adresa_sidlo_cislo===null),
            adresa_sidlo_mesto : !(props.lekar.errors.adresa_sidlo_mesto===null),
            adresa_sidlo_psc : !(props.lekar.errors.adresa_sidlo_psc===null),
            adresa_vysledky_ulica_cislo : !(props.lekar.errors.adresa_vysledky_ulica_cislo===null),
            adresa_vysledky_mesto : !(props.lekar.errors.adresa_vysledky_mesto===null),
            adresa_vysledky_psc : !(props.lekar.errors.adresa_vysledky_psc===null),

            suhlas_vop:!(props.lekar.errors.suhlas_vop===null)
    };

    const valid = ()=>{
        for(const property in touched){
            if(touched[property]===true&&props.lekar.errors[property].length>0) return false;
        }
        return true;
    }

	const validate = (event) => {
        //zmenil nieco
        var stepValid = props.stepValid;
		const target = event.target;
        const tval = target.type === 'checkbox' ? target.checked : target.value;
		var errVal = {...props.lekar.errors};	//existujuca hodnota 'errors' v props
        setShowSelect(false);
        setShowSelectPzs(false);
        if(validators.hasOwnProperty(target.name)){
            errVal[target.name] = [];
            validators[target.name].filter(val => val.validateOn <= validateOnEnum.BLUR).forEach(validator => {
                if (validator.validate(tval,props.lekar.adresa_vysledky_ina) === false) errVal[target.name].push(validator.invalidMsg);
            });
            if(errVal[target.name].length>0)    stepValid=false;
            props.dispatch({ type : 'SETLEKAR', payload : { ...props,validatedSteps:{_1:stepValid} , lekar:{...props.lekar,errors:{...props.lekar.errors,[target.name]:errVal[target.name]} }}} );
        }

	}

	const validateLekar = (tmpLekar) => {
        // validuj lekara
        let stepErrors = {...props.lekar.errors}
        stepValidators.validators.lekarValidator(tmpLekar, stepErrors, stepValidators.validators.validateOnEnum.FORM);
        stepErrors = {...stepErrors,email:props.lekar.errors.email,telefon:props.lekar.errors.telefon,suhlas_vop:props.lekar.errors.suhlas_vop};
        props.dispatch({ type: actionConstants.SET_VALIDATION_ERRORS, payload: { lekar: stepErrors } });
    }

    const handleChange = (index) => {
        //console.log("handleChange index=" + index);
        setIndex(index);
        if (index > -1 && index < options.length) {
            let tmpLekar = {
                    ...props.lekar,
                    kod_pzs: options[index].kod_pzs,
                    kod_lekara: options[index].kod_lekara,
                    meno: options[index].meno,
                    pzs: options[index].pzs
            };
            props.dispatch({
                type: 'SETLEKAR',
                payload: {
                    lekar:tmpLekar,
                    validatedSteps:{_1:props.stepValid} 
                }
            });
            setShowSelect(false);
            if(tmpLekar.kod_pzs) {
                tvojPzs(tmpLekar.kod_pzs, true, tmpLekar);
            } else {
                validateLekar(tmpLekar);
            }
        }
    }

    const handleChangePzs = (index,tmpLekar={}) => {
        //console.log("handleChange index=" + index);
        setIndexPzs(index);
        if (index > -1 && index < optionsPzs.length) {            
            tmpLekar = {
                    ...props.lekar,...tmpLekar,
                    kod_pzs: optionsPzs[index].pzs_kod,
                    pzs: optionsPzs[index].nazov_pzs,
                    ico: optionsPzs[index].ico,
                    adresa_sidlo_ulica_cislo: optionsPzs[index].ulica_cislo_ou,
                    adresa_sidlo_mesto: optionsPzs[index].obec_ou,
                    adresa_sidlo_psc: optionsPzs[index].psc_ou
            };
            props.dispatch({
                type: 'SETLEKAR',
                payload: {
                    lekar:tmpLekar,
                    validatedSteps:{_1:props.stepValid} 
                }
            });
            // setOptionsPzs([optionsPzs[index]]);
            setShowSelectPzs(false);
            validateLekar(tmpLekar);
        }
    }

    const tvojPzs = (val, s, tmpLekar={}) => {
        let kod = val;
        try {
            if (optionsPzs.length === 0 || val < props.lekar.kod_pzs || s) {
                api.getPzs(kod).then((data) => {
                    if (s) {
                        setIndexPzs(0);
                        setOptionsPzs(data);
                        optionsPzs.length=0;//vymaze
                        optionsPzs.push(...data);
                        handleChangePzs(0,tmpLekar);
                    } else {
                        setIndexPzs(-1);
                        setOptionsPzs(data);
                        setShowSelectPzs(true);
                    }
                }).catch(err=>{
                    setIndex(-1);
                    console.error(err.message)
                });
            } else {
                if (!s) {
                    setIndexPzs(-1);
                    setShowSelectPzs(true);
                }

            }
        } catch (err) {
            setIndexPzs(-1);
            setOptionsPzs(["Chyba."]);
            console.error(err);
        }
    }

    const tvojLekar = (val) => {
        let kod = val ;
        if (val.substring(0,1) === 'P') kod = val.substring(1) ;
        //console.log(kod);
        try {
            if(options.length===0||val.substring(1,5)!==props.lekar.kod_lekara.substring(1,5)){
                api.lekar(kod).then((data) => {
                    if (JSON.stringify(data) !== JSON.stringify(options)) {
                        //console.log("Nove data");
                        setIndex(-1);
                        setOptions(data);
                        setShowSelect(true);
                    }
                }).catch(err=>{
                    setIndex(-1);
                    console.error(err.message)
                });
            }else{
                setIndex(-1);
                setShowSelect(true);
            }
        } catch(err) {
            setIndex(-1);
            setOptions(["Chyba."]);
            console.error(err);
        }
    }
    //minimalny pocet zadanych ciselnich znakov
    const KOD_LEKARA_SEARCH_MINLENGTH = 5;
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setShowSelect(false);
        setShowSelectPzs(false);

        if (name === 'kod_lekara') {
            //vycisti options cache ak je zadanych menej zmakov ako minimum
            if (!minLength(value)) {
                setOptions([]);
                setIndex(-1);
            } else {
                tvojLekar(value);
            }

        }

        if (name === 'kod_pzs') {
            //vycisti options cache ak je zadanych menej zmakov ako minimum
            if (!minLengthPzs(value) ) {
                setOptionsPzs([]);
                setIndexPzs(-1);
            } else {
                tvojPzs(value);
            }
        }

        var stepValid = props.stepValid;
		var errVal = {...props.lekar.errors};	//existujuca hodnota 'errors' v props
        if(target.name==="adresa_vysledky_ina"){
            if(value===true){
                props.dispatch({ type : 'SETLEKAR', payload : { ...props, lekar:{...props.lekar,[name]: value },validatedSteps:{_1:false}} });
            }else{
                errVal.adresa_vysledky_ulica_cislo = null;
                errVal.adresa_vysledky_mesto = null;
                errVal.adresa_vysledky_psc = null;
                stepValid = valid();
                props.dispatch({ type : 'SETLEKAR', payload : { ...props,validatedSteps:{_1:stepValid} , lekar:{...props.lekar,[name]: value,errors:{...props.lekar.errors,...errVal} }}} );
            }
        }else
            if(validators.hasOwnProperty(target.name)&&validators[target.name].find(v=>{return v.validateOn===validateOnEnum.CHANGE})){
                errVal[target.name] = [];
                validators[target.name].filter(val => val.validateOn <= validateOnEnum.CHANGE).forEach(validator => {
                    if (validator.validate(value,props.lekar.adresa_vysledky_ina) === false) errVal[target.name].push(validator.invalidMsg);
                });
                if(errVal[target.name].length>0)    stepValid=false;
                props.dispatch({ type : 'SETLEKAR', payload : { ...props,validatedSteps:{_1:stepValid} , lekar:{...props.lekar,[name]: value,errors:{...props.lekar.errors,[target.name]:errVal[target.name]} }}} );
            }else
            props.dispatch({ type : 'SETLEKAR', payload : { ...props, lekar:{...props.lekar,[name]: value } } } )
    }

    const minLength = (kod) => {
        return ((kod
            &&(kod.substring(0,1) === 'A' && kod.length >= KOD_LEKARA_SEARCH_MINLENGTH+1))
            ||(kod.substring(0,1).match(/d/) && kod.length >= KOD_LEKARA_SEARCH_MINLENGTH))
    }

    const minLengthPzs = (kod) => {
        return ((kod
            &&(kod.length >= KOD_LEKARA_SEARCH_MINLENGTH+1))
            ||(kod.substring(0,1).match(/d/) && kod.length >= KOD_LEKARA_SEARCH_MINLENGTH))
    }

    const handleFocus = () => {
        let kod = props.lekar.kod_lekara;
        if(kod && minLength(kod)
            && (options === null||options.length===0)) {
            tvojLekar(kod);
        }
        if(options&&options.length) setShowSelect(true);
    }

    const handleFocusPzs = () => {
        let kod = props.lekar.kod_pzs;
        if(kod && minLengthPzs(kod)
            && (optionsPzs === null||optionsPzs.length===0)) {
            tvojPzs(kod);
        }
        if(optionsPzs&&optionsPzs.length) setShowSelectPzs(true);
    }

    return (
        <div className="box">
            <form className="opac1">
            <h3>Informácie o lekárovi</h3>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">

                            <label htmlFor="kod_lekara">
                                <span className={"validation-icon " + (touched.kod_lekara&&props.lekar.errors.kod_lekara.length > 0 ? "is-invalid" : (touched.kod_lekara?"valid":""))}></span>
                                Kód lekára&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input " + (touched.kod_lekara&&props.lekar.errors.kod_lekara.length > 0 ? "is-invalid" : "")} name="kod_lekara"    type="textbox"    value={props.lekar.kod_lekara}
                                    onFocus={handleFocus}
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    //onClick={setShowSelect(!showSelect)}
                                    style={{autocomplete:"false"}} />
                                <div className="form-error">
                                    {touched.kod_lekara&&!showSelect&&props.lekar.errors['kod_lekara'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                            <div>
                                <SelectItem options={options} show={showSelect} handleChange={handleChange}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="meno">
                                <span className={"validation-icon " + (touched.meno&&props.lekar.errors.meno.length > 0 ? "is-invalid" : (touched.meno?"valid":""))}></span>
                                Meno lekára&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input 
                                    className={"input "+ (touched.meno&&props.lekar.errors.meno.length > 0 ? "is-invalid" : "")}  
                                    name="meno"          
                                    type="textbox"    
                                    value={props.lekar.meno}            
                                    onChange={handleInputChange} 
                                    onBlur={validate}
                                />
                                <div className="form-error">
                                    {touched.meno&&props.lekar.errors['meno'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <LekarInput
                            gridSize='col-sm-6 col-xs-12'
                            name="meno" 
                            text="Meno lekára" 
                            validate={touched.meno&&props.lekar.errors.meno.length} 
                            touchItem={touched.meno}
                            value={props.lekar.meno}
                            error={touched.meno&&props.lekar.errors['meno']}
                        /> */}
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="kod_pzs">
                            <span className={"validation-icon " + (touched.kod_pzs&&props.lekar.errors.kod_pzs.length > 0 ? "is-invalid" : (touched.kod_pzs?"valid":""))}></span>
                                Kód poskytovateľa&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input " + (touched.kod_pzs&&props.lekar.errors.kod_pzs.length > 0 ? "is-invalid" : "")} name="kod_pzs"    type="textbox"    value={props.lekar.kod_pzs}      
                                    onFocus={handleFocusPzs}
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    style={{autocomplete:"false"}} />
                                <div className="form-error">
                                    {touched.kod_pzs&&props.lekar.errors['kod_pzs'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                            <div>
                                <SelectPzs options={optionsPzs} show={showSelectPzs} handleChange={handleChangePzs}/>
                            </div>                        
                        </div>

                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="ico">
                            <span className={"validation-icon " + (touched.ico&&props.lekar.errors.ico.length > 0 ? "is-invalid" : (touched.ico?"valid":""))}></span>
                                IČO poskytovateľa&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input " + (touched.ico&&props.lekar.errors.ico.length > 0 ? "is-invalid" : "")} name="ico"    type="textbox"    value={props.lekar.ico}      onChange={handleInputChange} onBlur={validate} />
                                <div className="form-error">
                                    {touched.ico&&props.lekar.errors['ico'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <label htmlFor="pzs">
                                <span className={"validation-icon " + (touched.pzs&&props.lekar.errors.pzs.length > 0 ? "is-invalid" : (touched.pzs?"valid":""))}></span>
                                Názov poskytovateľa&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input " + (touched.pzs&&props.lekar.errors.pzs.length > 0 ? "is-invalid" : "")}  name="pzs"    type="textbox"    value={props.lekar.pzs}      onChange={handleInputChange} onBlur={validate} />
                                <div className="form-error">
                                    {touched.pzs&&props.lekar.errors['pzs'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="email">
                            <span className={"validation-icon " + (touched.email&&props.lekar.errors.email.length > 0 ? "is-invalid" : (touched.email?"valid":""))}></span>
                                email&nbsp;*
                            </label>
                            <div className ="input-box">
                                {/* eslint-disable-next-line no-mixed-operators */}
                                <input className={"input " + (touched.email&&props.lekar.errors.email.length > 0 ? "is-invalid" : "")} name="email" type="textbox" value={props.lekar.email} onChange={handleInputChange} readOnly={props.loggedIn === true && props.lekar.kzOrigin === false } onBlur={validate} />
                                <div className="form-error">
                                    {touched.email&&props.lekar.errors['email'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="telefon">
                            <span className={"validation-icon " + (touched.telefon&&props.lekar.errors.telefon.length > 0 ? "is-invalid" : (touched.telefon?"valid":"")) }></span>
                                Telefón&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input "+ (touched.telefon&&props.lekar.errors.telefon.length > 0 ? "is-invalid" : "")}  name="telefon"    type="textbox"    value={props.lekar.telefon}      onChange={handleInputChange} onBlur={validate} />
                                <div className="form-error">
                                    {touched.telefon&&props.lekar.errors['telefon'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-xs-12">
                            <label htmlFor="adresa_sidlo_ulica_cislo">
                            <span className={"validation-icon " + (touched.adresa_sidlo_ulica_cislo&&props.lekar.errors.adresa_sidlo_ulica_cislo.length > 0 ? "is-invalid" : (touched.adresa_sidlo_ulica_cislo?"valid":""))}></span>
                                Ulica, číslo&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input "+ (touched.adresa_sidlo_ulica_cislo&&props.lekar.errors.adresa_sidlo_ulica_cislo.length > 0 ? "is-invalid" : "")}  name="adresa_sidlo_ulica_cislo"    type="textbox"    value={props.lekar.adresa_sidlo_ulica_cislo}      onChange={handleInputChange} onBlur={validate}/>
                                <div className="form-error">
                                    {touched.adresa_sidlo_ulica_cislo&&props.lekar.errors['adresa_sidlo_ulica_cislo'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-9 col-xs-12">
                            <label htmlFor="adresa_sidlo_mesto">
                            <span className={"validation-icon " + (touched.adresa_sidlo_mesto&&props.lekar.errors.adresa_sidlo_mesto.length > 0 ? "is-invalid" : (touched.adresa_sidlo_mesto?"valid":""))}></span>
                                Mesto&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input "+ (touched.adresa_sidlo_mesto&&props.lekar.errors.adresa_sidlo_mesto.length > 0 ? "is-invalid" : "")}  name="adresa_sidlo_mesto"    type="textbox"    value={props.lekar.adresa_sidlo_mesto}      onChange={handleInputChange} onBlur={validate}/>
                                <div className="form-error">
                                    {touched.adresa_sidlo_mesto&&props.lekar.errors['adresa_sidlo_mesto'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xs-12">
                            <label htmlFor="adresa_sidlo_psc">
                            <span className={"validation-icon " + (touched.adresa_sidlo_psc&&props.lekar.errors.adresa_sidlo_psc.length > 0 ? "is-invalid" : (touched.adresa_sidlo_psc?"valid":""))}></span>                            PSČ&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input "+ (touched.adresa_sidlo_psc&&props.lekar.errors.adresa_sidlo_psc.length > 0 ? "is-invalid" : "")}  name="adresa_sidlo_psc"    type="textbox"    value={props.lekar.adresa_sidlo_psc}      onChange={handleInputChange} onBlur={validate}/>
                                <div className="form-error">
                                    {touched.adresa_sidlo_psc&&props.lekar.errors['adresa_sidlo_psc'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <label className="inline-checkbox">
                                <input  name="adresa_vysledky_ina"    type="checkbox"    checked={props.lekar.adresa_vysledky_ina}      onChange={handleInputChange} />
                                <span className="checkmark"></span>
                                <span className="text">Iná adresa pre doručenie výsledkov</span>
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div></div>
                        <div className={`col-sm-12 col-xs-12${props.lekar.adresa_vysledky_ina ? '' : ' skry'}`}>
                            <label htmlFor="adresa_vysledky_ulica_cislo">
                            <span className={"validation-icon " + (touched.adresa_vysledky_ulica_cislo&&props.lekar.errors.adresa_vysledky_ulica_cislo.length > 0 ? "is-invalid" : (touched.adresa_vysledky_ulica_cislo?"valid":""))}></span>
                                Ulica, číslo &nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input "+ (touched.adresa_vysledky_ulica_cislo&&props.lekar.errors.adresa_vysledky_ulica_cislo.length > 0 ? "is-invalid" : "")}  name="adresa_vysledky_ulica_cislo"    type="textbox"    value={props.lekar.adresa_vysledky_ulica_cislo}      onChange={handleInputChange} onBlur={validate}/>
                                <div className="form-error">
                                    {touched.adresa_vysledky_ulica_cislo&&props.lekar.errors['adresa_vysledky_ulica_cislo'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/*
                    <div className="col-sm-12 col-xs-12" styl={ props.lekar.adresa_vysledky_ina ? {} :{"display":"none"}}>
                        <label htmlFor="adresa_vysledky_ulica_cislo">
                            <span className="validation-icon"></span>
                            Ulica, číslo&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_ulica_cislo"    type="textbox"    value={props.lekar.adresa_vysledky_ulica_cislo}      onChange={handleInputChange} />
                        </div>
                    </div>
                    */}
                    {/*
                    <div className="col-sm-8 col-xs-12" styl={ props.lekar.adresa_vysledky_ina ? {} :{"display":"none"}}>
                        <label htmlFor="adresa_vysledky_ulica">
                            <span className="validation-icon"></span>
                            Ulica&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_ulica"    type="textbox"    value={props.lekar.adresa_vysledky_ulica}      onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className="col-sm-3 col-xs-12" styl={ props.lekar.adresa_vysledky_ina ? {} :{"display":"none"}}>
                        <label htmlFor="adresa_vysledky_cislo">
                            <span className="validation-icon"></span>
                            Číslo&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_cislo"    type="textbox"    value={props.lekar.adresa_vysledky_cislo}      onChange={handleInputChange} />
                        </div>
                    </div>
                    */}

                    <div className="row">
                        <div className={`col-sm-9 col-xs-12${props.lekar.adresa_vysledky_ina ? '' : ' skry'}`}>
                            <label htmlFor="adresa_vysledky_mesto">
                            <span className={"validation-icon " + (touched.adresa_vysledky_mesto&&props.lekar.errors.adresa_vysledky_mesto.length > 0 ? "is-invalid" : (touched.adresa_vysledky_mesto?"valid":""))}></span>
                                Mesto&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input "+ (touched.adresa_vysledky_mesto&&props.lekar.errors.adresa_vysledky_mesto.length > 0 ? "is-invalid" : "")}  name="adresa_vysledky_mesto"    type="textbox"    value={props.lekar.adresa_vysledky_mesto}      onChange={handleInputChange} onBlur={validate}/>
                                <div className="form-error">
                                    {touched.adresa_vysledky_mesto&&props.lekar.errors['adresa_vysledky_mesto'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`col-sm-3 col-xs-12${props.lekar.adresa_vysledky_ina ? '' : ' skry'}`}>
                            <label htmlFor="adresa_vysledky_psc">
                                <span className={"validation-icon " + (touched.adresa_vysledky_psc&&props.lekar.errors.adresa_vysledky_psc.length > 0 ? "is-invalid" : (touched.adresa_vysledky_psc?"valid":""))}></span>                            PSČ&nbsp;*
                            </label>
                            <div className ="input-box">
                                <input className={"input "+ (touched.adresa_vysledky_psc&&props.lekar.errors.adresa_vysledky_psc.length > 0 ? "is-invalid" : "")}  name="adresa_vysledky_psc"    type="textbox"    value={props.lekar.adresa_vysledky_psc}      onChange={handleInputChange} onBlur={validate}/>
                                <div className="form-error">
                                    {touched.adresa_vysledky_psc&&props.lekar.errors['adresa_vysledky_psc'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*
                    <div className="col-sm-8 col-xs-12" styl={ props.lekar.adresa_vysledky_ina ? {} :{"display":"none"}}>
                        <label htmlFor="adresa_vysledky_mesto">
                            <span className="validation-icon"></span>
                            Mesto&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_mesto"    type="textbox"    value={props.lekar.adresa_vysledky_mesto}      onChange={handleInputChange} />
                        </div>
                    </div>
                    */}
                    
                    
                    {/*}    
                    <div className="col-sm-3 col-xs-12" styl={ props.lekar.adresa_vysledky_ina ? {} :{"display":"none"}}>
                        <label htmlFor="adresa_vysledky_psc">
                            <span className="validation-icon"></span>
                            PSČ&nbsp;*
                        </label>
                        <div className ="input-box">
                            <input className="input"  name="adresa_vysledky_psc"    type="textbox"    value={props.lekar.adresa_vysledky_psc}      onChange={handleInputChange} />
                        </div>
                    </div>
                    */}

                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <label className="inline-checkbox">
                                <input  name="suhlas_vop"    type="checkbox"    checked={props.lekar.suhlas_vop}      onChange={handleInputChange} onBlur={validate}/>
                                <span className="checkmark"></span>
                                <span className="text"> Súhlasím so
                                <a href="https://static.medirex.sk/storage/app/media/Kontent%20pre%20lekarsku%20cast/Pre%20lekarov/Legislativa/V%C5%A1eobecn%C3%A9-Obchodn%C3%A9-Podmienky-Lek%C3%A1ri.pdf" target="_blank" rel="noreferrer"> všeobecnými obchodnými podmienkami</a>
                                &nbsp;*</span>
                            </label>
                            <div className="form-error">
                                    {touched.suhlas_vop&&props.lekar.errors['suhlas_vop'].map((value, i) =>
                                        <span key={i}>{value}</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-error align-left col-sm-12 ">
                            <span>{ (props.stepValid===false&&valid()===false ? "Niektoré polia nie sú vyplnené alebo sú vyplnené chybným spôsobom. Prosím, skontrolujte si ich." : "") }</span>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default connect (mapStateToProps)(Lekar);
