import React, {useEffect, useState} from 'react';
import { connect,Redirect } from 'react-redux';
//import { useHistory } from 'react-router-dom';

const mapStateToProps = (state) => {
    return { state };
};

let errMsg = null;

function ErrorPage(props) {

    const [isLoaded, setIsLoaded] = useState(false);

    function queryParams(){
        if (isLoaded === false) {
          const queryParams = new URLSearchParams(window.location.search);
          if (queryParams) {
            setIsLoaded(true);
            test(queryParams);
          }
        }
      }

    //const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    document.addEventListener("DOMContentLoaded", function(event) { 
        setTimeout(()=>{
            window.close();
            //window.location.replace(`${config.appUrl}`);
        },3000);
    });

    const test = (queryParams) => {
        if(queryParams.get("id") === '-1000'){
            errMsg = "Nastala interná chyba spracovania."
        }
        else if(queryParams.get("id") === '-1001'){
            errMsg = "Platnosť relácie vypršala."
        }
        else if(queryParams.get("id") === '-1002'){
            errMsg = "Neexistujúca kombinácia A kód – P kód."
        }
        else if(queryParams.get("id") === '-1003'){
            errMsg = "Nie je možné priradiť pacienta."
        }
        else {
            errMsg = "Nastala neočakávaná chyba."
        }
    }

    queryParams();

    return (
        <div>
            <header className="header-cart--menu thank-you-header">
            </header>
            <div className="thank-you">
                <div className="inner">
                    <h1 className="headline">Nastala chyba!</h1>
                    <h2>{errMsg}</h2>
                    <p>Stránka sa automaticky zatvorí za 3 sekundy!</p>
                </div>
            </div>
        </div>

    );
}

export default connect(mapStateToProps)(ErrorPage);