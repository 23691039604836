const mkch10 = [{"code":"A00-B99","desc":"I. kapitola - Infekčné a parazitové choroby (A00-B99)"},
{"code":"A00-A09","desc":"Črevné infekčné choroby"},
{"code":"A00.-","desc":"Cholera"},
{"code":"A00.0","desc":"Cholera zapríčinená Vibrio cholerae 01, biovar cholerae"},
{"code":"A00.1","desc":"Cholera zapríčinená Vibrio cholerae 01, biovar eltor"},
{"code":"A00.9","desc":"Cholera, bližšie neurčená"},
{"code":"A01.-","desc":"Brušný týfus a paratýfus"},
{"code":"A01.0","desc":"Brušný týfus"},
{"code":"A01.1","desc":"Paratýfus A"},
{"code":"A01.2","desc":"Paratýfus B"},
{"code":"A01.3","desc":"Paratýfus C"},
{"code":"A01.4","desc":"Paratýfus, bližšie neurčený"},
{"code":"A02.-","desc":"Iné salmonelové infekcie"},
{"code":"A02.0","desc":"Salmonelová enteritída"},
{"code":"A02.1","desc":"Salmonelová sepsa"},
{"code":"A02.2","desc":"Lokalizovaná salmonelová infekcia"},
{"code":"A02.8","desc":"Iná salmonelová infekcia, bližšie určená"},
{"code":"A02.9","desc":"Salmonelová infekcia, bližšie neurčená"},
{"code":"A03.-","desc":"Šigelóza [baktériová červienka, dyzentéria]"},
{"code":"A03.0","desc":"Šigelóza zapríčinená Shigella dysenteriae"},
{"code":"A03.1","desc":"Šigelóza zapríčinená Shigella flexneri"},
{"code":"A03.2","desc":"Šigelóza zapríčinená Shigella boydii"},
{"code":"A03.3","desc":"Šigelóza zapríčinená Shigella sonnei"},
{"code":"A03.8","desc":"Iná šigelóza"},
{"code":"A03.9","desc":"Šigelóza, bližšie neurčená"},
{"code":"A04.-","desc":"Iné baktériové črevné infekcie"},
{"code":"A04.0","desc":"Infekcia zapríčinená enteropatogénnou Escherichia coli"},
{"code":"A04.1","desc":"Infekcia zapríčinená enterotoxikogénnou Escherichia coli"},
{"code":"A04.2","desc":"Infekcia zapríčinená enteroinvazívnou Escherichia coli"},
{"code":"A04.3","desc":"Infekcia zapríčinená enterohemoragickou Escherichia coli"},
{"code":"A04.4","desc":"Iné črevné infekcie zapríčinené Escherichia coli"},
{"code":"A04.5","desc":"Enteritída zapríčinená kampylobakterom"},
{"code":"A04.6","desc":"Enteritída zapríčinená Yersinia enterocolitica"},
{"code":"A04.7","desc":"Enterokolitída zapríčinená Clostridium difficile"},
{"code":"A04.8-","desc":"Iná baktériová črevná infekcia, bližšie určená"},
{"code":"A04.81","desc":"Enteritída zapríčinená Arcobacter spp."},
{"code":"A04.89","desc":"Iná baktériová črevná infekcia, bližšie určená"},
{"code":"A04.9","desc":"Baktériová črevná infekcia, bližšie neurčená"},
{"code":"A05.-","desc":"Iná baktériová potravinová otrava, nezatriedená inde"},
{"code":"A05.0","desc":"Potravinová otrava zapríčinená stafylokokmi"},
{"code":"A05.1","desc":"Botulizmus"},
{"code":"A05.2","desc":"Potravinová otrava zapríčinená Clostridium perfringens (Clostridium welchii)"},
{"code":"A05.3","desc":"Potravinová otrava zapríčinená Vibrio parahaemolyticus"},
{"code":"A05.4","desc":"Potravinová otrava zapríčinená Bacillus cereus"},
{"code":"A05.8","desc":"Iná bližšie určená baktériová potravinová otrava"},
{"code":"A05.9","desc":"Bližšie neurčená baktériová potravinová otrava"},
{"code":"A06.-","desc":"Amébová červienka - amebóza"},
{"code":"A06.0","desc":"Akútna amébová dyzentéria"},
{"code":"A06.1","desc":"Chronická črevná amebóza"},
{"code":"A06.2","desc":"Amébová nedyzenterická kolitída"},
{"code":"A06.3","desc":"Črevný amebóm"},
{"code":"A06.4","desc":"Amébový absces pečene"},
{"code":"A06.5†","desc":"Amébový absces pľúc (J99.8*)"},
{"code":"A06.6†","desc":"Amébový absces mozgu (G07*)"},
{"code":"A06.7","desc":"Kožná amebóza"},
{"code":"A06.8","desc":"Amébová infekcia na iných miestach"},
{"code":"A06.9","desc":"Bližšie neurčená amebóza"},
{"code":"A07.-","desc":"Iné protozoárne črevné choroby"},
{"code":"A07.0","desc":"Balantidióza"},
{"code":"A07.1","desc":"Giardióza [lambliáza]"},
{"code":"A07.2","desc":"Kryptosporidióza"},
{"code":"A07.3","desc":"Izosporióza"},
{"code":"A07.8","desc":"Iná protozoárna črevná choroba, bližšie určená"},
{"code":"A07.9","desc":"Protozoárna črevná choroba, bližšie neurčená"},
{"code":"A08.-","desc":"Vírusové a iné, bližšie určené črevné infekcie"},
{"code":"A08.0","desc":"Rotavírusová enteritída"},
{"code":"A08.1","desc":"Akútna gastroenteritída zapríčinená norovírusom [Norwalk]"},
{"code":"A08.2","desc":"Adenovírusová enteritída"},
{"code":"A08.3","desc":"Iná vírusová enteritída"},
{"code":"A08.4","desc":"Vírusová črevná infekcia, bližšie neurčená"},
{"code":"A08.5","desc":"Iné, bližšie určené črevné infekcie"},
{"code":"A09.-","desc":"Iná gastroenteritída a kolitída infekčného a bližšie neurčeného pôvodu"},
{"code":"A09.0","desc":"Iná a bližšie neurčená gastroenteritída a kolitída infekčného pôvodu"},
{"code":"A09.9","desc":"Iná a bližšie neurčená gastroenteritída a kolitída bližšie neurčeného pôvodu"},
{"code":"A15-A19","desc":"Tuberkulóza"},
{"code":"A15.-","desc":"Tuberkulóza dýchacej sústavy bakteriologicky, molekulovobiologicky alebo histologicky potvrdená"},
{"code":"A15.0","desc":"Tuberkulóza pľúc, potvrdená mikroskopickým vyšetrením spúta s dôkazom, alebo bez dôkazu kultivačne alebo molekulovobiologicky"},
{"code":"A15.1","desc":"Tuberkulóza pľúc, potvrdená len kultivačne"},
{"code":"A15.2","desc":"Tuberkulóza pľúc, potvrdená histologicky"},
{"code":"A15.3","desc":"Tuberkulóza pľúc, potvrdená inými a bližšie neurčenými metódami"},
{"code":"A15.4","desc":"Tuberkulóza vnútrohrudníkových lymfatických uzlín, potvrdená bakteriologicky, molekulovobiologicky alebo histologicky"},
{"code":"A15.5","desc":"Tuberkulóza hrtana, priedušnice a priedušiek, potvrdená bakteriologicky, molekulovobiologicky alebo histologicky"},
{"code":"A15.6","desc":"Tuberkulózna pleuritída, potvrdená bakteriologicky, molekulovobiologicky alebo histologicky"},
{"code":"A15.7","desc":"Primárna tuberkulóza dýchacej sústavy, potvrdená bakteriologicky, molekulovobiologicky alebo histologicky"},
{"code":"A15.8","desc":"Iná tuberkulóza dýchacej sústavy, potvrdená bakteriologicky, molekulovobiologicky alebo histologicky"},
{"code":"A15.9","desc":"Tuberkulóza dýchacej sústavy, potvrdená bakteriologicky, molekulovobiologicky alebo histologicky, bližšie neurčená"},
{"code":"A16.-","desc":"Tuberkulóza dýchacej sústavy nepotvrdená bakteriologicky, molekulovobiologicky alebo histologicky"},
{"code":"A16.0","desc":"Tuberkulóza pľúc bakteriologicky, molekulovobiologicky alebo histologicky negatívna"},
{"code":"A16.1","desc":"Tuberkulóza pľúc, bez vykonania bakteriologického, molekulovobiologického a histologického vyšetrenia"},
{"code":"A16.2","desc":"Tuberkulóza pľúc, bez údaja o bakteriologickom, molekulovobiologickom alebo histologickom potvrdení"},
{"code":"A16.3","desc":"Tuberkulóza vnútrohrudníkových lymfatických uzlín, bez údaja o bakteriologickom, molekulovobiologickom alebo histologickom potvrdení"},
{"code":"A16.4","desc":"Tuberkulóza hrtana, priedušnice a priedušiek bez údaja o bakteriologickom, molekulovobiologickom alebo histologickom potvrdení"},
{"code":"A16.5","desc":"Tuberkulózna pleuritída, bez údaja o bakteriologickom, molekulovobiologickom alebo histologickom potvrdení"},
{"code":"A16.6","desc":"Latentná turberkulózna infekcia"},
{"code":"A16.7","desc":"Primárna tuberkulóza dýchacej sústavy, bez údaja o bakteriologickom, molekulovobiologickom alebo histologickom potvrdení"},
{"code":"A16.8","desc":"Iná tuberkulóza dýchacej sústavy, bez údaja o bakteriologickom, molekulovobiologickom alebo histologickom potvrdení"},
{"code":"A16.9","desc":"Tuberkulóza dýchacej sústavy, bez údaja o bakteriologickom, molekulovobiologickom alebo histologickom potvrdení, bližšie neurčená"},
{"code":"A17.-†","desc":"Tuberkulóza nervovej sústavy"},
{"code":"A17.0†","desc":"Tuberkulózna meningitída (G01*)"},
{"code":"A17.1†","desc":"Meningový tuberkulóm (G07*)"},
{"code":"A17.8†","desc":"Iná tuberkulóza nervovej sústavy"},
{"code":"A17.9†","desc":"Tuberkulóza nervovej sústavy, bližšie neurčená"},
{"code":"A18.-","desc":"Tuberkulóza iných orgánov"},
{"code":"A18.0†","desc":"Tuberkulóza kostí a kĺbov"},
{"code":"A18.1","desc":"Tuberkulóza močovopohlavnej sústavy"},
{"code":"A18.2","desc":"Tuberkulózna periférna lymfadenopatia"},
{"code":"A18.3","desc":"Tuberkulóza čriev, peritonea a mezentériových lymfatických uzlín"},
{"code":"A18.4","desc":"Tuberkulóza kože a podkožného tkaniva"},
{"code":"A18.5","desc":"Tuberkulóza oka"},
{"code":"A18.6","desc":"Tuberkulóza ucha"},
{"code":"A18.7†","desc":"Tuberkulóza nadobličiek (E35.1*)"},
{"code":"A18.8","desc":"Tuberkulóza iných bližšie určených orgánov"},
{"code":"A19.-","desc":"Miliárna tuberkulóza"},
{"code":"A19.0","desc":"Akútna miliárna tuberkulóza na jedinom bližšie určenom mieste"},
{"code":"A19.1","desc":"Akútna miliárna tuberkulóza na viacerých miestach"},
{"code":"A19.2","desc":"Akútna miliárna tuberkulóza, bližšie neurčená"},
{"code":"A19.8","desc":"Iná miliárna tuberkulóza"},
{"code":"A19.9","desc":"Miliárna tuberkulóza, bližšie neurčená"},
{"code":"A20-A28","desc":"Baktériové zoonózy"},
{"code":"A20.-","desc":"Mor"},
{"code":"A20.0","desc":"Bubonický mor"},
{"code":"A20.1","desc":"Kožná forma moru"},
{"code":"A20.2","desc":"Pľúcna forma moru"},
{"code":"A20.3","desc":"Morová meningitída"},
{"code":"A20.7","desc":"Septikemická forma moru"},
{"code":"A20.8","desc":"Iná forma moru"},
{"code":"A20.9","desc":"Bližšie neurčený mor"},
{"code":"A21.-","desc":"Tularémia"},
{"code":"A21.0","desc":"Ulceroglandulárna tularémia"},
{"code":"A21.1","desc":"Okuloglandulárna tularémia"},
{"code":"A21.2","desc":"Pľúcna tularémia"},
{"code":"A21.3","desc":"Gastrointestinálna tularémia"},
{"code":"A21.7","desc":"Generalizovaná tularémia"},
{"code":"A21.8-","desc":"Iná forma tularémie"},
{"code":"A21.81","desc":"Glandulárna tularémia"},
{"code":"A21.82","desc":"Orofaryngeálna tularémia"},
{"code":"A21.89","desc":"Iná forma tularémie"},
{"code":"A21.9","desc":"Tularémia, bližšie neurčená"},
{"code":"A22.-","desc":"Antrax"},
{"code":"A22.0","desc":"Kožný antrax"},
{"code":"A22.1","desc":"Pľúcny antrax"},
{"code":"A22.2","desc":"Gastrointestinálny antrax"},
{"code":"A22.7","desc":"Antraxová sepsa"},
{"code":"A22.8","desc":"Iná forma antraxu"},
{"code":"A22.9","desc":"Bližšie neurčený antrax"},
{"code":"A23.-","desc":"Brucelóza"},
{"code":"A23.0","desc":"Brucelóza zapríčinená Brucella melitensis"},
{"code":"A23.1","desc":"Brucelóza zapríčinená Brucella abortus"},
{"code":"A23.2","desc":"Brucelóza zapríčinená Brucella suis"},
{"code":"A23.3","desc":"Brucelóza zapríčinená Brucella canis"},
{"code":"A23.8","desc":"Iná brucelóza"},
{"code":"A23.9","desc":"Brucelóza, bližšie neurčená"},
{"code":"A24.-","desc":"Sopľavka [malleus] a melioidóza [pseudomalleus]"},
{"code":"A24.0","desc":"Sopľavka"},
{"code":"A24.1","desc":"Akútna alebo fulminantná melioidóza"},
{"code":"A24.2","desc":"Subakútna a chronická melioidóza"},
{"code":"A24.3","desc":"Iná melioidóza"},
{"code":"A24.4","desc":"Melioidóza, bližšie neurčená"},
{"code":"A25.-","desc":"Horúčka po uhryznutí potkanom"},
{"code":"A25.0","desc":"Spirilóza"},
{"code":"A25.1","desc":"Streptobacilóza"},
{"code":"A25.9","desc":"Horúčka po uhryznutí potkanom, bližšie neurčená"},
{"code":"A26.-","desc":"Eryzipeloid"},
{"code":"A26.0","desc":"Kožný eryzipeloid"},
{"code":"A26.7","desc":"Eryzipelotrixová sepsa"},
{"code":"A26.8","desc":"Iná forma eryzipeloidu"},
{"code":"A26.9","desc":"Bližšie neurčený eryzipeloid"},
{"code":"A27.-","desc":"Leptospiróza"},
{"code":"A27.0","desc":"Leptospiróza zapríčinená Leptospira icterohaemorrhagiae [Weilova choroba]"},
{"code":"A27.8","desc":"Iná forma leptospirózy"},
{"code":"A27.9","desc":"Leptospiróza, bližšie neurčená"},
{"code":"A28.-","desc":"Iná baktériová zoonóza, nezatriedená inde"},
{"code":"A28.0","desc":"Pasteurelóza"},
{"code":"A28.1","desc":"Choroba z mačacieho škrabnutia"},
{"code":"A28.2","desc":"Extraintestinálna yersinióza"},
{"code":"A28.8","desc":"Iná baktériová zoonóza, bližšie určená, nezatriedená inde"},
{"code":"A28.9","desc":"Baktériová zoonóza, bližšie neurčená"},
{"code":"A30-A49","desc":"Iné baktériové choroby"},
{"code":"A30.-","desc":"Lepra [malomocenstvo, Hansenova choroba]"},
{"code":"A30.0","desc":"Neurčená [indeterminate] lepra"},
{"code":"A30.1","desc":"Tuberkuloidná lepra"},
{"code":"A30.2","desc":"Hraničná [borderline] tuberkuloidná lepra"},
{"code":"A30.3","desc":"Hraničná [borderline] lepra"},
{"code":"A30.4","desc":"Hraničná [borderline] lepromatózna lepra"},
{"code":"A30.5","desc":"Lepromatózna lepra"},
{"code":"A30.8","desc":"Iná forma lepry"},
{"code":"A30.9","desc":"Lepra, bližšie neurčená"},
{"code":"A31.-","desc":"Infekcia zapríčinená inou mykobaktériou"},
{"code":"A31.0","desc":"Pľúcna mykobakterióza"},
{"code":"A31.1","desc":"Kožná mykobakterióza"},
{"code":"A31.8-","desc":"Iné infekcie zapríčinené mykobaktériou"},
{"code":"A31.80","desc":"Diseminovaná atypická mykobakterióza"},
{"code":"A31.88","desc":"Iná infekcia zapríčinená mykobaktériou"},
{"code":"A31.9","desc":"Mykobaktériová infekcia, bližšie neurčená"},
{"code":"A32.-","desc":"Listerióza"},
{"code":"A32.0","desc":"Kožná listerióza"},
{"code":"A32.1†","desc":"Listériová meningitída a meningoencefalitída"},
{"code":"A32.7","desc":"Listériová sepsa"},
{"code":"A32.8","desc":"Iná forma listeriózy"},
{"code":"A32.9","desc":"Listerióza, bližšie neurčená"},
{"code":"A33","desc":"Tetanus novorodencov"},
{"code":"A34","desc":"Tetanus počas gravidity, pôrodu a v šestonedelí"},
{"code":"A35","desc":"Iný tetanus"},
{"code":"A36.-","desc":"Diftéria [ Záškrt ]"},
{"code":"A36.0","desc":"Hltanová diftéria"},
{"code":"A36.1","desc":"Nosohltanová diftéria"},
{"code":"A36.2","desc":"Hrtanová diftéria"},
{"code":"A36.3","desc":"Kožná diftéria"},
{"code":"A36.8","desc":"Iná diftéria"},
{"code":"A36.9","desc":"Bližšie neurčená diftéria"},
{"code":"A37.-","desc":"Pertussis [Čierny kašeľ, Divý kašeľ]"},
{"code":"A37.0","desc":"Pertussis zapríčinený Bordetella pertussis"},
{"code":"A37.1","desc":"Pertussis zapríčinený Bordetella parapertussis"},
{"code":"A37.8","desc":"Pertussis zapríčinený iným druhom bordetely"},
{"code":"A37.9","desc":"Pertussis, bližšie neurčený"},
{"code":"A38","desc":"Scarlatina [Šarlach]"},
{"code":"A39.-","desc":"Meningokoková infekcia"},
{"code":"A39.0†","desc":"Meningokoková meningitída (G01*)"},
{"code":"A39.1†","desc":"Waterhouseov-Friderichsenov syndróm (E35.1*)"},
{"code":"A39.2","desc":"Akútna meningokoková sepsa"},
{"code":"A39.3","desc":"Chronická meningokoková sepsa"},
{"code":"A39.4","desc":"Meningokoková sepsa, bližšie neurčená"},
{"code":"A39.5†","desc":"Meningokokové ochorenie srdca"},
{"code":"A39.8","desc":"Iná meningokoková infekcia"},
{"code":"A39.9","desc":"Meningokoková infekcia, bližšie neurčená"},
{"code":"A40.-","desc":"Streptokoková sepsa"},
{"code":"A40.0","desc":"Sepsa zapríčinená streptokokom zo skupiny A"},
{"code":"A40.1","desc":"Sepsa zapríčinená streptokokom zo skupiny B"},
{"code":"A40.2","desc":"Sepsa zapríčinená streptokokom zo skupiny D"},
{"code":"A40.3","desc":"Sepsa zapríčinená Streptococcus pneumoniae"},
{"code":"A40.8-","desc":"Iná streptokoková sepsa"},
{"code":"A40.81","desc":"Sepsa zapríčinená streptokokom zo skupiny C"},
{"code":"A40.89","desc":"Iná streptokoková sepsa"},
{"code":"A40.9","desc":"Sepsa zapríčinená streptokokmi, bližšie neurčená"},
{"code":"A41.-","desc":"Iná sepsa"},
{"code":"A41.0","desc":"Sepsa zapríčinená Staphylococcus aureus"},
{"code":"A41.1","desc":"Sepsa zapríčinená iným bližšie určeným stafylokokom"},
{"code":"A41.2","desc":"Sepsa zapríčinená bližšie neurčeným stafylokokom"},
{"code":"A41.3","desc":"Sepsa zapríčinená Haemophilus influenzae"},
{"code":"A41.4","desc":"Sepsa zapríčinená anaeróbnou baktériou"},
{"code":"A41.5-","desc":"Sepsa zapríčinená inou gramnegatívnou baktériou"},
{"code":"A41.51","desc":"Sepsa zapríčinená Escherichia coli [E. coli]"},
{"code":"A41.52","desc":"Sepsa zapríčinená pseudomonas"},
{"code":"A41.53","desc":"Sepsa zapríčinená multirezistentnou gramnegatívnou baktériou"},
{"code":"A41.58","desc":"Sepsa zapríčinená inou gramnegatívnou baktériou"},
{"code":"A41.8-","desc":"Iná sepsa, bližšie určená"},
{"code":"A41.81","desc":"Sepsa zapríčinená multirezistentnou grampozitívnou baktériou"},
{"code":"A41.89","desc":"Iná sepsa, bližšie určená"},
{"code":"A41.9","desc":"Sepsa, bližšie neurčená"},
{"code":"A42.-","desc":"Aktinomykóza"},
{"code":"A42.0","desc":"Pľúcna aktinomykóza"},
{"code":"A42.1","desc":"Brušná aktinomykóza"},
{"code":"A42.2","desc":"Cervikofaciálna aktinomykóza"},
{"code":"A42.7","desc":"Aktinomykotická sepsa"},
{"code":"A42.8","desc":"Iná forma aktinomykózy"},
{"code":"A42.9","desc":"Aktinomykóza, bližšie neurčená"},
{"code":"A43.-","desc":"Nokardióza"},
{"code":"A43.0","desc":"Pľúcna nokardióza"},
{"code":"A43.1","desc":"Kožná nokardióza"},
{"code":"A43.8","desc":"Iná forma nokardiózy"},
{"code":"A43.9","desc":"Bližšie neurčená nokardióza"},
{"code":"A44.-","desc":"Bartonelóza"},
{"code":"A44.0","desc":"Systémová bartonelóza"},
{"code":"A44.1","desc":"Kožná a mukokutánna bartonelóza"},
{"code":"A44.8","desc":"Iná forma bartonelózy"},
{"code":"A44.9","desc":"Bartonelóza, bližšie neurčená"},
{"code":"A46","desc":"Eryzipel [Erysipelas, Ruža]"},
{"code":"A48.-","desc":"Iná baktériová choroba, nezatriedená inde"},
{"code":"A48.0","desc":"Plynová gangréna"},
{"code":"A48.1","desc":"Legionelóza s pneumóniou [Legionárska choroba]"},
{"code":"A48.2","desc":"Legionelóza bez pneumónie [Pontiacka horúčka]"},
{"code":"A48.3","desc":"Syndróm toxického šoku"},
{"code":"A48.4","desc":"Brazílska purpurová horúčka"},
{"code":"A48.8","desc":"Iná baktériová choroba, bližšie určená"},
{"code":"A49.-","desc":"Baktériová infekcia bez bližšieho určenia miesta"},
{"code":"A49.0","desc":"Stafylokoková infekcia bez bližšieho určenia miesta"},
{"code":"A49.1","desc":"Streptokoková infekcia bez bližšieho určenia miesta"},
{"code":"A49.2","desc":"Infekcia haemophilus influenzae bez bližšieho určenia miesta"},
{"code":"A49.3","desc":"Mykoplazmová infekcia bez bližšieho určenia miesta"},
{"code":"A49.8","desc":"Iná baktériová infekcia bez bližšieho určenia miesta"},
{"code":"A49.9","desc":"Bližšie neurčená baktériová infekcia, bez bližšieho určenia miesta"},
{"code":"A50-A64","desc":"Infekcie s prevažne pohlavným prenosom"},
{"code":"A50.-","desc":"Vrodený syfilis [Lues congenita]"},
{"code":"A50.0","desc":"Včasný vrodený syfilis, symptomatický"},
{"code":"A50.1","desc":"Včasný vrodený syfilis, latentný"},
{"code":"A50.2","desc":"Včasný vrodený syfilis, bližšie neurčený"},
{"code":"A50.3","desc":"Neskorá vrodená syfilitická okulopatia"},
{"code":"A50.4","desc":"Neskorý vrodený neurosyfilis [Juvenilný neurosyfilis]"},
{"code":"A50.5","desc":"Iný neskorý vrodený syfilis, symptomatický"},
{"code":"A50.6","desc":"Neskorý vrodený syfilis, latentný"},
{"code":"A50.7","desc":"Neskorý vrodený syfilis, bližšie neurčený"},
{"code":"A50.9","desc":"Vrodený syfilis, bližšie neurčený"},
{"code":"A51.-","desc":"Včasný syfilis"},
{"code":"A51.0","desc":"Primárny genitálny syfilis"},
{"code":"A51.1","desc":"Primárny análny syfilis"},
{"code":"A51.2","desc":"Primárny syfilis na inom mieste"},
{"code":"A51.3","desc":"Sekundárny syfilis kože a slizníc"},
{"code":"A51.4","desc":"Iná forma sekundárneho syfilisu"},
{"code":"A51.5","desc":"Včasný syfilis, latentný"},
{"code":"A51.9","desc":"Včasný syfilis, bližšie neurčený"},
{"code":"A52.-","desc":"Neskorý syfilis"},
{"code":"A52.0†","desc":"Kardiovaskulárny syfilis"},
{"code":"A52.1","desc":"Neurosyfilis, symptomatický"},
{"code":"A52.2","desc":"Neurosyfilis, asymptomatický"},
{"code":"A52.3","desc":"Neurosyfilis, bližšie neurčený"},
{"code":"A52.7","desc":"Neskorý syfilis, symptomatický, iná forma"},
{"code":"A52.8","desc":"Neskorý syfilis, latentný"},
{"code":"A52.9","desc":"Neskorý syfilis, bližšie neurčený"},
{"code":"A53.-","desc":"Iný a bližšie neurčený syfilis"},
{"code":"A53.0","desc":"Latentný syfilis, bližšie neurčený ako včasný alebo neskorý"},
{"code":"A53.9","desc":"Syfilis, bližšie neurčený"},
{"code":"A54.-","desc":"Gonokoková infekcia"},
{"code":"A54.0","desc":"Gonokoková infekcia dolných častí močovopohlavnej sústavy bez abscesu uretrových alebo predsieňových žliaz"},
{"code":"A54.1","desc":"Gonokoková infekcia dolných častí močovopohlavnej sústavy s abscesom uretrových a predsieňových žliaz"},
{"code":"A54.2","desc":"Gonokoková pelveoperitonitída a iná gonokoková močovopohlavná infekcia"},
{"code":"A54.3","desc":"Gonokoková infekcia oka"},
{"code":"A54.4†","desc":"Gonokoková infekcia svalovo-kostrovej sústavy"},
{"code":"A54.5","desc":"Gonokoková faryngitída"},
{"code":"A54.6","desc":"Gonokoková infekcia anusu a rekta"},
{"code":"A54.8","desc":"Iná gonokoková infekcia"},
{"code":"A54.9","desc":"Gonokoková infekcia, bližšie neurčená"},
{"code":"A55","desc":"Lymphogranuloma venereum (chlamýdiový)"},
{"code":"A56.-","desc":"Iná pohlavne prenosná chlamýdiová choroba"},
{"code":"A56.0","desc":"Chlamýdiová infekcia dolných častí močovopohlavnej sústavy"},
{"code":"A56.1","desc":"Chlamýdiová infekcia panvovej pobrušnice a močovopohlavných orgánov"},
{"code":"A56.2","desc":"Chlamýdiová infekcia močovopohlavnej sústavy, bližšie neurčená"},
{"code":"A56.3","desc":"Chlamýdiová infekcia anusu a rekta"},
{"code":"A56.4","desc":"Chlamýdiová infekcia faryngu"},
{"code":"A56.8","desc":"Pohlavne prenosná chlamýdiová infekcia na iných miestach"},
{"code":"A57","desc":"Mäkký vred [ulcus molle]"},
{"code":"A58","desc":"Granuloma venereum (inguinale)"},
{"code":"A59.-","desc":"Trichomonóza"},
{"code":"A59.0","desc":"Urogenitálna trichomonóza"},
{"code":"A59.8","desc":"Trichomonóza na iných miestach"},
{"code":"A59.9","desc":"Trichomonóza, bližšie neurčená"},
{"code":"A60.-","desc":"Anogenitálna infekcia herpetickým vírusom [Herpes simplex]"},
{"code":"A60.0","desc":"Infekcia genitálií a močovopohlavnej sústavy zapríčinená herpetickým vírusom"},
{"code":"A60.1","desc":"Infekcia perianálnej kože a rekta zapríčinená herpetickým vírusom"},
{"code":"A60.9","desc":"Anogenitálna infekcia zapríčinená herpetickým vírusom, bližšie neurčená"},
{"code":"A63.-","desc":"Iná prevažne pohlavne prenosná choroba, nezatriedená inde"},
{"code":"A63.0","desc":"Anogenitálne (venerické) bradavice"},
{"code":"A63.8","desc":"Iná prevažne pohlavne prenosná choroba, bližšie určená"},
{"code":"A64","desc":"Pohlavne prenosná choroba, bližšie neurčená"},
{"code":"A65-A69","desc":"Iné spirochétové choroby"},
{"code":"A65","desc":"Nevenerický syfilis"},
{"code":"A66.-","desc":"Frambézia"},
{"code":"A66.0","desc":"Primárna lézia pri frambézii"},
{"code":"A66.1","desc":"Mnohopočetné papilómy a vlhká plazivá frambézia"},
{"code":"A66.2","desc":"Iné počiatočné lézie kože pri frambézii"},
{"code":"A66.3","desc":"Hyperkeratóza pri frambézii"},
{"code":"A66.4","desc":"Gumy a vredy pri frambézii"},
{"code":"A66.5","desc":"Gangóza"},
{"code":"A66.6","desc":"Kostné a kĺbové lézie pri frambézii"},
{"code":"A66.7","desc":"Iné prejavy frambézie"},
{"code":"A66.8","desc":"Latentná frambézia"},
{"code":"A66.9","desc":"Bližšie neurčená frambézia"},
{"code":"A67.-","desc":"Pinta [carate]"},
{"code":"A67.0","desc":"Pinta, primárne lézie"},
{"code":"A67.1","desc":"Pinta, stredne pokročilé lézie"},
{"code":"A67.2","desc":"Pinta, neskoré lézie"},
{"code":"A67.3","desc":"Pinta, zmiešané lézie"},
{"code":"A67.9","desc":"Pinta, bližšie neurčená"},
{"code":"A68.-","desc":"Návratná horúčka"},
{"code":"A68.0","desc":"Návratná horúčka prenášaná všami"},
{"code":"A68.1","desc":"Návratná horúčka prenášaná kliešťami"},
{"code":"A68.9","desc":"Návratná horúčka, bližšie neurčená"},
{"code":"A69.-","desc":"Iná infekcia zapríčinená spirochétami"},
{"code":"A69.0","desc":"Nekrotizujúca ulcerózna stomatitída"},
{"code":"A69.1","desc":"Iná fusospirochetóza"},
{"code":"A69.2-","desc":"Lymská choroba"},
{"code":"A69.21","desc":"Lymská choroba - včasná diseminovaná infekcia, kožná forma"},
{"code":"A69.22","desc":"Lymská choroba - včasná neuroborelióza"},
{"code":"A69.23","desc":"Lymská karditída"},
{"code":"A69.24","desc":"Lymská artritída"},
{"code":"A69.25","desc":"Lymská choroba - očná forma"},
{"code":"A69.26","desc":"Lymská choroba - chronická neuroborelióza"},
{"code":"A69.27","desc":"Lymská choroba - Acrodermatitis chronica atroficans"},
{"code":"A69.29","desc":"Iná Lymská choroba"},
{"code":"A69.8","desc":"Iná infekcia zapríčinená spirochétami, bližšie určená"},
{"code":"A69.9","desc":"Infekcia zapríčinená spirochétami, bližšie neurčená"},
{"code":"A70-A74","desc":"Iné choroby, zapríčinené chlamýdiami"},
{"code":"A70","desc":"Infekcia zapríčinená Chlamydia psittaci"},
{"code":"A71.-","desc":"Trachóm"},
{"code":"A71.0","desc":"Trachóm, začiatočné štádium"},
{"code":"A71.1","desc":"Trachóm, aktívne štádium"},
{"code":"A71.9","desc":"Trachóm, bližšie neurčený"},
{"code":"A74.-","desc":"Iná choroba zapríčinená chlamýdiami"},
{"code":"A74.0†","desc":"Chlamýdiový zápal spojoviek"},
{"code":"A74.8-","desc":"Iná chlamýdiová choroba"},
{"code":"A74.81","desc":"Chlamýdiová dilatačná kardiomyopatia"},
{"code":"A74.82","desc":"Chlamýdiová myokarditída"},
{"code":"A74.83","desc":"Chlamýdiová endokarditída"},
{"code":"A74.84","desc":"Chlamýdiová artritída"},
{"code":"A74.89","desc":"Iná chlamýdiová choroba"},
{"code":"A74.9","desc":"Chlamýdiová infekcia, bližšie neurčená"},
{"code":"A75-A79","desc":"Riketsiózy"},
{"code":"A75.-","desc":"Škvrnitý týfus"},
{"code":"A75.0","desc":"Škvrnitý týfus prenášaný všami zapríčinený Rickettsia prowazeki"},
{"code":"A75.1","desc":"Návratný škvrnitý týfus [Brillova choroba]"},
{"code":"A75.2","desc":"Škvrnitý týfus zapríčinený Rickettsia typhi [Riketsia mooseri]"},
{"code":"A75.3","desc":"Škvrnitý týfus zapríčinený Rickettsia tsutsugamushi [Rickettsia orientalis]"},
{"code":"A75.9","desc":"Škvrnitý týfus, bližšie neurčený"},
{"code":"A77.-","desc":"Škvrnité horúčky [Riketsiózy prenášané kliešťami]"},
{"code":"A77.0","desc":"Škvrnitá horúčka zapríčinená Rickettsia rickettsii"},
{"code":"A77.1","desc":"Škvrnitá horúčka zapríčinená Rickettsia conorii"},
{"code":"A77.2","desc":"Škvrnitá horúčka zapríčinená Rickettsia sibirica"},
{"code":"A77.3","desc":"Škvrnitá horúčka zapríčinená Rickettsia australis"},
{"code":"A77.8","desc":"Iná škvrnitá horúčka"},
{"code":"A77.9","desc":"Škvrnitá horúčka, bližšie neurčená"},
{"code":"A78","desc":"Q-horúčka"},
{"code":"A79.-","desc":"Iné riketsiózy"},
{"code":"A79.0","desc":"Volynská (zákopová) horúčka"},
{"code":"A79.1","desc":"Riketsiové kiahne zapríčinené Rickettsia akari"},
{"code":"A79.8","desc":"Iná riketsióza, bližšie určená"},
{"code":"A79.9","desc":"Riketsióza, bližšie neurčená"},
{"code":"A80-A89","desc":"Vírusové infekcie centrálnej nervovej sústavy (A80 – A89)"},
{"code":"A80.-","desc":"Akútna poliomyelitída"},
{"code":"A80.0","desc":"Akútna paralytická poliomyelitída spojená s očkovaním"},
{"code":"A80.1","desc":"Akútna paralytická poliomyelitída zapríčinená importovaným divým vírusom"},
{"code":"A80.2","desc":"Akútna paralytická poliomyelitída zapríčinená domácim divým vírusom"},
{"code":"A80.3","desc":"Iná akútna paralytická poliomyelitída, bližšie neurčená"},
{"code":"A80.4","desc":"Akútna neparalytická poliomyelitída"},
{"code":"A80.9","desc":"Akútna poliomyelitída, bližšie neurčená"},
{"code":"A81.-","desc":"Pomalá vírusová infekcia centrálnej nervovej sústavy"},
{"code":"A81.0","desc":"Creutzfeldtova-Jakobova choroba"},
{"code":"A81.1","desc":"Subakútna sklerotizujúca panencefalitída"},
{"code":"A81.2","desc":"Progresívna multifokálna leukoencefalopatia"},
{"code":"A81.8","desc":"Iná pomalá vírusová infekcia centrálnej nervovej sústavy"},
{"code":"A81.9","desc":"Pomalá vírusová infekcia centrálnej nervovej sústavy, bližšie neurčená"},
{"code":"A82.-","desc":"Besnota [rabies, lyssa]"},
{"code":"A82.0","desc":"Besnota, lesný typ"},
{"code":"A82.1","desc":"Besnota, mestský typ"},
{"code":"A82.9","desc":"Besnota, bližšie neurčená"},
{"code":"A83.-","desc":"Vírusová encefalitída, prenášaná komármi"},
{"code":"A83.0","desc":"Japonská encefalitída"},
{"code":"A83.1","desc":"Západná encefalitída koní"},
{"code":"A83.2","desc":"Východná encefalitída koní"},
{"code":"A83.3","desc":"Saintlouiská encefalitída"},
{"code":"A83.4","desc":"Austrálska encefalitída"},
{"code":"A83.5","desc":"Kalifornská encefalitída"},
{"code":"A83.6","desc":"Encefalitída zapríčinená vírusom Rocio"},
{"code":"A83.8","desc":"Iná vírusová encefalitída prenášaná komármi"},
{"code":"A83.9","desc":"Vírusová encefalitída prenášaná komármi, bližšie neurčená"},
{"code":"A84.-","desc":"Vírusová encefalitída prenášaná kliešťami"},
{"code":"A84.0","desc":"Kliešťová encefalitída Ďalekého východu [ruská jarno-letná encefalitída]"},
{"code":"A84.1","desc":"Stredoeurópska kliešťová encefalitída"},
{"code":"A84.8","desc":"Iná vírusová encefalitída prenášaná kliešťami"},
{"code":"A84.9","desc":"Vírusová encefalitída prenášaná kliešťami, bližšie neurčená"},
{"code":"A85.-","desc":"Iná vírusová encefalitída, nezatriedená inde"},
{"code":"A85.0†","desc":"Enterovírusová encefalitída (G05.1*)"},
{"code":"A85.1†","desc":"Adenovírusová encefalitída (G05.1*)"},
{"code":"A85.2","desc":"Vírusová encefalitída prenášaná článkonožcami, bližšie neurčená"},
{"code":"A85.8","desc":"Iná vírusová encefalitída, bližšie určená"},
{"code":"A86","desc":"Vírusová encefalitída, bližšie neurčená"},
{"code":"A87.-","desc":"Vírusová meningitída"},
{"code":"A87.0†","desc":"Enterovírusová meningitída (G02.0*)"},
{"code":"A87.1†","desc":"Adenovírusová meningitída (G02.0*)"},
{"code":"A87.2","desc":"Lymfocytová choriomeningitída"},
{"code":"A87.8","desc":"Iná vírusová meningitída"},
{"code":"A87.9","desc":"Vírusová meningitída, bližšie neurčená"},
{"code":"A88.-","desc":"Iná vírusová infekcia centrálnej nervovej sústavy, nezatriedená inde"},
{"code":"A88.0","desc":"Enterovírusová exantémová horúčka [Bostonský exantém]"},
{"code":"A88.1","desc":"Epidemické vertigo"},
{"code":"A88.8","desc":"Iná vírusová infekcia centrálnej nervovej sústavy, bližšie určená"},
{"code":"A89","desc":"Vírusová infekcia centrálnej nervovej sústavy, bližšie neurčená"},
{"code":"A90-A99","desc":"Vírusová horúčka a vírusová hemoragická horúčka prenášaná článkonožcami"},
{"code":"A90","desc":"Horúčka dengue [klasická dengue]"},
{"code":"A91","desc":"Hemoragická horúčka dengue"},
{"code":"A92.-","desc":"Iná vírusová horúčka prenášaná komármi"},
{"code":"A92.0","desc":"Vírusová choroba Chikungunya"},
{"code":"A92.1","desc":"Horúčka O'nyong-nyong"},
{"code":"A92.2","desc":"Venezuelská horúčka koní"},
{"code":"A92.3","desc":"Horúčka Západného Nílu"},
{"code":"A92.4","desc":"Horúčka Riftského údolia"},
{"code":"A92.8","desc":"Iná vírusová horúčka prenášaná komármi, bližšie určená"},
{"code":"A92.9","desc":"Vírusová horúčka prenášaná komármi, bližšie neurčená"},
{"code":"A93.-","desc":"Iná vírusová horúčka prenášaná článkonožcami, nezatriedená inde"},
{"code":"A93.0","desc":"Choroba zapríčinená vírusom Oropouche"},
{"code":"A93.1","desc":"Horúčka papatači (prenášaná piesočnými muchami) [sandfly fever]"},
{"code":"A93.2","desc":"Koloradská kliešťová horúčka"},
{"code":"A93.8","desc":"Iná vírusová horúčka prenášaná článkonožcami, bližšie určená"},
{"code":"A94","desc":"Vírusová horúčka prenášaná článkonožcami, bližšie neurčená"},
{"code":"A95.-","desc":"Žltá zimnica"},
{"code":"A95.0","desc":"Žltá zimnica, lesný typ"},
{"code":"A95.1","desc":"Žltá zimnica, mestský typ"},
{"code":"A95.9","desc":"Žltá zimnica, bližšie neurčená"},
{"code":"A96.-","desc":"Arenavírusová hemoragická horúčka"},
{"code":"A96.0","desc":"Hemoragická horúčka zapríčinená vírusom Junin"},
{"code":"A96.1","desc":"Hemoragická horúčka zapríčinená vírusom Machupo"},
{"code":"A96.2","desc":"Horúčka Lassa"},
{"code":"A96.8","desc":"Iná arenavírusová hemoragická horúčka"},
{"code":"A96.9","desc":"Arenavírusová hemoragická horúčka, bližšie neurčená"},
{"code":"A98.-","desc":"Iná vírusová hemoragická horúčka, nezatriedená inde"},
{"code":"A98.0","desc":"Krymsko-konžská hemoragická horúčka"},
{"code":"A98.1","desc":"Omská hemoragická horúčka"},
{"code":"A98.2","desc":"Horúčka Kyasanurského lesa"},
{"code":"A98.3","desc":"Choroba zapríčinená vírusom Marburg"},
{"code":"A98.4","desc":"Choroba zapríčinená vírusom Ebola"},
{"code":"A98.5","desc":"Hemoragická horúčka s renálnym syndrómom"},
{"code":"A98.8","desc":"Iná vírusová hemoragická horúčka, bližšie určená"},
{"code":"A99","desc":"Vírusová hemoragická horúčka, bližšie neurčená"},
{"code":"B00-B09","desc":"Vírusové infekcie charakterizované poškodením kože a slizníc"},
{"code":"B00.-","desc":"Infekcia herpetickým vírusom [Herpes simplex]"},
{"code":"B00.0","desc":"Herpetický ekzém"},
{"code":"B00.1","desc":"Herpetická vezikulárna dermatitída"},
{"code":"B00.2","desc":"Herpetická gingivostomatitída a tonzilofaryngitída"},
{"code":"B00.3†","desc":"Herpetická meningitída (G02.0*)"},
{"code":"B00.4†","desc":"Herpetická encefalitída (G05.1*)"},
{"code":"B00.5","desc":"Očná infekcia herpetickým vírusom"},
{"code":"B00.7","desc":"Diseminovaná herpetická choroba"},
{"code":"B00.8","desc":"Iná forma herpetickej infekcie"},
{"code":"B00.9","desc":"Herpetická infekcia, bližšie neurčená"},
{"code":"B01.-","desc":"Ovčie kiahne [Varicella]"},
{"code":"B01.0†","desc":"Varicelová meningitída (G02.0*)"},
{"code":"B01.1†","desc":"Varicelová encefalitída (G05.1*)"},
{"code":"B01.2†","desc":"Varicelový zápal pľúc (J17.1*)"},
{"code":"B01.8","desc":"Varicela s inými komplikáciami"},
{"code":"B01.9","desc":"Varicela bez komplikácií"},
{"code":"B02.-","desc":"Pásový opar [Herpes zoster, Zoster]"},
{"code":"B02.0†","desc":"Zosterová encefalitída (G05.1*)"},
{"code":"B02.1†","desc":"Zosterová meningitída (G02.0*)"},
{"code":"B02.2†","desc":"Zoster s postihnutím iných častí nervovej sústavy"},
{"code":"B02.3","desc":"Zosterová choroba oka"},
{"code":"B02.7","desc":"Diseminovaný zoster"},
{"code":"B02.8","desc":"Zoster s inými komplikáciami"},
{"code":"B02.9","desc":"Zoster bez komplikácií"},
{"code":"B03","desc":"Kiahne [Variola]"},
{"code":"B04","desc":"Opičie kiahne"},
{"code":"B05.-","desc":"Osýpky [Morbilli]"},
{"code":"B05.0†","desc":"Osýpky komplikované encefalitídou (G05.1*)"},
{"code":"B05.1†","desc":"Osýpky komplikované meningitídou (G05.1*)"},
{"code":"B05.2†","desc":"Osýpky komplikované pneumóniou (J17.1*)"},
{"code":"B05.3†","desc":"Osýpky komplikované zápalom stredného ucha (H67.1*)"},
{"code":"B05.4","desc":"Osýpky s črevnými komplikáciami"},
{"code":"B05.8","desc":"Osýpky s inými komplikáciami"},
{"code":"B05.9","desc":"Osýpky bez komplikácií"},
{"code":"B06.-","desc":"Ružienka [Rubeola]"},
{"code":"B06.0†","desc":"Ružienka s nervovými komplikáciami"},
{"code":"B06.8","desc":"Ružienka s inými komplikáciami"},
{"code":"B06.9","desc":"Ružienka bez komplikácií"},
{"code":"B07","desc":"Vírusové bradavice [Verrucae]"},
{"code":"B08.-","desc":"Iná vírusové infekcie charakterizované postihnutím kože a slizníc, nezatriedené inde"},
{"code":"B08.0","desc":"Iné ortopoxvírusové infekcie"},
{"code":"B08.1","desc":"Kontagiózne bradavice [Molluscum contagiosum]"},
{"code":"B08.2","desc":"Exanthema subitum [Šiesta choroba]"},
{"code":"B08.3","desc":"Erythema infectiosum [Piata choroba]"},
{"code":"B08.4","desc":"Enterovírusová vezikulárna stomatitída s exantémom"},
{"code":"B08.5","desc":"Enterovírusová vezikulárna faryngitída"},
{"code":"B08.8","desc":"Iná vírusová infekcia charakterizovaná postihnutím kože a slizníc, bližšie určená"},
{"code":"B09","desc":"Vírusová infekcia charakterizovaná postihnutím kože a slizníc, bližšie neurčená"},
{"code":"B15-B19","desc":"Vírusové hepatitídy"},
{"code":"B15.-","desc":"Akútna hepatitída A"},
{"code":"B15.0","desc":"Akútna hepatitída A s pečeňovou kómou"},
{"code":"B15.9","desc":"Akútna hepatitída A bez pečeňovej kómy"},
{"code":"B16.-","desc":"Akútna hepatitída B"},
{"code":"B16.0","desc":"Akútna hepatitída B s Delta vírusom (koinfekcia), s pečeňovou kómou"},
{"code":"B16.1","desc":"Akútna hepatitída B s Delta vírusom (koinfekcia), bez pečeňovej kómy"},
{"code":"B16.2","desc":"Akútna hepatitída B bez Delta vírusu, s pečeňovou kómou"},
{"code":"B16.9","desc":"Akútna hepatitída B bez Delta vírusu, bez pečeňovej kómy"},
{"code":"B17.-","desc":"Iná akútna vírusová hepatitída"},
{"code":"B17.0","desc":"Akútna Delta-vírusová (super)infekcia nosiča vírusu hepatitídy B"},
{"code":"B17.1","desc":"Akútna hepatitída C"},
{"code":"B17.2","desc":"Akútna hepatitída E"},
{"code":"B17.8","desc":"Iná akútna vírusová hepatitída, bližšie určená"},
{"code":"B17.9","desc":"Akútna vírusová hepatitída, bližšie neurčená"},
{"code":"B18.-","desc":"Chronická vírusová hepatitída"},
{"code":"B18.0","desc":"Chronická hepatitída B s Delta vírusom"},
{"code":"B18.1","desc":"Chronická hepatitída B bez Delta vírusu"},
{"code":"B18.2","desc":"Chronická hepatitída C"},
{"code":"B18.8","desc":"Iná chronická vírusová hepatitída"},
{"code":"B18.9","desc":"Chronická vírusová hepatitída, bližšie neurčená"},
{"code":"B19.-","desc":"Vírusová hepatitída, bližšie neurčená"},
{"code":"B19.0","desc":"Vírusová hepatitída s pečeňovou kómou, bližšie neurčená"},
{"code":"B19.9","desc":"Vírusová hepatitída bez pečeňovej kómy, bližšie neurčená"},
{"code":"B20-B24","desc":"Choroba zapríčinená vírusom ľudskej imunitnej nedostatočnosti [Choroba HIV]"},
{"code":"B20.-","desc":"Choroba HIV s následnými infekčnými a parazitovými chorobami"},
{"code":"B20.0","desc":"Choroba HIV s následnými mykobakteriovými infekciami"},
{"code":"B20.1","desc":"Choroba HIV s následnými inými bakteriovými infekciami"},
{"code":"B20.2","desc":"Choroba HIV s následnou cytomegalovírusovou chorobou"},
{"code":"B20.3","desc":"Choroba HIV s následnými inými vírusovými infekciami"},
{"code":"B20.4","desc":"Choroba HIV s následnými kandidózami"},
{"code":"B20.5","desc":"Choroba HIV s následnými inými mykózami"},
{"code":"B20.6","desc":"Choroba HIV s následnou pneumocystovou pneumóniou"},
{"code":"B20.7","desc":"Choroba HIV s následnými viacpočetnými infekciami"},
{"code":"B20.8","desc":"Choroba HIV s následnými inými infekčnými a parazitovými chorobami  bližšie určenými"},
{"code":"B20.9","desc":"Choroba HIV s následnými infekčnými alebo parazitovými chorobami bližšie neurčenými"},
{"code":"B21.-","desc":"Choroba HIV s následnými zhubnými nádormi"},
{"code":"B21.0","desc":"Choroba HIV s následným Kaposiho sarkómom"},
{"code":"B21.1","desc":"Choroba HIV s následným Burkittovým lymfómom"},
{"code":"B21.2","desc":"Choroba HIV s následnými inými typmi nehodgkinovho lymfómu"},
{"code":"B21.3","desc":"Choroba HIV s následnými inými zhubnými nádormi lymfatického krvotvorného a príbuzného tkaniva"},
{"code":"B21.7","desc":"Choroba HIV s následnými viacpočetnými zhubnými nádormi"},
{"code":"B21.8","desc":"Choroba HIV s následnými inými zhubnými nádormi"},
{"code":"B21.9","desc":"Choroba HIV s následnými zhubných nádormi, bližšie neurčenými"},
{"code":"B22.-","desc":"Choroba HIV s inými následnými chorobami, bližšie určenými"},
{"code":"B22.0","desc":"Choroba HIV s následnou encefalopatiou"},
{"code":"B22.1","desc":"Choroba HIV s následnou lymfoidnou intersticiálnou pneumonitídou"},
{"code":"B22.2","desc":"Choroba HIV s následným syndrómom celkového upadania"},
{"code":"B22.7","desc":"Choroba HIV s následnými rozličnými chorobami zatriedenými inde"},
{"code":"B23.-","desc":"Choroba HIV s inými následnými stavmi"},
{"code":"B23.0","desc":"Syndróm akútnej infekcie HIV"},
{"code":"B23.1","desc":"Choroba HIV s následnou (pretrvávajúcou) generalizovanou lymfadenopatiou"},
{"code":"B23.2","desc":"Choroba HIV s následnými hematologickými a imunologickými abnormalitami nezatriedenými inde"},
{"code":"B23.8","desc":"Choroba HIV s inými následnými, bližšie určenými stavmi"},
{"code":"B24","desc":"Choroba HIV, bližšie neurčená"},
{"code":"B25-B34","desc":"Iné vírusové choroby"},
{"code":"B25.-","desc":"Cytomegalovírusová choroba"},
{"code":"B25.0†","desc":"Cytomegalovírusová pneumónia (J17.1*)"},
{"code":"B25.1†","desc":"Cytomegalovírusová hepatitída (K77.0*)"},
{"code":"B25.2†","desc":"Cytomegalovírusová pankreatitída (K87.1*)"},
{"code":"B25.8-","desc":"Iné cytomegalovírusové choroby"},
{"code":"B25.80†","desc":"Cytomegalovírusová infekcia tráviacej trubice"},
{"code":"B25.88","desc":"Iná cytomegalovírusová choroba"},
{"code":"B25.9","desc":"Cytomegalovírusová choroba, bližšie neurčená"},
{"code":"B26.-","desc":"Mumps [Parotitis epidemica]"},
{"code":"B26.0†","desc":"Parotitická orchitída (N51.1*)"},
{"code":"B26.1†","desc":"Parotitická meningitída (G02.0*)"},
{"code":"B26.2†","desc":"Parotitická encefalitída (G05.1*)"},
{"code":"B26.3†","desc":"Parotitická pankreatitída (K87.1*)"},
{"code":"B26.8","desc":"Parotitída s inými komplikáciami"},
{"code":"B26.9","desc":"Parotitída bez komplikácií"},
{"code":"B27.-","desc":"Infekčná mononukleóza"},
{"code":"B27.0","desc":"Mononukleóza zapríčinená gamma - Herpes vírusom"},
{"code":"B27.1","desc":"Cytomegalovírusová mononukleóza"},
{"code":"B27.8","desc":"Iná infekčná mononukleóza"},
{"code":"B27.9","desc":"Infekčná mononukleóza, bližšie neurčená"},
{"code":"B30.-","desc":"Vírusový zápal spojoviek"},
{"code":"B30.0†","desc":"Keratokonjunktivitída zapríčinená adenovírusom (H19.2*)"},
{"code":"B30.1†","desc":"Konjunktivitída zapríčinená adenovírusom (H13.1*)"},
{"code":"B30.2","desc":"Vírusová faryngokonjunktivitída (vírusová)"},
{"code":"B30.3†","desc":"Akútna epidemická hemoragická konjunktivitída (enterovírusová) (H13.1*)"},
{"code":"B30.8†","desc":"Iná vírusová konjunktivitída (H13.1*)"},
{"code":"B30.9","desc":"Vírusová konjunktivitída, bližšie neurčená"},
{"code":"B33.-","desc":"Iná vírusová choroba, nezatriedená inde"},
{"code":"B33.0","desc":"Epidemická pleurodýnia"},
{"code":"B33.1","desc":"Choroba Ross River"},
{"code":"B33.2","desc":"Vírusová karditída"},
{"code":"B33.3","desc":"Retrovírusová infekcia, nezatriedená inde"},
{"code":"B33.4†","desc":"Hantavírusový pulmonálny (kardiopulmonálny) syndróm (J17.1*)"},
{"code":"B33.8","desc":"Iná vírusová choroba, bližšie určená"},
{"code":"B34.-","desc":"Vírusová infekcia bez bližšieho určenia miesta"},
{"code":"B34.0","desc":"Adenovírusová infekcia bez bližšieho určenia miesta"},
{"code":"B34.1","desc":"Enterovírusová infekcia bez bližšieho určenia miesta"},
{"code":"B34.2","desc":"Koronavírusová infekcia bez bližšieho určenia miesta"},
{"code":"B34.3","desc":"Parvovírusová infekcia bez bližšieho určenia miesta"},
{"code":"B34.4","desc":"Papovavírusová infekcia bez bližšieho určenia miesta"},
{"code":"B34.8","desc":"Iná vírusová infekcia bez bližšieho určenia miesta"},
{"code":"B34.9","desc":"Bližšie neurčená vírusová infekcia, bez bližšieho určenia miesta"},
{"code":"B35-B49","desc":"Mykózy"},
{"code":"B35.-","desc":"Dermatofytóza [Tinea]"},
{"code":"B35.0","desc":"Tinea barbae a tinea capitis"},
{"code":"B35.1","desc":"Tinea unguium"},
{"code":"B35.2","desc":"Tinea manuum"},
{"code":"B35.3","desc":"Tinea pedis"},
{"code":"B35.4","desc":"Tinea corporis"},
{"code":"B35.5","desc":"Tinea imbricata"},
{"code":"B35.6","desc":"Tinea cruris"},
{"code":"B35.8","desc":"Iná dermatofytóza"},
{"code":"B35.9","desc":"Dermatofytóza, bližšie neurčená"},
{"code":"B36.-","desc":"Iná povrchová mykóza"},
{"code":"B36.0","desc":"Pityriasis versicolor"},
{"code":"B36.1","desc":"Tinea nigra"},
{"code":"B36.2","desc":"Piedra alba"},
{"code":"B36.3","desc":"Piedra nigra"},
{"code":"B36.8","desc":"Iná povrchová mykóza, bližšie určená"},
{"code":"B36.9","desc":"Povrchová mykóza, bližšie neurčená"},
{"code":"B37.-","desc":"Kandidóza"},
{"code":"B37.0","desc":"Kandidová stomatitída"},
{"code":"B37.1","desc":"Pľúcna kandidóza"},
{"code":"B37.2","desc":"Kandidóza kože a nechtov"},
{"code":"B37.3†","desc":"Kandidóza vulvy a pošvy (N77.1*)"},
{"code":"B37.4","desc":"Kandidóza iných častí močovopohlavnej sústavy"},
{"code":"B37.5†","desc":"Kandidová meningitída (G02.1*)"},
{"code":"B37.6†","desc":"Kandidová endokarditída (I39.8*)"},
{"code":"B37.7","desc":"Kandidová sepsa"},
{"code":"B37.8-","desc":"Kandidóza na iných miestach"},
{"code":"B37.81","desc":"Kandidová ezofagitída"},
{"code":"B37.88","desc":"Kandidóza na iných miestach"},
{"code":"B37.9","desc":"Kandidóza, bližšie neurčená"},
{"code":"B38.-","desc":"Kokcidioidomykóza"},
{"code":"B38.0","desc":"Akútna kokcidioidomykóza pľúc"},
{"code":"B38.1","desc":"Chronická kokcidioidomykóza pľúc"},
{"code":"B38.2","desc":"Kokcidioidomykóza pľúc, bližšie neurčená"},
{"code":"B38.3","desc":"Kokcidioidomykóza kože"},
{"code":"B38.4†","desc":"Kokcidioidomykotická meningitída (G02.1*)"},
{"code":"B38.7","desc":"Diseminovaná kokcidioidomykóza"},
{"code":"B38.8","desc":"Iná forma kokcidioidomykózy"},
{"code":"B38.9","desc":"Kokcidioidomykóza, bližšie neurčená"},
{"code":"B39.-","desc":"Histoplazmóza"},
{"code":"B39.0","desc":"Akútna histoplazmóza pľúc zapríčinená Histoplasma capsulatum"},
{"code":"B39.1","desc":"Chronická histoplazmóza pľúc zapríčinená Histoplasma capsulatum"},
{"code":"B39.2","desc":"Histoplazmóza pľúc zapríčinená Histoplasma capsulatum, bližšie neurčená"},
{"code":"B39.3","desc":"Diseminovaná histoplazmóza zapríčinená Histoplasma capsulatum"},
{"code":"B39.4","desc":"Histoplazmóza zapríčinená Histoplasma capsulatum, bližšie neurčená"},
{"code":"B39.5","desc":"Histoplazmóza zapríčinená Histoplasma duboisii"},
{"code":"B39.9","desc":"Histoplazmóza, bližšie neurčená"},
{"code":"B40.-","desc":"Blastomykóza"},
{"code":"B40.0","desc":"Akútna blastomykóza pľúc"},
{"code":"B40.1","desc":"Chronická blastomykóza pľúc"},
{"code":"B40.2","desc":"Blastomykóza pľúc, bližšie neurčená"},
{"code":"B40.3","desc":"Blastomykóza kože"},
{"code":"B40.7","desc":"Diseminovaná blastomykóza"},
{"code":"B40.8","desc":"Iná forma blastomykózy"},
{"code":"B40.9","desc":"Blastomykóza, bližšie neurčená"},
{"code":"B41.-","desc":"Parakokcidioidomykóza"},
{"code":"B41.0","desc":"Parakokcidioidomykóza pľúc"},
{"code":"B41.7","desc":"Diseminovaná parakokcidioidomykóza"},
{"code":"B41.8","desc":"Iná forma parakokcidioidomykózy"},
{"code":"B41.9","desc":"Parakokcidioidomykóza, bližšie neurčená"},
{"code":"B42.-","desc":"Sporotrichóza"},
{"code":"B42.0†","desc":"Sporotrichóza pľúc"},
{"code":"B42.1","desc":"Lymfokutánna sporotrichóza"},
{"code":"B42.7","desc":"Diseminovaná sporotrichóza"},
{"code":"B42.8","desc":"Iná forma sporotrichózy"},
{"code":"B42.9","desc":"Sporotrichóza, bližšie neurčená"},
{"code":"B43.-","desc":"Chromomykóza a chromomykotický absces"},
{"code":"B43.0","desc":"Chromomykóza kože"},
{"code":"B43.1","desc":"Chromomykotický absces mozgu"},
{"code":"B43.2","desc":"Chromomykotický absces a cysta podkožia"},
{"code":"B43.8","desc":"Iná forma chromomykózy"},
{"code":"B43.9","desc":"Chromomykóza, bližšie neurčená"},
{"code":"B44.-","desc":"Aspergilóza"},
{"code":"B44.0","desc":"Invazívna aspergilóza pľúc"},
{"code":"B44.1","desc":"Iná aspergilóza pľúc"},
{"code":"B44.2","desc":"Aspergilóza tonzíl"},
{"code":"B44.7","desc":"Diseminovaná aspergilóza"},
{"code":"B44.8","desc":"Iná forma aspergilózy"},
{"code":"B44.9","desc":"Aspergilóza, bližšie neurčená"},
{"code":"B45.-","desc":"Kryptokokóza"},
{"code":"B45.0","desc":"Kryptokokóza pľúc"},
{"code":"B45.1","desc":"Kryptokokóza mozgu"},
{"code":"B45.2","desc":"Kryptokokóza kože"},
{"code":"B45.3","desc":"Kryptokokóza kostí"},
{"code":"B45.7","desc":"Diseminovaná kryptokokóza"},
{"code":"B45.8","desc":"Iná forma kryptokokózy"},
{"code":"B45.9","desc":"Kryptokokóza, bližšie neurčená"},
{"code":"B46.-","desc":"Zygomykóza"},
{"code":"B46.0","desc":"Mukormykóza pľúc"},
{"code":"B46.1","desc":"Rinocerebrálna mukormykóza"},
{"code":"B46.2","desc":"Mukormykóza tráviacej trubice"},
{"code":"B46.3","desc":"Mukormykóza kože"},
{"code":"B46.4","desc":"Diseminovaná mukormykóza"},
{"code":"B46.5","desc":"Mukormykóza, bližšie neurčená"},
{"code":"B46.8","desc":"Iná zygomykóza"},
{"code":"B46.9","desc":"Zygomykóza, bližšie neurčená"},
{"code":"B47.-","desc":"Mycetóm"},
{"code":"B47.0","desc":"Eumycetóm"},
{"code":"B47.1","desc":"Aktinomycetóm"},
{"code":"B47.9","desc":"Mycetóm, bližšie neurčený"},
{"code":"B48.-","desc":"Iná mykóza, nezatriedená inde"},
{"code":"B48.0","desc":"Lobomykóza"},
{"code":"B48.1","desc":"Rinosporidióza"},
{"code":"B48.2","desc":"Allescherióza"},
{"code":"B48.3","desc":"Geotrichóza"},
{"code":"B48.4","desc":"Penicilóza"},
{"code":"B48.7","desc":"Mykóza zapríčinená oportunistickým patogénom"},
{"code":"B48.8","desc":"Iná mykóza, bližšie určená"},
{"code":"B49","desc":"Mykóza, bližšie neurčená"},
{"code":"B50-B64","desc":"Protozoárne choroby"},
{"code":"B50.-","desc":"Malária zapríčinená Plasmodium falciparum"},
{"code":"B50.0","desc":"Malária zapríčinená Plasmodium falciparum s mozgovými komplikáciami"},
{"code":"B50.8","desc":"Iná ťažká a komplikovaná malária zapríčinená Plasmodium falciparum"},
{"code":"B50.9","desc":"Malária zapríčinená Plasmodium falciparum, bližšie neurčená"},
{"code":"B51.-","desc":"Malária zapríčinená Plasmodium vivax"},
{"code":"B51.0","desc":"Malária zapríčinená Plasmodium vivax s ruptúrou sleziny"},
{"code":"B51.8","desc":"Malária zapríčinená Plasmodium vivax s inými komplikáciami"},
{"code":"B51.9","desc":"Malária zapríčinená Plasmodium vivax bez komplikácií"},
{"code":"B52.-","desc":"Malária zapríčinená Plasmodium malariae"},
{"code":"B52.0","desc":"Malária zapríčinená Plasmodium malariae s nefropatiou"},
{"code":"B52.8","desc":"Malária zapríčinená Plasmodium malariae s inými komplikáciami"},
{"code":"B52.9","desc":"Malária zapríčinená Plasmodium malariae bez komplikácií"},
{"code":"B53.-","desc":"Iná parazitologicky potvrdená malária"},
{"code":"B53.0","desc":"Malária zapríčinená Plasmodium ovale"},
{"code":"B53.1","desc":"Malária zapríčinená opičími plazmódiami"},
{"code":"B53.8","desc":"Iná parazitologicky potvrdená malária, nezatriedená inde"},
{"code":"B54","desc":"Malária, bližšie neurčená"},
{"code":"B55.-","desc":"Leišmanióza"},
{"code":"B55.0","desc":"Viscerálna leišmanióza"},
{"code":"B55.1","desc":"Kožná leišmanióza"},
{"code":"B55.2","desc":"Leišmanióza kože a slizníc (mukokutánna)"},
{"code":"B55.9","desc":"Leišmanióza, bližšie neurčená"},
{"code":"B56.-","desc":"Africká trypanozomóza"},
{"code":"B56.0","desc":"Gambijská trypanozomóza"},
{"code":"B56.1","desc":"Rodézska trypanozomóza"},
{"code":"B56.9","desc":"Africká trypanozomóza, bližšie neurčená"},
{"code":"B57.-","desc":"Chagasova choroba"},
{"code":"B57.0†","desc":"Akútna Chagasova choroba s postihnutím srdca (I41.2*, I98.1*)"},
{"code":"B57.1","desc":"Akútna Chagasova choroba bez postihnutia srdca"},
{"code":"B57.2","desc":"Chagasova choroba (chronická) s postihnutím srdca"},
{"code":"B57.3","desc":"Chagasova choroba (chronická) s postihnutím tráviacej sústavy"},
{"code":"B57.4","desc":"Chagasova choroba (chronická) s postihnutím nervovej sústavy"},
{"code":"B57.5","desc":"Chagasova choroba (chronická) s postihnutím iných orgánov"},
{"code":"B58.-","desc":"Toxoplazmóza"},
{"code":"B58.0†","desc":"Toxoplazmová okulopatia"},
{"code":"B58.1†","desc":"Toxoplazmová hepatitída (K77.0*)"},
{"code":"B58.2†","desc":"Toxoplazmová meningoencefalitída (G05.2*)"},
{"code":"B58.3†","desc":"Toxoplazmóza pľúc (J17.3*)"},
{"code":"B58.8","desc":"Toxoplazmóza s postihnutím iných orgánov"},
{"code":"B58.9","desc":"Toxoplazmóza, bližšie neurčená"},
{"code":"B59†","desc":"Pneumocystóza (J17.3*)"},
{"code":"B60.-","desc":"Iná protozoárna choroba, nezatriedená inde"},
{"code":"B60.0","desc":"Babezióza"},
{"code":"B60.1","desc":"Akantamebóza"},
{"code":"B60.2","desc":"Naeglerióza"},
{"code":"B60.8","desc":"Iná protozoárna choroba, bližšie určená"},
{"code":"B64","desc":"Protozoárna choroba, bližšie neurčená"},
{"code":"B65-B83","desc":"Helmintózy"},
{"code":"B65.-","desc":"Schistozomóza [Bilharzióza]"},
{"code":"B65.0","desc":"Schistozomóza zapríčinená Schistosoma haematobium"},
{"code":"B65.1","desc":"Schistozomóza zapríčinená Schistosoma mansoni"},
{"code":"B65.2","desc":"Schistozomóza zapríčinená Schistosoma japonicum"},
{"code":"B65.3","desc":"Cerkáriová dermatitída"},
{"code":"B65.8","desc":"Iné schistozomózy"},
{"code":"B65.9","desc":"Schistozomóza, bližšie neurčená"},
{"code":"B66.-","desc":"Iná trematodóza"},
{"code":"B66.0","desc":"Opistorchóza"},
{"code":"B66.1","desc":"Klonorchóza"},
{"code":"B66.2","desc":"Dikrocelióza"},
{"code":"B66.3","desc":"Fasciolóza"},
{"code":"B66.4","desc":"Paragonimóza"},
{"code":"B66.5","desc":"Fasciolopsóza"},
{"code":"B66.8","desc":"Iná infekcia motolicou, bližšie určená"},
{"code":"B66.9","desc":"Infekcia motolicou, bližšie neurčená"},
{"code":"B67.-","desc":"Echinokokóza"},
{"code":"B67.0","desc":"Cystická echinokokóza pečene zapríčinená Echinococcus granulosus"},
{"code":"B67.1","desc":"Cystická echinokokóza pľúc zapríčinená Echinococcus granulosus"},
{"code":"B67.2","desc":"Cystická echinokokóza kostí zapríčinená Echinococcus granulosus"},
{"code":"B67.3","desc":"Cystická echinokokóza zapríčinená Echinococcus granulosus na viacerých a na iných miestach"},
{"code":"B67.4","desc":"Cystická echinokokóza zapríčinená Echinococcus granulosus, bližšie neurčená"},
{"code":"B67.5","desc":"Alveolová echinokokóza pečene zapríčinená Echinococcus multilocularis"},
{"code":"B67.6","desc":"Alveolová echinokokóza zapríčinená Echinococcus multilocularis na iných a na viacerých miestach"},
{"code":"B67.7","desc":"Alveolová echinokokóza zapríčinená Echinococcus multilocularis, bližšie neurčená"},
{"code":"B67.8","desc":"Echinokokóza pečene, bližšie neurčená"},
{"code":"B67.9","desc":"Iná echinokokóza, bližšie neurčená"},
{"code":"B68.-","desc":"Tenióza"},
{"code":"B68.0","desc":"Tenióza zapríčinená Taenia solium"},
{"code":"B68.1","desc":"Tenióza zapríčinená Taenia saginata"},
{"code":"B68.9","desc":"Tenióza, bližšie neurčená"},
{"code":"B69.-","desc":"Cysticerkóza"},
{"code":"B69.0","desc":"Cysticerkóza centrálnej nervovej sústavy"},
{"code":"B69.1","desc":"Cysticerkóza oka"},
{"code":"B69.8","desc":"Cysticerkóza na iných miestach"},
{"code":"B69.9","desc":"Cysticerkóza, bližšie neurčená"},
{"code":"B70.-","desc":"Difylobotrióza a sparganóza"},
{"code":"B70.0","desc":"Difylobotrióza"},
{"code":"B70.1","desc":"Sparganóza"},
{"code":"B71.-","desc":"Iná infekcia plochými červami"},
{"code":"B71.0","desc":"Hymenolepóza"},
{"code":"B71.1","desc":"Dipylidióza"},
{"code":"B71.8","desc":"Iná infekcia plochými červami, bližšie určená"},
{"code":"B71.9","desc":"Infekcia plochými červami, bližšie neurčená"},
{"code":"B72","desc":"Drakunkulóza"},
{"code":"B73","desc":"Onchocerkóza"},
{"code":"B74.-","desc":"Filarióza"},
{"code":"B74.0","desc":"Filarióza zapríčinená Wuchereria bancrofti"},
{"code":"B74.1","desc":"Filarióza zapríčinená Brugia malayi"},
{"code":"B74.2","desc":"Filarióza zapríčinená Brugia timori"},
{"code":"B74.3","desc":"Loióza"},
{"code":"B74.4","desc":"Mansonelóza"},
{"code":"B74.8","desc":"Iná filarióza"},
{"code":"B74.9","desc":"Filarióza, bližšie neurčená"},
{"code":"B75","desc":"Trichinelóza"},
{"code":"B76.-","desc":"Ankylostomóza a nekatoróza"},
{"code":"B76.0","desc":"Ankylostomóza"},
{"code":"B76.1","desc":"Nekatoróza"},
{"code":"B76.8","desc":"Iná ankylostómóza a nekatoróza"},
{"code":"B76.9","desc":"Ankylostomóza a nekatoróza, bližšie neurčená"},
{"code":"B77.-","desc":"Askaridóza"},
{"code":"B77.0","desc":"Askaridóza s črevnými komplikáciami"},
{"code":"B77.8","desc":"Askaridóza s inými komplikáciami"},
{"code":"B77.9","desc":"Askaridóza, bližšie neurčená"},
{"code":"B78.-","desc":"Strongyloidóza"},
{"code":"B78.0","desc":"Črevná strongyloidóza"},
{"code":"B78.1","desc":"Kožná strongyloidóza"},
{"code":"B78.7","desc":"Diseminovaná strongyloidóza"},
{"code":"B78.9","desc":"Strongyloidóza, bližšie neurčená"},
{"code":"B79","desc":"Trichuridóza"},
{"code":"B80","desc":"Enterobióza"},
{"code":"B81.-","desc":"Iná črevná helmintóza, nezatriedená inde"},
{"code":"B81.0","desc":"Anisakidóza"},
{"code":"B81.1","desc":"Črevná kapilarióza"},
{"code":"B81.2","desc":"Trichostrongylóza"},
{"code":"B81.3","desc":"Črevná angiostrongylóza"},
{"code":"B81.4","desc":"Zmiešaná črevná helmintóza"},
{"code":"B81.8","desc":"Iná črevná helmintóza, bližšie určená"},
{"code":"B82.-","desc":"Črevná parazitóza, bližšie neurčená"},
{"code":"B82.0","desc":"Črevná helmintóza, bližšie neurčená"},
{"code":"B82.9","desc":"Črevná parazitóza, bližšie neurčená"},
{"code":"B83.-","desc":"Iná helmintóza"},
{"code":"B83.0","desc":"Viscerálna larva migrans"},
{"code":"B83.1","desc":"Gnatostomóza"},
{"code":"B83.2","desc":"Angiostrongylóza zapríčinená Parastrongylus cantonensis"},
{"code":"B83.3","desc":"Syngamidóza"},
{"code":"B83.4","desc":"Vnútorná hirudinóza"},
{"code":"B83.8","desc":"Iná helmintóza, bližšie určená"},
{"code":"B83.9","desc":"Helmintóza, bližšie neurčená"},
{"code":"B85-B89","desc":"Zavšivenie [Pedikulóza], svrab [Akarióza] a iné zamorenia"},
{"code":"B85.-","desc":"Zavšivenie [Pedikulóza a Ftirióza]"},
{"code":"B85.0","desc":"Pedikulóza zapríčinená Pediculus humanus capitis"},
{"code":"B85.1","desc":"Pedikulóza zapríčinená Pediculus humanus corporis"},
{"code":"B85.2","desc":"Pedikulóza, bližšie neurčená"},
{"code":"B85.3","desc":"Ftirióza"},
{"code":"B85.4","desc":"Zmiešaná pedikulóza a ftirióza"},
{"code":"B86","desc":"Scabies"},
{"code":"B87.-","desc":"Myióza"},
{"code":"B87.0","desc":"Myióza kože"},
{"code":"B87.1","desc":"Myióza rany"},
{"code":"B87.2","desc":"Myióza oka"},
{"code":"B87.3","desc":"Myióza nosohltana"},
{"code":"B87.4","desc":"Myióza ucha"},
{"code":"B87.8","desc":"Myióza na iných miestach"},
{"code":"B87.9","desc":"Myióza, bližšie neurčená"},
{"code":"B88.-","desc":"Iné zamorenie"},
{"code":"B88.0","desc":"Iná akarióza"},
{"code":"B88.1","desc":"Tungióza"},
{"code":"B88.2","desc":"Iné zamorenie článkonožcami"},
{"code":"B88.3","desc":"Vonkajšia hirudinóza"},
{"code":"B88.8","desc":"Iné zamorenie, bližšie určenými ektoparazitmi"},
{"code":"B88.9","desc":"Zamorenie bližšie neurčenými ektoparazitmi"},
{"code":"B89","desc":"Parazitová choroba, bližšie neurčená"},
{"code":"B90-B94","desc":"Následky infekčných a parazitových chorôb"},
{"code":"B90.-","desc":"Následky tuberkulózy"},
{"code":"B90.0","desc":"Následky tuberkulózy centrálnej nervovej sústavy"},
{"code":"B90.1","desc":"Následky tuberkulózy močovopohlavnej sústavy"},
{"code":"B90.2","desc":"Následky tuberkulózy kostí a kĺbov"},
{"code":"B90.8","desc":"Následky tuberkulózy iných orgánov"},
{"code":"B90.9","desc":"Následky respiračnej a bližšie neurčenej tuberkulózy"},
{"code":"B91","desc":"Následky poliomyelitídy"},
{"code":"B92","desc":"Následky lepry"},
{"code":"B94.-","desc":"Následky iných a bližšie neurčených infekčných a parazitových chorôb"},
{"code":"B94.0","desc":"Následky trachómu"},
{"code":"B94.1","desc":"Následky vírusovej encefalitídy"},
{"code":"B94.2","desc":"Následky vírusovej hepatitídy"},
{"code":"B94.8","desc":"Následky iných bližšie určených infekčných a parazitových chorôb"},
{"code":"B94.9","desc":"Následky bližšie neurčených infekčných alebo parazitových chorôb"},
{"code":"B95-B98","desc":"Baktérie, vírusy a iní infekční pôvodcovia ako príčina chorôb zatriedených inde"},
{"code":"B95.-!","desc":"Streptokok a stafylokok ako príčina chorôb zatriedených inde"},
{"code":"B95.0!","desc":"Streptokok skupiny A ako príčina chorôb zatriedených inde"},
{"code":"B95.1!","desc":"Streptokok skupiny B ako príčina chorôb zatriedených inde"},
{"code":"B95.2!","desc":"Streptokok skupiny D ako príčina chorôb zatriedených inde"},
{"code":"B95.3!","desc":"Streptococcus pneumoniae ako príčina chorôb zatriedených inde"},
{"code":"B95.4-!","desc":"Iný streptokok ako príčina chorôb zatriedených inde"},
{"code":"B95.41!","desc":"Streptokok skupiny C ako príčina chorôb zatriedených inde"},
{"code":"B95.42!","desc":"Streptokok skupiny G ako príčina chorôb zatriedených inde"},
{"code":"B95.48!","desc":"Iný streptokok, bližšie určený, ako príčina chorôb zatriedených inde"},
{"code":"B95.5!","desc":"Streptokok, bližšie neurčený, ako príčina chorôb zatriedených inde"},
{"code":"B95.6!","desc":"Staphylococcus aureus ako príčina chorôb zatriedených inde"},
{"code":"B95.7!","desc":"Iný stafylokok ako príčina chorôb zatriedených inde"},
{"code":"B95.8!","desc":"Stafylokok, bližšie neurčený, ako príčina chorôb zatriedených inde"},
{"code":"B95.9-!","desc":"Iný grampozitívny mikroorganizmus, bližšie určený, ako príčina chorôb zatriedených inde"},
{"code":"B95.90!","desc":"Iný grampozitívny aeróbny mikroorganizmus, bližšie určený, ako príčina chorôb zatriedených inde"},
{"code":"B95.91!","desc":"Iný grampozitívny aneróbny nesporulujúci mikroorganizmus, bližšie určený, ako príčina chorôb zatriedených inde"},
{"code":"B96.-!","desc":"Iné baktérie ako príčina chorôb zatriedených inde"},
{"code":"B96.0!","desc":"Mykoplazma a ureaplazma ako príčina chorôb zatriedených inde"},
{"code":"B96.2!","desc":"Escherichia coli [E. coli] a iné Enterobacteriacae ako príčina chorôb zatriedených inde"},
{"code":"B96.3!","desc":"Haemophilus a Moraxella ako príčina chorôb zatriedených inde"},
{"code":"B96.5!","desc":"Pseudomonas a iná nefermentujúca baktéria ako príčina chorôb zatriedených inde"},
{"code":"B96.6!","desc":"Bacteroides fragilis [B. fragilis] a iná gramnegatívna anaeróbna baktéria ako príčina chorôb zatriedených inde"},
{"code":"B96.7!","desc":"Clostridium perfringens [C. perfringens] a iná grampozitívna sporulujúca anaeróbna baktéria ako príčina chorôb zatriedených inde"},
{"code":"B96.8!","desc":"Iná baktéria, bližšie určená, ako príčina chorôb zatriedených inde"},
{"code":"B97.-!","desc":"Vírus ako príčina chorôb zatriedených inde"},
{"code":"B97.0!","desc":"Adenovírus ako príčina chorôb zatriedených inde"},
{"code":"B97.1!","desc":"Enterovírus ako príčina chorôb zatriedených inde"},
{"code":"B97.2!","desc":"Koronavírus ako príčina chorôb zatriedených inde"},
{"code":"B97.3!","desc":"Retrovírus ako príčina chorôb zatriedených inde"},
{"code":"B97.4!","desc":"Respiračný syncytiálny vírus [RS-Virus] ako príčina chorôb zatriedených inde"},
{"code":"B97.5!","desc":"Reovírus ako príčina chorôb zatriedených inde"},
{"code":"B97.6!","desc":"Parvovírus ako príčina chorôb zatriedených inde"},
{"code":"B97.7!","desc":"Papilomavírus ako príčina chorôb zatriedených inde"},
{"code":"B97.8!","desc":"Iný vírus ako príčina chorôb zatriedených inde"},
{"code":"B98.-!","desc":"Iný infekčný pôvodca, bližšie určený, ako príčina chorôb zatriedených inde"},
{"code":"B98.0!","desc":"Helicobacter pylori [H. pylori] ako príčina chorôb zatriedených inde"},
{"code":"B98.1!","desc":"Vibrio vulnificus ako príčina chorôb zatriedených inde"},
{"code":"B99-B99","desc":"Iné infekčné choroby"},
{"code":"B99","desc":"Iná infekčná choroba, bližšie neurčená"},
{"code":"C00-D48","desc":"II. kapitola - Nádory (C00-D48)"},
{"code":"C00-C97","desc":"Zhubné nádory"},
{"code":"C00-C75","desc":"Primárne alebo predpokladané primárne zhubné nádory určenej lokalizácie okrem lymfatického, krvotvorného a príbuzného tkaniva"},
{"code":"C00-C14","desc":"Zhubné nádory pery, ústnej dutiny a hltana"},
{"code":"C00.-","desc":"Zhubný nádor pery"},
{"code":"C00.0","desc":"Zhubný nádor vonkajšej hornej pery"},
{"code":"C00.1","desc":"Zhubný nádor vonkajšej dolnej pery"},
{"code":"C00.2","desc":"Zhubný nádor vonkajšej pery, bližšie neurčený"},
{"code":"C00.3","desc":"Zhubný nádor vnútornej strany hornej pery"},
{"code":"C00.4","desc":"Zhubný nádor vnútornej strany dolnej pery"},
{"code":"C00.5","desc":"Zhubný nádor vnútornej strany pery, bližšie neurčený"},
{"code":"C00.6","desc":"Zhubný nádor spojky perí"},
{"code":"C00.8","desc":"Zhubný nádor pery presahujúci viaceré oblasti"},
{"code":"C00.9","desc":"Zhubný nádor pery, bližšie neurčený"},
{"code":"C01","desc":"Zhubný nádor koreňa jazyka"},
{"code":"C02.-","desc":"Zhubný nádor iných a bližšie neurčených častí jazyka"},
{"code":"C02.0","desc":"Zhubný nádor chrbtovej strany jazyka"},
{"code":"C02.1","desc":"Zhubný nádor okraja jazyka"},
{"code":"C02.2","desc":"Zhubný nádor spodnej strany jazyka"},
{"code":"C02.3","desc":"Zhubný nádor predných dvoch tretín jazyka, bližšie neurčená časť"},
{"code":"C02.4","desc":"Zhubný nádor jazykovej mandle"},
{"code":"C02.8","desc":"Zhubný nádor jazyka presahujúci viaceré oblasti"},
{"code":"C02.9","desc":"Zhubný nádor jazyka, bližšie neurčený"},
{"code":"C03.-","desc":"Zhubný nádor ďasna"},
{"code":"C03.0","desc":"Zhubný nádor horného ďasna"},
{"code":"C03.1","desc":"Zhubný nádor dolného ďasna"},
{"code":"C03.9","desc":"Zhubný nádor ďasna, bližšie neurčený"},
{"code":"C04.-","desc":"Zhubný nádor ústnej spodiny"},
{"code":"C04.0","desc":"Zhubný nádor prednej časti ústnej spodiny"},
{"code":"C04.1","desc":"Zhubný nádor bočnej časti ústnej spodiny"},
{"code":"C04.8","desc":"Zhubný nádor ústnej spodiny presahujúci viaceré oblasti"},
{"code":"C04.9","desc":"Zhubný nádor ústnej spodiny, bližšie neurčený"},
{"code":"C05.-","desc":"Zhubný nádor podnebia"},
{"code":"C05.0","desc":"Zhubný nádor tvrdého podnebia"},
{"code":"C05.1","desc":"Zhubný nádor mäkkého podnebia"},
{"code":"C05.2","desc":"Zhubný nádor uvuly"},
{"code":"C05.8","desc":"Zhubný nádor podnebia presahujúci viaceré oblasti"},
{"code":"C05.9","desc":"Zhubný nádor podnebia, bližšie neurčený"},
{"code":"C06.-","desc":"Zhubný nádor iných a bližšie neurčených častí úst"},
{"code":"C06.0","desc":"Zhubný nádor sliznice líca"},
{"code":"C06.1","desc":"Zhubný nádor vestibulum oris"},
{"code":"C06.2","desc":"Zhubný nádor retromolárnej časti úst"},
{"code":"C06.8","desc":"Zhubný nádor iných a bližšie neurčených častí úst presahujúci viaceré oblasti"},
{"code":"C06.9","desc":"Zhubný nádor úst, bližšie neurčený"},
{"code":"C07","desc":"Zhubný nádor príušnej žľazy"},
{"code":"C08.-","desc":"Zhubný nádor iných a bližšie neurčených veľkých slinných žliaz"},
{"code":"C08.0","desc":"Zhubný nádor submandibulárnej slinnej žľazy"},
{"code":"C08.1","desc":"Zhubný nádor sublingválnej slinnej žľazy"},
{"code":"C08.8","desc":"Zhubný nádor veľkých slinných žliaz presahujúci viaceré oblasti"},
{"code":"C08.9","desc":"Zhubný nádor veľkej slinnej žľazy, bližšie neurčený"},
{"code":"C09.-","desc":"Zhubný nádor mandle"},
{"code":"C09.0","desc":"Zhubný nádor fossa tonsillaris"},
{"code":"C09.1","desc":"Zhubný nádor podnebného oblúka (predná časť) (zadná časť)"},
{"code":"C09.8","desc":"Zhubný nádor mandle presahujúci viaceré oblasti"},
{"code":"C09.9","desc":"Zhubný nádor mandle, bližšie neurčený"},
{"code":"C10.-","desc":"Zhubný nádor ofaryngu"},
{"code":"C10.0","desc":"Zhubný nádor vallecula epiglottica"},
{"code":"C10.1","desc":"Zhubný nádor prednej plochy epiglottis"},
{"code":"C10.2","desc":"Zhubný nádor bočnej steny orofaryngu"},
{"code":"C10.3","desc":"Zhubný nádor zadnej steny orofaryngu"},
{"code":"C10.4","desc":"Zhubný nádor branchiálneho rázštepu"},
{"code":"C10.8","desc":"Zhubný nádor orofaryngu presahujúci viaceré oblasti"},
{"code":"C10.9","desc":"Zhubný nádor orofaryngu, bližšie neurčený"},
{"code":"C11.-","desc":"Zhubný nádor nazofaryngu"},
{"code":"C11.0","desc":"Zhubný nádor hornej steny nazofaryngu"},
{"code":"C11.1","desc":"Zhubný nádor zadnej steny nazofaryngu"},
{"code":"C11.2","desc":"Zhubný nádor bočnej steny nazofaryngu"},
{"code":"C11.3","desc":"Zhubný nádor prednej steny nazofaryngu"},
{"code":"C11.8","desc":"Zhubný nádor nazofaryngu presahujúci viaceré oblasti"},
{"code":"C11.9","desc":"Zhubný nádor nazofaryngu, bližšie neurčený"},
{"code":"C12","desc":"Zhubný nádor recessus piriformis"},
{"code":"C13.-","desc":"Zhubný nádor hypofaryngu"},
{"code":"C13.0","desc":"Zhubný nádor regio postcricoidea"},
{"code":"C13.1","desc":"Zhubný nádor hypofaryngovej strany aryepiglotickej krkvy"},
{"code":"C13.2","desc":"Zhubný nádor zadnej steny hypofaryngu"},
{"code":"C13.8","desc":"Zhubný nádor hypofaryngu presahujúci viaceré oblasti"},
{"code":"C13.9","desc":"Zhubný nádor hypofaryngu, bližšie neurčený"},
{"code":"C14.-","desc":"Zhubný nádor pery, ústnej dutiny a faryngu na inom a nepresne určenom mieste"},
{"code":"C14.0","desc":"Zhubný nádor faryngu, bližšie neurčený"},
{"code":"C14.2","desc":"Zhubný nádor Waldeyerovho lymfatického okruhu"},
{"code":"C14.8","desc":"Zhubný nádor pery, ústnej dutiny a hltana presahujúci viaceré oblasti"},
{"code":"C15-C26","desc":"Zhubné nádory tráviacich orgánov"},
{"code":"C15.-","desc":"Zhubný nádor pažeráka"},
{"code":"C15.0","desc":"Zhubný nádor krčnej časti pažeráka"},
{"code":"C15.1","desc":"Zhubný nádor hrudníkovej časti pažeráka"},
{"code":"C15.2","desc":"Zhubný nádor brušnej časti pažeráka"},
{"code":"C15.3","desc":"Zhubný nádor hornej tretiny pažeráka"},
{"code":"C15.4","desc":"Zhubný nádor strednej tretiny pažeráka"},
{"code":"C15.5","desc":"Zhubný nádor dolnej tretiny pažeráka"},
{"code":"C15.8","desc":"Zhubný nádor pažeráka presahujúci viaceré oblasti"},
{"code":"C15.9","desc":"Zhubný nádor pažeráka, bližšie neurčený"},
{"code":"C16.-","desc":"Zhubný nádor žalúdka"},
{"code":"C16.0","desc":"Zhubný nádor kardie"},
{"code":"C16.1","desc":"Zhubný nádor fundu žalúdka"},
{"code":"C16.2","desc":"Zhubný nádor tela žalúdka"},
{"code":"C16.3","desc":"Zhubný nádor antra pyloru"},
{"code":"C16.4","desc":"Zhubný nádor pyloru"},
{"code":"C16.5","desc":"Zhubný nádor malej kurvatúry žalúdka, bližšie neurčený"},
{"code":"C16.6","desc":"Zhubný nádor veľkej kurvatúry žalúdka, bližšie neurčený"},
{"code":"C16.8","desc":"Zhubný nádor žalúdka presahujúci viaceré oblasti"},
{"code":"C16.9","desc":"Zhubný nádor žalúdka, bližšie neurčený"},
{"code":"C17.-","desc":"Zhubný nádor tenkého čreva"},
{"code":"C17.0","desc":"Zhubný nádor dvanástnika"},
{"code":"C17.1","desc":"Zhubný nádor jejúna"},
{"code":"C17.2","desc":"Zhubný nádor ilea"},
{"code":"C17.3","desc":"Zhubný nádor Meckelovho divertikula"},
{"code":"C17.8","desc":"Zhubný nádor tenkého čreva presahujúci viaceré oblasti"},
{"code":"C17.9","desc":"Zhubný nádor tenkého čreva, bližšie neurčený"},
{"code":"C18.-","desc":"Zhubný nádor hrubého čreva"},
{"code":"C18.0","desc":"Zhubný nádor slepého čreva"},
{"code":"C18.1","desc":"Zhubný nádor červovitého prívesku slepého čreva"},
{"code":"C18.2","desc":"Zhubný nádor vzostupnej časti hrubého čreva"},
{"code":"C18.3","desc":"Zhubný nádor pečeňového ohybu hrubého čreva"},
{"code":"C18.4","desc":"Zhubný nádor priečnej časti hrubého čreva"},
{"code":"C18.5","desc":"Zhubný nádor slezinového ohybu hrubého čreva"},
{"code":"C18.6","desc":"Zhubný nádor zostupnej časti hrubého čreva"},
{"code":"C18.7","desc":"Zhubný nádor esovitej časti hrubého čreva"},
{"code":"C18.8","desc":"Zhubný nádor hrubého čreva presahujúci viaceré oblasti"},
{"code":"C18.9","desc":"Zhubný nádor hrubého čreva, bližšie neurčený"},
{"code":"C19","desc":"Zhubný nádor rektosigmoidového spojenia hrubého čreva"},
{"code":"C20","desc":"Zhubný nádor konečníka"},
{"code":"C21.-","desc":"Zhubný nádor anusu a análneho kanála"},
{"code":"C21.0","desc":"Zhubný nádor anusu, bližšie neurčený"},
{"code":"C21.1","desc":"Zhubný nádor análneho kanála"},
{"code":"C21.2","desc":"Zhubný nádor kloakovej oblasti"},
{"code":"C21.8","desc":"Zhubný nádor rekta, anusu a análneho kanála presahujúci viaceré oblasti"},
{"code":"C22.-","desc":"Zhubný nádor pečene a vnútropečeňových žlčových ciest"},
{"code":"C22.0","desc":"Hepatocelulárny karcinóm"},
{"code":"C22.1","desc":"Karcinóm vnútropečeňových žlčových ciest"},
{"code":"C22.2","desc":"Hepatoblastóm"},
{"code":"C22.3","desc":"Angiosarkóm pečene"},
{"code":"C22.4","desc":"Iný sarkóm pečene"},
{"code":"C22.7","desc":"Iný karcinóm pečene, bližšie určený"},
{"code":"C22.9","desc":"Zhubný nádor pečene, bližšie neurčený"},
{"code":"C23","desc":"Zhubný nádor žlčníka"},
{"code":"C24.-","desc":"Zhubný nádor iných a bližšie neurčených častí žlčových ciest"},
{"code":"C24.0","desc":"Zhubný nádor mimopečeňových žlčových ciest"},
{"code":"C24.1","desc":"Zhubný nádor ampulla Vateri"},
{"code":"C24.8","desc":"Zhubný nádor žlčových ciest presahujúci viaceré oblasti"},
{"code":"C24.9","desc":"Zhubný nádor žlčových ciest, bližšie neurčený"},
{"code":"C25.-","desc":"Zhubný nádor podžalúdkovej žľazy"},
{"code":"C25.0","desc":"Zhubný nádor hlavy podžalúdkovej žľazy"},
{"code":"C25.1","desc":"Zhubný nádor tela podžalúdkovej žľazy"},
{"code":"C25.2","desc":"Zhubný nádor chvosta podžalúdkovej žľazy"},
{"code":"C25.3","desc":"Zhubný nádor vývodu podžalúdkovej žľazy"},
{"code":"C25.4","desc":"Zhubný nádor endokrinnej časti podžalúdkovej žľazy"},
{"code":"C25.7","desc":"Zhubný nádor iných častí podžalúdkovej žľazy"},
{"code":"C25.8","desc":"Zhubný nádor podžalúdkovej žľazy presahujúci viaceré oblasti"},
{"code":"C25.9","desc":"Zhubný nádor podžalúdkovej žľazy, bližšie neurčený"},
{"code":"C26.-","desc":"Zhubný nádor iných a nepresne určených tráviacich orgánov"},
{"code":"C26.0","desc":"Zhubný nádor bližšie neurčenej časti črevného traktu"},
{"code":"C26.1","desc":"Zhubný nádor sleziny"},
{"code":"C26.8","desc":"Zhubný nádor tráviacej sústavy presahujúci viaceré oblasti"},
{"code":"C26.9","desc":"Zhubný nádor na nepresne určenom mieste tráviacej sústavy"},
{"code":"C30-C39","desc":"Zhubné nádory dýchacích a vnútrohrudníkových orgánov"},
{"code":"C30.-","desc":"Zhubný nádor nosovej dutiny a stredného ucha"},
{"code":"C30.0","desc":"Zhubný nádor nosovej dutiny"},
{"code":"C30.1","desc":"Zhubný nádor stredného ucha"},
{"code":"C31.-","desc":"Zhubný nádor prinosových dutín"},
{"code":"C31.0","desc":"Zhubný nádor čeľustnej dutiny"},
{"code":"C31.1","desc":"Zhubný nádor čuchovej dutiny"},
{"code":"C31.2","desc":"Zhubný nádor čelovej dutiny"},
{"code":"C31.3","desc":"Zhubný nádor dutiny klinovej kosti"},
{"code":"C31.8","desc":"Zhubný nádor prinosovej dutiny presahujúci viaceré oblasti"},
{"code":"C31.9","desc":"Zhubný nádor bližšie neurčenej prinosovej dutiny"},
{"code":"C32.-","desc":"Zhubný nádor hrtana"},
{"code":"C32.0","desc":"Zhubný nádor glottis"},
{"code":"C32.1","desc":"Zhubný nádor supraglottis"},
{"code":"C32.2","desc":"Zhubný nádor subglottis"},
{"code":"C32.3","desc":"Zhubný nádor hrtanovej chrupky"},
{"code":"C32.8","desc":"Zhubný nádor hrtana presahujúci viaceré oblasti"},
{"code":"C32.9","desc":"Zhubný nádor hrtana, bližšie neurčený"},
{"code":"C33","desc":"Zhubný nádor priedušnice"},
{"code":"C34.-","desc":"Zhubný nádor priedušiek a pľúc"},
{"code":"C34.0","desc":"Zhubný nádor hlavnej priedušky"},
{"code":"C34.1","desc":"Zhubný nádor priedušky alebo pľúc v hornom laloku"},
{"code":"C34.2","desc":"Zhubný nádor priedušky alebo pľúc v strednom laloku"},
{"code":"C34.3","desc":"Zhubný nádor priedušky alebo pľúc v dolnom laloku"},
{"code":"C34.8","desc":"Zhubný nádor priedušky alebo pľúc presahujúci viaceré oblasti"},
{"code":"C34.9","desc":"Zhubný nádor priedušky alebo pľúc, bližšie neurčený"},
{"code":"C37","desc":"Zhubný nádor týmusu"},
{"code":"C38.-","desc":"Zhubný nádor srdca, medzihrudia (mediastína) a pohrudnice"},
{"code":"C38.0","desc":"Zhubný nádor srdca"},
{"code":"C38.1","desc":"Zhubný nádor predného mediastína"},
{"code":"C38.2","desc":"Zhubný nádor zadného mediastína"},
{"code":"C38.3","desc":"Zhubný nádor bližšie neurčenej časti mediastína"},
{"code":"C38.4","desc":"Zhubný nádor pohrudnice"},
{"code":"C38.8","desc":"Zhubný nádor srdca, mediastína a pohrudnice presahujúci viaceré oblasti"},
{"code":"C39.-","desc":"Zhubný nádor iných a nepresne určených miest dýchacej sústavy a vnútrohrudníkových orgánov"},
{"code":"C39.0","desc":"Zhubný nádor bližšie neurčenej časti horných dýchacích ciest"},
{"code":"C39.8","desc":"Zhubný nádor dýchacích a vnútrohrudníkových orgánov presahujúci viaceré oblasti"},
{"code":"C39.9","desc":"Zhubný nádor na nepresne určených miestach dýchacej sústavy"},
{"code":"C40-C41","desc":"Zhubné nádory kostí a kĺbovej chrupky"},
{"code":"C40.-","desc":"Zhubný nádor kosti a kĺbovej chrupky končatín"},
{"code":"C40.0","desc":"Zhubný nádor kosti a klbovej chrupky lopatky a dlhých kostí hornej končatiny"},
{"code":"C40.1","desc":"Zhubný nádor kosti a klbovej chrupky krátkych kostí hornej končatiny"},
{"code":"C40.2","desc":"Zhubný nádor kosti a klbovej chrupky dlhých kostí dolnej končatiny"},
{"code":"C40.3","desc":"Zhubný nádor kosti a klbovej chrupky krátkych kostí dolnej končatiny"},
{"code":"C40.8","desc":"Zhubný nádor kosti a kĺbovej chrupky končatín presahujúci viaceré oblasti"},
{"code":"C40.9","desc":"Zhubný nádor kosti a kĺbovej chrupky končatiny, bližšie neurčený"},
{"code":"C41.-","desc":"Zhubný nádor kosti a kĺbovej chrupky na iných a bližšie neurčených miestach"},
{"code":"C41.0-","desc":"Zhubný nádor kostí mozgovej a tvárovej časti lebky"},
{"code":"C41.01","desc":"Zhubný nádor kosti a kĺbovej chrupky v kraniofaciálnej oblasti"},
{"code":"C41.02","desc":"Zhubný nádor kosti a kĺbovej chrupky v maxilofaciálnej oblasti"},
{"code":"C41.1","desc":"Zhubný nádor kosti a kĺbovej chrupky dolnej čeľuste"},
{"code":"C41.2","desc":"Zhubný nádor kosti a kĺbovej chrupky chrbtice"},
{"code":"C41.3-","desc":"Zhubný nádor kosti a kĺbovej chrupky rebra, sterna a kľúčovej kosti"},
{"code":"C41.30","desc":"Zhubný nádor kosti a kĺbovej chrupky rebra"},
{"code":"C41.31","desc":"Zhubný nádor kosti a kĺbovej chrupky hrudnej kosti"},
{"code":"C41.32","desc":"Zhubný nádor kosti a kĺbovej chrupky kľúčnej kosti"},
{"code":"C41.4","desc":"Zhubný nádor kosti a kĺbovej chrupky panvovej kosti"},
{"code":"C41.8","desc":"Zhubný nádor kosti a kĺbovej chrupky presahujúci viaceré oblasti"},
{"code":"C41.9","desc":"Zhubný nádor kosti a kĺbovej chrupky, bližšie neurčený"},
{"code":"C43-C44","desc":"Melanóm a iné zhubné nádory kože"},
{"code":"C43.-","desc":"Malígny melanóm kože"},
{"code":"C43.0","desc":"Malígny melanóm pery"},
{"code":"C43.1","desc":"Malígny melanóm mihalnice vrátane očného kútika"},
{"code":"C43.2","desc":"Malígny melanóm ucha a vonkajšieho zvukovodu"},
{"code":"C43.3","desc":"Malígny melanóm inej a bližšie neurčenej časti tváre"},
{"code":"C43.4","desc":"Malígny melanóm vlasatej časti hlavy a krku"},
{"code":"C43.5","desc":"Malígny melanóm trupu"},
{"code":"C43.6","desc":"Malígny melanóm hornej končatiny vrátane pleca"},
{"code":"C43.7","desc":"Malígny melanóm dolnej končatiny vrátane bedra"},
{"code":"C43.8","desc":"Malígny melanóm presahujúci viaceré oblasti"},
{"code":"C43.9","desc":"Malígny melanóm kože, bližšie neurčený"},
{"code":"C44.-","desc":"Iný zhubný nádor kože"},
{"code":"C44.0","desc":"Iný zhubný nádor kože pery"},
{"code":"C44.1","desc":"Iný zhubný nádor kože mihalnice vrátane očného kútika"},
{"code":"C44.2","desc":"Iný zhubný nádor kože ucha a vonkajšieho zvukovodu"},
{"code":"C44.3","desc":"Iný zhubný nádor kože iných a bližšie neurčených častí tváre"},
{"code":"C44.4","desc":"Iný zhubný nádor kože vlasatej časti hlavy a krku"},
{"code":"C44.5","desc":"Iný zhubný nádor kože trupu"},
{"code":"C44.6","desc":"Zhubný nádor kože hornej končatiny vrátane pleca"},
{"code":"C44.7","desc":"Zhubný nádor kože dolnej končatiny vrátane bedra"},
{"code":"C44.8","desc":"Zhubný nádor kože presahujúci viaceré oblasti"},
{"code":"C44.9","desc":"Zhubný nádor kože, bližšie neurčený"},
{"code":"C45-C49","desc":"Zhubné nádory mezotelu a mäkkého tkaniva"},
{"code":"C45.-","desc":"Mezotelióm"},
{"code":"C45.0","desc":"Mezotelióm pohrudnice"},
{"code":"C45.1","desc":"Mezotelióm pobrušnice"},
{"code":"C45.2","desc":"Mezotelióm osrdcovníka"},
{"code":"C45.7","desc":"Mezotelióm na inom mieste"},
{"code":"C45.9","desc":"Mezotelióm, bližšie neurčený"},
{"code":"C46.-","desc":"Kaposiho sarkóm"},
{"code":"C46.0","desc":"Kaposiho sarkóm kože"},
{"code":"C46.1","desc":"Kaposiho sarkóm mäkkého tkaniva"},
{"code":"C46.2","desc":"Kaposiho sarkóm podnebia"},
{"code":"C46.3","desc":"Kaposiho sarkóm lymfatických uzlín"},
{"code":"C46.7","desc":"Kaposiho sarkóm na iných miestach"},
{"code":"C46.8","desc":"Kaposiho sarkóm viacerých orgánov"},
{"code":"C46.9","desc":"Kaposiho sarkóm, bližšie neurčený"},
{"code":"C47.-","desc":"Zhubný nádor periférnych nervov a autonómnej nervovej sústavy"},
{"code":"C47.0","desc":"Zhubný nádor periférnych nervov hlavy, tváre a krku"},
{"code":"C47.1","desc":"Zhubný nádor periférnych nervov hornej končatiny vrátane pleca"},
{"code":"C47.2","desc":"Zhubný nádor periférnych nervov dolnej končatiny vrátane bedra"},
{"code":"C47.3","desc":"Zhubný nádor periférnych nervov hrudníka"},
{"code":"C47.4","desc":"Zhubný nádor periférnych nervov brucha"},
{"code":"C47.5","desc":"Zhubný nádor periférnych nervov panvy"},
{"code":"C47.6","desc":"Zhubný nádor periférnych nervov trupu, bližšie neurčený"},
{"code":"C47.8","desc":"Zhubný nádor periférnych nervov a autonómnej nervovej sústavy presahujúci viaceré oblasti"},
{"code":"C47.9","desc":"Zhubný nádor periférnych nervov a autonómnej nervovej sústavy, bližšie neurčený"},
{"code":"C48.-","desc":"Zhubný nádor retroperitonea a peritonea"},
{"code":"C48.0","desc":"Zhubný nádor retroperitonea"},
{"code":"C48.1","desc":"Zhubný nádor pobrušnice bližšie určenej časti"},
{"code":"C48.2","desc":"Zhubný nádor pobrušnice, bližšie neurčenej časti"},
{"code":"C48.8","desc":"Zhubný nádor retroperitonea a pobrušnice presahujúci viaceré oblasti"},
{"code":"C49.-","desc":"Zhubný nádor iného spojivového a mäkkého tkaniva"},
{"code":"C49.0","desc":"Zhubný nádor spojivového a mäkkého tkaniva hlavy, tváre a krku"},
{"code":"C49.1","desc":"Zhubný nádor spojivového a mäkkého tkaniva hornej končatiny vrátane pleca"},
{"code":"C49.2","desc":"Zhubný nádor spojivového a mäkkého tkaniva dolnej končatiny vrátane bedra"},
{"code":"C49.3","desc":"Zhubný nádor spojivového a mäkkého tkaniva hrudníka"},
{"code":"C49.4","desc":"Zhubný nádor spojivového a mäkkého tkaniva brucha"},
{"code":"C49.5","desc":"Zhubný nádor spojivového a mäkkého tkaniva malej panvy"},
{"code":"C49.6","desc":"Zhubný nádor spojivového a mäkkého tkaniva trupu, bližšie neurčený"},
{"code":"C49.8","desc":"Zhubný nádor spojivového a mäkkého tkaniva presahujúci viaceré oblasti"},
{"code":"C49.9","desc":"Zhubný nádor spojivového a mäkkého tkaniva, bližšie neurčený"},
{"code":"C50-C50","desc":"Zhubné nádory prsníka"},
{"code":"C50.-","desc":"Zhubný nádor prsníka"},
{"code":"C50.0","desc":"Zhubný nádor prsníkovej bradavky a dvorčeka"},
{"code":"C50.1","desc":"Zhubný nádor strednej časti prsníka"},
{"code":"C50.2","desc":"Zhubný nádor horného vnútorného kvadrantu prsníka"},
{"code":"C50.3","desc":"Zhubný nádor dolného vnútorného kvadrantu prsníka"},
{"code":"C50.4","desc":"Zhubný nádor horného vonkajšieho kvadrantu prsníka"},
{"code":"C50.5","desc":"Zhubný nádor dolného vonkajšieho kvadrantu prsníka"},
{"code":"C50.6","desc":"Zhubný nádor axilárnej časti prsníka"},
{"code":"C50.8","desc":"Zhubný nádor prsníka presahujúci viaceré oblasti"},
{"code":"C50.9","desc":"Zhubný nádor prsníka, bližšie neurčený"},
{"code":"C51-C58","desc":"Zhubné nádory ženských pohlavných orgánov"},
{"code":"C51.-","desc":"Zhubný nádor vulvy"},
{"code":"C51.0","desc":"Zhubný nádor veľkého pysku"},
{"code":"C51.1","desc":"Zhubný nádor malého pysku"},
{"code":"C51.2","desc":"Zhubný nádor klitorisu"},
{"code":"C51.8","desc":"Zhubný nádor vulvy presahujúci viaceré oblasti"},
{"code":"C51.9","desc":"Zhubný nádor vulvy, bližšie neurčený"},
{"code":"C52","desc":"Zhubný nádor pošvy"},
{"code":"C53.-","desc":"Zhubný nádor krčka maternice"},
{"code":"C53.0","desc":"Zhubný nádor endocervixu maternice"},
{"code":"C53.1","desc":"Zhubný nádor exocervixu maternice"},
{"code":"C53.8","desc":"Zhubný nádor krčka maternice presahujúci viaceré oblasti"},
{"code":"C53.9","desc":"Zhubný nádor krčka maternice, bližšie neurčený"},
{"code":"C54.-","desc":"Zhubný nádor tela maternice"},
{"code":"C54.0","desc":"Zhubný nádor istmu maternice"},
{"code":"C54.1","desc":"Zhubný nádor endometria"},
{"code":"C54.2","desc":"Zhubný nádor myometria"},
{"code":"C54.3","desc":"Zhubný nádor dna maternice"},
{"code":"C54.8","desc":"Zhubný nádor tela maternice presahujúci viaceré oblasti"},
{"code":"C54.9","desc":"Zhubný nádor tela maternice, bližšie neurčený"},
{"code":"C55","desc":"Zhubný nádor bližšie neurčenej časti maternice"},
{"code":"C56","desc":"Zhubný nádor vaječníka"},
{"code":"C57.-","desc":"Zhubný nádor iných a bližšie neurčených ženských pohlavných orgánov"},
{"code":"C57.0","desc":"Zhubný nádor vajíčkovodu"},
{"code":"C57.1","desc":"Zhubný nádor širokého väzu maternice"},
{"code":"C57.2","desc":"Zhubný nádor oblého väzu maternice"},
{"code":"C57.3","desc":"Zhubný nádor parametria"},
{"code":"C57.4","desc":"Zhubný nádor adnexov maternice, bližšie neurčený"},
{"code":"C57.7","desc":"Zhubný nádor iných ženských pohlavných orgánov, bližšie určených"},
{"code":"C57.8","desc":"Zhubný nádor ženských pohlavných orgánov presahujúci viaceré oblasti"},
{"code":"C57.9","desc":"Zhubný nádor ženského pohlavného orgánu, bližšie neurčeného"},
{"code":"C58","desc":"Zhubný nádor placenty"},
{"code":"C60-C63","desc":"Zhubné nádory mužských pohlavných orgánov"},
{"code":"C60.-","desc":"Zhubný nádor penisu"},
{"code":"C60.0","desc":"Zhubný nádor predkožky penisu"},
{"code":"C60.1","desc":"Zhubný nádor žaluďa penisu"},
{"code":"C60.2","desc":"Zhubný nádor tela penisu"},
{"code":"C60.8","desc":"Zhubný nádor penisu presahujúci viaceré oblasti"},
{"code":"C60.9","desc":"Zhubný nádor penisu, bližšie neurčený"},
{"code":"C61","desc":"Zhubný nádor prostaty"},
{"code":"C62.-","desc":"Zhubný nádor semenníka"},
{"code":"C62.0","desc":"Zhubný nádor nezostúpeného semenníka"},
{"code":"C62.1","desc":"Zhubný nádor zostúpeného semenníka"},
{"code":"C62.9","desc":"Zhubný nádor semenníka, bližšie neurčený"},
{"code":"C63.-","desc":"Zhubný nádor iných a bližšie neurčených mužských pohlavných orgánov"},
{"code":"C63.0","desc":"Zhubný nádor nadsemenníka"},
{"code":"C63.1","desc":"Zhubný nádor semenného povrazca"},
{"code":"C63.2","desc":"Zhubný nádor mieška"},
{"code":"C63.7","desc":"Zhubný nádor iných mužských pohlavných orgánov, bližšie určených"},
{"code":"C63.8","desc":"Zhubný nádor mužských pohlavných orgánov presahujúci viaceré oblasti"},
{"code":"C63.9","desc":"Zhubný nádor mužského pohlavného orgánu, bližšie neurčeného"},
{"code":"C64-C68","desc":"Zhubné nádory močovej sústavy"},
{"code":"C64","desc":"Zhubný nádor obličky okrem obličkovej panvičky"},
{"code":"C65","desc":"Zhubný nádor obličkovej panvičky"},
{"code":"C66","desc":"Zhubný nádor močovodu"},
{"code":"C67.-","desc":"Zhubný nádor močového mechúra"},
{"code":"C67.0","desc":"Zhubný nádor trigonum močového mechúra"},
{"code":"C67.1","desc":"Zhubný nádor klenby močového mechúra"},
{"code":"C67.2","desc":"Zhubný nádor bočnej steny močového mechúra"},
{"code":"C67.3","desc":"Zhubný nádor prednej steny močového mechúra"},
{"code":"C67.4","desc":"Zhubný nádor zadnej steny močového mechúra"},
{"code":"C67.5","desc":"Zhubný nádor krčka močového mechúra"},
{"code":"C67.6","desc":"Zhubný nádor ústia močovodu"},
{"code":"C67.7","desc":"Zhubný nádor urachu"},
{"code":"C67.8","desc":"Zhubný nádor močového mechúra presahujúci viaceré oblasti"},
{"code":"C67.9","desc":"Zhubný nádor močového mechúra, bližšie neurčený"},
{"code":"C68.-","desc":"Zhubný nádor iných a bližšie neurčených močových orgánov"},
{"code":"C68.0","desc":"Zhubný nádor močovej rúry"},
{"code":"C68.1","desc":"Zhubný nádor parauretrálnej žľazy"},
{"code":"C68.8","desc":"Zhubný nádor močových orgánov presahujúci viaceré oblasti"},
{"code":"C68.9","desc":"Zhubný nádor bližšie neurčených močových orgánov"},
{"code":"C69-C72","desc":"Zhubné nádory oka, mozgu a iných častí centrálnej nervovej sústavy"},
{"code":"C69.-","desc":"Zhubný nádor oka a očných adnexov"},
{"code":"C69.0","desc":"Zhubný nádor spojovky"},
{"code":"C69.1","desc":"Zhubný nádor rohovky"},
{"code":"C69.2","desc":"Zhubný nádor sietnice"},
{"code":"C69.3","desc":"Zhubný nádor cievovky"},
{"code":"C69.4","desc":"Zhubný nádor vráskovca"},
{"code":"C69.5","desc":"Zhubný nádor slznej žľazy a nosovoslzného kanála"},
{"code":"C69.6","desc":"Zhubný nádor očnice"},
{"code":"C69.8","desc":"Zhubný nádor oka a očných adnexov presahujúci viaceré oblasti"},
{"code":"C69.9","desc":"Zhubný nádor oka, bližšie neurčený"},
{"code":"C70.-","desc":"Zhubný nádor mozgovomiechových obalov"},
{"code":"C70.0","desc":"Zhubný nádor mozgovomiechových obalov: mozog"},
{"code":"C70.1","desc":"Zhubný nádor mozgovomiechových obalov: miecha"},
{"code":"C70.9","desc":"Zhubný nádor mozgovomoechových obalov, bližšie neurčený"},
{"code":"C71.-","desc":"Zhubný nádor mozgu"},
{"code":"C71.0","desc":"Zhubný nádor mozgu okrem lalokov a komôr"},
{"code":"C71.1","desc":"Zhubný nádor čelového laloka mozgu"},
{"code":"C71.2","desc":"Zhubný nádor spánkového laloka mozgu"},
{"code":"C71.3","desc":"Zhubný nádor temenného laloka mozgu"},
{"code":"C71.4","desc":"Zhubný nádor záhlavového laloka mozgu"},
{"code":"C71.5","desc":"Zhubný nádor mozgovej komory mozgu"},
{"code":"C71.6","desc":"Zhubný nádor mozočka"},
{"code":"C71.7","desc":"Zhubný nádor mozgového kmeňa"},
{"code":"C71.8","desc":"Zhubný nádor mozgu presahujúci viaceré oblasti"},
{"code":"C71.9","desc":"Zhubný nádor mozgu, bližšie neurčený"},
{"code":"C72.-","desc":"Zhubný nádor miechy, hlavových nervov a iných častí centrálnej nervovej sústavy"},
{"code":"C72.0","desc":"Zhubný nádor miechy"},
{"code":"C72.1","desc":"Zhubný nádor cauda equina"},
{"code":"C72.2","desc":"Zhubný nádor čuchového nervu [nn.olfactorii]"},
{"code":"C72.3","desc":"Zhubný nádor zrakového nervu [n.opticus]"},
{"code":"C72.4","desc":"Zhubný nádor sluchového nervu [n.vestibulocochlearis]"},
{"code":"C72.5","desc":"Zhubný nádor iných a bližšie neurčených hlavových nervov"},
{"code":"C72.8","desc":"Zhubný nádor, lézia presahujúca mozog a iné časti centrálnej nervovej sústavy"},
{"code":"C72.9","desc":"Zhubný nádor centrálnej nervovej sústavy, bližšie neurčený"},
{"code":"C73-C75","desc":"Zhubné nádory štítnej žľazy a iných žliaz s vnútorným vylučovaním"},
{"code":"C73","desc":"Zhubný nádor štítnej žľazy"},
{"code":"C74.-","desc":"Zhubný nádor nadobličky"},
{"code":"C74.0","desc":"Zhubný nádor kôry nadobličky"},
{"code":"C74.1","desc":"Zhubný nádor drene nadobličky"},
{"code":"C74.9","desc":"Zhubný nádor nadobličky, bližšie neurčený"},
{"code":"C75.-","desc":"Zhubný nádor iných žliaz s vnútorným vylučovaním a blízkych štruktúr"},
{"code":"C75.0","desc":"Zhubný nádor prištítnej žľazy"},
{"code":"C75.1","desc":"Zhubný nádor podmozgovej žľazy"},
{"code":"C75.2","desc":"Zhubný nádor kraniofaryngového vývodu"},
{"code":"C75.3","desc":"Zhubný nádor epifýzy"},
{"code":"C75.4","desc":"Zhubný nádor glomus caroticum"},
{"code":"C75.5","desc":"Zhubný nádor glomus aorticum a iných paraganglií"},
{"code":"C75.8","desc":"Zhubný nádor s postihnutím viacerých žliaz, bližšie neurčený"},
{"code":"C75.9","desc":"Zhubný nádor žľazy s vnútorným vylučovaním, bližšie neurčený"},
{"code":"C76-C80","desc":"Zhubné nádory na nepresne určených, sekundárnych a bližšie neurčených miestach"},
{"code":"C76.-","desc":"Zhubný nádor na iných a nepresne určených miestach"},
{"code":"C76.0","desc":"Zhubný nádor na iných a nepresne určených miestach hlavy, tváre a krku"},
{"code":"C76.1","desc":"Zhubný nádor na iných a nepresne určených miestach hrudníka"},
{"code":"C76.2","desc":"Zhubný nádor na iných a nepresne určených miestach brucha"},
{"code":"C76.3","desc":"Zhubný nádor na iných a nepresne určených miestach panvy"},
{"code":"C76.4","desc":"Zhubný nádor na iných a nepresne určených miestach hornej končatiny"},
{"code":"C76.5","desc":"Zhubný nádor na iných a nepresne určených miestach dolnej končatiny"},
{"code":"C76.7","desc":"Zhubný nádor na iných nepresne určených miestach"},
{"code":"C76.8","desc":"Zhubný nádor na iných a nepresne určených miestach presahujúci viaceré oblasti"},
{"code":"C77.-","desc":"Sekundárny a bližšie neurčený zhubný nádor lymfatických uzlín"},
{"code":"C77.0","desc":"Sekundárny a bližšie neurčený zhubný nádor lymfatických uzlín hlavy, tváre a krku"},
{"code":"C77.1","desc":"Sekundárny a bližšie neurčený zhubný nádor vnútrohrudníkovej lymfatickej uzlíny"},
{"code":"C77.2","desc":"Sekundárny a bližšie neurčený zhubný nádor vnútrobrušnej lymfatickej uzliny"},
{"code":"C77.3","desc":"Sekundárny a bližšie neurčený zhubný nádor lymfatických uzlín pazuchy a hornej končatiny"},
{"code":"C77.4","desc":"Sekundárny a bližšie neurčený zhubný nádor lymfatických uzlín slabiny a lymfatických uzlín dolnej končatiny"},
{"code":"C77.5","desc":"Sekundárny a bližšie neurčený zhubný nádor vnútropanvových lymfatických uzlín"},
{"code":"C77.8","desc":"Sekundárny a bližšie neurčený zhubný nádor lymfatických uzlín viacerých oblastí"},
{"code":"C77.9","desc":"Sekundárny a bližšie neurčený zhubný nádor bližšie neurčených lymfatických uzlín"},
{"code":"C78.-","desc":"Sekundárny zhubný nádor dýchacích a tráviacich orgánov"},
{"code":"C78.0","desc":"Sekundárny zhubný nádor pľúc"},
{"code":"C78.1","desc":"Sekundárny zhubný nádor mediastína"},
{"code":"C78.2","desc":"Sekundárny zhubný nádor pohrudnice"},
{"code":"C78.3","desc":"Sekundárny zhubný nádor iných a bližšie neurčených dýchacích orgánov"},
{"code":"C78.4","desc":"Sekundárny zhubný nádor tenkého čreva"},
{"code":"C78.5","desc":"Sekundárny zhubný nádor hrubého čreva a konečníka"},
{"code":"C78.6","desc":"Sekundárny zhubný nádor retroperitonea a pobrušnice"},
{"code":"C78.7","desc":"Sekundárny zhubný nádor pečene a vnútropečeňových žlčovodov"},
{"code":"C78.8","desc":"Sekundárny zhubný nádor iných a bližšie neurčených tráviacich orgánov"},
{"code":"C79.-","desc":"Sekundárny zhubný nádor na iných a bližšie neurčených miestach"},
{"code":"C79.0","desc":"Sekundárny zhubný nádor obličky a obličkovej panvičky"},
{"code":"C79.1","desc":"Sekundárny zhubný nádor močového mechúra a iných a bližšie neurčených močových orgánov"},
{"code":"C79.2","desc":"Sekundárny zhubný nádor kože"},
{"code":"C79.3","desc":"Sekundárny zhubný nádor mozgu a mozgových plien"},
{"code":"C79.4","desc":"Sekundárny zhubný nádor iných a bližšie neurčených častí nervovej sústavy"},
{"code":"C79.5","desc":"Sekundárny zhubný nádor kosti a kostnej drene"},
{"code":"C79.6","desc":"Sekundárny zhubný nádor vaječníka"},
{"code":"C79.7","desc":"Sekundárny zhubný nádor nadobličky"},
{"code":"C79.8-","desc":"Sekundárny zhubný nádor na iných bližšie určených miestach"},
{"code":"C79.81","desc":"Sekundárny zhubný nádor prsníka"},
{"code":"C79.82","desc":"Sekundárny zhubný nádor pohlavných orgánov"},
{"code":"C79.83","desc":"Sekundárny zhubný nádor perikardu"},
{"code":"C79.84","desc":"Iné sekundárne zhubné nádory srdca"},
{"code":"C79.88","desc":"Sekundárny zhubný nádor na iných bližšie určených miestach"},
{"code":"C79.9","desc":"Sekundárny zhubný nádor bez bližšieho určenia miesta"},
{"code":"C80.-","desc":"Zhubný nádor bez údajov o mieste"},
{"code":"C80.0","desc":"Zhubný nádor, bližšie neznámej primárnej lokalizácie, tak označený"},
{"code":"C80.9","desc":"Zhubný nádor, bližšie neurčenej primárnej lokalizácie"},
{"code":"C81-C96","desc":"Zhubné nádory s pôvodom alebo s predpokladaným pôvodom z lymfatického, hemopoetického a príbuzného tkaniva"},
{"code":"C81.-","desc":"Hodgkinov lymfóm"},
{"code":"C81.0","desc":"Nodulárny Hodgkinov lymfóm s prevahou lymfocytov"},
{"code":"C81.1","desc":"Klasický Hodgkinov lymfóm s nodulárnou sklerózou"},
{"code":"C81.2","desc":"Klasický Hodgkinov lymfóm so zmiešanou celularitou"},
{"code":"C81.3","desc":"Klasický Hodgkinov lymfóm chudobný na lymfocyty"},
{"code":"C81.4","desc":"Klasický Hodgkinov lymfóm bohatý na lymfocyty"},
{"code":"C81.7","desc":"Iný klasický Hodgkinov lymfóm"},
{"code":"C81.9","desc":"Hodgkinov lymfóm, bližšie neurčený"},
{"code":"C82.-","desc":"Folikulový lymfóm"},
{"code":"C82.0","desc":"Folikulový lymfóm, štádium I"},
{"code":"C82.1","desc":"Folikulový lymfóm, štádium II"},
{"code":"C82.2","desc":"Folikulový lymfóm, štádium III, bližšie neurčený"},
{"code":"C82.3","desc":"Folikulový lymfóm, štádium IIIa"},
{"code":"C82.4","desc":"Folikulový lymfóm, štádium IIIb"},
{"code":"C82.5","desc":"Difúzny lymfóm folikulového centra"},
{"code":"C82.6","desc":"Kožný lymfóm folikulového centra"},
{"code":"C82.7","desc":"Iný typ folikulového lymfómu"},
{"code":"C82.9","desc":"Folikulový lymfóm, bližšie neurčený"},
{"code":"C83.-","desc":"Nefolikulový lymfóm"},
{"code":"C83.0","desc":"Malobunkový lymfóm z B-buniek"},
{"code":"C83.1","desc":"Lymfóm z plášťových buniek"},
{"code":"C83.3","desc":"Difúzny veľkobunkový lymfóm z B-buniek"},
{"code":"C83.5","desc":"Lymfoblastový lymfóm"},
{"code":"C83.7","desc":"Burkittov lymfóm"},
{"code":"C83.8","desc":"Iný nefolikulový lymfóm"},
{"code":"C83.9","desc":"Nefolikulový lymfóm, bližšie neurčený"},
{"code":"C84.-","desc":"Lymfóm zo zrelých T/NK-buniek"},
{"code":"C84.0","desc":"Mycosis fungoides"},
{"code":"C84.1","desc":"Sézaryho choroba"},
{"code":"C84.4","desc":"Periférny lymfóm z T-buniek, nezatriedený"},
{"code":"C84.5","desc":"Iný lymfóm zo zrelých T/NK-buniek"},
{"code":"C84.6","desc":"Anaplastický veľkobunkový lymfóm, ALK-pozitívny"},
{"code":"C84.7","desc":"Anaplastický veľkobunkový lymfóm, ALK-negatívny"},
{"code":"C84.8","desc":"Kožný lymfóm z T-buniek, bližšie neurčený"},
{"code":"C84.9","desc":"Lymfóm zo zrelých T/NK-buniek, bližšie neurčený"},
{"code":"C85.-","desc":"Iný a bližšie neurčený typ non-Hodgkinovho lymfómu"},
{"code":"C85.1","desc":"Lymfóm z B-buniek, bližšie neurčený"},
{"code":"C85.2","desc":"Mediastinálny (týmusový) veľkobunkový lymfóm z B-buniek"},
{"code":"C85.7","desc":"Iný bližšie určený typ non-Hodgkinovho lymfómu"},
{"code":"C85.9","desc":"Non-Hodgkinov lymfóm, bližšie neurčený"},
{"code":"C86.-","desc":"Iný lymfóm z T/NK-buniek, bližšie určený"},
{"code":"C86.0","desc":"Extranodálny lymfóm z NK/T- buniek, nosový typ"},
{"code":"C86.1","desc":"Hepatosplenický lymfóm z T-buniek"},
{"code":"C86.2","desc":"Enteropatický (intestinálny) lymfóm z T-buniek"},
{"code":"C86.3","desc":"Podkožný panikulitíde podobný lymfóm z T-buniek"},
{"code":"C86.4","desc":"Blastový lymfóm z NK-buniek"},
{"code":"C86.5","desc":"Angioimunoblastový lymfóm z T-buniek"},
{"code":"C86.6","desc":"Primárna kožná CD30-pozitívna lymfoproliferatívna choroba z T-buniek"},
{"code":"C88.-","desc":"Zhubná imunoproliferatívna choroba"},
{"code":"C88.0-","desc":"Waldenströmova makroglobulinémia"},
{"code":"C88.00","desc":"Waldenströmova makroglobulinémia, bez kompletnej remisie"},
{"code":"C88.01","desc":"Waldenströmova makroglobulinémia, v kompletnej remisii"},
{"code":"C88.2-","desc":"Iné choroby ťažkých reťazcov"},
{"code":"C88.20","desc":"Iná choroba ťažkých reťazcov, bez kompletnej remisie"},
{"code":"C88.21","desc":"Iná choroba ťažkých reťazcov, v kompletnej remisii"},
{"code":"C88.3-","desc":"Imunoproliferatívna choroba tenkého čreva"},
{"code":"C88.30","desc":"Imunoproliferatívna choroba tenkého čreva, bez kompletnej remisie"},
{"code":"C88.31","desc":"Imunoproliferatívna choroba tenkého čreva, v kompletnej remisii"},
{"code":"C88.4-","desc":"Extranodálny lymfóm z B-buniek marginálnej zóny lymfatického tkaniva sliznice (MALT-lymfóm)"},
{"code":"C88.40","desc":"Extranodálny lymfóm z B-buniek marginálnej zóny lymfatického tkaniva sliznice (MALT-lymfóm), bez kompletnej remisie"},
{"code":"C88.41","desc":"Extranodálny lymfóm z B-buniek marginálnej zóny lymfatického tkaniva sliznice (MALT-lymfóm), v kompletnej remisii"},
{"code":"C88.7-","desc":"Iná zhubná imunoproliferatívna choroba"},
{"code":"C88.70","desc":"Iná zhubná imunoproliferatívna choroba, bez kompletnej remisie"},
{"code":"C88.71","desc":"Iná zhubná imunoproliferatívna choroba, v kompletnej remisii"},
{"code":"C88.9-","desc":"Zhubná imunoproliferatívna choroba, bližšie neurčená"},
{"code":"C88.90","desc":"Zhubná imunoproliferatívna choroba, bližšie neurčená, bez kompletnej remisie"},
{"code":"C88.91","desc":"Zhubná imunoproliferatívna choroba, bližšie neurčená, v kompletnej remisii"},
{"code":"C90.-","desc":"Mnohopočetný myelóm a zhubné nádory z plazmatických buniek"},
{"code":"C90.0-","desc":"Mnohopočetný myelóm"},
{"code":"C90.00","desc":"Mnohopočetný myelóm, bez kompletnej remisie"},
{"code":"C90.01","desc":"Mnohopočetný myelóm, v kompletnej remisii"},
{"code":"C90.1-","desc":"Plazmocytová leukémia"},
{"code":"C90.10","desc":"Plazmocytová leukémia, bez kompletnej remisie"},
{"code":"C90.11","desc":"Plazmocytová leukémia, v kompletnej remisii"},
{"code":"C90.2-","desc":"Extramedulárny plazmocytóm"},
{"code":"C90.20","desc":"Extramedulárny plazmocytóm, bez kompletnej remisie"},
{"code":"C90.21","desc":"Extramedulárny plazmocytóm, v kompletnej remisii"},
{"code":"C90.3-","desc":"Solitárny plazmocytóm"},
{"code":"C90.30","desc":"Solitárny plazmocytóm, bez kompletnej remisie"},
{"code":"C90.31","desc":"Solitárny plazmocytóm, v kompletnej remisii"},
{"code":"C91.-","desc":"Lymfocytová leukémia"},
{"code":"C91.0-","desc":"Akútna lymfoblastová leukémia (ALL)"},
{"code":"C91.00","desc":"Akútna lymfoblastová leukémia, bez kompletnej remisie"},
{"code":"C91.01","desc":"Akútna lymfoblastová leukémia, v kompletnej remisii"},
{"code":"C91.1-","desc":"Chronická lymfatická leukémia z B-buniek"},
{"code":"C91.10","desc":"Chronická lymfatická leukémia, bez kompletnej remisie"},
{"code":"C91.11","desc":"Chronická lymfatická leukémia, v kompletnej remisii"},
{"code":"C91.3-","desc":"Prolymfocytová leukémia z B-buniek"},
{"code":"C91.30","desc":"Prolymfocytová leukémia z B-buniek, bez kompletnej remisie"},
{"code":"C91.31","desc":"Prolymfocytová leukémia z B-buniek, v kompletnej remisii"},
{"code":"C91.4-","desc":"Vlasatobunková leukémia"},
{"code":"C91.40","desc":"Vlasatobunková leukémia, bez kompletnej remisie"},
{"code":"C91.41","desc":"Vlasatobunková leukémia, v kompletnej remisii"},
{"code":"C91.5-","desc":"Leukémia /lymfóm z dospelých T-buniek spojený s infekciou HTLV-1"},
{"code":"C91.50","desc":"Leukémia /lymfóm z dospelých T-buniek spojený s infekciou HTLV-1, bez kompletnej remisie"},
{"code":"C91.51","desc":"Leukémia /lymfóm z dospelých T-buniek spojený s infekciou HTLV-1, v kompletnej remisii"},
{"code":"C91.6-","desc":"Prolymfocytová leukémia z T-buniek"},
{"code":"C91.60","desc":"Prolymfocytová leukémia z T-buniek, bez kompletnej remisie"},
{"code":"C91.61","desc":"Prolymfocytová leukémia z T-buniek, v kompletnej remisii"},
{"code":"C91.7-","desc":"Iná lymfatická leukémia"},
{"code":"C91.70","desc":"Iná lymfatická leukémia, bez kompletnej remisie"},
{"code":"C91.71","desc":"Iná lymfatická leukémia, v kompletnej remisii"},
{"code":"C91.8-","desc":"Leukémia zo zrelých B-buniek Burkittovho typu"},
{"code":"C91.80","desc":"Leukémia zo zrelých B-buniek Burkittovho typu, bez kompletnej remisie"},
{"code":"C91.81","desc":"Leukémia zo zrelých B-buniek Burkittovho typu, v kompletnej remisii"},
{"code":"C91.9-","desc":"Lymfatická leukémia, bližšie neurčená"},
{"code":"C91.90","desc":"Lymfatická leukémia, bližšie neurčená, bez kompletnej remisie"},
{"code":"C91.91","desc":"Lymfatická leukémia, bližšie neurčená, v kompletnej remisii"},
{"code":"C92.-","desc":"Myeloická leukémia"},
{"code":"C92.0-","desc":"Akútna myeloblastová leukémia (AML)"},
{"code":"C92.00","desc":"Akútna myeloblastová leukémia (AML), bez kompletnej remisie"},
{"code":"C92.01","desc":"Akútna myeloblastová leukémia (AML), v kompletnej remisii"},
{"code":"C92.1-","desc":"Chronická myeloická leukémia (CML), BCR/ABL-pozitívna"},
{"code":"C92.10","desc":"Chronická myeloická leukémia BCR/ABL-pozitívna, bez kompletnej remisie"},
{"code":"C92.11","desc":"Chronická myeloická leukémia BCR/ABL-pozitívna, v kompletnej remisii"},
{"code":"C92.2-","desc":"Atypická chronická myeloická leukémia, BCR/ABL-negatívna"},
{"code":"C92.20","desc":"Atypická chronická myeloická leukémia, BCR/ABL-negatívna, bez kompletnej remisie"},
{"code":"C92.21","desc":"Atypická chronická myeloická leukémia BCR/ABL-negatívna, v kompletnej remisii"},
{"code":"C92.3-","desc":"Myelosarkóm"},
{"code":"C92.30","desc":"Myelosarkóm, bez kompletnej remisie"},
{"code":"C92.31","desc":"Myelosarkóm, v kompletnej remisii"},
{"code":"C92.4-","desc":"Akútna promyelocytová leukémia (PCL)"},
{"code":"C92.40","desc":"Akútna promyelocytová leukémia, bez kompletnej remisie"},
{"code":"C92.41","desc":"Akútna promyelocytová leukémia, v kompletnej remisii"},
{"code":"C92.5-","desc":"Akútna myelomonocytová leukémia"},
{"code":"C92.50","desc":"Akútna myelomonocytová leukémia, bez kompletnej remisie"},
{"code":"C92.51","desc":"Akútna myelomonocytová leukémia, v kompletnej remisii"},
{"code":"C92.6-","desc":"Akútna myeloická leukémia s 11q23 abnormalitou"},
{"code":"C92.60","desc":"Akútna myeloická leukémia s 11q23 abnormalitou, bez kompletnej remisie"},
{"code":"C92.61","desc":"Akútna myeloická leukémia s 11q23 abnormalitou, v kompletnej remisii"},
{"code":"C92.7-","desc":"Iná myeloická leukémia"},
{"code":"C92.70","desc":"Iná myeloická leukémia, bez kompletnej remisie"},
{"code":"C92.71","desc":"Iná myeloická leukémia, v kompletnej remisii"},
{"code":"C92.8-","desc":"Akútna myeloická leukémia s multilineárnou dyspláziou"},
{"code":"C92.80","desc":"Akútna myeloická leukémia s multilineárnou dyspláziou, bez kompletnej remisie"},
{"code":"C92.81","desc":"Akútna myeloická leukémia s multilineárnou dyspláziou, v kompletnej remisii"},
{"code":"C92.9-","desc":"Myeloická leukémia, bližšie neurčená"},
{"code":"C92.90","desc":"Myeloická leukémia, bližšie neurčená, bez kompletnej remisie"},
{"code":"C92.91","desc":"Myeloická leukémia, bližšie neurčená, v kompletnej remisii"},
{"code":"C93.-","desc":"Monocytová leukémia"},
{"code":"C93.0-","desc":"Akútna monoblastová/monocytová leukémia"},
{"code":"C93.00","desc":"Akútna monoblastová/monocytová leukémia, bez kompletnej remisie"},
{"code":"C93.01","desc":"Akútna monoblastová/monocytová leukémia, v kompletnej remisii"},
{"code":"C93.1-","desc":"Chronická myelomonocytová leukémia"},
{"code":"C93.10","desc":"Chronická myelomonocytová leukémia, bez kompletnej remisie"},
{"code":"C93.11","desc":"Chronická myelomonocytová leukémia, v kompletnej remisii"},
{"code":"C93.3-","desc":"Juvenilná myelomonocytová leukémia"},
{"code":"C93.30","desc":"Juvenilná myelomonocytová leukémia, bez kompletnej remisie"},
{"code":"C93.31","desc":"Juvenilná myelomonocytová leukémia, v kompletnej remisii"},
{"code":"C93.7-","desc":"Iná monocytová leukémia"},
{"code":"C93.70","desc":"Iná monocytová leukémia, bez kompletnej remisie"},
{"code":"C93.71","desc":"Iná monocytová leukémia, v kompletnej remisii"},
{"code":"C93.9-","desc":"Monocytová leukémia, bližšie neurčená"},
{"code":"C93.90","desc":"Monocytová leukémia, bližšie neurčená, bez kompletnej remisie"},
{"code":"C93.91","desc":"Monocytová leukémia, bližšie neurčená, v kompletnej remisii"},
{"code":"C94.-","desc":"Iná leukémia z bližšie určených buniek"},
{"code":"C94.0-","desc":"Akútna erytroleukémia"},
{"code":"C94.00","desc":"Akútna erytroleukémia, bez kompletnej remisie"},
{"code":"C94.01","desc":"Akútna erytroleukémia, v kompletnej remisii"},
{"code":"C94.2-","desc":"Akútna megakaryoblastová leukémia"},
{"code":"C94.20","desc":"Akútna megakaryoblastová leukémia, bez kompletnej remisie"},
{"code":"C94.21","desc":"Akútna megakaryoblastová leukémia, v kompletnej remisii"},
{"code":"C94.3-","desc":"Mastocytová leukémia"},
{"code":"C94.30","desc":"Mastocytová leukémia, bez kompletnej remisie"},
{"code":"C94.31","desc":"Mastocytová leukémia, v kompletnej remisii"},
{"code":"C94.4-","desc":"Akútna panmyelóza s myelofibrózou"},
{"code":"C94.40","desc":"Akútna panmyelóza s myelofibrózou, bez kompletnej remisie"},
{"code":"C94.41","desc":"Akútna panmyelóza s myelofibrózou, v kompletnej remisii"},
{"code":"C94.6-","desc":"Myelodysplastická a myeloproliferatívna choroba, inde nezatriedená"},
{"code":"C94.60","desc":"Myelodysplastická a myeloproliferatívna choroba, inde nezatriedená, bez kompletnej remisie"},
{"code":"C94.61","desc":"Myelodysplastická a myeloproliferatívna choroba, inde nezatriedená, v kompletnej remisii"},
{"code":"C94.7-","desc":"Iná bližšie určená leukémia"},
{"code":"C94.70","desc":"Iná bližšie určená leukémia, bez kompletnej remisie"},
{"code":"C94.71","desc":"Iná bližšie určená leukémia, v kompletnej remisii"},
{"code":"C94.8!","desc":"Blastová kríza pri chronickej myeloickej leukémii (CML)"},
{"code":"C95.-","desc":"Leukémia z bližšie neurčených buniek"},
{"code":"C95.0-","desc":"Akútna leukémia bližšie neurčeného bunkového typu"},
{"code":"C95.00","desc":"Akútna leukémia bližšie neurčeného bunkového typu, bez kompletnej remisie"},
{"code":"C95.01","desc":"Akútna leukémia bližšie neurčeného bunkového typu, v kompletnej remisii"},
{"code":"C95.1-","desc":"Chronická leukémia bližšie neurčeného bunkového typu"},
{"code":"C95.10","desc":"Chronická leukémia bližšie neurčeného bunkového typu, bez kompletnej remisie"},
{"code":"C95.11","desc":"Chronická leukémia bližšie neurčeného bunkového typu, v kompletnej remisii"},
{"code":"C95.7-","desc":"Iná leukémia bližšie neurčeného bunkového typu"},
{"code":"C95.70","desc":"Iná leukémia bližšie neurčeného bunkového typu, bez kompletnej remisie"},
{"code":"C95.71","desc":"Iná leukémia bližšie neurčeného bunkového typu, v kompletnej remisii"},
{"code":"C95.8!","desc":"Leukémia refraktérna na štandardnú indukčnú liečbu"},
{"code":"C95.9-","desc":"Leukémia, bližšie neurčená"},
{"code":"C95.90","desc":"Leukémia, bližšie neurčená, bez kompletnej remisie"},
{"code":"C95.91","desc":"Leukémia, bližšie neurčená, v kompletnej remisii"},
{"code":"C96.-","desc":"Iný a bližšie neurčený zhubný nádor lymfatického, krvotvorného a príbuzného tkaniva"},
{"code":"C96.0","desc":"Multifokálna a multisystémová (diseminovaná) histiocytóza z Langerhansových buniek [Lettererova-Siweho choroba]"},
{"code":"C96.2","desc":"Zhubný mastocytový nádor"},
{"code":"C96.4","desc":"Sarkóm z dendritických buniek (akcesórnych buniek)"},
{"code":"C96.5","desc":"Multifokálna a unisystémová histiocytóza z Langerhansových buniek"},
{"code":"C96.6","desc":"Unifokálna histiocytóza z Langerhansových buniek"},
{"code":"C96.7","desc":"Iný zhubný nádor lymfatického, hematopoetického a príbuzného tkaniva, bližšie určený"},
{"code":"C96.8","desc":"Histiocytový sarkóm"},
{"code":"C96.9","desc":"Zhubný nádor lymfatického, hematopoetického a príbuzného tkaniva, bližšie neurčený"},
{"code":"C97-C97","desc":"Zhubné nádory mnohopočetné na viacerých nezávislých primárnych miestach"},
{"code":"C97!","desc":"Zhubné nádory mnohopočetné na viacerých nezávislých primárnych miestach"},
{"code":"D00-D09","desc":"Karcinómy in situ [Carcinoma in situ, CIS]"},
{"code":"D00.-","desc":"Karcinóm in situ ústnej dutiny, pažeráka a žalúdka"},
{"code":"D00.0","desc":"Karcinóm in situ pery, ústnej dutiny a hltana"},
{"code":"D00.1","desc":"Karcinóm in situ pažeráka"},
{"code":"D00.2","desc":"Karcinóm in situ žalúdka"},
{"code":"D01.-","desc":"Karcinóm in situ iných a bližšie neurčených tráviacich orgánov"},
{"code":"D01.0","desc":"Karcinóm in situ hrubého čreva"},
{"code":"D01.1","desc":"Karcinóm in situ rektosigmoidového spojenia"},
{"code":"D01.2","desc":"Karcinóm in situ konečníka"},
{"code":"D01.3","desc":"Karcinóm in situ anusu a análneho kanála"},
{"code":"D01.4","desc":"Karcinóm in situ inej a bližšie neurčenej časti čreva"},
{"code":"D01.5","desc":"Karcinóm in situ pečene, žlčníka a žlčových ciest"},
{"code":"D01.7","desc":"Karcinóm in situ iného, bližšie určeného tráviaceho orgánu"},
{"code":"D01.9","desc":"Karcinóm in situ bližšie neurčeného tráviaceho orgánu"},
{"code":"D02.-","desc":"Karcinóm in situ stredného ucha a dýchacej sústavy"},
{"code":"D02.0","desc":"Karcinóm in situ hrtana"},
{"code":"D02.1","desc":"Karcinóm in situ priedušnice"},
{"code":"D02.2","desc":"Karcinóm in situ priedušky a pľúc"},
{"code":"D02.3","desc":"Karcinóm in situ iných častí dýchacej sústavy"},
{"code":"D02.4","desc":"Karcinóm in situ dýchacej sústavy, bližšie neurčený"},
{"code":"D03.-","desc":"Melanóm in situ"},
{"code":"D03.0","desc":"Melanóm in situ pery"},
{"code":"D03.1","desc":"Melanóm in situ mihalnice vrátane očného kútika"},
{"code":"D03.2","desc":"Melanóm in situ ucha a vonkajšieho zvukovodu"},
{"code":"D03.3","desc":"Melanóm in situ iných a bližšie neurčených častí tváre"},
{"code":"D03.4","desc":"Melanóm in situ vlasatej časti hlavy a krku"},
{"code":"D03.5","desc":"Melanóm in situ trupu"},
{"code":"D03.6","desc":"Melanóm in situ hornej končatiny vrátane pleca"},
{"code":"D03.7","desc":"Melanóm in situ dolnej končatiny vrátane bedra"},
{"code":"D03.8","desc":"Melanóm in situ iných miest"},
{"code":"D03.9","desc":"Melanóm in situ, bližšie neurčený"},
{"code":"D04.-","desc":"Karcinóm kože in situ"},
{"code":"D04.0","desc":"Karcinóm in situ kože pery"},
{"code":"D04.1","desc":"Karcinóm in situ kože mihalnice vrátane očného kútika"},
{"code":"D04.2","desc":"Karcinóm in situ kože ucha a vonkajšieho zvukovodu"},
{"code":"D04.3","desc":"Karcinóm in situ kože iných a bližšie neurčených častí tváre"},
{"code":"D04.4","desc":"Karcinóm in situ kože vlasatej časti hlavy a krku"},
{"code":"D04.5","desc":"Karcinóm in situ kože trupu"},
{"code":"D04.6","desc":"Karcinóm in situ kože hornej končatiny vrátane pleca"},
{"code":"D04.7","desc":"Karcinóm in situ kože dolnej končatiny vrátane bedra"},
{"code":"D04.8","desc":"Karcinóm in situ kože na iných miestach"},
{"code":"D04.9","desc":"Karcinóm in situ kože, bližšie neurčený"},
{"code":"D05.-","desc":"Karcinóm in situ prsníka"},
{"code":"D05.0","desc":"Karcinóm in situ prsníka, lobulárny"},
{"code":"D05.1","desc":"Karcinóm in situ prsníka, intraduktálny"},
{"code":"D05.7","desc":"Iný karcinóm in situ prsníka"},
{"code":"D05.9","desc":"Karcinóm in situ prsníka, bližšie neurčený"},
{"code":"D06.-","desc":"Karcinóm in situ krčka maternice"},
{"code":"D06.0","desc":"Karcinóm in situ endocervixu"},
{"code":"D06.1","desc":"Karcinóm in situ exocervixu"},
{"code":"D06.7","desc":"Karcinóm in situ inej časti krčka maternice"},
{"code":"D06.9","desc":"Karcinóm in situ krčka maternice, bližšie neurčený"},
{"code":"D07.-","desc":"Karcinóm in situ iných a bližšie neurčených pohlavných orgánov"},
{"code":"D07.0","desc":"Karcinóm in situ endometria"},
{"code":"D07.1","desc":"Karcinóm in situ vulvy"},
{"code":"D07.2","desc":"Karcinóm in situ pošvy"},
{"code":"D07.3","desc":"Karcinóm in situ iných a bližšie neurčených ženských pohlavných orgánov"},
{"code":"D07.4","desc":"Karcinóm in situ penisu"},
{"code":"D07.5","desc":"Karcinóm in situ prostaty"},
{"code":"D07.6","desc":"Karcinóm in situ iných a bližšie neurčených mužských pohlavných orgánov"},
{"code":"D09.-","desc":"Karcinóm in situ na iných a bližšie neurčených miestach"},
{"code":"D09.0","desc":"Karcinóm in situ močového mechúra"},
{"code":"D09.1","desc":"Karcinóm in situ iných a bližšie neurčených močových orgánov"},
{"code":"D09.2","desc":"Karcinóm in situ oka"},
{"code":"D09.3","desc":"Karcinóm in situ štítnej žľazy a iných žliaz s vnútorným vylučovaním"},
{"code":"D09.7","desc":"Karcinóm in situ na inom bližšie určenom mieste"},
{"code":"D09.9","desc":"Karcinóm in situ, bližšie neurčený"},
{"code":"D10-D36","desc":"Nezhubné nádory"},
{"code":"D10.-","desc":"Nezhubný nádor úst a hltana"},
{"code":"D10.0","desc":"Nezhubný nádor pery"},
{"code":"D10.1","desc":"Nezhubný nádor jazyka"},
{"code":"D10.2","desc":"Nezhubný nádor spodiny úst"},
{"code":"D10.3","desc":"Nezhubný nádor inej a bližšie neurčenej časti úst"},
{"code":"D10.4","desc":"Nezhubný nádor mandle"},
{"code":"D10.5","desc":"Nezhubný nádor inej časti orofaryngu"},
{"code":"D10.6","desc":"Nezhubný nádor nazofaryngu"},
{"code":"D10.7","desc":"Nezhubný nádor hypofaryngu"},
{"code":"D10.9","desc":"Nezhubný nádor faryngu, bližšie neurčený"},
{"code":"D11.-","desc":"Nezhubný nádor veľkých slinných žliaz"},
{"code":"D11.0","desc":"Nezhubný nádor príušnej žľazy"},
{"code":"D11.7","desc":"Nezhubný nádor inej veľkej slinnej žľazy"},
{"code":"D11.9","desc":"Nezhubný nádor bližšie neurčenej veľkej slinnej žľazy"},
{"code":"D12.-","desc":"Nezhubný nádor hrubého čreva, konečníka, anusu a análneho kanála"},
{"code":"D12.0","desc":"Nezhubný nádor slepého čreva"},
{"code":"D12.1","desc":"Nezhubný nádor červovitého prívesku slepého čreva"},
{"code":"D12.2","desc":"Nezhubný nádor vzostupnej časti hrubého čreva"},
{"code":"D12.3","desc":"Nezhubný nádor priečnej časti hrubého čreva"},
{"code":"D12.4","desc":"Nezhubný nádor zostupnej časti hrubého čreva"},
{"code":"D12.5","desc":"Nezhubný nádor esovitej časti hrubého čreva"},
{"code":"D12.6","desc":"Nezhubný nádor hrubého čreva, bližšie neurčený"},
{"code":"D12.7","desc":"Nezhubný nádor rektosigmoidového spojenia"},
{"code":"D12.8","desc":"Nezhubný nádor konečníka"},
{"code":"D12.9","desc":"Nezhubný nádor anusu a análneho kanála"},
{"code":"D13.-","desc":"Nezhubný nádor iných a nepresne určených častí tráviacej sústavy"},
{"code":"D13.0","desc":"Nezhubný nádor pažeráka"},
{"code":"D13.1","desc":"Nezhubný nádor žalúdka"},
{"code":"D13.2","desc":"Nezhubný nádor dvanástnika"},
{"code":"D13.3","desc":"Nezhubný nádor iných a bližšie neurčených častí tenkého čreva"},
{"code":"D13.4","desc":"Nezhubný nádor pečene"},
{"code":"D13.5","desc":"Nezhubný nádor extrahepatálnych žlčových ciest"},
{"code":"D13.6","desc":"Nezhubný nádor podžalúdkovej žľazy"},
{"code":"D13.7","desc":"Nezhubný nádor endokrinného pankreasu"},
{"code":"D13.9","desc":"Nezhubný nádor na nepresne určenom mieste tráviacej sústavy"},
{"code":"D14.-","desc":"Nezhubný nádor stredného ucha a dýchacej sústavy"},
{"code":"D14.0","desc":"Nezhubný nádor stredného ucha, nosovej dutiny a prinosových dutín"},
{"code":"D14.1","desc":"Nezhubný nádor hrtana"},
{"code":"D14.2","desc":"Nezhubný nádor priedušnice"},
{"code":"D14.3","desc":"Nezhubný nádor priedušky a pľúc"},
{"code":"D14.4","desc":"Nezhubný nádor dýchacej sústavy, bližšie neurčený"},
{"code":"D15.-","desc":"Nezhubný nádor iných a bližšie neurčených vnútrohrudníkových orgánov"},
{"code":"D15.0","desc":"Nezhubný nádor týmusu"},
{"code":"D15.1","desc":"Nezhubný nádor srdca"},
{"code":"D15.2","desc":"Nezhubný nádor mediastina"},
{"code":"D15.7","desc":"Nezhubný nádor iných určených vnútrohrudníkových orgánov"},
{"code":"D15.9","desc":"Nezhubný nádor vnútrohrudníkových orgánov, bližšie neurčený"},
{"code":"D16.-","desc":"Nezhubný nádor kosti a kĺbovej chrupky"},
{"code":"D16.0","desc":"Nezhubný nádor lopatky a dlhých kostí hornej končatiny"},
{"code":"D16.1","desc":"Nezhubný nádor krátkych kostí hornej končatiny"},
{"code":"D16.2","desc":"Nezhubný nádor dlhých kostí dolnej končatiny"},
{"code":"D16.3","desc":"Nezhubný nádor krátkych kostí dolnej končatiny"},
{"code":"D16.4-","desc":"Nezhubný nádor kostí lebky a tváre"},
{"code":"D16.41","desc":"Nezhubný nádor kosti a kĺbovej chrupky, kraniofaciálna oblasť"},
{"code":"D16.42","desc":"Nezhubný nádor kosti a kĺbovej chrupky, maxilofaciálna oblasť"},
{"code":"D16.5","desc":"Nezhubný nádor kostí dolnej čeľuste"},
{"code":"D16.6","desc":"Nezhubný nádor chrbtice"},
{"code":"D16.7-","desc":"Nezhubný nádor rebier, mostíka a kľúčnej kosti"},
{"code":"D16.70","desc":"Nezhubný nádor kosti a kĺbovej chrupky rebier"},
{"code":"D16.71","desc":"Nezhubný nádor kosti a kĺbovej chrupky hrudnej kosti"},
{"code":"D16.72","desc":"Nezhubný nádor kosti a kĺbovej chrupky kľúčnej kosti"},
{"code":"D16.8","desc":"Nezhubný nádor panvových kostí, krížovej kosti a kostrče"},
{"code":"D16.9","desc":"Nezhubný nádor kosti a kĺbovej chrupky, bližšie neurčený"},
{"code":"D17.-","desc":"Nezhubný lipomatózny nádor"},
{"code":"D17.0","desc":"Nezhubný lipomatózny nádor kože a podkožného tkaniva hlavy, tváre a krku"},
{"code":"D17.1","desc":"Nezhubný lipomatózny nádor kože a podkožného tkaniva trupu"},
{"code":"D17.2","desc":"Nezhubný lipomatózny nádor kože a podkožného tkaniva končatín"},
{"code":"D17.3","desc":"Nezhubný lipomatózny nádor kože a podkožného tkaniva na iných a bližšie neurčených miestach"},
{"code":"D17.4","desc":"Nezhubný lipomatózny nádor vnútrohrudníkových orgánov"},
{"code":"D17.5","desc":"Nezhubný lipomatózny nádor vnútrobrušných orgánov"},
{"code":"D17.6","desc":"Nezhubný lipomatózny nádor semenného povrazca"},
{"code":"D17.7","desc":"Nezhubný lipomatózny nádor na inom mieste"},
{"code":"D17.9","desc":"Nezhubný lipomatózny nádor, bližšie neurčený"},
{"code":"D18.-","desc":"Hemangióm a lymfangióm"},
{"code":"D18.0-","desc":"Hemangióm"},
{"code":"D18.00","desc":"Hemangióm bez bližšieho určenia miesta"},
{"code":"D18.01","desc":"Hemangióm kože a podkožia"},
{"code":"D18.02","desc":"Hemangióm intrakraniálny"},
{"code":"D18.03","desc":"Hemangióm hepatobiliárneho systému a pankreasu"},
{"code":"D18.04","desc":"Hemangióm tráviacej sústavy"},
{"code":"D18.05","desc":"Hemangióm ucha, nosa, úst a hrdla"},
{"code":"D18.08","desc":"Hemangióm na inom mieste"},
{"code":"D18.1-","desc":"Lymfangióm"},
{"code":"D18.10","desc":"Lymfangióm - hygroma colli cysticum"},
{"code":"D18.11","desc":"Lymfangióm axily"},
{"code":"D18.12","desc":"Lymfangióm ingvíny"},
{"code":"D18.13","desc":"Lymfangióm retroperitonea"},
{"code":"D18.18","desc":"Lymfangióm na inom mieste"},
{"code":"D18.19","desc":"Lymfangióm bez bližšieho určenia miesta"},
{"code":"D19.-","desc":"Nezhubný nádor mezotelového tkaniva"},
{"code":"D19.0","desc":"Nezhubný nádor mezotelového tkaniva pohrudnice"},
{"code":"D19.1","desc":"Nezhubný nádor mezotelového tkaniva pobrušnice"},
{"code":"D19.7","desc":"Nezhubný nádor mezotelového tkaniva na inom mieste"},
{"code":"D19.9","desc":"Nezhubný nádor mezotelového tkaniva, bližšie neurčený"},
{"code":"D20.-","desc":"Nezhubný nádor mäkkého tkaniva retroperitonea a peritonea"},
{"code":"D20.0","desc":"Nezhubný nádor retroperitonea"},
{"code":"D20.1","desc":"Nezhubný nádor pobrušnice"},
{"code":"D21.-","desc":"Iný nezhubný nádor spojivového a iného mäkkého tkaniva"},
{"code":"D21.0","desc":"Nezhubný nádor spojivového a iného mäkkého tkaniva hlavy, tváre a krku"},
{"code":"D21.1","desc":"Nezhubný nádor spojivového a iného mäkkého tkaniva hornej končatiny vrátane pleca"},
{"code":"D21.2","desc":"Nezhubný nádor spojivového a iného mäkkého tkaniva dolnej končatiny vrátane bedra"},
{"code":"D21.3","desc":"Nezhubný nádor spojivového a iného mäkkého tkaniva hrudníka"},
{"code":"D21.4","desc":"Nezhubný nádor spojivového a iného mäkkého tkaniva brucha"},
{"code":"D21.5","desc":"Nezhubný nádor spojivového a iného mäkkého tkaniva panvy"},
{"code":"D21.6","desc":"Nezhubný nádor spojivového a iného mäkkého tkaniva trupu, bližšie neurčený"},
{"code":"D21.9","desc":"Nezhubný nádor spojivového a iného mäkkého tkaniva, bližšie neurčený"},
{"code":"D22.-","desc":"Melanocytový névus"},
{"code":"D22.0","desc":"Melanocytový névus pery"},
{"code":"D22.1","desc":"Melanocytový névus mihalnice vrátane kútika oka"},
{"code":"D22.2","desc":"Melanocytový névus ucha a vonkajšieho zvukovodu"},
{"code":"D22.3","desc":"Melanocytový névus iných a bližšie neurčených častí tváre"},
{"code":"D22.4","desc":"Melanocytový névus vlasovej časti hlavy a krku"},
{"code":"D22.5","desc":"Melanocytový névus trupu"},
{"code":"D22.6","desc":"Melanocytový névus hornej končatiny vrátane pleca"},
{"code":"D22.7","desc":"Melanocytový névus dolnej končatiny vrátane bedra"},
{"code":"D22.9","desc":"Melanocytový névus, bližšie neurčený"},
{"code":"D23.-","desc":"Iný nezhubný nádor kože"},
{"code":"D23.0","desc":"Iný nezhubný nádor kože pery"},
{"code":"D23.1","desc":"Iný nezhubný nádor kože mihalnice vrátane kútika"},
{"code":"D23.2","desc":"Iný nezhubný nádor kože ucha a vonkajšieho zvukovodu"},
{"code":"D23.3","desc":"Iný nezhubný nádor kože iných a bližšie neurčených častí tváre"},
{"code":"D23.4","desc":"Iný nezhubný nádor kože vlasovej časti hlavy a krku"},
{"code":"D23.5","desc":"Iný nezhubný nádor kože trupu"},
{"code":"D23.6","desc":"Iný nezhubný nádor kože hornej končatiny vrátane pleca"},
{"code":"D23.7","desc":"Iný nezhubný nádor kože dolnej končatiny vrátane bedra"},
{"code":"D23.9","desc":"Iný nezhubný nádor kože, bližšie neurčený"},
{"code":"D24","desc":"Nezhubný nádor prsníka"},
{"code":"D25.-","desc":"Leiomyóm maternice"},
{"code":"D25.0","desc":"Submukózny leiomyóm maternice"},
{"code":"D25.1","desc":"Intramurálny leiomyóm maternice"},
{"code":"D25.2","desc":"Subserózny leiomyóm maternice"},
{"code":"D25.9","desc":"Leiomyóm maternice, bližšie neurčený"},
{"code":"D26.-","desc":"Iný nezhubný nádor maternice"},
{"code":"D26.0","desc":"Iný nezhubný nádor krčka maternice"},
{"code":"D26.1","desc":"Iný nezhubný nádor tela maternice"},
{"code":"D26.7","desc":"Iný nezhubný nádor iných častí maternice"},
{"code":"D26.9","desc":"Iný nezhubný nádor maternice, bližšie neurčený"},
{"code":"D27","desc":"Nezhubný nádor vaječníka"},
{"code":"D28.-","desc":"Nezhubný nádor iných a bližšie neurčených ženských pohlavných orgánov"},
{"code":"D28.0","desc":"Nezhubný nádor vulvy"},
{"code":"D28.1","desc":"Nezhubný nádor pošvy"},
{"code":"D28.2","desc":"Nezhubný nádor vajíčkovodov a väzov maternice"},
{"code":"D28.7","desc":"Nezhubný nádor iných bližšie určených ženských pohlavných orgánov"},
{"code":"D28.9","desc":"Nezhubný nádor bližšie neurčených ženských pohlavných orgánov"},
{"code":"D29.-","desc":"Nezhubný nádor mužských pohlavných orgánov"},
{"code":"D29.0","desc":"Nezhubný nádor penisu"},
{"code":"D29.1","desc":"Nezhubný nádor prostaty"},
{"code":"D29.2","desc":"Nezhubný nádor semenníka"},
{"code":"D29.3","desc":"Nezhubný nádor nadsemenníka"},
{"code":"D29.4","desc":"Nezhubný nádor mieška (skróta)"},
{"code":"D29.7","desc":"Nezhubný nádor iných mužských pohlavných orgánov"},
{"code":"D29.9","desc":"Nezhubný nádor bližšie neurčených mužských pohlavných orgánov"},
{"code":"D30.-","desc":"Nezhubný nádor močovej sústavy"},
{"code":"D30.0","desc":"Nezhubný nádor obličky"},
{"code":"D30.1","desc":"Nezhubný nádor obličkovej panvičky"},
{"code":"D30.2","desc":"Nezhubný nádor močovodu"},
{"code":"D30.3","desc":"Nezhubný nádor močového mechúra"},
{"code":"D30.4","desc":"Nezhubný nádor močovej rúry"},
{"code":"D30.7","desc":"Nezhubný nádor iných močových orgánov"},
{"code":"D30.9","desc":"Nezhubný nádor močového orgánu, bližšie neurčeného"},
{"code":"D31.-","desc":"Nezhubný nádor oka a očných adnexov"},
{"code":"D31.0","desc":"Nezhubný nádor spojovky"},
{"code":"D31.1","desc":"Nezhubný nádor rohovky"},
{"code":"D31.2","desc":"Nezhubný nádor sietnice"},
{"code":"D31.3","desc":"Nezhubný nádor cievovky"},
{"code":"D31.4","desc":"Nezhubný nádor vráskovca"},
{"code":"D31.5","desc":"Nezhubný nádor slznej žľazy a nosovoslzného kanála"},
{"code":"D31.6","desc":"Nezhubný nádor očnice, bližšie neurčený"},
{"code":"D31.9","desc":"Nezhubný nádor oka, bližšie neurčený"},
{"code":"D32.-","desc":"Nezhubný nádor mozgovomiechových obalov"},
{"code":"D32.0","desc":"Nezhubný nádor mozgovomiechových obalov: mozog"},
{"code":"D32.1","desc":"Nezhubný nádor mozgovomiechových obalov: miecha"},
{"code":"D32.9","desc":"Nezhubný nádor mozgovomiechových plien, bližšie neurčený"},
{"code":"D33.-","desc":"Nezhubný nádor mozgu a iných častí centrálnej nervovej sústavy"},
{"code":"D33.0","desc":"Nezhubný nádor mozgu: supratentoriálne"},
{"code":"D33.1","desc":"Nezhubný nádor mozgu: infratentóriálne"},
{"code":"D33.2","desc":"Nezhubný nádor mozgu, bližšie neurčený"},
{"code":"D33.3","desc":"Nezhubný nádor hlavových nervov"},
{"code":"D33.4","desc":"Nezhubný nádor miechy"},
{"code":"D33.7","desc":"Nezhubný nádor iných, bližšie určených častí centrálnej nervovej sústavy"},
{"code":"D33.9","desc":"Nezhubný nádor centrálnej nervovej sústavy, bližšie neurčený"},
{"code":"D34","desc":"Nezhubný nádor štítnej žľazy"},
{"code":"D35.-","desc":"Nezhubný nádor iných a bližšie neurčených žliaz s vnútorným vylučovaním"},
{"code":"D35.0","desc":"Nezhubný nádor nadobličky"},
{"code":"D35.1","desc":"Nezhubný nádor prištítnej žľazy"},
{"code":"D35.2","desc":"Nezhubný nádor podmozgovej žľazy"},
{"code":"D35.3","desc":"Nezhubný nádor kraniofaryngového vývodu"},
{"code":"D35.4","desc":"Nezhubný nádor epifýzy"},
{"code":"D35.5","desc":"Nezhubný nádor glomus caroticum"},
{"code":"D35.6","desc":"Nezhubný nádor glomus aorticum a iných paraganglií"},
{"code":"D35.7","desc":"Nezhubný nádor iných, bližšie určených žliaz s vnútorným vylučovaním"},
{"code":"D35.8","desc":"Nezhubný nádor viacerých žliaz s vnútorným vylučovaním"},
{"code":"D35.9","desc":"Nezhubný nádor žľazy s vnútorným vylučovaním, bližšie neurčený"},
{"code":"D36.-","desc":"Nezhubný nádor na iných a bližšie neurčených miestach"},
{"code":"D36.0","desc":"Nezhubný nádor lymfatických uzlín"},
{"code":"D36.1","desc":"Nezhubný nádor periférnych nervov a autonómnej nervovej sústavy"},
{"code":"D36.7","desc":"Nezhubný nádor na iných, bližšie určených miestach"},
{"code":"D36.9","desc":"Nezhubný nádor bez bližšieho určenia miesta"},
{"code":"D37-D48","desc":"Nádory s neurčitým alebo neznámym správaním"},
{"code":"D37.-","desc":"Nádor ústnej dutiny a tráviacich orgánov s neurčitým alebo neznámym správaním"},
{"code":"D37.0","desc":"Nádor pery, ústnej dutiny a hltana s neurčitým alebo neznámym správaním"},
{"code":"D37.1","desc":"Nádor s neurčitým alebo neznámym správaním: žalúdok"},
{"code":"D37.2","desc":"Nádor s neurčitým alebo neznámym správaním: tenké črevo"},
{"code":"D37.3","desc":"Nádor s neurčitým alebo neznámym správaním: červovitý prívesok"},
{"code":"D37.4","desc":"Nádor s neurčitým alebo neznámym správaním: hrubé črevo"},
{"code":"D37.5","desc":"Nádor s neurčitým alebo neznámym správaním: konečník"},
{"code":"D37.6","desc":"Nádor s neurčitým alebo neznámym správaním: pečeň, žlčník, žlčové cesty"},
{"code":"D37.7-","desc":"Nádor s neurčitým alebo neznámym správaním: iný tráviaci orgán"},
{"code":"D37.70","desc":"Nádor s neurčitým alebo neznámym správaním: pankreas"},
{"code":"D37.78","desc":"Nádor s neurčitým alebo neznámym správaním: iný tráviaci orgán"},
{"code":"D37.9","desc":"Nádor s neurčitým alebo neznámym správaním, bližšie neurčený tráviaci orgán"},
{"code":"D38.-","desc":"Nádor s neurčitým alebo neznámym správaním: stredné ucho, dýchacie a vnútrohrudníkové orgáne"},
{"code":"D38.0","desc":"Nádor s neurčitým alebo neznámym správaním: hrtan"},
{"code":"D38.1","desc":"Nádor s neurčitým alebo neznámym správaním: priedušnica, prieduška, pľúca"},
{"code":"D38.2","desc":"Nádor s neurčitým alebo neznámym správaním: pohrudnica"},
{"code":"D38.3","desc":"Nádor s neurčitým alebo neznámym správaním: mediastinum"},
{"code":"D38.4","desc":"Nádor s neurčitým alebo neznámym správaním: týmus"},
{"code":"D38.5","desc":"Nádor s neurčitým alebo neznámym správaním: iný dýchací orgán"},
{"code":"D38.6","desc":"Nádor s neurčitým alebo neznámym správaním, bližšie neurčený dýchací orgán"},
{"code":"D39.-","desc":"Nádor ženských pohlavných orgánov s neurčitým alebo neznámym správaním"},
{"code":"D39.0","desc":"Nádor s neurčitým alebo neznámym správaním: maternica"},
{"code":"D39.1","desc":"Nádor s neurčitým alebo neznámym správaním: vaječník"},
{"code":"D39.2","desc":"Nádor s neurčitým alebo neznámym správaním: placenta"},
{"code":"D39.7","desc":"Nádor s neurčitým alebo neznámym správaním: iný ženský pohlavný orgán"},
{"code":"D39.9","desc":"Nádor s neurčitým alebo neznámym správaním, bližšie neurčený ženský pohlavný orgán"},
{"code":"D40.-","desc":"Nádor mužských pohlavných orgánov s neurčitým alebo neznámym správaním"},
{"code":"D40.0","desc":"Nádor s neurčitým alebo neznámym správaním: prostata"},
{"code":"D40.1","desc":"Nádor s neurčitým alebo neznámym správaním: semenník"},
{"code":"D40.7","desc":"Nádor s neurčitým alebo neznámym správaním: iný mužský pohlavný orgán"},
{"code":"D40.9","desc":"Nádor s neurčitým alebo neznámym správaním, bližšie neurčený mužský pohlavný orgán"},
{"code":"D41.-","desc":"Nádor s neurčitým alebo neznámym správaním: močová sústava"},
{"code":"D41.0","desc":"Nádor s neurčitým alebo neznámym správaním: oblička"},
{"code":"D41.1","desc":"Nádor s neurčitým alebo neznámym správaním: obličková panvička"},
{"code":"D41.2","desc":"Nádor s neurčitým alebo neznámym správaním: močovod"},
{"code":"D41.3","desc":"Nádor s neurčitým alebo neznámym správaním: močová rúra"},
{"code":"D41.4","desc":"Nádor s neurčitým alebo neznámym správaním: močový mechúr"},
{"code":"D41.7","desc":"Nádor s neurčitým alebo neznámym správaním: iný močový orgán"},
{"code":"D41.9","desc":"Nádor s neurčitým alebo neznámym správaním, bližšie neurčený močový orgán"},
{"code":"D42.-","desc":"Nádor mozgovomiechových obalov s neurčitým alebo neznámym správaním"},
{"code":"D42.0","desc":"Nádor s neurčitým alebo neznámym správaním: mozgovomiechové obaly: mozog"},
{"code":"D42.1","desc":"Nádor s neurčitým alebo neznámym správaním: mozgovomiechové obaly: miecha"},
{"code":"D42.9","desc":"Nádor s neurčitým alebo neznámym správaním: mozgovomiechové obaly bližšie neurčené"},
{"code":"D43.-","desc":"Nádor s neurčitým alebo neznámym správaním: mozog a centrálna nervová sústava"},
{"code":"D43.0","desc":"Nádor s neurčitým alebo neznámym správaním: mozog - supratentriálne"},
{"code":"D43.1","desc":"Nádor s neurčitým alebo neznámym správaním: mozog - infratentoriálne"},
{"code":"D43.2","desc":"Nádor s neurčitým alebo neznámym správaním: mozog, bližšie neurčený"},
{"code":"D43.3","desc":"Nádor s neurčitým alebo neznámym správaním: hlavové nervy"},
{"code":"D43.4","desc":"Nádor s neurčitým alebo neznámym správaním: miecha"},
{"code":"D43.7","desc":"Nádor s neurčitým alebo neznámym správaním: iná časť centrálnej nervovej sústavy"},
{"code":"D43.9","desc":"Nádor s neurčitým alebo neznámym správaním: centrálny nervový systém, časť bližšie neurčená"},
{"code":"D44.-","desc":"Nádor s neurčitým alebo neznámym správaním: žľazy s vnútorným vylučovaním"},
{"code":"D44.0","desc":"Nádor s neurčitým alebo neznámym správaním: štítna žľaza"},
{"code":"D44.1","desc":"Nádor s neurčitým alebo neznámym správaním: nadoblička"},
{"code":"D44.2","desc":"Nádor s neurčitým alebo neznámym správaním: prištítna žľaza"},
{"code":"D44.3","desc":"Nádor s neurčitým alebo neznámym správaním: podmozgová žľaza"},
{"code":"D44.4","desc":"Nádor s neurčitým alebo neznámym správaním: kraniofaryngový vývod"},
{"code":"D44.5","desc":"Nádor s neurčitým alebo neznámym správaním: epifýza"},
{"code":"D44.6","desc":"Nádor s neurčitým alebo neznámym správaním: glomus caroticum"},
{"code":"D44.7","desc":"Nádor s neurčitým alebo neznámym správaním: glomus aorticum a iné paragangliá"},
{"code":"D44.8","desc":"Nádor s neurčitým alebo neznámym správaním: viaceré endokrinné žľazy"},
{"code":"D44.9","desc":"Nádor s neurčitým alebo neznámym správaním: bližšie neurčená žľaza s vnútorným vylučovaním"},
{"code":"D45","desc":"Polycythaemia vera"},
{"code":"D46.-","desc":"Myelodysplastický syndróm"},
{"code":"D46.0","desc":"Refraktérna anémia bez prstencových sideroblastov, potvrdená"},
{"code":"D46.1","desc":"Refraktérna anémia s prstencovými sideroblastmi"},
{"code":"D46.2","desc":"Refraktérna anémia s veľkým množstvom blastov"},
{"code":"D46.4","desc":"Refraktérna anémia, bližšie neurčená"},
{"code":"D46.5","desc":"Refraktérna anémia s dyspláziou viacerých línií"},
{"code":"D46.6","desc":"Myelodysplastický syndróm s izolovanou chromozómovou anomáliou del (5q)"},
{"code":"D46.7","desc":"Iný myelodysplastický syndróm"},
{"code":"D46.9","desc":"Myelodysplastický syndróm, bližšie neurčený"},
{"code":"D47.-","desc":"Iný nádor lymfatického, krvotvorného a príbuzného tkaniva s neurčitým alebo neznámym správaním"},
{"code":"D47.0","desc":"Histiocytový a mastocytový nádor s neurčitým alebo neznámym správaním"},
{"code":"D47.1","desc":"Chronická myeloproliferatívna choroba"},
{"code":"D47.2","desc":"Monoklonová gamapatia nejasného významu (MGUS)"},
{"code":"D47.3","desc":"Esenciálna (hemoragická) trombocytémia"},
{"code":"D47.4","desc":"Osteomyelofibróza"},
{"code":"D47.5","desc":"Chronická eozinofilová leukémia [Hypereozinofilový syndróm]"},
{"code":"D47.7","desc":"Iný bližšie určený nádor lymfatického, krvotvorného a príbuzného tkaniva s neurčitým alebo neznámym správaním"},
{"code":"D47.9","desc":"Nádor lymfatického, krvotvorného a príbuzného tkaniva s neurčitým alebo neznámym správaním, bližšie neurčený"},
{"code":"D48.-","desc":"Nádor na iných a bližšie neurčených miestach s neurčitým alebo neznámym správaním"},
{"code":"D48.0","desc":"Nádor s neurčitým alebo neznámym správaním: kosť a kĺbová chrupka"},
{"code":"D48.1","desc":"Nádor s neurčitým alebo neznámym správaním: spojivové a iné mäkké tkanivo"},
{"code":"D48.2","desc":"Nádor s neurčitým alebo neznámym správaním: periférne nervy a autonómny nervový systém"},
{"code":"D48.3","desc":"Nádor s neurčitým alebo neznámym správaním: retroperitoneum"},
{"code":"D48.4","desc":"Nádor s neurčitým alebo neznámym správaním: pobrušnica"},
{"code":"D48.5","desc":"Nádor s neurčitým alebo neznámym správaním: koža"},
{"code":"D48.6","desc":"Nádor s neurčitým alebo neznámym správaním: prsník"},
{"code":"D48.7","desc":"Nádor s neurčitým alebo neznámym správaním: iné bližšie určené miesto"},
{"code":"D48.9","desc":"Nádor s neurčitým alebo neznámym správaním, bližšie neurčený"},
{"code":"D50-D90","desc":"III. kapitola - Choroby krvi a krvotvorných orgánov a niektoré poruchy s účasťou imunitných mechanizmov (D50-D90)"},
{"code":"D50-D53","desc":"Nutričné anémie"},
{"code":"D50.-","desc":"Anémia z nedostatku železa"},
{"code":"D50.0","desc":"Anémia z nedostatku železa pri chronických stratách krvi"},
{"code":"D50.1","desc":"Sideropenická dysfágia"},
{"code":"D50.8","desc":"Iná anémia z nedostatku železa"},
{"code":"D50.9","desc":"Bližšie neurčená anémia z nedostatku železa"},
{"code":"D51.-","desc":"Anémia z nedostatku vitamínu B12"},
{"code":"D51.0","desc":"Anémia z nedostatku vitamínu B12 zapríčinená nedostatkom vnútorného faktora"},
{"code":"D51.1","desc":"Anémia z nedostatku vitamínu B12 zapríčinená selektívnou malabsorpciou vitamínu B12 s proteinúriou"},
{"code":"D51.2","desc":"Anémia z nedostatku transkobalamínu II"},
{"code":"D51.3","desc":"Iná anémia z nedostatku vitamínu B12 v potrave"},
{"code":"D51.8","desc":"Iná anémia z nedostatku vitamínu B12"},
{"code":"D51.9","desc":"Bližšie neurčená anémia z nedostatku vitamínu B12"},
{"code":"D52.-","desc":"Anémia z nedostatku kyseliny listovej"},
{"code":"D52.0","desc":"Anémia z nedostatku kyseliny listovej v potrave"},
{"code":"D52.1","desc":"Anémia z nedostatku kyseliny listovej zapríčinená liekmi"},
{"code":"D52.8","desc":"Iná anémia z nedostatku kyseliny listovej"},
{"code":"D52.9","desc":"Anémia z nedostatku kyseliny listovej, bližšie neurčená"},
{"code":"D53.-","desc":"Iná nutričná anémia"},
{"code":"D53.0","desc":"Anémia z nedostatku bielkovín"},
{"code":"D53.1","desc":"Iná megaloblastová anémia, nezatriedená inde"},
{"code":"D53.2","desc":"Skorbutová anémia"},
{"code":"D53.8","desc":"Iná bližšie určená nutričná anémia"},
{"code":"D53.9","desc":"Bližšie neurčená nutričná anémia"},
{"code":"D55-D59","desc":"Hemolytické anémie"},
{"code":"D55.-","desc":"Anémia zapríčinená poruchou enzýmov"},
{"code":"D55.0","desc":"Anémia zapríčinená nedostatočnou aktivitou glukóza-6-fosfát-dehydrogenázy"},
{"code":"D55.1","desc":"Anémia zapríčinená inou poruchou metabolizmu glutatiónu"},
{"code":"D55.2","desc":"Anémia zapríčinená poruchou enzýmov glykolýzy"},
{"code":"D55.3","desc":"Anémia zapríčinená poruchou metabolizmu nukleotidov"},
{"code":"D55.8","desc":"Iná anémia zapríčinená poruchou enzýmov"},
{"code":"D55.9","desc":"Anémia zapríčinená poruchou enzýmov, bližšie neurčená"},
{"code":"D56.-","desc":"Talasémia"},
{"code":"D56.0","desc":"Alfa-talasémia"},
{"code":"D56.1","desc":"Beta-talasémia"},
{"code":"D56.2","desc":"Delta-beta-talasémia"},
{"code":"D56.3","desc":"Talasemická črta"},
{"code":"D56.4","desc":"Dedičné pretrvávanie fetálneho hemoglobínu"},
{"code":"D56.8","desc":"Iná talasémia"},
{"code":"D56.9","desc":"Talasémia, bližšie neurčená"},
{"code":"D57.-","desc":"Kosáčikovitá anémia"},
{"code":"D57.0","desc":"Kosáčikovitá anémia s krízou"},
{"code":"D57.1","desc":"Kosáčikovitá anémia bez krízy"},
{"code":"D57.2","desc":"Zdvojená heterozygotná kosáčikovitá choroba"},
{"code":"D57.3","desc":"Kosáčikovitá črta"},
{"code":"D57.8","desc":"Iná kosáčikovitá porucha"},
{"code":"D58.-","desc":"Iná dedičná hemolytická anémia"},
{"code":"D58.0","desc":"Dedičná sférocytóza"},
{"code":"D58.1","desc":"Dedičná eliptocytóza"},
{"code":"D58.2","desc":"Iná hemoglobinopatia"},
{"code":"D58.8","desc":"Iná dedičná hemolytická anémia, bližšie určená"},
{"code":"D58.9","desc":"Dedičná hemolytická anémia, bližšie neurčená"},
{"code":"D59.-","desc":"Získaná hemolytická anémia"},
{"code":"D59.0","desc":"Autoimunitná hemolytická anémia zapríčinená liekmi"},
{"code":"D59.1","desc":"Iná autoimunitná hemolytická anémia"},
{"code":"D59.2","desc":"Neautoimunitná hemolytická anémia zapríčinená liekmi"},
{"code":"D59.3","desc":"Hemolyticko-uremický syndróm"},
{"code":"D59.4","desc":"Iná neautoimunitná hemolytická anémia"},
{"code":"D59.5","desc":"Paroxyzmálna nočná hemoglobinúria"},
{"code":"D59.6","desc":"Hemoglobinúria zapríčinená hemolýzou z iných vonkajších príčin"},
{"code":"D59.8","desc":"Iná získaná hemolytická anémia"},
{"code":"D59.9","desc":"Získaná hemolytická anémia, bližšie neurčená"},
{"code":"D60-D64","desc":"Aplastické a iné anémie"},
{"code":"D60.-","desc":"Získaná čistá aplázia červených krviniek [erytroblastopénia] [pure red cell aplasia]"},
{"code":"D60.0","desc":"Chronická získaná čistá aplázia červených krviniek"},
{"code":"D60.1","desc":"Prechodná získaná čistá aplázia červených krviniek"},
{"code":"D60.8","desc":"Iná získaná čistá aplázia červených krviniek"},
{"code":"D60.9","desc":"Získaná čistá aplázia červených krviniek, bližšie neurčená"},
{"code":"D61.-","desc":"Iná aplastická anémia"},
{"code":"D61.0","desc":"Vrodená aplastická anémia"},
{"code":"D61.1-","desc":"Aplastická anémia zapríčinená liekmi"},
{"code":"D61.10","desc":"Aplastická anémia zapríčinená cytostatickou liečbou"},
{"code":"D61.18","desc":"Iná aplastická anémia zapríčinená liekmi"},
{"code":"D61.19","desc":"Aplastická anémia zapríčinená liekmi, bližšie neurčená"},
{"code":"D61.2","desc":"Aplastická anémia z iných vonkajších príčin"},
{"code":"D61.3","desc":"Idiopatická aplastická anémia"},
{"code":"D61.8","desc":"Iná aplastická anémia, bližšie určená"},
{"code":"D61.9","desc":"Aplastická anémia, bližšie neurčená"},
{"code":"D62","desc":"Akútna posthemoragická anémia"},
{"code":"D63.-*","desc":"Anémia pri chronických chorobách zatriedených inde"},
{"code":"D63.0*","desc":"Anémia pri nádorových chorobách"},
{"code":"D63.8*","desc":"Anémia pri iných chronických chorobách zatriedených inde"},
{"code":"D64.-","desc":"Iná anémia"},
{"code":"D64.0","desc":"Dedičná sideroblastová [sideroachrestická] anémia"},
{"code":"D64.1","desc":"Sekundárna sideroblastová [sideroachrestická] anémia zapríčinená ochorením"},
{"code":"D64.2","desc":"Sekundárna sideroblastová [sideroachrestická] anémia zapríčinená liekmi a toxickými látkami"},
{"code":"D64.3","desc":"Iná sideroblastová [sideroachrestická] anémia"},
{"code":"D64.4","desc":"Vrodená dyserytropoetická anémia"},
{"code":"D64.8","desc":"Iná bližšie určená anémia"},
{"code":"D64.9","desc":"Anémia, bližšie neurčená"},
{"code":"D65-D69","desc":"Poruchy koagulácie, purpura a iné stavy krvácavosti"},
{"code":"D65.-","desc":"Diseminovaná intravaskulárna koagulácia [Defibrinačný syndróm]"},
{"code":"D65.0","desc":"Získaná afibrinogenémia"},
{"code":"D65.1","desc":"Diseminovaná intravaskulárna koagulácia [DIK, DIC]"},
{"code":"D65.2","desc":"Získané fibrinolytické krvácanie"},
{"code":"D65.9","desc":"Bližšie neurčený defibrinačný syndróm"},
{"code":"D66","desc":"Dedičný nedostatok faktora VIII"},
{"code":"D67","desc":"Dedičný nedostatok faktora IX"},
{"code":"D68.-","desc":"Iné poruchy koagulácie"},
{"code":"D68.0","desc":"Von Willebrandova choroba"},
{"code":"D68.1","desc":"Dedičný nedostatok faktora XI"},
{"code":"D68.2","desc":"Dedičný nedostatok iných koagulačných faktorov"},
{"code":"D68.3-","desc":"Stavy krvácavosti zapríčinené antikoagulanciami a protilátkami"},
{"code":"D68.30","desc":"Stav krvácavosti zapríčinený antikoagulanciami"},
{"code":"D68.31","desc":"Stav krvácavosti zapríčinený zvýšením protilátok proti faktoru VIII"},
{"code":"D68.32","desc":"Stav krvácavosti zapríčinený zvýšením plazmatickej koncentrácie protilátok proti iným faktorom zrážania krvi"},
{"code":"D68.38","desc":"Iný stav krvácavosti zapríčinený inými a bližšie neurčenými protilátkami"},
{"code":"D68.4","desc":"Získaný nedostatok koagulačného faktora"},
{"code":"D68.5","desc":"Primárny trombofilný stav (trombofília)"},
{"code":"D68.6","desc":"Iný trombofilný stav (trombofília)"},
{"code":"D68.8","desc":"Iná porucha zrážanlivosti krvi, bližšie určená"},
{"code":"D68.9","desc":"Porucha zrážanlivosti krvi, bližšie neurčená"},
{"code":"D69.-","desc":"Purpura a iné stavy krvácavosti"},
{"code":"D69.0","desc":"Alergická purpura"},
{"code":"D69.1","desc":"Kvalitatívna porucha doštičiek"},
{"code":"D69.2","desc":"Iná netrombocytopenická purpura"},
{"code":"D69.3","desc":"Idiopatická trombocytopenická purpura"},
{"code":"D69.4-","desc":"Iná primárna trombocytopénia"},
{"code":"D69.40","desc":"Iná primárna trombocytopénia nereagujúca na transfúznu liečbu"},
{"code":"D69.41","desc":"Iná primárna trombocytopénia reagujúca na transfúznu liečbu"},
{"code":"D69.5-","desc":"Sekundárna trombocytopénia"},
{"code":"D69.52","desc":"Trombocytopénia indukovaná heparínom, typ I"},
{"code":"D69.53","desc":"Trombocytopénia zapríčinená heparínom, typ II"},
{"code":"D69.57","desc":"Iná sekundárna trombocytopénia, označená ako refraktérna na transfúznu liečbu"},
{"code":"D69.58","desc":"Iná sekundárna trombocytopénia, neoznačená ako refraktérna na transfúznu liečbu"},
{"code":"D69.59","desc":"Sekundárna trombocytopénia, bližšie neurčená"},
{"code":"D69.6-","desc":"Trombocytopénia, bližšie neurčená"},
{"code":"D69.60","desc":"Trombocytopénia, bližšie neurčená, označená ako refraktérna na transfúznu liečbu"},
{"code":"D69.61","desc":"Trombocytopénia, bližšie neurčená, neoznačená ako refraktérna na transfúznu liečbu"},
{"code":"D69.8","desc":"Iný stav krvácavosti, bližšie určený"},
{"code":"D69.9","desc":"Stav krvácavosti, bližšie neurčený"},
{"code":"D70-D77","desc":"Iné choroby krvi a krvotvorných orgánov"},
{"code":"D70.-","desc":"Agranulocytóza a neutropénia"},
{"code":"D70.0","desc":"Vrodená agranulocytóza a neutropénia"},
{"code":"D70.1-","desc":"Agranulocytóza a neutropénia zapríčinená liekmi"},
{"code":"D70.10","desc":"Agranulocytóza a neutropénia zapríčinená liekmi, s kritickou fázou v trvaní 3 dni a menej"},
{"code":"D70.11","desc":"Agranulocytóza a neutropénia zapríčinená liekmi, s kritickou fázou v trvaní 10 až 19 dní"},
{"code":"D70.12","desc":"Agranulocytóza a neutropénia zapríčinená liekmi, s kritickou fázou v trvaní 20 a viac dní"},
{"code":"D70.13","desc":"Agranulocytóza a neutropénia zapríčinená liekmi, s kritickou fázou v trvaní 4 až 6 dní"},
{"code":"D70.14","desc":"Agranulocytóza a neutropénia zapríčinená liekmi, s kritickou fázou v trvaní 7 až 9 dní"},
{"code":"D70.18","desc":"Agranulocytóza a neutropénia zapríčinená liekmi s iným priebehom"},
{"code":"D70.19","desc":"Agranulocytóza a neutropénia zapríčinená liekmi, bližšie neurčená"},
{"code":"D70.3","desc":"Iná agranulocytóza"},
{"code":"D70.5","desc":"Cyklická neutropénia"},
{"code":"D70.6","desc":"Iná neutropénia"},
{"code":"D70.7","desc":"Neutropénia, bližšie neurčená"},
{"code":"D71","desc":"Funkčná porucha polymorfonukleárnych neutrofilov"},
{"code":"D72.-","desc":"Iná porucha bielych krviniek"},
{"code":"D72.0","desc":"Genetická anomália leukocytov"},
{"code":"D72.1","desc":"Eozinofília"},
{"code":"D72.8","desc":"Iná bližšie určená porucha leukocytov"},
{"code":"D72.9","desc":"Porucha leukocytov, bližšie neurčená"},
{"code":"D73.-","desc":"Choroba sleziny"},
{"code":"D73.0","desc":"Hyposplenizmus"},
{"code":"D73.1","desc":"Hypersplenizmus"},
{"code":"D73.2","desc":"Chronická kongestívna splenomegália"},
{"code":"D73.3","desc":"Absces sleziny"},
{"code":"D73.4","desc":"Cysta sleziny"},
{"code":"D73.5","desc":"Infarkt sleziny"},
{"code":"D73.8","desc":"Iná choroba sleziny"},
{"code":"D73.9","desc":"Choroba sleziny, bližšie neurčená"},
{"code":"D74.-","desc":"Methemoglobinémia"},
{"code":"D74.0","desc":"Vrodená methemoglobinémia"},
{"code":"D74.8","desc":"Iná methemoglobinémia"},
{"code":"D74.9","desc":"Methemoglobinémia, bližšie neurčená"},
{"code":"D75.-","desc":"Iná choroba krvi a krvotvorných orgánov"},
{"code":"D75.0","desc":"Familiárna erytrocytóza"},
{"code":"D75.1","desc":"Sekundárna polyglobúlia [polycytémia]"},
{"code":"D75.8","desc":"Iná bližšie určená choroba krvi a krvotvorných orgánov"},
{"code":"D75.9","desc":"Choroba krvi a krvotvorných orgánov, bližšie neurčená"},
{"code":"D76.-","desc":"Iné choroby s účasťou lymforetikulového a retikulohistiocytového tkaniva, bližšie určené"},
{"code":"D76.1","desc":"Hemofagocytová lymfohistiocytóza"},
{"code":"D76.2","desc":"Hemofagocytový syndróm spojený s infekciou"},
{"code":"D76.3","desc":"Iný syndróm histiocytózy"},
{"code":"D77*","desc":"Iné poruchy krvi a krvotvorných orgánov pri  chorobách zatriedených inde"},
{"code":"D80-D90","desc":"Určité poruchy s účasťou imunitných mechanizmov"},
{"code":"D80.-","desc":"Imunodeficit s prevahou nedostatku protilátok"},
{"code":"D80.0","desc":"Dedičná hypogamaglobulinémia"},
{"code":"D80.1","desc":"Nefamiliárna hypogamaglobulinémia"},
{"code":"D80.2","desc":"Selektívny nedostatok imunoglobulínu A [IgA]"},
{"code":"D80.3","desc":"Selektívny nedostatok podtried imunoglobulínu G [IgG]"},
{"code":"D80.4","desc":"Selektívny nedostatok imunoglobulínu M [IgM]"},
{"code":"D80.5","desc":"Imunodeficit so zvýšeným imunoglobulínu M [IgM]"},
{"code":"D80.6","desc":"Deficit protilátok s takmer normálnymi imunoglobulínmi alebo s hyperimunoglobulinémiou"},
{"code":"D80.7","desc":"Prechodná hypogamaglobulinémia v detstve"},
{"code":"D80.8","desc":"Iný imunodeficit s prevahou nedostatku protilátok"},
{"code":"D80.9","desc":"Bližšie neurčený imunodeficit s prevahou nedostatku protilátok"},
{"code":"D81.-","desc":"Kombinovaný imunodeficit"},
{"code":"D81.0","desc":"Ťažký kombinovaný imunodeficit [SCID] s retikulovou dysgenézou"},
{"code":"D81.1","desc":"Ťažký kombinovaný imunodeficit [SCID] s malým počtom T-buniek a B-buniek"},
{"code":"D81.2","desc":"Ťažký kombinovaný imunodeficit [SCID] s malým alebo normálnym počtom B-buniek"},
{"code":"D81.3","desc":"Deficit adenozíndezaminázy [ADA]"},
{"code":"D81.4","desc":"Nezelofov syndróm"},
{"code":"D81.5","desc":"Nedostatok purínnukleozid-fosforylázy [PNP]"},
{"code":"D81.6","desc":"Defekt hlavného komplexu histokompatibility I. triedy"},
{"code":"D81.7","desc":"Defekt hlavného komplexu histokompatibility II. triedy"},
{"code":"D81.8","desc":"Iný kombinovaný imunodeficit"},
{"code":"D81.9","desc":"Kombinovaný imunodeficit, bližšie neurčený"},
{"code":"D82.-","desc":"Imunodeficit spojený s inými ťažkými poruchami"},
{"code":"D82.0","desc":"Wiskottov-Aldrichov syndróm"},
{"code":"D82.1","desc":"Di Georgeov syndróm"},
{"code":"D82.2","desc":"Imunodeficit spojený s disproporčným malým vzrastom"},
{"code":"D82.3","desc":"Imunodeficit spojený s vrodenou defektnou odpoveďou na Epsteinov-Barrovej vírus"},
{"code":"D82.4","desc":"Syndróm nadbytku imunoglobulínu E [IgE]"},
{"code":"D82.8","desc":"Imunodeficit spojený s inými ťažkými poruchami, bližšie určenými"},
{"code":"D82.9","desc":"Imunodeficit spojený s ťažkou poruchou, bližšie neurčenou"},
{"code":"D83.-","desc":"Bežný premenlivý imunodeficit [common variable immunodeficiency -CVID]"},
{"code":"D83.0","desc":"Bežný premenlivý imunodeficit s prevahou abnormalít v počte a funkcii B-buniek"},
{"code":"D83.1","desc":"Bežný premenlivý imunodeficit s prevažujúcimi poruchami imunoregulačných T-buniek"},
{"code":"D83.2","desc":"Bežný premenlivý imunodeficit s autoprotilátkami proti B-bunkám alebo T-bunkám"},
{"code":"D83.8","desc":"Iný bežný premenlivý imunodeficit"},
{"code":"D83.9","desc":"Bežný premenlivý imunodeficit, bližšie neurčený"},
{"code":"D84.-","desc":"Iný imunodeficit"},
{"code":"D84.0","desc":"Porucha lymfocytového funkčného antigénu 1[LFA-1]"},
{"code":"D84.1","desc":"Porucha komplementového systému"},
{"code":"D84.8","desc":"Iný imunodeficit, bližšie určený"},
{"code":"D84.9","desc":"Imunodeficit, bližšie neurčený"},
{"code":"D86.-","desc":"Sarkoidóza"},
{"code":"D86.0","desc":"Sarkoidóza pľúc"},
{"code":"D86.1","desc":"Sarkoidóza lymfatických uzlín"},
{"code":"D86.2","desc":"Sarkoidóza pľúc so sarkoidózou lymfatických uzlín"},
{"code":"D86.3","desc":"Sarkoidóza kože"},
{"code":"D86.8","desc":"Sarkoidóza na iných a viacerých miestach"},
{"code":"D86.9","desc":"Sarkoidóza, bližšie neurčená"},
{"code":"D89.-","desc":"Iná porucha s účasťou imunitných mechanizmov, nezatriedená inde"},
{"code":"D89.0","desc":"Polyklonálna hypergamaglobulinémia"},
{"code":"D89.1","desc":"Kryoglobulinémia"},
{"code":"D89.2","desc":"Hypergamaglobulinémia, bližšie neurčená"},
{"code":"D89.3","desc":"Syndróm imunitnej obnovy"},
{"code":"D89.8","desc":"Iná porucha s účasťou imunitných mechanizmov, bližšie určená, nezatriedená inde"},
{"code":"D89.9","desc":"Porucha s účasťou imunitných mechanizmov, bližšie neurčená"},
{"code":"D90","desc":"Imunodeficit po ožiarení, po chemoterapii a po iných imunosupresívnych výkonoch"},
{"code":"E00-E90","desc":"IV. kapitola - Endokrinné, nutričné a metabolické choroby (E00-E90)"},
{"code":"E00-E07","desc":"Choroby štítnej žľazy"},
{"code":"E00.-","desc":"Vrodený syndróm z nedostatku jódu"},
{"code":"E00.0","desc":"Vrodený syndróm z nedostatku jódu, neurologický typ"},
{"code":"E00.1","desc":"Vrodený syndróm z nedostatku jódu, myxedémový typ"},
{"code":"E00.2","desc":"Vrodený syndróm z nedostatku jódu, zmiešaný typ"},
{"code":"E00.9","desc":"Vrodený syndróm z nedostatku jódu, bližšie neurčený"},
{"code":"E01.-","desc":"Choroba štítnej žľazy spojená s nedostatkom jódu a pridružené stavy"},
{"code":"E01.0","desc":"Difúzna struma zapríčinená nedostatkom jódu (endemická)"},
{"code":"E01.1","desc":"Viacuzlová struma zapríčinená nedostatkom jódu (endemická)"},
{"code":"E01.2","desc":"Struma zapríčinená nedostatkom jódu (endemická), bližšie neurčená"},
{"code":"E01.8","desc":"Iná choroba štítnej žľazy spojená s nedostatkom jódu a pridružené stavy"},
{"code":"E02","desc":"Subklinická hypotyreóza z nedostatku jódu"},
{"code":"E03.-","desc":"Iná hypotyreóza"},
{"code":"E03.0","desc":"Vrodená hypotyreóza s difúznou strumou"},
{"code":"E03.1","desc":"Vrodená hypotyreóza bez strumy"},
{"code":"E03.2","desc":"Hypotyreóza zapríčinená liekmi a inými látkami exogénneho pôvodu"},
{"code":"E03.3","desc":"Poinfekčná hypotyreóza"},
{"code":"E03.4","desc":"Získaná atrofia štítnej žľazy"},
{"code":"E03.5","desc":"Myxedémová kóma"},
{"code":"E03.8","desc":"Iná hypotyreóza, bližšie určená"},
{"code":"E03.9","desc":"Hypotyreóza, bližšie neurčená"},
{"code":"E04.-","desc":"Iná netoxická struma"},
{"code":"E04.0","desc":"Netoxická difúzna struma"},
{"code":"E04.1","desc":"Netoxický solitárny uzol štítnej žľazy"},
{"code":"E04.2","desc":"Netoxická viacuzlová struma"},
{"code":"E04.8","desc":"Iná netoxická struma, bližšie určená"},
{"code":"E04.9","desc":"Netoxická struma, bližšie neurčená"},
{"code":"E05.-","desc":"Hypertyreóza [Tyreotoxikóza]"},
{"code":"E05.0","desc":"Hypertyreóza s difúznou strumou"},
{"code":"E05.1","desc":"Hypertyreóza s jedným toxickým tyreoidálnym uzlom"},
{"code":"E05.2","desc":"Hypertyreóza s toxickou viacuzlovou strumou"},
{"code":"E05.3","desc":"Hypertyreóza zapríčinená ektopickým tkanivom štítnej žľazy"},
{"code":"E05.4","desc":"Hypertyreóza zapríčinená umelo [Hyperthyreosis factitia]"},
{"code":"E05.5","desc":"Tyreotoxická kríza"},
{"code":"E05.8","desc":"Iná hypertyreóza"},
{"code":"E05.9","desc":"Hypertyreóza, bližšie neurčená"},
{"code":"E06.-","desc":"Zápal štítnej žľazy"},
{"code":"E06.0","desc":"Akútna tyreoiditída"},
{"code":"E06.1","desc":"Subakútna tyreoiditída"},
{"code":"E06.2","desc":"Chronická tyreoiditída s prechodnou hypertyreózou"},
{"code":"E06.3","desc":"Autoimunitná tyreoiditída"},
{"code":"E06.4","desc":"Tyreoiditída zapríčinená liekom"},
{"code":"E06.5","desc":"Iná chronická tyreoiditída"},
{"code":"E06.9","desc":"Tyreoiditída, bližšie neurčená"},
{"code":"E07.-","desc":"Iná choroba štítnej žľazy"},
{"code":"E07.0","desc":"Hypersekrécia kalcitonínu (Medulárny karcinóm)"},
{"code":"E07.1","desc":"Dyshormonogénna struma (hrvoľ)"},
{"code":"E07.8","desc":"Iná choroba štítnej žľazy, bližšie určená"},
{"code":"E07.9","desc":"Choroba štítnej žľazy, bližšie neurčená"},
{"code":"E10-E14","desc":"Diabetes mellitus"},
{"code":"E10.-","desc":"Diabetes mellitus 1. typu"},
{"code":"E10.0-","desc":"Diabetes mellitus 1. typu: s kómou"},
{"code":"E10.01","desc":"Diabetes mellitus 1. typu: s kómou, dekompenzovaný"},
{"code":"E10.1-","desc":"Diabetes mellitus 1. typu: s ketoacidózou"},
{"code":"E10.11","desc":"Diabetes mellitus 1. typu: s ketoacidózou, dekompenzovaný"},
{"code":"E10.2-†","desc":"Diabetes mellitus 1. typu: s obličkovými komplikáciami"},
{"code":"E10.20†","desc":"Diabetes mellitus 1. typu: s obličkovými komplikáciami, kompenzovaný"},
{"code":"E10.21†","desc":"Diabetes mellitus 1. typu: s obličkovými komplikáciami, dekompenzovaný"},
{"code":"E10.3-†","desc":"Diabetes mellitus 1. typu: s očnými komplikáciami"},
{"code":"E10.30†","desc":"Diabetes mellitus 1. typu: s očnými komplikáciami, kompenzovaný"},
{"code":"E10.31†","desc":"Diabetes mellitus 1. typu: s očnými komplikáciami, dekompenzovaný"},
{"code":"E10.4-†","desc":"Diabetes mellitus 1. typu: s nervovými komplikáciami"},
{"code":"E10.40†","desc":"Diabetes mellitus 1. typu: s nervovými komplikáciami, kompenzovaný"},
{"code":"E10.41†","desc":"Diabetes mellitus 1. typu: s nervovými komplikáciami, dekompenzovaný"},
{"code":"E10.5-","desc":"Diabetes mellitus 1. typu: s periférnymi cievnymi komplikáciami"},
{"code":"E10.50","desc":"Diabetes mellitus 1. typu: s periférnymi cievnymi komplikáciami, kompenzovaný"},
{"code":"E10.51","desc":"Diabetes mellitus 1. typu: s periférnymi cievnymi komplikáciami, dekompenzovaný"},
{"code":"E10.6-","desc":"Diabetes mellitus 1. typu: s inými, bližšie určenými komplikáciami"},
{"code":"E10.60","desc":"Diabetes mellitus 1. typu: s inými, bližšie určenými komplikáciami, kompenzovaný"},
{"code":"E10.61","desc":"Diabetes mellitus 1. typu: s inými, bližšie určenými komplikáciami, dekompenzovaný"},
{"code":"E10.7-","desc":"Diabetes mellitus 1. typu: s mnohopočetnými komplikáciami"},
{"code":"E10.72","desc":"Diabetes mellitus 1. typu: s inými, mnohopočetnými komplikáciami, kompenzovaný"},
{"code":"E10.73","desc":"Diabetes mellitus 1. typu: s inými, mnohopočetnými komplikáciami, dekompenzovaný"},
{"code":"E10.74","desc":"Diabetes mellitus 1. typu: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, kompenzovaný"},
{"code":"E10.75","desc":"Diabetes mellitus 1. typu: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, dekompenzovaný"},
{"code":"E10.8-","desc":"Diabetes mellitus 1. typu: s bližšie neurčenými komplikáciami"},
{"code":"E10.80","desc":"Diabetes mellitus 1. typu: s bližšie neurčenými komplikáciami, kompenzovaný"},
{"code":"E10.81","desc":"Diabetes mellitus 1. typu: s bližšie neurčenými komplikáciami, dekompenzovaný"},
{"code":"E10.9-","desc":"Diabetes mellitus 1. typu: bez komplikácií"},
{"code":"E10.90","desc":"Diabetes mellitus 1. typu: bez komplikácií, kompenzovaný"},
{"code":"E10.91","desc":"Diabetes mellitus 1. typu: bez komplikácií, dekompenzovaný"},
{"code":"E10.92","desc":"Diabetes mellitus 1. typu: s nárokom na CGM/FGM - začiatok liečby"},
{"code":"E10.93","desc":"Diabetes mellitus 1. typu: s nárokom na CGM/FGM - pokračovanie liečby"},
{"code":"E10.94","desc":"Diabetes mellitus 1. typu: s nárokom na CGM/FGM - začiatok liečby rizikový dospelý"},
{"code":"E10.95","desc":"Diabetes mellitus 1. typu: s nárokom na CGM/FGM - pokračovanie liečby rizikový dospelý"},
{"code":"E11.-","desc":"Diabetes mellitus 2. typu"},
{"code":"E11.0-","desc":"Diabetes mellitus 2. typu: s kómou"},
{"code":"E11.01","desc":"Diabetes mellitus 2. typu: s kómou, dekompenzovaný"},
{"code":"E11.1-","desc":"Diabetes mellitus 2. typu: s ketoacidózou"},
{"code":"E11.11","desc":"Diabetes mellitus 2. typu: s ketoacidózou, dekompenzovaný"},
{"code":"E11.2-†","desc":"Diabetes mellitus 2. typu: s obličkovými komplikáciami"},
{"code":"E11.20†","desc":"Diabetes mellitus 2. typu: s obličkovými komplikáciami, kompenzovaný"},
{"code":"E11.21†","desc":"Diabetes mellitus 2. typu: s obličkovými komplikáciami, dekompenzovaný"},
{"code":"E11.3-†","desc":"Diabetes mellitus 2. typu: s očnými komplikáciami"},
{"code":"E11.30†","desc":"Diabetes mellitus 2. typu: s očnými komplikáciami, kompenzovaný"},
{"code":"E11.31†","desc":"Diabetes mellitus 2. typu: s očnými komplikáciami, dekompenzovaný"},
{"code":"E11.4-†","desc":"Diabetes mellitus 2. typu: s nervovými komplikáciami"},
{"code":"E11.40†","desc":"Diabetes mellitus 2. typu: s nervovými komplikáciami, kompenzovaný"},
{"code":"E11.41†","desc":"Diabetes mellitus 2. typu: s nervovými komplikáciami, dekompenzovaný"},
{"code":"E11.5-","desc":"Diabetes mellitus 2. typu: s periférnymi cievnymi komplikáciami"},
{"code":"E11.50","desc":"Diabetes mellitus 2. typu: s periférnymi cievnymi komplikáciami, kompenzovaný"},
{"code":"E11.51","desc":"Diabetes mellitus 2. typu: s periférnymi cievnymi komplikáciami, dekompenzovaný"},
{"code":"E11.6-","desc":"Diabetes mellitus 2. typu: s inými, bližšie určenými komplikáciami"},
{"code":"E11.60","desc":"Diabetes mellitus 2. typu: s inými, bližšie určenými komplikáciami, kompenzovaný"},
{"code":"E11.61","desc":"Diabetes mellitus 2. typu: s inými, bližšie určenými komplikáciami, dekompenzovaný"},
{"code":"E11.7-","desc":"Diabetes mellitus 2. typu: s inými mnohopočetnými komplikáciami,"},
{"code":"E11.72","desc":"Diabetes mellitus 2. typu: s inými mnohopočetnými komplikáciami, kompenzovaný"},
{"code":"E11.73","desc":"Diabetes mellitus 2. typu: s inými mnohopočetnými komplikáciami, dekompenzovaný"},
{"code":"E11.74","desc":"Diabetes mellitus 2. typu: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, kompenzovaný"},
{"code":"E11.75","desc":"Diabetes mellitus 2. typu: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, dekompenzovaný"},
{"code":"E11.8-","desc":"Diabetes mellitus 2. typu: s bližšie neurčenými komplikáciami"},
{"code":"E11.80","desc":"Diabetes mellitus 2. typu: s bližšie neurčenými komplikáciami, kompenzovaný"},
{"code":"E11.81","desc":"Diabetes mellitus 2. typu: s bližšie neurčenými komplikáciami, dekompenzovaný"},
{"code":"E11.9-","desc":"Diabetes mellitus 2. typu: bez komplikácií"},
{"code":"E11.90","desc":"Diabetes mellitus 2. typu: bez komplikácií, kompenzovaný"},
{"code":"E11.91","desc":"Diabetes mellitus 2. typu: bez komplikácií, dekompenzovaný"},
{"code":"E12.-","desc":"Diabetes mellitus spojený s podvýživou"},
{"code":"E12.0-","desc":"Diabetes mellitus spojený s podvýživou: s kómou"},
{"code":"E12.01","desc":"Diabetes mellitus spojený s podvýživou: s kómou, dekompenzovaný"},
{"code":"E12.1-","desc":"Diabetes mellitus spojený s podvýživou: s ketoacidózouň"},
{"code":"E12.11","desc":"Diabetes mellitus spojený s podvýživou: s ketoacidózou, dekompenzovaný"},
{"code":"E12.2-†","desc":"Diabetes mellitus spojený s podvýživou: s obličkovými komplikáciami"},
{"code":"E12.20†","desc":"Diabetes mellitus spojený s podvýživou: s obličkovými komplikáciami, kompenzovaný"},
{"code":"E12.21†","desc":"Diabetes mellitus spojený s podvýživou: s obličkovými komplikáciami, dekompenzovaný"},
{"code":"E12.3-†","desc":"Diabetes mellitus spojený s podvýživou: s očnými komplikáciami"},
{"code":"E12.30†","desc":"Diabetes mellitus spojený s podvýživou: s očnými komplikáciami, kompenzovaný"},
{"code":"E12.31†","desc":"Diabetes mellitus spojený s podvýživou: s očnými komplikáciami, dekompenzovaný"},
{"code":"E12.4-†","desc":"Diabetes mellitus spojený s podvýživou: s nervovými komplikáciami"},
{"code":"E12.40†","desc":"Diabetes mellitus spojený s podvýživou: s nervovými komplikáciami, kompenzovaný"},
{"code":"E12.41†","desc":"Diabetes mellitus spojený s podvýživou: s nervovými komplikáciami, dekompenzovaný"},
{"code":"E12.5-","desc":"Diabetes mellitus spojený s podvýživou: s periférnymi cievnymi komplikáciami"},
{"code":"E12.50","desc":"Diabetes mellitus spojený s podvýživou: s periférnymi cievnymi komplikáciami, kompenzovaný"},
{"code":"E12.51","desc":"Diabetes mellitus spojený s podvýživou: s periférnymi cievnymi komplikáciami, dekompenzovaný"},
{"code":"E12.6-","desc":"Diabetes mellitus spojený s podvýživou: s periférnymi cievnymi komplikáciami"},
{"code":"E12.60","desc":"Diabetes mellitus spojený s podvýživou: s inými bližšie určenými komplikáciami, kompenzovaný"},
{"code":"E12.61","desc":"Diabetes mellitus spojený s podvýživou: s inými bližšie určenými komplikáciami, dekompenzovaný"},
{"code":"E12.7-","desc":"Diabetes mellitus spojený s podvýživou: s mnohopočetnými komplikáciami"},
{"code":"E12.72","desc":"Diabetes mellitus spojený s podvýživou: s inými mnohopočetnými komplikáciami, kompenzovaný"},
{"code":"E12.73","desc":"Diabetes mellitus spojený s podvýživou: s inými mnohopočetnými komplikáciami, dekompenzovaný"},
{"code":"E12.74","desc":"Diabetes mellitus spojený s podvýživou: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, kompenzovaný"},
{"code":"E12.75","desc":"Diabetes mellitus spojený s podvýživou: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, dekompenzovaný"},
{"code":"E12.8-","desc":"Diabetes mellitus spojený s podvýživou: s bližšie neurčenými komplikáciami"},
{"code":"E12.80","desc":"Diabetes mellitus spojený s podvýživou: s bližšie neurčenými komplikáciami, kompenzovaný"},
{"code":"E12.81","desc":"Diabetes mellitus spojený s podvýživou: s bližšie neurčenými komplikáciami, dekompenzovaný"},
{"code":"E12.9-","desc":"Diabetes mellitus spojený s podvýživou: bez komplikácií"},
{"code":"E12.90","desc":"Diabetes mellitus spojený s podvýživou: bez komplikácií, kompenzovaný"},
{"code":"E12.91","desc":"Diabetes mellitus spojený s podvýživou: bez komplikácií, dekompenzovaný"},
{"code":"E13.-","desc":"Iný diabetes mellitus, bližšie určený"},
{"code":"E13.0-","desc":"Iný diabetes mellitus, bližšie určený: s kómou"},
{"code":"E13.01","desc":"Iný diabetes mellitus, bližšie určený: s kómou, dekompenzovaný"},
{"code":"E13.1-","desc":"Iný diabetes mellitus, bližšie určený: s ketoacidózou"},
{"code":"E13.11","desc":"Iný diabetes mellitus, bližšie určený: s ketoacidózou, dekompenzovaný"},
{"code":"E13.2-†","desc":"Iný diabetes mellitus, bližšie určený: s obličkovými komplikáciami"},
{"code":"E13.20†","desc":"Iný diabetes mellitus, bližšie určený: s obličkovými komplikáciami, kompenzovaný"},
{"code":"E13.21†","desc":"Iný diabetes mellitus, bližšie určený: s obličkovými komplikáciami, dekompenzovaný"},
{"code":"E13.3-†","desc":"Iný diabetes mellitus, bližšie určený: s očnými komplikáciami"},
{"code":"E13.30†","desc":"Iný diabetes mellitus, bližšie určený: s očnými komplikáciami, kompenzovaný"},
{"code":"E13.31†","desc":"Iný diabetes mellitus, bližšie určený: s očnými komplikáciami, dekompenzovaný"},
{"code":"E13.4-†","desc":"Iný diabetes mellitus, bližšie určený: s nervovými komplikáciami"},
{"code":"E13.40†","desc":"Iný diabetes mellitus, bližšie určený: s nervovými komplikáciami, kompenzovaný"},
{"code":"E13.41†","desc":"Iný diabetes mellitus, bližšie určený: s nervovými komplikáciami, dekompenzovaný"},
{"code":"E13.5-","desc":"Iný diabetes mellitus, bližšie určený: s periférnymi cievnymi komplikáciami"},
{"code":"E13.50","desc":"Iný diabetes mellitus, bližšie určený: s periférnymi cievnymi komplikáciami, kompenzovaný"},
{"code":"E13.51","desc":"Iný diabetes mellitus, bližšie určený: s periférnymi cievnymi komplikáciami, dekompenzovaný"},
{"code":"E13.6-","desc":"Iný diabetes mellitus, bližšie určený: s inými bližšie určenými komplikáciami"},
{"code":"E13.60","desc":"Iný diabetes mellitus, bližšie určený: s inými bližšie určenými komplikáciami, kompenzovaný"},
{"code":"E13.61","desc":"Iný diabetes mellitus, bližšie určený: s inými bližšie určenými komplikáciami, dekompenzovaný"},
{"code":"E13.7-","desc":"Iný diabetes mellitus, bližšie určený: s mnohopočetnými komplikáciami"},
{"code":"E13.72","desc":"Iný diabetes mellitus, bližšie určený: s inými mnohopočetnými komplikáciami, kompenzovaný"},
{"code":"E13.73","desc":"Iný diabetes mellitus, bližšie určený: s inými mnohopočetnými komplikáciami, dekompenzovaný"},
{"code":"E13.74","desc":"Iný diabetes mellitus, bližšie určený: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, kompenzovaný"},
{"code":"E13.75","desc":"Iný diabetes mellitus, bližšie určený: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, dekompenzovaný"},
{"code":"E13.8-","desc":"Iný diabetes mellitus, bližšie určený: s bližšie neurčenými komplikáciami"},
{"code":"E13.80","desc":"Iný diabetes mellitus, bližšie určený: s bližšie neurčenými komplikáciami, kompenzovaný"},
{"code":"E13.81","desc":"Iný diabetes mellitus, bližšie určený: s bližšie neurčenými komplikáciami, dekompenzovaný"},
{"code":"E13.9-","desc":"Iný diabetes mellitus, bližšie určený: bez komplikácií"},
{"code":"E13.90","desc":"Iný diabetes mellitus, bližšie určený: bez komplikácií, kompenzovaný"},
{"code":"E13.91","desc":"Iný diabetes mellitus, bližšie určený: bez komplikácií, dekompenzovaný"},
{"code":"E14.-","desc":"Diabetes mellitus, bližšie neurčený"},
{"code":"E14.0-","desc":"Diabetes mellitus, bližšie neurčený: s kómou"},
{"code":"E14.01","desc":"Diabetes mellitus, bližšie neurčený: s kómou, dekompenzovaný"},
{"code":"E14.1-","desc":"Diabetes mellitus, bližšie neurčený: s ketoacidózou"},
{"code":"E14.11","desc":"Diabetes mellitus, bližšie neurčený: s ketoacidózou, dekompenzovaný"},
{"code":"E14.2-†","desc":"Diabetes mellitus, bližšie neurčený: s obličkovými komplikáciami"},
{"code":"E14.20†","desc":"Diabetes mellitus, bližšie neurčený: s obličkovými komplikáciami, kompenzovaný"},
{"code":"E14.21†","desc":"Diabetes mellitus, bližšie neurčený: s obličkovými komplikáciami, dekompenzovaný"},
{"code":"E14.3-†","desc":"Diabetes mellitus, bližšie neurčený: s očnými komplikáciami"},
{"code":"E14.30†","desc":"Diabetes mellitus, bližšie neurčený: s očnými komplikáciami, kompenzovaný"},
{"code":"E14.31†","desc":"Diabetes mellitus, bližšie neurčený: s očnými komplikáciami, dekompenzovaný"},
{"code":"E14.4-†","desc":"Diabetes mellitus, bližšie neurčený: s nervovými komplikáciami"},
{"code":"E14.40†","desc":"Diabetes mellitus, bližšie neurčený: s nervovými komplikáciami, kompenzovaný"},
{"code":"E14.41†","desc":"Diabetes mellitus, bližšie neurčený: s nervovými komplikáciami, dekompenzovaný"},
{"code":"E14.5-","desc":"Diabetes mellitus, bližšie neurčený: s periférnymi cievnymi komplikáciami"},
{"code":"E14.50","desc":"Diabetes mellitus, bližšie neurčený: s periférnymi cievnymi komplikáciami, kompenzovaný"},
{"code":"E14.51","desc":"Diabetes mellitus, bližšie neurčený: s periférnymi cievnymi komplikáciami, dekompenzovaný"},
{"code":"E14.6-","desc":"Diabetes mellitus, bližšie neurčený: s inými bližšie určenými komplikáciami"},
{"code":"E14.60","desc":"Diabetes mellitus, bližšie neurčený: s inými bližšie určenými komplikáciami, kompenzovaný"},
{"code":"E14.61","desc":"Diabetes mellitus, bližšie neurčený: s inými bližšie určenými komplikáciami, dekompenzovaný"},
{"code":"E14.7-","desc":"Diabetes mellitus, bližšie neurčený: s inými mnohopočetnými komplikáciami"},
{"code":"E14.72","desc":"Diabetes mellitus, bližšie neurčený: s inými mnohopočetnými komplikáciami, kompenzovaný"},
{"code":"E14.73","desc":"Diabetes mellitus, bližšie neurčený: s mnohopočetnými komplikáciami, dekompenzovaný"},
{"code":"E14.74","desc":"Diabetes mellitus, bližšie neurčený: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, kompenzovaný"},
{"code":"E14.75","desc":"Diabetes mellitus, bližšie neurčený: s mnohopočetnými komplikáciami, so syndrómom diabetickej nohy, dekompenzovaný"},
{"code":"E14.8-","desc":"Diabetes mellitus, bližšie neurčený: s bližšie neurčenými komplikáciami"},
{"code":"E14.80","desc":"Diabetes mellitus, bližšie neurčený: s bližšie neurčenými komplikáciami, kompenzovaný"},
{"code":"E14.81","desc":"Diabetes mellitus, bližšie neurčený: s bližšie neurčenými komplikáciami, dekompenzovaný"},
{"code":"E14.9-","desc":"Diabetes mellitus, bližšie neurčený: bez komplikácií"},
{"code":"E14.90","desc":"Diabetes mellitus, bližšie neurčený: bez komplikácií, kompenzovaný"},
{"code":"E14.91","desc":"Diabetes mellitus, bližšie neurčený: bez komplikácií, dekompenzovaný"},
{"code":"E15-E16","desc":"Iné poruchy regulácie glukózy v krvi a vnútorného vylučovania podžalúdkovej žľazy"},
{"code":"E15","desc":"Nediabetická hypoglykemická kóma"},
{"code":"E16.-","desc":"Iná porucha vnútorného vylučovania podžalúdkovej žľazy"},
{"code":"E16.0","desc":"Hypoglykémia zapríčinená liekmi, bez kómy"},
{"code":"E16.1","desc":"Iná hypoglykémia"},
{"code":"E16.2","desc":"Hypoglykémia, bližšie neurčená"},
{"code":"E16.3","desc":"Zvýšená sekrécia glukagónu"},
{"code":"E16.4","desc":"Abnormálna sekrécia gastrínu"},
{"code":"E16.8","desc":"Iná porucha vnútornej sekrécie podžalúdkovej žľazy, bližšie určená"},
{"code":"E16.9","desc":"Porucha vnútornej sekrécie podžalúdkovej žľazy, bližšie neurčená"},
{"code":"E20-E35","desc":"Choroby ostatných žliaz s vnútorným vylučovaním"},
{"code":"E20.-","desc":"Hypoparatyreóza"},
{"code":"E20.0","desc":"Idiopatická hypoparatyreóza"},
{"code":"E20.1","desc":"Pseudohypoparatyreóza"},
{"code":"E20.8","desc":"Iná hypoparatyreóza"},
{"code":"E20.9","desc":"Hypoparatyreóza, bližšie neurčená"},
{"code":"E21.-","desc":"Hyperparatyreóza a iné choroby prištítnych žliaz"},
{"code":"E21.0","desc":"Primárna hyperparatyreóza"},
{"code":"E21.1","desc":"Sekundárna hyperparatyreóza, nezatriedená inde"},
{"code":"E21.2","desc":"Iná hyperparatyreóza"},
{"code":"E21.3","desc":"Hyperparatyreóza, bližšie neurčená"},
{"code":"E21.4","desc":"Iná bližšie určená choroba prištítnych žliaz"},
{"code":"E21.5","desc":"Choroba prištítnych žliaz, bližšie neurčená"},
{"code":"E22.-","desc":"Hyperfunkcia hypofýzy"},
{"code":"E22.0","desc":"Akromegália a pituitárny gigantizmus"},
{"code":"E22.1","desc":"Hyperprolaktinémia"},
{"code":"E22.2","desc":"Syndróm neprimeranej sekrécie antidiuretického hormónu"},
{"code":"E22.8","desc":"Iná hyperfunkcia hypofýzy"},
{"code":"E22.9","desc":"Hyperfunkcia hypofýzy, bližšie neurčená"},
{"code":"E23.-","desc":"Hypofunkcia a iné choroby hypofýzy"},
{"code":"E23.0","desc":"Hypopituitarizmus"},
{"code":"E23.1","desc":"Hypopituitarizmus zapríčinený liekom"},
{"code":"E23.2","desc":"Diabetes insipidus"},
{"code":"E23.3","desc":"Dysfunkcia hypotalamu, nezatriedená inde"},
{"code":"E23.6","desc":"Iná choroba hypofýzy"},
{"code":"E23.7","desc":"Choroba hypofýzy, bližšie neurčená"},
{"code":"E24.-","desc":"Cushingov syndróm"},
{"code":"E24.0","desc":"Centrálny Cushingov syndróm"},
{"code":"E24.1","desc":"Nelsonov syndróm"},
{"code":"E24.2","desc":"Cushingov syndróm zapríčinený liekom"},
{"code":"E24.3","desc":"Ektopický ACTH-syndróm"},
{"code":"E24.4","desc":"Pseudo-Cushingov syndróm zapríčinený alkoholom"},
{"code":"E24.8","desc":"Periférny Cushingov syndróm"},
{"code":"E24.9","desc":"Cushingov syndróm, bližšie neurčený"},
{"code":"E25.-","desc":"Kongenitálna adrenálna  hyperplázia"},
{"code":"E25.0-","desc":"Kongenitálna adrenálna hyperplázia spojená s nedostatkom enzýmov"},
{"code":"E25.00","desc":"Adrenogenitálna porucha, typ 3 (nedostatok 21-hydroxylázy), klasická forma"},
{"code":"E25.01","desc":"Adrenogenitálna porucha, typ 3 (nedostatok 21-hydroxylázy), forma s neskorým nástupom"},
{"code":"E25.08","desc":"Iná vrodená adrenogenitálna porucha spojená s nedostatkom enzýmov"},
{"code":"E25.09","desc":"Vrodená adrenogenitálna porucha spojená s nedostatkom enzýmov, bližšie neurčená"},
{"code":"E25.8","desc":"Iná adrenogenitálna porucha"},
{"code":"E25.9","desc":"Adrenogenitálna porucha, bližšie neurčená"},
{"code":"E26.-","desc":"Hyperaldosteronizmus"},
{"code":"E26.0","desc":"Primárny hyperaldosteronizmus"},
{"code":"E26.1","desc":"Sekundárny hyperaldosteronizmus"},
{"code":"E26.8","desc":"Iný hyperaldosteronizmus"},
{"code":"E26.9","desc":"Hyperaldosteronizmus, bližšie neurčený"},
{"code":"E27.-","desc":"Iná choroba nadobličiek"},
{"code":"E27.0","desc":"Iná hyperfunkcia kôry nadobličiek"},
{"code":"E27.1","desc":"Primárna nedostatočnosť kôry nadobličiek (Addisonova choroba)"},
{"code":"E27.2","desc":"Addisonova kríza"},
{"code":"E27.3","desc":"Nedostatočnosť kôry nadobličiek zapríčinená liekmi"},
{"code":"E27.4","desc":"Iná a bližšie neurčená nedostatočnosť kôry nadobličiek"},
{"code":"E27.5","desc":"Hyperfunkcia drene nadobličiek"},
{"code":"E27.8","desc":"Iná choroba nadobličiek, bližšie určená"},
{"code":"E27.9","desc":"Choroba nadobličiek, bližšie neurčená"},
{"code":"E28.-","desc":"Porucha funkcie vaječníkov"},
{"code":"E28.0","desc":"Porucha funkcie vaječníkov s nadbytkom estrogénov"},
{"code":"E28.1","desc":"Porucha funkcie vaječníkov s nadbytkom androgénov"},
{"code":"E28.2","desc":"Syndróm polycystických ovárií"},
{"code":"E28.3","desc":"Primárna nedostatočnosť vaječníkov"},
{"code":"E28.8","desc":"Iná porucha funkcie vaječníkov"},
{"code":"E28.9","desc":"Porucha funkcie vaječníkov, bližšie neurčená"},
{"code":"E29.-","desc":"Porucha funkcie semenníkov"},
{"code":"E29.0","desc":"Hyperfunkcia semenníkov"},
{"code":"E29.1","desc":"Hypofunkcia semenníkov"},
{"code":"E29.8","desc":"Iná porucha funkcie semenníkov"},
{"code":"E29.9","desc":"Porucha funkcie semenníkov, bližšie neurčená"},
{"code":"E30.-","desc":"Porucha puberty, nezatriedená inde"},
{"code":"E30.0","desc":"Oneskorená puberta"},
{"code":"E30.1","desc":"Predčasná puberta"},
{"code":"E30.8","desc":"Iná porucha puberty"},
{"code":"E30.9","desc":"Porucha puberty, bližšie neurčená"},
{"code":"E31.-","desc":"Porucha činnosti viacerých žliaz s vnútorným vylučovaním [polyglandulárna dysfunkcia]"},
{"code":"E31.0","desc":"Autoimunitná polyglandulárna insuficiencia"},
{"code":"E31.1","desc":"Polyglandulárna hyperfunkcia"},
{"code":"E31.8","desc":"Iná polyglandulárna dysfunkcia"},
{"code":"E31.9","desc":"Polyglandulárna dysfunkcia, bližšie neurčená"},
{"code":"E32.-","desc":"Choroba týmusu"},
{"code":"E32.0","desc":"Pretrvávajúca hyperplázia týmusu"},
{"code":"E32.1","desc":"Absces týmusu"},
{"code":"E32.8","desc":"Iná choroba týmusu"},
{"code":"E32.9","desc":"Choroba týmusu, bližšie neurčená"},
{"code":"E34.-","desc":"Iná choroba žliaz s vnútorným vylučovaním"},
{"code":"E34.0","desc":"Karcinoidový syndróm"},
{"code":"E34.1","desc":"Iná hypersekrécia črevných hormónov"},
{"code":"E34.2","desc":"Ektopická sekrécia hormónov, nezatriedená inde"},
{"code":"E34.3","desc":"Nízky vzrast, nezatriedený inde"},
{"code":"E34.4","desc":"Konštitučne vysoká postava"},
{"code":"E34.5-","desc":"Syndróm rezistencie na androgény"},
{"code":"E34.50","desc":"Syndróm čiastočnej rezistencie na androgény"},
{"code":"E34.51","desc":"Syndróm kompletnej rezistencie na androgény"},
{"code":"E34.59","desc":"Syndróm rezistencie na androgény, bližšie neurčený"},
{"code":"E34.8","desc":"Iná bližšie určená endokrinná porucha"},
{"code":"E34.9","desc":"Endokrinná porucha, bližšie neurčená"},
{"code":"E35.-*","desc":"Porucha žliaz s vnútorným vylučovaním pri chorobách zatriedených inde"},
{"code":"E35.0*","desc":"Endokrinná porucha, bližšie neurčená"},
{"code":"E35.1*","desc":"Choroba štítnej žľazy pri chorobách zatriedených inde"},
{"code":"E35.8*","desc":"Choroba nadobličiek pri chorobách zatriedených inde"},
{"code":"E40-E46","desc":"Choroby z podvýživy"},
{"code":"E40","desc":"Kvašiorkor"},
{"code":"E41","desc":"Nutričný marazmus"},
{"code":"E42","desc":"Marantický kvašiorkor"},
{"code":"E43","desc":"Ťažká proteínovo-energetická podvýživa, bližšie neurčená"},
{"code":"E44.-","desc":"Proteínovo-energetická podvýživa stredného a mierneho stupňa"},
{"code":"E44.0","desc":"Proteínovo-energetická podvýživa stredného stupňa"},
{"code":"E44.1","desc":"Proteínovo-energetická podvýživa mierneho stupňa"},
{"code":"E45","desc":"Zaostávanie vo vývine v dôsledku proteínovo-energetickej podvýživy"},
{"code":"E46","desc":"Proteínovo-energetická podvýživa, bližšie neurčená"},
{"code":"E50-E64","desc":"Iné nedostatky vo výžive"},
{"code":"E50.-","desc":"Nedostatok vitamínu A"},
{"code":"E50.0","desc":"Nedostatok vitamínu A so xerózou spojovky"},
{"code":"E50.1","desc":"Nedostatok vitamínu A s Bitotovými škvrnami a xerózou spojovky"},
{"code":"E50.2","desc":"Nedostatok vitamínu A s xerózou rohovky"},
{"code":"E50.3","desc":"Nedostatok vitamínu A s ulceráciou a xerózou rohovky"},
{"code":"E50.4","desc":"Nedostatok vitamínu A s keratomaláciou"},
{"code":"E50.5","desc":"Nedostatok vitamínu A s nočnou slepotou"},
{"code":"E50.6","desc":"Nedostatok vitamínu A so xeroftalmickými jazvami rohovky"},
{"code":"E50.7","desc":"Iné očné prejavy nedostatku vitamínu A"},
{"code":"E50.8","desc":"Iné prejavy nedostatku vitamínu A"},
{"code":"E50.9","desc":"Nedostatok vitamínu A, bližšie neurčený"},
{"code":"E51.-","desc":"Nedostatok tiamínu"},
{"code":"E51.1","desc":"Beri-beri"},
{"code":"E51.2","desc":"Wernickeho encefalopatia"},
{"code":"E51.8","desc":"Iný prejav nedostatku tiamínu"},
{"code":"E51.9","desc":"Nedostatok tiamínu, bližšie neurčený"},
{"code":"E52","desc":"Nedostatok niacínu [Pellagra]"},
{"code":"E53.-","desc":"Nedostatok iného vitamínu skupiny B"},
{"code":"E53.0","desc":"Nedostatok riboflavínu"},
{"code":"E53.1","desc":"Nedostatok pyridoxínu"},
{"code":"E53.8","desc":"Nedostatok iného bližšie určeného vitamínu skupiny B"},
{"code":"E53.9","desc":"Nedostatok vitamínu skupiny B, bližšie neurčený"},
{"code":"E54","desc":"Nedostatok vitamínu C"},
{"code":"E55.-","desc":"Nedostatok vitamínu D"},
{"code":"E55.0","desc":"Aktívna rachitída"},
{"code":"E55.9","desc":"Nedostatok vitamínu D, bližšie neurčený"},
{"code":"E56.-","desc":"Iný nedostatok vitamínu"},
{"code":"E56.0","desc":"Nedostatok vitamínu E"},
{"code":"E56.1","desc":"Nedostatok vitamínu K"},
{"code":"E56.8","desc":"Nedostatok iného vitamínu"},
{"code":"E56.9","desc":"Nedostatok vitamínu, bližšie neurčený"},
{"code":"E58","desc":"Nedostatok vápnika vo výžive"},
{"code":"E59","desc":"Nedostatok selénu vo výžive"},
{"code":"E60","desc":"Nedostatok zinku vo výžive"},
{"code":"E61.-","desc":"Nedostatok iného stopového prvku vo výžive"},
{"code":"E61.0","desc":"Nedostatok medi"},
{"code":"E61.1","desc":"Nedostatok železa"},
{"code":"E61.2","desc":"Nedostatok horčíka"},
{"code":"E61.3","desc":"Nedostatok mangánu"},
{"code":"E61.4","desc":"Nedostatok chrómu"},
{"code":"E61.5","desc":"Nedostatok molybdénu"},
{"code":"E61.6","desc":"Nedostatok vanádu"},
{"code":"E61.7","desc":"Nedostatok viacerých stopových prvkov"},
{"code":"E61.8","desc":"Nedostatok iného bližšie určeného stopového prvku"},
{"code":"E61.9","desc":"Nedostatok stopového prvku, bližšie neurčený"},
{"code":"E63.-","desc":"Iný nedostatok vo výžive"},
{"code":"E63.0","desc":"Nedostatok esenciálnych mastných kyselín"},
{"code":"E63.1","desc":"Nedostatok vo výžive v dôsledku nerovnováhy v zložení potravy"},
{"code":"E63.8","desc":"Iný nedostatok vo výžive, bližšie určený"},
{"code":"E63.9","desc":"Nedostatok vo výžive, bližšie neurčený"},
{"code":"E64.-","desc":"Následky podvýživy a iných nedostatkov vo výžive"},
{"code":"E64.0","desc":"Následky proteínovo-energetickej podvýživy"},
{"code":"E64.1","desc":"Následky nedostatku vitamínu A"},
{"code":"E64.2","desc":"Následky nedostatku vitamínu C"},
{"code":"E64.3","desc":"Následky rachitídy"},
{"code":"E64.8","desc":"Následky iných nedostatkov vo výžive"},
{"code":"E64.9","desc":"Následky bližšie neurčených nedostatkov vo výžive"},
{"code":"E65-E68","desc":"Obezita a iná nadmerná výživa"},
{"code":"E65","desc":"Lokalizovaná obezita"},
{"code":"E66.-","desc":"Obezita"},
{"code":"E66.0-","desc":"Obezita zapríčinená nadmerným energetickým príjmom"},
{"code":"E66.00","desc":"Obezita zapríčinená nadmerným energetickým príjmom, BMI: od 30 do menej ako 35"},
{"code":"E66.01","desc":"Obezita zapríčinená nadmerným energetickým príjmom, BMI: od 35 do menej ako 40"},
{"code":"E66.02","desc":"Obezita zapríčinená nadmerným energetickým príjmom, BMI: od 40 a viac"},
{"code":"E66.09","desc":"Obezita zapríčinená nadmerným energetickým príjmom, BMI neurčený"},
{"code":"E66.1-","desc":"Obezita zapríčinená liekmi"},
{"code":"E66.10","desc":"Obezita zapríčinená liekmi, BMI: od 30 do menej ako 35"},
{"code":"E66.11","desc":"Obezita zapríčinená liekmi, BMI: od 35 do menej ako 40"},
{"code":"E66.12","desc":"Obezita zapríčinená liekmi, BMI: 40 a viac"},
{"code":"E66.19","desc":"Obezita zapríčinená liekmi, BMI: neurčený"},
{"code":"E66.2-","desc":"Extrémna obezita s alveolovou hypoventiláciou"},
{"code":"E66.20","desc":"Extrémna obezita s alveolovou hypoventiláciou, BMI: od 30 do menej ako 35"},
{"code":"E66.21","desc":"Extrémna obezita s alveolovou hypoventiláciou, BMI: od 35 do menej ako 40"},
{"code":"E66.22","desc":"Extrémna obezita s alveolovou hypoventiláciou, BMI: 40 a viac"},
{"code":"E66.29","desc":"Extrémna obezita s alveolovou hypoventiláciou, BMI: bližšie neurčený"},
{"code":"E66.8-","desc":"Iná obezita"},
{"code":"E66.80","desc":"Iná obezita, BMI: od 30 do menej ako 35"},
{"code":"E66.81","desc":"Iná obezita, BMI: od 35 do menej ako 40"},
{"code":"E66.82","desc":"Iná obezita, BMI: 40 a viac"},
{"code":"E66.89","desc":"Iná obezita, BMI: neurčený"},
{"code":"E66.9-","desc":"Obezita, bližšie neurčená"},
{"code":"E66.90","desc":"Obezita, bližšie neurčená, BMI: od 30 do menej ako 35"},
{"code":"E66.91","desc":"Obezita, bližšie neurčená, BMI: od 35 do menej ako 40"},
{"code":"E66.92","desc":"Obezita, bližšie neurčená, BMI: 40 a viac"},
{"code":"E66.99","desc":"Obezita, bližšie neurčená, BMI: neurčený"},
{"code":"E67.-","desc":"Iná nadmerná výživa"},
{"code":"E67.0","desc":"Hypervitaminóza A"},
{"code":"E67.1","desc":"Hyperkarotenémia"},
{"code":"E67.2","desc":"Syndróm nadbytku vitamínu B6"},
{"code":"E67.3","desc":"Hypervitaminóza D"},
{"code":"E67.8","desc":"Iná bližšie určená nadmerná výživa"},
{"code":"E68","desc":"Následok nadmernej výživy"},
{"code":"E70-E90","desc":"Metabolické poruchy"},
{"code":"E70.-","desc":"Porucha metabolizmu aromatických aminokyselín"},
{"code":"E70.0","desc":"Klasická fenylketonúria"},
{"code":"E70.1","desc":"Iná hyperfenylalaninémia"},
{"code":"E70.2","desc":"Porucha metabolizmu tyrozínu"},
{"code":"E70.3","desc":"Albinizmus"},
{"code":"E70.8","desc":"Iná porucha metabolizmu aromatických aminokyselín"},
{"code":"E70.9","desc":"Porucha metabolizmu aromatických aminokyselín, bližšie neurčená"},
{"code":"E71.-","desc":"Poruchy metabolizmu aminokyselín s rozvetveným reťazcom a poruchy metabolizmu mastných kyselín"},
{"code":"E71.0","desc":"Choroba javorového sirupu [Leucinóza]"},
{"code":"E71.1","desc":"Iná porucha metabolizmu aminokyselín s rozvetveným reťazcom"},
{"code":"E71.2","desc":"Porucha metabolizmu aminokyselín s rozvetveným reťazcom, bližšie neurčená"},
{"code":"E71.3","desc":"Porucha metabolizmu mastných kyselín"},
{"code":"E72.-","desc":"Iná porucha metabolizmu aminokyselín"},
{"code":"E72.0","desc":"Porucha transportu aminokyselín"},
{"code":"E72.1","desc":"Porucha metabolizmu aminokyselín obsahujúcich síru"},
{"code":"E72.2","desc":"Porucha metabolického cyklu močoviny"},
{"code":"E72.3","desc":"Porucha metabolizmu lyzínu a hydroxylyzínu"},
{"code":"E72.4","desc":"Porucha metabolizmu ornitínu"},
{"code":"E72.5","desc":"Porucha metabolizmu glycínu"},
{"code":"E72.8","desc":"Iná porucha metabolizmu aminokyselín, bližšie určená"},
{"code":"E72.9","desc":"Porucha metabolizmu aminokyselín, bližšie neurčená"},
{"code":"E73.-","desc":"Intolerancia laktózy"},
{"code":"E73.0","desc":"Vrodený nedostatok laktázy"},
{"code":"E73.1","desc":"Sekundárny nedostatok laktázy"},
{"code":"E73.8","desc":"Iná intolerancia laktózy"},
{"code":"E73.9","desc":"Intolerancia laktózy, bližšie neurčená"},
{"code":"E74.-","desc":"Iná porucha metabolizmu sacharidov"},
{"code":"E74.0","desc":"Choroba z nahromadenia glykogénu (glykogenóza)"},
{"code":"E74.1","desc":"Porucha metabolizmu fruktózy"},
{"code":"E74.2","desc":"Porucha metabolizmu galaktózy"},
{"code":"E74.3","desc":"Iná porucha črevnej absorpcie sacharidov"},
{"code":"E74.4","desc":"Porucha metabolizmu pyruvátu a porucha glukoneogenézy"},
{"code":"E74.8","desc":"Iná porucha metabolizmu sacharidov, bližšie určená"},
{"code":"E74.9","desc":"Porucha metabolizmu sacharidov, bližšie neurčená"},
{"code":"E75.-","desc":"Porucha metabolizmu sfingolipidov a iná porucha ukladania lipidov"},
{"code":"E75.0","desc":"GM2-gangliozidóza"},
{"code":"E75.1","desc":"Iná gangliozidóza"},
{"code":"E75.2","desc":"Iná sfingolipidóza"},
{"code":"E75.3","desc":"Sfingolipidóza, bližšie neurčená"},
{"code":"E75.4","desc":"Neurónová ceroidová lipofuscinóza"},
{"code":"E75.5","desc":"Iná porucha ukladania lipidov"},
{"code":"E75.6","desc":"Porucha ukladania lipidov, bližšie neurčená"},
{"code":"E76.-","desc":"Porucha metabolizmu glykozaminoglykánu"},
{"code":"E76.0","desc":"Mukopolysacharidóza, typ I"},
{"code":"E76.1","desc":"Mukopolysacharidóza, typ II"},
{"code":"E76.2","desc":"Iná mukopolysacharidóza"},
{"code":"E76.3","desc":"Mukopolysacharidóza, bližšie neurčená"},
{"code":"E76.8","desc":"Iná porucha metabolizmu glykozaminoglykánu"},
{"code":"E76.9","desc":"Porucha metabolizmu glykozaminoglykánu, bližšie neurčená"},
{"code":"E77.-","desc":"Porucha metabolizmu glykoproteínov"},
{"code":"E77.0","desc":"Porucha posttranslančnej modifikácie lyzozómových enzýmov"},
{"code":"E77.1","desc":"Porucha odbúrania glykoproteínov"},
{"code":"E77.8","desc":"Iná porucha metabolizmu glykoproteínov"},
{"code":"E77.9","desc":"Porucha metabolizmu glykoproteínov, bližšie neurčená"},
{"code":"E78.-","desc":"Porucha metabolizmu lipoproteínov a iná lipidémia"},
{"code":"E78.0","desc":"Izolovaná hypercholesterolémia"},
{"code":"E78.1","desc":"Izolovaná hypertriacylglycerolémia"},
{"code":"E78.2","desc":"Zmiešaná hyperlipidémia"},
{"code":"E78.3","desc":"Hyperchylomikronémia"},
{"code":"E78.4","desc":"Iná hyperlipidémia"},
{"code":"E78.5","desc":"Hyperlipidémia, bližšie neurčená"},
{"code":"E78.6","desc":"Nedostatok lipoproteínov"},
{"code":"E78.8","desc":"Iná porucha metabolizmu lipoproteínov"},
{"code":"E78.9","desc":"Porucha metabolizmu lipoproteínov, bližšie neurčená"},
{"code":"E79.-","desc":"Porucha metabolizmu purínu a pyrimidínu"},
{"code":"E79.0","desc":"Hyperurikémia bez znakov zápalovej artritídy alebo tofovej dny"},
{"code":"E79.1","desc":"Leschov-Nyhanov syndróm"},
{"code":"E79.8","desc":"Iná porucha metabolizmu purínu a pyrimidínu"},
{"code":"E79.9","desc":"Porucha metabolizmu purínu a pyrimidínu, bližšie neurčená"},
{"code":"E80.-","desc":"Porucha metabolizmu porfyrínu a bilirubínu"},
{"code":"E80.0","desc":"Dedičná erytropoetická porfýria"},
{"code":"E80.1","desc":"Porphyria cutanea tarda"},
{"code":"E80.2","desc":"Iná porfýria"},
{"code":"E80.3","desc":"Porucha katalázy a peroxidázy"},
{"code":"E80.4","desc":"Gilbertov syndróm"},
{"code":"E80.5","desc":"Criglerov-Najjarov syndróm"},
{"code":"E80.6","desc":"Iná porucha metabolizmu bilirubínu"},
{"code":"E80.7","desc":"Porucha metabolizmu bilirubínu, bližšie neurčená"},
{"code":"E83.-","desc":"Porucha metabolizmu minerálov"},
{"code":"E83.0","desc":"Porucha metabolizmu medi"},
{"code":"E83.1","desc":"Porucha metabolizmu železa"},
{"code":"E83.2","desc":"Porucha metabolizmu zinku"},
{"code":"E83.3-","desc":"Porucha metabolizmu fosforu a fosfatázy"},
{"code":"E83.30","desc":"Familiárna hypofosfatemická rachitída"},
{"code":"E83.31","desc":"Rachitída závislá od vitamínu D"},
{"code":"E83.38","desc":"Iná porucha metabolizmu fosforu a fosfatáz"},
{"code":"E83.39","desc":"Porucha metabolizmu fosforu a fosfatáz, bližšie neurčená"},
{"code":"E83.4","desc":"Porucha metabolizmu horčíka"},
{"code":"E83.5-","desc":"Porucha metabolizmu vápnika"},
{"code":"E83.50","desc":"Kalcifylaxia"},
{"code":"E83.58","desc":"Iná porucha metabolizmu vápnika"},
{"code":"E83.59","desc":"Porucha metabolizmu vápnika, bližšie neurčená"},
{"code":"E83.8","desc":"Iná porucha metabolizmu minerálov"},
{"code":"E83.9","desc":"Porucha metabolizmu minerálov, bližšie neurčená"},
{"code":"E84.-","desc":"Cystická fibróza a primárna ciliárna dyskinézia"},
{"code":"E84.0","desc":"Cystická fibróza s pľúcnymi prejavmi"},
{"code":"E84.1","desc":"Cystická fibróza s črevnými prejavmi"},
{"code":"E84.2","desc":"Primárna ciliárna dyskinézia s respiračnými prejavmi"},
{"code":"E84.3","desc":"Primárna ciliárna dyskinézia s inými prejavmi"},
{"code":"E84.4","desc":"Primárna ciliárna dyskinézia"},
{"code":"E84.8-","desc":"Cystická fibróza s inými prejavmi"},
{"code":"E84.80","desc":"Cystická fibróza s pľúcnymi a črevnými prejavmi"},
{"code":"E84.87","desc":"Cystická fibróza s inými mnohopočetnými prejavmi"},
{"code":"E84.88","desc":"Cystická fibróza s inými prejavmi"},
{"code":"E84.9","desc":"Cystická fibróza, bližšie neurčená"},
{"code":"E85.-","desc":"Amyloidóza"},
{"code":"E85.0","desc":"Hereditárno-familiárna amyloidóza bez neuropatie"},
{"code":"E85.1","desc":"Hereditárno-familiárna amyloidóza s neuropatiou"},
{"code":"E85.2","desc":"Hereditárno-familiárna amyloidóza, bližšie neurčená"},
{"code":"E85.3","desc":"Sekundárna systémová amyloidóza"},
{"code":"E85.4","desc":"Orgánovo ohraničená (lokalizovaná) amyloidóza"},
{"code":"E85.8","desc":"Iná amyloidóza"},
{"code":"E85.9","desc":"Amyloidóza, bližšie neurčená"},
{"code":"E86","desc":"Dehydratácia, hypovolémia"},
{"code":"E87.-","desc":"Iná porucha rovnováhy tekutín, elektrolytov a acidobázickej rovnováhy"},
{"code":"E87.0","desc":"Hyperosmolalita a hypernatriémia"},
{"code":"E87.1","desc":"Hypoosmolalita a hyponatriémia"},
{"code":"E87.2","desc":"Acidóza"},
{"code":"E87.3","desc":"Alkalóza"},
{"code":"E87.4","desc":"Zmiešaná porucha acidobázickej rovnováhy"},
{"code":"E87.5","desc":"Hyperkaliémia"},
{"code":"E87.6","desc":"Hypokaliémia"},
{"code":"E87.7","desc":"Preťaženie tekutinou"},
{"code":"E87.8","desc":"Iná porucha rovnováhy elektrolytov a tekutín, nezatriedená inde"},
{"code":"E88.-","desc":"Iná porucha metabolizmu"},
{"code":"E88.0","desc":"Porucha metabolizmu plazmatických proteínov, nezatriedená inde"},
{"code":"E88.1","desc":"Lipodystrofia, nezatriedená inde"},
{"code":"E88.2","desc":"Lipomatóza, nezatriedená inde"},
{"code":"E88.3","desc":"Syndróm z rozpadu nádoru (tumor lysis syndrome)"},
{"code":"E88.8","desc":"Iná bližšie určená metabolická porucha"},
{"code":"E88.9","desc":"Metabolická porucha, bližšie neurčená"},
{"code":"E89.-","desc":"Endokrinná a metabolická porucha po lekárskom výkone, nezatriedená inde"},
{"code":"E89.0","desc":"Hypotyreóza po lekárskom výkone"},
{"code":"E89.1","desc":"Hypoinzulinémia po lekárskom výkone"},
{"code":"E89.2","desc":"Hypoparatyreóza po lekárskom výkone"},
{"code":"E89.3","desc":"Hypopituitarizmus po lekárskom výkone"},
{"code":"E89.4","desc":"Zlyhanie vaječníkov po lekárskom výkone"},
{"code":"E89.5","desc":"Hypofunkcia semenníkov po lekárskom výkone"},
{"code":"E89.6","desc":"Hypofunkcia kôry nadobličiek alebo drene nadobličiek po lekárskom výkone"},
{"code":"E89.8","desc":"Iná endokrinná a metabolická porucha po lekárskom výkone"},
{"code":"E89.9","desc":"Endokrinná a metabolická porucha po lekárskom výkone, bližšie neurčená"},
{"code":"E90*","desc":"Nutričná a metabolická porucha pri chorobách zatriedených inde"},
{"code":"F00-F99","desc":"V. kapitola - Duševné poruchy a poruchy správania (F00-F99)"},
{"code":"F00-F09","desc":"Organické duševné poruchy vrátane symptomatických"},
{"code":"F00.-*","desc":"Demencia pri Alzheimerovej chorobe (G30.-†)"},
{"code":"F00.0*","desc":"Demencia pri Alzheimerovej chorobe so skorým začiatkom (typ 2) (G30.0†)"},
{"code":"F00.1*","desc":"Demencia pri Alzheimerovej chorobe s neskorým začiatkom (typ 1) (G30.1†)"},
{"code":"F00.2*","desc":"Demencia pri Alzheimerovej chorobe, atypická alebo zmiešaná forma (G30.8†)"},
{"code":"F00.9*","desc":"Demencia pri Alzheimerovej chorobe, bližšie neurčená"},
{"code":"F01.-","desc":"Vaskulárna demencia"},
{"code":"F01.0","desc":"Vaskulárna demencia s akútnym začiatkom"},
{"code":"F01.1","desc":"Mnohoinfarktová demencia"},
{"code":"F01.2","desc":"Subkortikálna vaskulárna demencia"},
{"code":"F01.3","desc":"Zmiešaná kortikálna a subkortikálna vaskulárna demencia"},
{"code":"F01.8","desc":"Iná vaskulárna demencia"},
{"code":"F01.9","desc":"Vaskulárna demencia, bližšie neurčená"},
{"code":"F02.-*","desc":"Demencia pri iných chorobách zatriedených inde"},
{"code":"F02.0*","desc":"Demencia pri Pickovej chorobe (G31.0†)"},
{"code":"F02.1*","desc":"Demencia pri Creutzfeldtovej-Jakobovej chorobe (A81.0†)"},
{"code":"F02.2*","desc":"Demencia pri Huntingtonovej chorobe (G10†)"},
{"code":"F02.3*","desc":"Demencia pri Parkinsonovej chorobe (G20.-†)"},
{"code":"F02.4*","desc":"Demencia pri chorobe HIV (B22†)"},
{"code":"F02.8*","desc":"Demencia pri iných bližšie určených chorobách zatriedených inde"},
{"code":"F03","desc":"Demencia, bližšie neurčená"},
{"code":"F04","desc":"Organický amnestický syndróm nezapríčinený alkoholom alebo inými psychoaktívnymi látkami"},
{"code":"F05.-","desc":"Delírium nezapríčinené alkoholom alebo inými psychoaktívnymi látkami"},
{"code":"F05.0","desc":"Delírium bez demencie"},
{"code":"F05.1","desc":"Delírium pri demencii"},
{"code":"F05.8","desc":"Iné delírium"},
{"code":"F05.9","desc":"Delírium, bližšie neurčené"},
{"code":"F06.-","desc":"Iné duševné poruchy zapríčinené poškodením a dysfunkciou mozgu a somatickou chorobou"},
{"code":"F06.0","desc":"Organická halucinóza"},
{"code":"F06.1","desc":"Organická katatonická porucha"},
{"code":"F06.2","desc":"Organická porucha s bludmi [podobná schizofrénii]"},
{"code":"F06.3","desc":"Organická afektívna porucha"},
{"code":"F06.4","desc":"Organická úzkostná porucha"},
{"code":"F06.5","desc":"Organická disociatívna porucha"},
{"code":"F06.6","desc":"Emočná labilita [asténia] na organickom podklade"},
{"code":"F06.7","desc":"Ľahká kognitívna porucha"},
{"code":"F06.8","desc":"Iná psychická porucha zapríčinená poškodením a dysfunkciou mozgu a somatickou chorobou, bližšie určená"},
{"code":"F06.9","desc":"Psychická porucha zapríčinená poškodením a dysfunkciou mozgu a somatickou chorobou, bližšie neurčená"},
{"code":"F07.-","desc":"Poruchy osobnosti a správania zapríčinená chorobou, poškodením a dysfunkciou mozgu"},
{"code":"F07.0","desc":"Porucha osobnosti na organickom podklade"},
{"code":"F07.1","desc":"Postencefalitický syndróm"},
{"code":"F07.2","desc":"Postkomočný syndróm"},
{"code":"F07.8","desc":"Iná organická porucha osobnosti a správania zapríčinená chorobou, poškodením a dysfunkciou mozgu"},
{"code":"F07.9","desc":"Organická porucha osobnosti a správania zapríčinená chorobou, poškodením a dysfunkciou mozgu, bližšie neurčená"},
{"code":"F09","desc":"Organická alebo symptomatická psychická porucha, bližšie neurčená"},
{"code":"F10-F19","desc":"Poruchy psychiky a správania zapríčinené užívaním psychoaktívnych látok"},
{"code":"F10.-","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu"},
{"code":"F10.0","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu: akútna intoxikácia"},
{"code":"F10.1","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu: škodlivé užívanie"},
{"code":"F10.2","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu: syndróm závislosti"},
{"code":"F10.3","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu: abstinenčný syndróm"},
{"code":"F10.4","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu: abstinenčný syndróm s delíriom"},
{"code":"F10.5","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu: psychotická porucha"},
{"code":"F10.6","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu: amnestický syndróm"},
{"code":"F10.7","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F10.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním alkoholu"},
{"code":"F10.9","desc":"Porucha psychiky a správania zapríčinená užívaním alkoholu, bližšie neurčená"},
{"code":"F11.-","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov"},
{"code":"F11.0","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov: akútna intoxikácia"},
{"code":"F11.1","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov: škodlivé užívanie"},
{"code":"F11.2","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov: syndróm závislosti"},
{"code":"F11.3","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov: abstinenčný syndróm"},
{"code":"F11.4","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov: abstinenčný syndróm s delíriom"},
{"code":"F11.5","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov: psychotická porucha"},
{"code":"F11.6","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov: amnestický syndróm"},
{"code":"F11.7","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F11.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním opiátov"},
{"code":"F11.9","desc":"Porucha psychiky a správania zapríčinená užívaním opiátov, bližšie neurčená"},
{"code":"F12.-","desc":"Porucha psychiky a správania zapríčinená užívaním kanabinoidov"},
{"code":"F12.0","desc":"Porucha psychiky a správania zapríčinená užívaním kanabinoidov: akútna intoxikácia"},
{"code":"F12.1","desc":"Porucha psychiky a správania zapríčinená užívaním kanabinoidov: škodlivé užívanie"},
{"code":"F12.2","desc":"Porucha psychiky a správania zapríčinená užívaním kanabinoidov: syndróm závislosti"},
{"code":"F12.3","desc":"Porucha psychiky a správania zapríčinená užívaním kanabinoidov: abstinenčný syndróm"},
{"code":"F12.4","desc":"Porucha psychiky a správania. zapríčinená užívaním kanabinoidov: abstinenčný syndróm s delíriom"},
{"code":"F12.5","desc":"Porucha psychiky a správania zapríčinená užívaním kanabinoidov: psychotická porucha"},
{"code":"F12.6","desc":"Porucha psychiky a správania zapríčinená užívaním kanabinoidov: amnestický syndróm"},
{"code":"F12.7","desc":"Porucha psychiky a správania zapríčinená užívaním kanabinoidov: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F12.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním kanabinoidov"},
{"code":"F12.9","desc":"Porucha psychiky a správania zapríčinená užívaním kanabinoidov, bližšie neurčená"},
{"code":"F13.-","desc":"Porucha psychiky a správania zapríčinená užitím (užívaním) sedatív alebo hypnotík"},
{"code":"F13.0","desc":"Porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík: akútna intoxikácia"},
{"code":"F13.1","desc":"Porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík: škodlivé užívanie"},
{"code":"F13.2","desc":"Porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík: syndróm závislosti"},
{"code":"F13.3","desc":"Porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík: abstinenčný syndróm"},
{"code":"F13.4","desc":"Porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík: abstinenčný syndróm s delíriom"},
{"code":"F13.5","desc":"Porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík: psychotická porucha"},
{"code":"F13.6","desc":"Porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík: amnestický syndróm"},
{"code":"F13.7","desc":"Porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F13.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík"},
{"code":"F13.9","desc":"Porucha psychiky a správania zapríčinená užívaním sedatív alebo hypnotík, bližšie neurčená"},
{"code":"F14.-","desc":"Porucha psychiky a správania zapríčinená užívaním kokaínu"},
{"code":"F14.0","desc":"Porucha psychiky a správania zapríčinená užívaním kokaínu: akútna intoxikácia"},
{"code":"F14.1","desc":"Porucha psychiky a správania zapríčinená užívaním kokaínu: škodlivé užívanie"},
{"code":"F14.2","desc":"Porucha psychiky a správania zapríčinená užívaním kokaínu: syndróm závislosti"},
{"code":"F14.3","desc":"Porucha psychiky a správania zapríčinená užívaním kokaínu: abstinenčný syndróm"},
{"code":"F14.4","desc":"Porucha psychiky a správania zapríčinená užívaním kokaínu: abstinenčný syndróm s delíriom"},
{"code":"F14.5","desc":"Porucha psychiky a správania zapríčinená užívaním kokaínu: psychotická porucha"},
{"code":"F14.6","desc":"Porucha psychiky a správania zapríčinená užívaním kokaínu: amnestický syndróm"},
{"code":"F14.7","desc":"Porucha psychiky a správania zapríčinená užívaním kokaínu: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F14.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním kokaínu"},
{"code":"F14.9","desc":"Porucha psychiky a správania zapríčinená, užívaním kokaínu, bližšie neurčená"},
{"code":"F15.-","desc":"Porucha psychiky a správania zapríčinená, užívaním iných stimulancií vrátane kofeínu"},
{"code":"F15.0","desc":"Porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu: akútna intoxikácia"},
{"code":"F15.1","desc":"Porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu: škodlivé užívanie"},
{"code":"F15.2","desc":"Porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu: syndróm závislosti"},
{"code":"F15.3","desc":"Porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu: abstinenčný syndróm"},
{"code":"F15.4","desc":"Porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu: abstinenčný syndróm s delíriom"},
{"code":"F15.5","desc":"Porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu: psychotická porucha"},
{"code":"F15.6","desc":"Porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu: amnestický syndróm"},
{"code":"F15.7","desc":"Porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F15.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu"},
{"code":"F15.9","desc":"Porucha psychiky a správania zapríčinená užívaním iných stimulancií vrátane kofeínu, bližšie neurčená"},
{"code":"F16.-","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov"},
{"code":"F16.0","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov: akútna intoxikácia"},
{"code":"F16.1","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov: škodlivé užívanie"},
{"code":"F16.2","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov: syndróm závislosti"},
{"code":"F16.3","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov: abstinenčný syndróm"},
{"code":"F16.4","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov: abstinenčný syndróm s delíriom"},
{"code":"F16.5","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov: psychotická porucha"},
{"code":"F16.6","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov: amnestický syndróm"},
{"code":"F16.7","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F16.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním halucinogénov"},
{"code":"F16.9","desc":"Porucha psychiky a správania zapríčinená užívaním halucinogénov, bližšie neurčená"},
{"code":"F17.-","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku"},
{"code":"F17.0","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku: akútna intoxikácia"},
{"code":"F17.1","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku: škodlivé užívanie"},
{"code":"F17.2","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku: syndróm závislosti"},
{"code":"F17.3","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku: abstinenčný syndróm"},
{"code":"F17.4","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku: abstinenčný syndróm s delíriom"},
{"code":"F17.5","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku: psychotická porucha"},
{"code":"F17.6","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku: amnestický syndróm"},
{"code":"F17.7","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F17.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním tabaku"},
{"code":"F17.9","desc":"Porucha psychiky a správania zapríčinená užívaním tabaku, bližšie neurčená"},
{"code":"F18.-","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel"},
{"code":"F18.0","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel: akútna intoxikácia"},
{"code":"F18.1","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel: škodlivé užívanie"},
{"code":"F18.2","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel: syndróm závislosti"},
{"code":"F18.3","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel: abstinenčný syndróm"},
{"code":"F18.4","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel: abstinenčný syndróm s delíriom"},
{"code":"F18.5","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel: psychotická porucha"},
{"code":"F18.6","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel: amnestický syndróm"},
{"code":"F18.7","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F18.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel"},
{"code":"F18.9","desc":"Porucha psychiky a správania zapríčinená užívaním prchavých rozpúšťadiel, bližšie neurčená"},
{"code":"F19.-","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok"},
{"code":"F19.0","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok: akútna intoxikácia"},
{"code":"F19.1","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok: škodlivé užívanie"},
{"code":"F19.2","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok: syndróm závislosti"},
{"code":"F19.3","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok: abstinenčný syndróm"},
{"code":"F19.4","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok: abstinenčný syndróm s delíriom"},
{"code":"F19.5","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok: psychotická porucha"},
{"code":"F19.6","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok: amnestický syndróm"},
{"code":"F19.7","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok: reziduálna a neskoro nastupujúca psychotická porucha"},
{"code":"F19.8","desc":"Iná porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok"},
{"code":"F19.9","desc":"Porucha psychiky a správania zapríčinená užívaním viacerých drog a iných psychoaktívnych látok, bližšie neurčená"},
{"code":"F20-F29","desc":"Schizofrénia, schizotypové poruchy a poruchy s bludmi"},
{"code":"F20.-","desc":"Schizofrénia"},
{"code":"F20.0","desc":"Paranoidná schizofrénia"},
{"code":"F20.1","desc":"Hebefrénna schizofrénia"},
{"code":"F20.2","desc":"Katatonická schizofrénia"},
{"code":"F20.3","desc":"Nediferencovaná schizofrénia"},
{"code":"F20.4","desc":"Postchizofrenická depresia"},
{"code":"F20.5","desc":"Reziduálna schizofrénia"},
{"code":"F20.6","desc":"Jednoduchá schizofrénia"},
{"code":"F20.8","desc":"Iná schizofrénia"},
{"code":"F20.9","desc":"Schizofrénia, bližšie neurčená"},
{"code":"F21","desc":"Schizotypová porucha"},
{"code":"F22.-","desc":"Pretrvávajúce poruchy s bludmi"},
{"code":"F22.0","desc":"Porucha s bludmi"},
{"code":"F22.8","desc":"Iná pretrvávajúca porucha s bludmi"},
{"code":"F22.9","desc":"Pretrvávajúca porucha s bludmi, bližšie neurčená"},
{"code":"F23.-","desc":"Akútne a prechodné psychotické poruchy"},
{"code":"F23.0","desc":"Akútna polymorfná psychotická porucha bez príznakov schizofrénie"},
{"code":"F23.1","desc":"Akútna polymorfná psychotická porucha s príznakmi schizofrénie"},
{"code":"F23.2","desc":"Akútna psychotická porucha podobná schizofrénii"},
{"code":"F23.3","desc":"Iné akútne psychotické poruchy s prevahou bludov"},
{"code":"F23.8","desc":"Iné akútne a prechodné psychotické poruchy"},
{"code":"F23.9","desc":"Akútne a prechodné psychotické poruchy, bližšie neurčené"},
{"code":"F24","desc":"Indukovaná porucha s bludmi"},
{"code":"F25.-","desc":"Schizoafektívne poruchy"},
{"code":"F25.0","desc":"Schizoafektívna porucha, manický typ"},
{"code":"F25.1","desc":"Schizoafektívna porucha, depresívny typ"},
{"code":"F25.2","desc":"Schizoafektívna porucha, zmiešaný typ"},
{"code":"F25.8","desc":"Iná schizoafektívna porucha"},
{"code":"F25.9","desc":"Schizoafektívna porucha, bližšie neurčená"},
{"code":"F28","desc":"Iná neorganická psychotická porucha"},
{"code":"F29","desc":"Neorganická psychóza, bližšie neurčená"},
{"code":"F30-F39","desc":"Afektívne poruchy"},
{"code":"F30.-","desc":"Manická epizóda"},
{"code":"F30.0","desc":"Hypománia"},
{"code":"F30.1","desc":"Mánia bez psychotických príznakov"},
{"code":"F30.2","desc":"Mánia s psychotickými príznakmi"},
{"code":"F30.8","desc":"Iná manická epizóda"},
{"code":"F30.9","desc":"Manická epizóda, bližšie neurčená"},
{"code":"F31.-","desc":"Bipolárna afektívna porucha"},
{"code":"F31.0","desc":"Bipolárna afektívna porucha, terajšia hypomanická epizóda"},
{"code":"F31.1","desc":"Bipolárna afektívna porucha, terajšia manická epizóda bez psychotických príznakov"},
{"code":"F31.2","desc":"Bipolárna afektívna porucha, terajšia manická epizóda s psychotickými príznakmi"},
{"code":"F31.3","desc":"Bipolárna afektívna porucha, terajšia epizóda miernej alebo stredne ťažkej depresie"},
{"code":"F31.4","desc":"Bipolárna afektívna porucha, terajšia epizóda ťažkej depresie bez psychotických príznakov"},
{"code":"F31.5","desc":"Bipolárna afektívna porucha, terajšia epizóda ťažkej depresie s psychotickými príznakmi"},
{"code":"F31.6","desc":"Bipolárna afektívna porucha, terajšia zmiešaná epizóda"},
{"code":"F31.7","desc":"Bipolárna afektívna porucha, teraz v remisii"},
{"code":"F31.8","desc":"Iná bipolárna afektívna porucha"},
{"code":"F31.9","desc":"Bipolárna afektívna porucha, bližšie neurčená"},
{"code":"F32.-","desc":"Depresívna epizóda"},
{"code":"F32.0","desc":"Epizóda ľahkej depresie"},
{"code":"F32.1","desc":"Epizóda stredne ťažkej depresie"},
{"code":"F32.2","desc":"Epizóda ťažkej depresie bez psychotických príznakov"},
{"code":"F32.3","desc":"Epizóda ťažkej depresie s psychotickými príznakmi"},
{"code":"F32.8","desc":"Iná depresívna epizóda"},
{"code":"F32.9","desc":"Depresívna epizóda, bližšie neurčená"},
{"code":"F33.-","desc":"Recidivujúca depresívna porucha"},
{"code":"F33.0","desc":"Recidivujúca depresívna porucha, terajšia ľahká epizóda"},
{"code":"F33.1","desc":"Recidivujúca depresívna porucha, terajšia stredne ťažká epizóda"},
{"code":"F33.2","desc":"Recidivujúca depresívna porucha, terajšia ťažká epizóda bez psychotických príznakov"},
{"code":"F33.3","desc":"Recidivujúca depresívna porucha, terajšia ťažká epizóda s psychotickými príznakmi"},
{"code":"F33.4","desc":"Recidivujúca depresívna porucha, teraz v remisii"},
{"code":"F33.8","desc":"Iná recidivujúca depresívna porucha"},
{"code":"F33.9","desc":"Recidivujúca depresívna porucha, bližšie neurčená"},
{"code":"F34.-","desc":"Pretrvávajúca afektívna porucha"},
{"code":"F34.0","desc":"Cyklotýmia"},
{"code":"F34.1","desc":"Dystýmia"},
{"code":"F34.8","desc":"Iná pretrvávajúca afektívna porucha"},
{"code":"F34.9","desc":"Pretrvávajúca afektívna porucha, bližšie neurčená"},
{"code":"F38.-","desc":"Iné afektívne poruchy"},
{"code":"F38.0","desc":"Iná jednotlivá afektívna porucha"},
{"code":"F38.1","desc":"Iná recidivujúca afektívna porucha"},
{"code":"F38.8","desc":"Iná bližšie určená afektívna porucha"},
{"code":"F39","desc":"Afektívna porucha, bližšie neurčená"},
{"code":"F40-F48","desc":"Neurotické poruchy, poruchy podmienené stresom a somatoformné poruchy"},
{"code":"F40.-","desc":"Fóbická úzkostná porucha"},
{"code":"F40.0-","desc":"Agorafóbia"},
{"code":"F40.00","desc":"Agorafóbia bez panickej poruchy"},
{"code":"F40.01","desc":"Agorafóbia s panickou poruchou"},
{"code":"F40.1","desc":"Sociálna fóbia"},
{"code":"F40.2","desc":"Špecifická (izolovaná) fóbia"},
{"code":"F40.8","desc":"Iná fóbická úzkostná porucha"},
{"code":"F40.9","desc":"Fóbická úzkostná porucha"},
{"code":"F41.-","desc":"Iné úzkostné poruchy"},
{"code":"F41.0","desc":"Panická porucha [epizodická záchvatová úzkosť]"},
{"code":"F41.1","desc":"Generalizovaná úzkostná porucha"},
{"code":"F41.2","desc":"Zmiešaná úzkostná a depresívna porucha"},
{"code":"F41.3","desc":"Iná zmiešaná úzkostná porucha"},
{"code":"F41.8","desc":"Iná úzkostná porucha, bližšie určená"},
{"code":"F41.9","desc":"Úzkostná porucha, bližšie neurčená"},
{"code":"F42.-","desc":"Obsedantno-kompulzívna porucha"},
{"code":"F42.0","desc":"Prevažne obsedantné myšlienky alebo ruminácie"},
{"code":"F42.1","desc":"Prevažne kompulzívne konanie [obsedantné rituály]"},
{"code":"F42.2","desc":"Zmiešané obsesívne myšlienky a konanie"},
{"code":"F42.8","desc":"Iné obsedantno-kompulzívna poruchy"},
{"code":"F42.9","desc":"Obsedantno-kompulzívna poruchy, bližšie neurčené"},
{"code":"F43.-","desc":"Reakcia na ťažký stres a adaptačné poruchy"},
{"code":"F43.0","desc":"Akútna stresová reakcia"},
{"code":"F43.1","desc":"Posttraumatická stresová porucha"},
{"code":"F43.2","desc":"Adaptačné poruchy"},
{"code":"F43.8","desc":"Iná reakcia na ťažký stres"},
{"code":"F43.9","desc":"Reakcia na ťažký stres, bližšie neurčená"},
{"code":"F44.-","desc":"Disociatívne [konverzné] poruchy"},
{"code":"F44.0","desc":"Disociatívna amnézia"},
{"code":"F44.1","desc":"Disociatívna fúga"},
{"code":"F44.2","desc":"Disociačný stupor"},
{"code":"F44.3","desc":"Tranz a posadnutosť"},
{"code":"F44.4","desc":"Disociatívna motorická porucha"},
{"code":"F44.5","desc":"Disociatívne záchvaty"},
{"code":"F44.6","desc":"Disociatívna anestézia a strata citlivosti"},
{"code":"F44.7","desc":"Zmiešaná disociatívna [konverzná] porucha"},
{"code":"F44.8-","desc":"Iná disociatívna [konverzná] porucha"},
{"code":"F44.80","desc":"Ganserov syndróm"},
{"code":"F44.81","desc":"Viacpočetná osobnosť"},
{"code":"F44.82","desc":"Prechodná disociatívna [konverzná] porucha v detstve a v mladosti"},
{"code":"F44.88","desc":"Iná disociatívna [konverzná] porucha"},
{"code":"F44.9","desc":"Disociatívna [konverzná] porucha, bližšie neurčená"},
{"code":"F45.-","desc":"Somatoformné poruchy"},
{"code":"F45.0","desc":"Somatizačná porucha"},
{"code":"F45.1","desc":"Nediferencovaná somatoformná porucha"},
{"code":"F45.2","desc":"Hypochondrická porucha"},
{"code":"F45.3-","desc":"Somatoformná autonómna dysfunkcia"},
{"code":"F45.30","desc":"Somatoformná autonómna dysfunkcia srdca a obehovej sústavy"},
{"code":"F45.31","desc":"Somatoformná autonómna dysfunkcia horného úseku tráviacej trubice"},
{"code":"F45.32","desc":"Somatoformná autonómna dysfunkcia dolného úseku tráviacej trubice"},
{"code":"F45.33","desc":"Somatoformná autonómna dysfunkcia dýchacej sústavy"},
{"code":"F45.34","desc":"Somatoformná autonómna dysfunkcia močovopohlavnej sústavy"},
{"code":"F45.37","desc":"Somatoformná autonómna dysfunkcia viacerých orgánov a sústav"},
{"code":"F45.38","desc":"Somatoformná autonómna dysfunkcia iných orgánov a sústav"},
{"code":"F45.39","desc":"Somatoformná autonómna dysfunkcia bližšie neurčeného orgánu a sústavy"},
{"code":"F45.4-","desc":"Pretrvávajúca bolesťová porucha"},
{"code":"F45.40","desc":"Pretrvávajúca somatoformná bolesťová porucha"},
{"code":"F45.41","desc":"Chronická bolesťová porucha so somatickými a psychickými faktormi"},
{"code":"F45.8","desc":"Iná somatoformná porucha"},
{"code":"F45.9","desc":"Somatoformná porucha, bližšie neurčená"},
{"code":"F48.-","desc":"Iné neurotické poruchy"},
{"code":"F48.0","desc":"Neurasténia"},
{"code":"F48.1","desc":"Syndróm depersonalizácie a derealizácie"},
{"code":"F48.8","desc":"Iné neurotické poruchy, bližšie určené"},
{"code":"F48.9","desc":"Neurotická porucha, bližšie neurčená"},
{"code":"F50-F59","desc":"Poruchy správania spojené s poruchami fyziologických funkcií a somatických faktorov"},
{"code":"F50.-","desc":"Porucha príjmu potravy"},
{"code":"F50.0","desc":"Mentálna anorexia"},
{"code":"F50.1","desc":"Atypická mentálna anorexia"},
{"code":"F50.2","desc":"Mentálna bulímia"},
{"code":"F50.3","desc":"Atypická mentálna bulímia"},
{"code":"F50.4","desc":"Prejedanie spojené s inými psychickými poruchami"},
{"code":"F50.5","desc":"Vracanie spojené s inými psychickými poruchami"},
{"code":"F50.8","desc":"Iná porucha príjmu potravy"},
{"code":"F50.9","desc":"Porucha príjmu potravy, bližšie neurčená"},
{"code":"F51.-","desc":"Neorganická porucha spánku"},
{"code":"F51.0","desc":"Neorganická nespavosť"},
{"code":"F51.1","desc":"Neorganická spavosť"},
{"code":"F51.2","desc":"Neorganická porucha spánkového rytmu"},
{"code":"F51.3","desc":"Námesačnosť [somnambulizmus]"},
{"code":"F51.4","desc":"Nočná hrôza [pavor nocturnus]"},
{"code":"F51.5","desc":"Nočná mora"},
{"code":"F51.8","desc":"Iná neorganická porucha spánku"},
{"code":"F51.9","desc":"Neorganická porucha spánku, bližšie neurčená"},
{"code":"F52.-","desc":"Poruchy pohlavných funkcií nezapríčinené organickou poruchou alebo chorobou"},
{"code":"F52.0","desc":"Nedostatok alebo strata pohlavnej túžby"},
{"code":"F52.1","desc":"Sexuálna averzia a nedostatok sexuálnej rozkoše"},
{"code":"F52.2","desc":"Zlyhanie genitálnej odpovede"},
{"code":"F52.3","desc":"Porucha orgazmu"},
{"code":"F52.4","desc":"Predčasná ejakulácia"},
{"code":"F52.5","desc":"Neorganický vaginizmus"},
{"code":"F52.6","desc":"Neorganická dyspareunia"},
{"code":"F52.7","desc":"Nadmerná pohlavná túžba"},
{"code":"F52.8","desc":"Iná porucha pohlavných funkcií, nezapríčinená organickou poruchou alebo chorobou"},
{"code":"F52.9","desc":"Porucha pohlavných funkcií, nezapríčinená organickou poruchou alebo chorobou, bližšie neurčená"},
{"code":"F53.-","desc":"Porucha psychiky a správania v šestonedelí, nezatriedená inde"},
{"code":"F53.0","desc":"Mierna duševná porucha a porucha správania v šestonedelí, nezatriedená inde"},
{"code":"F53.1","desc":"Ťažká duševná porucha a porucha správania v šestonedelí, nezatriedená inde"},
{"code":"F53.8","desc":"Iná duševná porucha a porucha správania v šestonedelí, nezatriedená inde"},
{"code":"F53.9","desc":"Duševná porucha v šestonedelí, bližšie neurčená"},
{"code":"F54","desc":"Psychické faktory a faktory správania spojené s poruchami alebo chorobami zatriedenými inde"},
{"code":"F55.-","desc":"Škodlivé užívanie látok, ktoré nevytvárajú závislosť"},
{"code":"F55.0","desc":"Škodlivé užívanie látok nevyvolávajúcich závislosť: antidepresíva"},
{"code":"F55.1","desc":"Škodlivé užívanie látok nevyvolávajúcich závislosť: laxanciá"},
{"code":"F55.2","desc":"Škodlivé užívanie látok nevyvolávajúcich závislosť: analgetiká"},
{"code":"F55.3","desc":"Škodlivé užívanie látok nevyvolávajúcich závislosť: antacidá"},
{"code":"F55.4","desc":"Škodlivé užívanie látok nevyvolávajúcich závislosť: vitamíny"},
{"code":"F55.5","desc":"Škodlivé užívanie látok nevyvolávajúcich závislosť: steroidy a hormóny"},
{"code":"F55.6","desc":"Škodlivé užívanie látok nevyvolávajúcich závislosť: liečivé rastliny alebo prírodné liečivé prípravky"},
{"code":"F55.8","desc":"Škodlivé užívanie látok nevyvolávajúcich závislosť: iná látky"},
{"code":"F55.9","desc":"Škodlivé užívanie látok nevyvolávajúcich závislosť: bližšie neurčené látky"},
{"code":"F59","desc":"Poruchy správania spojené s poruchami fyziologických funkcií a somatických faktorov, bližšie neurčené"},
{"code":"F60-F69","desc":"Poruchy osobnosti a správania dospelých"},
{"code":"F60.-","desc":"Špecifické poruchy osobnosti"},
{"code":"F60.0","desc":"Paranoidná porucha osobnosti"},
{"code":"F60.1","desc":"Schizoidná porucha osobnosti"},
{"code":"F60.2","desc":"Disociálna porucha osobnosti"},
{"code":"F60.3-","desc":"Emočne nestabilná porucha osobnosti"},
{"code":"F60.30","desc":"Emočne nestabilná osobnosť, impulzívny typ"},
{"code":"F60.31","desc":"Emočne nestabilná osobnosť, hraničný typ"},
{"code":"F60.4","desc":"Histriónska porucha osobnosti"},
{"code":"F60.5","desc":"Anankastická porucha osobnosti"},
{"code":"F60.6","desc":"Úzkostná [vyhýbavá] porucha osobnosti"},
{"code":"F60.7","desc":"Závislá [astenická] porucha osobnosti"},
{"code":"F60.8","desc":"Iná špecifická porucha osobnosti"},
{"code":"F60.9","desc":"Porucha osobnosti, bližšie neurčená"},
{"code":"F61","desc":"Zmiešaná a iná porucha osobnosti"},
{"code":"F62.-","desc":"Pretrvávajúca zmena osobnosti, ktorú nemožno prisúdiť poškodeniu alebo chorobe mozgu"},
{"code":"F62.0","desc":"Trvalá zmena osobnosti po katastrofickom zážitku"},
{"code":"F62.1","desc":"Trvalá zmena osobnosti po psychickej chorobe"},
{"code":"F62.8-","desc":"Iná pretrvávajúca zmena osobnosti"},
{"code":"F62.80","desc":"Osobnostný syndróm chronickej bolesti"},
{"code":"F62.88","desc":"Iná pretrvávajúca zmena osobnosti"},
{"code":"F62.9","desc":"Pretrvávajúca zmena osobnosti, bližšie neurčená"},
{"code":"F63.-","desc":"Porucha návykov a impulzov"},
{"code":"F63.0","desc":"Patologické hráčstvo"},
{"code":"F63.1","desc":"Patologické podpaľačstvo [Pyromania]"},
{"code":"F63.2","desc":"Patologické kradnutie [Kleptománia]"},
{"code":"F63.3","desc":"Trichotilománia"},
{"code":"F63.8","desc":"Iná porucha návykov a impulzov"},
{"code":"F63.9","desc":"Porucha návykov a impulzov, bližšie neurčená"},
{"code":"F64.-","desc":"Porucha pohlavnej identity"},
{"code":"F64.0","desc":"Transsexualizmus"},
{"code":"F64.1","desc":"Transvestizmus"},
{"code":"F64.2","desc":"Porucha pohlavnej identity v detstve"},
{"code":"F64.8","desc":"Iná porucha pohlavnej identity"},
{"code":"F64.9","desc":"Porucha pohlavnej identity, bližšie neurčená"},
{"code":"F65.-","desc":"Porucha sexuálneho zamerania"},
{"code":"F65.0","desc":"Fetišizmus"},
{"code":"F65.1","desc":"Fetišistický transvestizmus"},
{"code":"F65.2","desc":"Exhibicionizmus"},
{"code":"F65.3","desc":"Voyeurizmus"},
{"code":"F65.4","desc":"Pedofília"},
{"code":"F65.5","desc":"Sadomasochizmus"},
{"code":"F65.6","desc":"Viacpočetná porucha sexuálneho zamerania"},
{"code":"F65.8","desc":"Iná porucha sexuálneho zamerania"},
{"code":"F65.9","desc":"Porucha sexuálneho zamerania, bližšie neurčená"},
{"code":"F66.-","desc":"Porucha psychiky a správania spojená so sexuálnym vývinom a orientáciou"},
{"code":"F66.0","desc":"Porucha pohlavného dozrievania"},
{"code":"F66.1","desc":"Egodystonická sexuálna orientácia"},
{"code":"F66.2","desc":"Porucha sexuálneho vzťahu"},
{"code":"F66.8","desc":"Iná porucha psychiky a správania spojená so sexuálnym vývinom a orientáciou"},
{"code":"F66.9","desc":"Porucha psychiky a správania spojená so sexuálnym vývinom a orientáciou, bližšie neurčená"},
{"code":"F68.-","desc":"Iná porucha osobnosti a správania dospelých"},
{"code":"F68.0","desc":"Zvýraznenie fyzických príznakov z psychických dôvodov"},
{"code":"F68.1","desc":"Úmyselné vyvolávanie alebo predstieranie somatických alebo psychických príznakov, alebo nespôsobilostí [predstieraná porucha]"},
{"code":"F68.8","desc":"Iná porucha osobnosti a správania dospelých, bližšie určená"},
{"code":"F69","desc":"Porucha osobnosti a správania dospelých, bližšie neurčená"},
{"code":"F70-F79","desc":"Duševná zaostalosť [Mentálna retardácia]"},
{"code":"F70.-","desc":"Mierna duševná zaostalosť"},
{"code":"F70.0","desc":"Mierna duševná zaostalosť bez poruchy správania alebo s minimálnou poruchou správania"},
{"code":"F70.1","desc":"Mierna duševná zaostalosť s významnou poruchou správania a s potrebou pozorovania alebo liečby"},
{"code":"F70.8","desc":"Mierna duševná zaostalosť s inou poruchou správania"},
{"code":"F70.9","desc":"Mierna duševná zaostalosť bez údaja o poruche správania"},
{"code":"F71.-","desc":"Stredne ťažká duševná zaostalosť"},
{"code":"F71.0","desc":"Stredne ťažká duševná zaostalosť bez poruchy správania alebo s minimálnou poruchou správania"},
{"code":"F71.1","desc":"Stredne ťažká duševná zaostalosť s významnou poruchou správania a s potrebou pozorovania alebo liečby"},
{"code":"F71.8","desc":"Stredne ťažká duševná zaostalosť s inou poruchou správania"},
{"code":"F71.9","desc":"Stredne ťažká duševná zaostalosť bez údaja o poruche správania"},
{"code":"F72.-","desc":"Ťažká duševná zaostalosť"},
{"code":"F72.0","desc":"Ťažká duševná zaostalosť bez poruchy správania alebo s minimálnou poruchou správania"},
{"code":"F72.1","desc":"Ťažká duševná zaostalosť s významnou poruchou správania a s potrebou pozorovania alebo liečby"},
{"code":"F72.8","desc":"Ťažká duševná zaostalosť s inou poruchou správania"},
{"code":"F72.9","desc":"Ťažká duševná zaostalosť bez údaja o poruche správania"},
{"code":"F73.-","desc":"Hlboká duševná zaostalosť"},
{"code":"F73.0","desc":"Hlboká duševná zaostalosť bez poruchy správania alebo s minimálnou poruchou správania"},
{"code":"F73.1","desc":"Hlboká duševná zaostalosť s významnou poruchou správania a s potrebou pozorovania alebo liečby"},
{"code":"F73.8","desc":"Hlboká duševná zaostalosť s inou poruchou správania"},
{"code":"F73.9","desc":"Hlboká duševná zaostalosť bez údaja o poruche správania"},
{"code":"F74.-","desc":"Disociácia inteligencie"},
{"code":"F74.0","desc":"Disociácia inteligencie bez poruchy správania alebo s minimálnou poruchou"},
{"code":"F74.1","desc":"Disociácia inteligencie s významnou poruchou správania a s potrebou pozorovania alebo liečby"},
{"code":"F74.8","desc":"Disociácia inteligencie s inou poruchou správania"},
{"code":"F74.9","desc":"Disociácia inteligencie, bez udania poruchy správania"},
{"code":"F78.-","desc":"Iná duševná zaostalosť"},
{"code":"F78.0","desc":"Iná duševná zaostalosť bez poruchy správania alebo s minimálnou poruchou správania"},
{"code":"F78.1","desc":"Iná duševná zaostalosť s významnou poruchou správania a s potrebou pozorovania alebo liečby"},
{"code":"F78.8","desc":"Iná duševná zaostalosť s inou poruchou správania"},
{"code":"F78.9","desc":"Iná duševná zaostalosť, bez údaja o poruche správania"},
{"code":"F79.-","desc":"Duševná zaostalosť, bližšie neurčená"},
{"code":"F79.0","desc":"Duševná zaostalosť bližšie neurčená, bez poruchy správania alebo s minimálnou poruchou správania"},
{"code":"F79.1","desc":"Duševná zaostalosť bližšie neurčená, s významnou poruchou správania a s potrebou pozorovania alebo liečby"},
{"code":"F79.8","desc":"Duševná zaostalosť bližšie neurčená, s inou poruchou správania"},
{"code":"F79.9","desc":"Duševná zaostalosť bližšie neurčená, bez údaja o poruche správania"},
{"code":"F80-F89","desc":"Poruchy psychického vývinu"},
{"code":"F80.-","desc":"Špecifická vývinová porucha reči a jazyka"},
{"code":"F80.0","desc":"Špecifická porucha artikulácie"},
{"code":"F80.1","desc":"Expresívna porucha reči"},
{"code":"F80.2-","desc":"Perceptívna porucha reči"},
{"code":"F80.20","desc":"Porucha sluchového vnímania a spracovania"},
{"code":"F80.28","desc":"Iná perceptívna porucha reči"},
{"code":"F80.3","desc":"Získaná afázia s epilepsiou [Landauov-Kleffnerov syndróm]"},
{"code":"F80.8","desc":"Iná vývinová porucha reči a jazyka"},
{"code":"F80.9","desc":"Vývinová porucha reči a jazyka, bližšie neurčená"},
{"code":"F81.-","desc":"Špecifická porucha vývinu školských zručností"},
{"code":"F81.0","desc":"Špecifická porucha čítania"},
{"code":"F81.1","desc":"Špecifická porucha hláskovania"},
{"code":"F81.2","desc":"Špecifická porucha aritmetických schopností"},
{"code":"F81.3","desc":"Zmiešaná porucha školských zručností"},
{"code":"F81.8","desc":"Iná vývinová porucha školských zručností"},
{"code":"F81.9","desc":"Vývinová porucha školských zručností, bližšie neurčená"},
{"code":"F82.-","desc":"Špecifická porucha vývinu motorických funkcií"},
{"code":"F82.0","desc":"Špecifická porucha vývinu hrubej motoriky"},
{"code":"F82.1","desc":"Špecifická porucha vývinu jemnej motoriky a grafomotoriky"},
{"code":"F82.2","desc":"Špecifická porucha vývinu ústnej motoriky"},
{"code":"F82.9","desc":"Špecifická porucha vývinu motorických funkcií, bližšie neurčená"},
{"code":"F83","desc":"Zmiešaná špecifická vývinová porucha"},
{"code":"F84.-","desc":"Pervazívna vývinová porucha"},
{"code":"F84.0","desc":"Detský autizmus"},
{"code":"F84.1","desc":"Atypický autizmus"},
{"code":"F84.2","desc":"Rettov syndróm"},
{"code":"F84.3","desc":"Iná detská dezintegračná porucha"},
{"code":"F84.4","desc":"Hyperaktívna porucha spojená s duševnou zaostalosťou a stereotypnými pohybmi"},
{"code":"F84.5","desc":"Aspergerov syndróm"},
{"code":"F84.8","desc":"Iná pervazívna vývinová porucha"},
{"code":"F84.9","desc":"Pervazívná vývinová porucha, bližšie neurčená"},
{"code":"F88","desc":"Iná porucha psychického vývinu"},
{"code":"F89","desc":"Porucha psychického vývinu, bližšie neurčená"},
{"code":"F90-F98","desc":"Poruchy správania a emotivity so zvyčajným začiatkom v detstve a počas dospievania"},
{"code":"F90.-","desc":"Hyperkinetická porucha"},
{"code":"F90.0","desc":"Porucha aktivity a pozornosti"},
{"code":"F90.1","desc":"Hyperkinetická porucha správania"},
{"code":"F90.8","desc":"Iná hyperkinetická porucha"},
{"code":"F90.9","desc":"Hyperkinetická porucha, bližšie neurčená"},
{"code":"F91.-","desc":"Porucha správania"},
{"code":"F91.0","desc":"Porucha správania viazaná na vzťahy v rodine"},
{"code":"F91.1","desc":"Porucha sociálneho správania pri chýbaní sociálnych väzieb"},
{"code":"F91.2","desc":"Porucha sociálneho správania so zachovaním sociálnych väzieb"},
{"code":"F91.3","desc":"Porucha správania s opozičným, vzdorovitým správaním"},
{"code":"F91.8","desc":"Iná porucha správania"},
{"code":"F91.9","desc":"Porucha správania, bližšie neurčená"},
{"code":"F92.-","desc":"Zmiešaná porucha správania a emotivity"},
{"code":"F92.0","desc":"Depresívna porucha správania"},
{"code":"F92.8","desc":"Iná zmiešaná porucha správania a emotivity"},
{"code":"F92.9","desc":"Zmiešaná porucha správania a emotivity, bližšie neurčená"},
{"code":"F93.-","desc":"Porucha emotivity v detskom veku"},
{"code":"F93.0","desc":"Úzkosť z odlúčenia v detskom veku"},
{"code":"F93.1","desc":"Fóbicko-úzkostná porucha v detskom veku"},
{"code":"F93.2","desc":"Sociálna úzkostná porucha v detskom veku"},
{"code":"F93.3","desc":"Porucha emotivity v dôsledku súrodeneckej rivality"},
{"code":"F93.8","desc":"Iná porucha emotivity v detskom veku"},
{"code":"F93.9","desc":"Porucha emotivity v detskom veku, bližšie neurčená"},
{"code":"F94.-","desc":"Porucha sociálneho fungovania so začiatkom v detstve a počas dospievania"},
{"code":"F94.0","desc":"Elektívny mutizmus"},
{"code":"F94.1","desc":"Reaktívna porucha pripútania v detstve [Reactive attachment disorder]"},
{"code":"F94.2","desc":"Dezinhibovaná porucha pripútania u detí [Disinhibited attachment disorder]"},
{"code":"F94.8","desc":"Iná porucha sociálneho fungovania v detstve"},
{"code":"F94.9","desc":"Porucha sociálneho fungovania v detstve, bližšie neurčená"},
{"code":"F95.-","desc":"Tiková porucha"},
{"code":"F95.0","desc":"Prechodná tiková porucha"},
{"code":"F95.1","desc":"Chronická pohybová alebo hlasová tiková porucha"},
{"code":"F95.2","desc":"Zmiešaná hlasová a rôznorodá pohybová tiková porucha [Syndróm de la Tourette]"},
{"code":"F95.8","desc":"Iná tiková porucha"},
{"code":"F95.9","desc":"Tiková porucha, bližšie neurčená"},
{"code":"F98.-","desc":"Iná porucha správania a emotivity so zvyčajným začiatkom v detstve a počas dospievania"},
{"code":"F98.0","desc":"Enuréza neorganického pôvodu"},
{"code":"F98.1","desc":"Enkopréza neorganického pôvodu"},
{"code":"F98.2","desc":"Porucha príjmu potravy v ranom detstve"},
{"code":"F98.3","desc":"Pica v ranom detstve"},
{"code":"F98.4","desc":"Stereotypné pohyby"},
{"code":"F98.5","desc":"Zajakavosť"},
{"code":"F98.6","desc":"Brblavosť [tumultus sermonis, ponáhľavá reč]"},
{"code":"F98.8","desc":"Iná bližšie určené poruchy správania a emotivity so zvyčajným začiatkom v detstve a počas dospievania"},
{"code":"F98.9","desc":"Poruchy správania a emotivity so zvyčajným začiatkom v detstve a počas dospievania, bližšie neurčené"},
{"code":"F99-F99","desc":"Duševné poruchy, bližšie neurčené"},
{"code":"F99","desc":"Duševná porucha, bližšie neurčená"},
{"code":"G00-G99","desc":"VI. kapitola - Choroby nervovej sústavy (G00-G99)"},
{"code":"G00-G09","desc":"Zápalové choroby centrálnej nervovej sústavy"},
{"code":"G00.-","desc":"Baktériová meningitída, nezatriedená inde"},
{"code":"G00.0","desc":"Meningitída zapríčinená Haemophilus influenzae"},
{"code":"G00.1","desc":"Pneumokoková meningitída"},
{"code":"G00.2","desc":"Streptokoková meningitída"},
{"code":"G00.3","desc":"Stafylokoková meningitída"},
{"code":"G00.8","desc":"Iná baktériová meningitída"},
{"code":"G00.9","desc":"Baktériová meningitída, bližšie neurčená"},
{"code":"G01*","desc":"Meningitída pri baktériových chorobách zatriedených inde"},
{"code":"G02.-*","desc":"Meningitída pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"G02.0*","desc":"Meningitída pri vírusových chorobách zatriedených inde"},
{"code":"G02.1*","desc":"Meningitída pri mykózach zatriedených inde"},
{"code":"G02.8*","desc":"Meningitída pri iných bližšie určených infekčných a parazitových chorobách zatriedených inde"},
{"code":"G03.-","desc":"Meningitída zapríčinená inými a bližšie neurčených príčinami"},
{"code":"G03.0","desc":"Nehnisová meningitída"},
{"code":"G03.1","desc":"Chronická meningitída"},
{"code":"G03.2","desc":"Benígna recidivujúca meningitída [Mollaretova]"},
{"code":"G03.8","desc":"Meningitída zapríčinená inými, bližšie určenými príčinami"},
{"code":"G03.9","desc":"Meningitída, bližšie neurčená"},
{"code":"G04.-","desc":"Encefalitída, myelitída a encefalomyelitída"},
{"code":"G04.0","desc":"Akútna diseminovaná encefalitída"},
{"code":"G04.1","desc":"Tropická spastická paraplégia"},
{"code":"G04.2","desc":"Baktériová meningoencefalitída a meningomyelitída, nezatriedená inde"},
{"code":"G04.8","desc":"Iná encefalitída, myelitída a encefalomyelitída"},
{"code":"G04.9","desc":"Encefalitída, myelitída a encefalomyelitída, bližšie neurčená"},
{"code":"G05.-*","desc":"Encefalitída, myelitída a encefalomyelitída pri chorobách zatriedených inde"},
{"code":"G05.0*","desc":"Encefalitída, myelitída a encefalomyelitída pri baktériových chorobách zatriedených inde"},
{"code":"G05.1*","desc":"Encefalitída, myelitída a encefalomyelitída pri vírusových chorobách zatriedených inde"},
{"code":"G05.2*","desc":"Encefalitída, myelitída a encefalomyelitída pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"G05.8*","desc":"Encefalitída, myelitída a encefalomyelitída pri iných chorobách zatriedených inde"},
{"code":"G06.-","desc":"Intrakraniálny a intraspinálny absces a granulóm"},
{"code":"G06.0","desc":"Intrakraniálny absces a granulóm"},
{"code":"G06.1","desc":"Intraspinálny absces a granulóm"},
{"code":"G06.2","desc":"Extradurálny a subdurálny absces, bližšie neurčený"},
{"code":"G07*","desc":"Intrakraniálny a intraspinálny absces a granulóm pri chorobách zatriedených inde"},
{"code":"G08","desc":"Intrakraniálna a intraspinálna flebitída a tromboflebitída"},
{"code":"G09","desc":"Následky zápalových chorôb centrálnej nervovej sústavy"},
{"code":"G10-G14","desc":"Systémové atrofie prvotne postihujúce centrálnu nervovú sústavu"},
{"code":"G10","desc":"Huntingtonova choroba"},
{"code":"G11.-","desc":"Dedičná ataxia"},
{"code":"G11.0","desc":"Vrodená neprogredujúca ataxia"},
{"code":"G11.1","desc":"Cerebelárna ataxia so skorým začiatkom"},
{"code":"G11.2","desc":"Cerebelárna ataxia s neskorým začiatkom"},
{"code":"G11.3","desc":"Cerebelárna ataxia s poruchou reparácie DNA"},
{"code":"G11.4","desc":"Dedičná spastická paraplégia"},
{"code":"G11.8","desc":"Iná dedičná ataxia"},
{"code":"G11.9","desc":"Dedičná ataxia, bližšie neurčená"},
{"code":"G12.-","desc":"Spinálna svalová atrofia a príbuzné syndrómy"},
{"code":"G12.0","desc":"Detská spinálna svalová atrofia, typ I [Werdnigov-Hoffmanov typ]"},
{"code":"G12.1","desc":"Iná zdedená spinálna svalová atrofia"},
{"code":"G12.2","desc":"Choroba motorického neurónu"},
{"code":"G12.8","desc":"Iná spinálna svalová atrofia a príbuzný syndróm"},
{"code":"G12.9","desc":"Spinálna svalová atrofia, bližšie neurčená"},
{"code":"G13.-*","desc":"Systémová atrofia prvotne postihujúca centrálny nervový systém pri chorobách zatriedených inde"},
{"code":"G13.0*","desc":"Paraneoplastická neuromyopatia a neuropatia"},
{"code":"G13.1*","desc":"Iná systémová atrofia postihujúca najmä centrálny nervový systém pri nádorovej chorobe"},
{"code":"G13.2*","desc":"Systémová atrofia postihujúca najmä centrálny nervový systém pri myxedéme (E00.1†, E03.-†)"},
{"code":"G13.8*","desc":"Systémová atrofia postihujúca najmä centrálny nervový systém pri iných chorobách zatriedených inde"},
{"code":"G14","desc":"Postpoliomyelitický syndróm"},
{"code":"G20-G26","desc":"Extrapyramídové poruchy a poruchy hybnosti"},
{"code":"G20.-","desc":"Parkinsonova choroba"},
{"code":"G20.0-","desc":"Parkinsonova choroba bez obmedzenia alebo s minimálnym obmedzením"},
{"code":"G20.00","desc":"Parkinsonova choroba bez obmedzenia alebo s minimálnym obmedzením, bez motorickej fluktuácie"},
{"code":"G20.01","desc":"Parkinsonova choroba bez obmedzenia alebo s minimálnym obmedzením, s motorickou fluktuáciou"},
{"code":"G20.1-","desc":"Parkinsonova choroba s miernym až stredne ťažkým obmedzením"},
{"code":"G20.10","desc":"Parkinsonova choroba s miernym až stredne ťažkým obmedzením, bez motorickej fluktuácie"},
{"code":"G20.11","desc":"Parkinsonova choroba s miernym až stredne ťažkým obmedzením, s motorickou fluktuáciou"},
{"code":"G20.2-","desc":"Parkinsonova choroba s veľmi ťažkým obmedzením"},
{"code":"G20.20","desc":"Parkinsonova choroba s veľmi ťažkým obmedzením, bez motorickej fluktuácie"},
{"code":"G20.21","desc":"Parkinsonova choroba s veľmi ťažkým obmedzením, s motorickou fluktuáciou"},
{"code":"G20.9-","desc":"Parkinsonova choroba, bližšie neurčená"},
{"code":"G20.90","desc":"Parkinsonova choroba, bližšie neurčená, bez motorickej fluktuácie"},
{"code":"G20.91","desc":"Parkinsonova choroba, bližšie neurčená, s motorickou fluktuáciou"},
{"code":"G21.-","desc":"Sekundárny Parkinsonov syndróm"},
{"code":"G21.0","desc":"Malígny neuroleptický syndróm"},
{"code":"G21.1","desc":"Iný sekundárny Parkinsonov syndróm zapríčinený liekmi"},
{"code":"G21.2","desc":"Sekundárny Parkinsonov syndróm zapríčinený inými vonkajšími faktormi"},
{"code":"G21.3","desc":"Parkinsonov syndróm po zápale mozgu"},
{"code":"G21.4","desc":"Vaskulárny Parkinsonov syndróm"},
{"code":"G21.8","desc":"Iný sekundárny Parkinsonov syndróm"},
{"code":"G21.9","desc":"Sekundárny Parkinsonov syndróm, bližšie neurčený"},
{"code":"G22*","desc":"Parkinsonov syndróm pri chorobách zatriedených inde"},
{"code":"G23.-","desc":"Iná degeneratívna choroba bazálnych ganglií"},
{"code":"G23.0","desc":"Hallervordenova-Spatzova choroba"},
{"code":"G23.1","desc":"Progresívna supranukleárna oftalmoplégia [Steele-Richardson-Olszewski syndrom]"},
{"code":"G23.2","desc":"Striatonigrálna degenerácia"},
{"code":"G23.8","desc":"Iná degeneratívna choroba bazálnych ganglií, bližšie určená"},
{"code":"G23.9","desc":"Degeneratívna choroba bazálnych ganglií, bližšie neurčená"},
{"code":"G24.-","desc":"Dystónia"},
{"code":"G24.0","desc":"Dystónia zapríčinená liekmi"},
{"code":"G24.1","desc":"Idiopatická familiárna dystónia"},
{"code":"G24.2","desc":"Idiopatická nefamiliárna dystónia"},
{"code":"G24.3","desc":"Spastický tortikolis"},
{"code":"G24.4","desc":"Idiopatická orofaciálna dystónia"},
{"code":"G24.5","desc":"Blefarospazmus"},
{"code":"G24.8","desc":"Iná dystónia"},
{"code":"G24.9","desc":"Dystónia, bližšie neurčená"},
{"code":"G25.-","desc":"Iná extrapyramídová porucha a porucha hybnosti"},
{"code":"G25.0","desc":"Esenciálny tremor"},
{"code":"G25.1","desc":"Tremor zapríčinený liekom"},
{"code":"G25.2","desc":"Iná bližšie určená forma tremoru"},
{"code":"G25.3","desc":"Myoklonus"},
{"code":"G25.4","desc":"Chorea zapríčinená liekom"},
{"code":"G25.5","desc":"Iná chorea"},
{"code":"G25.6","desc":"Tiky zapríčinené liekom a iné tiky organického pôvodu"},
{"code":"G25.8-","desc":"Iná bližšie určená extrapyramídová porucha a porucha hybnosti"},
{"code":"G25.80","desc":"Periodické pohyby nôh v spánku"},
{"code":"G25.81","desc":"Syndróm nepokojných nôh"},
{"code":"G25.88","desc":"Iná extrapyramídová porucha a porucha hybnosti, bližšie určená"},
{"code":"G25.9","desc":"Extrapyramídová porucha a porucha hybnosti, bližšie neurčená"},
{"code":"G26*","desc":"Extrapyramídové poruchy a poruchy hybnosti pri chorobách zatriedených inde"},
{"code":"G30-G32","desc":"Iné degeneratívne choroby nervovej sústavy"},
{"code":"G30.-†","desc":"Alzheimerova choroba (F00.-*)"},
{"code":"G30.0†","desc":"Alzheimerova choroba so skorým začiatkom (F00.0*)"},
{"code":"G30.1†","desc":"Alzheimerova choroba s neskorým začiatkom (F00.1*)"},
{"code":"G30.8†","desc":"Iná Alzheimerova choroba (F00.2*)"},
{"code":"G30.9†","desc":"Alzheimerova choroba, bližšie neurčená"},
{"code":"G31.-","desc":"Iná degeneratívna choroba nervovej sústavy, nezatriedená inde"},
{"code":"G31.0","desc":"Ohraničená atrofia mozgu"},
{"code":"G31.1","desc":"Starecká degenerácia mozgu, nezatriedená inde"},
{"code":"G31.2","desc":"Degenerácia nervovej sústavy zapríčinená alkoholom"},
{"code":"G31.8-","desc":"Iná bližšie určená degeneratívna choroba nervovej sústavy"},
{"code":"G31.81","desc":"Mitochondriová cytopatia"},
{"code":"G31.82","desc":"Demencia s Lewyho telieskami"},
{"code":"G31.88","desc":"Iná degeneratívna choroba nervovej sústavy, bližšie určená"},
{"code":"G31.9","desc":"Degeneratívna choroba nervovej sústavy, bližšie neurčená"},
{"code":"G32.-*","desc":"Iná degeneratívna choroba nervovej sústavy pri chorobách zatriedených inde"},
{"code":"G32.0*","desc":"Subakútna kombinovaná degenerácia miechy pri chorobách zatriedených inde"},
{"code":"G32.8*","desc":"Iná bližšie určená degeneratívna choroba nervovej sústavy pri chorobách zatriedených inde"},
{"code":"G35-G37","desc":"Demyelinizačné choroby centrálnej nervovej sústavy"},
{"code":"G35.-","desc":"Roztrúsená skleróza [Sclerosis multiplex, Encephalomyelitis disseminata]"},
{"code":"G35.0","desc":"Roztrúsená skleróza, prvá manifestácia"},
{"code":"G35.1-","desc":"Roztrúsená skleróza s relaps-remitujúcim priebehom"},
{"code":"G35.10","desc":"Roztrúsená skleróza s relaps-remitujúcim priebehom, bez prejavov relapsu alebo progresie"},
{"code":"G35.11","desc":"Roztrúsená skleróza s relaps-remitujúcim priebehom, s prejavmi relapsu alebo progresie"},
{"code":"G35.2-","desc":"Roztrúsená skleróza s primárne progresívnym priebehom"},
{"code":"G35.20","desc":"Roztrúsená skleróza s primárne progresívnym priebehom, bez prejavov relapsu alebo progresie"},
{"code":"G35.21","desc":"Roztrúsená skleróza s primárne progresívnym priebehom, s prejavmi relapsu alebo progresie"},
{"code":"G35.3-","desc":"Roztrúsená skleróza so sekundárne progresívnym priebehom"},
{"code":"G35.30","desc":"Roztrúsená skleróza so sekundárne progresívnym priebehom, bez prejavov relapsu alebo progresie"},
{"code":"G35.31","desc":"Roztrúsená skleróza so sekundárne progresívnym priebehom, s prejavmi relapsu alebo progresie"},
{"code":"G35.9","desc":"Roztrúsená skleróza, bližšie neurčená"},
{"code":"G36.-","desc":"Iná akútna roztrúsená [diseminovaná] demyelinizácia"},
{"code":"G36.0","desc":"Neuromyelitis optica [Devicova choroba]"},
{"code":"G36.1","desc":"Akútna a subakútna hemoragická leukoencefalitída [Hurstova choroba]"},
{"code":"G36.8","desc":"Iná akútna roztrúsená demyelinizácia, bližšie určená"},
{"code":"G36.9","desc":"Akútna roztrúsená demyelinizácia, bližšie neurčená"},
{"code":"G37.-","desc":"Iná demyelinizačná choroba centrálnej nervovej sústavy"},
{"code":"G37.0","desc":"Difúzna skleróza centrálnej nervovej sústavy [Schilderova choroba]"},
{"code":"G37.1","desc":"Centrálna demyelinizácia corpus callosum"},
{"code":"G37.2","desc":"Centrálna pontínna myelinolýza"},
{"code":"G37.3","desc":"Akútna priečna myelitída pri demyelinizačnej chorobe centrálnej nervovej sústavy"},
{"code":"G37.4","desc":"Subakútna nekrotizujúca myelitída"},
{"code":"G37.5","desc":"Koncentrická skleróza centrálnej nervovej sústavy [Balóova choroba]"},
{"code":"G37.8","desc":"Iná demyelinizačná choroba centrálnej nervovej sústavy, bližšie určená"},
{"code":"G37.9","desc":"Demyelinizačná choroba centrálnej nervovej sústavy, bližšie neurčená"},
{"code":"G40-G47","desc":"Epizodické a záchvatové poruchy"},
{"code":"G40.-","desc":"Epilepsia"},
{"code":"G40.0-","desc":"Lokalizovaná (fokálna) (parciálna) idiopatická epilepsia a epileptický syndróm s fokálne začínajúcimi záchvatmi"},
{"code":"G40.00","desc":"Pseudo-Lennoxov syndróm"},
{"code":"G40.01","desc":"Syndróm so sústavnými hrotmi a vlnami počas pomalého spánku [CSWS syndróm]"},
{"code":"G40.02","desc":"Benígna psychomotorická epilepsia"},
{"code":"G40.08","desc":"Iná idiopatická (fokálna) (parciálna) epilepsia a epileptický syndróm s fokálne začínajúcimi záchvatmi"},
{"code":"G40.09","desc":"Lokalizovaná (fokálna) (parciálna) idiopatická epilepsia a epileptický syndróm s fokálne začínajúcimi záchvatmi, bližšie neurčená"},
{"code":"G40.1","desc":"Lokalizovaná (fokálna) (parciálna) symptomatická epilepsia a epileptický syndróm s jednotlivými fokálnymi záchvatmi"},
{"code":"G40.2","desc":"Lokalizovaná (fokálna, parciálna) symptomatická epilepsia a epileptický syndróm s komplexnými fokálnymi záchvatmi"},
{"code":"G40.3","desc":"Generalizovaná idiopatická epilepsia a epileptický syndróm"},
{"code":"G40.4","desc":"Iná generalizovaná epilepsia a epileptický syndróm"},
{"code":"G40.5","desc":"Osobitný epileptický syndróm"},
{"code":"G40.6","desc":"Záchvaty grand mal, bližšie neurčené, so záchvatmi, alebo bez záchvatov petit mal"},
{"code":"G40.7","desc":"Záchvaty petit, bližšie neurčené, bez záchvatov grand mal"},
{"code":"G40.8","desc":"Iná epilepsia"},
{"code":"G40.9","desc":"Epilepsia, bližšie neurčená"},
{"code":"G41.-","desc":"Epileptický stav [Status epilepticus]"},
{"code":"G41.0","desc":"Status epilepticus grand mal"},
{"code":"G41.1","desc":"Status epilepticus petit mal"},
{"code":"G41.2","desc":"Status epilepticus s komplexnými fokálnymi záchvatmi"},
{"code":"G41.8","desc":"Iný status epilepticus"},
{"code":"G41.9","desc":"Status epilepticus, bližšie neurčený"},
{"code":"G43.-","desc":"Migréna"},
{"code":"G43.0","desc":"Migréna bez aury [bežná migréna]"},
{"code":"G43.1","desc":"Migréna s aurou [klasická migréna]"},
{"code":"G43.2","desc":"Status migraenosus [migrénový stav]"},
{"code":"G43.3","desc":"Komplikovaná migréna"},
{"code":"G43.8","desc":"Iná migréna"},
{"code":"G43.9","desc":"Migréna, bližšie neurčená"},
{"code":"G44.-","desc":"Iný syndróm bolesti hlavy"},
{"code":"G44.0","desc":"Syndróm klastrovej bolesti hlavy [Cluster headache syndrome]"},
{"code":"G44.1","desc":"Bolesť hlavy cievneho pôvodu, nezatriedená inde"},
{"code":"G44.2","desc":"Tenzná bolesť hlavy"},
{"code":"G44.3","desc":"Chronická poúrazová bolesť hlavy"},
{"code":"G44.4","desc":"Bolesť hlavy zapríčinená liekmi, nezatriedená inde"},
{"code":"G44.8","desc":"Iný syndróm bolesti hlavy, bližšie určený"},
{"code":"G45.-","desc":"Prechodná mozgová ischémia a príbuzné syndrómy"},
{"code":"G45.0-","desc":"Vertebrobazilárny syndróm [syndróm vertebrobazilárnej artérie, ]"},
{"code":"G45.02","desc":"Vertebrobazilárny syndróm, s kompletným ústupom od 1 do 24 hodín"},
{"code":"G45.03","desc":"Vertebrobazilárny syndróm, s kompletným ústupom do 1 hodiny"},
{"code":"G45.09","desc":"Vertebrobazilárny syndróm, s bližšie neurčeným priebehom ústupu"},
{"code":"G45.1-","desc":"Syndróm krčnice [a.carotis] (hemisferický)"},
{"code":"G45.12","desc":"Syndróm krčnice (hemisferický), s kompletným ústupom od 1 do 24 hodín"},
{"code":"G45.13","desc":"Syndróm krčnice (hemisferický), s kompletným ústupom do 1 hodiny"},
{"code":"G45.19","desc":"Syndróm krčnice (hemisferický), s bližšie neurčeným priebehom ústupu"},
{"code":"G45.2-","desc":"Syndrómy prívodných mozgových tepien, viacpočetné a obojstranné"},
{"code":"G45.22","desc":"Syndrómy prívodných mozgových tepien, viacpočetné a obojstranné, s kompletným ústupom od 1 do 24 hodín"},
{"code":"G45.23","desc":"Syndrómy prívodných mozgových tepien, viacpočetné a obojstranné, s kompletným ústupom do 1 hodiny"},
{"code":"G45.29","desc":"Syndrómy prívodných mozgových tepien, viacpočetné a obojstranné, s bližšie neurčeným priebehom ústupu"},
{"code":"G45.3-","desc":"Amaurosis fugax"},
{"code":"G45.32","desc":"Amaurosis fugax, s kompletným ústupom od 1 do 24 hodín"},
{"code":"G45.33","desc":"Amaurosis fugax, s kompletným ústupom do 1 hodiny"},
{"code":"G45.39","desc":"Amaurosis fugax s bližšie neurčeným priebehom ústupu"},
{"code":"G45.4-","desc":"Prechodná celková amnézia [amnestická epizóda]"},
{"code":"G45.42","desc":"Prechodná celková amnézia s kompletným ústupom od 1 do 24 hodín"},
{"code":"G45.43","desc":"Prechodná celková amnézia s kompletným ústupom do 1 hodiny"},
{"code":"G45.49","desc":"Prechodná celková amnézia s bližšie neurčeným priebehom ústupu"},
{"code":"G45.8-","desc":"Iná prechodné mozgové ischémie a príbuzné syndrómy"},
{"code":"G45.82","desc":"Iná prechodné mozgové ischémie a príbuzný syndróm, s kompletným ústupom od 1 do 24 hodín"},
{"code":"G45.83","desc":"Iná prechodné mozgové ischémie a a príbuzný syndróm, s kompletným ústupom do 1 hodiny"},
{"code":"G45.89","desc":"Iné prechodné mozgové ischémie a príbuzné syndrómy s bližšie neurčeným priebehom ústupu"},
{"code":"G45.9-","desc":"Prechodná mozgová ischémia, bližšie neurčená"},
{"code":"G45.92","desc":"Prechodná mozgová ischémia, bližšie neurčená, s kompletným ústupom od 1 do 24 hodín"},
{"code":"G45.93","desc":"Prechodná mozgová ischémia, bližšie neurčená, s kompletným ústupom do 1 hodiny"},
{"code":"G45.99","desc":"Prechodná mozgová ischémia, bližšie neurčená, s bližšie neurčeným priebehom ústupu"},
{"code":"G46.-*","desc":"Cievne mozgové syndrómy pri mozgových cievnych chorobách (I60-I67†)"},
{"code":"G46.0*","desc":"Syndróm strednej mozgovej tepny [a.cerebri media] (I66.0†)"},
{"code":"G46.1*","desc":"Syndróm prednej mozgovej tepny [a.cerebri anterior] (I66.1†)"},
{"code":"G46.2*","desc":"Syndróm zadnej mozgovej tepny [a.cerebri posterior] (I66.2†)"},
{"code":"G46.3*","desc":"Syndróm cievneho postihnutia mozgového kmeňa (I60-I67†)"},
{"code":"G46.4*","desc":"Syndróm cievneho postihnutia mozočka (I60-I67†)"},
{"code":"G46.5*","desc":"Čistý motorický lakunárny syndróm (I60-I67†)"},
{"code":"G46.6*","desc":"Čistý senzorický lakunárny syndróm (I60-I67†)"},
{"code":"G46.7*","desc":"Iný lakunárny syndróm (I60-I67†)"},
{"code":"G46.8*","desc":"Iný cievny mozgový syndróm pri cievnych mozgových chorobách (I60-I67†)"},
{"code":"G47.-","desc":"Poruchy spánku"},
{"code":"G47.0","desc":"Porucha zaspávania a trvania spánku [insomnia]"},
{"code":"G47.1","desc":"Nadmerná spavosť [hypersomnia]"},
{"code":"G47.2","desc":"Poruchy spánkového cyklu"},
{"code":"G47.3-","desc":"Spánkové apnoe"},
{"code":"G47.30","desc":"Centrálne spánkové apnoe"},
{"code":"G47.31","desc":"Obštrukčné spánkové apnoe"},
{"code":"G47.32","desc":"Hypoventilačný syndróm súvisiaci so spánkom"},
{"code":"G47.33","desc":"Obštrukčné spánkové apnoe detí"},
{"code":"G47.38","desc":"Iné spánkové apnoe"},
{"code":"G47.39","desc":"Spánkové apnoe, bližšie neurčené"},
{"code":"G47.4","desc":"Narkolepsia a kataplexia"},
{"code":"G47.8","desc":"Iná porucha spánku"},
{"code":"G47.9","desc":"Porucha spánku, bližšie neurčená"},
{"code":"G50-G59","desc":"Poruchy nervov, nervových koreňov a nervových spletí"},
{"code":"G50.-","desc":"Porucha trojklaného nervu [n. trigeminus]"},
{"code":"G50.0","desc":"Neuralgia trojklaného nervu"},
{"code":"G50.1","desc":"Atypická bolesť tváre"},
{"code":"G50.8","desc":"Iná porucha trojklaného nervu"},
{"code":"G50.9","desc":"Porucha trojklaného nervu, bližšie neurčená"},
{"code":"G51.-","desc":"Porucha tvárového tvárového nervu [n.facialis]"},
{"code":"G51.0","desc":"Paréza tvárového nervu [Bellova obrna]"},
{"code":"G51.1","desc":"Ganglionitis geniculata"},
{"code":"G51.2","desc":"Melkerssonov-Rosenthalov syndróm"},
{"code":"G51.3","desc":"Klonický spazmus polovice tváre"},
{"code":"G51.4","desc":"Tvárová myokýmia"},
{"code":"G51.8","desc":"Iná porucha tvárového nervu"},
{"code":"G51.9","desc":"Porucha tvárového nervu, bližšie neurčená"},
{"code":"G52.-","desc":"Poruchy ostatných hlavových nervov"},
{"code":"G52.0","desc":"Porucha čuchového nervu [n. olfactorius]"},
{"code":"G52.1","desc":"Porucha jazykovohltanového nervu [n. glossopharyngeus]"},
{"code":"G52.2","desc":"Porucha blúdivého nervu [n. vagus]"},
{"code":"G52.3","desc":"Porucha podjazykového nervu [n. hypoglossus]"},
{"code":"G52.7","desc":"Porucha viacerých hlavových nervov"},
{"code":"G52.8","desc":"Poruchy iných bližšie určených hlavových nervov"},
{"code":"G52.9","desc":"Porucha hlavového nervu, bližšie neurčená"},
{"code":"G53.-*","desc":"Poruchy hlavových nervov pri chorobách zatriedených inde"},
{"code":"G53.0*","desc":"Neuralgia po herpes zoster (B02.2†)"},
{"code":"G53.1*","desc":"Obrna viacerých hlavových nervov pri infekčných a parazitových chorobách zatriedených inde (A00-B99†)"},
{"code":"G53.2*","desc":"Obrna viacerých hlavových nervov pri sarkoidóze (D86.8†)"},
{"code":"G53.3*","desc":"Obrna viacerých hlavových nervov pri nádorovej chorobe (C00-D48†)"},
{"code":"G53.8*","desc":"Iná choroba hlavového nervu pri iných chorobách zatriedených inde"},
{"code":"G54.-","desc":"Choroby nervových koreňov a spletí"},
{"code":"G54.0","desc":"Poškodenie plexus brachialis"},
{"code":"G54.1","desc":"Poškodenie plexus lumbosacralis"},
{"code":"G54.2","desc":"Poškodenie krčných nervových koreňov, nezatriedené inde"},
{"code":"G54.3","desc":"Poškodenie hrudných nervových koreňov, nezatriedené inde"},
{"code":"G54.4","desc":"Poškodenie bedrových a krížových nervových koreňov, nezatriedené inde"},
{"code":"G54.5","desc":"Neuralgická amyotrofia"},
{"code":"G54.6","desc":"Syndróm fantómovej končatiny s bolesťou"},
{"code":"G54.7","desc":"Syndróm fantómovej končatiny bez bolesti"},
{"code":"G54.8","desc":"Iná choroba nervových koreňov a spletí"},
{"code":"G54.9","desc":"Choroba nervového koreňa a nervovej spleti, bližšie neurčená"},
{"code":"G55.-*","desc":"Kompresia nervových koreňov a spletí pri chorobách zatriedených inde"},
{"code":"G55.0*","desc":"Kompresia nervových koreňov a spletí pri nádorovej chorobe (C00-D48†)"},
{"code":"G55.1*","desc":"Kompresia nervových koreňov a spletí pri poškodení medzistavcových platničiek (M50-M51†)"},
{"code":"G55.2*","desc":"Kompresia nervových koreňov a spletí pri spondylóze (M47.-†)"},
{"code":"G55.3*","desc":"Kompresia nervových koreňov a spletí pri iných dorzopatiách (M45-M46†, M48.-†, M53-M54†)"},
{"code":"G55.8*","desc":"Kompresia nervových koreňov a spletí pri iných chorobách zatriedených inde"},
{"code":"G56.-","desc":"Mononeuropatia hornej končatiny"},
{"code":"G56.0","desc":"Syndróm karpálneho tunela"},
{"code":"G56.1","desc":"Iné poškodenie n. medianus"},
{"code":"G56.2","desc":"Poškodenie n. ulnaris"},
{"code":"G56.3","desc":"Poškodenie n. radialis"},
{"code":"G56.4","desc":"Kauzalgia"},
{"code":"G56.8","desc":"Iná mononeuropatia hornej končatiny"},
{"code":"G56.9","desc":"Mononeuropatia hornej končatiny, bližšie neurčená"},
{"code":"G57.-","desc":"Mononeuropatia dolnej končatiny"},
{"code":"G57.0","desc":"Poškodenie n. ischadicus"},
{"code":"G57.1","desc":"Meralgia paraesthetica"},
{"code":"G57.2","desc":"Poškodenie n. femoralis"},
{"code":"G57.3","desc":"Poškodenie n. fibularis communis"},
{"code":"G57.4","desc":"Poškodenie n. tibialis"},
{"code":"G57.5","desc":"Syndróm tarzálneho tunela"},
{"code":"G57.6","desc":"Poškodenie n. plantaris"},
{"code":"G57.8","desc":"Iná mononeuropatia dolnej končatiny"},
{"code":"G57.9","desc":"Mononeuropatia dolnej končatiny, bližšie neurčená"},
{"code":"G58.-","desc":"Iná mononeuropatia"},
{"code":"G58.0","desc":"Interkostálna neuropatia"},
{"code":"G58.7","desc":"Mononeuritis multiplex"},
{"code":"G58.8","desc":"Iná bližšie určená mononeuropatia"},
{"code":"G58.9","desc":"Mononeuropatia, bližšie neurčená"},
{"code":"G59.-*","desc":"Mononeuropatia pri chorobách zatriedených inde"},
{"code":"G59.0*","desc":"Diabetická mononeuropatia (E10-E14, štvrtá pozícia .4†)"},
{"code":"G59.8*","desc":"Iná mononeuropatia pri chorobách zatriedených inde"},
{"code":"G60-G64","desc":"Polyneuropatie a iné poruchy periférnej nervovej sústavy"},
{"code":"G60.-","desc":"Dedičná a idiopatická neuropatia"},
{"code":"G60.0","desc":"Dedičná senzomotorická neuropatia"},
{"code":"G60.1","desc":"Refsumova choroba"},
{"code":"G60.2","desc":"Neuropatia spojená s dedičnou ataxiou"},
{"code":"G60.3","desc":"Idiopatická progresívna neuropatia"},
{"code":"G60.8","desc":"Iná dedičná a idiopatická neuropatia"},
{"code":"G60.9","desc":"Dedičná a idiopatická neuropatia, bližšie neurčená"},
{"code":"G61.-","desc":"Zápalová polyneuropatia"},
{"code":"G61.0","desc":"Guillainov-Barrého syndróm"},
{"code":"G61.1","desc":"Sérová polyneuropatia"},
{"code":"G61.8","desc":"Iná zápalová polyneuropatia"},
{"code":"G61.9","desc":"Zápalová polyneuropatia, bližšie neurčená"},
{"code":"G62.-","desc":"Iná polyneuropatia"},
{"code":"G62.0","desc":"Polyneuropatia zapríčinená liekom"},
{"code":"G62.1","desc":"Alkoholová polyneuropatia"},
{"code":"G62.2","desc":"Polyneuropatia zapríčinená inými toxickými látkami"},
{"code":"G62.8-","desc":"Iná polyneuropatia, bližšie určená"},
{"code":"G62.80","desc":"Polyneuropatia kriticky chorých"},
{"code":"G62.88","desc":"Iná polyneuropatia, bližšie určená"},
{"code":"G62.9","desc":"Polyneuropatia, bližšie neurčená"},
{"code":"G63.-*","desc":"Polyneuropatia pri chorobách zatriedených inde"},
{"code":"G63.0*","desc":"Polyneuropatia pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"G63.1*","desc":"Polyneuropatia pri nádorovej chorobe(C00-D48†)"},
{"code":"G63.2*","desc":"Diabetická polyneuropatia (E10-E14, štvrtá pozícia .4†)"},
{"code":"G63.3*","desc":"Polyneuropatia pri iných endokrinných a metabolických chorobách (E00-E07†, E15-E16†, E20-E34†, E70-E89†)"},
{"code":"G63.4*","desc":"Polyneuropatia pri nedostatku vo výžive (E40-E64†)"},
{"code":"G63.5*","desc":"Polyneuropatia pri systémových chorobách spojiva (M30-M35†)"},
{"code":"G63.6*","desc":"Polyneuropatia pri iných svalovokostrových chorobách (M00-M25†, M40-M96†)"},
{"code":"G63.8*","desc":"Polyneuropatia pri iných chorobách zatriedených inde"},
{"code":"G64","desc":"Iná porucha periférnej nervovej sústavy"},
{"code":"G70-G73","desc":"Choroby nervovosvalovej platničky a svalov"},
{"code":"G70.-","desc":"Myasthenia gravis a iná nervovosvalová choroba"},
{"code":"G70.0","desc":"Myasthenia gravis"},
{"code":"G70.1","desc":"Toxická nervovosvalová choroba"},
{"code":"G70.2","desc":"Vrodená a vývinová myasténia"},
{"code":"G70.8","desc":"Iná myoneurálna porucha, bližšie určená"},
{"code":"G70.9","desc":"Myoneurálna porucha, bližšie neurčená"},
{"code":"G71.-","desc":"Primárna myopatia"},
{"code":"G71.0","desc":"Svalová dystrofia"},
{"code":"G71.1","desc":"Myotonická porucha"},
{"code":"G71.2","desc":"Vrodená myopatia"},
{"code":"G71.3","desc":"Mitochondriová myopatia, nezatriedená inde"},
{"code":"G71.8","desc":"Iná primárna choroba svalov"},
{"code":"G71.9","desc":"Primárna choroba svalov, bližšie neurčená"},
{"code":"G72.-","desc":"Iná myopatia"},
{"code":"G72.0","desc":"Myopatia zapríčinená liekmi"},
{"code":"G72.1","desc":"Alkoholová myopatia"},
{"code":"G72.2","desc":"Myopatia zapríčinená inými toxickými látkami"},
{"code":"G72.3","desc":"Periodická paralýza"},
{"code":"G72.4","desc":"Zápalová myopatia, nezatriedená inde"},
{"code":"G72.8-","desc":"Iná myopatie, bližšie určené"},
{"code":"G72.80","desc":"Myopatia kriticky chorých"},
{"code":"G72.88","desc":"Iná myopatia, bližšie určená"},
{"code":"G72.9","desc":"Myopatia, bližšie neurčená"},
{"code":"G73.-*","desc":"Choroba s poruchou nervovosvalovej platničky a svalu pri chorobách zatriedených inde"},
{"code":"G73.0*","desc":"Myastenický syndróm pri endokrinných chorobách"},
{"code":"G73.1*","desc":"Eatonov-Lambertov myastenický syndróm (C00-D48†)"},
{"code":"G73.2*","desc":"Iný myastenický syndróm pri nádorovej chorobe (C00-D48†)"},
{"code":"G73.3*","desc":"Myastenický syndróm pri iných chorobách zatriedených inde"},
{"code":"G73.4*","desc":"Myopatia pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"G73.5*","desc":"Myopatia pri endokrinných chorobách"},
{"code":"G73.6*","desc":"Myopatia pri metabolických chorobách"},
{"code":"G73.7*","desc":"Myopatia pri iných chorobách zatriedených inde"},
{"code":"G80-G83","desc":"Detská mozgová obrna a iné paralytické syndrómy"},
{"code":"G80.-","desc":"Detská mozgová obrna"},
{"code":"G80.0","desc":"Spastická tetraplegická detská mozgová obrna"},
{"code":"G80.1","desc":"Spastická diplegická detská mozgová obrna"},
{"code":"G80.2","desc":"Spastická hemiplegická detská mozgová obrna"},
{"code":"G80.3","desc":"Dyskinetická detská mozgová obrna"},
{"code":"G80.4","desc":"Ataktická detská mozgová obrna"},
{"code":"G80.8","desc":"Iná detská mozgová obrna"},
{"code":"G80.9","desc":"Detská mozgová obrna, bližšie neurčená"},
{"code":"G81.-","desc":"Hemiparéza a hemiplégia"},
{"code":"G81.0","desc":"Chabá hemiparéza a hemiplégia"},
{"code":"G81.1","desc":"Spastická hemiparéza a hemiplégia"},
{"code":"G81.9","desc":"Hemiparéza a hemiplégia, bližšie neurčená"},
{"code":"G82.-","desc":"Paraparéza a paraplégia, tetraparéza a tetraplégia"},
{"code":"G82.0-","desc":"Chabá paraparéza a paraplégia"},
{"code":"G82.00","desc":"Chabá paraparéza a paraplégia, akútne kompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.01","desc":"Chabá paraparéza a paraplégia, akútne nekompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.02","desc":"Chabá paraparéza a paraplégia, chronické kompletné priečne poškodenie miechy"},
{"code":"G82.03","desc":"Chabá paraparéza a paraplégia, chronické nekompletné priečne poškodenie miechy"},
{"code":"G82.09","desc":"Chabá paraparéza a paraplégia, bližšie neurčená"},
{"code":"G82.1-","desc":"Spastická paraparéza a paraplégia"},
{"code":"G82.10","desc":"Spastická paraparéza a paraplégia, akútne kompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.11","desc":"Spastická paraparéza a paraplégia, akútne nekompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.12","desc":"Spastická paraparéza a paraplégia, chronické kompletné priečne poškodenie miechy"},
{"code":"G82.13","desc":"Spastická paraparéza a paraplégia, chronické nekompletné priečne poškodenie miechy"},
{"code":"G82.19","desc":"Spastická paraparéza a paraplégia, bližšie neurčená"},
{"code":"G82.2-","desc":"Paraparéza a paraplégia, bližšie neurčená"},
{"code":"G82.20","desc":"Paraparéza a paraplégia, bližšie neurčená, akútne kompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.21","desc":"Paraparéza a paraplégia, bližšie neurčená, akútne nekompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.22","desc":"Paraparéza a paraplégia, bližšie neurčená, chronické kompletné priečne poškodenie miechy"},
{"code":"G82.23","desc":"Paraparéza a paraplégia, bližšie neurčená, chronické nekompletné priečne poškodenie miechy"},
{"code":"G82.29","desc":"Paraparéza a paraplégia, bližšie neurčená"},
{"code":"G82.3-","desc":"Chabá tetraparéza a tetraplégia"},
{"code":"G82.30","desc":"Chabá tetraparéza a tetraplégia, akútne kompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.31","desc":"Chabá tetraparéza a tetraplégia, akútne nekompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.32","desc":"Chabá tetraparéza a tetraplégia, chronické kompletné priečne poškodenie miechy"},
{"code":"G82.33","desc":"Chabá tetraparéza a tetraplégia, chronické nekompletné priečne poškodenie miechy"},
{"code":"G82.39","desc":"Chabá tetraparéza a tetraplégia, bližšie neurčená"},
{"code":"G82.4-","desc":"Spastická tetraparéza a tetraplégia"},
{"code":"G82.40","desc":"Spastická tetraparéza a tetraplégia, akútne kompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.41","desc":"Spastická tetraparéza a tetraplégia, akútne nekompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.42","desc":"Spastická tetraparéza a tetraplégia, chronické kompletné priečne poškodenie miechy"},
{"code":"G82.43","desc":"Spastická tetraparéza a tetraplégia, chronické nekompletné priečne poškodenie miechy"},
{"code":"G82.49","desc":"Spastická tetraparéza a tetraplégia, bližšie neurčená"},
{"code":"G82.5-","desc":"Tetraparéza a tetraplégia, bližšie neurčená"},
{"code":"G82.50","desc":"Tetraparéza a tetraplégia, bližšie neurčená, akútne kompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.51","desc":"Tetraparéza a tetraplégia, bližšie neurčená, akútne nekompletné priečne poškodenie miechy neúrazového pôvodu"},
{"code":"G82.52","desc":"Tetraparéza a tetraplégia, bližšie neurčená, chronické kompletné priečne poškodenie miechy"},
{"code":"G82.53","desc":"Tetraparéza a tetraplégia, bližšie neurčená, chronické nekompletné priečne poškodenie miechy"},
{"code":"G82.59","desc":"Tetraparéza a tetraplégia, bližšie neurčená"},
{"code":"G82.6-!","desc":"Funkčná úroveň pri poškodení miechy"},
{"code":"G82.60!","desc":"Funkčná úroveň pri poškodení miechy: C1 – C3"},
{"code":"G82.61!","desc":"Funkčná úroveň pri poškodení miechy: C4 – C5"},
{"code":"G82.62!","desc":"Funkčná úroveň pri poškodení miechy: C6 – C8"},
{"code":"G82.63!","desc":"Funkčná úroveň pri poškodení miechy: Th1 – Th6"},
{"code":"G82.64!","desc":"Funkčná úroveň pri poškodení miechy: Th7 – Th10"},
{"code":"G82.65!","desc":"Funkčná úroveň pri poškodení miechy: Th11 – L1"},
{"code":"G82.66!","desc":"Funkčná úroveň pri poškodení miechy: L2 – S1"},
{"code":"G82.67!","desc":"Funkčná úroveň pri poškodení miechy: S2 – S5"},
{"code":"G82.69!","desc":"Funkčná úroveň pri poškodení miechy bližšie neurčená"},
{"code":"G83.-","desc":"Iný paralytický syndróm"},
{"code":"G83.0","desc":"Diparéza a diplégia horných končatín"},
{"code":"G83.1","desc":"Monoparéza a monoplégia dolnej končatiny"},
{"code":"G83.2","desc":"Monoparéza a monoplégia hornej končatiny"},
{"code":"G83.3","desc":"Monoparéza a monoplégia, bližšie neurčená"},
{"code":"G83.4-","desc":"Syndróm cauda equina"},
{"code":"G83.40","desc":"Syndróm cauda equina, kompletný"},
{"code":"G83.41","desc":"Syndróm cauda equina, nekomplený"},
{"code":"G83.49","desc":"Syndróm cauda equina, bližšie neurčený"},
{"code":"G83.8-","desc":"Iný paralytický syndróm, bližšie určený"},
{"code":"G83.80","desc":"Locked-in syndróm"},
{"code":"G83.88","desc":"Iný paralytický syndróm, bližšie určený"},
{"code":"G83.9","desc":"Paralytický syndróm, bližšie neurčený"},
{"code":"G90-G99","desc":"Iné choroby nervovej sústavy"},
{"code":"G90.-","desc":"Porucha autonómnej nervovej sústavy"},
{"code":"G90.0-","desc":"Idiopatická periférna autonómna neuropatia"},
{"code":"G90.00","desc":"Syndróm karotického sínusu (Synkopa)"},
{"code":"G90.08","desc":"Iná idiopatická periférna autonómna neuropatia"},
{"code":"G90.09","desc":"Idiopatická periférna autonómna neuropatia, bližšie neurčená"},
{"code":"G90.1","desc":"Familiárna dysautonómia [Rileyho-Dayov syndróm]"},
{"code":"G90.2","desc":"Hornerov syndróm"},
{"code":"G90.3","desc":"Multisystémová degenerácia"},
{"code":"G90.4-","desc":"Autonómna dysreflexia"},
{"code":"G90.40","desc":"Autonómna dysreflexia ako hypertonická kríza"},
{"code":"G90.41","desc":"Autonómna dysreflexia ako záchvaty potenia"},
{"code":"G90.48","desc":"Iná autonómna dysreflexia"},
{"code":"G90.49","desc":"Autonómna dysreflexia, bližšie neurčená"},
{"code":"G90.8","desc":"Iná choroba autonómnej nervovej sústavy"},
{"code":"G90.9","desc":"Porucha autonómnej nervovej sústavy, bližšie neurčená"},
{"code":"G91.-","desc":"Hydrocefalus"},
{"code":"G91.0","desc":"Komunikujúci hydrocefalus"},
{"code":"G91.1","desc":"Obštrukčný hydrocefalus"},
{"code":"G91.2-","desc":"Normotenzný hydrocefalus"},
{"code":"G91.20","desc":"Idiopatický normotenzný hydrocefalus"},
{"code":"G91.21","desc":"Sekundárny normotenzný hydrocefalus"},
{"code":"G91.29","desc":"Normotenzný hydrocefalus, bližšie neurčený"},
{"code":"G91.3","desc":"Poúrazový hydrocefalus, bližšie neurčený"},
{"code":"G91.8","desc":"Iný hydrocefalus"},
{"code":"G91.9","desc":"Hydrocefalus, bližšie neurčený"},
{"code":"G92","desc":"Toxická encefalopatia"},
{"code":"G93.-","desc":"Iná choroba mozgu"},
{"code":"G93.0","desc":"Mozgová cysta"},
{"code":"G93.1","desc":"Anoxické poškodenie mozgu, nezatriedené inde"},
{"code":"G93.2","desc":"Benígna intrakraniálna hypertenzia"},
{"code":"G93.3","desc":"Chronický únavový syndróm"},
{"code":"G93.4","desc":"Encefalopatia, bližšie neurčená"},
{"code":"G93.5","desc":"Kompresia mozgu"},
{"code":"G93.6","desc":"Opuch mozgu"},
{"code":"G93.7","desc":"Reyeov syndróm"},
{"code":"G93.8-","desc":"Iná choroba mozgu, bližšie určená"},
{"code":"G93.80","desc":"Apalický syndróm"},
{"code":"G93.88","desc":"Iná choroba mozgu, bližšie určená"},
{"code":"G93.9","desc":"Choroba mozgu, bližšie neurčená"},
{"code":"G94.-*","desc":"Iná choroba mozgu pri chorobách zatriedených inde"},
{"code":"G94.0*","desc":"Hydrocefalus pri infekčných a parazitových chorobách zatriedených inde (A00-B99†)"},
{"code":"G94.1*","desc":"Hydrocefalus pri nádorovej chorobe (C00-D48†)"},
{"code":"G94.2*","desc":"Hydrocefalus pri iných chorobách zatriedených inde"},
{"code":"G94.8*","desc":"Iná choroba mozgu pri chorobách zatriedených inde, bližšie určená"},
{"code":"G95.-","desc":"Iná choroba miechy"},
{"code":"G95.0","desc":"Syringomyelia a syringobulbia"},
{"code":"G95.1","desc":"Cievna myelopatia"},
{"code":"G95.2","desc":"Kompresia miechy, bližšie neurčená"},
{"code":"G95.8-","desc":"Iná choroby miechy, bližšie určené"},
{"code":"G95.80","desc":"Obrna močového mechúra pri poškodení horného motoneurónu [UMNL]"},
{"code":"G95.81","desc":"Obrna močového mechúra pri poškodení dolného motoneurónu [LMNL]"},
{"code":"G95.82","desc":"Funkčná porucha močového mechúra zapríčinená spinálnym šokom"},
{"code":"G95.83","desc":"Spinálna spasticita priečne pruhovaného svalstva"},
{"code":"G95.84","desc":"Detrúzorovo-sfinkterová dyssynergia pri poškodení miechy"},
{"code":"G95.85","desc":"Deaferentačná bolesť pri poškodení miechy"},
{"code":"G95.88","desc":"Iná choroba miechy, bližšie určená"},
{"code":"G95.9","desc":"Choroba miechy, bližšie neurčená"},
{"code":"G96.-","desc":"Iné choroby centrálnej nervovej sústavy"},
{"code":"G96.0","desc":"Presakovanie mozgovomiechového moku"},
{"code":"G96.1","desc":"Choroba mozgových a miechových plien, nezatriedená inde"},
{"code":"G96.8","desc":"Iná choroba centrálnej nervovej sústavy, bližšie určená"},
{"code":"G96.9","desc":"Choroba centrálnej nervovej sústavy, bližšie neurčená"},
{"code":"G97.-","desc":"Choroba nervovej sústavy po lekárskom výkone, nezatriedená inde"},
{"code":"G97.0","desc":"Presakovanie mozgovomiechového moku po spinálnej punkcii"},
{"code":"G97.1","desc":"Iná reakcia na spinálnu a lumbálnu punkciu"},
{"code":"G97.2","desc":"Vnútrolebková hypotenzia po komorovom skrate"},
{"code":"G97.8-","desc":"Iná choroba nervovej sústavy po lekárskom výkone"},
{"code":"G97.80","desc":"Pooperačná likvorea"},
{"code":"G97.81","desc":"Pooperačný (cerebelárny) mutizmus"},
{"code":"G97.88","desc":"Iná choroba nervovej sústavy po lekárskom výkone"},
{"code":"G97.9","desc":"Choroba nervovej sústavy po lekárskom výkone, bližšie neurčená"},
{"code":"G98","desc":"Iná choroba nervovej sústavy, nezatriedená inde"},
{"code":"G99.-*","desc":"Iná choroby nervovej sústavy pri chorobách zatriedených inde"},
{"code":"G99.0*","desc":"Autonómna neuropatia pri endokrinných a metabolických chorobách"},
{"code":"G99.1*","desc":"Iná choroba autonómnej nervovej sústavy pri iných chorobách zatriedených inde"},
{"code":"G99.2*","desc":"Myelopatia pri chorobách zatriedených inde"},
{"code":"G99.8*","desc":"Iná choroba nervovej sústavy pri chorobách zatriedených inde, bližšie určená"},
{"code":"H00-H59","desc":"VII. kapitola - Choroby oka a očných adnexov (H00-H59)"},
{"code":"H00-H06","desc":"Choroby mihalníc, slzných orgánov a očnice"},
{"code":"H00.-","desc":"Hordeolum a chalazion"},
{"code":"H00.0","desc":"Hordeolum a iný hlboký zápal mihalnice"},
{"code":"H00.1","desc":"Chalazion"},
{"code":"H01.-","desc":"Iný zápal mihalnice"},
{"code":"H01.0","desc":"Blefaritída"},
{"code":"H01.1","desc":"Neinfekčná dermatóza mihalnice"},
{"code":"H01.8","desc":"Iný zápal mihalnice, bližšie určený"},
{"code":"H01.9","desc":"Zápal mihalnice, bližšie neurčený"},
{"code":"H02.-","desc":"Iná choroba mihalnice"},
{"code":"H02.0","desc":"Entropium a trichiáza mihalnice"},
{"code":"H02.1","desc":"Ektropium mihalnice"},
{"code":"H02.2","desc":"Lagoftalmus"},
{"code":"H02.3","desc":"Blefarochaláza"},
{"code":"H02.4","desc":"Ptóza mihalnice"},
{"code":"H02.5","desc":"Iná choroba postihujúca funkciu mihalnice"},
{"code":"H02.6","desc":"Xantelazma mihalnice"},
{"code":"H02.7","desc":"Iná degeneratívna choroba mihalnice a okolia oka"},
{"code":"H02.8","desc":"Iná choroba mihalnice, bližšie určená"},
{"code":"H02.9","desc":"Choroba mihalnice, bližšie neurčená"},
{"code":"H03.-*","desc":"Choroby mihalnice pri chorobách zatriedených inde"},
{"code":"H03.0*","desc":"Parazitové zamorenie mihalnice pri chorobách zatriedených inde"},
{"code":"H03.1*","desc":"Postihnutie mihalnice pri iných infekčných chorobách zatriedených inde"},
{"code":"H03.8*","desc":"Postihnutie mihalnice pri iných chorobách zatriedených inde"},
{"code":"H04.-","desc":"Choroba slzných orgánov"},
{"code":"H04.0","desc":"Dakryoadenitída"},
{"code":"H04.1","desc":"Iná choroba slznej žľazy"},
{"code":"H04.2","desc":"Epifora"},
{"code":"H04.3","desc":"Akútny a bližšie neurčený zápal slzných ciest"},
{"code":"H04.4","desc":"Chronický zápal slzných ciest"},
{"code":"H04.5","desc":"Stenóza a insuficiencia slzných ciest"},
{"code":"H04.6","desc":"Iná zmena v slzných cestách"},
{"code":"H04.8","desc":"Iná choroba slzných orgánov"},
{"code":"H04.9","desc":"Choroba slzných orgánov, bližšie neurčená"},
{"code":"H05.-","desc":"Choroba očnice"},
{"code":"H05.0","desc":"Akútny zápal očnice"},
{"code":"H05.1","desc":"Chronická zápalová choroba očnice"},
{"code":"H05.2","desc":"Exoftalmus"},
{"code":"H05.3","desc":"Deformácia očnice"},
{"code":"H05.4","desc":"Enoftalmus"},
{"code":"H05.5","desc":"Zadržané (staré) cudzie teleso po penetrujúcom poranení očnice"},
{"code":"H05.8","desc":"Iná choroba očnice"},
{"code":"H05.9","desc":"Choroba očnice, bližšie neurčená"},
{"code":"H06.-*","desc":"Choroba slzných orgánov a očnice pri chorobách zatriedených inde"},
{"code":"H06.0*","desc":"Choroba slzných orgánov pri chorobách zatriedených inde"},
{"code":"H06.1*","desc":"Parazitové zamorenie očnice pri chorobách zatriedených inde"},
{"code":"H06.2*","desc":"Exoftalmus pri poruche funkcie štítnej žľazy (E05.-†)"},
{"code":"H06.3*","desc":"Iná choroba očnice pri chorobách zatriedených inde"},
{"code":"H10-H13","desc":"Choroby spojovky"},
{"code":"H10.-","desc":"Konjuktivitída"},
{"code":"H10.0","desc":"Mukopurulentná konjunktivitída"},
{"code":"H10.1","desc":"Akútna atopická konjunktivitída"},
{"code":"H10.2","desc":"Iná akútna konjunktivitída"},
{"code":"H10.3","desc":"Akútna konjunktivitída, bližšie neurčená"},
{"code":"H10.4","desc":"Chronická konjunktivitída"},
{"code":"H10.5","desc":"Blefarokonjunktivitída"},
{"code":"H10.8","desc":"Iná konjunktivitída"},
{"code":"H10.9","desc":"Konjunktivitída, bližšie neurčená"},
{"code":"H11.-","desc":"Iná choroba spojovky"},
{"code":"H11.0","desc":"Pterygium"},
{"code":"H11.1","desc":"Spojovková degenerácia a depozity"},
{"code":"H11.2","desc":"Spojovková jazva"},
{"code":"H11.3","desc":"Spojovkové krvácanie"},
{"code":"H11.4","desc":"Iná cievna choroba a cysta spojovky"},
{"code":"H11.8","desc":"Iná choroba spojovky, bližšie určená"},
{"code":"H11.9","desc":"Choroba spojovky, bližšie neurčená"},
{"code":"H13.-*","desc":"Choroba spojovky pri chorobách zatriedených inde"},
{"code":"H13.0*","desc":"Filáriová infekcia spojovky (B74.-†)"},
{"code":"H13.1*","desc":"Konjunktivitída pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"H13.2*","desc":"Konjunktivitída pri iných chorobách zatriedených inde"},
{"code":"H13.3*","desc":"Očný pemfigoid (L12.-†)"},
{"code":"H13.8*","desc":"Iná choroba spojovky pri chorobách zatriedených inde"},
{"code":"H15-H22","desc":"Choroby očného bielka, rohovky, dúhovky a vráskovca (corpus ciliare)"},
{"code":"H15.-","desc":"Choroby bielka"},
{"code":"H15.0","desc":"Skleritída"},
{"code":"H15.1","desc":"Episkleritída"},
{"code":"H15.8","desc":"Iné choroby očného bielka"},
{"code":"H15.9","desc":"Choroba očného bielka, bližšie neurčená"},
{"code":"H16.-","desc":"Keratitída"},
{"code":"H16.0","desc":"Vred rohovky [ulcus corneae]"},
{"code":"H16.1","desc":"Iná povrchová keratitída bez konjunktivitídy"},
{"code":"H16.2","desc":"Keratokonjunktivitída"},
{"code":"H16.3","desc":"Intersticiálna a hlboká keratitída"},
{"code":"H16.4","desc":"Neovaskularizácia rohovky"},
{"code":"H16.8","desc":"Iná keratitída"},
{"code":"H16.9","desc":"Keratitída, bližšie neurčená"},
{"code":"H17.-","desc":"Jazva a zákal rohovky"},
{"code":"H17.0","desc":"Leucoma adhaerens"},
{"code":"H17.1","desc":"Iný centrálny zákal rohovky"},
{"code":"H17.8","desc":"Iná jazva a zákal rohovky"},
{"code":"H17.9","desc":"Jazva a zákal rohovky, bližšie neurčené"},
{"code":"H18.-","desc":"Iná choroba rohovky"},
{"code":"H18.0","desc":"Pigmentácie a depozity rohovky"},
{"code":"H18.1","desc":"Keratopathia bullosa"},
{"code":"H18.2","desc":"Iný opuch rohovky"},
{"code":"H18.3","desc":"Zmeny vo vrstvách rohovky"},
{"code":"H18.4","desc":"Degenerácia rohovky"},
{"code":"H18.5","desc":"Hereditárna dystrofia rohovky"},
{"code":"H18.6","desc":"Keratokonus"},
{"code":"H18.7","desc":"Iná deformácia rohovky"},
{"code":"H18.8-","desc":"Iná choroba rohovky, bližšie určená"},
{"code":"H18.80","desc":"Erózia rohovky"},
{"code":"H18.81","desc":"Keratopathia neuroparalytica"},
{"code":"H18.89","desc":"Iná choroba rohovky, bližšie určená"},
{"code":"H18.9","desc":"Choroba rohovky, bližšie neurčená"},
{"code":"H19.-*","desc":"Choroba očného bielka a rohovky pri chorobách zatriedených inde"},
{"code":"H19.0*","desc":"Skleritída a episkleritída pri chorobách zatriedených inde"},
{"code":"H19.1*","desc":"Herpetická keratitída a keratokonjunktivitída (B00.5†)"},
{"code":"H19.2*","desc":"Keratitída a keratokonjunktivitída pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"H19.3*","desc":"Keratitída a keratokonjunktivitída pri iných chorobách zatriedených inde"},
{"code":"H19.8*","desc":"Iná choroba očného bielka a rohovky pri chorobách zatriedených inde"},
{"code":"H20.-","desc":"Iridocyklitída"},
{"code":"H20.0","desc":"Akútna a subakútna iridocyklitída"},
{"code":"H20.1","desc":"Chronická iridocyklitída"},
{"code":"H20.2","desc":"Iridocyklitída zapríčinená šošovkou [fakogénna]"},
{"code":"H20.8","desc":"Iná iridocyklitída"},
{"code":"H20.9","desc":"Iridocyklitída, bližšie neurčená"},
{"code":"H21.-","desc":"Iná choroba dúhovky a vráskovca [corpus ciliare]"},
{"code":"H21.0","desc":"Hyféma"},
{"code":"H21.1","desc":"Iná vaskulárna choroba dúhovky a vráskovca"},
{"code":"H21.2","desc":"Degenerácia dúhovky a vráskovca"},
{"code":"H21.3","desc":"Cysta dúhovky, vráskovca a prednej komory"},
{"code":"H21.4","desc":"Membrány zrenice"},
{"code":"H21.5","desc":"Iný zrast a trhlina dúhovky a vráskovca"},
{"code":"H21.8","desc":"Iná bližšie určená choroba dúhovky a vráskovca"},
{"code":"H21.9","desc":"Choroba dúhovky a vráskovca, bližšie neurčená"},
{"code":"H22.-*","desc":"Choroby dúhovky a vráskovca pri chorobách zatriedených inde"},
{"code":"H22.0*","desc":"Iridocyklitída pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"H22.1*","desc":"Iridocyklitída pri iných chorobách zatriedených inde"},
{"code":"H22.8*","desc":"Iná choroba dúhovky a vráskovca pri chorobách zatriedených inde"},
{"code":"H25-H28","desc":"Choroby šošoviek"},
{"code":"H25.-","desc":"Senilná katarakta"},
{"code":"H25.0","desc":"Začínajúca senilná katarakta"},
{"code":"H25.1","desc":"Senilná katarakta nukleárna [fakoskleróm]"},
{"code":"H25.2","desc":"Senilná katarakta, Morgagniho typ"},
{"code":"H25.8","desc":"Iná senilná katarakta"},
{"code":"H25.9","desc":"Senilná katarakta, bližšie neurčená"},
{"code":"H26.-","desc":"Iná katarakta"},
{"code":"H26.0","desc":"Infantilná, juvenilná a presenilná katarakta"},
{"code":"H26.1","desc":"Úrazová katarakta"},
{"code":"H26.2","desc":"Komplikovaná katarakta"},
{"code":"H26.3","desc":"Katarakta zapríčinená liekmi"},
{"code":"H26.4","desc":"Sekundárna katarakta"},
{"code":"H26.8","desc":"Iná katarakta, bližšie určená"},
{"code":"H26.9","desc":"Zákal šošovky, bližšie neurčený"},
{"code":"H27.-","desc":"Iná choroba šošovky"},
{"code":"H27.0","desc":"Afakia"},
{"code":"H27.1-","desc":"Dislokácia šošovky"},
{"code":"H27.10","desc":"Dislokácia IOL"},
{"code":"H27.19","desc":"Dislokácia šošovky"},
{"code":"H27.8","desc":"Iná choroba šošovky, bližšie určená"},
{"code":"H27.9","desc":"Choroba šošovky, bližšie neurčená"},
{"code":"H28.-*","desc":"Katarakta a iná choroby šošovky pri chorobách zatriedených inde"},
{"code":"H28.0*","desc":"Diabetická katarakta (E10-E14, štvrtá pozícia .3†)"},
{"code":"H28.1*","desc":"Katarakta pri iných endokrinných, nutričných a metabolických chorobách"},
{"code":"H28.2*","desc":"Zákal šošovky pri iných chorobách zatriedených inde"},
{"code":"H28.8*","desc":"Iná choroba šošovky pri chorobách zatriedených inde"},
{"code":"H30-H36","desc":"Choroby cievovky a sietnice"},
{"code":"H30.-","desc":"Chorioretinitída"},
{"code":"H30.0","desc":"Ložisková chorioretinitída"},
{"code":"H30.1","desc":"Diseminovaná chorioretinitída"},
{"code":"H30.2","desc":"Cyclitis posterior"},
{"code":"H30.8","desc":"Iná chorioretinitída"},
{"code":"H30.9","desc":"Chorioretinitída, bližšie neurčená"},
{"code":"H31.-","desc":"Iná choroba cievovky"},
{"code":"H31.0","desc":"Chorioretinové jazvy"},
{"code":"H31.1","desc":"Degenerácia cievovky"},
{"code":"H31.2","desc":"Dedičná dystrofia cievovky"},
{"code":"H31.3","desc":"Krvácanie a ruptúra cievovky"},
{"code":"H31.4","desc":"Odlúpenie cievovky"},
{"code":"H31.8","desc":"Iná choroba cievovky, bližšie určená"},
{"code":"H31.9","desc":"Choroba cievovky, bližšie neurčená"},
{"code":"H32.-*","desc":"Choroby cievovky a sietnice pri chorobách zatriedených inde"},
{"code":"H32.0*","desc":"Chorioretinitída pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"H32.8*","desc":"Iná choroba cievovky a sietnice pri chorobách zatriedených inde"},
{"code":"H33.-","desc":"Odlúpenie sietnice a natrhnutie sietnice"},
{"code":"H33.0","desc":"Odlúpenie sietnice s trhlinou sietnice"},
{"code":"H33.1","desc":"Rozštiepenie sietnice a cysty sietnice"},
{"code":"H33.2","desc":"Serózne odlúpenie sietnice"},
{"code":"H33.3","desc":"Trhliny sietnice bez odlúpenia"},
{"code":"H33.4","desc":"Trakčné odlúpenie sietnice"},
{"code":"H33.5","desc":"Iné odlúpenie sietnice"},
{"code":"H34.-","desc":"Oklúzia ciev sietnice"},
{"code":"H34.0","desc":"Prechodná oklúzia tepny sietnice"},
{"code":"H34.1","desc":"Oklúzia a.centralis retinae"},
{"code":"H34.2","desc":"Iná oklúzia tepien sietnice"},
{"code":"H34.8-","desc":"Iná oklúzia ciev sietnice"},
{"code":"H34.80","desc":"Oklúzia žily sietnice čiastočná"},
{"code":"H34.81","desc":"Oklúzia žily sietnice centrálna"},
{"code":"H34.89","desc":"Iná oklúzia ciev sietnice"},
{"code":"H34.9","desc":"Oklúzia cievy sietnice, bližšie neurčená"},
{"code":"H35.-","desc":"Iné choroby sietnice"},
{"code":"H35.0-","desc":"Retinopatia zadnej steny oka a cievne zmeny sietnice"},
{"code":"H35.00","desc":"Neovaskularizácie sietnice pri iných ochoreniach"},
{"code":"H35.09","desc":"Retinopatia zadnej steny oka a cievne zmeny sietnice"},
{"code":"H35.1-","desc":"Retinopatia nedonosených"},
{"code":"H35.10","desc":"Neukončená vaskularizácia sietnice"},
{"code":"H35.19","desc":"Retinopatia nedonosených"},
{"code":"H35.2","desc":"Iná proliferatívna retinopatia"},
{"code":"H35.3-","desc":"Degenerácia makuly a zadného pólu oka"},
{"code":"H35.30","desc":"Vekom podmienená degenerácia makuly suchá forma"},
{"code":"H35.31","desc":"Vekom podmienená degenerácia makuly vlhká forma"},
{"code":"H35.32","desc":"Poruchy vitreomakulárneho rozhrania"},
{"code":"H35.39","desc":"Iná degenerácia makuly a zadného pólu oka"},
{"code":"H35.4","desc":"Periférna degenerácia sietnice"},
{"code":"H35.5","desc":"Dedičná dystrofia sietnice"},
{"code":"H35.6","desc":"Krvácanie do sietnice"},
{"code":"H35.7-","desc":"Oddelenie vrstiev sietnice"},
{"code":"H35.70","desc":"Centrálna serózna chorioretinopatia"},
{"code":"H35.79","desc":"Oddelenie vrstiev sietnice"},
{"code":"H35.8","desc":"Iná choroba sietnice, bližšie určená"},
{"code":"H35.9","desc":"Choroba sietnice, bližšie neurčená"},
{"code":"H36.-*","desc":"Choroba sietnice pri chorobách zatriedených inde"},
{"code":"H36.0-*","desc":"Diabetická retinopatia (E10-E14, štvrtá pozícia .3†)"},
{"code":"H36.00","desc":"Neproliferatívna diabetická retinopatia bez diabetického edému makuly"},
{"code":"H36.01","desc":"Neproliferatívna diabetická retinopatia s diabetickým edémom makuly"},
{"code":"H36.02","desc":"Proliferatívna diabetická retinopatia bez diabetického edému makuly"},
{"code":"H36.03","desc":"Proliferatívna diabetická retinopatia s diabetickým edémom makuly"},
{"code":"H36.09*","desc":"Diabetická retinopatia (E10-E14, štvrtá pozícia .3†)"},
{"code":"H36.8*","desc":"Iná choroba sietnice pri chorobách zatriedených inde"},
{"code":"H40-H42","desc":"Glaukóm"},
{"code":"H40.-","desc":"Glaukóm"},
{"code":"H40.0-","desc":"Podozrenie na glaukóm"},
{"code":"H40.00","desc":"Očná hypertenzia"},
{"code":"H40.01","desc":"Očná hypertenzia pri otvorenom uhle"},
{"code":"H40.02","desc":"Očná hypertenzia pri úzkom, okludabilnom uhle"},
{"code":"H40.03","desc":"Očná hypertenzia pri syndróme disperzie pigmentu"},
{"code":"H40.04","desc":"Očná hypertenzia pri exfoliatívnom syndróme"},
{"code":"H40.09","desc":"Podozrenie na glaukóm"},
{"code":"H40.1-","desc":"Primárny glaukóm s otvoreným uhlom"},
{"code":"H40.10","desc":"Primárny juvenilný glaukóm s neskorým nástupom"},
{"code":"H40.19","desc":"Primárny glaukóm s otvoreným uhlom"},
{"code":"H40.2","desc":"Primárny glaukóm so zatvoreným uhlom"},
{"code":"H40.3","desc":"Sekundárny glaukóm po poranení oka"},
{"code":"H40.4","desc":"Sekundárny glaukóm po zápale oka"},
{"code":"H40.5","desc":"Sekundárny glaukóm pri iných chorobách"},
{"code":"H40.6-","desc":"Sekundárny glaukóm zapríčinený liekmi"},
{"code":"H40.60","desc":"Sekundárny glaukóm zapríčinený liekmi steroidami"},
{"code":"H40.61","desc":"Sekundárny glaukóm zapríčinený liekmi antiparkinsonikami"},
{"code":"H40.62","desc":"Sekundárny glaukóm zapríčinený liekmi antipsychotikami"},
{"code":"H40.63","desc":"Sekundárny glaukóm zapríčinený liekmi antikonvulzívami"},
{"code":"H40.64","desc":"Sekundárny glaukóm zapríčinený liekmi antidepresívami"},
{"code":"H40.65","desc":"Sekundárny glaukóm zapríčinený liekmi benzodiazepínmi"},
{"code":"H40.66","desc":"Sekundárny glaukóm zapríčinený liekmi, iné"},
{"code":"H40.69","desc":"Sekundárny glaukóm zapríčinený liekmi"},
{"code":"H40.7-","desc":"Špecifický sekundárny glaukóm"},
{"code":"H40.70","desc":"Sekundárny glaukóm pigmentový"},
{"code":"H40.71","desc":"Sekundárny glaukóm exfoliatívny"},
{"code":"H40.79","desc":"Špecifický sekundárny glaukóm"},
{"code":"H40.8-","desc":"Iný glaukóm"},
{"code":"H40.80","desc":"Glaukóm indukovaný šošovkou"},
{"code":"H40.81","desc":"Glaukóm spojený s chirurgiou oka"},
{"code":"H40.82","desc":"Glaukóm spojený s vnútroočnou hemorágiou"},
{"code":"H40.83","desc":"Glaukóm spojený s vnútroočným tumorom"},
{"code":"H40.84","desc":"Glaukóm spojený so zvýšeným episklerálnym venóznym tlakom"},
{"code":"H40.89","desc":"Iný glaukóm"},
{"code":"H40.9","desc":"Glaukóm, bližšie neurčený"},
{"code":"H42.-*","desc":"Glaukóm pri chorobách zatriedených inde"},
{"code":"H42.0*","desc":"Glaukóm pri endokrinných, nutričných a metabolických chorobách zatriedených inde"},
{"code":"H42.8*","desc":"Glaukóm pri iných chorobách zatriedených inde"},
{"code":"H43-H45","desc":"Choroby sklovca a očnej gule"},
{"code":"H43.-","desc":"Choroba sklovca"},
{"code":"H43.0","desc":"Prolaps sklovca"},
{"code":"H43.1","desc":"Krvácanie do sklovca"},
{"code":"H43.2","desc":"Kryštálové depozity v sklovci"},
{"code":"H43.3","desc":"Iný zákal sklovca"},
{"code":"H43.8","desc":"Iná choroba sklovca"},
{"code":"H43.9","desc":"Choroba sklovca, bližšie neurčená"},
{"code":"H44.-","desc":"Choroba očnej gule"},
{"code":"H44.0","desc":"Hnisová endoftalmitída"},
{"code":"H44.1","desc":"Iná endoftalmitída"},
{"code":"H44.2","desc":"Degeneratívna myopia"},
{"code":"H44.3","desc":"Iná degeneratívna choroba očnej gule"},
{"code":"H44.4","desc":"Hypotónia oka"},
{"code":"H44.5","desc":"Degeneratívne postihnutie očnej gule"},
{"code":"H44.6","desc":"Zadržané (staré) vnútroočné cudzie teleso, magnetické"},
{"code":"H44.7","desc":"Zadržané (staré) vnútroočné cudzie teleso, nemagnetické"},
{"code":"H44.8","desc":"Iná choroba očnej gule"},
{"code":"H44.9","desc":"Choroba očnej gule, bližšie neurčená"},
{"code":"H45.-*","desc":"Choroba sklovca a očnej gule pri chorobách zatriedených inde"},
{"code":"H45.0*","desc":"Krvácanie do sklovca pri chorobách zatriedených inde"},
{"code":"H45.1*","desc":"Endoftalmitída pri chorobách zatriedených inde"},
{"code":"H45.8*","desc":"Iná choroba sklovca a očnej gule pri chorobách zatriedených inde"},
{"code":"H46-H48","desc":"Choroby zrakového nervu a zrakových dráh"},
{"code":"H46","desc":"Zápal zrakového nervu [neuritis n. optici]"},
{"code":"H47.-","desc":"Iná choroba zrakového nervu a zrakových dráh"},
{"code":"H47.0","desc":"Choroba zrakového nervu, nezatriedená inde"},
{"code":"H47.1","desc":"Opuch papily zrakového nervu, bližšie neurčený"},
{"code":"H47.2","desc":"Atrofia zrakového nervu"},
{"code":"H47.3","desc":"Iná choroba papily zrakového nervu"},
{"code":"H47.4","desc":"Choroba chiasma opticum"},
{"code":"H47.5","desc":"Choroba iných častí zrakových dráh"},
{"code":"H47.6","desc":"Choroba kôrového zrakového centra"},
{"code":"H47.7","desc":"Choroba zrakových dráh, bližšie neurčená"},
{"code":"H48.-*","desc":"Choroba zrakového nervu [n.opticus] a zrakových dráh pri chorobách zatriedených inde"},
{"code":"H48.0*","desc":"Atrofia zrakového nervu [n.opticus] pri chorobách zatriedených inde"},
{"code":"H48.1*","desc":"Retrobulbárna neuritída pri chorobách zatriedených inde"},
{"code":"H48.8*","desc":"Iná choroba zrakového nervu [n.opticus] a zrakových dráh pri chorobách zatriedených inde"},
{"code":"H49-H52","desc":"Poruchy očných svalov, binokulárnych pohybov, akomodácie a refrakcie"},
{"code":"H49.-","desc":"Paralytický strabizmus"},
{"code":"H49.0","desc":"Obrna tretieho hlavového nervu [n. oculomotorius]"},
{"code":"H49.1","desc":"Ochrnutie štvrtého hlavového nervu [n. trochlearis]"},
{"code":"H49.2","desc":"Ochrnutie šiesteho hlavového nervu [n. abducens]"},
{"code":"H49.3","desc":"Kompletná vonkajšia oftalmoplégia"},
{"code":"H49.4","desc":"Progresívna vonkajšia oftalmoplégia"},
{"code":"H49.8","desc":"Iný paralytický strabizmus"},
{"code":"H49.9","desc":"Paralytický strabizmus, bližšie neurčený"},
{"code":"H50.-","desc":"Iný strabizmus"},
{"code":"H50.0","desc":"Konvergentný konkomitantný strabizmus"},
{"code":"H50.1","desc":"Divergentný konkomitantný strabizmus"},
{"code":"H50.2","desc":"Vertikálny strabizmus"},
{"code":"H50.3","desc":"Intermitentný konkomitantný strabizmus"},
{"code":"H50.4","desc":"Iný a bližšie neurčený konkomitantný strabizmus"},
{"code":"H50.5","desc":"Heterofória"},
{"code":"H50.6","desc":"Mechanický strabizmus"},
{"code":"H50.8","desc":"Iný strabizmus, bližšie určený"},
{"code":"H50.9","desc":"Strabizmus, bližšie neurčený"},
{"code":"H51.-","desc":"Iná porucha binokulárnych pohybov"},
{"code":"H51.0","desc":"Ochrnutie konjugovaného pohľadu"},
{"code":"H51.1","desc":"Nedostatočná a nadmerná konvergencia"},
{"code":"H51.2","desc":"Internukleárna oftalmoplégia"},
{"code":"H51.8","desc":"Iná bližšie určená porucha binokulárnych pohybov"},
{"code":"H51.9","desc":"Porucha binokulárneho pohybu, bližšie neurčená"},
{"code":"H52.-","desc":"Porucha refrakcie a akomodácie"},
{"code":"H52.0","desc":"Hypermetropia"},
{"code":"H52.1","desc":"Myopia"},
{"code":"H52.2","desc":"Astigmatizmus"},
{"code":"H52.3","desc":"Anizometropia a anizeikonia"},
{"code":"H52.4","desc":"Presbyopia"},
{"code":"H52.5","desc":"Porucha akomodácie"},
{"code":"H52.6","desc":"Iná porucha refrakcie"},
{"code":"H52.7","desc":"Porucha refrakcie, bližšie neurčená"},
{"code":"H53-H54","desc":"Porucha videnia a slepota"},
{"code":"H53.-","desc":"Porucha videnia"},
{"code":"H53.0","desc":"Anoptická amblyopia [amblyopia ex anopsia]"},
{"code":"H53.1","desc":"Subjektívna porucha videnia"},
{"code":"H53.2","desc":"Diplopia"},
{"code":"H53.3","desc":"Iná porucha binokulárneho videnia"},
{"code":"H53.4","desc":"Porucha zorného poľa"},
{"code":"H53.5","desc":"Porucha farebného videnia"},
{"code":"H53.6","desc":"Nočná slepota [nyctalopia]"},
{"code":"H53.8","desc":"Iná zraková porucha"},
{"code":"H53.9","desc":"Zraková porucha, bližšie neurčená"},
{"code":"H54.-","desc":"Slepota a slabozrakosť"},
{"code":"H54.0","desc":"Slepota a veľmi ťažká slabozrakosť, binokulárna"},
{"code":"H54.1","desc":"Tažká slabozrakosť, binokulárna"},
{"code":"H54.2","desc":"Stredne ťažká slabozrakosť, binokulárna"},
{"code":"H54.3","desc":"Ľahká slabozrakosť, binokulárna"},
{"code":"H54.4","desc":"Slepota a veľmi ťažká slabozrakosť, monokulárna"},
{"code":"H54.5","desc":"Tažká slabozrakosť, monokulárne"},
{"code":"H54.6","desc":"Stredne ťažká slabozrakosť, monokulárna"},
{"code":"H54.9","desc":"Slabozrakosť binokulárna, bližšie neurčená"},
{"code":"H55-H59","desc":"Iné choroby oka a očných adnexov"},
{"code":"H55","desc":"Nystagmus a iné nepravidelné pohyby oka"},
{"code":"H57.-","desc":"Iné choroby oka a jeho adnexov"},
{"code":"H57.0","desc":"Anomálie funkcie zrenice"},
{"code":"H57.1","desc":"Bolesť oka"},
{"code":"H57.8","desc":"Iná choroby oka a jeho adnexov, bližšie určené"},
{"code":"H57.9","desc":"Choroba oka a jeho adnexov, bližšie neurčená"},
{"code":"H58.-*","desc":"Iná choroba oka a jeho adnexov pri chorobách zatriedených inde"},
{"code":"H58.0*","desc":"Anomálie funkcie zrenice pri chorobách zatriedených inde"},
{"code":"H58.1*","desc":"Poruchy videnia pri chorobách zatriedených inde"},
{"code":"H58.8*","desc":"Iná choroba oka a jeho adnexov, bližšie určená, pri chorobách zatriedených inde"},
{"code":"H59.-","desc":"Choroby oka a jeho adnexov po lekárskom výkone, nezatriedené inde"},
{"code":"H59.0","desc":"Afakická bulózna keratopatia po operácii katarakty"},
{"code":"H59.8","desc":"Iné choroby oka a jeho adnexov po lekárskom výkone"},
{"code":"H59.9","desc":"Choroba oka a jeho adnexov po lekárskom výkone, bližšie neurčená"},
{"code":"H60-H95","desc":"VIII. kapitola - Choroby ucha a hlávkového výbežku (H60-H95)"},
{"code":"H60-H62","desc":"Choroby vonkajšieho ucha"},
{"code":"H60.-","desc":"Zápal vonkajšieho ucha"},
{"code":"H60.0","desc":"Absces vonkajšieho ucha"},
{"code":"H60.1","desc":"Flegmóna vonkajšieho ucha"},
{"code":"H60.2","desc":"Malígny zápal vonkajšieho ucha [otitis externa maligna]"},
{"code":"H60.3","desc":"Iný infekčný zápal vonkajšieho ucha"},
{"code":"H60.4","desc":"Cholesteatóm vonkajšieho ucha"},
{"code":"H60.5","desc":"Akútny neinfekčný zápal vonkajšieho ucha"},
{"code":"H60.8","desc":"Iný zápal vonkajšieho ucha"},
{"code":"H60.9","desc":"Zápal vonkajšieho ucha, bližšie neurčený"},
{"code":"H61.-","desc":"Iné chorobym vonkajšieho ucha"},
{"code":"H61.0","desc":"Perichondritída vonkajšieho ucha"},
{"code":"H61.1","desc":"Neinfekčná choroba ušnice"},
{"code":"H61.2","desc":"Stvrdnutý ušný maz [cerumen]"},
{"code":"H61.3","desc":"Získaná stenóza vonkajšieho zvukovodu"},
{"code":"H61.8","desc":"Iná choroba vonkajšieho ucha, bližšie určená"},
{"code":"H61.9","desc":"Choroba vonkajšieho ucha, bližšie neurčená"},
{"code":"H62.-*","desc":"Choroba vonkajšieho ucha pri chorobách zatriedených inde"},
{"code":"H62.0*","desc":"Zápal vonkajšieho ucha pri baktériových chorobách zatriedených inde"},
{"code":"H62.1*","desc":"Zápal vonkajšieho ucha pri vírusových chorobách zatriedených inde"},
{"code":"H62.2*","desc":"Zápal vonkajšieho ucha pri mykózach zatriedených inde"},
{"code":"H62.3*","desc":"Zápal vonkajšieho ucha pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"H62.4*","desc":"Zápal vonkajšieho ucha pri iných chorobách zatriedených inde"},
{"code":"H62.8*","desc":"Iná choroba vonkajšieho ucha pri chorobách zatriedených inde"},
{"code":"H65-H75","desc":"Choroby stredného ucha a hlávkového výbežku"},
{"code":"H65.-","desc":"Nehnisový zápal stredného ucha"},
{"code":"H65.0","desc":"Akútny sérový zápal stredného ucha"},
{"code":"H65.1","desc":"Iný akútny nehnisový zápal stredného ucha"},
{"code":"H65.2","desc":"Chronický sérový zápal stredného ucha"},
{"code":"H65.3","desc":"Chronický hlienový zápal stredného ucha"},
{"code":"H65.4","desc":"Iný chronický nehnisový zápal stredného ucha"},
{"code":"H65.9","desc":"Nehnisový zápal stredného ucha, bližšie neurčený"},
{"code":"H66.-","desc":"Hnisový a bližšie neurčený zápal stredného ucha"},
{"code":"H66.0","desc":"Akútny hnisový zápal stredného ucha"},
{"code":"H66.1","desc":"Chronický tubotympanový hnisový zápal stredného ucha"},
{"code":"H66.2","desc":"Chronický atikoantrálny hnisový zápal stredného ucha"},
{"code":"H66.3","desc":"Iný chronický hnisový zápal stredného ucha"},
{"code":"H66.4","desc":"Hnisový zápal stredného ucha, bližšie neurčený"},
{"code":"H66.9","desc":"Zápal stredného ucha, bližšie neurčený"},
{"code":"H67.-*","desc":"Zápal stredného ucha pri chorobách zatriedených inde"},
{"code":"H67.0*","desc":"Zápal stredného ucha pri baktériových chorobách zatriedených inde"},
{"code":"H67.1*","desc":"Zápal stredného ucha pri vírusových chorobách zatriedených inde"},
{"code":"H67.8*","desc":"Zápal stredného ucha pri iných chorobách zatriedených inde"},
{"code":"H68.-","desc":"Zápal a uzáver sluchovej trubice"},
{"code":"H68.0","desc":"Zápal sluchovej trubice"},
{"code":"H68.1","desc":"Uzáver sluchovej trubice"},
{"code":"H69.-","desc":"Iná choroba sluchovej trubice"},
{"code":"H69.0","desc":"Otvorená sluchová trubica (tuba patens)"},
{"code":"H69.8","desc":"Iná choroba sluchovej trubice, bližšie určená"},
{"code":"H69.9","desc":"Choroba sluchovej trubice, bližšie neurčená"},
{"code":"H70.-","desc":"Zápal hlávkového výbežku a príbuzné choroby"},
{"code":"H70.0","desc":"Akútna mastoiditída"},
{"code":"H70.1","desc":"Chronická mastoiditída"},
{"code":"H70.2","desc":"Petrozitída"},
{"code":"H70.8","desc":"Iná mastoiditída a príbuzná choroba"},
{"code":"H70.9","desc":"Mastoiditída, bližšie neurčená"},
{"code":"H71","desc":"Cholesteatóm stredného ucha"},
{"code":"H72.-","desc":"Perforácia blany bubienka"},
{"code":"H72.0","desc":"Centrálna perforácia blany bubienka"},
{"code":"H72.1","desc":"Atiková perforácia blany bubienka"},
{"code":"H72.2","desc":"Iná okrajová perforácia blany bubienka"},
{"code":"H72.8","desc":"Iná perforácia blany bubienka"},
{"code":"H72.9","desc":"Perforácia blany bubienka, bližšie neurčená"},
{"code":"H73.-","desc":"Iné choroby blany bubienka"},
{"code":"H73.0","desc":"Akútna myringitída"},
{"code":"H73.1","desc":"Chronická myringitída"},
{"code":"H73.8","desc":"Iná choroba blany bubienka, bližšie určená"},
{"code":"H73.9","desc":"Choroba blany bubienka, bližšie neurčená"},
{"code":"H74.-","desc":"Iná choroba stredného ucha a hlávkového výbežku"},
{"code":"H74.0","desc":"Tympanoskleróza"},
{"code":"H74.1","desc":"Adhezívna choroba stredného ucha"},
{"code":"H74.2","desc":"Diskontinuita a dislokácia sluchových kostičiek"},
{"code":"H74.3","desc":"Iná získaná abnormalita sluchových kostičiek"},
{"code":"H74.4","desc":"Polyp stredného ucha"},
{"code":"H74.8","desc":"Iné choroby stredného ucha a hlávkového výbežku, bližšie určené"},
{"code":"H74.9","desc":"Choroba stredného ucha a hlávkového výbežku, bližšie neurčená"},
{"code":"H75.-*","desc":"Iná choroba stredného ucha a hlávkového výbežku pri chorobách zatriedených inde"},
{"code":"H75.0*","desc":"Mastoiditída pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"H75.8*","desc":"Iná choroba stredného ucha a hlávkového výbežku, bližšie určená, pri chorobách zatriedených inde"},
{"code":"H80-H83","desc":"Choroby vnútorného ucha"},
{"code":"H80.-","desc":"Otoskleróza"},
{"code":"H80.0","desc":"Otoskleróza s postihnutím oválneho okienka, neobliterujúca"},
{"code":"H80.1","desc":"Otoskleróza s postihnutím oválneho okienka, obliterujúca"},
{"code":"H80.2","desc":"Kochleárna otoskleróza"},
{"code":"H80.8","desc":"Iná otoskleróza"},
{"code":"H80.9","desc":"Otoskleróza, bližšie neurčená"},
{"code":"H81.-","desc":"Poruchy vestibulárnej funkcie"},
{"code":"H81.0","desc":"Meniereova choroba"},
{"code":"H81.1","desc":"Benígny záchvatový závrat - vertigo"},
{"code":"H81.2","desc":"Vestibulárna neuropatia"},
{"code":"H81.3","desc":"Iný periférny závrat - vertigo"},
{"code":"H81.4","desc":"Závrat - vertigo centrálneho pôvodu"},
{"code":"H81.8","desc":"Iná porucha vestibulárnej funkcie"},
{"code":"H81.9","desc":"Porucha vestibulárnej funkcie, bližšie neurčená"},
{"code":"H82*","desc":"Závratový syndróm pri chorobách zatriedených inde"},
{"code":"H83.-","desc":"Iné choroby vnútorného ucha"},
{"code":"H83.0","desc":"Zápal labyrintu vnútorného ucha"},
{"code":"H83.1","desc":"Fistula labyrintu vnútorného ucha"},
{"code":"H83.2","desc":"Porucha funkcie labyrintu vnútorného ucha"},
{"code":"H83.3","desc":"Vplyv hluku na vnútorné ucho"},
{"code":"H83.8","desc":"Iné choroby vnútorného ucha, bližšie určené"},
{"code":"H83.9","desc":"Choroba vnútorného ucha, bližšie neurčená"},
{"code":"H90-H95","desc":"Iné choroby ucha"},
{"code":"H90.-","desc":"Prevodová a percepčná strata sluchu"},
{"code":"H90.0","desc":"Obojstranná prevodová strata sluchu"},
{"code":"H90.1","desc":"Jednostranná prevodová strata sluchu s neporušeným sluchom na druhej strane"},
{"code":"H90.2","desc":"Prevodová strata sluchu, bližšie neurčená"},
{"code":"H90.3","desc":"Obojstranná percepčná strata sluchu"},
{"code":"H90.4","desc":"Jednostranná percepčná strata sluchu s neporušeným sluchom na druhej strane"},
{"code":"H90.5","desc":"Percepčná strata sluchu, bližšie neurčená"},
{"code":"H90.6","desc":"Obojstranná zmiešaná prevodová a percepčná strata sluchu"},
{"code":"H90.7","desc":"Jednostranná zmiešaná prevodová a percepčná strata sluchu s neporušeným sluchom na druhej strane"},
{"code":"H90.8","desc":"Zmiešaná prevodová a percepčná strata sluchu, bližšie neurčená"},
{"code":"H91.-","desc":"Iná strata sluchu"},
{"code":"H91.0","desc":"Ototoxická strata sluchu"},
{"code":"H91.1","desc":"Presbyakúzia"},
{"code":"H91.2","desc":"Náhla idiopatická strata sluchu"},
{"code":"H91.3","desc":"Hluchonemota, nezatriedená inde"},
{"code":"H91.8","desc":"Iná strata sluchu, bližšie určená"},
{"code":"H91.9","desc":"Strata sluchu, bližšie neurčená"},
{"code":"H92.-","desc":"Bolesť v uchu a výtok z ucha"},
{"code":"H92.0","desc":"Bolesť v uchu [otalgia]"},
{"code":"H92.1","desc":"Výtok z ucha [otorrhoe]"},
{"code":"H92.2","desc":"Krvácanie z ucha [otorrhagia]"},
{"code":"H93.-","desc":"Iné choroby ucha, nezatriedené inde"},
{"code":"H93.0.-","desc":"Degeneratívna a cievna choroba ucha"},
{"code":"H93.1","desc":"Zvonenie v ušiach [tinnitus aurium]"},
{"code":"H93.01","desc":"Pulzatilný tinnitus, pravé ucho"},
{"code":"H93.02","desc":"Pulzatilný tinnitus, ľavé ucho"},
{"code":"H93.03","desc":"Pulzatilný tinnitus, bilaterálne"},
{"code":"H93.09","desc":"Pulzatilný tinnitus, nešpecifikované ucho"},
{"code":"H93.2","desc":"Iný abnormálny sluchový vnem"},
{"code":"H93.3","desc":"Choroba sluchového nervu [n.vestibulocochlearis]"},
{"code":"H93.8","desc":"Iné choroby ucha, bližšie určené"},
{"code":"H93.9","desc":"Choroba ucha, bližšie neurčená"},
{"code":"H94.-*","desc":"Iné choroby ucha pri chorobách zatriedených inde"},
{"code":"H94.0*","desc":"Zápal sluchového nervu [n.vestibulocochlearis] pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"H94.8*","desc":"Iná bližšie určená choroba ucha pri chorobách zatriedených inde"},
{"code":"H95.-","desc":"Choroby ucha a hlávkového výbežku po lekárskom výkone, nezatriedené inde"},
{"code":"H95.0","desc":"Recidivujúci cholesteatóm v dutine po mastoidektómii"},
{"code":"H95.1","desc":"Iná choroba po mastoidektómii"},
{"code":"H95.8","desc":"Iná choroba ucha a hlávkového výbežku"},
{"code":"H95.9","desc":"Choroba ucha a hlávkového výbežku po lekárskom výkone, bližšie neurčená"},
{"code":"I00-I99","desc":"IX. kapitola - Choroby obehovej sústavy (I00-I99)"},
{"code":"I00-I02","desc":"Akútna reumatická horúčka"},
{"code":"I00","desc":"Reumatická horúčka bez postihnutia srdca"},
{"code":"I01.-","desc":"Reumatická horúčka s postihnutím srdca"},
{"code":"I01.0","desc":"Akútna reumatická perikarditída"},
{"code":"I01.1","desc":"Akútna reumatická endokarditída"},
{"code":"I01.2","desc":"Akútna reumatická myokarditída"},
{"code":"I01.8","desc":"Iná akútna reumatická choroba srdca"},
{"code":"I01.9","desc":"Akútna reumatická choroba srdca, bližšie neurčená"},
{"code":"I02.-","desc":"Reumatická chorea"},
{"code":"I02.0","desc":"Reumatická chorea s postihnutím srdca"},
{"code":"I02.9","desc":"Reumatická chorea bez postihnutia srdca"},
{"code":"I05-I09","desc":"Chronické reumatické choroby srdca"},
{"code":"I05.-","desc":"Reumatická choroba mitrálnej chlopne"},
{"code":"I05.0","desc":"Mitrálna stenóza"},
{"code":"I05.1","desc":"Reumatická mitrálna insuficiencia"},
{"code":"I05.2","desc":"Mitrálna stenóza s insuficienciou"},
{"code":"I05.8","desc":"Iná choroba mitrálnej chlopne"},
{"code":"I05.9","desc":"Choroba mitrálnej chlopne, bližšie neurčená"},
{"code":"I06.-","desc":"Reumatická choroba aortálnej chlopne"},
{"code":"I06.0","desc":"Reumatická aortálna stenóza"},
{"code":"I06.1","desc":"Reumatická aortálna insuficiencia"},
{"code":"I06.2","desc":"Reumatická aortálna stenóza s insuficienciou"},
{"code":"I06.8","desc":"Iná reumatická choroba aortálnej chlopne"},
{"code":"I06.9","desc":"Reumatická choroba aortálnej chlopne, bližšie neurčená"},
{"code":"I07.-","desc":"Reumatická choroba trikuspidálnej chlopne"},
{"code":"I07.0","desc":"Trikuspidálna stenóza"},
{"code":"I07.1","desc":"Trikuspidálna insuficiencia"},
{"code":"I07.2","desc":"Trikuspidálna stenóza s insuficienciou"},
{"code":"I07.8","desc":"Iná choroba trikuspidálnej chlopne"},
{"code":"I07.9","desc":"Choroba trikuspidálnej chlopne, bližšie neurčená"},
{"code":"I08.-","desc":"Choroba viacerých chlopní"},
{"code":"I08.0","desc":"Kombinovaná choroba mitrálnej a aortálnej chlopne"},
{"code":"I08.1","desc":"Kombinovaná choroba mitrálnej a trikuspidálnej chlopne"},
{"code":"I08.2","desc":"Kombinovaná choroba aortálnej a trikuspidálnej chlopne"},
{"code":"I08.3","desc":"Kombinovaná choroba mitrálnej, aortálnej a trikuspidálnej chlopne"},
{"code":"I08.8","desc":"Iná choroba viacerých chlopní"},
{"code":"I08.9","desc":"Choroba viacerých chlopní, bližšie neurčená"},
{"code":"I09.-","desc":"Iná reumatická choroba srdca"},
{"code":"I09.0","desc":"Reumatická myokarditída"},
{"code":"I09.1","desc":"Reumatická choroba endokardu bližšie neurčenej chlopne"},
{"code":"I09.2","desc":"Chronická reumatická perikarditída"},
{"code":"I09.8","desc":"Iná reumatická choroba srdca, bližšie určená"},
{"code":"I09.9","desc":"Reumatická choroba srdca, bližšie neurčená"},
{"code":"I10-I15","desc":"Artériová hypertenzia"},
{"code":"I10.-","desc":"Primárna [esenciálna] artériová hypertenzia"},
{"code":"I10.0-","desc":"Benígna primárna artériová hypertenzia"},
{"code":"I10.00","desc":"Benígna primárna artériová hypertenzia, bez hypertenznej krízy"},
{"code":"I10.01","desc":"Benígna primárna artériová hypertenzia, s hypertenznou krízou"},
{"code":"I10.1-","desc":"Malígna primárna artériová hypertenzia"},
{"code":"I10.10","desc":"Malígna primárna artériová hypertenzia, bez hypertenznej krízy"},
{"code":"I10.11","desc":"Malígna primárna artériová hypertenzia, s hypertenznou krízou"},
{"code":"I10.9-","desc":"Primárna artériová hypertenzia, bližšie neurčená"},
{"code":"I10.90","desc":"Primárna artériová hypertenzia, bližšie neurčená, bez hypertenznej krízy"},
{"code":"I10.91","desc":"Primárna artériová hypertenzia, bližšie neurčená, s hypertenznou krízou"},
{"code":"I11.-","desc":"Hypertenzná choroba srdca"},
{"code":"I11.0-","desc":"Hypertenzná choroba srdca s kongestívnym srdcovým zlyhávaním"},
{"code":"I11.00","desc":"Hypertenzná choroba srdca s kongestívnym srdcovým zlyhávaním, bez hypertenznej krízy"},
{"code":"I11.01","desc":"Hypertenzná choroba srdca s kongestívnym srdcovým zlyhávaním, s hypertenznou krízou"},
{"code":"I11.9-","desc":"Hypertenzná choroba srdca bez kongestívneho srdcového zlyhávania"},
{"code":"I11.90","desc":"Hypertenzná choroba srdca bez kongestívneho srdcového zlyhávania, bez hypertenznej krízy"},
{"code":"I11.91","desc":"Hypertenzná choroba srdca bez kongestívneho srdcového zlyhávania, s hypertenznou krízou"},
{"code":"I12.-","desc":"Hypertenzná choroba obličiek"},
{"code":"I12.0-","desc":"Hypertenzná choroba obličiek s obličkovým zlyhávaním"},
{"code":"I12.00","desc":"Hypertenzná choroba obličiek s obličkovým zlyhávaním, bez hypertenznej krízy"},
{"code":"I12.01","desc":"Hypertenzná choroba obličiek s obličkovým zlyhávaním, s hypertenznou krízou"},
{"code":"I12.9-","desc":"Hypertenzná choroba obličiek bez obličkového zlyhávania"},
{"code":"I12.90","desc":"Hypertenzná choroba obličiek bez obličkového zlyhávania, bez hypertenznej krízy"},
{"code":"I12.91","desc":"Hypertenzná choroba obličiek bez obličkového zlyhávania, s hypertenznou krízou"},
{"code":"I13.-","desc":"Hypertenzná choroba srdca a obličiek"},
{"code":"I13.0-","desc":"Hypertenzná choroba srdca a obličiek s kongestívnym srdcovým zlyhávaním"},
{"code":"I13.00","desc":"Hypertenzná choroba srdca a obličiek s kongestívnym srdcovým zlyhávaním, bez hypertenznej krízy"},
{"code":"I13.01","desc":"Hypertenzná choroba srdca a obličiek s kongestívnym srdcovým zlyhávaním, s hypertenznou krízou"},
{"code":"I13.1-","desc":"Hypertenzná choroba srdca a obličiek s obličkovým zlyhávaním"},
{"code":"I13.10","desc":"Hypertenzná choroba srdca a obličiek s obličkovým zlyhávaním, bez hypertenznej krízy"},
{"code":"I13.11","desc":"Hypertenzná choroba srdca a obličiek s obličkovým zlyhávaním, s hypertenznou krízou"},
{"code":"I13.2-","desc":"Hypertenzná choroba srdca a obličiek s kongestívnym srdcovým zlyhávaním aj s obličkovým zlyhávaním"},
{"code":"I13.20","desc":"Hypertenzná choroba srdca a obličiek s kongestívnym srdcovým zlyhávaním aj s obličkovým zlyhávaním, bez hypertenznej krízy"},
{"code":"I13.21","desc":"Hypertenzná choroba srdca a obličiek s kongestívnym srdcovým zlyhávaním aj s obličkovým zlyhávaním, s hypertenznou krízou"},
{"code":"I13.9-","desc":"Hypertenzná choroba srdca a obličiek, bližšie neurčená"},
{"code":"I13.90","desc":"Hypertenzná choroba srdca a obličiek bez hypertenznej krízy, bližšie neurčená"},
{"code":"I13.91","desc":"Hypertenzná choroba srdca a obličiek, bližšie neurčená, s hypertenznou krízou"},
{"code":"I15.-","desc":"Sekundárna artériová hypertenzia"},
{"code":"I15.0-","desc":"Renovaskulárna artériová hypertenzia"},
{"code":"I15.00","desc":"Renovaskulárna artériová hypertenzia, bez hypertenznej krízy"},
{"code":"I15.01","desc":"Renovaskulárna artériová hypertenzia, s hypertenznou krízou"},
{"code":"I15.1-","desc":"Sekundárna artériová hypertenzia zapríčinená inými chorobami obličiek"},
{"code":"I15.10","desc":"Sekundárna artériová hypertenzia zapríčinená inými chorobami obličiek, bez hypertenznej krízy"},
{"code":"I15.11","desc":"Sekundárna artériová hypertenzia zapríčinená inými chorobami obličiek, s hypertenznou krízou"},
{"code":"I15.2-","desc":"Sekundárna artériová hypertenzia zapríčinená endokrinnými chorobami"},
{"code":"I15.20","desc":"Sekundárna artériová hypertenzia zapríčinená endokrinnými chorobami, bez hypertenznej krízy"},
{"code":"I15.21","desc":"Sekundárna artériová hypertenzia zapríčinená endokrinnými chorobami, s hypertenznou krízou"},
{"code":"I15.8-","desc":"Iná sekundárna artériová hypertenzia"},
{"code":"I15.80","desc":"Iná sekundárna artériová hypertenzia, bez hypertenznej krízy"},
{"code":"I15.81","desc":"Iná sekundárna artériová hypertenzia, s hypertenznou krízou"},
{"code":"I15.9-","desc":"Sekundárna artériová hypertenzia, bližšie neurčená"},
{"code":"I15.90","desc":"Sekundárna artériová hypertenzia, bližšie neurčená, bez hypertenznej krízy"},
{"code":"I15.91","desc":"Sekundárna artériová hypertenzia, bližšie neurčená, s hypertenznou krízou"},
{"code":"I20-I25","desc":"Ischemická choroba srdca"},
{"code":"I20.-","desc":"Angina pectoris"},
{"code":"I20.0","desc":"Nestabilná angina pectoris"},
{"code":"I20.1","desc":"Angina pectoris s dokázaným spazmom vencovitých tepien"},
{"code":"I20.8","desc":"Iná forma angina pectoris"},
{"code":"I20.9","desc":"Angina pectoris, bližšie neurčená"},
{"code":"I21.-","desc":"Akútny infarkt myokardu"},
{"code":"I21.0","desc":"Akútny transmurálny infarkt myokardu prednej steny"},
{"code":"I21.1","desc":"Akútny transmurálny infarkt myokardu spodnej steny"},
{"code":"I21.2","desc":"Akútny transmurálny infarkt myokardu na iných miestach"},
{"code":"I21.3","desc":"Akútny transmurálny infarkt myokardu bez bližšieho určenia miesta"},
{"code":"I21.4","desc":"Akútny subendokardiálny infarkt myokardu"},
{"code":"I21.9","desc":"Akútny infarkt myokardu, bližšie neurčený"},
{"code":"I22.-","desc":"Ďalší infarkt myokardu"},
{"code":"I22.0","desc":"Ďalší infarkt myokardu prednej steny"},
{"code":"I22.1","desc":"Ďalší infarkt myokardu spodnej steny"},
{"code":"I22.8","desc":"Ďalší infarkt myokardu na inom mieste"},
{"code":"I22.9","desc":"Ďalší infarkt myokardu bez bližšieho určenia miesta"},
{"code":"I23.-","desc":"Určité akútne komplikácie po akútnom infarkte myokardu"},
{"code":"I23.0","desc":"Hemoperikard ako akútna komplikácia po akútnom infarkte myokardu"},
{"code":"I23.1","desc":"Defekt predsieňového septa ako akútna komplikácia po akútnom infarkte myokardu"},
{"code":"I23.2","desc":"Defekt komorovej priehradky ako akútna komplikácia po akútnom infarkte myokardu"},
{"code":"I23.3","desc":"Ruptúra srdcovej steny bez hemoperikardu ako akútna komplikácia po akútnom infarkte myokardu"},
{"code":"I23.4","desc":"Ruptúra chordae tendineae ako akútna komplikácia po akútnom infarkte myokardu"},
{"code":"I23.5","desc":"Ruptúra papilárneho svalu ako akútna komplikácia po akútnom infarkte myokardu"},
{"code":"I23.6","desc":"Trombóza predsiene, predsieňového uška a komory ako akútna komplikácia po akútnom infarkte myokardu"},
{"code":"I23.8","desc":"Iná akútna komplikácia po akútnom infarkte myokardu"},
{"code":"I24.-","desc":"Iná akútna forma ischemickej choroby srdca"},
{"code":"I24.0","desc":"Koronárna trombóza, ktorá neviedla k infarktu myokardu"},
{"code":"I24.1","desc":"Poinfarktový syndróm"},
{"code":"I24.8","desc":"Iná forma akútnej ischemickej choroby srdca"},
{"code":"I24.9","desc":"Akútna ischemická choroba srdca, bližšie neurčená"},
{"code":"I25.-","desc":"Chronická ischemická choroba srdca"},
{"code":"I25.0","desc":"Aterosklerotická srdcovocievna choroba, takto označená"},
{"code":"I25.1-","desc":"Aterosklerotická choroba srdca"},
{"code":"I25.10","desc":"Aterosklerotická choroba srdca, bez hemodynamicky závažných stenóz"},
{"code":"I25.11","desc":"Aterosklerotická choroba srdca, jednocievne postihnutie"},
{"code":"I25.12","desc":"Aterosklerotická choroba srdca, dvojcievne postihnutie"},
{"code":"I25.13","desc":"Aterosklerotická choroba srdca, trojcievne postihnutie"},
{"code":"I25.14","desc":"Aterosklerotická choroba srdca, stenóza ľavého hlavného kmeňa"},
{"code":"I25.15","desc":"Aterosklerotická choroba srdca so stenózou bajpasu"},
{"code":"I25.16","desc":"Aterosklerotická choroba srdca so stenózou stentu"},
{"code":"I25.19","desc":"Aterosklerotická choroba srdca, bližšie neurčená"},
{"code":"I25.2-","desc":"Starý infarkt myokardu"},
{"code":"I25.20","desc":"Starý infarkt myokardu, od 29 dní až po 4 mesiace"},
{"code":"I25.21","desc":"Starý infarkt myokardu, od 4 mesiacov až po 1 rok"},
{"code":"I25.22","desc":"Starý infarkt myokardu, od 1 roka vyššie"},
{"code":"I25.29","desc":"Starý infarkt myokardu, bližšie neurčený"},
{"code":"I25.3","desc":"Aneuryzma (steny) srdca"},
{"code":"I25.4","desc":"Aneuryzma vencovitej tepny"},
{"code":"I25.5","desc":"Ischemická kardiomyopatia"},
{"code":"I25.6","desc":"Nebolestivá (tichá) ischémia myokardu"},
{"code":"I25.8","desc":"Iná forma chronickej ischemickej choroby srdca"},
{"code":"I25.9","desc":"Chronická ischemická choroba srdca, bližšie neurčená"},
{"code":"I26-I28","desc":"Srdcovopľúcne choroby a choroby pľúcneho obehu"},
{"code":"I26.-","desc":"Pľúcna embólia"},
{"code":"I26.0","desc":"Pľúcna embólia s prejavmi akútneho cor pulmonale"},
{"code":"I26.7","desc":"Pľúcna embólia, splnené indikačné kritériá pre špecifickú výšku úhrady antikoagulačnej liečby"},
{"code":"I26.9","desc":"Pľúcna embólia bez prejavov akútneho cor pulmonale"},
{"code":"I27.-","desc":"Iné srdcovopľúcne choroby"},
{"code":"I27.0","desc":"Primárna pľúcna hypertenzia"},
{"code":"I27.1","desc":"Srdcová choroba pri kyfoskolióze"},
{"code":"I27.2-","desc":"Iná sekundárna pľúcna hypertenzia"},
{"code":"I27.20","desc":"Pľúcna hypertenzia pri chronickej tromboembolickej chorobe"},
{"code":"I27.28","desc":"Iná bližšie určená sekundárna pľúcna hypertenzia"},
{"code":"I27.8","desc":"Iné srdcovopľúcne choroby, bližšie určené"},
{"code":"I27.9","desc":"Srdcovopľúcna choroba, bližšie neurčená"},
{"code":"I28.-","desc":"Iná choroba pľúcnych ciev"},
{"code":"I28.0","desc":"Tepnovožilová fistula pľúcnych ciev"},
{"code":"I28.1","desc":"Aneuryzma pľúcnej tepny"},
{"code":"I28.8","desc":"Iná choroba pľúcnych ciev, bližšie určená"},
{"code":"I28.9","desc":"Choroba pľúcnych ciev, bližšie neurčená"},
{"code":"I30-I52","desc":"Iné choroby srdca"},
{"code":"I30.-","desc":"Akútna perikarditída"},
{"code":"I30.0","desc":"Akútna nešpecifická idiopatická perikarditída"},
{"code":"I30.1","desc":"Infekčná perikarditída"},
{"code":"I30.8","desc":"Iná forma akútnej perikarditídy"},
{"code":"I30.9","desc":"Akútna perikarditída, bližšie neurčená"},
{"code":"I31.-","desc":"Iné choroby osrdcovníka"},
{"code":"I31.0","desc":"Chronická adhezívna perikarditída"},
{"code":"I31.1","desc":"Chronická konstriktívna perikarditída"},
{"code":"I31.2","desc":"Hemoperikard, nezatriedený inde"},
{"code":"I31.3","desc":"Perikardový výpotok (nezápalový)"},
{"code":"I31.8","desc":"Iná choroba perikardu, bližšie určená"},
{"code":"I31.9","desc":"Choroba perikardu, bližšie neurčená"},
{"code":"I32.-*","desc":"Perikarditída pri chorobách zatriedených inde"},
{"code":"I32.0*","desc":"Perikarditída pri baktériových chorobách zatriedených inde"},
{"code":"I32.1*","desc":"Perikarditída pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"I32.8*","desc":"Perikarditída pri iných chorobách zatriedených inde"},
{"code":"I33.-","desc":"Akútna a subakútna endokarditída"},
{"code":"I33.0","desc":"Akútna a subakútna infekčná endokarditída"},
{"code":"I33.9","desc":"Akútna endokarditída, bližšie neurčená"},
{"code":"I34.-","desc":"Nereumatická choroba mitrálnej chlopne"},
{"code":"I34.0","desc":"Mitrálna insuficiencia"},
{"code":"I34.1","desc":"Prolaps mitrálnej chlopne"},
{"code":"I34.2","desc":"Nereumatická mitrálna stenóza"},
{"code":"I34.8-","desc":"Iná nereumatická choroba mitrálnej chlopne"},
{"code":"I34.80","desc":"Nereumatická mitrálna stenóza a insuficiencia"},
{"code":"I34.88","desc":"Iná nereumatická choroba mitrálnej chlopne"},
{"code":"I34.9","desc":"Nereumatická choroba mitrálnej chlopne, bližšie neurčená"},
{"code":"I35.-","desc":"Nereumatická choroba aortálnej chlopne"},
{"code":"I35.0","desc":"Aortálna stenóza"},
{"code":"I35.1","desc":"Aortálna insuficiencia"},
{"code":"I35.2","desc":"Aortálna stenóza s aortálnou insuficienciou"},
{"code":"I35.8","desc":"Iná choroba aortálnej chlopne"},
{"code":"I35.9","desc":"Choroba aortálnej chlopne, bližšie neurčená"},
{"code":"I36.-","desc":"Nereumatické choroby trikuspidálnej chlopne"},
{"code":"I36.0","desc":"Nereumatická trikuspidálna stenóza"},
{"code":"I36.1","desc":"Nereumatická trikuspidálna insuficiencia"},
{"code":"I36.2","desc":"Nereumatická trikuspidálna stenóza a insuficiencia"},
{"code":"I36.8","desc":"Iná nereumatická choroba trikuspidálnej chlopne"},
{"code":"I36.9","desc":"Nereumatická choroba trikuspidálnej chlopne, bližšie neurčená"},
{"code":"I37.-","desc":"Choroba pulmonálnej chlopne"},
{"code":"I37.0","desc":"Pulmonálna stenóza"},
{"code":"I37.1","desc":"Pulmonálna insuficiencia"},
{"code":"I37.2","desc":"Pulmonálna stenóza a insuficiencia"},
{"code":"I37.8","desc":"Iná choroba pulmonálnej chlopne"},
{"code":"I37.9","desc":"Choroba pulmonálnej chlopne, bližšie neurčená"},
{"code":"I38","desc":"Endokarditída bližšie neurčenej chlopne"},
{"code":"I39.-*","desc":"Endokarditída a chlopňová chyba pri chorobách zatriedených inde"},
{"code":"I39.0*","desc":"Choroba mitrálnej chlopne pri chorobách zatriedených inde"},
{"code":"I39.1*","desc":"Choroba aortálnej chlopne pri chorobách zatriedených inde"},
{"code":"I39.2*","desc":"Choroba trikuspidálnej chlopne pri chorobách zatriedených inde"},
{"code":"I39.3*","desc":"Choroba pulmonálnej chlopne pri chorobách zatriedených inde"},
{"code":"I39.4*","desc":"Choroba viacerých chlopní pri chorobách zatriedených inde"},
{"code":"I39.8*","desc":"Endokarditída neurčenej chlopne pri chorobách zatriedených inde"},
{"code":"I40.-","desc":"Akútna myokarditída"},
{"code":"I40.0","desc":"Infekčná myokarditída"},
{"code":"I40.1","desc":"Izolovaná myokarditída"},
{"code":"I40.8","desc":"Iná akútna myokarditída"},
{"code":"I40.9","desc":"Akútna myokarditída, bližšie neurčená"},
{"code":"I41.-*","desc":"Myokarditída pri chorobách zatriedených inde"},
{"code":"I41.0*","desc":"Myokarditída pri baktériových chorobách zatriedených inde"},
{"code":"I41.1*","desc":"Myokarditída pri vírusových chorobách zatriedených inde"},
{"code":"I41.2*","desc":"Myokarditída pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"I41.8*","desc":"Myokarditída pri iných chorobách zatriedených inde"},
{"code":"I42.-","desc":"Kardiomyopatia"},
{"code":"I42.0","desc":"Dilatačná kardiomyopatia"},
{"code":"I42.1","desc":"Obštrukčná hypertrofická kardiomyopatia"},
{"code":"I42.2","desc":"Iná hypertrofická kardiomyopatia"},
{"code":"I42.3","desc":"Endomyokardová (eozinofilná) choroba"},
{"code":"I42.4","desc":"Endokardová fibroelastóza"},
{"code":"I42.5","desc":"Iná reštrikčná kardiomyopatia"},
{"code":"I42.6","desc":"Alkoholová kardiomyopatia"},
{"code":"I42.7","desc":"Kardiomyopatia zapríčinená liekmi a inými vonkajšími faktormi"},
{"code":"I42.8-","desc":"Iné kardiomyopatie"},
{"code":"I42.80","desc":"Arytmogénna pravokomorová kardiomyopatia [ARVCM]"},
{"code":"I42.88","desc":"Iná kardiomyopatia"},
{"code":"I42.9","desc":"Kardiomyopatia, bližšie neurčená"},
{"code":"I43.-*","desc":"Kardiomyopatia pri chorobách zatriedených inde"},
{"code":"I43.0*","desc":"Kardiomyopatia pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"I43.1*","desc":"Kardiomyopatia pri metabolických chorobách"},
{"code":"I43.2*","desc":"Kardiomyopatia pri poruchách výživy"},
{"code":"I43.8*","desc":"Kardiomyopatia pri iných chorobách zatriedených inde"},
{"code":"I44.-","desc":"Atrioventrikulárna blokáda a blokáda ľavého ramienka"},
{"code":"I44.0","desc":"Atrioventrikulárna blokáda 1. stupňa"},
{"code":"I44.1","desc":"Atrioventrikulárna blokáda 2. stupňa"},
{"code":"I44.2","desc":"Atrioventrikulárna blokáda 3. stupňa"},
{"code":"I44.3","desc":"Iná a bližšie neurčená atrioventrikulárna blokáda"},
{"code":"I44.4","desc":"Ľavá predná fascikulárna blokáda"},
{"code":"I44.5","desc":"Ľavá zadná fascikulárna blokáda"},
{"code":"I44.6","desc":"Iná a bližšie neurčená fascikulárna blokáda"},
{"code":"I44.7","desc":"Blokáda ľavého ramienka, bližšie neurčená"},
{"code":"I45.-","desc":"Iná porucha vedenia srdcových vzruchov"},
{"code":"I45.0","desc":"Pravá fascikulárna blokáda"},
{"code":"I45.1","desc":"Iná a bližšie neurčená blokáda pravého ramienka"},
{"code":"I45.2","desc":"Bifascikulárna blokáda"},
{"code":"I45.3","desc":"Trifascikulárna blokáda"},
{"code":"I45.4","desc":"Nešpecifická intraventrikulárna blokáda"},
{"code":"I45.5","desc":"Iná bližšie určená srdcová blokáda"},
{"code":"I45.6","desc":"Syndróm preexcitácie"},
{"code":"I45.8","desc":"Iné poruchy vedenia srdcových vzruchov, bližšie určené"},
{"code":"I45.9","desc":"Porucha vedenia srdcových vzruchov, bližšie neurčená"},
{"code":"I46.-","desc":"Zastavenie srdca"},
{"code":"I46.0","desc":"Zastavenie srdca s úspešnou resuscitáciou"},
{"code":"I46.1","desc":"Náhla srdcová smrť, takto opísaná"},
{"code":"I46.8","desc":"Zlyhanie srdca"},
{"code":"I46.9","desc":"Zastavenie srdca, bližšie neurčené"},
{"code":"I47.-","desc":"Paroxyzmálna tachykardia"},
{"code":"I47.0","desc":"Komorová reentry-arytmia"},
{"code":"I47.1","desc":"Supraventrikulárna tachykardia"},
{"code":"I47.2","desc":"Komorová tachykardia"},
{"code":"I47.9","desc":"Paroxyzmálna tachykardia, bližšie neurčená"},
{"code":"I48.-","desc":"Fibrilácia predsiení a flater predsiení"},
{"code":"I48.0","desc":"Fibrilácia predsiení, paroxyzmálna"},
{"code":"I48.1","desc":"Fibrilácia predsiení, perzistentná"},
{"code":"I48.2","desc":"Fibrilácia predsiení, permanentná"},
{"code":"I48.3","desc":"Flater predsiení, typický"},
{"code":"I48.4","desc":"Flater predsiení, atypický"},
{"code":"I48.7","desc":"Fibrilácia alebo flutter predsiení, splnené indikačné kritériá pre špecifickú výšku úhrady antikoagulačnej liečby"},
{"code":"I48.9","desc":"Fibrilácia a flater predsiení, bližšie neurčené"},
{"code":"I49.-","desc":"Iné srdcové arytmia"},
{"code":"I49.0","desc":"Flater komôr a fibrilácia komôr"},
{"code":"I49.1","desc":"Predsieňová extrasystólia"},
{"code":"I49.2","desc":"Atrioventrikulárna junkčná extrasystólia"},
{"code":"I49.3","desc":"Komorová extrasystólia"},
{"code":"I49.4","desc":"Iná a bližšie neurčená extrasystólia"},
{"code":"I49.5","desc":"Syndróm chorého sínusu"},
{"code":"I49.8","desc":"Iná srdcová arytmia, bližšie určená"},
{"code":"I49.9","desc":"Srdcová arytmia, bližšie neurčená"},
{"code":"I50.-","desc":"Zlyhávanie srdca"},
{"code":"I50.0-","desc":"Zlyhávanie pravej komory"},
{"code":"I50.00","desc":"Primárne zlyhávanie pravej komory"},
{"code":"I50.01","desc":"Sekundárne zlyhávanie pravej komory"},
{"code":"I50.1-","desc":"Zlyhávanie ľavej komory"},
{"code":"I50.11","desc":"Zlyhávanie ľavej komory: bez ťažkostí"},
{"code":"I50.12","desc":"Zlyhávanie ľavej komory, s ťažkosťami pri veľkom zaťažení"},
{"code":"I50.13","desc":"Zlyhávanie ľavej komory, s ťažkosťami pri malom zaťažení"},
{"code":"I50.14","desc":"Zlyhávanie ľavej komory, s ťažkosťami v pokoji"},
{"code":"I50.19","desc":"Zlyhávanie ľavej komory, bližšie neurčené"},
{"code":"I50.9","desc":"Zlyhávanie srdca, bližšie neurčené"},
{"code":"I51.-","desc":"Komplikácie choroby srdca a nepresne opísaná choroba srdca"},
{"code":"I51.0","desc":"Získaný defekt srdcovej priehradky"},
{"code":"I51.1","desc":"Ruptúra chordae tendineae, nezatriedená inde"},
{"code":"I51.2","desc":"Ruptúra papilárneho svalu, nezatriedená inde"},
{"code":"I51.3","desc":"Vnútrosrdcová trombóza, nezatriedená inde"},
{"code":"I51.4","desc":"Myokarditída, bližšie neurčená"},
{"code":"I51.5","desc":"Degenerácia myokardu"},
{"code":"I51.6","desc":"Srdcovocievna choroba, bližšie neurčená"},
{"code":"I51.7","desc":"Kardiomegália"},
{"code":"I51.8","desc":"Iná choroba srdca, nepresne určená"},
{"code":"I51.9","desc":"Choroba srdca, bližšie neurčená"},
{"code":"I52.-*","desc":"Iná choroba srdca pri chorobách zatriedených inde"},
{"code":"I52.0*","desc":"Iná choroba srdca pri baktériových chorobách zatriedených inde"},
{"code":"I52.1*","desc":"Iná choroba srdca pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"I52.8*","desc":"Iná choroba srdca pri iných chorobách zatriedených inde"},
{"code":"I60-I69","desc":"Cievne choroby mozgu"},
{"code":"I60.-","desc":"Subarachnoidálne krvácanie"},
{"code":"I60.0","desc":"Subarachnoidálne krvácanie z karotického sifónu a bifurkácie"},
{"code":"I60.1","desc":"Subarachnoidálne krvácanie z a.cerebri media"},
{"code":"I60.2","desc":"Subarachnoidálne krvácanie z a.communicans anterior"},
{"code":"I60.3","desc":"Subarachnoidálne krvácanie z a.communicans posterior"},
{"code":"I60.4","desc":"Subarachnoidálne krvácanie z a.basilaris"},
{"code":"I60.5","desc":"Subarachnoidálne krvácanie z a.vertebralis"},
{"code":"I60.6","desc":"Subarachnoidálne krvácanie z iných intrakraniálnych tepien"},
{"code":"I60.7","desc":"Subarachnoidálne krvácanie z bližšie neurčenej intrakraniálnej tepny"},
{"code":"I60.8","desc":"Iné subarachnoidálne krvácanie"},
{"code":"I60.9","desc":"Subarachnoidálne krvácanie, bližšie neurčené"},
{"code":"I61.-","desc":"Vnútromozgové krvácanie"},
{"code":"I61.0","desc":"Vnútromozgové krvácanie do mozgovej hemisféry, subkortikálne"},
{"code":"I61.1","desc":"Vnútromozgové krvácanie do hemisféry, kortikálne"},
{"code":"I61.2","desc":"Vnútromozgové krvácanie do hemisféry, bližšie neurčené"},
{"code":"I61.3","desc":"Vnútromozgové krvácanie do mozgového kmeňa"},
{"code":"I61.4","desc":"Vnútromozgové krvácanie do mozočka (cerebela)"},
{"code":"I61.5","desc":"Vnútromozgové vnútrokomorové (intraventrikulárne) krvácanie"},
{"code":"I61.6","desc":"Vnútromozgové krvácanie na viacerých miestach"},
{"code":"I61.8","desc":"Iné vnútromozgové krvácanie"},
{"code":"I61.9","desc":"Vnútromozgové krvácanie, bližšie neurčené"},
{"code":"I62.-","desc":"Iné neúrazové intrakraniálne krvácanie"},
{"code":"I62.0-","desc":"Subdurálne krvácanie (neúrazové)"},
{"code":"I62.00","desc":"Subdurálne krvácanie (neúrazové), akútne"},
{"code":"I62.01","desc":"Subdurálne krvácanie (neúrazové), subakútne"},
{"code":"I62.02","desc":"Subdurálne krvácanie (neúrazové), chronické"},
{"code":"I62.09","desc":"Subdurálne krvácanie (neúrazové), bližšie neurčené"},
{"code":"I62.1","desc":"Neúrazové extradurálne krvácanie"},
{"code":"I62.9","desc":"Intrakraniálne krvácanie (neúrazové), bližšie neurčené"},
{"code":"I63.-","desc":"Mozgový infarkt"},
{"code":"I63.0","desc":"Mozgový infarkt, zapríčinený trombózou prívodných mozgových tepien"},
{"code":"I63.1","desc":"Mozgový infarkt, zapríčinený embóliou prívodných mozgových tepien"},
{"code":"I63.2","desc":"Mozgový infarkt, zapríčinený bližšie neurčeným uzáverom alebo zúžením prívodných mozgových tepien"},
{"code":"I63.3","desc":"Mozgový infarkt, zapríčinený trombózou mozgových tepien"},
{"code":"I63.4","desc":"Mozgový infarkt, zapríčinený embóliou mozgových tepien"},
{"code":"I63.5","desc":"Mozgový infarkt, zapríčinený bližšie neurčeným uzáverom alebo zúžením mozgových tepien"},
{"code":"I63.6","desc":"Mozgový infarkt, zapríčinený nehnisovou mozgovou žilovou trombózou"},
{"code":"I63.8","desc":"Iný mozgový infarkt"},
{"code":"I63.9","desc":"Mozgový infarkt, bližšie neurčený"},
{"code":"I64","desc":"Cievna mozgová príhoda bližšie neurčená ako krvácanie alebo infarkt"},
{"code":"I65.-","desc":"Uzáver a zúženie prívodných mozgových tepien, ktoré neviedli k mozgovému infarktu"},
{"code":"I65.0","desc":"Uzáver a zúženie a. vertebralis"},
{"code":"I65.1","desc":"Uzáver a zúženie a. basilaris"},
{"code":"I65.2","desc":"Uzáver a zúženie a. carotis"},
{"code":"I65.3","desc":"Uzáver a zúženie viacerých prívodných mozgových tepien, obojstranne"},
{"code":"I65.8","desc":"Uzáver a zúženie iných prívodných mozgových tepien"},
{"code":"I65.9","desc":"Uzáver a zúženie bližšie neurčenej prívodnej mozgovej tepny"},
{"code":"I66.-","desc":"Uzáver a zúženie mozgových tepien, ktoré neviedli k mozgovému infarktu"},
{"code":"I66.0","desc":"Uzáver a zúženie a.cerebri media"},
{"code":"I66.1","desc":"Uzáver a zúženie a.cerebri anterior"},
{"code":"I66.2","desc":"Uzáver a zúženie a.cerebri posterior"},
{"code":"I66.3","desc":"Uzáver a zúženie mozočkových tepien"},
{"code":"I66.4","desc":"Uzáver a zúženie viacerých mozgových tepien, obojstranne"},
{"code":"I66.8","desc":"Uzáver a zúženie inej mozgovej tepny"},
{"code":"I66.9","desc":"Uzáver a zúženie bližšie neurčenej mozgovej tepny"},
{"code":"I67.-","desc":"Iná cievna choroba mozgu"},
{"code":"I67.0","desc":"Disekcia mozgovej tepny"},
{"code":"I67.1-","desc":"Mozgová aneuryzma a mozgová artériovenózna fistula bez ruptúry"},
{"code":"I67.10","desc":"Získaná mozgová aneuryzma"},
{"code":"I67.11","desc":"Získaná mozgová artériovenózna fistula"},
{"code":"I67.2","desc":"Mozgová ateroskleróza"},
{"code":"I67.3","desc":"Progresívna subkortikálna cievna encefalopatia"},
{"code":"I67.4","desc":"Hypertenzná encefalopatia"},
{"code":"I67.5","desc":"Syndróm moyamoya"},
{"code":"I67.6","desc":"Nehnisová trombóza intrakraniálneho žilového systému"},
{"code":"I67.7","desc":"Mozgová arteritída, nezatriedená inde"},
{"code":"I67.8-","desc":"Iná cievna choroba mozgu, bližšie určená"},
{"code":"I67.80!","desc":"Vazospazmy pri subarachnoidálnom krvácaní"},
{"code":"I67.88","desc":"Iná cievna choroba mozgu, bližšie určená"},
{"code":"I67.9","desc":"Cievna choroba mozgu, bližšie neurčená"},
{"code":"I68.-*","desc":"Cievna choroba mozgu pri chorobách zatriedených inde"},
{"code":"I68.0*","desc":"Mozgová amyloidová angiopatia"},
{"code":"I68.1*","desc":"Mozgová arteritída pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"I68.2*","desc":"Mozgová arteritída pri iných chorobách zatriedených inde"},
{"code":"I68.8*","desc":"Iná cievna choroba mozgu pri chorobách zatriedených inde"},
{"code":"I69.-","desc":"Následky cievnych chorôb mozgu"},
{"code":"I69.0","desc":"Následky subarachnoidálneho krvácania"},
{"code":"I69.1","desc":"Následky vnútromozgového krvácania"},
{"code":"I69.2","desc":"Následky iného neúrazového intrakraniálneho krvácania"},
{"code":"I69.3","desc":"Následky mozgového infarktu"},
{"code":"I69.4","desc":"Následky cievnej mozgovej príhody bližšie neurčenej ako krvácanie alebo infarkt"},
{"code":"I69.8","desc":"Následky iných a bližšie neurčených cievnych chorôb mozgu"},
{"code":"I70-I79","desc":"Choroby tepien, tepničiek a vlásočníc"},
{"code":"I70.-","desc":"Ateroskleróza"},
{"code":"I70.0","desc":"Ateroskleróza aorty"},
{"code":"I70.1","desc":"Ateroskleróza obličkovej tepny"},
{"code":"I70.2-","desc":"Ateroskleróza končatinových tepien"},
{"code":"I70.20","desc":"Ateroskleróza iných a bližšie neurčených končatinových tepien"},
{"code":"I70.21","desc":"Ateroskleróza končatinových tepien s postihnutím panvy a dolných končatín, s ischemickou bolesťou po záťaži"},
{"code":"I70.22","desc":"Ateroskleróza končatinových tepien s postihnutím panvy a dolných končatín, s bolesťou v pokoji"},
{"code":"I70.23","desc":"Ateroskleróza končatinových tepien s postihnutím panvy a dolných končatín, s ulceráciami"},
{"code":"I70.24","desc":"Ateroskleróza končatinových tepien s postihnutím panvy a dolných končatín, s gangrénou"},
{"code":"I70.25","desc":"Ateroskleróza končatinových tepien s postihnutím pleca a horných končatín, všetky štádiá"},
{"code":"I70.8","desc":"Ateroskleróza iných tepien"},
{"code":"I70.9","desc":"Generalizovaná a bližšie neurčená ateroskleróza"},
{"code":"I71.-","desc":"Aneuryzma a disekcia aorty"},
{"code":"I71.0-","desc":"Disekcia aorty"},
{"code":"I71.00","desc":"Disekcia aorty bez bližšieho určenia miesta, bez prejavov ruptúry"},
{"code":"I71.01","desc":"Disekcia hrudníkovej aorty, bez prejavov ruptúry"},
{"code":"I71.02","desc":"Disekcia brušnej aorty, bez prejavov ruptúry"},
{"code":"I71.03","desc":"Disekcia hrudníkovo-brušnej aorty, bez prejavov ruptúry"},
{"code":"I71.04","desc":"Disekcia aorty bez bližšieho určenia miesta, s ruptúrou"},
{"code":"I71.05","desc":"Disekcia hrudníkovej aorty, s ruptúrou"},
{"code":"I71.06","desc":"Disekcia brušnej aorty, s ruptúrou"},
{"code":"I71.07","desc":"Disekcia hrudníkovo-brušnej aorty, s ruptúrou"},
{"code":"I71.1","desc":"Aneuryzma hrudníkovej aorty, s ruptúrou"},
{"code":"I71.2","desc":"Aneuryzma hrudníkovej aorty, bez prejavov ruptúry"},
{"code":"I71.3","desc":"Aneuryzma brušnej aorty, s ruptúrou"},
{"code":"I71.4","desc":"Aneuryzma brušnej aorty, bez prejavov ruptúry"},
{"code":"I71.5","desc":"Aneuryzma hrudníkovo-brušnej aorty, s ruptúrou"},
{"code":"I71.6","desc":"Aneuryzma hrudníkovo-brušnej aorty, bez prejavov ruptúry"},
{"code":"I71.8","desc":"Aneuryzma aorty bez bližšieho určenia miesta, s ruptúrou"},
{"code":"I71.9","desc":"Aneuryzma aorty bez bližšieho určenia miesta, bez prejavov ruptúry"},
{"code":"I72.-","desc":"Iná aneuryzma a disekcia"},
{"code":"I72.0","desc":"Aneuryzma a disekcia krčnej tepny"},
{"code":"I72.1","desc":"Aneuryzma a disekcia tepny hornej končatiny"},
{"code":"I72.2","desc":"Aneuryzma a disekcia obličkovej tepny"},
{"code":"I72.3","desc":"Aneuryzma a disekcia bedrovej tepny"},
{"code":"I72.4","desc":"Aneuryzma a disekcia tepny dolnej končatiny"},
{"code":"I72.5","desc":"Aneuryzma a disekcia iných prívodných mozgových tepien"},
{"code":"I72.6","desc":"Aneuryzma a disekcia vertebrálnej tepny"},
{"code":"I72.8","desc":"Aneuryzma iných tepien, bližšie určených"},
{"code":"I72.9","desc":"Aneuryzma a disekcia tepny bez bližšieho určenia miesta"},
{"code":"I73.-","desc":"Iné choroby periférnych ciev"},
{"code":"I73.0","desc":"Raynaudov syndróm"},
{"code":"I73.1","desc":"Thrombangiitis obliterans [Endangiitis von Winiwarter-Buerger]"},
{"code":"I73.8","desc":"Iné choroby periférnych ciev, bližšie určené"},
{"code":"I73.9","desc":"Choroba periférnych ciev, bližšie neurčená"},
{"code":"I74.-","desc":"Embólia a trombóza tepny"},
{"code":"I74.0","desc":"Embólia a trombóza brušnej aorty"},
{"code":"I74.1","desc":"Embólia a trombóza iných a bližšie neurčených častí aorty"},
{"code":"I74.2","desc":"Embólia a trombóza tepien horných končatín"},
{"code":"I74.3","desc":"Embólia a trombóza tepien dolných končatín"},
{"code":"I74.4","desc":"Embólia a trombóza tepien končatín, bližšie neurčená"},
{"code":"I74.5","desc":"Embólia a trombóza a.iliaca"},
{"code":"I74.8","desc":"Embólia a trombóza iných tepien"},
{"code":"I74.9","desc":"Embólia a trombóza bližšie neurčenej tepny"},
{"code":"I77.-","desc":"Iná choroba tepien a tepničiek"},
{"code":"I77.0","desc":"Získaná artériovenózna fistula"},
{"code":"I77.1","desc":"Striktúra tepny"},
{"code":"I77.2","desc":"Ruptúra tepny"},
{"code":"I77.3","desc":"Fibromuskulárna dysplázia tepien"},
{"code":"I77.4","desc":"Syndróm kompresie a.coeliaca"},
{"code":"I77.5","desc":"Nekróza tepny"},
{"code":"I77.6","desc":"Arteritída, bližšie neurčená"},
{"code":"I77.8","desc":"Iná choroba tepien a tepničiek, bližšie určená"},
{"code":"I77.9","desc":"Choroba tepien a tepničiek, bližšie neurčená"},
{"code":"I78.-","desc":"Choroba vlásočníc"},
{"code":"I78.0","desc":"Hereditárna hemoragická teleangiektázia"},
{"code":"I78.1","desc":"Nenádorový névus"},
{"code":"I78.8","desc":"Iné choroby vlásočníc"},
{"code":"I78.9","desc":"Choroba vlásočníc, bližšie neurčená"},
{"code":"I79.-*","desc":"Choroba tepien, tepničiek a vlásočníc pri chorobách zatriedených inde"},
{"code":"I79.0*","desc":"Aneuryzma aorty pri chorobách zatriedených inde"},
{"code":"I79.1*","desc":"Aortitída pri chorobách zatriedených inde"},
{"code":"I79.2*","desc":"Periférna angiopatia pri chorobách zatriedených inde"},
{"code":"I79.8*","desc":"Iná choroba tepien, tepničiek a vlásočníc pri chorobách zatriedených inde"},
{"code":"I80-I89","desc":"Choroby žíl, lymfatických ciev a lymfatických uzlín, nezatriedené inde"},
{"code":"I80.-","desc":"Trombóza, flebitída a tromboflebitída"},
{"code":"I80.0-","desc":"Trombóza, flebitída a tromboflebitída povrchových žíl dolných končatín"},
{"code":"I80.00","desc":"Hlboká žilová trombóza žíl dolných končatín - vyprovokovaná"},
{"code":"I80.01","desc":"Hlboká žilová trombóza žíl dolných končatín - vyprovokovaná, proximálna (od vena poplitea žily vrátane - nahor)"},
{"code":"I80.02","desc":"Hlboká žilová trombóza žíl dolných končatín - vyprovokovaná, distálna (infrapopliteálne žily, vrátane vnútrosvalových žíl)"},
{"code":"I80.1-","desc":"Trombóza, flebitída a tromboflebitída v. femoralis"},
{"code":"I80.10","desc":"Hlboká žilová trombóza žíl dolných končatín - idiopatická (nevyprovokovaná)"},
{"code":"I80.11","desc":"Hlboká žilová trombóza žíl dolných končatín - idiopatická, proximálna (od vena poplitea vrátane - nahor)"},
{"code":"I80.12","desc":"Hlboká žilová trombóza žíl dolných končatín - idiopatická, distálna (infrapopliteálne žily, vrátane vnútrosvalových žíl)"},
{"code":"I80.2","desc":"Recidivujúca hlboká žilová trombóza žíl dolných končatín"},
{"code":"I80.3","desc":"Hlboká žilová trombóza žíl horných končatín"},
{"code":"I80.4-","desc":"Povrchová flebitída a tromboflebitída dolných končatín"},
{"code":"I80.40","desc":"Povrchová flebitída a tromboflebitída dolných končatín"},
{"code":"I80.41","desc":"Povrchová varikózna tromboflebidíta"},
{"code":"I80.42","desc":"Povrchová nevarikózna tromboflebitída"},
{"code":"I80.7","desc":"Flebitída a tromboflebitída, splnené indikačné kritériá pre špecifickú výšku úhrady antikoagulačnej liečby"},
{"code":"I80.8","desc":"Trombóza, flebitída a tromboflebitída na iných miestach"},
{"code":"I80.9","desc":"Trombóza, flebitída a tromboflebitída bez bližšieho určenia miesta"},
{"code":"I81","desc":"Trombóza v. portae"},
{"code":"I82.-","desc":"Iná žilová embólia a trombóza"},
{"code":"I82.0","desc":"Buddov-Chiariho syndróm"},
{"code":"I82.1","desc":"Migrujúca tromboflebitída"},
{"code":"I82.2","desc":"Embólia a trombóza v.cava"},
{"code":"I82.3","desc":"Embólia a trombóza obličkovej žily"},
{"code":"I82.8","desc":"Embólia a trombóza iných bližšie určených žíl"},
{"code":"I82.9","desc":"Embólia a trombóza bližšie neurčenej žily"},
{"code":"I83.-","desc":"Varixy dolných končatín"},
{"code":"I83.0","desc":"Varixy dolných končatín s vredom"},
{"code":"I83.1","desc":"Varixy dolných končatín so zápalom"},
{"code":"I83.2","desc":"Varixy dolných končatín s vredom a zápalom"},
{"code":"I83.9","desc":"Varixy dolných končatín bez vredu alebo zápalu"},
{"code":"I85.-","desc":"Varixy pažeráka"},
{"code":"I85.0","desc":"Varixy pažeráka s krvácaním"},
{"code":"I85.9","desc":"Varixy pažeráka bez krvácania"},
{"code":"I86.-","desc":"Varixy na iných miestach"},
{"code":"I86.0","desc":"Podjazykové varixy"},
{"code":"I86.1","desc":"Varixy skróta"},
{"code":"I86.2","desc":"Varixy panvy"},
{"code":"I86.3","desc":"Varixy vulvy"},
{"code":"I86.4","desc":"Varixy žalúdka"},
{"code":"I86.8","desc":"Varixy na iných bližšie určených miestach"},
{"code":"I87.-","desc":"Iné choroby žíl"},
{"code":"I87.0-","desc":"Posttrombotický syndróm"},
{"code":"I87.00","desc":"Posttrombotický syndróm bez ulcerácie"},
{"code":"I87.01","desc":"Posttrombotický syndróm s ulceráciou"},
{"code":"I87.1","desc":"Kompresia žily"},
{"code":"I87.2","desc":"Žilová insuficiencia (chronická) (periférna)"},
{"code":"I87.8","desc":"Iné choroby žily, bližšie určené"},
{"code":"I87.9","desc":"Choroba žily, bližšie neurčená"},
{"code":"I88.-","desc":"Nešpecifická lymfadenitída"},
{"code":"I88.0","desc":"Nešpecifická mezenterická lymfadenitída"},
{"code":"I88.1","desc":"Chronická lymfadenitída okrem mezenterickej lymfadenitídy"},
{"code":"I88.8","desc":"Iná nešpecifická lymfadenitída"},
{"code":"I88.9","desc":"Nešpecifická lymfadenitída, bližšie neurčená"},
{"code":"I89.-","desc":"Iná neinfekčná choroba lymfatických ciev a lymfatických uzlín"},
{"code":"I89.0","desc":"Lymfedém, nezatriedený inde"},
{"code":"I89.1","desc":"Lymfangitída"},
{"code":"I89.8","desc":"Iné neinfekčné choroby lymfatických ciev a lymfatických uzlín, bližšie určené"},
{"code":"I89.9","desc":"Neinfekčná choroba lymfatických ciev a lymfatických uzlín, bližšie neurčená"},
{"code":"I95-I99","desc":"Iné a bližšie neurčené choroby obehovej sústavy"},
{"code":"I95.-","desc":"Artériová hypotenzia"},
{"code":"I95.0","desc":"Idiopatická artériová hypotenzia"},
{"code":"I95.1","desc":"Ortostatická artériová hypotenzia"},
{"code":"I95.2","desc":"Artériová hypotenzia zapríčinená liekmi"},
{"code":"I95.8","desc":"Iná artériová hypotenzia"},
{"code":"I95.9","desc":"Artériová hypotenzia, bližšie neurčená"},
{"code":"I97.-","desc":"Komplikácie obehovej sústavy po lekárskom výkone, nezatriedené inde"},
{"code":"I97.0","desc":"Postkardiotomický syndróm"},
{"code":"I97.1","desc":"Iná funkčná porucha po operácii srdca"},
{"code":"I97.2","desc":"Postmastektomický lymfedém"},
{"code":"I97.8","desc":"Iná komplikácia obehovej sústavy po lekárskom výkone, nezatriedená inde"},
{"code":"I97.9","desc":"Komplikácia obehovej sústavy po lekárskom výkone, bližšie neurčená"},
{"code":"I98.-*","desc":"Iné poruchy obehovej sústavy pri chorobách zatriedených inde"},
{"code":"I98.0*","desc":"Kardiovaskulárny syfilis"},
{"code":"I98.1*","desc":"Porucha obehovej sústavy pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"I98.2*","desc":"Varixy pažeráka a žalúdka pri chorobách zatriedených inde, bez krvácania"},
{"code":"I98.3*","desc":"Varixy pažeráka a žalúdka pri chorobách zatriedených inde, s krvácaním"},
{"code":"I98.8*","desc":"Iná bližšie určená porucha obehovej sústavy pri chorobách zatriedených inde"},
{"code":"I99","desc":"Iná a bližšie neurčená choroba obehovej sústavy"},
{"code":"J00-J99","desc":"X. kapitola - Choroby dýchacej sústavy (J00-J99)"},
{"code":"J00-J06","desc":"Akútne infekcie horných dýchacích ciest"},
{"code":"J00","desc":"Akútny zápal nosohltana [nádcha]"},
{"code":"J01.-","desc":"Akútny zápal prinosových dutín"},
{"code":"J01.0","desc":"Akútny zápal čeľustnej dutiny"},
{"code":"J01.1","desc":"Akútny zápal čelovej dutiny"},
{"code":"J01.2","desc":"Akútny zápal čuchovej dutiny"},
{"code":"J01.3","desc":"Akútny zápal klinovitej dutiny"},
{"code":"J01.4","desc":"Akútna pansinusitída"},
{"code":"J01.8","desc":"Iný akútny zápal prinosových dutín"},
{"code":"J01.9","desc":"Zápal prinosových dutín, bližšie neurčený"},
{"code":"J02.-","desc":"Akútny zápal hltana"},
{"code":"J02.0","desc":"Streptokokový zápal hltana"},
{"code":"J02.8","desc":"Akútny zápal hltana, zapríčinený inými bližšie určenými organizmami"},
{"code":"J02.9","desc":"Akútny zápal hltana, bližšie neurčený"},
{"code":"J03.-","desc":"Akútny zápal mandlí"},
{"code":"J03.0","desc":"Streptokokový zápal mandlí"},
{"code":"J03.8","desc":"Akútny zápal mandlí, zapríčinený iným bližšie určeným organizmom"},
{"code":"J03.9","desc":"Akútny zápal mandlí, bližšie neurčený"},
{"code":"J04.-","desc":"Akútny zápal hrtana a priedušnice"},
{"code":"J04.0","desc":"Akútny zápal hrtana"},
{"code":"J04.1","desc":"Akútny zápal priedušnice"},
{"code":"J04.2","desc":"Akútny zápal hrtana a priedušnice"},
{"code":"J05.-","desc":"Akútny obštrukčný zápal hrtana [krup] a akútny zápal hrtanovej príklopky [epiglotitída]"},
{"code":"J05.0","desc":"Akútny obštrukčný zápal hrtana [krup]"},
{"code":"J05.1","desc":"Akútny zápal hrtanovej príklopky [epiglotitída]"},
{"code":"J06.-","desc":"Akútna infekcia horných dýchacích ciest na viacerých a bližšie neurčených miestach"},
{"code":"J06.0","desc":"Akútny zápal hrtana a hltana"},
{"code":"J06.8","desc":"Iná akútna infekcia horných dýchacích ciest na viacerých miestach"},
{"code":"J06.9","desc":"Akútna infekcia horných dýchacích ciest, bližšie neurčená"},
{"code":"J09-J18","desc":"Chrípka [influenza] a zápal pľúc [pneumónia]"},
{"code":"J09","desc":"Chrípka zapríčinená určitým identifikovaným vírusom chrípky"},
{"code":"J10.-","desc":"Chrípka zapríčinená iným identifikovaným vírusom chrípky"},
{"code":"J10.0","desc":"Chrípka zapríčinená iným identifikovaným vírusom chrípky, so zápalom pľúc"},
{"code":"J10.1","desc":"Chrípka zapríčinená iným identifikovaným vírusom chrípky, s inými respiračnými prejavmi"},
{"code":"J10.8","desc":"Chrípka zapríčinená iným identifikovaným vírusom chrípky, s inými prejavmi"},
{"code":"J11.-","desc":"Chrípka zapríčinená neidentifikovaným vírusom"},
{"code":"J11.0","desc":"Chrípka zapríčinená neidentifikovaným vírusom, so zápalom pľúc"},
{"code":"J11.1","desc":"Chrípka zapríčinená neidentifikovaným vírusom, s inými respiračnými prejavmi"},
{"code":"J11.8","desc":"Chrípka zapríčinená neidentifikovaným vírusom, s inými prejavmi"},
{"code":"J12.-","desc":"Vírusový zápal pľúc, nezatriedený inde"},
{"code":"J12.0","desc":"Zápal pľúc zapríčinený adenovírusom"},
{"code":"J12.1","desc":"Zápal pľúc zapríčinený respiračným syncytiálnym vírusom"},
{"code":"J12.2","desc":"Zápal pľúc zapríčinený vírusom parainfluenzy"},
{"code":"J12.3","desc":"Zápal pľúc zapríčinený ľudským metapneumovírusom"},
{"code":"J12.8","desc":"Iný vírusový zápal pľúc"},
{"code":"J12.9","desc":"Vírusový zápal pľúc, bližšie neurčený"},
{"code":"J13","desc":"Zápal pľúc, zapríčinený Streptococcus pneumoniae"},
{"code":"J14","desc":"Zápal pľúc zapríčinený Haemophilus influenzae"},
{"code":"J15.-","desc":"Baktériový zápal pľúc, nezatriedený inde"},
{"code":"J15.0","desc":"Zápal pľúc zapríčinený Klebsiella pneumoniae"},
{"code":"J15.1","desc":"Zápal pľúc zapríčinený pseudomonas"},
{"code":"J15.2","desc":"Zápal pľúc zapríčinený stafylokokom"},
{"code":"J15.3","desc":"Zápal pľúc zapríčinený streptokokom skupiny B"},
{"code":"J15.4","desc":"Zápal pľúc zapríčinený iným streptokokom"},
{"code":"J15.5","desc":"Zápal pľúc zapríčinený Escherichia coli"},
{"code":"J15.6","desc":"Zápal pľúc zapríčinený inou aeróbnou gramnegatívnou baktériou"},
{"code":"J15.7","desc":"Zápal pľúc zapríčinený Mycoplasma pneumoniae"},
{"code":"J15.8","desc":"Iný baktériový zápal pľúc"},
{"code":"J15.9","desc":"Baktériový zápal pľúc, bližšie neurčený"},
{"code":"J16.-","desc":"Zápal pľúc zapríčinený iným mikroorganizmom, nezatriedený inde"},
{"code":"J16.0","desc":"Zápal pľúc zapríčinený chlamýdiami"},
{"code":"J16.8","desc":"Zápal pľúc zapríčinený iným bližšie určeným infekčným organizmom"},
{"code":"J17.-*","desc":"Zápal pľúc pri chorobách zatriedených inde"},
{"code":"J17.0*","desc":"Zápal pľúc pri baktériových chorobách zatriedených inde"},
{"code":"J17.1*","desc":"Zápal pľúc pri vírusových chorobách zatriedených inde"},
{"code":"J17.2*","desc":"Zápal pľúc pri mykózach zatriedených inde"},
{"code":"J17.3*","desc":"Zápal pľúc pri parazitových chorobách"},
{"code":"J17.8*","desc":"Zápal pľúc pri iných chorobách zatriedených inde"},
{"code":"J18.-","desc":"Zápal pľúc zapríčinený bližšie neurčeným mikroorganizmom"},
{"code":"J18.0","desc":"Bronchopneumónia, bližšie neurčená"},
{"code":"J18.1","desc":"Lobárny zápal pľúc, bližšie neurčený"},
{"code":"J18.2","desc":"Hypostatický zápal pľúc, bližšie neurčený"},
{"code":"J18.8","desc":"Iný zápal pľúc, zapríčinený bližšie neurčeným organizmom"},
{"code":"J18.9","desc":"Zápal pľúc, bližšie neurčený"},
{"code":"J20-J22","desc":"Iné akútne infekcie dolných dýchacích ciest"},
{"code":"J20.-","desc":"Akútny zápal priedušiek [akútna bronchitída]"},
{"code":"J20.0","desc":"Akútna bronchitída zapríčinená Mycoplasma pneumoniae"},
{"code":"J20.1","desc":"Akútna bronchitída zapríčinená Haemophilus influenzae"},
{"code":"J20.2","desc":"Akútna bronchitída zapríčinená streptokokom"},
{"code":"J20.3","desc":"Akútna bronchitída zapríčinená vírusom Coxsackie"},
{"code":"J20.4","desc":"Akútna bronchitída zapríčinená vírusom parainfluenzy"},
{"code":"J20.5","desc":"Akútna bronchitída zapríčinená respiračným syncytiálnym vírusom"},
{"code":"J20.6","desc":"Akútna bronchitída zapríčinená rinovírusom"},
{"code":"J20.7","desc":"Akútna bronchitída zapríčinená vírusom ECHO"},
{"code":"J20.8","desc":"Akútna bronchitída zapríčinená iným bližšie určeným organizmom"},
{"code":"J20.9","desc":"Akútna bronchitída, bližšie neurčená"},
{"code":"J21.-","desc":"Akútny zápal priedušničiek [akútna bronchiolitída]"},
{"code":"J21.0","desc":"Akútna bronchiolitída zapríčinená respiračným syncytiálnym vírusom"},
{"code":"J21.1","desc":"Akútna bronchiolitída zapríčinená ľudským metapneumovírusom"},
{"code":"J21.8","desc":"Akútna bronchiolitída zapríčinená iným bližšie určeným organizmom"},
{"code":"J21.9","desc":"Akútna bronchiolitída, bližšie neurčená"},
{"code":"J22","desc":"Akútna infekcia dolných dýchacích ciest, bližšie neurčená"},
{"code":"J30-J39","desc":"Iné choroby horných dýchacích ciest"},
{"code":"J30.-","desc":"Vazomotorická a alergická rinitída"},
{"code":"J30.0","desc":"Vazomotorická rinitída"},
{"code":"J30.1","desc":"Alergická rinitída zapríčinená peľom"},
{"code":"J30.2","desc":"Iná sezónna alergická rinitída"},
{"code":"J30.3","desc":"Iná alergická rinitída"},
{"code":"J30.4","desc":"Alergická rinitída, bližšie neurčená"},
{"code":"J31.-","desc":"Chronický zápal nosa, nosohltana a hltana"},
{"code":"J31.0","desc":"Chronický zápal nosa"},
{"code":"J31.1","desc":"Chronický zápal nosohltana"},
{"code":"J31.2","desc":"Chronický zápal hltana"},
{"code":"J32.-","desc":"Chronický zápal prinosových dutín"},
{"code":"J32.0","desc":"Chronický zápal čeľustnej dutiny"},
{"code":"J32.1","desc":"Chronický zápal čelovej dutiny"},
{"code":"J32.2","desc":"Chronický zápal čuchových dutín"},
{"code":"J32.3","desc":"Chronický zápal klinovitej dutiny"},
{"code":"J32.4","desc":"Chronická pansinusitída"},
{"code":"J32.8","desc":"Iná chronická sinusitída"},
{"code":"J32.9","desc":"Chronická sinusitída, bližšie neurčená"},
{"code":"J33.-","desc":"Nosový polyp"},
{"code":"J33.0","desc":"Polyp nosovej dutiny"},
{"code":"J33.1","desc":"Deformujúca polypóza prinosovej dutiny"},
{"code":"J33.8","desc":"Iný polyp prinosovej dutiny"},
{"code":"J33.9","desc":"Nosový polyp, bližšie neurčený"},
{"code":"J34.-","desc":"Iná choroba nosa a prinosových dutín"},
{"code":"J34.0","desc":"Absces, furunkul a karbunkul nosa"},
{"code":"J34.1","desc":"Cysta a mukokéla nosa a prinosovej dutiny"},
{"code":"J34.2","desc":"Vybočenie nosovej priehradky"},
{"code":"J34.3","desc":"Hypertrofia nosových mušlí"},
{"code":"J34.8","desc":"Iná choroba nosa a prinosových dutín, bližšie určená"},
{"code":"J35.-","desc":"Chronická choroba mandlí a adenoidného tkaniva"},
{"code":"J35.0","desc":"Chronický zápal mandlí"},
{"code":"J35.1","desc":"Hyperplázia mandlí"},
{"code":"J35.2","desc":"Hyperplázia adenoidného tkaniva"},
{"code":"J35.3","desc":"Hypertrofia mandlí s hypertrofiou adenoidného tkaniva"},
{"code":"J35.8","desc":"Iná chronická choroba mandlí a adenoidného tkaniva"},
{"code":"J35.9","desc":"Choroba mandlí a adenoidného tkaniva, bližšie neurčená"},
{"code":"J36","desc":"Peritonzilárny absces"},
{"code":"J37.-","desc":"Chronická laryngitída a chronická laryngotracheitída"},
{"code":"J37.0","desc":"Chronický zápal hrtana"},
{"code":"J37.1","desc":"Chronický zápal hrtana a priedušnice"},
{"code":"J38.-","desc":"Choroba hlasiviek a hrtana, nezatriedená inde"},
{"code":"J38.0-","desc":"Obrna hlasiviek a hrtana"},
{"code":"J38.00","desc":"Obrna hlasiviek a hrtana, bližšie neurčená"},
{"code":"J38.01","desc":"Jednostranná čiastočná obrna hlasiviek a hrtana"},
{"code":"J38.02","desc":"Jednostranná kompletná obrna hlasiviek a hrtana"},
{"code":"J38.03","desc":"Obojstranná čiastočná obrna hlasiviek a hrtana"},
{"code":"J38.1","desc":"Polyp hlasivky a hrtana"},
{"code":"J38.2","desc":"Uzlíky hlasiviek"},
{"code":"J38.3","desc":"Iné choroby hlasiviek"},
{"code":"J38.4","desc":"Opuch hrtana"},
{"code":"J38.5","desc":"Spazmus hrtana"},
{"code":"J38.6","desc":"Stenóza hrtana"},
{"code":"J38.7","desc":"Iná choroba hrtana"},
{"code":"J39.-","desc":"Iná choroba horných dýchacích ciest"},
{"code":"J39.0","desc":"Retrofaryngový a parafaryngový absces"},
{"code":"J39.1","desc":"Iný absces hltana"},
{"code":"J39.2","desc":"Iná choroba hltana"},
{"code":"J39.3","desc":"Hypersenzitívna reakcia horných dýchacích ciest bez bližšieho určenia miesta"},
{"code":"J39.8-","desc":"Iná choroba horných dýchacích ciest, bližšie určená"},
{"code":"J39.80","desc":"Získaná stenóza priedušnice"},
{"code":"J39.88","desc":"Iná choroba horných dýchacích ciest, bližšie určená"},
{"code":"J39.9","desc":"Choroba horných dýchacích ciest, bližšie neurčená"},
{"code":"J40-J47","desc":"Chronické choroby dolných dýchacích ciest"},
{"code":"J40","desc":"Bronchitída, neurčená ako akútna alebo chronická"},
{"code":"J41.-","desc":"Jednoduchá a hlienovohnisová chronická bronchitída"},
{"code":"J41.0","desc":"Jednoduchá chronická bronchitída"},
{"code":"J41.1","desc":"Hlienovohnisová chronická bronchitída"},
{"code":"J41.8","desc":"Zmiešaná jednoduchá a hlienovohnisová chronická bronchitída"},
{"code":"J42","desc":"Chronická bronchitída, bližšie neurčená"},
{"code":"J43.-","desc":"Emfyzém pľúc"},
{"code":"J43.0","desc":"MacLeodov syndróm"},
{"code":"J43.1","desc":"Panlobulárny emfyzém"},
{"code":"J43.2","desc":"Centrilobulárny emfyzém"},
{"code":"J43.8","desc":"Iný emfyzém"},
{"code":"J43.9","desc":"Emfyzém, bližšie neurčený"},
{"code":"J44.-","desc":"Iná chronická obštrukčná choroba pľúc"},
{"code":"J44.0-","desc":"Chronická obštrukčná choroba pľúc s akútnou infekciou dolných dýchacích ciest"},
{"code":"J44.00","desc":"Chronická obštrukčná choroba pľúc s akútnou infekciou dolných dýchacích ciest, FEV1 < 35 % príslušnej hodnoty"},
{"code":"J44.01","desc":"Chronická obštrukčná choroba pľúc s akútnou infekciou dolných dýchacích ciest, FEV1 >= 35 < 50 % príslušnej hodnoty"},
{"code":"J44.02","desc":"Chronická obštrukčná choroba pľúc s akútnou infekciou dolných dýchacích ciest, FEV1 >= 50 % a < 70 % príslušnej hodnoty"},
{"code":"J44.03","desc":"Chronická obštrukčná choroba pľúc s akútnou infekciou dolných dýchacích ciest, FEV1 >= 70 % príslušnej hodnoty"},
{"code":"J44.09","desc":"Chronická obštrukčná choroba pľúc s akútnou infekciou dolných dýchacích ciest, hodnota FEV1 bližšie neurčená"},
{"code":"J44.1-","desc":"Chronická obštrukčná choroba pľúc s akútnou exacerbáciou choroby, bližšie neurčená"},
{"code":"J44.10","desc":"Chronická obštrukčná choroba pľúc s akútnou exacerbáciou choroby, bližšie neurčená, FEV1 < 35 % príslušnej hodnoty"},
{"code":"J44.11","desc":"Chronická obštrukčná choroba pľúc s akútnou exacerbáciou choroby, bližšie neurčená, FEV1 >= 35 % a < 50 % príslušnej hodnoty"},
{"code":"J44.12","desc":"Chronická obštrukčná choroba pľúc s akútnou exacerbáciou choroby, bližšie neurčená, FEV1 >= 50 % a < 70 % príslušnej hodnoty"},
{"code":"J44.13","desc":"Chronická obštrukčná choroba pľúc s akútnou exacerbáciou choroby, bližšie neurčená, FEV1 >= 70 % príslušnej hodnoty"},
{"code":"J44.19","desc":"Chronická obštrukčná choroba pľúc s akútnou exacerbáciou choroby, bližšie neurčená, hodnota FEV1 bližšie neurčená"},
{"code":"J44.8-","desc":"Iná chronická obštrukčná choroba pľúc, bližšie určená"},
{"code":"J44.80","desc":"Iná chronická obštrukčná choroba pľúc, bližšie určená, FEV1 < 35 % príslušnej hodnoty"},
{"code":"J44.81","desc":"Iná chronická obštrukčná choroba pľúc, bližšie určená, FEV1 >= 35 % a < 50 % príslušnej hodnoty"},
{"code":"J44.82","desc":"Iná chronická obštrukčná choroba pľúc, bližšie určená, FEV1 >= 50 % a < 70 % príslušnej hodnoty"},
{"code":"J44.83","desc":"Iná chronická obštrukčná choroba pľúc, bližšie určená, FEV1 >= 70 % príslušnej hodnoty"},
{"code":"J44.89","desc":"Iná chronická obštrukčná choroba pľúc, bližšie určená, hodnota FEV1 bližšie neurčená"},
{"code":"J44.9-","desc":"Chronická obštrukčná choroba pľúc, bližšie neurčená"},
{"code":"J44.90","desc":"Chronická obštrukčná choroba pľúc, bližšie neurčená, FEV1 < 35 % príslušnej hodnoty"},
{"code":"J44.91","desc":"Chronická obštrukčná choroba pľúc, bližšie neurčená, FEV1 >= 35 % a < 50 % príslušnej hodnoty"},
{"code":"J44.92","desc":"Chronická obštrukčná choroba pľúc, bližšie neurčená, FEV1 >= 50 % a < 70 % príslušnej hodnoty"},
{"code":"J44.93","desc":"Chronická obštrukčná choroba pľúc, bližšie neurčená, FEV1 >= 70 % príslušnej hodnoty"},
{"code":"J44.99","desc":"Chronická obštrukčná choroba pľúc, bližšie neurčená, hodnota FEV1 bližšie neurčená"},
{"code":"J45.-","desc":"Bronchiálna astma"},
{"code":"J45.0","desc":"Bronchiálna astma, prevažne alergická"},
{"code":"J45.1","desc":"Bronchiálna astma, nealergická"},
{"code":"J45.8","desc":"Bronchiálna astma, zmiešaná"},
{"code":"J45.9","desc":"Bronchiálna astma, bližšie neurčená"},
{"code":"J46","desc":"Status asthmaticus"},
{"code":"J47","desc":"Bronchiektázie"},
{"code":"J60-J70","desc":"Choroby pľúc zapríčinené vonkajšími faktormi"},
{"code":"J60","desc":"Pneumokonióza baníkov uhoľných baní"},
{"code":"J61","desc":"Pneumokonióza zapríčinená azbestom a inými anorganickými vláknami"},
{"code":"J62.-","desc":"Pneumokonióza zapríčinená prachom obsahujúcim kremík"},
{"code":"J62.0","desc":"Pneumokonióza zapríčinená prachom mastenca (talkom)"},
{"code":"J62.8","desc":"Pneumokonióza zapríčinená iným prachom obsahujúcim kremík"},
{"code":"J63.-","desc":"Pneumokonióza zapríčinená inými anorganickými prachmi"},
{"code":"J63.0","desc":"Aluminóza (pľúc)"},
{"code":"J63.1","desc":"Bauxitová fibróza (pľúc)"},
{"code":"J63.2","desc":"Berylióza"},
{"code":"J63.3","desc":"Grafitová fibróza (pľúc)"},
{"code":"J63.4","desc":"Sideróza"},
{"code":"J63.5","desc":"Stanóza"},
{"code":"J63.8","desc":"Pneumokonióza zapríčinená inými bližšie určenými anorganickými prachmi"},
{"code":"J64","desc":"Pneumokonióza, bližšie neurčená"},
{"code":"J65","desc":"Pneumokonióza združená s tuberkulózou"},
{"code":"J66.-","desc":"Choroba dýchacích ciest zapríčinená špecifickým organickým prachom"},
{"code":"J66.0","desc":"Bysinóza"},
{"code":"J66.1","desc":"Choroba spracovateľov ľanu"},
{"code":"J66.2","desc":"Kanabinóza"},
{"code":"J66.8","desc":"Choroba dýchacích ciest zapríčinená iným bližšie určeným organickým prachom"},
{"code":"J67.-","desc":"Alergická alveolitída zapríčinená organickým prachom"},
{"code":"J67.0","desc":"Farmárske pľúca"},
{"code":"J67.1","desc":"Bagasóza"},
{"code":"J67.2","desc":"Pľúcna choroba chovateľov vtákov"},
{"code":"J67.3","desc":"Suberóza"},
{"code":"J67.4","desc":"Sladovnícke pľúca"},
{"code":"J67.5","desc":"Pľúcna choroba pestovateľov húb"},
{"code":"J67.6","desc":"Pľúcna choroba lúpačov javorovej kôry"},
{"code":"J67.7","desc":"Pľúcna choroba zapríčinená klimatizačnými zariadeniami"},
{"code":"J67.8","desc":"Alergická alveolitída zapríčinená iným organickým prachom"},
{"code":"J67.9","desc":"Alergická alveolitída zapríčinená bližšie neurčeným organickým prachom"},
{"code":"J68.-","desc":"Choroby dýchacej sústavy zapríčinené chemikáliami, plynmi, dymom a výparmi"},
{"code":"J68.0","desc":"Bronchitída a pneumonitída zapríčinená chemikáliami, plynmi, dymom a výparmi"},
{"code":"J68.1","desc":"Akútny pľúcny edém, zapríčinený chemikáliami, plynmi, dymom a výparmi"},
{"code":"J68.2","desc":"Zápal horných dýchacích ciest, zapríčinený chemikáliami, plynmi, dymom a výparmi"},
{"code":"J68.3","desc":"Iná akútna a subakútna choroba dýchacej sústavy zapríčinená chemikáliami, plynmi, dymom a výparmi"},
{"code":"J68.4","desc":"Chronická choroba dýchacej sústavy zapríčinená chemikáliami, plynmi, dymom a výparmi"},
{"code":"J68.8","desc":"Iná choroba dýchacej sústavy zapríčinená chemikáliami, plynmi, dymom a výparmi"},
{"code":"J68.9","desc":"Bližšie neurčená choroba dýchacej sústavy zapríčinená chemikáliami, plynmi, dymom a výparmi"},
{"code":"J69.-","desc":"Zápal pľúc, zapríčinený tuhými látkami a tekutinami"},
{"code":"J69.0","desc":"Zápal pľúc, zapríčinený vdýchnutím potravy a zvratkov"},
{"code":"J69.1","desc":"Zápal pľúc, zapríčinený olejmi a esenciami"},
{"code":"J69.8","desc":"Zápal pľúc, zapríčinený inými tuhými látkami a tekutinami"},
{"code":"J70.-","desc":"Choroba dýchacích orgánov zapríčinená inými vonkajšími faktormi"},
{"code":"J70.0","desc":"Akútne pľúcne prejavy zapríčinené žiarením"},
{"code":"J70.1","desc":"Chronické a iné pľúcne prejavy zapríčinené žiarením"},
{"code":"J70.2","desc":"Akútna pľúcna intersticiálna choroba zapríčinená liekmi"},
{"code":"J70.3","desc":"Chronická pľúcna intersticiálna choroba zapríčinená liekmi"},
{"code":"J70.4","desc":"Pľúcna intersticiálna choroba zapríčinená liekmi, bližšie neurčená"},
{"code":"J70.8","desc":"Choroba dýchacej sústavy zapríčinená inými bližšie určenými vonkajšími faktormi"},
{"code":"J70.9","desc":"Choroba dýchacej sústavy zapríčinená bližšie neurčenými vonkajšími faktormi"},
{"code":"J80-J84","desc":"Iné choroby dýchacích orgánov postihujúce najmä interstícium"},
{"code":"J80","desc":"Syndróm respiračnej tiesne  [ARDS]"},
{"code":"J81","desc":"Pľúcny opuch"},
{"code":"J82","desc":"Eozinofilný pľúcny infiltrát, nezatriedený inde"},
{"code":"J84.-","desc":"Iná choroba interstícia pľúc"},
{"code":"J84.0","desc":"Alveolové a parietoalveolové stavy"},
{"code":"J84.1","desc":"Iná choroba interstícia pľúc s fibrózou"},
{"code":"J84.8","desc":"Iná choroba interstícia pľúc, bližšie určená"},
{"code":"J84.9","desc":"Choroba interstícia pľúc, bližšie neurčená"},
{"code":"J85-J86","desc":"Hnisové a nekrotické choroby dolných dýchacích ciest"},
{"code":"J85.-","desc":"Absces pľúc a mediastína"},
{"code":"J85.0","desc":"Gangréna a nekróza pľúc"},
{"code":"J85.1","desc":"Absces pľúc so zápalom pľúc"},
{"code":"J85.2","desc":"Absces pľúc bez zápalu pľúc"},
{"code":"J85.3","desc":"Absces mediastína"},
{"code":"J86.-","desc":"Pyotorax"},
{"code":"J86.0","desc":"Pyotorax s fistulou"},
{"code":"J86.9","desc":"Pyotorax bez fistuly"},
{"code":"J90-J94","desc":"Iné choroby pohrudnice"},
{"code":"J90","desc":"Pohrudnicový výpotok, nezatriedený inde"},
{"code":"J91*","desc":"Pohrudnicový výpotok pri chorobách zatriedených inde"},
{"code":"J92.-","desc":"Pohrudnicové zrasty"},
{"code":"J92.0","desc":"Pohrudnicové zrasty s prítomnosťou azbestom"},
{"code":"J92.9","desc":"Pohrudnicové zrasty bez prítomnosti azbestu"},
{"code":"J93.-","desc":"Pneumotorax"},
{"code":"J93.0","desc":"Spontánny tenzný pneumotorax"},
{"code":"J93.1","desc":"Iný spontánny pneumotorax"},
{"code":"J93.8","desc":"Iný pneumotorax"},
{"code":"J93.9","desc":"Pneumotorax, bližšie neurčený"},
{"code":"J94.-","desc":"Iné choroby pohrudnice"},
{"code":"J94.0","desc":"Chylózny pohrudnicový výpotok"},
{"code":"J94.1","desc":"Fibrotorax"},
{"code":"J94.2","desc":"Hemotorax"},
{"code":"J94.8","desc":"Iná choroba pohrudnice, bližšie určená"},
{"code":"J94.9","desc":"Choroba pohrudnice, bližšie neurčená"},
{"code":"J95-J99","desc":"Iné choroby dýchacej sústavy"},
{"code":"J95.-","desc":"Choroby dýchacích ciest po lekárskom výkone, nezatriedené inde"},
{"code":"J95.0","desc":"Porucha funkcie tracheostómie"},
{"code":"J95.1","desc":"Akútna pulmonálna insuficiencia po hrudníkovej operácii"},
{"code":"J95.2","desc":"Akútna pulmonálna insuficiencia po mimohrudníkovom chirurgickom výkone"},
{"code":"J95.3","desc":"Chronická pulmonálna insuficiencia po chirurgickom výkone"},
{"code":"J95.4","desc":"Mendelsonov syndróm"},
{"code":"J95.5","desc":"Subglotická stenóza po chirurgickom výkone"},
{"code":"J95.8-","desc":"Iná porucha dýchacích orgánov po chirurgickom výkone"},
{"code":"J95.80","desc":"Iatrogénny pneumotorax"},
{"code":"J95.81","desc":"Stenóza priedušnice po lekárskom výkone"},
{"code":"J95.88","desc":"Iná porucha dýchacích orgánov po lekárskom výkone"},
{"code":"J95.9","desc":"Porucha dýchacích orgánov po lekárskom výkone, bližšie neurčená"},
{"code":"J96.-","desc":"Respiračná insuficiencia, nezatriedená inde"},
{"code":"J96.0-","desc":"Akútna respiračná insuficiencia, nezatriedená inde"},
{"code":"J96.00","desc":"Akútna respiračná insuficiencia, nezatriedená inde, typ I (hypoxemický)"},
{"code":"J96.01","desc":"Akútna respiračná insuficiencia, nezatriedená inde, typ II (hyperkapnický typ)"},
{"code":"J96.09","desc":"Akútna respiračná insuficiencia, nezatriedená inde, typ bližšie neurčený"},
{"code":"J96.1-","desc":"Chronická respiračná insuficiencia, nezatriedená inde"},
{"code":"J96.10","desc":"Chronická respiračná insuficiencia, nezatriedená inde, typ I (hypoxemický)"},
{"code":"J96.11","desc":"Chronická respiračná insuficiencia, nezatriedená inde, typ II (hyperkapnický)"},
{"code":"J96.19","desc":"Chronická respiračná insuficiencia, nezatriedená inde, typ bližšie neurčený"},
{"code":"J96.9-","desc":"Respiračná insuficiencia, bližšie neurčená"},
{"code":"J96.90","desc":"Respiračná insuficiencia, bližšie neurčená, typ I (hypoxemický)"},
{"code":"J96.91","desc":"Respiračná insuficiencia, bližšie neurčená, typ II (hyperkapnický)"},
{"code":"J96.99","desc":"Respiračná insuficiencia, bližšie neurčená, typ bližšie neurčený"},
{"code":"J98.-","desc":"Iné choroby dýchacej sústavy"},
{"code":"J98.0","desc":"Choroby priedušiek, nezatriedené inde"},
{"code":"J98.1","desc":"Kolaps pľúc"},
{"code":"J98.2","desc":"Intersticiálny emfyzém"},
{"code":"J98.3","desc":"Kompenzačný emfyzém"},
{"code":"J98.4","desc":"Iné choroby pľúc"},
{"code":"J98.5-","desc":"Choroby mediastína, nezatriedené inde"},
{"code":"J98.50","desc":"Mediastinitída"},
{"code":"J98.58","desc":"Iné choroby mediastína, nezatriedené inde"},
{"code":"J98.6","desc":"Choroby bránice"},
{"code":"J98.8","desc":"Iné choroba dýchacej sústavy, bližšie určené"},
{"code":"J98.9","desc":"Choroba dýchacej sústavy, bližšie neurčená"},
{"code":"J99.-*","desc":"Choroby dýchacej sústavy pri chorobách zatriedených inde"},
{"code":"J99.0*","desc":"Reumatoidná choroba pľúc (M05.1-†)"},
{"code":"J99.1*","desc":"Choroba dýchacej sústavy pri iných difúznych chorobách spojivového tkaniva"},
{"code":"J99.8*","desc":"Choroby dýchacej sústavy pri iných chorobách zatriedených inde"},
{"code":"K00-K93","desc":"XI. kapitola - Choroby tráviacej sústavy (K00-K93)"},
{"code":"K00-K14","desc":"Choroby ústnej dutiny, slinných žliaz a čeľustí"},
{"code":"K00.-","desc":"Poruchy vývinu a erupcie zubov"},
{"code":"K00.0","desc":"Poruchy počtu zubov"},
{"code":"K00.1","desc":"Nadpočetné zuby"},
{"code":"K00.2","desc":"Abnormalita veľkosti a tvaru zubov"},
{"code":"K00.3","desc":"Škvrnité zuby"},
{"code":"K00.4","desc":"Porucha tvorby zubov"},
{"code":"K00.5","desc":"Dedičná porucha zubnej štruktúry, nezatriedená inde"},
{"code":"K00.6","desc":"Porucha prerezávania zubov"},
{"code":"K00.7","desc":"Syndróm rastu chrupu"},
{"code":"K00.8","desc":"Iná porucha zubného vývinu"},
{"code":"K00.9","desc":"Porucha zubného vývinu, bližšie neurčená"},
{"code":"K01.-","desc":"Neprerezané a vrastené zuby"},
{"code":"K01.0","desc":"Neprerezané zuby"},
{"code":"K01.1","desc":"Vrastené zuby"},
{"code":"K02.-","desc":"Zubný kaz"},
{"code":"K02.0","desc":"Kaz ohraničený na sklovinu"},
{"code":"K02.1","desc":"Kaz dentínu"},
{"code":"K02.2","desc":"Kaz cementu"},
{"code":"K02.3","desc":"Zastavený zubný kaz"},
{"code":"K02.4","desc":"Odontoklázia"},
{"code":"K02.5","desc":"Zubný kaz s obnažením drene"},
{"code":"K02.8","desc":"Iný zubný kaz"},
{"code":"K02.9","desc":"Zubný kaz, bližšie neurčený"},
{"code":"K03.-","desc":"Iná choroba tvrdých tkanív zubov"},
{"code":"K03.0","desc":"Nadmerné obrusovanie zubov"},
{"code":"K03.1","desc":"Abrázia zubov"},
{"code":"K03.2","desc":"Erózia zubov"},
{"code":"K03.3","desc":"Patologická resorpcia koreňov zubov"},
{"code":"K03.4","desc":"Hypercementóza"},
{"code":"K03.5","desc":"Ankylóza zubov"},
{"code":"K03.6","desc":"Usadeniny [povlaky] na zuboch"},
{"code":"K03.7","desc":"Farebné zmeny tvrdých zubných tkanív po prerezaní zubov"},
{"code":"K03.8","desc":"Iné choroby tvrdých tkanív zubov, bližšie určené"},
{"code":"K03.9","desc":"Choroby tvrdých tkanív zubov, bližšie neurčené"},
{"code":"K04.-","desc":"Choroby zubnej drene a periapikálnych tkanív"},
{"code":"K04.0","desc":"Zápal zubnej drene"},
{"code":"K04.1","desc":"Nekróza zubnej drene"},
{"code":"K04.2","desc":"Degenerácia zubnej drene"},
{"code":"K04.3","desc":"Abnormálna tvorba tvrdého tkaniva v zubnej dreni"},
{"code":"K04.4","desc":"Akútna apikálna periodontitída dreňového pôvodu"},
{"code":"K04.5","desc":"Chronická apikálna periodontitída"},
{"code":"K04.6","desc":"Periapikálny absces s fistulou"},
{"code":"K04.7","desc":"Periapikálny absces bez fistuly"},
{"code":"K04.8","desc":"Radikulárna cysta"},
{"code":"K04.9","desc":"Iné a bližšie neurčené choroby zubnej drene a periapikálnych tkanív"},
{"code":"K05.-","desc":"Zápal ďasien a choroba parodontu"},
{"code":"K05.0","desc":"Akútny zápal ďasien"},
{"code":"K05.1","desc":"Chronický zápal ďasien"},
{"code":"K05.2","desc":"Akútna parodontitída"},
{"code":"K05.3","desc":"Chronická parodontitída"},
{"code":"K05.4","desc":"Parodontóza"},
{"code":"K05.5","desc":"Iná choroba parodontu"},
{"code":"K05.6","desc":"Choroba parodontu, bližšie neurčená"},
{"code":"K06.-","desc":"Iná choroba ďasien a bezzubého alveolového hrebeňa"},
{"code":"K06.0","desc":"Atrofia ďasien"},
{"code":"K06.1","desc":"Hypertrofia ďasien"},
{"code":"K06.2","desc":"Poškodenie ďasien a bezzubého alveolového hrebeňa zapríčinené traumou"},
{"code":"K06.8","desc":"Iná choroba ďasien a bezzubého alveolového hrebeňa, bližšie určená"},
{"code":"K06.9","desc":"Choroba ďasien a bezzubého alveolového hrebeňa, bližšie neurčená"},
{"code":"K07.-","desc":"Dentofaciálne anomálie (vrátane chýb zhryzu)"},
{"code":"K07.0","desc":"Väčšie anomálie veľkosti čeľustí"},
{"code":"K07.1","desc":"Anomálie polohy čeľustí oproti lebkovej spodine"},
{"code":"K07.2","desc":"Anomálie vzájomnej polohy zubných oblúkov"},
{"code":"K07.3","desc":"Anomálie postavenia zubov"},
{"code":"K07.4","desc":"Chybný zhryz, bližšie neurčený"},
{"code":"K07.5","desc":"Dentofaciálne funkčné abnormality"},
{"code":"K07.6","desc":"Choroba temporomandibulárneho kĺbu"},
{"code":"K07.8","desc":"Iné dentofaciálne anomálie"},
{"code":"K07.9","desc":"Dentofaciálna anomália, bližšie neurčená"},
{"code":"K08.-","desc":"Iné choroby zubov a podporných štruktúr"},
{"code":"K08.0","desc":"Exfoliácia zubov zapríčinená systémovou chorobou"},
{"code":"K08.1","desc":"Strata zubov zapríčinená úrazom, extrakciou alebo lokálnou chorobou parodontu"},
{"code":"K08.2","desc":"Atrofia bezzubého alveolového hrebeňa"},
{"code":"K08.3","desc":"Retinovaný zubný koreň [radix relicta]"},
{"code":"K08.8-","desc":"Iné choroby zubov a podporných štruktúr, bližšie určené"},
{"code":"K08.81","desc":"Patologická zlomenina zuba"},
{"code":"K08.88","desc":"Iné choroby zubov a podporných štruktúr, bližšie určené"},
{"code":"K08.9","desc":"Choroba zubov a podporných štruktúr, bližšie neurčená"},
{"code":"K09.-","desc":"Cysty v oblasti úst, nezatriedené inde"},
{"code":"K09.0","desc":"Vývinové odontogénne cysty"},
{"code":"K09.1","desc":"Vývinové neodontogénne cysty úst a ústnej dutiny"},
{"code":"K09.2","desc":"Iné čeľustné cysty"},
{"code":"K09.8","desc":"Iné cysty v oblasti úst, nezatriedené inde"},
{"code":"K09.9","desc":"Cysta v oblasti úst, bližšie neurčená"},
{"code":"K10.-","desc":"Iné choroby čeľustí"},
{"code":"K10.0","desc":"Vývinové choroby čeľustí"},
{"code":"K10.1","desc":"Centrálny obrovskobunkový granulóm"},
{"code":"K10.2-","desc":"Zápalové choroby čeľustí"},
{"code":"K10.20","desc":"Absces maxily bez rozšírenia do retromaxilárneho priestoru alebo do fossa canina"},
{"code":"K10.21","desc":"Absces maxily s rozšírením do retromaxilárneho priestoru alebo do fossa canina"},
{"code":"K10.28","desc":"Iný zápal čeľustí, bližšie určený"},
{"code":"K10.29","desc":"Zápal čeľustí, bližšie neurčený"},
{"code":"K10.3","desc":"Alveolitída čeľustí"},
{"code":"K10.8","desc":"Iná choroba čeľustí, bližšie určená"},
{"code":"K10.9","desc":"Choroba čeľustí, bližšie neurčená"},
{"code":"K11.-","desc":"Choroba slinných žliaz"},
{"code":"K11.0","desc":"Atrofia slinných žliaz"},
{"code":"K11.1","desc":"Hypertrofia slinných žliaz"},
{"code":"K11.2","desc":"Sialoadenitída"},
{"code":"K11.3","desc":"Absces slinnej žľazy"},
{"code":"K11.4","desc":"Fistula slinnej žľazy"},
{"code":"K11.5","desc":"Sialolitiáza"},
{"code":"K11.6","desc":"Mukokéla slinnej žľazy"},
{"code":"K11.7","desc":"Poruchy sekrécie slín"},
{"code":"K11.8","desc":"Iné choroby slinnej žľazy"},
{"code":"K11.9","desc":"Choroba slinnej žľazy, bližšie neurčená"},
{"code":"K12.-","desc":"Zápal sliznice úst a príbuzné choroby"},
{"code":"K12.0","desc":"Recidivujúce ústne afty"},
{"code":"K12.1","desc":"Iná forma stomatitídy"},
{"code":"K12.2-","desc":"Flegmóna a absces úst a ústnej dutiny"},
{"code":"K12.20","desc":"Flegmóna ústnej spodiny"},
{"code":"K12.21","desc":"Submandibulárny absces bez rozšírenia do mediastinálneho, parafaryngového alebo cervikálneho priestoru"},
{"code":"K12.22","desc":"Submandibulárny absces s rozšírením do mediastinálneho, parafaryngového alebo cervikálneho priestoru"},
{"code":"K12.23","desc":"Absces líca"},
{"code":"K12.28","desc":"Iná flegmóna a absces úst"},
{"code":"K12.29","desc":"Flegmóna a absces úst, bližšie neurčený"},
{"code":"K12.3","desc":"Zápal sliznice úst (ulcerózny)"},
{"code":"K13.-","desc":"Iná choroba pery a ústnej sliznice"},
{"code":"K13.0","desc":"Choroby pery"},
{"code":"K13.1","desc":"Hryzenie líca a pery"},
{"code":"K13.2","desc":"Leukoplakia a iná choroba ústneho epitelu vrátane jazyka"},
{"code":"K13.3","desc":"Vlasatá leukoplakia"},
{"code":"K13.4","desc":"Granulóm a lézie ústnej sliznice podobné granulómu"},
{"code":"K13.5","desc":"Ústna podsliznicová fibróza"},
{"code":"K13.6","desc":"Hyperplázia ústnej sliznice zapríčinená dráždením"},
{"code":"K13.7","desc":"Iná a bližšie neurčená lézia ústnej sliznice"},
{"code":"K14.-","desc":"Choroba jazyka"},
{"code":"K14.0","desc":"Glositída"},
{"code":"K14.1","desc":"Mapovitý jazyk"},
{"code":"K14.2","desc":"Glossitis rhombica mediana"},
{"code":"K14.3","desc":"Hypertrofia jazykových papíl"},
{"code":"K14.4","desc":"Atrofia jazykových papíl"},
{"code":"K14.5","desc":"Lingua plicata"},
{"code":"K14.6","desc":"Glosodýnia"},
{"code":"K14.8","desc":"Iná choroba jazyka"},
{"code":"K14.9","desc":"Choroba jazyka, bližšie neurčená"},
{"code":"K20-K31","desc":"Choroby pažeráka, žalúdka a dvanástnika"},
{"code":"K20","desc":"Ezofagitída"},
{"code":"K21.-","desc":"Gastroezofágová refluxová choroba"},
{"code":"K21.0","desc":"Gastroezofágová refluxová choroba s ezofagitídou"},
{"code":"K21.9","desc":"Gastroezofágová refluxová choroba bez ezofagitídy"},
{"code":"K22.-","desc":"Iná choroba pažeráka"},
{"code":"K22.0","desc":"Achalázia kardie"},
{"code":"K22.1","desc":"Vred pažeráka"},
{"code":"K22.2","desc":"Nepriechodnosť pažeráka"},
{"code":"K22.3","desc":"Prederavenie pažeráka"},
{"code":"K22.4","desc":"Dyskinéza pažeráka"},
{"code":"K22.5","desc":"Získaný divertikul pažeráka"},
{"code":"K22.6","desc":"Malloryho-Weissov syndróm"},
{"code":"K22.7","desc":"Barrettov pažerák"},
{"code":"K22.8","desc":"Iná choroba pažeráka, bližšie určená"},
{"code":"K22.9","desc":"Choroba pažeráka, bližšie neurčená"},
{"code":"K23.-*","desc":"Choroba pažeráka pri chorobe zatriedenej inde"},
{"code":"K23.0*","desc":"Tuberkulóza pažeráka (A18.8†)"},
{"code":"K23.1*","desc":"Megaezofág pri Chagasovej chorobe (B57.3†)"},
{"code":"K23.8*","desc":"Choroby pažeráka pri iných chorobách zatriedených inde"},
{"code":"K25.-","desc":"Vred žalúdka"},
{"code":"K25.0","desc":"Vred žalúdka, akútny s krvácaním"},
{"code":"K25.1","desc":"Vred žalúdka, akútny s perforáciou"},
{"code":"K25.2","desc":"Vred žalúdka, akútny s krvácaním a perforáciou"},
{"code":"K25.3","desc":"Vred žalúdka, akútny bez krvácania alebo perforácie"},
{"code":"K25.4","desc":"Vred žalúdka, chronický alebo bližšie neurčený, s krvácaním"},
{"code":"K25.5","desc":"Vred žalúdka, chronický alebo bližšie neurčený, s perforáciou"},
{"code":"K25.6","desc":"Vred žalúdka, chronický alebo bližšie neurčený, s krvácaním a perforáciou"},
{"code":"K25.7","desc":"Vred žalúdka, chronický, bez krvácania alebo perforácie"},
{"code":"K25.9","desc":"Vred žalúdka, bližšie neurčený ako akútny alebo chronický, bez krvácania alebo perforácie"},
{"code":"K26.-","desc":"Vred dvanástnika"},
{"code":"K26.0","desc":"Vred dvanástnika, akútny s krvácaním"},
{"code":"K26.1","desc":"Vred dvanástnika, akútny s perforáciou"},
{"code":"K26.2","desc":"Vred dvanástnika, akútny s krvácaním a perforáciou"},
{"code":"K26.3","desc":"Vred dvanástnika, akútny bez krvácania alebo perforácie"},
{"code":"K26.4","desc":"Vred dvanástnika, chronický alebo bližšie neurčený, s krvácaním"},
{"code":"K26.5","desc":"Vred dvanástnika, chronický alebo bližšie neurčený, s perforáciou"},
{"code":"K26.6","desc":"Vred dvanástnika, chronický alebo bližšie neurčený, s krvácaním a perforáciou"},
{"code":"K26.7","desc":"Vred dvanástnika, chronický, bez krvácania alebo perforácie"},
{"code":"K26.9","desc":"Vred dvanástnika, bližšie neurčený ako akútny alebo chronický, bez krvácania alebo perforácie"},
{"code":"K27.-","desc":"Peptický vred bez bližšieho určenia miesta"},
{"code":"K27.0","desc":"Peptický vred bez bližšieho určenia miesta, akútny s krvácaním"},
{"code":"K27.1","desc":"Peptický vred bez bližšieho určenia miesta, akútny s perforáciou"},
{"code":"K27.2","desc":"Peptický vred bez bližšieho určenia miesta, akútny s krvácaním a perforáciou"},
{"code":"K27.3","desc":"Peptický vred bez bližšieho určenia miesta, akútny bez krvácania alebo perforácie"},
{"code":"K27.4","desc":"Peptický vred bez bližšieho určenia miesta, chronický alebo bližšie neurčený, s krvácaním"},
{"code":"K27.5","desc":"Peptický vred bez bližšieho určenia miesta, chronický alebo bližšie neurčený, s perforáciou"},
{"code":"K27.6","desc":"Peptický vred bez bližšieho určenia miesta, chronický alebo bližšie neurčený, s krvácaním a perforáciou"},
{"code":"K27.7","desc":"Peptický vred bez bližšieho určenia miesta, chronický, bez krvácania alebo perforácie"},
{"code":"K27.9","desc":"Peptický vred bez bližšieho určenia miesta, bližšie neurčený ako akútny alebo chronický, bez krvácania alebo perforácie"},
{"code":"K28.-","desc":"Peptický vred jejúna"},
{"code":"K28.0","desc":"Peptický vred jejúna, akútny s krvácaním"},
{"code":"K28.1","desc":"Peptický vred jejúna, akútny s perforáciou"},
{"code":"K28.2","desc":"Peptický vred jejúna, akútny s krvácaním a perforáciou"},
{"code":"K28.3","desc":"Peptický vred jejúna, akútny bez krvácania alebo perforácie"},
{"code":"K28.4","desc":"Peptický vred jejúna, chronický alebo bližšie neurčený, s krvácaním"},
{"code":"K28.5","desc":"Peptický vred jejúna, chronický alebo bližšie neurčený, s perforáciou"},
{"code":"K28.6","desc":"Peptický vred jejúna, chronický alebo bližšie neurčený, s krvácaním a perforáciou"},
{"code":"K28.7","desc":"Peptický vred jejúna, chronický, bez krvácania alebo perforácie"},
{"code":"K28.9","desc":"Peptický vred jejúna, bližšie neurčený ako akútny alebo chronický, bez krvácania alebo perforácie"},
{"code":"K29.-","desc":"Gastritída a duodenitída"},
{"code":"K29.0","desc":"Akútna hemoragická gastritída"},
{"code":"K29.1","desc":"Iná akútna gastritída"},
{"code":"K29.2","desc":"Alkoholová gastritída"},
{"code":"K29.3","desc":"Chronická povrchová gastritída"},
{"code":"K29.4","desc":"Chronická atrofická gastritída"},
{"code":"K29.5","desc":"Chronická gastritída, bližšie neurčená"},
{"code":"K29.6","desc":"Iná gastritída"},
{"code":"K29.7","desc":"Gastritída, bližšie neurčená"},
{"code":"K29.8","desc":"Duodenitída"},
{"code":"K29.9","desc":"Gastroduodenitída, bližšie neurčená"},
{"code":"K30","desc":"Dyspepsia"},
{"code":"K31.-","desc":"Iná choroba žalúdka a dvanástnika"},
{"code":"K31.0","desc":"Akútna dilatácia žalúdka"},
{"code":"K31.1","desc":"Hypertrofická pylorostenóza dospelých"},
{"code":"K31.2","desc":"Klepsydrovitá striktúra a stenóza žalúdka"},
{"code":"K31.3","desc":"Pylorospazmus, nezatriedený inde"},
{"code":"K31.4","desc":"Divertikul žalúdka"},
{"code":"K31.5","desc":"Nepriechodnosť dvanástnika"},
{"code":"K31.6","desc":"Fistula žalúdka a dvanástnika"},
{"code":"K31.7","desc":"Polyp žalúdka a dvanástnika"},
{"code":"K31.8-","desc":"Iná choroba žalúdka a dvanástnika, bližšie určená"},
{"code":"K31.81","desc":"Angiodysplázia žalúdka a dvanástnika bez prejavov krvácania"},
{"code":"K31.82","desc":"Angiodysplázia žalúdka a dvanástnika s prejavmi krvácania"},
{"code":"K31.88","desc":"Iná choroba žalúdka a dvanástnika, bližšie určená"},
{"code":"K31.9","desc":"Choroba žalúdka a dvanástnika, bližšie neurčená"},
{"code":"K35-K38","desc":"Choroby červovitého prívesku"},
{"code":"K35.-","desc":"Akútna apendicitída"},
{"code":"K35.2","desc":"Akútna apendicitída s generalizovanou peritonitídou"},
{"code":"K35.3-","desc":"Akútna apendicitída s lokalizovanou peritonitídou"},
{"code":"K35.30","desc":"Akútna apendicitída s lokalizovanou peritonitídou, bez perforácie alebo ruptúry"},
{"code":"K35.31","desc":"Akútna apendicitída s lokalizovanou peritonitídou, s perforáciou alebo ruptúrou"},
{"code":"K35.32","desc":"Akútna apendicitída s abscesom pobrušnice"},
{"code":"K35.8","desc":"Akútna apendicitída, bližšie neurčená"},
{"code":"K36","desc":"Iná apendicitída"},
{"code":"K37","desc":"Apendicitída, bližšie neurčená"},
{"code":"K38.-","desc":"Iná choroba červovitého prívesku"},
{"code":"K38.0","desc":"Hyperplázia červovitého prívesku"},
{"code":"K38.1","desc":"Konkrementy červovitého prívesku"},
{"code":"K38.2","desc":"Divertikul červovitého prívesku"},
{"code":"K38.3","desc":"Fistula červovitého prívesku"},
{"code":"K38.8","desc":"Iná choroba červovitého prívesku, bližšie určená"},
{"code":"K38.9","desc":"Choroba červovitého prívesku, bližšie neurčená"},
{"code":"K40-K46","desc":"Prietrže [hernie]"},
{"code":"K40.-","desc":"Slabinová prietrž"},
{"code":"K40.0-","desc":"Obojstranná slabinová prietrž s obštrukciou bez gangrény"},
{"code":"K40.00","desc":"Obojstranná slabinová prietrž s obštrukciou bez gangrény, neoznačená ako recidivujúca"},
{"code":"K40.01","desc":"Obojstranná slabinová prietrž s obštrukciou bez gangrény, recidivujúca"},
{"code":"K40.1-","desc":"Obojstranná slabinová prietrž s gangrénou"},
{"code":"K40.10","desc":"Obojstranná slabinová prietrž s gangrénou, neoznačená ako recidivujúca"},
{"code":"K40.11","desc":"Obojstranná slabinová prietrž s gangrénou, recidivujúca"},
{"code":"K40.2-","desc":"Obojstranná slabinová prietrž bez obštrukcie a bez gangrény"},
{"code":"K40.20","desc":"Obojstranná slabinová prietrž bez obštrukcie alebo gangrény, neoznačená ako recidivujúca"},
{"code":"K40.21","desc":"Obojstranná slabinová prietrž bez obštrukcie alebo gangrény, recidivujúca"},
{"code":"K40.3-","desc":"Jednostranná alebo neurčená slabinová prietrž s obštrukciou bez gangrény"},
{"code":"K40.30","desc":"Jednostranná alebo neurčená slabinová prietrž s obštrukciou bez gangrény, neoznačená ako recidivujúca"},
{"code":"K40.31","desc":"Jednostranná alebo neurčená slabinová prietrž s obštrukciou bez gangrény, recidivujúca"},
{"code":"K40.4-","desc":"Jednostranná alebo neurčená slabinová prietrž s gangrénou"},
{"code":"K40.40","desc":"Jednostranná alebo neurčená slabinová prietrž s gangrénou, neoznačená ako recidivujúca"},
{"code":"K40.41","desc":"Jednostranná alebo neurčená slabinová prietrž s gangrénou, recidivujúca"},
{"code":"K40.9-","desc":"Jednostranná alebo neurčená slabinová prietrž bez obštrukcie a bez gangrény"},
{"code":"K40.90","desc":"Jednostranná alebo neurčená slabinová prietrž bez obštrukcie a bez gangrény, neoznačená ako recidivujúca"},
{"code":"K40.91","desc":"Jednostranná alebo neurčená slabinová prietrž bez obštrukcie a bez gangrény, recidivujúca"},
{"code":"K41.-","desc":"Stehnová prietrž"},
{"code":"K41.0","desc":"Obojstranná stehnová prietrž s obštrukciou bez gangrény"},
{"code":"K41.1","desc":"Obojstranná stehnová prietrž s gangrénou"},
{"code":"K41.2","desc":"Obojstranná stehnová prietrž bez obštrukcie a bez gangrény"},
{"code":"K41.3","desc":"Jednostranná alebo bližšie neurčená stehnová prietrž s obštrukciou bez gangrény"},
{"code":"K41.4","desc":"Jednostranná alebo bližšie neurčená stehnová prietrž s gangrénou"},
{"code":"K41.9","desc":"Jednostranná alebo bližšie neurčená stehnová prietrž bez obštrukcie a bez gangrény"},
{"code":"K42.-","desc":"Pupková prietrž"},
{"code":"K42.0","desc":"Pupková prietrž s obštrukciou bez gangrény"},
{"code":"K42.1","desc":"Pupková prietrž s gangrénou"},
{"code":"K42.9","desc":"Pupková prietrž bez obštrukcie a bez gangrény"},
{"code":"K43.-","desc":"Brušná prietrž [hernia ventralis]"},
{"code":"K43.0","desc":"Prietrž v jazve s obštrukciou bez gangrény"},
{"code":"K43.1","desc":"Prietrž v jazve s gangrénou"},
{"code":"K43.2","desc":"Prietrž v jazve bez obštrukcie a bez gangrény"},
{"code":"K43.3","desc":"Parastomická prietrž s obštrukciou bez gangrény"},
{"code":"K43.4","desc":"Parastomická prietrž s gangrénou"},
{"code":"K43.5","desc":"Parastomická prietrž bez obštrukcie a bez gangrény"},
{"code":"K43.6","desc":"Iná a bližšie neurčená prietrž s obštrukciou bez gangrény"},
{"code":"K43.7","desc":"Iná a bližšie neurčená prietrž s gangrénou"},
{"code":"K43.9","desc":"Iná a bližšie neurčená prietrž bez obštrukcie a bez gangrény"},
{"code":"K44.-","desc":"Bránicová prietrž"},
{"code":"K44.0","desc":"Bránicová prietrž s obštrukciou bez gangrény"},
{"code":"K44.1","desc":"Bránicová prietrž s gangrénou"},
{"code":"K44.9","desc":"Bránicová prietrž bez obštrukcie a bez gangrény"},
{"code":"K45.-","desc":"Iná brušná prietrž"},
{"code":"K45.0","desc":"Iná brušná prietrž, bližšie určená s obštrukciou bez gangrény"},
{"code":"K45.1","desc":"Iná brušná prietrž, bližšie určená s gangrénou"},
{"code":"K45.8","desc":"Iná brušná prietrž, bližšie určená bez obštrukcie a bez gangrény"},
{"code":"K46.-","desc":"Brušná prietrž, bližšie neurčená"},
{"code":"K46.0","desc":"Brušná prietrž, bližšie neurčená s obštrukciou bez gangrény"},
{"code":"K46.1","desc":"Brušná prietrž, bližšie neurčená s gangrénou"},
{"code":"K46.9","desc":"Brušná prietrž, bližšie neurčená bez obštrukcie a bez gangrény"},
{"code":"K50-K52","desc":"Neinfekčné zápaly tenkého a hrubého čreva"},
{"code":"K50.-","desc":"Crohnova choroba [Enteritis regionalis]"},
{"code":"K50.0","desc":"Crohnova choroba tenkého čreva"},
{"code":"K50.1","desc":"Crohnova choroba hrubého čreva"},
{"code":"K50.8-","desc":"Iná Crohnova choroba"},
{"code":"K50.80","desc":"Crohnova choroba žalúdka"},
{"code":"K50.81","desc":"Crohnova choroba pažeráka"},
{"code":"K50.82","desc":"Crohnova choroba viacerých častí tráviacej trubice"},
{"code":"K50.88","desc":"Iná Crohnova choroba"},
{"code":"K50.9","desc":"Crohnova choroba, bližšie neurčená"},
{"code":"K51.-","desc":"Ulcerózna kolitída"},
{"code":"K51.0","desc":"Ulcerózna (chronická) pankolitída"},
{"code":"K51.2","desc":"Ulcerózna (chronická) proktitída"},
{"code":"K51.3","desc":"Ulcerózna (chronická) rektosigmoiditída"},
{"code":"K51.4","desc":"Zápalové polypy hrubého čreva"},
{"code":"K51.5","desc":"Ľavostranná kolitída"},
{"code":"K51.8","desc":"Iná ulcerózna kolitída"},
{"code":"K51.9","desc":"Ulcerózna kolitída, bližšie neurčená"},
{"code":"K52.-","desc":"Iná neinfekčná gastroenteritída a kolitída"},
{"code":"K52.0","desc":"Gastroenteritída a kolitída zapríčinená radiáciou (postradiačná)"},
{"code":"K52.1","desc":"Toxická gastroenteritída a kolitída"},
{"code":"K52.2","desc":"Alergická a potravinová gastroenteritída a kolitída"},
{"code":"K52.3-","desc":"Neurčitá kolitída [colitis indeterminata]"},
{"code":"K52.30","desc":"Neurčitá pankolitída"},
{"code":"K52.31","desc":"Neurčitá kolitída colon descendens"},
{"code":"K52.32","desc":"Neurčitá kolitída colon rectosigmoideum"},
{"code":"K52.38","desc":"Iná neurčitá kolitída"},
{"code":"K52.8","desc":"Iná neinfekčná gastroenteritída a kolitída, bližšie určená"},
{"code":"K52.9","desc":"Neinfekčná gastroenteritída a kolitída, bližšie neurčená"},
{"code":"K55-K64","desc":"Iné choroby čriev"},
{"code":"K55.-","desc":"Cievna choroba čreva"},
{"code":"K55.0","desc":"Akútna cievna choroba čreva"},
{"code":"K55.1","desc":"Chronická cievna choroba čreva"},
{"code":"K55.2-","desc":"Angiodysplázia hrubého čreva"},
{"code":"K55.21","desc":"Angiodysplázia hrubého čreva, bez prejavov krvácania"},
{"code":"K55.22","desc":"Angiodysplázia hrubého čreva, s krvácaním"},
{"code":"K55.8-","desc":"Iná cievna choroba čreva"},
{"code":"K55.81","desc":"Angiodysplázia tenkého čreva, bez prejavov krvácania"},
{"code":"K55.82","desc":"Angiodysplázia tenkého čreva, s krvácaním"},
{"code":"K55.88","desc":"Iná cievna choroba čreva"},
{"code":"K55.9","desc":"Cievna choroba čreva, bližšie neurčená"},
{"code":"K56.-","desc":"Paralytický ileus a mechanický ileus bez prietrže"},
{"code":"K56.0","desc":"Paralytický ileus"},
{"code":"K56.1","desc":"Invaginácia"},
{"code":"K56.2","desc":"Volvulus"},
{"code":"K56.3","desc":"Ileus zapríčinený žlčovým kameňom"},
{"code":"K56.4","desc":"Iná obturácia čreva"},
{"code":"K56.5","desc":"Črevné adhézie [zrasty] s nepriechodnosťou"},
{"code":"K56.6","desc":"Iný a bližšie neurčený mechanický ileus"},
{"code":"K56.7","desc":"Ileus, bližšie neurčený"},
{"code":"K57.-","desc":"Divertikulóza čreva"},
{"code":"K57.0-","desc":"Divertikulóza tenkého čreva s perforáciou a abscesom"},
{"code":"K57.00","desc":"Divertikulóza tenkého čreva s perforáciou a abscesom, bez prejavov krvácania"},
{"code":"K57.01","desc":"Divertikulóza tenkého čreva s perforáciou, abscesom a krvácaním"},
{"code":"K57.02","desc":"Divertikulitída tenkého čreva s perforáciou a abscesom, bez prejavov krvácania"},
{"code":"K57.03","desc":"Divertikulitída tenkého čreva s perforáciou, abscesom a krvácaním"},
{"code":"K57.1-","desc":"Divertikulóza tenkého čreva bez perforácie alebo abscesu"},
{"code":"K57.10","desc":"Divertikulóza tenkého čreva bez perforácie, abscesu alebo prejavov krvácania"},
{"code":"K57.11","desc":"Divertikulóza tenkého čreva bez perforácie a abscesu, s krvácaním"},
{"code":"K57.12","desc":"Divertikulitída tenkého čreva bez perforácie, abscesu alebo prejavov krvácania"},
{"code":"K57.13","desc":"Divertikulitída tenkého čreva bez perforácie a abscesu, s krvácaním"},
{"code":"K57.2-","desc":"Divertikulóza hrubého čreva s perforáciou a abscesom"},
{"code":"K57.20","desc":"Divertikulóza hrubého čreva s perforáciou a abscesom, bez prejavov krvácania"},
{"code":"K57.21","desc":"Divertikulóza hrubého čreva s perforáciou, abscesom a krvácaním"},
{"code":"K57.22","desc":"Divertikulitída hrubého čreva s perforáciou a abscesom, bez prejavov krvácania"},
{"code":"K57.23","desc":"Divertikulitída hrubého čreva s perforáciou, abscesom a krvácaním"},
{"code":"K57.3-","desc":"Divertikulóza hrubého čreva bez perforácie alebo abscesu"},
{"code":"K57.30","desc":"Divertikulóza hrubého čreva bez perforácie, abscesu alebo prejavov krvácania"},
{"code":"K57.31","desc":"Divertikulóza hrubého čreva bez perforácie alebo abscesu, s krvácaním"},
{"code":"K57.32","desc":"Divertikulitída hrubého čreva bez perforácie, abscesu alebo prejavov krvácania"},
{"code":"K57.33","desc":"Divertikulitída hrubého čreva bez perforácie alebo abscesu, s krvácaním"},
{"code":"K57.4-","desc":"Divertikulóza tenkého aj hrubého čreva s perforáciou a abscesom"},
{"code":"K57.40","desc":"Divertikulóza tenkého a hrubého čreva s perforáciou a abscesom, bez prejavov krvácania"},
{"code":"K57.41","desc":"Divertikulóza tenkého a hrubého čreva s perforáciou, abscesom a krvácaním"},
{"code":"K57.42","desc":"Divertikulitída tenkého a hrubého čreva s perforáciou a abscesom, bez prejavov krvácania"},
{"code":"K57.43","desc":"Divertikulitída tenkého a hrubého čreva s perforáciou, abscesom a krvácaním"},
{"code":"K57.5-","desc":"Divertikulóza tenkého aj hrubého čreva bez perforácie alebo abscesu"},
{"code":"K57.50","desc":"Divertikulóza tenkého a hrubého čreva bez perforácie, abscesu alebo prejavov krvácania"},
{"code":"K57.51","desc":"Divertikulóza tenkého a hrubého čreva bez perforácie alebo abscesu, s krvácaním"},
{"code":"K57.52","desc":"Divertikulitída tenkého a hrubého čreva bez perforácie, abscesu alebo prejavov krvácania"},
{"code":"K57.53","desc":"Divertikulitída tenkého a hrubého čreva bez perforácie alebo abscesu, s krvácaním"},
{"code":"K57.8-","desc":"Divertikulóza čreva, časť bližšie neurčená, s perforáciou a abscesom"},
{"code":"K57.80","desc":"Divertikulóza čreva, časť bližšie neurčená, s perforáciou a abscesom, bez prejavov krvácania"},
{"code":"K57.81","desc":"Divertikulóza čreva, časť bližšie neurčená, s perforáciou, abscesom a krvácaním"},
{"code":"K57.82","desc":"Divertikulitída čreva, časť bližšie neurčená, s perforáciou a abscesom, bez prejavov krvácania"},
{"code":"K57.83","desc":"Divertikulitída čreva, časť bližšie neurčená, s perforáciou, abscesom a krvácaním"},
{"code":"K57.9-","desc":"Divertikulóza čreva, časť bližšie neurčená, bez perforácie alebo abscesu"},
{"code":"K57.90","desc":"Divertikulóza čreva, časť bližšie neurčená, bez perforácie, abscesu alebo prejavov krvácania"},
{"code":"K57.91","desc":"Divertikulóza čreva, časť bližšie neurčená, bez perforácie alebo abscesu, s krvácaním"},
{"code":"K57.92","desc":"Divertikulitída čreva, časť bližšie neurčená, bez perforácie, abscesu alebo prejavov krvácania"},
{"code":"K57.93","desc":"Divertikulitída čreva, časť bližšie neurčená, bez perforácie alebo abscesu, s krvácaním"},
{"code":"K58.-","desc":"Syndróm dráždivého čreva"},
{"code":"K58.0","desc":"Syndróm dráždivého čreva s hnačkou"},
{"code":"K58.9","desc":"Syndróm dráždivého čreva bez hnačky"},
{"code":"K59.-","desc":"Iná funkčná porucha čreva"},
{"code":"K59.0","desc":"Zápcha"},
{"code":"K59.1","desc":"Funkčná hnačka"},
{"code":"K59.2","desc":"Neurogénne črevo, nezatriedené inde"},
{"code":"K59.3","desc":"Megakolon, nezatriedený inde"},
{"code":"K59.4","desc":"Análny spazmus"},
{"code":"K59.8","desc":"Iná funkčná porucha čreva, bližšie určená"},
{"code":"K59.9","desc":"Funkčná črevná porucha, bližšie neurčená"},
{"code":"K60.-","desc":"Fisúra a fistula análnej a rektálnej oblasti"},
{"code":"K60.0","desc":"Akútna análna fisúra"},
{"code":"K60.1","desc":"Chronická análna fisúra"},
{"code":"K60.2","desc":"Análna fisúra, bližšie neurčená"},
{"code":"K60.3","desc":"Análna fistula"},
{"code":"K60.4","desc":"Rektálna fistula"},
{"code":"K60.5","desc":"Anorektálna fistula"},
{"code":"K61.-","desc":"Absces v análnej a rektálnej oblasti"},
{"code":"K61.0","desc":"Análny absces"},
{"code":"K61.1","desc":"Rektálny absces"},
{"code":"K61.2","desc":"Anorektálny absces"},
{"code":"K61.3","desc":"Ischiorektálny absces"},
{"code":"K61.4","desc":"Vnútrosfinkterový absces"},
{"code":"K62.-","desc":"Iné choroby anusu a konečníka"},
{"code":"K62.0","desc":"Polyp anusu"},
{"code":"K62.1","desc":"Polyp konečníka"},
{"code":"K62.2","desc":"Prolaps anusu"},
{"code":"K62.3","desc":"Prolaps konečníka"},
{"code":"K62.4","desc":"Stenóza anusu a konečníka"},
{"code":"K62.5","desc":"Krvácanie z anusu a konečníka"},
{"code":"K62.6","desc":"Vred anusu a konečníka"},
{"code":"K62.7","desc":"Radiačná proktitída"},
{"code":"K62.8","desc":"Iná choroba anusu a konečníka, bližšie určená"},
{"code":"K62.9","desc":"Choroba anusu a konečníka, bližšie neurčená"},
{"code":"K63.-","desc":"Iné choroby čreva"},
{"code":"K63.0","desc":"Absces čreva"},
{"code":"K63.1","desc":"Perforácia čreva (neúrazová)"},
{"code":"K63.2","desc":"Fistula čreva"},
{"code":"K63.3","desc":"Vred čreva"},
{"code":"K63.4","desc":"Enteroptóza"},
{"code":"K63.5","desc":"Polyp hrubého čreva"},
{"code":"K63.8","desc":"Iné choroby čreva, bližšie určené"},
{"code":"K63.9","desc":"Choroba čreva, bližšie neurčená"},
{"code":"K64.-","desc":"Hemoroidy a perianálna žilová trombóza"},
{"code":"K64.0","desc":"Hemoroidy 1. stupňa"},
{"code":"K64.1","desc":"Hemoroidy 2. stupňa"},
{"code":"K64.2","desc":"Hemoroidy 3. stupňa"},
{"code":"K64.3","desc":"Hemoroidy 4. stupňa"},
{"code":"K64.4","desc":"Reziduálne hemoroidálne kožné protuberancie"},
{"code":"K64.5","desc":"Perianálna žilová trombóza"},
{"code":"K64.8","desc":"Iné hemoroidy, bližšie určené"},
{"code":"K64.9","desc":"Hemoroidy, bližšie neurčené"},
{"code":"K65-K67","desc":"Choroby pobrušnice"},
{"code":"K65.-","desc":"Zápal pobrušnice"},
{"code":"K65.0","desc":"Akútny zápal pobrušnice"},
{"code":"K65.8","desc":"Iný zápal pobrušnice"},
{"code":"K65.9","desc":"Zápal pobrušnice, bližšie neurčený"},
{"code":"K66.-","desc":"Iné choroby pobrušnice"},
{"code":"K66.0","desc":"Pobrušnicové zrasty"},
{"code":"K66.1","desc":"Hemoperitoneum"},
{"code":"K66.8","desc":"Iné choroby pobrušnice, bližšie určené"},
{"code":"K66.9","desc":"Choroba pobrušnice, bližšie neurčená"},
{"code":"K67.-*","desc":"Choroba pobrušnice pri infekčnej chorobe zatriedenej inde"},
{"code":"K67.0*","desc":"Zápal pobrušnice, zapríčinený chlamýdiami (A74.8†)"},
{"code":"K67.1*","desc":"Gonokokový zápal pobrušnice (A54.8†)"},
{"code":"K67.2*","desc":"Syfilitický zápal pobrušnice (A52.7†)"},
{"code":"K67.3*","desc":"Tuberkulózny zápal pobrušnice (A18.3†)"},
{"code":"K67.8*","desc":"Iné choroby pobrušnice pri infekčných chorobách zatriedených inde"},
{"code":"K70-K77","desc":"Choroby pečene"},
{"code":"K70.-","desc":"Alkoholová choroba pečene"},
{"code":"K70.0","desc":"Alkoholová tuková choroba pečene"},
{"code":"K70.1","desc":"Alkoholová hepatitída"},
{"code":"K70.2","desc":"Alkoholová fibróza a skleróza pečene"},
{"code":"K70.3","desc":"Alkoholová cirhóza pečene"},
{"code":"K70.4","desc":"Alkoholové zlyhávanie pečene"},
{"code":"K70.9","desc":"Alkoholová choroba pečene, bližšie neurčená"},
{"code":"K71.-","desc":"Toxická choroba pečene"},
{"code":"K71.0","desc":"Toxická choroba pečene s cholestázou"},
{"code":"K71.1","desc":"Toxická choroba pečene s pečeňovou nekrózou"},
{"code":"K71.2","desc":"Toxická choroba pečene s akútnou hepatitídou"},
{"code":"K71.3","desc":"Toxická choroba pečene s chronickou perzistujúcou hepatitídou"},
{"code":"K71.4","desc":"Toxická choroba pečene s chronickou lobulárnou hepatitídou"},
{"code":"K71.5","desc":"Toxická choroba pečene s chronickou aktívnou hepatitídou"},
{"code":"K71.6","desc":"Toxická choroba pečene s hepatitídou, nezatriedená inde"},
{"code":"K71.7","desc":"Toxická choroba pečene s fibrózou a cirhózou pečene"},
{"code":"K71.8","desc":"Toxická choroba pečene s inými poruchami pečene"},
{"code":"K71.9","desc":"Toxická choroba pečene, bližšie neurčená"},
{"code":"K72.-","desc":"Zlyhávanie pečene, nezatriedené inde"},
{"code":"K72.0","desc":"Akútne a subakútne pečeňové zlyhávanie"},
{"code":"K72.1","desc":"Chronické pečeňové zlyhávanie"},
{"code":"K72.7-!","desc":"Pečeňová encefalopatia a pečeňová kóma"},
{"code":"K72.71!","desc":"Pečeňová encefalopatia,1. stupeň"},
{"code":"K72.72!","desc":"Pečeňová encefalopatia, 2. stupeň"},
{"code":"K72.73!","desc":"Pečeňová encefalopatia, 3. stupeň"},
{"code":"K72.74!","desc":"Pečeňová encefalopatia, 4. stupeň"},
{"code":"K72.79!","desc":"Pečeňová encefalopatia neurčeného stupňa"},
{"code":"K72.9","desc":"Pečeňové zlyhávanie, bližšie neurčené"},
{"code":"K73.-","desc":"Chronická hepatitída, nezatriedená inde"},
{"code":"K73.0","desc":"Chronická perzistujúca hepatitída, nezatriedená inde"},
{"code":"K73.1","desc":"Chronická lobulárna hepatitída, nezatriedená inde"},
{"code":"K73.2","desc":"Chronická aktívna hepatitída, nezatriedená inde"},
{"code":"K73.8","desc":"Iná chronická hepatitída, nezatriedená inde"},
{"code":"K73.9","desc":"Chronická hepatitída, bližšie neurčená"},
{"code":"K74.-","desc":"Fibróza a cirhóza pečene"},
{"code":"K74.0","desc":"Fibróza pečene"},
{"code":"K74.1","desc":"Skleróza pečene"},
{"code":"K74.2","desc":"Fibróza pečene so sklerózou pečene"},
{"code":"K74.3","desc":"Primárna biliárna cirhóza"},
{"code":"K74.4","desc":"Sekundárna biliárna cirhóza"},
{"code":"K74.5","desc":"Biliárna cirhóza, bližšie neurčená"},
{"code":"K74.6","desc":"Iná a bližšie neurčená cirhóza pečene"},
{"code":"K75.-","desc":"Iné zápalové choroby pečene"},
{"code":"K75.0","desc":"Absces pečene"},
{"code":"K75.1","desc":"Flebitída vrátnice (v. portae)"},
{"code":"K75.2","desc":"Nešpecifická reaktívna hepatitída"},
{"code":"K75.3","desc":"Granulomatózna hepatitída, nezatriedená inde"},
{"code":"K75.4","desc":"Autoimunitná hepatitída"},
{"code":"K75.8","desc":"Iná zápalová choroba pečene, bližšie určená"},
{"code":"K75.9","desc":"Zápalová choroba pečene, bližšie neurčená"},
{"code":"K76.-","desc":"Iná choroba pečene"},
{"code":"K76.0","desc":"Stukovatenie pečene, nezatriedené inde"},
{"code":"K76.1","desc":"Chronické pasívne prekrvenie (kongescia) pečene"},
{"code":"K76.2","desc":"Centrálna hemoragická nekróza pečene"},
{"code":"K76.3","desc":"Infarkt pečene"},
{"code":"K76.4","desc":"Pelióza pečene"},
{"code":"K76.5","desc":"Venookluzívna choroba pečene (Stuartov-Brasov syndróm)"},
{"code":"K76.6","desc":"Portálna hypertenzia"},
{"code":"K76.7","desc":"Hepatorenálny syndróm"},
{"code":"K76.8","desc":"Iná choroba pečene, bližšie určená"},
{"code":"K76.9","desc":"Choroba pečene, bližšie neurčená"},
{"code":"K77.-*","desc":"Choroba pečene pri chorobách zatriedených inde"},
{"code":"K77.0*","desc":"Pečeňová porucha pri infekčnej alebo parazitovej chorobe zatriedenej inde"},
{"code":"K77.1-*","desc":"Postihnutie pečene pri akútnej chorobe štepu proti hostiteľovi [GVHD]"},
{"code":"K77.11*","desc":"Postihnutie pečene pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 1.štádium (T86.01†)"},
{"code":"K77.12*","desc":"Postihnutie pečene pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 2.štádium (T86.02†)"},
{"code":"K77.13*","desc":"Postihnutie pečene pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 3.štádium (T86.02†)"},
{"code":"K77.14*","desc":"Postihnutie pečene pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 4.štádium (T86.02†)"},
{"code":"K77.8*","desc":"Pečeňová porucha pri iných chorobách zatriedených inde"},
{"code":"K80-K87","desc":"Choroby žlčníka, žlčových ciest a podžalúdkovej žľazy"},
{"code":"K80.-","desc":"Žlčové kamene [cholelitiáza]"},
{"code":"K80.0-","desc":"Kameň žlčníka s akútnou cholecystitídou"},
{"code":"K80.00","desc":"Kameň žlčníka s akútnou cholecystitídou, bez prejavov obštrukcie žlčových ciest"},
{"code":"K80.01","desc":"Kameň žlčníka s akútnou cholecystitídou, s obštrukciou žlčových ciest"},
{"code":"K80.1-","desc":"Kameň žlčníka s inou cholecystitídou"},
{"code":"K80.10","desc":"Kameň žlčníka s inou cholecystitídou, bez prejavov obštrukcie žlčových ciest"},
{"code":"K80.11","desc":"Kameň žlčníka s inou cholecystitídou, s obštrukciou žlčových ciest"},
{"code":"K80.2-","desc":"Kameň žlčníka bez cholecystitídy"},
{"code":"K80.20","desc":"Kameň žlčníka bez cholecystitídy, bez prejavov obštrukcie žlčových ciest"},
{"code":"K80.21","desc":"Kameň žlčníka bez cholecystitídy, s obštrukciou žlčových ciest"},
{"code":"K80.3-","desc":"Kameň žlčového vývodu s cholangitídou"},
{"code":"K80.30","desc":"Kameň žlčového vývodu s cholangitídou, bez prejavov obštrukcie žlčových ciest"},
{"code":"K80.31","desc":"Kameň žlčového vývodu s cholangitídou, s obštrukciou žlčových ciest"},
{"code":"K80.4-","desc":"Kameň žlčového vývodu s cholecystitídou"},
{"code":"K80.40","desc":"Kameň žlčového vývodu s cholecystitídou, bez prejavov obštrukcie žlčových ciest"},
{"code":"K80.41","desc":"Kameň žlčového vývodu s cholecystitídou, s obštrukciou žlčových ciest"},
{"code":"K80.5-","desc":"Kameň žlčového vývodu bez cholangitídy alebo cholecystitídy"},
{"code":"K80.50","desc":"Kameň žlčového vývodu bez cholangitídy alebo cholecystitídy, bez prejavov obštrukcie žlčových ciest"},
{"code":"K80.51","desc":"Kameň žlčového vývodu bez cholangitídy alebo cholecystitídy, s obštrukciou žlčových ciest"},
{"code":"K80.8-","desc":"Iná cholelitiáza"},
{"code":"K80.80","desc":"Iná cholelitiáza, bez prejavov obštrukcie žlčových ciest"},
{"code":"K80.81","desc":"Iná cholelitiáza, s obštrukciou žlčových ciest"},
{"code":"K81.-","desc":"Cholecystitída"},
{"code":"K81.0","desc":"Akútna cholecystitída"},
{"code":"K81.1","desc":"Chronická cholecystitída"},
{"code":"K81.8","desc":"Iná cholecystitída"},
{"code":"K81.9","desc":"Cholecystitída, bližšie neurčená"},
{"code":"K82.-","desc":"Iná choroba žlčníka"},
{"code":"K82.0","desc":"Obštrukcia žlčníka"},
{"code":"K82.1","desc":"Hydrops žlčníka"},
{"code":"K82.2","desc":"Perforácia žlčníka"},
{"code":"K82.3","desc":"Fistula žlčníka"},
{"code":"K82.4","desc":"Cholesterolóza žlčníka"},
{"code":"K82.8","desc":"Iná choroba žlčníka, bližšie určená"},
{"code":"K82.9","desc":"Choroba žlčníka, bližšie neurčená"},
{"code":"K83.-","desc":"Iná choroba žlčových ciest"},
{"code":"K83.0","desc":"Zápal žlčových ciest"},
{"code":"K83.1","desc":"Obštrukcia žlčových ciest"},
{"code":"K83.2","desc":"Perforácia žlčových ciest"},
{"code":"K83.3","desc":"Fistula žlčových ciest"},
{"code":"K83.4","desc":"Spazmus Oddiho zvierača"},
{"code":"K83.5","desc":"Žlčová cysta"},
{"code":"K83.8","desc":"Iná choroba žlčových ciest, bližšie určená"},
{"code":"K83.9","desc":"Choroba žlčových ciest, bližšie neurčená"},
{"code":"K85.-","desc":"Akútna pankreatitída"},
{"code":"K85.0-","desc":"Idiopatická akútna pankreatitída"},
{"code":"K85.00","desc":"Idiopatická akútna pankreatitída, bez orgánovej komplikácie"},
{"code":"K85.01","desc":"Idiopatická akútna pankreatitída, s orgánovou komplikáciou"},
{"code":"K85.1-","desc":"Akútna biliárna pankreatitída"},
{"code":"K85.10","desc":"Akútna biliárna pankreatitída, bez orgánovej komplikácie"},
{"code":"K85.11","desc":"Akútna biliárna pankreatitída, s orgánovou komplikáciou"},
{"code":"K85.2-","desc":"Akútna pankreatitída zapríčinená alkoholom"},
{"code":"K85.20","desc":"Akútna pankreatitída zapríčinená alkoholom, bez orgánovej komplikácie"},
{"code":"K85.21","desc":"Akútna pankreatitída zapríčinená alkoholom, s orgánovou komplikáciou"},
{"code":"K85.3-","desc":"Akútna pankreatitída zapríčinená liekmi"},
{"code":"K85.30","desc":"Akútna pankreatitída zapríčinená liekmi, bez orgánovej komplikácie"},
{"code":"K85.31","desc":"Akútna pankreatitída zapríčinená liekmi, s orgánovou komplikáciou"},
{"code":"K85.8-","desc":"Iná akútna pankreatitída"},
{"code":"K85.80","desc":"Iná akútna pankreatitída, bez orgánovej komplikácie"},
{"code":"K85.81","desc":"Iná akútna pankreatitída, s orgánovou komplikáciou"},
{"code":"K85.9-","desc":"Akútna pankreatitída, bližšie neurčená"},
{"code":"K85.90","desc":"Akútna pankreatitída, bližšie neurčená, bez orgánovej komplikácie"},
{"code":"K85.91","desc":"Akútna pankreatitída, bližšie neurčená, s orgánovou komplikáciou"},
{"code":"K86.-","desc":"Iná choroba podžalúdkovej žľazy"},
{"code":"K86.0","desc":"Chronická pankreatitída zapríčinená alkoholom"},
{"code":"K86.1","desc":"Iná chronická pankreatitída"},
{"code":"K86.2","desc":"Cysta pankreasu"},
{"code":"K86.3","desc":"Pseudocysta pankreasu"},
{"code":"K86.8","desc":"Iná choroba podžalúdkovej žľazy, bližšie určená"},
{"code":"K86.9","desc":"Choroba podžalúdkovej žľazy, bližšie neurčená"},
{"code":"K87.-*","desc":"Choroby žlčníka, žlčových ciest a podžalúdkovej žľazy pri chorobách zatriedených inde"},
{"code":"K87.0*","desc":"Choroby žlčníka a žlčových ciest pri chorobách zatriedených inde"},
{"code":"K87.1*","desc":"Choroby pankreasu pri chorobách zatriedených inde"},
{"code":"K90-K93","desc":"Iné choroby tráviacej sústavy"},
{"code":"K90.-","desc":"Črevná malabsorpcia"},
{"code":"K90.0","desc":"Celiakia"},
{"code":"K90.1","desc":"Tropická sprue"},
{"code":"K90.2","desc":"Syndróm slepej slučky, nezatriedený inde"},
{"code":"K90.3","desc":"Pankreatická steatorea"},
{"code":"K90.31","desc":"Syndróm črevného prerastania (SIBO)"},
{"code":"K90.4","desc":"Malabsorpcia zapríčinená neznášanlivosťou, nezatriedená inde"},
{"code":"K90.8","desc":"Iná črevná malabsorpcia"},
{"code":"K90.9","desc":"Črevná malabsorpcia, bližšie neurčená"},
{"code":"K91.-","desc":"Choroby tráviacej sústavy po lekárskom výkone, nezatriedené inde"},
{"code":"K91.0","desc":"Vracanie po gastrointestinálnom chirurgickom výkone"},
{"code":"K91.1","desc":"Syndrómy po operácii žalúdka"},
{"code":"K91.2","desc":"Malabsorpcia po chirurgickom výkone, nezatriedená inde"},
{"code":"K91.3","desc":"Mechanická črevná nepriechodnosť po chirurgickom výkone"},
{"code":"K91.4","desc":"Porucha funkcie kolostómie a enterostómie"},
{"code":"K91.5","desc":"Postcholecystektomický syndróm"},
{"code":"K91.8-","desc":"Iná choroby tráviacej sústavy po lekárskom výkone, nezatriedené inde"},
{"code":"K91.80","desc":"Generalizovaná mukozitída pri imunokompromitácii"},
{"code":"K91.88","desc":"Iné porucha tráviacej sústavy po lekárskom výkone, nezatriedené inde"},
{"code":"K91.9","desc":"Porucha tráviacej sústavy po lekárskom výkone, bližšie neurčená"},
{"code":"K92.-","desc":"Iné choroby tráviacej sústavy"},
{"code":"K92.0","desc":"Hemateméza"},
{"code":"K92.1","desc":"Meléna"},
{"code":"K92.2","desc":"Gastrointestinálne krvácanie, bližšie neurčené"},
{"code":"K92.8","desc":"Iné choroby tráviacej sústavy, bližšie určené"},
{"code":"K92.9","desc":"Choroba tráviacej sústavy, bližšie neurčená"},
{"code":"K93.-*","desc":"Choroby iných tráviacich orgánov pri chorobách zatriedených inde"},
{"code":"K93.0*","desc":"Tuberkulóza čriev, pobrušnice a lymfatických uzlín mezentéria"},
{"code":"K93.1*","desc":"Megakolon pri Chagasovej chorobe (B57.3†)"},
{"code":"K93.2-*","desc":"Postihnutie tráviacej trubice pri akútnej chorobe štepu proti hostiteľovi [GVHD]"},
{"code":"K93.21*","desc":"Postihnutie tráviacej trubice pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 1.štádium (T86.01†)"},
{"code":"K93.22*","desc":"Postihnutie tráviacej trubice pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 2.štádium (T86.02†)"},
{"code":"K93.23*","desc":"Postihnutie tráviacej trubice pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 3.štádium (T86.02†)"},
{"code":"K93.24*","desc":"Postihnutie tráviacej trubice pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 4. štádium (T86.02†)"},
{"code":"K93.8*","desc":"Choroby iných tráviacich orgánov, bližšie určených, pri chorobách zatriedených inde"},
{"code":"L00-L99","desc":"XII. kapitola - Choroby kože a podkožného tkaniva (L00-L99)"},
{"code":"L00-L08","desc":"Infekcie kože a podkožného tkaniva"},
{"code":"L00.-","desc":"Syndróm obarenej kože zapríčinený stafylokokmi"},
{"code":"L00.0","desc":"Syndróm obarenej kože zapríčinený stafylokokmi, s postihnutím menej ako 30 % povrchu tela"},
{"code":"L00.1","desc":"Syndróm obarenej kože zapríčinený stafylokokmi, s postihnutím 30 % povrchu tela a viac"},
{"code":"L01.-","desc":"Impetigo"},
{"code":"L01.0","desc":"Kontaktné impetigo akejkoľvek lokalizácie zapríčinené ktorýmkoľvek organizmom"},
{"code":"L01.1","desc":"Sekundárna impetiginizácia inej choroby kože"},
{"code":"L02.-","desc":"Kožný absces, furunkul a karbunkul"},
{"code":"L02.0","desc":"Kožný absces, furunkul a karbunkul tváre"},
{"code":"L02.1","desc":"Kožný absces, furunkul a karbunkul krku"},
{"code":"L02.2","desc":"Kožný absces, furunkul a karbunkul trupu"},
{"code":"L02.3","desc":"Kožný absces, furunkul a karbunkul zadku"},
{"code":"L02.4","desc":"Kožný absces, furunkul a karbunkul končatiny"},
{"code":"L02.8","desc":"Kožný absces, furunkul a karbunkul na inom mieste"},
{"code":"L02.9","desc":"Kožný absces, furunkul a karbunkul, bližšie neurčený"},
{"code":"L03.-","desc":"Flegmóna"},
{"code":"L03.0-","desc":"Flegmóna prstov ruky a nohy"},
{"code":"L03.01","desc":"Flegmóna prstov ruky"},
{"code":"L03.02","desc":"Flegmóna prstov nohy"},
{"code":"L03.1-","desc":"Flegmóna iných častí končatín"},
{"code":"L03.10","desc":"Flegmóna hornej končatiny"},
{"code":"L03.11","desc":"Flegmóna dolnej končatiny"},
{"code":"L03.2","desc":"Flegmóna tváre"},
{"code":"L03.3","desc":"Flegmóna trupu"},
{"code":"L03.8","desc":"Flegmóna na inom mieste"},
{"code":"L03.9","desc":"Flegmóna, bližšie neurčená"},
{"code":"L04.-","desc":"Akútny zápal lymfatických uzlín"},
{"code":"L04.0","desc":"Akútny zápal lymfatických uzlín tváre, hlavy a krku"},
{"code":"L04.1","desc":"Akútny zápal lymfatických uzlín hrudníka"},
{"code":"L04.2","desc":"Akútny zápal lymfatických uzlín hornej končatiny"},
{"code":"L04.3","desc":"Akútny zápal lymfatických uzlín dolnej končatiny"},
{"code":"L04.8","desc":"Akútny zápal lymfatických uzlín na inom mieste"},
{"code":"L04.9","desc":"Akútny zápal lymfatických uzlín, bližšie neurčený"},
{"code":"L05.-","desc":"Pilonidálna cysta"},
{"code":"L05.0","desc":"Pilonidálna cysta s abscesom"},
{"code":"L05.9","desc":"Pilonidálna cysta bez abscesu"},
{"code":"L08.-","desc":"Iná lokálna infekcia kože a podkožného tkaniva"},
{"code":"L08.0","desc":"Pyoderma"},
{"code":"L08.1","desc":"Erythrasma"},
{"code":"L08.8","desc":"Iná lokálna infekcia kože a podkožného tkaniva, bližšie určená"},
{"code":"L08.9","desc":"Lokálna infekcia kože a podkožného tkaniva, bližšie neurčená"},
{"code":"L10-L14","desc":"Pľuzgierové (bulózne) dermatózy"},
{"code":"L10.-","desc":"Pemfigus"},
{"code":"L10.0","desc":"Pemphigus vulgaris"},
{"code":"L10.1","desc":"Pemphigus vegetans"},
{"code":"L10.2","desc":"Pemphigus foliaceus"},
{"code":"L10.3","desc":"Brazílsky pemfigus [fogo selvagem]"},
{"code":"L10.4","desc":"Pemphigus erythematosus"},
{"code":"L10.5","desc":"Pemfigus, zapríčinený liekom"},
{"code":"L10.8","desc":"Iný pemfigus"},
{"code":"L10.9","desc":"Pemfigus, bližšie neurčený"},
{"code":"L11.-","desc":"Iná akantolytická dermatóza"},
{"code":"L11.0","desc":"Získaná folikulová keratóza"},
{"code":"L11.1","desc":"Prechodná akantolytická dermatóza (Groverova)"},
{"code":"L11.8","desc":"Iná akantolytická dermatóza, bližšie určená"},
{"code":"L11.9","desc":"Akantolytická dermatóza, bližšie neurčená"},
{"code":"L12.-","desc":"Pemfigoid"},
{"code":"L12.0","desc":"Pľuzgierový (bulózny) pemfigoid"},
{"code":"L12.1","desc":"Jazvový pemfigoid"},
{"code":"L12.2","desc":"Chronická pľuzgierová (bulózna) choroba detského veku"},
{"code":"L12.3","desc":"Získaná pľuzgierová (bulózna) epidermolýza"},
{"code":"L12.8","desc":"Iný pemfigoid"},
{"code":"L12.9","desc":"Pemfigoid, bližšie neurčený"},
{"code":"L13.-","desc":"Iné pľuzgierové dermatózy"},
{"code":"L13.0","desc":"Herpetiformná dermatitída (dermatitis herpetiformis)"},
{"code":"L13.1","desc":"Dermatitis pustulosa subcornealis"},
{"code":"L13.8","desc":"Iné pľuzgierové (bulózne) dermatózy, bližšie určené"},
{"code":"L13.9","desc":"Pľuzgierová (bulózna) dermatóza, bližšie neurčená"},
{"code":"L14*","desc":"Pľuzgierová (bulózna) dermatóza pri chorobách zatriedených inde"},
{"code":"L20-L30","desc":"Dermatitídy a ekzémy"},
{"code":"L20.-","desc":"Atopická dermatitída"},
{"code":"L20.0","desc":"Besnierovo prurigo"},
{"code":"L20.8","desc":"Iná atopická dermatitída"},
{"code":"L20.9","desc":"Atopická dermatitída, bližšie neurčená"},
{"code":"L21.-","desc":"Seboroická dermatitída"},
{"code":"L21.0","desc":"Seborea hlavy"},
{"code":"L21.1","desc":"Seboroická dermatitída detského veku"},
{"code":"L21.8","desc":"Iná seboroická dermatitída"},
{"code":"L21.9","desc":"Seboroická dermatitída, bližšie neurčená"},
{"code":"L22","desc":"Plienková dermatitída"},
{"code":"L23.-","desc":"Alergická kontaktná dermatitída"},
{"code":"L23.0","desc":"Alergická kontaktná dermatitída zapríčinená kovmi"},
{"code":"L23.1","desc":"Alergická kontaktná dermatitída zapríčinená náplasťami"},
{"code":"L23.2","desc":"Alergická kontaktná dermatitída zapríčinená kozmetickými prípravkami"},
{"code":"L23.3","desc":"Alergická kontaktná dermatitída zapríčinená kontaktom kože s liekmi"},
{"code":"L23.4","desc":"Alergická kontaktná dermatitída zapríčinená farbivami"},
{"code":"L23.5","desc":"Alergická kontaktná dermatitída zapríčinená inými chemickými látkami"},
{"code":"L23.6","desc":"Alergická kontaktná dermatitída zapríčinená kontaktom kože s potravinami"},
{"code":"L23.7","desc":"Alergická kontaktná dermatitída zapríčinená rastlinami okrem rastlinných potravín"},
{"code":"L23.8","desc":"Alergická kontaktná dermatitída zapríčinená inými agensmi"},
{"code":"L23.9","desc":"Alergická kontaktná dermatitída s neurčenou príčinou"},
{"code":"L24.-","desc":"Iritačná kontaktná dermatitída"},
{"code":"L24.0","desc":"Kontaktná dermatitída z podráždenia zapríčinená čistiacimi prostriedkami"},
{"code":"L24.1","desc":"Kontaktná dermatitída z podráždenia zapríčinená olejmi a tukmi"},
{"code":"L24.2","desc":"Kontaktná dermatitída z podráždenia zapríčinená rozpúšťadlami"},
{"code":"L24.3","desc":"Kontaktná dermatitída z podráždenia zapríčinená kozmetickými prípravkami"},
{"code":"L24.4","desc":"Kontaktná dermatitída z podráždenia zapríčinená kontaktom liekov s kožou"},
{"code":"L24.5","desc":"Kontaktná dermatitída z podráždenia zapríčinená inými chemickými látkami"},
{"code":"L24.6","desc":"Kontaktná dermatitída z podráždenia zapríčinená kontaktom potravy s kožou"},
{"code":"L24.7","desc":"Kontaktná dermatitída z podráždenia zapríčinená rastlinami okrem rastlinných potravín"},
{"code":"L24.8","desc":"Kontaktná dermatitída z podráždenia zapríčinená inými agensmi"},
{"code":"L24.9","desc":"Kontaktná dermatitída z podráždenia s neurčenou príčinou"},
{"code":"L25.-","desc":"Kontaktná dermatitída, bližšie neurčená"},
{"code":"L25.0","desc":"Kontaktná dermatitída zapríčinená kozmetickými prípravkami, bližšie neurčená"},
{"code":"L25.1","desc":"Kontaktná dermatitída zapríčinená kontaktom liekov s kožou, bližšie neurčená"},
{"code":"L25.2","desc":"Kontaktná dermatitída zapríčinená farbivami, bližšie neurčená"},
{"code":"L25.3","desc":"Kontaktná dermatitída zapríčinená inými chemickými látkami, bližšie neurčená"},
{"code":"L25.4","desc":"Kontaktná dermatitída zapríčinená kontaktom potravy s kožou, bližšie neurčená"},
{"code":"L25.5","desc":"Kontaktná dermatitída zapríčinená rastlinami okrem rastlinných potravín, bližšie neurčená"},
{"code":"L25.8","desc":"Kontaktná dermatitída zapríčinená inými agensmi, bližšie neurčená"},
{"code":"L25.9","desc":"Kontaktná dermatitída bez určenia príčiny, bližšie neurčená"},
{"code":"L26","desc":"Exfoliatívna dermatitída"},
{"code":"L27.-","desc":"Dermatitída zapríčinená látkami užitými vnútorne"},
{"code":"L27.0","desc":"Generalizovaná kožná vyrážka zapríčinená liečivami a liekmi"},
{"code":"L27.1","desc":"Lokalizovaná kožná vyrážka zapríčinená liečivami a liekmi"},
{"code":"L27.2","desc":"Dermatitída zapríčinená požitou potravou"},
{"code":"L27.8","desc":"Dermatitída zapríčinená inou látkou užitou orálne, enterálne alebo parenterálne"},
{"code":"L27.9","desc":"Dermatitída zapríčinená bližšie neurčenou látkou užitou orálne, enterálne alebo parenterálne"},
{"code":"L28.-","desc":"Lichen simplex chronicus a prurigo"},
{"code":"L28.0","desc":"Lichen simplex chronicus"},
{"code":"L28.1","desc":"Prurigo nodularis"},
{"code":"L28.2","desc":"Iné prurigo"},
{"code":"L29.-","desc":"Svrbenie [pruritus]"},
{"code":"L29.0","desc":"Svrbenie anusu"},
{"code":"L29.1","desc":"Svrbenie skróta"},
{"code":"L29.2","desc":"Svrbenie vulvy"},
{"code":"L29.3","desc":"Anogenitálne svrbenie, bližšie neurčené"},
{"code":"L29.8","desc":"Iné svrbenie"},
{"code":"L29.9","desc":"Svrbenie, bližšie neurčené"},
{"code":"L30.-","desc":"Iná dermatitída"},
{"code":"L30.0","desc":"Numulárna dermatitída"},
{"code":"L30.1","desc":"Dyshidróza (pompholyx)"},
{"code":"L30.2","desc":"Kožná autosenzibilizácia"},
{"code":"L30.3","desc":"Infekčná dermatitída (ekzematoidná)"},
{"code":"L30.4","desc":"Intertrigo (zaparenina) s erytémom"},
{"code":"L30.5","desc":"Pityriasis alba"},
{"code":"L30.8","desc":"Iná dermatitída, bližšie určená"},
{"code":"L30.9","desc":"Dermatitída, bližšie neurčená"},
{"code":"L40-L45","desc":"Papuloskvamózne choroby kože"},
{"code":"L40.-","desc":"Psoriáza"},
{"code":"L40.0","desc":"Psoriasis vulgaris"},
{"code":"L40.1","desc":"Generalizovaná pustulová psoriáza"},
{"code":"L40.2","desc":"Acrodermatitis continua suppurativa"},
{"code":"L40.3","desc":"Psoriasis pustulosa palmoplantaris"},
{"code":"L40.4","desc":"Psoriasis guttata"},
{"code":"L40.5†","desc":"Artropatická psoriáza (M07.0-M07.3*, M09.0-*)"},
{"code":"L40.8","desc":"Iná psoriáza"},
{"code":"L40.9","desc":"Psoriáza, bližšie neurčená"},
{"code":"L41.-","desc":"Parapsoriáza"},
{"code":"L41.0","desc":"Pityriasis lichenoides et varioliformis acuta"},
{"code":"L41.1","desc":"Pityriasis lichenoides chronica"},
{"code":"L41.2","desc":"Lymfomatoidná papulóza"},
{"code":"L41.3","desc":"Parapsoriáza malých plakov"},
{"code":"L41.4","desc":"Parapsoriáza veľkých plakov"},
{"code":"L41.5","desc":"Parapsoriáza s poikilodermiou"},
{"code":"L41.8","desc":"Iná parapsoriáza"},
{"code":"L41.9","desc":"Parapsoriáza, bližšie neurčená"},
{"code":"L42","desc":"Pityriasis rosea"},
{"code":"L43.-","desc":"Lichen ruber planus"},
{"code":"L43.0","desc":"Hypertrofický lichen ruber planus"},
{"code":"L43.1","desc":"Bulózny lichen ruber planus"},
{"code":"L43.2","desc":"Lichenoidná reakcia na lieky"},
{"code":"L43.3","desc":"Subakútny (aktívny) lichen ruber planus"},
{"code":"L43.8","desc":"Iný lichen ruber planus"},
{"code":"L43.9","desc":"Lichen ruber planus, bližšie neurčený"},
{"code":"L44.-","desc":"Iná papuloskvamózna choroba"},
{"code":"L44.0","desc":"Pityriasis rubra pilaris"},
{"code":"L44.1","desc":"Lichen nitidus"},
{"code":"L44.2","desc":"Lichen striatus"},
{"code":"L44.3","desc":"Lichen ruber moniliformis"},
{"code":"L44.4","desc":"Detská papulárna akrodermatitída [Giannottiho-Crostiho syndróm]"},
{"code":"L44.8","desc":"Iná papuloskvamózna choroba kože, bližšie určená"},
{"code":"L44.9","desc":"Papuloskvamózna choroba kože, bližšie neurčená"},
{"code":"L45*","desc":"Papuloskvamózna (pupencovo-šupinová) choroba kože pri chorobe zatriedenej inde"},
{"code":"L50-L54","desc":"Žihľavka [urticaria] a erytémy"},
{"code":"L50.-","desc":"Žihľavka [urticaria]"},
{"code":"L50.0","desc":"Alergická žihľavka"},
{"code":"L50.1","desc":"Idiopatická žihľavka"},
{"code":"L50.2","desc":"Žihľavka zapríčinená chladom alebo teplom"},
{"code":"L50.3","desc":"Dermografická žihľavka [urticaria factitia]"},
{"code":"L50.4","desc":"Vibračná žihľavka"},
{"code":"L50.5","desc":"Cholínergická žihľavka"},
{"code":"L50.6","desc":"Kontaktná žihľavka"},
{"code":"L50.8","desc":"Iná žihľavka"},
{"code":"L50.9","desc":"Žihľavka, bližšie neurčená"},
{"code":"L51.-","desc":"Erythema exsudativum multiforme"},
{"code":"L51.0","desc":"Nepľuzgierový multiformný erytém"},
{"code":"L51.1","desc":"Pľuzgierový multiformný erytém"},
{"code":"L51.2-","desc":"Toxická nekrolýza epidermy [Lyellov syndrom]"},
{"code":"L51.20","desc":"Toxická nekrolýza epidermy [Lyellov syndrom] s postihnutím menej ako 30 % povrchu tela"},
{"code":"L51.21","desc":"Toxická nekrolýza epidermy [Lyellov syndrom] s postihnutím 30 % povrchu tela a viac"},
{"code":"L51.8","desc":"Iný multiformný erytém"},
{"code":"L51.9","desc":"Multiformný erytém, bližšie neurčený"},
{"code":"L52","desc":"Erythema nodosum"},
{"code":"L53.-","desc":"Iná erytémová choroba"},
{"code":"L53.0","desc":"Toxický erytém"},
{"code":"L53.1","desc":"Erythema annulare centrifugum"},
{"code":"L53.2","desc":"Erythema marginatum"},
{"code":"L53.3","desc":"Iný chronický obrazcový erytém"},
{"code":"L53.8","desc":"Iná erytémová choroba, bližšie určená"},
{"code":"L53.9","desc":"Erytémová choroba, bližšie neurčená"},
{"code":"L54.-*","desc":"Erytém pri chorobách zatriedených inde"},
{"code":"L54.0*","desc":"Erythema marginatum pri akútnej reumatickej horúčke"},
{"code":"L54.8*","desc":"Erytém pri iných chorobách zatriedených inde"},
{"code":"L55-L59","desc":"Radiačné poškodenie kože a podkožného tkaniva"},
{"code":"L55.-","desc":"Akútna solárna dermatitída"},
{"code":"L55.0","desc":"Akútna solárna dermatitída 1. stupňa"},
{"code":"L55.1","desc":"Akútna solárna dermatitída 2. stupňa"},
{"code":"L55.2","desc":"Akútna solárna dermatitída 3. stupňa"},
{"code":"L55.8","desc":"Iná akútna solárna dermatitída"},
{"code":"L55.9","desc":"Akútna solárna dermatitída, bližšie neurčená"},
{"code":"L56.-","desc":"Iné akútne zmeny kože zapríčinené ultrafialovým žiarením"},
{"code":"L56.0","desc":"Fototoxická lieková reakcia"},
{"code":"L56.1","desc":"Fotoalergická lieková reakcia"},
{"code":"L56.2","desc":"Fotokontaktná dermatitída"},
{"code":"L56.3","desc":"Solárna žihľavka"},
{"code":"L56.4","desc":"Polymorfná svetelná dermatóza"},
{"code":"L56.8","desc":"Iná akútna zmena kože zapríčinená ultrafialovým žiarením, bližšie určená"},
{"code":"L56.9","desc":"Akútna zmena kože zapríčinená ultrafialovým žiarením, bližšie neurčená"},
{"code":"L57.-","desc":"Zmeny kože zapríčinené chronickým vystavením neionizujúcemu žiareniu"},
{"code":"L57.0","desc":"Aktinická keratóza"},
{"code":"L57.1","desc":"Aktinický retikuloid"},
{"code":"L57.2","desc":"Cutis rhomboidalis nuchae"},
{"code":"L57.3","desc":"Poikiloderma [Civatte]"},
{"code":"L57.4","desc":"Cutis laxa senilis"},
{"code":"L57.5","desc":"Aktinický granulóm"},
{"code":"L57.8","desc":"Iné kožné zmeny zapríčinené chronickým vystavením účinku neionizujúceho žiarenia"},
{"code":"L57.9","desc":"Kožné zmeny zapríčinené chronickým vystavením účinku neionizujúceho žiarenia, bližšie neurčené"},
{"code":"L58.-","desc":"Rádiodermatitída"},
{"code":"L58.0","desc":"Akútna rádiodermatitída"},
{"code":"L58.1","desc":"Chronická rádiodermatitída"},
{"code":"L58.9","desc":"Rádiodermatitída, bližšie neurčená"},
{"code":"L59.-","desc":"Iné poškodenie kože a podkožného tkaniva zapríčinené žiarením"},
{"code":"L59.0","desc":"Erytém a dermatitída zapríčinená ohňom (erythema et dermatitis ab igne)"},
{"code":"L59.8","desc":"Iné poškodenie kože a podkožného tkaniva zapríčinené žiarením, bližšie určené"},
{"code":"L59.9","desc":"Poškodenie kože a podkožného tkaniva zapríčinené žiarením, bližšie neurčené"},
{"code":"L60-L75","desc":"Choroby kožných adnexov"},
{"code":"L60.-","desc":"Choroba nechtov"},
{"code":"L60.0","desc":"Zarastajúci necht"},
{"code":"L60.1","desc":"Onycholýza"},
{"code":"L60.2","desc":"Onychogryfóza"},
{"code":"L60.3","desc":"Dystrofia nechtov"},
{"code":"L60.4","desc":"Beauove čiary"},
{"code":"L60.5","desc":"Syndróm žltých nechtov"},
{"code":"L60.8","desc":"Iná choroba nechtov"},
{"code":"L60.9","desc":"Choroba nechtov, bližšie neurčená"},
{"code":"L62.-*","desc":"Choroba nechtov pri chorobe zatriedenej inde"},
{"code":"L62.0*","desc":"Nechty tvaru hodinového sklíčka (tĺčikovité prsty) pri pachydermoperiostóze (M89.4-†)"},
{"code":"L62.8*","desc":"Choroba nechtov pri inej chorobe zatriedenej inde"},
{"code":"L63.-","desc":"Alopecia areata"},
{"code":"L63.0","desc":"Alopecia (capitis) totalis"},
{"code":"L63.1","desc":"Alopecia universalis"},
{"code":"L63.2","desc":"Ophiasis"},
{"code":"L63.8","desc":"Iná alopecia areata"},
{"code":"L63.9","desc":"Alopecia areata, bližšie neurčená"},
{"code":"L64.-","desc":"Androgénna alopécia"},
{"code":"L64.0","desc":"Androgénna alopécia, zapríčinená liekmi"},
{"code":"L64.8","desc":"Iná androgénna alopécia"},
{"code":"L64.9","desc":"Androgénna alopécia, bližšie neurčená"},
{"code":"L65.-","desc":"Iná nejazvová strata vlasov"},
{"code":"L65.0","desc":"Telogénne efluvium"},
{"code":"L65.1","desc":"Anagénne efluvium"},
{"code":"L65.2","desc":"Alopecia mucinosa"},
{"code":"L65.8","desc":"Iná nejazvová strata vlasov, bližšie určená"},
{"code":"L65.9","desc":"Nejazvová strata vlasov, bližšie neurčená"},
{"code":"L66.-","desc":"Jazvová alopécia"},
{"code":"L66.0","desc":"Pseudopelade Brocq"},
{"code":"L66.1","desc":"Lichen planopilaris"},
{"code":"L66.2","desc":"Folliculitis decalvans"},
{"code":"L66.3","desc":"Perifolliculitis capitis abscedens"},
{"code":"L66.4","desc":"Folliculitis ulerythematosa reticulata"},
{"code":"L66.8","desc":"Iná jazvová alopécia"},
{"code":"L66.9","desc":"Jazvová alopécia, bližšie neurčená"},
{"code":"L67.-","desc":"Abnormality farby a tvaru vlasov"},
{"code":"L67.0","desc":"Trichorrhexis nodosa"},
{"code":"L67.1","desc":"Zmeny farby vlasov"},
{"code":"L67.8","desc":"Iná abnormalita farby vlasov a vlasového kmeňa"},
{"code":"L67.9","desc":"Abnormalita farby vlasov a vlasového kmeňa, bližšie neurčená"},
{"code":"L68.-","desc":"Hypertrichóza"},
{"code":"L68.0","desc":"Hirzutizmus"},
{"code":"L68.1","desc":"Získaná lanugová hypertrichóza"},
{"code":"L68.2","desc":"Lokálna hypertrichóza"},
{"code":"L68.3","desc":"Polytrichia"},
{"code":"L68.8","desc":"Iná hypertrichóza"},
{"code":"L68.9","desc":"Hypertrichóza, bližšie neurčená"},
{"code":"L70.-","desc":"Akné"},
{"code":"L70.0","desc":"Acne vulgaris"},
{"code":"L70.1","desc":"Acne conglobata"},
{"code":"L70.2","desc":"Acne varioliformis"},
{"code":"L70.3","desc":"Acne tropica"},
{"code":"L70.4","desc":"Acne infantilis"},
{"code":"L70.5","desc":"Acne excoriée des jeunes filles"},
{"code":"L70.8","desc":"Iné akné"},
{"code":"L70.9","desc":"Akné, bližšie neurčené"},
{"code":"L71.-","desc":"Rosacea"},
{"code":"L71.0","desc":"Periorálna dermatitída"},
{"code":"L71.1","desc":"Rhinophyma"},
{"code":"L71.8","desc":"Iná rosacea"},
{"code":"L71.9","desc":"Rosacea, bližšie neurčená"},
{"code":"L72.-","desc":"Folikulová cysta kože a podkožného tkaniva"},
{"code":"L72.0","desc":"Epidermová cysta"},
{"code":"L72.1","desc":"Trichilémová cysta"},
{"code":"L72.2","desc":"Steatocystoma multiplex"},
{"code":"L72.8","desc":"Iná folikulová cysta kože a podkožného tkaniva"},
{"code":"L72.9","desc":"Folikulová cysta kože a podkožného tkaniva, bližšie neurčená"},
{"code":"L73.-","desc":"Iná choroba vlasových folikulov"},
{"code":"L73.0","desc":"Acne keloid [folliculitis sclerotisans nuchae]"},
{"code":"L73.1","desc":"Pseudofolliculitis barbae"},
{"code":"L73.2","desc":"Hidradenitis suppurativa"},
{"code":"L73.8","desc":"Iná choroba vlasových folikulov, bližšie určená"},
{"code":"L73.9","desc":"Choroba vlasových folikulov, bližšie neurčená"},
{"code":"L74.-","desc":"Choroba ekrinných potných žliaz"},
{"code":"L74.0","desc":"Miliaria rubra"},
{"code":"L74.1","desc":"Miliaria crystallina"},
{"code":"L74.2","desc":"Miliaria profunda"},
{"code":"L74.3","desc":"Miliaria, bližšie neurčená"},
{"code":"L74.4","desc":"Anhidróza"},
{"code":"L74.8","desc":"Iná choroba ekrinných potných žliaz"},
{"code":"L74.9","desc":"Choroba ekrinných potných žliaz, bližšie neurčená"},
{"code":"L75.-","desc":"Choroba apokrinných potných žliaz"},
{"code":"L75.0","desc":"Brómhidróza"},
{"code":"L75.1","desc":"Chrómhidróza"},
{"code":"L75.2","desc":"Apokrinná miliaria"},
{"code":"L75.8","desc":"Iné choroby apokrinných potných žliaz"},
{"code":"L75.9","desc":"Choroba apokrinných potných žliaz, bližšie neurčená"},
{"code":"L80-L99","desc":"Iné choroby kože a podkožného tkaniva"},
{"code":"L80","desc":"Vitiligo"},
{"code":"L81.-","desc":"Iná porucha pigmentácie"},
{"code":"L81.0","desc":"Pozápalová hyperpigmentácia"},
{"code":"L81.1","desc":"Chloazma"},
{"code":"L81.2","desc":"Pehy"},
{"code":"L81.3","desc":"Škvrny farby bielej kávy [café au lait]"},
{"code":"L81.4","desc":"Iná melanínová hyperpigmentácia"},
{"code":"L81.5","desc":"Leukoderma, nezatriedená inde"},
{"code":"L81.6","desc":"Iná porucha zo zníženej tvorby melanínu"},
{"code":"L81.7","desc":"Pigmentová purpura"},
{"code":"L81.8","desc":"Iné poruchy pigmentácie, bližšie určené"},
{"code":"L81.9","desc":"Porucha pigmentácie, bližšie neurčená"},
{"code":"L82","desc":"Seboroická keratóza"},
{"code":"L83","desc":"Acanthosis nigricans"},
{"code":"L84","desc":"Kurie oko a mozoľ"},
{"code":"L85.-","desc":"Iné zhrubnutia epidermy"},
{"code":"L85.0","desc":"Získaná ichtyóza"},
{"code":"L85.1","desc":"Získaná keratóza (keratoderma) dlaní a chodidiel"},
{"code":"L85.2","desc":"Keratosis punctata dlaní a chodidiel"},
{"code":"L85.3","desc":"Xerosis cutis"},
{"code":"L85.8","desc":"Iné zhrubnutie epidermy, bližšie určené"},
{"code":"L85.9","desc":"Zhrubnutie epidermy, bližšie neurčené"},
{"code":"L86*","desc":"Keratoderma pri chorobách zatriedených inde"},
{"code":"L87.-","desc":"Choroby transepidermálnej eliminácie"},
{"code":"L87.0","desc":"Folikulová a parafolikulová keratóza prenikajúca do kože [Kyrleova]"},
{"code":"L87.1","desc":"Reaktívna perforujúca kolagenóza"},
{"code":"L87.2","desc":"Elastosis perforans serpiginosa"},
{"code":"L87.8","desc":"Iné poruchy transepidermálnej eliminácie"},
{"code":"L87.9","desc":"Choroba transepidermálnej eliminácie, bližšie neurčená"},
{"code":"L88","desc":"Pyoderma gangrenosum"},
{"code":"L89.-","desc":"Dekubitus"},
{"code":"L89.0-","desc":"Dekubitus 1. stupňa"},
{"code":"L89.00","desc":"Dekubitus 1. stupňa: Hlava"},
{"code":"L89.01","desc":"Dekubitus 1. stupňa: Horná končatina"},
{"code":"L89.02","desc":"Dekubitus 1. stupňa: Tŕňový výbežok"},
{"code":"L89.03","desc":"Dekubitus 1. stupňa: Lopata bedrovej kosti"},
{"code":"L89.04","desc":"Dekubitus 1. stupňa: Krížová oblasť"},
{"code":"L89.05","desc":"Dekubitus 1. stupňa: Sedacia oblasť"},
{"code":"L89.06","desc":"Dekubitus 1. stupňa: Trochanter"},
{"code":"L89.07","desc":"Dekubitus 1. stupňa: Päta"},
{"code":"L89.08","desc":"Dekubitus 1. stupňa: Iná oblasť dolnej končatiny"},
{"code":"L89.09","desc":"Dekubitus 1. stupňa: Bližšie neurčené miesto"},
{"code":"L89.1-","desc":"Dekubitus 2. stupňa"},
{"code":"L89.10","desc":"Dekubitus 2. stupňa: Hlava"},
{"code":"L89.11","desc":"Dekubitus 2. stupňa: Horná končatina"},
{"code":"L89.12","desc":"Dekubitus 2. stupňa: Tŕňový výbežok"},
{"code":"L89.13","desc":"Dekubitus 2. stupňa: Lopata bedrovej kosti"},
{"code":"L89.14","desc":"Dekubitus 2. stupňa: Krížová oblasť"},
{"code":"L89.15","desc":"Dekubitus 2. stupňa: Sedacia oblasť"},
{"code":"L89.16","desc":"Dekubitus 2. stupňa: Trochanter"},
{"code":"L89.17","desc":"Dekubitus 2. stupňa: Päta"},
{"code":"L89.18","desc":"Dekubitus 2. stupňa: Iná oblasť dolnej končatiny"},
{"code":"L89.19","desc":"Dekubitus 2. stupňa: Bližšie neurčené miesto"},
{"code":"L89.2-","desc":"Dekubitus 3. stupňa"},
{"code":"L89.20","desc":"Dekubitus 3. stupňa: Hlava"},
{"code":"L89.21","desc":"Dekubitus 3. stupňa: Horná končatina"},
{"code":"L89.22","desc":"Dekubitus 3. stupňa: Tŕňový výbežok"},
{"code":"L89.23","desc":"Dekubitus 3. stupňa: Lopata bedrovej kosti"},
{"code":"L89.24","desc":"Dekubitus 3. stupňa: Krížová oblasť"},
{"code":"L89.25","desc":"Dekubitus 3. stupňa: Sedacia oblasť"},
{"code":"L89.26","desc":"Dekubitus 3. stupňa: Trochanter"},
{"code":"L89.27","desc":"Dekubitus 3. stupňa: Päta"},
{"code":"L89.28","desc":"Dekubitus 3. stupňa: Iná oblasť dolnej končatiny"},
{"code":"L89.29","desc":"Dekubitus 3. stupňa: Bližšie neurčené miesto"},
{"code":"L89.3-","desc":"Dekubitus 4. stupňa"},
{"code":"L89.30","desc":"Dekubitus 4. stupňa: Hlava"},
{"code":"L89.31","desc":"Dekubitus 4. stupňa: Horná končatina"},
{"code":"L89.32","desc":"Dekubitus 4. stupňa: Tŕňový výbežok"},
{"code":"L89.33","desc":"Dekubitus 4. stupňa: Lopata bedrovej kosti"},
{"code":"L89.34","desc":"Dekubitus 4. stupňa: Krížová oblasť"},
{"code":"L89.35","desc":"Dekubitus 4. stupňa: Sedacia oblasť"},
{"code":"L89.36","desc":"Dekubitus 4. stupňa: Trochanter"},
{"code":"L89.37","desc":"Dekubitus 4. stupňa: Päta"},
{"code":"L89.38","desc":"Dekubitus 4. stupňa: Iná oblasť dolnej končatiny"},
{"code":"L89.39","desc":"Dekubitus 4. stupňa: Bližšie neurčené miesto"},
{"code":"L89.9-","desc":"Dekubitus, stupeň bližšie neurčený"},
{"code":"L89.90","desc":"Dekubitus, stupeň bližšie neurčený: Hlava"},
{"code":"L89.91","desc":"Dekubitus, stupeň bližšie neurčený: Horná končatina"},
{"code":"L89.92","desc":"Dekubitus, stupeň bližšie neurčený: Tŕňový výbežok"},
{"code":"L89.93","desc":"Dekubitus, stupeň bližšie neurčený: Lopata bedrovej kosti"},
{"code":"L89.94","desc":"Dekubitus, stupeň bližšie neurčený: Krížová oblasť"},
{"code":"L89.95","desc":"Dekubitus, stupeň bližšie neurčený: Sedacia oblasť"},
{"code":"L89.96","desc":"Dekubitus, stupeň bližšie neurčený: Trochanter"},
{"code":"L89.97","desc":"Dekubitus, stupeň bližšie neurčený: Päta"},
{"code":"L89.98","desc":"Dekubitus, stupeň bližšie neurčený: Iná oblasť dolnej končatiny"},
{"code":"L89.99","desc":"Dekubitus, stupeň bližšie neurčený: Bližšie neurčené miesto"},
{"code":"L90.-","desc":"Atrofické choroby kože"},
{"code":"L90.0","desc":"Lichen sclerosus et atrophicus"},
{"code":"L90.1","desc":"Anetodermia, Schweningerov-Buzziho typ"},
{"code":"L90.2","desc":"Anetodermia, Jadassohnov-Pellizzariho typ"},
{"code":"L90.3","desc":"Idiopatická atrophodermia, Pasiniho-Pieriniho typ"},
{"code":"L90.4","desc":"Acrodermatitis chronica atrophicans"},
{"code":"L90.5","desc":"Jazvové stavy a fibróza kože"},
{"code":"L90.6","desc":"Striae cutis atrophicae"},
{"code":"L90.8","desc":"Iná atrofická choroba kože"},
{"code":"L90.9","desc":"Atrofická choroba kože, bližšie neurčená"},
{"code":"L91.-","desc":"Hypertrofická choroba kože"},
{"code":"L91.0","desc":"Hypertrofická jazva (keloid)"},
{"code":"L91.8","desc":"Iná hypertrofická choroba kože"},
{"code":"L91.9","desc":"Hypertrofická choroba kože, bližšie neurčená"},
{"code":"L92.-","desc":"Granulomatózne choroby kože a podkožného tkaniva"},
{"code":"L92.0","desc":"Granuloma annulare"},
{"code":"L92.1","desc":"Necrobiosis lipoidica, nezatriedená inde"},
{"code":"L92.2","desc":"Granuloma faciale (eozinofilný granulóm kože)"},
{"code":"L92.3","desc":"Kožný a podkožný granulóm okolo cudzích telies"},
{"code":"L92.8","desc":"Iná granulomatózna choroba kože a podkožného tkaniva"},
{"code":"L92.9","desc":"Granulomatózna choroba kože a podkožného tkaniva, bližšie neurčená"},
{"code":"L93.-","desc":"Lupus erythematosus"},
{"code":"L93.0","desc":"Diskoidný lupus erythematosus"},
{"code":"L93.1","desc":"Subakútny kožný lupus erythematosus"},
{"code":"L93.2","desc":"Iný lokálny lupus erythematosus"},
{"code":"L94.-","desc":"Iné lokalizované choroby spojivového tkaniva"},
{"code":"L94.0","desc":"Sclerodermia circumscripta [Morphaea]"},
{"code":"L94.1","desc":"Lineárna a pásovitá sklerodermia"},
{"code":"L94.2","desc":"Kalcinóza kože"},
{"code":"L94.3","desc":"Sklerodaktýlia"},
{"code":"L94.4","desc":"Gottronov syndróm"},
{"code":"L94.5","desc":"Poikiloderma vasculare atrophicans"},
{"code":"L94.6","desc":"Ainhum"},
{"code":"L94.8","desc":"Iné lokalizované choroby spojivového tkaniva, bližšie určené"},
{"code":"L94.9","desc":"Lokalizovaná choroba spojivového tkaniva, bližšie neurčená"},
{"code":"L95.-","desc":"Vaskulitída ohraničená na kožu, nezatriedená inde"},
{"code":"L95.0","desc":"Livedoidná vaskulitída"},
{"code":"L95.1","desc":"Erythema elevatum et diutinum"},
{"code":"L95.8","desc":"Iná vaskulitída ohraničená na kožu"},
{"code":"L95.9","desc":"Vaskulitída ohraničená na kožu, bližšie neurčená"},
{"code":"L97","desc":"Vred predkolenia, nezatriedený inde"},
{"code":"L98.-","desc":"Iná choroba kože a podkožného tkaniva, nezatriedená inde"},
{"code":"L98.0","desc":"Pyogénny (hnisový) granulóm"},
{"code":"L98.1","desc":"Dermatitis factitia (artefacta)"},
{"code":"L98.2","desc":"Febrilná neutrofilná dermatóza (Sweetov syndróm)"},
{"code":"L98.3","desc":"Eozinofilná celulitída (Wellsov syndróm)"},
{"code":"L98.4","desc":"Chronický vred kože, nezatriedený inde"},
{"code":"L98.5","desc":"Mucinóza kože"},
{"code":"L98.6","desc":"Iné infiltratívne choroby kože a podkožného tkaniva"},
{"code":"L98.8","desc":"Iná choroby kože a podkožného tkaniva, bližšie určené"},
{"code":"L98.9","desc":"Choroba kože a podkožného tkaniva, bližšie neurčená"},
{"code":"L99.-*","desc":"Iné choroby kože a podkožného tkaniva pri chorobách zatriedených inde"},
{"code":"L99.0*","desc":"Amyloidóza kože (E85.-†)"},
{"code":"L99.1-*","desc":"Postihnutie kože pri akútnej chorobe štepu proti hostiteľovi, [GVHD] (T86.01†, T86.02†)"},
{"code":"L99.11*","desc":"Postihnutie kože pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 1.štádium"},
{"code":"L99.12*","desc":"Postihnutie kože pri akútnej chorobe štepu proti hostiteľovi, [GVHD] 2.štádium"},
{"code":"L99.13*","desc":"Postihnutie kože pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 3.štádium"},
{"code":"L99.14*","desc":"Postihnutie kože pri akútnej chorobe štepu proti hostiteľovi [GVHD]: 4.štádium"},
{"code":"L99.8*","desc":"Iná choroba kože a podkožného tkaniva pri chorobách zatriedených inde, bližšie určená"},
{"code":"M00-M99","desc":"XIII. kapitola - Choroby svalovej a kostrovej sústavy a spojivového tkaniva (M00-M99)"},
{"code":"M00-M25","desc":"Artropatie"},
{"code":"M00-M03","desc":"Infekčné artropatie"},
{"code":"M00.-","desc":"Pyogénna artritída"},
{"code":"M00.0-","desc":"Artritída a polyartritída zapríčinená stafylokokmi"},
{"code":"M00.00","desc":"Artritída a polyartritída zapríčinená stafylokokmi, na viacerých miestach"},
{"code":"M00.01","desc":"Artritída a polyartritída zapríčinená stafylokokmi, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M00.02","desc":"Artritída a polyartritída zapríčinená stafylokokmi, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M00.03","desc":"Artritída a polyartritída zapríčinená stafylokokmi, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M00.04","desc":"Artritída a polyartritída zapríčinená stafylokokmi, v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M00.05","desc":"Artritída a polyartritída zapríčinená stafylokokmi, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M00.06","desc":"Artritída a polyartritída zapríčinená stafylokokmi, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M00.07","desc":"Artritída a polyartritída zapríčinená stafylokokmi, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M00.08","desc":"Artritída a polyartritída zapríčinená stafylokokmi, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M00.09","desc":"Artritída a polyartritída zapríčinená stafylokokmi, miesto bližšie neurčené"},
{"code":"M00.1-","desc":"Artritída a polyartritída zapríčinená pneumokokmi"},
{"code":"M00.10","desc":"Artritída a polyartritída zapríčinená pneumokokmi, na viacerých miestach"},
{"code":"M00.11","desc":"Artritída a polyartritída zapríčinená pneumokokmi, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M00.12","desc":"Artritída a polyartritída zapríčinená pneumokokmi, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M00.13","desc":"Artritída a polyartritída zapríčinená pneumokokmi, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M00.14","desc":"Artritída a polyartritída zapríčinená pneumokokmi, v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M00.15","desc":"Artritída a polyartritída zapríčinená pneumokokmi, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M00.16","desc":"Artritída a polyartritída zapríčinená pneumokokmi, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M00.17","desc":"Artritída a polyartritída zapríčinená pneumokokmi, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M00.18","desc":"Artritída a polyartritída zapríčinená pneumokokmi, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M00.19","desc":"Artritída a polyartritída zapríčinená pneumokokmi, miesto bližšie neurčené"},
{"code":"M00.2-","desc":"Artritída a polyartritída zapríčinená inými streptokokmi"},
{"code":"M00.20","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, na viacerých miestach"},
{"code":"M00.21","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M00.22","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M00.23","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M00.24","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M00.25","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M00.26","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M00.27","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M00.28","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, na inom mieste (hlava, krk, lebka, rebrá, trup, chrbtica)"},
{"code":"M00.29","desc":"Artritída a polyartritída zapríčinená inými streptokokmi, miesto bližšie neurčené"},
{"code":"M00.8-","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami"},
{"code":"M00.80","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami, na viacerých miestach"},
{"code":"M00.81","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M00.82","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M00.83","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M00.84","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami, v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M00.85","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M00.86","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb), zapríčinená inými bližšie určenými baktériami"},
{"code":"M00.87","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami: členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy),"},
{"code":"M00.88","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M00.89","desc":"Artritída a polyartritída zapríčinená inými bližšie určenými baktériami, miesto bližšie neurčené"},
{"code":"M00.9-","desc":"Pyogénna artritída, bližšie neurčená"},
{"code":"M00.90","desc":"Pyogénna artritída, bližšie neurčená, na viacerých miestach"},
{"code":"M00.91","desc":"Pyogénna artritída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M00.92","desc":"Pyogénna artritída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M00.93","desc":"Pyogénna artritída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M00.94","desc":"Pyogénna artritída, bližšie neurčená, v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M00.95","desc":"Pyogénna artritída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M00.96","desc":"Pyogénna artritída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M00.97","desc":"Pyogénna artritída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M00.98","desc":"Pyogénna artritída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M00.99","desc":"Pyogénna artritída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M01.-*","desc":"Priame infekcie kĺbu pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"M01.0-*","desc":"Artritída zapríčinená meningokokmi (A39.8†)"},
{"code":"M01.00*","desc":"Artritída zapríčinená meningokokmi (A39.8†), na viacerých miestach"},
{"code":"M01.01*","desc":"Artritída zapríčinená meningokokmi (A39.8†), v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M01.02*","desc":"Artritída zapríčinená meningokokmi (A39.8†), v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M01.03*","desc":"Artritída zapríčinená meningokokmi (A39.8†), v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M01.04*","desc":"Artritída zapríčinená meningokokmi (A39.8†), v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M01.05*","desc":"Artritída zapríčinená meningokokmi (A39.8†), v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M01.06*","desc":"Artritída zapríčinená meningokokmi (A39.8†), v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M01.07*","desc":"Artritída zapríčinená meningokokmi (A39.8†), v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M01.08*","desc":"Artritída zapríčinená meningokokmi (A39.8†), na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M01.09*","desc":"Artritída zapríčinená meningokokmi (A39.8†), miesto bližšie neurčené"},
{"code":"M01.1-*","desc":"Tuberkulózna artritída (A18.0†)"},
{"code":"M01.10*","desc":"Tuberkulózna artritída (A18.0†), na viacerých miestach"},
{"code":"M01.11*","desc":"Tuberkulózna artritída (A18.0†), v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M01.12*","desc":"Tuberkulózna artritída (A18.0†), v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M01.13*","desc":"Tuberkulózna artritída (A18.0†), v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M01.14*","desc":"Tuberkulózna artritída (A18.0†), v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M01.15*","desc":"Tuberkulózna artritída (A18.0†), v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M01.16*","desc":"Tuberkulózna artritída (A18.0†), v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M01.17*","desc":"Tuberkulózna artritída (A18.0†), v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M01.18*","desc":"Tuberkulózna artritída (A18.0†), na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M01.19*","desc":"Tuberkulózna artritída (A18.0†), miesto bližšie neurčené"},
{"code":"M01.2-*","desc":"Artritída pri Lymskej chorobe (A69.2†)"},
{"code":"M01.20*","desc":"Artritída pri Lymskej chorobe (A69.2†), na viacerých miestach pri Lymskej chorobe"},
{"code":"M01.21*","desc":"Artritída pri Lymskej chorobe (A69.2†), v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M01.22*","desc":"Artritída pri Lymskej chorobe (A69.2†), v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M01.23*","desc":"Artritída pri Lymskej chorobe (A69.2†), v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) pri Lymskej chorobe"},
{"code":"M01.24*","desc":"Artritída pri Lymskej chorobe (A69.2†), v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M01.25*","desc":"Artritída pri Lymskej chorobe (A69.2†), v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M01.26*","desc":"Artritída pri Lymskej chorobe (A69.2†), v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M01.27*","desc":"Artritída pri Lymskej chorobe (A69.2†), v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M01.28*","desc":"Artritída pri Lymskej chorobe (A69.2†), na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M01.29*","desc":"Artritída pri Lymskej chorobe (A69.2†): miesto bližšie"},
{"code":"M01.3-*","desc":"Artritída pri iných baktériových chorobách zatriedených inde"},
{"code":"M01.30*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, na viacerých miestach"},
{"code":"M01.31*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M01.32*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M01.33*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M01.34*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M01.35*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M01.36*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M01.37*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M01.38*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M01.39*","desc":"Artritída pri iných baktériových chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M01.4-*","desc":"Artritída pri ružienke (B06.8†)"},
{"code":"M01.40*","desc":"Artritída pri ružienke (B06.8†), na viacerých miestach"},
{"code":"M01.41*","desc":"Artritída pri ružienke (B06.8†), v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M01.42*","desc":"Artritída pri ružienke (B06.8†), v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M01.43*","desc":"Artritída pri ružienke predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M01.44*","desc":"Artritída pri ružienke (B06.8†), v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M01.45*","desc":"Artritída pri ružienke (B06.8†), v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M01.46*","desc":"Artritída pri ružienke (B06.8†), v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M01.47*","desc":"Artritída pri ružienke (B06.8†): členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M01.48*","desc":"Artritída pri ružienke (B06.8†), na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M01.49*","desc":"Artritída pri ružienke (B06.8†), miesto bližšie neurčené"},
{"code":"M01.5-*","desc":"Artritída pri iných vírusových chorobách zatriedených inde"},
{"code":"M01.50*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, na viacerých miestach"},
{"code":"M01.51*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M01.52*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) pri iných vírusových chorobách zatriedených inde"},
{"code":"M01.53*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M01.54*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M01.55*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M01.56*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M01.57*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) pri iných vírusových chorobách zatriedených inde"},
{"code":"M01.58*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M01.59*","desc":"Artritída pri iných vírusových chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M01.6-*","desc":"Artritída pri mykózach (B35-B49†)"},
{"code":"M01.60*","desc":"Artritída pri mykózach (B35-B49†), na viacerých miestach"},
{"code":"M01.61*","desc":"Artritída pri mykózach (B35-B49†), v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M01.62*","desc":"Artritída pri mykózach (B35-B49†), v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M01.63*","desc":"Artritída pri mykózach (B35-B49†), v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M01.64*","desc":"Artritída pri mykózach (B35-B49†), v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M01.65*","desc":"Artritída pri mykózach (B35-B49†), v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M01.66*","desc":"Artritída pri mykózach (B35-B49†), v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M01.67*","desc":"Artritída pri mykózach (B35-B49†), v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M01.68*","desc":"Artritída pri mykózach (B35-B49†), na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M01.69*","desc":"Artritída pri mykózach (B35-B49†), miesto bližšie neurčené"},
{"code":"M01.8-*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"M01.80*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde, na viacerých miestach"},
{"code":"M01.81*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) pri iných infekčných a parazitových chorobách zatriedených inde"},
{"code":"M01.82*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde: (ramenná kosť, lakťový kĺb)"},
{"code":"M01.83*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M01.84*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde, v oblasti ruky (prsty, zápästie, záprstie, kĺby medzi týmito kosťami)"},
{"code":"M01.85*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M01.86*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M01.87*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M01.88*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M01.89*","desc":"Artritída pri iných infekčných a parazitových chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M02.-","desc":"Reaktívne artropatie"},
{"code":"M02.0-","desc":"Artritída po črevnom bajpase"},
{"code":"M02.00","desc":"Artritída po črevnom bajpase, na viacerých miestach"},
{"code":"M02.01","desc":"Artritída po črevnom bajpase, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M02.02","desc":"Artritída po črevnom bajpase, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M02.03","desc":"Artritída po črevnom bajpase, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M02.04","desc":"Artritída po črevnom bajpase, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M02.05","desc":"Artritída po črevnom bajpase, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M02.06","desc":"Artritída po črevnom bajpase, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M02.07","desc":"Artritída po črevnom bajpase, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M02.08","desc":"Artritída po črevnom bajpase, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M02.09","desc":"Artritída po črevnom bajpase, miesto bližšie neurčené"},
{"code":"M02.1-","desc":"Postenteritická artritída"},
{"code":"M02.10","desc":"Postenteritická artritída, na viacerých miestach"},
{"code":"M02.11","desc":"Postenteritická artritída, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M02.12","desc":"Postenteritická artritída, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M02.13","desc":"Postenteritická artritída, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M02.14","desc":"Postenteritická artritída, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M02.15","desc":"Postenteritická artritída, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M02.16","desc":"Postenteritická artritída, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M02.17","desc":"Postenteritická artritída, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M02.18","desc":"Postenteritická artritída, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M02.19","desc":"Postenteritická artritída, miesto bližšie neurčené"},
{"code":"M02.2-","desc":"Artritída po očkovaní"},
{"code":"M02.20","desc":"Artritída po očkovaní, na viacerých miestach"},
{"code":"M02.21","desc":"Artritída po očkovaní, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M02.22","desc":"Artritída po očkovaní, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M02.23","desc":"Artritída po očkovaní, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M02.24","desc":"Artritída po očkovaní, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M02.25","desc":"Artritída po očkovaní, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M02.26","desc":"Artritída po očkovaní, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M02.27","desc":"Artritída po očkovaní, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M02.28","desc":"Artritída po očkovaní, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M02.29","desc":"Artritída po očkovaní, miesto bližšie neurčené"},
{"code":"M02.3-","desc":"Reiterova choroba"},
{"code":"M02.30","desc":"Reiterova choroba na viacerých miestach"},
{"code":"M02.31","desc":"Reiterova choroba v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M02.32","desc":"Reiterova choroba nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M02.33","desc":"Reiterova choroba predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M02.34","desc":"Reiterova choroba ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M02.35","desc":"Reiterova choroba panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M02.36","desc":"Reiterova choroba predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M02.37","desc":"Reiterova choroba členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M02.38","desc":"Reiterova choroba na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M02.39","desc":"Reiterova choroba, miesto bližšie neurčené"},
{"code":"M02.8-","desc":"Iná reaktívna artritída"},
{"code":"M02.80","desc":"Iná reaktívna artritída, na viacerých miestach"},
{"code":"M02.81","desc":"Iná reaktívna artritída, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M02.82","desc":"Iná reaktívna artritída, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M02.83","desc":"Iná reaktívna artritída, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M02.84","desc":"Iná reaktívna artritída, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M02.85","desc":"Iná reaktívna artritída, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M02.86","desc":"Iná reaktívna artritída, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M02.87","desc":"Iná reaktívna artritída, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M02.88","desc":"Iná reaktívna artritída, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M02.89","desc":"Iná reaktívna artritída, miesto bližšie neurčené"},
{"code":"M02.9-","desc":"Reaktívna artritída, bližšie neurčená"},
{"code":"M02.90","desc":"Reaktívna artritída, bližšie neurčená, na viacerých miestach"},
{"code":"M02.91","desc":"Reaktívna artritída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M02.92","desc":"Reaktívna artritída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M02.93","desc":"Reaktívna artritída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M02.94","desc":"Reaktívna artritída, bližšie neurčená: (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M02.95","desc":"Reaktívna artritída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M02.96","desc":"Reaktívna artritída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M02.97","desc":"Reaktívna artritída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M02.98","desc":"Reaktívna artritída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M02.99","desc":"Reaktívna artritída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M03.-*","desc":"Poinfekčná a reaktívna artritída pri chorobách zatriedených inde"},
{"code":"M03.0-*","desc":"Artritída po meningokokovej infekcii (A39.8†)"},
{"code":"M03.00*","desc":"Artritída po meningokokovej infekcii, na viacerých miestach (A39.8†)"},
{"code":"M03.01*","desc":"Artritída po meningokokovej infekcii, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (A39.8†)"},
{"code":"M03.02*","desc":"Artritída po meningokokovej infekcii, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (A39.8†)"},
{"code":"M03.03*","desc":"Artritída po meningokokovej infekcii, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (A39.8†)"},
{"code":"M03.04*","desc":"Artritída po meningokokovej infekcii, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (A39.8†)"},
{"code":"M03.05*","desc":"Artritída po meningokokovej infekcii, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (A39.8†)"},
{"code":"M03.06*","desc":"Artritída po meningokokovej infekcii, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (A39.8†)"},
{"code":"M03.07*","desc":"Artritída po meningokokovej infekcii, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (A39.8†)"},
{"code":"M03.08*","desc":"Artritída po meningokokovej infekcii, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (A39.8†)"},
{"code":"M03.09*","desc":"Artritída po meningokokovej infekcii, miesto bližšie neurčené (A39.8†)"},
{"code":"M03.1-*","desc":"Poinfekčná artritída pri syfilise"},
{"code":"M03.10*","desc":"Poinfekčná artritída pri syfilise, na viacerých miestach"},
{"code":"M03.11*","desc":"Poinfekčná artritída pri syfilise, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M03.12*","desc":"Poinfekčná artritída pri syfilise, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M03.13*","desc":"Poinfekčná artritída pri syfilise, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M03.14*","desc":"Poinfekčná artritída pri syfilise, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M03.15*","desc":"Poinfekčná artritída pri syfilise: v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M03.16*","desc":"Poinfekčná artritída pri syfilise, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M03.17*","desc":"Poinfekčná artritída pri syfilise, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M03.18*","desc":"Poinfekčná artritída pri syfilise, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M03.19*","desc":"Poinfekčná artritída pri syfilise, miesto bližšie neurčené"},
{"code":"M03.2-*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde"},
{"code":"M03.20*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, na viacerých miestach"},
{"code":"M03.21*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M03.22*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M03.23*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M03.24*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) pri chorobách zatriedených inde"},
{"code":"M03.25*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M03.26*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M03.27*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M03.28*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M03.29*","desc":"Iné poinfekčné artritídy pri chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M03.6-*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde"},
{"code":"M03.60*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, na viacerých miestach"},
{"code":"M03.61*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M03.62*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M03.63*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M03.64*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M03.65*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M03.66*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M03.67*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M03.68*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M03.69*","desc":"Reaktívna artritída pri iných chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M05-M14","desc":"Zápalové polyartropatie"},
{"code":"M05.-","desc":"Séropozitívna reumatoidná artritída"},
{"code":"M05.0-","desc":"Feltyho syndróm"},
{"code":"M05.00","desc":"Feltyho syndróm na viacerých miestach"},
{"code":"M05.01","desc":"Feltyho syndróm v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M05.02","desc":"Feltyho syndróm nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M05.03","desc":"Feltyho syndróm predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M05.04","desc":"Feltyho syndróm ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M05.05","desc":"Feltyho syndróm panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M05.06","desc":"Feltyho syndróm predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M05.07","desc":"Feltyho syndróm členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M05.08","desc":"Feltyho syndróm na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M05.09","desc":"Feltyho syndróm, miesto bližšie neurčené"},
{"code":"M05.1-†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi (J99.0*)"},
{"code":"M05.10†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi, na viacerých miestach (J99.0*)"},
{"code":"M05.11†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (J99.0*)"},
{"code":"M05.12†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi: nadlaktia (ramenná kosť, lakťový kĺb) (J99.0*)"},
{"code":"M05.13†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (J99.0*)"},
{"code":"M05.14†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi (J99.0*), v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (J99.0*)"},
{"code":"M05.15†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi (J99.0*), v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M05.16†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (J99.0*)"},
{"code":"M05.17†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi, s artritídou členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (J99.0*)"},
{"code":"M05.18†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (J99.0*)"},
{"code":"M05.19†","desc":"Séropozitívna reumatoidná artritída s pľúcnymi prejavmi, miesto bližšie neurčené (J99.0*)"},
{"code":"M05.2-","desc":"Reumatoidná artritída s vaskulitídou"},
{"code":"M05.20","desc":"Reumatoidná artritída s vaskulitídou, na viacerých miestach"},
{"code":"M05.21","desc":"Reumatoidná artritída s vaskulitídou, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M05.22","desc":"Reumatoidná artritída s vaskulitídou, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M05.23","desc":"Reumatoidná artritída s vaskulitídou, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M05.24","desc":"Reumatoidná artritída s vaskulitídou, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M05.25","desc":"Reumatoidná artritída s vaskulitídou, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M05.26","desc":"Reumatoidná artritída s vaskulitídou, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M05.27","desc":"Reumatoidná artritída s vaskulitídou, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M05.28","desc":"Reumatoidná artritída s vaskulitídou, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M05.29","desc":"Reumatoidná artritída s vaskulitídou, miesto bližšie neurčené"},
{"code":"M05.3-†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav"},
{"code":"M05.30†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, na viacerých miestach"},
{"code":"M05.31†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M05.32†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M05.33†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M05.34†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M05.35†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M05.36†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M05.37†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M05.38†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M05.39†","desc":"Reumatoidná artritída s postihnutím iných orgánov a sústav, miesto bližšie neurčené"},
{"code":"M05.8-","desc":"Iná séropozitívna reumatoidná artritída"},
{"code":"M05.80","desc":"Iná séropozitívna reumatoidná artritída, na viacerých miestach"},
{"code":"M05.81","desc":"Iná séropozitívna reumatoidná artritída, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M05.82","desc":"Iná séropozitívna reumatoidná artritída, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M05.83","desc":"Iná séropozitívna reumatoidná artritída: predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M05.84","desc":"Iná séropozitívna reumatoidná artritída, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M05.85","desc":"Iná séropozitívna reumatoidná artritída, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M05.86","desc":"Iná séropozitívna reumatoidná artritída, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M05.87","desc":"Iná séropozitívna reumatoidná artritída, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M05.88","desc":"Iná séropozitívna reumatoidná artritída, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M05.89","desc":"Iná séropozitívna reumatoidná artritída, miesto bližšie neurčené"},
{"code":"M05.9-","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená"},
{"code":"M05.90","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, na viacerých miestach"},
{"code":"M05.91","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M05.92","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M05.93","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M05.94","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M05.95","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M05.96","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M05.97","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M05.98","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M05.99","desc":"Séropozitívna reumatoidná artritída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M06.-","desc":"Iné reumatoidné artritídy"},
{"code":"M06.0-","desc":"Séronegatívna reumatoidná artritída"},
{"code":"M06.00","desc":"Séronegatívna reumatoidná artritída, na viacerých miestach"},
{"code":"M06.01","desc":"Séronegatívna reumatoidná artritída, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M06.02","desc":"Séronegatívna reumatoidná artritída, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M06.03","desc":"Séronegatívna reumatoidná artritída, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M06.04","desc":"Séronegatívna reumatoidná artritída, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M06.05","desc":"Séronegatívna reumatoidná artritída, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M06.06","desc":"Séronegatívna reumatoidná artritída, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M06.07","desc":"Séronegatívna reumatoidná artritída, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M06.08","desc":"Séronegatívna reumatoidná artritída, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M06.09","desc":"Séronegatívna reumatoidná artritída, miesto bližšie neurčené"},
{"code":"M06.1-","desc":"Stillova choroba dospelých"},
{"code":"M06.10","desc":"Stillova choroba dospelých s postihnutím kĺbov na viacerých miestach"},
{"code":"M06.11","desc":"Stillova choroba dospelých s postihnutím kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M06.12","desc":"Stillova choroba dospelých s postihnutím kĺbov nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M06.13","desc":"Stillova choroba dospelých s postihnutím kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M06.14","desc":"Stillova choroba dospelých s postihnutím kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M06.15","desc":"Stillova choroba dospelých s postihnutím kĺbov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M06.16","desc":"Stillova choroba dospelých s postihnutím kĺbov predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M06.17","desc":"Stillova choroba dospelých s postihnutím kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M06.18","desc":"Stillova choroba dospelých s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M06.19","desc":"Stillova choroba dospelých: miesto postihnutia kĺbov bližšie neurčené"},
{"code":"M06.2-","desc":"Burzitída pri reumatoidnej artritíde"},
{"code":"M06.20","desc":"Burzitída pri reumatoidnej artritíde na viacerých miestach"},
{"code":"M06.21","desc":"Burzitída pri reumatoidnej artritíde v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M06.22","desc":"Burzitída pri reumatoidnej artritíde nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M06.23","desc":"Burzitída pri reumatoidnej artritíde predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M06.24","desc":"Burzitída pri reumatoidnej artritíde ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M06.25","desc":"Burzitída pri reumatoidnej artritíde panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M06.26","desc":"Burzitída pri reumatoidnej artritíde predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M06.27","desc":"Burzitída pri reumatoidnej artritíde členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M06.28","desc":"Burzitída pri reumatoidnej artritíde na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M06.29","desc":"Burzitída pri reumatoidnej artritíde, miesto bližšie neurčené"},
{"code":"M06.3-","desc":"Reumatoidné uzlíky"},
{"code":"M06.30","desc":"Reumatoidné uzlíky na viacerých miestach"},
{"code":"M06.31","desc":"Reumatoidné uzlíky v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M06.32","desc":"Reumatoidné uzlíky nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M06.33","desc":"Reumatoidné uzlíky predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M06.34","desc":"Reumatoidné uzlíky ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M06.35","desc":"Reumatoidné uzlíky panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M06.36","desc":"Reumatoidné uzlíky predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M06.37","desc":"Reumatoidné uzlíky členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M06.38","desc":"Reumatoidné uzlíky na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M06.39","desc":"Reumatoidné uzlíky, miesto bližšie neurčené"},
{"code":"M06.4-","desc":"Zápalová polyartropatia"},
{"code":"M06.40","desc":"Zápalová polyartropatia na viacerých miestach"},
{"code":"M06.41","desc":"Zápalová polyartropatia v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M06.42","desc":"Zápalová polyartropatia nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M06.43","desc":"Zápalová polyartropatia predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M06.44","desc":"Zápalová polyartropatia ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M06.45","desc":"Zápalová polyartropatia panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M06.46","desc":"Zápalová polyartropatia predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M06.47","desc":"Zápalová polyartropatia členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M06.48","desc":"Zápalová polyartropatia na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M06.49","desc":"Zápalová polyartropatia, miesto bližšie neurčené"},
{"code":"M06.8-","desc":"Iná reumatoidná artritída, bližšie určená"},
{"code":"M06.80","desc":"Iná reumatoidná artritída, bližšie určená, na viacerých miestach"},
{"code":"M06.81","desc":"Iná reumatoidná artritída, bližšie určená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M06.82","desc":"Iná reumatoidná artritída, bližšie určená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M06.83","desc":"Iná reumatoidná artritída, bližšie určená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M06.84","desc":"Iná reumatoidná artritída, bližšie určená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M06.85","desc":"Iná reumatoidná artritída, bližšie určená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M06.86","desc":"Iná reumatoidná artritída, bližšie určená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M06.87","desc":"Iná reumatoidná artritída, bližšie určená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M06.88","desc":"Iná reumatoidná artritída, bližšie určená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M06.89","desc":"Iná reumatoidná artritída, bližšie určená, miesto bližšie neurčené"},
{"code":"M06.9-","desc":"Reumatoidná artritída, bližšie neurčená"},
{"code":"M06.90","desc":"Reumatoidná artritída, bližšie neurčená, na viacerých miestach"},
{"code":"M06.91","desc":"Reumatoidná artritída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M06.92","desc":"Reumatoidná artritída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M06.93","desc":"Reumatoidná artritída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M06.94","desc":"Reumatoidná artritída, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M06.95","desc":"Reumatoidná artritída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M06.96","desc":"Reumatoidná artritída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M06.97","desc":"Reumatoidná artritída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M06.98","desc":"Reumatoidná artritída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M06.99","desc":"Reumatoidná artritída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M07.-*","desc":"Psoriatická a enteropatická artropatia"},
{"code":"M07.0-*","desc":"Distálna interfalangeálna psoriatická artritída (L40.5†)"},
{"code":"M07.00*","desc":"Distálna interfalangeálna psoriatická artritída, na viacerých miestach (L40.5†)"},
{"code":"M07.04*","desc":"Distálna interfalangeálna psoriatická artritída, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (L40.5†)"},
{"code":"M07.07*","desc":"Distálna psoriatická artritída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (L40.5†)"},
{"code":"M07.09*","desc":"Distálna interfalangeálna psoriatická artritída, miesto bližšie neurčené (L40.5†)"},
{"code":"M07.1-*","desc":"Arthritis mutilans (L40.5†)"},
{"code":"M07.10*","desc":"Arthritis mutilans, na viacerých miestach (L40.5†)"},
{"code":"M07.11*","desc":"Arthritis mutilans, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)(L40.5†)"},
{"code":"M07.12*","desc":"Arthritis mutilans, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (L40.5†)"},
{"code":"M07.13*","desc":"Arthritis mutilans, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (L40.5†)"},
{"code":"M07.14*","desc":"Arthritis mutilans (L40.5†): (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (L40.5†)"},
{"code":"M07.15*","desc":"Arthritis mutilans, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (L40.5†)"},
{"code":"M07.16*","desc":"Arthritis mutilans, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (L40.5†)"},
{"code":"M07.17*","desc":"Arthritis mutilans, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (L40.5†)"},
{"code":"M07.18*","desc":"Arthritis mutilans, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (L40.5†)"},
{"code":"M07.19*","desc":"Arthritis mutilans, miesto bližšie neurčené (L40.5†)"},
{"code":"M07.2*","desc":"Psoriatická spondylitída (L40.5†)"},
{"code":"M07.3-*","desc":"Iná psoriatická artritída (L40.5†)"},
{"code":"M07.30*","desc":"Iná psoriatická artritída, na viacerých miestach (L40.5†)"},
{"code":"M07.31*","desc":"Iná psoriatická artritída, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (L40.5†)"},
{"code":"M07.32*","desc":"Iná psoriatická artritída, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (L40.5†)"},
{"code":"M07.33*","desc":"Iná psoriatická artritída, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (L40.5†)"},
{"code":"M07.34*","desc":"Iná psoriatická artritída, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (L40.5†)"},
{"code":"M07.35*","desc":"Iná psoriatická artritída, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (L40.5†)"},
{"code":"M07.36*","desc":"Iná psoriatická artritída, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (L40.5†)"},
{"code":"M07.37*","desc":"Iná psoriatická artritída, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (L40.5†)"},
{"code":"M07.38*","desc":"Iná psoriatická artritída, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (L40.5†)"},
{"code":"M07.39*","desc":"Iná psoriatická artritída, miesto bližšie neurčené (L40.5†)"},
{"code":"M07.4-*","desc":"Artritída pri Crohnovej chorobe (K50.-†)"},
{"code":"M07.40*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov na viacerých miestach (K50.-†)"},
{"code":"M07.41*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (K50.-†)"},
{"code":"M07.42*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov nadlaktia (ramenná kosť, lakťový kĺb) (K50.-†)"},
{"code":"M07.43*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (K50.-†)"},
{"code":"M07.44*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (K50.-†)"},
{"code":"M07.45*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (K50.-†)"},
{"code":"M07.46*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov predkolenia (ihlica, píšťala, kolenný kĺb) (K50.-†)"},
{"code":"M07.47*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (K50.-†)"},
{"code":"M07.48*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (K50.-†)"},
{"code":"M07.49*","desc":"Artritída pri Crohnovej chorobe s postihnutím kĺbov, miesto bližšie neurčené (K50.-†)"},
{"code":"M07.5-*","desc":"Artritída pri ulceróznej kolitíde (K51.-†)"},
{"code":"M07.50*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov na viacerých miestach (K51.-†)"},
{"code":"M07.51*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (K51.-†)"},
{"code":"M07.52*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov nadlaktia (ramenná kosť, lakťový kĺb) (K51.-†)"},
{"code":"M07.53*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (K51.-†)"},
{"code":"M07.54*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (K51.-†)"},
{"code":"M07.55*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (K51.-†)"},
{"code":"M07.56*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov predkolenia (ihlica, píšťala, kolenný kĺb) (K51.-†)"},
{"code":"M07.57*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (K51.-†)"},
{"code":"M07.58*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (K51.-†)"},
{"code":"M07.59*","desc":"Artritída pri ulceróznej kolitíde s postihnutím kĺbov, miesto bližšie neurčené (K51.-†)"},
{"code":"M07.6-*","desc":"Iná enteropatická artritída"},
{"code":"M07.60*","desc":"Iná enteropatická artritída na viacerých miestach"},
{"code":"M07.61*","desc":"Iná enteropatická artritída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M07.62*","desc":"Iná enteropatická artritída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M07.63*","desc":"Iná enteropatická artritída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M07.64*","desc":"Iná enteropatická artritída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M07.65*","desc":"Iná enteropatická artritída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M07.66*","desc":"Iná enteropatická artritída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M07.67*","desc":"Iná enteropatická artritída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M07.68*","desc":"Iná enteropatická artritída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M07.69*","desc":"Iná enteropatická artritída, miesto bližšie neurčené"},
{"code":"M08.-","desc":"Juvenilná artritída"},
{"code":"M08.0-","desc":"Juvenilná reumatoidná artritída"},
{"code":"M08.00","desc":"Juvenilná reumatoidná artritída na viacerých miestach"},
{"code":"M08.01","desc":"Juvenilná reumatoidná artritída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M08.02","desc":"Juvenilná reumatoidná artritída v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M08.03","desc":"Juvenilná reumatoidná artritída v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M08.04","desc":"Juvenilná reumatoidná artritída v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M08.05","desc":"Juvenilná reumatoidná artritída v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M08.06","desc":"Juvenilná reumatoidná artritída v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M08.07","desc":"Juvenilná reumatoidná artritída v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M08.08","desc":"Juvenilná reumatoidná artritída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M08.09","desc":"Juvenilná reumatoidná artritída, miesto bližšie neurčené"},
{"code":"M08.1-","desc":"Juvenilná ankylozujúca spondylitída"},
{"code":"M08.10","desc":"Juvenilná ankylozujúca spondylitída na viacerých miestach"},
{"code":"M08.11","desc":"Juvenilná ankylozujúca spondylitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M08.12","desc":"Juvenilná ankylozujúca spondylitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M08.13","desc":"Juvenilná ankylozujúca spondylitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M08.14","desc":"Juvenilná ankylozujúca spondylitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M08.15","desc":"Juvenilná ankylozujúca spondylitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M08.16","desc":"Juvenilná ankylozujúca spondylitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M08.17","desc":"Juvenilná ankylozujúca spondylitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M08.18","desc":"Juvenilná ankylozujúca spondylitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M08.19","desc":"Juvenilná ankylozujúca spondylitída, miesto bližšie neurčené"},
{"code":"M08.2-","desc":"Juvenilná chronická artritída so systémovým začiatkom"},
{"code":"M08.20","desc":"Juvenilná chronická artritída so systémovým začiatkom, na viacerých miestach"},
{"code":"M08.21","desc":"Juvenilná chronická artritída so systémovým začiatkom, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M08.22","desc":"Juvenilná chronická artritída so systémovým začiatkom, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M08.23","desc":"Juvenilná chronická artritída so systémovým začiatkom, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M08.24","desc":"Juvenilná chronická artritída so systémovým začiatkom, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M08.25","desc":"Juvenilná chronická artritída so systémovým začiatkom, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M08.26","desc":"Juvenilná chronická artritída so systémovým začiatkom, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M08.27","desc":"Juvenilná chronická artritída so systémovým začiatkom, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M08.28","desc":"Juvenilná chronická artritída so systémovým začiatkom, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M08.29","desc":"Juvenilná chronická artritída so systémovým začiatkom, miesto bližšie neurčené"},
{"code":"M08.3","desc":"Juvenilná chronická artritída (séronegatívna), polyartikulárna forma"},
{"code":"M08.4-","desc":"Juvenilná chronická artritída, oligoartikulárna forma"},
{"code":"M08.40","desc":"Juvenilná chronická artritída, oligoartikulárna forma, s postihnutím na viacerých miestach"},
{"code":"M08.41","desc":"Juvenilná chronická artritída, oligoartikulárna forma, s postihnutím v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M08.42","desc":"Juvenilná chronická artritída, oligoartikulárna forma, s postihnutím nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M08.43","desc":"Juvenilná chronická artritída, oligoartikulárna forma, s postihnutím predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M08.44","desc":"Juvenilná chronická artritída, oligoartikulárna forma, s postihnutím ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M08.45","desc":"Juvenilná chronická artritída, oligoartikulárna forma, s postihnutím panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M08.46","desc":"Juvenilná chronická artritída, oligoartikulárna forma, s postihnutím predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M08.47","desc":"Juvenilná chronická artritída, oligoartikulárna forma, s postihnutím členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M08.48","desc":"Juvenilná chronická artritída, oligoartikulárna forma, s postihnutím na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M08.49","desc":"Juvenilná chronická artritída, oligoartikulárna forma, miesto postihnutia bližšie neurčené"},
{"code":"M08.7-","desc":"Juvenilná artritída s vaskulitídou"},
{"code":"M08.70","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov na viacerých miestach"},
{"code":"M08.71","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M08.72","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M08.73","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M08.74","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M08.75","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M08.76","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M08.77","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M08.78","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M08.79","desc":"Juvenilná artritída s vaskulitídou, s postihnutím kĺbov, miesto bližšie neurčené"},
{"code":"M08.8-","desc":"Iná juvenilná artritída"},
{"code":"M08.80","desc":"Iná juvenilná artritída na viacerých miestach"},
{"code":"M08.81","desc":"Iná juvenilná artritída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M08.82","desc":"Iná juvenilná artritída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M08.83","desc":"Iná juvenilná artritída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M08.84","desc":"Iná juvenilná artritída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M08.85","desc":"Iná juvenilná artritída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M08.86","desc":"Iná juvenilná artritída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M08.87","desc":"Iná juvenilná artritída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M08.88","desc":"Iná juvenilná artritída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M08.89","desc":"Iná juvenilná artritída, miesto bližšie neurčené"},
{"code":"M08.9-","desc":"Juvenilná artritída, bližšie neurčená"},
{"code":"M08.90","desc":"Juvenilná artritída, bližšie neurčená, na viacerých miestach"},
{"code":"M08.91","desc":"Juvenilná artritída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M08.92","desc":"Juvenilná artritída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M08.93","desc":"Juvenilná artritída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M08.94","desc":"Juvenilná artritída, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M08.95","desc":"Juvenilná artritída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M08.96","desc":"Juvenilná artritída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M08.97","desc":"Juvenilná artritída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M08.98","desc":"Juvenilná artritída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M08.99","desc":"Juvenilná artritída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M09.-*","desc":"Juvenilná artritída pri chorobách zatriedených inde"},
{"code":"M09.0-*","desc":"Juvenilná artritída pri psoriáze"},
{"code":"M09.00*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov na viacerých miestach (L40.5†)"},
{"code":"M09.01*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (L40.5†)"},
{"code":"M09.02*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov nadlaktia (ramenná kosť, lakťový kĺb) (L40.5†)"},
{"code":"M09.03*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (L40.5†)"},
{"code":"M09.04*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (L40.5†)"},
{"code":"M09.05*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (L40.5†)"},
{"code":"M09.06*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov predkolenia (ihlica, píšťala, kolenný kĺb) (L40.5†)"},
{"code":"M09.07*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (L40.5†)"},
{"code":"M09.08*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (L40.5†)"},
{"code":"M09.09*","desc":"Juvenilná artritída pri psoriáze, s postihnutím kĺbov, miesto bližšie neurčené (L40.5†)"},
{"code":"M09.1-*","desc":"Juvenilná artritída pri Crohnovej chorobe (K50.-†)"},
{"code":"M09.10*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov na viacerých miestach (K50.-†)"},
{"code":"M09.11*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M09.12*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov nadlaktia (ramenná kosť, lakťový kĺb) (K50.-†)"},
{"code":"M09.13*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (K50.-†)"},
{"code":"M09.14*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (K50.-†)"},
{"code":"M09.15*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (K50.-†)"},
{"code":"M09.16*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov predkolenia (ihlica, píšťala, kolenný kĺb) (K50.-†)"},
{"code":"M09.17*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (K50.-†)"},
{"code":"M09.18*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (K50.-†)"},
{"code":"M09.19*","desc":"Juvenilná artritída pri Crohnovej chorobe, s postihnutím kĺbov, miesto bližšie neurčené (K50.-†)"},
{"code":"M09.2-*","desc":"Juvenilná artritída pri ulceróznej kolitíde (K51.-†)"},
{"code":"M09.20*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov na viacerých miestach (K51.-†)"},
{"code":"M09.21*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (K51.-†)"},
{"code":"M09.22*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov nadlaktia (ramenná kosť, lakťový kĺb) (K51.-†)"},
{"code":"M09.23*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (K51.-†)"},
{"code":"M09.24*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (K51.-†)"},
{"code":"M09.25*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (K51.-†)"},
{"code":"M09.26*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov predkolenia (ihlica, píšťala, kolenný kĺb) (K51.-†)"},
{"code":"M09.27*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (K51.-†)"},
{"code":"M09.28*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (K51.-†)"},
{"code":"M09.29*","desc":"Juvenilná artritída pri ulceróznej kolitíde, s postihnutím kĺbov, miesto bližšie neurčené (K51.-†)"},
{"code":"M09.8-*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde"},
{"code":"M09.80*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov na viacerých miestach"},
{"code":"M09.81*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M09.82*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M09.83*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M09.84*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M09.85*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M09.86*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M09.87*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M09.88*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M09.89*","desc":"Juvenilná artritída pri iných chorobách zatriedených inde, s postihnutím kĺbov, miesto bližšie neurčené"},
{"code":"M10.-","desc":"Dna [Lámka]"},
{"code":"M10.0-","desc":"Idiopatická dna"},
{"code":"M10.00","desc":"Idiopatická dna na viacerých miestach"},
{"code":"M10.01","desc":"Idiopatická dna v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M10.02","desc":"Idiopatická dna nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M10.03","desc":"Idiopatická dna predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M10.04","desc":"Idiopatická dna ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M10.05","desc":"Idiopatická dna panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M10.06","desc":"Idiopatická dna predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M10.07","desc":"Idiopatická dna členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M10.08","desc":"Idiopatická dna na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M10.09","desc":"Idiopatická dna, miesto bližšie neurčené"},
{"code":"M10.1-","desc":"Dna zapríčinená olovom"},
{"code":"M10.10","desc":"Dna zapríčinená olovom, na viacerých miestach"},
{"code":"M10.11","desc":"Dna zapríčinená olovom, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M10.12","desc":"Dna zapríčinená olovom, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M10.13","desc":"Dna zapríčinená olovom, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M10.14","desc":"Dna zapríčinená olovom, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M10.15","desc":"Dna zapríčinená olovom, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M10.16","desc":"Dna zapríčinená olovom, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M10.17","desc":"Dna zapríčinená olovom, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M10.18","desc":"Dna zapríčinená olovom, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M10.19","desc":"Dna zapríčinená olovom, miesto bližšie neurčené"},
{"code":"M10.2-","desc":"Dna zapríčinená liekmi"},
{"code":"M10.20","desc":"Dna zapríčinená liekmi, na viacerých miestach"},
{"code":"M10.21","desc":"Dna zapríčinená liekmi, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M10.22","desc":"Dna zapríčinená liekmi, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M10.23","desc":"Dna zapríčinená liekmi, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M10.24","desc":"Dna zapríčinená liekmi, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M10.25","desc":"Dna zapríčinená liekmiv v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M10.26","desc":"Dna zapríčinená liekmi, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M10.27","desc":"Dna zapríčinená liekmi, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M10.28","desc":"Dna zapríčinená liekmi, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M10.29","desc":"Dna zapríčinená liekmi, miesto bližšie neurčené"},
{"code":"M10.3-","desc":"Dna zapríčinená poruchou funkcie obličiek"},
{"code":"M10.30","desc":"Dna zapríčinená poruchou funkcie obličiek, na viacerých miestach"},
{"code":"M10.31","desc":"Dna zapríčinená poruchou funkcie obličiek: s postihnutím v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M10.32","desc":"Dna zapríčinená poruchou funkcie obličiek: s postihnutím nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M10.33","desc":"Dna zapríčinená poruchou funkcie obličiek: s postihnutím predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M10.34","desc":"Dna zapríčinená poruchou funkcie obličiek: s postihnutím ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M10.35","desc":"Dna zapríčinená poruchou funkcie obličiek: s postihnutím panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M10.36","desc":"Dna zapríčinená poruchou funkcie obličiek: s postihnutím predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M10.37","desc":"Dna zapríčinená poruchou funkcie obličiek: s postihnutím členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M10.38","desc":"Dna zapríčinená poruchou funkcie obličiek: s postihnutím na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M10.39","desc":"Dna zapríčinená poruchou funkcie obličiek: s postihnutím, miesto bližšie neurčené"},
{"code":"M10.4-","desc":"Iná sekundárna dna"},
{"code":"M10.40","desc":"Iná sekundárna dna na viacerých miestach"},
{"code":"M10.41","desc":"Iná sekundárna dna v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M10.42","desc":"Iná sekundárna dna nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M10.43","desc":"Iná sekundárna dna predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M10.44","desc":"Iná sekundárna dna ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M10.45","desc":"Iná sekundárna dna panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M10.46","desc":"Iná sekundárna dna predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M10.47","desc":"Iná sekundárna dna členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M10.48","desc":"Iná sekundárna dna na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M10.49","desc":"Iná sekundárna dna, miesto bližšie neurčené"},
{"code":"M10.9-","desc":"Dna, bližšie neurčená"},
{"code":"M10.90","desc":"Dna, bližšie neurčená, na viacerých miestach"},
{"code":"M10.91","desc":"Dna, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M10.92","desc":"Dna, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M10.93","desc":"Dna, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M10.94","desc":"Dna, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M10.95","desc":"Dna, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M10.96","desc":"Dna, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M10.97","desc":"Dna, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M10.98","desc":"Dna, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M10.99","desc":"Dna, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M11.-","desc":"Iné kryštálové artropatie"},
{"code":"M11.0-","desc":"Apatitová artropatia"},
{"code":"M11.00","desc":"Apatitová artropatia na viacerých miestach"},
{"code":"M11.01","desc":"Apatitová artropatia v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M11.02","desc":"Apatitová artropatia nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M11.03","desc":"Apatitová artropatia predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M11.04","desc":"Apatitová artropatia ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M11.05","desc":"Apatitová artropatia panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M11.06","desc":"Apatitová artropatia predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M11.07","desc":"Apatitová artropatia členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M11.08","desc":"Apatitová artropatia na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M11.09","desc":"Apatitová artropatia, miesto bližšie neurčené"},
{"code":"M11.1-","desc":"Familiárna chondrokalcinóza"},
{"code":"M11.10","desc":"Familiárna chondrokalcinóza na viacerých miestach"},
{"code":"M11.11","desc":"Familiárna chondrokalcinóza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M11.12","desc":"Familiárna chondrokalcinóza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M11.13","desc":"Familiárna chondrokalcinóza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M11.14","desc":"Familiárna chondrokalcinóza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M11.15","desc":"Familiárna chondrokalcinóza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M11.16","desc":"Familiárna chondrokalcinóza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M11.17","desc":"Familiárna chondrokalcinóza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M11.18","desc":"Familiárna chondrokalcinóza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M11.19","desc":"Familiárna chondrokalcinóza, miesto bližšie neurčené"},
{"code":"M11.2-","desc":"Iná chondrokalcinóza"},
{"code":"M11.20","desc":"Iná chondrokalcinóza na viacerých miestach"},
{"code":"M11.21","desc":"Iná chondrokalcinóza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M11.22","desc":"Iná chondrokalcinóza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M11.23","desc":"Iná chondrokalcinóza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M11.24","desc":"Iná chondrokalcinóza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M11.25","desc":"Iná chondrokalcinóza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M11.26","desc":"Iná chondrokalcinóza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M11.27","desc":"Iná chondrokalcinóza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M11.28","desc":"Iná chondrokalcinóza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M11.29","desc":"Iná chondrokalcinóza, miesto bližšie neurčené"},
{"code":"M11.8-","desc":"Iné kryštálové artropatie, bližšie určené"},
{"code":"M11.80","desc":"Iné kryštálové artropatie, bližšie určené, na viacerých miestach"},
{"code":"M11.81","desc":"Iné kryštálové artropatie, bližšie určené, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M11.82","desc":"Iné kryštálové artropatie, bližšie určené, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M11.83","desc":"Iné kryštálové artropatie, bližšie určené, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M11.84","desc":"Iné kryštálové artropatie, bližšie určené, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M11.85","desc":"Iné kryštálové artropatie, bližšie určené, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M11.86","desc":"Iné kryštálové artropatie, bližšie určené, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M11.87","desc":"Iné kryštálové artropatie, bližšie určené, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M11.88","desc":"Iné kryštálové artropatie, bližšie určené, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M11.89","desc":"Iné kryštálové artropatie, bližšie určené, miesto bližšie neurčené"},
{"code":"M11.9-","desc":"Kryštálová artropatia, bližšie neurčená"},
{"code":"M11.90","desc":"Kryštálová artropatia, bližšie neurčená, na viacerých miestach"},
{"code":"M11.91","desc":"Kryštálová artropatia, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M11.92","desc":"Kryštálová artropatia, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M11.93","desc":"Kryštálová artropatia, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M11.94","desc":"Kryštálová artropatia, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M11.95","desc":"Kryštálová artropatia, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M11.96","desc":"Kryštálová artropatia, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M11.97","desc":"Kryštálová artropatia, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M11.98","desc":"Kryštálová artropatia, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M11.99","desc":"Kryštálová artropatia, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M12.-","desc":"Iné artropatie, bližšie určené"},
{"code":"M12.0-","desc":"Chronická poreumatická artritída [Jaccoudova]"},
{"code":"M12.00","desc":"Chronická poreumatická artritída na viacerých miestach"},
{"code":"M12.01","desc":"Chronická poreumatická artritída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M12.02","desc":"Chronická poreumatická artritída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M12.03","desc":"Chronická poreumatická artritída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M12.04","desc":"Chronická poreumatická artritída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M12.05","desc":"Chronická poreumatická artritída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M12.06","desc":"Chronická poreumatická artritída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M12.07","desc":"Chronická poreumatická artritída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M12.08","desc":"Chronická poreumatická artritída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M12.09","desc":"Chronická poreumatická artritída, miesto bližšie neurčené"},
{"code":"M12.1-","desc":"Kaschinova-Beckova choroba"},
{"code":"M12.10","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov na viacerých miestach"},
{"code":"M12.11","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M12.12","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M12.13","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M12.14","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M12.15","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M12.16","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M12.17","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M12.18","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M12.19","desc":"Kaschinova-Beckova choroba s postihnutím kĺbov, miesto bližšie neurčené"},
{"code":"M12.2-","desc":"Vilonodulárna synovitída (pigmentová)"},
{"code":"M12.20","desc":"Vilonodulárna synovitída (pigmentová) na viacerých miestach"},
{"code":"M12.21","desc":"Vilonodulárna synovitída (pigmentová) v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M12.22","desc":"Vilonodulárna synovitída (pigmentová) nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M12.23","desc":"Vilonodulárna synovitída (pigmentová) predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M12.24","desc":"Vilonodulárna synovitída (pigmentová) ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M12.25","desc":"Vilonodulárna synovitída (pigmentová) panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M12.26","desc":"Vilonodulárna synovitída (pigmentová) predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M12.27","desc":"Vilonodulárna synovitída (pigmentová) členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M12.28","desc":"Vilonodulárna synovitída (pigmentová) na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M12.29","desc":"Vilonodulárna synovitída (pigmentová), miesto bližšie neurčené"},
{"code":"M12.3-","desc":"Palindromický reumatizmus"},
{"code":"M12.30","desc":"Palindromický reumatizmus na viacerých miestach"},
{"code":"M12.31","desc":"Palindromický reumatizmus v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M12.32","desc":"Palindromický reumatizmus nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M12.33","desc":"Palindromický reumatizmus predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M12.34","desc":"Palindromický reumatizmus ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M12.35","desc":"Palindromický reumatizmus panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M12.36","desc":"Palindromický reumatizmus predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M12.37","desc":"Palindromický reumatizmus členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M12.38","desc":"Palindromický reumatizmus na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M12.39","desc":"Palindromický reumatizmus, miesto bližšie neurčené"},
{"code":"M12.4-","desc":"Intermitentná hydrartróza"},
{"code":"M12.40","desc":"Intermitentná hydrartróza na viacerých miestach"},
{"code":"M12.41","desc":"Intermitentná hydrartróza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M12.42","desc":"Intermitentná hydrartróza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M12.43","desc":"Intermitentná hydrartróza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M12.44","desc":"Intermitentná hydrartróza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M12.45","desc":"Intermitentná hydrartróza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M12.46","desc":"Intermitentná hydrartróza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M12.47","desc":"Intermitentná hydrartróza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M12.48","desc":"Intermitentná hydrartróza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M12.49","desc":"Intermitentná hydrartróza, miesto bližšie neurčené"},
{"code":"M12.5-","desc":"Úrazová artropatia"},
{"code":"M12.50","desc":"Úrazová artropatia na viacerých miestach"},
{"code":"M12.51","desc":"Úrazová artropatia v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M12.52","desc":"Úrazová artropatia nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M12.53","desc":"Úrazová artropatia predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M12.54","desc":"Úrazová artropatia ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M12.55","desc":"Úrazová artropatia panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M12.56","desc":"Úrazová artropatia predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M12.57","desc":"Úrazová artropatia členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M12.58","desc":"Úrazová artropatia na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M12.59","desc":"Úrazová artropatia, miesto bližšie neurčené"},
{"code":"M12.8-","desc":"Iná artropatia, bližšie určená, nezatriedená inde"},
{"code":"M12.80","desc":"Iná artropatia, bližšie určená, nezatriedená inde, s postihnutím kĺbov na viacerých miestach"},
{"code":"M12.81","desc":"Iná artropatia, bližšie určená, nezatriedená inde, s postihnutím kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M12.82","desc":"Iná artropatia, bližšie určená, nezatriedená inde, s postihnutím kĺbov nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M12.83","desc":"Iná artropatia, bližšie určená, nezatriedená inde, s postihnutím kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M12.84","desc":"Iná artropatia, bližšie určená, nezatriedená inde, s postihnutím kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M12.85","desc":"Iná artropatia, bližšie určená, nezatriedená inde, s postihnutím kĺbov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M12.86","desc":"Iná artropatia, bližšie určená, nezatriedená inde, s postihnutím kĺbov predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M12.87","desc":"Iná bližšie určená artropatia, nezatriedená inde, s postihnutím kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M12.88","desc":"Iná artropatia, bližšie určená, nezatriedená inde, s postihnutím kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M12.89","desc":"Iná artropatia, bližšie určená, nezatriedená inde, s postihnutím kĺbov, miesto bližšie neurčené"},
{"code":"M13.-","desc":"Iná artritída"},
{"code":"M13.0","desc":"Polyartritída, bližšie neurčená"},
{"code":"M13.1-","desc":"Monoartritída, nezatriedená inde"},
{"code":"M13.11","desc":"Monoartritída, nezatriedená inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M13.12","desc":"Monoartritída, nezatriedená inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb),"},
{"code":"M13.13","desc":"Monoartritída, nezatriedená inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb),"},
{"code":"M13.14","desc":"Monoartritída, nezatriedená inde: nezatriedená inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami),"},
{"code":"M13.15","desc":"Monoartritída, nezatriedená inde, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb),"},
{"code":"M13.16","desc":"Monoartritída, nezatriedená inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb),"},
{"code":"M13.17","desc":"Monoartritída, nezatriedená inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy),"},
{"code":"M13.18","desc":"Monoartritída, nezatriedená inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica),"},
{"code":"M13.19","desc":"Monoartritída, nezatriedená inde, miesto bližšie neurčené,"},
{"code":"M13.8-","desc":"Iná artritída, bližšie určená"},
{"code":"M13.80","desc":"Iná artritída, bližšie určená, na viacerých miestach"},
{"code":"M13.81","desc":"Iná artritída, bližšie určená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M13.82","desc":"Iná artritída, bližšie určená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M13.83","desc":"Iná artritída, bližšie určená: predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M13.84","desc":"Iná artritída, bližšie určená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M13.85","desc":"Iná artritída, bližšie určená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M13.86","desc":"Iná artritída, bližšie určená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M13.87","desc":"Iná artritída, bližšie určená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M13.88","desc":"Iná artritída, bližšie určená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M13.89","desc":"Iná artritída, bližšie určená, miesto bližšie neurčené"},
{"code":"M13.9-","desc":"Artritída, bližšie neurčená"},
{"code":"M13.90","desc":"Artritída, bližšie neurčená, na viacerých miestach"},
{"code":"M13.91","desc":"Artritída, bližšie neurčená:v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M13.92","desc":"Artritída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M13.93","desc":"Artritída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M13.94","desc":"Artritída, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M13.95","desc":"Artritída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M13.96","desc":"Artritída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M13.97","desc":"Artritída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M13.98","desc":"Artritída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M13.99","desc":"Artritída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M14.-*","desc":"Artropatie pri iných chorobách zatriedených inde"},
{"code":"M14.0*","desc":"Dnová artropatia zapríčinená enzýmovými defektmi a inými dedičnými poruchami"},
{"code":"M14.1*","desc":"Kryštálová artropatia pri iných metabolických poruchách zatriedených inde"},
{"code":"M14.2*","desc":"Diabetická artropatia (E10-E14, štvrtá pozícia .6†)"},
{"code":"M14.3*","desc":"Multicentrická retikulohistiocytóza (E78.8†)"},
{"code":"M14.4*","desc":"Artropatia pri amyloidóze (E85.-†)"},
{"code":"M14.5*","desc":"Artropatia pri iných endokrinných, nutričných a metabolických chorobách"},
{"code":"M14.6*","desc":"Neuropatická artropatia"},
{"code":"M14.8*","desc":"Artropatia pri iných bližšie určených chorobách zatriedených inde"},
{"code":"M15-M19","desc":"Artróza"},
{"code":"M15.-","desc":"Polyartróza"},
{"code":"M15.0","desc":"Primárna generalizovaná (osteo-)artróza"},
{"code":"M15.1","desc":"Heberdenove uzly (s artropatiou)"},
{"code":"M15.2","desc":"Bouchardove uzly (s artropatiou)"},
{"code":"M15.3","desc":"Sekundárna mnohopočetná artróza"},
{"code":"M15.4","desc":"Erozívna (osteo-)artróza"},
{"code":"M15.8","desc":"Iná polyartróza"},
{"code":"M15.9","desc":"Polyartróza, bližšie neurčená"},
{"code":"M16.-","desc":"Artróza bedrového kĺbu"},
{"code":"M16.0","desc":"Primárna artróza bedrového kĺbu, obojstranná"},
{"code":"M16.1","desc":"Iná primárna artróza bedrového kĺbu"},
{"code":"M16.2","desc":"Artróza bedrového kĺbu zapríčinená dyspláziou, obojstranná"},
{"code":"M16.3","desc":"Iná dysplastická artróza bedrového kĺbu"},
{"code":"M16.4","desc":"Poúrazová artróza bedrového kĺbu, obojstranná"},
{"code":"M16.5","desc":"Iná poúrazová koxartróza"},
{"code":"M16.6","desc":"Iná sekundárna artróza bedrového kĺbu, obojstranná"},
{"code":"M16.7","desc":"Iná sekundárna artróza bedrového kĺbu"},
{"code":"M16.9","desc":"Artróza bedrového kĺbu, bližšie neurčená"},
{"code":"M17.-","desc":"Artróza kolenného kĺbu"},
{"code":"M17.0","desc":"Primárna artróza kolenného kĺbu, obojstranná"},
{"code":"M17.1","desc":"Iná primárna artróza kolenného kĺbu"},
{"code":"M17.2","desc":"Poúrazová artróza kolenného kĺbu, obojstranná"},
{"code":"M17.3","desc":"Iná poúrazová artróza kolenného kĺbu"},
{"code":"M17.4","desc":"Iná sekundárna artróza kolenného kĺbu, obojstranná"},
{"code":"M17.5","desc":"Iná sekundárna artróza kolenného kĺbu"},
{"code":"M17.9","desc":"Artróza kolenného kĺbu, bližšie neurčená"},
{"code":"M18.-","desc":"Artróza prvého karpometakarpového kĺbu"},
{"code":"M18.0","desc":"Primárna artróza prvého karpometakarpového kĺbu, obojstranná"},
{"code":"M18.1","desc":"Iná primárna artróza prvého karpometakarpového kĺbu"},
{"code":"M18.2","desc":"Poúrazová artróza prvého karpometakarpového kĺbu, obojstranná"},
{"code":"M18.3","desc":"Iná poúrazová artróza prvého karpometakarpového kĺbu"},
{"code":"M18.4","desc":"Iná sekundárna artróza prvého karpometakarpového kĺbu, obojstranná"},
{"code":"M18.5","desc":"Iná sekundárna artróza prvého karpometakarpového kĺbu"},
{"code":"M18.9","desc":"Artróza prvého karpometakarpového kĺbu, bližšie neurčená"},
{"code":"M19.-","desc":"Iné artrózy"},
{"code":"M19.0-","desc":"Primárna artróza iných kĺbov"},
{"code":"M19.01","desc":"Primárna artróza iných kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M19.02","desc":"Primárna artróza iných kĺbov nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M19.03","desc":"Primárna artróza iných kĺbov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M19.04","desc":"Primárna artróza iných kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M19.05","desc":"Primárna artróza iných kĺbov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M19.07","desc":"Primárna artróza iných kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M19.08","desc":"Primárna artróza iných kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M19.09","desc":"Primárna artróza iných kĺbov, miesto bližšie neurčené"},
{"code":"M19.1-","desc":"Poúrazová artróza iných kĺbov"},
{"code":"M19.11","desc":"Poúrazová artróza iných kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M19.12","desc":"Poúrazová artróza iných kĺbov nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M19.13","desc":"Poúrazová artróza iných kĺbov v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M19.14","desc":"Poúrazová artróza iných kĺbov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M19.15","desc":"Poúrazová artróza iných kĺbov v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M19.17","desc":"Poúrazová artróza iných kĺbov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M19.18","desc":"Poúrazová artróza iných kĺbov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M19.19","desc":"Poúrazová artróza iných kĺbov, miesto bližšie neurčené"},
{"code":"M19.2-","desc":"Iná sekundárna artróza"},
{"code":"M19.21","desc":"Iná sekundárna artróza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M19.22","desc":"Iná sekundárna artróza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M19.23","desc":"Iná sekundárna artróza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M19.24","desc":"Iná sekundárna artróza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M19.25","desc":"Iná sekundárna artróza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M19.27","desc":"Iná sekundárna artróza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M19.28","desc":"Iná sekundárna artróza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M19.29","desc":"Iná sekundárna artróza, miesto bližšie neurčené"},
{"code":"M19.8-","desc":"Iná artróza, bližšie určená"},
{"code":"M19.81","desc":"Iná artróza, bližšie určená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M19.82","desc":"Iná artróza, bližšie určená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M19.83","desc":"Iná artróza, bližšie určená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M19.84","desc":"Iná artróza, bližšie určená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M19.85","desc":"Iná artróza, bližšie určená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M19.87","desc":"Iná artróza, bližšie určená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M19.88","desc":"Iná artróza, bližšie určená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M19.89","desc":"Iná artróza, bližšie určená, miesto bližšie neurčené"},
{"code":"M19.9-","desc":"Artróza, bližšie neurčená"},
{"code":"M19.91","desc":"Artróza, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M19.92","desc":"Artróza, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M19.93","desc":"Artróza, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M19.94","desc":"Artróza, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M19.95","desc":"Artróza, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M19.97","desc":"Artróza, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M19.98","desc":"Artróza, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M19.99","desc":"Artróza, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M20-M25","desc":"Iné choroby kĺbov"},
{"code":"M20.-","desc":"Získané deformity prstov rúk a nôh"},
{"code":"M20.0","desc":"Deformity jedného prsta alebo viacerých prstov"},
{"code":"M20.1","desc":"Vbočený palec nohy [hallux valgus] (získaný)"},
{"code":"M20.2","desc":"Stuhnutý palec nohy [hallux rigidus])"},
{"code":"M20.3","desc":"Iná deformita palca nohy (získaná)"},
{"code":"M20.4","desc":"Iný kladivkový prst (prsty) nohy (získaný)"},
{"code":"M20.5","desc":"Iné deformity prsta (prstov) nohy (získané)"},
{"code":"M20.6","desc":"Získaná deformita prsta (prstov), bližšie neurčená"},
{"code":"M21.-","desc":"Iné získané deformity končatín"},
{"code":"M21.0-","desc":"Valgózna deformita [vbočenie], nezatriedená inde"},
{"code":"M21.00","desc":"Valgózna deformita [vbočenie], nezatriedená inde, na viacerých miestach"},
{"code":"M21.01","desc":"Valgózna deformita [vbočenie], nezatriedená inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M21.02","desc":"Valgózna deformita [vbočenie], nezatriedená inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M21.03","desc":"Valgózna deformita [vbočenie], nezatriedená inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M21.04","desc":"Valgózna deformita [vbočenie], nezatriedená inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M21.05","desc":"Valgózna deformita [vbočenie], nezatriedená inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M21.06","desc":"Valgózna deformita [vbočenie], nezatriedená inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M21.07","desc":"Valgózna deformita [vbočenie], nezatriedená inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M21.08","desc":"Valgózna deformita [vbočenie], nezatriedená inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M21.09","desc":"Valgózna deformita [vbočenie], nezatriedená inde, miesto bližšie neurčené"},
{"code":"M21.1-","desc":"Varózna deformita [vybočenie]"},
{"code":"M21.10","desc":"Varózna deformita [vybočenie], nezatriedená inde, na viacerých miestach"},
{"code":"M21.11","desc":"Varózna deformita [vybočenie], nezatriedená inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M21.12","desc":"Varózna deformita [vybočenie], nezatriedená inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M21.13","desc":"Varózna deformita [vybočenie], nezatriedená inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M21.14","desc":"Varózna deformita [vybočenie], nezatriedená inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M21.15","desc":"Varózna deformita [vybočenie], nezatriedená inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M21.16","desc":"Varózna deformita [vybočenie], nezatriedená inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M21.17","desc":"Varózna deformita [vybočenie], nezatriedená inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M21.18","desc":"Varózna deformita [vybočenie], nezatriedená inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M21.19","desc":"Varózna deformita [vybočenie], nezatriedená inde, miesto bližšie neurčené"},
{"code":"M21.2-","desc":"Flekčná deformita"},
{"code":"M21.20","desc":"Flekčná deformita na viacerých miestach"},
{"code":"M21.21","desc":"Flekčná deformita v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M21.22","desc":"Flekčná deformita nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M21.23","desc":"Flekčná deformita predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M21.24","desc":"Flekčná deformita ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M21.25","desc":"Flekčná deformita panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M21.26","desc":"Flekčná deformita predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M21.27","desc":"Flekčná deformita členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M21.28","desc":"Flekčná deformita na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M21.29","desc":"Flekčná deformita, miesto bližšie neurčené"},
{"code":"M21.3-","desc":"Ovisnuté zápästie alebo noha (získané)"},
{"code":"M21.30","desc":"Ovisnuté zápästie alebo noha (získané), na viacerých miestach"},
{"code":"M21.33","desc":"Ovisnuté zápästie (získané) s postihnutím predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M21.37","desc":"Ovisnutá noha (získaná) s postihnutím členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M21.4-","desc":"Plochá noha [Pes planus] (získaná)"},
{"code":"M21.40","desc":"Plochá noha statická [Pes planus staticus]"},
{"code":"M21.41","desc":"Plochá noha fixovaná [Pes planus fixatus]"},
{"code":"M21.42","desc":"Plochá noha kontrahovaná [Pes planus contractus]"},
{"code":"M21.43","desc":"Priečneplochá noha [Pes transversoplanus]"},
{"code":"M21.44","desc":"Plochá noha [Pes planus], bližšie neurčená"},
{"code":"M21.5-","desc":"Získaná pazúrovitá ruka a krátka ruka [talipomanus], získaná pazúrovitá noha a konská noha [talipes]"},
{"code":"M21.50","desc":"Získaná pazúrovitá ruka a krátka ruka [talipomanus], získaná pazúrovitá noha a konská noha [talipes] na viacerých miestach"},
{"code":"M21.54","desc":"Získaná pazúrovitá ruka a krátka ruka [talipomanus]"},
{"code":"M21.57","desc":"Získaná pazúrovitá noha a konská noha [talipes]"},
{"code":"M21.6-","desc":"Iná získaná deformita členka a nohy"},
{"code":"M21.60","desc":"Iná získaná deformita členka a nohy na viacerých miestach"},
{"code":"M21.67","desc":"Iná získaná deformita členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M21.7-","desc":"Nerovnaká dĺžka končatín (získaná)"},
{"code":"M21.70","desc":"Nerovnaká dĺžka končatín (získaná) na viacerých miestach"},
{"code":"M21.71","desc":"Nerovnaká dĺžka končatín (získaná) v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M21.72","desc":"Nerovnaká dĺžka končatín (získaná) v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M21.73","desc":"Nerovnaká dĺžka končatín (získaná) v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M21.74","desc":"Nerovnaká dĺžka končatín (získaná) v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M21.75","desc":"Nerovnaká dĺžka končatín (získaná) v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M21.76","desc":"Nerovnaká dĺžka končatín (získaná) v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M21.77","desc":"Nerovnaká dĺžka končatín (získaná) v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M21.79","desc":"Nerovnaká dĺžka končatín (získaná), miesto bližšie neurčené"},
{"code":"M21.8-","desc":"Iná získaná deformita končatiny, bližšie určená"},
{"code":"M21.80","desc":"Iná získaná deformita končatiny, bližšie určená, na viacerých miestach"},
{"code":"M21.81","desc":"Iná získaná deformita končatiny, bližšie určená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M21.82","desc":"Iná získaná deformita končatiny, bližšie určená: v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M21.83","desc":"Iná získaná deformita končatiny, bližšie určená: v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M21.84","desc":"Iná získaná deformita končatiny, bližšie určená: v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M21.85","desc":"Iná získaná deformita končatiny, bližšie určená: v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M21.86","desc":"Iná získaná deformita končatiny, bližšie určená: v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M21.87","desc":"Iná získaná deformita končatiny, bližšie určená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M21.89","desc":"Iná získaná deformita končatiny, bližšie určená: miesto bližšie neurčené"},
{"code":"M21.9-","desc":"Získaná deformita končatiny, bližšie neurčená"},
{"code":"M21.90","desc":"Získaná deformita končatiny, bližšie neurčená, na viacerých miestach"},
{"code":"M21.91","desc":"Získaná deformita končatiny, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M21.92","desc":"Získaná deformita končatiny, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M21.93","desc":"Získaná deformita končatiny, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M21.94","desc":"Získaná deformita končatiny, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M21.95","desc":"Získaná deformita končatiny, bližšie neurčená, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M21.96","desc":"Získaná deformita končatiny, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M21.97","desc":"Získaná deformita končatiny, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M21.99","desc":"Získaná deformita končatiny, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M22.-","desc":"Choroby pately"},
{"code":"M22.0","desc":"Habituálna luxácia pately"},
{"code":"M22.1","desc":"Habituálna subluxácia pately"},
{"code":"M22.2","desc":"Patelofemorálna porucha"},
{"code":"M22.3","desc":"Iné poškodenie pately"},
{"code":"M22.4","desc":"Chondromalácia pately"},
{"code":"M22.8","desc":"Iná porucha pately"},
{"code":"M22.9","desc":"Choroba pately, bližšie neurčená"},
{"code":"M23.-","desc":"Vnútorná porucha kolenného kĺbu"},
{"code":"M23.0-","desc":"Ganglion menisku"},
{"code":"M23.00","desc":"Ganglion menisku: postihnutie na viacerých miestach"},
{"code":"M23.01","desc":"Ganglion menisku: postihnutie predného rohu vnútorného menisku"},
{"code":"M23.02","desc":"Ganglion menisku: postihnutie zadného rohu vnútorného menisku"},
{"code":"M23.03","desc":"Ganglion menisku: postihnutie inej a bližšie neurčenej časti vnútorného menisku"},
{"code":"M23.04","desc":"Ganglion menisku: postihnutie predného rohu vonkajšieho menisku"},
{"code":"M23.05","desc":"Ganglion menisku: postihnutie zadného rohu vonkajšieho menisku"},
{"code":"M23.06","desc":"Ganglion menisku: postihnutie inej a bližšie neurčenej časti vonkajšieho menisku"},
{"code":"M23.09","desc":"Ganglion menisku: meniskus bližšie neurčený"},
{"code":"M23.1-","desc":"Diskovitý meniskus (vrodený)"},
{"code":"M23.10","desc":"Diskovitý meniskus (vrodený): postihnutie na viacerých miestach"},
{"code":"M23.11","desc":"Diskovitý meniskus (vrodený): postihnutie predného rohu vnútorného menisku"},
{"code":"M23.12","desc":"Diskovitý meniskus (vrodený): postihnutie zadného rohu vnútorného menisku"},
{"code":"M23.13","desc":"Diskovitý meniskus (vrodený): postihnutie inej a bližšie neurčenej časti vnútorného menisku"},
{"code":"M23.14","desc":"Diskovitý meniskus (vrodený): postihnutie predného rohu vonkajšieho menisku"},
{"code":"M23.15","desc":"Diskovitý meniskus (vrodený): postihnutie zadného rohu vonkajšieho menisku"},
{"code":"M23.16","desc":"Diskovitý meniskus (vrodený): postihnutie inej a bližšie neurčenej časti vonkajšieho menisku"},
{"code":"M23.19","desc":"Diskovitý meniskus (vrodený): meniskus bližšie neurčený"},
{"code":"M23.2-","desc":"Poškodenie menisku po starej trhline alebo poranení"},
{"code":"M23.20","desc":"Poškodenie menisku po starej trhline alebo poranení, na viacerých miestach"},
{"code":"M23.21","desc":"Poškodenie menisku po starej trhline alebo poranení: postihnutie predného rohu vnútorného menisku"},
{"code":"M23.22","desc":"Poškodenie menisku po starej trhline alebo poranení: postihnutie zadného rohu vnútorného menisku"},
{"code":"M23.23","desc":"Poškodenie menisku po starej trhline alebo poranení: postihnutie inej a bližšie neurčenej časti vnútorného menisku"},
{"code":"M23.24","desc":"Poškodenie menisku po starej trhline alebo poranení: postihnutie predného rohu vonkajšieho menisku"},
{"code":"M23.25","desc":"Poškodenie menisku po starej trhline alebo poranení: postihnutie zadného rohu vonkajšieho menisku"},
{"code":"M23.26","desc":"Poškodenie menisku po starej trhline alebo poranení: postihnutie inej a bližšie neurčenej časti vonkajšieho menisku"},
{"code":"M23.29","desc":"Poškodenie menisku po starej trhline alebo poranení: meniskus bližšie neurčený"},
{"code":"M23.3-","desc":"Iné poškodenie menisku"},
{"code":"M23.30","desc":"Iné poškodenie menisku na viacerých miestach"},
{"code":"M23.31","desc":"Iné poškodenie menisku: postihnutie predného rohu vnútorného menisku"},
{"code":"M23.32","desc":"Iné poškodenie menisku: postihnutie zadného rohu vnútorného menisku"},
{"code":"M23.33","desc":"Iné poškodenie menisku: postihnutie inej a bližšie neurčenej časti vnútorného menisku"},
{"code":"M23.34","desc":"Iné poškodenie menisku: postihnutie predného rohu vonkajšieho menisku"},
{"code":"M23.35","desc":"Iné poškodenie menisku: postihnutie zadného rohu vonkajšieho menisku"},
{"code":"M23.36","desc":"Iné poškodenie menisku: postihnutie inej a bližšie neurčenej časti vonkajšieho menisku"},
{"code":"M23.39","desc":"Iné poškodenie menisku: meniskus bližšie neurčený"},
{"code":"M23.4-","desc":"Voľné teleso v kolennom kĺbe"},
{"code":"M23.40","desc":"Voľné teleso v kolennom kĺbe na viacerých miestach"},
{"code":"M23.41","desc":"Voľné teleso v kolennom kĺbe, v oblasti predného skríženého väzu alebo predného rohu vnútorného menisku"},
{"code":"M23.42","desc":"Voľné teleso v kolennom kĺbe, v oblasti zadného skríženého väzu alebo zadného rohu vnútorného menisku"},
{"code":"M23.43","desc":"Voľné teleso v kolennom kĺbe, v oblasti tibiálneho kolaterálneho väzu alebo inej a bližšie neurčenej časti vnútorného menisku"},
{"code":"M23.44","desc":"Voľné teleso v kolennom kĺbe, v oblasti fibulárneho kolaterálneho väzu alebo predného rohu vonkajšieho menisku"},
{"code":"M23.45","desc":"Voľné teleso v kolennom kĺbe, v oblasti zadného rohu vonkajšieho menisku"},
{"code":"M23.46","desc":"Voľné teleso v kolennom kĺbe, v inej a bližšie neurčenej časti vonkajšieho menisku"},
{"code":"M23.47","desc":"Voľné teleso v kolennom kĺbe, v kĺbovom puzdre"},
{"code":"M23.49","desc":"Voľné teleso v kolennom kĺbe, väz alebo meniskus bližšie neurčený"},
{"code":"M23.5-","desc":"Chronická instabilita kolena"},
{"code":"M23.50","desc":"Chronická instabilita kolena na viacerých miestach"},
{"code":"M23.51","desc":"Chronická instabilita kolena v oblasti predného skríženého väzu alebo predného rohu vnútorného menisku"},
{"code":"M23.52","desc":"Chronická instabilita kolena v oblasti zadného skríženého väzu alebo zadného rohu vnútorného menisku"},
{"code":"M23.53","desc":"Chronická instabilita kolena v oblasti tibiálneho kolaterálneho väzu alebo inej a bližšie neurčenej časti vnútorného menisku"},
{"code":"M23.54","desc":"Chronická instabilita kolena v oblasti fibulárneho kolaterálneho väzu alebo predného rohu vonkajšieho menisku"},
{"code":"M23.55","desc":"Chronická instabilita kolena v oblasti zadného rohu vonkajšieho menisku"},
{"code":"M23.56","desc":"Chronická instabilita kolena v inej a bližšie neurčenej časti vonkajšieho menisku"},
{"code":"M23.57","desc":"Chronická instabilita kolena v kĺbovom puzdre"},
{"code":"M23.59","desc":"Chronická instabilita kolena: väz alebo meniskus bližšie neurčený"},
{"code":"M23.6-","desc":"Iné spontánne natrhnutie jedného alebo viacerých väzov kolenného kĺbu"},
{"code":"M23.60","desc":"Iné spontánne natrhnutie jedného alebo viacerých väzov kolenného kĺbu na viacerých miestach"},
{"code":"M23.61","desc":"Iné spontánne natrhnutie jedného alebo viacerých väzov kolenného kĺbu: predný skrížený väz"},
{"code":"M23.62","desc":"Iné spontánne natrhnutie jedného alebo viacerých väzov kolenného kĺbu: zadný skrížený väz"},
{"code":"M23.63","desc":"Iné spontánne natrhnutie jedného alebo viacerých väzov kolenného kĺbu: tibiálny kolaterálny väz"},
{"code":"M23.64","desc":"Iné spontánne natrhnutie jedného alebo viacerých väzov kolenného kĺbu: fibulárny kolaterálny väz"},
{"code":"M23.67","desc":"Iné spontánne natrhnutie jedného alebo viacerých väzov kolenného kĺbu: kĺbové puzdro"},
{"code":"M23.69","desc":"Iné spontánne natrhnutie jedného alebo viacerých väzov kolenného kĺbu: väz bližšie neurčený"},
{"code":"M23.8-","desc":"Iné vnútorné poškodenie kolenného kĺbu"},
{"code":"M23.80","desc":"Iné vnútorné poškodenie kolenného kĺbu na viacerých miestach"},
{"code":"M23.81","desc":"Iné vnútorné poškodenie kolenného kĺbu: predný skrížený väz alebo predný roh vnútorného menisku"},
{"code":"M23.82","desc":"Iné vnútorné poškodenie kolenného kĺbu: zadný skrížený väz alebo zadný roh vnútorného menisku"},
{"code":"M23.83","desc":"Iné vnútorné poškodenie kolenného kĺbu: tibiálny kolaterálny väz alebo iná a bližšie neurčená časť vnútorného menisku"},
{"code":"M23.84","desc":"Iné vnútorné poškodenie kolenného kĺbu: fibulárny kolaterálny väz alebo predný roh vonkajšieho menisku"},
{"code":"M23.85","desc":"Iné vnútorné poškodenie kolenného kĺbu: zadný roh vonkajšieho menisku"},
{"code":"M23.86","desc":"Iné vnútorné poškodenie kolenného kĺbu: iná a bližšie neurčená časť vonkajšieho menisku"},
{"code":"M23.87","desc":"Iné vnútorné poškodenie kolenného kĺbu: kĺbové puzdro"},
{"code":"M23.89","desc":"Iné vnútorné poškodenie kolenného kĺbu: väz alebo meniskus bližšie neurčený"},
{"code":"M23.9-","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené"},
{"code":"M23.90","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené, na viacerých miestach"},
{"code":"M23.91","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené: predný skrížený väz alebo predný roh vnútorného menisku"},
{"code":"M23.92","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené: zadný skrížený väz alebo zadný roh vnútorného menisku"},
{"code":"M23.93","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené: tibiálny kolaterálny väz alebo iná a bližšie neurčená časť vnútorného menisku"},
{"code":"M23.94","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené: fibulárny kolaterálny väz alebo predný roh vonkajšieho menisku"},
{"code":"M23.95","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené: zadný roh vonkajšieho menisku"},
{"code":"M23.96","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené: iná a bližšie neurčená časť vonkajšieho menisku"},
{"code":"M23.97","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené: kĺbové puzdro"},
{"code":"M23.99","desc":"Vnútorné poškodenie kolenného kĺbu, bližšie neurčené: väz alebo meniskus bližšie neurčený"},
{"code":"M24.-","desc":"Iné špecifické poruchy kĺbov"},
{"code":"M24.0-","desc":"Voľné teleso v kĺbe"},
{"code":"M24.00","desc":"Voľné teleso v kĺbe na viacerých miestach"},
{"code":"M24.01","desc":"Voľné teleso v kĺbe v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M24.02","desc":"Voľné teleso v kĺbe nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M24.03","desc":"Voľné teleso v kĺbe predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M24.04","desc":"Voľné teleso v kĺbe ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M24.05","desc":"Voľné teleso v kĺbe panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M24.07","desc":"Voľné teleso v kĺbe členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M24.08","desc":"Voľné teleso v kĺbe na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M24.09","desc":"Voľné teleso v kĺbe, miesto bližšie neurčené"},
{"code":"M24.1-","desc":"Iné poškodenie kĺbovej chrupky"},
{"code":"M24.10","desc":"Iné poškodenie kĺbovej chrupky na viacerých miestach"},
{"code":"M24.11","desc":"Iné poškodenie kĺbovej chrupky v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M24.12","desc":"Iné poškodenie kĺbovej chrupky nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M24.13","desc":"Iné poškodenie kĺbovej chrupky predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M24.14","desc":"Iné poškodenie kĺbovej chrupky ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M24.15","desc":"Iné poškodenie kĺbovej chrupky panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M24.17","desc":"Iné poškodenie kĺbovej chrupky členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M24.18","desc":"Iné poškodenie kĺbovej chrupky na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M24.19","desc":"Iné poškodenie kĺbovej chrupky, miesto bližšie neurčené"},
{"code":"M24.2-","desc":"Choroby väzov"},
{"code":"M24.20","desc":"Choroby väzov na viacerých miestach"},
{"code":"M24.21","desc":"Choroby väzov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M24.22","desc":"Choroby väzov nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M24.23","desc":"Choroby väzov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M24.24","desc":"Choroby väzov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M24.25","desc":"Choroby väzov panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M24.27","desc":"Choroby väzov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M24.28","desc":"Choroby väzov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M24.29","desc":"Choroby väzov, miesto bližšie neurčené"},
{"code":"M24.3-","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde"},
{"code":"M24.30","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, na viacerých miestach"},
{"code":"M24.31","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M24.32","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M24.33","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M24.34","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M24.35","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M24.36","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M24.37","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M24.38","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M24.39","desc":"Patologická luxácia a subluxácia kĺbu, nezatriedená inde, miesto bližšie neurčené"},
{"code":"M24.4-","desc":"Habituálna luxácia a subluxácia kĺbu"},
{"code":"M24.40","desc":"Habituálna luxácia a subluxácia kĺbu na viacerých miestach"},
{"code":"M24.41","desc":"Habituálna luxácia a subluxácia kĺbu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M24.42","desc":"Habituálna luxácia a subluxácia kĺbu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M24.43","desc":"Habituálna luxácia a subluxácia kĺbu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M24.44","desc":"Habituálna luxácia a subluxácia kĺbu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M24.45","desc":"Habituálna luxácia a subluxácia kĺbu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M24.46","desc":"Habituálna luxácia a subluxácia kĺbu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M24.47","desc":"Habituálna luxácia a subluxácia kĺbu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M24.48","desc":"Habituálna luxácia a subluxácia kĺbu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M24.49","desc":"Habituálna luxácia a subluxácia kĺbu, miesto bližšie neurčené"},
{"code":"M24.5-","desc":"Kontraktúra kĺbu"},
{"code":"M24.50","desc":"Kontraktúra kĺbu na viacerých miestach"},
{"code":"M24.51","desc":"Kontraktúra kĺbu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M24.52","desc":"Kontraktúra kĺbu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M24.53","desc":"Kontraktúra kĺbu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M24.54","desc":"Kontraktúra kĺbu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M24.55","desc":"Kontraktúra kĺbu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M24.56","desc":"Kontraktúra kĺbu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M24.57","desc":"Kontraktúra kĺbu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M24.58","desc":"Kontraktúra kĺbu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M24.59","desc":"Kontraktúra kĺbu, miesto bližšie neurčené"},
{"code":"M24.6-","desc":"Ankylóza kĺbu"},
{"code":"M24.60","desc":"Ankylóza kĺbu na viacerých miestach"},
{"code":"M24.61","desc":"Ankylóza kĺbu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M24.62","desc":"Ankylóza kĺbu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M24.63","desc":"Ankylóza kĺbu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M24.64","desc":"Ankylóza kĺbu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M24.65","desc":"Ankylóza kĺbu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M24.66","desc":"Ankylóza kĺbu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M24.67","desc":"Ankylóza kĺbu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M24.68","desc":"Ankylóza kĺbu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M24.69","desc":"Ankylóza kĺbu, miesto bližšie neurčené"},
{"code":"M24.7","desc":"Protrúzia acetábula"},
{"code":"M24.8-","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde"},
{"code":"M24.80","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde, na viacerých miestach"},
{"code":"M24.81","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M24.82","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde: v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M24.83","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde: v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M24.84","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M24.85","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde: v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M24.87","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde: v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M24.88","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde: na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M24.89","desc":"Iné bližšie určené poškodenie kĺbu, nezatriedené inde, miesto bližšie neurčené"},
{"code":"M24.9-","desc":"Poškodenie kĺbu, bližšie neurčené"},
{"code":"M24.90","desc":"Poškodenie kĺbu, bližšie neurčené, na viacerých miestach"},
{"code":"M24.91","desc":"Poškodenie kĺbu, bližšie neurčené, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M24.92","desc":"Poškodenie kĺbu, bližšie neurčené, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M24.93","desc":"Poškodenie kĺbu, bližšie neurčené, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M24.94","desc":"Poškodenie kĺbu, bližšie neurčené, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M24.95","desc":"Poškodenie kĺbu, bližšie neurčené, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M24.97","desc":"Poškodenie kĺbu, bližšie neurčené, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M24.98","desc":"Poškodenie kĺbu, bližšie neurčené, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M24.99","desc":"Poškodenie kĺbu, bližšie neurčené, miesto bližšie neurčené"},
{"code":"M25.-","desc":"Iná choroba kĺbu, nezatriedená inde"},
{"code":"M25.0-","desc":"Hemartros"},
{"code":"M25.00","desc":"Hemartros na viacerých miestach"},
{"code":"M25.01","desc":"Hemartros v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.02","desc":"Hemartros nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M25.03","desc":"Hemartros predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.04","desc":"Hemartros ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.05","desc":"Hemartros panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.06","desc":"Hemartros predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.07","desc":"Hemartros členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.08","desc":"Hemartros na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.09","desc":"Hemartros, miesto bližšie neurčené"},
{"code":"M25.1-","desc":"Fistula kĺbu"},
{"code":"M25.10","desc":"Fistula kĺbu na viacerých miestach"},
{"code":"M25.11","desc":"Fistula kĺbu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.12","desc":"Fistula kĺbu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M25.13","desc":"Fistula kĺbu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.14","desc":"Fistula kĺbu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.15","desc":"Fistula kĺbu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.16","desc":"Fistula kĺbu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.17","desc":"Fistula kĺbu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.18","desc":"Fistula kĺbu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.19","desc":"Fistula kĺbu, miesto bližšie neurčené"},
{"code":"M25.2-","desc":"Labilný (uvoľnený) kĺb"},
{"code":"M25.20","desc":"Labilný (uvoľnený) kĺb na viacerých miestach"},
{"code":"M25.21","desc":"Labilný (uvoľnený) kĺb v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.22","desc":"Labilný (uvoľnený) kĺb nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M25.23","desc":"Labilný (uvoľnený) kĺb predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.24","desc":"Labilný (uvoľnený) kĺb ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.25","desc":"Labilný (uvoľnený) kĺb panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.26","desc":"Labilný (uvoľnený) kĺb predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.27","desc":"Labilný (uvoľnený) kĺb členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.28","desc":"Labilný (uvoľnený) kĺb na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.29","desc":"Labilný (uvoľnený) kĺb, miesto bližšie neurčené"},
{"code":"M25.3-","desc":"Iná instabilita kĺbu"},
{"code":"M25.30","desc":"Iná instabilita kĺbu na viacerých miestach"},
{"code":"M25.31","desc":"Iná instabilita kĺbu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.32","desc":"Iná instabilita kĺbu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M25.33","desc":"Iná instabilita kĺbu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.34","desc":"Iná instabilita kĺbu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.35","desc":"Iná instabilita kĺbu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.36","desc":"Iná instabilita kĺbu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.37","desc":"Iná instabilita kĺbu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.38","desc":"Iná instabilita kĺbu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.39","desc":"Iná instabilita kĺbu, miesto bližšie neurčené"},
{"code":"M25.4-","desc":"Výpotok v kĺbe"},
{"code":"M25.40","desc":"Výpotok v kĺbe na viacerých miestach"},
{"code":"M25.41","desc":"Výpotok v kĺbe v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.42","desc":"Výpotok v kĺbe nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M25.43","desc":"Výpotok v kĺbe predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.44","desc":"Výpotok v kĺbe ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.45","desc":"Výpotok v kĺbe panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.46","desc":"Výpotok v kĺbe predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.47","desc":"Výpotok v kĺbe členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.48","desc":"Výpotok v kĺbe na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.49","desc":"Výpotok v kĺbe, miesto bližšie neurčené"},
{"code":"M25.5-","desc":"Bolesť kĺbu"},
{"code":"M25.50","desc":"Bolesť kĺbu na viacerých miestach"},
{"code":"M25.51","desc":"Bolesť kĺbu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.52","desc":"Bolesť kĺbu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M25.53","desc":"Bolesť kĺbu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.54","desc":"Bolesť kĺbu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.55","desc":"Bolesť kĺbu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.56","desc":"Bolesť kĺbu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.57","desc":"Bolesť kĺbu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.58","desc":"Bolesť kĺbu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.59","desc":"Bolesť kĺbu, miesto bližšie neurčené"},
{"code":"M25.6-","desc":"Stuhnutie kĺbu, nezatriedené inde"},
{"code":"M25.60","desc":"Stuhnutie kĺbu, nezatriedené inde, na viacerých miestach"},
{"code":"M25.61","desc":"Stuhnutie kĺbu, nezatriedené inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.62","desc":"Stuhnutie kĺbu, nezatriedené inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb),"},
{"code":"M25.63","desc":"Stuhnutie kĺbu, nezatriedené inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.64","desc":"Stuhnutie kĺbu, nezatriedené inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.65","desc":"Stuhnutie kĺbu, nezatriedené inde, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.66","desc":"Stuhnutie kĺbu, nezatriedené inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.67","desc":"Stuhnutie kĺbu, nezatriedené inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.68","desc":"Stuhnutie kĺbu, nezatriedené inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.69","desc":"Stuhnutie kĺbu, nezatriedené inde, miesto bližšie neurčené"},
{"code":"M25.7-","desc":"Osteofyt"},
{"code":"M25.70","desc":"Osteofyt na viacerých miestach"},
{"code":"M25.71","desc":"Osteofyt v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.72","desc":"Osteofyt nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M25.73","desc":"Osteofyt predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.74","desc":"Osteofyt ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.75","desc":"Osteofyt panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.76","desc":"Osteofyt predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.77","desc":"Osteofyt členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.78","desc":"Osteofyt na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.79","desc":"Osteofyt, miesto bližšie neurčené"},
{"code":"M25.8-","desc":"Iná choroba kĺbov, bližšie určená"},
{"code":"M25.80","desc":"Iná choroba kĺbov, bližšie určená: kĺbov na viacerých miestach"},
{"code":"M25.81","desc":"Iná choroba kĺbov, bližšie určená: kĺbov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.82","desc":"Iná choroba kĺbov, bližšie určená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M25.83","desc":"Iná choroba kĺbov, bližšie určená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.84","desc":"Iná choroba kĺbov, bližšie určená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.85","desc":"Iná choroba kĺbov, bližšie určená: oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.86","desc":"Iná choroba kĺbov, bližšie určená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.87","desc":"Iná choroba kĺbov, bližšie určená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.88","desc":"Iná choroba kĺbov, bližšie určená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.89","desc":"Iná choroba kĺbov, bližšie určená, miesto bližšie neurčené"},
{"code":"M25.9-","desc":"Choroba kĺbu, bližšie neurčená"},
{"code":"M25.90","desc":"Choroba kĺbu, bližšie neurčená, na viacerých miestach"},
{"code":"M25.91","desc":"Choroba kĺbu, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M25.92","desc":"Choroba kĺbu, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M25.93","desc":"Choroba kĺbu, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M25.94","desc":"Choroba kĺbu, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M25.95","desc":"Choroba kĺbu, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M25.96","desc":"Choroba kĺbu, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M25.97","desc":"Choroba kĺbu, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M25.98","desc":"Choroba kĺbu, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M25.99","desc":"Choroba kĺbu, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M30-M36","desc":"Systémové choroby spojiva"},
{"code":"M30.-","desc":"Polyarteriitis nodosa a príbuzné choroby"},
{"code":"M30.0","desc":"Polyarteriitis nodosa"},
{"code":"M30.1","desc":"Polyarteritída s postihnutím pľúc"},
{"code":"M30.2","desc":"Juvenilná polyarteritída"},
{"code":"M30.3","desc":"Mukokutánny uzlinový syndróm [Kawasakiho choroba]"},
{"code":"M30.8","desc":"Iné choroby príbuzné polyarteriitis nodosa"},
{"code":"M31.-","desc":"Iné nekrotizujúce vaskulopatie"},
{"code":"M31.0","desc":"Hypersenzitívna vaskulitída"},
{"code":"M31.1","desc":"Trombotická mikroangiopatia"},
{"code":"M31.2","desc":"Letálny granulóm strednej čiary"},
{"code":"M31.3","desc":"Wegenerova granulomatóza"},
{"code":"M31.4","desc":"Syndróm aortálneho oblúka [Takayasu syndrom]"},
{"code":"M31.5","desc":"Obrovskobunková arteriitída s polymyalgia rheumatica"},
{"code":"M31.6","desc":"Iná obrovskobunková arteriitída"},
{"code":"M31.7","desc":"Mikroskopická polyangiitída"},
{"code":"M31.8","desc":"Iná nekrotizujúca vaskulopatia, bližšie určená"},
{"code":"M31.9","desc":"Nekrotizujúca vaskulopatia, bližšie neurčená"},
{"code":"M32.-","desc":"Systémový lupus erythematosus"},
{"code":"M32.0","desc":"Lupus erythematosus, zapríčinený liekmi"},
{"code":"M32.1†","desc":"Systémový lupus erythematosus s postihnutím orgánov alebo systémov"},
{"code":"M32.8","desc":"Iná forma systémového lupus erythematosus"},
{"code":"M32.9","desc":"Systémový lupus erythematosus, bližšie neurčený"},
{"code":"M33.-","desc":"Dermatopolymyozitída"},
{"code":"M33.0","desc":"Juvenilná dermatomyozitída"},
{"code":"M33.1","desc":"Iná dermatomyozitída"},
{"code":"M33.2","desc":"Polymyozitída"},
{"code":"M33.9","desc":"Dermatopolymyozitída, bližšie neurčená"},
{"code":"M34.-","desc":"Systémová skleróza"},
{"code":"M34.0","desc":"Progresívna systémová skleróza"},
{"code":"M34.1","desc":"Syndróm CR(E)ST"},
{"code":"M34.2","desc":"Systémová skleróza, zapríčinená liekmi alebo chemickými látkami"},
{"code":"M34.8","desc":"Iná forma systémovej sklerózy"},
{"code":"M34.9","desc":"Systémová skleróza, bližšie neurčená"},
{"code":"M35.-","desc":"Iné systémové postihnutie spojiva"},
{"code":"M35.0","desc":"Sicca syndróm [Sjögren syndrom]"},
{"code":"M35.1","desc":"Iné prekrývajúce sa syndrómy"},
{"code":"M35.2","desc":"Behçetova choroba"},
{"code":"M35.3","desc":"Polymyalgia rheumatica"},
{"code":"M35.4","desc":"Difúzna (eozinofilná) fasciitída"},
{"code":"M35.5","desc":"Multifokálna fibroskleróza"},
{"code":"M35.6","desc":"Recidivujúca panikulitída [Pfeifer-Weber-Christian choroba]"},
{"code":"M35.7","desc":"Syndróm hypermobility"},
{"code":"M35.8","desc":"Iné systémové postihnutie spojiva, bližšie určené"},
{"code":"M35.9","desc":"Systémové postihnutie spojiva, bližšie neurčené"},
{"code":"M36.-*","desc":"Systémové choroby spojiva pri chorobách zatriedených inde"},
{"code":"M36.0*","desc":"Dermatomyozitída – polymyozitída pri nádorovej chorobe"},
{"code":"M36.1*","desc":"Artropatia pri nádorovej chorobe"},
{"code":"M36.2*","desc":"Hemofilická artropatia"},
{"code":"M36.3*","desc":"Artropatia pri iných chorobách krvi zatriedených inde"},
{"code":"M36.4*","desc":"Artropatia pri hypersenzitívnych reakciách zatriedených inde"},
{"code":"M36.8*","desc":"Systémové choroby spojiva pri iných chorobách zatriedených inde"},
{"code":"M40-M54","desc":"Choroby chrbtice a chrbta (M40 – M54)"},
{"code":"M40-M43","desc":"Deformujúce dorzopatie"},
{"code":"M40.-","desc":"Kyfóza a lordóza"},
{"code":"M40.0-","desc":"Posturálna kyfóza"},
{"code":"M40.00","desc":"Posturálna kyfóza na viacerých miestach chrbtice"},
{"code":"M40.01","desc":"Posturálna kyfóza okcipito-atlanto-axiálnej oblasti"},
{"code":"M40.02","desc":"Posturálna kyfóza krčnej oblasti"},
{"code":"M40.03","desc":"Posturálna kyfóza krčnohrudníkovej oblasti"},
{"code":"M40.04","desc":"Posturálna kyfóza hrudníkovej oblasti"},
{"code":"M40.05","desc":"Posturálna kyfóza hrudníkovodriekovej oblasti"},
{"code":"M40.06","desc":"Posturálna kyfóza driekovej oblasti"},
{"code":"M40.07","desc":"Posturálna kyfóza driekovokrížovej oblasti"},
{"code":"M40.08","desc":"Posturálna kyfóza krížovej a krížovokostrčovej oblasti"},
{"code":"M40.09","desc":"Posturálna kyfóza: oblasť bližšie neurčená"},
{"code":"M40.1-","desc":"Iná sekundárna kyfóza"},
{"code":"M40.10","desc":"Iná sekundárna kyfóza na viacerých miestach chrbtice"},
{"code":"M40.11","desc":"Iná sekundárna kyfóza okcipito-atlanto-axiálnej oblasti"},
{"code":"M40.12","desc":"Iná sekundárna kyfóza krčnej oblasti"},
{"code":"M40.13","desc":"Iná sekundárna kyfóza krčnohrudníkovej oblasti"},
{"code":"M40.14","desc":"Iná sekundárna kyfóza hrudníkovej oblasti"},
{"code":"M40.15","desc":"Iná sekundárna kyfóza hrudníkovodriekovej oblasti"},
{"code":"M40.16","desc":"Iná sekundárna kyfóza driekovej oblasti"},
{"code":"M40.17","desc":"Iná sekundárna kyfóza driekovokrížovej oblasti"},
{"code":"M40.18","desc":"Iná sekundárna kyfóza krížovej a krížovokostrčovej oblasti"},
{"code":"M40.19","desc":"Iná sekundárna kyfóza: oblasť bližšie neurčená"},
{"code":"M40.2-","desc":"Iná a bližšie neurčená kyfóza"},
{"code":"M40.20","desc":"Iná a bližšie neurčená kyfóza na viacerých miestach chrbtice"},
{"code":"M40.21","desc":"Iná a bližšie neurčená kyfóza okcipito-atlanto-axiálnej oblasti"},
{"code":"M40.22","desc":"Iná a bližšie neurčená kyfóza krčnej oblasti"},
{"code":"M40.23","desc":"Iná a bližšie neurčená kyfóza krčnohrudníkovej oblasti"},
{"code":"M40.24","desc":"Iná a bližšie neurčená kyfóza hrudníkovej oblasti"},
{"code":"M40.25","desc":"Iná a bližšie neurčená kyfóza hrudníkovodriekovej oblasti"},
{"code":"M40.26","desc":"Iná a bližšie neurčená kyfóza driekovej oblasti"},
{"code":"M40.27","desc":"Iná a bližšie neurčená kyfóza driekovokrížovej oblasti"},
{"code":"M40.28","desc":"Iná a bližšie neurčená kyfóza krížovej a krížovokostrčovej oblasti"},
{"code":"M40.29","desc":"Iná a bližšie neurčená kyfóza: oblasť bližšie neurčená"},
{"code":"M40.3-","desc":"Plochý chrbát"},
{"code":"M40.30","desc":"Plochý chrbát na viacerých miestach chrbtice"},
{"code":"M40.31","desc":"Plochý chrbát okcipito-atlanto-axiálnej oblasti"},
{"code":"M40.32","desc":"Plochý chrbát krčnej oblasti"},
{"code":"M40.33","desc":"Plochý chrbát krčnohrudníkovej oblasti"},
{"code":"M40.34","desc":"Plochý chrbát hrudníkovej oblasti"},
{"code":"M40.35","desc":"Plochý chrbát hrudníkovodriekovej oblasti"},
{"code":"M40.36","desc":"Plochý chrbát driekovej oblasti"},
{"code":"M40.37","desc":"Plochý chrbát driekovokrížovej oblasti"},
{"code":"M40.38","desc":"Plochý chrbát krížovej a krížovokostrčovej oblasti"},
{"code":"M40.39","desc":"Plochý chrbát: oblasť bližšie neurčená"},
{"code":"M40.4-","desc":"Iná lordóza"},
{"code":"M40.40","desc":"Iná lordóza na viacerých miestach chrbtice"},
{"code":"M40.41","desc":"Iná lordóza okcipito-atlanto-axiálnej oblasti"},
{"code":"M40.42","desc":"Iná lordóza krčnej oblasti"},
{"code":"M40.43","desc":"Iná lordóza krčnohrudníkovej oblasti"},
{"code":"M40.44","desc":"Iná lordóza hrudníkovej oblasti"},
{"code":"M40.45","desc":"Iná lordóza hrudníkovodriekovej oblasti"},
{"code":"M40.46","desc":"Iná lordóza driekovej oblasti"},
{"code":"M40.47","desc":"Iná lordóza driekovokrížovej oblasti"},
{"code":"M40.48","desc":"Iná lordóza krížovej a krížovokostrčovej oblasti"},
{"code":"M40.49","desc":"Iná lordóza: oblasť bližšie neurčená"},
{"code":"M40.5-","desc":"Lordóza, bližšie neurčená"},
{"code":"M40.50","desc":"Lordóza, bližšie neurčená, na viacerých miestach chrbtice"},
{"code":"M40.51","desc":"Lordóza, bližšie neurčená: okcipito-atlanto-axiálnej oblasti"},
{"code":"M40.52","desc":"Lordóza, bližšie neurčená: krčnej oblasti"},
{"code":"M40.53","desc":"Lordóza, bližšie neurčená: krčnohrudníkovej oblasti"},
{"code":"M40.54","desc":"Lordóza, bližšie neurčená: hrudníkovej oblasti"},
{"code":"M40.55","desc":"Lordóza, bližšie neurčená: hrudníkovodriekovej oblasti"},
{"code":"M40.56","desc":"Lordóza, bližšie neurčená: driekovej oblasti"},
{"code":"M40.57","desc":"Lordóza, bližšie neurčená: driekovokrížovej oblasti"},
{"code":"M40.58","desc":"Lordóza, bližšie neurčená: krížovej a krížovokostrčovej oblasti"},
{"code":"M40.59","desc":"Lordóza, bližšie neurčená: oblasť bližšie neurčená"},
{"code":"M41.-","desc":"Skolióza"},
{"code":"M41.0-","desc":"Infantilná idiopatická skolióza"},
{"code":"M41.00","desc":"Infantilná idiopatická skolióza na viacerých miestach chrbtice"},
{"code":"M41.01","desc":"Infantilná idiopatická skolióza okcipito-atlanto-axiálnej oblasti"},
{"code":"M41.02","desc":"Infantilná idiopatická skolióza krčnej oblasti"},
{"code":"M41.03","desc":"Infantilná idiopatická skolióza krčnohrudníkovej oblasti"},
{"code":"M41.04","desc":"Infantilná idiopatická skolióza hrudníkovej oblasti"},
{"code":"M41.05","desc":"Infantilná idiopatická skolióza hrudníkovodriekovej oblasti"},
{"code":"M41.06","desc":"Infantilná idiopatická skolióza driekovej oblasti"},
{"code":"M41.07","desc":"Infantilná idiopatická skolióza driekovokrížovej oblasti"},
{"code":"M41.08","desc":"Infantilná idiopatická skolióza krížovej a krížovokostrčovej oblasti"},
{"code":"M41.09","desc":"Infantilná idiopatická skolióza: oblasť bližšie neurčená"},
{"code":"M41.1-","desc":"Juvenilná idiopatická skolióza"},
{"code":"M41.10","desc":"Juvenilná idiopatická skolióza na viacerých miestach chrbtice"},
{"code":"M41.11","desc":"Juvenilná idiopatická skolióza okcipito-atlanto-axiálnej oblasti"},
{"code":"M41.12","desc":"Juvenilná idiopatická skolióza krčnej oblasti"},
{"code":"M41.13","desc":"Juvenilná idiopatická skolióza krčnohrudníkovej oblasti"},
{"code":"M41.14","desc":"Juvenilná idiopatická skolióza hrudníkovej oblasti"},
{"code":"M41.15","desc":"Juvenilná idiopatická skolióza hrudníkovodriekovej oblasti"},
{"code":"M41.16","desc":"Juvenilná idiopatická skolióza driekovej oblasti"},
{"code":"M41.17","desc":"Juvenilná idiopatická skolióza driekovokrížovej oblasti"},
{"code":"M41.18","desc":"Juvenilná idiopatická skolióza krížovej a krížovokostrčovej oblasti"},
{"code":"M41.19","desc":"Juvenilná idiopatická skolióza: oblasť bližšie neurčená"},
{"code":"M41.2-","desc":"Iná idiopatická skolióza"},
{"code":"M41.20","desc":"Iná idiopatická skolióza na viacerých miestach chrbtice"},
{"code":"M41.21","desc":"Iná idiopatická skolióza okcipito-atlanto-axiálnej oblasti"},
{"code":"M41.22","desc":"Iná idiopatická skolióza krčnej oblasti"},
{"code":"M41.23","desc":"Iná idiopatická skolióza krčnohrudníkovej oblasti"},
{"code":"M41.24","desc":"Iná idiopatická skolióza hrudníkovej oblasti"},
{"code":"M41.25","desc":"Iná idiopatická skolióza hrudníkovodriekovej oblasti"},
{"code":"M41.26","desc":"Iná idiopatická skolióza driekovej oblasti"},
{"code":"M41.27","desc":"Iná idiopatická skolióza driekovokrížovej oblasti"},
{"code":"M41.28","desc":"Iná idiopatická skolióza krížovej a krížovokostrčovej oblasti"},
{"code":"M41.29","desc":"Iná idiopatická skolióza: oblasť bližšie neurčená"},
{"code":"M41.3-","desc":"Hrudníková skolióza"},
{"code":"M41.30","desc":"Hrudníková skolióza na viacerých miestach chrbtice"},
{"code":"M41.31","desc":"Hrudníková skolióza okcipito-atlanto-axiálnej oblasti"},
{"code":"M41.32","desc":"Hrudníková skolióza krčnej oblasti"},
{"code":"M41.33","desc":"Hrudníková skolióza krčnohrudníkovej oblasti"},
{"code":"M41.34","desc":"Hrudníková skolióza hrudníkovej oblasti"},
{"code":"M41.35","desc":"Hrudníková skolióza hrudníkovodriekovej oblasti"},
{"code":"M41.36","desc":"Hrudníková skolióza driekovej oblasti"},
{"code":"M41.37","desc":"Hrudníková skolióza driekovokrížovej oblasti"},
{"code":"M41.38","desc":"Hrudníková skolióza krížovej a krížovokostrčovej oblasti"},
{"code":"M41.39","desc":"Hrudníková skolióza: oblasť bližšie neurčená"},
{"code":"M41.4-","desc":"Neuromyopatická skolióza"},
{"code":"M41.40","desc":"Neuromyopatická skolióza na viacerých miestach chrbtice"},
{"code":"M41.41","desc":"Neuromyopatická skolióza okcipito-atlanto-axiálnej oblasti"},
{"code":"M41.42","desc":"Neuromyopatická skolióza krčnej oblasti"},
{"code":"M41.43","desc":"Neuromyopatická skolióza krčnohrudníkovej oblasti"},
{"code":"M41.44","desc":"Neuromyopatická skolióza hrudníkovej oblasti"},
{"code":"M41.45","desc":"Neuromyopatická skolióza hrudníkovodriekovej oblasti"},
{"code":"M41.46","desc":"Neuromyopatická skolióza driekovej oblasti"},
{"code":"M41.47","desc":"Neuromyopatická skolióza driekovokrížovej oblasti"},
{"code":"M41.48","desc":"Neuromyopatická skolióza krížovej a krížovokostrčovej oblasti"},
{"code":"M41.49","desc":"Neuromyopatická skolióza: oblasť bližšie neurčená"},
{"code":"M41.5-","desc":"Iná sekundárna skolióza"},
{"code":"M41.50","desc":"Iná sekundárna skolióza na viacerých miestach chrbtice"},
{"code":"M41.51","desc":"Iná sekundárna skolióza okcipito-atlanto-axiálnej oblasti"},
{"code":"M41.52","desc":"Iná sekundárna skolióza krčnej oblasti"},
{"code":"M41.53","desc":"Iná sekundárna skolióza krčnohrudníkovej oblasti"},
{"code":"M41.54","desc":"Iná sekundárna skolióza hrudníkovej oblasti"},
{"code":"M41.55","desc":"Iná sekundárna skolióza hrudníkovodriekovej oblasti"},
{"code":"M41.56","desc":"Iná sekundárna skolióza driekovej oblasti"},
{"code":"M41.57","desc":"Iná sekundárna skolióza driekovokrížovej oblasti"},
{"code":"M41.58","desc":"Iná sekundárna skolióza krížovej a krížovokostrčovej oblasti"},
{"code":"M41.59","desc":"Iná sekundárna skolióza: oblasť bližšie neurčená"},
{"code":"M41.8-","desc":"Iná forma skoliózy"},
{"code":"M41.80","desc":"Iná forma skoliózy na viacerých miestach chrbtice"},
{"code":"M41.81","desc":"Iná forma skoliózy okcipito-atlanto-axiálnej oblasti"},
{"code":"M41.82","desc":"Iná forma skoliózy krčnej oblasti"},
{"code":"M41.83","desc":"Iná forma skoliózy krčnohrudníkovej oblasti"},
{"code":"M41.84","desc":"Iná forma skoliózy hrudníkovej oblasti"},
{"code":"M41.85","desc":"Iná forma skoliózy hrudníkovodriekovej oblasti"},
{"code":"M41.86","desc":"Iná forma skoliózy driekovej oblasti"},
{"code":"M41.87","desc":"Iná forma skoliózy driekovokrížovej oblasti"},
{"code":"M41.88","desc":"Iná forma skoliózy krížovej a krížovokostrčovej oblasti"},
{"code":"M41.89","desc":"Iná forma skoliózy: oblasť bližšie neurčená"},
{"code":"M41.9-","desc":"Skolióza, bližšie neurčená"},
{"code":"M41.90","desc":"Skolióza, bližšie neurčená, na viacerých miestach chrbtice"},
{"code":"M41.91","desc":"Skolióza, bližšie neurčená: okcipito-atlanto-axiálnej oblasti"},
{"code":"M41.92","desc":"Skolióza, bližšie neurčená: krčnej oblasti"},
{"code":"M41.93","desc":"Skolióza, bližšie neurčená: krčnohrudníkovej oblasti"},
{"code":"M41.94","desc":"Skolióza, bližšie neurčená: hrudníkovej oblasti"},
{"code":"M41.95","desc":"Skolióza, bližšie neurčená: hrudníkovodriekovej oblasti"},
{"code":"M41.96","desc":"Skolióza, bližšie neurčená: driekovej oblasti"},
{"code":"M41.97","desc":"Skolióza, bližšie neurčená: driekovokrížovej oblasti"},
{"code":"M41.98","desc":"Skolióza, bližšie neurčená: krížovej a krížovokostrčovej oblasti"},
{"code":"M41.99","desc":"Skolióza, bližšie neurčená: oblasť bližšie neurčená"},
{"code":"M42.-","desc":"Osteochondróza chrbtice"},
{"code":"M42.0-","desc":"Juvenilná osteochondróza chrbtice"},
{"code":"M42.00","desc":"Juvenilná osteochondróza chrbtice na viacerých miestach"},
{"code":"M42.01","desc":"Juvenilná osteochondróza chrbtice v okcipito-atlanto-axiálnej oblasti"},
{"code":"M42.02","desc":"Juvenilná osteochondróza chrbtice v krčnej oblasti"},
{"code":"M42.03","desc":"Juvenilná osteochondróza chrbtice v krčnohrudníkovej oblasti"},
{"code":"M42.04","desc":"Juvenilná osteochondróza chrbtice v hrudníkovej oblasti"},
{"code":"M42.05","desc":"Juvenilná osteochondróza chrbtice v hrudníkovodriekovej oblasti"},
{"code":"M42.06","desc":"Juvenilná osteochondróza chrbtice v driekovej oblasti"},
{"code":"M42.07","desc":"Juvenilná osteochondróza chrbtice v driekovokrížovej oblasti"},
{"code":"M42.08","desc":"Juvenilná osteochondróza chrbtice v krížovej a krížovokostrčovej oblasti"},
{"code":"M42.09","desc":"Juvenilná osteochondróza chrbtice: oblasť bližšie neurčená"},
{"code":"M42.1-","desc":"Osteochondróza chrbtice v dospelosti"},
{"code":"M42.10","desc":"Osteochondróza chrbtice v dospelosti na viacerých miestach"},
{"code":"M42.11","desc":"Osteochondróza chrbtice v dospelosti v okcipito-atlanto-axiálnej oblasti"},
{"code":"M42.12","desc":"Osteochondróza chrbtice v dospelosti v krčnej oblasti"},
{"code":"M42.13","desc":"Osteochondróza chrbtice v dospelosti v krčnohrudníkovej oblasti"},
{"code":"M42.14","desc":"Osteochondróza chrbtice v dospelosti v hrudníkovej oblasti"},
{"code":"M42.15","desc":"Osteochondróza chrbtice v dospelosti v hrudníkovodriekovej oblasti"},
{"code":"M42.16","desc":"Osteochondróza chrbtice v dospelosti v driekovej oblasti"},
{"code":"M42.17","desc":"Osteochondróza chrbtice v dospelosti v driekovokrížovej oblasti"},
{"code":"M42.18","desc":"Osteochondróza chrbtice v dospelosti v krížovej a krížovokostrčovej oblasti"},
{"code":"M42.19","desc":"Osteochondróza chrbtice v dospelosti: oblasť bližšie neurčená"},
{"code":"M42.9-","desc":"Osteochondróza chrbtice, bližšie neurčená"},
{"code":"M42.90","desc":"Osteochondróza chrbtice, bližšie neurčená, na viacerých miestach"},
{"code":"M42.91","desc":"Osteochondróza chrbtice, bližšie neurčená: v okcipito-atlanto-axiálnej oblasti"},
{"code":"M42.92","desc":"Osteochondróza chrbtice, bližšie neurčená: v krčnej oblasti"},
{"code":"M42.93","desc":"Osteochondróza chrbtice, bližšie neurčená: v krčnohrudníkovej oblasti"},
{"code":"M42.94","desc":"Osteochondróza chrbtice, bližšie neurčená: v hrudníkovej oblasti"},
{"code":"M42.95","desc":"Osteochondróza chrbtice, bližšie neurčená: v hrudníkovodriekovej oblasti"},
{"code":"M42.96","desc":"Osteochondróza chrbtice, bližšie neurčená: v driekovej oblasti"},
{"code":"M42.97","desc":"Osteochondróza chrbtice, bližšie neurčená: v driekovokrížovej oblasti"},
{"code":"M42.98","desc":"Osteochondróza chrbtice, bližšie neurčená: v krížovej a krížovokostrčovej oblasti"},
{"code":"M42.99","desc":"Osteochondróza chrbtice, bližšie neurčená: oblasť bližšie neurčená,"},
{"code":"M43.-","desc":"Iné deformujúce dorzopatie"},
{"code":"M43.0-","desc":"Spondylolýza"},
{"code":"M43.00","desc":"Spondylolýza na viacerých miestach chrbtice"},
{"code":"M43.01","desc":"Spondylolýza v okcipito-atlanto-axiálnej oblasti"},
{"code":"M43.02","desc":"Spondylolýza v krčnej oblasti"},
{"code":"M43.03","desc":"Spondylolýza v krčnohrudníkovej oblasti"},
{"code":"M43.04","desc":"Spondylolýza v hrudníkovej oblasti"},
{"code":"M43.05","desc":"Spondylolýza v hrudníkovodriekovej oblasti"},
{"code":"M43.06","desc":"Spondylolýza v driekovej oblasti"},
{"code":"M43.07","desc":"Spondylolýza v driekovokrížovej oblasti"},
{"code":"M43.08","desc":"Spondylolýza v krížovej a krížovokostrčovej oblasti"},
{"code":"M43.09","desc":"Spondylolýza: oblasť bližšie neurčená"},
{"code":"M43.1-","desc":"Spondylolistéza"},
{"code":"M43.10","desc":"Spondylolistéza na viacerých miestach chrbtice"},
{"code":"M43.11","desc":"Spondylolistéza v okcipito-atlanto-axiálnej oblasti"},
{"code":"M43.12","desc":"Spondylolistéza v krčnej oblasti"},
{"code":"M43.13","desc":"Spondylolistéza v krčnohrudníkovej oblasti"},
{"code":"M43.14","desc":"Spondylolistéza v hrudníkovej oblasti"},
{"code":"M43.15","desc":"Spondylolistéza v hrudníkovodriekovej oblasti"},
{"code":"M43.16","desc":"Spondylolistéza v driekovej oblasti"},
{"code":"M43.17","desc":"Spondylolistéza v driekovokrížovej oblasti"},
{"code":"M43.18","desc":"Spondylolistéza v krížovej a krížovokostrčovej oblasti"},
{"code":"M43.19","desc":"Spondylolistéza: oblasť bližšie neurčená"},
{"code":"M43.2-","desc":"Iná fúzia chrbtice"},
{"code":"M43.20","desc":"Iná fúzia chrbtice na viacerých miestach"},
{"code":"M43.21","desc":"Iná fúzia chrbtice v okcipito-atlanto-axiálnej oblasti"},
{"code":"M43.22","desc":"Iná fúzia chrbtice v krčnej oblasti"},
{"code":"M43.23","desc":"Iná fúzia chrbtice v krčnohrudníkovej oblasti"},
{"code":"M43.24","desc":"Iná fúzia chrbtice v hrudníkovej oblasti"},
{"code":"M43.25","desc":"Iná fúzia chrbtice v hrudníkovodriekovej oblasti"},
{"code":"M43.26","desc":"Iná fúzia chrbtice v driekovej oblasti"},
{"code":"M43.27","desc":"Iná fúzia chrbtice v driekovokrížovej oblasti"},
{"code":"M43.28","desc":"Iná fúzia chrbtice v krížovej a krížovokostrčovej oblasti"},
{"code":"M43.29","desc":"Iná fúzia chrbtice: oblasť bližšie neurčená"},
{"code":"M43.3","desc":"Habituálna atlantoaxiálna subluxácia s myelopatiou"},
{"code":"M43.4","desc":"Iná habituálna atlantoaxiálna subluxácia"},
{"code":"M43.5-","desc":"Iná habituálna subluxácia stavcov"},
{"code":"M43.50","desc":"Iná habituálna subluxácia stavcov na viacerých miestach chrbtice"},
{"code":"M43.52","desc":"Iná habituálna subluxácia stavcov krčnej oblasti"},
{"code":"M43.53","desc":"Iná habituálna subluxácia stavcov krčnohrudníkovej oblasti"},
{"code":"M43.54","desc":"Iná habituálna subluxácia stavcov hrudníkovej oblasti"},
{"code":"M43.55","desc":"Iná habituálna subluxácia stavcov hrudníkovodriekovej oblasti"},
{"code":"M43.56","desc":"Iná habituálna subluxácia stavcov driekovej oblasti"},
{"code":"M43.57","desc":"Iná habituálna subluxácia stavcov driekovokrížovej oblasti"},
{"code":"M43.58","desc":"Iná habituálna subluxácia stavcov krížovej a krížovokostrčovej oblasti"},
{"code":"M43.59","desc":"Iná habituálna subluxácia stavcov: oblasť bližšie neurčená"},
{"code":"M43.6","desc":"Torticollis"},
{"code":"M43.8-","desc":"Iné deformujúce dorzopatie, bližšie určené"},
{"code":"M43.80","desc":"Iné deformujúce dorzopatie, bližšie určené, na viacerých miestach chrbtice"},
{"code":"M43.81","desc":"Iné deformujúce dorzopatie, bližšie určené: okcipito-atlanto-axiálnej oblasti"},
{"code":"M43.82","desc":"Iné deformujúce dorzopatie, bližšie určené: krčnej oblasti"},
{"code":"M43.83","desc":"Iné deformujúce dorzopatie, bližšie určené: krčnohrudníkovej oblasti"},
{"code":"M43.84","desc":"Iné deformujúce dorzopatie, bližšie určené: hrudníkovej oblasti"},
{"code":"M43.85","desc":"Iné deformujúce dorzopatie, bližšie určené: hrudníkovodriekovej oblasti"},
{"code":"M43.86","desc":"Iné deformujúce dorzopatie, bližšie určené: driekovej oblasti"},
{"code":"M43.87","desc":"Iné deformujúce dorzopatie, bližšie určené: driekovokrížovej oblasti"},
{"code":"M43.88","desc":"Iné deformujúce dorzopatie, bližšie určené: krížovej a krížovokostrčovej oblasti"},
{"code":"M43.89","desc":"Iné deformujúce dorzopatie, bližšie určené: oblasť bližšie neurčená"},
{"code":"M43.9-","desc":"Deformujúca dorzopatia, bližšie neurčená"},
{"code":"M43.90","desc":"Deformujúca dorzopatia, bližšie neurčená, na viacerých miestach chrbtice"},
{"code":"M43.91","desc":"Deformujúca dorzopatia, bližšie neurčená: okcipito-atlanto-axiálnej oblasti"},
{"code":"M43.92","desc":"Deformujúca dorzopatia, bližšie neurčená: krčnej oblasti"},
{"code":"M43.93","desc":"Deformujúca dorzopatia, bližšie neurčená: krčnohrudníkovej oblasti"},
{"code":"M43.94","desc":"Deformujúca dorzopatia, bližšie neurčená: hrudníkovej oblasti"},
{"code":"M43.95","desc":"Deformujúca dorzopatia, bližšie neurčená: hrudníkovodriekovej oblasti"},
{"code":"M43.96","desc":"Deformujúca dorzopatia, bližšie neurčená: driekovej oblasti"},
{"code":"M43.97","desc":"Deformujúca dorzopatia, bližšie neurčená: driekovokrížovej oblasti"},
{"code":"M43.98","desc":"Deformujúca dorzopatia, bližšie neurčená: krížovej a krížovokostrčovej oblasti"},
{"code":"M43.99","desc":"Deformujúca dorzopatia, bližšie neurčená:: oblasť bližšie neurčená"},
{"code":"M45-M49","desc":"Spondylopatie"},
{"code":"M45.-","desc":"Ankylozujúca spondylitída [spondylitis ankylosans]"},
{"code":"M45.0-","desc":"Ankylozujúca spondylitída"},
{"code":"M45.00","desc":"Ankylozujúca spondylitída na viacerých miestach chrbtice"},
{"code":"M45.01","desc":"Ankylozujúca spondylitída okcipito-atlanto-axiálnej oblasti"},
{"code":"M45.02","desc":"Ankylozujúca spondylitída krčnej oblasti"},
{"code":"M45.03","desc":"Ankylozujúca spondylitída krčnohrudníkovej oblasti"},
{"code":"M45.04","desc":"Ankylozujúca spondylitída hrudníkovej oblasti"},
{"code":"M45.05","desc":"Ankylozujúca spondylitída hrudníkovodriekovej oblasti"},
{"code":"M45.06","desc":"Ankylozujúca spondylitída driekovej oblasti"},
{"code":"M45.07","desc":"Ankylozujúca spondylitída driekovokrížovej oblasti"},
{"code":"M45.08","desc":"Ankylozujúca spondylitída krížovej a krížovokostrčovej oblasti"},
{"code":"M45.09","desc":"Ankylozujúca spondylitída: oblasť bližšie neurčená"},
{"code":"M46.-","desc":"Iné zápalové spondylopatie"},
{"code":"M46.0-","desc":"Spinálna entezopatia"},
{"code":"M46.00","desc":"Spinálna entezopatia na viacerých miestach chrbtice"},
{"code":"M46.01","desc":"Spinálna entezopatia okcipito-atlanto-axiálnej oblasti"},
{"code":"M46.02","desc":"Spinálna entezopatia krčnej oblasti"},
{"code":"M46.03","desc":"Spinálna entezopatia krčnohrudníkovej oblasti"},
{"code":"M46.04","desc":"Spinálna entezopatia hrudníkovej oblasti"},
{"code":"M46.05","desc":"Spinálna entezopatia hrudníkovodriekovej oblasti"},
{"code":"M46.06","desc":"Spinálna entezopatia driekovej oblasti"},
{"code":"M46.07","desc":"Spinálna entezopatia driekovokrížovej oblasti"},
{"code":"M46.08","desc":"Spinálna entezopatia krížovej a krížovokostrčovej oblasti"},
{"code":"M46.09","desc":"Spinálna entezopatia: oblasť bližšie neurčená"},
{"code":"M46.1","desc":"Sakroilitída, nezatriedená inde"},
{"code":"M46.2-","desc":"Osteomyelitída stavca"},
{"code":"M46.20","desc":"Osteomyelitída stavca na viacerých miestach chrbtice"},
{"code":"M46.21","desc":"Osteomyelitída stavca v okcipito-atlanto-axiálnej oblasti"},
{"code":"M46.22","desc":"Osteomyelitída stavca v krčnej oblasti"},
{"code":"M46.23","desc":"Osteomyelitída stavca v krčnohrudníkovej oblasti"},
{"code":"M46.24","desc":"Osteomyelitída stavca v hrudníkovej oblasti"},
{"code":"M46.25","desc":"Osteomyelitída stavca v hrudníkovodriekovej oblasti"},
{"code":"M46.26","desc":"Osteomyelitída stavca v driekovej oblasti"},
{"code":"M46.27","desc":"Osteomyelitída stavca v driekovokrížovej oblasti"},
{"code":"M46.28","desc":"Osteomyelitída stavca v krížovej a krížovokostrčovej oblasti"},
{"code":"M46.29","desc":"Osteomyelitída stavca v: oblasť bližšie neurčená"},
{"code":"M46.3-","desc":"Infekcia medzistavcovej platničky (hnisová)"},
{"code":"M46.30","desc":"Infekcia medzistavcovej platničky (hnisová) na viacerých miestach chrbtice"},
{"code":"M46.31","desc":"Infekcia medzistavcovej platničky (hnisová) v okcipito-atlanto-axiálnej oblasti"},
{"code":"M46.32","desc":"Infekcia medzistavcovej platničky (hnisová) v krčnej oblasti"},
{"code":"M46.33","desc":"Infekcia medzistavcovej platničky (hnisová) v krčnohrudníkovej oblasti"},
{"code":"M46.34","desc":"Infekcia medzistavcovej platničky (hnisová) v hrudníkovej oblasti"},
{"code":"M46.35","desc":"Infekcia medzistavcovej platničky (hnisová) v hrudníkovodriekovej oblasti"},
{"code":"M46.36","desc":"Infekcia medzistavcovej platničky (hnisová) v driekovej oblasti"},
{"code":"M46.37","desc":"Infekcia medzistavcovej platničky (hnisová) v driekovokrížovej oblasti"},
{"code":"M46.38","desc":"Infekcia medzistavcovej platničky (hnisová) v krížovej a krížovokostrčovej oblasti"},
{"code":"M46.39","desc":"Infekcia medzistavcovej platničky (hnisová): oblasť bližšie neurčená"},
{"code":"M46.4-","desc":"Zápal medzistavcovej platničky, bližšie neurčený"},
{"code":"M46.40","desc":"Zápal medzistavcovej platničky, bližšie neurčený, na viacerých miestach chrbtice"},
{"code":"M46.41","desc":"Zápal medzistavcovej platničky, bližšie neurčený: okcipito-atlanto-axiálnej oblasti"},
{"code":"M46.42","desc":"Zápal medzistavcovej platničky, bližšie neurčený: krčnej oblasti"},
{"code":"M46.43","desc":"Zápal medzistavcovej platničky, bližšie neurčený: krčnohrudníkovej oblasti"},
{"code":"M46.44","desc":"Zápal medzistavcovej platničky, bližšie neurčený: hrudníkovej oblasti"},
{"code":"M46.45","desc":"Zápal medzistavcovej platničky, bližšie neurčený: hrudníkovodriekovej oblasti"},
{"code":"M46.46","desc":"Zápal medzistavcovej platničky, bližšie neurčený: driekovej oblasti"},
{"code":"M46.47","desc":"Zápal medzistavcovej platničky, bližšie neurčený: driekovokrížovej oblasti"},
{"code":"M46.48","desc":"Zápal medzistavcovej platničky, bližšie neurčený: krížovej a krížovokostrčovej oblasti"},
{"code":"M46.49","desc":"Zápal medzistavcovej platničky, bližšie neurčený: oblasť bližšie neurčená"},
{"code":"M46.5-","desc":"Iná infekčná spondylopatia"},
{"code":"M46.50","desc":"Iná infekčná spondylopatia na viacerých miestach chrbtice"},
{"code":"M46.51","desc":"Iná infekčná spondylopatia okcipito-atlanto-axiálnej oblasti"},
{"code":"M46.52","desc":"Iná infekčná spondylopatia krčnej oblasti"},
{"code":"M46.53","desc":"Iná infekčná spondylopatia krčnohrudníkovej oblasti"},
{"code":"M46.54","desc":"Iná infekčná spondylopatia hrudníkovej oblasti"},
{"code":"M46.55","desc":"Iná infekčná spondylopatia hrudníkovodriekovej oblasti"},
{"code":"M46.56","desc":"Iná infekčná spondylopatia driekovej oblasti"},
{"code":"M46.57","desc":"Iná infekčná spondylopatia driekovokrížovej oblasti"},
{"code":"M46.58","desc":"Iná infekčná spondylopatia krížovej a krížovokostrčovej oblasti"},
{"code":"M46.59","desc":"Iná infekčná spondylopatia: oblasť bližšie neurčená"},
{"code":"M46.8-","desc":"Iná zápalová spondylopatia, bližšie určená:"},
{"code":"M46.80","desc":"Iná zápalová spondylopatia, bližšie určená, na viacerých miestach chrbtice"},
{"code":"M46.81","desc":"Iná zápalová spondylopatia, bližšie určená: okcipito-atlanto-axiálnej oblasti"},
{"code":"M46.82","desc":"Iná zápalová spondylopatia, bližšie určená: krčnej oblasti"},
{"code":"M46.83","desc":"Iná zápalová spondylopatia, bližšie určená: krčnohrudníkovej oblasti"},
{"code":"M46.84","desc":"Iná zápalová spondylopatia, bližšie určená: hrudníkovej oblasti"},
{"code":"M46.85","desc":"Iná zápalová spondylopatia, bližšie určená: hrudníkovodriekovej oblasti"},
{"code":"M46.86","desc":"Iná zápalová spondylopatia, bližšie určená: driekovej oblasti"},
{"code":"M46.87","desc":"Iná zápalová spondylopatia, bližšie určená: driekovokrížovej oblasti"},
{"code":"M46.88","desc":"Iná zápalová spondylopatia, bližšie určená: krížovej a krížovokostrčovej oblasti"},
{"code":"M46.89","desc":"Iná zápalová spondylopatia, bližšie určená:: oblasť bližšie neurčená"},
{"code":"M46.9-","desc":"Zápalová spondylopatia, bližšie neurčená:"},
{"code":"M46.90","desc":"Zápalová spondylopatia, bližšie neurčená, na viacerých miestach chrbtice"},
{"code":"M46.91","desc":"Zápalová spondylopatia, bližšie neurčená: okcipito-atlanto-axiálnej oblasti"},
{"code":"M46.92","desc":"Zápalová spondylopatia, bližšie neurčená: krčnej oblasti"},
{"code":"M46.93","desc":"Zápalová spondylopatia, bližšie neurčená: krčnohrudníkovej oblasti"},
{"code":"M46.94","desc":"Zápalová spondylopatia, bližšie neurčená: hrudníkovej oblasti"},
{"code":"M46.95","desc":"Zápalová spondylopatia, bližšie neurčená: hrudníkovodriekovej oblasti"},
{"code":"M46.96","desc":"Zápalová spondylopatia, bližšie neurčená: driekovej oblasti"},
{"code":"M46.97","desc":"Zápalová spondylopatia, bližšie neurčená: driekovokrížovej oblasti"},
{"code":"M46.98","desc":"Zápalová spondylopatia, bližšie neurčená: krížovej a krížovokostrčovej oblasti"},
{"code":"M46.99","desc":"Zápalová spondylopatia, bližšie neurčená: oblasť bližšie neurčená"},
{"code":"M47.-","desc":"Spondylóza"},
{"code":"M47.0-†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis (G99.2*)"},
{"code":"M47.00†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis na viacerých miestach chrbtice (G99.2*)"},
{"code":"M47.01†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis v okcipito-atlanto-axiálnej oblasti (G99.2*)"},
{"code":"M47.02†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis v krčnej oblasti (G99.2*)"},
{"code":"M47.03†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis v krčnohrudníkovej oblasti (G99.2*)"},
{"code":"M47.04†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis v hrudníkovej oblasti (G99.2*)"},
{"code":"M47.05†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis v hrudníkovodriekovej oblasti (G99.2*)"},
{"code":"M47.06†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis v driekovej oblasti (G99.2*)"},
{"code":"M47.07†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis v driekovokrížovej oblasti (G99.2*)"},
{"code":"M47.08†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis v krížovej a krížovokostrčovej oblasti (G99.2*)"},
{"code":"M47.09†","desc":"Syndróm kompresie a.spinalis anterior a a.vertebralis v: oblasť bližšie neurčená (G99.2*)"},
{"code":"M47.1-","desc":"Iná spondylóza s myelopatiou"},
{"code":"M47.10","desc":"Iná spondylóza s myelopatiou na viacerých miestach chrbtice"},
{"code":"M47.11","desc":"Iná spondylóza s myelopatiou v okcipito-atlanto-axiálnej oblasti"},
{"code":"M47.12","desc":"Iná spondylóza s myelopatiou v krčnej oblasti"},
{"code":"M47.13","desc":"Iná spondylóza s myelopatiou v krčnohrudníkovej oblasti"},
{"code":"M47.14","desc":"Iná spondylóza s myelopatiou v hrudníkovej oblasti"},
{"code":"M47.15","desc":"Iná spondylóza s myelopatiou v hrudníkovodriekovej oblasti"},
{"code":"M47.16","desc":"Iná spondylóza s myelopatiou v driekovej oblasti"},
{"code":"M47.17","desc":"Iná spondylóza s myelopatiou v driekovokrížovej oblasti"},
{"code":"M47.18","desc":"Iná spondylóza s myelopatiou v krížovej a krížovokostrčovej oblasti"},
{"code":"M47.19","desc":"Iná spondylóza s myelopatiou v: oblasť bližšie neurčená"},
{"code":"M47.2-","desc":"Iná spondylóza s radikulopatiou"},
{"code":"M47.20","desc":"Iná spondylóza s radikulopatiou na viacerých miestach chrbtice"},
{"code":"M47.21","desc":"Iná spondylóza s radikulopatiou v okcipito-atlanto-axiálnej oblasti"},
{"code":"M47.22","desc":"Iná spondylóza s radikulopatiou v krčnej oblasti"},
{"code":"M47.23","desc":"Iná spondylóza s radikulopatiou v krčnohrudníkovej oblasti"},
{"code":"M47.24","desc":"Iná spondylóza s radikulopatiou v hrudníkovej oblasti"},
{"code":"M47.25","desc":"Iná spondylóza s radikulopatiou v hrudníkovodriekovej oblasti"},
{"code":"M47.26","desc":"Iná spondylóza s radikulopatiou v driekovej oblasti"},
{"code":"M47.27","desc":"Iná spondylóza s radikulopatiou v driekovokrížovej oblasti"},
{"code":"M47.28","desc":"Iná spondylóza s radikulopatiou v krížovej a krížovokostrčovej oblasti"},
{"code":"M47.29","desc":"Iná spondylóza s radikulopatiou: oblasť bližšie neurčená"},
{"code":"M47.8-","desc":"Iná spondylóza"},
{"code":"M47.80","desc":"Iná spondylóza na viacerých miestach chrbtice"},
{"code":"M47.81","desc":"Iná spondylóza okcipito-atlanto-axiálnej oblasti"},
{"code":"M47.82","desc":"Iná spondylóza krčnej oblasti"},
{"code":"M47.83","desc":"Iná spondylóza krčnohrudníkovej oblasti"},
{"code":"M47.84","desc":"Iná spondylóza hrudníkovej oblasti"},
{"code":"M47.85","desc":"Iná spondylóza hrudníkovodriekovej oblasti"},
{"code":"M47.86","desc":"Iná spondylóza driekovej oblasti"},
{"code":"M47.87","desc":"Iná spondylóza driekovokrížovej oblasti"},
{"code":"M47.88","desc":"Iná spondylóza krížovej a krížovokostrčovej oblasti"},
{"code":"M47.89","desc":"Iná spondylóza: oblasť bližšie neurčená"},
{"code":"M47.9-","desc":"Spondylóza, bližšie neurčená"},
{"code":"M47.90","desc":"Spondylóza, bližšie neurčená, na viacerých miestach chrbtice"},
{"code":"M47.91","desc":"Spondylóza, bližšie neurčená: okcipito-atlanto-axiálnej oblasti"},
{"code":"M47.92","desc":"Spondylóza, bližšie neurčená: krčnej oblasti"},
{"code":"M47.93","desc":"Spondylóza, bližšie neurčená: krčnohrudníkovej oblasti"},
{"code":"M47.94","desc":"Spondylóza, bližšie neurčená: hrudníkovej oblasti"},
{"code":"M47.95","desc":"Spondylóza, bližšie neurčená: hrudníkovodriekovej oblasti"},
{"code":"M47.96","desc":"Spondylóza, bližšie neurčená: driekovej oblasti"},
{"code":"M47.97","desc":"Spondylóza, bližšie neurčená: driekovokrížovej oblasti"},
{"code":"M47.98","desc":"Spondylóza, bližšie neurčená: krížovej a krížovokostrčovej oblasti"},
{"code":"M47.99","desc":"Spondylóza, bližšie neurčená: oblasť bližšie neurčená"},
{"code":"M48.-","desc":"Iná spondylopatia"},
{"code":"M48.0-","desc":"Stenóza spinálneho kanála"},
{"code":"M48.00","desc":"Stenóza spinálneho kanála na viacerých miestach chrbtice"},
{"code":"M48.01","desc":"Stenóza spinálneho kanála v okcipito-atlanto-axiálnej oblasti"},
{"code":"M48.02","desc":"Stenóza spinálneho kanála v krčnej oblasti"},
{"code":"M48.03","desc":"Stenóza spinálneho kanála v krčnohrudníkovej oblasti"},
{"code":"M48.04","desc":"Stenóza spinálneho kanála v hrudníkovej oblasti"},
{"code":"M48.05","desc":"Stenóza spinálneho kanála v hrudníkovodriekovej oblasti"},
{"code":"M48.06","desc":"Stenóza spinálneho kanála v driekovej oblasti"},
{"code":"M48.07","desc":"Stenóza spinálneho kanála v driekovokrížovej oblasti"},
{"code":"M48.08","desc":"Stenóza spinálneho kanála v krížovej a krížovokostrčovej oblasti"},
{"code":"M48.09","desc":"Stenóza spinálneho kanála: oblasť bližšie neurčená"},
{"code":"M48.1-","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba]"},
{"code":"M48.10","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba] na viacerých miestach chrbtice"},
{"code":"M48.11","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba] okcipito-atlanto-axiálnej oblasti"},
{"code":"M48.12","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba] krčnej oblasti"},
{"code":"M48.13","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba] krčnohrudníkovej oblasti"},
{"code":"M48.14","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba] hrudníkovej oblasti"},
{"code":"M48.15","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba] hrudníkovodriekovej oblasti"},
{"code":"M48.16","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba] driekovej oblasti"},
{"code":"M48.17","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba] driekovokrížovej oblasti"},
{"code":"M48.18","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba] krížovej a krížovokostrčovej oblasti"},
{"code":"M48.19","desc":"Difúzna idiopatická kostrová hyperostóza [Forestierova choroba]: oblasť bližšie neurčená"},
{"code":"M48.2-","desc":"Baastrupov syndróm"},
{"code":"M48.20","desc":"Baastrupov syndróm na viacerých miestach chrbtice"},
{"code":"M48.21","desc":"Baastrupov syndróm okcipito-atlanto-axiálnej oblasti"},
{"code":"M48.22","desc":"Baastrupov syndróm krčnej oblasti"},
{"code":"M48.23","desc":"Baastrupov syndróm krčnohrudníkovej oblasti"},
{"code":"M48.24","desc":"Baastrupov syndróm hrudníkovej oblasti"},
{"code":"M48.25","desc":"Baastrupov syndróm hrudníkovodriekovej oblasti"},
{"code":"M48.26","desc":"Baastrupov syndróm driekovej oblasti"},
{"code":"M48.27","desc":"Baastrupov syndróm driekovokrížovej oblasti"},
{"code":"M48.28","desc":"Baastrupov syndróm krížovej a krížovokostrčovej oblasti"},
{"code":"M48.29","desc":"Baastrupov syndróm: oblasť bližšie neurčená"},
{"code":"M48.3-","desc":"Úrazová spondylopatia"},
{"code":"M48.30","desc":"Úrazová spondylopatia na viacerých miestach chrbtice"},
{"code":"M48.31","desc":"Úrazová spondylopatia v okcipito-atlanto-axiálnej oblasti"},
{"code":"M48.32","desc":"Úrazová spondylopatia v krčnej oblasti"},
{"code":"M48.33","desc":"Úrazová spondylopatia v krčnohrudníkovej oblasti"},
{"code":"M48.34","desc":"Úrazová spondylopatia v hrudníkovej oblasti"},
{"code":"M48.35","desc":"Úrazová spondylopatia v hrudníkovodriekovej oblasti"},
{"code":"M48.36","desc":"Úrazová spondylopatia v driekovej oblasti"},
{"code":"M48.37","desc":"Úrazová spondylopatia v driekovokrížovej oblasti"},
{"code":"M48.38","desc":"Úrazová spondylopatia v krížovej a krížovokostrčovej oblasti"},
{"code":"M48.39","desc":"Úrazová spondylopatia: oblasť bližšie neurčená"},
{"code":"M48.4-","desc":"Únavová zlomenina stavca"},
{"code":"M48.40","desc":"Únavová zlomenina stavca na viacerých miestach chrbtice"},
{"code":"M48.41","desc":"Únavová zlomenina stavca v okcipito-atlanto-axiálnej oblasti"},
{"code":"M48.42","desc":"Únavová zlomenina stavca v krčnej oblasti"},
{"code":"M48.43","desc":"Únavová zlomenina stavca v krčnohrudníkovej oblasti"},
{"code":"M48.44","desc":"Únavová zlomenina stavca v hrudníkovej oblasti"},
{"code":"M48.45","desc":"Únavová zlomenina stavca v hrudníkovodriekovej oblasti"},
{"code":"M48.46","desc":"Únavová zlomenina stavca v driekovej oblasti"},
{"code":"M48.47","desc":"Únavová zlomenina stavca v driekovokrížovej oblasti"},
{"code":"M48.48","desc":"Únavová zlomenina stavca v krížovej a krížovokostrčovej oblasti"},
{"code":"M48.49","desc":"Únavová zlomenina stavca: oblasť bližšie neurčená"},
{"code":"M48.5-","desc":"Kompresia (zrútenie) tela stavca, nezatriedená inde"},
{"code":"M48.50","desc":"Kompresia (zrútenie) tela stavca na viacerých miestach chrbtice, nezatriedená inde"},
{"code":"M48.51","desc":"Kompresia (zrútenie) tela stavca v okcipito-atlanto-axiálnej oblasti, nezatriedená inde"},
{"code":"M48.52","desc":"Kompresia (zrútenie) tela stavca v krčnej oblasti, nezatriedená inde"},
{"code":"M48.53","desc":"Kompresia (zrútenie) tela stavca v krčnohrudníkovej oblasti, nezatriedená inde"},
{"code":"M48.54","desc":"Kompresia (zrútenie) tela stavca v hrudníkovej oblasti, nezatriedená inde"},
{"code":"M48.55","desc":"Kompresia (zrútenie) tela stavca v hrudníkovodriekovej oblasti, nezatriedená inde"},
{"code":"M48.56","desc":"Kompresia (zrútenie) tela stavca v driekovej oblasti, nezatriedená inde"},
{"code":"M48.57","desc":"Kompresia (zrútenie) tela stavca v driekovokrížovej oblasti, nezatriedená inde"},
{"code":"M48.58","desc":"Kompresia (zrútenie) tela stavca v krížovej a krížovokostrčovej oblasti, nezatriedená inde"},
{"code":"M48.59","desc":"Kompresia (zrútenie) tela stavca: oblasť bližšie neurčená, nezatriedená inde"},
{"code":"M48.8-","desc":"Iná spondylopatia, bližšie určená"},
{"code":"M48.80","desc":"Iná spondylopatia, bližšie určená, na viacerých miestach chrbtice"},
{"code":"M48.81","desc":"Iná spondylopatia, bližšie určená: v okcipito-atlanto-axiálnej oblasti"},
{"code":"M48.82","desc":"Iná spondylopatia, bližšie určená: v krčnej oblasti"},
{"code":"M48.83","desc":"Iná spondylopatia, bližšie určená: v krčnohrudníkovej oblasti"},
{"code":"M48.84","desc":"Iná spondylopatia, bližšie určená: v hrudníkovej oblasti"},
{"code":"M48.85","desc":"Iná spondylopatia, bližšie určená: v hrudníkovodriekovej oblasti"},
{"code":"M48.86","desc":"Iná spondylopatia, bližšie určená: v driekovej oblasti"},
{"code":"M48.87","desc":"Iná spondylopatia, bližšie určená: v driekovokrížovej oblasti"},
{"code":"M48.88","desc":"Iná spondylopatia, bližšie určená: v krížovej a krížovokostrčovej oblasti"},
{"code":"M48.89","desc":"Iná spondylopatia, bližšie určená:: oblasť bližšie neurčená"},
{"code":"M48.9-","desc":"Spondylopatia, bližšie neurčená"},
{"code":"M48.90","desc":"Spondylopatia, bližšie neurčená, na viacerých miestach chrbtice"},
{"code":"M48.91","desc":"Spondylopatia, bližšie neurčená: v okcipito-atlanto-axiálnej oblasti"},
{"code":"M48.92","desc":"Spondylopatia, bližšie neurčená: v krčnej oblasti, bližšie neurčená"},
{"code":"M48.93","desc":"Spondylopatia, bližšie neurčená: v krčnohrudníkovej oblasti"},
{"code":"M48.94","desc":"Spondylopatia, bližšie neurčená: v hrudníkovej oblasti"},
{"code":"M48.95","desc":"Spondylopatia, bližšie neurčená: v hrudníkovodriekovej oblasti"},
{"code":"M48.96","desc":"Spondylopatia, bližšie neurčená: v driekovej oblasti"},
{"code":"M48.97","desc":"Spondylopatia, bližšie neurčená: v driekovokrížovej oblasti"},
{"code":"M48.98","desc":"Spondylopatia, bližšie neurčená: v krížovej a krížovokostrčovej oblasti"},
{"code":"M48.99","desc":"Spondylopatia, bližšie neurčená: oblasť bližšie neurčená"},
{"code":"M49.-*","desc":"Spondylopatia pri chorobách zatriedených inde"},
{"code":"M49.0-*","desc":"Tuberkulóza chrbtice (A18.0†)"},
{"code":"M49.00*","desc":"Tuberkulóza chrbtice na viacerých miestach"},
{"code":"M49.01*","desc":"Tuberkulóza chrbtice v okcipito-atlanto-axiálnej oblasti (A18.0†)"},
{"code":"M49.02*","desc":"Tuberkulóza chrbtice v krčnej oblasti (A18.0†)"},
{"code":"M49.03*","desc":"Tuberkulóza chrbtice v krčnohrudníkovej oblasti (A18.0†)"},
{"code":"M49.04*","desc":"Tuberkulóza chrbtice v hrudníkovej oblasti (A18.0†)"},
{"code":"M49.05*","desc":"Tuberkulóza chrbtice v hrudníkovodriekovej oblasti (A18.0†)"},
{"code":"M49.06*","desc":"Tuberkulóza chrbtice v driekovej oblasti (A18.0†)"},
{"code":"M49.07*","desc":"Tuberkulóza chrbtice v driekovokrížovej oblasti (A18.0†)"},
{"code":"M49.08*","desc":"Tuberkulóza chrbtice v krížovej a krížovokostrčovej oblasti (A18.0†)"},
{"code":"M49.09*","desc":"Tuberkulóza v neurčenej oblasti chrbtice (A18.0†)"},
{"code":"M49.1-*","desc":"Brucelová spondylitída (A23.-†)"},
{"code":"M49.10*","desc":"Brucelová spondylitída na viacerých miestach chrbtice (A23.-†)"},
{"code":"M49.11*","desc":"Brucelová spondylitída v okcipito-atlanto-axiálnej oblasti (A23.-†)"},
{"code":"M49.12*","desc":"Brucelová spondylitída v krčnej oblasti (A23.-†)"},
{"code":"M49.13*","desc":"Brucelová spondylitída v krčnohrudníkovej oblasti (A23.-†)"},
{"code":"M49.14*","desc":"Brucelová spondylitída v hrudníkovej oblasti (A23.-†)"},
{"code":"M49.15*","desc":"Brucelová spondylitída v hrudníkovodriekovej oblasti (A23.-†)"},
{"code":"M49.16*","desc":"Brucelová spondylitída v driekovej oblasti (A23.-†)"},
{"code":"M49.17*","desc":"Brucelová spondylitída v driekovokrížovej oblasti (A23.-†)"},
{"code":"M49.18*","desc":"Brucelová spondylitída v krížovej a krížovokostrčovej oblasti (A23.-†)"},
{"code":"M49.19*","desc":"Brucelová spondylitída v: oblasť bližšie neurčená (A23.-†)"},
{"code":"M49.2-*","desc":"Enterobaktériová spondylitída (A01-A04†)"},
{"code":"M49.20*","desc":"Enterobaktériová spondylitída na viacerých miestach chrbtice (A01-A04†)"},
{"code":"M49.21*","desc":"Enterobaktériová spondylitída v okcipito-atlanto-axiálnej oblasti (A01-A04†)"},
{"code":"M49.22*","desc":"Enterobaktériová spondylitída v krčnej oblasti (A01-A04†)"},
{"code":"M49.23*","desc":"Enterobaktériová spondylitída v krčnohrudníkovej oblasti (A01-A04†)"},
{"code":"M49.24*","desc":"Enterobaktériová spondylitída v hrudníkovej oblasti (A01-A04†)"},
{"code":"M49.25*","desc":"Enterobaktériová spondylitída v hrudníkovodriekovej oblasti (A01-A04†)"},
{"code":"M49.26*","desc":"Enterobaktériová spondylitída v driekovej oblasti (A01-A04†)"},
{"code":"M49.27*","desc":"Enterobaktériová spondylitída v driekovokrížovej oblasti (A01-A04†)"},
{"code":"M49.28*","desc":"Enterobaktériová spondylitída v krížovej a krížovokostrčovej oblasti (A01-A04†)"},
{"code":"M49.29*","desc":"Enterobaktériová spondylitída v neurčenej oblasti (A01-A04†)"},
{"code":"M49.3-*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde"},
{"code":"M49.30*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde, na viacerých miestach chrbtice"},
{"code":"M49.31*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde, v okcipito-atlanto-axiálnej oblasti"},
{"code":"M49.32*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe, zatriedenej inde, v krčnej oblasti"},
{"code":"M49.33*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde, v krčnohrudníkovej oblasti"},
{"code":"M49.34*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde, v hrudníkovej oblasti"},
{"code":"M49.35*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde, v hrudníkovodriekovej oblasti"},
{"code":"M49.36*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde, v driekovej oblasti"},
{"code":"M49.37*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde, v driekovokrížovej oblasti"},
{"code":"M49.38*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde, v krížovej a krížovokostrčovej oblasti"},
{"code":"M49.39*","desc":"Spondylopatia pri inej infekčnej alebo parazitovej chorobe zatriedenej inde, oblasť bližšie neurčená"},
{"code":"M49.4-*","desc":"Neuropatická spondylopatia"},
{"code":"M49.40*","desc":"Neuropatická spondylopatia na viacerých miestach chrbtice"},
{"code":"M49.41*","desc":"Neuropatická spondylopatia v okcipito-atlanto-axiálnej oblasti"},
{"code":"M49.42*","desc":"Neuropatická spondylopatia v krčnej oblasti"},
{"code":"M49.43*","desc":"Neuropatická spondylopatia v krčnohrudníkovej oblasti"},
{"code":"M49.44*","desc":"Neuropatická spondylopatia v hrudníkovej oblasti"},
{"code":"M49.45*","desc":"Neuropatická spondylopatia v hrudníkovodriekovej oblasti"},
{"code":"M49.46*","desc":"Neuropatická spondylopatia v driekovej oblasti"},
{"code":"M49.47*","desc":"Neuropatická spondylopatia v driekovokrížovej oblasti"},
{"code":"M49.48*","desc":"Neuropatická spondylopatia v krížovej a krížovokostrčovej oblasti"},
{"code":"M49.49*","desc":"Neuropatická spondylopatia v neurčenej oblasti chrbtice"},
{"code":"M49.5-*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde"},
{"code":"M49.50*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde, na viacerých miestach chrbtice"},
{"code":"M49.51*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde, v okcipito-atlanto-axiálnej oblasti"},
{"code":"M49.52*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde, v krčnej oblasti"},
{"code":"M49.53*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde, v krčnohrudníkovej oblasti"},
{"code":"M49.54*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde, v hrudníkovej oblasti"},
{"code":"M49.55*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde, v hrudníkovodriekovej oblasti"},
{"code":"M49.56*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde, v driekovej oblasti"},
{"code":"M49.57*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde, v driekovokrížovej oblasti"},
{"code":"M49.58*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde, v krížovej a krížovokostrčovej oblasti"},
{"code":"M49.59*","desc":"Kompresia (zrútenie) tela stavca pri chorobe zatriedenej inde: oblasť bližšie neurčená"},
{"code":"M49.8-*","desc":"Spondylopatia pri inej chorobe zatriedenej inde"},
{"code":"M49.80*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, na viacerých miestach chrbtice"},
{"code":"M49.81*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, v okcipito-atlanto-axiálnej oblasti"},
{"code":"M49.82*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, v krčnej oblasti"},
{"code":"M49.83*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, v krčnohrudníkovej oblasti"},
{"code":"M49.84*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, v hrudníkovej oblasti"},
{"code":"M49.85*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, v hrudníkovodriekovej oblasti"},
{"code":"M49.86*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, v driekovej oblasti"},
{"code":"M49.87*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, v driekovokrížovej oblasti"},
{"code":"M49.88*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, v krížovej a krížovokostrčovej oblasti"},
{"code":"M49.89*","desc":"Spondylopatia pri inej chorobe zatriedenej inde, v neurčenej oblasti"},
{"code":"M50-M54","desc":"Iné dorzopatie"},
{"code":"M50.-","desc":"Poškodenie krčnej medzistavcovej platničky"},
{"code":"M50.0†","desc":"Poškodenie krčnej medzistavcovej platničky s myelopatiou (G99.2*)"},
{"code":"M50.1","desc":"Poškodenie krčnej medzistavcovej platničky s radikulopatiou"},
{"code":"M50.2","desc":"Iná dislokácia krčnej medzistavcovej platničky"},
{"code":"M50.3","desc":"Iná degenerácia krčnej medzistavcovej platničky"},
{"code":"M50.8","desc":"Iné poškodenie krčnej medzistavcovej platničky"},
{"code":"M50.9","desc":"Poškodenie krčnej medzistavcovej platničky, bližšie neurčené"},
{"code":"M51.-","desc":"Iné poškodenie medzistavcovej platničky"},
{"code":"M51.0†","desc":"Poškodenie driekových a iných medzistavcových platničiek s myelopatiou (G99.2*)"},
{"code":"M51.1†","desc":"Poškodenie driekových a iných medzistavcových platničiek s radikulopatiou (G55.1*)"},
{"code":"M51.2","desc":"Iná dislokácia medzistavcovej platničky, bližšie určená"},
{"code":"M51.3","desc":"Iná degenerácia medzistavcovej platničky, bližšie určená"},
{"code":"M51.4","desc":"Schmorlove uzly"},
{"code":"M51.8","desc":"Iné poškodenie medzistavcovej platničky, bližšie určené"},
{"code":"M51.9","desc":"Poškodenie medzistavcovej platničky, bližšie neurčené"},
{"code":"M53.-","desc":"Iné dorzopatie, nezatriedené inde"},
{"code":"M53.0","desc":"Cervikokraniálny syndróm"},
{"code":"M53.1","desc":"Cervikobrachiálny syndróm"},
{"code":"M53.2-","desc":"Instabilita chrbtice"},
{"code":"M53.20","desc":"Instabilita chrbtice na viacerých miestach"},
{"code":"M53.21","desc":"Instabilita chrbtice v okcipito-atlanto-axiálnej oblasti"},
{"code":"M53.22","desc":"Instabilita chrbtice v krčnej oblasti"},
{"code":"M53.23","desc":"Instabilita chrbtice v krčnohrudníkovej oblasti"},
{"code":"M53.24","desc":"Instabilita chrbtice v hrudníkovej oblasti"},
{"code":"M53.25","desc":"Instabilita chrbtice v hrudníkovodriekovej oblasti"},
{"code":"M53.26","desc":"Instabilita chrbtice v driekovej oblasti"},
{"code":"M53.27","desc":"Instabilita chrbtice v driekovokrížovej oblasti"},
{"code":"M53.28","desc":"Instabilita chrbtice v krížovej a krížovokostrčovej oblasti"},
{"code":"M53.29","desc":"Instabilita chrbtice: oblasť bližšie neurčená"},
{"code":"M53.3","desc":"Choroba krížovokostrčovej oblasti, nezatriedená inde"},
{"code":"M53.8-","desc":"Iné dorzopatie, bližšie určené"},
{"code":"M53.80","desc":"Iné dorzopatie, bližšie určené, na viacerých miestach chrbtice"},
{"code":"M53.81","desc":"Iné dorzopatie, bližšie určené: v okcipito-atlanto-axiálnej oblasti"},
{"code":"M53.82","desc":"Iné dorzopatie, bližšie určené: v krčnej oblasti"},
{"code":"M53.83","desc":"Iné dorzopatie, bližšie určené: v krčnohrudníkovej oblasti"},
{"code":"M53.84","desc":"Iné dorzopatie, bližšie určené: v hrudníkovej oblasti"},
{"code":"M53.85","desc":"Iné dorzopatie, bližšie určené: v hrudníkovodriekovej oblasti"},
{"code":"M53.86","desc":"Iné dorzopatie, bližšie určené: v driekovej oblasti"},
{"code":"M53.87","desc":"Iné dorzopatie, bližšie určené: v driekovokrížovej oblasti"},
{"code":"M53.88","desc":"Iné dorzopatie, bližšie určené: v krížovej a krížovokostrčovej oblasti"},
{"code":"M53.89","desc":"Iné dorzopatie, bližšie určené: oblať bližšie neurčená"},
{"code":"M53.9-","desc":"Dorzopatia, bližšie neurčená"},
{"code":"M53.90","desc":"Dorzopatia, bližšie neurčená, na viacerých miestach chrbtice"},
{"code":"M53.91","desc":"Dorzopatia, bližšie neurčená: v okcipito-atlanto-axiálnej oblasti"},
{"code":"M53.92","desc":"Dorzopatia, bližšie neurčená: v krčnej oblasti"},
{"code":"M53.93","desc":"Dorzopatia, bližšie neurčená: v krčnohrudníkovej oblasti"},
{"code":"M53.94","desc":"Dorzopatia, bližšie neurčená: v hrudníkovej oblasti"},
{"code":"M53.95","desc":"Dorzopatia, bližšie neurčená: v hrudníkovodriekovej oblasti"},
{"code":"M53.96","desc":"Dorzopatia, bližšie neurčená: v driekovej oblasti"},
{"code":"M53.97","desc":"Dorzopatia, bližšie neurčená: v driekovokrížovej oblasti"},
{"code":"M53.98","desc":"Dorzopatia, bližšie neurčená: v krížovej a krížovokostrčovej oblasti"},
{"code":"M53.99","desc":"Dorzopatia, bližšie neurčená: oblasť bližšie neurčená"},
{"code":"M54.-","desc":"Bolesť chrbta [Dorzalgia]"},
{"code":"M54.0-","desc":"Panikulitída krku a chrbta"},
{"code":"M54.00","desc":"Panikulitída krku a chrbta na viacerých miestach"},
{"code":"M54.01","desc":"Panikulitída krku a chrbta v okcipito-atlanto-axiálnej oblasti"},
{"code":"M54.02","desc":"Panikulitída krku a chrbta v krčnej oblasti"},
{"code":"M54.03","desc":"Panikulitída krku a chrbta v krčnohrudníkovej oblasti"},
{"code":"M54.04","desc":"Panikulitída krku a chrbta v hrudníkovej oblasti"},
{"code":"M54.05","desc":"Panikulitída krku a chrbta v hrudníkovodriekovej oblasti"},
{"code":"M54.06","desc":"Panikulitída krku a chrbta v driekovej oblasti"},
{"code":"M54.07","desc":"Panikulitída krku a chrbta v driekovokrížovej oblasti"},
{"code":"M54.08","desc":"Panikulitída krku a chrbta v krížovej a krížovokostrčovej oblasti"},
{"code":"M54.09","desc":"Panikulitída krku a chrbta: oblasť bližšie neurčená"},
{"code":"M54.1-","desc":"Radikulopatia"},
{"code":"M54.10","desc":"Radikulopatia na viacerých miestach chrbtice"},
{"code":"M54.11","desc":"Radikulopatia v okcipito-atlanto-axiálnej oblasti"},
{"code":"M54.12","desc":"Radikulopatia v krčnej oblasti"},
{"code":"M54.13","desc":"Radikulopatia v krčnohrudníkovej oblasti"},
{"code":"M54.14","desc":"Radikulopatia v hrudníkovej oblasti"},
{"code":"M54.15","desc":"Radikulopatia v hrudníkovodriekovej oblasti"},
{"code":"M54.16","desc":"Radikulopatia v driekovej oblasti"},
{"code":"M54.17","desc":"Radikulopatia v driekovokrížovej oblasti"},
{"code":"M54.18","desc":"Radikulopatia v krížovej a krížovokostrčovej oblasti"},
{"code":"M54.19","desc":"Radikulopatia: oblasť bližšie neurčená"},
{"code":"M54.2","desc":"Cervikalgia"},
{"code":"M54.3","desc":"Ischialgia"},
{"code":"M54.4","desc":"Lumboischialgia"},
{"code":"M54.5","desc":"Bolesť v krížovej oblasti"},
{"code":"M54.50","desc":"Bolesť v dolnej časti chrbta, mnohopočetné postihnutie chrbtice"},
{"code":"M54.6","desc":"Bolesť v oblasti hrudníkovej chrbtice"},
{"code":"M54.8-","desc":"Iná bolesť chrbta"},
{"code":"M54.80","desc":"Iná bolesť chrbta na viacerých miestach chrbtice"},
{"code":"M54.81","desc":"Iná bolesť chrbta v okcipito-atlanto-axiálnej oblasti"},
{"code":"M54.82","desc":"Iná bolesť chrbta v krčnej oblasti"},
{"code":"M54.83","desc":"Iná bolesť chrbta v krčnohrudníkovej oblasti"},
{"code":"M54.84","desc":"Iná bolesť chrbta v hrudníkovej oblasti"},
{"code":"M54.85","desc":"Iná bolesť chrbta v hrudníkovodriekovej oblasti"},
{"code":"M54.86","desc":"Iná bolesť chrbta v driekovej oblasti"},
{"code":"M54.87","desc":"Iná bolesť chrbta v driekovokrížovej oblasti"},
{"code":"M54.88","desc":"Iná bolesť chrbta v krížovej a krížovokostrčovej oblasti"},
{"code":"M54.89","desc":"Iná bolesť chrbta v: oblasť bližšie neurčená"},
{"code":"M54.9-","desc":"Bolesť chrbta, bližšie neurčená"},
{"code":"M54.90","desc":"Bolesť chrbta, bližšie neurčená na viacerých miestach chrbtice"},
{"code":"M54.91","desc":"Bolesť chrbta, bližšie neurčená v okcipito-atlanto-axiálnej oblasti"},
{"code":"M54.92","desc":"Bolesť chrbta, bližšie neurčená v krčnej oblasti"},
{"code":"M54.93","desc":"Bolesť chrbta, bližšie neurčená v krčnohrudníkovej oblasti"},
{"code":"M54.94","desc":"Bolesť chrbta, bližšie neurčená v hrudníkovej oblasti"},
{"code":"M54.95","desc":"Bolesť chrbta, bližšie neurčená v hrudníkovodriekovej oblasti"},
{"code":"M54.96","desc":"Bolesť chrbta, bližšie neurčená v driekovej oblasti"},
{"code":"M54.97","desc":"Bolesť chrbta, bližšie neurčená v driekovokrížovej oblasti"},
{"code":"M54.98","desc":"Bolesť chrbta, bližšie neurčená v krížovej a krížovokostrčovej oblasti"},
{"code":"M54.99","desc":"Bolesť chrbta, bližšie neurčená: oblasť bližšie neurčená"},
{"code":"M60-M79","desc":"(M60 – M79) Choroby mäkkého tkaniva"},
{"code":"M60-M63","desc":"(M60 - M63) Choroby svalov"},
{"code":"M60.-","desc":"Myozitída"},
{"code":"M60.0-","desc":"Infekčná myozitída"},
{"code":"M60.00","desc":"Infekčná myozitída na viacerých miestach"},
{"code":"M60.01","desc":"Infekčná myozitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M60.02","desc":"Infekčná myozitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M60.03","desc":"Infekčná myozitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M60.04","desc":"Infekčná myozitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M60.05","desc":"Infekčná myozitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M60.06","desc":"Infekčná myozitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M60.07","desc":"Infekčná myozitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M60.08","desc":"Infekčná myozitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M60.09","desc":"Infekčná myozitída, miesto bližšie neurčené"},
{"code":"M60.1-","desc":"Intersticiálna myozitída"},
{"code":"M60.10","desc":"Intersticiálna myozitída na viacerých miestach"},
{"code":"M60.11","desc":"Intersticiálna myozitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M60.12","desc":"Intersticiálna myozitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M60.13","desc":"Intersticiálna myozitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M60.14","desc":"Intersticiálna myozitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M60.15","desc":"Intersticiálna myozitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M60.16","desc":"Intersticiálna myozitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M60.17","desc":"Intersticiálna myozitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M60.18","desc":"Intersticiálna myozitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M60.19","desc":"Intersticiálna myozitída, miesto bližšie neurčené"},
{"code":"M60.2-","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde"},
{"code":"M60.20","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, na viacerých miestach"},
{"code":"M60.21","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M60.22","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M60.23","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M60.24","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M60.25","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M60.26","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M60.27","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M60.28","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M60.29","desc":"Granulóm z cudzieho telesa v mäkkom tkanive, nezatriedený inde, miesto bližšie neurčené"},
{"code":"M60.8-","desc":"Iná myozitída"},
{"code":"M60.80","desc":"Iná myozitída na viacerých miestach"},
{"code":"M60.81","desc":"Iná myozitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M60.82","desc":"Iná myozitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M60.83","desc":"Iná myozitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M60.84","desc":"Iná myozitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M60.85","desc":"Iná myozitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M60.86","desc":"Iná myozitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M60.87","desc":"Iná myozitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M60.88","desc":"Iná myozitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M60.89","desc":"Iná myozitída, miesto bližšie neurčené"},
{"code":"M60.9-","desc":"Myozitída, bližšie neurčená"},
{"code":"M60.90","desc":"Myozitída, bližšie neurčená, na viacerých miestach"},
{"code":"M60.91","desc":"Myozitída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M60.92","desc":"Myozitída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M60.93","desc":"Myozitída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M60.94","desc":"Myozitída, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M60.95","desc":"Myozitída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M60.96","desc":"Myozitída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M60.97","desc":"Myozitída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M60.98","desc":"Myozitída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M60.99","desc":"Myozitída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M61.-","desc":"Vápenatenie a kostnatenie svalov"},
{"code":"M61.0-","desc":"Myositis ossificans traumatica"},
{"code":"M61.00","desc":"Myositis ossificans traumatica na viacerých miestach"},
{"code":"M61.01","desc":"Myositis ossificans traumatica v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M61.02","desc":"Myositis ossificans traumatica nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M61.03","desc":"Myositis ossificans traumatica predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M61.04","desc":"Myositis ossificans traumatica ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M61.05","desc":"Myositis ossificans traumatica panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M61.06","desc":"Myositis ossificans traumatica predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M61.07","desc":"Myositis ossificans traumatica členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M61.08","desc":"Myositis ossificans traumatica na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M61.09","desc":"Myositis ossificans traumatica, miesto bližšie neurčené"},
{"code":"M61.1-","desc":"Myositis ossificans progressiva"},
{"code":"M61.10","desc":"Myositis ossificans progressiva na viacerých miestach"},
{"code":"M61.11","desc":"Myositis ossificans progressiva v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M61.12","desc":"Myositis ossificans progressiva nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M61.13","desc":"Myositis ossificans progressiva predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M61.14","desc":"Myositis ossificans progressiva ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M61.15","desc":"Myositis ossificans progressiva panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M61.16","desc":"Myositis ossificans progressiva predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M61.17","desc":"Myositis ossificans progressiva členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M61.18","desc":"Myositis ossificans progressiva na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M61.19","desc":"Myositis ossificans progressiva, miesto bližšie neurčené"},
{"code":"M61.2-","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí"},
{"code":"M61.20","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí na viacerých miestach"},
{"code":"M61.21","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M61.22","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M61.23","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M61.24","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M61.25","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M61.26","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M61.27","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M61.28","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M61.29","desc":"Vápenatenie a kostnatenie svalu pri ochrnutí, miesto bližšie neurčené"},
{"code":"M61.3-","desc":"Vápenatenie a kostnatenie svalu pri popáleninách"},
{"code":"M61.30","desc":"Vápenatenie a kostnatenie svalov na viacerých miestach pri popáleninách"},
{"code":"M61.31","desc":"Vápenatenie a kostnatenie svalov pri popáleninách v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M61.32","desc":"Vápenatenie a kostnatenie svalov pri popáleninách nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M61.33","desc":"Vápenatenie a kostnatenie svalov pri popáleninách predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M61.34","desc":"Vápenatenie a kostnatenie svalov pri popáleninách ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M61.35","desc":"Vápenatenie a kostnatenie svalov pri popáleninách oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M61.36","desc":"Vápenatenie a kostnatenie svalov pri popáleninách predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M61.37","desc":"Vápenatenie a kostnatenie svalov pri popáleninách členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M61.38","desc":"Vápenatenie a kostnatenie svalov pri popáleninách na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M61.39","desc":"Vápenatenie a kostnatenie svalov pri popáleninách, miesto bližšie neurčené"},
{"code":"M61.4-","desc":"Iné vápenatenie svalu"},
{"code":"M61.40","desc":"Iné vápenatenie svalu na viacerých miestach"},
{"code":"M61.41","desc":"Iné vápenatenie svalu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M61.42","desc":"Iné vápenatenie svalu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M61.43","desc":"Iné vápenatenie svalu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M61.44","desc":"Iné vápenatenie svalu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M61.45","desc":"Iné vápenatenie svalu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M61.46","desc":"Iné vápenatenie svalu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M61.47","desc":"Iné vápenatenie svalu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M61.48","desc":"Iná vápenatenie svalu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M61.49","desc":"Iné vápenatenie svalu, miesto bližšie neurčené"},
{"code":"M61.5-","desc":"Iné kostnatenie svalu"},
{"code":"M61.50","desc":"Iné kostnatenie svalu na viacerých miestach"},
{"code":"M61.51","desc":"Iné kostnatenie svalu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M61.52","desc":"Iné kostnatenie svalu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M61.53","desc":"Iné kostnatenie svalu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M61.54","desc":"Iné kostnatenie svalu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M61.55","desc":"Iné kostnatenie svalu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M61.56","desc":"Iné kostnatenie svalu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M61.57","desc":"Iné kostnatenie svalu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M61.58","desc":"Iné kostnatenie svalu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M61.59","desc":"Iné kostnatenie svalu, miesto bližšie neurčené"},
{"code":"M61.9-","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené"},
{"code":"M61.90","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, na viacerých miestach"},
{"code":"M61.91","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M61.92","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M61.93","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M61.94","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M61.95","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M61.96","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M61.97","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M61.98","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M61.99","desc":"Vápenatenie a kostnatenie svalu, bližšie neurčené, miesto bližšie neurčené"},
{"code":"M62.-","desc":"Iné choroby svalov"},
{"code":"M62.0-","desc":"Diastáza svalu"},
{"code":"M62.00","desc":"Diastáza svalu na viacerých miestach"},
{"code":"M62.01","desc":"Diastáza svalu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M62.02","desc":"Diastáza svalu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M62.03","desc":"Diastáza svalu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M62.04","desc":"Diastáza svalu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M62.05","desc":"Diastáza svalu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M62.06","desc":"Diastáza svalu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M62.07","desc":"Diastáza svalu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M62.08","desc":"Diastáza svalu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M62.09","desc":"Diastáza svalu, miesto bližšie neurčené"},
{"code":"M62.1-","desc":"Iné roztrhnutie svalu (neúrazové)"},
{"code":"M62.10","desc":"Iné roztrhnutie svalu (neúrazové) na viacerých miestach"},
{"code":"M62.11","desc":"Iné roztrhnutie svalu (neúrazové) v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M62.12","desc":"Iné roztrhnutie svalu (neúrazové) v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M62.13","desc":"Iné roztrhnutie svalu (neúrazové) v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M62.14","desc":"Iné roztrhnutie svalu (neúrazové) v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M62.15","desc":"Iné roztrhnutie svalu (neúrazové) v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M62.16","desc":"Iné roztrhnutie svalu (neúrazové) v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M62.17","desc":"Iné roztrhnutie svalu (neúrazové) v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M62.18","desc":"Iné roztrhnutie svalu (neúrazové) na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M62.19","desc":"Iné roztrhnutie svalu (neúrazové), miesto bližšie neurčené"},
{"code":"M62.2-","desc":"Ischemický infarkt svalu (neúrazový)"},
{"code":"M62.20","desc":"Ischemický infarkt svalu (neúrazový) na viacerých miestach"},
{"code":"M62.21","desc":"Ischemický infarkt svalu (neúrazový) v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M62.22","desc":"Ischemický infarkt svalu (neúrazový) nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M62.23","desc":"Ischemický infarkt svalu (neúrazový) predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M62.24","desc":"Ischemický infarkt svalu (neúrazový) ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M62.25","desc":"Ischemický infarkt svalu (neúrazový) panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M62.26","desc":"Ischemický infarkt svalu (neúrazový) predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M62.27","desc":"Ischemický infarkt svalu (neúrazový) členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M62.28","desc":"Ischemický infarkt svalu (neúrazový) na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M62.29","desc":"Ischemický infarkt svalu (neúrazový), miesto bližšie neurčené"},
{"code":"M62.3-","desc":"Syndróm nehybnosti (paraplegický)"},
{"code":"M62.30","desc":"Syndróm nehybnosti (paraplegický) na viacerých miestach"},
{"code":"M62.31","desc":"Syndróm nehybnosti (paraplegický) v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M62.32","desc":"Syndróm nehybnosti (paraplegický) v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M62.33","desc":"Syndróm nehybnosti (paraplegický) v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M62.34","desc":"Syndróm nehybnosti (paraplegický) v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M62.35","desc":"Syndróm nehybnosti (paraplegický) v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M62.36","desc":"Syndróm nehybnosti (paraplegický) v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M62.37","desc":"Syndróm nehybnosti (paraplegický) v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M62.38","desc":"Syndróm nehybnosti (paraplegický) na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M62.39","desc":"Syndróm nehybnosti (paraplegický), miesto bližšie neurčené"},
{"code":"M62.4-","desc":"Kontraktúra svalu"},
{"code":"M62.40","desc":"Kontraktúra svalu na viacerých miestach"},
{"code":"M62.41","desc":"Kontraktúra svalu v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M62.42","desc":"Kontraktúra svalu nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M62.43","desc":"Kontraktúra svalu predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M62.44","desc":"Kontraktúra svalu ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M62.45","desc":"Kontraktúra svalu panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M62.46","desc":"Kontraktúra svalu predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M62.47","desc":"Kontraktúra svalu členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M62.48","desc":"Kontraktúra svalu na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M62.49","desc":"Kontraktúra svalu, miesto bližšie neurčené"},
{"code":"M62.5-","desc":"Zmenšenie a atrofia svalu, nezatriedená inde"},
{"code":"M62.50","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, na viacerých miestach"},
{"code":"M62.51","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M62.52","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M62.53","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M62.54","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M62.55","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M62.56","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M62.57","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M62.58","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M62.59","desc":"Zmenšenie a atrofia svalu, nezatriedená inde, miesto bližšie neurčené"},
{"code":"M62.6-","desc":"Poškodenie svalu z preťaženia"},
{"code":"M62.60","desc":"Poškodenie svalu z preťaženia na viacerých miestach"},
{"code":"M62.61","desc":"Poškodenie svalu z preťaženia v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M62.62","desc":"Poškodenie svalu z preťaženia v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M62.63","desc":"Poškodenie svalu z preťaženia v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M62.64","desc":"Poškodenie svalu z preťaženia v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M62.65","desc":"Poškodenie svalu z preťaženia v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M62.66","desc":"Poškodenie svalu z preťaženia v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M62.67","desc":"Poškodenie svalu z preťaženia v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M62.68","desc":"Poškodenie svalu z preťaženia na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M62.69","desc":"Poškodenie svalu z preťaženia, miesto bližšie neurčené"},
{"code":"M62.8-","desc":"Iné choroby svalov, bližšie určené"},
{"code":"M62.80","desc":"Iné choroby svalov, bližšie určené, na viacerých miestach"},
{"code":"M62.81","desc":"Iné choroby svalov, bližšie určené, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M62.82","desc":"Iné choroby svalov, bližšie určené, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M62.83","desc":"Iné choroby svalov, bližšie určené, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M62.84","desc":"Iné choroby svalov, bližšie určené, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M62.85","desc":"Iné choroby svalov, bližšie určené, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M62.86","desc":"Iné choroby svalov, bližšie určené, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M62.87","desc":"Iné choroby svalov, bližšie určené, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M62.88","desc":"Iné choroby svalov, bližšie určené, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M62.89","desc":"Iné choroby svalov, bližšie určené, miesto bližšie neurčené"},
{"code":"M62.9-","desc":"Choroba svalov, bližšie neurčená:"},
{"code":"M62.90","desc":"Choroba svalov, bližšie neurčená, na viacerých miestach"},
{"code":"M62.91","desc":"Choroba svalov, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M62.92","desc":"Choroba svalov, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M62.93","desc":"Choroba svalov, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M62.94","desc":"Choroba svalov, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M62.95","desc":"Choroba svalov, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M62.96","desc":"Choroba svalov, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M62.97","desc":"Choroba svalov, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M62.98","desc":"Choroba svalov, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M62.99","desc":"Choroba svalov, bližšie neurčená:, miesto bližšie neurčené"},
{"code":"M63.-*","desc":"Choroby svalov pri chorobách zatriedených inde"},
{"code":"M63.0-*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde"},
{"code":"M63.00*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, na viacerých miestach"},
{"code":"M63.01*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M63.02*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M63.03*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M63.04*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M63.05*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M63.06*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M63.07*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M63.08*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M63.09*","desc":"Myozitída pri baktériovej chorobe zatriedenej inde, miesto bližšie neurčené"},
{"code":"M63.1-*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde"},
{"code":"M63.10*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, na viacerých miestach"},
{"code":"M63.11*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M63.12*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M63.13*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M63.14*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M63.15*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, v panvovej oblasti a v oblasti stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M63.16*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M63.17*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M63.18*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M63.19*","desc":"Myozitída pri prvokovej a parazitovej infekcii zatriedenej inde, miesto bližšie neurčené"},
{"code":"M63.2-*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde"},
{"code":"M63.20*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, na viacerých miestach"},
{"code":"M63.21*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M63.22*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M63.23*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M63.24*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M63.25*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, v panvovej oblasti a v oblasti stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M63.26*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M63.27*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M63.28*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M63.29*","desc":"Myozitída pri inej infekčnej chorobe zatriedenej inde, miesto bližšie neurčené"},
{"code":"M63.3-*","desc":"Myozitída pri sarkoidóze (D86.8†)"},
{"code":"M63.30*","desc":"Myozitída pri sarkoidóze na viacerých miestach (D86.8†)"},
{"code":"M63.31*","desc":"Myozitída pri sarkoidóze, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (D86.8†)"},
{"code":"M63.32*","desc":"Myozitída pri sarkoidóze, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (D86.8†)"},
{"code":"M63.33*","desc":"Myozitída pri sarkoidóze, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (D86.8†)"},
{"code":"M63.34*","desc":"Myozitída pri sarkoidóze, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (D86.8†)"},
{"code":"M63.35*","desc":"Myozitída pri sarkoidóze, v panvovej oblasti a v oblasti stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (D86.8†)"},
{"code":"M63.36*","desc":"Myozitída pri sarkoidóze, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (D86.8†)"},
{"code":"M63.37*","desc":"Myozitída pri sarkoidóze, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (D86.8†)"},
{"code":"M63.38*","desc":"Myozitída pri sarkoidóze, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (D86.8†)"},
{"code":"M63.39*","desc":"Myozitída pri sarkoidóze, miesto bližšie neurčené (D86.8†)"},
{"code":"M63.8-*","desc":"Iné choroby svalov pri chorobách zatriedených inde"},
{"code":"M63.80*","desc":"Iné choroby svalov pri chorobách zatriedených inde, na viacerých miestach"},
{"code":"M63.81*","desc":"Iné choroby svalov pri chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M63.82*","desc":"Iné choroby svalov pri chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M63.83*","desc":"Iné choroby svalov pri chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M63.84*","desc":"Iné choroby svalov pri chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M63.85*","desc":"Iné choroby svalov pri chorobách zatriedených inde: v panvovej oblasti a v oblasti stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M63.86*","desc":"Iné choroby svalov pri chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M63.87*","desc":"Iné choroby svalov pri chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M63.88*","desc":"Iné choroby svalov pri chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M63.89*","desc":"Iné choroby svalov pri chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M65-M68","desc":"Choroby synoviálnej blany a šľachy"},
{"code":"M65.-","desc":"Zápal synoviálnej blany a šľachovej pošvy [synovitída a tendosynovitída]"},
{"code":"M65.0-","desc":"Absces šľachovej pošvy"},
{"code":"M65.00","desc":"Absces šľachovej pošvy na viacerých miestach"},
{"code":"M65.01","desc":"Absces šľachovej pošvy v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M65.02","desc":"Absces šľachovej pošvy nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M65.03","desc":"Absces šľachovej pošvy predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M65.04","desc":"Absces šľachovej pošvy ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M65.05","desc":"Absces šľachovej pošvy panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M65.06","desc":"Absces šľachovej pošvy predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M65.07","desc":"Absces šľachovej pošvy členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M65.08","desc":"Absces šľachovej pošvy na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M65.09","desc":"Absces šľachovej pošvy, miesto bližšie neurčené"},
{"code":"M65.1-","desc":"Iná infekčná (tendo)synovitída"},
{"code":"M65.10","desc":"Iná infekčná (tendo)synovitída na viacerých miestach"},
{"code":"M65.11","desc":"Iná infekčná (tendo)synovitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M65.12","desc":"Iná infekčná (tendo)synovitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M65.13","desc":"Iná infekčná (tendo)synovitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M65.14","desc":"Iná infekčná (tendo)synovitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M65.15","desc":"Iná infekčná (tendo)synovitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M65.16","desc":"Iná infekčná (tendo)synovitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M65.17","desc":"Iná infekčná (tendo)synovitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M65.18","desc":"Iná infekčná (tendo)synovitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M65.19","desc":"Iná infekčná (tendo)synovitída, miesto bližšie neurčené"},
{"code":"M65.2-","desc":"Vápenatejúca tendinitída"},
{"code":"M65.20","desc":"Vápenatejúca tendinitída na viacerých miestach"},
{"code":"M65.22","desc":"Vápenatejúca tendinitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M65.23","desc":"Vápenatejúca tendinitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M65.24","desc":"Vápenatejúca tendinitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M65.25","desc":"Vápenatejúca tendinitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M65.26","desc":"Vápenatejúca tendinitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M65.27","desc":"Vápenatejúca tendinitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) )"},
{"code":"M65.28","desc":"Vápenatejúca tendinitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M65.29","desc":"Vápenatejúca tendinitída, miesto bližšie neurčené"},
{"code":"M65.3","desc":"Skákavý prst [digitus recellens]"},
{"code":"M65.4","desc":"Styloradiálna tendosynovitída [de Quervain]"},
{"code":"M65.8-","desc":"Iná synovitída a tendosynovitída"},
{"code":"M65.80","desc":"Iná synovitída a tendosynovitída na viacerých miestach"},
{"code":"M65.81","desc":"Iná synovitída a tendosynovitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M65.82","desc":"Iná synovitída a tendosynovitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M65.83","desc":"Iná synovitída a tendosynovitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M65.84","desc":"Iná synovitída a tendosynovitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M65.85","desc":"Iná synovitída a tendosynovitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M65.86","desc":"Iná synovitída a tendosynovitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M65.87","desc":"Iná synovitída a tendosynovitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M65.88","desc":"Iná synovitída a tendosynovitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M65.89","desc":"Iná synovitída a tendosynovitída, miesto bližšie neurčené"},
{"code":"M65.9-","desc":"Synovitída a tendosynovitída, bližšie neurčená"},
{"code":"M65.90","desc":"Synovitída a tendosynovitída, bližšie neurčená, na viacerých miestach"},
{"code":"M65.91","desc":"Synovitída a tendosynovitída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M65.92","desc":"Synovitída a tendosynovitída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M65.93","desc":"Synovitída a tendosynovitída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M65.94","desc":"Synovitída a tendosynovitída, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M65.95","desc":"Synovitída a tendosynovitída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M65.96","desc":"Synovitída a tendosynovitída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M65.97","desc":"Synovitída a tendosynovitída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M65.98","desc":"Synovitída a tendosynovitída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M65.99","desc":"Synovitída a tendosynovitída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M66.-","desc":"Spontánne roztrhnutie synoviálnej blany a šľachy"},
{"code":"M66.0","desc":"Puknutie popliteálnej cysty"},
{"code":"M66.1-","desc":"Roztrhnutie synoviálnej blany"},
{"code":"M66.10","desc":"Roztrhnutie synoviálnej blany na viacerých miestach"},
{"code":"M66.11","desc":"Roztrhnutie synoviálnej blany v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M66.12","desc":"Roztrhnutie synoviálnej blany nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M66.13","desc":"Roztrhnutie synoviálnej blany predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M66.14","desc":"Roztrhnutie synoviálnej blany ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M66.15","desc":"Roztrhnutie synoviálnej blany panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M66.16","desc":"Roztrhnutie synoviálnej blany predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M66.17","desc":"Roztrhnutie synoviálnej blany členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M66.18","desc":"Roztrhnutie synoviálnej blany na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M66.19","desc":"Roztrhnutie synoviálnej blany, miesto bližšie neurčené"},
{"code":"M66.2-","desc":"Spontánne roztrhnutie šliach extenzorov"},
{"code":"M66.20","desc":"Spontánne roztrhnutie šliach extenzorov na viacerých miestach"},
{"code":"M66.21","desc":"Spontánne roztrhnutie šliach extenzorov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M66.22","desc":"Spontánne roztrhnutie šliach extenzorov nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M66.23","desc":"Spontánne roztrhnutie šliach extenzorov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M66.24","desc":"Spontánne roztrhnutie šliach extenzorov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M66.25","desc":"Spontánne roztrhnutie šliach extenzorov oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M66.26","desc":"Spontánne roztrhnutie šliach extenzorov predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M66.27","desc":"Spontánne roztrhnutie šliach extenzorov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M66.28","desc":"Spontánne roztrhnutie šliach extenzorov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M66.29","desc":"Spontánne roztrhnutie šliach extenzorov, miesto bližšie neurčené"},
{"code":"M66.3-","desc":"Spontánne roztrhnutie šliach flexorov"},
{"code":"M66.30","desc":"Spontánne roztrhnutie šliach flexorov na viacerých miestach"},
{"code":"M66.31","desc":"Spontánne roztrhnutie šliach flexorov v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M66.32","desc":"Spontánne roztrhnutie šliach flexorov nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M66.33","desc":"Spontánne roztrhnutie šliach flexorov predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M66.34","desc":"Spontánne roztrhnutie šliach flexorov ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M66.35","desc":"Spontánne roztrhnutie šliach flexorov v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M66.36","desc":"Spontánne roztrhnutie šliach flexorov predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M66.37","desc":"Spontánne roztrhnutie šliach flexorov členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M66.38","desc":"Spontánne roztrhnutie šliach flexorov na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M66.39","desc":"Spontánne roztrhnutie šliach flexorov, miesto bližšie neurčené"},
{"code":"M66.4-","desc":"Spontánne roztrhnutie iných šliach"},
{"code":"M66.40","desc":"Spontánne roztrhnutie iných šliach na viacerých miestach"},
{"code":"M66.41","desc":"Spontánne roztrhnutie iných šliach v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M66.42","desc":"Spontánne roztrhnutie iných šliach nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M66.43","desc":"Spontánne roztrhnutie iných šliach predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M66.44","desc":"Spontánne roztrhnutie iných šliach ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M66.45","desc":"Spontánne roztrhnutie iných šliach panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M66.46","desc":"Spontánne roztrhnutie iných šliach predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M66.47","desc":"Spontánne roztrhnutie iných šliach členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M66.48","desc":"Spontánne roztrhnutie iných šliach na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M66.49","desc":"Spontánne roztrhnutie iných šliach, miesto bližšie neurčené"},
{"code":"M66.5-","desc":"Spontánne roztrhnutie šliach, bližšie neurčených"},
{"code":"M66.50","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, na viacerých miestach"},
{"code":"M66.51","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M66.52","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M66.53","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M66.54","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M66.55","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M66.56","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M66.57","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M66.58","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M66.59","desc":"Spontánne roztrhnutie šliach, bližšie neurčených, miesto bližšie neurčené"},
{"code":"M67.-","desc":"Iné choroby synoviálnej blany a šľachy"},
{"code":"M67.0","desc":"Krátka Achillova šľacha (získaná)"},
{"code":"M67.1-","desc":"Iná kontraktúra šľachy (šľachovej pošvy)"},
{"code":"M67.10","desc":"Iná kontraktúra šľachy (šľachovej pošvy) na viacerých miestach"},
{"code":"M67.11","desc":"Iná kontraktúra šľachy (šľachovej pošvy) v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M67.12","desc":"Iná kontraktúra šľachy (šľachovej pošvy) nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M67.13","desc":"Iná kontraktúra šľachy (šľachovej pošvy) predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M67.14","desc":"Iná kontraktúra šľachy (šľachovej pošvy) ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M67.15","desc":"Iná kontraktúra šľachy (šľachovej pošvy) panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M67.16","desc":"Iná kontraktúra šľachy (šľachovej pošvy) predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M67.17","desc":"Iná kontraktúra šľachy (šľachovej pošvy) členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M67.18","desc":"Iná kontraktúra šľachy (šľachovej pošvy) na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M67.19","desc":"Iná kontraktúra šľachy (šľachovej pošvy), miesto bližšie neurčené"},
{"code":"M67.2-","desc":"Hypertrofia synoviálnej blany, nezatriedená inde"},
{"code":"M67.20","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, na viacerých miestach"},
{"code":"M67.21","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M67.22","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M67.23","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M67.24","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M67.25","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M67.26","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M67.27","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M67.28","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M67.29","desc":"Hypertrofia synoviálnej blany, nezatriedená inde, miesto bližšie neurčené"},
{"code":"M67.3-","desc":"Prechodná synovitída"},
{"code":"M67.30","desc":"Prechodná synovitída na viacerých miestach"},
{"code":"M67.31","desc":"Prechodná synovitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M67.32","desc":"Prechodná synovitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M67.33","desc":"Prechodná synovitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M67.34","desc":"Prechodná synovitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M67.35","desc":"Prechodná synovitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M67.36","desc":"Prechodná synovitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M67.37","desc":"Prechodná synovitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M67.38","desc":"Prechodná synovitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M67.39","desc":"Prechodná synovitída, miesto bližšie neurčené"},
{"code":"M67.4-","desc":"Ganglion"},
{"code":"M67.40","desc":"Ganglion na viacerých miestach"},
{"code":"M67.41","desc":"Ganglion v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M67.42","desc":"Ganglion nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M67.43","desc":"Ganglion predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M67.44","desc":"Ganglion ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M67.45","desc":"Ganglion panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M67.46","desc":"Ganglion predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M67.47","desc":"Ganglion členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M67.48","desc":"Ganglion na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M67.49","desc":"Ganglion, miesto bližšie neurčené"},
{"code":"M67.8-","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené"},
{"code":"M67.80","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, na viacerých miestach"},
{"code":"M67.81","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M67.82","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M67.83","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M67.84","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M67.85","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M67.86","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M67.87","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M67.88","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M67.89","desc":"Iné choroby synoviálnej blany a šliach, bližšie určené, miesto bližšie neurčené"},
{"code":"M67.9-","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená"},
{"code":"M67.90","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená, na viacerých miestach"},
{"code":"M67.91","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M67.92","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M67.93","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M67.94","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M67.95","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M67.96","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M67.97","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M67.98","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M67.99","desc":"Choroba synoviálnej blany a šliach, bližšie neurčená:, miesto bližšie neurčené"},
{"code":"M68.-*","desc":"Choroby synoviálnej blany a šliach pri chorobách zatriedených inde"},
{"code":"M68.0-*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde"},
{"code":"M68.00*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde, na viacerých miestach"},
{"code":"M68.01*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M68.02*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M68.03*","desc":"Synovitída a tendosynovitída pri baktériových chorobách, zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M68.04*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M68.05*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M68.06*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M68.07*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M68.08*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M68.09*","desc":"Synovitída a tendosynovitída pri baktériových chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M68.8-*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde"},
{"code":"M68.80*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, na viacerých miestach"},
{"code":"M68.81*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M68.82*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M68.83*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M68.84*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M68.85*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M68.86*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M68.87*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M68.88*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, v inej oblasti (lebka, hlava, krk, rebrá, trup, chrbtica)"},
{"code":"M68.89*","desc":"Iná choroba synoviálnej blany a šliach pri chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M70-M79","desc":"Iné choroby mäkkého tkaniva"},
{"code":"M70.-","desc":"Choroba mäkkého tkaniva v súvislosti so zaťažovaním, preťažovaním a tlakom"},
{"code":"M70.0","desc":"Chronická krepitujúca synovitída ruky a zápästia"},
{"code":"M70.1","desc":"Burzitída ruky"},
{"code":"M70.2","desc":"Burzitída olekranu"},
{"code":"M70.3","desc":"Iná burzitída lakťa"},
{"code":"M70.4","desc":"Prepatelárna burzitída"},
{"code":"M70.5","desc":"Iná burzitída kolena"},
{"code":"M70.6","desc":"Trochanterická burzitída"},
{"code":"M70.7","desc":"Iná burzitída bedra"},
{"code":"M70.8","desc":"Iná choroba mäkkého tkaniva súvisiaca so zaťažovaním, preťažovaním a tlakom"},
{"code":"M70.9","desc":"Choroba mäkkého tkaniva súvisiaca so zaťažovaním, preťažovaním a tlakom, bližšie neurčená"},
{"code":"M71.-","desc":"Iná burzopatia"},
{"code":"M71.0-","desc":"Absces burzy"},
{"code":"M71.00","desc":"Absces burzy na viacerých miestach"},
{"code":"M71.01","desc":"Absces burzy v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M71.02","desc":"Absces burzy nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M71.03","desc":"Absces burzy predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M71.04","desc":"Absces burzy ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M71.05","desc":"Absces burzy panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M71.06","desc":"Absces burzy predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M71.07","desc":"Absces burzy členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M71.08","desc":"Absces burzy na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M71.09","desc":"Absces burzy, miesto bližšie neurčené"},
{"code":"M71.1-","desc":"Iná infekčná burzitída"},
{"code":"M71.10","desc":"Iná infekčná burzitída na viacerých miestach"},
{"code":"M71.11","desc":"Iná infekčná burzitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M71.12","desc":"Iná infekčná burzitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M71.13","desc":"Iná infekčná burzitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M71.14","desc":"Iná infekčná burzitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M71.15","desc":"Iná infekčná burzitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M71.16","desc":"Iná infekčná burzitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M71.17","desc":"Iná infekčná burzitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M71.18","desc":"Iná infekčná burzitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M71.19","desc":"Iná infekčná burzitída, miesto bližšie neurčené"},
{"code":"M71.2","desc":"Synoviálna cysta v popliteálnej oblasti [Bakerova cysta]"},
{"code":"M71.3-","desc":"Iná cysta burzy"},
{"code":"M71.30","desc":"Iná cysta burzy na viacerých miestach"},
{"code":"M71.31","desc":"Iná cysta burzy v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M71.32","desc":"Iná cysta burzy nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M71.33","desc":"Iná cysta burzy predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M71.34","desc":"Iná cysta burzy ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M71.35","desc":"Iná cysta burzy panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M71.36","desc":"Iná cysta burzy predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M71.37","desc":"Iná cysta burzy členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M71.38","desc":"Iná cysta burzy na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M71.39","desc":"Iná cysta burzy, miesto bližšie neurčené"},
{"code":"M71.4-","desc":"Bursitis calcarea"},
{"code":"M71.40","desc":"Bursitis calcarea na viacerých miestach"},
{"code":"M71.42","desc":"Bursitis calcarea nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M71.43","desc":"Bursitis calcarea predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M71.44","desc":"Bursitis calcarea ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M71.45","desc":"Bursitis calcarea panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M71.46","desc":"Bursitis calcarea predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M71.47","desc":"Bursitis calcarea členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M71.48","desc":"Bursitis calcarea na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M71.49","desc":"Bursitis calcarea, miesto bližšie neurčené"},
{"code":"M71.5-","desc":"Iná burzitída, nezatriedená inde"},
{"code":"M71.50","desc":"Iná burzitída, nezatriedená inde, na viacerých miestach"},
{"code":"M71.52","desc":"Iná burzitída, nezatriedená inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M71.53","desc":"Iná burzitída, nezatriedená inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M71.54","desc":"Iná burzitída, nezatriedená inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M71.55","desc":"Iná burzitída, nezatriedená inde, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M71.56","desc":"Iná burzitída, nezatriedená inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M71.57","desc":"Iná burzitída, nezatriedená inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M71.58","desc":"Iná burzitída, nezatriedená inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M71.59","desc":"Iná burzitída, nezatriedená inde, miesto bližšie neurčené,"},
{"code":"M71.8-","desc":"Iná burzopatia, bližšie určená"},
{"code":"M71.80","desc":"Iná burzopatia, bližšie určená, na viacerých miestach"},
{"code":"M71.81","desc":"Iná burzopatia, bližšie určená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M71.82","desc":"Iná burzopatia, bližšie určená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M71.83","desc":"Iná burzopatia, bližšie určená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M71.84","desc":"Iná burzopatia, bližšie určená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M71.85","desc":"Iná burzopatia, bližšie určená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M71.86","desc":"Iná burzopatia, bližšie určená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M71.87","desc":"Iná burzopatia, bližšie určená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M71.88","desc":"Iná burzopatia, bližšie určená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M71.89","desc":"Iná burzopatia, bližšie určená:, miesto bližšie neurčené"},
{"code":"M71.9-","desc":"Burzopatia, bližšie neurčená"},
{"code":"M71.90","desc":"Burzopatia, bližšie neurčená, na viacerých miestach"},
{"code":"M71.91","desc":"Burzopatia, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M71.92","desc":"Burzopatia, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M71.93","desc":"Burzopatia, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M71.94","desc":"Burzopatia, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M71.95","desc":"Burzopatia, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M71.96","desc":"Burzopatia, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M71.97","desc":"Burzopatia, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M71.98","desc":"Burzopatia, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M71.99","desc":"Burzopatia, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M72.-","desc":"Fibromatózy"},
{"code":"M72.0","desc":"Fibromatóza palmárnej aponeurózy (Dupuytrenova kontraktúra)"},
{"code":"M72.1","desc":"Fibrózne uzlíky pri degeneratívnych (artrotických) zmenách prstov rúk"},
{"code":"M72.2","desc":"Fibromatóza plantárnej aponeurózy"},
{"code":"M72.4-","desc":"Pseudosarkómová fibromatóza"},
{"code":"M72.40","desc":"Pseudosarkómová fibromatóza na viacerých miestach"},
{"code":"M72.41","desc":"Pseudosarkómová fibromatóza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M72.42","desc":"Pseudosarkómová fibromatóza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M72.43","desc":"Pseudosarkómová fibromatóza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M72.44","desc":"Pseudosarkómová fibromatóza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M72.45","desc":"Pseudosarkómová fibromatóza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M72.46","desc":"Pseudosarkómová fibromatóza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M72.47","desc":"Pseudosarkómová fibromatóza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M72.48","desc":"Pseudosarkómová fibromatóza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M72.49","desc":"Pseudosarkómová fibromatóza, miesto bližšie neurčené"},
{"code":"M72.6-","desc":"Nekrotizujúca fasciitída"},
{"code":"M72.60","desc":"Nekrotizujúca fasciitída na viacerých miestach"},
{"code":"M72.61","desc":"Nekrotizujúca fasciitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M72.62","desc":"Nekrotizujúca fasciitída v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M72.63","desc":"Nekrotizujúca fasciitída v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M72.64","desc":"Nekrotizujúca fasciitída v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M72.65","desc":"Nekrotizujúca fasciitída v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M72.66","desc":"Nekrotizujúca fasciitída v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M72.67","desc":"Nekrotizujúca fasciitída v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M72.68","desc":"Nekrotizujúca fasciitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M72.69","desc":"Nekrotizujúca fasciitída, miesto bližšie neurčené"},
{"code":"M72.8-","desc":"Iné fibromatózy"},
{"code":"M72.80","desc":"Iné fibromatózy na viacerých miestach"},
{"code":"M72.81","desc":"Iné fibromatózy v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M72.82","desc":"Iné fibromatózy v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M72.83","desc":"Iné fibromatózy v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M72.84","desc":"Iné fibromatózy v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M72.85","desc":"Iné fibromatózy v panvovej oblasti a v oblasti stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M72.86","desc":"Iné fibromatózy v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M72.87","desc":"Iné fibromatózy v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M72.88","desc":"Iné fibromatózy na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M72.89","desc":"Iné fibromatózy, miesto bližšie neurčené"},
{"code":"M72.9-","desc":"Fibromatóza, bližšie neurčená"},
{"code":"M72.90","desc":"Fibromatóza, bližšie neurčená, na viacerých miestach"},
{"code":"M72.91","desc":"Fibromatóza, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M72.92","desc":"Fibromatóza, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M72.93","desc":"Fibromatóza, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M72.94","desc":"Fibromatóza, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M72.95","desc":"Fibromatóza, bližšie neurčená, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M72.96","desc":"Fibromatóza, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M72.97","desc":"Fibromatóza, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M72.98","desc":"Fibromatóza, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M72.99","desc":"Fibromatóza, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M73.-*","desc":"Choroby mäkkého tkaniva pri chorobách zatriedených inde"},
{"code":"M73.0-*","desc":"Gonokoková burzitída (A54.4†)"},
{"code":"M73.00*","desc":"Gonokoková burzitída na viacerých miestach (A54.4†)"},
{"code":"M73.01*","desc":"Gonokoková burzitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (A54.4†)"},
{"code":"M73.02*","desc":"Gonokoková burzitída nadlaktia (ramenná kosť, lakťový kĺb) (A54.4†)"},
{"code":"M73.03*","desc":"Gonokoková burzitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (A54.4†)"},
{"code":"M73.04*","desc":"Gonokoková burzitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (A54.4†)"},
{"code":"M73.05*","desc":"Gonokoková burzitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (A54.4†)"},
{"code":"M73.06*","desc":"Gonokoková burzitída predkolenia (ihlica, píšťala, kolenný kĺb) (A54.4†)"},
{"code":"M73.07*","desc":"Gonokoková burzitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (A54.4†)"},
{"code":"M73.08*","desc":"Gonokoková burzitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (A54.4†)"},
{"code":"M73.09*","desc":"Gonokoková burzitída, miesto bližšie neurčené (A54.4†)"},
{"code":"M73.1-*","desc":"Syfilitická burzitída (A52.7†)"},
{"code":"M73.10*","desc":"Syfilitická burzitída na viacerých miestach (A52.7†)"},
{"code":"M73.11*","desc":"Syfilitická burzitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (A52.7†)"},
{"code":"M73.12*","desc":"Syfilitická burzitída nadlaktia (ramenná kosť, lakťový kĺb) (A52.7†)"},
{"code":"M73.13*","desc":"Syfilitická burzitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (A52.7†)"},
{"code":"M73.14*","desc":"Syfilitická burzitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (A52.7†)"},
{"code":"M73.15*","desc":"Syfilitická burzitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (A52.7†)"},
{"code":"M73.16*","desc":"Syfilitická burzitída predkolenia (ihlica, píšťala, kolenný kĺb) (A52.7†)"},
{"code":"M73.17*","desc":"Syfilitická burzitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (A52.7†)"},
{"code":"M73.18*","desc":"Syfilitická burzitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (A52.7†)"},
{"code":"M73.19*","desc":"Syfilitická burzitída, miesto bližšie neurčené (A52.7†)"},
{"code":"M73.8-*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde"},
{"code":"M73.80*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, na viacerých miestach"},
{"code":"M73.81*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M73.82*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M73.83*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M73.84*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M73.85*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M73.86*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M73.87*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M73.88*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M73.89*","desc":"Iné choroby mäkkého tkaniva pri chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M75.-","desc":"Poškodenie pleca"},
{"code":"M75.0","desc":"Adhezívna kapsulitída pleca"},
{"code":"M75.1","desc":"Syndróm manžety rotátorov"},
{"code":"M75.2","desc":"Tendinitída m. biceps brachii"},
{"code":"M75.3","desc":"Vápenatejúca tendinitída pleca"},
{"code":"M75.4","desc":"Syndróm narazeného ramena"},
{"code":"M75.5","desc":"Burzitída pleca"},
{"code":"M75.8","desc":"Iné poškodenie pleca"},
{"code":"M75.9","desc":"Poškodenie pleca, bližšie neurčené"},
{"code":"M76.-","desc":"Entezopatia dolnej končatiny, okrem nohy"},
{"code":"M76.0","desc":"Gluteálna tendinitída"},
{"code":"M76.1","desc":"Tendinítída m. psoas"},
{"code":"M76.2","desc":"Ostroha na crista iliaca"},
{"code":"M76.3","desc":"Syndróm iliotibiálneho traktu"},
{"code":"M76.4","desc":"Burzitída tibiálneho kolaterálneho väzu [Stieda-Pellegrini]"},
{"code":"M76.5","desc":"Tendinitída šľachy pately"},
{"code":"M76.6","desc":"Tendinitída Achillovej šľachy"},
{"code":"M76.7","desc":"Tendinitída peroneálnych svalov"},
{"code":"M76.8","desc":"Iná entezopatia dolnej končatiny, okrem nohy"},
{"code":"M76.9","desc":"Entezopatia dolnej končatiny, bližšie neurčená"},
{"code":"M77.-","desc":"Iná entezopatia"},
{"code":"M77.0","desc":"Mediálna epikondylitída"},
{"code":"M77.1","desc":"Laterálna epikondylitída"},
{"code":"M77.2","desc":"Periartritída zápästia"},
{"code":"M77.3","desc":"Ostroha pätovej kosti [kohútia ostroha]"},
{"code":"M77.4","desc":"Metatarzalgia"},
{"code":"M77.5","desc":"Iná entezopatia nohy"},
{"code":"M77.8","desc":"Iná entezopatia, nezatriedená inde"},
{"code":"M77.9","desc":"Entezopatia, bližšie neurčená"},
{"code":"M79.-","desc":"Iná choroba mäkkého tkaniva, nezatriedená inde"},
{"code":"M79.0-","desc":"Reumatizmus, bližšie neurčený"},
{"code":"M79.00","desc":"Reumatizmus, bližšie neurčený, na viacerých miestach"},
{"code":"M79.01","desc":"Reumatizmus, bližšie neurčený, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M79.02","desc":"Reumatizmus, bližšie neurčený, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M79.03","desc":"Reumatizmus, bližšie neurčený, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M79.04","desc":"Reumatizmus, bližšie neurčený, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M79.05","desc":"Reumatizmus, bližšie neurčený, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M79.06","desc":"Reumatizmus, bližšie neurčený, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M79.07","desc":"Reumatizmus, bližšie neurčený, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M79.08","desc":"Reumatizmus, bližšie neurčený, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M79.09","desc":"Reumatizmus, bližšie neurčený, miesto bližšie neurčené"},
{"code":"M79.1-","desc":"Myalgia"},
{"code":"M79.10","desc":"Myalgia na viacerých miestach"},
{"code":"M79.11","desc":"Myalgia v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M79.12","desc":"Myalgia nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M79.13","desc":"Myalgia predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M79.14","desc":"Myalgia ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M79.15","desc":"Myalgia panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M79.16","desc":"Myalgia predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M79.17","desc":"Myalgia členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M79.18","desc":"Myalgia na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M79.19","desc":"Myalgia, miesto bližšie neurčené"},
{"code":"M79.2-","desc":"Neuralgia a neuritída, bližšie neurčená"},
{"code":"M79.20","desc":"Neuralgia a neuritída, bližšie neurčená, na viacerých miestach"},
{"code":"M79.21","desc":"Neuralgia a neuritída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M79.22","desc":"Neuralgia a neuritída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M79.23","desc":"Neuralgia a neuritída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M79.24","desc":"Neuralgia a neuritída, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M79.25","desc":"Neuralgia a neuritída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M79.26","desc":"Neuralgia a neuritída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M79.27","desc":"Neuralgia a neuritída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M79.28","desc":"Neuralgia a neuritída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M79.29","desc":"Neuralgia a neuritída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M79.3-","desc":"Panikulitída, bližšie neurčená"},
{"code":"M79.30","desc":"Panikulitída, bližšie neurčená, na viacerých miestach"},
{"code":"M79.31","desc":"Panikulitída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M79.32","desc":"Panikulitída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M79.33","desc":"Panikulitída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M79.34","desc":"Panikulitída, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M79.35","desc":"Panikulitída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M79.36","desc":"Panikulitída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M79.37","desc":"Panikulitída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M79.38","desc":"Panikulitída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M79.39","desc":"Panikulitída, bližšie neurčená:, miesto bližšie neurčené"},
{"code":"M79.4-","desc":"Hypertrofia (infrapatelárneho) tukového vankúša"},
{"code":"M79.46","desc":"Hypertrofia (infrapatelárneho) tukového vankúša[Hoffov Kastertov syndrom] predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M79.5-","desc":"Reziduálne cudzie teleso v mäkkom tkanive"},
{"code":"M79.50","desc":"Reziduálne cudzie teleso v mäkkom tkanive na viacerých miestach"},
{"code":"M79.51","desc":"Reziduálne cudzie teleso v mäkkom tkanive v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M79.52","desc":"Reziduálne cudzie teleso v mäkkom tkanive nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M79.53","desc":"Reziduálne cudzie teleso v mäkkom tkanive predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M79.54","desc":"Reziduálne cudzie teleso v mäkkom tkanive ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M79.55","desc":"Reziduálne cudzie teleso v mäkkom tkanive panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M79.56","desc":"Reziduálne cudzie teleso v mäkkom tkanive predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M79.57","desc":"Reziduálne cudzie teleso v mäkkom tkanive členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M79.58","desc":"Reziduálne cudzie teleso v mäkkom tkanive na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M79.59","desc":"Reziduálne cudzie teleso v mäkkom tkanive, miesto bližšie neurčené"},
{"code":"M79.6-","desc":"Bolesť v končatine"},
{"code":"M79.60","desc":"Bolesť v končatine na viacerých miestach"},
{"code":"M79.61","desc":"Bolesť v končatine v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M79.62","desc":"Bolesť v končatine nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M79.63","desc":"Bolesť v končatine predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M79.64","desc":"Bolesť v končatine ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M79.65","desc":"Bolesť v končatine panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M79.66","desc":"Bolesť v končatine predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M79.67","desc":"Bolesť v končatine členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M79.69","desc":"Bolesť v končatine, miesto bližšie neurčené"},
{"code":"M79.7-","desc":"Fibromyalgia"},
{"code":"M79.70","desc":"Fibromyalgia na viacerých miestach"},
{"code":"M79.8-","desc":"Iná choroba mäkkého tkaniva, bližšie určená"},
{"code":"M79.80","desc":"Iná choroba mäkkého tkaniva, bližšie určená, na viacerých miestach"},
{"code":"M79.81","desc":"Iná choroba mäkkého tkaniva, bližšie určená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M79.82","desc":"Iná choroba mäkkého tkaniva, bližšie určená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M79.83","desc":"Iná choroba mäkkého tkaniva, bližšie určená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M79.84","desc":"Iná choroba mäkkého tkaniva, bližšie určená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M79.85","desc":"Iná choroba mäkkého tkaniva, bližšie určená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M79.86","desc":"Iná choroba mäkkého tkaniva, bližšie určená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M79.87","desc":"Iná choroba mäkkého tkaniva, bližšie určená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M79.88","desc":"Iná choroba mäkkého tkaniva, bližšie určená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M79.89","desc":"Iná choroba mäkkého tkaniva, bližšie určená, miesto bližšie neurčené"},
{"code":"M79.9-","desc":"Choroba mäkkého tkaniva, bližšie neurčená"},
{"code":"M79.90","desc":"Choroba mäkkého tkaniva, bližšie neurčená, na viacerých miestach"},
{"code":"M79.91","desc":"Choroba mäkkého tkaniva, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M79.92","desc":"Choroba mäkkého tkaniva, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M79.93","desc":"Choroba mäkkého tkaniva, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M79.94","desc":"Choroba mäkkého tkaniva, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M79.95","desc":"Choroba mäkkého tkaniva, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M79.96","desc":"Choroba mäkkého tkaniva, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M79.97","desc":"Choroba mäkkého tkaniva, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M79.98","desc":"Choroba mäkkého tkaniva, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M79.99","desc":"Choroba mäkkého tkaniva, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M80-M94","desc":"Osteopatie a chondropatie"},
{"code":"M80-M85","desc":"Poruchy hustoty a štruktúry kostí"},
{"code":"M80.-","desc":"Osteoporóza s patologickou zlomeninou"},
{"code":"M80.0-","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou"},
{"code":"M80.00","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou na viacerých miestach"},
{"code":"M80.01","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M80.02","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M80.03","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M80.04","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M80.05","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M80.06","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M80.07","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M80.08","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M80.09","desc":"Postmenopauzálna osteoporóza s patologickou zlomeninou, miesto bližšie neurčené"},
{"code":"M80.1-","desc":"Postovariektomická osteoporóza s patologickou zlomeninou"},
{"code":"M80.10","desc":"Postovariektomická osteoporóza s patologickou zlomeninou na viacerých miestach"},
{"code":"M80.11","desc":"Postovariektomická osteoporóza s patologickou zlomeninou v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M80.12","desc":"Postovariektomická osteoporóza s patologickou zlomeninou nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M80.13","desc":"Postovariektomická osteoporóza s patologickou zlomeninou predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M80.14","desc":"Postovariektomická osteoporóza s patologickou zlomeninou ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M80.15","desc":"Postovariektomická osteoporóza s patologickou zlomeninou panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M80.16","desc":"Postovariektomická osteoporóza s patologickou zlomeninou predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M80.17","desc":"Postovariektomická osteoporóza s patologickou zlomeninou členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M80.18","desc":"Postovariektomická osteoporóza s patologickou zlomeninou na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M80.19","desc":"Postovariektomická osteoporóza s patologickou zlomeninou, miesto bližšie neurčené"},
{"code":"M80.2-","desc":"Osteoporóza z inaktivity s patologickou zlomeninou"},
{"code":"M80.20","desc":"Osteoporóza z inaktivity s patologickou zlomeninou na viacerých miestach"},
{"code":"M80.21","desc":"Osteoporóza z inaktivity s patologickou zlomeninou v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M80.22","desc":"Osteoporóza z inaktivity s patologickou zlomeninou nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M80.23","desc":"Osteoporóza z inaktivity s patologickou zlomeninou predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M80.24","desc":"Osteoporóza z inaktivity s patologickou zlomeninou ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M80.25","desc":"Osteoporóza z inaktivity s patologickou zlomeninou panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M80.26","desc":"Osteoporóza z inaktivity s patologickou zlomeninou predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M80.27","desc":"Osteoporóza z inaktivity s patologickou zlomeninou členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M80.28","desc":"Osteoporóza z inaktivity s patologickou zlomeninou na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M80.29","desc":"Osteoporóza z inaktivity s patologickou zlomeninou, miesto bližšie neurčené"},
{"code":"M80.3-","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou"},
{"code":"M80.30","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou na viacerých miestach"},
{"code":"M80.31","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M80.32","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M80.33","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M80.34","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M80.35","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M80.36","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M80.37","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M80.38","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M80.39","desc":"Pooperačná malabsorpčná osteoporóza s patologickou zlomeninou, miesto bližšie neurčené"},
{"code":"M80.4-","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou"},
{"code":"M80.40","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou na viacerých miestach"},
{"code":"M80.41","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M80.42","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M80.43","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M80.44","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M80.45","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M80.46","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M80.47","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M80.48","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M80.49","desc":"Osteoporóza zapríčinená liekmi s patologickou zlomeninou, miesto bližšie neurčené"},
{"code":"M80.5-","desc":"Idiopatická osteoporóza s patologickou zlomeninou"},
{"code":"M80.50","desc":"Idiopatická osteoporóza s patologickou zlomeninou na viacerých miestach"},
{"code":"M80.51","desc":"Idiopatická osteoporóza s patologickou zlomeninou v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M80.52","desc":"Idiopatická osteoporóza s patologickou zlomeninou nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M80.53","desc":"Idiopatická osteoporóza s patologickou zlomeninou predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M80.54","desc":"Idiopatická osteoporóza s patologickou zlomeninou ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M80.55","desc":"Idiopatická osteoporóza s patologickou zlomeninou panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M80.56","desc":"Idiopatická osteoporóza s patologickou zlomeninou predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M80.57","desc":"Idiopatická osteoporóza s patologickou zlomeninou členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M80.58","desc":"Idiopatická osteoporóza s patologickou zlomeninou na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M80.59","desc":"Idiopatická osteoporóza s patologickou zlomeninou, miesto bližšie neurčené"},
{"code":"M80.8-","desc":"Iná osteoporóza s patologickou zlomeninou"},
{"code":"M80.80","desc":"Iná osteoporóza s patologickou zlomeninou na viacerých miestach"},
{"code":"M80.81","desc":"Iná osteoporóza s patologickou zlomeninou v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M80.82","desc":"Iná osteoporóza s patologickou zlomeninou nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M80.83","desc":"Iná osteoporóza s patologickou zlomeninou predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M80.84","desc":"Iná osteoporóza s patologickou zlomeninou ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M80.85","desc":"Iná osteoporóza s patologickou zlomeninou panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M80.86","desc":"Iná osteoporóza s patologickou zlomeninou predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M80.87","desc":"Iná osteoporóza s patologickou zlomeninou členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M80.88","desc":"Iná osteoporóza s patologickou zlomeninou na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M80.89","desc":"Iná osteoporóza s patologickou zlomeninou, miesto bližšie neurčené"},
{"code":"M80.9-","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená"},
{"code":"M80.90","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, na viacerých miestach"},
{"code":"M80.91","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M80.92","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M80.93","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M80.94","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M80.95","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M80.96","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M80.97","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M80.98","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M80.99","desc":"Osteoporóza s patologickou zlomeninou, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M81.-","desc":"Osteoporóza bez patologickej zlomeniny"},
{"code":"M81.0-","desc":"Postmenopauzálna osteoporóza"},
{"code":"M81.00","desc":"Postmenopauzálna osteoporóza na viacerých miestach"},
{"code":"M81.01","desc":"Postmenopauzálna osteoporóza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M81.02","desc":"Postmenopauzálna osteoporóza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M81.03","desc":"Postmenopauzálna osteoporóza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M81.04","desc":"Postmenopauzálna osteoporóza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M81.05","desc":"Postmenopauzálna osteoporóza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M81.06","desc":"Postmenopauzálna osteoporóza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M81.07","desc":"Postmenopauzálna osteoporóza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M81.08","desc":"Postmenopauzálna osteoporóza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M81.09","desc":"Postmenopauzálna osteoporóza, miesto bližšie neurčené"},
{"code":"M81.1-","desc":"Postovariektomická osteoporóza"},
{"code":"M81.10","desc":"Postovariektomická osteoporóza na viacerých miestach"},
{"code":"M81.11","desc":"Postovariektomická osteoporóza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M81.12","desc":"Postovariektomická osteoporóza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M81.13","desc":"Postovariektomická osteoporóza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M81.14","desc":"Postovariektomická osteoporóza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M81.15","desc":"Postovariektomická osteoporóza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M81.16","desc":"Postovariektomická osteoporóza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M81.17","desc":"Postovariektomická osteoporóza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M81.18","desc":"Postovariektomická osteoporóza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M81.19","desc":"Postovariektomická osteoporóza, miesto bližšie neurčené"},
{"code":"M81.2-","desc":"Osteoporóza z inaktivity"},
{"code":"M81.20","desc":"Osteoporóza z inaktivity na viacerých miestach"},
{"code":"M81.21","desc":"Osteoporóza z inaktivity v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M81.22","desc":"Osteoporóza z inaktivity v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M81.23","desc":"Osteoporóza z inaktivity v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M81.24","desc":"Osteoporóza z inaktivity v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M81.25","desc":"Osteoporóza z inaktivity v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M81.26","desc":"Osteoporóza z inaktivity v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M81.27","desc":"Osteoporóza z inaktivity v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M81.28","desc":"Osteoporóza z inaktivity na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M81.29","desc":"Osteoporóza z inaktivity, miesto bližšie neurčené"},
{"code":"M81.3-","desc":"Pooperačná malabsorpčná osteoporóza"},
{"code":"M81.30","desc":"Pooperačná malabsorpčná osteoporóza na viacerých miestach"},
{"code":"M81.31","desc":"Pooperačná malabsorpčná osteoporóza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M81.32","desc":"Pooperačná malabsorpčná osteoporóza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M81.33","desc":"Pooperačná malabsorpčná osteoporóza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M81.34","desc":"Pooperačná malabsorpčná osteoporóza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M81.35","desc":"Pooperačná malabsorpčná osteoporóza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M81.36","desc":"Pooperačná malabsorpčná osteoporóza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M81.37","desc":"Pooperačná malabsorpčná osteoporóza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M81.38","desc":"Pooperačná malabsorpčná osteoporóza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M81.39","desc":"Pooperačná malabsorpčná osteoporóza, miesto bližšie neurčené"},
{"code":"M81.4-","desc":"Osteopozóza zapríčinená liekmi"},
{"code":"M81.40","desc":"Osteopozóza zapríčinená liekmi na viacerých miestach"},
{"code":"M81.41","desc":"Osteopozóza zapríčinená liekmi v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M81.42","desc":"Osteopozóza zapríčinená liekmi nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M81.43","desc":"Osteopozóza zapríčinená liekmi predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M81.44","desc":"Osteopozóza zapríčinená liekmi ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M81.45","desc":"Osteopozóza zapríčinená liekmi panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M81.46","desc":"Osteopozóza zapríčinená liekmi predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M81.47","desc":"Osteopozóza zapríčinená liekmi členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M81.48","desc":"Osteopozóza zapríčinená liekmi na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M81.49","desc":"Osteopozóza zapríčinená liekmi, miesto bližšie neurčené"},
{"code":"M81.5-","desc":"Idiopatická osteoporóza"},
{"code":"M81.50","desc":"Idiopatická osteoporóza na viacerých miestach"},
{"code":"M81.51","desc":"Idiopatická osteoporóza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M81.52","desc":"Idiopatická osteoporóza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M81.53","desc":"Idiopatická osteoporóza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M81.54","desc":"Idiopatická osteoporóza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M81.55","desc":"Idiopatická osteoporóza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M81.56","desc":"Idiopatická osteoporóza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M81.57","desc":"Idiopatická osteoporóza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M81.58","desc":"Idiopatická osteoporóza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M81.59","desc":"Idiopatická osteoporóza, miesto bližšie neurčené"},
{"code":"M81.6-","desc":"Lokalizovaná osteoporóza [Lequesneova]"},
{"code":"M81.60","desc":"Lokalizovaná osteoporóza [Lequesneova] na viacerých miestach"},
{"code":"M81.61","desc":"Lokalizovaná osteoporóza [Lequesneova] v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M81.62","desc":"Lokalizovaná osteoporóza [Lequesneova] nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M81.63","desc":"Lokalizovaná osteoporóza [Lequesneova] predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M81.64","desc":"Lokalizovaná osteoporóza [Lequesneova] ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M81.65","desc":"Lokalizovaná osteoporóza [Lequesneova] panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M81.66","desc":"Lokalizovaná osteoporóza [Lequesneova] predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M81.67","desc":"Lokalizovaná osteoporóza [Lequesneova] členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M81.68","desc":"Lokalizovaná osteoporóza [Lequesneova] na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M81.69","desc":"Lokalizovaná osteoporóza [Lequesneova], miesto bližšie neurčené"},
{"code":"M81.8-","desc":"Iná osteoporóza"},
{"code":"M81.80","desc":"Iná osteoporóza na viacerých miestach"},
{"code":"M81.81","desc":"Iná osteoporóza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M81.82","desc":"Iná osteoporóza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M81.83","desc":"Iná osteoporóza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M81.84","desc":"Iná osteoporóza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M81.85","desc":"Iná osteoporóza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M81.86","desc":"Iná osteoporóza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M81.87","desc":"Iná osteoporóza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M81.88","desc":"Iná osteoporóza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M81.89","desc":"Iná osteoporóza, miesto bližšie neurčené"},
{"code":"M81.9-","desc":"Osteoporóza, bližšie neurčená"},
{"code":"M81.90","desc":"Osteoporóza, bližšie neurčená, na viacerých miestach"},
{"code":"M81.91","desc":"Osteoporóza, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M81.92","desc":"Osteoporóza, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M81.93","desc":"Osteoporóza, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M81.94","desc":"Osteoporóza, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M81.95","desc":"Osteoporóza, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M81.96","desc":"Osteoporóza, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M81.97","desc":"Osteoporóza, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M81.98","desc":"Osteoporóza, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M81.99","desc":"Osteoporóza, bližšie neurčená:, miesto bližšie neurčené"},
{"code":"M82.-*","desc":"Osteoporóza pri chorobe zatriedenej inde"},
{"code":"M82.0-*","desc":"Osteoporóza pri mnohopočetnom myelóme (C90.0-†)"},
{"code":"M82.00*","desc":"Osteoporóza pri mnohopočetnom myelóme, na viacerých miestach (C90.0-†)"},
{"code":"M82.01*","desc":"Osteoporóza pri mnohopočetnom myelóme, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (C90.0-†)"},
{"code":"M82.02*","desc":"Osteoporóza pri mnohopočetnom myelóme, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (C90.0-†)"},
{"code":"M82.03*","desc":"Osteoporóza pri mnohopočetnom myelóme, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (C90.0-†)"},
{"code":"M82.04*","desc":"Osteoporóza pri mnohopočetnom myelóme, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (C90.0-†)"},
{"code":"M82.05*","desc":"Osteoporóza pri mnohopočetnom myelóme, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (C90.0-†)"},
{"code":"M82.06*","desc":"Osteoporóza pri mnohopočetnom myelóme, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (C90.0-†)"},
{"code":"M82.07*","desc":"Osteoporóza pri mnohopočetnom myelóme, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (C90.0-†)"},
{"code":"M82.08*","desc":"Osteoporóza pri mnohopočetnom myelóme, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (C90.0-†)"},
{"code":"M82.09*","desc":"Osteoporóza pri mnohopočetnom myelóme, miesto bližšie neurčené (C90.0-†)"},
{"code":"M82.1-*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním (E00-E34†)"},
{"code":"M82.10*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, na viacerých miestach (E00-E34†)"},
{"code":"M82.11*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (E00-E34†)"},
{"code":"M82.12*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (E00-E34†)"},
{"code":"M82.13*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (E00-E34†)"},
{"code":"M82.14*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (E00-E34†)"},
{"code":"M82.15*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (E00-E34†)"},
{"code":"M82.16*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (E00-E34†)"},
{"code":"M82.17*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (E00-E34†)"},
{"code":"M82.18*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (E00-E34†)"},
{"code":"M82.19*","desc":"Osteoporóza pri chorobách žliaz s vnútorným vylučovaním, miesto bližšie neurčené (E00-E34†)"},
{"code":"M82.8-*","desc":"Osteoporóza pri iných chorobách zatriedených inde"},
{"code":"M82.80*","desc":"Osteoporóza pri iných chorobách zatriedených inde, na viacerých miestach"},
{"code":"M82.81*","desc":"Osteoporóza pri iných chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M82.82*","desc":"Osteoporóza pri iných chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M82.83*","desc":"Osteoporóza pri iných chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M82.84*","desc":"Osteoporóza pri iných chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M82.85*","desc":"Osteoporóza pri iných chorobách zatriedených inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M82.86*","desc":"Osteoporóza pri iných chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M82.87*","desc":"Osteoporóza pri iných chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M82.88*","desc":"Osteoporóza pri iných chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M82.89*","desc":"Osteoporóza pri iných chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M83.-","desc":"Osteomalácia v dospelosti"},
{"code":"M83.0-","desc":"Osteomalácia v šestonedelí"},
{"code":"M83.00","desc":"Osteomalácia v šestonedelí na viacerých miestach"},
{"code":"M83.01","desc":"Osteomalácia v šestonedelí v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M83.02","desc":"Osteomalácia v šestonedelí v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M83.03","desc":"Osteomalácia v šestonedelí v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M83.04","desc":"Osteomalácia v šestonedelí v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M83.05","desc":"Osteomalácia v šestonedelí v oblasti panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M83.06","desc":"Osteomalácia v šestonedelí v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M83.07","desc":"Osteomalácia v šestonedelí v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M83.08","desc":"Osteomalácia v šestonedelí na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M83.09","desc":"Osteomalácia v šestonedelí, miesto bližšie neurčené"},
{"code":"M83.1-","desc":"Starecká osteomalácia"},
{"code":"M83.10","desc":"Starecká osteomalácia na viacerých miestach"},
{"code":"M83.11","desc":"Starecká osteomalácia v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M83.12","desc":"Starecká osteomalácia nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M83.13","desc":"Starecká osteomalácia predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M83.14","desc":"Starecká osteomalácia ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M83.15","desc":"Starecká osteomalácia panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M83.16","desc":"Starecká osteomalácia predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M83.17","desc":"Starecká osteomalácia členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M83.18","desc":"Starecká osteomalácia na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M83.19","desc":"Starecká osteomalácia, miesto bližšie neurčené"},
{"code":"M83.2-","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou"},
{"code":"M83.20","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou, na viacerých miestach"},
{"code":"M83.21","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M83.22","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M83.23","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M83.24","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M83.25","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M83.26","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M83.27","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M83.28","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M83.29","desc":"Osteomalácia v dospelosti zapríčinená malabsorpciou:, miesto bližšie neurčené"},
{"code":"M83.3-","desc":"Osteomalácia v dospelosti zapríčinená podvýživou"},
{"code":"M83.30","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, na viacerých miestach"},
{"code":"M83.31","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M83.32","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M83.33","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M83.34","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M83.35","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M83.36","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M83.37","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M83.38","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M83.39","desc":"Osteomalácia v dospelosti zapríčinená podvýživou, miesto bližšie neurčené"},
{"code":"M83.4-","desc":"Hliníková kostná choroba"},
{"code":"M83.40","desc":"Hliníková kostná choroba na viacerých miestach"},
{"code":"M83.41","desc":"Hliníková kostná choroba v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M83.42","desc":"Hliníková kostná choroba nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M83.43","desc":"Hliníková kostná choroba predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M83.44","desc":"Hliníková kostná choroba ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M83.45","desc":"Hliníková kostná choroba panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M83.46","desc":"Hliníková kostná choroba predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M83.47","desc":"Hliníková kostná choroba členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M83.48","desc":"Hliníková kostná choroba na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M83.49","desc":"Hliníková kostná choroba, miesto bližšie neurčené"},
{"code":"M83.5-","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi:"},
{"code":"M83.50","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, na viacerých miestach"},
{"code":"M83.51","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M83.52","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M83.53","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M83.54","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M83.55","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M83.56","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M83.57","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M83.58","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M83.59","desc":"Iná osteomalácia v dospelosti zapríčinená liekmi, miesto bližšie neurčené"},
{"code":"M83.8-","desc":"Iná osteomalácia v dospelosti"},
{"code":"M83.80","desc":"Iná osteomalácia v dospelosti na viacerých miestach"},
{"code":"M83.81","desc":"Iná osteomalácia v dospelosti v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M83.82","desc":"Iná osteomalácia v dospelosti v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M83.83","desc":"Iná osteomalácia v dospelosti v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M83.84","desc":"Iná osteomalácia v dospelosti v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M83.85","desc":"Iná osteomalácia v dospelosti v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M83.86","desc":"Iná osteomalácia v dospelosti v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M83.87","desc":"Iná osteomalácia v dospelosti v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M83.88","desc":"Iná osteomalácia v dospelosti na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M83.89","desc":"Iná osteomalácia v dospelosti, miesto bližšie neurčené"},
{"code":"M83.9-","desc":"Osteomalácia v dospelosti, bližšie neurčená"},
{"code":"M83.90","desc":"Osteomalácia v dospelosti, bližšie neurčená, na viacerých miestach"},
{"code":"M83.91","desc":"Osteomalácia v dospelosti, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M83.92","desc":"Osteomalácia v dospelosti, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M83.93","desc":"Osteomalácia v dospelosti, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M83.94","desc":"Osteomalácia v dospelosti, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M83.95","desc":"Osteomalácia v dospelosti, bližšie neurčená, v oblasti panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M83.96","desc":"Osteomalácia v dospelosti, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M83.97","desc":"Osteomalácia v dospelosti, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M83.98","desc":"Osteomalácia v dospelosti, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M83.99","desc":"Osteomalácia v dospelosti, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M84.-","desc":"Porucha celistvosti kosti"},
{"code":"M84.0-","desc":"Zle zahojená zlomenina kosti"},
{"code":"M84.00","desc":"Zle zahojená zlomenina na viacerých miestach"},
{"code":"M84.01","desc":"Zle zahojená zlomenina v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M84.02","desc":"Zle zahojená zlomenina nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M84.03","desc":"Zle zahojená zlomenina predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M84.04","desc":"Zle zahojená zlomenina ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M84.05","desc":"Zle zahojená zlomenina panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M84.06","desc":"Zle zahojená zlomenina predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M84.07","desc":"Zle zahojená zlomenina členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M84.08","desc":"Zle zahojená zlomenina na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M84.09","desc":"Zle zahojená zlomenina, miesto bližšie neurčené"},
{"code":"M84.1-","desc":"Pseudoartróza po zlomenine"},
{"code":"M84.10","desc":"Pseudoartróza po zlomenine na viacerých miestach"},
{"code":"M84.11","desc":"Pseudoartróza po zlomenine v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M84.12","desc":"Pseudoartróza po zlomenine nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M84.13","desc":"Pseudoartróza po zlomenine predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M84.14","desc":"Pseudoartróza po zlomenine ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M84.15","desc":"Pseudoartróza po zlomenine panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M84.16","desc":"Pseudoartróza po zlomenine predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M84.17","desc":"Pseudoartróza po zlomenine členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M84.18","desc":"Pseudoartróza po zlomenine na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M84.19","desc":"Pseudoartróza po zlomenine, miesto bližšie neurčené"},
{"code":"M84.2-","desc":"Predĺžené hojenie zlomeniny"},
{"code":"M84.20","desc":"Predĺžené hojenie zlomeniny na viacerých miestach"},
{"code":"M84.21","desc":"Predĺžené hojenie zlomeniny v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M84.22","desc":"Predĺžené hojenie zlomeniny nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M84.23","desc":"Predĺžené hojenie zlomeniny predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M84.24","desc":"Predĺžené hojenie zlomeniny ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M84.25","desc":"Predĺžené hojenie zlomeniny panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M84.26","desc":"Predĺžené hojenie zlomeniny predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M84.27","desc":"Predĺžené hojenie zlomeniny členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M84.28","desc":"Predĺžené hojenie zlomeniny na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M84.29","desc":"Predĺžené hojenie zlomeniny, miesto bližšie neurčené"},
{"code":"M84.3-","desc":"Únavová zlomenina, nezatriedená inde"},
{"code":"M84.30","desc":"Únavová zlomenina, nezatriedená inde, na viacerých miestach"},
{"code":"M84.31","desc":"Únavová zlomenina, nezatriedená inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M84.32","desc":"Únavová zlomenina, nezatriedená inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M84.33","desc":"Únavová zlomenina, nezatriedená inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M84.34","desc":"Únavová zlomenina, nezatriedená inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M84.35","desc":"Únavová zlomenina, nezatriedená inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M84.36","desc":"Únavová zlomenina, nezatriedená inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M84.37","desc":"Únavová zlomenina, nezatriedená inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M84.38","desc":"Únavová zlomenina, nezatriedená inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M84.39","desc":"Únavová zlomenina, nezatriedená inde, miesto bližšie neurčené"},
{"code":"M84.4-","desc":"Patologická zlomenina, nezatriedená inde"},
{"code":"M84.40","desc":"Patologická zlomenina, nezatriedená inde, na viacerých miestach"},
{"code":"M84.41","desc":"Patologická zlomenina, nezatriedená inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M84.42","desc":"Patologická zlomenina, nezatriedená inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M84.43","desc":"Patologická zlomenina, nezatriedená inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M84.44","desc":"Patologická zlomenina, nezatriedená inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M84.45","desc":"Patologická zlomenina, nezatriedená inde, v oblasti panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M84.46","desc":"Patologická zlomenina, nezatriedená inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M84.47","desc":"Patologická zlomenina, nezatriedená inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M84.48","desc":"Patologická zlomenina, nezatriedená inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M84.49","desc":"Patologická zlomenina, nezatriedená inde, miesto bližšie neurčené"},
{"code":"M84.8-","desc":"Iná porucha celistvosti kosti"},
{"code":"M84.80","desc":"Iné poruchy celistvosti kosti na viacerých miestach"},
{"code":"M84.81","desc":"Iné poruchy celistvosti kosti v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M84.82","desc":"Iné poruchy celistvosti kosti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M84.83","desc":"Iné poruchy celistvosti kosti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M84.84","desc":"Iné poruchy celistvosti kosti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M84.85","desc":"Iné poruchy celistvosti kosti panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M84.86","desc":"Iné poruchy celistvosti kosti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M84.87","desc":"Iné poruchy celistvosti kosti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M84.88","desc":"Iné poruchy celistvosti kosti na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M84.89","desc":"Iné poruchy celistvosti kosti, miesto bližšie neurčené"},
{"code":"M84.9-","desc":"Porucha celistvosti kosti, bližšie neurčená"},
{"code":"M84.90","desc":"Porucha celistvosti kosti, bližšie neurčená, na viacerých miestach"},
{"code":"M84.91","desc":"Porucha celistvosti kosti, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M84.92","desc":"Porucha celistvosti kosti, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M84.93","desc":"Porucha celistvosti kosti, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M84.94","desc":"Porucha celistvosti kosti, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M84.95","desc":"Porucha celistvosti kosti, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M84.96","desc":"Porucha celistvosti kosti, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M84.97","desc":"Porucha celistvosti kosti, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M84.98","desc":"Porucha celistvosti kosti, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M84.99","desc":"Porucha celistvosti kosti, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M85.-","desc":"Iné poruchy hustoty a štruktúry kostí"},
{"code":"M85.0-","desc":"Fibrózna dysplázia (jednej kosti)"},
{"code":"M85.00","desc":"Fibrózna dysplázia (jednej kosti), na viacerých miestach"},
{"code":"M85.01","desc":"Fibrózna dysplázia (jednej kosti), v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M85.02","desc":"Fibrózna dysplázia (jednej kosti), v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M85.03","desc":"Fibrózna dysplázia (jednej kosti), v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M85.04","desc":"Fibrózna dysplázia (jednej kosti), v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M85.05","desc":"Fibrózna dysplázia (jednej kosti), v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M85.06","desc":"Fibrózna dysplázia (jednej kosti), v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M85.07","desc":"Fibrózna dysplázia (jednej kosti), v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M85.08","desc":"Fibrózna dysplázia (jednej kosti), na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M85.09","desc":"Fibrózna dysplázia (jednej kosti):, miesto bližšie neurčené"},
{"code":"M85.1-","desc":"Fluoróza kostry"},
{"code":"M85.10","desc":"Fluoróza kostry na viacerých miestach"},
{"code":"M85.11","desc":"Fluoróza kostry v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M85.12","desc":"Fluoróza kostry nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M85.13","desc":"Fluoróza kostry predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M85.14","desc":"Fluoróza kostry ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M85.15","desc":"Fluoróza kostry panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M85.16","desc":"Fluoróza kostry predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M85.17","desc":"Fluoróza kostry členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M85.18","desc":"Fluoróza kostry na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M85.19","desc":"Fluoróza kostry, miesto bližšie neurčené"},
{"code":"M85.2","desc":"Hyperostóza lebky (črepu)"},
{"code":"M85.3-","desc":"Ostitis condensans"},
{"code":"M85.30","desc":"Ostitis condensans na viacerých miestach"},
{"code":"M85.31","desc":"Ostitis condensans v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M85.32","desc":"Ostitis condensans nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M85.33","desc":"Ostitis condensans predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M85.34","desc":"Ostitis condensans ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M85.35","desc":"Ostitis condensans panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M85.36","desc":"Ostitis condensans predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M85.37","desc":"Ostitis condensans členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M85.38","desc":"Ostitis condensans na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M85.39","desc":"Ostitis condensans, miesto bližšie neurčené"},
{"code":"M85.4-","desc":"Solitárna kostná cysta"},
{"code":"M85.40","desc":"Solitárna kostná cysta na viacerých miestach"},
{"code":"M85.41","desc":"Solitárna kostná cysta v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M85.42","desc":"Solitárna kostná cysta nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M85.43","desc":"Solitárna kostná cysta predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M85.44","desc":"Solitárna kostná cysta ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M85.45","desc":"Solitárna kostná cysta panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M85.46","desc":"Solitárna kostná cysta predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M85.47","desc":"Solitárna kostná cysta členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M85.48","desc":"Solitárna kostná cysta na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M85.49","desc":"Solitárna kostná cysta, miesto bližšie neurčené"},
{"code":"M85.5-","desc":"Aneuryzmová kostná cysta"},
{"code":"M85.50","desc":"Aneuryzmová kostná cysta na viacerých miestach"},
{"code":"M85.51","desc":"Aneuryzmová kostná cysta v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M85.52","desc":"Aneuryzmová kostná cysta nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M85.53","desc":"Aneuryzmová kostná cysta predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M85.54","desc":"Aneuryzmová kostná cysta ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M85.55","desc":"Aneuryzmová kostná cysta panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M85.56","desc":"Aneuryzmová kostná cysta predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M85.57","desc":"Aneuryzmová kostná cysta členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M85.58","desc":"Aneuryzmová kostná cysta na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M85.59","desc":"Aneuryzmová kostná cysta, miesto bližšie neurčené"},
{"code":"M85.6-","desc":"Iná kostná cysta"},
{"code":"M85.60","desc":"Iná kostná cysta na viacerých miestach"},
{"code":"M85.61","desc":"Iná kostná cysta v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M85.62","desc":"Iná kostná cysta nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M85.63","desc":"Iná kostná cysta predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M85.64","desc":"Iná kostná cysta ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M85.65","desc":"Iná kostná cysta panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M85.66","desc":"Iná kostná cysta predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M85.67","desc":"Iná kostná cysta členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M85.68","desc":"Iná kostná cysta na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M85.69","desc":"Iná kostná cysta, miesto bližšie neurčené"},
{"code":"M85.8-","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené"},
{"code":"M85.80","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, na viacerých miestach"},
{"code":"M85.81","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M85.82","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M85.83","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M85.84","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M85.85","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M85.86","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M85.87","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M85.88","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M85.89","desc":"Iné poruchy hustoty a štruktúry kosti, bližšie určené, miesto bližšie neurčené"},
{"code":"M85.9-","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená"},
{"code":"M85.90","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, na viacerých miestach"},
{"code":"M85.91","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M85.92","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M85.93","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M85.94","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M85.95","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M85.96","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M85.97","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M85.98","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M85.99","desc":"Porucha hustoty a štruktúry kostí, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M86-M90","desc":"Iné osteopatie"},
{"code":"M86.-","desc":"Osteomyelitída"},
{"code":"M86.0-","desc":"Akútna hematogénna osteomyelitída"},
{"code":"M86.00","desc":"Akútna hematogénna osteomyelitída na viacerých miestach"},
{"code":"M86.01","desc":"Akútna hematogénna osteomyelitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M86.02","desc":"Akútna hematogénna osteomyelitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M86.03","desc":"Akútna hematogénna osteomyelitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M86.04","desc":"Akútna hematogénna osteomyelitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M86.05","desc":"Akútna hematogénna osteomyelitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M86.06","desc":"Akútna hematogénna osteomyelitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M86.07","desc":"Akútna hematogénna osteomyelitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M86.08","desc":"Akútna hematogénna osteomyelitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M86.09","desc":"Akútna hematogénna osteomyelitída, miesto bližšie neurčené"},
{"code":"M86.1-","desc":"Iná akútna osteomyelitída"},
{"code":"M86.10","desc":"Iná akútna osteomyelitída na viacerých miestach"},
{"code":"M86.11","desc":"Iná akútna osteomyelitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M86.12","desc":"Iná akútna osteomyelitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M86.13","desc":"Iná akútna osteomyelitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M86.14","desc":"Iná akútna osteomyelitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M86.15","desc":"Iná akútna osteomyelitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M86.16","desc":"Iná akútna osteomyelitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M86.17","desc":"Iná akútna osteomyelitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M86.18","desc":"Iná akútna osteomyelitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M86.19","desc":"Iná akútna osteomyelitída, miesto bližšie neurčené"},
{"code":"M86.2-","desc":"Subakútna osteomyelitída"},
{"code":"M86.20","desc":"Subakútna osteomyelitída na viacerých miestach"},
{"code":"M86.21","desc":"Subakútna osteomyelitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M86.22","desc":"Subakútna osteomyelitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M86.23","desc":"Subakútna osteomyelitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M86.24","desc":"Subakútna osteomyelitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M86.25","desc":"Subakútna osteomyelitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M86.26","desc":"Subakútna osteomyelitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M86.27","desc":"Subakútna osteomyelitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M86.28","desc":"Subakútna osteomyelitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M86.29","desc":"Subakútna osteomyelitída, miesto bližšie neurčené"},
{"code":"M86.3-","desc":"Chronická multifokálna osteomyelitída"},
{"code":"M86.30","desc":"Chronická multifokálna osteomyelitída na viacerých miestach"},
{"code":"M86.31","desc":"Chronická multifokálna osteomyelitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M86.32","desc":"Chronická multifokálna osteomyelitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M86.33","desc":"Chronická multifokálna osteomyelitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M86.34","desc":"Chronická multifokálna osteomyelitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M86.35","desc":"Chronická multifokálna osteomyelitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M86.36","desc":"Chronická multifokálna osteomyelitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M86.37","desc":"Chronická multifokálna osteomyelitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M86.38","desc":"Chronická multifokálna osteomyelitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M86.39","desc":"Chronická multifokálna osteomyelitída, miesto bližšie neurčené"},
{"code":"M86.4-","desc":"Chronická osteomyelitída s fistulou"},
{"code":"M86.40","desc":"Chronická osteomyelitída s fistulou na viacerých miestach"},
{"code":"M86.41","desc":"Chronická osteomyelitída s fistulou v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M86.42","desc":"Chronická osteomyelitída s fistulou nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M86.43","desc":"Chronická osteomyelitída s fistulou predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M86.44","desc":"Chronická osteomyelitída s fistulou ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M86.45","desc":"Chronická osteomyelitída s fistulou panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M86.46","desc":"Chronická osteomyelitída s fistulou predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M86.47","desc":"Chronická osteomyelitída s fistulou členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M86.48","desc":"Chronická osteomyelitída s fistulou na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M86.49","desc":"Chronická osteomyelitída s fistulou, miesto bližšie neurčené"},
{"code":"M86.5-","desc":"Iná chronická hematogénna osteomyelitída"},
{"code":"M86.50","desc":"Iná chronická hematogénna osteomyelitída na viacerých miestach"},
{"code":"M86.51","desc":"Iná chronická hematogénna osteomyelitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M86.52","desc":"Iná chronická hematogénna osteomyelitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M86.53","desc":"Iná chronická hematogénna osteomyelitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M86.54","desc":"Iná chronická hematogénna osteomyelitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M86.55","desc":"Iná chronická hematogénna osteomyelitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M86.56","desc":"Iná chronická hematogénna osteomyelitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M86.57","desc":"Iná chronická hematogénna osteomyelitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M86.58","desc":"Iná chronická hematogénna osteomyelitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M86.59","desc":"Iná chronická hematogénna osteomyelitída, miesto bližšie neurčené"},
{"code":"M86.6-","desc":"Iná chronická osteomyelitída"},
{"code":"M86.60","desc":"Iná chronická osteomyelitída na viacerých miestach"},
{"code":"M86.61","desc":"Iná chronická osteomyelitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M86.62","desc":"Iná chronická osteomyelitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M86.63","desc":"Iná chronická osteomyelitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M86.64","desc":"Iná chronická osteomyelitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M86.65","desc":"Iná chronická osteomyelitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M86.66","desc":"Iná chronická osteomyelitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M86.67","desc":"Iná chronická osteomyelitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M86.68","desc":"Iná chronická osteomyelitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M86.69","desc":"Iná chronická osteomyelitída, miesto bližšie neurčené"},
{"code":"M86.8-","desc":"Iná osteomyelitída"},
{"code":"M86.80","desc":"Iná osteomyelitída na viacerých miestach"},
{"code":"M86.81","desc":"Iná osteomyelitída v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M86.82","desc":"Iná osteomyelitída nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M86.83","desc":"Iná osteomyelitída predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M86.84","desc":"Iná osteomyelitída ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M86.85","desc":"Iná osteomyelitída panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M86.86","desc":"Iná osteomyelitída predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M86.87","desc":"Iná osteomyelitída členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M86.88","desc":"Iná osteomyelitída na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M86.89","desc":"Iná osteomyelitída, miesto bližšie neurčené"},
{"code":"M86.9-","desc":"Osteomyelitída, bližšie neurčená"},
{"code":"M86.90","desc":"Osteomyelitída, bližšie neurčená, na viacerých miestach"},
{"code":"M86.91","desc":"Osteomyelitída, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M86.92","desc":"Osteomyelitída, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M86.93","desc":"Osteomyelitída, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M86.94","desc":"Osteomyelitída, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M86.95","desc":"Osteomyelitída, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M86.96","desc":"Osteomyelitída, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M86.97","desc":"Osteomyelitída, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M86.98","desc":"Osteomyelitída, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M86.99","desc":"Osteomyelitída, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M87.-","desc":"Osteonekróza"},
{"code":"M87.0-","desc":"Idiopatická aseptická nekróza kosti"},
{"code":"M87.00","desc":"Idiopatická aseptická nekróza kosti na viacerých miestach"},
{"code":"M87.01","desc":"Idiopatická aseptická nekróza kosti v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M87.02","desc":"Idiopatická aseptická nekróza kosti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M87.03","desc":"Idiopatická aseptická nekróza kosti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M87.04","desc":"Idiopatická aseptická nekróza kosti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M87.05","desc":"Idiopatická aseptická nekróza kosti panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M87.06","desc":"Idiopatická aseptická nekróza kosti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M87.07","desc":"Idiopatická aseptická nekróza kosti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M87.08","desc":"Idiopatická aseptická nekróza kosti na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M87.09","desc":"Idiopatická aseptická nekróza kosti, miesto bližšie neurčené"},
{"code":"M87.1-","desc":"Osteonekróza zapríčinená liekmi"},
{"code":"M87.10","desc":"Osteonekróza zapríčinená liekmi, na viacerých miestach"},
{"code":"M87.11","desc":"Osteonekróza zapríčinená liekmi, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M87.12","desc":"Osteonekróza zapríčinená liekmi, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M87.13","desc":"Osteonekróza zapríčinená liekmi, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M87.14","desc":"Osteonekróza zapríčinená liekmi, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M87.15","desc":"Osteonekróza zapríčinená liekmi, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M87.16","desc":"Osteonekróza zapríčinená liekmi, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M87.17","desc":"Osteonekróza zapríčinená liekmi, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M87.18","desc":"Osteonekróza zapríčinená liekmi, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M87.19","desc":"Osteonekróza zapríčinená liekmi:, miesto bližšie neurčené"},
{"code":"M87.2-","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom"},
{"code":"M87.20","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, na viacerých miestach"},
{"code":"M87.21","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M87.22","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M87.23","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M87.24","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M87.25","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M87.26","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M87.27","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M87.28","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M87.29","desc":"Osteonekróza zapríčinená predchádzajúcim úrazom, miesto bližšie neurčené"},
{"code":"M87.3-","desc":"Iná sekundárna osteonekróza"},
{"code":"M87.30","desc":"Iná sekundárna osteonekróza na viacerých miestach"},
{"code":"M87.31","desc":"Iná sekundárna osteonekróza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M87.32","desc":"Iná sekundárna osteonekróza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M87.33","desc":"Iná sekundárna osteonekróza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M87.34","desc":"Iná sekundárna osteonekróza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M87.35","desc":"Iná sekundárna osteonekróza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M87.36","desc":"Iná sekundárna osteonekróza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M87.37","desc":"Iná sekundárna osteonekróza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M87.38","desc":"Iná sekundárna osteonekróza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M87.39","desc":"Iná sekundárna osteonekróza, miesto bližšie neurčené"},
{"code":"M87.8-","desc":"Iná osteonekróza"},
{"code":"M87.80","desc":"Iná osteonekróza na viacerých miestach"},
{"code":"M87.81","desc":"Iná osteonekróza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M87.82","desc":"Iná osteonekróza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M87.83","desc":"Iná osteonekróza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M87.84","desc":"Iná osteonekróza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M87.85","desc":"Iná osteonekróza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M87.86","desc":"Iná osteonekróza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M87.87","desc":"Iná osteonekróza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M87.88","desc":"Iná osteonekróza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M87.89","desc":"Iná osteonekróza, miesto bližšie neurčené"},
{"code":"M87.9-","desc":"Osteonekróza, bližšie neurčená:"},
{"code":"M87.90","desc":"Osteonekróza, bližšie neurčená, na viacerých miestach"},
{"code":"M87.91","desc":"Osteonekróza, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M87.92","desc":"Osteonekróza, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M87.93","desc":"Osteonekróza, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M87.94","desc":"Osteonekróza, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M87.95","desc":"Osteonekróza, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M87.96","desc":"Osteonekróza, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M87.97","desc":"Osteonekróza, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M87.98","desc":"Osteonekróza, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M87.99","desc":"Osteonekróza, bližšie neurčená:, miesto bližšie neurčené"},
{"code":"M88.-","desc":"Deformujúca osteodystrofia [Pagetova choroba]"},
{"code":"M88.0","desc":"Deformujúca osteodystrofia [Pagetova choroba] lebky"},
{"code":"M88.8-","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí"},
{"code":"M88.80","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí, na viacerých miestach"},
{"code":"M88.81","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M88.82","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M88.83","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M88.84","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M88.85","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M88.86","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M88.87","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M88.88","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M88.89","desc":"Deformujúca osteodystrofia [Pagetova choroba] iných kostí:, miesto bližšie neurčené"},
{"code":"M88.9-","desc":"Deformujúca osteodystrofia, bližšie neurčená"},
{"code":"M88.90","desc":"Deformujúca osteodystrofia, bližšie neurčená, na viacerých miestach"},
{"code":"M88.91","desc":"Deformujúca osteodystrofia, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M88.92","desc":"Deformujúca osteodystrofia, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M88.93","desc":"Deformujúca osteodystrofia, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M88.94","desc":"Deformujúca osteodystrofia, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M88.95","desc":"Deformujúca osteodystrofia, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M88.96","desc":"Deformujúca osteodystrofia, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M88.97","desc":"Deformujúca osteodystrofia, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M88.98","desc":"Deformujúca osteodystrofia, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M88.99","desc":"Deformujúca osteodystrofia, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M89.-","desc":"Iné choroby kostí"},
{"code":"M89.0-","desc":"Algoneurodystrofia"},
{"code":"M89.00","desc":"Algoneurodystrofia na viacerých miestach"},
{"code":"M89.01","desc":"Algoneurodystrofia v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M89.02","desc":"Algoneurodystrofia nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M89.03","desc":"Algoneurodystrofia predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M89.04","desc":"Algoneurodystrofia ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M89.05","desc":"Algoneurodystrofia panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M89.06","desc":"Algoneurodystrofia predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M89.07","desc":"Algoneurodystrofia členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M89.08","desc":"Algoneurodystrofia na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M89.09","desc":"Algoneurodystrofia, miesto bližšie neurčené"},
{"code":"M89.1-","desc":"Zastavenie rastu v epifýze"},
{"code":"M89.10","desc":"Zastavenie rastu v epifýze, na viacerých miestach"},
{"code":"M89.11","desc":"Zastavenie rastu v epifýze, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M89.12","desc":"Zastavenie rastu v epifýze, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M89.13","desc":"Zastavenie rastu v epifýze, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M89.14","desc":"Zastavenie rastu v epifýze, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M89.15","desc":"Zastavenie rastu v epifýze, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M89.16","desc":"Zastavenie rastu v epifýze, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M89.17","desc":"Zastavenie rastu v epifýze, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M89.18","desc":"Zastavenie rastu v epifýze, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M89.19","desc":"Zastavenie rastu v epifýze, miesto bližšie neurčené"},
{"code":"M89.2-","desc":"Iná porucha vývinu a rastu kosti"},
{"code":"M89.20","desc":"Iné poruchy vývinu a rastu kosti na viacerých miestach"},
{"code":"M89.21","desc":"Iné poruchy vývinu a rastu kosti v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M89.22","desc":"Iné poruchy vývinu a rastu kosti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M89.23","desc":"Iné poruchy vývinu a rastu kosti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M89.24","desc":"Iné poruchy vývinu a rastu kosti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M89.25","desc":"Iné poruchy vývinu a rastu kosti panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M89.26","desc":"Iné poruchy vývinu a rastu kosti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M89.27","desc":"Iné poruchy vývinu a rastu kosti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M89.28","desc":"Iné poruchy vývinu a rastu kosti na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M89.29","desc":"Iné poruchy vývinu a rastu kosti, miesto bližšie neurčené"},
{"code":"M89.3-","desc":"Hypertrofia kosti"},
{"code":"M89.30","desc":"Hypertrofia kosti na viacerých miestach"},
{"code":"M89.31","desc":"Hypertrofia kosti v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M89.32","desc":"Hypertrofia kosti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M89.33","desc":"Hypertrofia kosti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M89.34","desc":"Hypertrofia kosti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M89.35","desc":"Hypertrofia kosti panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M89.36","desc":"Hypertrofia kosti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M89.37","desc":"Hypertrofia kosti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M89.38","desc":"Hypertrofia kosti na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M89.39","desc":"Hypertrofia kosti, miesto bližšie neurčené"},
{"code":"M89.4-","desc":"Iná hypertrofická osteoartropatia"},
{"code":"M89.40","desc":"Iná hypertrofická osteoartropatia na viacerých miestach"},
{"code":"M89.41","desc":"Iná hypertrofická osteoartropatia v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M89.42","desc":"Iná hypertrofická osteoartropatia nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M89.43","desc":"Iná hypertrofická osteoartropatia predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M89.44","desc":"Iná hypertrofická osteoartropatia ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M89.45","desc":"Iná hypertrofická osteoartropatia panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M89.46","desc":"Iná hypertrofická osteoartropatia predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M89.47","desc":"Iná hypertrofická osteoartropatia členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M89.48","desc":"Iná hypertrofická osteoartropatia na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M89.49","desc":"Iná hypertrofická osteoartropatia, miesto bližšie neurčené"},
{"code":"M89.5-","desc":"Osteolýza"},
{"code":"M89.50","desc":"Osteolýza na viacerých miestach"},
{"code":"M89.51","desc":"Osteolýza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M89.52","desc":"Osteolýza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M89.53","desc":"Osteolýza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M89.54","desc":"Osteolýza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M89.55","desc":"Osteolýza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M89.56","desc":"Osteolýza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M89.57","desc":"Osteolýza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M89.58","desc":"Osteolýza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M89.59","desc":"Osteolýza, miesto bližšie neurčené"},
{"code":"M89.6-","desc":"Osteopatia po poliomyelitíde"},
{"code":"M89.60","desc":"Osteopatia po poliomyelitíde na viacerých miestach"},
{"code":"M89.61","desc":"Osteopatia po poliomyelitíde v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M89.62","desc":"Osteopatia po poliomyelitíde v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M89.63","desc":"Osteopatia po poliomyelitíde v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M89.64","desc":"Osteopatia po poliomyelitíde v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M89.65","desc":"Osteopatia po poliomyelitíde v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M89.66","desc":"Osteopatia po poliomyelitíde v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M89.67","desc":"Osteopatia po poliomyelitíde v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M89.68","desc":"Osteopatia po poliomyelitíde na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M89.69","desc":"Osteopatia po poliomyelitíde, miesto bližšie neurčené"},
{"code":"M89.8-","desc":"Iná choroba kosti, bližšie určená"},
{"code":"M89.80","desc":"Iná choroba kosti, bližšie určená, na viacerých miestach"},
{"code":"M89.81","desc":"Iná choroba kosti, bližšie určená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M89.82","desc":"Iná choroba kosti, bližšie určená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M89.83","desc":"Iná choroba kosti, bližšie určená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M89.84","desc":"Iná choroba kosti, bližšie určená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M89.85","desc":"Iná choroba kosti, bližšie určená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M89.86","desc":"Iná choroba kosti, bližšie určená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M89.87","desc":"Iná choroba kosti, bližšie určená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M89.88","desc":"Iná choroba kosti, bližšie určená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M89.89","desc":"Iná choroba kosti, bližšie určená, miesto bližšie neurčené"},
{"code":"M89.9-","desc":"Choroba kosti, bližšie neurčená:"},
{"code":"M89.90","desc":"Choroba kosti, bližšie neurčená, na viacerých miestach"},
{"code":"M89.91","desc":"Choroba kosti, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M89.92","desc":"Choroba kosti, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M89.93","desc":"Choroba kosti, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M89.94","desc":"Choroba kosti, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M89.95","desc":"Choroba kosti, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M89.96","desc":"Choroba kosti, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M89.97","desc":"Choroba kosti, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M89.98","desc":"Choroba kosti, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M89.99","desc":"Choroba kosti, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M90.-*","desc":"Osteopatia pri chorobe zatriedenej inde"},
{"code":"M90.0-*","desc":"Tuberkulóza kosti (A18.0†)"},
{"code":"M90.00*","desc":"Tuberkulóza kosti na viacerých miestach (A18.0†)"},
{"code":"M90.01*","desc":"Tuberkulóza kosti v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (A18.0†)"},
{"code":"M90.02*","desc":"Tuberkulóza kosti nadlaktia (ramenná kosť, lakťový kĺb) (A18.0†)"},
{"code":"M90.03*","desc":"Tuberkulóza kosti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (A18.0†)"},
{"code":"M90.04*","desc":"Tuberkulóza kosti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (A18.0†)"},
{"code":"M90.05*","desc":"Tuberkulóza kosti panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (A18.0†)"},
{"code":"M90.06*","desc":"Tuberkulóza kosti predkolenia (ihlica, píšťala, kolenný kĺb) (A18.0†)"},
{"code":"M90.07*","desc":"Tuberkulóza kosti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (A18.0†)"},
{"code":"M90.08*","desc":"Tuberkulóza kosti na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (A18.0†)"},
{"code":"M90.09*","desc":"Tuberkulóza kosti, miesto bližšie neurčené (A18.0†)"},
{"code":"M90.1-*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde"},
{"code":"M90.10*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, na viacerých miestach"},
{"code":"M90.11*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M90.12*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M90.13*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M90.14*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M90.15*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M90.16*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M90.17*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M90.18*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M90.19*","desc":"Periostitída pri iných infekčných chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M90.2-*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde"},
{"code":"M90.20*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, na viacerých miestach"},
{"code":"M90.21*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M90.22*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M90.23*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M90.24*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M90.25*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M90.26*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M90.27*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M90.28*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M90.29*","desc":"Osteopatia pri iných infekčných chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M90.3-*","desc":"Osteonekróza pri kesónovej chorobe (T70.3†)"},
{"code":"M90.30*","desc":"Osteonekróza pri kesónovej chorobe, na viacerých miestach (T70.3†)"},
{"code":"M90.31*","desc":"Osteonekróza pri kesónovej chorobe, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (T70.3†)"},
{"code":"M90.32*","desc":"Osteonekróza pri kesónovej chorobe, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (T70.3†)"},
{"code":"M90.33*","desc":"Osteonekróza pri kesónovej chorobe, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (T70.3†)"},
{"code":"M90.34*","desc":"Osteonekróza pri kesónovej chorobe, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (T70.3†)"},
{"code":"M90.35*","desc":"Osteonekróza pri kesónovej chorobe, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (T70.3†)"},
{"code":"M90.36*","desc":"Osteonekróza pri kesónovej chorobe, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (T70.3†)"},
{"code":"M90.37*","desc":"Osteonekróza pri kesónovej chorobe, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (T70.3†)"},
{"code":"M90.38*","desc":"Osteonekróza pri kesónovej chorobe, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (T70.3†)"},
{"code":"M90.39*","desc":"Osteonekróza pri kesónovej chorobe, miesto bližšie neurčené (T70.3†)"},
{"code":"M90.4-*","desc":"Osteonekróza zapríčinená hemoglobinopatiou (D50-D64†)"},
{"code":"M90.40*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, na viacerých miestach (D50-D64†)"},
{"code":"M90.41*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (D50-D64†)"},
{"code":"M90.42*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (D50-D64†)"},
{"code":"M90.43*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) D50-D64†)"},
{"code":"M90.44*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami )( D50-D64†)"},
{"code":"M90.45*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (D50-D64†)"},
{"code":"M90.46*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (D50-D64†)"},
{"code":"M90.47*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (D50-D64†)"},
{"code":"M90.48*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (D50-D64†)"},
{"code":"M90.49*","desc":"Osteonekróza zapríčinená hemoglobinopatiou, miesto bližšie neurčené (D50-D64†)"},
{"code":"M90.5-*","desc":"Osteonekróza pri iných chorobách zatriedených inde"},
{"code":"M90.50*","desc":"Osteonekróza pri iných chorobách zatriedených inde, na viacerých miestach"},
{"code":"M90.51*","desc":"Osteonekróza pri iných chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M90.52*","desc":"Osteonekróza pri iných chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M90.53*","desc":"Osteonekróza pri iných chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M90.54*","desc":"Osteonekróza pri iných chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M90.55*","desc":"Osteonekróza pri iných chorobách zatriedených inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M90.56*","desc":"Osteonekróza pri iných chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M90.57*","desc":"Osteonekróza pri iných chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M90.58*","desc":"Osteonekróza pri iných chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M90.59*","desc":"Osteonekróza pri iných chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M90.6-*","desc":"Deformujúca osteodystrofia pri nádoroch (C00-D48†)"},
{"code":"M90.60*","desc":"Deformujúca osteodystrofia pri nádoroch, na viacerých miestach (C00-D48†)"},
{"code":"M90.61*","desc":"Deformujúca osteodystrofia pri nádoroch, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (C00-D48†)"},
{"code":"M90.62*","desc":"Deformujúca osteodystrofia pri nádoroch, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (C00-D48†)"},
{"code":"M90.63*","desc":"Deformujúca osteodystrofia pri nádoroch, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (C00-D48†)"},
{"code":"M90.64*","desc":"Deformujúca osteodystrofia pri nádoroch, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (C00-D48†)"},
{"code":"M90.65*","desc":"Deformujúca osteodystrofia pri nádoroch, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (C00-D48†)"},
{"code":"M90.66*","desc":"Deformujúca osteodystrofia pri nádoroch, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (C00-D48†)"},
{"code":"M90.67*","desc":"Deformujúca osteodystrofia pri nádoroch, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (C00-D48†)"},
{"code":"M90.68*","desc":"Deformujúca osteodystrofia pri nádoroch, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (C00-D48†)"},
{"code":"M90.69*","desc":"Deformujúca osteodystrofia pri nádoroch, miesto bližšie neurčené (C00-D48†)"},
{"code":"M90.7-*","desc":"Zlomenina kosti pri nádore (C00-D48†)"},
{"code":"M90.70*","desc":"Zlomenina kosti pri nádore, na viacerých miestach (C00-D48†)"},
{"code":"M90.71*","desc":"Zlomenina kosti pri nádore, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb) (C00-D48†)"},
{"code":"M90.72*","desc":"Zlomenina kosti pri nádore, v oblasti nadlaktia (ramenná kosť, lakťový kĺb) (C00-D48†)"},
{"code":"M90.73*","desc":"Zlomenina kosti pri nádore, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb) (C00-D48†)"},
{"code":"M90.74*","desc":"Zlomenina kosti pri nádore, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami) (C00-D48†)"},
{"code":"M90.75*","desc":"Zlomenina kosti pri nádore, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb) (C00-D48†)"},
{"code":"M90.76*","desc":"Zlomenina kosti pri nádore, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb) (C00-D48†)"},
{"code":"M90.77*","desc":"Zlomenina kosti pri nádore, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy) (C00-D48†)"},
{"code":"M90.78*","desc":"Zlomenina kosti pri nádore, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica) (C00-D48†)"},
{"code":"M90.79*","desc":"Zlomenina kosti pri nádore, miesto bližšie neurčené (C00-D48†)"},
{"code":"M90.8-*","desc":"Osteopatia pri iných chorobách zatriedených inde"},
{"code":"M90.80*","desc":"Osteopatia pri iných chorobách zatriedených inde, na viacerých miestach"},
{"code":"M90.81*","desc":"Osteopatia pri iných chorobách zatriedených inde, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M90.82*","desc":"Osteopatia pri iných chorobách zatriedených inde, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M90.83*","desc":"Osteopatia pri iných chorobách zatriedených inde, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M90.84*","desc":"Osteopatia pri iných chorobách zatriedených inde, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M90.85*","desc":"Osteopatia pri iných chorobách zatriedených inde, v oblasti panvy a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M90.86*","desc":"Osteopatia pri iných chorobách zatriedených inde, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M90.87*","desc":"Osteopatia pri iných chorobách zatriedených inde, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M90.88*","desc":"Osteopatia pri iných chorobách zatriedených inde, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M90.89*","desc":"Osteopatia pri iných chorobách zatriedených inde, miesto bližšie neurčené"},
{"code":"M91-M94","desc":"Chondropatie"},
{"code":"M91.-","desc":"Juvenilná osteochondróza bedra a panvy"},
{"code":"M91.0","desc":"Juvenilná osteochondróza panvy"},
{"code":"M91.1","desc":"Juvenilná osteochondróza hlavice stehnovej kosti [Leggova-Calvéova-Perthesova choroba]"},
{"code":"M91.2","desc":"Coxa plana"},
{"code":"M91.3","desc":"Pseudokoxalgia"},
{"code":"M91.8","desc":"Iná juvenilná osteochondróza bedra a panvy"},
{"code":"M91.9","desc":"Juvenilná osteochondróza bedra a panvy, bližšie neurčená"},
{"code":"M92.-","desc":"Iná juvenilná osteochondróza"},
{"code":"M92.0","desc":"Juvenilná osteochondróza ramennej kosti"},
{"code":"M92.1","desc":"Juvenilná osteochondróza vretennej a lakťovej kosti"},
{"code":"M92.2","desc":"Juvenilná osteochondróza ruky"},
{"code":"M92.3","desc":"Iná juvenilná osteochondróza hornej končatiny"},
{"code":"M92.4","desc":"Juvenilná osteochondróza pately"},
{"code":"M92.5","desc":"Juvenilná osteochondróza píštaly a ihlice"},
{"code":"M92.6","desc":"Juvenilná osteochondróza priehlavku"},
{"code":"M92.7","desc":"Juvenilná osteochondróza predpriehlavku (metatarzu)"},
{"code":"M92.8","desc":"Iná juvenilná osteochondróza, bližšie určená"},
{"code":"M92.9","desc":"Juvenilná osteochondróza, bližšie neurčená"},
{"code":"M93.-","desc":"Iné osteochondropatie"},
{"code":"M93.0","desc":"Epiphyseolysis capitis femoris (neúrazová)"},
{"code":"M93.1","desc":"Kienböckova choroba dospelých"},
{"code":"M93.2","desc":"Osteochondritis dissecans"},
{"code":"M93.8","desc":"Iná osteochondropatia, bližšie určená"},
{"code":"M93.9","desc":"Osteochondropatia, bližšie neurčená"},
{"code":"M94.-","desc":"Iná choroba chrupky"},
{"code":"M94.0","desc":"Syndróm chondrokostálneho spojenia [Tietzeho]"},
{"code":"M94.1","desc":"Recidivujúca polychondritída"},
{"code":"M94.2-","desc":"Chondromalácia"},
{"code":"M94.20","desc":"Chondromalácia na viacerých miestach"},
{"code":"M94.21","desc":"Chondromalácia v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M94.22","desc":"Chondromalácia nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M94.23","desc":"Chondromalácia predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M94.24","desc":"Chondromalácia ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M94.25","desc":"Chondromalácia panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M94.26","desc":"Chondromalácia predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M94.27","desc":"Chondromalácia členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M94.28","desc":"Chondromalácia na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M94.29","desc":"Chondromalácia, miesto bližšie neurčené"},
{"code":"M94.3-","desc":"Chondrolýza"},
{"code":"M94.30","desc":"Chondrolýza na viacerých miestach"},
{"code":"M94.31","desc":"Chondrolýza v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M94.32","desc":"Chondrolýza nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M94.33","desc":"Chondrolýza predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M94.34","desc":"Chondrolýza ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M94.35","desc":"Chondrolýza panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M94.36","desc":"Chondrolýza predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M94.37","desc":"Chondrolýza členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M94.38","desc":"Chondrolýza na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M94.39","desc":"Chondrolýza, miesto bližšie neurčené"},
{"code":"M94.8-","desc":"Iné choroby chrupky, bližšie určené"},
{"code":"M94.80","desc":"Iné choroby chrupky, bližšie určené, na viacerých miestach"},
{"code":"M94.81","desc":"Iné choroby chrupky, bližšie určené, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M94.82","desc":"Iné choroby chrupky, bližšie určené, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M94.83","desc":"Iné choroby chrupky, bližšie určené, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M94.84","desc":"Iné choroby chrupky, bližšie určené, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M94.85","desc":"Iné choroby chrupky, bližšie určené, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M94.86","desc":"Iné choroby chrupky, bližšie určené, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M94.87","desc":"Iné choroby chrupky, bližšie určené, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M94.88","desc":"Iné choroby chrupky, bližšie určené, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M94.89","desc":"Iné choroby chrupky, bližšie určené, miesto bližšie neurčené"},
{"code":"M94.9-","desc":"Choroba chrupky, bližšie neurčená"},
{"code":"M94.90","desc":"Choroba chrupky, bližšie neurčená, na viacerých miestach"},
{"code":"M94.91","desc":"Choroba chrupky, bližšie neurčená, v oblasti pleca (kľúčna kosť, lopatka, akromioklavikulárny, ramenný, sternoklavikulárny kĺb)"},
{"code":"M94.92","desc":"Choroba chrupky, bližšie neurčená, v oblasti nadlaktia (ramenná kosť, lakťový kĺb)"},
{"code":"M94.93","desc":"Choroba chrupky, bližšie neurčená, v oblasti predlaktia (lakťová kosť, vretenná kosť, zápästný kĺb)"},
{"code":"M94.94","desc":"Choroba chrupky, bližšie neurčená, v oblasti ruky (zápästie, záprstie, prsty, kĺby medzi týmito kosťami)"},
{"code":"M94.95","desc":"Choroba chrupky, bližšie neurčená, v panvovej oblasti a stehna (panva, stehnová kosť, zadok, bedro, bedrový kĺb, sakroiliakálny kĺb)"},
{"code":"M94.96","desc":"Choroba chrupky, bližšie neurčená, v oblasti predkolenia (ihlica, píšťala, kolenný kĺb)"},
{"code":"M94.97","desc":"Choroba chrupky, bližšie neurčená, v oblasti členka a nohy (predpriehlavok, priehlavok, prsty nohy, členkový kĺb, iné kĺby nohy)"},
{"code":"M94.98","desc":"Choroba chrupky, bližšie neurčená, na inom mieste (hlava, krk, rebrá, lebka, trup, chrbtica)"},
{"code":"M94.99","desc":"Choroba chrupky, bližšie neurčená, miesto bližšie neurčené"},
{"code":"M95-M99","desc":"Iné choroby svalovej a kostrovej sústavy a spojivového tkaniva"},
{"code":"M95.-","desc":"Iná získaná deformita svalovej a kostrovej sústavy a spojivového tkaniva"},
{"code":"M95.0","desc":"Získaná deformita nosa"},
{"code":"M95.1","desc":"Karfiolovité ucho"},
{"code":"M95.2","desc":"Iná získaná deformita hlavy"},
{"code":"M95.3","desc":"Získaná deformita krku"},
{"code":"M95.4","desc":"Získaná deformita hrudníka a rebra"},
{"code":"M95.5","desc":"Získaná deformita panvy"},
{"code":"M95.8","desc":"Iná získaná deformita svalovej a kostrovej sústavy, bližšie určená"},
{"code":"M95.9","desc":"Získaná deformita svalovej a kostrovej sústavy, bližšie neurčená"},
{"code":"M96.-","desc":"Porucha svalovej a kostrovej sústavy po lekárskom výkone, nezatriedená inde"},
{"code":"M96.0","desc":"Pseudoartróza po fúzii alebo artrodéze"},
{"code":"M96.1","desc":"Postlaminektomický syndróm, nezatriedený inde"},
{"code":"M96.2","desc":"Kyfóza po ožiarení"},
{"code":"M96.3","desc":"Kyfóza po laminektómii"},
{"code":"M96.4","desc":"Pooperačná lordóza"},
{"code":"M96.5","desc":"Skolióza po ožiarení"},
{"code":"M96.6","desc":"Zlomenina kosti po vložení ortopedického implantátu, kĺbovej protézy alebo kostnej platničky"},
{"code":"M96.8-","desc":"Iné choroby svalovej a kostrovej sústavy po lekárskom výkone"},
{"code":"M96.80","desc":"Elektívne ponechané otvorené sternum po chirurgickom výkone na hrudníku"},
{"code":"M96.81","desc":"Nestabilný hrudný kôš po chirurgickom výkone na hrudníku"},
{"code":"M96.88","desc":"Iná choroba sústavy svalov a kostí po lekárskom výkone"},
{"code":"M96.9","desc":"Choroba svalovej a kostrovej sústavy po lekárskom výkone, bližšie neurčená"},
{"code":"M99.-","desc":"Biomechanické poškodenie, nezatriedené inde"},
{"code":"M99.0-","desc":"Segmentová a somatická dysfunkcia"},
{"code":"M99.00","desc":"Segmentová a somatická dysfunkcia oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.01","desc":"Segmentová a somatická dysfunkcia krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.02","desc":"Segmentová a somatická dysfunkcia hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.03","desc":"Segmentová a somatická dysfunkcia v driekovej (driekovokrížovej) oblasti"},
{"code":"M99.04","desc":"Segmentová a somatická dysfunkcia krížovej (krížovokostrčovej, krížovobedrovej) oblasti"},
{"code":"M99.05","desc":"Segmentová a somatická dysfunkcia panvovej oblasti (bedrovej alebo lonovej oblasti)"},
{"code":"M99.06","desc":"Segmentová a somatická dysfunkcia dolnej končatiny"},
{"code":"M99.07","desc":"Segmentová a somatická dysfunkcia hornej končatiny (akromioklavikulárna, sternoklavikulárna)"},
{"code":"M99.08","desc":"Segmentová a somatická dysfunkcia hrudného koša (kostochondrálna, kostovertebrálna, sternochondrálna)"},
{"code":"M99.09","desc":"Segmentová a somatická dysfunkcia, v oblasti brucha a na iných miestach"},
{"code":"M99.1-","desc":"Subluxačný komplex stavcov"},
{"code":"M99.10","desc":"Subluxačný komplex stavcov oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.11","desc":"Subluxačný komplex stavcov krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.12","desc":"Subluxačný komplex stavcov hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.13","desc":"Subluxačný komplex stavcov driekovej (driekovokrížovej) oblasti"},
{"code":"M99.14","desc":"Subluxačný komplex stavcov krížovej (krížovokostrčovej, krížovobedrovej) oblasti"},
{"code":"M99.19","desc":"Subluxačný komplex stavcov, v oblasti brucha a na iných miestach"},
{"code":"M99.2-","desc":"Subluxačná stenóza spinálneho kanála"},
{"code":"M99.20","desc":"Subluxačná stenóza spinálneho kanála oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.21","desc":"Subluxačná stenóza spinálneho kanála krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.22","desc":"Subluxačná stenóza spinálneho kanála hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.23","desc":"Subluxačná stenóza spinálneho kanála driekovej (driekovokrížovej) oblasti"},
{"code":"M99.24","desc":"Subluxačná stenóza spinálneho kanála krížovej (krížovokostrčovej, krížovobedrovej) oblasti"},
{"code":"M99.29","desc":"Subluxačná stenóza spinálneho kanála, v oblasti brucha a na iných miestach"},
{"code":"M99.3-","desc":"Kostná stenóza spinálneho kanála"},
{"code":"M99.30","desc":"Kostná stenóza spinálneho kanála oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.31","desc":"Kostná stenóza spinálneho kanála krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.32","desc":"Kostná stenóza spinálneho kanála hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.33","desc":"Kostná stenóza spinálneho kanála driekovej (driekovokrížovej) oblasti"},
{"code":"M99.34","desc":"Kostná stenóza spinálneho kanála krížovej (krížovokostrčovej, krížovobedrovej) oblasti"},
{"code":"M99.39","desc":"Kostná stenóza spinálneho kanála, v oblasti brucha a na iných miestach"},
{"code":"M99.4-","desc":"Stenóza spinálneho kanála spojivovým tkanivom"},
{"code":"M99.40","desc":"Stenóza spinálneho kanála spojivovým tkanivom v oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.41","desc":"Stenóza spinálneho kanála spojivovým tkanivom v krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.42","desc":"Stenóza spinálneho kanála spojivovým tkanivom v hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.43","desc":"Stenóza spinálneho kanála spojivovým tkanivom v driekovej (driekovokrížovej) oblasti"},
{"code":"M99.44","desc":"Stenóza spinálneho kanála spojivovým tkanivom v krížovej (krížovokostrčovej, krížovobedrovej) oblasti"},
{"code":"M99.49","desc":"Stenóza spinálneho kanála spojivovým tkanivom, v oblasti brucha a na iných miestach"},
{"code":"M99.5-","desc":"Stenóza spinálneho kanála medzistavcovou platničkou"},
{"code":"M99.50","desc":"Stenóza spinálneho kanála medzistavcovou platničkou v oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.51","desc":"Stenóza spinálneho kanála medzistavcovou platničkou v krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.52","desc":"Stenóza spinálneho kanála medzistavcovou platničkou v hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.53","desc":"Stenóza spinálneho kanála medzistavcovou platničkou v driekovej (driekovokrížovej) oblasti"},
{"code":"M99.59","desc":"Stenóza spinálneho kanála medzistavcovou platničkou, v oblasti brucha a na iných miestach"},
{"code":"M99.6-","desc":"Kostná alebo subluxačná stenóza medzistavcových otvorov"},
{"code":"M99.60","desc":"Kostná alebo subluxačná stenóza medzistavcových otvorov v oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.61","desc":"Kostná alebo subluxačná stenóza medzistavcových otvorov v krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.62","desc":"Kostná alebo subluxačná stenóza medzistavcových otvorov v hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.63","desc":"Kostná alebo subluxačná stenóza medzistavcových otvorov v driekovej (driekovokrížovej) oblasti"},
{"code":"M99.64","desc":"Kostná alebo subluxačná stenóza medzistavcových otvorov v krížovej (krížovokostrčovej, krížovobedrovej) oblasti"},
{"code":"M99.69","desc":"Kostná alebo subluxačná stenóza medzistavcových otvorov, v oblasti brucha a na iných miestach"},
{"code":"M99.7-","desc":"Stenóza medzistavcových otvorov spojivovým tkanivom alebo medzistavcovou platničkou"},
{"code":"M99.70","desc":"Stenóza medzistavcových otvorov spojivovým tkanivom alebo medzistavcovou platničkou v oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.71","desc":"Stenóza medzistavcových otvorov spojivovým tkanivom alebo medzistavcovou platničkou v krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.72","desc":"Stenóza medzistavcových otvorov spojivovým tkanivom alebo medzistavcovou platničkou v hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.73","desc":"Stenóza medzistavcových otvorov spojivovým tkanivom alebo medzistavcovou platničkou v driekovej (driekovokrížovej) oblasti"},
{"code":"M99.74","desc":"Stenóza medzistavcových otvorov spojivovým tkanivom alebo medzistavcovou platničkou v krížovej (krížovokostrčovej, krížovobedrovej) oblasti"},
{"code":"M99.79","desc":"Stenóza medzistavcových otvorov spojivovým tkanivom alebo medzistavcovou platničkou, v oblasti brucha a na iných miestach"},
{"code":"M99.8-","desc":"Iná biomechanická porucha funkcie"},
{"code":"M99.80","desc":"Iná biomechanická porucha funkcie v oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.81","desc":"Iná biomechanická porucha funkcie v krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.82","desc":"Iná biomechanická porucha funkcie v hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.83","desc":"Iná biomechanická porucha funkcie v driekovej (driekovokrížovej) oblasti"},
{"code":"M99.84","desc":"Iná biomechanická porucha funkcie v krížovej (krížovokostrčovej, krížovobedrovej) oblasti"},
{"code":"M99.85","desc":"Iná biomechanická porucha funkcie v panvovej oblasti (bedrovej alebo lonovej oblasti)"},
{"code":"M99.86","desc":"Iná biomechanická porucha funkcie v oblasti dolnej končatiny"},
{"code":"M99.87","desc":"Iná biomechanická porucha funkcie v oblasti hornej končatiny (akromioklavikulárna, sternoklavikulárna)"},
{"code":"M99.88","desc":"Iná biomechanická porucha funkcie v oblasti hrudného koša (kostochondrálna, kostovertebrálna, sternochondrálna)"},
{"code":"M99.89","desc":"Iná biomechanická porucha funkcie, v oblasti brucha a na iných miestach"},
{"code":"M99.9-","desc":"Biomechanická porucha funkcie, bližšie neurčená"},
{"code":"M99.90","desc":"Biomechanická porucha funkcie, bližšie neurčená, v oblasti hlavy (okcipitocervikálnej oblasti)"},
{"code":"M99.91","desc":"Biomechanická porucha funkcie, bližšie neurčená: v krčnej (krčnohrudníkovej) oblasti"},
{"code":"M99.92","desc":"Biomechanická porucha funkcie, bližšie neurčená: v hrudníkovej (hrudníkovodriekovej) oblasti"},
{"code":"M99.93","desc":"Biomechanická porucha funkcie, bližšie neurčená: v driekovej (driekovokrížovej) oblasti"},
{"code":"M99.94","desc":"Biomechanická porucha funkcie, bližšie neurčená: v krížovej (krížovokostrčovej, krížovobedrovej) oblasti"},
{"code":"M99.95","desc":"Biomechanická porucha funkcie, bližšie neurčená: v panvovej oblasti (bedrovej alebo lonovej oblasti)"},
{"code":"M99.96","desc":"Biomechanická porucha funkcie, bližšie neurčená, v oblasti dolnej končatiny"},
{"code":"M99.97","desc":"Biomechanická porucha funkcie, bližšie neurčená, v oblasti hornej končatiny (akromioklavikulárna, sternoklavikulárna)"},
{"code":"M99.98","desc":"Biomechanická porucha funkcie, bližšie neurčená, v oblasti hrudného koša (kostochondrálna, kostovertebrálna, sternochondrálna)"},
{"code":"M99.99","desc":"Biomechanická porucha funkcie, bližšie neurčená, v oblasti brucha a na iných miestach"},
{"code":"N00-N99","desc":"XIV. kapitola - Choroby močovopohlavnej sústavy (N00-N99)"},
{"code":"N00-N08","desc":"Choroby glomerulov"},
{"code":"N00.-","desc":"Akútny nefritický syndróm"},
{"code":"N00.0","desc":"Akútny nefritický syndróm: malé abnormality glomerulov"},
{"code":"N00.1","desc":"Akútny nefritický syndróm: fokálne a segmentové lézie glomerulov"},
{"code":"N00.2","desc":"Akútny nefritický syndróm: difúzna membránová glomerulonefritída"},
{"code":"N00.3","desc":"Akútny nefritický syndróm: difúzna mezangioproliferatívna glomerulonefritída"},
{"code":"N00.4","desc":"Akútny nefritický syndróm: difúzna endokapilárna proliferatívna glomerulonefritída"},
{"code":"N00.5","desc":"Akútny nefritický syndróm: difúzna mezangiokapilárna glomerulonefritída"},
{"code":"N00.6","desc":"Akútny nefritický syndróm: choroba denzných depozitov"},
{"code":"N00.7","desc":"Akútny nefritický syndróm: difúzna glomerulonefritída s výrastkami (polmesiačikmi)"},
{"code":"N00.8","desc":"Akútny nefritický syndróm: iné morfologické zmeny"},
{"code":"N00.9","desc":"Akútny nefritický syndróm: morfologická zmena bližšie neurčená"},
{"code":"N01.-","desc":"Rýchlo progredujúci nefritický syndróm"},
{"code":"N01.0","desc":"Rýchlo progredujúci nefritický syndróm: malé abnormality glomerulov"},
{"code":"N01.1","desc":"Rýchlo progredujúci nefritický syndróm: fokálne a segmentové lézie glomerulov"},
{"code":"N01.2","desc":"Rýchlo progredujúci nefritický syndróm: difúzna membránová glomerulonefritída"},
{"code":"N01.3","desc":"Rýchlo progredujúci nefritický syndróm: difúzna mezangioproliferatívna glomerulonefritída"},
{"code":"N01.4","desc":"Rýchlo progredujúci nefritický syndróm: difúzna endokapilárna proliferatívna glomerulonefritída"},
{"code":"N01.5","desc":"Rýchlo progredujúci nefritický syndróm: difúzna mezangiokapilárna glomerulonefritída"},
{"code":"N01.6","desc":"Rýchlo progredujúci nefritický syndróm: choroba denzných depozitov"},
{"code":"N01.7","desc":"Rýchlo progredujúci nefritický syndróm: difúzna glomerulonefritída s výrastkami (polmesiačikmi)"},
{"code":"N01.8","desc":"Rýchlo progredujúci nefritický syndróm: iné morfologické zmeny"},
{"code":"N01.9","desc":"Rýchlo progredujúci nefritický syndróm: morfologická zmena bližšie neurčená"},
{"code":"N02.-","desc":"Recidivujúca a pretrvávajúca hematúria"},
{"code":"N02.0","desc":"Recidivujúca a pretrvávajúca hematúria: malé abnormality glomerulov"},
{"code":"N02.1","desc":"Recidivujúca a pretrvávajúca hematúria: fokálne a segmentové lézie glomerulov"},
{"code":"N02.2","desc":"Recidivujúca a pretrvávajúca hematúria: difúzna membránová glomerulonefritída"},
{"code":"N02.3","desc":"Recidivujúca a pretrvávajúca hematúria: difúzna mezangioproliferatívna glomerulonefritída"},
{"code":"N02.4","desc":"Recidivujúca a pretrvávajúca hematúria: difúzna endokapilárna proliferatívna glomerulonefritída"},
{"code":"N02.5","desc":"Recidivujúca a pretrvávajúca hematúria: difúzna mezangiokapilárna glomerulonefritída"},
{"code":"N02.6","desc":"Recidivujúca a pretrvávajúca hematúria: choroba denzných depozitov"},
{"code":"N02.7","desc":"Recidivujúca a pretrvávajúca hematúria: difúzna glomerulonefritída s výrastkami (polmesiačikmi)"},
{"code":"N02.8","desc":"Recidivujúca a pretrvávajúca hematúria: iné morfologické zmeny"},
{"code":"N02.9","desc":"Recidivujúca a pretrvávajúca hematúria: morfologická zmena bližšie neurčená"},
{"code":"N03.-","desc":"Chronický nefritický syndróm"},
{"code":"N03.0","desc":"Chronický nefritický syndróm: malé abnormality glomerulov"},
{"code":"N03.1","desc":"Chronický nefritický syndróm: fokálne a segmentové lézie glomerulov"},
{"code":"N03.2","desc":"Chronický nefritický syndróm: difúzna membránová glomerulonefritída"},
{"code":"N03.3","desc":"Chronický nefritický syndróm: difúzna mezangioproliferatívna glomerulonefritída"},
{"code":"N03.4","desc":"Chronický nefritický syndróm: difúzna endokapilárna proliferatívna glomerulonefritída"},
{"code":"N03.5","desc":"Chronický nefritický syndróm: difúzna mezangiokapilárna glomerulonefritída"},
{"code":"N03.6","desc":"Chronický nefritický syndróm: choroba denzných depozitov"},
{"code":"N03.7","desc":"Chronický nefritický syndróm: difúzna glomerulonefritída s výrastkami (polmesiačikmi)"},
{"code":"N03.8","desc":"Chronický nefritický syndróm: iné morfologické zmeny"},
{"code":"N03.9","desc":"Chronický nefritický syndróm: morfologická zmena bližšie neurčená"},
{"code":"N04.-","desc":"Nefrotický syndróm"},
{"code":"N04.0","desc":"Nefrotický syndróm: malé abnormality glomerulov"},
{"code":"N04.1","desc":"Nefrotický syndróm: fokálne a segmentové lézie glomerulov"},
{"code":"N04.2","desc":"Nefrotický syndróm: difúzna membránová glomerulonefritída"},
{"code":"N04.3","desc":"Nefrotický syndróm: difúzna mezangioproliferatívna glomerulonefritída"},
{"code":"N04.4","desc":"Nefrotický syndróm: difúzna endokapilárna proliferatívna glomerulonefritída"},
{"code":"N04.5","desc":"Nefrotický syndróm: difúzna mezangiokapilárna glomerulonefritída"},
{"code":"N04.6","desc":"Nefrotický syndróm: choroba denzných depozitov"},
{"code":"N04.7","desc":"Nefrotický syndróm: difúzna glomerulonefritída s výrastkami (polmesiačikmi)"},
{"code":"N04.8","desc":"Nefrotický syndróm: iné morfologické zmeny"},
{"code":"N04.9","desc":"Nefrotický syndróm: morfologická zmena bližšie neurčená"},
{"code":"N05.-","desc":"Nefritický syndróm, bližšie neurčený"},
{"code":"N05.0","desc":"Nefritický syndróm, bližšie neurčený: malé abnormality glomerulov"},
{"code":"N05.1","desc":"Nefritický syndróm, bližšie neurčený: fokálne a segmentové lézie glomerulov"},
{"code":"N05.2","desc":"Nefritický syndróm, bližšie neurčený: difúzna membránová glomerulonefritída"},
{"code":"N05.3","desc":"Nefritický syndróm, bližšie neurčený: difúzna mezangioproliferatívna glomerulonefritída"},
{"code":"N05.4","desc":"Nefritický syndróm, bližšie neurčený: difúzna endokapilárna proliferatívna glomerulonefritída"},
{"code":"N05.5","desc":"Nefritický syndróm, bližšie neurčený: difúzna mezangiokapilárna glomerulonefritída"},
{"code":"N05.6","desc":"Nefritický syndróm, bližšie neurčený: choroba denzných depozitov"},
{"code":"N05.7","desc":"Nefritický syndróm, bližšie neurčený: difúzna glomerulonefritída s výrastkami (polmesiačikmi)"},
{"code":"N05.8","desc":"Nefritický syndróm, bližšie neurčený: iné morfologické zmeny"},
{"code":"N05.9","desc":"Nefritický syndróm, bližšie neurčený: morfologická zmena bližšie neurčená"},
{"code":"N06.-","desc":"Izolovaná proteinúria s morfologickými zmenami"},
{"code":"N06.0","desc":"Izolovaná proteinúria s morfologickými zmenami: malé abnormality glomerulov"},
{"code":"N06.1","desc":"Izolovaná proteinúria s morfologickými zmenami: fokálne a segmentové lézie glomerulov"},
{"code":"N06.2","desc":"Izolovaná proteinúria s morfologickými zmenami: difúzna membránová glomerulonefritída"},
{"code":"N06.3","desc":"Izolovaná proteinúria s morfologickými zmenami: difúzna mezangioproliferatívna glomerulonefritída"},
{"code":"N06.4","desc":"Izolovaná proteinúria s morfologickými zmenami: difúzna endokapilárna proliferatívna glomerulonefritída"},
{"code":"N06.5","desc":"Izolovaná proteinúria s bližšie určenými morfologickými zmenami: difúzna mezangiokapilárna glomerulonefritída"},
{"code":"N06.6","desc":"Izolovaná proteinúria s morfologickými zmenami: choroba denzných depozitov"},
{"code":"N06.7","desc":"Izolovaná proteinúria s morfologickými zmenami: difúzna glomerulonefritída s výrastkami (polmesiačikmi)"},
{"code":"N06.8","desc":"Izolovaná proteinúria s morfologickými zmenami: iné morfologické zmeny"},
{"code":"N06.9","desc":"Izolovaná proteinúria s morfologickými zmenami: morfologická zmena bližšie neurčená"},
{"code":"N07.-","desc":"Hereditárna nefropatia, nezatriedená inde"},
{"code":"N07.0","desc":"Hereditárna nefropatia, nezatriedená inde: malé abnormality glomerulov"},
{"code":"N07.1","desc":"Hereditárna nefropatia, nezatriedená inde: fokálne a segmentové lézie glomerulov"},
{"code":"N07.2","desc":"Hereditárna nefropatia, nezatriedená inde: difúzna membránová glomerulonefritída"},
{"code":"N07.3","desc":"Hereditárna nefropatia, nezatriedená inde: difúzna mezangioproliferatívna glomerulonefritída"},
{"code":"N07.4","desc":"Hereditárna nefropatia, nezatriedená inde: difúzna endokapilárna proliferatívna glomerulonefritída"},
{"code":"N07.5","desc":"Hereditárna nefropatia, nezatriedená inde: difúzna mezangiokapilárna glomerulonefritída"},
{"code":"N07.6","desc":"Hereditárna nefropatia, nezatriedená inde: choroba denzných depozitov"},
{"code":"N07.7","desc":"Hereditárna nefropatia, nezatriedená inde: difúzna glomerulonefritída s výrastkami (polmesiačikmi)"},
{"code":"N07.8","desc":"Hereditárna nefropatia, nezatriedená inde: iné morfologické zmeny"},
{"code":"N07.9","desc":"Hereditárna nefropatia, nezatriedená inde: morfologická zmena bližšie neurčená"},
{"code":"N08.-*","desc":"Glomerulová choroba pri chorobách zatriedených inde"},
{"code":"N08.0*","desc":"Glomerulová choroba pri infekčných alebo parazitových chorobách zatriedených inde"},
{"code":"N08.1*","desc":"Glomerulová choroba pri nádorových chorobách"},
{"code":"N08.2*","desc":"Glomerulová choroba pri chorobách krvi a poruchách imunitného systému"},
{"code":"N08.3*","desc":"Glomerulová choroba pri diabetes mellitus (E10-E14, štvrtá pozícia .2†)"},
{"code":"N08.4*","desc":"Glomerulová choroba pri iných endokrinných, nutričných a metabolických chorobách"},
{"code":"N08.5*","desc":"Glomerulová choroba pri systémových chorobách spojiva"},
{"code":"N08.8*","desc":"Glomerulová choroba pri iných chorobách zatriedených inde"},
{"code":"N10-N16","desc":"Tubulointersticiálne choroby obličiek"},
{"code":"N10","desc":"Akútna tubulointersticiálna nefritída"},
{"code":"N11.-","desc":"Chronická tubulointersticiálna nefritída"},
{"code":"N11.0","desc":"Chronická neobštrukčná pyelonefritída spojená s refluxom"},
{"code":"N11.1","desc":"Chronická obštrukčná pyelonefritída"},
{"code":"N11.8","desc":"Iná chronická tubulointersticiálna nefritída"},
{"code":"N11.9","desc":"Chronická tubulointersticiálna nefritída, bližšie neurčená"},
{"code":"N12","desc":"Tubulointersticiálna nefritída, neurčená ako akútna, alebo chronická"},
{"code":"N13.-","desc":"Obštrukčná a refluxová uropatia"},
{"code":"N13.0","desc":"Hydronefróza s obštrukciou pyeloureterového prechodu"},
{"code":"N13.1","desc":"Hydronefróza so striktúrou močovodu, nezatriedená inde"},
{"code":"N13.2","desc":"Hydronefróza pri obštrukcii kameňom obličky a močovodu"},
{"code":"N13.3","desc":"Iná a bližšie neurčená hydronefróza"},
{"code":"N13.4","desc":"Hydroureter"},
{"code":"N13.5","desc":"Zalomenie a striktúra močovodu bez hydronefrózy"},
{"code":"N13.6","desc":"Pyonefros"},
{"code":"N13.7","desc":"Uropatia pri vezikoureterovom refluxe"},
{"code":"N13.8","desc":"Iná obštrukčná a refluxová uropatia"},
{"code":"N13.9","desc":"Obštrukčná a refluxová uropatia, bližšie neurčená"},
{"code":"N14.-","desc":"Tubulointersticiálna a tubulová choroba zapríčinená liekmi alebo ťažkými kovmi"},
{"code":"N14.0","desc":"Nefropatia zapríčinená analgetikami"},
{"code":"N14.1","desc":"Nefropatia zapríčinená liečivami, liekmi alebo biologickými látkami"},
{"code":"N14.2","desc":"Nefropatia zapríčinená bližšie neurčeným liečivom, liekom alebo biologickou látkou"},
{"code":"N14.3","desc":"Nefropatia zapríčinená ťažkými kovmi"},
{"code":"N14.4","desc":"Toxická nefropatia, nezatriedená inde"},
{"code":"N15.-","desc":"Iná tubulointersticiálna choroba obličiek"},
{"code":"N15.0","desc":"Balkánska nefropatia"},
{"code":"N15.1-","desc":"Absces obličky a perinefritický absces"},
{"code":"N15.10","desc":"Absces obličky"},
{"code":"N15.11","desc":"Perinefritický absces"},
{"code":"N15.8","desc":"Iná tubulointersticiálna choroba obličiek, bližšie určená"},
{"code":"N15.9","desc":"Tubulointersticiálna choroba obličiek, bližšie neurčená"},
{"code":"N16.-*","desc":"Tubulointersticiálna choroba pri chorobách zatriedených inde"},
{"code":"N16.0*","desc":"Tubulointersticiálna choroba pri infekčných alebo parazitových chorobách zatriedených inde"},
{"code":"N16.1*","desc":"Tubulointersticiálna choroba pri nádorových chorobách"},
{"code":"N16.2*","desc":"Tubulointersticiálna choroba obličiek pri chorobách krvi a poruchách imunitného systému"},
{"code":"N16.3*","desc":"Tubulointersticiálna choroba obličiek pri metabolických chorobách"},
{"code":"N16.4*","desc":"Tubulointersticiálna choroba obličiek pri systémových chorobách spojiva"},
{"code":"N16.5*","desc":"Tubulointersticiálna choroba obličiek pri odvrhnutí transplantátu"},
{"code":"N16.8*","desc":"Tubulointersticiálna choroba obličiek pri iných chorobách zatriedených inde"},
{"code":"N17-N19","desc":"Zlyhanie obličiek"},
{"code":"N17.-","desc":"Akútne zlyhanie obličiek"},
{"code":"N17.0","desc":"Akútne zlyhanie obličiek s tubulovou nekrózou"},
{"code":"N17.1","desc":"Akútne zlyhanie obličiek s akútnou kortikálnou nekrózou"},
{"code":"N17.2","desc":"Akútne zlyhanie obličiek s dreňovou nekrózou"},
{"code":"N17.8","desc":"Iné akútne zlyhanie obličiek"},
{"code":"N17.9","desc":"Akútne zlyhanie obličiek, bližšie neurčené"},
{"code":"N18.-","desc":"Chronická choroba obličiek"},
{"code":"N18.1","desc":"Chronická choroba obličiek, 1. štádium"},
{"code":"N18.2","desc":"Chronická choroba obličiek, 2. štádium"},
{"code":"N18.3","desc":"Chronická choroba obličiek, 3. štádium"},
{"code":"N18.4","desc":"Chronická choroba obličiek, 4. štádium"},
{"code":"N18.5","desc":"Chronická choroba obličiek, 5. štádium"},
{"code":"N18.8-","desc":"Iná chronická choroba obličiek"},
{"code":"N18.80","desc":"Jednostranná chronická porucha funkcie obličky"},
{"code":"N18.89","desc":"Iná chronická choroba obličiek, štádium bližšie neurčené"},
{"code":"N18.9","desc":"Chronická choroba obličiek, bližšie neurčená"},
{"code":"N19","desc":"Zlyhanie obličiek, bližšie neurčené"},
{"code":"N20-N23","desc":"Urolitiáza"},
{"code":"N20.-","desc":"Konkrement obličky [nefrolitiáza] a močovodu [ureterolitiáza]"},
{"code":"N20.0","desc":"Konkrement obličky"},
{"code":"N20.1","desc":"Konkrement močovodu"},
{"code":"N20.2","desc":"Konkrement obličky s konkrementom močovodu"},
{"code":"N20.9","desc":"Močový konkrement, bližšie neurčený"},
{"code":"N21.-","desc":"Konkrement dolných močových ciest"},
{"code":"N21.0","desc":"Konkrement močového mechúra"},
{"code":"N21.1","desc":"Konkrement močovej rúry"},
{"code":"N21.8","desc":"Konkrement inej dolnej časti močovej sústavy"},
{"code":"N21.9","desc":"Konkrement dolnej časti močovej sústavy, bližšie neurčený"},
{"code":"N22.-*","desc":"Konkrement močových ciest pri chorobách zatriedených inde"},
{"code":"N22.0*","desc":"Močový konkrement pri schistozomóze [bilharzióze] (B65.-†)"},
{"code":"N22.8*","desc":"Konkrement močovej sústavy pri iných chorobách zatriedených inde"},
{"code":"N23","desc":"Obličková kolika, bližšie neurčená"},
{"code":"N25-N29","desc":"Iné choroby obličiek a močovodov"},
{"code":"N25.-","desc":"Choroba vyplývajúca z poškodenia funkcie obličkových tubulov"},
{"code":"N25.0","desc":"Renálna osteodystrofia"},
{"code":"N25.1","desc":"Nefrogénny diabetes insipidus"},
{"code":"N25.8","desc":"Iná choroba, vyplývajúca z poškodenej funkcie obličkových tubulov"},
{"code":"N25.9","desc":"Choroba, vyplývajúca z poškodenej funkcie obličkových tubulov, bližšie neurčená"},
{"code":"N26","desc":"Scvrknutá oblička, bližšie neurčená"},
{"code":"N27.-","desc":"Malá oblička z neznámej príčiny"},
{"code":"N27.0","desc":"Malá oblička z neznámej príčiny, jednostranne"},
{"code":"N27.1","desc":"Malá oblička z neznámej príčiny, obojstranne"},
{"code":"N27.9","desc":"Malá oblička z neznámej príčiny, bližšie neurčená"},
{"code":"N28.-","desc":"Iná choroba obličiek a močovodov, nezatriedené inde"},
{"code":"N28.0","desc":"Ischémia a infarkt obličky"},
{"code":"N28.1","desc":"Cysta obličky, získaná"},
{"code":"N28.8","desc":"Iná choroba obličiek a močovodov, bližšie určená"},
{"code":"N28.9","desc":"Choroba obličiek a močovodov, bližšie neurčená"},
{"code":"N29.-*","desc":"Iné choroby obličiek a močovodov pri chorobách zatriedených inde"},
{"code":"N29.0*","desc":"Neskorý syfilis obličiek (A52.7†)"},
{"code":"N29.1*","desc":"Iná choroba obličiek a močovodov pri infekčných a parazitových chorobách zatriedených inde"},
{"code":"N29.8*","desc":"Iné choroby obličiek a močovodov pri iných chorobách zatriedených inde"},
{"code":"N30-N39","desc":"Iné choroby močovej sústavy"},
{"code":"N30.-","desc":"Cystitída"},
{"code":"N30.0","desc":"Akútna cystitída"},
{"code":"N30.1","desc":"Intersticiálna cystitída (chronická)"},
{"code":"N30.2","desc":"Iná chronická cystitída"},
{"code":"N30.3","desc":"Trigonitída"},
{"code":"N30.4","desc":"Postradiačná cystitída"},
{"code":"N30.8","desc":"Iná cystitída"},
{"code":"N30.9","desc":"Cystitída, bližšie neurčená"},
{"code":"N31.-","desc":"Nervovosvalová dysfunkcia močového mechúra, nezatriedená inde"},
{"code":"N31.0","desc":"Dráždivý neurogénny močový mechúr, nezatriedený inde"},
{"code":"N31.1","desc":"Reflexný neurogénny močový mechúr, nezatriedený inde"},
{"code":"N31.2","desc":"Atonický neurogénny močový mechúr, nezatriedený inde"},
{"code":"N31.8-","desc":"Iná nervovosvalová dysfunkcia močového mechúra"},
{"code":"N31.80","desc":"Nervovosvalový močový mechúr s nízkou poddajnosťou, organicky fixovaný"},
{"code":"N31.81","desc":"Hypo- a akontraktilita svalov močového mechúra bez nervovej poruchy"},
{"code":"N31.82","desc":"Instabilný mechúr bez nervovej poruchy"},
{"code":"N31.88","desc":"Iná nervovosvalová dysfunkcia močového mechúra"},
{"code":"N31.9","desc":"Nervovosvalová dysfunkcia močového mechúra, bližšie neurčená"},
{"code":"N32.-","desc":"Iné choroby močového mechúra"},
{"code":"N32.0","desc":"Obštrukcia krčka močového mechúra"},
{"code":"N32.1","desc":"Vezikointestinálna fistula"},
{"code":"N32.2","desc":"Fistula močového mechúra, nezatriedená inde"},
{"code":"N32.3","desc":"Divertikul močového mechúra"},
{"code":"N32.4","desc":"Ruptúra močového mechúra, neúrazová"},
{"code":"N32.8-","desc":"Iná choroba močového mechúra, bližšie určená"},
{"code":"N32.80","desc":"Hyperaktívny močový mechúr"},
{"code":"N32.89","desc":"Iná choroba močového mechúra, bližšie určená"},
{"code":"N32.9","desc":"Choroba močového mechúra, bližšie neurčená"},
{"code":"N33.-*","desc":"Choroba močového mechúra pri chorobe zatriedenej inde"},
{"code":"N33.0*","desc":"Tuberkulózna cystitída (A18.1†)"},
{"code":"N33.8*","desc":"Choroby močového mechúra pri iných chorobách zatriedených inde"},
{"code":"N34.-","desc":"Uretritída a uretrový syndróm"},
{"code":"N34.0","desc":"Absces močovej rúry"},
{"code":"N34.1","desc":"Zápal močovej rúry, bližšie neučený"},
{"code":"N34.2","desc":"Iný zápal močovej rúry"},
{"code":"N34.3","desc":"Uretrový syndróm, bližšie neurčený"},
{"code":"N35.-","desc":"Striktúra uretry"},
{"code":"N35.0","desc":"Poúrazová striktúra uretry"},
{"code":"N35.1","desc":"Poinfekčná striktúra uretry, nezatriedená inde"},
{"code":"N35.8","desc":"Iná striktúra uretry"},
{"code":"N35.9","desc":"Striktúra uretry, bližšie neurčená"},
{"code":"N36.-","desc":"Iné choroby močovej rúry"},
{"code":"N36.0","desc":"Fistula uretry"},
{"code":"N36.1","desc":"Divertikul uretry"},
{"code":"N36.2","desc":"Karunkul uretry"},
{"code":"N36.3","desc":"Prolaps sliznice uretry"},
{"code":"N36.8","desc":"Iné choroby uretry, bližšie určené"},
{"code":"N36.9","desc":"Choroba uretry, bližšie neurčená"},
{"code":"N37.-*","desc":"Choroby uretry pri chorobách zatriedených inde"},
{"code":"N37.0*","desc":"Uretritída pri chorobách zatriedených inde"},
{"code":"N37.8*","desc":"Iné choroby močovej rúry pri chorobách zatriedených inde"},
{"code":"N39.-","desc":"Iná choroba močovej sústavy"},
{"code":"N39.0","desc":"Infekcia močovej sústavy, miesto bližšie neurčené"},
{"code":"N39.1","desc":"Pretrvávajúca proteinúria, bližšie neurčená"},
{"code":"N39.2","desc":"Ortostatická proteinúria, bližšie neurčená"},
{"code":"N39.3","desc":"Stresová inkontinencia moču"},
{"code":"N39.4-","desc":"Iná inkontinencia moču, bližšie určená"},
{"code":"N39.40","desc":"Reflexná inkontinencia moču"},
{"code":"N39.41","desc":"Inkontinencia moču z pretekania"},
{"code":"N39.42","desc":"Nutkavá inkontinencia moču"},
{"code":"N39.43","desc":"Extrauretrálna inkontinencia moču"},
{"code":"N39.47!","desc":"Recidivujúca inkontinencia moču"},
{"code":"N39.48","desc":"Iná inkontinencia moču, bližšie určená"},
{"code":"N39.8-","desc":"Iné choroby močovej sústavy, bližšie určené"},
{"code":"N39.81","desc":"Syndróm panvových bolestí a hematúrie [Loin Pain Hematuria Syndrome, LPHS]"},
{"code":"N39.88","desc":"Iné choroby močovej sústavy, bližšie určené"},
{"code":"N39.9","desc":"Choroba močovej sústavy, bližšie neurčená"},
{"code":"N40-N51","desc":"Choroby mužských pohlavných orgánov"},
{"code":"N40","desc":"Hyperplázia prostaty"},
{"code":"N41.-","desc":"Zápalové choroby prostaty"},
{"code":"N41.0","desc":"Akútna prostatitída"},
{"code":"N41.1","desc":"Chronická prostatitída"},
{"code":"N41.2","desc":"Absces prostaty"},
{"code":"N41.3","desc":"Prostatocystitída"},
{"code":"N41.8","desc":"Iná zápalová choroba prostaty"},
{"code":"N41.9","desc":"Zápalová choroba prostaty, bližšie neurčená"},
{"code":"N42.-","desc":"Iná choroba prostaty"},
{"code":"N42.0","desc":"Konkrement prostaty"},
{"code":"N42.1","desc":"Kongescia a krvácanie z prostaty"},
{"code":"N42.2","desc":"Atrofia prostaty"},
{"code":"N42.3","desc":"Dysplázia prostaty"},
{"code":"N42.8","desc":"Iná choroba prostaty, bližšie určená"},
{"code":"N42.9","desc":"Choroba prostaty, bližšie neurčená"},
{"code":"N43.-","desc":"Hydrokéla a spermatokéla"},
{"code":"N43.0","desc":"Encystovaná hydrokéla"},
{"code":"N43.1","desc":"Infikovaná hydrokéla"},
{"code":"N43.2","desc":"Iná hydrokéla"},
{"code":"N43.3","desc":"Hydrokéla, bližšie neurčená"},
{"code":"N43.4","desc":"Spermatokéla"},
{"code":"N44.-","desc":"Torzia semenníka a hidatídy"},
{"code":"N44.0","desc":"Torzia semenníka"},
{"code":"N44.1","desc":"Torzia hidatídy [Morgagni]"},
{"code":"N45.-","desc":"Orchitída a epididymitída"},
{"code":"N45.0","desc":"Orchitída, epididymitída a epididymoorchitída s abscesom"},
{"code":"N45.9","desc":"Orchitída, epididymitída a epididymoorchitída bez abscesu"},
{"code":"N46","desc":"Mužská neplodnosť"},
{"code":"N47","desc":"Hyperplastická predkožka, fimóza a parafimóza"},
{"code":"N48.-","desc":"Iná choroba penisu"},
{"code":"N48.0","desc":"Leukoplakia penisu"},
{"code":"N48.1","desc":"Balanopostitída"},
{"code":"N48.2","desc":"Iná zápalová choroba penisu"},
{"code":"N48.3-","desc":"Priapizmus"},
{"code":"N48.30","desc":"Nízkoprietokový (venookluzívny, statický, ischemický) priapizmus"},
{"code":"N48.31","desc":"Vysokoprietokový (arteriogénny, dynamický, neischemický) priapizmus"},
{"code":"N48.38","desc":"Iný priapizmus"},
{"code":"N48.39","desc":"Priapizmus, bližšie neurčený"},
{"code":"N48.4","desc":"Impotencia organického pôvodu"},
{"code":"N48.5","desc":"Vred penisu"},
{"code":"N48.6","desc":"Induratio penis plastica [balanitis xerotica obliterans]"},
{"code":"N48.8","desc":"Iné choroby penisu, bližšie určené"},
{"code":"N48.9","desc":"Choroba penisu, bližšie neurčená"},
{"code":"N49.-","desc":"Zápalová choroba mužských pohlavných orgánov, nezatriedené inde"},
{"code":"N49.0","desc":"Zápalová choroba semenných mechúrikov"},
{"code":"N49.1","desc":"Zápalová choroba semenného povrazca, semenníkového pošvového obalu a semenovodu"},
{"code":"N49.2","desc":"Zápalová choroba mieška"},
{"code":"N49.8-","desc":"Zápalová choroba iných bližšie určených mužských pohlavných ústrojov"},
{"code":"N49.80","desc":"Fournierova gangréna u muža"},
{"code":"N49.88","desc":"Zápalová choroba iných bližšie určených mužských pohlavných orgánov"},
{"code":"N49.9","desc":"Zápalová choroba bližšie neurčeného mužského pohlavného orgánu"},
{"code":"N50.-","desc":"Iné choroby mužských pohlavných orgánov"},
{"code":"N50.0","desc":"Atrofia semenníka"},
{"code":"N50.1","desc":"Cievne choroby mužských pohlavných orgánov"},
{"code":"N50.8","desc":"Iné choroby mužských pohlavných orgánov, bližšie určené"},
{"code":"N50.9","desc":"Choroby mužských pohlavných orgánov, bližšie neurčené"},
{"code":"N51.-*","desc":"Choroby mužských pohlavných orgánov pri chorobách zatriedených inde"},
{"code":"N51.0*","desc":"Choroby prostaty pri chorobách zatriedených inde"},
{"code":"N51.1*","desc":"Choroby semenníka a nadsemenníka pri chorobách zatriedených inde"},
{"code":"N51.2*","desc":"Balanitída pri chorobách zatriedených inde"},
{"code":"N51.8*","desc":"Iné choroby mužských pohlavných orgánov pri chorobách zatriedených inde"},
{"code":"N60-N64","desc":"Choroby prsníka"},
{"code":"N60.-","desc":"Benígna dysplázia prsníka"},
{"code":"N60.0","desc":"Solitárna cysta prsníka"},
{"code":"N60.1","desc":"Difúzna cystická mastopatia"},
{"code":"N60.2","desc":"Fibroadenóza prsníka"},
{"code":"N60.3","desc":"Fibroskleróza prsníka"},
{"code":"N60.4","desc":"Rozšírenie mliekovodu prsníka"},
{"code":"N60.8","desc":"Iná benígna dysplázia prsníka"},
{"code":"N60.9","desc":"Benígna dysplázia prsníka, bližšie neurčená"},
{"code":"N61","desc":"Zápalová choroba prsníka"},
{"code":"N62","desc":"Hypertrofia prsníka"},
{"code":"N63","desc":"Hrčka v prsníku, bližšie neurčená"},
{"code":"N64.-","desc":"Iné choroby prsníka"},
{"code":"N64.0","desc":"Fisúra a fistula bradavky"},
{"code":"N64.1","desc":"Tuková nekróza prsníka"},
{"code":"N64.2","desc":"Atrofia prsníka"},
{"code":"N64.3","desc":"Galaktorea bez súvislosti s pôrodom"},
{"code":"N64.4","desc":"Mastodýnia"},
{"code":"N64.5","desc":"Iné objektívne a subjektívne príznaky v prsníku"},
{"code":"N64.8","desc":"Iná choroba prsníka, bližšie určená"},
{"code":"N64.9","desc":"Choroba prsníka, bližšie neurčená"},
{"code":"N70-N77","desc":"Zápalové choroby ženských panvových orgánov"},
{"code":"N70.-","desc":"Salpingitída a ooforitída"},
{"code":"N70.0","desc":"Akútna salpingitída a ooforitída"},
{"code":"N70.1","desc":"Chronická salpingitída a ooforitída"},
{"code":"N70.9","desc":"Salpingitída a ooforitída, bližšie neurčená"},
{"code":"N71.-","desc":"Zápalová choroba maternice, okrem krčka maternice"},
{"code":"N71.0","desc":"Akútna zápalová choroba maternice"},
{"code":"N71.1","desc":"Chronická zápalová choroba maternice"},
{"code":"N71.9","desc":"Zápalová choroba maternice, bližšie neurčená"},
{"code":"N72","desc":"Zápalová choroba krčka maternice"},
{"code":"N73.-","desc":"Iná zápalová choroba ženských panvových orgánov"},
{"code":"N73.0","desc":"Akútna parametritída a panvová celulitída"},
{"code":"N73.1","desc":"Chronická parametritída a panvová celulitída"},
{"code":"N73.2","desc":"Parametritída a panvová celulitída, bližšie neurčená"},
{"code":"N73.3","desc":"Akútna pelveoperitonitída u žien"},
{"code":"N73.4","desc":"Chronická pelveoperitonitída u žien"},
{"code":"N73.5","desc":"Pelveoperitonitída u žien, bližšie neurčená"},
{"code":"N73.6","desc":"Panvové peritoneálne adhézie u žien"},
{"code":"N73.8","desc":"Iné zápalové choroby ženských panvových orgánov, bližšie určené"},
{"code":"N73.9","desc":"Zápalová choroba ženských panvových orgánov, bližšie neurčená"},
{"code":"N74.-*","desc":"Zápalové choroby ženských panvových orgánov pri chorobách zatriedených inde"},
{"code":"N74.0*","desc":"Tuberkulózna infekcia krčka maternice (A18.1†)"},
{"code":"N74.1*","desc":"Tuberkulózny zápal ženských panvových orgánov (A18.1†)"},
{"code":"N74.2*","desc":"Syfilitický zápal ženských panvových orgánov (A51.4†, A52.7†)"},
{"code":"N74.3*","desc":"Gonokokový zápal ženských panvových orgánov (A54.2†)"},
{"code":"N74.4*","desc":"Chlamýdiový zápal ženských panvových orgánov"},
{"code":"N74.8*","desc":"Zápalová choroba ženských panvových orgánov pri inej chorobe zatriedenej inde"},
{"code":"N75.-","desc":"Choroby Bartholinovej žľazy"},
{"code":"N75.0","desc":"Cysta Bartholinovej žľazy"},
{"code":"N75.1","desc":"Absces Bartholinovej žľazy"},
{"code":"N75.8","desc":"Iné choroby Bartholinovej žľazy"},
{"code":"N75.9","desc":"Choroba Bartholinovej žľazy, bližšie neurčená"},
{"code":"N76.-","desc":"Iný zápal pošvy a vulvy"},
{"code":"N76.0","desc":"Akútna vaginitída"},
{"code":"N76.1","desc":"Subakútna a chronická vaginitída"},
{"code":"N76.2","desc":"Akútna vulvitída"},
{"code":"N76.3","desc":"Subakútna a chronická vulvitída"},
{"code":"N76.4","desc":"Absces vulvy"},
{"code":"N76.5","desc":"Ulcerácia pošvy"},
{"code":"N76.6","desc":"Ulcerácia vulvy"},
{"code":"N76.8-","desc":"Iné zápaly pošvy a vulvy, bližšie určené"},
{"code":"N76.80","desc":"Fournierova gangréna u ženy"},
{"code":"N76.88","desc":"Iné zápaly pošvy a vulvy, bližšie určené"},
{"code":"N77.-*","desc":"Vulvovaginálne ulcerácie a zápaly pri chorobách zatriedených inde"},
{"code":"N77.0*","desc":"Ulcerácia vulvy pri infekčných alebo parazitových chorobách zatriedených inde"},
{"code":"N77.1*","desc":"Vaginitída, vulvitída a vulvovaginitída pri infekčných alebo parazitových chorobách zatriedených inde"},
{"code":"N77.8*","desc":"Vulvovaginálna ulcerácia a zápal pri iných chorobách zatriedených inde"},
{"code":"N80-N98","desc":"Nezápalové choroby ženských pohlavných orgánov"},
{"code":"N80.-","desc":"Endometrióza"},
{"code":"N80.0","desc":"Endometrióza maternice"},
{"code":"N80.1","desc":"Endometrióza vaječníka"},
{"code":"N80.2","desc":"Endometrióza vajíčkovodu"},
{"code":"N80.3","desc":"Endometrióza panvového peritonea"},
{"code":"N80.4","desc":"Endometrióza rektovaginálneho septa a pošvy"},
{"code":"N80.5","desc":"Endometrióza čreva"},
{"code":"N80.6","desc":"Endometrióza v kožnej jazve"},
{"code":"N80.8","desc":"Iná endometrióza"},
{"code":"N80.9","desc":"Endometrióza, bližšie neurčená"},
{"code":"N81.-","desc":"Prolaps pohlavných orgánov u ženy"},
{"code":"N81.0","desc":"Uretrokéla u ženy"},
{"code":"N81.1","desc":"Cystokéla"},
{"code":"N81.2","desc":"Nekompletný uterovaginálny prolaps"},
{"code":"N81.3","desc":"Kompletný uterovaginálny prolaps"},
{"code":"N81.4","desc":"Uterovaginálny prolaps, bližšie neurčený"},
{"code":"N81.5","desc":"Pošvová enterokéla"},
{"code":"N81.6","desc":"Rektokéla"},
{"code":"N81.8","desc":"Iný prolaps pohlavných orgánov u ženy"},
{"code":"N81.9","desc":"Prolaps pohlavných orgánov u ženy, bližšie neurčený"},
{"code":"N82.-","desc":"Fistula s postihnutím ženských pohlavných orgánov"},
{"code":"N82.0","desc":"Vezikovaginálna fistula"},
{"code":"N82.1","desc":"Iná fistula medzi ženskými močovými a pohlavnými orgánmi"},
{"code":"N82.2","desc":"Fistula medzi pošvou a tenkým črevom"},
{"code":"N82.3","desc":"Fistula medzi pošvou a hrubým črevom"},
{"code":"N82.4","desc":"Iná fistula medzi ženskými pohlavnými orgánmi a črevom"},
{"code":"N82.5","desc":"Fistula medzi ženskými pohlavnými orgánmi a kožou"},
{"code":"N82.8","desc":"Iná fistula ženských pohlavných orgánov"},
{"code":"N82.9","desc":"Fistula ženských pohlavných orgánov, bližšie neurčená"},
{"code":"N83.-","desc":"Nezápalová choroba vaječníka, vajíčkovodu a širokého väzu maternice"},
{"code":"N83.0","desc":"Folikulová cysta vaječníka"},
{"code":"N83.1","desc":"Luteálna cysta"},
{"code":"N83.2","desc":"Iná a bližšie neurčená cysta vaječníkov"},
{"code":"N83.3","desc":"Získaná atrofia vaječníka a vajíčkovodu"},
{"code":"N83.4","desc":"Prolaps a hernia vaječníka a vajíčkovodu"},
{"code":"N83.5","desc":"Torzia vaječníka, stopky vaječníka a vajíčkovodu"},
{"code":"N83.6","desc":"Hematosalpinx"},
{"code":"N83.7","desc":"Hematóm širokého väzu maternice"},
{"code":"N83.8","desc":"Iné nezápalové zmeny vaječníka, vajíčkovodu a širokého väzu maternice"},
{"code":"N83.9","desc":"Nezápalové zmeny vaječníka, vajíčkovodu a širokého väzu maternice, bližšie neurčené"},
{"code":"N84.-","desc":"Polyp ženských pohlavných orgánov"},
{"code":"N84.0","desc":"Polyp tela maternice"},
{"code":"N84.1","desc":"Polyp krčka maternice"},
{"code":"N84.2","desc":"Polyp pošvy"},
{"code":"N84.3","desc":"Polyp vulvy"},
{"code":"N84.8","desc":"Polyp iných častí ženských pohlavných orgánov"},
{"code":"N84.9","desc":"Polyp ženských pohlavných orgánov, bližšie neurčený"},
{"code":"N85.-","desc":"Iná nezápalová choroba maternice, okrem krčka"},
{"code":"N85.0","desc":"Glandulárna hyperplázia endometria"},
{"code":"N85.1","desc":"Adenomatózna hyperplázia endometria"},
{"code":"N85.2","desc":"Hypertrofia maternice"},
{"code":"N85.3","desc":"Subinvolúcia maternice"},
{"code":"N85.4","desc":"Malpozícia maternice"},
{"code":"N85.5","desc":"Inverzia maternice"},
{"code":"N85.6","desc":"Vnútromaternicové zrasty"},
{"code":"N85.7","desc":"Hematometra"},
{"code":"N85.8","desc":"Iná nezápalová choroba maternice, bližšie určená"},
{"code":"N85.9","desc":"Nezápalová choroba maternice, bližšie neurčená"},
{"code":"N86","desc":"Erózia a ektropium krčka maternice"},
{"code":"N87.-","desc":"Dysplázia krčka maternice"},
{"code":"N87.0","desc":"Ľahká dysplázia krčka maternice"},
{"code":"N87.1","desc":"Stredná dysplázia krčka maternice"},
{"code":"N87.2","desc":"Ťažká dysplázia krčka maternice, nezatriedená inde"},
{"code":"N87.9","desc":"Dysplázia krčka maternice, bližšie neurčená"},
{"code":"N88.-","desc":"Iné nezápalové choroby krčka maternice"},
{"code":"N88.0","desc":"Leukoplakia krčka maternice"},
{"code":"N88.1","desc":"Stará lacerácia krčka maternice"},
{"code":"N88.2","desc":"Striktúra a stenóza krčka maternice"},
{"code":"N88.3","desc":"Inkompetencia krčka maternice"},
{"code":"N88.4","desc":"Hypertrofické predĺženie krčka maternice"},
{"code":"N88.8","desc":"Iná nezápalová choroba krčka maternice, bližšie určená"},
{"code":"N88.9","desc":"Nezápalová choroba krčka maternice, bližšie neurčená"},
{"code":"N89.-","desc":"Iná nezápalová choroba pošvy"},
{"code":"N89.0","desc":"Ľahká dysplázia pošvy"},
{"code":"N89.1","desc":"Stredne pokročilá dysplázia pošvy"},
{"code":"N89.2","desc":"Ťažká dysplázia pošvy, nezatriedená inde"},
{"code":"N89.3","desc":"Dysplázia pošvy, bližšie neurčená"},
{"code":"N89.4","desc":"Leukoplakia pošvy"},
{"code":"N89.5","desc":"Striktúra a atrézia pošvy"},
{"code":"N89.6","desc":"Tesný prstenec hymenu"},
{"code":"N89.7","desc":"Hematokolpos"},
{"code":"N89.8","desc":"Iná nezápalová choroba pošvy, bližšie určená"},
{"code":"N89.9","desc":"Nezápalová choroba pošvy, bližšie neurčená"},
{"code":"N90.-","desc":"Iná nezápalová choroba vulvy a hrádze"},
{"code":"N90.0","desc":"Ľahká dysplázia vulvy"},
{"code":"N90.1","desc":"Stredne ťažká dysplázia vulvy"},
{"code":"N90.2","desc":"Ťažká dysplázia vulvy, nezatriedená inde"},
{"code":"N90.3","desc":"Dysplázia vulvy, bližšie neurčená"},
{"code":"N90.4","desc":"Leukoplakia vulvy"},
{"code":"N90.5","desc":"Atrofia vulvy"},
{"code":"N90.6","desc":"Hypertrofia vulvy"},
{"code":"N90.7","desc":"Cysta vulvy"},
{"code":"N90.8","desc":"Iná nezápalová choroba vulvy a hrádze, bližšie určená"},
{"code":"N90.9","desc":"Nezápalová choroba vulvy a hrádze, bližšie neurčená"},
{"code":"N91.-","desc":"Chýbajúca, slabá a zriedkavá menštruácia"},
{"code":"N91.0","desc":"Primárna amenorea"},
{"code":"N91.1","desc":"Sekundárna amenorea"},
{"code":"N91.2","desc":"Amenorea, bližšie neurčená"},
{"code":"N91.3","desc":"Primárna oligomenorea"},
{"code":"N91.4","desc":"Sekundárna oligomenorea"},
{"code":"N91.5","desc":"Oligomenorea, bližšie neurčená"},
{"code":"N92.-","desc":"Nadmerná, častá a nepravidelná menštruácia"},
{"code":"N92.0","desc":"Nadmerná alebo častá menštruácia s pravidelným cyklom"},
{"code":"N92.1","desc":"Nadmerná alebo častá menštruácia s nepravidelným cyklom"},
{"code":"N92.2","desc":"Nadmerná menštruácia v puberte"},
{"code":"N92.3","desc":"Ovulačné krvácanie"},
{"code":"N92.4","desc":"Nadmerné krvácanie v premenopauze"},
{"code":"N92.5","desc":"Iná nepravidelná menštruácia, bližšie určená"},
{"code":"N92.6","desc":"Nepravidelná menštruácia, bližšie neurčená"},
{"code":"N93.-","desc":"Iné abnormálne krvácanie z maternice alebo pošvy"},
{"code":"N93.0","desc":"Postkoitálne a kontaktné krvácanie"},
{"code":"N93.8","desc":"Iné abnormálne krvácanie z maternice alebo pošvy, bližšie určené"},
{"code":"N93.9","desc":"Abnormálne krvácanie z maternice alebo pošvy, bližšie neurčené"},
{"code":"N94.-","desc":"Bolesť a iné odchýlky spojené so ženskými pohlavnými orgánmi a menštruačným cyklom"},
{"code":"N94.0","desc":"Ovulačná bolesť (Mittelschmerz)"},
{"code":"N94.1","desc":"Dyspareunia"},
{"code":"N94.2","desc":"Vaginizmus"},
{"code":"N94.3","desc":"Predmenštruačný syndróm"},
{"code":"N94.4","desc":"Primárna dysmenorea"},
{"code":"N94.5","desc":"Sekundárna dysmenorea"},
{"code":"N94.6","desc":"Dysmenorea, bližšie neurčená"},
{"code":"N94.8","desc":"Iné odchýlky spojené so ženskými pohlavnými orgánmi a menštruačným cyklom, bližšie určené"},
{"code":"N94.9","desc":"Odchýlka spojená so ženskými pohlavnými orgánmi a menštruačným cyklom, bližšie neurčená"},
{"code":"N95.-","desc":"Menopauzové a iné perimenopauzové poruchy"},
{"code":"N95.0","desc":"Pomenopauzové krvácanie"},
{"code":"N95.1","desc":"Menopauzové a klimakterické poruchy"},
{"code":"N95.2","desc":"Pomenopauzová atrofická vaginitída"},
{"code":"N95.3","desc":"Poruchy spojené s arteficiálnou menopauzou"},
{"code":"N95.8","desc":"Iné menopauzové a perimenopauzové poruchy, bližšie určené"},
{"code":"N95.9","desc":"Menopauzová a perimenopauzová porucha, bližšie neurčená"},
{"code":"N96","desc":"Sklon k habituálnemu potrácaniu"},
{"code":"N97.-","desc":"Ženská neplodnosť"},
{"code":"N97.0","desc":"Ženská neplodnosť spojená s anovuláciou"},
{"code":"N97.1","desc":"Ženská neplodnosť tubárneho pôvodu"},
{"code":"N97.2","desc":"Ženská neplodnosť maternicového pôvodu"},
{"code":"N97.3","desc":"Ženská neplodnosť krčkového pôvodu"},
{"code":"N97.4","desc":"Ženská neplodnosť spojená s mužskými faktormi"},
{"code":"N97.8","desc":"Ženská neplodnosť iného pôvodu"},
{"code":"N97.9","desc":"Ženská neplodnosť, bližšie neurčená"},
{"code":"N98.-","desc":"Komplikácie spojené s umelým oplodnením"},
{"code":"N98.0","desc":"Infekcia spojená s umelým oplodnením"},
{"code":"N98.1","desc":"Hyperstimulácia vaječníkov"},
{"code":"N98.2","desc":"Komplikácie pri pokuse zaviesť oplodnené vajíčko po oplodnení in vitro"},
{"code":"N98.3","desc":"Komplikácie pri pokuse zaviesť embryo pri prenose embrya"},
{"code":"N98.8","desc":"Iné komplikácie spojené s umelým oplodnením"},
{"code":"N98.9","desc":"Komplikácia spojená s umelým oplodnením, bližšie neurčená"},
{"code":"N99-N99","desc":"Iné choroby močovopohlavnej sústavy"},
{"code":"N99.-","desc":"Choroba močovopohlavných orgánov, nezatriedená inde"},
{"code":"N99.0","desc":"Poškodenie obličiek po lekárskom výkone"},
{"code":"N99.1","desc":"Striktúra uretry po lekárskom výkone"},
{"code":"N99.2","desc":"Adhézie pošvy po chirurgickom výkone"},
{"code":"N99.3","desc":"Prolaps pošvovej klenby po hysterektómii"},
{"code":"N99.4","desc":"Zrasty panvového peritonea po chirurgickom výkone"},
{"code":"N99.5","desc":"Porucha funkcie vonkajšieho ústia močovej sústavy"},
{"code":"N99.8","desc":"Iné choroby močovopohlavnej sústavy po lekárskom výkone"},
{"code":"N99.9","desc":"Choroba močovopohlavnej sústavy po lekárskom výkone, bližšie neurčená"},
{"code":"O00-O99","desc":"XV. kapitola - Gravidita, pôrod a šestonedelie (O00-O99)"},
{"code":"O00-O08","desc":"Gravidita končiaca potratom"},
{"code":"O00.-","desc":"Mimomaternicová gravidita"},
{"code":"O00.0","desc":"Abdominálna gravidita"},
{"code":"O00.1","desc":"Tubárna gravidita"},
{"code":"O00.2","desc":"Ováriová gravidita"},
{"code":"O00.8","desc":"Iná mimomaternicová gravidita"},
{"code":"O00.9","desc":"Mimomaternicová gravidita, bližšie neurčená"},
{"code":"O01.-","desc":"Mola hydatidosa"},
{"code":"O01.0","desc":"Klasická mola hydatidosa"},
{"code":"O01.1","desc":"Nekompletná a parciálna mola hydatidosa"},
{"code":"O01.9","desc":"Mola hydatidosa, bližšie neurčená"},
{"code":"O02.-","desc":"Iný abnormálny výtvor počatia"},
{"code":"O02.0","desc":"Poškodené plodové vajce a nehydatidová mola"},
{"code":"O02.1","desc":"Missed abortion (zadržaný potrat)"},
{"code":"O02.8","desc":"Iný abnormálny výtvor počatia, bližšie určený"},
{"code":"O02.9","desc":"Abnormálny výtvor počatia, bližšie neurčený"},
{"code":"O03.-","desc":"Spontánny potrat"},
{"code":"O03.0","desc":"Spontánny potrat: nekompletný potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O03.1","desc":"Spontánny potrat: nekompletný potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O03.2","desc":"Spontánny potrat: nekompletný potrat, komplikovaný embóliou"},
{"code":"O03.3","desc":"Spontánny potrat: nekompletný potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O03.4","desc":"Spontánny potrat: nekompletný potrat bez komplikácií"},
{"code":"O03.5","desc":"Spontánny potrat: kompletný alebo bližšie neurčený potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O03.6","desc":"Spontánny potrat: kompletný alebo bližšie neurčený potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O03.7","desc":"Spontánny potrat: kompletný alebo bližšie neurčený potrat, komplikovaný embóliou"},
{"code":"O03.8","desc":"Spontánny potrat: kompletný alebo bližšie neurčený potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O03.9","desc":"Spontánny potrat: kompletný alebo bližšie neurčený potrat bez komplikácií"},
{"code":"O04.-","desc":"Lekársky potrat"},
{"code":"O04.0","desc":"Lekársky potrat: nekompletný potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O04.1","desc":"Lekársky potrat: nekompletný potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O04.2","desc":"Lekársky potrat: nekompletný potrat, komplikovaný embóliou"},
{"code":"O04.3","desc":"Lekársky potrat: nekompletný potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O04.4","desc":"Lekársky potrat: nekompletný potrat bez komplikácií"},
{"code":"O04.5","desc":"Lekársky potrat: kompletný alebo bližšie neurčený potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O04.6","desc":"Lekársky potrat: kompletný alebo bližšie neurčený potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O04.7","desc":"Lekársky potrat: kompletný alebo bližšie neurčený potrat, komplikovaný embóliou"},
{"code":"O04.8","desc":"Lekársky potrat: kompletný alebo bližšie neurčený potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O04.9","desc":"Lekársky potrat: kompletný alebo bližšie neurčený potrat bez komplikácií"},
{"code":"O05.-","desc":"Iný potrat"},
{"code":"O05.0","desc":"Iný potrat: nekompletný potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O05.1","desc":"Iný potrat: nekompletný potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O05.2","desc":"Iný potrat: nekompletný potrat, komplikovaný embóliou"},
{"code":"O05.3","desc":"Iný potrat: nekompletný potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O05.4","desc":"Iný potrat: nekompletný potrat bez komplikácií"},
{"code":"O05.5","desc":"Iný potrat: kompletný alebo bližšie neurčený potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O05.6","desc":"Iný potrat: kompletný alebo bližšie neurčený potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O05.7","desc":"Iný potrat: kompletný alebo bližšie neurčený potrat, komplikovaný embóliou"},
{"code":"O05.8","desc":"Iný potrat: kompletný alebo bližšie neurčený potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O05.9","desc":"Iný potrat: kompletný alebo bližšie neurčený potrat bez komplikácií"},
{"code":"O06.-","desc":"Potrat, bližšie neurčený"},
{"code":"O06.0","desc":"Bližšie neurčený potrat: nekompletný potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O06.1","desc":"Bližšie neurčený potrat: nekompletný potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O06.2","desc":"Bližšie neurčený potrat: nekompletný potrat, komplikovaný embóliou"},
{"code":"O06.3","desc":"Bližšie neurčený potrat: nekompletný potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O06.4","desc":"Bližšie neurčený potrat: nekompletný potrat bez komplikácií"},
{"code":"O06.5","desc":"Bližšie neurčený potrat: kompletný alebo bližšie neurčený potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O06.6","desc":"Bližšie neurčený potrat: kompletný alebo bližšie neurčený potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O06.7","desc":"Bližšie neurčený potrat: kompletný alebo bližšie neurčený potrat, komplikovaný embóliou"},
{"code":"O06.8","desc":"Bližšie neurčený potrat: kompletný alebo bližšie neurčený potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O06.9","desc":"Bližšie neurčený potrat: kompletný alebo bližšie neurčený potrat bez komplikácií"},
{"code":"O07.-","desc":"Neúspešný pokus o potrat"},
{"code":"O07.0","desc":"Neúspešný lekársky pokus o potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O07.1","desc":"Neúspešný lekársky pokus o potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O07.2","desc":"Neúspešný lekársky pokus o potrat, komplikovaný embóliou"},
{"code":"O07.3","desc":"Neúspešný lekársky pokus o potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O07.4","desc":"Neúspešný lekársky pokus o potrat bez komplikácií"},
{"code":"O07.5","desc":"Iný a bližše neurčený neúspešný pokus o potrat, komplikovaný infekciou pohlavných a panvových orgánov"},
{"code":"O07.6","desc":"Iný a bližšie neurčený neúspešný pokus o potrat, komplikovaný oneskoreným alebo nadmerným krvácaním"},
{"code":"O07.7","desc":"Iný a bližšie neurčený neúspešný pokus o potrat, komplikovaný embóliou"},
{"code":"O07.8","desc":"Iný a bližšie neurčený neúspešný pokus o potrat s inými a bližšie neurčenými komplikáciami"},
{"code":"O07.9","desc":"Iný a bližšie neurčený neúspešný pokus o potrat bez komplikácií"},
{"code":"O08.-","desc":"Komplikácie po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.0","desc":"Infekcia pohlavných a panvových orgánov po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.1","desc":"Oneskorené alebo nadmerné krvácanie po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.2","desc":"Embólia po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.3","desc":"Šok po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.4","desc":"Zlyhávanie obličiek po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.5","desc":"Metabolické poruchy po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.6","desc":"Poškodenie panvových orgánov a tkanív po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.7","desc":"Iné žilové komplikácie po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.8","desc":"Iné komplikácie po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa"},
{"code":"O08.9","desc":"Komplikácie po potrate, mimomaternicovej gravidite a gravidite s molou hydatidosa, bližšie neurčené"},
{"code":"O09-O09","desc":"Trvanie gravidity"},
{"code":"O09.-!","desc":"Trvanie gravidity"},
{"code":"O09.0!","desc":"Trvanie gravidity menej ako 5 ukončených týždňov"},
{"code":"O09.1!","desc":"Trvanie gravidity od začatého 5. po ukončený 13. týždeň"},
{"code":"O09.2!","desc":"Trvanie gravidity od začatého 14. po ukončený 19. týždeň"},
{"code":"O09.3!","desc":"Trvanie gravidity od začatého 20. po ukončený 25. týždeň"},
{"code":"O09.4!","desc":"Trvanie gravidity od začatého 26. po ukončený 33.týždeň"},
{"code":"O09.5!","desc":"Trvanie gravidity od začatého 34. po ukončený 36. týždeň"},
{"code":"O09.6!","desc":"Trvanie gravidity od začatého 37. po ukončený 41.týždeň"},
{"code":"O09.7!","desc":"Trvanie gravidity viac ako 41 ukončených týždňov"},
{"code":"O09.9!","desc":"Trvanie gravidity, bližšie neurčené"},
{"code":"O10-O16","desc":"Edémy, proteinúria a artériová hypertenzia v gravidite, pri pôrode a v šestonedelí"},
{"code":"O10.-","desc":"Predtým existujúca artériová hypertenzia, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O10.0","desc":"Predtým existujúca primárna artériová hypertenzia, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O10.1","desc":"Predtým existujúca hypertenzná choroba srdca, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O10.2","desc":"Predtým existujúca hypertenzná choroba obličiek, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O10.3","desc":"Predtým existujúca hypertenzná choroba srdca a obličiek, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O10.4","desc":"Predtým existujúca sekundárna artériová hypertenzia, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O10.9","desc":"Predtým existujúca artériová hypertenzia, komplikujúca graviditu, pôrod a šestonedelie, bližšie neurčená"},
{"code":"O11","desc":"Predtým existujúca artériová hypertenzia komplikujúca graviditu, pôrod a šestonedelie, s nasadajúcou preeklampsiou"},
{"code":"O12.-","desc":"Gestačné (zapríčinené graviditou) opuchy a proteinúria bez hypertenzie"},
{"code":"O12.0","desc":"Gestačné opuchy"},
{"code":"O12.1","desc":"Gestačná proteinúria"},
{"code":"O12.2","desc":"Gestačné opuchy s proteinúriou"},
{"code":"O13","desc":"Gestačná (zapríčinená graviditou) artériová hypertenzia bez významnej proteinúrie"},
{"code":"O14.-","desc":"Gestačná (zapríčinená graviditou) artériová hypertenzia s významnou proteinúriou"},
{"code":"O14.0","desc":"Mierna a stredne ťažká preeklampsia"},
{"code":"O14.1","desc":"Ťažká preeklampsia"},
{"code":"O14.2","desc":"Syndróm HELLP"},
{"code":"O14.9","desc":"Preeklampsia, bližšie neurčená"},
{"code":"O15.-","desc":"Eklampsia"},
{"code":"O15.0","desc":"Eklampsia v gravidite"},
{"code":"O15.1","desc":"Eklampsia pri pôrode"},
{"code":"O15.2","desc":"Eklampsia v šestonedelí"},
{"code":"O15.9","desc":"Eklampsia, časovo bližšie neurčená"},
{"code":"O16","desc":"Artériová hypertenzia matky, bližšie neurčená"},
{"code":"O20-O29","desc":"Iné choroby matky súvisiace najmä s graviditou"},
{"code":"O20.-","desc":"Krvácanie vo včasnej gravidite"},
{"code":"O20.0","desc":"Hroziaci potrat (abortus imminens)"},
{"code":"O20.8","desc":"Iné krvácanie vo včasnej gravidite"},
{"code":"O20.9","desc":"Krvácanie vo včasnej gravidite, bližšie neurčené"},
{"code":"O21.-","desc":"Nadmerné vracanie v gravidite"},
{"code":"O21.0","desc":"Hyperemesis gravidarum mierneho stupňa"},
{"code":"O21.1","desc":"Hyperemesis gravidarum s poruchou vnútorného prostredia"},
{"code":"O21.2","desc":"Neskoré vracanie v gravidite"},
{"code":"O21.8","desc":"Iné vracanie, komplikujúce graviditu"},
{"code":"O21.9","desc":"Vracanie počas gravidity, bližšie neurčené"},
{"code":"O22.-","desc":"Žilové choroby ako komplikácie v gravidite"},
{"code":"O22.0","desc":"Varixy dolných končatín v gravidite"},
{"code":"O22.1","desc":"Varixy pohlavných orgánov v gravidite"},
{"code":"O22.2","desc":"Povrchová tromboflebitída v gravidite"},
{"code":"O22.3","desc":"Hĺbková flebotrombóza v gravidite"},
{"code":"O22.4","desc":"Hemoroidy v gravidite"},
{"code":"O22.5","desc":"Trombóza mozgových žíl v gravidite"},
{"code":"O22.8","desc":"Iné žilové choroby ako komplikácie v gravidite"},
{"code":"O22.9","desc":"Žilová choroba ako komplikácia v gravidite, bližšie neurčená"},
{"code":"O23.-","desc":"Infekcia močovopohlavných orgánov v gravidite"},
{"code":"O23.0","desc":"Infekcia obličiek v gravidite"},
{"code":"O23.1","desc":"Infekcia močového mechúra v gravidite"},
{"code":"O23.2","desc":"Infekcia močovej rúry v gravidite"},
{"code":"O23.3","desc":"Infekcia iných častí močovej sústavy v gravidite"},
{"code":"O23.4","desc":"Infekcia močovej sústavy v gravidite, bližšie neurčená"},
{"code":"O23.5","desc":"Infekcia pohlavných orgánov v gravidite"},
{"code":"O23.9","desc":"Iná a bližšie neurčená infekcia močovopohlavných orgánov v gravidite"},
{"code":"O24.-","desc":"Diabetes mellitus v gravidite"},
{"code":"O24.0","desc":"Diabetes mellitus v gravidite: predtým existujúci diabetes mellitus, typ 1 (diabetes mellitus primárne závislý od inzulínu)"},
{"code":"O24.1","desc":"Diabetes mellitus v gravidite: predtým existujúci diabetes mellitus, typ 2 (diabetes mellitus primárne nezávislý od inzulínu)"},
{"code":"O24.2","desc":"Diabetes mellitus v gravidite: predtým existujúci diabetes mellitus súvisiaci s podvýživou"},
{"code":"O24.3","desc":"Diabetes mellitus v gravidite: predtým existujúci diabetes mellitus, bližšie neurčený"},
{"code":"O24.4","desc":"Diabetes mellitus so vznikom v gravidite"},
{"code":"O24.9","desc":"Diabetes mellitus v gravidite, bližšie neurčený"},
{"code":"O25","desc":"Podvýživa v gravidite"},
{"code":"O26.-","desc":"Starostlivosť o matku pre iné stavy súvisiace najmä s graviditou"},
{"code":"O26.0","desc":"Nadmerný hmotnostný prírastok v gravidite"},
{"code":"O26.1","desc":"Malý hmotnostný prírastok v gravidite"},
{"code":"O26.2","desc":"Starostlivosť o gravidnú ženu pri habituálnom potrácaní"},
{"code":"O26.3","desc":"Ponechaná vnútromaternicová antikoncepčná pomôcka v gravidite"},
{"code":"O26.4","desc":"Herpes gestationis"},
{"code":"O26.5","desc":"Syndróm artériovej hypotenzie matky"},
{"code":"O26.6","desc":"Choroba pečene v gravidite, pri pôrode a v šestonedelí"},
{"code":"O26.7","desc":"Subluxácia symphysis ossis pubis v gravidite, pri pôrode a v šestonedelí"},
{"code":"O26.8-","desc":"Iné stavy súvisiace s graviditou, bližšie určené"},
{"code":"O26.81","desc":"Choroba obličiek súvisiaca s graviditou"},
{"code":"O26.82","desc":"Syndróm karpálneho tunela počas gravidity"},
{"code":"O26.83","desc":"Periférna neuritída počas gravidity"},
{"code":"O26.88","desc":"Iné stavy súvisiace s graviditou, bližšie určené"},
{"code":"O26.9","desc":"Stav súvisiaci s graviditou, bližšie neurčený"},
{"code":"O28.-","desc":"Abnormálny nález pri prenatálnom skríningu matky"},
{"code":"O28.0","desc":"Abnormálny hematologický nález v prenatálnom skríningu matky"},
{"code":"O28.1","desc":"Abnormálny biochemický nález v prenatálnom skríningu matky"},
{"code":"O28.2","desc":"Abnormálny cytologický nález v prenatálnom skríningu matky"},
{"code":"O28.3","desc":"Abnormálny ultrazvukový nález v prenatálnom skríningu matky"},
{"code":"O28.4","desc":"Abnormálny rádiologický nález v prenatálnom skríningu matky"},
{"code":"O28.5","desc":"Abnormálny chromozómový a genetický nález v prenatálnom skríningu matky"},
{"code":"O28.8","desc":"Iný abnormálny nález v prenatálnom skríningu matky"},
{"code":"O28.9","desc":"Abnormálny nález v prenatálnom skríningu matky, bližšie neurčený"},
{"code":"O29.-","desc":"Komplikácie anestézie v gravidite"},
{"code":"O29.0","desc":"Pľúcne komplikácie anestézie v gravidite"},
{"code":"O29.1","desc":"Srdcové komplikácie anestézie v gravidite"},
{"code":"O29.2","desc":"Komplikácie centrálnej nervovej sústavy v spojení s anestéziou v gravidite"},
{"code":"O29.3","desc":"Toxická reakcia na lokálnu anestéziu v gravidite"},
{"code":"O29.4","desc":"Bolesť hlavy po spinálnej a epidurálnej anestézii v gravidite"},
{"code":"O29.5","desc":"Iná komplikácia spinálnej a epidurálnej anestézie v gravidite"},
{"code":"O29.6","desc":"Neúspešná alebo sťažená intubácia v gravidite"},
{"code":"O29.8","desc":"Iné komplikácie anestézie v gravidite"},
{"code":"O29.9","desc":"Komplikácia anestézie v gravidite, bližšie neurčená"},
{"code":"O30-O48","desc":"Starostlivosť o matku so zameraním na plod, amniovú dutinu a na možné komplikácie pri pôrode"},
{"code":"O30.-","desc":"Viacplodová gravidita"},
{"code":"O30.0","desc":"Gravidita s dvojčatami"},
{"code":"O30.1","desc":"Gravidita s trojčatami"},
{"code":"O30.2","desc":"Gravidita so štvorčatami"},
{"code":"O30.8","desc":"Iná viacplodová gravidita"},
{"code":"O30.9","desc":"Viacplodová gravidita, bližšie neurčená"},
{"code":"O31.-","desc":"Komplikácie špecifické pre viacplodovú graviditu"},
{"code":"O31.0","desc":"Fetus papyraceus"},
{"code":"O31.1","desc":"Pokračujúca gravidita po potrate jedného alebo viacerých plodov"},
{"code":"O31.2","desc":"Pokračujúca gravidita po vnútromaternicovej smrti jedného alebo viacerých plodov"},
{"code":"O31.8","desc":"Iná komplikácia špecifická pre viacplodovú graviditu"},
{"code":"O32.-","desc":"Starostlivosť o matku pre zistené alebo suspektné nepravidelné naliehanie plodu"},
{"code":"O32.0","desc":"Starostlivosť o matku pre nestabilnú polohu plodu"},
{"code":"O32.1","desc":"Starostlivosť o matku pre naliehanie plodu panvovým koncom"},
{"code":"O32.2","desc":"Starostlivosť o matku pre priečnu a šikmú polohu plodu"},
{"code":"O32.3","desc":"Starostlivosť o matku pre tvárovú a čelovú polohu plodu a pri naliehaní plodu bradou"},
{"code":"O32.4","desc":"Starostlivosť o matku pre veľkú hlavičku plodu v termíne"},
{"code":"O32.5","desc":"Starostlivosť o matku pre viacplodovú graviditu s nesprávnym naliehaním a nesprávnou polohou jedného alebo viacerých plodov"},
{"code":"O32.6","desc":"Starostlivosť o matku pre súčasné nesprávne naliehanie a nesprávnu polohu plodu"},
{"code":"O32.8","desc":"Starostlivosť o matku pre iné nesprávne naliehanie alebo nesprávnu polohu plodu"},
{"code":"O32.9","desc":"Starostlivosť o matku pre iné nepravidelné naliehanie alebo nesprávnu polohu plodu, bližšie neurčené"},
{"code":"O33.-","desc":"Starostlivosť o matku pri zistenom alebo suspektnom nepomere medzi plodom a panvou"},
{"code":"O33.0","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou, zapríčinený deformitou kostí panvy"},
{"code":"O33.1","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou, zapríčinený celkovo zúženou panvou"},
{"code":"O33.2","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou, zapríčinený zúžením panvového vchodu"},
{"code":"O33.3","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou, zapríčinený zúžením panvového východu"},
{"code":"O33.4","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou, zapríčinený rozličnými príčinami zo strany matky a plodu"},
{"code":"O33.5","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou, zapríčinený nezvyčajne veľkým plodom"},
{"code":"O33.6","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou, zapríčinený hydrocefalickým plodom"},
{"code":"O33.7","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou, zapríčinený inými abnormalitami plodu"},
{"code":"O33.8","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou z iných príčin"},
{"code":"O33.9","desc":"Starostlivosť o matku pre nepomer medzi plodom a panvou, bližšie neurčený"},
{"code":"O34.-","desc":"Starostlivosť o matku pre zistenú alebo suspektnú abnormalitu panvových orgánov matky"},
{"code":"O34.0","desc":"Starostlivosť o matku pre vrodenú chybu maternice"},
{"code":"O34.1","desc":"Starostlivosť o matku pre nádor tela maternice"},
{"code":"O34.2","desc":"Starostlivosť o matku pre jazvu na maternici po predchádzajúcom chirurgickom výkone"},
{"code":"O34.3-","desc":"Starostlivosť o matku pre insuficienciu krčka maternice"},
{"code":"O34.30","desc":"Starostlivosť o matku pri dĺžke krčka maternice pod 10 mm alebo pri insuficiencii, potvrdenej vaginálnou ultrasonografiou"},
{"code":"O34.31","desc":"Starostlivosť o matku pri prolapse plodového vaku"},
{"code":"O34.38","desc":"Starostlivosť o matku pri inej insuficiencii krčka maternice"},
{"code":"O34.39","desc":"Starostlivosť o matku pri bližšie neurčenej insuficiencii krčka maternice"},
{"code":"O34.4","desc":"Starostlivosť o matku pre iné abnormality krčka maternice"},
{"code":"O34.5","desc":"Starostlivosť o matku pre iné abnormality gravidnej maternice"},
{"code":"O34.6","desc":"Starostlivosť o matku pre abnormalitu pošvy"},
{"code":"O34.7","desc":"Starostlivosť o matku pre abnormalitu vulvy a hrádze"},
{"code":"O34.8","desc":"Starostlivosť o matku pre iné abnormality panvových orgánov"},
{"code":"O34.9","desc":"Starostlivosť o matku pre abnormalitu panvových orgánov, bližšie neurčenú"},
{"code":"O35.-","desc":"Starostlivosť o matku pre zistenú alebo suspektnú abnormalitu a poškodenie plodu"},
{"code":"O35.0","desc":"Starostlivosť o matku pre (suspektnú) vývinovú chybu centrálnej nervovej sústavy plodu"},
{"code":"O35.1","desc":"Starostlivosť o matku pre (suspektnú) chromozómovú anomáliu plodu"},
{"code":"O35.2","desc":"Starostlivosť o matku pre (suspektnú) dedičnú chorobu plodu"},
{"code":"O35.3","desc":"Starostlivosť o matku pre (suspektné) poškodenie plodu vírusovou chorobou matky"},
{"code":"O35.4","desc":"Starostlivosť o matku pre (suspektné) poškodene plodu alkoholom"},
{"code":"O35.5","desc":"Starostlivosť o matku pre (suspektné) poškodenie plodu liekom alebo drogou"},
{"code":"O35.6","desc":"Starostlivosť o matku pre (suspektné) poškodenie plodu žiarením"},
{"code":"O35.7","desc":"Starostlivosť o matku pre (suspektné) poškodenie plodu iným lekárskym výkonom"},
{"code":"O35.8","desc":"Starostlivosť o matku pre inú (suspektnú) abnormalitu a (suspektné) poškodenie plodu"},
{"code":"O35.9","desc":"Starostlivosť o matku pre (suspektnú) bližšie neurčenú abnormalitu a (suspektné) poškodenie plodu"},
{"code":"O36.-","desc":"Starostlivosť o matku pre iné zistené alebo suspektné problémy plodu"},
{"code":"O36.0","desc":"Starostlivosť o matku pre izoimunizáciu v systéme Rh"},
{"code":"O36.1","desc":"Starostlivosť o matku pre inú izoimunizáciu"},
{"code":"O36.2","desc":"Starostlivosť o matku pre fetálny hydrops"},
{"code":"O36.3","desc":"Starostlivosť o matku pre príznaky hypoxie plodu"},
{"code":"O36.4","desc":"Starostlivosť o matku pre vnútromaternicovú smrť plodu"},
{"code":"O36.5","desc":"Starostlivosť o matku pre zníženú hmotnosť plodu"},
{"code":"O36.6","desc":"Starostlivosť o matku pre nadmernú hmotnosť plodu"},
{"code":"O36.7","desc":"Starostlivosť o matku pre abdominálnu graviditu so živým plodom"},
{"code":"O36.8","desc":"Starostlivosť o matku pre iné problémy plodu, bližšie určené"},
{"code":"O36.9","desc":"Starostlivosť o matku pre problémy plodu, bližšie neurčené"},
{"code":"O40","desc":"Polyhydramnion"},
{"code":"O41.-","desc":"Iné poruchy plodovej vody a plodových obalov"},
{"code":"O41.0","desc":"Oligohydramnion"},
{"code":"O41.1","desc":"Infekcia plodového vaku a plodových obalov"},
{"code":"O41.8","desc":"Iné poruchy plodovej vody a obalov, bližšie určené"},
{"code":"O41.9","desc":"Poruchy plodovej vody a obalov, bližšie neurčené"},
{"code":"O42.-","desc":"Predčasné puknutie plodových obalov"},
{"code":"O42.0","desc":"Predčasné puknutie plodových obalov, začiatok pôrodnej činnosti do 24 hodín"},
{"code":"O42.1-","desc":"Predčasné puknutie plodových obalov, začiatok pôrodnej činnosti po 24 hodinách"},
{"code":"O42.11","desc":"Predčasné puknutie plodových obalov, začiatok pôrodnej činnosti po 1 až 7 dňoch"},
{"code":"O42.12","desc":"Predčasné puknutie plodových obalov, začiatok pôrodnej činnosti po viac ako 7 dňoch"},
{"code":"O42.2","desc":"Predčasné puknutie plodových obalov, pôrod oddialený liečbou"},
{"code":"O42.9","desc":"Predčasné puknutie plodových obalov, bližšie neurčené"},
{"code":"O43.-","desc":"Porucha placenty"},
{"code":"O43.0","desc":"Syndróm placentárnej transfúzie"},
{"code":"O43.1","desc":"Malformácia placenty"},
{"code":"O43.2-","desc":"Chorobne priľnavá placenta"},
{"code":"O43.20","desc":"Placenta accreta"},
{"code":"O43.21","desc":"Placenta increta alebo placenta percreta"},
{"code":"O43.8","desc":"Iná porucha placenty"},
{"code":"O43.9","desc":"Porucha placenty, bližšie neurčená"},
{"code":"O44.-","desc":"Placenta praevia a nízko uložená placenta"},
{"code":"O44.0-","desc":"Placenta praevia a nízko uložená placenta bez (prebiehajúceho) krvácania"},
{"code":"O44.00","desc":"Nízko uložená placenta bez krvácania"},
{"code":"O44.01","desc":"Placenta praevia bez krvácania"},
{"code":"O44.1-","desc":"Placenta praevia a nízko uložená placenta s prebiehajúcim krvácaním"},
{"code":"O44.10","desc":"Nízko uložená placenta s prebiehajúcim krvácaním"},
{"code":"O44.11","desc":"Placenta praevia s prebiehajúcim krvácaním"},
{"code":"O45.-","desc":"Predčasné odlupovanie placenty [Abruptio placentae]"},
{"code":"O45.0","desc":"Predčasné odlupovanie placenty s poruchou koagulácie"},
{"code":"O45.8","desc":"Iné predčasné odlupovanie placenty"},
{"code":"O45.9","desc":"Predčasné odlupovanie placenty, bližšie neurčené"},
{"code":"O46.-","desc":"Krvácanie pred pôrodom, nezatriedené inde"},
{"code":"O46.0","desc":"Krvácanie pred pôrodom s poruchou zrážanlivosti"},
{"code":"O46.8","desc":"Iné krvácanie pred pôrodom"},
{"code":"O46.9","desc":"Krvácanie pred pôrodom, bližšie neurčené"},
{"code":"O47.-","desc":"Falošná pôrodná činnosť"},
{"code":"O47.0","desc":"Falošná pôrodná činnosť pred ukončením 37. týždňa gravidity"},
{"code":"O47.1","desc":"Falošná pôrodná činnosť po ukončení 37. týždňa gravidity a neskôr"},
{"code":"O47.9","desc":"Falošná pôrodná činnosť, bližšie neurčená"},
{"code":"O48","desc":"Predĺžená gravidita"},
{"code":"O60-O75","desc":"Komplikácie pôrodnej činnosti a pôrodu"},
{"code":"O60.-","desc":"Predčasná pôrodná činnosť a pôrod"},
{"code":"O60.0","desc":"Predčasná pôrodná činnosť bez pôrodu"},
{"code":"O60.1","desc":"Predčasná pôrodná činnosť s predčasným pôrodom"},
{"code":"O60.2","desc":"Predčasná pôrodná činnosť s pôrodom v termíne"},
{"code":"O60.3","desc":"Predčasný pôrod bez spontánnej pôrodnej činnosti"},
{"code":"O61.-","desc":"Neúspešná indukcia pôrodnej činnosti"},
{"code":"O61.0","desc":"Neúspešná lieková indukcia pôrodnej činnosti"},
{"code":"O61.1","desc":"Neúspešná inštrumentálna indukcia pôrodnej činnosti"},
{"code":"O61.8","desc":"Iná neúspešná indukcia pôrodnej činnosti"},
{"code":"O61.9","desc":"Neúspešná indukcia pôrodnej činnosti, bližšie neurčená"},
{"code":"O62.-","desc":"Porucha pôrodnej činnosti"},
{"code":"O62.0","desc":"Primárne slabá pôrodná činnosť"},
{"code":"O62.1","desc":"Sekundárne slabá pôrodná činnosť"},
{"code":"O62.2","desc":"Iná slabá pôrodná činnosť"},
{"code":"O62.3","desc":"Náhly pôrod (partus praecipitatus)"},
{"code":"O62.4","desc":"Hypertonické, nekoordinované a predĺžené kontrakcie maternice"},
{"code":"O62.8","desc":"Iná porucha pôrodnej činnosti"},
{"code":"O62.9","desc":"Porucha pôrodnej činnosti, bližšie neurčená"},
{"code":"O63.-","desc":"Predĺžený pôrod"},
{"code":"O63.0","desc":"Predĺžená prvá pôrodná doba"},
{"code":"O63.1","desc":"Predĺžená druhá pôrodná doba"},
{"code":"O63.2","desc":"Oneskorený pôrod druhého dvojčaťa, trojčaťa atď."},
{"code":"O63.9","desc":"Predĺžený pôrod, bližšie neurčený"},
{"code":"O64.-","desc":"Nepokračujúci pôrod, zapríčinený nesprávnou polohou a naliehaním plodu"},
{"code":"O64.0","desc":"Nepokračujúci pôrod, zapríčinený nekompletnou rotáciou hlavičky plodu"},
{"code":"O64.1","desc":"Nepokračujúci pôrod, zapríčinený polohou panvovým koncom"},
{"code":"O64.2","desc":"Nepokračujúci pôrod, zapríčinený tvárovou polohou"},
{"code":"O64.3","desc":"Nepokračujúci pôrod, zapríčinený čelovou polohou"},
{"code":"O64.4","desc":"Nepokračujúci pôrod, zapríčinený naliehaním pliecka"},
{"code":"O64.5","desc":"Nepokračujúci pôrod, zapríčinený kombinovanou poruchou naliehania plodu"},
{"code":"O64.8","desc":"Nepokračujúci pôrod, zapríčinený inou nesprávnou polohou a naliehaním plodu"},
{"code":"O64.9","desc":"Nepokračujúci pôrod, zapríčinený nesprávnou polohou a naliehaním plodu, bližšie neurčený"},
{"code":"O65.-","desc":"Nepokračujúci pôrod, zapríčinený abnormalitami panvy matky"},
{"code":"O65.0","desc":"Nepokračujúci pôrod, zapríčinený deformovanou panvou"},
{"code":"O65.1","desc":"Nepokračujúci pôrod, zapríčinený celkovo zúženou panvou"},
{"code":"O65.2","desc":"Nepokračujúci pôrod, zapríčinený zúžením panvového vchodu"},
{"code":"O65.3","desc":"Nepokračujúci pôrod, zapríčinený zúžením panvového východu a kontrakciou strednej časti panvovej dutiny"},
{"code":"O65.4","desc":"Nepokračujúci pôrod, zapríčinený nepomerom medzi plodom a panvou, bližšie neurčený"},
{"code":"O65.5","desc":"Nepokračujúci pôrod, zapríčinený abnormalitami panvových orgánov matky"},
{"code":"O65.8","desc":"Nepokračujúci pôrod, zapríčinený inými abnormalitami panvy matky"},
{"code":"O65.9","desc":"Nepokračujúci pôrod, zapríčinený abnormalitou panvy matky, bližšie neurčený"},
{"code":"O66.-","desc":"Iný nepokračujúci pôrod"},
{"code":"O66.0","desc":"Nepokračujúci pôrod, zapríčinený dystokiou pliecka plodu"},
{"code":"O66.1","desc":"Komplikácia pôrodu zapríčinená zakliesnením dvojčiat"},
{"code":"O66.2","desc":"Nepokračujúci pôrod, zapríčinený nezvyčajne veľkým plodom"},
{"code":"O66.3","desc":"Nepokračujúci pôrod, zapríčinený inými abnormalitami plodu"},
{"code":"O66.4","desc":"Neúspešný pokus o pôrod, bližšie neurčený"},
{"code":"O66.5","desc":"Neúspešné použitie vákuového extraktora alebo klieští, bližšie neurčené"},
{"code":"O66.8","desc":"Iný nepokračujúci pôrod, bližšie určený"},
{"code":"O66.9","desc":"Nepokračujúci pôrod, bližšie neurčený"},
{"code":"O67.-","desc":"Pôrodná činnosť a pôrod, komplikované krvácaním pri pôrode, nezatriedené inde"},
{"code":"O67.0","desc":"Krvácanie pri pôrode s poruchou koagulácie"},
{"code":"O67.8","desc":"Iné krvácanie pri pôrode"},
{"code":"O67.9","desc":"Krvácanie pri pôrode, bližšie neurčené"},
{"code":"O68.-","desc":"Komplikácie pôrodnej činnosti a pôrodu distresom plodu"},
{"code":"O68.0","desc":"Komplikácie pôrodnej činnosti a pôrodu pre abnormálnu srdcovú frekvenciu plodu"},
{"code":"O68.1","desc":"Komplikácie pôrodnej činnosti a pôrodu pre mekónium v plodovej vode"},
{"code":"O68.2","desc":"Komplikácie pôrodnej činnosti a pôrodu pre abnormálnu srdcovú frekvenciu plodu s mekóniom v plodovej vode"},
{"code":"O68.3","desc":"Komplikácie pôrodnej činnosti a pôrodu pre biochemický dôkaz distresu plodu"},
{"code":"O68.8","desc":"Komplikácie pôrodnej činnosti a pôrodu pre iný dôkaz distresu plodu"},
{"code":"O68.9","desc":"Komplikácie pôrodnej činnosti a pôrodu pre distres plodu, bližšie neurčený"},
{"code":"O69.-","desc":"Komplikácie pôrodnej činnosti a pôrodu pre poruchy pupočníka"},
{"code":"O69.0","desc":"Komplikácie pôrodnej činnosti a pôrodu pre prolaps pupočníka"},
{"code":"O69.1","desc":"Komplikácie pôrodnej činnosti a pôrodu pre ovinutie pupočníka okolo krku, s kompresiou krku"},
{"code":"O69.2","desc":"Komplikácie pôrodnej činnosti a pôrodu pre iné zapletenie pupočníka, s kompresiou"},
{"code":"O69.3","desc":"Komplikácie pôrodnej činnosti a pôrodu pre krátky pupočník"},
{"code":"O69.4","desc":"Komplikácie pôrodnej činnosti a pôrodu pre vcestné cievy [vasa praevia]"},
{"code":"O69.5","desc":"Komplikácie pôrodnej činnosti a pôrodu pre poškodenie ciev pupočníka"},
{"code":"O69.8","desc":"Komplikácie pôrodnej činnosti a pôrodu pre iné poruchy pupočníka"},
{"code":"O69.9","desc":"Komplikácie pôrodnej činnosti a pôrodu pre poruchu pupočníka, bližšie neurčené"},
{"code":"O70.-","desc":"Natrhnutie hrádze pri pôrode"},
{"code":"O70.0","desc":"Natrhnutie hrádze 1. stupňa pri pôrode"},
{"code":"O70.1","desc":"Natrhnutie hrádze 2. stupňa pri pôrode"},
{"code":"O70.2","desc":"Natrhnutie hrádze 3. stupňa pri pôrode"},
{"code":"O70.3","desc":"Natrhnutie hrádze 4. stupňa pri pôrode"},
{"code":"O70.9","desc":"Natrhnutie hrádze pri pôrode, bližšie neurčené"},
{"code":"O71.-","desc":"Iné pôrodné poranenie"},
{"code":"O71.0","desc":"Ruptúra maternice pred začiatkom pôrodnej činnosti"},
{"code":"O71.1","desc":"Ruptúra maternice počas pôrodnej činnosti"},
{"code":"O71.2","desc":"Inverzia maternice po pôrode"},
{"code":"O71.3","desc":"Natrhnutie krčka maternice pri pôrode"},
{"code":"O71.4","desc":"Samostatná vysoká lacerácia pošvy pri pôrode"},
{"code":"O71.5","desc":"Iné poranenie panvových orgánov pri pôrode"},
{"code":"O71.6","desc":"Poškodenie panvových kĺbov a väzov pri pôrode"},
{"code":"O71.7","desc":"Hematóm panvy pri pôrode"},
{"code":"O71.8","desc":"Iné poranenie pri pôrode, bližšie určené"},
{"code":"O71.9","desc":"Poranenie pri pôrode, bližšie neurčené"},
{"code":"O72.-","desc":"Krvácanie po pôrode"},
{"code":"O72.0","desc":"Krvácanie v 3.pôrodnej dobe"},
{"code":"O72.1","desc":"Iné krvácanie bezprostredne po pôrode"},
{"code":"O72.2","desc":"Oneskorené a sekundárne krvácanie po pôrode"},
{"code":"O72.3","desc":"Poruchy zrážanlivosti krvi po pôrode"},
{"code":"O73.-","desc":"Zadržanie placenty a blán bez krvácania"},
{"code":"O73.0","desc":"Zadržanie placenty bez krvácania"},
{"code":"O73.1","desc":"Zadržanie častí placenty a blán bez krvácania"},
{"code":"O74.-","desc":"Komplikácie anestézie počas pôrodnej činnosti a pri pôrode"},
{"code":"O74.0","desc":"Aspiračná pneumónia zapríčinená anestéziou počas pôrodnej činnosti a pôrodu"},
{"code":"O74.1","desc":"Iné pľúcne komplikácie anestézie počas pôrodnej činnosti a pôrodu"},
{"code":"O74.2","desc":"Srdcové komplikácie anestézie počas pôrodnej činnosti a pôrodu"},
{"code":"O74.3","desc":"Komplikácie centrálnej nervovej sústavy v spojení s anestéziou počas pôrodnej činnosti a pôrodu"},
{"code":"O74.4","desc":"Toxická reakcia na lokálnu anestéziu počas pôrodnej činnosti a pôrodu"},
{"code":"O74.5","desc":"Bolesť hlavy po spinálnej a epidurálnej anestézii počas pôrodnej činnosti a pôrodu"},
{"code":"O74.6","desc":"Iné komplikácie pri spinálnej a epidurálnej anestézii počas pôrodnej činnosti a pôrodu"},
{"code":"O74.7","desc":"Neúspešná alebo sťažená intubácia počas pôrodnej činnosti a pôrodu"},
{"code":"O74.8","desc":"Iné komplikácie anestézie počas pôrodnej činnosti a pôrodu"},
{"code":"O74.9","desc":"Komplikácia anestézie počas pôrodnej činnosti a pôrodu, bližšie neurčená"},
{"code":"O75.-","desc":"Iná komplikácia pôrodnej činnosti a pôrodu, nezatriedené inde"},
{"code":"O75.0","desc":"Distres matky počas pôrodnej činnosti a pôrodu"},
{"code":"O75.1","desc":"Šok počas pôrodnej činnosti, po nej a po pôrode"},
{"code":"O75.2","desc":"Horúčka počas pôrodu, nezatriedená inde"},
{"code":"O75.3","desc":"Iná infekcia počas pôrodnej činnosti"},
{"code":"O75.4","desc":"Iná komplikácia pôrodníckych operácií a výkonov"},
{"code":"O75.5","desc":"Protrahovaný pôrod po umelom pretrhnutí plodových blán"},
{"code":"O75.6","desc":"Protrahovaný pôrod po spontánnom alebo bližšie neurčenom puknutí plodových blán"},
{"code":"O75.7","desc":"Vaginálny pôrod po predchádzajúcom cisárskom reze"},
{"code":"O75.8","desc":"Iná komplikácia pôrodnej činnosti a pôrodu, bližšie určená"},
{"code":"O75.9","desc":"Komplikácia pôrodnej činnosti a pôrodu, bližšie neurčená"},
{"code":"O80-O84","desc":"Pôrod"},
{"code":"O80.-","desc":"Spontánny pôrod jedného plodu"},
{"code":"O80.0","desc":"Spontánny pôrod jedného plodu záhlavím"},
{"code":"O80.1","desc":"Spontánny pôrod jedného plodu panvovým koncom"},
{"code":"O80.8","desc":"Iný spontánny pôrod jedného plodu"},
{"code":"O80.9","desc":"Spontánny pôrod jedného plodu, bližšie neurčený"},
{"code":"O81.-","desc":"Pôrod jedného plodu kliešťami alebo vákuovým extraktorom"},
{"code":"O81.0","desc":"Pôrod jedného plodu východovými kliešťami"},
{"code":"O81.1","desc":"Pôrod jedného plodu strednými kliešťami"},
{"code":"O81.2","desc":"Pôrod jedného plodu strednými kliešťami s rotáciou hlavičky"},
{"code":"O81.3","desc":"Iný pôrod jedného plodu kliešťami, bližšie neurčený"},
{"code":"O81.4","desc":"Pôrod jedného plodu vákuovým extraktorom"},
{"code":"O81.5","desc":"Pôrod jedného plodu s použitím kombinácie klieští a vákuového extraktora"},
{"code":"O82.-","desc":"Pôrod jedného plodu cisárskym rezom"},
{"code":"O82.0","desc":"Pôrod jedného plodu elektívnym cisárskym rezom"},
{"code":"O82.1","desc":"Pôrod jedného plodu naliehavým cisárskym rezom"},
{"code":"O82.2","desc":"Pôrod jedného plodu cisárskym rezom s hysterektómiou"},
{"code":"O82.8","desc":"Iný pôrod jedného plodu cisárskym rezom"},
{"code":"O82.9","desc":"Pôrod cisárskym rezom, bližšie neurčený"},
{"code":"O83.-","desc":"Iné vedenie pôrodu jedného plodu"},
{"code":"O83.0","desc":"Extrakcia plodu pri polohe panvovým koncom"},
{"code":"O83.1","desc":"Iné vedenie pôrodu jedného plodu pri polohe panvovým koncom"},
{"code":"O83.2","desc":"Iné operačné vedenie pôrodu jedného plodu"},
{"code":"O83.3","desc":"Pôrod živého plodu pri brušnej gravidite"},
{"code":"O83.4","desc":"Zmenšovacie operácie na uľahčenie pôrodu jedného plodu"},
{"code":"O83.8","desc":"Iné vedenie pôrodu jedného plodu, bližšie určené"},
{"code":"O83.9","desc":"Vedenie pôrodu jedného plodu, bližšie neurčené"},
{"code":"O84.-","desc":"Plody pri viacplodovej gravidite"},
{"code":"O84.0","desc":"Pôrody pri viacplodovej gravidite, všetky spontánne"},
{"code":"O84.1","desc":"Pôrody pri viacplodovej gravidite, všetky kliešťami a vákuumextraktorom"},
{"code":"O84.2","desc":"Pôrody pri viacplodovej gravidite, všetky cisárskym rezom"},
{"code":"O84.8","desc":"Iné pôrody pri viacplodovej gravidite"},
{"code":"O84.9","desc":"Pôrody pri viacplodovej gravidite, bližšie neurčené"},
{"code":"O85-O92","desc":"Komplikácie vyskytujúce sa najmä v šestonedelí"},
{"code":"O85","desc":"Puerperálna sepsa"},
{"code":"O86.-","desc":"Iná puerperálna infekcia"},
{"code":"O86.0","desc":"Infekcia pôrodníckej operačnej rany"},
{"code":"O86.1","desc":"Iná infekcia pohlavných orgánov po pôrode"},
{"code":"O86.2","desc":"Infekcia močových orgánov po pôrode"},
{"code":"O86.3","desc":"Iná infekcia močovopohlavnej sústavy po pôrode"},
{"code":"O86.4","desc":"Horúčka neznámeho pôvodu po pôrode"},
{"code":"O86.8","desc":"Iná puerperálna infekcia, bližšie určená"},
{"code":"O87.-","desc":"Žilové choroby ako komplikácie v šestonedelí"},
{"code":"O87.0","desc":"Povrchová tromboflebitída v šestonedelí"},
{"code":"O87.1","desc":"Hĺbková žilová trombóza (flebotrombóza) v šestonedelí"},
{"code":"O87.2","desc":"Hemoroidy v šestonedelí"},
{"code":"O87.3","desc":"Trombóza mozgových žíl v šestonedelí"},
{"code":"O87.8","desc":"Iné žilové choroby ako komplikácie v šestonedelí"},
{"code":"O87.9","desc":"Žilová choroba ako komplikácia v šestonedelí, bližšie neurčená"},
{"code":"O88.-","desc":"Embólia v gestačnom období"},
{"code":"O88.0","desc":"Vzduchová embólia v gestačnom období"},
{"code":"O88.1","desc":"Embólia plodovou vodou"},
{"code":"O88.2-","desc":"Tromboembólia v gestačnom období"},
{"code":"O88.20","desc":"Tromboembólia pľúc v gestačnom období"},
{"code":"O88.28","desc":"Iná tromboembólia v gestačnom období"},
{"code":"O88.3","desc":"Pyemická a septická embólia v gestačnom období"},
{"code":"O88.8","desc":"Iná embólia v gestačnom období"},
{"code":"O89.-","desc":"Komplikácie anestézie v šestonedelí"},
{"code":"O89.0","desc":"Pľúcne komplikácie anestézie v šestonedelí"},
{"code":"O89.1","desc":"Srdcové komplikácie anestézie v šestonedelí"},
{"code":"O89.2","desc":"Komplikácie centrálnej nervovej sústavy v spojení s anestéziou v šestonedelí"},
{"code":"O89.3","desc":"Toxická reakcia na lokálnu anestéziu v šestonedelí"},
{"code":"O89.4","desc":"Bolesť hlavy po spinálnej a epidurálnej anestézii v šestonedelí"},
{"code":"O89.5","desc":"Iné komplikácie spinálnej a epidurálnej anestézie v šestonedelí"},
{"code":"O89.6","desc":"Neúspešná alebo sťažená intubácia v šestonedelí"},
{"code":"O89.8","desc":"Iné komplikácie anestézie v šestonedelí"},
{"code":"O89.9","desc":"Komplikácia anestézie v šestonedelí, bližšie neurčená"},
{"code":"O90.-","desc":"Komplikácie v šestonedelí, nezatriedené inde"},
{"code":"O90.0","desc":"Dehiscencia rany po cisárskom reze"},
{"code":"O90.1","desc":"Dehiscencia pôrodníckej rany hrádze"},
{"code":"O90.2","desc":"Hematóm pôrodníckej rany"},
{"code":"O90.3","desc":"Kardiomyopatia v šestonedelí"},
{"code":"O90.4","desc":"Akútne poškodenie obličiek po pôrode"},
{"code":"O90.5","desc":"Zápal štítnej žľazy po pôrode"},
{"code":"O90.8","desc":"Iná komplikácia šestonedelia, nezatriedená inde"},
{"code":"O90.9","desc":"Komplikácia šestonedelia, bližšie neurčená"},
{"code":"O91.-","desc":"Infekcie prsníka v súvislosti s pôrodom"},
{"code":"O91.0-","desc":"Infekcia bradavky v súvislosti s pôrodom"},
{"code":"O91.00","desc":"Infekcia bradavky v súvislosti s pôrodom, bez ťažkostí pri priložení"},
{"code":"O91.01","desc":"Infekcia bradavky v súvislosti s pôrodom, s ťažkosťami pri priložení"},
{"code":"O91.1-","desc":"Absces prsníka v súvislosti s pôrodom"},
{"code":"O91.10","desc":"Absces prsníka v súvislosti s pôrodom, bez ťažkostí pri priložení"},
{"code":"O91.11","desc":"Absces prsníka v súvislosti s pôrodom, s ťažkosťami pri priložení"},
{"code":"O91.2-","desc":"Nehnisová mastitída v súvislosti s pôrodom"},
{"code":"O91.20","desc":"Nehnisová mastitída v súvislosti s pôrodom, bez ťažkostí pri priložení"},
{"code":"O91.21","desc":"Nehnisová mastitída v súvislosti s pôrodom, s ťažkosťami pri priložení"},
{"code":"O92.-","desc":"Iná choroba prsníka a porucha laktácie v súvislosti s pôrodom"},
{"code":"O92.0-","desc":"Retrakcia bradavky v súvislosti s pôrodom"},
{"code":"O92.00","desc":"Retrakcia bradavky v súvislosti s pôrodom, bez ťažkostí pri priložení"},
{"code":"O92.01","desc":"Retrakcia bradavky v súvislosti s pôrodom, s ťažkosťami pri priložení"},
{"code":"O92.1-","desc":"Trhlinka bradavky v súvislosti s pôrodom"},
{"code":"O92.10","desc":"Trhlinka bradavky v súvislosti s pôrodom, bez ťažkostí pri priložení"},
{"code":"O92.11","desc":"Trhlinka bradavky v súvislosti s pôrodom, s ťažkosťami pri priložení"},
{"code":"O92.2-","desc":"Iná a bližšie neurčená choroba prsníka v súvislosti s pôrodom"},
{"code":"O92.20","desc":"Iná a bližšie neurčená choroba prsníka v súvislosti s pôrodom, bez ťažkostí pri priložení"},
{"code":"O92.21","desc":"Iná a bližšie neurčená choroba prsníka v súvislosti s pôrodom, s ťažkosťami pri priložení"},
{"code":"O92.3-","desc":"Agalakcia"},
{"code":"O92.30","desc":"Agalakcia, bez ťažkostí pri priložení"},
{"code":"O92.31","desc":"Agalakcia, s ťažkosťami pri priložení"},
{"code":"O92.4-","desc":"Hypogalakcia"},
{"code":"O92.40","desc":"Hypogalakcia, bez ťažkostí pri priložení"},
{"code":"O92.41","desc":"Hypogalakcia, s ťažkosťami pri priložení"},
{"code":"O92.5-","desc":"Zastavenie laktácie"},
{"code":"O92.50","desc":"Zastavenie laktácie, bez ťažkostí pri priložení"},
{"code":"O92.51","desc":"Zastavenie laktácie, s ťažkosťami pri priložení"},
{"code":"O92.6-","desc":"Galaktorea"},
{"code":"O92.60","desc":"Galaktorea, bez ťažkostí pri priložení"},
{"code":"O92.61","desc":"Galaktorea, s ťažkosťami pri priložení"},
{"code":"O92.7-","desc":"Iná porucha laktácie, bližšie neurčená"},
{"code":"O92.70","desc":"Iná a bližšie neurčená porucha laktácie, bez ťažkostí pri priložení"},
{"code":"O92.71","desc":"Iná a bližšie neurčená porucha laktácie, s ťažkosťami pri priložení"},
{"code":"O94-O99","desc":"Iné chorobné stavy v gravidite, pôrode a šestonedelí, nezatriedené inde"},
{"code":"O94","desc":"Následky komplikácií gravidity, pôrodu a šestonedelia"},
{"code":"O95","desc":"Smrť v gravidite z bližšie neurčenej príčiny"},
{"code":"O96.-","desc":"Smrť z akejkoľvek pôrodníckej príčiny, ktorá nastala po viac ako 42 dňoch, ale menej ako jeden rok od pôrodu"},
{"code":"O96.0","desc":"Smrť z priamej pôrodníckej príčiny, ktorá nastala po viac ako 42 dňoch, ale menej ako jeden rok od pôrodu"},
{"code":"O96.1","desc":"Smrť z nepriamej pôrodníckej príčiny, ktorá nastala po viac ako 42 dňoch, ale menej ako jeden rok od pôrodu"},
{"code":"O96.9","desc":"Smrť z bližšie neurčenej pôrodníckej príčiny, ktorá nastala po viac ako 42 dňoch, ale menej ako jeden rok od pôrodu"},
{"code":"O97.-","desc":"Smrť z následkov pôrodníckej príčiny"},
{"code":"O97.0","desc":"Smrť z následkov priamej pôrodníckej príčiny"},
{"code":"O97.1","desc":"Smrť z následkov nepriamej pôrodníckej príčiny"},
{"code":"O97.9","desc":"Smrť z následkov bližšie neurčenej pôrodníckej príčiny"},
{"code":"O98.-","desc":"Infekčná a parazitová choroba matky, ktorá sa dá zatriediť inde, ale komplikuje graviditu, pôrod a šestonedelie"},
{"code":"O98.0","desc":"Tuberkulóza, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O98.1","desc":"Syfilis, komplikujúci graviditu, pôrod a šestonedelie"},
{"code":"O98.2","desc":"Kvapavka, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O98.3","desc":"Iná infekcia s prevažne pohlavným spôsobom prenášania, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O98.4","desc":"Vírusová hepatitída, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O98.5","desc":"Iná vírusová choroba, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O98.6","desc":"Prvoková choroba, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O98.7","desc":"Choroba HIV, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O98.8","desc":"Iná infekčná a parazitová choroba matky, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O98.9","desc":"Infekčná a parazitová choroba matky, komplikujúca graviditu, pôrod a šestonedelie, bližšie neurčená"},
{"code":"O99.-","desc":"Iné choroby matky, ktorá sa dajú zatriediť inde, ale komplikujú graviditu, pôrod a šestonedelie"},
{"code":"O99.0","desc":"Anémia, komplikujúca graviditu, pôrod a šestonedelie"},
{"code":"O99.1","desc":"Iné choroby krvi a krvotvorných orgánov a určité poruchy imunitných mechanizmov, komplikujúce graviditu, pôrod a šestonedelie"},
{"code":"O99.2","desc":"Endokrinné, nutričné a metabolické choroby, komplikujúce graviditu, pôrod a šestonedelie"},
{"code":"O99.3","desc":"Duševné choroby a choroby nervovej sústavy, komplikujúce graviditu, pôrod a šestonedelie"},
{"code":"O99.4","desc":"Choroby obehovej sústavy, komplikujúce graviditu, pôrod a šestonedelie"},
{"code":"O99.5","desc":"Choroby dýchacej sústavy, komplikujúce graviditu, pôrod a šestonedelie"},
{"code":"O99.6","desc":"Choroby tráviacej sústavy, komplikujúce graviditu, pôrod a šestonedelie"},
{"code":"O99.7","desc":"Choroby kože a podkožného tkaniva, komplikujúce graviditu, pôrod a šestonedelie"},
{"code":"O99.8","desc":"Iné choroby a stavy, komplikujúce graviditu, pôrod a šestonedelie, bližšie určené"},
{"code":"P00-P96","desc":"XVI. kapitola - Určité choroby vzniknuté v perinatálnom období (P00-P96)"},
{"code":"P00-P04","desc":"Poškodenie plodu a novorodenca chorobami matky a komplikáciami gravidity, pôrodnej činnosti a pôrodu"},
{"code":"P00.-","desc":"Poškodenie plodu a novorodenca chorobami matky, ktoré nemusia súvisieť s terajšou graviditou"},
{"code":"P00.0","desc":"Poškodenie plodu a novorodenca artériovou hypertenziou matky"},
{"code":"P00.1","desc":"Poškodenie plodu a novorodenca chorobou obličiek a močových ciest matky"},
{"code":"P00.2","desc":"Poškodenie plodu a novorodenca infekčnými a parazitovými chorobami matky"},
{"code":"P00.3","desc":"Poškodenie plodu a novorodenca inými obehovými a dýchacími chorobami matky"},
{"code":"P00.4","desc":"Poškodenie plodu a novorodenca poruchami výživy matky"},
{"code":"P00.5","desc":"Poškodenie plodu a novorodenca poranením matky"},
{"code":"P00.6","desc":"Poškodenie plodu a novorodenca chirurgickým výkonom u matky"},
{"code":"P00.7","desc":"Poškodenie plodu a novorodenca inými lekárskymi výkonmi u matky, nezatriedenými inde"},
{"code":"P00.8","desc":"Poškodenie plodu a novorodenca inými stavmi matky"},
{"code":"P00.9","desc":"Poškodenie plodu a novorodenca bližšie neurčenými stavmi matky"},
{"code":"P01.-","desc":"Poškodenie plodu a novorodenca materskými komplikáciami v gravidite"},
{"code":"P01.0","desc":"Poškodenie plodu a novorodenca insuficienciou krčka maternice"},
{"code":"P01.1","desc":"Poškodenie plodu a novorodenca predčasným puknutím blán"},
{"code":"P01.2","desc":"Poškodenie plodu a novorodenca oligohydramniónom"},
{"code":"P01.3","desc":"Poškodenie plodu a novorodenca polyhydramniónom"},
{"code":"P01.4","desc":"Poškodenie plodu a novorodenca ektopickou graviditou"},
{"code":"P01.5","desc":"Poškodenie plodu a novorodenca viacpočetnou graviditou"},
{"code":"P01.6","desc":"Poškodenie plodu a novorodenca smrťou matky"},
{"code":"P01.7","desc":"Poškodenie plodu a novorodenca nepravidelným naliehaním pred začiatkom pôrodu"},
{"code":"P01.8","desc":"Poškodenie plodu a novorodenca inými materskými komplikáciami gravidity"},
{"code":"P01.9","desc":"Poškodenie plodu a novorodenca materskou komplikáciou gravidity, bližšie neurčené"},
{"code":"P02.-","desc":"Poškodenie plodu a novorodenca komplikáciami placenty, pupočníka a blán"},
{"code":"P02.0","desc":"Poškodenie plodu a novorodenca vcestnou placentou"},
{"code":"P02.1","desc":"Poškodenie plodu a novorodenca inými formami odlúpenia placenty a krvácaním"},
{"code":"P02.2","desc":"Poškodenie plodu a novorodenca inými a bližšie neurčenými morfologickými a funkčnými abnormalitami placenty"},
{"code":"P02.3","desc":"Poškodenie plodu a novorodenca placentovými transfúznymi syndrómami"},
{"code":"P02.4","desc":"Poškodenie plodu a novorodenca vykĺznutým pupočníkom"},
{"code":"P02.5","desc":"Poškodenie plodu a novorodenca iným stlačením pupočníka"},
{"code":"P02.6","desc":"Poškodenie plodu a novorodenca inými a bližšie neurčenými stavmi pupočníka"},
{"code":"P02.7","desc":"Poškodenie plodu a novorodenca chorioamnionitídou"},
{"code":"P02.8","desc":"Poškodenie plodu a novorodenca inými abnormalitami blán"},
{"code":"P02.9","desc":"Poškodenie plodu a novorodenca bližšie neurčenými abnormalitami blán"},
{"code":"P03.-","desc":"Poškodenie plodu a novorodenca inými komplikáciami pôrodnej činnosti a pôrodu"},
{"code":"P03.0","desc":"Poškodenie plodu a novorodenca pôrodom panvovým koncom a extrakciou"},
{"code":"P03.1","desc":"Poškodenie plodu a novorodenca inou nepravidelnosťou naliehania, držania tela a polohy a disproporciou počas pôrodnej činnosti a pôrodu"},
{"code":"P03.2","desc":"Poškodenie plodu a novorodenca kliešťovým pôrodom"},
{"code":"P03.3","desc":"Poškodenie plodu a novorodenca pôrodom vákuovým extraktorom"},
{"code":"P03.4","desc":"Poškodenie plodu a novorodenca pôrodom cisárskym rezom"},
{"code":"P03.5","desc":"Poškodenie plodu a novorodenca náhlym pôrodom"},
{"code":"P03.6","desc":"Poškodenie plodu a novorodenca abnormálnymi maternicovými kontrakciami"},
{"code":"P03.8","desc":"Poškodenie plodu a novorodenca inými bližšie určenými komplikáciami pôrodnej činnosti a pôrodu"},
{"code":"P03.9","desc":"Poškodenie plodu a novorodenca komplikáciou pôrodnej činnosti a pôrodu, bližšie neurčenou"},
{"code":"P04.-","desc":"Poškodenie plodu a novorodenca škodlivými látkami prenesenými placentou alebo materským mliekom"},
{"code":"P04.0","desc":"Poškodenie plodu a novorodenca anestéziou a analgéziou matky v gravidite, počas pôrodnej činnosti a pôrodu"},
{"code":"P04.1","desc":"Poškodenie plodu a novorodenca inou liečbou matky"},
{"code":"P04.2","desc":"Poškodenie plodu a novorodenca užívaním tabaku matkou"},
{"code":"P04.3","desc":"Poškodenie plodu a novorodenca užívaním alkoholu matkou"},
{"code":"P04.4","desc":"Poškodenie plodu a novorodenca užívaním návykových liekov a drog matkou"},
{"code":"P04.5","desc":"Poškodenie plodu a novorodenca chemickými látkami z potravy matky"},
{"code":"P04.6","desc":"Poškodenie plodu a novorodenca pri vystavení matky chemickým látkam zo životného prostredia"},
{"code":"P04.8","desc":"Poškodenie plodu a novorodenca inými škodlivými vplyvmi pôsobiacimi na matku"},
{"code":"P04.9","desc":"Poškodenie plodu a novorodenca škodlivým vplyvom pôsobiacim na matku, bližšie neurčeným"},
{"code":"P05-P08","desc":"Poruchy súvisiace s dĺžkou gravidity a s vývinom plodu"},
{"code":"P05.-","desc":"Nedostatočný rast a podvýživa plodu"},
{"code":"P05.0","desc":"Plod priveľmi ľahký na svoj gestačný vek"},
{"code":"P05.1","desc":"Plod priveľmi malý na svoj gestačný vek"},
{"code":"P05.2","desc":"Podvýživa plodu bez uvedenia, či ide o priveľmi ľahký alebo priveľmi malý plod na svoj gestačný vek"},
{"code":"P05.9","desc":"Pomalý rast plodu, bližšie neurčený"},
{"code":"P07.-","desc":"Porucha súvisiaca so skrátenou graviditou a s malou pôrodnou hmotnosťou, nezatriedená inde"},
{"code":"P07.0-","desc":"Novorodenec s extrémne malou pôrodnou hmotnosťou"},
{"code":"P07.00","desc":"Novorodenec s pôrodnou hmotnosťou menej ako 500 gramov"},
{"code":"P07.01","desc":"Novorodenec s pôrodnou hmotnosťou od 500 do 750 gramov"},
{"code":"P07.02","desc":"Novorodenec s pôrodnou hmotnosťou od 750 do 1000 gramov"},
{"code":"P07.1-","desc":"Novorodenec s inou nízkou pôrodnou hmotnosťou"},
{"code":"P07.10","desc":"Novorodenec s pôrodnou hmotnosťou od 1000 do 1250 gramov"},
{"code":"P07.11","desc":"Novorodenec s pôrodnou hmotnosťou od 1250 do 1500 gramov"},
{"code":"P07.12","desc":"Novorodenec s pôrodnou hmotnosťou od 1500 do 2500 gramov"},
{"code":"P07.2","desc":"Novorodenec s extrémnou nezrelosťou"},
{"code":"P07.3","desc":"Iný predčasne narodený novorodenec"},
{"code":"P08.-","desc":"Porucha súvisiaca s predĺženou graviditou a s vysokou pôrodnou hmotnosťou"},
{"code":"P08.0","desc":"Výnimočne veľký novorodenec"},
{"code":"P08.1","desc":"Iný novorodenec priveľmi ťažký vzhľadom na dĺžku gravidity"},
{"code":"P08.2","desc":"Prenášaný novorodenec, ktorý nie je priveľmi ťažký vzhľadom na dĺžku gravidity"},
{"code":"P10-P15","desc":"Poranenia pri pôrode"},
{"code":"P10.-","desc":"Intrakraniálne poranenie a krvácanie zapríčinené poranením pri pôrode"},
{"code":"P10.0","desc":"Subdurálne krvácanie zapríčinené poranením pri pôrode"},
{"code":"P10.1","desc":"Mozgové krvácanie zapríčinené poranením pri pôrode"},
{"code":"P10.2","desc":"Vnútrokomorové krvácanie zapríčinené poranením pri pôrode"},
{"code":"P10.3","desc":"Subarachnoidálne krvácanie zapríčinené poranením pri pôrode"},
{"code":"P10.4","desc":"Roztrhnutie tentória zapríčinené poranením pri pôrode"},
{"code":"P10.8","desc":"Iné vnútrolebkové poranenia a krvácania zapríčinené poranením pri pôrode"},
{"code":"P10.9","desc":"Bližšie neurčené vnútrolebkové poranenie a krvácanie zapríčinené poranením pri pôrode"},
{"code":"P11.-","desc":"Iné pôrodné poranenie centrálnej nervovej sústavy"},
{"code":"P11.0","desc":"Mozgový opuch, zapríčinený poranením pri pôrode"},
{"code":"P11.1","desc":"Iné bližšie určené poškodenie mozgu zapríčinené poranením pri pôrode"},
{"code":"P11.2","desc":"Bližšie neurčené poškodenie mozgu zapríčinené poranením pri pôrode"},
{"code":"P11.3","desc":"Pôrodné poranenie tvárového nervu"},
{"code":"P11.4","desc":"Pôrodné poranenie iných hlavových nervov"},
{"code":"P11.5-","desc":"Pôrodné poranenie chrbtice a miechy"},
{"code":"P11.50","desc":"Pôrodné poranenie chrbtice a miechy s akútnym ochrnutím"},
{"code":"P11.51","desc":"Pôrodné poranenie chrbtice a miechy s chronickým ochrnutím"},
{"code":"P11.59","desc":"Pôrodné poranenie chrbtice a miechy bez ochrnutia"},
{"code":"P11.9","desc":"Pôrodné poranenie centrálnej nervovej sústavy, bližšie neurčené"},
{"code":"P12.-","desc":"Pôrodné poranenie vlasatej časti hlavy"},
{"code":"P12.0","desc":"Zakrvácanie pod okosticu lebky zapríčinené poranením pri pôrode"},
{"code":"P12.1","desc":"Pôrodný nádor záhlavia, zapríčinený poranením pri pôrode"},
{"code":"P12.2","desc":"Epikraniálne subaponeurotické krvácanie zapríčinené poranením pri pôrode"},
{"code":"P12.3","desc":"Podliatina vlasatej časti hlavy zapríčinená poranením pri pôrode"},
{"code":"P12.4","desc":"Poranenie vlasatej časti hlavy novorodenca zapríčinené monitorovaním"},
{"code":"P12.8","desc":"Iné pôrodné poranenia vlasatej časti hlavy"},
{"code":"P12.9","desc":"Pôrodné poranenia vlasatej časti hlavy, bližšie neurčené"},
{"code":"P13.-","desc":"Pôrodné poranenie kostry"},
{"code":"P13.0","desc":"Zlomenina lebky zapríčinená poranením pri pôrode"},
{"code":"P13.1","desc":"Iné pôrodné poranenia lebky"},
{"code":"P13.2","desc":"Pôrodné poranenie stehnovej kosti"},
{"code":"P13.3","desc":"Pôrodné poranenie iných dlhých kostí"},
{"code":"P13.4","desc":"Zlomenina kľúčnej kosti zapríčinená poranením pri pôrode"},
{"code":"P13.8","desc":"Pôrodné poranenie iných častí kostry"},
{"code":"P13.9","desc":"Pôrodné poranenie kostry, bližšie neurčené"},
{"code":"P14.-","desc":"Pôrodné poranenie periférnej nervovej sústavy"},
{"code":"P14.0","desc":"Erbovo ochrnutie zapríčinené poranením pri pôrode"},
{"code":"P14.1","desc":"Klumpkeovej ochrnutie zapríčinené poranením pri pôrode"},
{"code":"P14.2","desc":"Ochrnutie bránicového nervu zapríčinené poranením pri pôrode"},
{"code":"P14.3","desc":"Iné poranenie plexus brachialis pri pôrode"},
{"code":"P14.8","desc":"Pôrodné poranenie iných častí periférnej nervovej sústavy"},
{"code":"P14.9","desc":"Pôrodné poranenie periférnej nervovej sústavy, bližšie neurčené"},
{"code":"P15.-","desc":"Iné poranenie pri pôrode"},
{"code":"P15.0","desc":"Pôrodné poranenie pečene"},
{"code":"P15.1","desc":"Pôrodné poranenie sleziny"},
{"code":"P15.2","desc":"Poranenie kývača hlavy zapríčinené poranením pri pôrode"},
{"code":"P15.3","desc":"Pôrodné poranenie oka"},
{"code":"P15.4","desc":"Pôrodné poranenie tváre"},
{"code":"P15.5","desc":"Pôrodné poranenie vonkajších genitálií"},
{"code":"P15.6","desc":"Nekróza podkožného tuku zapríčinená poranením pri pôrode"},
{"code":"P15.8","desc":"Iné pôrodné poranenie, bližšie určené"},
{"code":"P15.9","desc":"Pôrodné poranenie, bližšie neurčené"},
{"code":"P20-P29","desc":"Choroby špecifické pre perinatálne obdobie"},
{"code":"P20.-","desc":"Vnútromaternicová hypoxia"},
{"code":"P20.0","desc":"Vnútromaternicová hypoxia zaznamenaná po prvýkrát pred začatím pôrodnej činnosti"},
{"code":"P20.1","desc":"Vnútromaternicová hypoxia zaznamenaná po prvýkrát počas pôrodnej činnosti a pôrodu"},
{"code":"P20.9","desc":"Vnútromaternicová hypoxia, bližšie neurčená"},
{"code":"P21.-","desc":"Pôrodná asfyxia"},
{"code":"P21.0","desc":"Ťažká pôrodná asfyxia"},
{"code":"P21.1","desc":"Ľahká a stredná pôrodná asfyxia"},
{"code":"P21.9","desc":"Pôrodná asfyxia, bližšie neurčená"},
{"code":"P22.-","desc":"Respiračná tieseň [respiratory distress] novorodenca"},
{"code":"P22.0","desc":"Syndróm respiračnej tiesne (respiratory distress syndrome) novorodenca"},
{"code":"P22.1","desc":"Prechodná tachypnea novorodenca"},
{"code":"P22.8","desc":"Iná respiračná tieseň novorodenca"},
{"code":"P22.9","desc":"Respiračná tieseň novorodenca, bližšie neurčená"},
{"code":"P23.-","desc":"Vrodený zápal pľúc"},
{"code":"P23.0","desc":"Vrodený zápal pľúc, zapríčinený vírusom"},
{"code":"P23.1","desc":"Vrodený zápal pľúc, zapríčinený chlamýdiou"},
{"code":"P23.2","desc":"Vrodený zápal pľúc, zapríčinený stafylokokom"},
{"code":"P23.3","desc":"Vrodený zápal pľúc, zapríčinený streptokokom skupiny B"},
{"code":"P23.4","desc":"Vrodený zápal pľúc, zapríčinený Escherichia coli"},
{"code":"P23.5","desc":"Vrodený zápal pľúc, zapríčinený pseudomonas"},
{"code":"P23.6","desc":"Vrodený zápal pľúc, zapríčinený inou baktériou"},
{"code":"P23.8","desc":"Vrodený zápal pľúc, zapríčinený iným organizmom"},
{"code":"P23.9","desc":"Vrodený zápal pľúc, bližšie neurčený"},
{"code":"P24.-","desc":"Novorodenecký syndróm z aspirácie"},
{"code":"P24.0","desc":"Novorodenecká aspirácia smolky"},
{"code":"P24.1","desc":"Novorodenecká aspirácia plodovej vody a hlienu"},
{"code":"P24.2","desc":"Novorodenecká aspirácia krvi"},
{"code":"P24.3","desc":"Novorodenecká aspirácia mlieka a regurgitovanej potravy"},
{"code":"P24.8","desc":"Iný novorodenecký syndróm z aspirácie"},
{"code":"P24.9","desc":"Novorodenecký syndróm z aspirácie, bližšie neurčený"},
{"code":"P25.-","desc":"Intersticiálny emfyzém a príbuzné stavy so vznikom v perinatálnom období"},
{"code":"P25.0","desc":"Intersticiálny emfyzém so vznikom v perinatálnom období"},
{"code":"P25.1","desc":"Pneumotorax so vznikom v perinatálnom období"},
{"code":"P25.2","desc":"Pneumomediastínum so vznikom v perinatálnom období"},
{"code":"P25.3","desc":"Pneumoperikard so vznikom v perinatálnom období"},
{"code":"P25.8","desc":"Iné stavy súvisiace s intersticiálnym emfyzémom so vznikom v perinatálnom období"},
{"code":"P26.-","desc":"Pľúcne krvácanie so vznikom v perinatálnom období"},
{"code":"P26.0","desc":"Tracheobronchiálne krvácanie so vznikom v perinatálnom období"},
{"code":"P26.1","desc":"Masívne pľúcne krvácanie so vznikom v perinatálnom období"},
{"code":"P26.8","desc":"Iné pľúcne krvácania so vznikom v perinatálnom období"},
{"code":"P26.9","desc":"Bližšie neurčené pľúcne krvácanie so vznikom v perinatálnom období"},
{"code":"P27.-","desc":"Chronická respiračná choroba so vznikom v perinatálnom období"},
{"code":"P27.0","desc":"Wilsonov-Mikityho syndróm"},
{"code":"P27.1","desc":"Bronchopulmonálna dysplázia so vznikom v perinátalnom období"},
{"code":"P27.8","desc":"Iná chronická respiračná choroba so vznikom v perinatálnom období"},
{"code":"P27.9","desc":"Bližšie neurčená chronická respiračná choroba so vznikom v perinatálnom období"},
{"code":"P28.-","desc":"Iné respiračné poruchy so vznikom v perinatálnom období"},
{"code":"P28.0","desc":"Primárna atelektáza novorodenca"},
{"code":"P28.1","desc":"Iná a bližšie neurčená atelektáza novorodenca"},
{"code":"P28.2","desc":"Záchvaty cyanózy novorodenca"},
{"code":"P28.3","desc":"Primárne spánkové apnoe novorodenca"},
{"code":"P28.4","desc":"Iné apnoe novorodenca"},
{"code":"P28.5","desc":"Respiračné zlyhanie novorodenca"},
{"code":"P28.8","desc":"Iná porucha dýchania novorodenca, bližšie určená"},
{"code":"P28.9","desc":"Porucha dýchania novorodenca, bližšie neurčená"},
{"code":"P29.-","desc":"Srdcovocievne poruchy so vznikom v perinatálnom období"},
{"code":"P29.0","desc":"Srdcové zlyhávanie u novorodenca"},
{"code":"P29.1","desc":"Porucha srdcového rytmu u novorodenca"},
{"code":"P29.2","desc":"Artériová hypertenzia u novorodenca"},
{"code":"P29.3","desc":"Pretrvávajúci fetálny obeh"},
{"code":"P29.4","desc":"Prechodná ischémia myokardu u novorodenca"},
{"code":"P29.8","desc":"Iná srdcovocievna porucha so vznikom v perinatálnom období"},
{"code":"P29.9","desc":"Srdcovocievna porucha so vznikom v perinatálnom období, bližšie neurčená"},
{"code":"P35-P39","desc":"Infekcie špecifické pre perinatálne obdobie"},
{"code":"P35.-","desc":"Vrodená vírusová choroba"},
{"code":"P35.0","desc":"Rubeolová embryopatia"},
{"code":"P35.1","desc":"Vrodená cytomegalovírusová infekcia"},
{"code":"P35.2","desc":"Vrodená infekcia vírusom herpes simplex"},
{"code":"P35.3","desc":"Vrodená vírusová hepatitída"},
{"code":"P35.8","desc":"Iná vrodená vírusová choroba"},
{"code":"P35.9","desc":"Vrodená vírusová choroba, bližšie neurčená"},
{"code":"P36.-","desc":"Baktériová sepsa u novorodenca"},
{"code":"P36.0","desc":"Sepsa u novorodenca, zapríčinená streptokokom zo skupiny B"},
{"code":"P36.1","desc":"Sepsa u novorodenca, zapríčinená iným a bližšie neurčeným streptokokom"},
{"code":"P36.2","desc":"Sepsa u novorodenca, zapríčinená staphylococcus aureus"},
{"code":"P36.3","desc":"Sepsa u novorodenca, zapríčinená iným a bližšie neurčeným stafylokokom"},
{"code":"P36.4","desc":"Sepsa u novorodenca, zapríčinená escherichia coli"},
{"code":"P36.5","desc":"Sepsa u novorodenca, zapríčinená anaeróbnym mikroorganizmom"},
{"code":"P36.8","desc":"Iná baktériová sepsa u novorodenca"},
{"code":"P36.9","desc":"Baktériová sepsa u novorodenca, bližšie neurčená"},
{"code":"P37.-","desc":"Iná vrodená infekčná a parazitová choroba"},
{"code":"P37.0","desc":"Vrodená tuberkulóza"},
{"code":"P37.1","desc":"Vrodená toxoplazmóza"},
{"code":"P37.2","desc":"Novorodenecká (diseminovaná) listerióza"},
{"code":"P37.3","desc":"Vrodená malária plasmodium falciparum"},
{"code":"P37.4","desc":"Iná vrodená malária"},
{"code":"P37.5","desc":"Kandidóza u novorodenca"},
{"code":"P37.8","desc":"Iná vrodená infekčná a parazitová choroba, bližšie určená"},
{"code":"P37.9","desc":"Vrodená infekčná alebo parazitová choroba, bližšie neurčená"},
{"code":"P38","desc":"Omfalitída u novorodenca bez krvácania alebo s miernym krvácaním"},
{"code":"P39.-","desc":"Iná infekcia špecifická pre perinatálne obdobie"},
{"code":"P39.0","desc":"Novorodenecká infekčná mastitída (zápal prsníkov)"},
{"code":"P39.1","desc":"Novorodenecká konjunktivitída a dakryocystitída"},
{"code":"P39.2","desc":"Intraamniotická infekcia plodu, nezatriedená inde"},
{"code":"P39.3","desc":"Novorodenecká infekcia močovej sústavy"},
{"code":"P39.4","desc":"Novorodenecká kožná infekcia"},
{"code":"P39.8","desc":"Iná bližšie určená infekcia špecifická pre perinatálne obdobie"},
{"code":"P39.9","desc":"Infekcia špecifická pre perinatálne obdobie, bližšie neurčená"},
{"code":"P50-P61","desc":"Hemoragické a krvné choroby plodu a novorodenca"},
{"code":"P50.-","desc":"Fetálna strata krvi"},
{"code":"P50.0","desc":"Fetálna strata krvi pri vasa praevia (vcestných cievach)"},
{"code":"P50.1","desc":"Fetálna strata krvi z roztrhnutého pupočníka"},
{"code":"P50.2","desc":"Fetálna strata krvi z placenty"},
{"code":"P50.3","desc":"Krvácanie do dvojčaťa (fetofetálne)"},
{"code":"P50.4","desc":"Krvácanie do obehu matky"},
{"code":"P50.5","desc":"Fetálna strata krvi z prerezaného pupočníka druhého dvojčaťa"},
{"code":"P50.8","desc":"Iná fetálna strata krvi"},
{"code":"P50.9","desc":"Fetálna strata krvi, bližšie neurčená"},
{"code":"P51.-","desc":"Krvácanie z pupočníka novorodenca"},
{"code":"P51.0","desc":"Masívne krvácanie z pupočníka novorodenca"},
{"code":"P51.8","desc":"Iné krvácanie z pupočníka novorodenca"},
{"code":"P51.9","desc":"Krvácanie z pupočníka novorodenca, bližšie neurčené"},
{"code":"P52.-","desc":"Vnútrolebkové neúrazové krvácanie plodu a novorodenca"},
{"code":"P52.0","desc":"Vnútrokomorové (neúrazové) krvácanie plodu a novorodenca 1. stupňa"},
{"code":"P52.1","desc":"Vnútrokomorové (neúrazové) krvácanie plodu a novorodenca 2. stupňa"},
{"code":"P52.2","desc":"Vnútrokomorové (neúrazové) krvácanie plodu a novorodenca 3. stupňa"},
{"code":"P52.3","desc":"Bližšie neurčené vnútrokomorové (neúrazové) krvácanie plodu a novorodenca"},
{"code":"P52.4","desc":"Mozgové (neúrazové) krvácanie plodu a novorodenca"},
{"code":"P52.5","desc":"Subarachnoidálne (neúrazové) krvácanie plodu a novorodenca"},
{"code":"P52.6","desc":"Mozočkové (neúrazové) krvácanie a krvácanie do zadnej jamy plodu a novorodenca"},
{"code":"P52.8","desc":"Iné vnútrolebkové (neúrazové) krvácania plodu a novorodenca"},
{"code":"P52.9","desc":"Vnútrolebkové (neúrazové) krvácanie plodu a novorodenca, bližšie neurčené"},
{"code":"P53","desc":"Hemoragická choroba plodu a novorodenca"},
{"code":"P54.-","desc":"Iné krvácanie novorodenca"},
{"code":"P54.0","desc":"Hemateméza u novorodenca"},
{"code":"P54.1","desc":"Meléna u novorodenca"},
{"code":"P54.2","desc":"Krvácanie z konečníka u novorodenca"},
{"code":"P54.3","desc":"Iné gastrointestinálne krvácanie u novorodenca"},
{"code":"P54.4","desc":"Krvácanie do nadobličky u novorodenca"},
{"code":"P54.5","desc":"Kožné krvácanie u novorodenca"},
{"code":"P54.6","desc":"Pošvové krvácanie u novorodenca"},
{"code":"P54.8","desc":"Iné bližšie určené krvácanie novorodenca"},
{"code":"P54.9","desc":"Krvácanie novorodenca, bližšie neurčené"},
{"code":"P55.-","desc":"Hemolytická choroba plodu a novorodenca"},
{"code":"P55.0","desc":"Rh izoimunizácia plodu a novorodenca"},
{"code":"P55.1","desc":"ABO izoimunizácia plodu a novorodenca"},
{"code":"P55.8","desc":"Iná hemolytická choroba plodu a novorodenca"},
{"code":"P55.9","desc":"Hemolytická choroba plodu a novorodenca, bližšie neurčená"},
{"code":"P56.-","desc":"Hydrops plodu v dôsledku hemolytickej choroby"},
{"code":"P56.0","desc":"Hydrops plodu v dôsledku izoimunizácie"},
{"code":"P56.9","desc":"Hydrops plodu v dôsledku inej a bližšie neurčenej hemolytickej choroby"},
{"code":"P57.-","desc":"Jadrový ikterus"},
{"code":"P57.0","desc":"Jadrový ikterus v dôsledku izoimunizácie"},
{"code":"P57.8","desc":"Iný jadrový ikterus, bližšie určený"},
{"code":"P57.9","desc":"Jadrový ikterus, bližšie neurčený"},
{"code":"P58.-","desc":"Novorodenecká žltačka zapríčinená inou nadmernou hemolýzou"},
{"code":"P58.0","desc":"Novorodenecká žltačka zapríčinená podliatinami"},
{"code":"P58.1","desc":"Novorodenecká žltačka zapríčinená krvácaním"},
{"code":"P58.2","desc":"Novorodenecká žltačka zapríčinená infekciou"},
{"code":"P58.3","desc":"Novorodenecká žltačka zapríčinená polycytémiou"},
{"code":"P58.4","desc":"Novorodenecká žltačka zapríčinená liekmi alebo toxínmi, prenesenými z matky alebo podanými novorodencovi"},
{"code":"P58.5","desc":"Novorodenecká žltačka zapríčinená prehltnutím materskej krvi"},
{"code":"P58.8","desc":"Novorodenecká žltačka zapríčinená inou bližšie určenou nadmernou hemolýzou"},
{"code":"P58.9","desc":"Novorodenecká žltačka zapríčinená nadmernou hemolýzou, bližšie neurčená"},
{"code":"P59.-","desc":"Novorodenecká žltačka z iných a bližšie neurčených príčin"},
{"code":"P59.0","desc":"Novorodenecká žltačka súvisiaca s predčasným pôrodom"},
{"code":"P59.1","desc":"Syndróm hustej žlče"},
{"code":"P59.2","desc":"Novorodenecká žltačka z iného a bližšie neurčeného poškodenia pečeňových buniek"},
{"code":"P59.3","desc":"Novorodenecká žltačka z inhibítorov materského mlieka"},
{"code":"P59.8","desc":"Novorodenecká žltačka z iných bližšie určených príčin"},
{"code":"P59.9","desc":"Novorodenecká žltačka, bližšie neurčená"},
{"code":"P60","desc":"Diseminovaná intravaskulárna koagulácia plodu a novorodenca"},
{"code":"P61.-","desc":"Iná perinatálna krvná choroba"},
{"code":"P61.0","desc":"Prechodná novorodenecká trombocytopénia"},
{"code":"P61.1","desc":"Novorodenecká polycytémia"},
{"code":"P61.2","desc":"Anémia nedonosených"},
{"code":"P61.3","desc":"Vrodená anémia z fetálnej straty krvi"},
{"code":"P61.4","desc":"Iná vrodená anémia, nezatriedené inde"},
{"code":"P61.5","desc":"Prechodná novorodenecká neutropénia"},
{"code":"P61.6","desc":"Iná prechodná novorodenecká porucha zrážania"},
{"code":"P61.8","desc":"Iná bližšie určená perinatálna hematologická porucha"},
{"code":"P61.9","desc":"Perinatálna hematologická porucha, bližšie neurčená"},
{"code":"P70-P74","desc":"Prechodné endokrinné a metabolické poruchy špecifické pre plod a novorodenca"},
{"code":"P70.-","desc":"Prechodná porucha metabolizmu sacharidov špecifická pre plod a novorodenca"},
{"code":"P70.0","desc":"Syndróm dieťaťa matky s gestačným diabetes mellitus"},
{"code":"P70.1","desc":"Syndróm dieťaťa matky s diabetes mellitus"},
{"code":"P70.2","desc":"Diabetes mellitus u novorodenca"},
{"code":"P70.3","desc":"Iatrogénna hypoglykémia u novorodenca"},
{"code":"P70.4","desc":"Iná hypoglykémia u novorodenca"},
{"code":"P70.8","desc":"Iná prechodná porucha metabolizmu sacharidov plodu a novorodenca"},
{"code":"P70.9","desc":"Prechodná porucha metabolizmu sacharidov plodu a novorodenca, bližšie neurčená"},
{"code":"P71.-","desc":"Prechodná porucha metabolizmu vápnika a horčíka u novorodenca"},
{"code":"P71.0","desc":"Hypokalciémia z kravského mlieka u novorodenca"},
{"code":"P71.1","desc":"Iná hypokalciémia u novorodenca"},
{"code":"P71.2","desc":"Hypomagneziémia u novorodenca"},
{"code":"P71.3","desc":"Novorodenecká tetania bez nedostatku kalcia alebo magnézia"},
{"code":"P71.4","desc":"Prechodná hypoparatyreóza u novorodenca"},
{"code":"P71.8","desc":"Iná prechodná porucha metabolizmu vápnika a horčíka u novorodenca"},
{"code":"P71.9","desc":"Prechodná porucha metabolizmu vápnika a horčíka u novorodenca, bližšie neurčená"},
{"code":"P72.-","desc":"Iná prechodná endokrinná porucha u novorodenca"},
{"code":"P72.0","desc":"Struma u novorodenca, nezatriedená inde"},
{"code":"P72.1","desc":"Prechodná hypertyreóza u novorodenca"},
{"code":"P72.2","desc":"Iná prechodná porucha funkcie štítnej žľazy u novorodenca, nezatriedená inde"},
{"code":"P72.8","desc":"Iná prechodná endokrinná porucha u novorodenca, bližšie určená"},
{"code":"P72.9","desc":"Prechodná endokrinná porucha u novorodenca, bližšie neurčená"},
{"code":"P74.-","desc":"Iná prechodná elektrolytová a metabolická porucha u novorodenca"},
{"code":"P74.0","desc":"Neskorá metabolická acidóza u novorodenca"},
{"code":"P74.1","desc":"Dehydratácia u novorodenca"},
{"code":"P74.2","desc":"Porucha rovnováhy sodíka u novorodenca"},
{"code":"P74.3","desc":"Porucha rovnováhy draslíka u novorodenca"},
{"code":"P74.4","desc":"Iná prechodná elektrolytová porucha u novorodenca"},
{"code":"P74.5","desc":"Prechodná tyrozinémia u novorodenca"},
{"code":"P74.8","desc":"Iná prechodná metabolická porucha u novorodenca"},
{"code":"P74.9","desc":"Prechodná metabolická porucha u novorodenca, bližšie neurčená"},
{"code":"P75-P78","desc":"Choroby tráviacej sústavy plodu a novorodenca"},
{"code":"P75*","desc":"Mekóniový ileus pri cystickej fibróze (E84.1†)"},
{"code":"P76.-","desc":"Iná črevná nepriechodnosť u novorodenca"},
{"code":"P76.0","desc":"Syndróm mekóniovej zátky"},
{"code":"P76.1","desc":"Prechodný ileus u novorodenca"},
{"code":"P76.2","desc":"Črevná nepriechodnosť zapríčinená zahusteným mliekom"},
{"code":"P76.8","desc":"Iná črevná nepriechodnosť u novorodenca, bližšie určená"},
{"code":"P76.9","desc":"Črevná nepriechodnosť u novorodenca, bližšie neurčená"},
{"code":"P77","desc":"Nekrotizujúca enterokolitída plodu a novorodenca"},
{"code":"P78.-","desc":"Iná perinatálna porucha tráviacej sústavy"},
{"code":"P78.0","desc":"Perinatálna perforácia čreva"},
{"code":"P78.1","desc":"Iná novorodenecká peritonitída"},
{"code":"P78.2","desc":"Novorodenecká hemateméza a meléna zapríčinená prehltnutím materskej krvi"},
{"code":"P78.3","desc":"Neinfekčná novorodenecká hnačka"},
{"code":"P78.8","desc":"Iná perinatálna porucha tráviacej sústavy, bližšie určená"},
{"code":"P78.9","desc":"Perinatálna porucha tráviaceho systému, bližšie neurčená"},
{"code":"P80-P83","desc":"Choroby postihujúce kožu a reguláciu telesnej teploty plodu a novorodenca"},
{"code":"P80.-","desc":"Hypotermia u novorodenca"},
{"code":"P80.0","desc":"Syndróm poškodenia chladom (podchladenia) u novorodenca"},
{"code":"P80.8","desc":"Iná hypotermia u novorodenca"},
{"code":"P80.9","desc":"Hypotermia u novorodenca, bližšie neurčená"},
{"code":"P81.-","desc":"Iná porucha regulácie telesnej teploty u novorodenca"},
{"code":"P81.0","desc":"Hypertermia u novorodenca z prehriateho prostredia"},
{"code":"P81.8","desc":"Iná porucha regulácie telesnej teploty u novorodenca, bližšie určená"},
{"code":"P81.9","desc":"Porucha regulácie telesnej teploty u novorodenca, bližšie neurčená"},
{"code":"P83.-","desc":"Iná choroba kože špecifické pre plod a novorodenca"},
{"code":"P83.0","desc":"Sclerema neonatorum"},
{"code":"P83.1","desc":"Novorodenecký toxický erytém"},
{"code":"P83.2","desc":"Hydrops plodu, nezapríčinený hemolytickou chorobou"},
{"code":"P83.3","desc":"Iný a bližšie neurčený opuch špecifický pre plod a novorodenca"},
{"code":"P83.4","desc":"Zdurenie prsných žliaz u novorodenca"},
{"code":"P83.5","desc":"Kongenitálna hydrokéla"},
{"code":"P83.6","desc":"Pupkový polyp u novorodenca"},
{"code":"P83.8","desc":"Iná bližšie určená choroba kože špecifická pre plod a novorodenca"},
{"code":"P83.9","desc":"Porucha kože špecifická pre plod a novorodenca, bližšie neurčená"},
{"code":"P90-P96","desc":"Iné poruchy so vznikom v perinatálnom období"},
{"code":"P90","desc":"Kŕče [konvulzie] u novorodenca"},
{"code":"P91.-","desc":"Iná mozgová porucha u novorodenca"},
{"code":"P91.0","desc":"Mozgová ischémia u novorodenca"},
{"code":"P91.1","desc":"Získaná periventrikulárna cysta u novorodenca"},
{"code":"P91.2","desc":"Mozgová leukomalácia u novorodenca"},
{"code":"P91.3","desc":"Mozgová dráždivosť novorodencov"},
{"code":"P91.4","desc":"Mozgový útlm novorodencov"},
{"code":"P91.5","desc":"Kóma u novorodenca"},
{"code":"P91.6","desc":"Hypoxicko-ischemická encefalopatia u novorodenca"},
{"code":"P91.8-","desc":"Iná bližšie určená mozgová porucha u novorodenca"},
{"code":"P91.80","desc":"Locked-in syndróm a apalický syndróm novorodenca"},
{"code":"P91.88","desc":"Iná porucha mozgovej funkcie novorodenca, bližšie určená"},
{"code":"P91.9","desc":"Mozgová porucha u novorodenca, bližšie neurčená"},
{"code":"P92.-","desc":"Problém s kŕmením novorodenca"},
{"code":"P92.0","desc":"Vracanie u novorodenca"},
{"code":"P92.1","desc":"Regurgitácia a ruminácia u novorodenca"},
{"code":"P92.2","desc":"Pomalý príjem potravy u novorodenca"},
{"code":"P92.3","desc":"Podvýživa (znížený príjem potravy) u novorodenca"},
{"code":"P92.4","desc":"Nadmerná výživa u novorodenca"},
{"code":"P92.5","desc":"Problém s dojčením u novorodenca"},
{"code":"P92.8","desc":"Iný problém s výživou (kŕmením) u novorodenca"},
{"code":"P92.9","desc":"Problém s výživou (kŕmením) u novorodenca, bližšie neurčený"},
{"code":"P93","desc":"Reakcia na lieky a intoxikácia liekmi podanými plodu a novorodencovi"},
{"code":"P94.-","desc":"Porucha svalového tonusu u novorodenca"},
{"code":"P94.0","desc":"Prechodná myasthenia gravis u novorodenca"},
{"code":"P94.1","desc":"Vrodená svalová hypertónia"},
{"code":"P94.2","desc":"Vrodená svalová hypotónia"},
{"code":"P94.8","desc":"Iná porucha svalového tonusu u novorodenca"},
{"code":"P94.9","desc":"Porucha svalového tonusu u novorodenca, bližšie neurčená"},
{"code":"P95","desc":"Smrť plodu z bližšie neurčenej príčiny"},
{"code":"P96.-","desc":"Iná porucha so začiatkom v perinatálnom období"},
{"code":"P96.0","desc":"Vrodené zlyhávanie obličiek"},
{"code":"P96.1","desc":"Abstinenčný syndróm u novorodenca matky užívajúcej návykové lieky a drogy"},
{"code":"P96.2","desc":"Abstinenčný syndróm novorodenca pri liečebnom podávaní liečiv"},
{"code":"P96.3","desc":"Široké lebkové švy u novorodenca"},
{"code":"P96.4","desc":"Ukončenie gravidity ako príčina poškodenia plodu a novorodenca"},
{"code":"P96.5","desc":"Komplikácia vnútromaternicového výkonu, nezatriedená inde, ako príčina patologických stavov plodu a novorodenca"},
{"code":"P96.8","desc":"Iná porucha so začiatkom v perinatálnom období, bližšie určená"},
{"code":"P96.9","desc":"Porucha so začiatkom v perinatálnom období, bližšie neurčená"},
{"code":"Q00-Q99","desc":"XVII. kapitola - Vrodené chyby, deformity a chromozómové anomálie (Q00-Q99)"},
{"code":"Q00-Q07","desc":"Vrodená chyba nervovej sústavy"},
{"code":"Q00.-","desc":"Anencefalus a podobná vrodená chyba"},
{"code":"Q00.0","desc":"Anencefália"},
{"code":"Q00.1","desc":"Kraniorachischíza"},
{"code":"Q00.2","desc":"Iniencefália"},
{"code":"Q01.-","desc":"Encefalokéla"},
{"code":"Q01.0","desc":"Čelová encefalokéla"},
{"code":"Q01.1","desc":"Nosovočelová encefalokéla"},
{"code":"Q01.2","desc":"Okcipitálna encefalokéla"},
{"code":"Q01.8","desc":"Encefalokéla na inom mieste"},
{"code":"Q01.9","desc":"Encefalokéla, bližšie neurčená"},
{"code":"Q02","desc":"Mikrocefália"},
{"code":"Q03.-","desc":"Vrodený hydrocefalus"},
{"code":"Q03.0","desc":"Chyby mozgového mokovodu"},
{"code":"Q03.1","desc":"Atrézia pristredného [Magendieho] a bočného [Luschkovho] otvoru štvrtej komory"},
{"code":"Q03.8","desc":"Iný vrodený hydrocefalus"},
{"code":"Q03.9","desc":"Vrodený hydrocefalus, bližšie neurčený"},
{"code":"Q04.-","desc":"Iná vrodená chyba mozgu"},
{"code":"Q04.0","desc":"Vrodená chyba corpus callosum"},
{"code":"Q04.1","desc":"Arinencefália"},
{"code":"Q04.2","desc":"Holoprozencefália"},
{"code":"Q04.3","desc":"Iná redukčná deformita mozgu"},
{"code":"Q04.4","desc":"Septooptická dysplázia"},
{"code":"Q04.5","desc":"Megalencefália"},
{"code":"Q04.6","desc":"Vrodená cysta mozgu"},
{"code":"Q04.8","desc":"Iná vrodená chyba mozgu, bližšie určená"},
{"code":"Q04.9","desc":"Vrodená chyba mozgu, bližšie neurčená"},
{"code":"Q05.-","desc":"Rázštep chrbtice [Spina bifida]"},
{"code":"Q05.0","desc":"Krčná spina bifida (rázštep chrbtice) s hydrocefalom"},
{"code":"Q05.1","desc":"Hrudníková spina bifida (rázštep chrbtice) s hydrocefalom"},
{"code":"Q05.2","desc":"Drieková spina bifida (rázštep chrbtice) s hydrocefalom"},
{"code":"Q05.3","desc":"Krížová spina bifida (rázštep chrbtice) s hydrocefalom"},
{"code":"Q05.4","desc":"Spina bifida (rázštep chrbtice) s hydrocefalom, bližšie neurčený"},
{"code":"Q05.5","desc":"Krčná spina bifida (rázštep chrbtice) bez hydrocefalu"},
{"code":"Q05.6","desc":"Hrudníková spina bifida (rázštep chrbtice) bez hydrocefalu"},
{"code":"Q05.7","desc":"Drieková spina bifida (rázštep chrbtice) bez hydrocefalu"},
{"code":"Q05.8","desc":"Krížová spina bifida (rázštep chrbtice) bez hydrocefalu"},
{"code":"Q05.9","desc":"Spina bifida (rázštep chrbtice), bližšie neurčená"},
{"code":"Q06.-","desc":"Iná vrodená chyba miechy"},
{"code":"Q06.0","desc":"Amyélia"},
{"code":"Q06.1","desc":"Hypoplázia a dysplázia miechy"},
{"code":"Q06.2","desc":"Diastematomyélia"},
{"code":"Q06.3","desc":"Iná vrodená chyba konského chvosta (cauda equina)"},
{"code":"Q06.4","desc":"Hydromyélia"},
{"code":"Q06.8","desc":"Iná vrodená chyba miechy, bližšie určená"},
{"code":"Q06.9","desc":"Vrodená chyba miechy, bližšie neurčená"},
{"code":"Q07.-","desc":"Iná vrodená chyba nervovej sústavy"},
{"code":"Q07.0","desc":"Arnoldov-Chiariho syndróm"},
{"code":"Q07.8","desc":"Iná vrodená chyba nervovej sústavy, bližšie určená"},
{"code":"Q07.9","desc":"Vrodená chyba nervovej sústavy, bližšie neurčená"},
{"code":"Q10-Q18","desc":"Vrodené chyby oka, ucha, tváre a krku"},
{"code":"Q10.-","desc":"Vrodené chyby mihalnice, slzných orgánov a očnice"},
{"code":"Q10.0","desc":"Vrodená ptóza"},
{"code":"Q10.1","desc":"Vrodené ektropium"},
{"code":"Q10.2","desc":"Vrodené entropium"},
{"code":"Q10.3","desc":"Iná vrodená chyba mihalnice"},
{"code":"Q10.4","desc":"Chýbanie alebo agenéza slzných orgánov"},
{"code":"Q10.5","desc":"Vrodené zúženie (stenóza alebo striktúra) nosovoslzného kanála"},
{"code":"Q10.6","desc":"Iná vrodená chyba slzných orgánov"},
{"code":"Q10.7","desc":"Vrodená chyba očnice"},
{"code":"Q11.-","desc":"Anoftalmus, mikroftalmus a makroftalmus"},
{"code":"Q11.0","desc":"Cystická očná guľa"},
{"code":"Q11.1","desc":"Iný anoftalmus"},
{"code":"Q11.2","desc":"Mikroftalmus"},
{"code":"Q11.3","desc":"Makroftalmus"},
{"code":"Q12.-","desc":"Vrodená chyba šošovky"},
{"code":"Q12.0","desc":"Vrodený sivý zákal (katarakta)"},
{"code":"Q12.1","desc":"Vrodená dislokácia šošovky"},
{"code":"Q12.2","desc":"Kolobóm šošovky"},
{"code":"Q12.3","desc":"Vrodená afakia"},
{"code":"Q12.4","desc":"Sférofakia"},
{"code":"Q12.8","desc":"Iná vrodená chyba šošovky"},
{"code":"Q12.9","desc":"Vrodená chyba šošovky, bližšie neurčená"},
{"code":"Q13.-","desc":"Vrodená chyba predného segmentu oka"},
{"code":"Q13.0","desc":"Kolobóm dúhovky"},
{"code":"Q13.1","desc":"Chýbanie dúhovky"},
{"code":"Q13.2","desc":"Iná vrodená chyba dúhovky"},
{"code":"Q13.3","desc":"Vrodený zákal rohovky"},
{"code":"Q13.4","desc":"Iná vrodená chyba rohovky"},
{"code":"Q13.5","desc":"Belasá skléra"},
{"code":"Q13.8","desc":"Iná vrodená chyba predného segmentu oka"},
{"code":"Q13.9","desc":"Vrodená chyba predného segmentu oka, bližšie neurčená"},
{"code":"Q14.-","desc":"Vrodená chyba zadného segmentu oka"},
{"code":"Q14.0","desc":"Vrodená chyba sklovca"},
{"code":"Q14.1","desc":"Vrodená chyba sietnice"},
{"code":"Q14.2","desc":"Vrodená chyba terča zrakového nervu"},
{"code":"Q14.3","desc":"Vrodená chyba cievovky"},
{"code":"Q14.8","desc":"Iná vrodená chyba zadného segmentu oka"},
{"code":"Q14.9","desc":"Vrodená chyba zadného segmentu oka, bližšie neurčená"},
{"code":"Q15.-","desc":"Iná vrodená chyba oka"},
{"code":"Q15.0","desc":"Vrodený glaukóm"},
{"code":"Q15.8","desc":"Iná vrodená chyba oka, bližšie určená"},
{"code":"Q15.9","desc":"Vrodená chyba oka, bližšie neurčená"},
{"code":"Q16.-","desc":"Vrodené chyby ucha, zapríčiňujúce zhoršenie sluchu"},
{"code":"Q16.0","desc":"Vrodené chýbanie ušnice"},
{"code":"Q16.1","desc":"Vrodené chýbanie, atrézia a striktúra (vonkajšieho) zvukovodu"},
{"code":"Q16.2","desc":"Chýbanie sluchovej [Eustachovej] trubice (vrodené)"},
{"code":"Q16.3","desc":"Vrodená chyba sluchových kostičiek"},
{"code":"Q16.4","desc":"Iná vrodená chyba stredného ucha"},
{"code":"Q16.5","desc":"Vrodená chyba vnútorného ucha"},
{"code":"Q16.9","desc":"Vrodená chyba ucha, zapríčiňujúca zhoršenie sluchu, bližšie neurčená"},
{"code":"Q17.-","desc":"Iná vrodená chyba ucha"},
{"code":"Q17.0","desc":"Prídavná ušnica"},
{"code":"Q17.1","desc":"Makrócia"},
{"code":"Q17.2","desc":"Mikrócia"},
{"code":"Q17.3","desc":"Iné znetvorené ucho"},
{"code":"Q17.4","desc":"Chybne uložené ucho"},
{"code":"Q17.5","desc":"Odstávajúce ucho"},
{"code":"Q17.8","desc":"Iná vrodená chyba ucha, bližšie určená"},
{"code":"Q17.9","desc":"Vrodená chyba ucha, bližšie neurčená"},
{"code":"Q18.-","desc":"Iná vrodená chyba tváre a krku"},
{"code":"Q18.0","desc":"Branchiogénny sínus, fistula a cysta"},
{"code":"Q18.1","desc":"Preaurikulárny sínus a cysta"},
{"code":"Q18.2","desc":"Iná branchiogénna chyba"},
{"code":"Q18.3","desc":"Kožná riasa šije [Pterygium colli]"},
{"code":"Q18.4","desc":"Makrostómia"},
{"code":"Q18.5","desc":"Mikrostómia"},
{"code":"Q18.6","desc":"Makrocheilia"},
{"code":"Q18.7","desc":"Mikrocheilia"},
{"code":"Q18.8","desc":"Iná vrodená chyba tváre a krku, bližšie určená"},
{"code":"Q18.9","desc":"Vrodená chyba tváre a krku, bližšie neurčená"},
{"code":"Q20-Q28","desc":"Vrodená chyba obehovej sústavy"},
{"code":"Q20.-","desc":"Vrodená chyba srdcových dutín a ich spojení"},
{"code":"Q20.0","desc":"Spoločný tepnový kmeň [truncus arteriosus communis]"},
{"code":"Q20.1","desc":"Dvojvýtoková pravá komora"},
{"code":"Q20.2","desc":"Dvojvýtoková ľavá komora"},
{"code":"Q20.3","desc":"Nezhodné [diskordantné] komorovotepnové spojenie"},
{"code":"Q20.4","desc":"Dvojvtoková komora"},
{"code":"Q20.5","desc":"Nezhodné [diskordantné] predsieňovokomorové spojenie"},
{"code":"Q20.6","desc":"Súmernosť [izomerizmus] predsieňových ušiek"},
{"code":"Q20.8","desc":"Iná vrodená chyba srdcových dutín a ich spojení"},
{"code":"Q20.9","desc":"Vrodená chyba srdcových dutín a ich spojení, bližšie neurčená"},
{"code":"Q21.-","desc":"Vrodená chyba srdcových priehradiek"},
{"code":"Q21.0","desc":"Defekt medzikomorovej priehradky"},
{"code":"Q21.1","desc":"Defekt predsieňovej priehradky"},
{"code":"Q21.2","desc":"Defekt predsieňovo-komorovej priehradky"},
{"code":"Q21.3","desc":"Fallotova tetralógia"},
{"code":"Q21.4","desc":"Defekt aortopulmonálneho septa"},
{"code":"Q21.8-","desc":"Iná vrodená chyba srdcových priehradiek"},
{"code":"Q21.80","desc":"Fallotova pentalógia"},
{"code":"Q21.88","desc":"Iná vrodená chyba srdcových priehradiek"},
{"code":"Q21.9","desc":"Vrodená chyba srdcovej priehradky, bližšie neurčená"},
{"code":"Q22.-","desc":"Vrodená chyba pulmonálnej a trikuspidálnej chlopne"},
{"code":"Q22.0","desc":"Atrézia pulmonálnej chlopne"},
{"code":"Q22.1","desc":"Vrodené zúženie pulmonálnej chlopne"},
{"code":"Q22.2","desc":"Vrodená pulmonálna insuficiencia"},
{"code":"Q22.3","desc":"Iná vrodená chyba pulmonálnej chlopne"},
{"code":"Q22.4","desc":"Vrodená trikuspidálna stenóza"},
{"code":"Q22.5","desc":"Ebsteinova anomália"},
{"code":"Q22.6","desc":"Hypoplastický pravokomorový syndróm"},
{"code":"Q22.8","desc":"Iná vrodená chyba trikuspidálnej chlopne"},
{"code":"Q22.9","desc":"Vrodená chyba trikuspidálnej chlopne, bližšie neurčená"},
{"code":"Q23.-","desc":"Vrodená chyba aortálnej a mitrálnej chlopne"},
{"code":"Q23.0","desc":"Vrodená aortálna stenóza"},
{"code":"Q23.1","desc":"Vrodená aortálna insuficiencia"},
{"code":"Q23.2","desc":"Vrodená mitrálna stenóza"},
{"code":"Q23.3","desc":"Vrodená mitrálna insuficiencia"},
{"code":"Q23.4","desc":"Hypoplastický ľavokomorový syndróm"},
{"code":"Q23.8","desc":"Iná vrodená chyba aortálnej a mitrálnej chlopne"},
{"code":"Q23.9","desc":"Vrodená chyba aortálnej a mitrálnej chlopne, bližšie neurčená"},
{"code":"Q24.-","desc":"Iná vrodená chyba srdca"},
{"code":"Q24.0","desc":"Dextrokardia"},
{"code":"Q24.1","desc":"Levokardia"},
{"code":"Q24.2","desc":"Cor triatriatum (trojpredsieňové srdce)"},
{"code":"Q24.3","desc":"Infundibulárna stenóza pľúcnice"},
{"code":"Q24.4","desc":"Vrodená subaortálna stenóza"},
{"code":"Q24.5","desc":"Chyba koronárnych ciev"},
{"code":"Q24.6","desc":"Vrodená srdcová blokáda"},
{"code":"Q24.8","desc":"Iná vrodená chyba srdca, bližšie určená"},
{"code":"Q24.9","desc":"Vrodená chyba srdca, bližšie neurčená"},
{"code":"Q25.-","desc":"Vrodené chyby veľkých tepien"},
{"code":"Q25.0","desc":"Otvorený tepnový spoj [Ductus arteriosus patens]"},
{"code":"Q25.1","desc":"Koarktácia aorty"},
{"code":"Q25.2","desc":"Atrézia aorty"},
{"code":"Q25.3","desc":"Stenóza aorty"},
{"code":"Q25.4","desc":"Iná vrodená chyba aorty"},
{"code":"Q25.5","desc":"Atrézia pľúcnej tepny"},
{"code":"Q25.6","desc":"Stenóza pľúcnej tepny"},
{"code":"Q25.7","desc":"Iná vrodená chyba pľúcnej tepny"},
{"code":"Q25.8","desc":"Iná vrodená chyba veľkých tepien"},
{"code":"Q25.9","desc":"Vrodená chyba veľkých tepien, bližšie neurčená"},
{"code":"Q26.-","desc":"Vrodená chyba veľkých žíl"},
{"code":"Q26.0","desc":"Vrodená stenóza dutej žily"},
{"code":"Q26.1","desc":"Pretrvávajúca ľavá horná dutá žila"},
{"code":"Q26.2","desc":"Kompletný anomálny návrat pľúcnych žíl"},
{"code":"Q26.3","desc":"Čiastočný anomálny návrat pľúcnych žíl"},
{"code":"Q26.4","desc":"Anomálny návrat pľúcnych žíl, bližšie neurčený"},
{"code":"Q26.5","desc":"Anomálny návrat v. portae"},
{"code":"Q26.6","desc":"Fistula medzi v. portae a a.hepatica"},
{"code":"Q26.8","desc":"Iná vrodená chyba veľkých žíl"},
{"code":"Q26.9","desc":"Vrodená chyba veľkej žily, bližšie neurčená"},
{"code":"Q27.-","desc":"Iná vrodená chyba periférnej cievnej sústavy"},
{"code":"Q27.0","desc":"Vrodené chýbanie a hypoplázia pupkovej tepny"},
{"code":"Q27.1","desc":"Vrodená stenóza obličkovej tepny"},
{"code":"Q27.2","desc":"Iná vrodená chyba obličkovej tepny"},
{"code":"Q27.3","desc":"Artériovenózna chyba periférnych ciev"},
{"code":"Q27.4","desc":"Vrodená flebektázia"},
{"code":"Q27.8","desc":"Iná vrodená chyba periférnej cievnej sústavy, bližšie určená"},
{"code":"Q27.9","desc":"Vrodená chyba periférnej cievnej sústavy, bližšie neurčená"},
{"code":"Q28.-","desc":"Iná vrodená chyba obehovej sústavy"},
{"code":"Q28.0-","desc":"Artériovenózna chyba precerebrálnych ciev"},
{"code":"Q28.00","desc":"Vrodená artériovenózna aneuryzma precerebrálnych ciev"},
{"code":"Q28.01","desc":"Vrodená artériovenózna fistula precerebrálnych ciev"},
{"code":"Q28.08","desc":"Iná vrodená artériovenózna chyba precerebrálnych ciev"},
{"code":"Q28.09","desc":"Vrodená artériovenózna chyba precerebrálnych ciev, bližšie neurčená"},
{"code":"Q28.1-","desc":"Iná chyba precerebrálnych ciev"},
{"code":"Q28.10","desc":"Vrodená aneuryzma precerebrálnch ciev"},
{"code":"Q28.11","desc":"Vrodená fistula precerebrálnych ciev"},
{"code":"Q28.18","desc":"Iná vrodená chyba precerebrálnych ciev"},
{"code":"Q28.19","desc":"Vrodená chyba precerebrálnych ciev, bližšie neurčená"},
{"code":"Q28.2-","desc":"Artériovenózna chyba cerebrálnych ciev"},
{"code":"Q28.20","desc":"Vrodená artériovenózna aneuryzma cerebrálnych ciev"},
{"code":"Q28.21","desc":"Vrodená artériovenózna fistula cerebrálnych ciev"},
{"code":"Q28.28","desc":"Iná vrodená artériovenózna chyba cerebrálnych ciev"},
{"code":"Q28.29","desc":"Vrodená artériovenózna malformácia cerebrálnych ciev, bližšie neurčená"},
{"code":"Q28.3-","desc":"Iné chyby mozgových ciev"},
{"code":"Q28.30","desc":"Vrodená aneuryzma cerebrálnych ciev"},
{"code":"Q28.31","desc":"Vrodená fistula cerebrálnych ciev"},
{"code":"Q28.38","desc":"Iná vrodená chyba cerebrálnych ciev"},
{"code":"Q28.39","desc":"Vrodená chyba cerebrálnych ciev, bližšie neurčená"},
{"code":"Q28.8-","desc":"Iné vrodené chyby obehovej sústavy, bližšie určená"},
{"code":"Q28.80","desc":"Iná vrodená aneuryzma"},
{"code":"Q28.81","desc":"Iná vrodená fistula obehovej sústavy"},
{"code":"Q28.88","desc":"Iná vrodená chyba obehovej sústavy, bližšie určená"},
{"code":"Q28.9","desc":"Vrodená chyba obehovej sústavy, bližšie neurčená"},
{"code":"Q30-Q34","desc":"Vrodené chyby dýchacích orgánov"},
{"code":"Q30.-","desc":"Vrodená chyba nosa"},
{"code":"Q30.0","desc":"Atrézia choán"},
{"code":"Q30.1","desc":"Nevyvinutie a nedostatočné vyvinutie nosa"},
{"code":"Q30.2","desc":"Škára, zárez a rázštep nosa"},
{"code":"Q30.3","desc":"Vrodené prederavenie nosovej priehradky"},
{"code":"Q30.8","desc":"Iná vrodená chyba nosa"},
{"code":"Q30.9","desc":"Vrodená chyba nosa, bližšie neurčená"},
{"code":"Q31.-","desc":"Vrodená chyba hrtana"},
{"code":"Q31.0","desc":"Diafragma hrtana"},
{"code":"Q31.1","desc":"Vrodená subglotická stenóza"},
{"code":"Q31.2","desc":"Hypoplázia hrtana"},
{"code":"Q31.3","desc":"Laryngokéla"},
{"code":"Q31.5","desc":"Vrodená laryngomalácia"},
{"code":"Q31.8","desc":"Iná vrodená chyba hrtana"},
{"code":"Q31.9","desc":"Vrodená chyba hrtana, bližšie neurčená"},
{"code":"Q32.-","desc":"Vrodená chyba priedušnice a priedušiek"},
{"code":"Q32.0","desc":"Vrodená tracheomalácia"},
{"code":"Q32.1","desc":"Iná vrodená chyba priedušnice"},
{"code":"Q32.2","desc":"Vrodená malácia priedušky"},
{"code":"Q32.3","desc":"Vrodené zúženie priedušky"},
{"code":"Q32.4","desc":"Iná vrodená chyba priedušiek"},
{"code":"Q33.-","desc":"Vrodená chyba pľúc"},
{"code":"Q33.0","desc":"Vrodené cystické pľúca"},
{"code":"Q33.1","desc":"Prídavný lalok pľúc"},
{"code":"Q33.2","desc":"Sekvestrácia pľúc"},
{"code":"Q33.3","desc":"Nevyvinutie pľúc"},
{"code":"Q33.4","desc":"Vrodené bronchiektázie"},
{"code":"Q33.5","desc":"Ektopické tkanivo v pľúcach"},
{"code":"Q33.6","desc":"Hypoplázia a dysplázia pľúc"},
{"code":"Q33.8","desc":"Iná vrodená chyba pľúc"},
{"code":"Q33.9","desc":"Vrodená chyba pľúc, bližšie neurčená"},
{"code":"Q34.-","desc":"Iná vrodená chyba dýchacích orgánov"},
{"code":"Q34.0","desc":"Anomália pohrudnice"},
{"code":"Q34.1","desc":"Vrodená cysta mediastína"},
{"code":"Q34.8","desc":"Iná vrodená chyba dýchacích orgánov, bližšie určená"},
{"code":"Q34.9","desc":"Vrodená chyba dýchacích orgánov, bližšie neurčená"},
{"code":"Q35-Q37","desc":"Rázštep pery a rázštep podnebia"},
{"code":"Q35.-","desc":"Rázštep podnebia"},
{"code":"Q35.1","desc":"Rázštep tvrdého podnebia"},
{"code":"Q35.3","desc":"Rázštep mäkkého podnebia"},
{"code":"Q35.5","desc":"Rázštep tvrdého podnebia s rázštepom mäkkého podnebia"},
{"code":"Q35.7","desc":"Rázštep čapíka [uvuly]"},
{"code":"Q35.9","desc":"Rázštep podnebia, bližšie neurčený"},
{"code":"Q36.-","desc":"Rázštep pery"},
{"code":"Q36.0","desc":"Obojstranný rázštep pery"},
{"code":"Q36.1","desc":"Stredový rázštep pery"},
{"code":"Q36.9","desc":"Jednostranný rázštep pery"},
{"code":"Q37.-","desc":"Rázštep podnebia s rázštepom pery"},
{"code":"Q37.0","desc":"Rázštep tvrdého podnebia s obojstranným rázštepom pery"},
{"code":"Q37.1","desc":"Rázštep tvrdého podnebia s jednostranným rázštepom pery"},
{"code":"Q37.2","desc":"Rázštep mäkkého podnebia s obojstranným rázštepom pery"},
{"code":"Q37.3","desc":"Rázštep mäkkého podnebia s jednostranným rázštepom pery"},
{"code":"Q37.4","desc":"Rázštep tvrdého a mäkkého podnebia s obojstranným rázštepom pery"},
{"code":"Q37.5","desc":"Rázštep tvrdého a mäkkého podnebia s jednostranným rázštepom pery"},
{"code":"Q37.8","desc":"Rázštep podnebia s obojstranným rázštepom pery, bližšie neurčený"},
{"code":"Q37.9","desc":"Rázštep podnebia s jednostranným rázštepom pery, bližšie neurčený"},
{"code":"Q38-Q45","desc":"Iné vrodené chyby tráviacej sústavy"},
{"code":"Q38.-","desc":"Iná vrodená chyba jazyka, úst a hltana"},
{"code":"Q38.0","desc":"Vrodená chyba pier, nezatriedená inde"},
{"code":"Q38.1","desc":"Ankyloglosia"},
{"code":"Q38.2","desc":"Makroglosia"},
{"code":"Q38.3","desc":"Iná vrodená chyba jazyka"},
{"code":"Q38.4","desc":"Vrodená chyba slinných žliaz a vývodov slinných žliaz"},
{"code":"Q38.5","desc":"Vrodená chyba podnebia, nezatriedená inde"},
{"code":"Q38.6","desc":"Iná vrodená chyba úst"},
{"code":"Q38.7","desc":"Vačok hltana (faryngový pouch)"},
{"code":"Q38.8","desc":"Iná vrodená chyba hltana"},
{"code":"Q39.-","desc":"Vrodená chyba pažeráka"},
{"code":"Q39.0","desc":"Atrézia pažeráka bez fistuly"},
{"code":"Q39.1","desc":"Atrézia pažeráka s fistulou medzi priedušnicou a pažerákom"},
{"code":"Q39.2","desc":"Vrodená fistula medzi priedušnicou a pažerákom bez atrézie"},
{"code":"Q39.3","desc":"Vrodené zúženie (stenóza alebo striktúra) pažeráka"},
{"code":"Q39.4","desc":"Pažeráková membrána"},
{"code":"Q39.5","desc":"Vrodené rozšírenie pažeráka"},
{"code":"Q39.6","desc":"Divertikul pažeráka (vrodený)"},
{"code":"Q39.8","desc":"Iná vrodená chyba pažeráka"},
{"code":"Q39.9","desc":"Vrodená chyba pažeráka, bližšie neurčená"},
{"code":"Q40.-","desc":"Iná vrodená chyba hornej tráviacej trubice"},
{"code":"Q40.0","desc":"Vrodená hypertrofická pylorostenóza"},
{"code":"Q40.1","desc":"Vrodená hiátová hernia"},
{"code":"Q40.2","desc":"Iná vrodená chyba žalúdka, bližšie určená"},
{"code":"Q40.3","desc":"Vrodená chyba žalúdka, bližšie neurčená"},
{"code":"Q40.8","desc":"Iná vrodená chyba hornej časti tráviacej trubice, bližšie určená"},
{"code":"Q40.9","desc":"Vrodená chyba hornej časti tráviacej trubice, bližšie neurčená"},
{"code":"Q41.-","desc":"Vrodené chýbanie, atrézia a stenóza tenkého čreva"},
{"code":"Q41.0","desc":"Vrodené chýbanie, atrézia a stenóza dvanástnika"},
{"code":"Q41.1","desc":"Vrodené chýbanie, atrézia a stenóza jejúna"},
{"code":"Q41.2","desc":"Vrodené chýbanie, atrézia a stenóza ilea"},
{"code":"Q41.8","desc":"Vrodené chýbanie, atrézia a stenóza iných bližšie určených častí tenkého čreva"},
{"code":"Q41.9","desc":"Vrodené chýbanie, atrézia a stenóza tenkého čreva, bližšie neurčenej časti"},
{"code":"Q42.-","desc":"Vrodené chýbanie, atrézia a stenóza hrubého čreva"},
{"code":"Q42.0","desc":"Vrodené chýbanie, atrézia a stenóza konečníka s fistulou"},
{"code":"Q42.1","desc":"Vrodené chýbanie, atrézia a stenóza konečníka bez fistuly"},
{"code":"Q42.2","desc":"Vrodené chýbanie, atrézia a stenóza anusu s fistulou"},
{"code":"Q42.3","desc":"Vrodené chýbanie, atrézia a stenóza anusu bez fistuly"},
{"code":"Q42.8","desc":"Vrodené chýbanie, atrézia a stenóza iných častí hrubého čreva"},
{"code":"Q42.9","desc":"Vrodené chýbanie, atrézia a stenóza bližšie neurčenej časti hrubého čreva"},
{"code":"Q43.-","desc":"Iná vrodená chyba čreva"},
{"code":"Q43.0","desc":"Meckelov divertikul"},
{"code":"Q43.1","desc":"Hirschprungova choroba"},
{"code":"Q43.2","desc":"Iná vrodená porucha funkcie hrubého čreva"},
{"code":"Q43.3","desc":"Vrodená chyba fixácie čriev"},
{"code":"Q43.4-","desc":"Zdvojenie čreva"},
{"code":"Q43.40","desc":"Zdvojenie tenkého čreva"},
{"code":"Q43.41","desc":"Zdvojenie hrubého čreva"},
{"code":"Q43.42","desc":"Zdvojenie rekta"},
{"code":"Q43.49","desc":"Zdvojenie čreva, bližšie neurčené"},
{"code":"Q43.5","desc":"Ektopický anus"},
{"code":"Q43.6","desc":"Vrodená fistula rekta a anusu"},
{"code":"Q43.7","desc":"Pretrvávajúca kloaka"},
{"code":"Q43.8","desc":"Iná vrodená chyba čreva, bližšie určená"},
{"code":"Q43.9","desc":"Vrodená chyba čreva, bližšie neurčená"},
{"code":"Q44.-","desc":"Vrodená chyba žlčníka, žlčových vývodov a pečene"},
{"code":"Q44.0","desc":"Agenéza, aplázia a hypoplázia žlčníka"},
{"code":"Q44.1","desc":"Iná vrodená chyba žlčníka"},
{"code":"Q44.2","desc":"Atrézia žlčových ciest"},
{"code":"Q44.3","desc":"Vrodené stenóza alebo striktúra žlčových ciest"},
{"code":"Q44.4","desc":"Cysta žlčovodu"},
{"code":"Q44.5","desc":"Iná vrodená chyba žlčových ciest"},
{"code":"Q44.6","desc":"Cystická choroba pečene"},
{"code":"Q44.7","desc":"Iná vrodená chyba pečene"},
{"code":"Q45.-","desc":"Iná vrodená chyba tráviacej sústavy"},
{"code":"Q45.0","desc":"Agenéza, aplázia a hypoplázia podžalúdkovej žľazy"},
{"code":"Q45.1","desc":"Prstencová podžalúdková žľaza [pancreas anulare]"},
{"code":"Q45.2","desc":"Vrodená cysta podžalúdkovej žľazy"},
{"code":"Q45.3","desc":"Iná vrodená chyba podžalúdkovej žľazy a jej vývodu"},
{"code":"Q45.8","desc":"Iná vrodená chyba tráviacej sústavy, bližšie určená"},
{"code":"Q45.9","desc":"Vrodená chyba tráviacej sústavy, bližšie neurčená"},
{"code":"Q50-Q56","desc":"Vrodená chyba pohlavných orgánov"},
{"code":"Q50.-","desc":"Vrodená chyba vaječníkov, vajíčkovodov a širokých väzov maternice"},
{"code":"Q50.0","desc":"Vrodené chýbanie vaječníka"},
{"code":"Q50.1","desc":"Vývinová (dysontogenetická) cysta vaječníka"},
{"code":"Q50.2","desc":"Vrodená torzia vaječníka"},
{"code":"Q50.3","desc":"Iná vrodená chyba vaječníka"},
{"code":"Q50.4","desc":"Embryonálna cysta vajíčkovodu"},
{"code":"Q50.5","desc":"Embryonálna cysta širokého väzu maternice [lig.latum uteri]"},
{"code":"Q50.6","desc":"Iná vrodená chyba vajíčkovodu a širokého väzu"},
{"code":"Q51.-","desc":"Vrodená chyba maternice a krčka maternice"},
{"code":"Q51.0","desc":"Agenéza a aplázia maternice"},
{"code":"Q51.1","desc":"Dvojitá maternica s dvojitým krčkom a dvojitou pošvou"},
{"code":"Q51.2","desc":"Iné zdvojenie maternice"},
{"code":"Q51.3","desc":"Dvojrohá maternica [uterus bicornis ]"},
{"code":"Q51.4","desc":"Jednorohá maternica [uterus unicornis]"},
{"code":"Q51.5","desc":"Agenéza a aplázia krčka maternice"},
{"code":"Q51.6","desc":"Embryonálna cysta krčka maternice"},
{"code":"Q51.7","desc":"Vrodená fistula medzi maternicou, tráviacim a močovým traktom"},
{"code":"Q51.8","desc":"Iná vrodená chyba maternice a krčka maternice"},
{"code":"Q51.9","desc":"Vrodená chyba maternice a krčka maternice, bližšie neurčená"},
{"code":"Q52.-","desc":"Iná vrodená chyba ženských pohlavných orgánov"},
{"code":"Q52.0","desc":"Vrodené chýbanie pošvy"},
{"code":"Q52.1","desc":"Zdvojenie pošvy"},
{"code":"Q52.2","desc":"Vrodená fistula medzi konečníkom a pošvou"},
{"code":"Q52.3","desc":"Neperforovaný hymen"},
{"code":"Q52.4","desc":"Iná vrodená chyba pošvy"},
{"code":"Q52.5","desc":"Fúzia pyskov"},
{"code":"Q52.6","desc":"Vrodená chyba klitorisu"},
{"code":"Q52.7","desc":"Iná vrodená chyba vulvy"},
{"code":"Q52.8","desc":"Iná vrodená chyba ženských pohlavných orgánov, bližšie určená"},
{"code":"Q52.9","desc":"Vrodená chyba ženských pohlavných orgánov, bližšie neurčená"},
{"code":"Q53.-","desc":"Nezostúpený semenník"},
{"code":"Q53.0","desc":"Ektopický semenník"},
{"code":"Q53.1","desc":"Jednostranne nezostúpený semenník"},
{"code":"Q53.2","desc":"Obojstranne nezostúpený semenník"},
{"code":"Q53.9","desc":"Nezostúpený semenník, bližšie neurčený"},
{"code":"Q54.-","desc":"Hypospádia"},
{"code":"Q54.0","desc":"Hypospádia žaluďa [balanická]"},
{"code":"Q54.1","desc":"Hypospádia penisu [penilná]"},
{"code":"Q54.2","desc":"Hypospádia penisu a skróta [penoskrotálna]"},
{"code":"Q54.3","desc":"Hypospádia perineálna"},
{"code":"Q54.4","desc":"Vrodené ventrálne ohnutie penisu [vrodená chordea]"},
{"code":"Q54.8","desc":"Iná hypospádia"},
{"code":"Q54.9","desc":"Hypospádia, bližšie neurčená"},
{"code":"Q55.-","desc":"Iné vrodené chyby mužských pohlavných orgánov"},
{"code":"Q55.0","desc":"Chýbanie a aplázia semenníka"},
{"code":"Q55.1","desc":"Hypoplázia semenníka a mieška"},
{"code":"Q55.2","desc":"Iná vrodená chyba semenníka a mieška"},
{"code":"Q55.3","desc":"Atrézia semenovodu"},
{"code":"Q55.4","desc":"Iná vrodená chyba semenovodu, nadsemenníkov, semenných vačkov a prostaty"},
{"code":"Q55.5","desc":"Vrodené chýbanie a aplázia penisu"},
{"code":"Q55.6","desc":"Iná vrodená chyba penisu"},
{"code":"Q55.8","desc":"Iná vrodená chyba mužských pohlavných orgánov, bližšie určená"},
{"code":"Q55.9","desc":"Vrodená chyba mužských pohlavných orgánov, bližšie neurčená"},
{"code":"Q56.-","desc":"Neurčité pohlavie a pseudohermafroditizmus"},
{"code":"Q56.0","desc":"Hermafroditizmus, nezatriedený inde"},
{"code":"Q56.1","desc":"Mužský pseudohermafroditizmus, nezatriedený inde"},
{"code":"Q56.2","desc":"Ženský pseudohermafroditizmus, nezatriedený inde"},
{"code":"Q56.3","desc":"Pseudohermafroditizmus, bližšie neurčený"},
{"code":"Q56.4","desc":"Neurčité pohlavie, bližšie neurčené"},
{"code":"Q60-Q64","desc":"Vrodené chyby močovej sústavy"},
{"code":"Q60.-","desc":"Agenéza a iné hypoplázie obličiek"},
{"code":"Q60.0","desc":"Jednostranná agenéza obličky"},
{"code":"Q60.1","desc":"Obojstranná agenéza obličky"},
{"code":"Q60.2","desc":"Agenéza obličky, bližšie neurčená"},
{"code":"Q60.3","desc":"Jednostranná hypoplázia obličky"},
{"code":"Q60.4","desc":"Obojstranná hypoplázia obličky"},
{"code":"Q60.5","desc":"Hypoplázia obličky, bližšie neurčená"},
{"code":"Q60.6","desc":"Potterovej syndróm"},
{"code":"Q61.-","desc":"Cystická choroba obličiek"},
{"code":"Q61.0","desc":"Vrodená samostatná (solitárna) cysta obličky"},
{"code":"Q61.1","desc":"Polycystické obličky, autozomálne recesívne"},
{"code":"Q61.2","desc":"Polycystické obličky, autozomálne dominantné"},
{"code":"Q61.3","desc":"Polycystické obličky, bližšie neurčené"},
{"code":"Q61.4","desc":"Dysplázia obličiek"},
{"code":"Q61.5","desc":"Medulárna cystická oblička"},
{"code":"Q61.8","desc":"Iná cystická choroba obličiek"},
{"code":"Q61.9","desc":"Cystická choroba obličiek, bližšie neurčená"},
{"code":"Q62.-","desc":"Vrodené obštrukčné chyby obličkovej panvičky a vrodené chyby močovodu"},
{"code":"Q62.0","desc":"Vrodená hydronefróza"},
{"code":"Q62.1","desc":"Atrézia a vrodená stenóza močovodu"},
{"code":"Q62.2","desc":"Vrodený megaureter"},
{"code":"Q62.3","desc":"Iná obštrukčná chyba obličkovej panvičky a močovodu"},
{"code":"Q62.4","desc":"Agenéza močovodu"},
{"code":"Q62.5","desc":"Zdvojenie močovodu"},
{"code":"Q62.6","desc":"Malpozícia močovodu"},
{"code":"Q62.7","desc":"Vrodený veziko-uretero-renálny reflux"},
{"code":"Q62.8","desc":"Iná vrodená chyba močovodu"},
{"code":"Q63.-","desc":"Iná vrodená chyba obličiek"},
{"code":"Q63.0","desc":"Prídavná [akcesórna] oblička"},
{"code":"Q63.1","desc":"Lalokovitá, spojená a podkovovitá oblička"},
{"code":"Q63.2","desc":"Ektopická oblička"},
{"code":"Q63.3","desc":"Hyperplastická a obrovská oblička"},
{"code":"Q63.8","desc":"Iná vrodená chyba obličiek, bližšie určená"},
{"code":"Q63.9","desc":"Vrodená chyba obličky, bližšie neurčená"},
{"code":"Q64.-","desc":"Iná vrodená chyba močovej sústavy"},
{"code":"Q64.0","desc":"Epispádia"},
{"code":"Q64.1","desc":"Exstrofia močového mechúra"},
{"code":"Q64.2","desc":"Vrodená zadná chlopňa močovej rúry"},
{"code":"Q64.3","desc":"Iná atrézia a stenóza močovej rúry a krčka močového mechúra"},
{"code":"Q64.4","desc":"Chyba urachu [prvomočovodu]"},
{"code":"Q64.5","desc":"Vrodené chýbanie mechúra a močovej rúry"},
{"code":"Q64.6","desc":"Vrodený divertikul mechúra"},
{"code":"Q64.7","desc":"Iná vrodená chyba močového mechúra a močovej rúry"},
{"code":"Q64.8","desc":"Iná vrodená chyba močovej sústavy, bližšie určená"},
{"code":"Q64.9","desc":"Vrodená chyba močovej sústavy, bližšie neurčená"},
{"code":"Q65-Q79","desc":"Vrodené chyby a deformity svalov a kostí"},
{"code":"Q65.-","desc":"Vrodené deformity bedra"},
{"code":"Q65.0","desc":"Jednostranné vrodené vykĺbenie bedra"},
{"code":"Q65.1","desc":"Obojstranné vrodené vykĺbenie bedra"},
{"code":"Q65.2","desc":"Vrodené vykĺbenie bedra, bližšie neurčené"},
{"code":"Q65.3","desc":"Jednostranná vrodená subluxácia bedra"},
{"code":"Q65.4","desc":"Obojstranná vrodená subluxácia bedra"},
{"code":"Q65.5","desc":"Vrodená subluxácia bedra, bližšie neurčená"},
{"code":"Q65.6","desc":"Nestabilné bedro"},
{"code":"Q65.8","desc":"Iná vrodená deformita bedra"},
{"code":"Q65.9","desc":"Vrodená deformita bedra, bližšie neurčená"},
{"code":"Q66.-","desc":"Vrodená deformita nôh"},
{"code":"Q66.0","desc":"Pes equinovarus congenitus"},
{"code":"Q66.1","desc":"Pes calcaneovarus congenitus"},
{"code":"Q66.2","desc":"Pes adductus (congenitus)"},
{"code":"Q66.3","desc":"Iná vrodená varózna deformita nohy"},
{"code":"Q66.4","desc":"Pes calcaneovalgus congenitus"},
{"code":"Q66.5","desc":"Pes planus congenitus"},
{"code":"Q66.6","desc":"Iná vrodená valgózna deformita nohy"},
{"code":"Q66.7","desc":"Pes cavus"},
{"code":"Q66.8","desc":"Iná vrodená deformita nohy"},
{"code":"Q66.9","desc":"Vrodená deformita nohy, bližšie neurčená"},
{"code":"Q67.-","desc":"Vrodená deformita svalov a kostí hlavy, tváre, chrbtice a hrudníka"},
{"code":"Q67.0","desc":"Nesúmernosť tváre"},
{"code":"Q67.1","desc":"Stlačená tvár"},
{"code":"Q67.2","desc":"Dolichocefália"},
{"code":"Q67.3","desc":"Plagiocefália"},
{"code":"Q67.4","desc":"Iná vrodená deformita lebky, tváre a čeľuste"},
{"code":"Q67.5","desc":"Vrodená deformita chrbtice"},
{"code":"Q67.6","desc":"Pectus excavatum"},
{"code":"Q67.7","desc":"Pectus carinatum"},
{"code":"Q67.8","desc":"Iná vrodená deformita hrudníka"},
{"code":"Q68.-","desc":"Iná vrodená deformita svalov a kostí"},
{"code":"Q68.0","desc":"Vrodená deformita kývača hlavy"},
{"code":"Q68.1","desc":"Vrodená deformita ruky"},
{"code":"Q68.2","desc":"Vrodená deformita kolena"},
{"code":"Q68.3","desc":"Vrodené ohnutie stehnovej kosti"},
{"code":"Q68.4","desc":"Vrodené ohnutie píšťaly a ihlice"},
{"code":"Q68.5","desc":"Vrodené ohnutie dlhých kostí dolnej končatiny, bližšie neurčené"},
{"code":"Q68.8","desc":"Iná vrodená deformita svalov a kostí, bližšie určená"},
{"code":"Q69.-","desc":"Polydaktýlia"},
{"code":"Q69.0","desc":"Nadpočetný prst (prsty) ruky"},
{"code":"Q69.1","desc":"Nadpočetný palec (palce) ruky"},
{"code":"Q69.2","desc":"Nadpočetný prst (prsty) nohy"},
{"code":"Q69.9","desc":"Polydaktýlia, bližšie neurčená"},
{"code":"Q70.-","desc":"Syndaktýlia"},
{"code":"Q70.0","desc":"Zrastené prsty ruky"},
{"code":"Q70.1","desc":"Blany medzi prstami ruky"},
{"code":"Q70.2","desc":"Zrastené prsty nohy"},
{"code":"Q70.3","desc":"Blany medzi prstami nohy"},
{"code":"Q70.4","desc":"Polysyndaktýlia"},
{"code":"Q70.9","desc":"Syndaktýlia, bližšie neurčená"},
{"code":"Q71.-","desc":"Redukčné defekty hornej končatiny"},
{"code":"Q71.0","desc":"Vrodené kompletné chýbanie hornej končatiny (končatín)"},
{"code":"Q71.1","desc":"Vrodené chýbanie ramena a predlaktia s prítomnosťou ruky"},
{"code":"Q71.2","desc":"Vrodené chýbanie predlaktia a ruky"},
{"code":"Q71.3","desc":"Vrodené chýbanie ruky a prsta (prstov)"},
{"code":"Q71.4","desc":"Pozdĺžny redukčný defekt vretenovitej kosti (radius)"},
{"code":"Q71.5","desc":"Skrátenie lakťovej kosti"},
{"code":"Q71.6","desc":"Klepetovitá ruka"},
{"code":"Q71.8","desc":"Iný redukčný defekt hornej končatiny (končatín)"},
{"code":"Q71.9","desc":"Redukčný defekt hornej končatiny, bližšie neurčený"},
{"code":"Q72.-","desc":"Redukčné defekty dolnej končatiny"},
{"code":"Q72.0","desc":"Vrodené kompletné chýbanie dolnej končatiny (končatín)"},
{"code":"Q72.1","desc":"Vrodené chýbanie stehna a predkolenia s prítomnou nohou"},
{"code":"Q72.2","desc":"Vrodené chýbanie predkolenia a nohy"},
{"code":"Q72.3","desc":"Vrodené chýbanie nohy a prstov"},
{"code":"Q72.4","desc":"Skrátenie stehnovej kosti"},
{"code":"Q72.5","desc":"Skrátenie píšťaly"},
{"code":"Q72.6","desc":"Skrátenie ihlice"},
{"code":"Q72.7","desc":"Rázštep nohy"},
{"code":"Q72.8","desc":"Iný redukčný defekt dolnej končatiny (končatín)"},
{"code":"Q72.9","desc":"Redukčný defekt dolnej končatiny, bližšie neurčený"},
{"code":"Q73.-","desc":"Redukčný defekt bližšie neurčenej končatiny"},
{"code":"Q73.0","desc":"Vrodené chýbanie bližšie neurčenej končatiny (končatín)"},
{"code":"Q73.1","desc":"Fokomélia, bližšie neurčená končatina (končatiny)"},
{"code":"Q73.8","desc":"Iný redukčný defekt bližšie neurčenej končatiny (končatín)"},
{"code":"Q74.-","desc":"Iná vrodená chyba končatiny (končatín)"},
{"code":"Q74.0","desc":"Iná vrodená chyba hornej končatiny (končatín) vrátane plecového pletenca"},
{"code":"Q74.1","desc":"Vrodená chyba kolena"},
{"code":"Q74.2","desc":"Iná vrodená chyba dolnej končatiny (končatín) vrátane panvového pletenca"},
{"code":"Q74.3","desc":"Arthrogryposis multiplex congenita"},
{"code":"Q74.8","desc":"Iná vrodená chyba končatiny (končatín), bližšie určená"},
{"code":"Q74.9","desc":"Vrodená chyba končatiny (končatín), bližšie neurčená"},
{"code":"Q75.-","desc":"Iná vrodená chyba kostí lebky a tvárových kostí"},
{"code":"Q75.0","desc":"Kraniosynostóza"},
{"code":"Q75.1","desc":"Kranofaciálna dyzostóza"},
{"code":"Q75.2","desc":"Hypertelorizmus"},
{"code":"Q75.3","desc":"Makrocefália"},
{"code":"Q75.4","desc":"Mandibulofaciálna dyzostóza"},
{"code":"Q75.5","desc":"Okulomandibulárna dyzostóza"},
{"code":"Q75.8","desc":"Iná vrodená chyba kostí lebky a tvárových kostí, bližšie určená"},
{"code":"Q75.9","desc":"Vrodená chyba kostí lebky a tvárových kostí, bližšie neurčená"},
{"code":"Q76.-","desc":"Vrodená chyba chrbtice a kostí hrudníka"},
{"code":"Q76.0","desc":"Skrytý rázštep chrbtice [Spina bifida occulta]"},
{"code":"Q76.1","desc":"Klippelov-Feilov syndróm"},
{"code":"Q76.2-","desc":"Vrodená spondylolistéza a spondylolýza"},
{"code":"Q76.21","desc":"Vrodená spondylolistéza"},
{"code":"Q76.22","desc":"Vrodená spondylolýza"},
{"code":"Q76.3","desc":"Vrodená skolióza zapríčinená vrodenou chybou kosti"},
{"code":"Q76.4","desc":"Iná vrodená chyba chrbtice bez skoliózy"},
{"code":"Q76.5","desc":"Krčné rebro"},
{"code":"Q76.6","desc":"Iná vrodená chyba rebier"},
{"code":"Q76.7","desc":"Vrodená chyba hrudníkovej kosti"},
{"code":"Q76.8","desc":"Iná vrodená chyba kostí hrudníka"},
{"code":"Q76.9","desc":"Vrodená chyba kostí hrudníka, bližšie neurčená"},
{"code":"Q77.-","desc":"Osteochondrodysplázie s poruchou rastu dlhých kostí a chrbtice"},
{"code":"Q77.0","desc":"Achondrogenéza"},
{"code":"Q77.1","desc":"Letálny nanizmus"},
{"code":"Q77.2","desc":"Syndróm krátkeho rebra"},
{"code":"Q77.3","desc":"Chondrodysplasia punctata"},
{"code":"Q77.4","desc":"Achondroplázia"},
{"code":"Q77.5","desc":"Diastrofická dysplázia"},
{"code":"Q77.6","desc":"Chondroektodermálna dysplázia"},
{"code":"Q77.7","desc":"Spondyloepifýzová dysplázia"},
{"code":"Q77.8","desc":"Iná osteochondrodysplázia s poruchami rastu dlhých kostí a chrbtice"},
{"code":"Q77.9","desc":"Osteochondrodysplázia s poruchami rastu dlhých kostí a chrbtice, bližšie neurčená"},
{"code":"Q78.-","desc":"Iná osteochondrodysplázia"},
{"code":"Q78.0","desc":"Osteogenesis imperfecta"},
{"code":"Q78.1","desc":"Polyostotická fibrózna dysplázia [Jaffého-Lichtensteinov syndróm]"},
{"code":"Q78.2","desc":"Osteopetróza"},
{"code":"Q78.3","desc":"Progresívna diafýzová dysplázia"},
{"code":"Q78.4","desc":"Enchondromatóza"},
{"code":"Q78.5","desc":"Metafýzová dysplázia"},
{"code":"Q78.6","desc":"Mnohopočetné vrodené exostózy"},
{"code":"Q78.8","desc":"Iná osteochondrodysplázia, bližšie určená"},
{"code":"Q78.9","desc":"Osteochondrodysplázia, bližšie neurčená"},
{"code":"Q79.-","desc":"Vrodené chyby svalov a kostí, nezatriedené inde"},
{"code":"Q79.0","desc":"Vrodená diafragmatická hernia (bránicová prietrž)"},
{"code":"Q79.1","desc":"Iná vrodená chyba bránice"},
{"code":"Q79.2","desc":"Exomfalus"},
{"code":"Q79.3","desc":"Gastroschíza"},
{"code":"Q79.4","desc":"Agenéza brušných svalov [Prune belly syndróm]"},
{"code":"Q79.5","desc":"Iná vrodená chyba brušnej steny"},
{"code":"Q79.6","desc":"Ehlersov-Danlosov syndróm"},
{"code":"Q79.8","desc":"Iná vrodená chyba svalov a kostí"},
{"code":"Q79.9","desc":"Vrodená chyba svalov a kostí, bližšie neurčená"},
{"code":"Q80-Q89","desc":"Iná vrodená chyba"},
{"code":"Q80.-","desc":"Vrodená ichtyóza"},
{"code":"Q80.0","desc":"Ichthyosis vulgaris"},
{"code":"Q80.1","desc":"Ichtyóza viazaná na chromozóm X"},
{"code":"Q80.2","desc":"Lamelárna ichtyóza"},
{"code":"Q80.3","desc":"Vrodená bulózne ichtyoziformná erytrodermia"},
{"code":"Q80.4","desc":"Ichtyosis congenita gravis [Harlekinfetus]"},
{"code":"Q80.8","desc":"Iná vrodená ichtyóza"},
{"code":"Q80.9","desc":"Vrodená ichtyóza, bližšie neurčená"},
{"code":"Q81.-","desc":"Bulózna epidermolýza"},
{"code":"Q81.0","desc":"Jednoduchá bulózna epidermolýza"},
{"code":"Q81.1","desc":"Letálna bulózna epidermolýza"},
{"code":"Q81.2","desc":"Dystrofická bulózna epidermolýza"},
{"code":"Q81.8","desc":"Iná bulózna epidermolýza"},
{"code":"Q81.9","desc":"Bulózna epidermolýza, bližšie neurčená"},
{"code":"Q82.-","desc":"Iná vrodená chyba kože"},
{"code":"Q82.0","desc":"Dedičný lymfedém"},
{"code":"Q82.1","desc":"Xeroderma pigmentosum"},
{"code":"Q82.2","desc":"Mastocytóza"},
{"code":"Q82.3","desc":"Incontinentia pigmenti"},
{"code":"Q82.4","desc":"Ektodermálna dysplázia (anhidrotická)"},
{"code":"Q82.5","desc":"Vrodený nenádorový névus"},
{"code":"Q82.8","desc":"Iná vrodená chyba kože, bližšie určená"},
{"code":"Q82.9","desc":"Vrodená chyba kože, bližšie neurčená"},
{"code":"Q83.-","desc":"Vrodená chyba prsníka"},
{"code":"Q83.0","desc":"Vrodené chýbanie prsníka s chýbajúcou bradavkou"},
{"code":"Q83.1","desc":"Prídavný prsník"},
{"code":"Q83.2","desc":"Chýbajúca bradavka"},
{"code":"Q83.3","desc":"Prídavná bradavka"},
{"code":"Q83.8","desc":"Iná vrodená chyba prsníka"},
{"code":"Q83.9","desc":"Vrodená chyba prsníka, bližšie neurčená"},
{"code":"Q84.-","desc":"Iná vrodená chyba spoločnej (kožnej) pokrývky [integumentum commune]"},
{"code":"Q84.0","desc":"Vrodená alopécia"},
{"code":"Q84.1","desc":"Vrodené morfologické zmeny vlasov, nezatriedené inde"},
{"code":"Q84.2","desc":"Iná vrodená chyba vlasov"},
{"code":"Q84.3","desc":"Anonychia"},
{"code":"Q84.4","desc":"Vrodená leukonychia"},
{"code":"Q84.5","desc":"Zväčšené a hypertrofické nechty"},
{"code":"Q84.6","desc":"Iná vrodená chyba nechtov"},
{"code":"Q84.8","desc":"Iná vrodená chyba spoločnej (kožnej) pokrývky,bližšie určená"},
{"code":"Q84.9","desc":"Vrodená chyba spoločnej (kožnej) pokrývky, bližšie neurčená"},
{"code":"Q85.-","desc":"Fakomatóza, nezatriedené inde"},
{"code":"Q85.0","desc":"Neurofibromatóza (benígna)"},
{"code":"Q85.1","desc":"Tuberózna skleróza"},
{"code":"Q85.8","desc":"Iná fakomatóza, nezatriedená inde"},
{"code":"Q85.9","desc":"Fakomatóza, bližšie neurčená"},
{"code":"Q86.-","desc":"Syndróm vrodenej chyby, zapríčinený známymi vonkajšími príčinami, nezatriedený inde"},
{"code":"Q86.0","desc":"FAS - Fetálny alkoholový syndróm (dysmorfický)"},
{"code":"Q86.1","desc":"Hydantoínový syndróm plodu"},
{"code":"Q86.2","desc":"Dysmorfizmus, zapríčinený warfarínom"},
{"code":"Q86.8","desc":"Iný syndróm vrodených chýb, zapríčinený známymi vonkajšími príčinami"},
{"code":"Q87.-","desc":"Syndrómy iných bližšie určených vrodených chýb, postihujúci viaceré systémy"},
{"code":"Q87.0","desc":"Syndrómy vrodených chýb postihujúce najmä výzor tváre"},
{"code":"Q87.1","desc":"Syndrómy vrodených chýb spojené najmä s nízkym vzrastom"},
{"code":"Q87.2","desc":"Syndrómy vrodených chýb zahŕňajúce najmä končatiny"},
{"code":"Q87.3","desc":"Syndrómy vrodených chýb zahŕňajúce skorý nadmerný rast"},
{"code":"Q87.4","desc":"Marfanov syndróm"},
{"code":"Q87.5","desc":"Iné syndrómy vrodených chýb s inými zmenami kostí"},
{"code":"Q87.8","desc":"Iné syndrómy vrodených chýb, nezatriedené inde, bližšie určené"},
{"code":"Q89.-","desc":"Iná vrodená chyba, nezatriedená inde"},
{"code":"Q89.0","desc":"Vrodená chyba sleziny"},
{"code":"Q89.1","desc":"Vrodená chyba nadobličiek"},
{"code":"Q89.2","desc":"Vrodená chyba iných žliaz s vnútorným vylučovaním"},
{"code":"Q89.3","desc":"Obrátená poloha (situs inversus)"},
{"code":"Q89.4","desc":"Spojené (zrastené) dvojčatá"},
{"code":"Q89.7","desc":"Mnohopočetné vrodené chyby, nezatriedené inde"},
{"code":"Q89.8","desc":"Iná vrodená chyba, bližšie určená"},
{"code":"Q89.9","desc":"Vrodená chyba, bližšie neurčená"},
{"code":"Q90-Q99","desc":"Chromozómové abnormality, nezatriedené inde"},
{"code":"Q90.-","desc":"Downov syndróm"},
{"code":"Q90.0","desc":"Trizómia 21, meiotická nondisjunkcia"},
{"code":"Q90.1","desc":"Trizómia 21, mozaicizmus (mitotická nondisjunkcia)"},
{"code":"Q90.2","desc":"Trizómia 21, translokácia"},
{"code":"Q90.9","desc":"Downov syndróm, bližšie neurčený"},
{"code":"Q91.-","desc":"Edwardsov syndróm a Patauov syndróm"},
{"code":"Q91.0","desc":"Trizómia 18, meiotická nondisjunkcia"},
{"code":"Q91.1","desc":"Trizómia 18, mozaicizmus (mitotická nondisjunkcia)"},
{"code":"Q91.2","desc":"Trizómia 18, translokácia"},
{"code":"Q91.3","desc":"Edwardsov syndróm, bližšie neurčený"},
{"code":"Q91.4","desc":"Trizómia 13, meiotická nondisjunkcia"},
{"code":"Q91.5","desc":"Trizómia 13, mozaicizmus (mitotická nondisjunkcia)"},
{"code":"Q91.6","desc":"Trizómia 13, translokácia"},
{"code":"Q91.7","desc":"Patauov syndróm, bližšie neurčený"},
{"code":"Q92.-","desc":"Iné trizómie a čiastočné trizómie autozómov, nezatriedené inde"},
{"code":"Q92.0","desc":"Kompletná chromozómová trizómia, meiotická nondisjunkcia"},
{"code":"Q92.1","desc":"Kompletná chromozómová trizómia, mozaicizmus (mitotická nondisjunkcia)"},
{"code":"Q92.2","desc":"Veľká čiastočná trizómia"},
{"code":"Q92.3","desc":"Malá čiastočná trizómia"},
{"code":"Q92.4","desc":"Duplikácie viditeľné iba v prometafáze"},
{"code":"Q92.5","desc":"Duplikácie s inou komplexnou prestavbou"},
{"code":"Q92.6","desc":"Nadpočetné (marker) chromozómy"},
{"code":"Q92.7","desc":"Triploidia a polyploidia"},
{"code":"Q92.8","desc":"Iná trizómia a čiastočná trizómia autozómov, nezatriedená inde"},
{"code":"Q92.9","desc":"Trizómia a čiastočná trizómia autozómov, bližšie neurčená"},
{"code":"Q93.-","desc":"Monozómia a delécia autozómov, nezatriedená inde"},
{"code":"Q93.0","desc":"Kompletná chromozómová monozómia, meiotická nondisjunkcia"},
{"code":"Q93.1","desc":"Kompletná chromozómová monozómia, mozaicizmus (mitotická nondisjunkcia)"},
{"code":"Q93.2","desc":"Prsteňovitý alebo dicentrický chromozóm"},
{"code":"Q93.3","desc":"Delécia krátkeho ramena chromozómu 4"},
{"code":"Q93.4","desc":"Delécia krátkeho ramena chromozómu 5"},
{"code":"Q93.5","desc":"Iná delécia časti chromozómu"},
{"code":"Q93.6","desc":"Delécia viditeľná iba v prometafáze"},
{"code":"Q93.7","desc":"Delécia s inou komplexnou prestavbou"},
{"code":"Q93.8","desc":"Iná delécia autozómov"},
{"code":"Q93.9","desc":"Delécia autozómov, bližšie neurčená"},
{"code":"Q95.-","desc":"Balansovaná chromozómová prestavba a štruktúrne markery, nezatriedené inde"},
{"code":"Q95.0","desc":"Vyvážená translokácia a inzercia u normálneho jednotlivca"},
{"code":"Q95.1","desc":"Chromozómová inverzia u normálneho jednotlivca"},
{"code":"Q95.2","desc":"Vyvážená autozómová prestavba (rearrangement) u abnormálneho jednotlivca"},
{"code":"Q95.3","desc":"Vyvážená prestavba (rearrangement) medzi pohlavnými a autozómovými chromozómami u abnormálneho jednotlivca"},
{"code":"Q95.4","desc":"Jednotlivec s nadpočetným (marker) heterochromatínom"},
{"code":"Q95.5","desc":"Jednotlivec s fragilnými miestami na autozómoch"},
{"code":"Q95.8","desc":"Iné vyvážené prestavby (rearrangement) a štruktúrne markery (abnormálne chromozómy)"},
{"code":"Q95.9","desc":"Vyvážená prestavba (rearrangement) a štruktúrny marker (abnormálny gén), bližšie neurčený"},
{"code":"Q96.-","desc":"Turnerov syndróm"},
{"code":"Q96.0","desc":"Karyotyp 45,X"},
{"code":"Q96.1","desc":"Karyotyp 46,X izo (Xq)"},
{"code":"Q96.2","desc":"Karyotyp 46,X s patologickým pohlavným chromozómom, s výnimkou izo (Xq)"},
{"code":"Q96.3","desc":"Mozaicizmus, 45,X/46, XX alebo XY"},
{"code":"Q96.4","desc":"Mozaicizmus, 45,X /iná bunková línia (e) s patologickým pohlavným chromozómom"},
{"code":"Q96.8","desc":"Iný variant Turnerovho syndrómu"},
{"code":"Q96.9","desc":"Turnerov syndróm, bližšie neurčený"},
{"code":"Q97.-","desc":"Iná abnormalita pohlavných chromozómov so ženským fenotypom, nezatriedená inde"},
{"code":"Q97.0","desc":"Karyotyp 47,XXX"},
{"code":"Q97.1","desc":"Ženský fenotyp s viac ako troma chromozómami X"},
{"code":"Q97.2","desc":"Mozaicizmus, línie s rozličným počtom X chromozómov"},
{"code":"Q97.3","desc":"Ženský fenotyp s karyotypom 46, XY"},
{"code":"Q97.8","desc":"Iná abnormalita pohlavných chromozómov so ženským fenotypom, bližšie určená"},
{"code":"Q97.9","desc":"Abnormalita pohlavných chromozómov so ženským fenotypom, bližšie neurčená"},
{"code":"Q98.-","desc":"Iná abnormalita pohlavných chromozómov s mužským fenotypom, nezatriedená inde"},
{"code":"Q98.0","desc":"Klinefelterov syndróm, karyotyp 47,XXY"},
{"code":"Q98.1","desc":"Klinefelterov syndróm, mužský fenotyp s viac ako dvoma chromozómami X"},
{"code":"Q98.2","desc":"Klinefelterov syndróm, mužský fenotyp s karyotypom 46,XX"},
{"code":"Q98.3","desc":"Iný mužský fenotyp s karyotypom 46,XX"},
{"code":"Q98.4","desc":"Klinefelterov syndróm, bližšie neurčený"},
{"code":"Q98.5","desc":"Karyotyp 47,XYY"},
{"code":"Q98.6","desc":"Mužský fenotyp so štruktúrnou abnormalitou pohlavných chromozómov"},
{"code":"Q98.7","desc":"Mužský fenotyp s mozaicizmom pohlavných chromozómov"},
{"code":"Q98.8","desc":"Iná abnormalita pohlavných chromozómov s mužským fenotypom, bližšie určená"},
{"code":"Q98.9","desc":"Abnormalita pohlavných chromozómov s mužským fenotypom, bližšie neurčená"},
{"code":"Q99.-","desc":"Iná chromozómová abnormalita, nezatriedená inde"},
{"code":"Q99.0","desc":"Chiméra 46,XX/46,XY"},
{"code":"Q99.1","desc":"Pravý hermafrodit 46,XX"},
{"code":"Q99.2","desc":"Fragilný chromozóm X"},
{"code":"Q99.8","desc":"Iná chromozómová abnormalita, bližšie určená"},
{"code":"Q99.9","desc":"Chromozómová abnormalita, bližšie neurčená"},
{"code":"R00-R99","desc":"XVIII. kapitola - Subjektívne a objektívne príznaky, abnormálne klinické a laboratórne nálezy, nezatriedené inde (R00-R99)"},
{"code":"R00-R09","desc":"Subjektívne a objektívne príznaky postihnutia obehovej a dýchacej sústavy, bližšie určené"},
{"code":"R00.-","desc":"Poruchy srdcového rytmu"},
{"code":"R00.0","desc":"Tachykardia, bližšie neurčená"},
{"code":"R00.1","desc":"Bradykardia, bližšie neurčená"},
{"code":"R00.2","desc":"Palpitácie"},
{"code":"R00.8","desc":"Iná a bližšie neurčená porucha srdcovej činnosti"},
{"code":"R01.-","desc":"Srdcový šelest a iný srdcový zvuk"},
{"code":"R01.0","desc":"Benígny a neškodný (akcidentálny) srdcový šelest"},
{"code":"R01.1","desc":"Srdcový šelest, bližšie neurčený"},
{"code":"R01.2","desc":"Iný srdcový zvuk"},
{"code":"R02","desc":"Gangréna, nezatriedená inde"},
{"code":"R03.-","desc":"Abnormálne hodnoty krvného tlaku bez určenia diagnózy"},
{"code":"R03.0","desc":"Nameranie zvýšeného krvného tlaku bez diagnózy artériovej hypertenzie"},
{"code":"R03.1","desc":"Nameranie nešpecifického zníženého krvného tlaku"},
{"code":"R04.-","desc":"Krvácanie z dýchacích ciest"},
{"code":"R04.0","desc":"Epistaxa"},
{"code":"R04.1","desc":"Krvácanie z hrdla"},
{"code":"R04.2","desc":"Hemoptýza"},
{"code":"R04.8","desc":"Krvácanie z iných miest v dýchacích cestách"},
{"code":"R04.9","desc":"Krvácanie z dýchacích ciest, bližšie neurčené"},
{"code":"R05","desc":"Kašeľ"},
{"code":"R06.-","desc":"Porucha dýchania"},
{"code":"R06.0","desc":"Dýchavičnosť (dyspnoe)"},
{"code":"R06.1","desc":"Stridor (hvizdot) pri dýchaní"},
{"code":"R06.2","desc":"Sipenie (pískanie) pri dýchaní"},
{"code":"R06.3","desc":"Periodické dýchanie"},
{"code":"R06.4","desc":"Hyperventilácia"},
{"code":"R06.5","desc":"Dýchanie ústami"},
{"code":"R06.6","desc":"Čkanie"},
{"code":"R06.7","desc":"Kýchanie"},
{"code":"R06.8-","desc":"Iná a bližšie neurčená porucha dýchania"},
{"code":"R06.80","desc":"Akútna život ohrozujúca príhoda v dojčenskom veku"},
{"code":"R06.88","desc":"Iná a bližšie neurčená porucha dýchania"},
{"code":"R07.-","desc":"Bolesť v hrdle a bolesť v hrudníku"},
{"code":"R07.0","desc":"Bolesť v hrdle"},
{"code":"R07.1","desc":"Bolesť v hrudníku pri dýchaní"},
{"code":"R07.2","desc":"Prekordiálna bolesť"},
{"code":"R07.3","desc":"Iná bolesť v hrudníku"},
{"code":"R07.4","desc":"Bolesť v hrudníku, bližšie neurčená"},
{"code":"R09.-","desc":"Iné subjektívne a objektívne príznaky postihnutia obehovej a dýchacej sústavy"},
{"code":"R09.0","desc":"Asfyxia"},
{"code":"R09.1","desc":"Zápal pohrudnice"},
{"code":"R09.2","desc":"Zastavenie dýchania"},
{"code":"R09.3","desc":"Abnormálne spútum"},
{"code":"R09.8","desc":"Iné subjektívne a objektívne príznaky postihnutia obehovej a dýchacej sústavy, bližšie určené"},
{"code":"R10-R19","desc":"Subjektívne a objektívne príznaky postihnutia tráviacej sústavy a brucha"},
{"code":"R10.-","desc":"Bolesť brucha a panvy"},
{"code":"R10.0","desc":"Akútne brucho"},
{"code":"R10.1","desc":"Bolesť v hornej časti brucha"},
{"code":"R10.2","desc":"Bolesť v oblasti panvy a hrádze"},
{"code":"R10.3","desc":"Bolesť v iných častiach podbrušia"},
{"code":"R10.4","desc":"Iná a bližšie neurčená bolesť brucha"},
{"code":"R11","desc":"Pocit na vracanie (nauzea) a vracanie (vomitus)"},
{"code":"R12","desc":"Pálenie záhy"},
{"code":"R13.-","desc":"Dysfágia"},
{"code":"R13.0","desc":"Dysfágia s potrebou dohľadu počas prijímania potravy"},
{"code":"R13.1","desc":"Dysfágia pri tracheostómii s (čiastočne) nafúknutým balónikom a potrebou toalety dýchacích ciest"},
{"code":"R13.9","desc":"Iná dysfágia, bližšie neurčená"},
{"code":"R14","desc":"Flatulencia a podobné poruchy"},
{"code":"R15","desc":"Inkontinencia stolice"},
{"code":"R16.-","desc":"Hepatomegália a splenomegália, nezatriedené inde"},
{"code":"R16.0","desc":"Hepatomegália, nezatriedená inde"},
{"code":"R16.1","desc":"Splenomegália, nezatriedená inde"},
{"code":"R16.2","desc":"Hepatomegália so splenomegáliou, nezatriedená inde"},
{"code":"R17","desc":"Žltačka, bližšie neurčená"},
{"code":"R18","desc":"Ascites"},
{"code":"R19.-","desc":"Iné subjektívne a objektívne príznaky postihnutia tráviacej sústavy a brucha"},
{"code":"R19.0","desc":"Zdurenie, masa, hrča v oblasti brucha alebo panvy"},
{"code":"R19.1","desc":"Abnormálne črevné zvuky"},
{"code":"R19.2","desc":"Viditeľná peristaltika"},
{"code":"R19.3","desc":"Stuhnutie (rigidita) brucha"},
{"code":"R19.4","desc":"Zmena vo vyprázdňovaní stolice"},
{"code":"R19.5","desc":"Iná porucha stolice"},
{"code":"R19.6","desc":"Halitóza"},
{"code":"R19.8","desc":"Iné subjektívne a objektívne príznaky postihnutia tráviacej sústavy a brucha, bližšie určené"},
{"code":"R20-R23","desc":"Subjektívne a objektívne príznaky postihnutia kože a podkožného tkaniva"},
{"code":"R20.-","desc":"Porucha kožnej citlivosti"},
{"code":"R20.0","desc":"Necitlivosť kože"},
{"code":"R20.1","desc":"Znížená citlivosť [hypestézia] kože"},
{"code":"R20.2","desc":"Kožné parestézie"},
{"code":"R20.3","desc":"Zvýšená citlivosť [hyperestézia] kože"},
{"code":"R20.8","desc":"Iná porucha kožnej citlivosti, bližšie určená"},
{"code":"R21","desc":"Raš a iné nešpecifické kožné vyrážky"},
{"code":"R22.-","desc":"Lokalizované zdurenie, útvar, hrča kože a podkožného tkaniva"},
{"code":"R22.0","desc":"Lokalizované zdurenie, útvar, hrča na hlave"},
{"code":"R22.1","desc":"Lokalizované zdurenie, útvar, hrča na krku"},
{"code":"R22.2","desc":"Lokalizované zdurenie, útvar, hrča na trupe"},
{"code":"R22.3","desc":"Lokalizované zdurenie, útvar, hrča na hornej končatine"},
{"code":"R22.4","desc":"Lokalizované zdurenie, útvar, hrča na dolnej končatine"},
{"code":"R22.7","desc":"Lokalizované zdurenie, útvar, hrča na viacerých miestach"},
{"code":"R22.9","desc":"Lokalizované zdurenie, útvar, hrča, bližšie neurčená"},
{"code":"R23.-","desc":"Iné kožné zmeny"},
{"code":"R23.0","desc":"Cyanóza"},
{"code":"R23.1","desc":"Bledosť [pallor]"},
{"code":"R23.2","desc":"Sčervenenie"},
{"code":"R23.3","desc":"Spontánne ekchymózy"},
{"code":"R23.4","desc":"Zmeny štruktúry kože"},
{"code":"R23.8","desc":"Iné a bližšie neurčené zmeny kože"},
{"code":"R25-R29","desc":"Subjektívne a objektívne príznaky postihnutia nervovej, svalovej a kostrovej sústavy"},
{"code":"R25.-","desc":"Abnormálne mimovôľové pohyby"},
{"code":"R25.0","desc":"Abnormálne pohyby hlavy"},
{"code":"R25.1","desc":"Trasľavosť [tremor], bližšie neurčená"},
{"code":"R25.2","desc":"Kŕč a spazmus"},
{"code":"R25.3","desc":"Fascikulácia"},
{"code":"R25.8","desc":"Iné a bližšie neurčené abnormálne mimovôľové pohyby"},
{"code":"R26.-","desc":"Porucha chôdze a pohyblivosti"},
{"code":"R26.0","desc":"Ataktická chôdza"},
{"code":"R26.1","desc":"Paralytická chôdza"},
{"code":"R26.2","desc":"Ťažkosti pri chôdzi, nezatriedené inde"},
{"code":"R26.3","desc":"Imobilita"},
{"code":"R26.8","desc":"Iná a bližšie neurčená porucha chôdze a pohyblivosti (mobility)"},
{"code":"R27.-","desc":"Iná porucha koordinácie"},
{"code":"R27.0","desc":"Ataxia, bližšie neurčená"},
{"code":"R27.8","desc":"Iná a bližšie neurčená porucha koordinácie"},
{"code":"R29.-","desc":"Iný subjektívny a objektívny príznak postihnutia nervovej, svalovej a kostrovej sústavy"},
{"code":"R29.0","desc":"Tetania"},
{"code":"R29.1","desc":"Meningizmus"},
{"code":"R29.2","desc":"Abnormálny reflex"},
{"code":"R29.3","desc":"Porucha držania tela"},
{"code":"R29.4","desc":"Lúpavé bedro [coxa saltans]"},
{"code":"R29.5","desc":"Neurologický neglect syndróm"},
{"code":"R29.6","desc":"Náchylnosť k pádom, nezatriedená inde"},
{"code":"R29.8","desc":"Iný a bližšie neurčený subjektívny a objetívny príznak postihnutia nervovej, svalovej a kostrovej sústavy"},
{"code":"R30-R39","desc":"Subjektívne a objektívne príznaky porúch močovej sústavy"},
{"code":"R30.-","desc":"Bolesť pri močení"},
{"code":"R30.0","desc":"Dyzúria"},
{"code":"R30.1","desc":"Bolestivé nutkanie na močenie [tenesmus vesicae]"},
{"code":"R30.9","desc":"Bolestivé močenie, bližšie neurčené"},
{"code":"R31","desc":"Hematúria, bližšie neurčená"},
{"code":"R32","desc":"Inkontinencia moču, bližšie neurčená"},
{"code":"R33","desc":"Retencia moču"},
{"code":"R34","desc":"Anúria a oligúria"},
{"code":"R35","desc":"Polyúria"},
{"code":"R36","desc":"Výtok z uretry"},
{"code":"R39.-","desc":"Iný subjektívny a objektívny príznak poruchy močovej sústavy"},
{"code":"R39.0","desc":"Extravazácia moču"},
{"code":"R39.1","desc":"Iné poruchy močenia"},
{"code":"R39.2","desc":"Extrarenálna urémia"},
{"code":"R39.8","desc":"Iný a bližšie neurčený subjektívny a objektívny príznak poruchy močovej sústavy"},
{"code":"R40-R46","desc":"Subjektívne a objektívne príznaky porúch poznávania, vnímania, emočného stavu a správania"},
{"code":"R40.-","desc":"Somnolencia, sopor a kóma"},
{"code":"R40.0","desc":"Somnolencia"},
{"code":"R40.1","desc":"Sopor (stupor)"},
{"code":"R40.2","desc":"Kóma, bližšie neurčená"},
{"code":"R41.-","desc":"Iné subjektívne a objektívne príznaky porúch poznávacích funkcií a vedomia"},
{"code":"R41.0","desc":"Dezorientácia, bližšie neurčená"},
{"code":"R41.1","desc":"Antegrádna amnézia"},
{"code":"R41.2","desc":"Retrográdna amnézia"},
{"code":"R41.3","desc":"Iná amnézia"},
{"code":"R41.8","desc":"Iné a bližšie neurčené subjektívne a objektívne príznaky porúch poznávacích funkcií a vedomia"},
{"code":"R42","desc":"Závrat [vertigo]"},
{"code":"R43.-","desc":"Poruchy čuchu a chuti"},
{"code":"R43.0","desc":"Anosmia"},
{"code":"R43.1","desc":"Parosmia"},
{"code":"R43.2","desc":"Parageusia"},
{"code":"R43.8","desc":"Iné a bližšie neurčené poruchy čuchu a chuti"},
{"code":"R44.-","desc":"Iné subjektívne a objektívne príznaky porúch celkovej citlivosti a zmyslového vnímania"},
{"code":"R44.0","desc":"Sluchové halucinácie"},
{"code":"R44.1","desc":"Zrakové halucinácie"},
{"code":"R44.2","desc":"Iné halucinácie"},
{"code":"R44.3","desc":"Halucinácie, bližšie neurčené"},
{"code":"R44.8","desc":"Iné a bližšie neurčené subjektívne a objektívne príznaky porúch celkovej citlivosti a zmyslového vnímania"},
{"code":"R45.-","desc":"Subjektívne a objektívne príznaky porúch emočného stavu"},
{"code":"R45.0","desc":"Nervozita"},
{"code":"R45.1","desc":"Nepokoj a vzrušenosť"},
{"code":"R45.2","desc":"Pocit nešťastia"},
{"code":"R45.3","desc":"Demoralizácia a apatia"},
{"code":"R45.4","desc":"Podráždenosť a zlosť"},
{"code":"R45.5","desc":"Nepriateľské pocity"},
{"code":"R45.6","desc":"Fyzické násilie"},
{"code":"R45.7","desc":"Stav emočného šoku a stresu, bližšie neurčený"},
{"code":"R45.8","desc":"Iné subjektívne a objektívne príznaky porúch emočného stavu"},
{"code":"R46.-","desc":"Subjektívne a objektívne príznaky súvisiace s výzorom a správaním"},
{"code":"R46.0","desc":"Veľmi nízka úroveň osobnej hygieny"},
{"code":"R46.1","desc":"Bizarný osobný zjav"},
{"code":"R46.2","desc":"Čudné a nevysvetliteľné správanie"},
{"code":"R46.3","desc":"Hyperaktivita"},
{"code":"R46.4","desc":"Pomalosť a slabé reagovanie"},
{"code":"R46.5","desc":"Podozrievavosť a nápadná vyhýbavosť"},
{"code":"R46.6","desc":"Neprimeraná ustarostenosť a zaujatosť zaťažkávacími udalosťami"},
{"code":"R46.7","desc":"Veľavravnosť s nepodstatnými podrobnosťami, ktoré zastierajú dôvod na kontakt"},
{"code":"R46.8","desc":"Iné subjektívne a objektívne príznaky súvisiace s výzorom a správaním"},
{"code":"R47-R49","desc":"Subjektívne a objektívne príznaky porúch reči a hlasu"},
{"code":"R47.-","desc":"Poruchy reči, nezatriedené inde"},
{"code":"R47.0","desc":"Dysfázia a afázia"},
{"code":"R47.1","desc":"Dysartria a anartria"},
{"code":"R47.8","desc":"Iná a bližšie neurčená porucha reči"},
{"code":"R48.-","desc":"Dyslexia a iné symbolické dysfunkcie, nezatriedené inde"},
{"code":"R48.0","desc":"Dyslexia a alexia"},
{"code":"R48.1","desc":"Agnózia"},
{"code":"R48.2","desc":"Apraxia"},
{"code":"R48.8","desc":"Iné symbolické dysfunkcie, bližšie neurčené"},
{"code":"R49.-","desc":"Poruchy hlasu"},
{"code":"R49.0","desc":"Dysfónia"},
{"code":"R49.1","desc":"Afónia"},
{"code":"R49.2","desc":"Hypernazalita a hyponazalita"},
{"code":"R49.8","desc":"Iné poruchy hlasu, bližšie neurčené"},
{"code":"R50-R69","desc":"Celkové subjektívne a objektívne príznaky"},
{"code":"R50.-","desc":"Horúčka iného a neznámeho pôvodu"},
{"code":"R50.2","desc":"Horúčka zapríčinená liekmi"},
{"code":"R50.8-","desc":"Iná horúčka, bližšie určená"},
{"code":"R50.80","desc":"Horúčka neznámeho pôvodu"},
{"code":"R50.88","desc":"Iná horúčka, bližšie určená"},
{"code":"R50.9","desc":"Horúčka, bližšie neurčená"},
{"code":"R51","desc":"Bolesť hlavy"},
{"code":"R52.-","desc":"Bolesť, nezatriedená inde"},
{"code":"R52.0","desc":"Akútna bolesť"},
{"code":"R52.1","desc":"Chronická neutíšiteľná bolesť"},
{"code":"R52.2","desc":"Iná chronická bolesť"},
{"code":"R52.3","desc":"Chronická neuropatická bolesť"},
{"code":"R52.4","desc":"Chronická nádorová bolesť"},
{"code":"R52.9","desc":"Bolesť, bližšie neurčená"},
{"code":"R53","desc":"Malátnosť a únava"},
{"code":"R54","desc":"Senilita"},
{"code":"R55","desc":"Synkopa a kolaps"},
{"code":"R56.-","desc":"Kŕče, nezatriedené inde"},
{"code":"R56.0","desc":"Febrilné kŕče"},
{"code":"R56.8","desc":"Iné kŕče, bližšie neurčené"},
{"code":"R57.-","desc":"Šok, nezatriedený inde"},
{"code":"R57.0","desc":"Kardiogénny šok"},
{"code":"R57.1","desc":"Hypovolemický šok"},
{"code":"R57.2","desc":"Septický šok"},
{"code":"R57.8","desc":"Iný šok"},
{"code":"R57.9","desc":"Šok, bližšie neurčený"},
{"code":"R58","desc":"Krvácanie, nezatriedené inde"},
{"code":"R59.-","desc":"Zväčšenie lymfatických uzlín"},
{"code":"R59.0","desc":"Lokalizované zväčšenie lymfatických uzlín"},
{"code":"R59.1","desc":"Generalizované zväčšenie lymfatických uzlín"},
{"code":"R59.9","desc":"Zväčšenie lymfatických uzlín, bližšie neurčené"},
{"code":"R60.-","desc":"Opuch, nezatriedený inde"},
{"code":"R60.0","desc":"Lokalizovaný opuch"},
{"code":"R60.1","desc":"Generalizovaný opuch"},
{"code":"R60.9","desc":"Opuch, bližšie neurčený"},
{"code":"R61.-","desc":"Hyperhidróza"},
{"code":"R61.0","desc":"Lokalizovaná hyperhidróza"},
{"code":"R61.1","desc":"Generalizovaná hyperhidróza"},
{"code":"R61.9","desc":"Hyperhidróza, bližšie neurčená"},
{"code":"R62.-","desc":"Zaostávanie v očakávanom fyziologickom vývine"},
{"code":"R62.0","desc":"Oneskorené dosiahnutie očakávaného stupňa fyziologického vývinu"},
{"code":"R62.8","desc":"Iné zaostávanie v očakávanom fyziologickom vývine"},
{"code":"R62.9","desc":"Zaostávanie v očakávanom normálnom fyziologickom vývine, bližšie neurčené"},
{"code":"R63.-","desc":"Subjektívne a objektívne príznaky súvisiace s prijímaním potravy a tekutín"},
{"code":"R63.0","desc":"Anorexia (nechutenstvo)"},
{"code":"R63.1","desc":"Polydipsia"},
{"code":"R63.2","desc":"Polyfágia"},
{"code":"R63.3","desc":"Ťažkosti s výživou a nesprávne usmerňovanie výživy"},
{"code":"R63.4","desc":"Abnormálne chudnutie"},
{"code":"R63.5","desc":"Abnormálne priberanie"},
{"code":"R63.6","desc":"Nedostatočný príjem potravy a vody v dôsledku zanedbávania seba samého"},
{"code":"R63.8","desc":"Iné subjektívne a objektívne príznaky súvisiace s prijímaním potravy a tekutín"},
{"code":"R64","desc":"Kachexia (vycivenosť)"},
{"code":"R65.-!","desc":"Syndróm systémovej zápalovej odpovede [SIRS]"},
{"code":"R65.0!","desc":"Syndróm systémovej zápalovej odpovede (SIRS) infekčného pôvodu bez orgánového zlyhávania"},
{"code":"R65.1!","desc":"Syndróm systémovej zápalovej odpovede (SIRS) infekčnej genézy s orgánovým zlyhávaním"},
{"code":"R65.2!","desc":"Syndróm systémovej zápalovej odpovede (SIRS) neinfekčnej genézy bez orgánového zlyhávania"},
{"code":"R65.3!","desc":"Syndróm systémovej zápalovej odpovede (SIRS) neinfekčnej genézy s orgánovým zlyhávaním"},
{"code":"R65.9!","desc":"Syndróm systémovej zápalovej odpovede (SIRS), bližšie neurčený"},
{"code":"R68.-","desc":"Iné celkové subjektívne a objektívne príznaky"},
{"code":"R68.0","desc":"Hypotermia nesúvisiaca s nízkou teplotou prostredia"},
{"code":"R68.1","desc":"Nešpecifické príznaky charakteristické pre dojčenský vek"},
{"code":"R68.2","desc":"Suchosť úst, bližšie neurčená"},
{"code":"R68.3","desc":"Kyjačikovité prsty"},
{"code":"R68.8","desc":"Iné celkové subjektívne a objektívne príznaky, bližšie určené"},
{"code":"R69","desc":"Neznáme a bližšie neurčené príčiny chorobnosti"},
{"code":"R70-R79","desc":"Abnormálne nálezy pri vyšetrení krvi bez určenej diagnózy"},
{"code":"R70.-","desc":"Zrýchlená sedimentácia erytrocytov a abnormálna viskozita plazmy"},
{"code":"R70.0","desc":"Zrýchlená sedimentácia erytrocytov"},
{"code":"R70.1","desc":"Abnormálna viskozita plazmy"},
{"code":"R71","desc":"Abnormality červených krviniek"},
{"code":"R72","desc":"Abnormality bielych krviniek, nezatriedené inde"},
{"code":"R73.-","desc":"Zvýšená koncentrácia glukózy v krvnom sére"},
{"code":"R73.0","desc":"Abnormálny glukózový tolerančný test"},
{"code":"R73.9","desc":"Hyperglykémia, bližšie neurčená"},
{"code":"R74.-","desc":"Abnormálna aktivita enzýmov v krvnom sére"},
{"code":"R74.0","desc":"Zvýšená aktivita aminotransferáz a dehydrogenázy kyseliny mliečnej [LDH] v krvnom sére"},
{"code":"R74.8","desc":"Abnormálna aktivita iných enzýmov v krvnom sére"},
{"code":"R74.9","desc":"Abnormálna aktivita bližšie neurčeného enzýmu v krvnom sére"},
{"code":"R75","desc":"Laboratórny dôkaz vírusu ľudskej imunodeficiencie (HIV)"},
{"code":"R76.-","desc":"Iný abnormálny imunologický nález v krvnom sére"},
{"code":"R76.0","desc":"Zvýšený titer protilátky"},
{"code":"R76.1","desc":"Abnormálna reakcia na tuberkulínový test"},
{"code":"R76.2","desc":"Falošne pozitívna sérologická skúška na syfilis"},
{"code":"R76.8","desc":"Iné abnormálne imunologické nálezy v krvnom sére, bližšie určené"},
{"code":"R76.9","desc":"Abnormálny imunologický nález v krvnom sére, bližšie neurčený"},
{"code":"R77.-","desc":"Iné abnormality bielkovín plazmy"},
{"code":"R77.0","desc":"Abnormality albumínu"},
{"code":"R77.1","desc":"Abnormality globulínu"},
{"code":"R77.2","desc":"Abnormality alfa-fetoproteínu"},
{"code":"R77.8","desc":"Ostatné bližšie určené abnormality bielkovín plazmy"},
{"code":"R77.9","desc":"Abnormality bielkovín plazmy, bližšie neurčené"},
{"code":"R78.-","desc":"Nálezy liekov a iných látok, ktoré sa normálne v krvi nenachádzajú"},
{"code":"R78.0","desc":"Nález alkoholu v krvi"},
{"code":"R78.1","desc":"Nález opiátov v krvi"},
{"code":"R78.2","desc":"Nález kokaínu v krvi"},
{"code":"R78.3","desc":"Nález halucinogénu v krvi"},
{"code":"R78.4","desc":"Nález iných liekov a látok s možnosťou závislosti"},
{"code":"R78.5","desc":"Nález psychotropnej látky v krvi"},
{"code":"R78.6","desc":"Nález steroidovej látky v krvi"},
{"code":"R78.7","desc":"Nález abnormálnej koncentrácie ťažkého kovu v krvi"},
{"code":"R78.8","desc":"Nález inej bližšie určenej látky, ktorá sa normálne v krvi nenachádza"},
{"code":"R78.9","desc":"Nález bližšie neurčenej látky, ktorá sa normálne v krvi nenachádza"},
{"code":"R79.-","desc":"Iné abnormálne nálezy v chemickom zložení krvi"},
{"code":"R79.0","desc":"Abnormálna koncentrácia minerálov v krvnom sére"},
{"code":"R79.8","desc":"Iné bližšie určené abnormálne nálezy v chemickom zložení krvi"},
{"code":"R79.9","desc":"Abnormálny nález v chemickom zložení krvi, bližšie neurčený"},
{"code":"R80-R82","desc":"Abnormálne nálezy pri vyšetrení moču bez diagnózy"},
{"code":"R80","desc":"Izolovaná proteinúria"},
{"code":"R81","desc":"Glykozúria"},
{"code":"R82.-","desc":"Iné abnormálne nálezy v moči"},
{"code":"R82.0","desc":"Chylúria"},
{"code":"R82.1","desc":"Myoglobinúria"},
{"code":"R82.2","desc":"Bilirubinúria"},
{"code":"R82.3","desc":"Hemoglobinúria"},
{"code":"R82.4","desc":"Acetonúria"},
{"code":"R82.5","desc":"Zvýšená koncentrácia liekov, liečiv a biologicky aktívnych látok v moči"},
{"code":"R82.6","desc":"Abnormálna koncentrácia látky prevažne nemedicínskeho pôvodu v moči"},
{"code":"R82.7","desc":"Abnormálne nálezy pri mikrobiologickom vyšetrení moču"},
{"code":"R82.8","desc":"Abnormálne nálezy pri cytologickom a histologickom vyšetrení moču"},
{"code":"R82.9","desc":"Iné a bližšie neurčené abnormálne nálezy v moči"},
{"code":"R83-R89","desc":"Abnormálne nálezy pri vyšetrovaní iných telových tekutín, látok a tkanív bez diagnózy"},
{"code":"R83.-","desc":"Abnormálny nález v mozgovomiechovom moku"},
{"code":"R83.0","desc":"Abnormálny nález v mozgovomiechovom moku: abnormálna hodnota enzýmov"},
{"code":"R83.1","desc":"Abnormálny nález v mozgovomiechovom moku: abnormálna hodnota hormónov"},
{"code":"R83.2","desc":"Abnormálny nález v mozgovomicehovom moku: abnormálna hodnota liekov, liečiv a biologicky aktívnych látok"},
{"code":"R83.3","desc":"Abnormálny nález v mozgovomiechovom moku: abnormálna hodnota látok prevažne nemedicínskeho pôvodu"},
{"code":"R83.4","desc":"Abnormálny nález v mozgovomiechovom moku: abnormálny imunologický nález"},
{"code":"R83.5","desc":"Abnormálny nález v mozgovomiechovom moku: abnormálny mikrobiologický nález"},
{"code":"R83.6","desc":"Abnormálny nález v mozgovomiechovom moku: abnormálny cytologický nález"},
{"code":"R83.7","desc":"Abnormálny nález v mozgovomiechovom moku: abnormálny histologický nález"},
{"code":"R83.8","desc":"Abnormálny nález v mozgovomiechovom moku: iný abnormálny nález"},
{"code":"R83.9","desc":"Abnormálny nález v mozgovomiechovom moku: bližšie neurčený abnormálny nález"},
{"code":"R84.-","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka"},
{"code":"R84.0","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: abnormálna hodnota enzýmov"},
{"code":"R84.1","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: abnormálna hodnota hornónov"},
{"code":"R84.2","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: abnormálna hodnota liekov, liečiv a biologicky aktívnych látok"},
{"code":"R84.3","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: abnormálna hodnota látok prevažne nemedicínskeho pôvodu"},
{"code":"R84.4","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: abnormálny imunologický nález"},
{"code":"R84.5","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: abnormálny mikrobiologický nález"},
{"code":"R84.6","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: abnormálny cytologický nález"},
{"code":"R84.7","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: abnormálny histologický nález"},
{"code":"R84.8","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: iný abnormálny nález"},
{"code":"R84.9","desc":"Abnormálny nález vo vzorkách z dýchacích orgánov a hrudníka: bližšie neurčený abnormálny nález"},
{"code":"R85.-","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny"},
{"code":"R85.0","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: abnormálna hodnota enzýmov"},
{"code":"R85.1","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: abnormálna hodnota hormónov"},
{"code":"R85.2","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: abnormálna hodnota liekov, liečiv a biologicky aktívnych látok"},
{"code":"R85.3","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: abnormálna hodnota látok prevažne nemedicínskeho pôvodu"},
{"code":"R85.4","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: abnormálny imunologický nález"},
{"code":"R85.5","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: abnormálny mikrobiologický nález"},
{"code":"R85.6","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: abnormálny cytologický nález"},
{"code":"R85.7","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: abnormálny histologický nález"},
{"code":"R85.8","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: iný abnormálny nález"},
{"code":"R85.9","desc":"Abnormálny nález vo vzorkách z tráviacich orgánov a brušnej dutiny: bližšie neurčený abnormálny nález"},
{"code":"R86.-","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov"},
{"code":"R86.0","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: abnormálna hodnota enzýmov"},
{"code":"R86.1","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: abnormálna hodnota hormónov"},
{"code":"R86.2","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: abnormálna hodnota liekov, liečiv a biologicky aktívnych látok"},
{"code":"R86.3","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: abnormálna hodnota látok prevažne nemedicínskeho pôvodu"},
{"code":"R86.4","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: abnormálny imunologický nález"},
{"code":"R86.5","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: abnormálny mikrobiologický nález"},
{"code":"R86.6","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: abnormálny cytologický nález"},
{"code":"R86.7","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: abnormálny histologický nález"},
{"code":"R86.8","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: iný abnormálny nález"},
{"code":"R86.9","desc":"Abnormálny nález vo vzorkách z mužských pohlavných orgánov: bližšie neurčený abnormálny nález"},
{"code":"R87.-","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov"},
{"code":"R87.0","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: abnormálna hladina enzýmov"},
{"code":"R87.1","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: abnormálna hladina hormónov"},
{"code":"R87.2","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: abnormálna hladina liekov, liečiv a biologicky aktívnych látok"},
{"code":"R87.3","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: abnormálna hladina látok prevažne nemedicínskeho pôvodu"},
{"code":"R87.4","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: abnormálny imunologický nález"},
{"code":"R87.5","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: abnormálny mikrobiologický nález"},
{"code":"R87.6.-","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: abnormálny cytologický nález"},
{"code":"R87.60","desc":"Abnormálne skvamózne bunky neznámej signifikancie (ASCUS)"},
{"code":"R87.61","desc":"Abnormálne skvanózne bunky vysokého stupňa (ASC-H)"},
{"code":"R87.62","desc":"Skvamózna intraepiteliálna lézia nízkeho stupňa (L-SIL)"},
{"code":"R87.63","desc":"Skvamózna intraepiteliálna lézia vysokého stupňa (H-SIL)"},
{"code":"R87.64","desc":"Cytologický dôkaz malignity zo steru krčka maternice"},
{"code":"R87.65","desc":"Chýbajúce žľazové bunky transformačnej zóny"},
{"code":"R87.66","desc":"Iný abnormálny cytologický nález"},
{"code":"R87.67","desc":"Nešpecifikovaný abnormálny cytologický nález"},
{"code":"R87.7","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: abnormálny histologický nález"},
{"code":"R87.8","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: iný abnormálny nález"},
{"code":"R87.9","desc":"Abnormálny nález vo vzorkách zo ženských pohlavných orgánov: bližšie neurčený abnormálny nález"},
{"code":"R89.-","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív"},
{"code":"R89.0","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: abnormálna hodnota enzýmov"},
{"code":"R89.1","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: abnormálna hodnota hormónov"},
{"code":"R89.2","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: abnormálna hodnota liekov, liečiv a biologicky aktívnych látok"},
{"code":"R89.3","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: abnormálna hodnota látok prevažne nemedicínskeho pôvodu"},
{"code":"R89.4","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: abnormálný imunologicky nález"},
{"code":"R89.5","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: abnormálny mikrobiologický nález"},
{"code":"R89.6","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: abnormálny cytologický nález"},
{"code":"R89.7","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: abnormálny histologický nález"},
{"code":"R89.8","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: iný abnormálny nález"},
{"code":"R89.9","desc":"Abnormálny nález vo vzorkách z iných orgánov, sústav a tkanív: bližšie neurčený abnormálny nález"},
{"code":"R90-R94","desc":"Abnormálne nálezy pri diagnostickom zobrazovaní a funkčných vyšetreniach bez diagnózy"},
{"code":"R90.-","desc":"Abnormálny nález pri diagnostickom zobrazovaní centrálnej nervovej sústavy"},
{"code":"R90.0","desc":"Vnútrolebkový rozpínavý proces"},
{"code":"R90.8","desc":"Iné abnormálne nálezy pri diagnostickom zobrazovaní centrálnej nervovej sústavy"},
{"code":"R91","desc":"Abnormálny nález pri diagnostickom zobrazení pľúc"},
{"code":"R92","desc":"Abnormálny nález pri diagnostickom zobrazení prsníka"},
{"code":"R93.-","desc":"Abnormálny nález pri diagnostickom zobrazení iných štruktúr tela"},
{"code":"R93.0","desc":"Abnormálny nález pri diagnostickom zobrazení lebky a hlavy, nezatriedený inde"},
{"code":"R93.1","desc":"Abnormálny nález pri diagnostickom zobrazení srdca a koronárnej cirkulácie"},
{"code":"R93.2","desc":"Abnormálny nález pri diagnostickom zobrazení pečene a žlčových ciest"},
{"code":"R93.3","desc":"Abnormálny nález pri diagnostickom zobrazení iných častí tráviacej sústavy"},
{"code":"R93.4","desc":"Abnormálny nález pri diagnostickom zobrazení močových orgánov"},
{"code":"R93.5","desc":"Abnormálny nález pri diagnostickom zobrazení iných oblastí brucha vrátane retroperitonea"},
{"code":"R93.6","desc":"Abnormálny nález pri diagnostickom zobrazení končatín"},
{"code":"R93.7","desc":"Abnormálny nález pri diagnostickom zobrazení iných častí svalovej a kostrovej sústavy"},
{"code":"R93.8","desc":"Abnormálny nález pri diagnostickom zobrazení iných bližšie určených telových štruktúr"},
{"code":"R94.-","desc":"Abnormálne výsledky funkčných vyšetrení"},
{"code":"R94.0","desc":"Abnormálne výsledky funkčných vyšetrení centrálnej nervovej sústavy"},
{"code":"R94.1","desc":"Abnormálne výsledky funkčných vyšetrení periférnej nervovej sústavy a zmyslových orgánov"},
{"code":"R94.2","desc":"Abnormálne výsledky funkčných vyšetrení pľúc"},
{"code":"R94.3","desc":"Abnormálne výsledky kardiovaskulárnych funkčných vyšetrení"},
{"code":"R94.4","desc":"Abnormálne výsledky funkčných vyšetrení obličiek"},
{"code":"R94.5","desc":"Abnormálne výsledky funkčných vyšetrení pečene"},
{"code":"R94.6","desc":"Abnormálne výsledky funkčných vyšetrení štítnej žľazy"},
{"code":"R94.7","desc":"Abnormálne výsledky vyšetrení iných endokrinných funkcií"},
{"code":"R94.8","desc":"Abnormálne výsledky funkčných vyšetrení iných orgánov a sústav"},
{"code":"R95-R99","desc":"Nepresne určené a neznáme príčiny smrti"},
{"code":"R95.-","desc":"Náhla smrť dojčaťa"},
{"code":"R95.0","desc":"Syndróm náhlej smrti dojčaťa [SIDS] so zmienkou o pitve"},
{"code":"R95.9","desc":"Syndróm náhlej smrti dojčaťa [SIDS] bez zmienky o pitve"},
{"code":"R96.-","desc":"Iná náhla smrť z neznámej príčiny"},
{"code":"R96.0","desc":"Náhla smrť"},
{"code":"R96.1","desc":"Smrť, ktorá nastala za menej ako 24 hodín od začiatku príznakov, ináč nevysvetlená"},
{"code":"R98","desc":"Smrť bez svedkov"},
{"code":"R99","desc":"Iná nepresne určená a neurčená príčina smrti"},
{"code":"S00-T98","desc":"XIX. kapitola - Poranenia, otravy a niektoré iné následky vonkajších príčin (S00-T98)"},
{"code":"S00-S09","desc":"Poranenia hlavy"},
{"code":"S00.-","desc":"Povrchové poranenie hlavy"},
{"code":"S00.0-","desc":"Povrchové poranenie vlasatej kože hlavy"},
{"code":"S00.00","desc":"Povrchové poranenie vlasatej kože hlavy: druh poranenia bližšie neurčený"},
{"code":"S00.01","desc":"Povrchové poranenie vlasatej kože hlavy: odrenina"},
{"code":"S00.02","desc":"Povrchové poranenie vlasatej kože hlavy: tvorba pľuzgierov (netermických)"},
{"code":"S00.03","desc":"Povrchové poranenie vlasatej kože hlavy: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S00.04","desc":"Povrchové poranenie vlasatej kože hlavy: povrchové cudzie telesá (úlomky)"},
{"code":"S00.05","desc":"Povrchové poranenie vlasatej kože hlavy: pomliaždenie"},
{"code":"S00.08","desc":"Povrchové poranenie vlasatej kože hlavy: iné"},
{"code":"S00.1","desc":"Pomliaždenie mihalnice a okolia oka"},
{"code":"S00.2-","desc":"Iné povrchové poranenie mihalnice a okolia oka"},
{"code":"S00.20","desc":"Iné povrchové poranenie mihalnice a okolia oka: druh poranenia bližšie neurčený"},
{"code":"S00.21","desc":"Iné povrchové poranenie mihalníc a periokulárnej oblasti: odrenina"},
{"code":"S00.22","desc":"Iné povrchové poranenie mihalníc a periokulárnej oblasti: tvorba pľuzgierov (netermických)"},
{"code":"S00.23","desc":"Iné povrchové poranenie mihalníc a periokulárnej oblasti: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S00.24","desc":"Iné povrchové poranenie mihalníc a periokulárnej oblasti: povrchové cudzie telesá (úlomky)"},
{"code":"S00.28","desc":"Iné povrchové poranenie mihalníc a periokulárnej oblasti: iné"},
{"code":"S00.3-","desc":"Povrchové poranenie nosa"},
{"code":"S00.30","desc":"Povrchové poranenie nosa: druh poranenia bližšie neurčený"},
{"code":"S00.31","desc":"Povrchové poranenie nosa: odrenina"},
{"code":"S00.32","desc":"Povrchové poranenie nosa: tvorba pľuzgierov (netermických)"},
{"code":"S00.33","desc":"Povrchové poranenie nosa: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S00.34","desc":"Povrchové poranenie nosa: povrchové cudzie telesá (úlomky)"},
{"code":"S00.35","desc":"Povrchové poranenie nosa: pomliaždenie"},
{"code":"S00.38","desc":"Povrchové poranenie nosa: iné"},
{"code":"S00.4-","desc":"Povrchové poranenie ucha"},
{"code":"S00.40","desc":"Povrchové poranenie ucha: druh poranenia bližšie neurčený"},
{"code":"S00.41","desc":"Povrchové poranenie ucha: odrenina"},
{"code":"S00.42","desc":"Povrchové poranenie ucha: tvorba pľuzgierov (netermických)"},
{"code":"S00.43","desc":"Povrchové poranenie ucha: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S00.44","desc":"Povrchové poranenie ucha: povrchové cudzie telesá (úlomky)"},
{"code":"S00.45","desc":"Povrchové poranenie ucha: pomliaždenie"},
{"code":"S00.48","desc":"Povrchové poranenie ucha: iné"},
{"code":"S00.5-","desc":"Povrchové poranenie pery a ústnej dutiny"},
{"code":"S00.50","desc":"Povrchové poranenie pery a ústnej dutiny: druh poranenia bližšie neurčený"},
{"code":"S00.51","desc":"Povrchové poranenie pery a ústnej dutiny: odrenina"},
{"code":"S00.52","desc":"Povrchové poranenie pery a ústnej dutiny: tvorba pľuzgierov (netermických)"},
{"code":"S00.53","desc":"Povrchové poranenie pery a ústnej dutiny: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S00.54","desc":"Povrchové poranenie pery a ústnej dutiny: povrchové cudzie telesá (úlomky)"},
{"code":"S00.55","desc":"Povrchové poranenie pery a ústnej dutiny: pomliaždenie"},
{"code":"S00.58","desc":"Povrchové poranenie pery a ústnej dutiny: iné"},
{"code":"S00.7","desc":"Viaceré povrchové poranenia hlavy"},
{"code":"S00.8-","desc":"Povrchové poranenie inej časti hlavy"},
{"code":"S00.80","desc":"Povrchové poranenie inej časti hlavy: druh poranenia bližšie neurčený"},
{"code":"S00.81","desc":"Povrchové poranenie inej časti hlavy: odrenina"},
{"code":"S00.82","desc":"Povrchové poranenie inej časti hlavy: tvorba pľuzgierov (netermických)"},
{"code":"S00.83","desc":"Povrchové poranenie inej časti hlavy: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S00.84","desc":"Povrchové poranenie inej časti hlavy: povrchové cudzie telesá (úlomky)"},
{"code":"S00.85","desc":"Povrchové poranenie inej časti hlavy: pomliaždenie"},
{"code":"S00.88","desc":"Povrchové poranenie inej časti hlavy: iné"},
{"code":"S00.9-","desc":"Povrchové poranenie hlavy bližšie neurčenej časti"},
{"code":"S00.90","desc":"Povrchové poranenie hlavy bližšie neurčenej časti: druh poranenia bližšie neurčený"},
{"code":"S00.91","desc":"Povrchové poranenie hlavy bližšie neurčenej časti: odrenina"},
{"code":"S00.92","desc":"Povrchové poranenie hlavy bližšie neurčenej časti: tvorba pľuzgierov (netermických)"},
{"code":"S00.93","desc":"Povrchové poranenie hlavy bližšie neurčenej časti: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S00.94","desc":"Povrchové poranenie hlavy bližšie neurčenej časti: povrchové cudzie telesá (úlomky)"},
{"code":"S00.95","desc":"Povrchové poranenie hlavy bližšie neurčenej časti: pomliaždenie"},
{"code":"S00.98","desc":"Povrchové poranenie hlavy bližšie neurčenej časti: iné"},
{"code":"S01.-","desc":"Otvorená rana hlavy"},
{"code":"S01.0","desc":"Otvorená rana vlasatej časti hlavy"},
{"code":"S01.1","desc":"Otvorená rana mihalnice a okolia oka"},
{"code":"S01.2-","desc":"Otvorená rana nosa"},
{"code":"S01.20","desc":"Otvorená rana nosa: časť bližšie neurčená"},
{"code":"S01.21","desc":"Otvorená rana nosa: vonkajšia koža nosa"},
{"code":"S01.22","desc":"Otvorená rana nosa: nosové dierky"},
{"code":"S01.23","desc":"Otvorená rana nosa: nosová priehradka"},
{"code":"S01.29","desc":"Otvorená rana nosa: iné a viaceré časti nosa"},
{"code":"S01.3-","desc":"Otvorená rana ucha, vrátane vnútorného ucha"},
{"code":"S01.30","desc":"Otvorená rana ucha: časť bližšie neurčená"},
{"code":"S01.31","desc":"Otvorená rana ucha: ušnica"},
{"code":"S01.33","desc":"Otvorená rana ucha: tragus"},
{"code":"S01.34","desc":"Otvorená rana ucha: vonkajší zvukovod"},
{"code":"S01.35","desc":"Otvorená rana ucha: sluchová trubica"},
{"code":"S01.36","desc":"Otvorená rana ucha: sluchové kostičky"},
{"code":"S01.37","desc":"Otvorená rana ucha: bubienok"},
{"code":"S01.38","desc":"Otvorená rana: vnútorné ucho"},
{"code":"S01.39","desc":"Otvorená rana ucha: iné a viaceré časti ucha a sluchových štruktúr"},
{"code":"S01.4-","desc":"Otvorená rana líca a temporomandibulárnej oblasti"},
{"code":"S01.41","desc":"Otvorená rana líca a temporomandibulárnej oblasti: líce"},
{"code":"S01.42","desc":"Otvorená rana líca a temporomandibulárnej oblasti: oblasť hornej čeľuste"},
{"code":"S01.43","desc":"Otvorená rana líca a temporomandibulárnej oblasti: oblasť sánky"},
{"code":"S01.49","desc":"Otvorená rana líca a temporomandibulárnej oblasti: iné a viaceré časti"},
{"code":"S01.5-","desc":"Otvorená rana pery a ústnej dutiny"},
{"code":"S01.50","desc":"Otvorená rana pery a ústnej dutiny: ústa, bližšie neurčená časť"},
{"code":"S01.51","desc":"Otvorená rana pery a ústnej dutiny: pera"},
{"code":"S01.52","desc":"Otvorená rana pery a ústnej dutiny: sliznica líca"},
{"code":"S01.53","desc":"Otvorená rana pery a ústnej dutiny: ďasná (Processus alveolaris)"},
{"code":"S01.54","desc":"Otvorená rana pery a ústnej dutiny: jazyk a ústna spodina"},
{"code":"S01.55","desc":"Otvorená rana pery a ústnej dutiny: podnebie"},
{"code":"S01.59","desc":"Otvorená rana pery a ústnej dutiny: iné a viaceré časti pier a ústnej dutiny"},
{"code":"S01.7","desc":"Viaceré otvorené rany hlavy"},
{"code":"S01.8-","desc":"Otvorená rana iných častí hlavy"},
{"code":"S01.80","desc":"Otvorená rana iných častí hlavy, bližšie neurčená"},
{"code":"S01.83!","desc":"Otvorená rana (ktorákoľvek časť hlavy) v spojení s intrakraniálnym poranením"},
{"code":"S01.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení na hlave"},
{"code":"S01.85!","desc":"Poškodenie mäkkých častí II. stupňa pri zatvorenej zlomenine alebo vykĺbení na hlave"},
{"code":"S01.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení na hlave"},
{"code":"S01.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení na hlave"},
{"code":"S01.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení na hlave"},
{"code":"S01.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení na hlave"},
{"code":"S01.9","desc":"Otvorená rana hlavy, bližšie neurčenej časti"},
{"code":"S02.-","desc":"Zlomenina lebky a tvárových kostí"},
{"code":"S02.0","desc":"Zlomenina lebkovej klenby"},
{"code":"S02.1","desc":"Zlomenina spodiny lebky"},
{"code":"S02.2","desc":"Zlomenina nosových kostí"},
{"code":"S02.3","desc":"Zlomenina spodiny očnice"},
{"code":"S02.4","desc":"Zlomenina jarmovej kosti a hornej čeľuste"},
{"code":"S02.5","desc":"Zlomenina zuba"},
{"code":"S02.6-","desc":"Zlomenina sánky"},
{"code":"S02.60","desc":"Zlomenina sánky: bližšie neurčená časť"},
{"code":"S02.61","desc":"Zlomenina sánky: processus condylaris"},
{"code":"S02.62","desc":"Zlomenina sánky: subkondylová"},
{"code":"S02.63","desc":"Zlomenina sánky: processus coronoideus"},
{"code":"S02.64","desc":"Zlomenina sánky: ramus mandibulae, bližšie neurčený"},
{"code":"S02.65","desc":"Zlomenina sánky: angulus mandibulae"},
{"code":"S02.66","desc":"Zlomenina sánky: symphysis mandibulae"},
{"code":"S02.67","desc":"Zlomenina sánky: pars alveolaris"},
{"code":"S02.68","desc":"Zlomenina sánky: corpus mandibulae, iné a bližšie neurčené časti"},
{"code":"S02.69","desc":"Zlomenina sánky: viaceré časti"},
{"code":"S02.7","desc":"Viaceré zlomeniny lebky a tvárových kostí"},
{"code":"S02.8","desc":"Zlomenina inej kosti lebky a tváre"},
{"code":"S02.9","desc":"Zlomenina kosti lebky a tváre, bližšie neurčená časť"},
{"code":"S03.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbov a väzov hlavy"},
{"code":"S03.0","desc":"Vykĺbenie sánky"},
{"code":"S03.1","desc":"Dislokácia chrupkovej časti nosovej priehradky"},
{"code":"S03.2","desc":"Dislokácia zuba"},
{"code":"S03.3","desc":"Vykĺbenie inej a bližšie neurčenej časti hlavy"},
{"code":"S03.4","desc":"Vyvrtnutie a natiahnutie sánky"},
{"code":"S03.5","desc":"Vyvrtnutie a natiahnutie kĺbov a väzov iných a bližšie neurčených častí hlavy"},
{"code":"S04.-","desc":"Poranenie hlavových nervov"},
{"code":"S04.0","desc":"Poranenie zrakového nervu (n. opticus) a zrakových dráh"},
{"code":"S04.1","desc":"Poranenie okohybného nervu (n. oculomotorius)"},
{"code":"S04.2","desc":"Poranenie kladkového nervu (n. trochlearis)"},
{"code":"S04.3","desc":"Poranenie trojklaného nervu (n. trigeminus)"},
{"code":"S04.4","desc":"Poranenie odťahujúceho nervu (n. abducens)"},
{"code":"S04.5","desc":"Poranenie tvárového nervu (n. facialis)"},
{"code":"S04.6","desc":"Poranenie sluchového nervu (n. acusticus)"},
{"code":"S04.7","desc":"Poranenie prídavného nervu (n. accessorius)"},
{"code":"S04.8","desc":"Poranenie iného hlavového nervu"},
{"code":"S04.9","desc":"Poranenie bližšie neurčeného hlavového nervu"},
{"code":"S05.-","desc":"Poranenie oka a očnice"},
{"code":"S05.0","desc":"Poranenie spojovky a abrázia rohovky bez zmienky o cudzom telese"},
{"code":"S05.1","desc":"Pomliaždenie očnej gule a tkanív očnice"},
{"code":"S05.2","desc":"Tržná rana a roztrhnutie oka s prolapsom alebo stratou vnútroočného tkaniva"},
{"code":"S05.3","desc":"Tržná rana oka bez prolapsu alebo straty vnútroočného tkaniva"},
{"code":"S05.4","desc":"Penetrujúca rana očnice s cudzím telesom alebo bez cudzieho telesa"},
{"code":"S05.5","desc":"Penetrujúca rana očnej gule s cudzím telesom"},
{"code":"S05.6","desc":"Penetrujúca rana očnej gule bez cudzieho telesa"},
{"code":"S05.7","desc":"Avulzia (vytrhnutie) oka"},
{"code":"S05.8","desc":"Iné poranenie oka a očnice"},
{"code":"S05.9","desc":"Poranenie oka a očnice, bližšie neurčené"},
{"code":"S06.-","desc":"Vnútrolebkové poranenie"},
{"code":"S06.0","desc":"Otras mozgu"},
{"code":"S06.1","desc":"Poúrazový opuch mozgu"},
{"code":"S06.2-","desc":"Difúzne poranenie mozgu"},
{"code":"S06.20","desc":"Difúzne poranenie mozgu a mozočka, bližšie neurčené"},
{"code":"S06.21","desc":"Difúzne pomliaždenie mozgu"},
{"code":"S06.22","desc":"Difúzne pomliaždenie mozočka"},
{"code":"S06.23","desc":"Viaceré vnútromozgové a mozočkové hematómy"},
{"code":"S06.28","desc":"Iné difúzne poranenie mozgu a mozočka"},
{"code":"S06.3-","desc":"Ložiskové poranenie mozgu"},
{"code":"S06.30","desc":"Ložiskové poranenie mozgu a mozočka, bližšie neurčené"},
{"code":"S06.31","desc":"Ložiskové pomliaždenie mozgu"},
{"code":"S06.32","desc":"Ložiskové pomliaždenie mozočka"},
{"code":"S06.33","desc":"Ložiskový hematóm mozgu"},
{"code":"S06.34","desc":"Ložiskový hematóm mozočka"},
{"code":"S06.38","desc":"Iné ložiskové poranenie mozgu a mozočka"},
{"code":"S06.4","desc":"Epidurálne krvácanie"},
{"code":"S06.5","desc":"Subdurálne krvácanie po úraze"},
{"code":"S06.6","desc":"Subarachnoidálne krvácanie po úraze"},
{"code":"S06.7-!","desc":"Bezvedomie pri poranení lebky a mozgu"},
{"code":"S06.70!","desc":"Bezvedomie pri poranení lebky a mozgu, trvajúce menej ako 30 minút"},
{"code":"S06.71!","desc":"Bezvedomie pri poranení lebky a mozgu, trvajúce 30 minút až 24 hodín"},
{"code":"S06.72!","desc":"Bezvedomie pri poranení lebky a mozgu, trvajúce viac ako 24 hodín, s návratom do pôvodného stavu vedomia"},
{"code":"S06.73!","desc":"Bezvedomie pri poranení lebky a mozgu, trvajúce viac ako 24 hodín, bez návratu do pôvodného stavu vedomia"},
{"code":"S06.79!","desc":"Bezvedomie pri poranení lebky a mozgu, bližšie neurčeného trvania"},
{"code":"S06.8","desc":"Iné vnútrolebkové poranenie"},
{"code":"S06.9","desc":"Vnútrolebkové poranenie, bližšie neurčené"},
{"code":"S07.-","desc":"Drvivé poranenie hlavy"},
{"code":"S07.0","desc":"Drvivé poranenie tváre"},
{"code":"S07.1","desc":"Drvivé poranenie lebky"},
{"code":"S07.8","desc":"Drvivé poranenie iných častí hlavy"},
{"code":"S07.9","desc":"Drvivé poranenie hlavy, časť bližšie neurčená"},
{"code":"S08.-","desc":"Úrazová amputácia časti hlavy"},
{"code":"S08.0","desc":"Avulzia (odtrhnutie) vlasatej časti hlavy"},
{"code":"S08.1","desc":"Úrazová amputácia ucha"},
{"code":"S08.8","desc":"Úrazová amputácia inej časti hlavy"},
{"code":"S08.9","desc":"Úrazová amputácia bližšie neurčenej časti hlavy"},
{"code":"S09.-","desc":"Iné a bližšie neurčené poranenie hlavy"},
{"code":"S09.0","desc":"Poranenie cievy hlavy, nezatriedené inde"},
{"code":"S09.1","desc":"Poranenie svalu a šľachy hlavy"},
{"code":"S09.2","desc":"Úrazové roztrhnutie ušného bubienka"},
{"code":"S09.7","desc":"Viaceré poranenia hlavy"},
{"code":"S09.8","desc":"Iné poranenie hlavy, bližšie určené"},
{"code":"S09.9","desc":"Poranenie hlavy, bližšie neurčené"},
{"code":"S10-S19","desc":"Poranenia krku"},
{"code":"S10.-","desc":"Povrchové poranenie krku"},
{"code":"S10.0","desc":"Pomliaždenie hrdla"},
{"code":"S10.1-","desc":"Iné a bližšie neurčené povrchové poranenie hrdla"},
{"code":"S10.10","desc":"Iné a bližšie neurčené povrchové poranenia hrdla: druh poranenia bližšie neurčený"},
{"code":"S10.11","desc":"Iné a bližšie neurčené povrchové poranenia hrdla: odrenina"},
{"code":"S10.12","desc":"Iné a bližšie neurčené povrchové poranenia hrdla: tvorba pľuzgierov (netermických)"},
{"code":"S10.13","desc":"Iné a bližšie neurčené povrchové poranenia hrdla: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S10.14","desc":"Iné a bližšie neurčené povrchové poranenia hrdla: povrchové cudzie telesá (úlomky)"},
{"code":"S10.18","desc":"Iné a bližšie neurčené povrchové poranenia hrdla: iné"},
{"code":"S10.7","desc":"Viaceré povrchové poranenia krku"},
{"code":"S10.8-","desc":"Povrchové poranenie inej časti krku"},
{"code":"S10.80","desc":"Povrchové poranenie inej časti krku: druh poranenia bližšie neurčený"},
{"code":"S10.81","desc":"Povrchové poranenie inej časti krku: odrenina"},
{"code":"S10.82","desc":"Povrchové poranenie inej časti krku: tvorba pľuzgierov (netermických)"},
{"code":"S10.83","desc":"Povrchové poranenie inej časti krku: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S10.84","desc":"Povrchové poranenie inej časti krku: povrchové cudzie teleso (úlomky)"},
{"code":"S10.85","desc":"Povrchové poranenie inej časti krku: pomliaždenie"},
{"code":"S10.88","desc":"Povrchové poranenie inej časti krku: iné"},
{"code":"S10.9-","desc":"Povrchové poranenie krku, bližšie neurčená časť"},
{"code":"S10.90","desc":"Povrchové poranenie krku, bližšie neurčená časť: druh poranenia bližšie neurčený"},
{"code":"S10.91","desc":"Povrchové poranenie krku, bližšie neurčená časť: odrenina"},
{"code":"S10.92","desc":"Povrchové poranenie krku, bližšie neurčená časť: tvorba pľuzgierov (netermických)"},
{"code":"S10.93","desc":"Povrchové poranenie krku, bližšie neurčená časť: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S10.94","desc":"Povrchové poranenie krku, bližšie neurčená časť: povrchové cudzie telesá (úlomky)"},
{"code":"S10.95","desc":"Povrchové poranenie krku, bližšie neurčená časť: pomliaždenie"},
{"code":"S10.98","desc":"Povrchové poranenie krku, bližšie neurčená časť: iné"},
{"code":"S11.-","desc":"Otvorená rana krku"},
{"code":"S11.0-","desc":"Otvorená rana, postihujúca hrtan (larynx) a priedušnicu"},
{"code":"S11.01","desc":"Otvorená rana hrtana"},
{"code":"S11.02","desc":"Otvorená rana krčnej časti priedušnice"},
{"code":"S11.1","desc":"Otvorená rana postihujúca štítnu žľazu"},
{"code":"S11.2-","desc":"Otvorená rana postihujúca hltan (farynx) a krčnú časť pažeráka"},
{"code":"S11.21","desc":"Otvorená rana hltana"},
{"code":"S11.22","desc":"Otvorená rana krčnej časti pažeráka"},
{"code":"S11.7","desc":"Viaceré otvorené rany krku"},
{"code":"S11.8-","desc":"Otvorená rana iných častí krku"},
{"code":"S11.80","desc":"Bližšie neurčená otvorená rana iných častí krku"},
{"code":"S11.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení na krku"},
{"code":"S11.85!","desc":"Poškodenie mäkkých častí II. stupňa pri zatvorenej zlomenine alebo vykĺbení na krku"},
{"code":"S11.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení na krku"},
{"code":"S11.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení na krku"},
{"code":"S11.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení na krku"},
{"code":"S11.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení na krku"},
{"code":"S11.9","desc":"Otvorená rana krku, bližšie neurčenej časti"},
{"code":"S12.-","desc":"Zlomenina krčnej chrbtice"},
{"code":"S12.0","desc":"Zlomenina 1.krčného stavca"},
{"code":"S12.1","desc":"Zlomenina 2.krčného stavca"},
{"code":"S12.2-","desc":"Zlomenina iného bližšie určeného krčného stavca"},
{"code":"S12.21","desc":"Zlomenina 3.krčného stavca"},
{"code":"S12.22","desc":"Zlomenina 4. krčného stavca"},
{"code":"S12.23","desc":"Zlomenina 5. krčného stavca"},
{"code":"S12.24","desc":"Zlomenina 6. krčného stavca"},
{"code":"S12.25","desc":"Zlomenina 7. krčného stavca"},
{"code":"S12.7","desc":"Viaceré zlomeniny krčnej chrbtice"},
{"code":"S12.8","desc":"Zlomenina iných častí krku"},
{"code":"S12.9","desc":"Zlomenina krku, bližšie neurčenej časti"},
{"code":"S13.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbov a väzov krku"},
{"code":"S13.0","desc":"Úrazové natrhnutie krčnej medzistavcovej platničky"},
{"code":"S13.1-","desc":"Vykĺbenie krčného stavca"},
{"code":"S13.10","desc":"Vykĺbenie krčného stavca v bližšie neurčenej úrovni"},
{"code":"S13.11","desc":"Vykĺbenie krčného stavca v úrovni C1/C2"},
{"code":"S13.12","desc":"Vykĺbenie krčného stavca v úrovni C2/C3"},
{"code":"S13.13","desc":"Vykĺbenie krčného stavca v úrovni C3/C4"},
{"code":"S13.14","desc":"Vykĺbenie krčného stavca v úrovni C4/C5"},
{"code":"S13.15","desc":"Vykĺbenie krčného stavca v úrovni C5/C6"},
{"code":"S13.16","desc":"Vykĺbenie krčného stavca v úrovni C6/C7"},
{"code":"S13.17","desc":"Vykĺbenie krčného stavca v úrovni C7/Th1"},
{"code":"S13.18","desc":"Vykĺbenie krčného stavca v inej úrovni"},
{"code":"S13.2","desc":"Vykĺbenie inej a bližšie neurčenej časti krku"},
{"code":"S13.3","desc":"Viaceré vykĺbenia v oblasti krku"},
{"code":"S13.4","desc":"Vyvrtnutie a natiahnutie krčnej chrbtice"},
{"code":"S13.5","desc":"Vyvrtnutie a natiahnutie krku v oblasti štítnej žľazy"},
{"code":"S13.6","desc":"Vyvrtnutie a natiahnutie kĺbov a väzov iných a bližšie neurčených častí krku"},
{"code":"S14.-","desc":"Poranenie krčných nervov a krčnej miechy"},
{"code":"S14.0","desc":"Otras a opuch krčnej miechy"},
{"code":"S14.1-","desc":"Iné a bližšie neurčené poranenie krčnej miechy"},
{"code":"S14.10","desc":"Poranenie krčnej miechy, bližšie neurčené"},
{"code":"S14.11","desc":"Kompletné priečne poranenie krčnej miechy"},
{"code":"S14.12","desc":"Poranenie centrálnej časti krčnej miechy (nekompletné priečne poranenie)"},
{"code":"S14.13","desc":"Iné nekompletné priečne poranenie krčnej miechy"},
{"code":"S14.2","desc":"Poranenie krčného nervového koreňa"},
{"code":"S14.3","desc":"Poranenie plexus brachialis"},
{"code":"S14.4","desc":"Poranenie periférnych krčných nervov"},
{"code":"S14.5","desc":"Poranenie nervov krčného sympatika"},
{"code":"S14.6","desc":"Poranenie iných a bližšie neurčených krčných nervov"},
{"code":"S14.7-!","desc":"Funkčná úroveň pri poranení krčnej miechy"},
{"code":"S14.70!","desc":"Funkčná úroveň pri poranení krčnej miechy: bližšie neurčená"},
{"code":"S14.71!","desc":"Funkčná úroveň pri poranení krčnej miechy: C1"},
{"code":"S14.72!","desc":"Funkčná úroveň pri poranení krčnej miechy: C2"},
{"code":"S14.73!","desc":"Funkčná úroveň pri poranení krčnej miechy: C3"},
{"code":"S14.74!","desc":"Funkčná úroveň pri poranení krčnej miechy: C4"},
{"code":"S14.75!","desc":"Funkčná úroveň pri poranení krčnej miechy: C5"},
{"code":"S14.76!","desc":"Funkčná úroveň pri poranení krčnej miechy: C6"},
{"code":"S14.77!","desc":"Funkčná úroveň pri poranení krčnej miechy: C7"},
{"code":"S14.78!","desc":"Funkčná úroveň pri poranení krčnej miechy: C8"},
{"code":"S15.-","desc":"Poranenie krvných ciev v úrovni krku"},
{"code":"S15.0-","desc":"Poranenie a.carotis"},
{"code":"S15.00","desc":"Poranenie a.carotis, časť bližšie neurčená"},
{"code":"S15.01","desc":"Poranenie a.carotis communis"},
{"code":"S15.02","desc":"Poranenie a.carotis externa"},
{"code":"S15.03","desc":"Poranenie a.carotis interna"},
{"code":"S15.1","desc":"Poranenie a.vertebralis"},
{"code":"S15.2","desc":"Poranenie v. jugularis externa"},
{"code":"S15.3","desc":"Poranenie v. jugularis interna"},
{"code":"S15.7","desc":"Poranenie viacerých krvných ciev v úrovni krku"},
{"code":"S15.8","desc":"Poranenie iných krvných ciev v úrovni krku"},
{"code":"S15.9","desc":"Poranenie krvných ciev v úrovni krku, bližšie neurčených"},
{"code":"S16","desc":"Poranenie svalov a šliach v úrovni krku"},
{"code":"S17.-","desc":"Drvivé poranenie krku"},
{"code":"S17.0","desc":"Drvivé poranenie hrtana a priedušnice"},
{"code":"S17.8","desc":"Drvivé poranenie inej časti krku"},
{"code":"S17.9","desc":"Drvivé poranenie bližšie neurčenej časti krku"},
{"code":"S18","desc":"Úrazová amputácia v úrovni krku"},
{"code":"S19.-","desc":"Iné a bližšie neurčené poranenie krku"},
{"code":"S19.7","desc":"Viaceré poranenia krku"},
{"code":"S19.8","desc":"Iné poranenie krku, bližšie určené"},
{"code":"S19.9","desc":"Poranenie krku, bližšie neurčené"},
{"code":"S20-S29","desc":"Poranenia hrudníka"},
{"code":"S20.-","desc":"Povrchové poranenie hrudníka"},
{"code":"S20.0","desc":"Pomliaždenie prsníka"},
{"code":"S20.1-","desc":"Iné a bližšie neurčené povrchové poranenia prsníka"},
{"code":"S20.10","desc":"Iné a bližšie neurčené povrchové poranenia prsníka: druh poranenia bližšie neurčený"},
{"code":"S20.11","desc":"Iné a bližšie neurčené povrchové poranenia prsníka: odrenina"},
{"code":"S20.12","desc":"Iné a bližšie neurčené povrchové poranenia prsníka: tvorba pľuzgierov (netermických)"},
{"code":"S20.13","desc":"Iné a bližšie neurčené povrchové poranenia prsníka: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S20.14","desc":"Iné a bližšie neurčené povrchové poranenia prsníka: povrchové cudzie telesá (úlomky)"},
{"code":"S20.18","desc":"Iné a bližšie neurčené povrchové poranenia prsníka: iné"},
{"code":"S20.2","desc":"Pomliaždenie hrudníka"},
{"code":"S20.3-","desc":"Iné povrchové poranenie prednej steny hrudníka"},
{"code":"S20.30","desc":"Iné povrchové poranenie prednej steny hrudníka: druh poranenia bližšie neurčený"},
{"code":"S20.31","desc":"Iné povrchové poranenie prednej steny hrudníka: odrenina"},
{"code":"S20.32","desc":"Iné povrchové poranenie prednej steny hrudníka: tvorba pľuzgierov (netermických)"},
{"code":"S20.33","desc":"Iné povrchové poranenie prednej steny hrudníka: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S20.34","desc":"Iné povrchové poranenie prednej steny hrudníka: povrchové cudzie telesá (úlomky)"},
{"code":"S20.38","desc":"Iné povrchové poranenie prednej steny hrudníka: iné"},
{"code":"S20.4-","desc":"Iné povrchové poranenie zadnej steny hrudníka"},
{"code":"S20.40","desc":"Iné povrchové poranenie zadnej steny hrudníka: druh poranenia bližšie neurčený"},
{"code":"S20.41","desc":"Iné povrchové poranenie zadnej steny hrudníka: odrenina"},
{"code":"S20.42","desc":"Iné povrchové poranenie zadnej steny hrudníka: tvorba pľuzgierov (netermických)"},
{"code":"S20.43","desc":"Iné povrchové poranenie zadnej steny hrudníka: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S20.44","desc":"Iné povrchové poranenie zadnej steny hrudníka: povrchové cudzie telesá (úlomky)"},
{"code":"S20.48","desc":"Iné povrchové poranenie zadnej steny hrudníka: iné"},
{"code":"S20.7","desc":"Viaceré povrchové poranenia hrudníka"},
{"code":"S20.8-","desc":"Povrchové poranenie inej a bližšie neurčenej časti hrudníka"},
{"code":"S20.80","desc":"Povrchové poranenie inej a bližšie neurčenej časti hrudníka: druh poranenia bližšie neurčený"},
{"code":"S20.81","desc":"Povrchové poranenie inej a bližšie neurčenej časti hrudníka: odrenina"},
{"code":"S20.82","desc":"Povrchové poranenie inej a bližšie neurčenej časti hrudníka: tvorba pľuzgierov (netermických)"},
{"code":"S20.83","desc":"Povrchové poranenie inej a bližšie neurčenej časti hrudníka: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S20.84","desc":"Povrchové poranenie inej a bližšie neurčenej časti hrudníka: povrchové cudzie teleso (úlomky)"},
{"code":"S20.85","desc":"Povrchové poranenie inej a bližšie neurčenej časti hrudníka: pomliaždenie"},
{"code":"S20.88","desc":"Povrchové poranenie inej a bližšie neurčenej časti hrudníka: iné"},
{"code":"S21.-","desc":"Otvorená rana hrudníka"},
{"code":"S21.0","desc":"Otvorená rana prsníka"},
{"code":"S21.1","desc":"Otvorená rana prednej steny hrudníka"},
{"code":"S21.2","desc":"Otvorená rana zadnej steny hrudníka"},
{"code":"S21.7","desc":"Viaceré otvorené rany steny hrudníka"},
{"code":"S21.8-","desc":"Otvorená rana iných častí hrudníka"},
{"code":"S21.80","desc":"Otvorená rana inej časti hrudníka, bližšie neurčenej"},
{"code":"S21.83!","desc":"Otvorená rana každej časti hrudníka v spojení s vnútrohrudníkovým poranením"},
{"code":"S21.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení na hrudníku"},
{"code":"S21.85!","desc":"Poškodenie mäkkých častí II. stupňa pri zatvorenej zlomenine alebo vykĺbení na hrudníku"},
{"code":"S21.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení na hrudníku"},
{"code":"S21.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení na hrudníku"},
{"code":"S21.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení na hrudníku"},
{"code":"S21.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení na hrudníku"},
{"code":"S21.9","desc":"Otvorená rana hrudníka, bližšie neurčenej časti"},
{"code":"S22.-","desc":"Zlomenina rebra (rebier), hrudnej kosti a hrudníkovej chrbtice"},
{"code":"S22.0-","desc":"Zlomenina hrudníkového stavca"},
{"code":"S22.00","desc":"Zlomenina hrudníkového stavca, úroveň bližšie neurčená"},
{"code":"S22.01","desc":"Zlomenina hrudníkového stavca Th1 a Th2"},
{"code":"S22.02","desc":"Zlomenina hrudníkového stavca Th3 a Th4"},
{"code":"S22.03","desc":"Zlomenina hrudníkového stavca Th5 a Th6"},
{"code":"S22.04","desc":"Zlomenina hrudníkového stavca Th7 a Th8"},
{"code":"S22.05","desc":"Zlomenina hrudníkového stavca Th9 a Th10"},
{"code":"S22.06","desc":"Zlomenina hrudníkového stavca Th11 a Th12"},
{"code":"S22.1","desc":"Viaceré zlomeniny hrudníkovej chrbtice"},
{"code":"S22.2","desc":"Zlomenina mostíka (sterna)"},
{"code":"S22.3-","desc":"Zlomenina rebra"},
{"code":"S22.31","desc":"Zlomenina prvého rebra"},
{"code":"S22.32","desc":"Zlomenina iného rebra"},
{"code":"S22.4-","desc":"Sériová zlomenina rebier"},
{"code":"S22.40","desc":"Sériová zlomenina rebier, bližšie neurčená"},
{"code":"S22.41","desc":"Sériová zlomenina rebier s postihnutím prvého rebra"},
{"code":"S22.42","desc":"Sériová zlomenina rebier s postihnutím dvoch rebier"},
{"code":"S22.43","desc":"Sériová zlomenina rebier s postihnutím troch rebier"},
{"code":"S22.44","desc":"Sériová zlomenina rebier s postihnutím štyroch a viacerých rebier"},
{"code":"S22.5","desc":"Instabilný (vlajúci) hrudník"},
{"code":"S22.8","desc":"Zlomenina inej kostnej časti hrudníka"},
{"code":"S22.9","desc":"Zlomenina bližšie neurčenej kostnej časti hrudníka"},
{"code":"S23.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbov a väzov hrudníka"},
{"code":"S23.0","desc":"Úrazové natrhnutie hrudníkovej medzistavcovej platničky"},
{"code":"S23.1-","desc":"Vykĺbenie hrudníkového stavca"},
{"code":"S23.10","desc":"Vykĺbenie hrudníkového stavca v bližšie neurčenej úrovni"},
{"code":"S23.11","desc":"Vykĺbenie hrudníkového stavca v úrovni Th1/Th2 a Th2/Th3"},
{"code":"S23.12","desc":"Vykĺbenie hrudníkového stavca v úrovni Th3/Th4 a Th4/Th5"},
{"code":"S23.13","desc":"Vykĺbenie hrudníkového stavca v úrovni Th5/Th6 a Th6/Th7"},
{"code":"S23.14","desc":"Vykĺbenie hrudníkového stavca v úrovni Th7/Th8 a Th8/Th9"},
{"code":"S23.15","desc":"Vykĺbenie hrudníkového stavca v úrovni Th9/Th10 a Th10/Th11"},
{"code":"S23.16","desc":"Vykĺbenie hrudníkového stavca v úrovni Th11/Th12"},
{"code":"S23.17","desc":"Vykĺbenie hrudníkového stavca v úrovni Th12/L1"},
{"code":"S23.2","desc":"Vykĺbenie iných a bližšie neurčených častí hrudníka"},
{"code":"S23.3","desc":"Vyvrtnutie a natiahnutie hrudníkovej chrbtice"},
{"code":"S23.4","desc":"Vyvrtnutie a natiahnutie rebier a hrudnej kosti"},
{"code":"S23.5","desc":"Vyvrtnutie a natiahnutie iných a bližšie neurčených častí hrudníka"},
{"code":"S24.-","desc":"Poranenie nervov a miechy v úrovni hrudníka"},
{"code":"S24.0","desc":"Otras a opuch hrudníkovej miechy"},
{"code":"S24.1-","desc":"Iné a bližšie neurčené poranenie hrudníkovej miechy"},
{"code":"S24.10","desc":"Poranenie hrudníkovej miechy, bližšie neurčené"},
{"code":"S24.11","desc":"Kompletné priečne poranenie hrudníkovej miechy"},
{"code":"S24.12","desc":"Nekompletné priečne poranenie hrudníkovej miechy"},
{"code":"S24.2","desc":"Poranenie hrudníkového nervového koreňa"},
{"code":"S24.3","desc":"Poranenie periférnych nervov hrudníka"},
{"code":"S24.4","desc":"Poranenie nervov hrudníkového sympatika"},
{"code":"S24.5","desc":"Poranenie iných nervov hrudníka"},
{"code":"S24.6","desc":"Poranenie bližšie neurčeného nervu hrudníka"},
{"code":"S24.7-!","desc":"Funkčná výška pri poranení hrudníkovej miechy"},
{"code":"S24.70!","desc":"Funkčná výška pri poranení hrudníkovej miechy: úroveň bližšie neurčená"},
{"code":"S24.71!","desc":"Funkčná výška pri poranení hrudníkovej miechy: Th1"},
{"code":"S24.72!","desc":"Funkčná výška pri poranení hrudníkovej miechy: Th2/Th3"},
{"code":"S24.73!","desc":"Funkčná výška pri poranení hrudníkovej miechy: Th4/Th5"},
{"code":"S24.74!","desc":"Funkčná výška pri poranení hrudníkovej miechy: Th6/Th7"},
{"code":"S24.75!","desc":"Funkčná výška pri poranení hrudníkovej miechy: Th8/Th9"},
{"code":"S24.76!","desc":"Funkčná výška pri poranení hrudníkovej miechy: Th10/Th11"},
{"code":"S24.77!","desc":"Funkčná výška pri poranení hrudníkovej miechy: Th12"},
{"code":"S25.-","desc":"Poranenie krvných ciev hrudníka"},
{"code":"S25.0","desc":"Poranenie hrudníkovej aorty"},
{"code":"S25.1","desc":"Poranenie truncus brachiocephalicus alebo a.subclavia"},
{"code":"S25.2","desc":"Poranenie hornej dutej žily"},
{"code":"S25.3","desc":"Poranenie v. innominata (v. brachiocephalica) alebo v. subclavia"},
{"code":"S25.4","desc":"Poranenie pľúcnych krvných ciev"},
{"code":"S25.5","desc":"Poranenie medzirebrových ciev"},
{"code":"S25.7","desc":"Poranenie viacerých krvných ciev hrudníka"},
{"code":"S25.8","desc":"Poranenie iných krvných ciev hrudníka"},
{"code":"S25.9","desc":"Poranenie bližšie neurčenej krvnej cievy hrudníka"},
{"code":"S26.-","desc":"Poranenie srdca"},
{"code":"S26.0","desc":"Poranenie srdca so zakrvácaním do osrdcovníkového vaku (hemoperikardom)"},
{"code":"S26.8-","desc":"Iné poranenia srdca"},
{"code":"S26.81","desc":"Pomliaždenie srdca"},
{"code":"S26.82","desc":"Tržné poranenie srdca bez otvorenia srdcovej dutiny"},
{"code":"S26.83","desc":"Tržné poranenie srdca s otvorením srdcovej dutiny"},
{"code":"S26.88","desc":"Iné poranenia srdca"},
{"code":"S26.9","desc":"Poranenie srdca, bližšie neurčené"},
{"code":"S27.-","desc":"Poranenie iného a bližšie neurčeného vnútrohrudníkového orgánu"},
{"code":"S27.0","desc":"Úrazový pneumotorax"},
{"code":"S27.1","desc":"Úrazový hemotorax"},
{"code":"S27.2","desc":"Úrazový hemopneumotorax"},
{"code":"S27.3-","desc":"Iné poranenie pľúc"},
{"code":"S27.31","desc":"Pomliaždenie a hematóm pľúc"},
{"code":"S27.32","desc":"Tržné poranenie pľúc"},
{"code":"S27.38","desc":"Iné a bližšie neurčené poranenie pľúc"},
{"code":"S27.4","desc":"Poranenie priedušky"},
{"code":"S27.5","desc":"Poranenie priedušnice v oblasti hrudníka"},
{"code":"S27.6","desc":"Poranenie pohrudnice"},
{"code":"S27.7","desc":"Viaceré poranenia vnútrohrudníkových orgánov"},
{"code":"S27.8-","desc":"Poranenie iných bližšie určených vnútrohrudníkových orgánov a štruktúr"},
{"code":"S27.81","desc":"Poranenie bránice"},
{"code":"S27.82","desc":"Poranenie ductus thoracicus"},
{"code":"S27.83","desc":"Poranenie hrudnej časti pažeráka"},
{"code":"S27.84","desc":"Poranenie dojčenskej žľazy [týmusu]"},
{"code":"S27.88","desc":"Poranenie iných bližšie určených vnútrohrudníkových orgánov a štruktúr"},
{"code":"S27.9","desc":"Poranenie vnútrohrudníkového orgánu, bližšie nerčeného"},
{"code":"S28.-","desc":"Drvivé poranenie hrudníka a úrazová amputácia časti hrudníka"},
{"code":"S28.0","desc":"Rozdrvený hrudník"},
{"code":"S28.1","desc":"Úrazová amputácia časti hrudníka"},
{"code":"S29.-","desc":"Iné a bližšie neurčené poranenie hrudníka"},
{"code":"S29.0","desc":"Poranenie svalu a šľachy v úrovni hrudníka"},
{"code":"S29.7","desc":"Viaceré poranenia hrudníka"},
{"code":"S29.8","desc":"Iné poranenie hrudníka, bližšie určené"},
{"code":"S29.9","desc":"Poranenie hrudníka, bližšie neurčené"},
{"code":"S30-S39","desc":"Poranenia brucha, drieku, driekovej chrbtice a panvy"},
{"code":"S30.-","desc":"Povrchové poranenie brucha, drieku a panvy"},
{"code":"S30.0","desc":"Pomliaždenie driekovokrížovej oblasti a panvy"},
{"code":"S30.1","desc":"Pomliaždenie brušnej steny"},
{"code":"S30.2","desc":"Pomliaždenie vonkajších pohlavných orgánov"},
{"code":"S30.7","desc":"Viaceré povrchové poranenia brucha, drieku a panvy"},
{"code":"S30.8-","desc":"Iné povrchové poranenia brucha, drieku a panvy"},
{"code":"S30.80","desc":"Iné povrchové poranenie brucha, drieku a panvy: druh poranenia bližšie neurčený"},
{"code":"S30.81","desc":"Iné povrchové poranenie brucha, drieku a panvy: odrenina"},
{"code":"S30.82","desc":"Iné povrchové poranenie brucha, drieku a panvy: tvorba pľuzgierov (netermických)"},
{"code":"S30.83","desc":"Iné povrchové poranenie brucha, drieku a panvy: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S30.84","desc":"Iné povrchové poranenie brucha, drieku a panvy: povrchové cudzie telesá (úlomky)"},
{"code":"S30.85","desc":"Iné povrchové poranenie brucha, drieku a panvy: pomliaždenie"},
{"code":"S30.88","desc":"Iné povrchové poranenie brucha, drieku a panvy: iné"},
{"code":"S30.9-","desc":"Povrchové poranenie bližšie neurčenej časti brucha, drieku a panvy"},
{"code":"S30.90","desc":"Povrchové poranenie bližšie neurčenej časti brucha, drieku a panvy: druh poranenia bližšie neurčený"},
{"code":"S30.91","desc":"Povrchové poranenie bližšie neurčenej časti brucha, drieku a panvy: odrenina"},
{"code":"S30.92","desc":"Povrchové poranenie bližšie neurčenej časti brucha, drieku a panvy: tvorba pľuzgierov (netermických)"},
{"code":"S30.93","desc":"Povrchové poranenie bližšie neurčenej časti brucha, drieku a panvy: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S30.94","desc":"Povrchové poranenie bližšie neurčenej časti brucha, drieku a panvy: povrchové cudzie telesá (úlomky)"},
{"code":"S30.95","desc":"Povrchové poranenie bližšie neurčenej časti brucha, drieku a panvy: pomliaždenie"},
{"code":"S30.98","desc":"Povrchové poranenie bližšie neurčenej časti brucha, drieku a panvy: iné"},
{"code":"S31.-","desc":"Otvorená rana brucha, drieku a panvy"},
{"code":"S31.0","desc":"Otvorená rana drieku a panvy"},
{"code":"S31.1","desc":"Otvorená rana brušnej steny"},
{"code":"S31.2","desc":"Otvorená rana penisu"},
{"code":"S31.3","desc":"Otvorená rana skróta a semenníkov"},
{"code":"S31.4","desc":"Otvorená rana pošvy a vulvy"},
{"code":"S31.5","desc":"Otvorená rana iných a bližšie neurčených častí vonkajších pohlavných orgánov"},
{"code":"S31.7","desc":"Viaceré otvorené rany brucha, drieku a panvy"},
{"code":"S31.8-","desc":"Otvorená rana inej a bližšie neurčenej časti brucha"},
{"code":"S31.80","desc":"Otvorená rana inej a bližšie neurčenej časti brucha"},
{"code":"S31.83!","desc":"Otvorená rana (ktorákoľvek časť brucha, drieku a panvy) v spojení s vnútrobrušným poranením"},
{"code":"S31.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení driekovej chrbtice a panvy"},
{"code":"S31.85!","desc":"Poškodenie mäkkých častí II. stupňa pri zatvorenej zlomenine alebo vykĺbení driekovej chrbtice a panvy"},
{"code":"S31.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení driekovej chrbtice a panvy"},
{"code":"S31.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení driekovej chrbtice a panvy"},
{"code":"S31.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení driekovej chrbtice a panvy"},
{"code":"S31.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení driekovej chrbtice a panvy"},
{"code":"S32.-","desc":"Zlomenina driekovej časti chrbtice a panvy"},
{"code":"S32.0-","desc":"Zlomenina driekového stavca"},
{"code":"S32.00","desc":"Zlomenina driekového stavca, úroveň bližšie neurčená"},
{"code":"S32.01","desc":"Zlomenina driekového stavca L1"},
{"code":"S32.02","desc":"Zlomenina driekového stavca L2"},
{"code":"S32.03","desc":"Zlomenina driekového stavca L3"},
{"code":"S32.04","desc":"Zlomenina driekového stavca L4"},
{"code":"S32.05","desc":"Zlomenina driekového stavca L5"},
{"code":"S32.1","desc":"Zlomenina krížovej kosti"},
{"code":"S32.2","desc":"Zlomenina kostrče"},
{"code":"S32.3","desc":"Zlomenina os ilium"},
{"code":"S32.4","desc":"Zlomenina acetabula"},
{"code":"S32.5","desc":"Zlomenina os pubis"},
{"code":"S32.7","desc":"Viaceré zlomeniny driekovej chrbtice a panvy"},
{"code":"S32.8-","desc":"Zlomenina iných a bližšie neurčených častí driekovej chrbtice a panvy"},
{"code":"S32.81","desc":"Zlomenina os ischium"},
{"code":"S32.82","desc":"Zlomenina driekovej chrbtice a krížovej kosti, bližšie neurčená časť"},
{"code":"S32.83","desc":"Zlomenina panvy, bližšie neurčená časť"},
{"code":"S32.89","desc":"Zlomenina iných a viacerých častí panvy"},
{"code":"S33.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbov a väzov driekovej chrbtice a panvy"},
{"code":"S33.0","desc":"Úrazové natrhnutie driekovej medzistavcovej platničky"},
{"code":"S33.1-","desc":"Vykĺbenie driekového stavca"},
{"code":"S33.10","desc":"Vykĺbenie driekového stavca, úroveň bližšie neurčená"},
{"code":"S33.11","desc":"Vykĺbenie driekového stavca L1/L2"},
{"code":"S33.12","desc":"Vykĺbenie driekového stavca L2/L3"},
{"code":"S33.13","desc":"Vykĺbenie driekového stavca L3/L4"},
{"code":"S33.14","desc":"Vykĺbenie driekového stavca L4/L5"},
{"code":"S33.15","desc":"Vykĺbenie driekového stavca L5/S1"},
{"code":"S33.2","desc":"Vykĺbenie sakroiliakálneho kĺbu a krížovokostrčového spojenia"},
{"code":"S33.3","desc":"Vykĺbenie iných a bližšie neurčených častí driekovej chrbtice a panvy"},
{"code":"S33.4","desc":"Úrazové natrhnutie (pretrhnutie) lonovej spony (symfýzy)"},
{"code":"S33.5-","desc":"Vyvrtnutie a natiahnutie driekovej chrbtice"},
{"code":"S33.50","desc":"Vyvrtnutie a natiahnutie driekovej chrbtice: bližšie neurčené"},
{"code":"S33.51","desc":"Vyvrtnutie a natiahnutie driekovokrížového spojenia a jeho väzov"},
{"code":"S33.6","desc":"Vyvrtnutie a natiahnutie sakroiliakálneho kĺbu"},
{"code":"S33.7","desc":"Vyvrtnutie a natiahnutie iných a bližšie neurčených častí driekovej chrbtice a panvy"},
{"code":"S34.-","desc":"Poranenie nervov a miechy v úrovni brucha, drieku a panvy"},
{"code":"S34.0","desc":"Otras a opuch driekovej časti miechy"},
{"code":"S34.1-","desc":"Iné poranenie driekovej časti miechy"},
{"code":"S34.10","desc":"Kompletné priečne poranenie driekovej časti miechy"},
{"code":"S34.11","desc":"Nekompletné priečne poranenie driekovej časti miechy"},
{"code":"S34.18","desc":"Iné poranenie driekovej časti miechy"},
{"code":"S34.2","desc":"Poranenie driekového a krížového nervového koreňa"},
{"code":"S34.3-","desc":"Poranenie cauda equina"},
{"code":"S34.30","desc":"Kompletný úrazový syndróm cauda equina"},
{"code":"S34.31","desc":"Nekompletný úrazový syndróm cauda equina"},
{"code":"S34.38","desc":"Iné a bližšie neurčené poranenie cauda equina"},
{"code":"S34.4","desc":"Poranenie plexus lumbosacralis"},
{"code":"S34.5","desc":"Poranenie nervov driekového a krížového sympatika a panvových sympatikových nervov"},
{"code":"S34.6","desc":"Poranenie periférneho nervu (nervov) brucha, drieku a panvy"},
{"code":"S34.7-!","desc":"Funkčná úroveň pri poranení driekovokrížovej miechy"},
{"code":"S34.70!","desc":"Funkčná úroveň pri poranení driekovokrížovej miechy: bližšie neurčená"},
{"code":"S34.71!","desc":"Funkčná úroveň pri poranení driekovokrížovej miechy: L1"},
{"code":"S34.72!","desc":"Funkčná úroveň pri poranení driekovokrížovej miechy: L2"},
{"code":"S34.73!","desc":"Funkčná úroveň pri poranení driekovokrížovej miechy: L3"},
{"code":"S34.74!","desc":"Funkčná úroveň pri poranení driekovokrížovej miechy: L4"},
{"code":"S34.75!","desc":"Funkčná úroveň pri poranení driekovokrížovej miechy: L5"},
{"code":"S34.76!","desc":"Funkčná úroveň pri poranení driekovokrížovej miechy: S1"},
{"code":"S34.77!","desc":"Funkčná úroveň pri poranení driekovokrížovej miechy: S2 – S5"},
{"code":"S34.8","desc":"Poranenie iných a bližšie neurčených nervov brucha, drieku a panvy"},
{"code":"S35.-","desc":"Poranenie krvných ciev v úrovni brucha, drieku a panvy"},
{"code":"S35.0","desc":"Poranenie brušnej aorty"},
{"code":"S35.1","desc":"Poranenie dolnej dutej žily"},
{"code":"S35.2","desc":"Poranenie truncus coeliacus alebo a.mesenterica"},
{"code":"S35.3","desc":"Poranenie v. portae alebo v. lienalis"},
{"code":"S35.4","desc":"Poranenie krvných ciev obličky"},
{"code":"S35.5","desc":"Poranenie iliakálnych krvných ciev"},
{"code":"S35.7","desc":"Poranenie viacerých krvných ciev brucha, drieku a panvy"},
{"code":"S35.8","desc":"Poranenie iných krvných ciev brucha, drieku a panvy"},
{"code":"S35.9","desc":"Poranenie bližšie neurčenej krvnej cievy v úrovni brucha, drieku a panvy"},
{"code":"S36.-","desc":"Poranenie vnútrobrušných orgánov"},
{"code":"S36.0-","desc":"Poranenie sleziny"},
{"code":"S36.00","desc":"Poranenie sleziny, bližšie neurčené"},
{"code":"S36.01","desc":"Hematóm sleziny"},
{"code":"S36.02","desc":"Roztrhnutie puzdra sleziny, bez väčších trhlín parenchýmu"},
{"code":"S36.03","desc":"Tržné poranenie sleziny vrátane parenchýmu"},
{"code":"S36.04","desc":"Masívna ruptúra parenchýmu sleziny"},
{"code":"S36.08","desc":"Iné poranenie sleziny"},
{"code":"S36.1-","desc":"Poranenie pečene alebo žlčníka"},
{"code":"S36.10","desc":"Poranenie pečene, bližšie neurčené"},
{"code":"S36.11","desc":"Pomliaždenie a hematóm pečene"},
{"code":"S36.12","desc":"Tržné poranenie pečene, bližšie neurčené"},
{"code":"S36.13","desc":"Ľahké tržné poranenie pečene"},
{"code":"S36.14","desc":"Stredne ťažké poranenie pečene"},
{"code":"S36.15","desc":"Ťažké tržné poranenie pečene"},
{"code":"S36.16","desc":"Iné poranenie pečene"},
{"code":"S36.17","desc":"Poranenie žlčníka"},
{"code":"S36.18","desc":"Poranenie žlčových ciest"},
{"code":"S36.2-","desc":"Poranenie pankreasu"},
{"code":"S36.20","desc":"Poranenie pankreasu, časť bližšie neurčená"},
{"code":"S36.21","desc":"Poranenie hlavy pankreasu"},
{"code":"S36.22","desc":"Poranenie tela pankreasu"},
{"code":"S36.23","desc":"Poranenie chvosta pankreasu"},
{"code":"S36.29","desc":"Poranenie inej a viacerých častí pankreasu"},
{"code":"S36.3","desc":"Poranenie žalúdka"},
{"code":"S36.4-","desc":"Poranenie tenkého čreva"},
{"code":"S36.40","desc":"Poranenie tenkého čreva, časť bližšie neurčená"},
{"code":"S36.41","desc":"Poranenie duodena"},
{"code":"S36.49","desc":"Poranenie iných a viacerých častí tenkého čreva"},
{"code":"S36.5-","desc":"Poranenie hrubého čreva"},
{"code":"S36.50","desc":"Poranenie hrubého čreva, časť bližšie neurčená"},
{"code":"S36.51","desc":"Poranenie colon ascendens"},
{"code":"S36.52","desc":"Poranenie colon transversum"},
{"code":"S36.53","desc":"Poranenie colon descendens"},
{"code":"S36.54","desc":"Poranenie colon sigmoideum"},
{"code":"S36.59","desc":"Poranenie iných a viacerých častí hrubého čreva"},
{"code":"S36.6","desc":"Poranenie konečníka"},
{"code":"S36.7","desc":"Poranenie viacerých vnútrobrušných orgánov"},
{"code":"S36.8-","desc":"Poranenie iných vnútrobrušných orgánov"},
{"code":"S36.81","desc":"Poranenie pobrušnice"},
{"code":"S36.82","desc":"Poranenie mezentéria"},
{"code":"S36.83","desc":"Poranenie retroperitonea"},
{"code":"S36.88","desc":"Poranenie iných vnútrobrušných orgánov"},
{"code":"S36.9","desc":"Poranenie bližšie neurčeného vnútrobrušného orgánu"},
{"code":"S37.-","desc":"Poranenie močových orgánov a panvových orgánov"},
{"code":"S37.0-","desc":"Poranenie obličky"},
{"code":"S37.00","desc":"Poranenie obličky, bližšie neurčené"},
{"code":"S37.01","desc":"Pomliaždenie a hematóm obličky"},
{"code":"S37.02","desc":"Tržné poranenie obličky"},
{"code":"S37.03","desc":"Kompletná ruptúra parenchýmu obličky"},
{"code":"S37.1","desc":"Poranenie močovodu"},
{"code":"S37.2-","desc":"Poranenie močového mechúra"},
{"code":"S37.20","desc":"Poranenie močového mechúra, bližšie neurčené"},
{"code":"S37.21","desc":"Pomliaždenie močového mechúra"},
{"code":"S37.22","desc":"Roztrhnutie močového mechúra"},
{"code":"S37.28","desc":"Iné poranenie močového mechúra"},
{"code":"S37.3-","desc":"Poranenie močovej rúry"},
{"code":"S37.30","desc":"Poranenie močovej rúry, bližšie neurčené"},
{"code":"S37.31","desc":"Poranenie močovej rúry, pars membranacea"},
{"code":"S37.32","desc":"Poranenie močovej rúry, pars spongiosa"},
{"code":"S37.33","desc":"Poranenie močovej rúry, pars prostatica"},
{"code":"S37.38","desc":"Poranenie močovej rúry, iná časť"},
{"code":"S37.4","desc":"Poranenie vaječníka"},
{"code":"S37.5","desc":"Poranenie vajíčkovodu"},
{"code":"S37.6","desc":"Poranenie maternice"},
{"code":"S37.7","desc":"Poranenie viacerých panvových orgánov"},
{"code":"S37.8-","desc":"Poranenie iných močových a panvových orgánov"},
{"code":"S37.81","desc":"Poranenie nadobličky"},
{"code":"S37.82","desc":"Poranenie prostaty"},
{"code":"S37.83","desc":"Poranenie semenného váčka"},
{"code":"S37.84","desc":"Poranenie semenovodu"},
{"code":"S37.88","desc":"Poranenie iných panvových orgánov"},
{"code":"S37.9","desc":"Poranenie bližšie neurčeného panvového orgánu"},
{"code":"S38.-","desc":"Drvivé poranenie a úrazová amputácia časti brucha, drieku a panvy"},
{"code":"S38.0","desc":"Drvivé poranenie vonkajších pohlavných orgánov"},
{"code":"S38.1","desc":"Drvivé poranenie iných a bližšie neurčených častí brucha, drieku a panvy"},
{"code":"S38.2","desc":"Úrazová amputácia vonkajších pohlavných orgánov"},
{"code":"S38.3","desc":"Úrazová amputácia iných a bližšie neurčených častí brucha, drieku a panvy"},
{"code":"S39.-","desc":"Iné a bližšie neurčené poranenie brucha, drieku a panvy"},
{"code":"S39.0","desc":"Poranenie svalu a šľachy brucha, drieku a panvy"},
{"code":"S39.6","desc":"Poranenie vnútrobrušných orgánov s poranením panvových orgánov"},
{"code":"S39.7","desc":"Viaceré poranenia brucha, drieku a panvy"},
{"code":"S39.8-","desc":"Iné bližšie určené poranenie brucha, drieku a panvy"},
{"code":"S39.80","desc":"Zlomenina penisu"},
{"code":"S39.88","desc":"Iné poranenia brucha, driekovokrížovej oblasti a panvy, bližšie určené"},
{"code":"S39.9","desc":"Poranenie brucha, drieku a panvy, bližšie neurčené"},
{"code":"S40-S49","desc":"Poranenia pleca a ramena"},
{"code":"S40.-","desc":"Povrchové poranenie pleca a ramena"},
{"code":"S40.0","desc":"Pomliaždenie pleca a ramena"},
{"code":"S40.7","desc":"Viaceré povrchové poranenia pleca a ramena"},
{"code":"S40.8-","desc":"Iné povrchové poranenie pleca a ramena"},
{"code":"S40.81","desc":"Iné povrchové poranenia pleca a ramena: odrenina"},
{"code":"S40.82","desc":"Iné povrchové poranenia pleca a ramena: tvorba pľuzgierov (netermických)"},
{"code":"S40.83","desc":"Iné povrchové poranenia pleca a ramena: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S40.84","desc":"Iné povrchové poranenia pleca a ramena: povrchové cudzie telesá (úlomky)"},
{"code":"S40.88","desc":"Iné povrchové poranenia pleca a ramena: iné"},
{"code":"S40.9","desc":"Povrchové poranenie pleca a ramena, bližšie neurčené"},
{"code":"S41.-","desc":"Otvorená rana pleca a ramena"},
{"code":"S41.0","desc":"Otvorená rana pleca"},
{"code":"S41.1","desc":"Otvorená rana ramena"},
{"code":"S41.7","desc":"Viaceré otvorené rany pleca a ramena"},
{"code":"S41.8-","desc":"Otvorená rana inej a bližšie neurčenej časti plecového pletenca"},
{"code":"S41.80","desc":"Bližšie neurčená otvorená rana inej a bližšie neurčenej časti plecového pletenca"},
{"code":"S41.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení ramena"},
{"code":"S41.85!","desc":"Poškodenie mäkkých častí II. stupňa pri zatvorenej zlomenine alebo vykĺbení ramena"},
{"code":"S41.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení ramena"},
{"code":"S41.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení ramena"},
{"code":"S41.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení ramena"},
{"code":"S41.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení ramena"},
{"code":"S42.-","desc":"Zlomenina pleca a ramena"},
{"code":"S42.0-","desc":"Zlomenina kľúčnej kosti"},
{"code":"S42.00","desc":"Zlomenina kľúčnej kosti, časť bližšie neurčená"},
{"code":"S42.01","desc":"Zlomenina kľúčnej kosti, mediálna tretina"},
{"code":"S42.02","desc":"Zlomenina kľúčnej kosti, stredná tretina"},
{"code":"S42.03","desc":"Zlomenina kľúčnej kosti, laterálna tretina"},
{"code":"S42.09","desc":"Zlomenina kľúčnej kosti, viacnásobná"},
{"code":"S42.1-","desc":"Zlomenina lopatky"},
{"code":"S42.10","desc":"Zlomenina lopatky, časť bližšie neurčená"},
{"code":"S42.11","desc":"Zlomenina lopatky, telo"},
{"code":"S42.12","desc":"Zlomenina lopatky, akromion"},
{"code":"S42.13","desc":"Zlomenina lopatky, processus coracoideus"},
{"code":"S42.14","desc":"Zlomenina lopatky, cavitas glenoidalis a krčok lopatky"},
{"code":"S42.19","desc":"Zlomenina lopatky, viacnásobná"},
{"code":"S42.2-","desc":"Zlomenina horného konca ramennej kosti"},
{"code":"S42.20","desc":"Zlomenina horného konca ramennej kosti, časť bližšie neurčená"},
{"code":"S42.21","desc":"Zlomenina horného konca ramennej kosti, hlava"},
{"code":"S42.22","desc":"Zlomenina horného konca ramennej kosti, collum chirurgicum"},
{"code":"S42.23","desc":"Zlomenina horného konca ramennej kosti, collum anatomicum"},
{"code":"S42.24","desc":"Zlomenina horného konca ramennej kosti, tuberculum majus"},
{"code":"S42.29","desc":"Zlomenina horného konca ramennej kosti, iné a mnohopočetné časti"},
{"code":"S42.3","desc":"Zlomenina diafýzy ramennej kosti"},
{"code":"S42.4-","desc":"Zlomenina dolného konca ramennej kosti"},
{"code":"S42.40","desc":"Zlomenina dolného konca ramennej kosti, časť bližšie neurčená"},
{"code":"S42.41","desc":"Zlomenina dolného konca ramennej kosti, suprakondylová"},
{"code":"S42.42","desc":"Zlomenina dolného konca ramennej kosti, epicondylus lateralis"},
{"code":"S42.43","desc":"Zlomenina dolného konca ramennej kosti, epicondylus medialis"},
{"code":"S42.44","desc":"Zlomenina dolného konca ramennej kosti, epicondylus, epicondyly, bližšie neurčené"},
{"code":"S42.45","desc":"Zlomenina dolného konca ramennej kosti, transkondylová (tvaru T alebo Y)"},
{"code":"S42.49","desc":"Zlomenina dolného konca ramennej kosti, iné a viaceré časti"},
{"code":"S42.7","desc":"Viaceré zlomeniny kľúčnej kosti, lopatky a ramennej kosti"},
{"code":"S42.8","desc":"Zlomenina iných častí pleca a ramena"},
{"code":"S42.9","desc":"Zlomenina plecového pletenca"},
{"code":"S43.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbov a väzov plecového pletenca"},
{"code":"S43.0-","desc":"Vykĺbenie plecového kĺbu"},
{"code":"S43.00","desc":"Vykĺbenie plecového kĺbu, bližšie neurčené"},
{"code":"S43.01","desc":"Vykĺbenie ramennej kosti dopredu"},
{"code":"S43.02","desc":"Vykĺbenie ramennej kosti dozadu"},
{"code":"S43.03","desc":"Vykĺbenie ramennej kosti dole"},
{"code":"S43.08","desc":"Vykĺbenie iných častí ramena"},
{"code":"S43.1","desc":"Vykĺbenie akromioklavikulárneho kĺbu"},
{"code":"S43.2","desc":"Vykĺbenie sternoklavikulárneho kĺbu"},
{"code":"S43.3","desc":"Vykĺbenie iných a bližšie neurčených častí plecového pletenca"},
{"code":"S43.4","desc":"Vyvrtnutie a natiahnutie plecového kĺbu"},
{"code":"S43.5","desc":"Vyvrtnutie a natiahnutie akromioklavikulárneho kĺbu"},
{"code":"S43.6","desc":"Vyvrtnutie a natiahnutie sternoklavikulárneho kĺbu"},
{"code":"S43.7","desc":"Vyvrtnutie a natiahnutie inej a bližšie neurčenej časti plecového pletenca"},
{"code":"S44.-","desc":"Poranenie nervov v úrovni pleca a ramena"},
{"code":"S44.0","desc":"Poranenie n.ulnaris v úrovni ramena"},
{"code":"S44.1","desc":"Poranenie n. medianus v úrovni ramena"},
{"code":"S44.2","desc":"Poranenie n. radialis v úrovni ramena"},
{"code":"S44.3","desc":"Poranenie n. axillaris"},
{"code":"S44.4","desc":"Poranenie n. musculocutaneus"},
{"code":"S44.5","desc":"Poranenie kožného senzorického nervu v úrovni pleca a ramena"},
{"code":"S44.7","desc":"Poranenie viacerých nervov v úrovni pleca a ramena"},
{"code":"S44.8","desc":"Poranenie iných nervov v úrovni pleca a ramena"},
{"code":"S44.9","desc":"Poranenie bližšie neurčeného nervu v úrovni pleca a ramena"},
{"code":"S45.-","desc":"Poranenie krvných ciev v úrovni pleca a ramena"},
{"code":"S45.0","desc":"Poranenie a.axillaris"},
{"code":"S45.1","desc":"Poranenie a.brachialis"},
{"code":"S45.2","desc":"Poranenie v. axillaris a v. brachialis"},
{"code":"S45.3","desc":"Poranenie povrchovej žily v úrovni pleca a ramena"},
{"code":"S45.7","desc":"Poranenie viacerých krvných ciev v úrovni pleca a ramena"},
{"code":"S45.8","desc":"Poranenie iných krvných ciev v úrovni pleca a ramena"},
{"code":"S45.9","desc":"Poranenie bližšie neurčenej krvnej cievy v úrovni pleca a ramena"},
{"code":"S46.-","desc":"Poranenie svalu a šľachy v úrovni pleca a ramena"},
{"code":"S46.0","desc":"Poranenie svalu a šľachy rotátorovej manžety ramena"},
{"code":"S46.1","desc":"Poranenie svalu a šľachy dlhej hlavy dvojhlavého svalu ramena (m. biceps brachii)"},
{"code":"S46.2","desc":"Poranenie svalu a šľachy inej časti dvojhlavého svalu ramena (m. biceps brachii)"},
{"code":"S46.3","desc":"Poranenie svalu a šľachy trojhlavého svalu ramena (m. triceps brachii)"},
{"code":"S46.7","desc":"Poranenie viacerých svalov a šliach v úrovni pleca a ramena"},
{"code":"S46.8","desc":"Poranenie iného svalu a šľachy v úrovni pleca a ramena"},
{"code":"S46.9","desc":"Poranenie bližšie neurčeného svalu a šľachy v úrovni pleca a ramena"},
{"code":"S47","desc":"Drvivé poranenie pleca a ramena"},
{"code":"S48.-","desc":"Úrazová amputácia pleca a ramena"},
{"code":"S48.0","desc":"Úrazová amputácia v plecovom kĺbe"},
{"code":"S48.1","desc":"Úrazová amputácia v úrovni medzi plecom a lakťom"},
{"code":"S48.9","desc":"Úrazová amputácia v oblasti pleca a ramena, úroveň bližšie neurčená"},
{"code":"S49.-","desc":"Iné a bližšie neurčené poranenie pleca a ramena"},
{"code":"S49.7","desc":"Viaceré poranenia pleca a ramena"},
{"code":"S49.8","desc":"Iné poranenie pleca a ramena, bližšie určené"},
{"code":"S49.9","desc":"Poranenie pleca a ramena, bližšie neurčené"},
{"code":"S50-S59","desc":"Poranenia lakťa a predlaktia"},
{"code":"S50.-","desc":"Povrchové poranenie lakťa a predlaktia"},
{"code":"S50.0","desc":"Pomliaždenie lakťa"},
{"code":"S50.1","desc":"Pomliaždenie iných a bližšie neurčených častí predlaktia"},
{"code":"S50.7","desc":"Viaceré povrchové poranenia predlaktia"},
{"code":"S50.8-","desc":"Iné povrchové poranenie predlaktia"},
{"code":"S50.81","desc":"Iné povrchové poranenie predlaktia: odrenina"},
{"code":"S50.82","desc":"Iné povrchové poranenie predlaktia: tvorba pľuzgierov (netermických)"},
{"code":"S50.83","desc":"Iné povrchové poranenie predlaktia: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S50.84","desc":"Iné povrchové poranenie predlaktia: povrchové cudzie teleso (úlomky)"},
{"code":"S50.88","desc":"Iné povrchové poranenie predlaktia: Ostatné povrchové poranenia lakťa"},
{"code":"S50.9","desc":"Povrchové poranenie predlaktia, bližšie neurčené"},
{"code":"S51.-","desc":"Otvorená rana predlaktia"},
{"code":"S51.0","desc":"Otvorená rana lakťa"},
{"code":"S51.7","desc":"Viaceré otvorené rany predlaktia"},
{"code":"S51.8-","desc":"Otvorená rana iných častí predlaktia"},
{"code":"S51.80","desc":"Bližšie neurčená otvorená rana iných častí predlaktia"},
{"code":"S51.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení predlaktia"},
{"code":"S51.85!","desc":"Poškodenie mäkkých častí II. stupňa pri zatvorenej zlomenine alebo vykĺbení predlaktia"},
{"code":"S51.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení predlaktia"},
{"code":"S51.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení predlaktia"},
{"code":"S51.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení predlaktia"},
{"code":"S51.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení predlaktia"},
{"code":"S51.9","desc":"Otvorená rana predlaktia bližšie neurčenej časti"},
{"code":"S52.-","desc":"Zlomenina predlaktia"},
{"code":"S52.0-","desc":"Zlomenina horného konca lakťovej kosti"},
{"code":"S52.00","desc":"Zlomenina horného konca lakťovej kosti: časť bližšie neurčená"},
{"code":"S52.01","desc":"Zlomenina horného konca lakťovej kosti: olekranon"},
{"code":"S52.02","desc":"Zlomenina horného konca lakťovej kosti: processus coronoideus"},
{"code":"S52.09","desc":"Zlomenina horného konca lakťovej kosti: iné a mnohopočetné časti"},
{"code":"S52.1-","desc":"Zlomenina horného konca vretennej kosti"},
{"code":"S52.10","desc":"Zlomenina horného konca vretennej kosti: časť bližšie neurčená"},
{"code":"S52.11","desc":"Zlomenina horného konca vretennej kosti: hlava"},
{"code":"S52.12","desc":"Zlomenina horného konca vretennej kosti: collum"},
{"code":"S52.19","desc":"Zlomenina horného konca vretennej kosti: iné a mnohopočetné časti"},
{"code":"S52.2-","desc":"Zlomenina diafýzy lakťovej kosti"},
{"code":"S52.20","desc":"Zlomenina diafýzy lakťovej kosti, časť bližšie neurčená"},
{"code":"S52.21","desc":"Zlomenina proximálnej časti diafýzy lakťovej kosti s luxáciou hlavičky vretennej kosti"},
{"code":"S52.3-","desc":"Zlomenina diafýzy vretennej kosti"},
{"code":"S52.30","desc":"Zlomenina diafýzy vretennej kosti: časť bližšie neurčená"},
{"code":"S52.31","desc":"Zlomenina dolnej časti diafýzy vretennej kosti s luxáciou hlavičky lakťovej kosti"},
{"code":"S52.4","desc":"Zlomenina diafýzy lakťovej a vretennej kosti"},
{"code":"S52.5-","desc":"Zlomenina dolného konca vretennej kosti"},
{"code":"S52.50","desc":"Zlomenina dolného konca vretennej kosti: bližšie neurčená"},
{"code":"S52.51","desc":"Zlomenina dolného konca vretennej kosti: extenzný typ"},
{"code":"S52.52","desc":"Zlomenina dolného konca vretennej kosti: flekčný typ"},
{"code":"S52.59","desc":"Zlomenina dolného konca vretennej kosti: iné a mnohopočetné časti"},
{"code":"S52.6","desc":"Zlomenina dolného konca lakťovej a vretennej kosti"},
{"code":"S52.7","desc":"Viaceré zlomeniny predlaktia"},
{"code":"S52.8","desc":"Zlomenina inej časti predlaktia"},
{"code":"S52.9","desc":"Zlomenina predlaktia, bližšie neurčenej časti"},
{"code":"S53.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbov a väzov lakťa"},
{"code":"S53.0","desc":"Vykĺbenie hlavice vretennej kosti"},
{"code":"S53.1-","desc":"Vykĺbenie iných a bližšie neurčených častí lakťa"},
{"code":"S53.10","desc":"Vykĺbenie lakťa: bližšie neurčené"},
{"code":"S53.11","desc":"Iné a bližšie neurčené vykĺbenie lakťa: dopredu"},
{"code":"S53.12","desc":"Iné a bližšie neurčené vykĺbenie lakťa: dozadu"},
{"code":"S53.13","desc":"Iné a bližšie neurčené vykĺbenie lakťa: mediálne"},
{"code":"S53.14","desc":"Iné a bližšie neurčené vykĺbenie lakťa: laterálne"},
{"code":"S53.18","desc":"Vykĺbenie lakťa: iné"},
{"code":"S53.2","desc":"Úrazová ruptúra lig.collaterale radiale"},
{"code":"S53.3","desc":"Úrazová ruptúra lig.collaterale ulnare"},
{"code":"S53.4-","desc":"Vyvrtnutie a natiahnutie lakťa"},
{"code":"S53.40","desc":"Vyvrtnutie a natiahnutie lakťa: časť bližšie neurčená"},
{"code":"S53.41","desc":"Vyvrtnutie a natiahnutie lakťa: lig.collaterale radiale"},
{"code":"S53.42","desc":"Vyvrtnutie a natiahnutie lakťa: lig.collaterale ulnare"},
{"code":"S53.43","desc":"Vyvrtnutie a natiahnutie lakťa: humeroradiálny kĺb"},
{"code":"S53.44","desc":"Vyvrtnutie a natiahnutie lakťa: humeroulnárny kĺb"},
{"code":"S53.48","desc":"Vyvrtnutie a natiahnutie lakťa: iné časti"},
{"code":"S54.-","desc":"Poranenie nervov v úrovni predlaktia"},
{"code":"S54.0","desc":"Poranenie n. ulnaris v úrovni predlaktia"},
{"code":"S54.1","desc":"Poranenie n. medianus v úrovni predlaktia"},
{"code":"S54.2","desc":"Poranenie n. radialis v úrovni predlaktia"},
{"code":"S54.3","desc":"Poranenie kožného senzorického nervu v úrovni predlaktia"},
{"code":"S54.7","desc":"Poranenie viacerých nervov v úrovni predlaktia"},
{"code":"S54.8","desc":"Poranenie iných nervov v úrovni predlaktia"},
{"code":"S54.9","desc":"Poranenie bližšie neurčeného nervu v úrovni predlaktia"},
{"code":"S55.-","desc":"Poranenie krvných ciev v úrovni predlaktia"},
{"code":"S55.0","desc":"Poranenie a.ulnaris v úrovni predlaktia"},
{"code":"S55.1","desc":"Poranenie a.radialis v úrovni predlaktia"},
{"code":"S55.2","desc":"Poranenie žily v úrovni predlaktia"},
{"code":"S55.7","desc":"Poranenie viacerých krvných ciev v úrovni predlaktia"},
{"code":"S55.8","desc":"Poranenie iných krvných ciev v úrovni predlaktia"},
{"code":"S55.9","desc":"Poranenie bližšie neurčenej krvnej cievy v úrovni predlaktia"},
{"code":"S56.-","desc":"Poranenie svalu a šľachy v úrovni predlaktia"},
{"code":"S56.0","desc":"Poranenie svalu a šľachy ohýbača (flexoru) palca v úrovni predlaktia"},
{"code":"S56.1","desc":"Poranenie svalu a šľachy ohýbača (flexoru) iného prsta alebo prstov v úrovni predlaktia"},
{"code":"S56.2","desc":"Poranenie svalu a šľachy iného ohýbača (flexoru) v úrovni predlaktia"},
{"code":"S56.3","desc":"Poranenie svalu a šľachy vystierača (extenzoru) alebo odťahovača (abduktoru) palca v úrovni predlaktia"},
{"code":"S56.4","desc":"Poranenie svalu a šľachy vystierača (extenzoru) iného prsta alebo prstov v úrovni predlaktia"},
{"code":"S56.5","desc":"Poranenie iného svalu a šľachy vystierača (extenzoru) v úrovni predlaktia"},
{"code":"S56.7","desc":"Poranenie viacerých svalov a šliach v úrovni predlaktia"},
{"code":"S56.8","desc":"Poranenie iných a bližšie neurčených svalov a šliach v úrovni predlaktia"},
{"code":"S57.-","desc":"Drvivé poranenie predlaktia"},
{"code":"S57.0","desc":"Drvivé poranenie lakťa"},
{"code":"S57.8","desc":"Drvivé poranenie iných častí predlaktia"},
{"code":"S57.9","desc":"Drvivé poranenie predlaktia, bližšie neurčenej časti"},
{"code":"S58.-","desc":"Úrazová amputácia lakťa a predlaktia"},
{"code":"S58.0","desc":"Úrazová amputácia v lakti"},
{"code":"S58.1","desc":"Úrazová amputácia medzi lakťom a zápästím"},
{"code":"S58.9","desc":"Úrazová amputácia predlaktia, úroveň bližšie neurčená"},
{"code":"S59.-","desc":"Iné a bližšie neurčené poranenia predlaktia"},
{"code":"S59.7","desc":"Viaceré poranenia lakťa a predlaktia"},
{"code":"S59.8","desc":"Iné a bližšie určené poranenie predlaktia"},
{"code":"S59.9","desc":"Poranenie predlaktia, bližšie neurčené"},
{"code":"S60-S69","desc":"Poranenia zápästia a ruky"},
{"code":"S60.-","desc":"Povrchové poranenie zápästia a ruky"},
{"code":"S60.0","desc":"Pomliaždenie prsta (prstov) ruky bez poškodenia nechta"},
{"code":"S60.1","desc":"Pomliaždenie prsta (prstov) ruky s poškodením nechta"},
{"code":"S60.2","desc":"Pomliaždenie iných častí zápästia a ruky"},
{"code":"S60.7","desc":"Viaceré povrchové poranenia zápästia a ruky"},
{"code":"S60.8-","desc":"Iné povrchové poranenie zápästia a ruky"},
{"code":"S60.81","desc":"Iné povrchové poranenia zápästia a ruky: odrenina"},
{"code":"S60.82","desc":"Iné povrchové poranenia zápästia a ruky: tvorba pľuzgierov (netermických)"},
{"code":"S60.83","desc":"Iné povrchové poranenia zápästia a ruky: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S60.84","desc":"Iné povrchové poranenia zápästia a ruky: povrchové cudzie telesá (úlomky)"},
{"code":"S60.88","desc":"Iné povrchové poranenia zápästia a ruky: iné"},
{"code":"S60.9","desc":"Povrchové poranenie zápästia a ruky, bližšie neurčené"},
{"code":"S61.-","desc":"Otvorená rana zápästia a ruky"},
{"code":"S61.0","desc":"Otvorená rana prsta (prstov) ruky bez poškodenia nechta"},
{"code":"S61.1","desc":"Otvorená rana prsta (prstov) ruky s poškodením nechta"},
{"code":"S61.7","desc":"Viaceré otvorené rany zápästia a ruky"},
{"code":"S61.8-","desc":"Otvorená rana iných častí zápästia a ruky"},
{"code":"S61.80","desc":"Bližšie neurčená otvorená rana iných častí zápästia a ruky"},
{"code":"S61.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení zápästia a ruky"},
{"code":"S61.85!","desc":"Poškodenie mäkkých častí II. stupňa pri zatvorenej zlomenine alebo vykĺbení zápästia a ruky"},
{"code":"S61.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení zápästia a ruky"},
{"code":"S61.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení zápästia a ruky"},
{"code":"S61.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení zápästia a ruky"},
{"code":"S61.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení zápästia a ruky"},
{"code":"S61.9","desc":"Otvorená rana časti zápästia a ruky, bližšie neurčená"},
{"code":"S62.-","desc":"Zlomenina v úrovni zápästia a ruky"},
{"code":"S62.0","desc":"Zlomenina os naviculare"},
{"code":"S62.1-","desc":"Zlomenina jednej alebo viacerých iných zápästných kostí"},
{"code":"S62.10","desc":"Zlomenina zápästnej kosti, bližšie neurčená"},
{"code":"S62.11","desc":"Zlomenina os lunatum"},
{"code":"S62.12","desc":"Zlomenina os triquetrum"},
{"code":"S62.13","desc":"Zlomenina os pisiforme"},
{"code":"S62.14","desc":"Zlomenina os trapezium"},
{"code":"S62.15","desc":"Zlomenina os trapezoideum"},
{"code":"S62.16","desc":"Zlomenina os capitatum"},
{"code":"S62.17","desc":"Zlomenina os hamatum"},
{"code":"S62.19","desc":"Zlomenina inej a viacerých zápästných kostí"},
{"code":"S62.2-","desc":"Zlomenina prvej záprstnej kosti"},
{"code":"S62.20","desc":"Zlomenina prvej záprstnej kosti, časť bližšie neurčená"},
{"code":"S62.21","desc":"Zlomenina prvej záprstnej kosti, báza"},
{"code":"S62.22","desc":"Zlomenina prvej záprstnej kosti, diafýza"},
{"code":"S62.23","desc":"Zlomenina prvej záprstnej kosti, krčok"},
{"code":"S62.24","desc":"Zlomenina prvej záprstnej kosti, hlava"},
{"code":"S62.3-","desc":"Zlomenina inej záprstnej kosti"},
{"code":"S62.30","desc":"Zlomenina inej záprstnej kosti, časť bližšie neurčená"},
{"code":"S62.31","desc":"Zlomenina inej záprstnej kosti, báza"},
{"code":"S62.32","desc":"Zlomenina inej záprstnej kosti, diafýza"},
{"code":"S62.33","desc":"Zlomenina inej záprstnej kosti, krčok"},
{"code":"S62.34","desc":"Zlomenina inej záprstnej kosti, hlava"},
{"code":"S62.4","desc":"Viaceré zlomeniny záprstných kostí"},
{"code":"S62.5-","desc":"Zlomenina palca"},
{"code":"S62.50","desc":"Zlomenina palca, časť bližšie neurčená"},
{"code":"S62.51","desc":"Zlomenina palca, horný článok"},
{"code":"S62.52","desc":"Zlomenina palca, dolný článok"},
{"code":"S62.6-","desc":"Zlomenina iného prsta ruky"},
{"code":"S62.60","desc":"Zlomenina iného prsta ruky, časť bližšie neurčená"},
{"code":"S62.61","desc":"Zlomenina iného prsta ruky, horný článok"},
{"code":"S62.62","desc":"Zlomenina iného prsta ruky, stredný článok"},
{"code":"S62.63","desc":"Zlomenina iného prsta ruky, dolný článok"},
{"code":"S62.7","desc":"Viaceré zlomeniny prstov ruky"},
{"code":"S62.8","desc":"Zlomenina iných a bližšie neurčených častí zápästia a ruky"},
{"code":"S63.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbov a väzov v úrovni zápästia a ruky"},
{"code":"S63.0-","desc":"Vykĺbenie zápästia"},
{"code":"S63.00","desc":"Vykĺbenie zápästia, časť bližšie neurčená"},
{"code":"S63.01","desc":"Vykĺbenie zápästia, radioulnárny kĺb"},
{"code":"S63.02","desc":"Vykĺbenie zápästia, radiokarpový kĺb"},
{"code":"S63.03","desc":"Vykĺbenie zápästia, mediokarpový kĺb"},
{"code":"S63.04","desc":"Vykĺbenie zápästia, karpometakarpový kĺb"},
{"code":"S63.08","desc":"Vykĺbenie zápästia, iné"},
{"code":"S63.1-","desc":"Vykĺbenie prsta ruky"},
{"code":"S63.10","desc":"Vykĺbenie prsta ruky, časť bližšie neurčená"},
{"code":"S63.11","desc":"Vykĺbenie prsta ruky, metakarpofalangový kĺb"},
{"code":"S63.12","desc":"Vykĺbenie prsta ruky, interfalangový kĺb"},
{"code":"S63.2","desc":"Viaceré vykĺbenia prstov ruky"},
{"code":"S63.3","desc":"Úrazové roztrhnutie väzu zápästia a ruky"},
{"code":"S63.4","desc":"Úrazové roztrhnutie väzu prsta ruky v metakarpofalangovom a interfalangovom kĺbe"},
{"code":"S63.5-","desc":"Vyvrtnutie a natiahnutie zápästia"},
{"code":"S63.50","desc":"Vyvrtnutie a natiahnutie zápästia, časť bližšie neurčená"},
{"code":"S63.51","desc":"Vyvrtnutie a natiahnutie zápästia, karpový kĺb"},
{"code":"S63.52","desc":"Vyvrtnutie a natiahnutie zápästia, radiokarpový kĺb"},
{"code":"S63.53","desc":"Vyvrtnutie a natiahnutie zápästia, karpometakarpový kĺb"},
{"code":"S63.58","desc":"Vyvrtnutie a natiahnutie zápästia, iná časť"},
{"code":"S63.6-","desc":"Vyvrtnutie a natiahnutie jedného alebo viacerých prstov ruky"},
{"code":"S63.60","desc":"Vyvrtnutie a natiahnutie jedného alebo viacerých prstov, časť bližšie neurčená"},
{"code":"S63.61","desc":"Vyvrtnutie a natiahnutie jedného alebo viacerých prstov, metakarpofalangový kĺb"},
{"code":"S63.62","desc":"Vyvrtnutie a natiahnutie jedného alebo viacerých prstov, interfalangový kĺb"},
{"code":"S63.68","desc":"Vyvrtnutie a natiahnutie jedného alebo viacerých prstov, iná časť"},
{"code":"S63.7","desc":"Vyvrtnutie a natiahnutie iných a bližšie neurčených častí ruky"},
{"code":"S64.-","desc":"Poranenie nervov v úrovni zápästia a ruky"},
{"code":"S64.0","desc":"Poranenie n. ulnaris v úrovni zápästia a ruky"},
{"code":"S64.1","desc":"Poranenie n. medianus v úrovni zápästia a ruky"},
{"code":"S64.2","desc":"Poranenie n. radialis v úrovni zápästia a ruky"},
{"code":"S64.3","desc":"Poranenie n. digitalis palca"},
{"code":"S64.4","desc":"Poranenie n. digitalis iného prsta ruky"},
{"code":"S64.7","desc":"Poranenie viacerých nervov v úrovni zápästia a ruky"},
{"code":"S64.8","desc":"Poranenie iného nervu v úrovni zápästia a ruky"},
{"code":"S64.9","desc":"Poranenie bližšie neurčeného nervu v úrovni zápästia a ruky"},
{"code":"S65.-","desc":"Poranenie krvných ciev v úrovni zápästia a ruky"},
{"code":"S65.0","desc":"Poranenie a.ulnaris v úrovni zápästia a ruky"},
{"code":"S65.1","desc":"Poranenie a.radialis v úrovni zápästia a ruky"},
{"code":"S65.2","desc":"Poranenie ciev povrchového dlaňového oblúka"},
{"code":"S65.3","desc":"Poranenie ciev hĺbkového dlaňového oblúka"},
{"code":"S65.4","desc":"Poranenie krvnej cievy (ciev) palca"},
{"code":"S65.5","desc":"Poranenie krvnej cievy (ciev) iného prsta ruky"},
{"code":"S65.7","desc":"Poranenie viacerých krvných ciev v úrovni zápästia a ruky"},
{"code":"S65.8","desc":"Poranenie inej krvnej cievy v úrovni zápästia a ruky"},
{"code":"S65.9","desc":"Poranenie bližšie neurčenej krvnej cievy v úrovni zápästia a ruky"},
{"code":"S66.-","desc":"Poranenie svalu a šľachy v úrovni zápästia a ruky"},
{"code":"S66.0","desc":"Poranenie svalu a šľachy dlhého ohýbača palca [m. flexor pollicis longus] v úrovni zápästia a ruky"},
{"code":"S66.1","desc":"Poranenie svalu a šľachy ohýbača iného prsta v úrovni zápästia a ruky"},
{"code":"S66.2","desc":"Poranenie svalu a šľachy vystierača palca [m. extensor pollicis] v úrovni zápästia a ruky"},
{"code":"S66.3","desc":"Poranenie svalu a šľachy vystierača iného prsta v úrovni zápästia a ruky"},
{"code":"S66.4","desc":"Poranenie vnútorného svalu a šľachy palca v úrovni zápästia a ruky"},
{"code":"S66.5","desc":"Poranenie vnútorného svalu a šľachy iného prsta v úrovni zápästia a ruky"},
{"code":"S66.6","desc":"Poranenie viacerých svalov a šliach ohýbačov v úrovni zápästia a ruky"},
{"code":"S66.7","desc":"Poranenie viacerých svalov a šliach vystieračov v úrovni zápästia a ruky"},
{"code":"S66.8","desc":"Poranenie iného svalu a šľachy v úrovni zápästia a ruky"},
{"code":"S66.9","desc":"Poranenie bližšie neurčeného svalu a šľachy v úrovni zápästia a ruky"},
{"code":"S67.-","desc":"Drvivé poranenie zápästia a ruky"},
{"code":"S67.0","desc":"Drvivé poranenie palca a iného prsta alebo prstov ruky"},
{"code":"S67.8","desc":"Drvivé poranenie inej a bližšie neurčenej časti zápästia a ruky"},
{"code":"S68.-","desc":"Úrazová amputácia zápästia a ruky"},
{"code":"S68.0","desc":"Úrazová amputácia palca (kompletná) (čiastočná)"},
{"code":"S68.1","desc":"Úrazová amputácia iného (jedného) prsta ruky (kompletná) (čiastočná)"},
{"code":"S68.2","desc":"Samostatná úrazová amputácia dvoch alebo viacerých prstov ruky (kompletná) (čiastočná)"},
{"code":"S68.3","desc":"Kombinovaná úrazová amputácia (časti) prsta (prstov) s inými časťami zápästia a ruky"},
{"code":"S68.4","desc":"Úrazová amputácia ruky v úrovni zápästia"},
{"code":"S68.8","desc":"Úrazová amputácia inej časti zápästia a ruky"},
{"code":"S68.9","desc":"Úrazová amputácia zápästia a ruky v bližšie neurčenej úrovni"},
{"code":"S69.-","desc":"Iné a bližšie neurčené poranenia zápästia a ruky"},
{"code":"S69.7","desc":"Viaceré poranenia zápästia a ruky"},
{"code":"S69.8","desc":"Iné poranenia zápästia a ruky, bližšie určené"},
{"code":"S69.9","desc":"Poranenie zápästia a ruky, bližšie neurčené"},
{"code":"S70-S79","desc":"Poranenia bedra a stehna"},
{"code":"S70.-","desc":"Povrchové poranenie bedra a stehna"},
{"code":"S70.0","desc":"Pomliaždenie bedra"},
{"code":"S70.1","desc":"Pomliaždenie stehna"},
{"code":"S70.7","desc":"Viaceré povrchové poranenia bedra a stehna"},
{"code":"S70.8-","desc":"Iné povrchové poranenie bedra a stehna"},
{"code":"S70.81","desc":"Iné povrchové poranenia bedra a stehna: odrenina"},
{"code":"S70.82","desc":"Iné povrchové poranenia bedra a stehna: tvorba pľuzgierov (netermických)"},
{"code":"S70.83","desc":"Iné povrchové poranenia bedra a stehna: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S70.84","desc":"Iné povrchové poranenia bedra a stehna: povrchové cudzie teleso (úlomky)"},
{"code":"S70.88","desc":"Iné povrchové poranenia bedra a stehna: iné"},
{"code":"S70.9","desc":"Povrchové poranenie bedra a stehna, bližšie neurčené"},
{"code":"S71.-","desc":"Otvorená rana bedra a stehna"},
{"code":"S71.0","desc":"Otvorená rana bedra"},
{"code":"S71.1","desc":"Otvorená rana stehna"},
{"code":"S71.7","desc":"Viaceré otvorené rany bedra a stehna"},
{"code":"S71.8-","desc":"Otvorená rana inej a bližšie neurčenej časti panvového pletenca"},
{"code":"S71.80","desc":"Otvorená rana inej a bližšie neurčenej časti panvového pletenca"},
{"code":"S71.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení bedra a stehna"},
{"code":"S71.85!","desc":"Poškodenie mäkkých častí II. stupňa pri zatvorenej zlomenine alebo vykĺbení bedra a stehna"},
{"code":"S71.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení bedra a stehna"},
{"code":"S71.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení bedra a stehna"},
{"code":"S71.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení bedra a stehna"},
{"code":"S71.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení bedra a stehna"},
{"code":"S72.-","desc":"Zlomenina stehnovej kosti"},
{"code":"S72.0-","desc":"Zlomenina krčka stehnovej kosti"},
{"code":"S72.00","desc":"Zlomenina krčka stehnovej kosti, časť bližšie neurčená"},
{"code":"S72.01","desc":"Zlomenina krčka stehnovej kosti, intrakapsulárna"},
{"code":"S72.02","desc":"Zlomenina krčka stehnovej kosti, proximálna epifýza (epifyzeolýza)"},
{"code":"S72.03","desc":"Zlomenina krčka stehnovej kosti, subkapitálna"},
{"code":"S72.04","desc":"Zlomenina krčka stehnovej kosti, mediocervikálna"},
{"code":"S72.05","desc":"Zlomenina krčka stehnovej kosti, báza"},
{"code":"S72.08","desc":"Zlomenina krčka stehnovej kosti, iná časť"},
{"code":"S72.1-","desc":"Pertrochanterová zlomenina"},
{"code":"S72.10","desc":"Zlomenina stehnovej kosti, trochanterová, bližšie neurčená"},
{"code":"S72.11","desc":"Zlomenina stehnovej kosti, intertrochanterová"},
{"code":"S72.2","desc":"Subtrochanterová zlomenina"},
{"code":"S72.3","desc":"Zlomenina diafýzy stehnovej kosti"},
{"code":"S72.4-","desc":"Zlomenina dolného konca stehnovej kosti"},
{"code":"S72.40","desc":"Zlomenina dolného konca stehnovej kosti, časť bližšie neurčená"},
{"code":"S72.41","desc":"Zlomenina dolného konca stehnovej kosti, condylus (lateralis) (medialis)"},
{"code":"S72.42","desc":"Zlomenina dolného konca stehnovej kosti, epifýza (rozpustenie epifýzy)"},
{"code":"S72.43","desc":"Zlomenina dolného konca stehnovej kosti, suprakondylová"},
{"code":"S72.44","desc":"Zlomenina dolného konca stehnovej kosti, interkondylová"},
{"code":"S72.7","desc":"Viaceré zlomeniny stehnovej kosti"},
{"code":"S72.8","desc":"Zlomenina inej časti stehnovej kosti"},
{"code":"S72.9","desc":"Zlomenina stehnovej kosti, časť bližšie neurčená"},
{"code":"S73.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbu a väzov bedra"},
{"code":"S73.0-","desc":"Vykĺbenie bedra"},
{"code":"S73.00","desc":"Vykĺbenie bedra, bližšie neurčené"},
{"code":"S73.01","desc":"Vykĺbenie bedra, dozadu"},
{"code":"S73.02","desc":"Vykĺbenie bedra, dopredu"},
{"code":"S73.08","desc":"Vykĺbenie bedra, iné"},
{"code":"S73.1-","desc":"Vyvrtnutie a natiahnutie bedra"},
{"code":"S73.10","desc":"Vyvrtnutie a natiahnutie bedrového kĺbu, časť bližšie neurčená"},
{"code":"S73.11","desc":"Vyvrtnutie a natiahnutie bedrového kĺbu, iliofemorálny väz"},
{"code":"S73.12","desc":"Vyvrtnutie a natiahnutie bedrového kĺbu, ischiokapsulárny väz"},
{"code":"S73.18","desc":"Vyvrtnutie a natiahnutie bedrového kĺbu, iná časť"},
{"code":"S74.-","desc":"Poranenie nervu v úrovni bedra a stehna"},
{"code":"S74.0","desc":"Poranenie sedacieho nervu (n. ischiadicus) v úrovni bedra a stehna"},
{"code":"S74.1","desc":"Poranenie stehnového nervu (n. femoralis) v úrovni bedra a stehna"},
{"code":"S74.2","desc":"Poranenie senzorických kožných nervov v úrovni bedra a stehna"},
{"code":"S74.7","desc":"Poranenie viacerých nervov v úrovni bedra a stehna"},
{"code":"S74.8","desc":"Poranenie iných nervov v úrovni bedra a stehna"},
{"code":"S74.9","desc":"Poranenie bližšie neurčeného nervu v úrovni bedra a stehna"},
{"code":"S75.-","desc":"Poranenie krvných ciev v úrovni bedra a stehna"},
{"code":"S75.0","desc":"Poranenie a. femoralis"},
{"code":"S75.1","desc":"Poranenie v. femoralis v úrovni bedra a stehna"},
{"code":"S75.2","desc":"Poranenie v. saphena magna v úrovni bedra a stehna"},
{"code":"S75.7","desc":"Poranenie viacerých krvných ciev v úrovni bedra a stehna"},
{"code":"S75.8","desc":"Poranenie iných krvných ciev v úrovni bedra a stehna"},
{"code":"S75.9","desc":"Poranenie bližšie neurčených krvných ciev v úrovni bedra a stehna"},
{"code":"S76.-","desc":"Poranenie svalu a šľachy v úrovni bedra a stehna"},
{"code":"S76.0","desc":"Poranenie svalu a šľachy bedra"},
{"code":"S76.1","desc":"Poranenie štvorhlavého svalu stehna [m. quadriceps femoris] a jeho šľachy"},
{"code":"S76.2","desc":"Poranenie svalu a šľachy priťahovača (adduktora) stehna"},
{"code":"S76.3","desc":"Poranenie svalu a šľachy zadnej svalovej skupiny v úrovni stehna"},
{"code":"S76.4","desc":"Poranenie iných a bližšie neurčených svalov a šliach v úrovni stehna"},
{"code":"S76.7","desc":"Poranenie viacerých svalov a šliach v úrovni bedra a stehna"},
{"code":"S77.-","desc":"Drvivé poranenie bedra a stehna"},
{"code":"S77.0","desc":"Drvivé poranenie bedra"},
{"code":"S77.1","desc":"Drvivé poranenie stehna"},
{"code":"S77.2","desc":"Drvivé poranenie bedra a stehna"},
{"code":"S78.-","desc":"Úrazová amputácia bedra a stehna"},
{"code":"S78.0","desc":"Úrazová amputácia v bedrovom kĺbe"},
{"code":"S78.1","desc":"Úrazová amputácia v úrovni medzi bedrom a kolenom"},
{"code":"S78.9","desc":"Úrazová amputácia bedra a stehna v bližšie neurčenej úrovni"},
{"code":"S79.-","desc":"Iné a bližšie neurčené poranenie bedra a stehna"},
{"code":"S79.7","desc":"Viaceré poranenia bedra a stehna"},
{"code":"S79.8","desc":"Iné a bližšie určené poranenie bedra a stehna"},
{"code":"S79.9","desc":"Poranenie bedra a stehna, bližšie neurčené"},
{"code":"S80-S89","desc":"Poranenia kolena a predkolenia"},
{"code":"S80.-","desc":"Povrchové poranenie predkolenia"},
{"code":"S80.0","desc":"Pomliaždenie kolena"},
{"code":"S80.1","desc":"Pomliaždenie iných a bližšie neurčených častí predkolenia"},
{"code":"S80.7","desc":"Viaceré povrchové poranenia predkolenia"},
{"code":"S80.8-","desc":"Iné povrchové poranenie predkolenia"},
{"code":"S80.81","desc":"Iné povrchové poranenie predkolenia: odrenina"},
{"code":"S80.82","desc":"Iné povrchové poranenie predkolenia: tvorba pľuzgierov (netermických)"},
{"code":"S80.83","desc":"Iné povrchové poranenie predkolenia: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S80.84","desc":"Iné povrchové poranenie predkolenia: povrchové cudzie teleso (úlomky)"},
{"code":"S80.88","desc":"Iné povrchové poranenie predkolenia: iné"},
{"code":"S80.9","desc":"Povrchové poranenie predkolenia, bližšie neurčené"},
{"code":"S81.-","desc":"Otvorená rana predkolenia"},
{"code":"S81.0","desc":"Otvorená rana kolena"},
{"code":"S81.7","desc":"Viaceré otvorené rany predkolenia"},
{"code":"S81.8-","desc":"Otvorená rana iných častí predkolenia"},
{"code":"S81.80","desc":"Otvorená rana iných častí predkolenia, bližšie neurčená"},
{"code":"S81.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení predkolenia"},
{"code":"S81.85!","desc":"Poškodenie mäkkých častí II. stupňa pri zatvorenej zlomenine alebo vykĺbení predkolenia"},
{"code":"S81.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení predkolenia"},
{"code":"S81.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení predkolenia"},
{"code":"S81.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení predkolenia"},
{"code":"S81.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení predkolenia"},
{"code":"S81.9","desc":"Otvorená rana bližšie neurčenej časti predkolenia"},
{"code":"S82.-","desc":"Zlomenina predkolenia vrátane členka"},
{"code":"S82.0","desc":"Zlomenina jabĺčka [patella]"},
{"code":"S82.1-","desc":"Zlomenina horného konca píšťaly [tibia]"},
{"code":"S82.11","desc":"Zlomenina horného konca píšťaly, so zlomeninou ihlice (ktorákoľvek časť)"},
{"code":"S82.18","desc":"Zlomenina horného konca píšťaly, iná"},
{"code":"S82.2-","desc":"Zlomenina diafýzy píšťaly [tibia]"},
{"code":"S82.21","desc":"Zlomenina diafýzy píšťaly, so zlomeninou ihlice (ktorákoľvek časť)"},
{"code":"S82.28","desc":"Zlomenina diafýzy píšťaly, iná"},
{"code":"S82.3-","desc":"Zlomenina dolného konca píšťaly [tibia]"},
{"code":"S82.31","desc":"Zlomenina dolného konca píšťaly, so zlomeninou ihlice (ktorákoľvek časť)"},
{"code":"S82.38","desc":"Zlomenina dolného konca píšťaly, iná"},
{"code":"S82.4-","desc":"Zlomenina ihlice, izolovaná [fibula]"},
{"code":"S82.40","desc":"Zlomenina ihlice, izolovaná, časť bližšie neurčená"},
{"code":"S82.41","desc":"Zlomenina ihlice, izolovaná, horný koniec"},
{"code":"S82.42","desc":"Zlomenina ihlice, izolovaná, diafýza"},
{"code":"S82.49","desc":"Zlomenina ihlice, izolovaná, viaceré miesta"},
{"code":"S82.5","desc":"Zlomenina vnútorného členka"},
{"code":"S82.6","desc":"Zlomenina vonkajšieho členka"},
{"code":"S82.7","desc":"Viaceré zlomeniny predkolenia"},
{"code":"S82.8-","desc":"Zlomenina inej časti predkolenia"},
{"code":"S82.81","desc":"Bimaleolárna zlomenina"},
{"code":"S82.82","desc":"Trimaleolárna zlomenina"},
{"code":"S82.88","desc":"Zlomenina iných častí predkolenia"},
{"code":"S82.9","desc":"Zlomenina bližšie neurčenej časti predkolenia"},
{"code":"S83.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbov a väzov kolena"},
{"code":"S83.0","desc":"Vykĺbenie jabĺčka (patella)"},
{"code":"S83.1-","desc":"Vykĺbenie kolenného kĺbu"},
{"code":"S83.10","desc":"Vykĺbenie kolenného kĺbu, bližšie neurčené"},
{"code":"S83.11","desc":"Vykĺbenie kolenného kĺbu, vykĺbenie horného konca píšťaly (tibia) dopredu"},
{"code":"S83.12","desc":"Vykĺbenie kolenného kĺbu, vykĺbenie horného konca píšťaly (tibia) dozadu"},
{"code":"S83.13","desc":"Vykĺbenie kolenného kĺbu, vykĺbenie horného konca píšťaly (tibia) mediálne"},
{"code":"S83.14","desc":"Vykĺbenie kolenného kĺbu, vykĺbenie horného konca píšťaly (tibia) laterálne"},
{"code":"S83.18","desc":"Vykĺbenie kolenného kĺbu, iné"},
{"code":"S83.2","desc":"Trhlina menisku, akútna"},
{"code":"S83.3","desc":"Čerstvá trhlina kĺbovej chrupky kolena"},
{"code":"S83.4-","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu s postihnutím (fibulárneho) (tibiálneho) bočného väzu"},
{"code":"S83.40","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, bližšie neurčený bočný väz"},
{"code":"S83.41","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, distorzia fibulárneho vonkajšieho bočného väzu"},
{"code":"S83.42","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, distorzia tibiálneho vnútorného bočného väzu"},
{"code":"S83.43","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, natrhnutie fibulárneho vonkajšieho bočného väzu"},
{"code":"S83.44","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, natrhnutie tibiálneho vnútorného bočného väzu"},
{"code":"S83.5-","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu s postihnutím (predného) (zadného) skríženého väzu"},
{"code":"S83.50","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, bližšie neurčený skrížený väz"},
{"code":"S83.51","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, distorzia predného skríženého väzu"},
{"code":"S83.52","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, distorzia zadného skríženého väzu"},
{"code":"S83.53","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, natrhnutie predného skríženého väzu"},
{"code":"S83.54","desc":"Vyvrtnutie a natiahnutie kolenného kĺbu, natrhnutie zadného skríženého väzu"},
{"code":"S83.6","desc":"Vyvrtnutie a natiahnutie inej a bližšie neurčenej časti kolena"},
{"code":"S83.7","desc":"Poranenie viacerých útvarov kolena"},
{"code":"S84.-","desc":"Poranenie nervu v úrovni predkolenia"},
{"code":"S84.0","desc":"Poranenie n. tibialis v úrovni predkolenia"},
{"code":"S84.1","desc":"Poranenie n. peroneus v úrovni predkolenia"},
{"code":"S84.2","desc":"Poranenie senzorických kožných nervov v úrovni predkolenia"},
{"code":"S84.7","desc":"Poranenie viacerých nervov v úrovni predkolenia"},
{"code":"S84.8","desc":"Poranenie iných nervov v úrovni predkolenia"},
{"code":"S84.9","desc":"Poranenie bližšie neurčeného nervu v úrovni predkolenia"},
{"code":"S85.-","desc":"Poranenie krvných ciev v úrovni predkolenia"},
{"code":"S85.0","desc":"Poranenie zákolennej tepny [a.poplitea]"},
{"code":"S85.1","desc":"Poranenie (prednej) (zadnej) píšťalovej tepny [a.tibialis]"},
{"code":"S85.2","desc":"Poranenie ihlicovej tepny [a.peronea]"},
{"code":"S85.3","desc":"Poranenie veľkej skrytej žily [v. saphena magna] v úrovni predkolenia"},
{"code":"S85.4","desc":"Poranenie malej skrytej žily [v. saphena parva] v úrovni predkolenia"},
{"code":"S85.5","desc":"Poranenie zákolennej žily [v. poplitea]"},
{"code":"S85.7","desc":"Poranenie viacerých krvných ciev v úrovni predkolenia"},
{"code":"S85.8","desc":"Poranenie iných krvných ciev v úrovni predkolenia"},
{"code":"S85.9","desc":"Poranenie bližšie neurčenej krvnej cievy v úrovni predkolenia"},
{"code":"S86.-","desc":"Poranenie svalu a šľachy v úrovni predkolenia"},
{"code":"S86.0","desc":"Poranenie Achillovej šľachy"},
{"code":"S86.1","desc":"Poranenie iného svalu (svalov) a šľachy (šliach) zadnej svalovej skupiny v úrovni predkolenia"},
{"code":"S86.2","desc":"Poranenie svalu (svalov) a šľachy (šliach) prednej svalovej skupiny v úrovni predkolenia"},
{"code":"S86.3","desc":"Poranenie svalu (svalov) a šľachy (šliach) ihlicovej (peroneálnej) svalovej skupiny v úrovni predkolenia"},
{"code":"S86.7","desc":"Poranenie viacerých svalov a šliach v úrovni predkolenia"},
{"code":"S86.8","desc":"Poranenie iného svalu a šľachy v úrovni predkolenia"},
{"code":"S86.9","desc":"Poranenie bližšie neurčeného svalu a šľachy v úrovni predkolenia"},
{"code":"S87.-","desc":"Drvivé poranenie predkolenia"},
{"code":"S87.0","desc":"Drvivé poranenie kolena"},
{"code":"S87.8","desc":"Drvivé poranenie inej a bližšie neurčenej časti predkolenia"},
{"code":"S88.-","desc":"Úrazová amputácia predkolenia"},
{"code":"S88.0","desc":"Úrazová amputácia v úrovni kolena"},
{"code":"S88.1","desc":"Úrazová amputácia v úrovni medzi kolenom a členkom"},
{"code":"S88.9","desc":"Úrazová amputácia predkolenia v bližšie neurčenej úrovni"},
{"code":"S89.-","desc":"Iné a bližšie neurčené poranenie predkolenia"},
{"code":"S89.7","desc":"Viaceré poranenia predkolenia"},
{"code":"S89.8","desc":"Iné poranenie predkolenia, bližšie určené"},
{"code":"S89.9","desc":"Poranenie predkolenia, bližšie neurčené"},
{"code":"S90-S99","desc":"Poranenia členka a nohy"},
{"code":"S90.-","desc":"Povrchové poranenie členka a nohy"},
{"code":"S90.0","desc":"Pomliaždenie členka"},
{"code":"S90.1","desc":"Pomliaždenie prsta (prstov) nohy bez poškodenia nechta"},
{"code":"S90.2","desc":"Pomliaždenie prsta (prstov) nohy s poškodením nechta"},
{"code":"S90.3","desc":"Pomliaždenie iných a bližšie neurčených častí nohy"},
{"code":"S90.7","desc":"Viaceré povrchové poranenia členka a nohy"},
{"code":"S90.8-","desc":"Iné povrchové poranenie členka a nohy"},
{"code":"S90.81","desc":"Iné povrchové poranenie členka a nohy: odrenina"},
{"code":"S90.82","desc":"Iné povrchové poranenie členka a nohy: tvorba pľuzgierov (netermických)"},
{"code":"S90.83","desc":"Iné povrchové poranenie členka a nohy: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"S90.84","desc":"Iné povrchové poranenie členka a nohy: povrchové cudzie teleso (úlomky)"},
{"code":"S90.88","desc":"Iné povrchové poranenie členka a nohy: iné"},
{"code":"S90.9","desc":"Povrchové poranenie členka a nohy, bližšie neurčené"},
{"code":"S91.-","desc":"Otvorená rana členka a nohy"},
{"code":"S91.0","desc":"Otvorená rana členka"},
{"code":"S91.1","desc":"Otvorená rana prsta (prstov) nohy bez poškodenia nechta"},
{"code":"S91.2","desc":"Otvorená rana prsta (prstov) nohy s poškodením nechta"},
{"code":"S91.3","desc":"Otvorená rana inej časti nohy"},
{"code":"S91.7","desc":"Viaceré otvorené rany členka a nohy"},
{"code":"S91.8-","desc":"Otvorená rana inej časti členka a nohy"},
{"code":"S91.80","desc":"Otvorená rana inej časti členka a nohy"},
{"code":"S91.84!","desc":"Poškodenie mäkkých častí I. stupňa pri zatvorenej zlomenine alebo vykĺbení nohy"},
{"code":"S91.85!","desc":"Poškodenie mäkkých tkanív II. stupňa pri zatvorenej zlomenine alebo vykĺbení nohy"},
{"code":"S91.86!","desc":"Poškodenie mäkkých častí III. stupňa pri zatvorenej zlomenine alebo vykĺbení nohy"},
{"code":"S91.87!","desc":"Poškodenie mäkkých častí I. stupňa pri otvorenej zlomenine alebo vykĺbení nohy"},
{"code":"S91.88!","desc":"Poškodenie mäkkých častí II. stupňa pri otvorenej zlomenine alebo vykĺbení nohy"},
{"code":"S91.89!","desc":"Poškodenie mäkkých častí III. stupňa pri otvorenej zlomenine alebo vykĺbení nohy"},
{"code":"S92.-","desc":"Zlomenina nohy [okrem členka]"},
{"code":"S92.0","desc":"Zlomenina pätovej kosti"},
{"code":"S92.1","desc":"Zlomenina členkovej kosti"},
{"code":"S92.2-","desc":"Zlomenina jednej alebo viacerých iných priehlavkových kostí"},
{"code":"S92.20","desc":"Zlomenina jednej alebo viacerých ostatných priehlavkových kostí, bližšie neurčených"},
{"code":"S92.21","desc":"Zlomenina os naviculare"},
{"code":"S92.22","desc":"Zlomenina os cuboideum"},
{"code":"S92.23","desc":"Zlomenina os cuneiforme (intermediárna) (laterálna) (mediálna)"},
{"code":"S92.28","desc":"Zlomenina iných priehlavkových kostí"},
{"code":"S92.3","desc":"Zlomenina predpriehlavkovej kosti"},
{"code":"S92.4","desc":"Zlomenina prvého prsta nohy"},
{"code":"S92.5","desc":"Zlomenina iného prsta nohy"},
{"code":"S92.7","desc":"Viaceré zlomeniny nohy"},
{"code":"S92.9","desc":"Zlomenina nohy, bližšie neurčená"},
{"code":"S93.-","desc":"Vykĺbenie, vyvrtnutie a natiahnutie kĺbu a väzu v úrovni členka a nohy"},
{"code":"S93.0","desc":"Vykĺbenie členkového kĺbu"},
{"code":"S93.1-","desc":"Vykĺbenie jedného alebo viacerých prstov nohy"},
{"code":"S93.10","desc":"Vykĺbenie jedného alebo viacerých prstov nohy, bližšie neurčené"},
{"code":"S93.11","desc":"Vykĺbenie jedného alebo viacerých prstov nohy, metatarzofalangový kĺb"},
{"code":"S93.12","desc":"Vykĺbenie jedného alebo viacerých prstov nohy, interfalangový kĺb"},
{"code":"S93.2","desc":"Roztrhnutie väzov v úrovni členka a nohy"},
{"code":"S93.3-","desc":"Vykĺbenie inej a bližšie neurčenej časti nohy"},
{"code":"S93.30","desc":"Vykĺbenie iných a bližšie neurčených častí nohy, časť bližšie neurčená"},
{"code":"S93.31","desc":"Vykĺbenie iných a bližšie neurčených častí nohy, priehlavkové kosti, kĺb bližšie neurčený"},
{"code":"S93.32","desc":"Vykĺbenie iných a bližšie neurčených častí nohy, mediotarzálny kĺb"},
{"code":"S93.33","desc":"Vykĺbenie iných a bližšie neurčených častí nohy, tarzometatarzálny kĺb"},
{"code":"S93.34","desc":"Vykĺbenie iných a bližšie neurčených častí nohy, metatarzálne (kosti), kĺb bližšie neurčený"},
{"code":"S93.38","desc":"Vykĺbenie iných a bližšie neurčených častí nohy, iné"},
{"code":"S93.4-","desc":"Vyvrtnutie a natiahnutie členka"},
{"code":"S93.40","desc":"Vyvrtnutie a natiahnutie členka, časť bližšie neurčená"},
{"code":"S93.41","desc":"Vyvrtnutie a natiahnutie členka, lig.deltoideum"},
{"code":"S93.42","desc":"Vyvrtnutie a natiahnutie členka, lig.calcaneofibulare"},
{"code":"S93.43","desc":"Vyvrtnutie a natiahnutie členka, lig.tibiofibulare (anterior) (posterior), distálne"},
{"code":"S93.48","desc":"Vyvrtnutie a natiahnutie členka, iná časť"},
{"code":"S93.5","desc":"Vyvrtnutie a natiahnutie prsta (prstov) nohy"},
{"code":"S93.6","desc":"Vyvrtnutie a natiahnutie inej a bližšie neurčenej časti nohy"},
{"code":"S94.-","desc":"Poranenie nervov v úrovni členka a nohy"},
{"code":"S94.0","desc":"Poranenie n. plantaris lateralis"},
{"code":"S94.1","desc":"Poranenie n. plantaris medialis"},
{"code":"S94.2","desc":"Poranenie n. peroneus profundus v úrovni členka a nohy"},
{"code":"S94.3","desc":"Poranenie senzorických kožných nervov v úrovni členka a nohy"},
{"code":"S94.7","desc":"Poranenie viacerých nervov v úrovni členka a nohy"},
{"code":"S94.8","desc":"Poranenie iného nervu v úrovni členka a nohy"},
{"code":"S94.9","desc":"Poranenie bližšie neurčeného nervu v úrovni členka a nohy"},
{"code":"S95.-","desc":"Poranenie krvných ciev v úrovni členka a nohy"},
{"code":"S95.0","desc":"Poranenie a.dorsalis pedis"},
{"code":"S95.1","desc":"Poranenie a.plantaris pedis"},
{"code":"S95.2","desc":"Poranenie v. dorsalis pedis"},
{"code":"S95.7","desc":"Poranenie viacerých krvných ciev v úrovni členka a nohy"},
{"code":"S95.8","desc":"Poranenie inej krvnej cievy v úrovni členka a nohy"},
{"code":"S95.9","desc":"Poranenie bližšie neurčenej krvnej cievy v úrovni členka a nohy"},
{"code":"S96.-","desc":"Poranenie svalu a šľachy v úrovni členka a nohy"},
{"code":"S96.0","desc":"Poranenie svalu a šľachy dlhého ohýbača palca (m. flexor hallucis longus) v úrovni členka a nohy"},
{"code":"S96.1","desc":"Poranenie svalu a šľachy dlhého vystierača palca (m. extensor hallucis longus) v úrovni členka a nohy"},
{"code":"S96.2","desc":"Poranenie krátkych svalov a šliach v úrovni členka a nohy"},
{"code":"S96.7","desc":"Poranenie viacerých svalov a šliach v úrovni členka a nohy"},
{"code":"S96.8","desc":"Poranenie iných svalov a šliach v úrovni členka a nohy"},
{"code":"S96.9","desc":"Poranenie bližšie neurčeného svalu a šľachy v úrovni členka a nohy"},
{"code":"S97.-","desc":"Drvivé poranenie členka a nohy"},
{"code":"S97.0","desc":"Drvivé poranenie členka"},
{"code":"S97.1","desc":"Drvivé poranenie prsta (prstov) nohy"},
{"code":"S97.8","desc":"Drvivé poranenie inej časti členka a nohy"},
{"code":"S98.-","desc":"Úrazová amputácia členka a nohy"},
{"code":"S98.0","desc":"Úrazová amputácia nohy v úrovni členka"},
{"code":"S98.1","desc":"Úrazová amputácia jedného prsta nohy"},
{"code":"S98.2","desc":"Úrazová amputácia dvoch alebo viacerých prstov nohy"},
{"code":"S98.3","desc":"Úrazová amputácia inej časti nohy"},
{"code":"S98.4","desc":"Úrazová amputácia nohy v bližšie neurčenej úrovni"},
{"code":"S99.-","desc":"Iné a bližšie neurčené poranenie členka a nohy"},
{"code":"S99.7","desc":"Viaceré poranenia členka a nohy"},
{"code":"S99.8","desc":"Iné poranenie členka a nohy, bližšie určené"},
{"code":"S99.9","desc":"Poranenie členka a nohy, bližšie neurčené"},
{"code":"T00-T07","desc":"Poranenia postihujúce viaceré oblasti tela"},
{"code":"T00.-","desc":"Povrchové poranenie viacerých oblastí tela"},
{"code":"T00.0","desc":"Povrchové poranenie postihujúce hlavu aj krk"},
{"code":"T00.1","desc":"Povrchové poranenie postihujúce hrudník aj brucho, hrudník aj driek, alebo hrudník aj panvu"},
{"code":"T00.2","desc":"Povrchové poranenie postihujúce viaceré oblasti hornej končatiny (horných končatín)"},
{"code":"T00.3","desc":"Povrchové poranenie postihujúce viaceré oblasti dolnej končatiny (dolných končatín)"},
{"code":"T00.6","desc":"Povrchové poranenie postihujúce viaceré oblasti hornej končatiny (horných končatín) a viaceré oblasti dolnej končatiny (dolných končatín)"},
{"code":"T00.8","desc":"Povrchové poranenie postihujúce iné kombinácie oblastí tela"},
{"code":"T00.9","desc":"Viaceré povrchové poranenia, bližšie neurčené"},
{"code":"T01.-","desc":"Otvorená rana postihujúca viaceré oblasti tela"},
{"code":"T01.0","desc":"Otvorená rana postihujúca hlavu aj krk"},
{"code":"T01.1","desc":"Otvorená rana postihujúca hrudník aj brucho, hrudník aj driek, alebo hrudník aj panvu"},
{"code":"T01.2","desc":"Otvorená rana postihujúca viaceré oblasti hornej končatiny (horných končatín)"},
{"code":"T01.3","desc":"Otvorená rana postihujúca viaceré oblasti dolnej končatiny (dolných končatín)"},
{"code":"T01.6","desc":"Otvorená rana postihujúca viaceré oblasti hornej končatiny (horných končatín) a viaceré oblasti dolnej končatiny (dolných končatín)"},
{"code":"T01.8","desc":"Otvorená rana postihujúca inú kombináciu oblastí tela"},
{"code":"T01.9","desc":"Viaceré otvorené rany, bližšie neurčené"},
{"code":"T02.-","desc":"Zlomeniny postihujúce viaceré oblasti tela"},
{"code":"T02.0-","desc":"Zlomeniny postihujúce hlavu aj krk"},
{"code":"T02.00","desc":"Zlomeniny postihujúce hlavu aj krk, zatvorené alebo bez bližšieho určenia"},
{"code":"T02.01","desc":"Zlomeniny postihujúce hlavu aj krk, otvorené"},
{"code":"T02.1-","desc":"Zlomeniny postihujúce hrudník aj driekovokrížovú oblasť, alebo hrudník aj panvu"},
{"code":"T02.10","desc":"Zlomeniny postihujúce hrudník aj driekovokrížovú oblasť, alebo hrudník aj panvu, zatvorené alebo bez bližšieho určenia"},
{"code":"T02.11","desc":"Zlomeniny postihujúce hrudník aj driekovokrížovú oblasť, alebo hrudník aj panvu, otvorené"},
{"code":"T02.2-","desc":"Zlomeniny postihujúce viaceré oblasti jednej hornej končatiny"},
{"code":"T02.20","desc":"Zlomeniny postihujúce viaceré oblasti jednej hornej končatiny, zatvorené alebo bez bližšieho určenia"},
{"code":"T02.21","desc":"Zlomeniny postihujúce viaceré oblasti jednej hornej končatiny, otvorené"},
{"code":"T02.3-","desc":"Zlomeniny postihujúce viaceré oblasti jednej dolnej končatiny"},
{"code":"T02.30","desc":"Zlomeniny postihujúce viaceré oblasti jednej dolnej končatiny, zatvorené alebo bez bližšieho určenia"},
{"code":"T02.31","desc":"Zlomeniny postihujúce viaceré oblasti jednej dolnej končatiny, otvorené"},
{"code":"T02.4-","desc":"Zlomeniny postihujúce viaceré oblasti obidvoch horných končatín"},
{"code":"T02.40","desc":"Zlomeniny postihujúce viaceré oblasti oboch horných končatín, zatvorené alebo bez bližšieho určenia"},
{"code":"T02.41","desc":"Zlomeniny postihujúce viaceré oblasti oboch horných končatín, otvorené"},
{"code":"T02.5-","desc":"Zlomeniny postihujúce viaceré oblasti oboch dolných končatín"},
{"code":"T02.50","desc":"Zlomeniny viacerých oblastí oboch dolných končatín, zatvorené alebo bez bližšieho určenia"},
{"code":"T02.51","desc":"Zlomeniny viacerých oblastí oboch dolných končatín, otvorené"},
{"code":"T02.6-","desc":"Zlomeniny postihujúce viaceré oblasti hornej končatiny (horných končatín) a viaceré oblasti dolnej končatiny (dolných končatín)"},
{"code":"T02.60","desc":"Zlomeniny postihujúce viaceré oblasti hornej končatiny (horných končatín) a viaceré oblasti dolnej končatiny (dolných končatín), zatvorené alebo bez bližšieho určenia"},
{"code":"T02.61","desc":"Zlomeniny viacerých oblastí hornej končatiny (horných končatín) a viacerých oblastí dolnej končatiny (dolných končatín), otvorené"},
{"code":"T02.7-","desc":"Zlomeniny postihujúce hrudník s driekom a končatinou (končatinami) alebo hrudník s panvou a končatinou (končatinami)"},
{"code":"T02.70","desc":"Zlomeniny postihujúce hrudník s driekom a končatinou (končatinami) alebo hrudník s panvou a končatinou (končatinami), zatvorené alebo bez bližšieho určenia"},
{"code":"T02.71","desc":"Zlomeniny postihujúce hrudník s driekom a končatinou (končatinami) alebo hrudník s panvou a končatinou (končatinami), otvorené"},
{"code":"T02.8-","desc":"Zlomeniny postihujúce iné kombinácie oblastí tela"},
{"code":"T02.80","desc":"Zlomeniny postihujúce iné kombinácie oblastí tela, zatvorené alebo bez bližšieho určenia"},
{"code":"T02.81","desc":"Zlomeniny postihujúce iné kombinácie oblastí tela, otvorené"},
{"code":"T02.9-","desc":"Bližšie neurčené viaceré zlomeniny"},
{"code":"T02.90","desc":"Bližšie neurčené viaceré zlomeniny, zatvorené alebo bez bližšieho určenia"},
{"code":"T02.91","desc":"Bližšie neurčené viaceré zlomeniny, otvorené"},
{"code":"T03.-","desc":"Vykĺbenia, vyvrtnutia a natiahnutia postihujúce viaceré oblasti tela"},
{"code":"T03.0","desc":"Vykĺbenia, vyvrtnutia a natiahnutia postihujúce hlavu aj krk"},
{"code":"T03.1","desc":"Vykĺbenia, vyvrtnutia a natiahnutia postihujúce hrudník aj driek, alebo hrudník aj panvu"},
{"code":"T03.2","desc":"Vykĺbenia, vyvrtnutia a natiahnutia postihujúce viaceré oblasti hornej končatiny (horných končatín)"},
{"code":"T03.3","desc":"Vykĺbenia, vyvrtnutia a natiahnutia postihujúce viaceré oblasti dolnej končatiny (dolných končatín)"},
{"code":"T03.4","desc":"Vykĺbenia, vyvrtnutia a natiahnutia postihujúce viaceré oblasti hornej aj dolnej končatiny (horných aj dolných končatín)"},
{"code":"T03.8","desc":"Vykĺbenia, vyvrtnutia a natiahnutia postihujúce iné kombinácie telesných oblastí"},
{"code":"T03.9","desc":"Viaceré vykĺbenia, vyvrtnutia a natiahnutia, bližšie neurčené"},
{"code":"T04.-","desc":"Drvivé poranenie postihujúce viaceré oblasti tela"},
{"code":"T04.0","desc":"Drvivé poranenie postihujúce hlavu aj krk"},
{"code":"T04.1","desc":"Drvivé poranenie postihujúce hrudník aj brucho, hrudník aj driek, hrudník aj panvu"},
{"code":"T04.2","desc":"Drvivé poranenie postihujúce viaceré oblasti hornej končatiny (horných končatín)"},
{"code":"T04.3","desc":"Drvivé poranenie postihujúce viaceré oblasti dolnej končatiny (dolných končatín)"},
{"code":"T04.4","desc":"Drvivé poranenie postihujúce viaceré oblasti hornej aj dolnej končatiny (horných aj dolných končatín)"},
{"code":"T04.7","desc":"Drvivé poranenia postihujúce hrudník, brucho aj končatinu (končatiny), alebo hrudník, driek aj končatinu (končatiny), alebo hrudník, panvu aj končatinu (končatiny)"},
{"code":"T04.8","desc":"Drvivé poranenia postihujúce iné kombinácie telesných oblastí"},
{"code":"T04.9","desc":"Viaceré drvivé poranenia, bližšie neurčené"},
{"code":"T05.-","desc":"Úrazová amputácia postihujúca viaceré oblasti tela"},
{"code":"T05.0","desc":"Úrazová amputácia oboch rúk"},
{"code":"T05.1","desc":"Úrazová amputácia jednej ruky a druhej hornej končatiny (ktorákoľvek úroveň okrem ruky)"},
{"code":"T05.2","desc":"Úrazová amputácia oboch horných končatín (ktorákoľvek úroveň)"},
{"code":"T05.3","desc":"Úrazová amputácia oboch nôh"},
{"code":"T05.4","desc":"Úrazová amputácia jednej nohy a druhej dolnej končatiny (ktorákoľvek úroveň okrem nohy)"},
{"code":"T05.5","desc":"Úrazová amputácia oboch dolných končatín (ktorákoľvek úroveň)"},
{"code":"T05.6","desc":"Úrazová amputácia hornej aj dolnej končatiny (končatín) v akejkoľvek kombinácii (ktorákoľvek úroveň)"},
{"code":"T05.8","desc":"Úrazová amputácia postihujúca iné kombinácie telesných oblastí"},
{"code":"T05.9","desc":"Viaceré úrazové amputácie, bližšie neurčené"},
{"code":"T06.-","desc":"Iné poranenia postihujúce viaceré oblasti tela, nezatriedené inde"},
{"code":"T06.0","desc":"Poranenie mozgu a hlavových nervov kombinované s poranením nervov a miechy v úrovni krku"},
{"code":"T06.1","desc":"Poranenie nervov a miechy postihujúce iné viaceré telesné oblasti"},
{"code":"T06.2","desc":"Poranenie nervov postihujúce viaceré oblasti tela"},
{"code":"T06.3","desc":"Poranenie krvných ciev postihujúce viaceré oblasti"},
{"code":"T06.4","desc":"Poranenie svalov a šliach postihujúce viaceré oblasti tela"},
{"code":"T06.5","desc":"Poranenie vnútrohrudníkových aj vnútrobrušných orgánov, alebo vnútrohrudníkových aj panvových orgánov"},
{"code":"T06.8","desc":"Iné bližšie určené poranenie postihujúce viaceré oblasti tela"},
{"code":"T07","desc":"Viaceré poranenia, bližšie neurčené"},
{"code":"T08-T14","desc":"Poranenia bližšie neurčenej časti trupu, končatín alebo inej oblasti tela"},
{"code":"T08.-","desc":"Zlomenina chrbtice v bližšie neurčenej úrovni"},
{"code":"T08.0","desc":"Zlomenina chrbtice v bližšie neurčenej úrovni, zatvorená alebo bez bližšieho určenia"},
{"code":"T08.1","desc":"Zlomenina chrbtice v bližšie neurčenej úrovni, otvorená"},
{"code":"T09.-","desc":"Iné poranenia chrbtice a trupu v bližšie neurčenej úrovni"},
{"code":"T09.0-","desc":"Povrchové poranenie trupu v bližšie neurčenej úrovni"},
{"code":"T09.00","desc":"Povrchové poranenie trupu v bližšie neurčenej úrovni, druh poranenia bližšie neurčený"},
{"code":"T09.01","desc":"Povrchové poranenie trupu v bližšie neurčenej úrovni: odrenina"},
{"code":"T09.02","desc":"Povrchové poranenie trupu v bližšie neurčenej úrovni: tvorba pľuzgierov (netermických)"},
{"code":"T09.03","desc":"Povrchové poranenie trupu v bližšie neurčenej úrovni: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"T09.04","desc":"Povrchové poranenie trupu v bližšie neurčenej úrovni: povrchové cudzie teleso (úlomky)"},
{"code":"T09.05","desc":"Povrchové poranenie trupu v bližšie neurčenej úrovni: pomliaždenie"},
{"code":"T09.08","desc":"Povrchové poranenie trupu v bližšie neurčenej úrovni: iné"},
{"code":"T09.1","desc":"Otvorená rana trupu v bližšie neurčenej úrovni"},
{"code":"T09.2","desc":"Vykĺbenie, vyvrtnutie a natiahnutie bližšie neurčeného kĺbu a väzu trupu"},
{"code":"T09.3","desc":"Poranenie miechy v bližšie neurčenej úrovni"},
{"code":"T09.4","desc":"Poranenie bližšie neurčeného nervu, miechového koreňa a nervovej spleti trupu"},
{"code":"T09.5","desc":"Poranenie bližšie neurčeného svalu a šľachy trupu"},
{"code":"T09.6","desc":"Úrazová amputácia trupu v bližšie neurčenej úrovni"},
{"code":"T09.8","desc":"Iné bližšie určené poranenia trupu v bližšie neurčenej úrovni"},
{"code":"T09.9","desc":"Poranenie trupu v bližšie neurčenej úrovni, bližšie neurčené"},
{"code":"T10.-","desc":"Zlomenina hornej končatiny v bližšie neurčenej úrovni"},
{"code":"T10.0","desc":"Zlomenina hornej končatiny v bližšie neurčenej úrovni, zatvorená alebo bez bližšieho určenia"},
{"code":"T10.1","desc":"Zlomenina hornej končatiny v bližšie neurčenej úrovni, otvorená"},
{"code":"T11.-","desc":"Iné poranenie hornej končatiny v bližšie neurčenej úrovni"},
{"code":"T11.0-","desc":"Povrchové poranenie hornej končatiny v bližšie neurčenej úrovni"},
{"code":"T11.00","desc":"Povrchové poranenie hornej končatiny v bližšie neurčenej úrovni, druh poranenia bližšie neurčený"},
{"code":"T11.01","desc":"Povrchové poranenie hornej končatiny v bližšie neurčenej úrovni: odrenina"},
{"code":"T11.02","desc":"Povrchové poranenie hornej končatiny v bližšie neurčenej úrovni: tvorba pľuzgierov (netermických)"},
{"code":"T11.03","desc":"Povrchové poranenie hornej končatiny v bližšie neurčenej úrovni: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"T11.04","desc":"Povrchové poranenie hornej končatiny v bližšie neurčenej úrovni: povrchové cudzie teleso (úlomky)"},
{"code":"T11.05","desc":"Povrchové poranenie hornej končatiny v bližšie neurčenej úrovni: pomliaždenie"},
{"code":"T11.08","desc":"Povrchové poranenie hornej končatiny v bližšie neurčenej úrovni: iné"},
{"code":"T11.1","desc":"Otvorená rana hornej končatiny v bližšie neurčenej úrovni"},
{"code":"T11.2","desc":"Vykĺbenie, vyvrtnutie a natiahnutie bližšie neurčeného kĺbu a väzu hornej končatiny, v bližšie neurčenej úrovni"},
{"code":"T11.3","desc":"Poranenie bližšie neurčeného nervu hornej končatiny, v bližšie neurčenej úrovni"},
{"code":"T11.4","desc":"Poranenie bližšie neurčenej cievy hornej končatiny, v bližšie neurčenej úrovni"},
{"code":"T11.5","desc":"Poranenie bližšie neurčeného svalu a šľachy hornej končatiny, v bližšie neurčenej úrovni"},
{"code":"T11.6","desc":"Úrazová amputácia hornej končatiny, v bližšie neurčenej úrovni"},
{"code":"T11.8","desc":"Iné bližšie určené poranenie hornej končatiny, v bližšie neurčenej úrovni"},
{"code":"T11.9","desc":"Bližšie neurčené poranenie hornej končatiny, v bližšie neurčenej úrovni"},
{"code":"T12.-","desc":"Zlomenina dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T12.0","desc":"Zlomenina dolnej končatiny, v bližšie neurčenej úrovni, zatvorená alebo bez bližšieho určenia"},
{"code":"T12.1","desc":"Zlomenina dolnej končatiny, v bližšie neurčenej úrovni, otvorená"},
{"code":"T13.-","desc":"Iné poranenie dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T13.0-","desc":"Povrchové poranenie dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T13.00","desc":"Povrchové poranenie dolnej končatiny, v bližšie neurčenej úrovni, druh poranenia bližšie neurčený"},
{"code":"T13.01","desc":"Povrchové poranenie dolnej končatiny, v bližšie neurčenej úrovni: odrenina"},
{"code":"T13.02","desc":"Povrchové poranenie dolnej končatiny, v bližšie neurčenej úrovni: tvorba pľuzgierov (netermických)"},
{"code":"T13.03","desc":"Povrchové poranenie dolnej končatiny, v bližšie neurčenej úrovni: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"T13.04","desc":"Povrchové poranenie dolnej končatiny, v bližšie neurčenej úrovni: povrchové cudzie teleso (úlomky)"},
{"code":"T13.05","desc":"Povrchové poranenie dolnej končatiny, v bližšie neurčenej úrovni: pomliaždenie"},
{"code":"T13.08","desc":"Povrchové poranenie dolnej končatiny, v bližšie neurčenej úrovni: iné"},
{"code":"T13.1","desc":"Otvorená rana dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T13.2","desc":"Vykĺbenie, vyvrtnutie a natiahnutie bližšie neurčeného kĺbu a väzu dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T13.3","desc":"Poranenie bližšie neurčeného nervu dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T13.4","desc":"Poranenie bližšie neurčenej krvnej cievy dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T13.5","desc":"Poranenie bližšie neurčeného svalu a šľachy dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T13.6","desc":"Úrazová amputácia dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T13.8","desc":"Iné bližšie určené poranenie dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T13.9","desc":"Bližšie neurčené poranenie dolnej končatiny, v bližšie neurčenej úrovni"},
{"code":"T14.-","desc":"Poranenie: oblasť bližšie neurčená tela"},
{"code":"T14.0-","desc":"Povrchové poranenie: oblasť bližšie neurčená tela"},
{"code":"T14.00","desc":"Povrchové poranenie: oblasť bližšie neurčená tela, druh poranenia bližšie neurčený"},
{"code":"T14.01","desc":"Povrchové poranenie: oblasť bližšie neurčená tela: odrenina"},
{"code":"T14.02","desc":"Povrchové poranenie: oblasť bližšie neurčená tela: tvorba pľuzgierov (netermických)"},
{"code":"T14.03","desc":"Povrchové poranenie: oblasť bližšie neurčená tela: uštipnutie hmyzom alebo vpich (nejedovatý)"},
{"code":"T14.04","desc":"Povrchové poranenie: oblasť bližšie neurčená tela: povrchové cudzie teleso (úlomky)"},
{"code":"T14.05","desc":"Povrchové poranenie: oblasť bližšie neurčená tela: pomliaždenie"},
{"code":"T14.08","desc":"Povrchové poranenie: oblasť bližšie neurčená tela: iné"},
{"code":"T14.1","desc":"Otvorená rana: oblasť bližšie neurčená tela"},
{"code":"T14.2-","desc":"Zlomenina: oblasť bližšie neurčená tela"},
{"code":"T14.20","desc":"Zlomenina: oblasť bližšie neurčená tela, zatvorená alebo bez bližšieho určenia"},
{"code":"T14.21","desc":"Zlomenina: oblasť bližšie neurčená tela, otvorená"},
{"code":"T14.3","desc":"Vykĺbenie, vyvrtnutie a natiahnutie: oblasť bližšie neurčená tela"},
{"code":"T14.4","desc":"Poranenie nervu (nervov): oblasť bližšie neurčená tela"},
{"code":"T14.5","desc":"Poranenie krvnej cievy (ciev): oblasť bližšie neurčená tela"},
{"code":"T14.6","desc":"Poranenie svalov a šliach: oblasť bližšie neurčená tela"},
{"code":"T14.7","desc":"Drvivé poranenie a úrazová amputácia: oblasť bližšie neurčená tela"},
{"code":"T14.8","desc":"Iné poranenie: oblasť bližšie neurčená tela"},
{"code":"T14.9","desc":"Poranenie, bližšie neurčené"},
{"code":"T15-T19","desc":"Účinky cudzieho telesa, ktoré vniklo prirodzeným telesným otvorom"},
{"code":"T15.-","desc":"Cudzie teleso vo vonkajšom oku"},
{"code":"T15.0","desc":"Cudzie teleso v rohovke"},
{"code":"T15.1","desc":"Cudzie teleso v spojovkovom vaku"},
{"code":"T15.8","desc":"Cudzie teleso na inom mieste a na viacerých miestach vonkajšieho oka"},
{"code":"T15.9","desc":"Cudzie teleso vo vonkajšom oku, miesto bližšie neurčené"},
{"code":"T16","desc":"Cudzie teleso v uchu"},
{"code":"T17.-","desc":"Cudzie teleso v dýchacej sústave"},
{"code":"T17.0","desc":"Cudzie teleso v prinosovej dutine"},
{"code":"T17.1","desc":"Cudzie teleso v nozdre"},
{"code":"T17.2","desc":"Cudzie teleso v hltane"},
{"code":"T17.3","desc":"Cudzie teleso v hrtane"},
{"code":"T17.4","desc":"Cudzie teleso v priedušnici"},
{"code":"T17.5","desc":"Cudzie teleso v prieduške"},
{"code":"T17.8","desc":"Cudzie teleso na inom mieste a na viacerých miestach dýchacej sústavy"},
{"code":"T17.9","desc":"Cudzie teleso bez bližšieho určenia miesta dýchacej sústavy"},
{"code":"T18.-","desc":"Cudzie teleso v tráviacej sústave"},
{"code":"T18.0","desc":"Cudzie teleso v ústach"},
{"code":"T18.1","desc":"Cudzie teleso v pažeráku"},
{"code":"T18.2","desc":"Cudzie teleso v žalúdku"},
{"code":"T18.3","desc":"Cudzie teleso v tenkom čreve"},
{"code":"T18.4","desc":"Cudzie teleso v hrubom čreve"},
{"code":"T18.5","desc":"Cudzie teleso v anuse a rekte"},
{"code":"T18.8","desc":"Cudzie teleso na inom mieste a na viacerých miestach tráviacej sústavy"},
{"code":"T18.9","desc":"Cudzie teleso bez bližšieho určenia miesta tráviacej sústavy"},
{"code":"T19.-","desc":"Cudzie teleso v močovopohlavnej sústave"},
{"code":"T19.0","desc":"Cudzie teleso v močovej rúre"},
{"code":"T19.1","desc":"Cudzie teleso v močovom mechúre"},
{"code":"T19.2","desc":"Cudzie teleso vo vulve a pošve"},
{"code":"T19.3","desc":"Cudzie teleso v maternici (ktorejkoľvek časti)"},
{"code":"T19.8","desc":"Cudzie teleso na inom mieste a na viacerých miestach močovopohlavnej sústavy"},
{"code":"T19.9","desc":"Cudzie teleso bez bližšieho určenia miesta močovopohlavnej sústavy"},
{"code":"T20-T32","desc":"Popáleniny alebo poleptania"},
{"code":"T20-T25","desc":"Popáleniny a poleptania povrchu tela s určením miesta postihnutia"},
{"code":"T20.-","desc":"Popálenina a poleptanie hlavy a krku"},
{"code":"T20.0","desc":"Popálenina hlavy a krku bližšie neurčeného stupňa"},
{"code":"T20.1","desc":"Popálenina hlavy a krku 1. stupňa"},
{"code":"T20.2-","desc":"Popálenina hlavy a krku 2. stupňa"},
{"code":"T20.20","desc":"Popálenina hlavy a krku 2a. stupňa"},
{"code":"T20.21","desc":"Popálenina hlavy a krku 2b. stupňa"},
{"code":"T20.3","desc":"Popálenina hlavy a krku 3. stupňa"},
{"code":"T20.4","desc":"Poleptanie hlavy a krku bližšie neurčeného stupňa"},
{"code":"T20.5","desc":"Poleptanie hlavy a krku 1. stupňa"},
{"code":"T20.6-","desc":"Poleptanie hlavy a krku 2. stupňa"},
{"code":"T20.60","desc":"Poleptanie hlavy a krku 2a. stupňa"},
{"code":"T20.61","desc":"Poleptanie hlavy a krku 2b. stupňa"},
{"code":"T20.7","desc":"Poleptanie hlavy a krku 3. stupňa"},
{"code":"T21.-","desc":"Popálenina a poleptanie trupu"},
{"code":"T21.0-","desc":"Popálenina trupu bližšie neurčeného stupňa"},
{"code":"T21.00","desc":"Popálenina trupu bližšie neurčeného stupňa, časť bližšie neurčená"},
{"code":"T21.01","desc":"Popálenina trupu bližšie neurčeného stupňa, prsník"},
{"code":"T21.02","desc":"Popálenina trupu bližšie neurčeného stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.03","desc":"Popálenina trupu bližšie neurčeného stupňa, brušná stena"},
{"code":"T21.04","desc":"Popálenina trupu bližšie neurčeného stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.05","desc":"Popálenina trupu bližšie neurčeného stupňa, vonkajšie genitálie"},
{"code":"T21.09","desc":"Popálenina trupu bližšie neurčeného stupňa, iná časť"},
{"code":"T21.1-","desc":"Popálenina trupu 1. stupňa"},
{"code":"T21.10","desc":"Popálenina trupu 1. stupňa, časť bližšie neurčená"},
{"code":"T21.11","desc":"Popálenina trupu 1. stupňa, prsník"},
{"code":"T21.12","desc":"Popálenina trupu 1. stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.13","desc":"Popálenina trupu 1. stupňa, brušná stena"},
{"code":"T21.14","desc":"Popálenina trupu 1. stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.15","desc":"Popálenina trupu 1. stupňa, vonkajšie genitálie"},
{"code":"T21.19","desc":"Popálenina trupu 1. stupňa, iná časť"},
{"code":"T21.2-","desc":"Popálenina trupu 2a. stupňa"},
{"code":"T21.20","desc":"Popálenina trupu 2a. stupňa, časť bližšie neurčená"},
{"code":"T21.21","desc":"Popálenina trupu 2a. stupňa, prsník"},
{"code":"T21.22","desc":"Popálenina trupu 2a. stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.23","desc":"Popálenina trupu 2a. stupňa, brušná stena"},
{"code":"T21.24","desc":"Popálenina trupu 2a. stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.25","desc":"Popálenina trupu 2a. stupňa, vonkajšie genitálie"},
{"code":"T21.29","desc":"Popálenina trupu 2a. stupňa, iná časť"},
{"code":"T21.3-","desc":"Popálenina trupu 3. stupňa"},
{"code":"T21.30","desc":"Popálenina trupu 3. stupňa, časť bližšie neurčená"},
{"code":"T21.31","desc":"Popálenina trupu 3. stupňa, prsník"},
{"code":"T21.32","desc":"Popálenina trupu 3. stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.33","desc":"Popálenina trupu 3. stupňa, brušná stena"},
{"code":"T21.34","desc":"Popálenina trupu 3. stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.35","desc":"Popálenina trupu 3. stupňa, vonkajšie genitálie"},
{"code":"T21.39","desc":"Popálenina trupu 3. stupňa, iná časť"},
{"code":"T21.4-","desc":"Poleptanie trupu bližšie neurčeného stupňa"},
{"code":"T21.40","desc":"Poleptanie trupu bližšie neurčeného stupňa, časť bližšie neurčená"},
{"code":"T21.41","desc":"Poleptanie trupu bližšie neurčeného stupňa, prsník"},
{"code":"T21.42","desc":"Poleptanie trupu bližšie neurčeného stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.43","desc":"Poleptanie trupu bližšie neurčeného stupňa, brušná stena"},
{"code":"T21.44","desc":"Poleptanie trupu bližšie neurčeného stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.45","desc":"Poleptanie trupu bližšie neurčeného stupňa, vonkajšie genitálie"},
{"code":"T21.49","desc":"Poleptanie trupu bližšie neurčeného stupňa, iná časť"},
{"code":"T21.5-","desc":"Poleptanie trupu 1. stupňa"},
{"code":"T21.50","desc":"Poleptanie trupu 1. stupňa, časť bližšie neurčená"},
{"code":"T21.51","desc":"Poleptanie trupu 1. stupňa, prsník"},
{"code":"T21.52","desc":"Poleptanie trupu 1. stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.53","desc":"Poleptanie trupu 1. stupňa, brušná stena"},
{"code":"T21.54","desc":"Poleptanie trupu 1. stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.55","desc":"Poleptanie trupu 1. stupňa, vonkajšie genitálie"},
{"code":"T21.59","desc":"Poleptanie trupu 1. stupňa, iná časť"},
{"code":"T21.6-","desc":"Poleptanie trupu 2a. stupňa"},
{"code":"T21.60","desc":"Poleptanie trupu 2a. stupňa, časť bližšie neurčená"},
{"code":"T21.61","desc":"Poleptanie trupu 2a. stupňa, prsník"},
{"code":"T21.62","desc":"Poleptanie trupu 2a. stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.63","desc":"Poleptanie trupu 2a. stupňa, brušná stena"},
{"code":"T21.64","desc":"Poleptanie trupu 2a. stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.65","desc":"Poleptanie trupu 2a. stupňa, vonkajšie genitálie"},
{"code":"T21.69","desc":"Poleptanie trupu 2a. stupňa, iná časť"},
{"code":"T21.7-","desc":"Poleptanie trupu 3. stupňa"},
{"code":"T21.70","desc":"Poleptanie trupu 3. stupňa, časť bližšie neurčená"},
{"code":"T21.71","desc":"Poleptanie trupu 3. stupňa, prsník"},
{"code":"T21.72","desc":"Poleptanie trupu 3. stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.73","desc":"Poleptanie trupu 3. stupňa, brušná stena"},
{"code":"T21.74","desc":"Poleptanie trupu 3. stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.75","desc":"Poleptanie trupu 3. stupňa, vonkajšie genitálie"},
{"code":"T21.79","desc":"Poleptanie trupu 3. stupňa, iná časť"},
{"code":"T21.8-","desc":"Popálenina trupu 2b. stupňa"},
{"code":"T21.80","desc":"Popálenina trupu 2b. stupňa, časť bližšie neurčená"},
{"code":"T21.81","desc":"Popálenina trupu 2b. stupňa, prsník"},
{"code":"T21.82","desc":"Popálenina trupu 2b. stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.83","desc":"Popálenina trupu 2b. stupňa, brušná stena"},
{"code":"T21.84","desc":"Popálenina trupu 2b. stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.85","desc":"Popálenina trupu 2b. stupňa, vonkajšie genitálie"},
{"code":"T21.89","desc":"Popálenina trupu 2b. stupňa, iná časť"},
{"code":"T21.9-","desc":"Poleptanie trupu 2b. stupňa"},
{"code":"T21.90","desc":"Poleptanie trupu 2b. stupňa, časť bližšie neurčená"},
{"code":"T21.91","desc":"Poleptanie trupu 2b. stupňa, prsník"},
{"code":"T21.92","desc":"Poleptanie trupu 2b. stupňa, stena hrudníka s výnimkou prsníka a bradavky"},
{"code":"T21.93","desc":"Poleptanie trupu 2b. stupňa, brušná časť"},
{"code":"T21.94","desc":"Poleptanie trupu 2b. stupňa, chrbát (ktorákoľvek časť)"},
{"code":"T21.95","desc":"Poleptanie trupu 2b. stupňa, vonkajšie genitálie"},
{"code":"T21.99","desc":"Poleptanie trupu 2b. stupňa, iná časť"},
{"code":"T22.-","desc":"Popálenina a poleptanie pleca a hornej končatiny okrem zápästia a ruky"},
{"code":"T22.0-","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa"},
{"code":"T22.00","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa, časť bližšie neurčená"},
{"code":"T22.01","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa, lakeť a predlaktie"},
{"code":"T22.02","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.03","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa, podpazušie"},
{"code":"T22.1-","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa"},
{"code":"T22.10","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa, časť bližšie neurčená"},
{"code":"T22.11","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa, lakeť a predlaktie"},
{"code":"T22.12","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.13","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa, podpazušie"},
{"code":"T22.2-","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa"},
{"code":"T22.20","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa, časť bližšie neurčená"},
{"code":"T22.21","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa, lakeť a predlaktie"},
{"code":"T22.22","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.23","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa, podpazušie"},
{"code":"T22.3-","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa"},
{"code":"T22.30","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa, časť bližšie neurčená"},
{"code":"T22.31","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa, lakeť a predlaktie"},
{"code":"T22.32","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.33","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa, podpazušie"},
{"code":"T22.4-","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa"},
{"code":"T22.40","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa, časť bližšie neurčená"},
{"code":"T22.41","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa, lakeť a predlaktie"},
{"code":"T22.42","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.43","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, bližšie neurčeného stupňa, podpazušie"},
{"code":"T22.5-","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa,"},
{"code":"T22.50","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa, časť bližšie neurčená"},
{"code":"T22.51","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa, lakeť a predlaktie"},
{"code":"T22.52","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.53","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 1. stupňa, podpazušie"},
{"code":"T22.6-","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa"},
{"code":"T22.60","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa, časť bližšie neurčená"},
{"code":"T22.61","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa, lakeť a predlaktie"},
{"code":"T22.62","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.63","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2a. stupňa, podpazušie"},
{"code":"T22.7-","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa"},
{"code":"T22.70","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa, časť bližšie neurčená"},
{"code":"T22.71","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa, lakeť a predlaktie"},
{"code":"T22.72","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.73","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 3. stupňa, podpazušie"},
{"code":"T22.8-","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa"},
{"code":"T22.80","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa, časť bližšie neurčená"},
{"code":"T22.81","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa, lakeť a predlaktie"},
{"code":"T22.82","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.83","desc":"Popálenina pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa, podpazušie"},
{"code":"T22.9-","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa"},
{"code":"T22.90","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa, časť bližšie neurčená"},
{"code":"T22.91","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa, lakeť a predlaktie"},
{"code":"T22.92","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa, horná časť ramena a oblasť pleca"},
{"code":"T22.93","desc":"Poleptanie pleca a hornej končatiny okrem zápästia a ruky, 2b. stupňa, podpazušie"},
{"code":"T23.-","desc":"Popálenina a poleptanie zápästia a ruky"},
{"code":"T23.0","desc":"Popálenina zápästia a ruky bližšie neurčeného stupňa"},
{"code":"T23.1","desc":"Popálenina zápästia a ruky 1. stupňa"},
{"code":"T23.2-","desc":"Popálenina zápästia a ruky 2. stupňa"},
{"code":"T23.20","desc":"Popálenina zápästia a ruky 2a. stupňa"},
{"code":"T23.21","desc":"Popálenina zápästia a ruky 2b. stupňa"},
{"code":"T23.3","desc":"Popálenina zápästia a ruky 3. stupňa"},
{"code":"T23.4","desc":"Poleptanie zápästia a ruky bližšie neurčeného stupňa"},
{"code":"T23.5","desc":"Poleptanie zápästia a ruky 1. stupňa"},
{"code":"T23.6-","desc":"Poleptanie zápästia a ruky 2. stupňa"},
{"code":"T23.60","desc":"Poleptanie zápästia a ruky 2a. stupňa"},
{"code":"T23.61","desc":"Poleptanie zápästia a ruky 2b. stupňa"},
{"code":"T23.7","desc":"Poleptanie zápästia a ruky 3. stupňa"},
{"code":"T24.-","desc":"Popálenina a poleptanie bedra a dolnej končatiny okrem členka a nohy"},
{"code":"T24.0","desc":"Popálenina bedra a dolnej končatiny okrem členka a nohy bližšie neurčeného stupňa"},
{"code":"T24.1","desc":"Popálenina bedra a dolnej končatiny okrem členka a nohy 1. stupňa"},
{"code":"T24.2-","desc":"Popálenina bedra a dolnej končatiny okrem členka a nohy 2. stupňa"},
{"code":"T24.20","desc":"Popálenina bedra a dolnej končatiny okrem členka a nohy 2a. stupňa"},
{"code":"T24.21","desc":"Popálenina bedra a dolnej končatiny okrem členka a nohy 2b. stupňa"},
{"code":"T24.3","desc":"Popálenina bedra a dolnej končatiny okrem členka a nohy 3. stupňa"},
{"code":"T24.4","desc":"Poleptanie bedra a dolnej končatiny okrem členka a nohy bližšie neurčeného stupňa"},
{"code":"T24.5","desc":"Poleptanie bedra a dolnej končatiny okrem členka a nohy 1. stupňa"},
{"code":"T24.6-","desc":"Poleptanie bedra a dolnej končatiny okrem členka a nohy 2. stupňa"},
{"code":"T24.60","desc":"Poleptanie bedra a dolnej končatiny okrem členka a nohy 2a. stupňa"},
{"code":"T24.61","desc":"Poleptanie bedra a dolnej končatiny okrem členka a nohy 2b. stupňa"},
{"code":"T24.7","desc":"Poleptanie bedra a dolnej končatiny okrem členka a nohy 3. stupňa"},
{"code":"T25.-","desc":"Popálenina a poleptanie členka a nohy"},
{"code":"T25.0","desc":"Popálenina členka a nohy bližšie neurčeného stupňa"},
{"code":"T25.1","desc":"Popálenina členka a nohy 1. stupňa"},
{"code":"T25.2-","desc":"Popálenina členka a nohy 2. stupňa"},
{"code":"T25.20","desc":"Popálenina členka a nohy 2a. stupňa"},
{"code":"T25.21","desc":"Popálenina členka a nohy 2b. stupňa"},
{"code":"T25.3","desc":"Popálenina členka a nohy 3. stupňa"},
{"code":"T25.4","desc":"Poleptanie členka a nohy bližšie neurčeného stupňa"},
{"code":"T25.5","desc":"Poleptanie členka a nohy 1. stupňa"},
{"code":"T25.6-","desc":"Poleptanie členka a nohy 2. stupňa"},
{"code":"T25.60","desc":"Poleptanie členka a nohy 2a. stupňa"},
{"code":"T25.61","desc":"Poleptanie členka a nohy 2b. stupňa"},
{"code":"T25.7","desc":"Poleptanie členka a nohy 3. stupňa"},
{"code":"T26-T28","desc":"Popáleniny a poleptania obmedzené na oko a vnútorné orgány"},
{"code":"T26.-","desc":"Popálenina a poleptanie obmedzené na oko a očné adnexy"},
{"code":"T26.0","desc":"Popálenina mihalnice a okolia oka"},
{"code":"T26.1","desc":"Popálenina rohovky a spojovkového vaku"},
{"code":"T26.2","desc":"Popálenina s následnou ruptúrou a deštrukciou očnej gule"},
{"code":"T26.3","desc":"Popálenina iných častí oka a očných adnexov"},
{"code":"T26.4","desc":"Popálenina bližšie neurčenej časti oka a očných adnexov"},
{"code":"T26.5","desc":"Poleptanie mihalnice a okolia oka"},
{"code":"T26.6","desc":"Poleptanie rohovky a spojovkového vaku"},
{"code":"T26.7","desc":"Poleptanie s následnou ruptúrou a deštrukciou očnej gule"},
{"code":"T26.8","desc":"Poleptanie iných častí oka a očných adnexov"},
{"code":"T26.9","desc":"Poleptanie bližšie neurčenej časti oka a očných adnexov"},
{"code":"T27.-","desc":"Popálenina a poleptanie dýchacích orgánov"},
{"code":"T27.0","desc":"Popálenina hrtana a priedušnice"},
{"code":"T27.1","desc":"Popálenina hrtana a priedušnice s pľúcami"},
{"code":"T27.2","desc":"Popálenina iných častí dýchacích ciest"},
{"code":"T27.3","desc":"Popálenina bližšie neurčenej časti dýchacích ciest"},
{"code":"T27.4","desc":"Poleptanie hrtana a priedušnice"},
{"code":"T27.5","desc":"Poleptanie hrtana a priedušnice s pľúcami"},
{"code":"T27.6","desc":"Poleptanie iných častí dýchacích ciest"},
{"code":"T27.7","desc":"Poleptanie bližšie neurčenej časti dýchacích ciest"},
{"code":"T28.-","desc":"Popálenina a poleptanie iných vnútorných orgánov"},
{"code":"T28.0","desc":"Popálenina úst a hltana"},
{"code":"T28.1","desc":"Popálenina pažeráka"},
{"code":"T28.2","desc":"Popálenina iných častí tráviacej sústavy"},
{"code":"T28.3","desc":"Popálenina vnútorných močovopohlavných orgánov"},
{"code":"T28.4","desc":"Popálenina iných a bližšie neurčených vnútorných orgánov"},
{"code":"T28.5","desc":"Poleptanie úst a hltana"},
{"code":"T28.6","desc":"Poleptanie pažeráka"},
{"code":"T28.7","desc":"Poleptanie iných častí tráviacej sústavy"},
{"code":"T28.8","desc":"Poleptanie vnútorných močovopohlavných orgánov"},
{"code":"T28.9","desc":"Poleptanie iných a bližšie neurčených vnútorných orgánov"},
{"code":"T29-T32","desc":"Popáleniny a poleptania viacerých a bližšie neurčených častí tela"},
{"code":"T29.-","desc":"Popálenina a poleptania viacerých častí tela"},
{"code":"T29.0","desc":"Popálenina viacerých oblastí tela bližšie neurčeného stupňa"},
{"code":"T29.1","desc":"Popálenina viacerých oblastí tela, kde sa ako najťažší uvádza 1.stupeň"},
{"code":"T29.2-","desc":"Popálenina viacerých oblastí tela, kde sa ako najťažší uvádza 2.stupeň"},
{"code":"T29.20","desc":"Popálenina viacerých oblastí tela, kde sa ako najťažší uvádza 2a.stupeň"},
{"code":"T29.21","desc":"Popálenina viacerých oblastí tela, kde sa ako najťažší uvádza 2b. stupeň"},
{"code":"T29.3","desc":"Popálenina viacerých oblastí tela, uvedený najmenej raz 3.stupeň"},
{"code":"T29.4","desc":"Poleptanie viacerých častí tela bližšie neurčeného stupňa"},
{"code":"T29.5","desc":"Poleptanie viacerých častí tela, kde sa ako najťažší uvádza 1.stupeň"},
{"code":"T29.6-","desc":"Poleptanie viacerých častí tela, kde sa ako najťažší uvádza 2.stupeň"},
{"code":"T29.60","desc":"Poleptanie viacerých častí tela, kde sa ako najťažší uvádza 2a.stupeň"},
{"code":"T29.61","desc":"Poleptanie viacerých častí tela, kde sa ako najťažší uvádza 2b. stupeň"},
{"code":"T29.7","desc":"Poleptanie viacerých častí tela, uvedený najmenej raz 3.stupeň"},
{"code":"T30.-","desc":"Popálenina a poleptanie: oblasť bližšie neurčená tela"},
{"code":"T30.0","desc":"Popálenina: oblasť bližšie neurčená tela bližšie neurčeného stupňa"},
{"code":"T30.1","desc":"Popálenina: oblasť bližšie neurčená tela 1. stupňa"},
{"code":"T30.2-","desc":"Popálenina: oblasť bližšie neurčená tela 2. stupňa"},
{"code":"T30.20","desc":"Popálenina: oblasť bližšie neurčená tela 2a. stupňa"},
{"code":"T30.21","desc":"Popálenina: oblasť bližšie neurčená tela 2. stupňa"},
{"code":"T30.3","desc":"Popálenina: oblasť bližšie neurčená tela 3. stupňa"},
{"code":"T30.4","desc":"Poleptanie: oblasť bližšie neurčená tela bližšie neurčeného stupňa"},
{"code":"T30.5","desc":"Poleptanie: oblasť bližšie neurčená tela 1. stupňa"},
{"code":"T30.6-","desc":"Poleptanie: oblasť bližšie neurčená tela 2. stupňa"},
{"code":"T30.60","desc":"Poleptanie: oblasť bližšie neurčená tela 2a. stupňa"},
{"code":"T30.61","desc":"Poleptanie: oblasť bližšie neurčená tela 2b. stupňa"},
{"code":"T30.7","desc":"Poleptanie: oblasť bližšie neurčená tela 3. stupňa"},
{"code":"T31.-!","desc":"Popáleniny zatriedené podľa rozsahu postihnutého povrchu tela"},
{"code":"T31.0-!","desc":"Popáleniny postihujúce menej ako 10 % povrchu tela"},
{"code":"T31.00!","desc":"Popáleniny postihujúce menej ako 10 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel popáleniny 3. stupňa"},
{"code":"T31.1-!","desc":"Popáleniny postihujúce 10 – 19 % povrchu tela"},
{"code":"T31.10!","desc":"Popáleniny postihujúce 10 – 19 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel popáleniny 3. stupňa"},
{"code":"T31.11!","desc":"Popáleniny postihujúce 10 – 19 % povrchu tela: 10 – 19 % popáleniny 3. stupňa"},
{"code":"T31.2-!","desc":"Popáleniny postihujúce 20 – 29 % povrchu tela"},
{"code":"T31.20!","desc":"Popáleniny postihujúce 20 – 29 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel popáleniny 3. stupňa"},
{"code":"T31.21!","desc":"Popáleniny postihujúce 20 – 29 % povrchu tela: 10 – 19 % popáleniny 3. stupňa"},
{"code":"T31.22!","desc":"Popáleniny postihujúce 20 – 29 % povrchu tela: 20 – 29 % popáleniny 3. stupňa"},
{"code":"T31.3-!","desc":"Popáleniny postihujúce 30 – 39 % povrchu tela"},
{"code":"T31.30!","desc":"Popáleniny postihujúce 30 – 39 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel popáleniny 3. stupňa"},
{"code":"T31.31!","desc":"Popáleniny postihujúce 30 – 39 % povrchu tela: 10 – 19 % popáleniny 3. stupňa"},
{"code":"T31.32!","desc":"Popáleniny postihujúce 30 – 39 % povrchu tela: 20 – 29 % popáleniny 3. stupňa"},
{"code":"T31.33!","desc":"Popáleniny postihujúce 30 – 39 % povrchu tela: 30 – 39 % popáleniny 3. stupňa"},
{"code":"T31.4-!","desc":"Popáleniny postihujúce 40 – 49 % povrchu tela"},
{"code":"T31.40!","desc":"Popáleniny postihujúce 40 – 49 % povrchu tela: pričom popáleniny 3. stupňa postihujú menej ako 10 % alebo bližšie neurčenú časť"},
{"code":"T31.41!","desc":"Popáleniny postihujúce 40 – 49 % povrchu tela: 10 – 19 % popáleniny 3. stupňa"},
{"code":"T31.42!","desc":"Popáleniny postihujúce 40 – 49 % povrchu tela: 20 – 29 % popáleniny 3. stupňa"},
{"code":"T31.43!","desc":"Popáleniny postihujúce 40 – 49 % povrchu tela: 30 – 39 % popáleniny 3. stupňa"},
{"code":"T31.44!","desc":"Popáleniny postihujúce 40 – 49 % povrchu tela: 40 – 49 % popáleniny 3. stupňa"},
{"code":"T31.5-!","desc":"Popáleniny postihujúce 50 – 59 % povrchu tela"},
{"code":"T31.50!","desc":"Popáleniny postihujúce 50 – 59 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel popáleniny 3. stupňa"},
{"code":"T31.51!","desc":"Popáleniny postihujúce 50 – 59 % povrchu tela: 10 – 19 % popáleniny 3. stupňa"},
{"code":"T31.52!","desc":"Popáleniny postihujúce 50 – 59 % povrchu tela: 20 – 29 % popáleniny 3. stupňa"},
{"code":"T31.53!","desc":"Popáleniny postihujúce 50 – 59 % povrchu tela: 30 – 39 % popáleniny 3. stupňa"},
{"code":"T31.54!","desc":"Popáleniny postihujúce 50 – 59 % povrchu tela: 40 – 49 % popáleniny 3. stupňa"},
{"code":"T31.55!","desc":"Popáleniny postihujúce 50 – 59 % povrchu tela: 50 – 59 % popáleniny 3. stupňa"},
{"code":"T31.6-!","desc":"Popáleniny postihujúce 60 – 69 % povrchu tela"},
{"code":"T31.60!","desc":"Popáleniny postihujúce 60 – 69 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel popáleniny 3. stupňa"},
{"code":"T31.61!","desc":"Popáleniny postihujúce 60 – 69 % povrchu tela: 10 – 19 % popáleniny 3. stupňa"},
{"code":"T31.62!","desc":"Popáleniny postihujúce 60 – 69 % povrchu tela: 20 – 29 % popáleniny 3. stupňa"},
{"code":"T31.63!","desc":"Popáleniny postihujúce 60 – 69 % povrchu tela: 30 – 39 % popáleniny 3. stupňa"},
{"code":"T31.64!","desc":"Popáleniny postihujúce 60 – 69 % povrchu tela: 40 – 49 % popáleniny 3. stupňa"},
{"code":"T31.65!","desc":"Popáleniny postihujúce 60 – 69 % povrchu tela: 50 – 59 % popáleniny 3. stupňa"},
{"code":"T31.66!","desc":"Popáleniny postihujúce 60 – 69 % povrchu tela: 60 – 69 % popáleniny 3. stupňa"},
{"code":"T31.7-!","desc":"Popáleniny postihujúce 70 – 79 % povrchu tela"},
{"code":"T31.70!","desc":"Popáleniny postihujúce 70 – 79 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel popáleniny 3. stupňa"},
{"code":"T31.71!","desc":"Popáleniny postihujúce 70 – 79 % povrchu tela: 10 – 19 % popáleniny 3. stupňa"},
{"code":"T31.72!","desc":"Popáleniny postihujúce 70 – 79 % povrchu tela: 20 – 29 % popáleniny 3. stupňa"},
{"code":"T31.73!","desc":"Popáleniny postihujúce 70 – 79 % povrchu tela: 30 – 39 % popáleniny 3. stupňa"},
{"code":"T31.74!","desc":"Popáleniny postihujúce 70 – 79 % povrchu tela: 40 – 49 % popáleniny 3. stupňa"},
{"code":"T31.75!","desc":"Popáleniny postihujúce 70 – 79 % povrchu tela: 50 – 59 % popáleniny 3. stupňa"},
{"code":"T31.76!","desc":"Popáleniny postihujúce 70 – 79 % povrchu tela: 60 – 69 % popáleniny 3. stupňa"},
{"code":"T31.77!","desc":"Popáleniny postihujúce 70 – 79 % povrchu tela: 70 – 79 % popáleniny 3. stupňa"},
{"code":"T31.8-!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela"},
{"code":"T31.80!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel popáleniny 3. stupňa"},
{"code":"T31.81!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela: 10 – 19 % popáleniny 3. stupňa"},
{"code":"T31.82!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela: 20 – 29 % popáleniny 3. stupňa"},
{"code":"T31.83!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela: 30 – 39 % popáleniny 3. stupňa"},
{"code":"T31.84!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela: 40 – 49 % popáleniny 3. stupňa"},
{"code":"T31.85!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela: 50 – 59 % popáleniny 3. stupňa"},
{"code":"T31.86!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela: 60 – 69 % popáleniny 3. stupňa"},
{"code":"T31.87!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela: 70 – 79 % popáleniny 3. stupňa"},
{"code":"T31.88!","desc":"Popáleniny postihujúce 80 – 89 % povrchu tela: 80 – 89 % popáleniny 3. stupňa"},
{"code":"T31.9-!","desc":"Popáleniny postihujúce 90 % alebo viac % povrchu tela"},
{"code":"T31.90!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel popáleniny 3. stupňa"},
{"code":"T31.91!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: 10 – 19 % popáleniny 3. stupňa"},
{"code":"T31.92!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: 20 – 29 % popáleniny 3. stupňa"},
{"code":"T31.93!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: 30 – 39 % popáleniny 3. stupňa"},
{"code":"T31.94!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: 40 – 49 % popáleniny 3. stupňa"},
{"code":"T31.95!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: 50 – 59 % popáleniny 3. stupňa"},
{"code":"T31.96!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: 60 – 69 % popáleniny 3. stupňa"},
{"code":"T31.97!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: 70 – 79 % popáleniny 3. stupňa"},
{"code":"T31.98!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: 80 – 89 % popáleniny 3. stupňa"},
{"code":"T31.99!","desc":"Popáleniny postihujúce 90 % a viac povrchu tela: 90 % alebo viac popáleniny 3. stupňa"},
{"code":"T32.-!","desc":"Poleptania zatriedené podľa rozsahu postihnutého povrchu tela"},
{"code":"T32.0-!","desc":"Poleptania postihujúce menej ako 10 % povrchu tela"},
{"code":"T32.00!","desc":"Poleptania postihujúce menej ako 10 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.1-!","desc":"Poleptania postihujúce 10 – 19 % povrchu tela"},
{"code":"T32.10!","desc":"Poleptania postihujúce 10 – 19 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.11!","desc":"Poleptania postihujúce 10 – 19 % povrchu tela: 10 – 19 % poleptania 3. stupňa"},
{"code":"T32.2-!","desc":"Poleptania postihujúce 20 – 29 % povrchu tela"},
{"code":"T32.20!","desc":"Poleptania postihujúce 20 – 29 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.21!","desc":"Poleptania postihujúce 20 – 29 % povrchu tela: 10 – 19 % poleptania 3. stupňa"},
{"code":"T32.22!","desc":"Poleptania postihujúce 20 – 29 % povrchu tela: 20 – 29 % poleptania 3. stupňa"},
{"code":"T32.3-!","desc":"Poleptania postihujúce 30 – 39 % povrchu tela"},
{"code":"T32.30!","desc":"Poleptania postihujúce 30 – 39 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.31!","desc":"Poleptania postihujúce 30 – 39 % povrchu tela: 10 – 19 % poleptania 3. stupňa"},
{"code":"T32.32!","desc":"Poleptania postihujúce 30 – 39 % povrchu tela: 20 – 29 % poleptania 3. stupňa"},
{"code":"T32.33!","desc":"Poleptania postihujúce 30 – 39 % povrchu tela: 30 – 39 % poleptania 3. stupňa"},
{"code":"T32.4-!","desc":"Poleptania postihujúce 40 – 49 % povrchu tela"},
{"code":"T32.40!","desc":"Poleptania postihujúce 40 – 49 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.41!","desc":"Poleptania postihujúce 40 – 49 % povrchu tela: 10 – 19 % poleptania 3. stupňa"},
{"code":"T32.42!","desc":"Poleptania postihujúce 40 – 49 % povrchu tela: 20 – 29 % poleptania 3. stupňa"},
{"code":"T32.43!","desc":"Poleptania postihujúce 40 – 49 % povrchu tela: 30 – 39 % poleptania 3. stupňa"},
{"code":"T32.44!","desc":"Poleptania postihujúce 40 – 49 % povrchu tela: 40 – 49 % poleptania 3. stupňa"},
{"code":"T32.5-!","desc":"Poleptania postihujúce 50 – 59 % povrchu tela"},
{"code":"T32.50!","desc":"Poleptania postihujúce 50 – 59 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.51!","desc":"Poleptania postihujúce 50 – 59 % povrchu tela: 10 – 19 % poleptania 3. stupňa"},
{"code":"T32.52!","desc":"Poleptania postihujúce 50 – 59 % povrchu tela: 20 – 29 % poleptania 3. stupňa"},
{"code":"T32.53!","desc":"Poleptania postihujúce 50 – 59 % povrchu tela: 30 – 39 % poleptania 3. stupňa"},
{"code":"T32.54!","desc":"Poleptania postihujúce 50 – 59 % povrchu tela: 40 – 49 % poleptania 3. stupňa"},
{"code":"T32.55!","desc":"Poleptania postihujúce 50 – 59 % povrchu tela: 50 – 59 % poleptania 3. stupňa"},
{"code":"T32.6-!","desc":"Poleptania postihujúce 60 – 69 % povrchu tela"},
{"code":"T32.60!","desc":"Poleptania postihujúce 60 – 69 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.61!","desc":"Poleptania postihujúce 60 – 69 % povrchu tela: 10 – 19 % poleptania 3. stupňa"},
{"code":"T32.62!","desc":"Poleptania postihujúce 60 – 69 % povrchu tela: 20 – 29 % poleptania 3. stupňa"},
{"code":"T32.63!","desc":"Poleptania postihujúce 60 – 69 % povrchu tela: 30 – 39 % poleptania 3. stupňa"},
{"code":"T32.64!","desc":"Poleptania postihujúce 60 – 69 % povrchu tela: 40 – 49 % poleptania 3. stupňa"},
{"code":"T32.65!","desc":"Poleptania postihujúce 60 – 69 % povrchu tela: 50 – 59 % poleptania 3. stupňa"},
{"code":"T32.66!","desc":"Poleptania postihujúce 60 – 69 % povrchu tela: 60 – 69 % poleptania 3. stupňa"},
{"code":"T32.7-!","desc":"Poleptania postihujúce 70 – 79 % povrchu tela"},
{"code":"T32.70!","desc":"Poleptania postihujúce 70 – 79 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.71!","desc":"Poleptania postihujúce 70 – 79 % povrchu tela: 10 – 19 % poleptania 3. stupňa"},
{"code":"T32.72!","desc":"Poleptania postihujúce 70 – 79 % povrchu tela: 20 – 29 % poleptania 3. stupňa"},
{"code":"T32.73!","desc":"Poleptania postihujúce 70 – 79 % povrchu tela: 30 – 39 % poleptania 3. stupňa"},
{"code":"T32.74!","desc":"Poleptania postihujúce 70 – 79 % povrchu tela: 40 – 49 % poleptania 3. stupňa"},
{"code":"T32.75!","desc":"Poleptania postihujúce 70 – 79 % povrchu tela: 50 – 59 % poleptania 3. stupňa"},
{"code":"T32.76!","desc":"Poleptania postihujúce 70 – 79 % povrchu tela: 60 – 69 % poleptania 3. stupňa"},
{"code":"T32.77!","desc":"Poleptania postihujúce 70 – 79 % povrchu tela: 70 – 79 % poleptania 3. stupňa"},
{"code":"T32.8-!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela"},
{"code":"T32.80!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.81!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela: 10 – 19 % poleptania 3. stupňa"},
{"code":"T32.82!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela: 20 – 29 % poleptania 3. stupňa"},
{"code":"T32.83!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela: 30 – 39 % poleptania 3. stupňa"},
{"code":"T32.84!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela: 40 – 49 % poleptania 3. stupňa"},
{"code":"T32.85!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela: 50 – 59 % poleptania 3. stupňa"},
{"code":"T32.86!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela: 60 – 69 % poleptania 3. stupňa"},
{"code":"T32.87!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela: 70 – 79 % poleptania 3. stupňa"},
{"code":"T32.88!","desc":"Poleptania postihujúce 80 – 89 % povrchu tela: 80 – 89 % poleptania 3. stupňa"},
{"code":"T32.9-!","desc":"Poleptania postihujúce 90 % povrchu tela a viac"},
{"code":"T32.90!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: menej ako 10 %, alebo bližšie neurčený podiel poleptania 3. stupňa"},
{"code":"T32.91!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: 10 – 19 % poleptania 3. stupňa"},
{"code":"T32.92!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: 20 – 29 % poleptania 3. stupňa"},
{"code":"T32.93!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: 30 – 39 % poleptania 3. stupňa"},
{"code":"T32.94!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: 40 – 49 % poleptania 3. stupňa"},
{"code":"T32.95!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: 50 – 59 % poleptania 3. stupňa"},
{"code":"T32.96!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: 60 – 69 % poleptania 3. stupňa"},
{"code":"T32.97!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: 70 – 79 % poleptania 3. stupňa"},
{"code":"T32.98!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: 80 – 89 % poleptania 3. stupňa"},
{"code":"T32.99!","desc":"Poleptania postihujúce 90 % a viac povrchu tela: 90% a viac poleptania 3. stupňa"},
{"code":"T33-T35","desc":"Omrzliny"},
{"code":"T33.-","desc":"Povrchová omrzlina"},
{"code":"T33.0","desc":"Povrchová omrzlina hlavy"},
{"code":"T33.1","desc":"Povrchová omrzlina krku"},
{"code":"T33.2","desc":"Povrchová omrzlina hrudníka"},
{"code":"T33.3","desc":"Povrchová omrzlina brušnej steny, drieku a panvy"},
{"code":"T33.4","desc":"Povrchová omrzlina hornej končatiny"},
{"code":"T33.5","desc":"Povrchová omrzlina zápästia a ruky"},
{"code":"T33.6","desc":"Povrchová omrzlina bedra a stehna"},
{"code":"T33.7","desc":"Povrchová omrzlina kolena a predkolenia"},
{"code":"T33.8","desc":"Povrchová omrzlina členka a nohy"},
{"code":"T33.9","desc":"Povrchová omrzlina na iných a bližšie neurčených miestach"},
{"code":"T34.-","desc":"Omrzlina s nekrózou tkaniva"},
{"code":"T34.0","desc":"Omrzlina s nekrózou tkaniva hlavy"},
{"code":"T34.1","desc":"Omrzlina s nekrózou tkaniva krku"},
{"code":"T34.2","desc":"Omrzlina s nekrózou tkaniva hrudníka"},
{"code":"T34.3","desc":"Omrzlina s nekrózou tkaniva brušnej steny, drieku a panvy"},
{"code":"T34.4","desc":"Omrzlina s nekrózou tkaniva hornej končatiny"},
{"code":"T34.5","desc":"Omrzlina s nekrózou tkaniva zápästia a ruky"},
{"code":"T34.6","desc":"Omrzlina s nekrózou tkaniva bedra a stehna"},
{"code":"T34.7","desc":"Omrzlina s nekrózou tkaniva kolena a predkolenia"},
{"code":"T34.8","desc":"Omrzlina s nekrózou tkaniva členka a nohy"},
{"code":"T34.9","desc":"Omrzlina s nekrózou tkaniva na iných a bližšie neurčených miestach"},
{"code":"T35.-","desc":"Omrzlina postihujúca viaceré časti tela a bližšie neurčená omrzlina"},
{"code":"T35.0","desc":"Povrchová omrzlina postihujúca viaceré časti tela"},
{"code":"T35.1","desc":"Omrzlina s tkanivovou nekrózou postihujúca viaceré časti tela"},
{"code":"T35.2","desc":"Omrzlina hlavy a krku, bližšie neurčená"},
{"code":"T35.3","desc":"Omrzlina hrudníka, brucha, drieku a panvy, bližšie neurčená"},
{"code":"T35.4","desc":"Omrzlina hornej končatiny, bližšie neurčená"},
{"code":"T35.5","desc":"Omrzlina dolnej končatiny, bližšie neurčená"},
{"code":"T35.6","desc":"Omrzlina postihujúca viaceré časti tela, bližšie neurčená"},
{"code":"T35.7","desc":"Omrzlina bližšie neurčenej časti tela, bližšie neurčená"},
{"code":"T36-T50","desc":"Otravy liekmi, liečivami a biologickými látkami"},
{"code":"T36.-","desc":"Otrava celkovo účinkujúcimi antibiotikami"},
{"code":"T36.0","desc":"Otrava penicilínmi"},
{"code":"T36.1","desc":"Otrava cefalosporínmi a inými betalaktámovými antibiotikami"},
{"code":"T36.2","desc":"Otrava liekmi zo skupiny chloramfenikolu"},
{"code":"T36.3","desc":"Otrava makrolidmi"},
{"code":"T36.4","desc":"Otrava tetracyklínmi"},
{"code":"T36.5","desc":"Otrava aminoglykozidmi"},
{"code":"T36.6","desc":"Otrava rifamycínmi"},
{"code":"T36.7","desc":"Otrava protiplesňovými antibiotikami používanými systémovo"},
{"code":"T36.8","desc":"Otrava inými celkovo účinkujúcimi antibiotikami"},
{"code":"T36.9","desc":"Otrava celkovo účinkujúcimi antibiotikami, bližšie neurčenými"},
{"code":"T37.-","desc":"Otrava inými systémovo účinkujúcimi protiinfekčnými a antiparazitovými prostriedkami"},
{"code":"T37.0","desc":"Otrava sulfonamidmi"},
{"code":"T37.1","desc":"Otrava antimykobaktériovými liekmi"},
{"code":"T37.2","desc":"Otrava antimalarikami a liekmi účinkujúcimi na iné krvné prvoky (protozá)"},
{"code":"T37.3","desc":"Otrava inými antiprotozoárnymi liekmi"},
{"code":"T37.4","desc":"Otrava antihelmintikami"},
{"code":"T37.5","desc":"Otrava protivírusovými liekmi"},
{"code":"T37.8","desc":"Otrava inými bližšie určenými celkovo účinkujúcimi protiinfekčnými a antiparazitovými liekmi"},
{"code":"T37.9","desc":"Otrava celkovo účinkujúcimi protiinfekčnými a antiparazitovými liekmi, bližšie neurčenými"},
{"code":"T38.-","desc":"Otrava hormónmi, ich syntetickými náhradami a antagonistami, nezatriedená inde"},
{"code":"T38.0","desc":"Otrava glukokortikoidmi a ich syntetickými analógmi"},
{"code":"T38.1","desc":"Otrava hormónmi štítnej žľazy a ich náhradami"},
{"code":"T38.2","desc":"Otrava tyreostatikami"},
{"code":"T38.3","desc":"Otrava inzulínom a orálnymi antidiabetikami"},
{"code":"T38.4","desc":"Otrava orálnymi kontraceptívami"},
{"code":"T38.5","desc":"Otrava inými estrogénmi a progesterónmi"},
{"code":"T38.6","desc":"Otrava antigonadotropínmi, antiestrogénmi, antiandrogénmi, nezatriedenými inde"},
{"code":"T38.7","desc":"Otrava androgénmi a príbuznými anabolikami"},
{"code":"T38.8","desc":"Otrava inými a bližšie neurčenými hormónmi a ich syntetickými náhradami"},
{"code":"T38.9","desc":"Otrava inými a bližšie neurčenými antagonistami hormónov"},
{"code":"T39.-","desc":"Otrava neopioidovými analgetikami, antipyretikami a antireumatikami"},
{"code":"T39.0","desc":"Otrava salicylátmi"},
{"code":"T39.1","desc":"Otrava derivátmi 4-aminofenolu"},
{"code":"T39.2","desc":"Otrava pyrazolónovými derivátmi"},
{"code":"T39.3","desc":"Otrava inými nesteroidovými antiflogistikami"},
{"code":"T39.4","desc":"Otrava antireumatikami, nezatriedenými inde"},
{"code":"T39.8","desc":"Otrava inými neopioidovými analgetikami a antipyretikami, nezatriedenými inde"},
{"code":"T39.9","desc":"Otrava neopioidovými analgetikami, antipyretikami a antireumatikami, bližšie neurčenými"},
{"code":"T40.-","desc":"Otrava opiátmi a psychodysleptikami [halucinogénmi]"},
{"code":"T40.0","desc":"Otrava ópiom"},
{"code":"T40.1","desc":"Otrava heroínom"},
{"code":"T40.2","desc":"Otrava inými opioidmi"},
{"code":"T40.3","desc":"Otrava metadónom"},
{"code":"T40.4","desc":"Otrava inými syntetickými opiátmi"},
{"code":"T40.5","desc":"Otrava kokaínom"},
{"code":"T40.6","desc":"Otrava inými a bližšie neurčenými opiátmi"},
{"code":"T40.7","desc":"Otrava derivátmi cannabis"},
{"code":"T40.8","desc":"Otrava lysergidom [LSD]"},
{"code":"T40.9","desc":"Otrava inými a bližšie neurčenými psychodysleptikami [halucinogénmi]"},
{"code":"T41.-","desc":"Otrava anestetikami a liečebnými plynmi"},
{"code":"T41.0","desc":"Otrava inhalačnými anestetikami"},
{"code":"T41.1","desc":"Otrava intravenóznymi anestetikami"},
{"code":"T41.2","desc":"Otrava inými a bližšie neurčenými celkovými anestetikami"},
{"code":"T41.3","desc":"Otrava lokálnymi anestetikami"},
{"code":"T41.4","desc":"Otrava anestetikami, bližšie neurčenými"},
{"code":"T41.5","desc":"Otrava liečebnými plynmi"},
{"code":"T42.-","desc":"Otrava antiepileptikami, sedatívami, hypnotikami a antiparkinsonikami"},
{"code":"T42.0","desc":"Otrava derivátmi hydantoínu"},
{"code":"T42.1","desc":"Otrava iminostilbénmi"},
{"code":"T42.2","desc":"Otrava sukcinimidmi a oxazolidínmi"},
{"code":"T42.3","desc":"Otrava barbiturátmi"},
{"code":"T42.4","desc":"Otrava benzodiazepínmi"},
{"code":"T42.5","desc":"Otrava zmiešanými antiepileptikami, nezatriedenými inde"},
{"code":"T42.6","desc":"Otrava inými antiepileptikami, sedatívami a hypnotikami"},
{"code":"T42.7","desc":"Otrava antiepileptikami, sedatívami a hypnotikami, bližšie neurčenými"},
{"code":"T42.8","desc":"Otrava antiparkinsonikami a inými centrálnymi myorelaxanciami"},
{"code":"T43.-","desc":"Otrava psychotropnými látkami, nezatriedenými inde"},
{"code":"T43.0","desc":"Otrava tricyklickými a tetracyklickými antidepresívami"},
{"code":"T43.1","desc":"Otrava antidepresívami skupiny inhibítorov monoaminooxidázy"},
{"code":"T43.2","desc":"Otrava inými a bližšie neurčenými antidepresívami"},
{"code":"T43.3","desc":"Otrava fenotiazínovými antipsychotikami a neuroleptikami"},
{"code":"T43.4","desc":"Otrava butyrofenónovými a tioxanténovými neuroleptikami"},
{"code":"T43.5","desc":"Otrava inými a bližšie neurčenými antipsychotikami a neuroleptikami"},
{"code":"T43.6","desc":"Otrava psychostimulanciami s nebezpečenstvom návyku"},
{"code":"T43.8","desc":"Otrava inými psychotropnými látkami, nezatriedenými inde"},
{"code":"T43.9","desc":"Otrava psychotropnou látkou, bližšie neurčenou"},
{"code":"T44.-","desc":"Otrava liečivami, účinkujúcimi najmä na autonómnu nervovú sústavu"},
{"code":"T44.0","desc":"Otrava inhibítormi cholínesterázy"},
{"code":"T44.1","desc":"Otrava inými parasympatikomimetikami (cholínergikami)"},
{"code":"T44.2","desc":"Otrava liekmi blokujúcimi gangliá (ganglioplegiká), nezatriedenými inde"},
{"code":"T44.3","desc":"Otrava inými parasympatikolytikami (anticholínergikami a antimuskarinikami) a spazmolytikami, nezatriedenými inde"},
{"code":"T44.4","desc":"Otrava agonistami prevažne alfa-adrenoreceptorov, nezatriedenými inde"},
{"code":"T44.5","desc":"Otrava agonistami prevažne beta-adrenoreceptorov, nezatriedenými inde"},
{"code":"T44.6","desc":"Otrava antagonistami alfa-adrenoreceptorov, nezatriedenými inde"},
{"code":"T44.7","desc":"Otrava, antagonistami beta-adrenoreceptorov, nezatriedenými inde"},
{"code":"T44.8","desc":"Otrava prostriedkami, účinkujúcimi centrálne a blokujúcimi adrenergný neurón, nezatriedenými inde"},
{"code":"T44.9","desc":"Otrava inými a bližšie neurčenými liekmi, účinkujúcimi primárne na autonómnu nervovú sústavu"},
{"code":"T45.-","desc":"Otrava prostriedkami, účinkujúcimi najmä celkovo a na zložky krvi, nezatriedenými inde"},
{"code":"T45.0","desc":"Otrava antialergikami a antiemetikami"},
{"code":"T45.1","desc":"Otrava antineoplastickými a imunosupresívnymi liekmi"},
{"code":"T45.2","desc":"Otrava vitamínmi, nezatriedenými inde"},
{"code":"T45.3","desc":"Otrava enzýmami, nezatriedenými inde"},
{"code":"T45.4","desc":"Otrava železom a jeho zlúčeninami"},
{"code":"T45.5","desc":"Otrava antikoagulanciami"},
{"code":"T45.6","desc":"Otrava liekmi, ovplyvňujúcimi fibrinolýzu"},
{"code":"T45.7","desc":"Otrava antagonistami antikoagulancií, vitamínom K a inými koagulanciami"},
{"code":"T45.8","desc":"Otrava inými prostriedkami, účinkujúcimi najmä celkovo a na zložky krvi"},
{"code":"T45.9","desc":"Otrava bližšie neurčenými prostriedkami, účinkujúcimi najmä celkovo a na zložky krvi"},
{"code":"T46.-","desc":"Otrava prostriedkami, účinkujúcimi najmä na obehovú sústavu"},
{"code":"T46.0","desc":"Otrava srdcovými glykozidmi a liečivami s podobným účinkom"},
{"code":"T46.1","desc":"Otrava kalciovými antagonistami"},
{"code":"T46.2","desc":"Otrava inými antiarytmikami, nezatriedenými inde"},
{"code":"T46.3","desc":"Otrava koronárnymi vazodilatanciami, nezatriedenými inde"},
{"code":"T46.4","desc":"Otrava inhibítormi enzýmu, konvertujúceho angiotenzín"},
{"code":"T46.5","desc":"Otrava inými antihypertenzívami, nezatriedenými inde"},
{"code":"T46.6","desc":"Otrava hypolipidemikami a protiartériosklerotickými liekmi"},
{"code":"T46.7","desc":"Otrava periférnymi vazodilatanciami"},
{"code":"T46.8","desc":"Otrava antivarikóznymi liekmi vrátane sklerotizujúcich látok"},
{"code":"T46.9","desc":"Otrava inými a bližšie neurčenými prostriedkami, účinkujúcimi najmä na obehovú sústavu"},
{"code":"T47.-","desc":"Otrava prostriedkami, účinkujúcimi najmä na tráviacu sústavu"},
{"code":"T47.0","desc":"Otrava antagonistami histamínových H2-receptorov"},
{"code":"T47.1","desc":"Otrava inými antacidami a liekmi, tlmiacimi žalúdkovú sekréciu"},
{"code":"T47.2","desc":"Otrava dráždivými (stimulujúcimi) preháňadlami"},
{"code":"T47.3","desc":"Otrava salinickými a osmotickými preháňadlami"},
{"code":"T47.4","desc":"Otrava inými preháňadlami"},
{"code":"T47.5","desc":"Otrava digestívami"},
{"code":"T47.6","desc":"Otrava liekmi proti hnačke"},
{"code":"T47.7","desc":"Otrava emetikami"},
{"code":"T47.8","desc":"Otrava inými prostriedkami, účinkujúcimi najmä na tráviacu sústavu"},
{"code":"T47.9","desc":"Otrava bližšie neurčeným liekom, účinkujúcim najmä na tráviacu sústavu"},
{"code":"T48.-","desc":"Otrava prostriedkami, účinkujúcimi primárne na hladké a kostrové svaly a na dýchaciu sústavu"},
{"code":"T48.0","desc":"Otrava oxytocikami"},
{"code":"T48.1","desc":"Otrava relaxanciami kostrových svalov (prostriedkami blokujúcimi neuromuskulárny prevod)"},
{"code":"T48.2","desc":"Otrava inými a bližšie neurčenými prostriedkami, účinkujúcimi najmä na svaly"},
{"code":"T48.3","desc":"Otrava antitusikami"},
{"code":"T48.4","desc":"Otrava expektoranciami"},
{"code":"T48.5","desc":"Otrava liekmi proti prechladnutiu"},
{"code":"T48.6","desc":"Otrava antiastmatikami, nezatriedenými inde"},
{"code":"T48.7","desc":"Otrava inými a bližšie neurčenými prostriedkami, účinkujúcimi najmä na dýchaciu sústavu"},
{"code":"T49.-","desc":"Otrava prostriedkami na lokálne použitie účinkujúcimi, primárne na kožu a sliznice a prostriedkami používanými v oftalmológii, otorinolaryngológii a stomatológii"},
{"code":"T49.0","desc":"Otrava protiplesňovými, protiinfekčnými a protizápalovými liekmi s miestnym účinokm, nezatriedenými inde"},
{"code":"T49.1","desc":"Otrava antipruriginózami"},
{"code":"T49.2","desc":"Otrava lokálnymi adstringenciami a detergenciami"},
{"code":"T49.3","desc":"Otrava emolienciami, demulcenciami a ochrannými prostriedkami"},
{"code":"T49.4","desc":"Otrava keratolytikami, keratoplastikami a inými liekmi a prípravkami na ošetrovanie vlasov"},
{"code":"T49.5","desc":"Otrava liekmi a prípravkami používanými v oftalmológii"},
{"code":"T49.6","desc":"Otrava liekmi a prípravkami používanými v otorinolaryngológii"},
{"code":"T49.7","desc":"Otrava zubnými liekmi na miestne použitie"},
{"code":"T49.8","desc":"Otrava inými prostriedkami na miestne použitie"},
{"code":"T49.9","desc":"Otrava prostriedkami na miestne použitie, bližšie neurčenými"},
{"code":"T50.-","desc":"Otrava diuretikami a inými bližšie neurčenými liekmi, liečivami a biologickými látkami"},
{"code":"T50.0","desc":"Otrava mineralokortikoidmi a ich antagonistami"},
{"code":"T50.1","desc":"Otrava slučkovými diuretikami"},
{"code":"T50.2","desc":"Otrava inhibítormi karboanhydrázy, benzotiadiazidmi a inými diuretikami"},
{"code":"T50.3","desc":"Otrava prostriedkami, ovplyvňujúcimi elektrolytovú, energetickú a vodnú rovnováhu"},
{"code":"T50.4","desc":"Otrava liekmi, ovplyvňujúcimi metabolizmus kyseliny močovej"},
{"code":"T50.5","desc":"Otrava liekmi, znižujúcimi chuť do jedenia (anorektiká)"},
{"code":"T50.6","desc":"Otrava antidótami a chelátujúcimi prostriedkami, nezatriedenými inde"},
{"code":"T50.7","desc":"Otrava analeptikami a antagonistami opioidových receptorov"},
{"code":"T50.8","desc":"Otrava diagnostickými prostriedkami"},
{"code":"T50.9","desc":"Otrava inými a bližšie neurčenými liekmi, liečivami a biologickými látkami"},
{"code":"T51-T65","desc":"Toxické účinky látok používané prevažne mimo lekárstva"},
{"code":"T51.-","desc":"Toxický účinok alkoholu"},
{"code":"T51.0","desc":"Toxický účinok etanolu"},
{"code":"T51.1","desc":"Toxický účinok metanolu"},
{"code":"T51.2","desc":"Toxický účinok 2-propanolu"},
{"code":"T51.3","desc":"Otrava zmesou vyšších alkoholov, vznikajúcich pri alkoholovom kvasení"},
{"code":"T51.8","desc":"Toxický účinok iných alkoholov"},
{"code":"T51.9","desc":"Toxický účinok alkoholu, bližšie neurčeného"},
{"code":"T52.-","desc":"Toxický účinok organických rozpúšťadiel"},
{"code":"T52.0","desc":"Toxický účinok výrobkov z ropy"},
{"code":"T52.1","desc":"Toxický účinok benzénu"},
{"code":"T52.2","desc":"Toxický účinok homológov benzénu"},
{"code":"T52.3","desc":"Toxický účinok glykolov"},
{"code":"T52.4","desc":"Toxický účinok ketónov"},
{"code":"T52.8","desc":"Toxický účinok iných organických rozpúšťadiel"},
{"code":"T52.9","desc":"Toxický účinok organických rozpúšťadiel, bližšie neurčených"},
{"code":"T53.-","desc":"Toxický účinok halogénových derivátov alifatických a aromatických uhľovodíkov"},
{"code":"T53.0","desc":"Toxický účinok karbóntetrachloridu"},
{"code":"T53.1","desc":"Toxický účinok chloroformu"},
{"code":"T53.2","desc":"Toxický účinok trichlóretylénu"},
{"code":"T53.3","desc":"Toxický účinok tetrachlóretylénu"},
{"code":"T53.4","desc":"Toxický účinok dichlórmetánu"},
{"code":"T53.5","desc":"Toxický účinok chlórofluórokarbónov"},
{"code":"T53.6","desc":"Toxický účinok iných halogénových derivátov alifatických uhľovodíkov"},
{"code":"T53.7","desc":"Toxický účinok iných halogénových derivátov aromatických uhľovodíkov"},
{"code":"T53.9","desc":"Toxický účinok halogénových derivátov alifatických a aromatických uhľovodíkov, nepresne určených"},
{"code":"T54.-","desc":"Toxický účinok leptavej látky"},
{"code":"T54.0","desc":"Toxický účinok fenolu a homológov fenolu"},
{"code":"T54.1","desc":"Toxický účinok inej leptavej organickej zlúčeniny"},
{"code":"T54.2","desc":"Toxický účinok leptavej kyseliny a látky podobnej kyselinám"},
{"code":"T54.3","desc":"Toxický účinok leptavého lúhu a látky podobnej lúhom"},
{"code":"T54.9","desc":"Toxický účinok leptavej látky, bližšie neurčenej"},
{"code":"T55","desc":"Toxický účinok mydla a detergencia"},
{"code":"T56.-","desc":"Toxický účinok kovu"},
{"code":"T56.0","desc":"Toxický účinok olova a jeho zlúčenín"},
{"code":"T56.1","desc":"Toxický účinok ortuti a jej zlúčenín"},
{"code":"T56.2","desc":"Toxický účinok chrómu a jeho zlúčenín"},
{"code":"T56.3","desc":"Toxický účinok kadmia a jeho zlúčenín"},
{"code":"T56.4","desc":"Toxický účinok medi a jej zlúčenín"},
{"code":"T56.5","desc":"Toxický účinok zinku a jeho zlúčenín"},
{"code":"T56.6","desc":"Toxický účinok cínu a jeho zlúčenín"},
{"code":"T56.7","desc":"Toxický účinok berýlia a jeho zlúčenín"},
{"code":"T56.8","desc":"Toxický účinok iného kovu"},
{"code":"T56.9","desc":"Toxický účinok bližšie neurčeného kovu"},
{"code":"T57.-","desc":"Toxický účinok inej anorganickej látky"},
{"code":"T57.0","desc":"Toxický účinok arzénu a jeho zlúčenín"},
{"code":"T57.1","desc":"Toxický účinok fosforu a jeho zlúčenín"},
{"code":"T57.2","desc":"Toxický účinok mangánu a jeho zlúčenín"},
{"code":"T57.3","desc":"Toxický účinok kyanovodíku"},
{"code":"T57.8","desc":"Toxický účinok inej anorganickej látky, bližšie určenej"},
{"code":"T57.9","desc":"Toxický účinok bližšie neurčenej anorganickej látky"},
{"code":"T58","desc":"Toxický účinok oxidu uhoľnatého (CO)"},
{"code":"T59.-","desc":"Toxický účinok iného plynu, dymu a výparov"},
{"code":"T59.0","desc":"Toxický účinok oxidu dusíka"},
{"code":"T59.1","desc":"Toxický účinok oxidu siričitého"},
{"code":"T59.2","desc":"Toxický účinok formaldehydu"},
{"code":"T59.3","desc":"Toxický účinok slzotvorného plynu"},
{"code":"T59.4","desc":"Toxický účinok plynného chlóru"},
{"code":"T59.5","desc":"Toxický účinok plynného fluóru a fluorovodíka"},
{"code":"T59.6","desc":"Toxický účinok sírovodíka"},
{"code":"T59.7","desc":"Toxický účinok oxidu uhličitého"},
{"code":"T59.8","desc":"Toxický účinok iného plynu, dymu a výparov, bližšie určených"},
{"code":"T59.9","desc":"Toxický účinok plynu, dymu a výparov, bližšie neurčených"},
{"code":"T60.-","desc":"Toxický účinok pesticídu"},
{"code":"T60.0","desc":"Toxický účinok organofosfátového a karbamátového insekticídu"},
{"code":"T60.1","desc":"Toxický účinok halogénovaného insekticídu"},
{"code":"T60.2","desc":"Toxický účinok iného insekticídu"},
{"code":"T60.3","desc":"Toxický účinok herbicídu a fungicídu"},
{"code":"T60.4","desc":"Toxický účinok rodenticídu"},
{"code":"T60.8","desc":"Toxický účinok iného pesticídu"},
{"code":"T60.9","desc":"Toxický účinok bližšie neurčeného pesticídu"},
{"code":"T61.-","desc":"Toxický účinok škodlivých látok požitých ako morská potrava"},
{"code":"T61.0","desc":"Otrava rybou Ciguatera"},
{"code":"T61.1","desc":"Otrava rybou Scombroid"},
{"code":"T61.2","desc":"Otrava inou rybou a mäkkýšom"},
{"code":"T61.8","desc":"Toxický účinok inej morskej potravy"},
{"code":"T61.9","desc":"Toxický účinok bližšie neurčenej morskej potravy"},
{"code":"T62.-","desc":"Toxický účinok iných škodlivých látok požitých ako potrava"},
{"code":"T62.0","desc":"Toxický účinok požitých húb"},
{"code":"T62.1","desc":"Toxický účinok zjedených bobúľ"},
{"code":"T62.2","desc":"Toxický účinok iných požitých rastlín alebo ich častí"},
{"code":"T62.8","desc":"Toxický účinok inej škodlivej látky požitej ako potrava, bližšie určenej"},
{"code":"T62.9","desc":"Toxický účinok škodlivej látky požitej ako potrava, bližšie neurčenej"},
{"code":"T63.-","desc":"Toxický účinok kontaktu s jedovatými zvieratami"},
{"code":"T63.0","desc":"Toxický účinok hadieho jedu"},
{"code":"T63.1","desc":"Toxický účinok jedu iných plazov"},
{"code":"T63.2","desc":"Toxický účinok jedu škorpióna"},
{"code":"T63.3","desc":"Toxický účinok jedu pavúka"},
{"code":"T63.4","desc":"Toxický účinok jedu iných článkonožcov"},
{"code":"T63.5","desc":"Toxický účinok kontaktu s rybou"},
{"code":"T63.6","desc":"Toxický účinok kontaktu s inými morským živočíchom"},
{"code":"T63.8","desc":"Toxický účinok kontaktu s iným jedovatým živočíchom"},
{"code":"T63.9","desc":"Toxický účinok kontaktu s jedovatým živočíchom, bližšie neurčeným"},
{"code":"T64","desc":"Toxický účinok aflatoxínu a iných mykotoxínov, znečisťujúcich potravu"},
{"code":"T65.-","desc":"Toxický účinok inej a bližšie neurčenej látky"},
{"code":"T65.0","desc":"Toxický účinok kyanidu"},
{"code":"T65.1","desc":"Toxický účinok strychnínu a jeho solí"},
{"code":"T65.2","desc":"Toxický účinok tabaku a nikotínu"},
{"code":"T65.3","desc":"Toxický účinok nitroderivátov a aminoderivátov benzénu a jeho homológov"},
{"code":"T65.4","desc":"Toxický účinok karbóndisulfidu"},
{"code":"T65.5","desc":"Toxický účinok nitroglycerínu a iných nitrokyselín a esterov"},
{"code":"T65.6","desc":"Toxický účinok náterov a farieb, nezatriedených inde"},
{"code":"T65.8","desc":"Toxický účinok inej bližšie určenej látky"},
{"code":"T65.9","desc":"Toxický účinok bližšie neurčenej látky"},
{"code":"T66-T78","desc":"Iné a bližšie neurčené účinky vonkajších príčin"},
{"code":"T66","desc":"Bližšie neurčené účinky žiarenia"},
{"code":"T67.-","desc":"Účinky tepla a svetla"},
{"code":"T67.0","desc":"Tepelný a slnečný úpal"},
{"code":"T67.1","desc":"Synkopa z horúčavy"},
{"code":"T67.2","desc":"Kŕče z horúčavy"},
{"code":"T67.3","desc":"Vyčerpanie z horúčavy zapríčinené stratou vody"},
{"code":"T67.4","desc":"Vyčerpanie z horúčavy zapríčinené stratou soli"},
{"code":"T67.5","desc":"Vyčerpanie z horúčavy, bližšie neurčené"},
{"code":"T67.6","desc":"Prechodná únava z horúčavy"},
{"code":"T67.7","desc":"Opuch z horúčavy"},
{"code":"T67.8","desc":"Iné účinky horúčavy a svetla"},
{"code":"T67.9","desc":"Účinok horúčavy a svetla, bližšie neurčený"},
{"code":"T68","desc":"Podchladenie"},
{"code":"T69.-","desc":"Iné účinky zníženej teploty"},
{"code":"T69.0","desc":"Ruka a noha vystavená vlhkému chladu"},
{"code":"T69.1","desc":"Oziabliny"},
{"code":"T69.8","desc":"Iný účinok zníženej teploty, bližšie určený"},
{"code":"T69.9","desc":"Účinok zníženej teploty, bližšie neurčený"},
{"code":"T70.-","desc":"Účinok tlaku vzduchu a tlaku vody"},
{"code":"T70.0","desc":"Ušná barotrauma"},
{"code":"T70.1","desc":"Barotrauma prinosových dutín"},
{"code":"T70.2","desc":"Iný a bližšie neurčený účinok veľkej výšky"},
{"code":"T70.3","desc":"Kesónová choroba (dekompresná choroba)"},
{"code":"T70.4","desc":"Účinok tekutiny pod vysokým tlakom"},
{"code":"T70.8","desc":"Iný účinok tlaku vzduchu a tlaku vody"},
{"code":"T70.9","desc":"Účinok tlaku vzduchu a tlaku vody, bližšie neurčený"},
{"code":"T71","desc":"Zadusenie"},
{"code":"T73.-","desc":"Účinky iných nedostatkov [deprivácií]"},
{"code":"T73.0","desc":"Účinky hladu"},
{"code":"T73.1","desc":"Účinky smädu"},
{"code":"T73.2","desc":"Vyčerpanie zapríčinené vystavením poveternostným vplyvom"},
{"code":"T73.3","desc":"Vyčerpanie zapríčinené nadmernou námahou"},
{"code":"T73.8","desc":"Iný účinok nedostatku"},
{"code":"T73.9","desc":"Účinok nedostatku, bližšie neurčený"},
{"code":"T74.-","desc":"Syndróm, zapríčinený zlým zaobchádzaním [týraním]"},
{"code":"T74.0","desc":"Zanedbanie alebo opustenie"},
{"code":"T74.1","desc":"Fyzické zneužitie"},
{"code":"T74.2","desc":"Pohlavné zneužitie"},
{"code":"T74.3","desc":"Psychické zneužitie"},
{"code":"T74.8","desc":"Iný syndróm, zapríčinený zlým zaobchádzaním"},
{"code":"T74.9","desc":"Syndróm, zapríčinený zlým zaobchádzaním, bližšie neurčený"},
{"code":"T75.-","desc":"Účinky iných vonkajších príčin"},
{"code":"T75.0","desc":"Účinok blesku"},
{"code":"T75.1","desc":"Utopenie a topenie (nie smrteľné)"},
{"code":"T75.2","desc":"Účinky vibrácie"},
{"code":"T75.3","desc":"Kinetóza"},
{"code":"T75.4","desc":"Účinok elektrického prúdu"},
{"code":"T75.8","desc":"Iný účinok z vonkajších príčin, bližšie určený"},
{"code":"T78.-","desc":"Nežiaduci účinok, nezatriedený inde"},
{"code":"T78.0","desc":"Anafylaktický šok ako nežiaduci účinok potraviny"},
{"code":"T78.1","desc":"Iný nežiaduci účinok potraviny, nezatriedený inde"},
{"code":"T78.2","desc":"Anafylaktický šok, bližšie neurčený"},
{"code":"T78.3","desc":"Angioneurotický edém"},
{"code":"T78.4","desc":"Alergia, bližšie neurčená"},
{"code":"T78.8","desc":"Iný nežiaduci účinok, nezatriedený inde"},
{"code":"T78.9","desc":"Nežiaduci účinok, bližšie neurčený"},
{"code":"T79-T79","desc":"Určité včasné komplikácie úrazov"},
{"code":"T79.-","desc":"Určité včasné komplikácie úrazu, nezatriedené inde"},
{"code":"T79.0","desc":"Vzduchová embólia (poúrazová)"},
{"code":"T79.1","desc":"Tuková embólia (poúrazová)"},
{"code":"T79.2","desc":"Poúrazové druhotné a opakované krvácanie"},
{"code":"T79.3","desc":"Poúrazová infekcia rany, nezatriedená inde"},
{"code":"T79.4","desc":"Úrazový šok"},
{"code":"T79.5","desc":"Úrazová anúria"},
{"code":"T79.6","desc":"Poúrazová ischémia svalu"},
{"code":"T79.7","desc":"Poúrazový podkožný emfyzém"},
{"code":"T79.8","desc":"Iná včasná komplikácia úrazu"},
{"code":"T79.9","desc":"Včasná komplikácia úrazu, bližšie neurčená"},
{"code":"T80-T88","desc":"Komplikácie zdravotnej starostlivosti, nezatriedené inde"},
{"code":"T80.-","desc":"Komplikácia po infúzii, transfúzii a liečebnej injekcii"},
{"code":"T80.0","desc":"Vzduchová embólia po infúzii, transfúzii a liečebnej injekcii"},
{"code":"T80.1","desc":"Cievna komplikácia po infúzii, transfúzii a liečebnej injekcii"},
{"code":"T80.2","desc":"Infekcia po infúzii, transfúzii a liečebnej injekcii"},
{"code":"T80.3","desc":"Reakcia zapríčinená inkompatibilitou v systéme ABO"},
{"code":"T80.4","desc":"Reakcia zapríčinená inkompatibilitou v systéme Rh"},
{"code":"T80.5","desc":"Anafylaktický šok, zapríčinený sérom"},
{"code":"T80.6","desc":"Iná reakcia na sérum"},
{"code":"T80.8","desc":"Iná komplikácia po infúzii, transfúzii a liečebnej injekcii"},
{"code":"T80.9","desc":"Komplikácia po infúzii, transfúzii a liečebnej injekcii, bližšie neurčená"},
{"code":"T81.-","desc":"Komplikácia po výkone, nezatriedená inde"},
{"code":"T81.0","desc":"Krvácanie a hematóm, komplikujúci výkon, nezatriedené inde"},
{"code":"T81.1","desc":"Šok počas výkonu alebo po výkone, nezatriedený inde"},
{"code":"T81.2","desc":"Náhodné naštiepenie a natrhnutie počas výkonu, nezatriedené inde"},
{"code":"T81.3","desc":"Rozpad operačnej rany, nezatriedený inde"},
{"code":"T81.4","desc":"Infekcia po výkone, nezatriedená inde"},
{"code":"T81.5","desc":"Cudzie teleso náhodne ponechané v telovej dutine alebo operačnej rane po výkone"},
{"code":"T81.6","desc":"Akútna reakcia na cudziu látku, ponechanú náhodne počas výkonu"},
{"code":"T81.7","desc":"Cievna komplikácia po výkone, nezatriedená inde"},
{"code":"T81.8","desc":"Iná komplikácia výkonu, nezatriedená inde"},
{"code":"T81.9","desc":"Komplikácia výkonu, bližšie neurčená"},
{"code":"T82.-","desc":"Komplikácia zapríčinená srdcovou a cievnou protézou, implantátom a transplantátom"},
{"code":"T82.0","desc":"Mechanická komplikácia zapríčinená srdcovou chlopňovou protézou"},
{"code":"T82.1","desc":"Mechanická komplikácia zapríčinená srdcovou elektronickou pomôckou"},
{"code":"T82.2","desc":"Mechanická komplikácia zapríčinená obchádzkou (bajpasom) vencovitej tepny a chlopňovou náhradou (transplantátom)"},
{"code":"T82.3","desc":"Mechanická komplikácia zapríčinená iným cievnym transplantátom"},
{"code":"T82.4","desc":"Mechanická komplikácia zapríčinená cievnym dialyzačným katétrom"},
{"code":"T82.5","desc":"Mechanická komplikácia zapríčinená inou srdcovou a cievnou pomôckou a implantátom"},
{"code":"T82.6","desc":"Infekcia a zápalová reakcia zapríčinená srdcovou chlopňovou protézou (umelou chlopňou)"},
{"code":"T82.7","desc":"Infekcia a zápalová reakcia zapríčinená inou srdcovou a cievnou protézou, implantátom a transplantátom"},
{"code":"T82.8","desc":"Iná komplikácia zapríčinená srdcovou a cievnou protézou, implantátom a transplantátom, bližšie určená"},
{"code":"T82.9","desc":"Komplikácia zapríčinená srdcovou a cievnou protézou, implantátom a transplantátom, bližšie neurčená"},
{"code":"T83.-","desc":"Komplikácia zapríčinená močovopohlavnou protézou, implantátom a transplantátom"},
{"code":"T83.0","desc":"Mechanická komplikácia zapríčinená (trvalým) močovým katétrom"},
{"code":"T83.1","desc":"Mechanická komplikácia zapríčinená inou močovou pomôckou a implantátom"},
{"code":"T83.2","desc":"Mechanická komplikácia zapríčinená transplantátom močového orgánu"},
{"code":"T83.3","desc":"Mechanická komplikácia zapríčinená vnútromaternicovou antikoncepčnou pomôckou"},
{"code":"T83.4","desc":"Mechanická komplikácia zapríčinená inou protézou, implantátom a transplantátom v pohlavnej sústave"},
{"code":"T83.5","desc":"Infekcia a zápalová reakcia zapríčinená protézou, implantátom a transplantátom v močovej sústave"},
{"code":"T83.6","desc":"Infekcia a zápalová reakcia zapríčinená protézou, implantátom a transplantátom v pohlavnej sústave"},
{"code":"T83.8","desc":"Iná komplikácia zapríčinená protézou, implantátom a transplantátom v močovopohlavnej sústave"},
{"code":"T83.9","desc":"Bližšie neurčená komplikácia zapríčinená protézou, implantátom a transplantátom v močovopohlavnej sústave"},
{"code":"T84.-","desc":"Komplikácia zapríčinená vnútornou ortopedickou protézou, implantátom a transplantátom"},
{"code":"T84.0","desc":"Mechanická komplikácia zapríčinená kĺbovou endoprotézou"},
{"code":"T84.1","desc":"Mechanická komplikácia zapríčinená pomôckou na vnútornú fixáciu kostí končatiny"},
{"code":"T84.2","desc":"Mechanická komplikácia zapríčinená pomôckou na vnútornú fixáciu iných kostí"},
{"code":"T84.3","desc":"Mechanická komplikácia zapríčinená inou kostnou pomôckou, implantátom a transplantátom"},
{"code":"T84.4","desc":"Mechanická komplikácia zapríčinená inou vnútornou ortopedickou pomôckou, implantátom a transplantátom"},
{"code":"T84.5","desc":"Infekcia a zápalová reakcia zapríčinená kĺbovou endoprotézou"},
{"code":"T84.6","desc":"Infekcia a zápalová reakcia zapríčinená vnútornou kostnou fixačnou pomôckou (na ktoromkoľvek mieste)"},
{"code":"T84.7","desc":"Infekcia a zápalová reakcia zapríčinená inou ortopedickou endoprotézou, implantátom a štepom"},
{"code":"T84.8","desc":"Iná komplikácia zapríčinená ortopedickou endoprotézou, implantátom a štepom"},
{"code":"T84.9","desc":"Bližšie neurčená komplikácia vnútornej ortopedickej protetickej pomôcky, implantátu a štepu"},
{"code":"T85.-","desc":"Komplikácia zapríčinená inou vnútornou protézou, implantátom a štepom"},
{"code":"T85.0","desc":"Mechanická komplikácia zapríčinená vnútrolebkovou komorovou skratkou (šantom)"},
{"code":"T85.1","desc":"Mechanická komplikácia zapríčinená implantovaným elektronickým stimulátorom nervovej sústavy"},
{"code":"T85.2","desc":"Mechanická komplikácia zapríčinená vnútroočnou šošovkou"},
{"code":"T85.3","desc":"Mechanická komplikácia zapríčinená inou očnou protetickou pomôckou, implantátom a transplantátom"},
{"code":"T85.4","desc":"Mechanická komplikácia zapríčinená protézou alebo implantátom prsníka"},
{"code":"T85.5","desc":"Mechanická komplikácia zapríčinená gastrointestinálnou protézou, implantátom a transplantátom"},
{"code":"T85.6","desc":"Mechanická komplikácia zapríčinená inou gastrointestinálnou protézou, implantátom a transplantátom, bližšie určenou"},
{"code":"T85.7-","desc":"Infekcia a zápalová reakcia zapríčinená inou vnútornou protézou, implantátom a transplantátom"},
{"code":"T85.71","desc":"Infekcia a zápalová reakcia zapríčinená katétrom na peritoneálnu dialýzu"},
{"code":"T85.78","desc":"Infekcia a zápalová reakcia zapríčinená inou vnútornou protézou, implantátom alebo transplantátom"},
{"code":"T85.8-","desc":"Iná komplikácia zapríčinená, vnútornou protézou, implantátom alebo transplantátom, nezatriedená inde"},
{"code":"T85.81","desc":"Iná komplikácia zapríčinená vnútornou protézou, implantátom alebo transplantátom, v nervovej sústave"},
{"code":"T85.82","desc":"Kapsulová fibróza prsníka zapríčinená protézou alebo implantátom prsníka"},
{"code":"T85.88","desc":"Iná komplikácia zapríčinená vnútornou protézou, implantátom alebo transplantátom, nezatriedená inde"},
{"code":"T85.9","desc":"Komplikácia zapríčinená vnútornou protézou, implantátom alebo transplantátom, bližšie neurčená"},
{"code":"T86.-","desc":"Zlyhávanie a odvrhnutie transplantovaných orgánov a tkanív"},
{"code":"T86.0-","desc":"Zlyhanie transplantátu krvotvorných kmeňových buniek a choroba graft versus host"},
{"code":"T86.00","desc":"Zlyhanie transplantátu krvotvorných kmeňových buniek"},
{"code":"T86.01†","desc":"Akútna choroba graft versus host [GVHD]: 1. a 2. stupeň"},
{"code":"T86.02†","desc":"Akútna choroba graft versus host [GVHD]: 3. a 4. stupeň"},
{"code":"T86.03","desc":"Chronická choroba graft versus host [GVHD]: ohraničená forma"},
{"code":"T86.04","desc":"Chronická choroba graft versus host [GVHD]: rozšírená forma"},
{"code":"T86.09","desc":"Choroba graft versus host [GVHD]: bližšie neurčená"},
{"code":"T86.1-","desc":"Zlyhávanie a odvrhnutie transplantovanej obličky"},
{"code":"T86.10","desc":"Akútne zhoršenie funkcie transplantovanej obličky"},
{"code":"T86.11","desc":"Chronické zhoršenie funkcie transplantovanej obličky"},
{"code":"T86.12","desc":"Predĺžené prijatie funkcie transplantátu"},
{"code":"T86.19","desc":"Iná a bližšie neurčená funkčná porucha, zlyhávanie a odvrhnutie transplantovanej obličky"},
{"code":"T86.2","desc":"Zlyhávanie a odvrhnutie transplantovaného srdca"},
{"code":"T86.3","desc":"Zlyhávanie a odvrhnutie súčasne transplantovaného srdca a pľúc"},
{"code":"T86.4-","desc":"Zlyhávanie a odvrhnutie transplantovanej pečene"},
{"code":"T86.40","desc":"Akútne zhoršenie funkcie transplantovanej pečene"},
{"code":"T86.41","desc":"Chronické zhoršenie funkcie transplantovanej pečene"},
{"code":"T86.49","desc":"Iná a bližšie neurčená porucha funkcie, zlyhávanie a odvrhnutie transplantovanej pečene"},
{"code":"T86.5-","desc":"Zlyhávanie a odvrhnutie transplantovanej kože"},
{"code":"T86.50","desc":"Porucha prekrvenia transplantovanej kože"},
{"code":"T86.51","desc":"Nekróza transplantovanej kože"},
{"code":"T86.52","desc":"Strata kožného transplantátu"},
{"code":"T86.59","desc":"Iná a bližšie neurčená porucha funkcie, zlyhávanie a odvrhnutie transplantovanej kože"},
{"code":"T86.8-","desc":"Zlyhávanie a odvrhnutie iného transplantovaného orgánu a tkaniva"},
{"code":"T86.81","desc":"Zlyhanie a odvrhnutie transplantovaných pľúc"},
{"code":"T86.82","desc":"Zlyhanie a odvrhnutie transplantovaného pankreasu"},
{"code":"T86.83","desc":"Zlyhanie a odvrhnutie transplantovanej rohovky oka"},
{"code":"T86.88","desc":"Zlyhanie a odvrhnutie iného transplantovaného orgánu a tkaniva"},
{"code":"T86.9","desc":"Zlyhávanie a odvrhnutie bližšie neurčeného orgánu a tkaniva"},
{"code":"T87.-","desc":"Komplikácia charakteristická pre replantáciu a amputáciu"},
{"code":"T87.0","desc":"Komplikácia súvisiaca so znovupripojením (časti) hornej končatiny"},
{"code":"T87.1","desc":"Komplikácia súvisiaca so znovupripojením (časti) dolnej končatiny"},
{"code":"T87.2","desc":"Komplikácia inej znovupripojenej časti tela"},
{"code":"T87.3","desc":"Neuróm kýpťa po amputácii"},
{"code":"T87.4","desc":"Infekcia kýpťa po amputácii"},
{"code":"T87.5","desc":"Nekróza kýpťa po amputácii"},
{"code":"T87.6","desc":"Iná a bližšie neurčená komplikácia amputačného kýpťa"},
{"code":"T88.-","desc":"Iná komplikácia lekárskej a zdravotníckej starostlivosti, nezatriedená inde"},
{"code":"T88.0","desc":"Infekcia po imunizácii"},
{"code":"T88.1","desc":"Iná komplikácia po imunizácii, nezatriedená inde"},
{"code":"T88.2","desc":"Šok, zapríčinený anestéziou"},
{"code":"T88.3","desc":"Malígna hypertermia zapríčinená anestéziou"},
{"code":"T88.4","desc":"Nevydarená alebo ťažká intubácia"},
{"code":"T88.5","desc":"Iná komplikácia anestézie"},
{"code":"T88.6","desc":"Anafylaktický šok ako nežiaduci účinok správne indikovaného a správne podaného lieku alebo liečiva"},
{"code":"T88.7","desc":"Nežiaduci účinok lieku alebo liečiva, bližšie neurčený"},
{"code":"T88.8","desc":"Iná komplikácia chirurgického výkonu a lekárskej starostlivosti, nezatriedená inde, bližšie určená"},
{"code":"T88.9","desc":"Komplikácia chirurgického výkonu a lekárskej starostlivosti, bližšie neurčená"},
{"code":"T89-T89","desc":"Iná komplikácia úrazu, nezatriedená inde"},
{"code":"T89.-","desc":"Iná komplikácia úrazu, bližšie určená"},
{"code":"T89.0-","desc":"Komplikácia otvorenej rany"},
{"code":"T89.00","desc":"Komplikácia otvorenej rany, bližšie neurčená"},
{"code":"T89.01","desc":"Komplikácia otvorenej rany, cudzie teleso (s infekciou alebo bez infekcie)"},
{"code":"T89.02","desc":"Komplikácia otvorenej rany, infekcia"},
{"code":"T89.03","desc":"Komplikácia otvorenej rany, iná"},
{"code":"T90-T98","desc":"Neskoré následky poranení, otráv a iné dôsledky vonkajších príčin"},
{"code":"T90.-","desc":"Neskoré následky poranenia hlavy"},
{"code":"T90.0","desc":"Neskoré následky povrchového poranenia hlavy"},
{"code":"T90.1","desc":"Neskoré následky otvorenej rany hlavy"},
{"code":"T90.2","desc":"Neskoré následky zlomeniny lebky a tvárových kostí"},
{"code":"T90.3","desc":"Neskoré následky poranenia hlavových nervov"},
{"code":"T90.4","desc":"Neskoré následky poranenia oka a očnice"},
{"code":"T90.5","desc":"Neskoré následky vnútrolebkového poranenia"},
{"code":"T90.8","desc":"Neskoré následky iného bližšie určeného poranenia hlavy"},
{"code":"T90.9","desc":"Neskoré následky bližšie neurčeného poranenia hlavy"},
{"code":"T91.-","desc":"Neskoré následky poranenia krku a trupu"},
{"code":"T91.0","desc":"Neskoré následky povrchového poranenia a otvorenej rany krku a trupu"},
{"code":"T91.1","desc":"Neskoré následky zlomeniny chrbtice"},
{"code":"T91.2","desc":"Neskoré následky inej zlomeniny hrudníka a panvy"},
{"code":"T91.3","desc":"Neskoré následky poranenia miechy"},
{"code":"T91.4","desc":"Neskoré následky poranenia vnútrohrudníkových orgánov"},
{"code":"T91.5","desc":"Neskoré následky poranenia vnútrobrušných a panvových orgánov"},
{"code":"T91.8","desc":"Neskoré následky iného bližšie určeného poranenia krku a trupu"},
{"code":"T91.9","desc":"Neskoré následky bližšie neurčeného poranenia krku a trupu"},
{"code":"T92.-","desc":"Neskoré následky poranenia hornej končatiny"},
{"code":"T92.0","desc":"Neskoré následky otvorenej rany hornej končatiny"},
{"code":"T92.1","desc":"Neskoré následky zlomeniny ramena"},
{"code":"T92.2","desc":"Neskoré následky zlomeniny v úrovni zápästia a ruky"},
{"code":"T92.3","desc":"Neskoré následky vykĺbenia, vyvrtnutia a natiahnutia hornej končatiny"},
{"code":"T92.4","desc":"Neskoré následky poranenia nervu hornej končatiny"},
{"code":"T92.5","desc":"Neskoré následky poranenia svalu a šľachy hornej končatiny"},
{"code":"T92.6","desc":"Neskoré následky drvivého poranenia a úrazovej amputácie hornej končatiny"},
{"code":"T92.8","desc":"Neskoré následky iných bližšie určených poranení hornej končatiny"},
{"code":"T92.9","desc":"Neskoré následky bližšie neurčeného poranenia hornej končatiny"},
{"code":"T93.-","desc":"Neskoré následky poranenia dolnej končatiny"},
{"code":"T93.0","desc":"Neskoré následky otvorenej rany dolnej končatiny"},
{"code":"T93.1","desc":"Neskoré následky zlomeniny stehnovej kosti"},
{"code":"T93.2","desc":"Neskoré následky inej zlomeniny dolnej končatiny"},
{"code":"T93.3","desc":"Neskoré následky vykĺbenia, vyvrtnutia a natiahnutia dolnej končatiny"},
{"code":"T93.4","desc":"Neskoré následky poranenia nervu dolnej končatiny"},
{"code":"T93.5","desc":"Neskoré následky poranenia svalu a šľachy dolnej končatiny"},
{"code":"T93.6","desc":"Neskoré následky drvivého poranenia a úrazovej amputácie dolnej končatiny"},
{"code":"T93.8","desc":"Neskoré následky iného bližšie určeného poranenia dolnej končatiny"},
{"code":"T93.9","desc":"Neskoré následky bližšie neurčeného poranenia dolnej končatiny"},
{"code":"T94.-","desc":"Neskoré následky poranenia viacerých a bližšie neurčených oblastí tela"},
{"code":"T94.0","desc":"Neskoré následky poranenia viacerých oblastí tela"},
{"code":"T94.1","desc":"Neskoré následky poranenia: oblasť bližšie neurčená tela"},
{"code":"T95.-","desc":"Neskoré následky popálenia, poleptania a omrzlín"},
{"code":"T95.0","desc":"Neskoré následky popálenia, poleptania a omrzliny hlavy a krku"},
{"code":"T95.1","desc":"Neskoré následky popálenia, poleptania a omrzliny trupu"},
{"code":"T95.2","desc":"Neskoré následky popálenia, poleptania a omrzliny hornej končatiny"},
{"code":"T95.3","desc":"Neskoré následky popálenia, poleptania a omrzliny dolnej končatiny"},
{"code":"T95.4","desc":"Neskoré následky popálenia a poleptania zatriediteľné iba podľa rozsahu postihnutého povrchu tela"},
{"code":"T95.8","desc":"Neskoré následky inej bližšie určenej popáleniny, poleptania a omrzliny"},
{"code":"T95.9","desc":"Neskoré následky bližšie neurčenej popáleniny, poleptania a omrzliny"},
{"code":"T96","desc":"Neskoré následky otravy liekmi, liečivami a biologicky aktívnymi látkami"},
{"code":"T97","desc":"Neskoré následky toxických účinkov látky používanej najmä mimo lekárstva"},
{"code":"T98.-","desc":"Neskoré následky iných a bližšie neurčených účinkov vonkajších príčin"},
{"code":"T98.0","desc":"Neskoré následky účinkov cudzieho telesa vniknutého prirodzeným otvorom"},
{"code":"T98.1","desc":"Neskoré následky iných a bližšie neurčených účinkov vonkajších príčin"},
{"code":"T98.2","desc":"Neskoré následky niektorých včasných komplikácií úrazov"},
{"code":"T98.3","desc":"Neskoré následky komplikácií chirurgického výkonu a lekárskej starostlivosti, nezatriedené inde"},
{"code":"V01-Y98","desc":"XX. kapitola - Vonkajšie príčiny chorobnosti a úmrtnosti (V01-Y98)"},
{"code":"V01-X59","desc":"Nehody"},
{"code":"V01-V99","desc":"Dopravné nehody"},
{"code":"V01-V09","desc":"Poranenie chodca pri dopravnej nehode"},
{"code":"V01.-","desc":"Poranenie chodca pri zrážke s bicyklom"},
{"code":"V01.0","desc":"Poranenie chodca pri zrážke s bicyklom pri nedopravnej nehode"},
{"code":"V01.1","desc":"Poranenie chodca pri zrážke s bicyklom pri dopravnej nehode"},
{"code":"V01.9","desc":"Poranenie chodca pri zrážke s bicyklom pri bližšie neurčenej nehode"},
{"code":"V02.-","desc":"Poranenie chodca pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V02.0","desc":"Poranenie chodca pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom pri nedopravnej nehode"},
{"code":"V02.1","desc":"Poranenie chodca pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom pri dopravnej nehode"},
{"code":"V02.9","desc":"Poranenie chodca pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom pri bližšie neurčenej nehode"},
{"code":"V03.-","desc":"Poranenie chodca pri zrážke s osobným, dodávkovým alebo ľahkým nákladným autom"},
{"code":"V03.0","desc":"Poranenie chodca pri zrážke s osobným, dodávkovým alebo ľahkým nákladným autom pri nedopravnej nehode"},
{"code":"V03.1","desc":"Poranenie chodca pri zrážke s osobným, dodávkovým alebo ľahkým nákladným autom pri dopravnej nehode"},
{"code":"V03.9","desc":"Poranenie chodca pri zrážke s osobným, dodávkovým alebo ľahkým nákladným autom pri bližšie neurčenej nehode"},
{"code":"V04.-","desc":"Poranenie chodca pri zrážke s kamiónom alebo autobusom"},
{"code":"V04.0","desc":"Poranenie chodca pri zrážke s kamiónom alebo autobusom pri nedopravnej nehode"},
{"code":"V04.1","desc":"Poranenie chodca pri zrážke s kamiónom alebo autobusom pri dopravnej nehode"},
{"code":"V04.9","desc":"Poranenie chodca pri zrážke s kamiónom alebo autobusom pri bližšie neurčenej nehode"},
{"code":"V05.-","desc":"Poranenie chodca pri zrážke s vlakom alebo koľajovým vozidlom"},
{"code":"V05.0","desc":"Poranenie chodca pri zrážke s vlakom alebo koľajovým vozidlom pri nedopravnej nehode"},
{"code":"V05.1","desc":"Poranenie chodca pri zrážke s vlakom alebo koľajovým vozidlom pri dopravnej nehode"},
{"code":"V05.9","desc":"Poranenie chodca pri zrážke s vlakom alebo koľajovým vozidlom pri bližšie neurčenej nehode"},
{"code":"V06.-","desc":"Poranenie chodca pri zrážke s iným nemotorovým vozidlom"},
{"code":"V06.0","desc":"Poranenie chodca pri zrážke s iným nemotorovým vozidlom pri nedopravnej nehode"},
{"code":"V06.1","desc":"Poranenie chodca pri zrážke s iným nemotorovým vozidlom pri dopravnej nehode"},
{"code":"V06.9","desc":"Poranenie chodca pri zrážke s iným nemotorovým vozidlom pri bližšie neurčenej dopravnej alebo nedopravnej nehode"},
{"code":"V09.-","desc":"Poranenie chodca pri inej a bližšie neurčenej dopravnej nehode"},
{"code":"V09.0","desc":"Poranenie chodca pri nedopravnej nehode s účasťou iných a bližšie neurčených motorových vozidiel"},
{"code":"V09.1","desc":"Poranenie chodca pri bližšie neurčenej nedopravnej nehode"},
{"code":"V09.2","desc":"Poranenie chodca pri dopravnej nehode s účasťou iných a bližšie neurčených motorových vozidiel"},
{"code":"V09.3","desc":"Poranenie chodca pri bližšie neurčenej dopravnej nehode"},
{"code":"V09.9","desc":"Poranenie chodca pri bližšie neurčenej prepravnej nehode"},
{"code":"V10-V19","desc":"Poranenie bicyklistu pri dopravnej nehode"},
{"code":"V10.-","desc":"Poranenie bicyklistu pri zrážke s chodcom alebo so zvieraťom"},
{"code":"V10.0","desc":"Poranenia bicyklistu pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V10.1","desc":"Poranenia bicyklistu pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V10.2","desc":"Poranenia bicyklistu pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného bicyklistu pri nedopravnej nehode"},
{"code":"V10.3","desc":"Poranenia bicyklistu pri zrážke s chodcom alebo zvieraťom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V10.4","desc":"Poranenia bicyklistu pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri dopravnej nehode"},
{"code":"V10.5","desc":"Poranenia bicyklistu pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V10.9","desc":"Poranenia bicyklistu pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného bicyklistu pri dopravnej nehode"},
{"code":"V11.-","desc":"Poranenie bicyklistu pri zrážke s iným bicyklom"},
{"code":"V11.0","desc":"Poranenia bicyklistu pri zrážke s iným bicyklom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V11.1","desc":"Poranenia bicyklistu pri zrážke s iným bicyklom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V11.2","desc":"Poranenia bicyklistu pri zrážke s iným bicyklom, poranenie bližšie neurčeného bicyklistu pri nedopravnej nehode"},
{"code":"V11.3","desc":"Poranenia bicyklistu pri zrážke s iným bicyklom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V11.4","desc":"Poranenia bicyklistu pri zrážke s iným bicyklom, poranenie vodiča pri dopravnej nehode"},
{"code":"V11.5","desc":"Poranenia bicyklistu pri zrážke s iným bicyklom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V11.9","desc":"Poranenia bicyklistu pri zrážke s iným bicyklom, poranenie bližšie neurčeného bicyklistu pri dopravnej nehode"},
{"code":"V12.-","desc":"Poranenie bicyklistu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V12.0","desc":"Poranenie bicyklistu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V12.1","desc":"Poranenie bicyklistu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V12.2","desc":"Poranenie bicyklistu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného bicyklistu pri nedopravnej nehode"},
{"code":"V12.3","desc":"Poranenie bicyklistu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V12.4","desc":"Poranenie bicyklistu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V12.5","desc":"Poranenie bicyklistu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V12.9","desc":"Poranenie bicyklistu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného bicyklistu pri dopravnej nehode"},
{"code":"V13.-","desc":"Poranenie bicyklistu pri zrážke s osobným, dodávkovým alebo nákladným autom"},
{"code":"V13.0","desc":"Poranenie bicyklistu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V13.1","desc":"Poranenie bicyklistu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V13.2","desc":"Poranenie bicyklistu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného bicyklistu pri nedopravnej nehode"},
{"code":"V13.3","desc":"Poranenie bicyklistu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V13.4","desc":"Poranenie bicyklistu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri dopravnej nehode"},
{"code":"V13.5","desc":"Poranenie bicyklistu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V13.9","desc":"Poranenie bicyklistu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného bicyklistu pri dopravnej nehode"},
{"code":"V14.-","desc":"Poranenie bicyklistu pri zrážke s kamiónom alebo autobusom"},
{"code":"V14.0","desc":"Poranenie bicyklistu pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V14.1","desc":"Poranenie bicyklistu pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V14.2","desc":"Poranenie bicyklistu pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného bicyklistu pri nedopravnej nehode"},
{"code":"V14.3","desc":"Poranenie bicyklistu pri zrážke s kamiónom alebo autobusom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V14.4","desc":"Poranenie bicyklistu pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri dopravnej nehode"},
{"code":"V14.5","desc":"Poranenie bicyklistu pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V14.9","desc":"Poranenie bicyklistu pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného bicyklistu pri dopravnej nehode"},
{"code":"V15.-","desc":"Poranenie bicyklistu pri zrážke s vlakom alebo koľajovým vozidlom"},
{"code":"V15.0","desc":"Poranenie bicyklistu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V15.1","desc":"Poranenie bicyklistu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V15.2","desc":"Poranenie bicyklistu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného bicyklistu pri nedopravnej nehode"},
{"code":"V15.3","desc":"Poranenie bicyklistu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V15.4","desc":"Poranenie bicyklistu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V15.5","desc":"Poranenie bicyklistu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V15.9","desc":"Poranenie bicyklistu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného bicyklistu pri dopravnej nehode"},
{"code":"V16.-","desc":"Poranenie bicyklistu pri zrážke s iným nemotorovým vozidlom"},
{"code":"V16.0","desc":"Poranenie bicyklistu pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V16.1","desc":"Poranenie bicyklistu pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V16.2","desc":"Poranenie bicyklistu pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného bicyklistu pri nedopravnej nehode"},
{"code":"V16.3","desc":"Poranenie bicyklistu pri zrážke s iným nemotorovým vozidlom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V16.4","desc":"Poranenie bicyklistu pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V16.5","desc":"Poranenie bicyklistu pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V16.9","desc":"Poranenie bicyklistu pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného bicyklistu pri dopravnej nehode"},
{"code":"V17.-","desc":"Poranenie bicyklistu pri zrážke s pevným alebo nepohyblivým predmetom"},
{"code":"V17.0","desc":"Poranenie bicyklistu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V17.1","desc":"Poranenie bicyklistu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V17.2","desc":"Poranenie bicyklistu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného bicyklistu pri nedopravnej nehode"},
{"code":"V17.3","desc":"Poranenie bicyklistu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V17.4","desc":"Poranenie bicyklistu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri dopravnej nehode"},
{"code":"V17.5","desc":"Poranenie bicyklistu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V17.9","desc":"Poranenie bicyklistu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného bicyklistu pri dopravnej nehode"},
{"code":"V18.-","desc":"Poranenie bicyklistu pri dopravnej nehode bez zrážky"},
{"code":"V18.0","desc":"Poranenie bicyklistu pri dopravnej nehode bez zrážky, poranenie vodiča pri nedopravnej nehode"},
{"code":"V18.1","desc":"Poranenie bicyklistu pri dopravnej nehode bez zrážky, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V18.2","desc":"Poranenie bicyklistu pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného bicyklistu pri nedopravnej nehode"},
{"code":"V18.3","desc":"Poranenie bicyklistu pri dopravnej nehode bez zrážky, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V18.4","desc":"Poranenie bicyklistu pri dopravnej nehode bez zrážky, poranenie vodiča pri dopravnej nehode"},
{"code":"V18.5","desc":"Poranenie bicyklistu pri dopravnej nehode bez zrážky, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V18.9","desc":"Poranenie bicyklistu pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného bicyklistu pri dopravnej nehode"},
{"code":"V19.-","desc":"Poranenie bicyklistu pri iných a bližšie neurčených dopravných nehodách"},
{"code":"V19.0","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V19.1","desc":"Poranenie cestujúceho pri zrážke s iným a bližšie neurčeným motorovým vozidlom pri nedopravnej nehode"},
{"code":"V19.2","desc":"Poranenie bližšie neurčeného bicyklistu pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V19.3","desc":"Poranenie bicyklistu (ktoréhokoľvek) pri bližšie neurčenej nedopravnej nehode"},
{"code":"V19.4","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V19.5","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V19.6","desc":"Poranenie bližšie neurčeného bicyklistu pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V19.8","desc":"Poranenie bicyklistu (ktoréhokoľvek) pri iných bližšie určených dopravných nehodách"},
{"code":"V19.9","desc":"Poranenie bicyklistu (ktoréhokoľvek) pri bližšie neurčenej dopravnej nehode"},
{"code":"V20-V29","desc":"Poranenie jazdca na motocykli pri dopravnej nehode"},
{"code":"V20.-","desc":"Poranenie jazdca na motocykli pri zrážke s chodcom alebo so zvieraťom"},
{"code":"V20.0","desc":"Poranenie jazdca na motocykli pri zrážke s chodcom alebo so zvieraťom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V20.1","desc":"Poranenie jazdca na motocykli pri zrážke s chodcom alebo so zvieraťom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V20.2","desc":"Poranenie jazdca na motocykli pri zrážke s chodcom alebo so zvieraťom, poranenie bližšie neurčeného jazdca na motocykli pri nedopravnej nehode"},
{"code":"V20.3","desc":"Poranenie jazdca na motocykli pri zrážke s chodcom alebo so zvieraťom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V20.4","desc":"Poranenie jazdca na motocykli pri zrážke s chodcom alebo so zvieraťom, poranenie vodiča pri dopravnej nehode"},
{"code":"V20.5","desc":"Poranenie jazdca na motocykli pri zrážke s chodcom alebo so zvieraťom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V20.9","desc":"Poranenie jazdca na motocykli pri zrážke s chodcom alebo so zvieraťom, poranenie bližšie neurčeného jazdca na motocykli pri dopravnej nehode"},
{"code":"V21.-","desc":"Poranenie jazdca na motocykli pri zrážke bicyklistom"},
{"code":"V21.0","desc":"Poranenie jazdca na motocykli pri zrážke bicyklistom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V21.1","desc":"Poranenie jazdca na motocykli pri zrážke bicyklistom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V21.2","desc":"Poranenie jazdca na motocykli pri zrážke bicyklistom, poranenie bližšie neurčeného jazdca na motocykli pri nedopravnej nehode"},
{"code":"V21.3","desc":"Poranenie jazdca na motocykli pri zrážke bicyklistom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V21.4","desc":"Poranenie jazdca na motocykli pri zrážke bicyklistom, poranenie vodiča pri dopravnej nehode"},
{"code":"V21.5","desc":"Poranenie jazdca na motocykli pri zrážke bicyklistom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V21.9","desc":"Poranenie jazdca na motocykli pri zrážke bicyklistom, poranenie bližšie neurčeného jazdca na motocykli pri dopravnej nehode"},
{"code":"V22.-","desc":"Poranenie jazdca na motocykli pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V22.0","desc":"Poranenie jazdca na motocykli pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V22.1","desc":"Poranenie jazdca na motocykli pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V22.2","desc":"Poranenie jazdca na motocykli pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného jazdca na motocykli pri nedopravnej nehode"},
{"code":"V22.3","desc":"Poranenie jazdca na motocykli pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V22.4","desc":"Poranenie jazdca na motocykli pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V22.5","desc":"Poranenie jazdca na motocykli pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V22.9","desc":"Poranenie jazdca na motocykli pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného jazdca na motocykli pri dopravnej nehode"},
{"code":"V23.-","desc":"Poranenie jazdca na motocykli pri zrážke s osobným, dodávkovým alebo nákladným autom"},
{"code":"V23.0","desc":"Poranenie jazdca na motocykli pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V23.1","desc":"Poranenie jazdca na motocykli pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V23.2","desc":"Poranenie jazdca na motocykli pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného jazdca na motocykli pri nedopravnej nehode"},
{"code":"V23.3","desc":"Poranenie jazdca na motocykli pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V23.4","desc":"Poranenie jazdca na motocykli pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri dopravnej nehode"},
{"code":"V23.5","desc":"Poranenie jazdca na motocykli pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V23.9","desc":"Poranenie jazdca na motocykli pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného jazdca na motocykli pri dopravnej nehode"},
{"code":"V24.-","desc":"Poranenie jazdca na motocykli pri zrážke s kamiónom alebo autobusom"},
{"code":"V24.0","desc":"Poranenie jazdca na motocykli pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V24.1","desc":"Poranenie jazdca na motocykli pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V24.2","desc":"Poranenie jazdca na motocykli pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného jazdca na motocykli pri nedopravnej nehode"},
{"code":"V24.3","desc":"Poranenie jazdca na motocykli pri zrážke s kamiónom alebo autobusom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V24.4","desc":"Poranenie jazdca na motocykli pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri dopravnej nehode"},
{"code":"V24.5","desc":"Poranenie jazdca na motocykli pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V24.9","desc":"Poranenie jazdca na motocykli pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného jazdca na motocykli pri dopravnej nehode"},
{"code":"V25.-","desc":"Poranenie jazdca na motocykli pri zrážke s vlakom alebo koľajovým vozidlom"},
{"code":"V25.0","desc":"Poranenie jazdca na motocykli pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V25.1","desc":"Poranenie jazdca na motocykli pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V25.2","desc":"Poranenie jazdca na motocykli pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného jazdca na motocykli pri nedopravnej nehode"},
{"code":"V25.3","desc":"Poranenie jazdca na motocykli pri zrážke s vlakom alebo koľajovýcm vozidlo, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V25.4","desc":"Poranenie jazdca na motocykli pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V25.5","desc":"Poranenie jazdca na motocykli pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V25.9","desc":"Poranenie jazdca na motocykli pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného jazdca na motocykli pri dopravnej nehode"},
{"code":"V26.-","desc":"Poranenie jazdca na motocykli pri zrážke s iným nemotorovým vozidlom"},
{"code":"V26.0","desc":"Poranenie jazdca na motocykli pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V26.1","desc":"Poranenie jazdca na motocykli pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V26.2","desc":"Poranenie jazdca na motocykli pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného jazdca na motocykli pri nedopravnej nehode"},
{"code":"V26.3","desc":"Poranenie jazdca na motocykli pri zrážke s iným nemotorovým vozidlom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V26.4","desc":"Poranenie jazdca na motocykli pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V26.5","desc":"Poranenie jazdca na motocykli pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V26.9","desc":"Poranenie jazdca na motocykli pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného jazdca na motocykli pri dopravnej nehode"},
{"code":"V27.-","desc":"Poranenie jazdca na motocykli pri zrážke s pevným alebo nepohyblivým predmetom"},
{"code":"V27.0","desc":"Poranenie jazdca na motocykli pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V27.1","desc":"Poranenie jazdca na motocykli pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V27.2","desc":"Poranenie jazdca na motocykli pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného jazdca na motocykli pri nedopravnej nehode"},
{"code":"V27.3","desc":"Poranenie jazdca na motocykli pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V27.4","desc":"Poranenie jazdca na motocykli pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri dopravnej nehode"},
{"code":"V27.5","desc":"Poranenie jazdca na motocykli pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V27.9","desc":"Poranenie jazdca na motocykli pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného jazdca na motocykli pri dopravnej nehode"},
{"code":"V28.-","desc":"Poranenie jazdca na motocykli pri dopravnej nehode bez zrážky"},
{"code":"V28.0","desc":"Poranenie jazdca na motocykli pri dopravnej nehode bez zrážky, poranenie vodiča pri nedopravnej nehode"},
{"code":"V28.1","desc":"Poranenie jazdca na motocykli pri dopravnej nehode bez zrážky, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V28.2","desc":"Poranenie jazdca na motocykli pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného jazdca na motocykli pri nedopravnej nehode"},
{"code":"V28.3","desc":"Poranenie jazdca na motocykli pri dopravnej nehode bez zrážky, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V28.4","desc":"Poranenie jazdca na motocykli pri dopravnej nehode bez zrážky, poranenie vodiča pri dopravnej nehode"},
{"code":"V28.5","desc":"Poranenie jazdca na motocykli pri dopravnej nehode bez zrážky, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V28.9","desc":"Poranenie jazdca na motocykli pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného jazdca na motocykli pri dopravnej nehode"},
{"code":"V29.-","desc":"Poranenie jazdca na motocykli pri iných a bližšie neurčených dopravných nehodách"},
{"code":"V29.0","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V29.1","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V29.2","desc":"Poranenie bližšie neurčeného jazdca na motocykli pri zrážke s inými a bližšie neurčeným motorovými vozidlami pri nedopravnej nehode"},
{"code":"V29.3","desc":"Poranenie jazdca na motocykli (ktoréhokoľvek) pri bližšie neurčenej nedopravnej nehode"},
{"code":"V29.4","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V29.5","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V29.6","desc":"Poranenie bližšie neurčeného jazdca na motocykli pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V29.8","desc":"Poranenie jazdca na motocykli (ktoréhokoľvek) pri iných bližšie určených dopravných nehodách"},
{"code":"V29.9","desc":"Poranenie jazdca na motocykli (ktoréhokoľvek) pri bližšie neurčenej dopravnej nehode"},
{"code":"V30-V39","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V30.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo so zvieraťom"},
{"code":"V30.0","desc":"Poranenia člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V30.1","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V30.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo zvieraťom, poranenie osoby mimo vozidla pri nedopravenej nehode"},
{"code":"V30.3","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri nedopravnej nehode"},
{"code":"V30.4","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo zvieraťom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V30.5","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri dopravnej nehode"},
{"code":"V30.6","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V30.7","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo zvieraťom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V30.9","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V31.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom"},
{"code":"V31.0","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V31.1","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V31.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V31.3","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri nedopravnej nehode"},
{"code":"V31.4","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V31.5","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom, poranenie vodiča pri dopravnej nehode"},
{"code":"V31.6","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V31.7","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V31.9","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V32.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V32.0","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V32.1","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V32.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V32.3","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri nedopravnej nehode"},
{"code":"V32.4","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V32.5","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V32.6","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V32.7","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V32.9","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V33.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom"},
{"code":"V33.0","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V33.1","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V33.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V33.3","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri nedopravnej nehode"},
{"code":"V33.4","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V33.5","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri dopravnej nehode"},
{"code":"V33.6","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V33.7","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V33.9","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V34.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom"},
{"code":"V34.0","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V34.1","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V34.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V34.3","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri nedopravnej nehode"},
{"code":"V34.4","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V34.5","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri dopravnej nehode"},
{"code":"V34.6","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V34.7","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V34.9","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V35.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom"},
{"code":"V35.0","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V35.1","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V35.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V35.3","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri nedopravnej nehode"},
{"code":"V35.4","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V35.5","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V35.6","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V35.7","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V35.9","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V36.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom"},
{"code":"V36.0","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V36.1","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V36.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V36.3","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri nedopravnej nehode"},
{"code":"V36.4","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V36.5","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V36.6","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V36.7","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V36.9","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V37.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom"},
{"code":"V37.0","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V37.1","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V37.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V37.3","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri nedopravnej nehode"},
{"code":"V37.4","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V37.5","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri dopravnej nehode"},
{"code":"V37.6","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V37.7","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V37.9","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V38.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky"},
{"code":"V38.0","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky, poranenie vodiča pri nedopravnej nehode"},
{"code":"V38.1","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V38.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V38.3","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri nedopravnej nehode"},
{"code":"V38.4","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky, poranenie osoby počas nastupovania alebo vystupovania"},
{"code":"V38.5","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky, poranenie vodiča pri dopravnej nehode"},
{"code":"V38.6","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V38.7","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V38.9","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri dopravnej nehode"},
{"code":"V39.-","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri iných a bližšie neurčených dopravných nehodách"},
{"code":"V39.0","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V39.1","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V39.2","desc":"Poranenie člena posádky trojkolesového motorového vozidla pri zrážke s iným a bližšie neurčeným motorovým vozidlom pri nedopravnej nehode"},
{"code":"V39.3","desc":"Poranenie (ktoréhokoľvek) člena posádky trojkolesového motorového vozidla pri bližšie neurčenej nedopravnej nehode"},
{"code":"V39.4","desc":"Poranenie vodiča pri zrážke s iným a bližšie neurčeným motorovým vozidlom pri dopravnej nehode"},
{"code":"V39.5","desc":"Poranenie cestujúceho pri zrážke s iným a bližšie neurčeným motorovým vozidlom pri dopravnej nehode"},
{"code":"V39.6","desc":"Poranenie bližšie neurčeného člena posádky trojkolesového motorového vozidla pri zrážke s iným a bližšie neurčeným motorovým vozidlom pri dopravnej nehode"},
{"code":"V39.8","desc":"Poranenie (ktoréhokoľvek) člena posádky trojkolesového motorového vozidla pri iných bližšie určených dopravných nehodách"},
{"code":"V39.9","desc":"Poranenie (ktoréhokoľvek) člena posádky trojkolesového motorového vozidla pri bližšie neurčenej dopravnej nehode"},
{"code":"V40-V49","desc":"Poranenia člena posádky osobného auta pri dopravnej nehode"},
{"code":"V40.-","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom"},
{"code":"V40.0","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V40.1","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V40.2","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V40.3","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posádky osobného auta pri nedopravnej nehode"},
{"code":"V40.4","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V40.5","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri dopravnej nehode"},
{"code":"V40.6","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V40.7","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom, poranenie osoby mimo mimo vozidla pri dopravnej nehode"},
{"code":"V40.9","desc":"Poranenie člena posádky osobného auta pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posádky osobného auta pri dopravnej nehode"},
{"code":"V41.-","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom"},
{"code":"V41.0","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V41.1","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V41.2","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V41.3","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky osobného auta pri nedopravnej nehode"},
{"code":"V41.4","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V41.5","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom, poranenie vodiča pri dopravnej nehode"},
{"code":"V41.6","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V41.7","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V41.9","desc":"Poranenie člena posádky osobného auta pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky osobného auta pri dopravnej nehode"},
{"code":"V42.-","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V42.0","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V42.1","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V42.2","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V42.3","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky osobného auta pri nedopravnej nehode"},
{"code":"V42.4","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V42.5","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V42.6","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V42.7","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V42.9","desc":"Poranenie člena posádky osobného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky osobného auta pri dopravnej nehode"},
{"code":"V43.-","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom"},
{"code":"V43.0","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V43.1","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V43.2","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V43.3","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky osobného auta pri nedopravnej nehode"},
{"code":"V43.4","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V43.5","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri dopravnej nehode"},
{"code":"V43.6","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V43.7","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V43.9","desc":"Poranenie člena posádky osobného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky osobného auta pri dopravnej nehode"},
{"code":"V44.-","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo autobusom"},
{"code":"V44.0","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V44.1","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V44.2","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo autobusom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V44.3","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného člena posádky osobného auta pri nedopravnej nehode"},
{"code":"V44.4","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo s autobusom: poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V44.5","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri dopravnej nehode"},
{"code":"V44.6","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V44.7","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo autobusom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V44.9","desc":"Poranenie člena posádky osobného auta pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného člena posádky osobného auta pri dopravnej nehode"},
{"code":"V45.-","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom"},
{"code":"V45.0","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V45.1","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V45.2","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V45.3","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného člena posádky osobného auta pri nedopravnej nehode"},
{"code":"V45.4","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V45.5","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V45.6","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V45.7","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V45.9","desc":"Poranenie člena posádky osobného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného člena posádky osobného auta pri dopravnej nehode"},
{"code":"V46.-","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom"},
{"code":"V46.0","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V46.1","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V46.2","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V46.3","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného člena posádky osobného auta pri nedopravnej nehode"},
{"code":"V46.4","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V46.5","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V46.6","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V46.7","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V46.9","desc":"Poranenie člena posádky osobného auta pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného člena posádky osobného auta pri dopravnej nehode"},
{"code":"V47.-","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom"},
{"code":"V47.0","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V47.1","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V47.2","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V47.3","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky osobného auta pri nedopravnej nehode"},
{"code":"V47.4","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V47.5","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri dopravnej nehode"},
{"code":"V47.6","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V47.7","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V47.9","desc":"Poranenie člena posádky osobného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky osobného auta pri dopravnej nehode"},
{"code":"V48.-","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky"},
{"code":"V48.0","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky, poranenie vodiča pri nedopravnej nehode"},
{"code":"V48.1","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V48.2","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V48.3","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky osobného auta pri nedopravnej nehode"},
{"code":"V48.4","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V48.5","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky, poranenie vodiča pri dopravnej nehode"},
{"code":"V48.6","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V48.7","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V48.9","desc":"Poranenie člena posádky osobného auta pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky osobného auta pri dopravnej nehode"},
{"code":"V49.-","desc":"Poranenie člena posádky osobného auta pri iných a bližšie neurčených dopravných nehodách"},
{"code":"V49.0","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V49.1","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V49.2","desc":"Poranenie bližšie neurčeného člena posádky osobného auta pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V49.3","desc":"Poranenie (ktoréhokoľvek) člena posádky osobného auta pri bližšie neurčenej nedopravnej nehode"},
{"code":"V49.4","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V49.5","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V49.6","desc":"Poranenie bližšie neurčeného člena posádky osobného auta pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V49.8","desc":"Poranenie (ktoréhokoľvek) člena posádky osobného auta pri iných bližšie určených dopravných nehodách"},
{"code":"V49.9","desc":"Poranenie (ktoréhokoľvek) člena posádky osobného auta pri bližšie neurčenej dopravnej nehode"},
{"code":"V50-V59","desc":"Poranenia člena posádky nákladného alebo dodávkového auta pri dopravnej nehode"},
{"code":"V50.-","desc":"Poranenie cestujúceho v nákladnom alebo dodávkovom aute pri zrážke s chodcom alebo zvieraťom"},
{"code":"V50.0","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V50.1","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V50.2","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s chodcom alebo zvieraťom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V50.3","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri nedopravnej nehode"},
{"code":"V50.4","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s chodcom alebo zvieraťom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V50.5","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri dopravnej nehode"},
{"code":"V50.6","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V50.7","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s chodcom alebo zvieraťom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V50.9","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posády dodávky alebo nákladného auta pri dopravnej nehode"},
{"code":"V51.-","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom"},
{"code":"V51.0","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V51.1","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V51.2","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V51.3","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri nedopravnej nehode"},
{"code":"V51.4","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V51.5","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom, poranenie vodiča pri dopravnej nehode"},
{"code":"V51.6","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V51.7","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V51.9","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri dopravnej nehode"},
{"code":"V52.-","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V52.0","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V52.1","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V52.2","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V52.3","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri nedopravnej nehode"},
{"code":"V52.4","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V52.5","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V52.6","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V52.7","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V52.9","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri dopravnej nehode"},
{"code":"V53.-","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom"},
{"code":"V53.0","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V53.1","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom,: poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V53.2","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V53.3","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri nedopravnej nehode"},
{"code":"V53.4","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V53.5","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri dopravnej nehode"},
{"code":"V53.6","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V53.7","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V53.9","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri dopravnej nehode"},
{"code":"V54.-","desc":"Poranenie cestujúceho v nákladnom alebo dodávkovom aute pri zrážke s kamiónom alebo s autobusom"},
{"code":"V54.0","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s kamiónom alebo s autobusom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V54.1","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s kamiónom alebo s autobusom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V54.2","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s kamiónom alebo s autobusom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V54.3","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s ťažkým nákladným vozidlom alebo autobusom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri nedopravnej nehode"},
{"code":"V54.4","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s ťažkým nákladným vozidlom alebo autobusom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V54.5","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s ťažkým nákladným vozidlom alebo autobusom, poranenie vodiča pri dopravnej nehode"},
{"code":"V54.6","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s ťažkým nákladným vozidlom alebo autobusom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V54.7","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s ťažkým nákladným vozidlom alebo autobusom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V54.9","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s ťažkým nákladným vozidlom alebo autobusom, poranenie bližšie neurčeného člena posádky v dodávkovom alebo nákladnom aute pri dopravnej nehode"},
{"code":"V55.-","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom"},
{"code":"V55.0","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V55.1","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V55.2","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V55.3","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného cestujúceho v dodávke alebo nákladnom aute pri nedopravnej nehode"},
{"code":"V55.4","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V55.5","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V55.6","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V55.7","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V55.9","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného člena posádky v dodávke alebo nákladnom aute pri dopravnej nehode"},
{"code":"V56.-","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom"},
{"code":"V56.0","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V56.1","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V56.2","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V56.3","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri nedopravnej nehode"},
{"code":"V56.4","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V56.5","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V56.6","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V56.7","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V56.9","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri dopravnej nehode"},
{"code":"V57.-","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s pevným alebo nepohyblivým predmetom"},
{"code":"V57.0","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V57.1","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V57.2","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V57.3","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri nedopravnej nehode"},
{"code":"V57.4","desc":"Poranenie člena posádky v nákladnom alebo dodávkovom aute pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V57.5","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri dopravnej nehode"},
{"code":"V57.6","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V57.7","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V57.9","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri dopravnej nehode"},
{"code":"V58.-","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky"},
{"code":"V58.0","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky, poranenie vodiča pri nedopravnej nehode"},
{"code":"V58.1","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V58.2","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V58.3","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri nedopravnej nehode"},
{"code":"V58.4","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky, poranenie osoby pri nastupovaní alebo vystupovaní"},
{"code":"V58.5","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky, poranenie vodiča pri dopravnej nehode"},
{"code":"V58.6","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V58.7","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V58.9","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri dopravnej nehode"},
{"code":"V59.-","desc":"Poranenie člena posádky dodávky alebo nákladného auta pri inej a bližšie neurčenej dopravnej nehode"},
{"code":"V59.0","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V59.1","desc":"Poranenie cestujúceho pri zrážke s inými alebo bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V59.2","desc":"Poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri zrážke s inými alebo bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V59.3","desc":"Poranenie člena posádky (ktoréhokoľvek) dodávky alebo nákladného auta pri bližšie neurčenej nedopravnej nehode"},
{"code":"V59.4","desc":"Poranenie vodiča pri zrážke s iným a bližšie neurčeným motorovým vozidlom pri dopravnej nehode"},
{"code":"V59.5","desc":"Poranenie cestujúceho pri zrážke s iným a bližšie neurčeným motorovým vozidlom pri dopravnej nehode"},
{"code":"V59.6","desc":"Poranenie bližšie neurčeného člena posádky dodávky alebo nákladného auta pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V59.8","desc":"Poranenie člena posádky (ktoréhokoľvek) dodávky alebo nákladného auta pri inej bližšie určenej dopravnej nehode"},
{"code":"V59.9","desc":"Poranenie člena posádky (ktoréhokoľvek) dodávky alebo nákladného auta pri bližšie určenej dopravnej nehode"},
{"code":"V60-V69","desc":"Poranenie cestujúceho v kamióne pri dopravnej nehode"},
{"code":"V60.-","desc":"Poranenie cestujúceho v kamióne pri zrážke s chodcom alebo so zvieraťom"},
{"code":"V60.0","desc":"Poranenie člena posádky kamióna pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V60.1","desc":"Poranenie člena posádky kamióna pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V60.2","desc":"Poranenie člena posádky kamióna pri zrážke s chodcom alebo zvieraťom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V60.3","desc":"Poranenie člena posádky kamióna pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posádky kamióna pri nedopravnej nehode"},
{"code":"V60.4","desc":"Poranenie člena posádky kamióna pri zrážke s chodcom alebo zvieraťom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V60.5","desc":"Poranenie člena posádky kamióna pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri dopravnej nehode"},
{"code":"V60.6","desc":"Poranenie člena posádky kamióna pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V60.7","desc":"Poranenie člena posádky kamióna pri zrážke s chodcom alebo zvieraťom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V60.9","desc":"Poranenie člena posádky kamióna pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posádky v kamióne pri dopravnej nehode"},
{"code":"V61.-","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom"},
{"code":"V61.0","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V61.1","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V61.2","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V61.3","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky kamióna pri nedopravnej nehode"},
{"code":"V61.4","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V61.5","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom, poranenie vodiča pri dopravnej nehode"},
{"code":"V61.6","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V61.7","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V61.9","desc":"Poranenie člena posádky kamióna pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky kamióna pri dopravnej nehode"},
{"code":"V62.-","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V62.0","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V62.1","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V62.2","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V62.3","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky kamióna pri nedopravnej nehode"},
{"code":"V62.4","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V62.5","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V62.6","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V62.7","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V62.9","desc":"Poranenie člena posádky kamióna pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky kamióna pri dopravnej nehode"},
{"code":"V63.-","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom"},
{"code":"V63.0","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V63.1","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V63.2","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V63.3","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky kamióna pri nedopravnej nehode"},
{"code":"V63.4","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V63.5","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri dopravnej nehode"},
{"code":"V63.6","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V63.7","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V63.9","desc":"Poranenie člena posádky kamióna pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky kamióna pri dopravnej nehode"},
{"code":"V64.-","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom"},
{"code":"V64.0","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V64.1","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V64.2","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V64.3","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného člena posádky kamióna pri nedopravnej nehode"},
{"code":"V64.4","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V64.5","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri dopravnej nehode"},
{"code":"V64.6","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V64.7","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V64.9","desc":"Poranenie člena posádky kamióna pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného člena posádky kamióna pri dopravnej nehode"},
{"code":"V65.-","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom"},
{"code":"V65.0","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V65.1","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V65.2","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V65.3","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného člena posádky kamióna pri nedopravnej nehode"},
{"code":"V65.4","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V65.5","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V65.6","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V65.7","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V65.9","desc":"Poranenie člena posádky kamióna pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného člena posádky kamióna pri dopravnej nehode"},
{"code":"V66.-","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom"},
{"code":"V66.0","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V66.1","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V66.2","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V66.3","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného člena posádky kamióna pri nedopravnej nehode"},
{"code":"V66.4","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V66.5","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V66.6","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V66.7","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V66.9","desc":"Poranenie člena posádky kamióna pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného člena posádky kamióna pri dopravnej nehode"},
{"code":"V67.-","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom"},
{"code":"V67.0","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V67.1","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V67.2","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V67.3","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky kamióna pri nedopravnej nehode"},
{"code":"V67.4","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V67.5","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri dopravnej nehode"},
{"code":"V67.6","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V67.7","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V67.9","desc":"Poranenie člena posádky kamióna pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky kamióna pri dopravnej nehode"},
{"code":"V68.-","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky"},
{"code":"V68.0","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky, poranenie vodiča pri nedopravnej nehode"},
{"code":"V68.1","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V68.2","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V68.3","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky kamióna pri nedopravnej nehode"},
{"code":"V68.4","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V68.5","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky, poranenie vodiča pri dopravnej nehode"},
{"code":"V68.6","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V68.7","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V68.9","desc":"Poranenie člena posádky kamióna pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky kamióna pri dopravnej nehode"},
{"code":"V69.-","desc":"Poranenie člena posádky kamióna pri inej a bližšie neurčenej dopravnej nehode"},
{"code":"V69.0","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V69.1","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V69.2","desc":"Poranenie bližšie neurčeného člena posádky kamióna pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V69.3","desc":"Poranenie (ktoréhokoľvek) člena posádky kamióna pri bližšie neurčenej nedopravnej nehode"},
{"code":"V69.4","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V69.5","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V69.6","desc":"Poranenie bližšie neurčeného člena posádky kamióna pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V69.8","desc":"Poranenie (ktoréhokoľvek) člena posádky kamióna pri inej bližšie určenej dopravnej nehode"},
{"code":"V69.9","desc":"Poranenie (ktoréhokoľvek) člena posádky kamióna pri bližšie neurčenej dopravnej nehode"},
{"code":"V70-V79","desc":"Poranenia člena posádky autobusu pri dopravnej nehode"},
{"code":"V70.-","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom"},
{"code":"V70.0","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V70.1","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V70.2","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom, poranenie osoby vozidla pri nedopravnej nehode"},
{"code":"V70.3","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posádky autobusu pri nedopravnej nehode"},
{"code":"V70.4","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V70.5","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom, poranenie vodiča pri dopravnej nehode"},
{"code":"V70.6","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V70.7","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V70.9","desc":"Poranenie člena posádky autobusu pri zrážke s chodcom alebo zvieraťom, poranenie bližšie neurčeného člena posádky pri dopravnej nehode"},
{"code":"V71.-","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom"},
{"code":"V71.0","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V71.1","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V71.2","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V71.3","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky autobusu pri nedopravnej nehode"},
{"code":"V71.4","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V71.5","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom, poranenie vodiča pri dopravnej nehode"},
{"code":"V71.6","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V71.7","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V71.9","desc":"Poranenie člena posádky autobusu pri zrážke s bicyklistom, poranenie bližšie neurčeného člena posádky pri dopravnej nehode"},
{"code":"V72.-","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V72.0","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V72.1","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V72.2","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V72.3","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky autobusu pri nedopravnej nehode"},
{"code":"V72.4","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V72.5","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V72.6","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V72.7","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V72.9","desc":"Poranenie člena posádky autobusu pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom, poranenie bližšie neurčeného člena posádky autobusu pri dopravnej nehode"},
{"code":"V73.-","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom"},
{"code":"V73.0","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V73.1","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V73.2","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V73.3","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky autobusu pri nedopravnej nehode"},
{"code":"V73.4","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V73.5","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie vodiča pri dopravnej nehode"},
{"code":"V73.6","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V73.7","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V73.9","desc":"Poranenie člena posádky autobusu pri zrážke s osobným, dodávkovým alebo nákladným autom, poranenie bližšie neurčeného člena posádky autobusu pri dopravnej nehode"},
{"code":"V74.-","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom"},
{"code":"V74.0","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V74.1","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V74.2","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V74.3","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného člena posádky autobusu pri nedopravnej nehode"},
{"code":"V74.4","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V74.5","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom, poranenie vodiča pri dopravnej nehode"},
{"code":"V74.6","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V74.7","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V74.9","desc":"Poranenie člena posádky autobusu pri zrážke s kamiónom alebo autobusom, poranenie bližšie neurčeného člena posádky pri dopravnej nehode"},
{"code":"V75.-","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom"},
{"code":"V75.0","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V75.1","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V75.2","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V75.3","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného člena posádky autobusu pri nedopravnej nehode"},
{"code":"V75.4","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V75.5","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V75.6","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V75.7","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V75.9","desc":"Poranenie člena posádky autobusu pri zrážke s vlakom alebo koľajovým vozidlom, poranenie bližšie neurčeného člena posádky autobusu pri dopravnej nehode"},
{"code":"V76.-","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom"},
{"code":"V76.0","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V76.1","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V76.2","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V76.3","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného člena posádky autobusu pri nedopravnej nehode"},
{"code":"V76.4","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V76.5","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom, poranenie vodiča pri dopravnej nehode"},
{"code":"V76.6","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V76.7","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V76.9","desc":"Poranenie člena posádky autobusu pri zrážke s iným nemotorovým vozidlom, poranenie bližšie neurčeného cestujúceho pri dopravnej nehode"},
{"code":"V77.-","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom"},
{"code":"V77.0","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri nedopravnej nehode"},
{"code":"V77.1","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V77.2","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V77.3","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky autobusu pri nedopravnej nehode"},
{"code":"V77.4","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V77.5","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie vodiča pri dopravnej nehode"},
{"code":"V77.6","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V77.7","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V77.9","desc":"Poranenie člena posádky autobusu pri zrážke s pevným alebo nepohyblivým predmetom, poranenie bližšie neurčeného člena posádky pri dopravnej nehode"},
{"code":"V78.-","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky"},
{"code":"V78.0","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky, poranenie vodiča pri nedopravnej nehode"},
{"code":"V78.1","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky, poranenie cestujúceho pri nedopravnej nehode"},
{"code":"V78.2","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri nedopravnej nehode"},
{"code":"V78.3","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky autobusu pri nedopravnej nehode"},
{"code":"V78.4","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky, poranenie osoby pri vystupovaní alebo nastupovaní"},
{"code":"V78.5","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky, poranenie vodiča pri dopravnej nehode"},
{"code":"V78.6","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky, poranenie cestujúceho pri dopravnej nehode"},
{"code":"V78.7","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky, poranenie osoby mimo vozidla pri dopravnej nehode"},
{"code":"V78.9","desc":"Poranenie člena posádky autobusu pri dopravnej nehode bez zrážky, poranenie bližšie neurčeného člena posádky autobusu pri dopravnej nehode"},
{"code":"V79.-","desc":"Poranenie člena posádky autobusu pri iných a bližšie neurčených dopravných nehodách"},
{"code":"V79.0","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V79.1","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V79.2","desc":"Poranenie bližšie neurčeného člena posádky autobusu pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri nedopravnej nehode"},
{"code":"V79.3","desc":"Poranenie (ktoréhokoľvek) člena posádky autobusu pri bližšie neurčenej nedopravnej nehode"},
{"code":"V79.4","desc":"Poranenie vodiča pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V79.5","desc":"Poranenie cestujúceho pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V79.6","desc":"Poranenie bližšie neurčeného člena posádky autobusu pri zrážke s inými a bližšie neurčenými motorovými vozidlami pri dopravnej nehode"},
{"code":"V79.8","desc":"Poranenie (ktoréhokoľvek) člena posádky autobusu pri iných bližšie určených dopravných nehodách"},
{"code":"V79.9","desc":"Poranenie (ktoréhokoľvek) člena posádky autobusu pri bližšie neurčenej dopravnej nehode"},
{"code":"V80-V89","desc":"Dopravné nehody pri použití iných suchozemských dopravných prostriedkov"},
{"code":"V80.-","desc":"Poranenie jazdca alebo člena posádky vozidla ťahaného zvieraťom pri dopravnej nehode"},
{"code":"V80.0","desc":"Poranenie jazdca alebo člena posádky pri páde alebo pri zhodení zo zvieraťa alebo z vozidla, ktoré ťahá zviera, pri nehode bez zrážky"},
{"code":"V80.1","desc":"Poranenie jazdca alebo člena posádky pri zrážke s chodcom alebo zvieraťom"},
{"code":"V80.2","desc":"Poranenie jazdca alebo člena posádky pri zrážke s bicyklom"},
{"code":"V80.3","desc":"Poranenie jazdca alebo člena posádky pri zrážke s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V80.4","desc":"Poranenie jazdca alebo člena posádky pri zrážke s osobným autom, dodávkovým alebo nákladným autom, kamiónom alebo autobusom"},
{"code":"V80.5","desc":"Poranenie jazdca alebo člena posádky pri zrážke s iným bližšie určeným motorovým vozidlom"},
{"code":"V80.6","desc":"Poranenie jazdca alebo člena posádky pri zrážke s vlakom alebo koľajovým vozidlom"},
{"code":"V80.7","desc":"Poranenie jazdca alebo člena posádky pri zrážke s iným nemotorovým vozidlom"},
{"code":"V80.8","desc":"Poranenie jazdca alebo člena posádky pri zrážke s pevným alebo nepohyblivým predmetom"},
{"code":"V80.9","desc":"Poranenie jazdca alebo člena posádky pri iných a bližšie neurčených dopravných nehodách"},
{"code":"V81.-","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri dopravnej nehode"},
{"code":"V81.0","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri zrážke s motorovým vozidlom pri nedopravnej nehode"},
{"code":"V81.1","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri zrážke s motorovým vozidlom pri dopravnej nehode"},
{"code":"V81.2","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri zrážke s inými vozňami alebo ich narazením"},
{"code":"V81.3","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri zrážke s iným predmetom"},
{"code":"V81.4","desc":"Poranenie osoby nastupujúcej do vlaku alebo koľajového vozidla, prípadne vystupujúcej z nich"},
{"code":"V81.5","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri páde vo vlaku alebo v koľajovom vozidle"},
{"code":"V81.6","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri páde z vlaku alebo z koľajového vozidla"},
{"code":"V81.7","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri vykoľajení bez predchádzajúcej zrážky"},
{"code":"V81.8","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri iných bližšie určených vlakových nehodách"},
{"code":"V81.9","desc":"Poranenie člena posádky vlaku alebo koľajového vozidla pri bližšie neurčenej vlakovej nehode"},
{"code":"V82.-","desc":"Poranenie člena posádky vozidla miestnej dopravy pri dopravnej nehode"},
{"code":"V82.0","desc":"Poranenie člena posádky vozidla miestnej dopravy pri zrážke s motorovým vozidlom pri nedopravnej nehode"},
{"code":"V82.1","desc":"Poranenie člena posádky vozidla miestnej dopravy pri zrážke s motorovým vozidlom pri dopravnej nehode"},
{"code":"V82.2","desc":"Poranenie člena posádky vozidla miestnej dopravy pri zrážke s inými vozňami alebo ich narazením"},
{"code":"V82.3","desc":"Poranenie cestujúceho električkou pri zrážke s iným predmetom"},
{"code":"V82.4","desc":"Poranenie osoby nastupujúcej do električky alebo vystupujúcej z električky"},
{"code":"V82.5","desc":"Poranenie cestujúceho električkou pri páde v električke"},
{"code":"V82.6","desc":"Poranenie cestujúceho električkou pri páde z električky"},
{"code":"V82.7","desc":"Poranenie cestujúceho električkou pri vykoľajení bez predchádzajúcej zrážky"},
{"code":"V82.8","desc":"Poranenie cestujúceho električkou pri inej bližšie určenej dopravnej nehode"},
{"code":"V82.9","desc":"Poranenie cestujúceho električkou pri bližšie neurčenej dopravnej nehode"},
{"code":"V83.-","desc":"Poranenie cestujúceho v špeciálnom vozidle používanom najmä v priemyselných objektoch pri dopravnej nehode"},
{"code":"V83.0","desc":"Poranenie vodiča špeciálneho priemyselného vozidla pri dopravnej nehode"},
{"code":"V83.1","desc":"Poranenie cestujúceho špeciálneho priemyselného vozidla pri dopravnej nehode"},
{"code":"V83.2","desc":"Poranenie osoby mimo špeciálneho priemyselného vozidla pri dopravnej nehode"},
{"code":"V83.3","desc":"Poranenie bližšie neurčeného člena posádky v špeciálnom priemyselnom vozidle pri dopravnej nehode"},
{"code":"V83.4","desc":"Poranenie osoby pri nastupovaní do špeciálneho priemyselného vozidla alebo pri vystupovaní z neho"},
{"code":"V83.5","desc":"Poranenie vodiča špeciálneho priemyselného vozidla pri nedopravnej nehode"},
{"code":"V83.6","desc":"Poranenie cestujúceho špeciálneho priemyselného vozidla pri nedopravnej nehode"},
{"code":"V83.7","desc":"Poranenie osoby mimo špeciálneho priemyselného vozidla pri nedopravnej nehode"},
{"code":"V83.9","desc":"Poranenie bližšie neurčeného člena posádky špeciálnym priemyselným vozidlom pri nedopravnej nehode"},
{"code":"V84.-","desc":"Poranenie člena posádky v špeciálnom vozidle používanom najmä v poľnohospodárstve pri dopravnej nehode"},
{"code":"V84.0","desc":"Poranenie vodiča špeciálneho poľnohospodárskeho vozidla pri dopravnej nehode"},
{"code":"V84.1","desc":"Poranenie cestujúceho v špeciálnom poľnohospodárskom vozidle pri dopravnej nehode"},
{"code":"V84.2","desc":"Poranenie osoby mimo špeciálneho poľnohospodárskeho vozidla pri dopravnej nehode"},
{"code":"V84.3","desc":"Poranenie bližšie neurčeného cestujúceho špeciálnym poľnohospodárskym vozidlom pri dopravnej nehode"},
{"code":"V84.4","desc":"Poranenie osoby nastupujúcej do špeciálneho poľnohospodárskeho vozidla alebo vystupujúcej z neho"},
{"code":"V84.5","desc":"Poranenie vodiča špeciálneho poľnohospodárskeho vozidla pri nedopravnej nehode"},
{"code":"V84.6","desc":"Poranenie cestujúceho špeciálnym poľnohospodárskym vozidlom pri nedopravnej nehode"},
{"code":"V84.7","desc":"Poranenie osoby mimo špeciálneho poľnohospodárskeho vozidla pri nedopravnej nehode"},
{"code":"V84.9","desc":"Poranenie bližšie neurčeného cestujúceho špeciálnym poľnohospodárskym vozidlom pri nedopravnej nehode"},
{"code":"V85.-","desc":"Poranenie člena posádky špeciálneho stavebného vozidla pri dopravnej nehode"},
{"code":"V85.0","desc":"Poranenie vodiča špeciálneho stavebného vozidla pri dopravnej nehode"},
{"code":"V85.1","desc":"Poranenie cestujúceho v špeciálnom stavebnom vozidle pri dopravnej nehode"},
{"code":"V85.2","desc":"Poranenie osoby mimo špeciálneho stavebného vozidla pri dopravnej nehode"},
{"code":"V85.3","desc":"Poranenie bližšie neurčeného člena posádky špeciálneho stavebného vozidla pri dopravnej nehode"},
{"code":"V85.4","desc":"Poranenie osoby nastupujúcej do špeciálneho stavebného vozidla alebo vystupujúcej z neho"},
{"code":"V85.5","desc":"Poranenie vodiča špeciálneho stavebného vozidla pri nedopravnej nehode"},
{"code":"V85.6","desc":"Poranenie cestujúceho v špeciálnom stavebnom vozidle pri nedopravnej nehode"},
{"code":"V85.7","desc":"Poranenie osoby mimo špeciálneho stavebného vozidla pri nedopravnej nehode"},
{"code":"V85.9","desc":"Poranenie bližšie neurčeného člena posádky špeciálneho stavebného vozidla pri nedopravnej nehode"},
{"code":"V86.-","desc":"Poranenie člena posádky v špeciálnom terénnom vozidle, alebo inak upravenom motorovom vozidle na použitie mimo ciest pri dopravnej nehode"},
{"code":"V86.0","desc":"Poranenie vodiča špeciálneho terénneho vozidla alebo iného motorového vozidla na použitie mimo ciest pri dopravnej nehode"},
{"code":"V86.1","desc":"Poranenie cestujúceho v špeciálnom terénnom vozidle alebo v inom motorovom vozidle na použitie mimo ciest pri dopravnej nehode"},
{"code":"V86.2","desc":"Poranenie osoby mimo špeciálneho terénneho vozidla alebo iného motorového vozidla na použitie mimo ciest pri dopravnej nehode"},
{"code":"V86.3","desc":"Poranenie bližšie neurčeného člena posádky špeciálneho terénneho vozidla alebo iného motorového vozidla na použitie mimo ciest pri dopravnej nehode"},
{"code":"V86.4","desc":"Poranenie osoby pri nastupovaní do špeciálneho terénneho vozidla či iného motorového vozidla na použitie mimo ciest alebo pri vystupovaní z neho"},
{"code":"V86.5","desc":"Poranenie vodiča špeciálneho terénneho vozidla alebo iného motorového vozidla na použitie mimo ciest pri nedopravnej nehode"},
{"code":"V86.6","desc":"Poranenie cestujúceho v špeciálnom terénnom vozidle alebo v inom motorovom vozidle na použitie mimo ciest pri nedopravnej nehode"},
{"code":"V86.7","desc":"Poranenie osoby mimo špeciálneho terénneho vozidla alebo iného motorového vozidla na použitie mimo ciest pri nedopravnej nehode"},
{"code":"V86.9","desc":"Poranenie bližšie neurčeného člena posádky v špeciálnom terénnom vozidle alebo v inom motorovom vozidle na použitie mimo ciest pri nedopravnej nehode"},
{"code":"V87.-","desc":"Presne určený typ dopravnej nehody, ale spôsob dopravy poraneného neznámy"},
{"code":"V87.0","desc":"Poranenie osoby pri (dopravnej) zrážke osobného auta s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V87.1","desc":"Poranenie osoby pri (dopravnej) zrážke iného motorového vozidla s dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V87.2","desc":"Poranenie osoby pri (dopravnej) zrážke osobného auta s dodávkovým alebo nákladným autom"},
{"code":"V87.3","desc":"Poranenie osoby pri (dopravnej) zrážke osobného auta s autobusom"},
{"code":"V87.4","desc":"Poranenie osoby pri (dopravnej) zrážke osobného auta s kamiónom"},
{"code":"V87.5","desc":"Poranenie osoby pri (dopravnej) zrážke kamióna s autobusom"},
{"code":"V87.6","desc":"Poranenie osoby pri (dopravnej) zrážke vlaku alebo koľajového vozidla s osobným autom"},
{"code":"V87.7","desc":"Poranenie osoby pri (dopravnej) zrážke medzi inými bližšie určenými motorovými vozidlami"},
{"code":"V87.8","desc":"Poranenie osoby pri iných bližšie určených dopravných nehodách s účasťou motorového vozidla bez (dopravnej) zrážky"},
{"code":"V87.9","desc":"Poranenie osoby pri iných bližšie určených dopravných nehodách (so zrážkou) (bez zrážky) s účasťou motorového vozidla"},
{"code":"V88.-","desc":"Presne určený typ nedopravnej nehody, ale spôsob dopravy poraneného neznámy"},
{"code":"V88.0","desc":"Poranenie osoby pri nedopravnej zrážke medzi osobným autom a dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V88.1","desc":"Poranenie osoby pri nedopravnej zrážke medzi iným motorovým vozidlom a dvojkolesovým alebo trojkolesovým motorovým vozidlom"},
{"code":"V88.2","desc":"Poranenie osoby pri nedopravnej zrážke medzi osobným autom a dodávkovým alebo nákladným autom"},
{"code":"V88.3","desc":"Poranenie osoby pri nedopravnej zrážke medzi osobným autom a autobusom"},
{"code":"V88.4","desc":"Poranenie osoby pri nedopravnej zrážke medzi osobným autom a kamiónom"},
{"code":"V88.5","desc":"Poranenie osoby pri nedopravnej zrážke medzi kamiónom a autobusom"},
{"code":"V88.6","desc":"Poranenie osoby pri nedopravnej zrážke medzi vlakom alebo koľajovým vozidlom a osobným autom"},
{"code":"V88.7","desc":"Poranenie osoby pri nedopravnej zrážke medzi inými bližšie určenými motorovými vozidlami"},
{"code":"V88.8","desc":"Poranenie osoby pri iných bližšie určených dopravných nehodách bez zrážky s účasťou motorového vozidla"},
{"code":"V88.9","desc":"Poranenie osoby pri iných bližšie určených nedopravných prepravných nehodách s účasťou nemotorového vozidla (so zrážkou) (bez zrážky)"},
{"code":"V89.-","desc":"Dopravná nehoda motorového alebo nemotorového vozidla bez bližšieho určenia typu vozidla"},
{"code":"V89.0","desc":"Poranenie osoby pri nedopravnej nehode bližšie neurčeného motorového vozidla"},
{"code":"V89.1","desc":"Poranenie osoby pri nedopravnej nehode bližšie neurčeného nemotorového vozidla"},
{"code":"V89.2","desc":"Poranenie osoby pri dopravnej nehode bližšie neurčeného motorového vozidla"},
{"code":"V89.3","desc":"Poranenie osoby pri dopravnej nehode bližšie neurčeného nemotorového vozidla"},
{"code":"V89.9","desc":"Poranenie osoby pri nehode bližšie neurčeného vozidla"},
{"code":"V90-V94","desc":"Dopravné nehody pri vodnej doprave"},
{"code":"V90.-","desc":"Nehoda plavidla ako príčina utopenia a ponorenia"},
{"code":"V90.0","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: obchodná loď"},
{"code":"V90.1","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: osobná loď"},
{"code":"V90.2","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: rybársky čln"},
{"code":"V90.3","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: iné poháňané plavidlo"},
{"code":"V90.4","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: plachetnica"},
{"code":"V90.5","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: kanoe alebo kajak"},
{"code":"V90.6","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: nafukovacie plavidlo (nemotorové)"},
{"code":"V90.7","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: vodné lyže"},
{"code":"V90.8","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: iné nemotorové vodné plavidlá"},
{"code":"V90.9","desc":"Nehoda plavidla ako príčina utopenia a ponorenia: bližšie neurčené plavidlo"},
{"code":"V91.-","desc":"Nehoda plavidla ako príčina iného poranenia"},
{"code":"V91.0","desc":"Nehoda plavidla ako príčina iného poranenia: obchodná loď"},
{"code":"V91.1","desc":"Nehoda plavidla ako príčina iného poranenia: osobná loď"},
{"code":"V91.2","desc":"Nehoda plavidla ako príčina iného poranenia: rybársky čln"},
{"code":"V91.3","desc":"Nehoda plavidla ako príčina iného poranenia: iné poháňané plavidlo"},
{"code":"V91.4","desc":"Nehoda plavidla ako príčina iného poranenia: plachetnica"},
{"code":"V91.5","desc":"Nehoda plavidla ako príčina iného poranenia: kanoe alebo kajak"},
{"code":"V91.6","desc":"Nehoda plavidla ako príčina iného poranenia: nafukovacie plavidlo (nemotorové)"},
{"code":"V91.7","desc":"Nehoda plavidla ako príčina iného poranenia: vodné lyže"},
{"code":"V91.8","desc":"Nehoda plavidla ako príčina iného poranenia: iné nemotorové vodné plavidlá"},
{"code":"V91.9","desc":"Nehoda plavidla ako príčina iného poranenia: bližšie neurčené plavidlo"},
{"code":"V92.-","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla"},
{"code":"V92.0","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: obchodná loď"},
{"code":"V92.1","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: osobná loď"},
{"code":"V92.2","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: rybársky čln"},
{"code":"V92.3","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: iné poháňané plavidlo"},
{"code":"V92.4","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: plachetnica"},
{"code":"V92.5","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: kanoe alebo kajak"},
{"code":"V92.6","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: nafukovacie plavidlo (nemotorové)"},
{"code":"V92.7","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: vodné lyže"},
{"code":"V92.8","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: iné nemotorové vodné plavidlá"},
{"code":"V92.9","desc":"Utopenie a ponorenie pri vodnej doprave bez nehody plavidla: bližšie neurčené plavidlo"},
{"code":"V93.-","desc":"Nehoda na palube plavidla bez nehody plavidla nezapríčiňujúca utopenie a ponorenie"},
{"code":"V93.0","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: obchodná loď"},
{"code":"V93.1","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: osobná loď"},
{"code":"V93.2","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: rybársky čln"},
{"code":"V93.3","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: iné poháňané plavidlo"},
{"code":"V93.4","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: plachetnica"},
{"code":"V93.5","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: kanoe alebo kajak"},
{"code":"V93.6","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: nafukovacie plavidlo (nemotorové)"},
{"code":"V93.7","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: vodné lyže"},
{"code":"V93.8","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: iné nemotorové vodné plavidlá"},
{"code":"V93.9","desc":"Nehoda na palube plavidla bez nehody plavidla, nezapríčiňujúca utopenie a ponorenie: bližšie neurčené plavidlo"},
{"code":"V94.-","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave"},
{"code":"V94.0","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: obchodná loď"},
{"code":"V94.1","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: osobná loď"},
{"code":"V94.2","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: rybársky čln"},
{"code":"V94.3","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: iné poháňané plavidlo"},
{"code":"V94.4","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: plachetnica"},
{"code":"V94.5","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: kanoe alebo kajak"},
{"code":"V94.6","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: nafukovacie plavidlo (nemotorové)"},
{"code":"V94.7","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: vodné lyže"},
{"code":"V94.8","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: iné nemotorové vodné plavidlá"},
{"code":"V94.9","desc":"Iná a bližšie neurčená nehoda pri vodnej doprave: bližšie neurčené plavidlo"},
{"code":"V95-V97","desc":"Nehody pri preprave vzduchom a vesmírnym priestorom"},
{"code":"V95.-","desc":"Nehoda motorového lietadla ako príčina zranenia člena posádky"},
{"code":"V95.0","desc":"Nehoda helikoptéry ako príčina zranenia člena posádky"},
{"code":"V95.1","desc":"Nehoda ultraľahkého, mikroľahkého alebo motorového klzáka (vetroňa) ako príčina zranenia člena posádky"},
{"code":"V95.2","desc":"Nehoda iného súkromného lietadla s pevnými krídlami ako príčina zranenia člena posádky"},
{"code":"V95.3","desc":"Nehoda komerčného lietadla s pevnými krídlami ako príčina zranenia člena posádky"},
{"code":"V95.4","desc":"Nehoda kozmickej lode ako príčina zranenia člena posádky"},
{"code":"V95.8","desc":"Iná letecká nehoda ako príčina zranenia člena posádky"},
{"code":"V95.9","desc":"Bližšie neurčená letecká nehoda ako príčina zranenia člena posádky"},
{"code":"V96.-","desc":"Nehoda bezmotorového lietadla ako príčina zranenia člena posádky"},
{"code":"V96.0","desc":"Nehoda balóna ako príčina zranenia člena posádky"},
{"code":"V96.1","desc":"Nehoda závesného klzáka ako príčina zranenia člena posádky"},
{"code":"V96.2","desc":"Nehoda bezmotorového klzáka ako príčina zranenia člena posádky"},
{"code":"V96.8","desc":"Nehoda iného bezmotorového lietadla ako príčina zranenia člena posádky"},
{"code":"V96.9","desc":"Nehoda bližšie neurčeného bezmotorového lietadla ako príčina zranenia člena posádky"},
{"code":"V97.-","desc":"Iné bližšie určené dopravné letecké nehody"},
{"code":"V97.0","desc":"Poranenie cestujúceho v lietadle pri inej bližšie určenej leteckej dopravnej nehode"},
{"code":"V97.1","desc":"Poranenie cestujúceho počas nastupovania alebo vystupovania z lietadla"},
{"code":"V97.2","desc":"Zranenie parašutistu pri leteckej dopravnej nehode"},
{"code":"V97.3","desc":"Poranenie osoby na zemi pri leteckej dopravnej nehode"},
{"code":"V97.8","desc":"Iné nehody pri leteckej preprave, nezatriedené inde"},
{"code":"V98-V99","desc":"Iné a bližšie neurčené dopravné nehody"},
{"code":"V98","desc":"Iná dopravná nehoda, bližšie určená"},
{"code":"V99","desc":"Dopravná nehoda, bližšie neurčená"},
{"code":"W00-X59","desc":"Iné vonkajšie príčiny náhodných poranení"},
{"code":"W00-W19","desc":"Pády"},
{"code":"W00","desc":"Pád na tej istej úrovni na ľade a snehu"},
{"code":"W00.0","desc":"Pád na tej istej rovine na ľade a snehu - MU: Domov"},
{"code":"W00.1","desc":"Pád na tej istej rovine na ľade a snehu - MU: Kolektívne ústavné zariadenia"},
{"code":"W00.2","desc":"Pád na tej istej rovine na ľade a snehu - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W00.3","desc":"Pád na tej istej rovine na ľade a snehu - MU: Športové a atletické priestranstvo"},
{"code":"W00.4","desc":"Pád na tej istej rovine na ľade a snehu - MU: Ulica a cesta"},
{"code":"W00.5","desc":"Pád na tej istej rovine na ľade a snehu - MU: Obchod a služby"},
{"code":"W00.6","desc":"Pád na tej istej rovine na ľade a snehu - MU: Priemyselné objekty"},
{"code":"W00.7","desc":"Pád na tej istej rovine na ľade a snehu - MU: Poľnohospodárske"},
{"code":"W00.8","desc":"Pád na tej istej rovine na ľade a snehu - MU: Iné špecifikované miesta"},
{"code":"W00.9","desc":"Pád na tej istej rovine na ľade a snehu - MU: Nešpecifikované miesto"},
{"code":"W01","desc":"Pád na tej istej úrovni následkom pošmyknutia, potknutia a zakopnutia"},
{"code":"W01.0","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Domov"},
{"code":"W01.1","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Kolektívne ústavné zariadenia"},
{"code":"W01.2","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W01.3","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Športové a atletické priestranstvo"},
{"code":"W01.4","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Ulica a cesta"},
{"code":"W01.5","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Obchod a služby"},
{"code":"W01.6","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Priemyselné objekty"},
{"code":"W01.7","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Poľnohospodárske"},
{"code":"W01.8","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Iné špecifikované miesta"},
{"code":"W01.9","desc":"Pád na tej istej rovine pri pokĺznutí, podvrtnutí a potknutí - MU: Nešpecifikované miesto"},
{"code":"W02","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo jazde na skateboarde"},
{"code":"W02.0","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Domov"},
{"code":"W02.1","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Kolektívne ústavné zariadenia"},
{"code":"W02.2","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W02.3","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Športové a atletické priestranstvo"},
{"code":"W02.4","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Ulica a cesta"},
{"code":"W02.5","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Obchod a služby"},
{"code":"W02.6","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Priemyselné objekty"},
{"code":"W02.7","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Poľnohospodárske"},
{"code":"W02.8","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Iné špecifikované miesta"},
{"code":"W02.9","desc":"Pád pri korčuľovaní, lyžovaní, korčuľovaní na kolieskových korčuliach alebo skateboarde - MU: Nešpecifikované miesto"},
{"code":"W03","desc":"Iný pád na tej istej rovine, zapríčinený zrážkou s inou osobou alebo sotením inou osobou"},
{"code":"W03.0","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou - MU: Domov"},
{"code":"W03.1","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou - MU: Kolektívne ústavné zariadenia"},
{"code":"W03.2","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou- MU: Školy, iné inštit. a admin. budovy"},
{"code":"W03.3","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou- MU: Športové a atletické priestranstvo"},
{"code":"W03.4","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou - MU: Ulica a cesta"},
{"code":"W03.5","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou - MU: Obchod a služby"},
{"code":"W03.6","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou - MU: Priemyselné objekty"},
{"code":"W03.7","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou - MU: Poľnohospodárske"},
{"code":"W03.8","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou - MU: Iné špecifikované miesta"},
{"code":"W03.9","desc":"Iný pád na tej istej rovine zapríčinený zrážkou s inou osobou alebo strčením inou osobou - MU: Nešpecifikované miesto"},
{"code":"W04","desc":"Pád pri nesení alebo podopieraní inými osobami"},
{"code":"W04.0","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Domov"},
{"code":"W04.1","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Kolektívne ústavné zariadenia"},
{"code":"W04.2","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W04.3","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Športové a atletické priestranstvo"},
{"code":"W04.4","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Ulica a cesta"},
{"code":"W04.5","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Obchod a služby"},
{"code":"W04.6","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Priemyselné objekty"},
{"code":"W04.7","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Poľnohospodárske"},
{"code":"W04.8","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Iné špecifikované miesta"},
{"code":"W04.9","desc":"Pád pri nesení alebo podopieraní inými osobami - MU: Nešpecifikované miesto"},
{"code":"W05","desc":"Pád z invalidného vozíka (kolieskovej stoličky)"},
{"code":"W05.0","desc":"Pád zo stoličky s kolieskami - MU: Domov"},
{"code":"W05.1","desc":"Pád zo stoličky s kolieskami - MU: Kolektívne ústavné zariadenia"},
{"code":"W05.2","desc":"Pád zo stoličky s kolieskami - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W05.3","desc":"Pád zo stoličky s kolieskami - MU: Športové a atletické priestranstvo"},
{"code":"W05.4","desc":"Pád zo stoličky s kolieskami - MU: Ulica a cesta"},
{"code":"W05.5","desc":"Pád zo stoličky s kolieskami - MU: Obchod a služby"},
{"code":"W05.6","desc":"Pád zo stoličky s kolieskami - MU: Priemyselné objekty"},
{"code":"W05.7","desc":"Pád zo stoličky s kolieskami - MU: Poľnohospodárske"},
{"code":"W05.8","desc":"Pád zo stoličky s kolieskami - MU: Iné špecifikované miesta"},
{"code":"W05.9","desc":"Pád zo stoličky s kolieskami - MU: Nešpecifikované miesto"},
{"code":"W06","desc":"Pád z postele"},
{"code":"W06.0","desc":"Pád z postele - MU: Domov"},
{"code":"W06.1","desc":"Pád z postele - MU: Kolektívne ústavné zariadenia"},
{"code":"W06.2","desc":"Pád z postele - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W06.3","desc":"Pád z postele - MU: Športové a atletické priestranstvo"},
{"code":"W06.4","desc":"Pád z postele - MU: Ulica a cesta"},
{"code":"W06.5","desc":"Pád z postele - MU: Obchod a služby"},
{"code":"W06.6","desc":"Pád z postele - MU: Priemyselné objekty"},
{"code":"W06.7","desc":"Pád z postele - MU: Poľnohospodárske"},
{"code":"W06.8","desc":"Pád z postele - MU: Iné špecifikované miesta"},
{"code":"W06.9","desc":"Pád z postele - MU: Nešpecifikované miesto"},
{"code":"W07","desc":"Pád zo stoličky"},
{"code":"W07.0","desc":"Pád zo stoličky - MU: Domov"},
{"code":"W07.1","desc":"Pád zo stoličky - MU: Kolektívne ústavné zariadenia"},
{"code":"W07.2","desc":"Pád zo stoličky - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W07.3","desc":"Pád zo stoličky - MU: Športové a atletické priestranstvo"},
{"code":"W07.4","desc":"Pád zo stoličky - MU: Ulica a cesta"},
{"code":"W07.5","desc":"Pád zo stoličky - MU: Obchod a služby"},
{"code":"W07.6","desc":"Pád zo stoličky - MU: Priemyselné objekty"},
{"code":"W07.7","desc":"Pád zo stoličky - MU: Poľnohospodárske"},
{"code":"W07.8","desc":"Pád zo stoličky - MU: Iné špecifikované miesta"},
{"code":"W07.9","desc":"Pád zo stoličky - MU: Nešpecifikované miesto"},
{"code":"W08","desc":"Pád z iného nábytku"},
{"code":"W08.0","desc":"Pád z iného nábytku - MU: Domov"},
{"code":"W08.1","desc":"Pád z iného nábytku - MU: Kolektívne ústavné zariadenia"},
{"code":"W08.2","desc":"Pád z iného nábytku - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W08.3","desc":"Pád z iného nábytku - MU: Športové a atletické priestranstvo"},
{"code":"W08.4","desc":"Pád z iného nábytku - MU: Ulica a cesta"},
{"code":"W08.5","desc":"Pád z iného nábytku - MU: Obchod a služby"},
{"code":"W08.6","desc":"Pád z iného nábytku - MU: Priemyselné objekty"},
{"code":"W08.7","desc":"Pád z iného nábytku - MU: Poľnohospodárske"},
{"code":"W08.8","desc":"Pád z iného nábytku - MU: Iné špecifikované miesta"},
{"code":"W08.9","desc":"Pád z iného nábytku - MU: Nešpecifikované miesto"},
{"code":"W09","desc":"Pád z náradia ihriska"},
{"code":"W09.0","desc":"Pád z náradia ihriska - MU: Domov"},
{"code":"W09.1","desc":"Pád z náradia ihriska - MU: Kolektívne ústavné zariadenia"},
{"code":"W09.2","desc":"Pád z náradia ihriska - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W09.3","desc":"Pád z náradia ihriska - MU: Športové a atletické priestranstvo"},
{"code":"W09.4","desc":"Pád z náradia ihriska - MU: Ulica a cesta"},
{"code":"W09.5","desc":"Pád z náradia ihriska - MU: Obchod a služby"},
{"code":"W09.6","desc":"Pád z náradia ihriska - MU: Priemyselné objekty"},
{"code":"W09.7","desc":"Pád z náradia ihriska - MU: Poľnohospodárske"},
{"code":"W09.8","desc":"Pád z náradia ihriska - MU: Iné špecifikované miesta"},
{"code":"W09.9","desc":"Pád z náradia ihriska - MU: Nešpecifikované miesto"},
{"code":"W10","desc":"Pád na schody a zo schodov alebo stupňov"},
{"code":"W10.0","desc":"Pád na schody a zo schodov alebo stupňov - MU: Domov"},
{"code":"W10.1","desc":"Pád na schody a zo schodov alebo stupňov - MU: Kolektívne ústavné zariadenia"},
{"code":"W10.2","desc":"Pád na schody a zo schodov alebo stupňov - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W10.3","desc":"Pád na schody a zo schodov alebo stupňov - MU: Športové a atletické priestranstvo"},
{"code":"W10.4","desc":"Pád na schody a zo schodov alebo stupňov - MU: Ulica a cesta"},
{"code":"W10.5","desc":"Pád na schody a zo schodov alebo stupňov - MU: Obchod a služby"},
{"code":"W10.6","desc":"Pád na schody a zo schodov alebo stupňov - MU: Priemyselné objekty"},
{"code":"W10.7","desc":"Pád na schody a zo schodov alebo stupňov - MU: Poľnohospodárske"},
{"code":"W10.8","desc":"Pád na schody a zo schodov alebo stupňov - MU: Iné špecifikované miesta"},
{"code":"W10.9","desc":"Pád na schody a zo schodov alebo stupňov - MU: Nešpecifikované miesto"},
{"code":"W11.0","desc":"Pád na rebrík a z rebríka - MU: Domov"},
{"code":"W11","desc":"Pád na rebrík a z rebríka"},
{"code":"W12","desc":"Pád na lešenie a z lešenia"},
{"code":"W13","desc":"Pád z budovy alebo konštrukcie alebo prepadnutie nimi"},
{"code":"W14","desc":"Pád zo stromu"},
{"code":"W15","desc":"Pád z brala (skaly)"},
{"code":"W16","desc":"Ponáranie alebo skákanie do vody ako príčina poranenia iného, ako je utopenie alebo topenie"},
{"code":"W17","desc":"Iný pád z jednej úrovne na druhú"},
{"code":"W18","desc":"Iný pád na tej istej úrovni"},
{"code":"W19","desc":"Pád, bližšie neurčený"},
{"code":"W20-W49","desc":"Vystavenie účinkom neživých mechanických síl"},
{"code":"W20","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom"},
{"code":"W21","desc":"Narazenie do športového náradia alebo zasiahnutie náradím"},
{"code":"W22","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi"},
{"code":"W23","desc":"Zachytenie, pomliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich"},
{"code":"W24","desc":"Zachytenie zdvíhacími a prenosovými zariadeniami, nezatriedené inde"},
{"code":"W25","desc":"Poranenie (kontakt) ostrým sklom"},
{"code":"W26","desc":"Poranenie (kontakt s) nožom, mečom alebo dýkou"},
{"code":"W27","desc":"Poranenie (kontakt s) ručným náradím bez pohonu"},
{"code":"W28","desc":"Poranenie (kontakt s) motorovým strihačom trávnika"},
{"code":"W29","desc":"Poranenie (kontakt) iným motorovým náradím a prístrojmi pre domácnosť"},
{"code":"W30","desc":"Poranenie (kontakt s) poľnohospodárskymi mechanizmami"},
{"code":"W31","desc":"Poranenie (kontakt s) inými a bližšie neurčenými mechanizmami"},
{"code":"W32","desc":"Zásah výstrelom z krátkej (ručnej) strelnej zbrane"},
{"code":"W33","desc":"Zásah výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane"},
{"code":"W34","desc":"Zásah výstrelom z inej a bližšie neurčenej strelnej zbrane"},
{"code":"W35","desc":"Zásah výbuchom a puknutie nádrže bojlera"},
{"code":"W36","desc":"Zásah výbuchom a puknutie plynového valca"},
{"code":"W37","desc":"Zásah výbuchom a puknutie nafúkanej pneumatiky, rúry alebo hadice"},
{"code":"W38","desc":"Zásah výbuchom a puknutie iných bližšie určených zariadení pod tlakom"},
{"code":"W39","desc":"Zásah výstrelom z ohňostroja"},
{"code":"W40","desc":"Zásah výbuchom iných materiálov"},
{"code":"W41","desc":"Vystavenie účinku prúdenia pod vysokým tlakom"},
{"code":"W42","desc":"Vystavenie hluku"},
{"code":"W43","desc":"Vystavenie vibráciám"},
{"code":"W44","desc":"Cudzie teleso vnikajúce do oka, cez oko alebo iný prirodzený otvor tela"},
{"code":"W45.-","desc":"Cudzie teleso alebo predmet prenikajúci cez kožu"},
{"code":"W46","desc":"Kontakt s injekčnou ihlou"},
{"code":"W49.-!","desc":"Vystavenie inej a bližšie neurčenej neživej mechanickej sile"},
{"code":"W49.9!","desc":"Poranenie účinkom inej a bližšie neurčenej neživej mechanickej sily"},
{"code":"W50-W64","desc":"Vystavenie účinkom živých mechanických síl"},
{"code":"W50","desc":"Udretie, bitie, kopnutie, vykrútenie, uštipnutie, alebo poškriabanie inou osobou"},
{"code":"W51","desc":"Sotenie alebo narazenie sotením do inej osoby"},
{"code":"W52","desc":"Pomliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike"},
{"code":"W53","desc":"Uhryznutie potkanom"},
{"code":"W54","desc":"Uhryznutie alebo udretie psom"},
{"code":"W55","desc":"Uhryznutie alebo udretie iným cicavcom"},
{"code":"W56","desc":"Poranenie morským zvieraťom"},
{"code":"W57","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a iným nejedovatým článkonožcom"},
{"code":"W58","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom"},
{"code":"W59","desc":"Uhryznutie alebo pomliaždenie iným plazom"},
{"code":"W60.-","desc":"Kontakt s rastlinnými tŕňmi, pichliačmi a ostrými listami"},
{"code":"W64.-!","desc":"Vystavenie bližšie neurčenej živej mechanickej sile"},
{"code":"W64.9!","desc":"Nehoda s vystavením bližšie neurčenej živej mechanickej sile"},
{"code":"W65-W74","desc":"Náhodné utopenie a topenie"},
{"code":"W65","desc":"Utopenie a topenie vo vani"},
{"code":"W66","desc":"Utopenie a topenie po páde do vane"},
{"code":"W67","desc":"Utopenie a topenie v plaveckom bazéne"},
{"code":"W68","desc":"Utopenie a topenie po páde do plaveckého bazéna"},
{"code":"W69","desc":"Utopenie a topenie v prírodných vodách"},
{"code":"W70","desc":"Utopenie a topenie pri páde do vody v prírodných vodách"},
{"code":"W73","desc":"Iné utopenie a topenie, bližšie určené"},
{"code":"W74","desc":"Utopenie a topenie, bližšie neurčené"},
{"code":"W75-W84","desc":"Iné náhodné udusenia"},
{"code":"W75","desc":"Náhodné udusenie a zaškrtenie v posteli"},
{"code":"W76","desc":"Iné náhodné obesenie a zaškrtenie"},
{"code":"W77","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi"},
{"code":"W78","desc":"Vdýchnutie obsahu žalúdka (vývratkov)"},
{"code":"W79","desc":"Vdýchnutie a prehltnutie potravy, zapríčiňujúce zapchatie dýchacích ciest"},
{"code":"W80","desc":"Vdýchnutie a prehltnutie iných predmetov, zapríčiňujúce zapchatie dýchacích ciest"},
{"code":"W81","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka"},
{"code":"W83","desc":"Iné bližšie určené ohrozenie dýchania"},
{"code":"W84","desc":"Bližšie neurčené ohrozenie dýchania"},
{"code":"W85-W99","desc":"Vystavenie elektrickému prúdu, žiareniu, extrémnej teplote a tlaku okolitého vzduchu"},
{"code":"W85","desc":"Vystavenie elektrickému vedeniu"},
{"code":"W86","desc":"Vystavenie inému bližšie určenému elektrickému prúdu"},
{"code":"W87.-!","desc":"Vystavenie bližšie neurčenému elektrickému prúdu"},
{"code":"W87.9!","desc":"Nehoda s vystavením elektrickému prúdu"},
{"code":"W88","desc":"Vystavenie ionizujúcemu žiareniu"},
{"code":"W89","desc":"Vystavenie umelému viditeľnému a ultrafialovému svetlu"},
{"code":"W90","desc":"Vystavenie inému neionizujúcemu žiareniu"},
{"code":"W91.-!","desc":"Vystavenie bližšie neurčenému žiareniu"},
{"code":"W91.9!","desc":"Nehoda s vystavením žiareniu"},
{"code":"W92.-!","desc":"Vystavenie nadmernej horúčave umelého pôvodu"},
{"code":"W92.9!","desc":"Nehoda s vystavením nadmernej horúčave umelého pôvodu"},
{"code":"W93.-!","desc":"Vystavenie nadmernému chladu umelého pôvodu"},
{"code":"W93.9!","desc":"Nehoda s vystavením nadmernému chladu umelého pôvodu"},
{"code":"W94.-!","desc":"Vystavenie vysokému alebo nízkemu tlaku vzduchu, alebo zmenám tlaku vzduchu"},
{"code":"W94.9!","desc":"Nehoda s vystavením zmenám tlaku vzduchu"},
{"code":"W99!","desc":"Vystavenie inému a bližšie neurčenému umelému činiteľovi prostredia"},
{"code":"X00-X09","desc":"Vystavenie dymu, ohňu a plameňom"},
{"code":"X00","desc":"Vystavenie nekontrolovanému ohňu v budove alebo stavbe"},
{"code":"X01","desc":"Vystavenie nekontrolovanému ohňu mimo budovy alebo stavby"},
{"code":"X02","desc":"Vystavenie kontrolovanému ohňu v budove alebo stavbe"},
{"code":"X03","desc":"Vystavenie kontrolovanému ohňu mimo budovy alebo stavby"},
{"code":"X04","desc":"Vystavenie zapáleniu veľmi horľavého materiálu"},
{"code":"X05","desc":"Vystavenie zapáleniu alebo roztopeniu nočného oblečenia"},
{"code":"X06","desc":"Vystavenie zapáleniu alebo roztopeniu iného oblečenia a šiat"},
{"code":"X08","desc":"Vystavenie inému bližšie určenému dymu, ohňu a plameňu"},
{"code":"X09","desc":"Vystavenie bližšie neurčenému dymu, ohňu a plameňu"},
{"code":"X10-X19","desc":"Kontakt s horúčavou a horúcimi látkami"},
{"code":"X10","desc":"Kontakt s horúcim nápojom, potravinami, tukom a olejom na varenie"},
{"code":"X11","desc":"Kontakt s horúcou vodovodnou vodou"},
{"code":"X12","desc":"Kontakt s inou horúcou tekutinou"},
{"code":"X13","desc":"Kontakt s vodnou parou a horúcimi výparmi"},
{"code":"X14","desc":"Kontakt s horúcim vzduchom a plynom"},
{"code":"X15","desc":"Kontakt s horúcimi nádobami a prístrojmi v domácnosti"},
{"code":"X16","desc":"Kontakt s horúcimi varičmi, radiátormi a rúrami"},
{"code":"X17","desc":"Kontakt s horúcimi motormi, strojovým zariadením a nástrojmi"},
{"code":"X18","desc":"Kontakt s inými horúcimi kovmi"},
{"code":"X19.-!","desc":"Kontakt s inou a bližšie neurčenou horúčavou alebo horúcou látkou"},
{"code":"X19.9!","desc":"Popálenie alebo obarenie inou a bližšie neurčenou horúčavou alebo horúcou látkou"},
{"code":"X20-X29","desc":"Kontakt s jedovatými živočíchmi a rastlinami ako príčina otravy alebo toxickej reakcie"},
{"code":"X20","desc":"Kontakt s jedovatým hadom alebo jaštericou"},
{"code":"X21","desc":"Kontakt s jedovatým pavúkom"},
{"code":"X22","desc":"Kontakt so škorpiónom"},
{"code":"X23","desc":"Kontakt so sršňom, osou alebo včelou"},
{"code":"X24","desc":"Kontakt so stonožkou a jedovatou tropickou mnohonožkou"},
{"code":"X25","desc":"Kontakt s iným bližšie určeným jedovatým článkonožcom"},
{"code":"X26","desc":"Kontakt s jedovatým morským zvieraťom alebo rastlinou"},
{"code":"X27","desc":"Kontakt s iným bližšie určeným jedovatým zvieraťom"},
{"code":"X28","desc":"Kontakt s inou bližšie určenou jedovatou rastlinou"},
{"code":"X29.-!","desc":"Kontakt s bližšie neurčeným jedovatým zvieraťom a rastlinou"},
{"code":"X29.9!","desc":"Nehoda s kontaktom s bližšie neurčeným jedovatým zvieraťom a rastlinou"},
{"code":"X30-X39","desc":"Vystavenie účinkom prírodných síl"},
{"code":"X30","desc":"Vystavenie nadmernej prirodzenej horúčave"},
{"code":"X31","desc":"Vystavenie nadmernému prirodzenému chladu"},
{"code":"X32","desc":"Vystavenie slnečnému svetlu"},
{"code":"X33","desc":"Obeť blesku"},
{"code":"X34.-","desc":"Obeť zemetrasenia"},
{"code":"X34.0","desc":"Obeť ničivých pohybov zeme pri zemetrasení"},
{"code":"X34.1","desc":"Obeť cunami"},
{"code":"X34.8","desc":"Obeť bližšie určených účinkov zemetrasenia"},
{"code":"X34.9","desc":"Obeť bližšie neurčených účinkov zemetrasenia"},
{"code":"X35","desc":"Obeť výbuchu sopky"},
{"code":"X36","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme"},
{"code":"X37","desc":"Obeť ničivej víchrice"},
{"code":"X38","desc":"Obeť záplavy"},
{"code":"X39","desc":"Poškodenie účinkami iných a bližšie neurčených prírodných síl"},
{"code":"X40-X49","desc":"Náhodné otravy a priotrávenia škodlivými látkami"},
{"code":"X40","desc":"Náhodná otrava a priotrávenie neopioidovými analgetikami, antipyretikami a antireumatikami"},
{"code":"X41","desc":"Náhodná otrava a priotrávenie antiepileptikami, sedatívami, hypnotikami, antiparkinsonikami a psychotropnými liekmi, nezatriedená inde"},
{"code":"X42","desc":"Náhodná otrava a priotrávenie opioidovými analgetikami a psychodysleptikami (halucinogénmi), nezatriedená inde"},
{"code":"X43","desc":"Náhodná otrava a priotrávenie inými liečivami, účinkujúcimi na autonómnu nervovú sústavu"},
{"code":"X44","desc":"Náhodná otrava a priotrávenie inými a bližšie neurčenými liečivami, liekmi a biologickými látkami"},
{"code":"X45","desc":"Náhodná otrava a priotrávenie alkoholom"},
{"code":"X46","desc":"Náhodná otrava a priotrávenie organickými rozpúšťadlami, halogénovanými uhľovodíkmi a ich výparmi"},
{"code":"X47","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi"},
{"code":"X48","desc":"Náhodná otrava a priotrávenie pesticídmi"},
{"code":"X49.-!","desc":"Náhodná otrava a priotrávenie inými a bližšie neurčenými chemikáliami a škodlivými látkami"},
{"code":"X49.9!","desc":"Náhodná otrava"},
{"code":"X50-X57","desc":"Preťaženie, cestovanie a nedostatok základných potrieb"},
{"code":"X50","desc":"Preťaženie a namáhavé alebo opakované pohyby"},
{"code":"X51","desc":"Cestovanie a pohyb"},
{"code":"X52","desc":"Dlhší pobyt v beztiažovom prostredí"},
{"code":"X53","desc":"Nedostatok potravy"},
{"code":"X54","desc":"Nedostatok vody"},
{"code":"X57","desc":"Bližšie neurčený nedostatok"},
{"code":"X58-X59","desc":"Náhodné vystavenie iným a bližšie neurčeným vplyvom"},
{"code":"X58","desc":"Vystavenie inému bližšie určenému vplyvu"},
{"code":"X59.-!","desc":"Vystavenie bližšie neurčenému vplyvu"},
{"code":"X59.9!","desc":"Vystavenie bližšie neurčenému vplyvu, spôsobujúcemu iné a bližšie neurčené poranenie"},
{"code":"X60-X84","desc":"Úmyselné samopoškodenia"},
{"code":"X60","desc":"Úmyselné samopoškodenie otravou a priotrávením neopioidovými analgetikami, antipyretikami a antireumatikami"},
{"code":"X61","desc":"Úmyselné samopoškodenie otravou a priotrávením antiepileptikami, sedatívami, hypnotikami, antiparkinsonikami a psychotropnými liekmi, nezatriedené inde"},
{"code":"X62","desc":"Úmyselné samopoškodenie otravou a priotrávením opioidovými analgetikami a psychodysleptikami (halucinogénmi), nezatriedené inde"},
{"code":"X63","desc":"Úmyselné samopoškodenie otravou a priotrávením liekmi, účinkujúcimi na autonómnu nervovú sústavu"},
{"code":"X64","desc":"Úmyselné samopoškodenie otravou a priotrávením inými a bližšie neurčenými liekmi, liečivami a biologicky aktívnymi látkami"},
{"code":"X65","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholom"},
{"code":"X66","desc":"Úmyselné samopoškodenie otravou a priotrávením organickými rozpúšťadlami, halogénovanými uhľovodíkmi a ich výparmi"},
{"code":"X67","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi"},
{"code":"X68","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi"},
{"code":"X69","desc":"Úmyselné samopoškodenie otravou a priotrávením inými a bližšie neurčenými chemickými a škodlivými látkami"},
{"code":"X70","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením"},
{"code":"X71","desc":"Úmyselné samopoškodenie utopením a topením"},
{"code":"X72","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane"},
{"code":"X73","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane"},
{"code":"X74","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane"},
{"code":"X75","desc":"Úmyselné samopoškodenie výbušnou látkou"},
{"code":"X76","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi"},
{"code":"X77","desc":"Úmyselné samopoškodenie parou, horúcimi výparmi a horúcimi predmetmi"},
{"code":"X78","desc":"Úmyselné samopoškodenie ostrým predmetom"},
{"code":"X79","desc":"Úmyselné samopoškodenie tupým predmetom"},
{"code":"X80","desc":"Úmyselné samopoškodenie skokom z výšky"},
{"code":"X81","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet"},
{"code":"X82","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom"},
{"code":"X83","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami"},
{"code":"X84.-!","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami"},
{"code":"X84.9!","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami"},
{"code":"X85-Y09","desc":"Napadnutia (útoky)"},
{"code":"X85","desc":"Napadnutie liekmi, liečivami a biologicky aktívnymi látkami"},
{"code":"X86","desc":"Napadnutie žieravinami"},
{"code":"X87","desc":"Napadnutie pesticídmi"},
{"code":"X88","desc":"Napadnutie plynmi a výparmi"},
{"code":"X89","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami"},
{"code":"X90","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami"},
{"code":"X91","desc":"Napadnutie obesením, zaškrtením a zahrdúsením"},
{"code":"X92","desc":"Napadnutie utopením a topením"},
{"code":"X93","desc":"Napadnutie výstrelom z ručnej strelnej zbrane"},
{"code":"X94","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane"},
{"code":"X95","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane"},
{"code":"X96","desc":"Napadnutie výbušnými látkami"},
{"code":"X97","desc":"Napadnutie dymom, požiarom a plameňmi"},
{"code":"X98","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi"},
{"code":"X99","desc":"Napadnutie ostrým predmetom"},
{"code":"Y00","desc":"Napadnutie tupým predmetom"},
{"code":"Y01","desc":"Napadnutie zhodením z výšky"},
{"code":"Y02","desc":"Napadnutie sotením alebo umiestnením obete pred pohybujúci sa predmet"},
{"code":"Y03","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom"},
{"code":"Y04","desc":"Napadnutie s použitím fyzickej sily"},
{"code":"Y05","desc":"Sexuálne napadnutie s použitím fyzickej sily"},
{"code":"Y06.-","desc":"Zanedbanie starostlivosti a opustenie"},
{"code":"Y06.0","desc":"Zanedbanie starostlivosti a opustenie manželským partnerom, druhom alebo družkou"},
{"code":"Y06.1","desc":"Zanedbanie starostlivosti a opustenie rodičom"},
{"code":"Y06.2","desc":"Zanedbanie starostlivosti a opustenie známym alebo priateľom"},
{"code":"Y06.8","desc":"Zanedbanie starostlivosti a opustenie inou bližšie určenou osobou"},
{"code":"Y06.9","desc":"Zanedbanie starostlivosti a opustenie bližšie neurčenou osobou"},
{"code":"Y07.-","desc":"Iný syndróm týrania"},
{"code":"Y07.0","desc":"Iný syndróm týrania manželom, manželkou alebo partnerom, partnerkou"},
{"code":"Y07.1","desc":"Iný syndróm týrania rodičom"},
{"code":"Y07.2","desc":"Iný syndróm týrania známym alebo priateľom"},
{"code":"Y07.3","desc":"Iný syndróm týrania úradnými osobami"},
{"code":"Y07.8","desc":"Iný syndróm týrania inými bližšie určenými osobami"},
{"code":"Y07.9","desc":"Iný syndróm týrania bližšie neurčenou osobou"},
{"code":"Y08","desc":"Napadnutie iným bližšie určeným spôsobom"},
{"code":"Y09.-!","desc":"Násilný útok"},
{"code":"Y09.9!","desc":"Násilný útok"},
{"code":"Y10-Y34","desc":"Udalosti s neurčeným úmyslom"},
{"code":"Y10","desc":"Otrava a priotrávenie neopioidovými analgetikami, antipyretikami a antireumatikami a vystavenie týmto látkam, s neurčeným úmyslom"},
{"code":"Y11","desc":"Otrava antiepileptikami, sedatívami, hypnotikami, antiparkinsonikami a psychotropnými liekmi, nezatriedenými inde, a vystavenie týmto látkam, s neurčeným úmyslom"},
{"code":"Y12","desc":"Otrava opioidovými analgetikami a halucinogénmi (psychodysleptikami), nezatriedená inde, a vystavenie týmto látkam, s neurčeným úmyslom"},
{"code":"Y13","desc":"Otrava inými liekmi, účinkujúcimi na autonómnu nervovú sústavu a vystavenie týmto látkam, s neurčeným úmyslom"},
{"code":"Y14","desc":"Otrava inými a bližšie neurčenými liečivami, liekmi a biologickými látkami a vystavenie týmto látkam, s neurčeným úmyslom"},
{"code":"Y15","desc":"Otrava alkoholom a vystavenie (expozícia) alkoholu, s neurčeným úmyslom"},
{"code":"Y16","desc":"Otrava organickými rozpúšťadlami, halogénovanými uhľovodíkmi a ich výparmi a vystavenie týmto látkam, s neurčeným úmyslom"},
{"code":"Y17","desc":"Otrava inými plynmi a výparmi a vystavenie týmto látkam, s neurčeným úmyslom"},
{"code":"Y18","desc":"Otrava pesticídmi a vystavenie týmto látkam, s neurčeným úmyslom"},
{"code":"Y19","desc":"Otrava inými a bližšie neurčenými chemikáliami a škodlivými látkami a vystavenie týmto látkam, s neurčeným úmyslom"},
{"code":"Y20","desc":"Obesenie, zaškrtenie a udusenie, s neurčeným úmyslom"},
{"code":"Y21","desc":"Utopenie a topenie, s neurčeným úmyslom"},
{"code":"Y22","desc":"Zásah výstrelom z ručnej zbrane, s neurčeným úmyslom"},
{"code":"Y23","desc":"Zásah výstrelom z pušky (guľovnice), brokovnice a dlhej strelnej zbrane, s neurčeným úmyslom"},
{"code":"Y24","desc":"Zásah výstrelom z inej a bližšie neurčenej strelnej zbrane, s neurčeným úmyslom"},
{"code":"Y25","desc":"Kontakt s výbušnou látkou, s neurčeným úmyslom"},
{"code":"Y26","desc":"Vystavenie dymu, požiaru a plameňom, s neurčeným úmyslom"},
{"code":"Y27","desc":"Kontakt s parou, horúcimi výparmi a horúcimi predmetmi, s neurčeným úmyslom"},
{"code":"Y28","desc":"Kontakt s ostrým predmetom, s neurčeným úmyslom"},
{"code":"Y29","desc":"Kontakt s tupým predmetom, s neurčeným úmyslom"},
{"code":"Y30","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom"},
{"code":"Y31","desc":"Pád, ľahnutie si alebo vbehnutie pred pohybujúci sa predmet alebo do neho, s neurčeným úmyslom"},
{"code":"Y32","desc":"Nehoda motorového vozidla, s neurčeným úmyslom"},
{"code":"Y33","desc":"Iná udalosť s neurčeným úmyslom, bližšie určená"},
{"code":"Y34.-!","desc":"Udalosť s neurčeným úmyslom, bližšie neurčená"},
{"code":"Y34.9!","desc":"Udalosť s neurčeným úmyslom, bližšie neurčená"},
{"code":"Y35-Y36","desc":"Zákonné výkony a vojnové operácie"},
{"code":"Y35.-!","desc":"Poranenia pri zákonnom zásahu"},
{"code":"Y35.0!","desc":"Poranenia pri zákonnom zásahu s použitím strelnej zbrane"},
{"code":"Y35.1!","desc":"Poranenia pri zákonnom zásahu s použitím výbušnín"},
{"code":"Y35.2!","desc":"Poranenia pri zákonnom zásahu s použitím plynu"},
{"code":"Y35.3!","desc":"Poranenia pri zákonnom zásahu s použitím tupých predmetov"},
{"code":"Y35.4!","desc":"Poranenia pri zákonnom zásahu s použitím ostrého predmetu"},
{"code":"Y35.5!","desc":"Zákonná poprava"},
{"code":"Y35.6!","desc":"Poranenia pri zákonnom zásahu s použitím iných bližšie určených prostriedkov"},
{"code":"Y35.7!","desc":"Poranenia pri zákonnom zásahu s použitím bližšie neurčených prostriedkov"},
{"code":"Y36.-!","desc":"Poranenia pri vojnovej operácii"},
{"code":"Y36.0!","desc":"Poranenia pri vojnovej operácii s použitím výbušných námorných zbraní"},
{"code":"Y36.1!","desc":"Poranenia pri vojnovej operácii, spôsobujúcej zničenie lietadla"},
{"code":"Y36.2!","desc":"Poranenia pri vojnovej operácii s použitím výbušnín a črepín"},
{"code":"Y36.3!","desc":"Poranenia pri vojnovej operácii s použitím ohňa, požiaru a horúcich látok"},
{"code":"Y36.4!","desc":"Poranenia pri vojnovej operácii s použitím výstrelov zo strelných zbraní a s inými konvenčnými zbraňami"},
{"code":"Y36.5!","desc":"Poranenia pri vojnovej operácii s použitím jadrových zbraní"},
{"code":"Y36.6!","desc":"Poranenia pri vojnovej operácii s použitím biologických zbraní"},
{"code":"Y36.7!","desc":"Poranenia pri vojnovej operácii s použitím chemických zbraní a iných foriem nekonvenčného vedenia vojny"},
{"code":"Y36.8!","desc":"Poranenia pri vojnovej operácii, uskutočnenej po zastavení bojov"},
{"code":"Y36.9!","desc":"Poranenia pri vojnovej operácii, bližšie neurčenej"},
{"code":"Y40-Y84","desc":"Komplikácie lekárskej starostlivosti"},
{"code":"Y40-Y59","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití liekov, liečiv a biologicky aktívnych látok"},
{"code":"Y40.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: celkovo účinkujúce antibiotiká"},
{"code":"Y40.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: penicilíny"},
{"code":"Y40.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: cefalosporíny a iné beta-laktámové antibiotiká"},
{"code":"Y40.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: skupina chloramfenikolu"},
{"code":"Y40.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: makrolidy"},
{"code":"Y40.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: tetracyklíny"},
{"code":"Y40.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: aminoglykozidy"},
{"code":"Y40.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: rifamycíny"},
{"code":"Y40.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antimykotiká použité systémovo"},
{"code":"Y40.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné celkovo účinkujúce antibiotiká"},
{"code":"Y40.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: celkovo účinkujúce antibiotikum, bližšie neurčené"},
{"code":"Y41.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné celkovo účinkujúce protiinfekčné a antiparazitové lieky"},
{"code":"Y41.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: sulfonamidy"},
{"code":"Y41.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antituberkulotiká (protimykobaktériové lieky)"},
{"code":"Y41.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antimalariká a lieky účinkujúce na iné krvné prvoky (protozoá)"},
{"code":"Y41.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné antiprotozoálne lieky"},
{"code":"Y41.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antihelmintiká"},
{"code":"Y41.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: protivírusové lieky"},
{"code":"Y41.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné bližšie určené celkovo účinkujúce protiinfekčné a antiparazitové lieky"},
{"code":"Y41.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: celkovo účinkujúce protiinfekčné a antiparazitové lieky, bližšie neurčené"},
{"code":"Y42.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: hormóny, ich syntetické náhrady a antagonisti, nezatriedené inde"},
{"code":"Y42.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: glukokortikoidy a ich syntetické analógy"},
{"code":"Y42.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: tyreoidálne hormóny a ich náhrady"},
{"code":"Y42.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antityreoidálne lieky"},
{"code":"Y42.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: inzulín a orálne antidiabetiká"},
{"code":"Y42.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: orálne kontraceptíva"},
{"code":"Y42.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné estrogény a gestagény"},
{"code":"Y42.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antigonadotropíny, antiestrogény, antiandrogény, nezatriedené inde"},
{"code":"Y42.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: androgény a príbuzné anaboliká"},
{"code":"Y42.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené hormóny a ich syntetické náhrady"},
{"code":"Y42.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené antagonisti hormónov"},
{"code":"Y43.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky s primárne celkovým účinkom"},
{"code":"Y43.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antialergické a antiemetické lieky"},
{"code":"Y43.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: protinádorové lieky a antimetabolity"},
{"code":"Y43.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: protinádorové prírodné prípravky"},
{"code":"Y43.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné protinádorové lieky"},
{"code":"Y43.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: imunosupresívne látky"},
{"code":"Y43.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: acidifikujúce a alkalizujúce látky"},
{"code":"Y43.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: enzýmy, nezatriedené inde"},
{"code":"Y43.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné látky s primárne celkovým účinkom, nezatriedené inde"},
{"code":"Y43.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látka s celkovým účinkom, bližšie neurčená"},
{"code":"Y44.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky s primárnym účinkom na zložky krvi"},
{"code":"Y44.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: prípravky železa a iné prípravky proti hypochrómnej anémii"},
{"code":"Y44.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: vitamín B12, kyselina listová a prípravky proti megaloblastovej anémii"},
{"code":"Y44.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antikoagulanciá"},
{"code":"Y44.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antagonisti antikoagulancií, vitamín K a iné koagulanciá"},
{"code":"Y44.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antitrombotické lieky (inhibítory agregácie trombocytov)"},
{"code":"Y44.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: trombolytické lieky"},
{"code":"Y44.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: prirodzená krv a krvné prípravky"},
{"code":"Y44.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: náhrady plazmy"},
{"code":"Y44.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené látky, účinkujúce na zložky krvi"},
{"code":"Y45.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: analgetiká, antipyretiká a protizápalové lieky"},
{"code":"Y45.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: opioidy a podobné analgetiká"},
{"code":"Y45.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: salicyláty"},
{"code":"Y45.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: deriváty kyseliny propiónovej"},
{"code":"Y45.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné nesteroidné protizápalové lieky (NSAID)"},
{"code":"Y45.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antireumatiká"},
{"code":"Y45.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: deriváty 4-aminofenolu"},
{"code":"Y45.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné analgetiká a antipyretiká"},
{"code":"Y45.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: analgetiká, antipyretiká a protizápalové lieky, bližšie neurčené"},
{"code":"Y46.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antiepileptiká a antiparkinsoniká"},
{"code":"Y46.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: sukcinimidy"},
{"code":"Y46.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: oxazolidíndióny"},
{"code":"Y46.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: deriváty hydantoínu"},
{"code":"Y46.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: deoxybarbituráty"},
{"code":"Y46.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iminostilbény"},
{"code":"Y46.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: kyselina valproová"},
{"code":"Y46.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené antiepileptiká"},
{"code":"Y46.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antiparkinsoniká"},
{"code":"Y46.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lieky proti spasticite"},
{"code":"Y47.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: sedatíva, hypnotiká a anxiolytiká"},
{"code":"Y47.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: barbituráty, nezatriedené inde"},
{"code":"Y47.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: benzodiazepíny"},
{"code":"Y47.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: chloralové deriváty"},
{"code":"Y47.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: paraldehyd"},
{"code":"Y47.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: zlúčeniny brómu"},
{"code":"Y47.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: zmiešané sedatíva a hypnotiká, nezatriedené inde"},
{"code":"Y47.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné sedatíva, hypnotiká a anxiolytiká"},
{"code":"Y47.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: sedatíva, hypnotiká a anxiolytiká, bližšie neurčené"},
{"code":"Y48.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: anestetiká a liečebné plyny"},
{"code":"Y48.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: inhalačné anestetiká"},
{"code":"Y48.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: parenterálne anestetiká"},
{"code":"Y48.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené celkové anestetiká"},
{"code":"Y48.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lokálne anestetiká"},
{"code":"Y48.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: anestetikum, bližšie neurčené"},
{"code":"Y48.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: liečebné plyny"},
{"code":"Y49.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: psychotropné lieky, nezatriedené inde"},
{"code":"Y49.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: tricyklické a tetracyklické antidepresíva"},
{"code":"Y49.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antidepresíva zo skupiny inhibítorov monoaminooxidázy"},
{"code":"Y49.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené antidepresíva"},
{"code":"Y49.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: fenotiazínové antipsychotiká a neuroleptiká"},
{"code":"Y49.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: butyrofenónové a tioxanténové neuroleptiká"},
{"code":"Y49.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné antipsychotiká a neuroleptiká"},
{"code":"Y49.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: halucinogény (psychodysleptiká)"},
{"code":"Y49.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: psychostimulanciá ohrozujúce návykom"},
{"code":"Y49.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné psychotropné látky, nezatriedené inde"},
{"code":"Y49.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: psychotropný liek, bližšie neurčený"},
{"code":"Y50.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: stimulanciá centrálnej nervovej sústavy, nezatriedené inde"},
{"code":"Y50.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: analeptiká"},
{"code":"Y50.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antagonisti opioidových receptorov"},
{"code":"Y50.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: metylxantíny, nezatriedené inde"},
{"code":"Y50.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné stimulanciá centrálnej nervovej sústavy"},
{"code":"Y50.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: stimulanciá centrálnej nervovej sústavy, bližšie neurčené"},
{"code":"Y51.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: liečivá, účinkujúce najmä na autonómnu nervovú sústavu"},
{"code":"Y51.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: inhibítory cholínesterázy"},
{"code":"Y51.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné parasympatikomimetiká (cholínergiká)"},
{"code":"Y51.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: ganglioplegiká, nezatriedené inde"},
{"code":"Y51.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné parasympatolytiká (anticholínergiká a antimuskariniká) a spazmolytiká, nezatriedené inde"},
{"code":"Y51.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: agonisti prevažne alfa-adrenoreceptorov, nezatriedené inde"},
{"code":"Y51.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: agonisti prevažne beta-adrenoreceptorov, nezatriedené inde"},
{"code":"Y51.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antagonisti alfa-adrenoreceptorov, nezatriedené inde"},
{"code":"Y51.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antagonisti beta-adrenoreceptorov, nezatriedené inde"},
{"code":"Y51.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: centrálne účinkujúce blokátory adrenergného neurónu, nezatriedené inde"},
{"code":"Y51.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené lieky, účinkujúce primárne na autonómnu nervovú sústavu"},
{"code":"Y52.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky, účinkujúce primárne na srdcovocievnu sústavu"},
{"code":"Y52.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: srdcové stimulujúce glykozidy a lieky s podobným účinkom"},
{"code":"Y52.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: kalciové antagonisti"},
{"code":"Y52.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné antiarytmiká, nezatriedené inde"},
{"code":"Y52.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: koronárne vazodilatanciá, nezatriedené inde"},
{"code":"Y52.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: inhibítory enzýmu konvertujúceho angiotenzín"},
{"code":"Y52.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné antihypertenzíva, nezatriedené inde"},
{"code":"Y52.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: hypolipidemiká a antiartériosklerotické lieky"},
{"code":"Y52.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: periférne vazodilatanciá"},
{"code":"Y52.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antivarikózne lieky vrátane sklerotizujúcich látok"},
{"code":"Y52.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené látky, účinkujúce primárne na srdcovocievnu sústavu"},
{"code":"Y53.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky, účinkujúce primárne na tráviacu sústavu"},
{"code":"Y53.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antagonisti histamínových h2 receptorov"},
{"code":"Y53.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné antacidá a lieky, tlmiace žalúdkovú sekréciu"},
{"code":"Y53.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: dráždivé (stimulujúce) preháňadlá"},
{"code":"Y53.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: soľné a osmotické preháňadlá"},
{"code":"Y53.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné preháňadlá"},
{"code":"Y53.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: digestíva"},
{"code":"Y53.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lieky proti hnačke"},
{"code":"Y53.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: emetiká (dávidlá)"},
{"code":"Y53.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné látky, účinkujúce primárne na tráviacu sústavu"},
{"code":"Y53.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látka, účinkujúca primárne na tráviacu sústavu, bližšie neurčená"},
{"code":"Y54.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky, primárne účinkujúce na vodnú rovnováhu a na metabolizmus minerálov a kyseliny močovej"},
{"code":"Y54.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: mineralokortikoidy"},
{"code":"Y54.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antagonisti mineralokortikoidov (antagonisti aldosterónu)"},
{"code":"Y54.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: inhibítory karboanhydrázy"},
{"code":"Y54.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: benzotiadiazínové deriváty"},
{"code":"Y54.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: slučkové diuretiká"},
{"code":"Y54.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné diuretiká"},
{"code":"Y54.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky ovplyvňujúce rovnováhu vody, elektrolytov, a energie"},
{"code":"Y54.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky ovplyvňujúce kalcifikáciu"},
{"code":"Y54.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky ovplyvňujúce metabolizmus kyseliny močovej"},
{"code":"Y54.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: minerálne soli, nezatriedené inde"},
{"code":"Y55.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky, účinkujúce primárne na hladké a kostrové svaly a na dýchaciu sústavu"},
{"code":"Y55.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: oxytociká"},
{"code":"Y55.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: relaxanciá kostrových svalov (neuromuskulárne blokátory)"},
{"code":"Y55.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené látky, primárne účinkujúce na svaly"},
{"code":"Y55.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antitusiká"},
{"code":"Y55.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: expektoranciá"},
{"code":"Y55.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lieky proti prechladnutiu"},
{"code":"Y55.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antiastmatiká, nezatriedené inde"},
{"code":"Y55.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené látky na liečbu chorôb dýchacej sústavy"},
{"code":"Y56.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látky na lokálne použitie, účinkujúce primárne na kožu a sliznicu a lieky používané v oftalmológii, otorinolaryngológii a stomatológii"},
{"code":"Y56.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: protihubové, protiinfekčné a protizápalové lieky s miestnym účinkom, nezatriedené inde"},
{"code":"Y56.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antipruriginóza"},
{"code":"Y56.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lokálne adstringenciá a detergenciá"},
{"code":"Y56.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: emolienciá, demulcenciá a ochranné látky"},
{"code":"Y56.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: keratolytiká, keratoplastiká a iné lieky a prípravky na ošetrenie vlasov"},
{"code":"Y56.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lieky a prípravky používané v oftalmológii"},
{"code":"Y56.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lieky a prípravky používané v otorinolaryngológii"},
{"code":"Y56.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: zubné (dentálne) lieky na miestne použitie"},
{"code":"Y56.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné látky s lokálnym účinkom"},
{"code":"Y56.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: látka s lokálnym účinkom, bližšie neurčená"},
{"code":"Y57.-!","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití liekov a liečiv: iné a bližšie neurčené lieky a liečivá"},
{"code":"Y57.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lieky znižujúce chuť do jedenia (anorektiká)"},
{"code":"Y57.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lipotropné lieky"},
{"code":"Y57.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: antidotá a chelátujúce látky, nezatriedené inde"},
{"code":"Y57.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: lieky odstrašujúce od pitia alkoholu"},
{"code":"Y57.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: farmaceutické excipienciá (vehikulá)"},
{"code":"Y57.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: kontrastné látky používané pri rtg vyšetrení"},
{"code":"Y57.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné látky používané pri diagnostickom vyšetrení"},
{"code":"Y57.7","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: vitamíny, nezatriedené inde"},
{"code":"Y57.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné liečivá a lieky"},
{"code":"Y57.9!","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: liečivo alebo liek, bližšie neurčené"},
{"code":"Y58.-","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: baktériová očkovacia látka"},
{"code":"Y58.0","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka BCG"},
{"code":"Y58.1","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka proti týfusu a paratýfusu"},
{"code":"Y58.2","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka proti cholere"},
{"code":"Y58.3","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka proti moru"},
{"code":"Y58.4","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka proti tetanu"},
{"code":"Y58.5","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka proti diftérii"},
{"code":"Y58.6","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka proti pertussis vrátane kombinácií s pertussis"},
{"code":"Y58.8","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: zmiešané baktériové očkovacie látky okrem kombinácii s pertussis"},
{"code":"Y58.9","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené baktériové očkovacie látky"},
{"code":"Y59.-!","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iné a bližšie neurčené očkovacie látky a biologicky aktívne látky"},
{"code":"Y59.0!","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka proti vírusovým chorobám"},
{"code":"Y59.1!","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka proti ricketsiózam"},
{"code":"Y59.2!","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: očkovacia látka proti protozoám"},
{"code":"Y59.3!","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: imunoglobulín"},
{"code":"Y59.8!","desc":"Nežiaduce vedľajšie účinky pri liečebnom použití: iná bližšie určená očkovacia látka a biologicky aktívna látka"},
{"code":"Y59.9!","desc":"Komplikácie zapríčinené očkovacou látkou a biologicky aktívnou látkou, bližšie neurčenou"},
{"code":"Y60-Y69","desc":"Poškodenia pacientov počas chirurgickej a lekárskej starostlivosti"},
{"code":"Y60.-","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas chirurgickej a lekárskej starostlivosti"},
{"code":"Y60.0","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas chirurgického výkonu"},
{"code":"Y60.1","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas infúzie alebo transfúzie"},
{"code":"Y60.2","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas dialýzy alebo inej perfúzii"},
{"code":"Y60.3","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas injekcie alebo imunizácie"},
{"code":"Y60.4","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas endoskopického vyšetrenia"},
{"code":"Y60.5","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas katetrizácie srdca"},
{"code":"Y60.6","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas aspirácie, punkcie a inej katetrizácie"},
{"code":"Y60.7","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas podávania klyzmy"},
{"code":"Y60.8","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas inej chirurgickej a lekárskej starostlivosti"},
{"code":"Y60.9","desc":"Neúmyselné porezanie, pichnutie, prederavenie alebo krvácanie počas bližšie neurčenej chirurgickej a lekárskej starostlivosti"},
{"code":"Y61.-","desc":"Náhodne ponechaný cudzí predmet v tele počas chirurgickej a lekárskej starostlivosti"},
{"code":"Y61.0","desc":"Náhodne ponechaný cudzí predmet v tele počas chirurgického výkonu"},
{"code":"Y61.1","desc":"Náhodne ponechaný cudzí predmet v tele počas infúzie alebo transfúzie"},
{"code":"Y61.2","desc":"Náhodne ponechaný cudzí predmet v tele počas dialýzy alebo inej perfúzie"},
{"code":"Y61.3","desc":"Náhodne ponechaný cudzí predmet v tele počas injekcie alebo imunizácie"},
{"code":"Y61.4","desc":"Náhodne ponechaný cudzí predmet v tele počas endoskopického vyšetrenia"},
{"code":"Y61.5","desc":"Náhodne ponechaný cudzí predmet v tele počas katetrizácie srdca"},
{"code":"Y61.6","desc":"Náhodne ponechaný cudzí predmet v tele počas aspirácie, punkcie a inej katetrizácie"},
{"code":"Y61.7","desc":"Náhodne ponechaný cudzí predmet v tele počas odstraňovania katétra alebo následného ošetrenia"},
{"code":"Y61.8","desc":"Náhodne ponechaný cudzí predmet v tele počas inej chirurgickej a lekárskej starostlivosti"},
{"code":"Y61.9","desc":"Náhodne ponechaný cudzí predmet v tele počas bližšie neurčenej chirurgickej a lekárskej starostlivosti"},
{"code":"Y62.-","desc":"Nedodržanie zásad sterility počas chirurgickej a lekárskej starostlivosti"},
{"code":"Y62.0","desc":"Nedodržanie zásad sterility počas chirurgického výkonu"},
{"code":"Y62.1","desc":"Nedodržanie zásad sterility počas infúzie alebo transfúzie"},
{"code":"Y62.2","desc":"Nedodržanie zásad sterility počas dialýzy alebo inej perfúzie"},
{"code":"Y62.3","desc":"Nedodržanie zásad sterility počas injekcie alebo imunizácie"},
{"code":"Y62.4","desc":"Nedodržanie zásad sterility počas endoskopického vyšetrenia"},
{"code":"Y62.5","desc":"Nedodržanie zásad sterility počas katetrizácie srdca"},
{"code":"Y62.6","desc":"Nedodržanie zásad sterility počas aspirácie, punkcie a inej katetrizácie"},
{"code":"Y62.8","desc":"Nedodržanie zásad sterility počas inej chirurgickej a lekárskej starostlivosti"},
{"code":"Y62.9","desc":"Nedodržanie zásad sterility počas bližšie neurčenej chirurgickej a lekárskej starostlivosti"},
{"code":"Y63.-","desc":"Nedodržanie dávkovania počas chirurgickej a lekárskej starostlivosti"},
{"code":"Y63.0","desc":"Nedodržanie dávkovania počas chirurgickej a lekárskej starostlivosti: podanie nadmerného množstva krvi alebo inej tekutiny pri transfúzii alebo infúzii"},
{"code":"Y63.1","desc":"Nedodržanie dávkovania počas chirurgickej a lekárskej starostlivosti: nesprávne riedenie tekutiny použitej pri infúzii"},
{"code":"Y63.2","desc":"Nedodržanie dávkovania počas chirurgickej a lekárskej starostlivosti: predávkovanie žiarenia pri terapii"},
{"code":"Y63.3","desc":"Nedodržanie dávkovania počas chirurgickej a lekárskej starostlivosti: ožiarenie pacienta z nepozornosti počas chirurgickej a lekárskej starostlivosti"},
{"code":"Y63.4","desc":"Nedodržanie dávkovania počas chirurgickej a lekárskej starostlivosti: chyba v dávkovaní pri liečbe elektrošokom alebo inzulínovým šokom"},
{"code":"Y63.5","desc":"Nedodržanie dávkovania počas chirurgickej a lekárskej starostlivosti: neprimeraná teplota pri lokálnej aplikácii alebo tampónovaní"},
{"code":"Y63.6","desc":"Nedodržanie dávkovania počas chirurgickej a lekárskej starostlivosti: nepodanie potrebného liečiva, lieku alebo biologickej látky"},
{"code":"Y63.8","desc":"Nedodržanie dávkovania počas inej chirurgickej a lekárskej starostlivosti"},
{"code":"Y63.9","desc":"Nedodržanie dávkovania počas bližšie neurčenej chirurgickej a lekárskej starostlivosti"},
{"code":"Y64.-","desc":"Kontaminované liečivo alebo biologicky aktívna látka"},
{"code":"Y64.0","desc":"Kontaminované liečivá alebo biologické látky: transfúzia alebo infúzia kontaminovaného liečiva alebo biologickej látky"},
{"code":"Y64.1","desc":"Kontaminované liečivá alebo biologické látky: injekcia kontaminovaného liečiva alebo biologickej látky, prípadne jej použitie na imunizáciu (štepenie)"},
{"code":"Y64.8","desc":"Kontaminované liečivá alebo biologické látky: iný spôsob podania kontaminovaného liečiva alebo biologickej látky"},
{"code":"Y64.9","desc":"Kontaminované liečivá alebo biologické látky: bližšie neurčený spôsob podania kontaminovaného lieku alebo biologickej látky"},
{"code":"Y65.-","desc":"Iné poškodenia počas lekárskej starostlivosti"},
{"code":"Y65.0","desc":"Iné poškodenia počas lekárskej starostlivosti: transfúzia inkompatibilnej krvi"},
{"code":"Y65.1","desc":"Iné poškodenia počas lekárskej starostlivosti: infúzia nesprávnej tekutiny"},
{"code":"Y65.2","desc":"Iné poškodenia počas lekárskej starostlivosti: zlyhanie sutúry alebo ligatúry pri chirurgickej operácii"},
{"code":"Y65.3","desc":"Iné poškodenia počas lekárskej starostlivosti: nesprávne umiestnenie endotracheálnej rúrky pri anestetickom výkone"},
{"code":"Y65.4","desc":"Iné poškodenia počas lekárskej starostlivosti: neúspech pri zavádzaní alebo odstraňovaní inej rúrky alebo nástroja"},
{"code":"Y65.5","desc":"Iné poškodenia počas lekárskej starostlivosti: vykonanie neprimeranej operácie"},
{"code":"Y65.8","desc":"Iné poškodenia počas lekárskej starostlivosti: iné bližšie určené nehody lekárskej starostlivosti"},
{"code":"Y66","desc":"Neposkytnutie lekárskej starostlivosti"},
{"code":"Y69!","desc":"Poškodenie počas lekárskej starostlivosti, (bližšie neurčené)"},
{"code":"Y70-Y82","desc":"Nepriaznivé príhody súvisiace s použitím lekárskych technických prístrojov pri diagnostických a liečebných výkonoch"},
{"code":"Y70.-","desc":"Nepriaznivé príhody súvisiace s použitím anestetických prístrojov"},
{"code":"Y70.0","desc":"Nepriaznivé príhody súvisiace s použitím anestetických prístrojov: diagnostické a monitorovacie pomôcky"},
{"code":"Y70.1","desc":"Nepriaznivé príhody súvisiace s použitím anestetických prístrojov: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y70.2","desc":"Nepriaznivé príhody súvisiace s použitím anestetických prístrojov: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y70.3","desc":"Nepriaznivé príhody súvisiace s použitím anestetických prístrojov: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y70.8","desc":"Nepriaznivé príhody súvisiace s použitím anestetických prístrojov: rozličné pomôcky, nezatriedené inde"},
{"code":"Y71.-","desc":"Nepriaznivé príhody súvisiace s použitím srdcovocievnych prístrojov (pomôcok)"},
{"code":"Y71.0","desc":"Nepriaznivé príhody súvisiace s použitím srdcovocievnych prístrojov (pomôcok): diagnostické a monitorovacie pomôcky"},
{"code":"Y71.1","desc":"Nepriaznivé príhody súvisiace s použitím srdcovocievnych prístrojov (pomôcok): liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y71.2","desc":"Nepriaznivé príhody súvisiace s použitím srdcovocievnych prístrojov (pomôcok): protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y71.3","desc":"Nepriaznivé príhody súvisiace s použitím srdcovocievnych prístrojov (pomôcok): chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y71.8","desc":"Nepriaznivé príhody súvisiace s použitím srdcovocievnych prístrojov (pomôcok): rozličné pomôcky, nezatriedené inde"},
{"code":"Y72.-","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v otorinolaryngológii"},
{"code":"Y72.0","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v otorinolaryngológii: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y72.1","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v otorinolaryngológii: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y72.2","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v otorinolaryngológii: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y72.3","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v otorinolaryngológii: chirurgické nástroje, materiály, pomôcky (vrátane sutúr)"},
{"code":"Y72.8","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v otorinolaryngológii: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y73.-","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gastroenterológii a v urológii"},
{"code":"Y73.0","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gastroenterológii a v urológii: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y73.1","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gastroenterológii a v urológii: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y73.2","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gastroenterológii a v urológii: protetické a iné implantáty, materiály, doplnkové pomôcky"},
{"code":"Y73.3","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gastroenterológii a v urológii: chirurgické nástroje, materiály, pomôcky (vrátane sutúr)"},
{"code":"Y73.8","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gastroenterológii a v urológii: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y74.-","desc":"Nepriaznivé príhody súvisiace so zariadeniami a pomôckami na všeobecné nemocničné a osobné použitie"},
{"code":"Y74.0","desc":"Nepriaznivé príhody s pomôckami na všeobecné nemocničné a osobné použitie: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y74.1","desc":"Nepriaznivé príhody s pomôckami na všeobecné nemocničné a osobné použitie: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y74.2","desc":"Nepriaznivé príhody s pomôckami na všeobecné nemocničné a osobné použitie: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y74.3","desc":"Nepriaznivé príhody s pomôckami na všeobecné nemocničné a osobné použitie: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y74.8","desc":"Nepriaznivé príhody s pomôckami na všeobecné nemocničné a osobné použitie: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y75.-","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v neurológii"},
{"code":"Y75.0","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v neurológii: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y75.1","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v neurológii: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y75.2","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v neurológii: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y75.3","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v neurológii: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y75.8","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v neurológii: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y76.-","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gynekológii a pôrodníctve"},
{"code":"Y76.0","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gynekológii a pôrodníctve: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y76.1","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gynekológii a pôrodníctve: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y76.2","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gynekológii a pôrodníctve: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y76.3","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gynekológii a pôrodníctve: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y76.8","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v gynekológii a pôrodníctve: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y77.-","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v oftalmológii"},
{"code":"Y77.0","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v oftalmológii: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y77.1","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v oftalmológii: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y77.2","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v oftalmológii: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y77.3","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v oftalmológii: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y77.8","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v oftalmológii: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y78.-","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v rádiológii"},
{"code":"Y78.0","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v rádiológii: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y78.1","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v rádiológii: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y78.2","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v rádiológii: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y78.3","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v rádiológii: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y78.8","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v rádiológii: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y79.-","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v ortopédii"},
{"code":"Y79.0","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v ortopédii: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y79.1","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v ortopédii: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y79.2","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v ortopédii: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y79.3","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v ortopédii: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y79.8","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok v ortopédii: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y80.-","desc":"Nepriaznivé príhody súvisiace s použitím pomôcok pri fyzikálnej liečbe"},
{"code":"Y80.0","desc":"Nepriaznivé príhody súvisiace s použitím pomôcok pri fyzikálnej liečbe: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y80.1","desc":"Nepriaznivé príhody súvisiace s použitím pomôcok pri fyzikálnej liečbe: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y80.2","desc":"Nepriaznivé príhody súvisiace s použitím pomôcok pri fyzikálnej liečbe: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y80.3","desc":"Nepriaznivé príhody súvisiace s použitím pomôcok pri fyzikálnej liečbe: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y80.8","desc":"Nepriaznivé príhody súvisiace s použitím pomôcok pri fyzikálnej liečbe: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y81.-","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok vo všeobecnej a plastickej chirurgii"},
{"code":"Y81.0","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok vo všeobecnej a plastickej chirurgii: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y81.1","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok vo všeobecnej a plastickej chirurgii: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y81.2","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok vo všeobecnej a plastickej chirurgii: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y81.3","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok vo všeobecnej a plastickej chirurgii: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y81.8","desc":"Nepriaznivé príhody súvisiace s použitím zariadení a pomôcok vo všeobecnej a plastickej chirurgii: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y82.-!","desc":"Nepriaznivé príhody súvisiace s použitím iných a bližšie neurčených lekárskych pomôcok"},
{"code":"Y82.0!","desc":"Nepriaznivé príhody súvisiace s použitím iných a bližšie neurčených lekárskych pomôcok: diagnostické a monitorovacie zariadenia a pomôcky"},
{"code":"Y82.1!","desc":"Nepriaznivé príhody súvisiace s použitím iných a bližšie neurčených lekárskych pomôcok: liečebné (nechirurgické) a rehabilitačné pomôcky"},
{"code":"Y82.2!","desc":"Nepriaznivé príhody súvisiace s použitím iných a bližšie neurčených lekárskych pomôcok: protetické a iné implantáty, materiály a doplnkové pomôcky"},
{"code":"Y82.3!","desc":"Nepriaznivé príhody súvisiace s použitím iných a bližšie neurčených lekárskych pomôcok: chirurgické nástroje, materiály a pomôcky (vrátane sutúr)"},
{"code":"Y82.8!","desc":"Nepriaznivé príhody súvisiace s použitím iných a bližšie neurčených lekárskych pomôcok: rozličné zariadenia a pomôcky, nezatriedené inde"},
{"code":"Y83-Y84","desc":"Chirurgické a iné lekárske výkony ako príčiny abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu"},
{"code":"Y83.-","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu"},
{"code":"Y83.0","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: transplantácia celého orgánu"},
{"code":"Y83.1","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: implantácia umelej vnútornej pomôcky"},
{"code":"Y83.2","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: výkon s anastomózou, obchádzkou (bajpasom) alebo štepom"},
{"code":"Y83.3","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: výkon s vytvorením vonkajšieho ústia (stómie)"},
{"code":"Y83.4","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: iný rekonštrukčný výkon"},
{"code":"Y83.5","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: amputácia končatiny (končatín)"},
{"code":"Y83.6","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: vybratie iného orgánu (čiastočné) (kompletné)"},
{"code":"Y83.8","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: iný chirurgický výkon"},
{"code":"Y83.9","desc":"Chirurgický výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: chirurgický výkon, bližšie neurčený"},
{"code":"Y84.-!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu"},
{"code":"Y84.0!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: srdcová katetrizácia"},
{"code":"Y84.1!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: obličková dialýza"},
{"code":"Y84.2!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: rádiologický výkon a rádioterapia"},
{"code":"Y84.3!","desc":"Iný lekársky výkony ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: šoková terapia"},
{"code":"Y84.4!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: aspirácia tekutiny"},
{"code":"Y84.5!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: zavedenie žalúdkovej alebo dvanástnikovej sondy"},
{"code":"Y84.6!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: katetrizácia močových ciest"},
{"code":"Y84.7!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: odobratie vzorky krvi"},
{"code":"Y84.8!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: iný lekársky výkon"},
{"code":"Y84.9!","desc":"Iný lekársky výkon ako príčina abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu: lekársky výkon, bližšie neurčený"},
{"code":"Y85-Y89","desc":"Neskoré následky vonkajších príčin chorobnosti a úmrtnosti"},
{"code":"Y85.-","desc":"Neskoré následky dopravných nehôd"},
{"code":"Y85.0","desc":"Neskoré následky nehody motorového vozidla"},
{"code":"Y85.9","desc":"Neskoré následky iných a bližšie neurčených dopravných nehôd"},
{"code":"Y86","desc":"Neskoré následky iných nehôd"},
{"code":"Y87.-","desc":"Neskoré následky úmyselného sebapoškodenia, napadnutia a udalostí s neurčeným úmyslom"},
{"code":"Y87.0","desc":"Neskoré následky úmyselného sebapoškodenia"},
{"code":"Y87.1","desc":"Neskoré následky napadnutia"},
{"code":"Y87.2","desc":"Neskoré následky udalostí s neurčeným úmyslom"},
{"code":"Y88.-","desc":"Neskoré následky chirurgickej a inej lekárskej starostlivosti ako vonkajšej príčiny"},
{"code":"Y88.0","desc":"Neskoré následky nepriaznivých účinkov liekov, liečiv a biologicky aktívnych látok pri ich liečebnom použití"},
{"code":"Y88.1","desc":"Neskoré následky nepriaznivých príhod u pacientov počas chirurgického a iného lekárskeho výkonu"},
{"code":"Y88.2","desc":"Neskoré následky nepriaznivých príhod súvisiacich s použitím lekárskych prístrojov pri ich diagnostickom a liečebnom použití"},
{"code":"Y88.3","desc":"Neskoré následky chirurgických a lekárskych výkonov ako príčiny abnormálnej reakcie pacienta alebo neskoršej komplikácie, bez nehody v čase výkonu"},
{"code":"Y89.-","desc":"Neskoré následky iných vonkajších príčin"},
{"code":"Y89.0","desc":"Neskoré následky zákonného zásahu"},
{"code":"Y89.1","desc":"Neskoré následky vojnových operácií"},
{"code":"Y89.9","desc":"Neskoré následky bližšie neurčenej vonkajšej príčiny"},
{"code":"Y90-Y98","desc":"Pridružené faktory súvisiace s príčinami chorobnosti a úmrtnosti, zatriedenými inde"},
{"code":"Y90.-","desc":"Dôkaz vplyvu alkoholu podľa koncentrácie alkoholu v krvi"},
{"code":"Y90.0","desc":"Koncentrácia alkoholu v krvi menej ako 20 mg/100 ml"},
{"code":"Y90.1","desc":"Koncentrácia alkoholu v krvi 20 – 39 mg/100 ml"},
{"code":"Y90.2","desc":"Koncentrácia alkoholu v krvi 40 – 59 mg/100 ml"},
{"code":"Y90.3","desc":"Koncentrácia alkoholu v krvi 60 – 79 mg/100 ml"},
{"code":"Y90.4","desc":"Koncentrácia alkoholu v krvi 80 – 99 mg/100 ml"},
{"code":"Y90.5","desc":"Koncentrácia alkoholu v krvi 100 – 119 mg/100 ml"},
{"code":"Y90.6","desc":"Koncentrácia alkoholu v krvi 120 – 199 mg/100 ml"},
{"code":"Y90.7","desc":"Koncentrácia alkoholu v krvi 200 – 239 mg/100 ml"},
{"code":"Y90.8","desc":"Koncentrácia alkoholu v krvi 240 mg/100 ml a viac"},
{"code":"Y90.9","desc":"Alkohol v krvi, koncentrácia bližšie neurčená"},
{"code":"Y91.-","desc":"Dôkaz vplyvu alkoholu podľa stupňa intoxikácie"},
{"code":"Y91.0","desc":"Intoxikácia alkoholom mierneho stupňa"},
{"code":"Y91.1","desc":"Intoxikácia alkoholom stredného stupňa"},
{"code":"Y91.2","desc":"Intoxikácia alkoholom ťažkého stupňa"},
{"code":"Y91.3","desc":"Intoxikácia alkoholom veľmi ťažkého stupňa"},
{"code":"Y91.9","desc":"Vplyv alkoholu, bližšie neurčený"},
{"code":"Y95","desc":"Nozokomiálna choroba"},
{"code":"Y96","desc":"Choroba z povolania"},
{"code":"Y97","desc":"Choroba súvisiaca so znečisteným životným prostredím"},
{"code":"Y98","desc":"Choroba súvisiaca so spôsobom života"},
{"code":"Z00-Z99","desc":"XXI. kapitola - Faktory ovplyvňujúce zdravotný stav a styk so zdravotníckymi službami (Z00-Z99)"},
{"code":"Z00-Z13","desc":"Osoby, ktoré vyhľadali zdravotnícke služby kvôli vyšetreniu a prehliadke"},
{"code":"Z00.-","desc":"Všeobecné vyšetrenie a prehliadka osôb bez ťažkostí a bez uvedenej diagnózy"},
{"code":"Z00.0","desc":"Všeobecné lekárske vyšetrenie"},
{"code":"Z00.1","desc":"Rutinné vyšetrenie zdravotného stavu dieťaťa"},
{"code":"Z00.2","desc":"Vyšetrenie v období rýchleho rastu v detstve"},
{"code":"Z00.3","desc":"Vyšetrenie vývinu dospievajúceho"},
{"code":"Z00.4","desc":"Všeobecné psychiatrické vyšetrenie, nezatriedené inde"},
{"code":"Z00.5","desc":"Vyšetrenie potenciálneho darcu orgánov a tkanív"},
{"code":"Z00.6","desc":"Vyšetrenie na porovnávanie a kontrolu v rámci klinického výskumného programu"},
{"code":"Z00.8","desc":"Iné všeobecné vyšetrenie"},
{"code":"Z01.-","desc":"Špeciálne vyšetrenie a prehliadka osôb bez ťažkostí a bez uvedenej diagnózy"},
{"code":"Z01.0","desc":"Vyšetrenie očí a zraku"},
{"code":"Z01.1","desc":"Vyšetrenie uší a sluchu"},
{"code":"Z01.2","desc":"Vyšetrenie zubov"},
{"code":"Z01.3","desc":"Vyšetrenie krvného tlaku"},
{"code":"Z01.4","desc":"Gynekologické vyšetrenie (celkové) (rutinné)"},
{"code":"Z01.5","desc":"Diagnostické kožné testy a testy senzibilizácie"},
{"code":"Z01.6","desc":"Rádiologické vyšetrenie, nezatriedené inde"},
{"code":"Z01.7","desc":"Laboratórne vyšetrenie"},
{"code":"Z01.8-","desc":"Iné špeciálne vyšetrenie, bližšie určené"},
{"code":"Z01.80","desc":"Objasnenie predispozície k malígnym poruchám srdcového rytmu"},
{"code":"Z01.81","desc":"Objasnenie predispozície ku kardiomyopatii"},
{"code":"Z01.88","desc":"Iné bližšie určené špeciálne vyšetrenie"},
{"code":"Z01.9","desc":"Špeciálne vyšetrenie, bližšie neurčené"},
{"code":"Z02.-","desc":"Vyšetrenie a návšteva na administratívne účely"},
{"code":"Z02.0","desc":"Vyšetrenie pred prijatím do výchovnovzdelávacieho zariadenia"},
{"code":"Z02.1","desc":"Vyšetrenie pred prijatím do zamestnania"},
{"code":"Z02.2","desc":"Vyšetrenie pred prijatím do ubytovacích zariadení"},
{"code":"Z02.3","desc":"Vyšetrenie pri odvode brancov"},
{"code":"Z02.4","desc":"Vyšetrenie pre vodičský preukaz"},
{"code":"Z02.5","desc":"Vyšetrenie pred športovou súťažou"},
{"code":"Z02.6","desc":"Vyšetrenie pre poisťovacie účely"},
{"code":"Z02.7","desc":"Vydanie lekárskeho potvrdenia"},
{"code":"Z02.8","desc":"Iné vyšetrenie na administratívne účely"},
{"code":"Z02.9","desc":"Vyšetrenie na administratívne účely, bližšie neurčené"},
{"code":"Z03.-","desc":"Lekárske pozorovanie a vyšetrenie pre podozrenie z choroby a chorobného stavu"},
{"code":"Z03.0","desc":"Pozorovanie pri podozrení na tuberkulózu"},
{"code":"Z03.1","desc":"Pozorovanie pri podozrení na zhubný nádor"},
{"code":"Z03.2","desc":"Pozorovanie pri podozrení na duševnú poruchu a poruchu správania"},
{"code":"Z03.3","desc":"Pozorovanie pri podozrení na nervovú chorobu"},
{"code":"Z03.4","desc":"Pozorovanie pri podozrení na infarkt myokardu"},
{"code":"Z03.5","desc":"Pozorovanie pri podozrení na inú srdcovocievnu chorobu"},
{"code":"Z03.6","desc":"Pozorovanie pri podozrení na toxický účinok použitej látky"},
{"code":"Z03.8","desc":"Pozorovanie pri podozrení na inú chorobu a chorobný stav"},
{"code":"Z03.9","desc":"Pozorovanie pri podozrení na bližšie neurčenú chorobu a chorobný stav"},
{"code":"Z04.-","desc":"Vyšetrenie a pozorovanie pre iné príčiny"},
{"code":"Z04.0","desc":"Krvné testy na alkohol a návykové látky"},
{"code":"Z04.1","desc":"Vyšetrenie a pozorovanie po dopravnej nehode"},
{"code":"Z04.2","desc":"Vyšetrenie a pozorovanie po pracovnom úraze"},
{"code":"Z04.3","desc":"Vyšetrenie a pozorovanie po inom úraze"},
{"code":"Z04.4","desc":"Vyšetrenie a pozorovanie po údajnom znásilnení a pokuse o znásilnenie"},
{"code":"Z04.5","desc":"Vyšetrenie a pozorovanie po inom zavinenom poranení"},
{"code":"Z04.6","desc":"Všeobecné psychiatrické vyšetrenie, vyžiadané úradne"},
{"code":"Z04.8","desc":"Vyšetrenie a pozorovanie z iných bližšie určených dôvodov"},
{"code":"Z04.9","desc":"Vyšetrenie a pozorovanie z bližšie neurčených dôvodov"},
{"code":"Z06.-","desc":"Periodické preventívne prehliadky pre príslušníkov Hasičského a záchranného zboru a Horskej záchrannej služby"},
{"code":"Z06.0","desc":"Periodické preventívne prehliadky pre príslušníkov Hasičského a záchranného zboru a Horskej záchrannej služby"},
{"code":"Z08.-","desc":"Sledovanie po liečbe zhubného nádoru"},
{"code":"Z08.0","desc":"Sledovanie po chirurgickom výkone pre zhubný nádor"},
{"code":"Z08.1","desc":"Sledovanie po rádioterapii zhubného nádoru"},
{"code":"Z08.2","desc":"Sledovanie po chemoterapii zhubného nádoru"},
{"code":"Z08.7","desc":"Sledovanie po kombinovanej liečbe zhubného nádoru"},
{"code":"Z08.8","desc":"Sledovanie po inej liečbe zhubného nádoru"},
{"code":"Z08.9","desc":"Sledovanie po bližšie neurčenej liečbe zhubného nádoru"},
{"code":"Z09.-","desc":"Sledovanie po liečbe inej choroby ako zhubného nádoru"},
{"code":"Z09.0","desc":"Sledovanie po chirurgickom výkone pre iný chorobný stav"},
{"code":"Z09.1","desc":"Sledovanie po rádioterapii pre iný chorobný stav"},
{"code":"Z09.2","desc":"Sledovanie po chemoterapii pre iný chorobný stav"},
{"code":"Z09.3","desc":"Sledovanie po psychoterapii"},
{"code":"Z09.4","desc":"Sledovanie po liečbe zlomeniny"},
{"code":"Z09.7","desc":"Sledovanie po kombinovanej liečbe pre iný chorobný stav"},
{"code":"Z09.8-","desc":"Sledovanie po inej liečbe pre iný chorobný stav"},
{"code":"Z09.80","desc":"Sledovanie po transplantácii orgánu"},
{"code":"Z09.88","desc":"Sledovanie po inej liečbe pre iný chorobný stav"},
{"code":"Z09.9","desc":"Sledovanie po nešpecifikovanej liečbe pre iný chorobný stav"},
{"code":"Z10.-","desc":"Rutinná všeobecná zdravotná kontrola definovanej skupiny obyvateľstva"},
{"code":"Z10.0","desc":"Zdravotná prehliadka zamestancov"},
{"code":"Z10.1","desc":"Rutinná všeobecná zdravotná kontrola obyvateľov ústavov"},
{"code":"Z10.2","desc":"Rutinná všeobecná zdravotná kontrola príslušníkov ozbrojených zložiek"},
{"code":"Z10.3","desc":"Rutinná všeobecná zdravotná kontrola členov športových družstiev"},
{"code":"Z10.8","desc":"Rutinná všeobecná zdravotná kontrola inej definovanej skupiny obyvateľstva"},
{"code":"Z11.-","desc":"Špeciálne skríningové vyšetrenie, zamerané na infekčné a parazitové choroby"},
{"code":"Z11.0","desc":"Špeciálne skríningové vyšetrenie zamerané na črevné infekčné choroby"},
{"code":"Z11.1","desc":"Špeciálne skríningové vyšetrenie zamerané na pľúcnu tuberkulózu"},
{"code":"Z11.2","desc":"Špeciálne skríningové vyšetrenie zamerané na iné baktériové choroby"},
{"code":"Z11.3","desc":"Špeciálne skríningové vyšetrenie zamerané na infekčné choroby s prevažne pohlavným spôsob prenášania"},
{"code":"Z11.4","desc":"Špeciálne skríningové vyšetrenie zamerané na vírus ľudskej imunitnej nedostatočnosti (HIV)"},
{"code":"Z11.5","desc":"Špeciálne skríningové vyšetrenie zamerané na iné vírusové choroby"},
{"code":"Z11.6","desc":"Špeciálne skríningové vyšetrenie zamerané na iné protozoárne choroby a helmintózy"},
{"code":"Z11.8","desc":"Špeciálne skríningové vyšetrenie zamerané na iné infekčné a parazitové choroby"},
{"code":"Z11.9","desc":"Špeciálne skríningové vyšetrenie zamerané na infekčné a parazitové choroby, bližšie neurčené"},
{"code":"Z12.-","desc":"Špeciálne skríningové vyšetrenie, zamerané na nádorové choroby"},
{"code":"Z12.0","desc":"Špeciálne skríningové vyšetrenie, zamerané na nádor žalúdka"},
{"code":"Z12.1","desc":"Špeciálne skríningové vyšetrenie, zamerané na nádor čreva"},
{"code":"Z12.2","desc":"Špeciálne skríningové vyšetrenie, zamerané na nádor dýchacích orgánov"},
{"code":"Z12.3","desc":"Špeciálne skríningové vyšetrenie, zamerané na nádor prsníka"},
{"code":"Z12.4","desc":"Špeciálne skríningové vyšetrenie, zamerané na nádor krčka maternice"},
{"code":"Z12.5","desc":"Špeciálne skríningové vyšetrenie, zamerané na nádor prostaty"},
{"code":"Z12.6","desc":"Špeciálne skríningové vyšetrenie, zamerané na nádor močového mechúra"},
{"code":"Z12.8","desc":"Špeciálne skríningové vyšetrenie, zamerané na nádory iných lokalizácií"},
{"code":"Z12.9","desc":"Špeciálne skríningové vyšetrenie, zamerané na bližšie neurčený nádor"},
{"code":"Z13.-","desc":"Špeciálne skríningové vyšetrenie, zamerané na iné choroby a poruchy"},
{"code":"Z13.0","desc":"Špeciálne skríningové vyšetrenie, zamerané na choroby krvi a krvotvorných orgánov a určité poruchy imunitných mechanizmov"},
{"code":"Z13.1","desc":"Špeciálne skríningové vyšetrenie, zamerané na diabetes mellitus"},
{"code":"Z13.2","desc":"Špeciálne skríningové vyšetrenie, zamerané na poruchy výživy"},
{"code":"Z13.3","desc":"Špeciálne skríningové vyšetrenie zamerané na duševné poruchy a poruchy správania"},
{"code":"Z13.4","desc":"Špeciálne skríningové vyšetrenie, zamerané na určité vývinové poruchy v detstve"},
{"code":"Z13.5","desc":"Špeciálne skríningové vyšetrenie, zamerané na očné a ušné choroby"},
{"code":"Z13.6","desc":"Špeciálne skríningové vyšetrenie, zamerané na srdcovocievne choroby"},
{"code":"Z13.7","desc":"Špeciálne skríningové vyšetrenie, zamerané na vrodené chyby, deformity a chromozómové anomálie"},
{"code":"Z13.8","desc":"Špeciálne skríningové vyšetrenie, zamerané na iné bližšie určené choroby a poruchy"},
{"code":"Z13.9","desc":"Špeciálne skríningové vyšetrenie, bližšie neurčené"},
{"code":"Z17.-","desc":"Periodické preventívne prehliadky pre príslušníkov Policajného zboru"},
{"code":"Z17.0","desc":"Periodické preventívne prehliadky pre príslušníkov Policajného zboru"},
{"code":"Z20-Z29","desc":"Osoby s potenciálnym ohrozením zdravia vo vzťahu k prenosným chorobám"},
{"code":"Z20.-","desc":"Kontakt a vystavenie prenosným chorobám"},
{"code":"Z20.0","desc":"Kontakt a vystavenie prenosným črevným chorobám"},
{"code":"Z20.1","desc":"Kontakt a vystavenie tuberkulóze"},
{"code":"Z20.2","desc":"Kontakt a vystavenie prenosným chorobám s prevažne pohlavným spôsobom prenášania"},
{"code":"Z20.3","desc":"Kontakt a vystavenie besnote"},
{"code":"Z20.4","desc":"Kontakt a vystavenie ružienke"},
{"code":"Z20.5","desc":"Kontakt a vystavenie vírusovej hepatitíde"},
{"code":"Z20.6","desc":"Kontakt a vystavenie HIV (vírusu ľudského imunodeficitu)"},
{"code":"Z20.7","desc":"Kontakt a vystavenie pedikulóze (zavšiveniu), akarinóze a iným parazitovým zamoreniam"},
{"code":"Z20.8","desc":"Kontakt a vystavenie inej prenosnej chorobe"},
{"code":"Z20.9","desc":"Kontakt a vystavenie bližšie neurčenej prenosnej chorobe"},
{"code":"Z21","desc":"Bezpríznakový stav infekcie HIV (vírusom ľudského imunodeficitu)"},
{"code":"Z22.-","desc":"Nosičstvo pôvodcu infekčnej choroby"},
{"code":"Z22.0","desc":"Nosičstvo pôvodcu brušného týfusu"},
{"code":"Z22.1","desc":"Nosičstvo pôvodcu inej črevnej infekčnej choroby"},
{"code":"Z22.2","desc":"Nosičstvo pôvodcu záškrtu"},
{"code":"Z22.3","desc":"Nosičstvo pôvodcu inej bližšie určenej baktériovej infekcie"},
{"code":"Z22.4","desc":"Nosičstvo pôvodcu infekčnej choroby s prevažne pohlavným spôsobom prenášania"},
{"code":"Z22.5","desc":"Nosičstvo vírusu hepatitídy"},
{"code":"Z22.6","desc":"Nosičstvo humánneho T-lymfotropného vírusu 1.typu [HTLV-1]"},
{"code":"Z22.8","desc":"Nosičstvo pôvodcu inej infekčnej choroby"},
{"code":"Z22.9","desc":"Nosičstvo pôvodcu bližšie neurčenej infekčnej choroby"},
{"code":"Z23.-","desc":"Potreba imunizácie proti jednotlivým baktériovým chorobám"},
{"code":"Z23.0","desc":"Potreba imunizácie výlučne proti cholere"},
{"code":"Z23.1","desc":"Potreba imunuzácie výlučne proti týfusu – paratýfusu [TAB]"},
{"code":"Z23.2","desc":"Potreba imunizácie proti tuberkulóze [BCG]"},
{"code":"Z23.3","desc":"Potreba imunizácie proti moru"},
{"code":"Z23.4","desc":"Potreba imunizácie proti tularémii"},
{"code":"Z23.5","desc":"Potreba imunizácie výlučne proti tetanu"},
{"code":"Z23.6","desc":"Potreba imunizácie výlučne proti záškrtu"},
{"code":"Z23.7","desc":"Potreba imunizácie výlučne proti čiernemu kašľu"},
{"code":"Z23.8","desc":"Potreba imunizácie proti inej jednotlivej baktériovej chorobe"},
{"code":"Z24.-","desc":"Potreba imunizácie proti určitým jednotlivým vírusovým chorobám"},
{"code":"Z24.0","desc":"Potreba imunizácie proti poliomyelitíde"},
{"code":"Z24.1","desc":"Potreba imunizácie proti vírusovému zápalu mozgu, prenášanému článkonožcami"},
{"code":"Z24.2","desc":"Potreba imunizácie proti besnote"},
{"code":"Z24.3","desc":"Potreba imunizácie proti žltej zimnici"},
{"code":"Z24.4","desc":"Potreba imunizácie výlučne proti osýpkam"},
{"code":"Z24.5","desc":"Potreba imunizácie výlučne proti ružienke"},
{"code":"Z24.6","desc":"Potreba imunizácie proti vírusovej hepatitíde"},
{"code":"Z25.-","desc":"Potreba imunizácie proti iným jednotlivým vírusovým chorobám"},
{"code":"Z25.0","desc":"Potreba imunizácie výlučne proti mumpsu"},
{"code":"Z25.1","desc":"Potreba imunizácie výlučne proti chrípke"},
{"code":"Z25.7-","desc":"Potreba imunizácie výlučne proti COVID-19"},
{"code":"Z25.70","desc":"Potreba imunizácie výlučne proti COVID-19, očkovacia látka Tozinameran"},
{"code":"Z25.71","desc":"Potreba imunizácie výlučne proti COVID-19, očkovacia látka mRNA-1273"},
{"code":"Z25.72","desc":"Potreba imunizácie výlučne proti COVID-19, očkovacia látka ChAdOx1-SARS-COV-2"},
{"code":"Z25.73","desc":"Potreba imunizácie výlučne proti COVID-19, očkovacia látka Ad26.COV2.S"},
{"code":"Z25.74","desc":"Potreba imunizácie výlučne proti COVID-19, očkovacia látka Gam-COVID-Vac"},
{"code":"Z25.75","desc":"Potreba imunizácie výlučne proti COVID-19, Nuvaxovid injekčná disperzia, očkovacia látka proti COVID-19 (rekombinantná, s adjuvans)"},
{"code":"Z25.79","desc":"Potreba imunizácie výlučne proti COVID-19, iná očkovacia látka"},
{"code":"Z25.8","desc":"Potreba imunizácie proti inej bližšie určenej jednotlivej vírusovej chorobe"},
{"code":"Z26.-","desc":"Potreba imunizácie proti iným jednotlivým infekčným chorobám"},
{"code":"Z26.0","desc":"Potreba imunizácie proti leišmanióze"},
{"code":"Z26.8","desc":"Potreba imunizácie proti iným bližšie určeným jednotlivým infekčným chorobám"},
{"code":"Z26.9","desc":"Potreba imunizácie proti bližšie neurčenej infekčnej chorobe"},
{"code":"Z27.-","desc":"Potreba imunizácie proti kombinácii infekčných chorôb"},
{"code":"Z27.0","desc":"Potreba imunizácie proti cholere s týfusom – paratýfusom [Cholera+TAB]"},
{"code":"Z27.1","desc":"Potreba imunizácie proti záškrtu – tetanu – čiernemu kašľu [DPT]"},
{"code":"Z27.2","desc":"Potreba imunizácie proti záškrtu – tetanu – čiernemu kašľu s týfusom – paratýfusom [DPT+TAB]"},
{"code":"Z27.3","desc":"Potreba imunizácie proti záškrtu – tetanu – čiernemu kašľu s poliomyelitídou [DPT+Polio]"},
{"code":"Z27.4","desc":"Potreba imunizácie proti osýpkam – parotitíde – ružienke [MMR]"},
{"code":"Z27.8","desc":"Potreba imunizácie proti inej kombinácii infekčných chorôb"},
{"code":"Z27.9","desc":"Potreba imunizácie proti bližšie neurčenej kombinácii infekčných chorôb"},
{"code":"Z28.-","desc":"Nevykonaná imunizácia"},
{"code":"Z28.0","desc":"Nevykonaná imunizácia pre kontraindikáciu"},
{"code":"Z28.1","desc":"Nevykonaná imunizácia pre odmietnutie pacienta z náboženských dôvodov alebo pod tlakom okolia"},
{"code":"Z28.2","desc":"Nevykonaná imunizácia pre odmietnutie pacienta z iných a bližšie neurčených dôvodov"},
{"code":"Z28.8","desc":"Nevykonaná imunizácia pre iný dôvod"},
{"code":"Z28.9","desc":"Nevykonaná imunizácia pre bližšie neurčený dôvod"},
{"code":"Z29.-","desc":"Potreba iných preventívnych postupov"},
{"code":"Z29.0","desc":"Izolácia ako preventívne opatrenie"},
{"code":"Z29.1","desc":"Profylaktická imunoterapia"},
{"code":"Z29.2-","desc":"Iná profylaktická chemoterapia"},
{"code":"Z29.20","desc":"Lokálna profylaktická chemoterapia"},
{"code":"Z29.21","desc":"Celková profylaktická chemoterapia"},
{"code":"Z29.28","desc":"Iná profylaktická chemoterapia"},
{"code":"Z29.8","desc":"Iný profylaktický postup, bližšie určený"},
{"code":"Z29.9","desc":"Profylaktický postup, bližšie neurčený"},
{"code":"Z30-Z39","desc":"Osoby využívajúce zdravotnícke služby pre problémy súvisiace s reprodukciou"},
{"code":"Z30.-","desc":"Riadenie antikoncepcie"},
{"code":"Z30.0","desc":"Všeobecné poradenstvo a odporúčanie v otázke antikoncepcie"},
{"code":"Z30.1","desc":"Zavedenie (vnútromaternicového) antikoncepčného telieska"},
{"code":"Z30.2","desc":"Sterilizácia"},
{"code":"Z30.3","desc":"Vyvolanie menštruácie"},
{"code":"Z30.4","desc":"Dohľad nad liekovou antikoncepciou (kontraceptívami)"},
{"code":"Z30.5","desc":"Dohľad nad (vnútromaternicovým) antikoncepčným telieskom"},
{"code":"Z30.8","desc":"Iné riadenie antikoncepcie"},
{"code":"Z30.9","desc":"Riadenie antikoncepcie, bližšie neurčené"},
{"code":"Z31.-","desc":"Vyšetrenie a liečenie porúch fertility"},
{"code":"Z31.0","desc":"Tuboplastika alebo vazoplastika po predchádzajúcej sterilizácii"},
{"code":"Z31.1","desc":"Umelé oplodnenie"},
{"code":"Z31.2","desc":"Oplodnenie in vitro"},
{"code":"Z31.3","desc":"Iná asistovaná metóda oplodnenia"},
{"code":"Z31.4","desc":"Vyšetrenie a testovanie plodnosti (fertility)"},
{"code":"Z31.5","desc":"Genetické poradenstvo"},
{"code":"Z31.6","desc":"Všeobecné poradenstvo a odporúčanie v otázke plodnosti"},
{"code":"Z31.8","desc":"Iné riadenie plodnosti"},
{"code":"Z31.9","desc":"Riadenie plodnosti, bližšie neurčené"},
{"code":"Z32.-","desc":"Vyšetrenie a testy, zamerané na zistenie gravidity"},
{"code":"Z32.0","desc":"Gravidita, (ešte) nepotvrdená"},
{"code":"Z32.1","desc":"Potvrdená gravidita"},
{"code":"Z33!","desc":"Gravidita ako vedľajší nález"},
{"code":"Z34.-","desc":"Dohľad nad priebehom normálnej gravidity"},
{"code":"Z34.0","desc":"Dohľad nad normálnou prvou graviditou"},
{"code":"Z34.8","desc":"Dohľad nad inou normálnou graviditou"},
{"code":"Z34.9","desc":"Dohľad nad normálnou graviditou, bližšie neurčenou"},
{"code":"Z35.-","desc":"Dohľad nad priebehom rizikovej gravidity"},
{"code":"Z35.0","desc":"Dohľad nad graviditou s anamnézou neplodnosti"},
{"code":"Z35.1","desc":"Dohľad nad graviditou s anamnézou potratu"},
{"code":"Z35.2","desc":"Dohľad nad graviditou s inou nepriaznivou pôrodníckou anamnézou"},
{"code":"Z35.3","desc":"Dohľad nad graviditou s anamnézou nedostatočnej prenatálnej starostlivosti"},
{"code":"Z35.4","desc":"Dohľad nad graviditou mnohorodičky"},
{"code":"Z35.5","desc":"Dohľad nad staršou prvorodičkou"},
{"code":"Z35.6","desc":"Dohľad nad veľmi mladou prvorodičkou"},
{"code":"Z35.7","desc":"Dohľad nad rizikovou graviditou v dôsledku sociálnych problémov"},
{"code":"Z35.8","desc":"Dohľad nad inou rizikovou graviditou"},
{"code":"Z35.9","desc":"Dohľad nad rizikovou graviditou, bližšie neurčenou"},
{"code":"Z36.-","desc":"Prenatálny skríning"},
{"code":"Z36.0","desc":"Prenatálny skríning chromozómových anomálií"},
{"code":"Z36.1","desc":"Prenatálny skríning na zvýšenú hodnotu alfafetoproteínu"},
{"code":"Z36.2","desc":"Iný prenatálny skríning, založený na amniocentéze"},
{"code":"Z36.3","desc":"Prenatálny skríning malformácií ultrazvukom a inými fyzikálnymi metódami"},
{"code":"Z36.4","desc":"Prenatálny skríning spomalenia rastu plodu ultrazvukom a inými fyzikálnymi metódami"},
{"code":"Z36.5","desc":"Prenatálny skríning izoimunizácie"},
{"code":"Z36.8","desc":"Iný prenatálny skríning"},
{"code":"Z36.9","desc":"Prenatálny skríning, bližšie neurčený"},
{"code":"Z37.-!","desc":"Výsledok pôrodu"},
{"code":"Z37.0!","desc":"Jedno dieťa, živonarodené"},
{"code":"Z37.1!","desc":"Jedno dieťa, mŕtvonarodené"},
{"code":"Z37.2!","desc":"Dvojčatá, živonarodené"},
{"code":"Z37.3!","desc":"Dvojčatá, jedno živonarodené, jedno mŕtvonarodené"},
{"code":"Z37.4!","desc":"Dvojčatá, mŕtvonarodené"},
{"code":"Z37.5!","desc":"Iný viacpočetný pôrod, všetky deti živonarodené"},
{"code":"Z37.6!","desc":"Iný viacpočetný pôrod, niektoré deti živonarodené"},
{"code":"Z37.7!","desc":"Iný viacpočetný pôrod, všetky deti mŕtvonarodené"},
{"code":"Z37.9!","desc":"Výsledok pôrodu, bližšie neurčený"},
{"code":"Z38.-","desc":"Živonarodené deti podľa miesta narodenia"},
{"code":"Z38.0","desc":"Jedno dieťa, narodené v nemocnici"},
{"code":"Z38.1","desc":"Jedno dieťa, narodené mimo nemocnice"},
{"code":"Z38.2","desc":"Jedno dieťa, miesto narodenia bližšie neurčené"},
{"code":"Z38.3","desc":"Dvojčatá, narodené v nemocnici"},
{"code":"Z38.4","desc":"Dvojčatá, narodené mimo nemocnice"},
{"code":"Z38.5","desc":"Dvojčatá, miesto narodenia bližšie neurčené"},
{"code":"Z38.6","desc":"Deti z inej viacnásobnej gravidity, narodené v nemocnici"},
{"code":"Z38.7","desc":"Deti z inej viacnásobnej gravidity, narodené mimo nemocnice"},
{"code":"Z38.8","desc":"Deti z inej viacnásobnej gravidity, bližšie neurčené miesto narodenia"},
{"code":"Z39.-","desc":"Popôrodná starostlivosť a vyšetrenie matky"},
{"code":"Z39.0","desc":"Starostlivosť a vyšetrenie matky ihneď po pôrode"},
{"code":"Z39.1","desc":"Starostlivosť a vyšetrenie matky počas laktácie"},
{"code":"Z39.2","desc":"Ďalšie bežné sledovanie matky po pôrode"},
{"code":"Z40-Z54","desc":"Osoby, ktoré sa stretávajú so zdravotníckymi službami za účelom špecifických výkonov a zdravotnej starostlivosti"},
{"code":"Z40.-","desc":"Profylaktický chirurgický výkon"},
{"code":"Z40.0-","desc":"Profylaktický chirurgický výkon pre rizikové faktory rozvoja zhubného nádoru"},
{"code":"Z40.00","desc":"Profylaktický chirurgický výkon pre ohrozenie zhubným nádorom prsníka"},
{"code":"Z40.01","desc":"Profylaktický chirurgický výkon pre ohrozenie zhubným nádorom vaječníka"},
{"code":"Z40.08","desc":"Profylaktický chirurgický výkon pre ohrozenie iným zhubným nádorom"},
{"code":"Z40.8","desc":"Iný profylaktický operačný výkon"},
{"code":"Z40.9","desc":"Profylaktický chirurgický výkon, bližšie neurčený"},
{"code":"Z41.-","desc":"Výkony z iných dôvodov ako zo zdravotnej indikácie"},
{"code":"Z41.0","desc":"Transplantácia vlasov"},
{"code":"Z41.1","desc":"Plastická operácia z kozmetických príčin"},
{"code":"Z41.3","desc":"Prepichnutie uší"},
{"code":"Z41.2","desc":"Rutinná a rituálna obriezka"},
{"code":"Z41.8","desc":"Iný výkon z iných dôvodov ako zo zdravotnej indikácie"},
{"code":"Z41.9","desc":"Výkony z iných dôvodov ako zo zdravotnej indikácie, bližšie neurčené"},
{"code":"Z42.-","desc":"Následná starostlivosť v plastickej chirurgii"},
{"code":"Z42.0","desc":"Následná starostlivosť, zahŕňajúca plastický operačný výkon na hlave a krku"},
{"code":"Z42.1","desc":"Následná starostlivosť, zahŕňajúca plastický chirurgický výkon na prsníku"},
{"code":"Z42.2","desc":"Následná starostlivosť, zahŕňajúca plastický chirurgický výkon na iných častiach trupu"},
{"code":"Z42.3","desc":"Následná starostlivosť, zahŕňajúca plastický chirurgický výkon na hornej končatine"},
{"code":"Z42.4","desc":"Následná starostlivosť, zahŕňajúca plastický chirurgický výkon na dolnej končatine"},
{"code":"Z42.8","desc":"Následná starostlivosť, zahŕňajúca plastický chirurgický výkon na inej časti tela"},
{"code":"Z42.9","desc":"Následná starostlivosť, zahŕňajúca plastický chirurgický výkon, bližšie neurčený"},
{"code":"Z43.-","desc":"Ošetrovanie umelo vytvorených telesných otvorov (stómii)"},
{"code":"Z43.0","desc":"Ošetrovanie tracheostómie"},
{"code":"Z43.1","desc":"Ošetrovanie gastrostómie"},
{"code":"Z43.2","desc":"Ošetrovanie ileostómie"},
{"code":"Z43.3","desc":"Ošetrovanie kolostómie"},
{"code":"Z43.4","desc":"Ošetrovanie iných umelo vytvorených vyústení tráviacej sústavy"},
{"code":"Z43.5","desc":"Ošetrovanie cystostómie"},
{"code":"Z43.6","desc":"Ošetrovanie iných umelo vytvorených vyústení močovej sústavy"},
{"code":"Z43.7","desc":"Ošetrovanie umelo vytvorenej pošvy"},
{"code":"Z43.8-","desc":"Ošetrenie iných umelo vytvorených telesných otvorov"},
{"code":"Z43.80","desc":"Ošetrenie hrudníkovej stómie"},
{"code":"Z43.88","desc":"Ošetrenie iného umelého vyústenia"},
{"code":"Z43.9","desc":"Ošetrovanie bližšie neurčeného umelo vytvoreného telesného otvoru (stómie)"},
{"code":"Z44.-","desc":"Skúšanie a prispôsobovanie vonkajšej protetickej pomôcky"},
{"code":"Z44.0","desc":"Skúšanie a prispôsobovanie protézy hornej končatiny, kompletnej alebo čiastočnej"},
{"code":"Z44.1","desc":"Skúšanie a prispôsobovanie protézy dolnej končatiny, kompletnej alebo čiastočnej"},
{"code":"Z44.2","desc":"Skúšanie a prispôsobovanie očnej protézy"},
{"code":"Z44.3","desc":"Skúšanie a prispôsobovanie vonkajšej protézy prsníka"},
{"code":"Z44.8","desc":"Skúšanie a prispôsobovanie inej vonkajšej protetickej pomôcky"},
{"code":"Z44.9","desc":"Skúšanie a prispôsobovanie bližšie neurčenej vonkajšej protetickej pomôcky"},
{"code":"Z45.-","desc":"Prispôsobovanie a údržba implantovanej pomôcky"},
{"code":"Z45.0","desc":"Kontrola a nastavenie kardiostimulátora"},
{"code":"Z45.1","desc":"Prispôsobovanie a údržba infúznej pumpy"},
{"code":"Z45.2-","desc":"Prispôsobovanie a udržiavanie cievneho prístupu"},
{"code":"Z45.20","desc":"Prispôsobovanie a udržiavanie chirurgicky implantovaného cievneho permanentného katétra"},
{"code":"Z45.29","desc":"Prispôsobovanie a udržiavanie iného a bližšie neurčeného cievneho prístupu"},
{"code":"Z45.3","desc":"Prispôsobovanie a údržba implantovaného načúvacieho prístroja"},
{"code":"Z45.8-","desc":"Prispôsobovanie a údržba inej implantovanej zdravotníckej pomôcky"},
{"code":"Z45.80","desc":"Prispôsobovanie a údržba neurostimulátora"},
{"code":"Z45.81","desc":"Preskúšanie funkčných parametrov stimulátora bránice"},
{"code":"Z45.82","desc":"Preskúšanie funkčných parametrov liekovej pumpy"},
{"code":"Z45.83","desc":"Prispôsobovanie a údržba systému podporujúceho srdce"},
{"code":"Z45.84","desc":"Prispôsobovanie a udržiavanie stentu priedušky"},
{"code":"Z45.85","desc":"Prispôsobovanie a udržiavanie stentu priedušnice"},
{"code":"Z45.88","desc":"Prispôsobovanie a údržba inej implantovanej zdravotníckej pomôcky"},
{"code":"Z45.9","desc":"Prispôsobovanie a údržba bližšie neurčenej implantovanej zdravotníckej pomôcky"},
{"code":"Z46.-","desc":"Skúšanie a prispôsobovanie iného prístroja alebo zdravotníckej pomôcky"},
{"code":"Z46.0","desc":"Skúšanie a prispôsobovanie okuliarov a kontaktných šošoviek"},
{"code":"Z46.1","desc":"Skúšanie a prispôsobovanie načúvacej pomôcky"},
{"code":"Z46.2","desc":"Skúšanie a prispôsobovanie iného lekárskeho prístroja alebo pomôcky pre nervový systém a zmyslové orgány"},
{"code":"Z46.3","desc":"Skúšanie a prispôsobovanie zubnej protetickej pomôcky"},
{"code":"Z46.4","desc":"Skúšanie a prispôsobovanie ortodontickej pomôcky"},
{"code":"Z46.5","desc":"Skúšanie a prispôsobovanie ileostómie a iných pomôcok pre tráviaci systém"},
{"code":"Z46.6","desc":"Skúšanie a prispôsobovanie urologických pomôcok"},
{"code":"Z46.7","desc":"Skúšanie a prispôsobovanie ortopedických pomôcok"},
{"code":"Z46.8","desc":"Skúšanie a prispôsobovanie iného bližšie určeného prístroja alebo zdravotníckej pomôcky"},
{"code":"Z46.9","desc":"Skúšanie a prispôsobovanie bližšie neurčeného prístroja alebo zdravotníckej pomôcky"},
{"code":"Z47.-","desc":"Iná následná ortopedická starostlivosť"},
{"code":"Z47.0","desc":"Následná starostlivosť, zahŕňajúca odstránenie kostnej dlahy alebo inej vnútornej fixačnej pomôcky"},
{"code":"Z47.8","desc":"Iná bližšie určená následná ortopedická starostlivosť"},
{"code":"Z47.9","desc":"Následná ortopedická starostlivosť, bližšie neurčená"},
{"code":"Z48.-","desc":"Iná následná chirurgická starostlivosť"},
{"code":"Z48.0","desc":"Starostlivosť o chirurgické obväzy a sutúry"},
{"code":"Z48.8","desc":"Iná bližšie určená následná chirurgická starostlivosť"},
{"code":"Z48.9","desc":"Následná chirurgická starostlivosť, bližšie neurčená"},
{"code":"Z49.-","desc":"Starostlivosť pri dialýze"},
{"code":"Z49.0","desc":"Starostlivosť počas prípravy na dialýzu"},
{"code":"Z49.1","desc":"Mimotelová dialýza"},
{"code":"Z49.2","desc":"Iná dialýza"},
{"code":"Z50.-!","desc":"Starostlivosť s použitím rehabilitačných výkonov"},
{"code":"Z50.0!","desc":"Rehabilitácia pri chorobách srdca"},
{"code":"Z50.1!","desc":"Iná fyzikálna liečba"},
{"code":"Z50.2!","desc":"Rehabilitácia pri alkoholizme"},
{"code":"Z50.3!","desc":"Rehabilitácia pri liekovej alebo drogovej závislosti"},
{"code":"Z50.4!","desc":"Psychoterapia, nezatriedená inde"},
{"code":"Z50.5!","desc":"Logopedická liečba (liečba porúch hlasu, reči)"},
{"code":"Z50.6!","desc":"Ortopedické cvičenia"},
{"code":"Z50.7!","desc":"Pracovná liečba a rehabilitácia, nezatriedená inde"},
{"code":"Z50.8!","desc":"Starostlivosť, zahŕňajúca iné rehabilitačné výkony"},
{"code":"Z50.9!","desc":"Starostlivosť, zahŕňajúca rehabilitačné výkony, bližšie neurčené"},
{"code":"Z51.-","desc":"Iná zdravotná starostlivosť"},
{"code":"Z51.0","desc":"Liečebná rádioterapia"},
{"code":"Z51.1","desc":"Cyklus chemoterapie pre zhubný nádor"},
{"code":"Z51.2","desc":"Iná chemoterapia"},
{"code":"Z51.3","desc":"Transfúzia krvi bez uvedenia diagnózy"},
{"code":"Z51.4","desc":"Príprava pred následnou liečbou, nezatriedená inde"},
{"code":"Z51.5","desc":"Paliatívna starostlivosť"},
{"code":"Z51.6","desc":"Desenzibilizácia na alergény"},
{"code":"Z51.8-","desc":"Iná bližšie určená zdravotná starostlivosť"},
{"code":"Z51.81","desc":"Aferéza"},
{"code":"Z51.82","desc":"Kombinovaná liečba zhubného nádoru žiarením a chemoterapiou"},
{"code":"Z51.83","desc":"Opioidová substitúcia"},
{"code":"Z51.88","desc":"Iná bližšie určená lekárska starostlivosť"},
{"code":"Z51.9","desc":"Zdravotná starostlivosť, bližšie neurčená"},
{"code":"Z52.-","desc":"Darcovia orgánov a tkanív"},
{"code":"Z52.0-","desc":"Darca krvi"},
{"code":"Z52.00","desc":"Darca plnej krvi"},
{"code":"Z52.01","desc":"Darca kmeňových buniek"},
{"code":"Z52.08","desc":"Darca iných krvných zložiek"},
{"code":"Z52.1","desc":"Darca kože"},
{"code":"Z52.2","desc":"Darca kosti"},
{"code":"Z52.3","desc":"Darca kostnej drene"},
{"code":"Z52.4","desc":"Darca obličky"},
{"code":"Z52.5","desc":"Darca rohovky"},
{"code":"Z52.6","desc":"Darca pečene"},
{"code":"Z52.7","desc":"Darca srdca"},
{"code":"Z52.8-","desc":"Darca iného orgánu alebo tkaniva"},
{"code":"Z52.81","desc":"Darca pľúc"},
{"code":"Z52.82","desc":"Darca pankreasu"},
{"code":"Z52.89","desc":"Darca iného orgánu alebo tkaniva"},
{"code":"Z52.9","desc":"Darca bližšie neurčeného orgánu alebo tkaniva"},
{"code":"Z53.-","desc":"Osoby, ktoré navštívili zdravotnícke služby kvôli určitým neuskutočneným výkonom"},
{"code":"Z53.0","desc":"Neuskutočnenie výkonu pre kontraindikáciu"},
{"code":"Z53.1","desc":"Neuskutočnenie výkonu z rozhodnutia pacienta pre náboženské dôvody a skupinový nátlak"},
{"code":"Z53.2","desc":"Neuskutočnenie výkonu z rozhodnutia pacienta pre iné a bližšie neurčené príčiny"},
{"code":"Z53.8","desc":"Neuskutočnenie výkonu pre iné príčiny"},
{"code":"Z53.9","desc":"Neuskutočnenie výkonu pre bližšie neurčenú príčinu"},
{"code":"Z54.-!","desc":"Rekonvalescencia"},
{"code":"Z54.0!","desc":"Rekonvalescencia po operačnom výkone"},
{"code":"Z54.1!","desc":"Rekonvalescencia po rádioterapii"},
{"code":"Z54.2!","desc":"Rekonvalescencia po chemoterapii"},
{"code":"Z54.3!","desc":"Rekonvalescencia po psychoterapii"},
{"code":"Z54.4!","desc":"Rekonvalescencia po liečbe zlomeniny"},
{"code":"Z54.7!","desc":"Rekonvalescencia po kombinovanej liečbe"},
{"code":"Z54.8!","desc":"Rekonvalescencia po inej liečbe"},
{"code":"Z54.9!","desc":"Rekonvalescencia po bližšie neurčenej liečbe"},
{"code":"Z55-Z65","desc":"Osoby s potenciálnym ohrozením zdravia v súvislosti so sociálno-ekonomickými a psychosociálnymi okolnosťami"},
{"code":"Z55.-","desc":"Problémy súvisiace so vzdelávaním a gramotnosťou"},
{"code":"Z55.0","desc":"Negramotnosť a nízky stupeň gramotnosti"},
{"code":"Z55.1","desc":"Nedostupné a nedosiahnuteľné školské vzdelanie"},
{"code":"Z55.2","desc":"Nezvládnutie skúšok"},
{"code":"Z55.3","desc":"Zlý prospech v škole"},
{"code":"Z55.4","desc":"Zlá prispôsobivosť pri vyučovaní a konflikty s učiteľmi a spolužiakmi"},
{"code":"Z55.8","desc":"Iné problémy súvisiace so vzdelávaním a gramotnosťou"},
{"code":"Z55.9","desc":"Problémy súvisiace so vzdelávaním a gramotnosťou, bližšie neurčené"},
{"code":"Z56.-","desc":"Problémy súvisiace so zamestnaním a nezamestnanosťou"},
{"code":"Z56.0","desc":"Nezamestnanosť, bližšie neurčená"},
{"code":"Z56.1","desc":"Zmena zamestnania"},
{"code":"Z56.2","desc":"Hrozba straty zamestnania"},
{"code":"Z56.3","desc":"Stresujúci pracovný režim"},
{"code":"Z56.4","desc":"Konflikt s nadriadeným a spolupracovníkmi"},
{"code":"Z56.5","desc":"Nevhodná práca"},
{"code":"Z56.6","desc":"Iné fyzické a duševné vypätie v súvislosti s prácou"},
{"code":"Z56.7","desc":"Iné a bližšie neurčené problémy súvisiace so zamestnaním"},
{"code":"Z57.-","desc":"Profesionálne vystavenie rizikovým faktorom"},
{"code":"Z57.0","desc":"Profesionálne vystavenie hluku"},
{"code":"Z57.1","desc":"Profesionálne vystavenie žiareniu (radiácii)"},
{"code":"Z57.2","desc":"Profesionálne vystavenie prachu"},
{"code":"Z57.3","desc":"Profesionálne vystavenie iným znečisťujúcim látkam v ovzduší"},
{"code":"Z57.4","desc":"Profesionálne vystavenie toxickým látkam v poľnohospodárstve"},
{"code":"Z57.5","desc":"Profesionálne vystavenie toxickým látkam v iných priemyselných odvetviach"},
{"code":"Z57.6","desc":"Profesionálne vystavenie extrémnej teplote"},
{"code":"Z57.7","desc":"Profesionálne vystavenie vibráciám"},
{"code":"Z57.8","desc":"Profesionálne vystavenie iným rizikovým faktorom"},
{"code":"Z57.9","desc":"Profesionálne vystavenie bližšie neurčenému rizikovému faktoru"},
{"code":"Z58.-","desc":"Problémy súvisiace so životným prostredím"},
{"code":"Z58.0","desc":"Vystavenie hluku"},
{"code":"Z58.1","desc":"Vystavenie znečistenému ovzdušiu"},
{"code":"Z58.2","desc":"Vystavenie znečistenej vode"},
{"code":"Z58.3","desc":"Vystavenie znečistenej pôde"},
{"code":"Z58.4","desc":"Vystavenie žiareniu"},
{"code":"Z58.5","desc":"Vystavenie inému znečisteniu"},
{"code":"Z58.6","desc":"Nedostatočné zásobovanie pitnou vodou"},
{"code":"Z58.7","desc":"Vystavenie tabakovému dymu"},
{"code":"Z58.8","desc":"Iné problémy súvisiace so životným prostredím"},
{"code":"Z58.9","desc":"Problém súvisiaci so životným prostredím, bližšie neurčený"},
{"code":"Z59.-","desc":"Problémy súvisiace s bývaním alebo ekonomickou situáciou"},
{"code":"Z59.0","desc":"Bezdomovstvo"},
{"code":"Z59.1","desc":"Neprimerané bývanie"},
{"code":"Z59.2","desc":"Nezhody so susedmi, nájomníkmi a vlastníkom"},
{"code":"Z59.3","desc":"Problémy súvisiace s bývaním v ubytovacom zariadení"},
{"code":"Z59.4","desc":"Nedostatok primeranej potravy"},
{"code":"Z59.5","desc":"Extrémna chudoba"},
{"code":"Z59.6","desc":"Nízky príjem"},
{"code":"Z59.7","desc":"Nedostatočné sociálne poistenie a sociálna starostlivosť"},
{"code":"Z59.8","desc":"Iné problémy súvisiace s bývaním a ekonomickou situáciou"},
{"code":"Z59.9","desc":"Problém súvisiaci s bývaním a ekonomickou situáciou, bližšie neurčený"},
{"code":"Z60.-","desc":"Problémy súvisiace so sociálnym prostredím"},
{"code":"Z60.0","desc":"Problémy s prispôsobením sa zmenám životného cyklu"},
{"code":"Z60.1","desc":"Atypická rodičovská situácia"},
{"code":"Z60.2","desc":"Osamelo žijúca osoba"},
{"code":"Z60.3","desc":"Ťažkosti s kultúrnou asimiláciou"},
{"code":"Z60.4","desc":"Sociálne vylúčenie a odvrhnutie"},
{"code":"Z60.5","desc":"Terč diskriminácie a perzekúcie"},
{"code":"Z60.8","desc":"Iné problémy súvisiace so sociálnym prostredím"},
{"code":"Z60.9","desc":"Problém súvisiaci so sociálnym prostredím, bližšie neurčený"},
{"code":"Z61.-","desc":"Problémy súvisiace s negatívnymi udalosťami v detstve"},
{"code":"Z61.0","desc":"Strata láskyplného vzťahu v detstve"},
{"code":"Z61.1","desc":"Premiestnenie z domova v detstve"},
{"code":"Z61.2","desc":"Zmena rodinných vzťahov v detstve"},
{"code":"Z61.3","desc":"Udalosti vedúce k strate sebaúcty v detstve"},
{"code":"Z61.4","desc":"Problémy súvisiace s údajným pohlavným zneužitím dieťaťa osobou z okruhu primárnej starostlivosti"},
{"code":"Z61.5","desc":"Problémy súvisiace s údajným pohlavným zneužitím dieťaťa osobou mimo okruhu primárnej starostlivosti"},
{"code":"Z61.6","desc":"Problémy súvisiace s údajným fyzickým zneužitím dieťaťa"},
{"code":"Z61.7","desc":"Osobná desivá skúsenosť v detstve"},
{"code":"Z61.8","desc":"Iné negatívne udalosti v detstve"},
{"code":"Z61.9","desc":"Negatívna udalosť v detstve, bližšie neurčená"},
{"code":"Z62.-","desc":"Iné problémy súvisiace s výchovou"},
{"code":"Z62.0","desc":"Nedostatočný rodičovský dozor a kontrola"},
{"code":"Z62.1","desc":"Nadmerná rodičovská ochrana"},
{"code":"Z62.2","desc":"Ústavná výchova"},
{"code":"Z62.3","desc":"Nepriateľstvo voči dieťaťu, dieťa ako obetný baránok"},
{"code":"Z62.4","desc":"Emočné zanedbávanie dieťaťa"},
{"code":"Z62.5","desc":"Iné problémy súvisiace so zanedbávaním výchovy"},
{"code":"Z62.6","desc":"Neprimeraný rodičovský nátlak a iné abnormálne spôsoby výchovy"},
{"code":"Z62.8","desc":"Iné bližšie určené problémy súvisiace s výchovou"},
{"code":"Z62.9","desc":"Problém súvisiaci s výchovou, bližšie neurčený"},
{"code":"Z63.-","desc":"Iné problémy súvisiace so skupinou primárnej starostlivosti vrátane rodinnej situácie"},
{"code":"Z63.0","desc":"Problémy vo vzťahu medzi manželmi alebo partnermi"},
{"code":"Z63.1","desc":"Problémy vo vzťahu s rodičmi a svokrovcami"},
{"code":"Z63.2","desc":"Neprimeraná rodinná podpora"},
{"code":"Z63.3","desc":"Neprítomnosť člena rodiny"},
{"code":"Z63.4","desc":"Zmiznutie a smrť člena rodiny"},
{"code":"Z63.5","desc":"Rozpadnutie rodiny odlúčením a rozvodom"},
{"code":"Z63.6","desc":"Príbuzný odkazáný na domácu starostlivosť"},
{"code":"Z63.7","desc":"Iné stresujúce životné udalosti postihujúce rodinu a domácnosť"},
{"code":"Z63.8","desc":"Iné bližšie určené problémy súvisiace so skupinou primárnej starostlivosti"},
{"code":"Z63.9","desc":"Problémy súvisiace so skupinou primárnej starostlivosti, bližšie neurčené"},
{"code":"Z64.-","desc":"Problémy súvisiace s určitými psychosociálnymi okolnosťami"},
{"code":"Z64.0","desc":"Problémy súvisiace s nechcenou graviditou"},
{"code":"Z64.1","desc":"Problémy súvisiace s multiparitou"},
{"code":"Z64.8","desc":"Iné kontaktné príležitosti vo vzťahu k určitým psychosociálnym okolnostiam"},
{"code":"Z65.-","desc":"Problémy súvisiace s inými psychosociálnymi okolnosťami"},
{"code":"Z65.0","desc":"Odsúdenie v civilnom a trestnom procese bez uväznenia"},
{"code":"Z65.1","desc":"Uväznenie a iné zadržanie"},
{"code":"Z65.2","desc":"Problémy súvisiace s prepustením z väzenia"},
{"code":"Z65.3","desc":"Problémy súvisiace s inými právnymi okolnosťami"},
{"code":"Z65.4","desc":"Obeť kriminálneho činu a terorizmu"},
{"code":"Z65.5","desc":"Vystavenie pohrome, vojne a iným prejavom nepriateľstva"},
{"code":"Z65.8","desc":"Iné bližšie určené problémy súvisiace s psychosociálnymi okolnosťami"},
{"code":"Z65.9","desc":"Problém súvisiaci s bližšie neurčenými psychosociálnymi okolnosťami"},
{"code":"Z70-Z76","desc":"Osoby, ktoré vyhľadali zdravotnícke služby za iných okolností"},
{"code":"Z70.-","desc":"Poradenstvo súvisiace so vzťahom k sexualite, sexuálnym správaním a orientáciou"},
{"code":"Z70.0","desc":"Poradenstvo súvisiace s pohlavným postojom"},
{"code":"Z70.1","desc":"Poradenstvo súvisiace s pacientovým pohlavným správaním a orientáciou"},
{"code":"Z70.2","desc":"Poradenstvo súvisiace s pohlavným správaním a orientáciou tretej osoby"},
{"code":"Z70.3","desc":"Poradenstvo súvisiace s kombináciou problémov pohlavného postoja, správania a orientácie"},
{"code":"Z70.8","desc":"Iné sexuologické poradenstvo"},
{"code":"Z70.9","desc":"Sexuologické poradenstvo, bližšie neurčené"},
{"code":"Z71.-","desc":"Osoby, ktoré vyhľadali zdravotnícke služby kvôli inému poradenstvu, nezatriedenému inde"},
{"code":"Z71.0","desc":"Osoba radiaca sa v záujme inej osoby"},
{"code":"Z71.1","desc":"Osoba so strachom z choroby, ktorá nebola dokázaná"},
{"code":"Z71.2","desc":"Osoba žiadajúca vysvetlenie laboratórnych nálezov"},
{"code":"Z71.3","desc":"Diétne poradenstvo a dohľad"},
{"code":"Z71.4","desc":"Poradenstvo a dohľad pri abúze alkoholu"},
{"code":"Z71.5","desc":"Poradenstvo a dohľad pri abúze drog"},
{"code":"Z71.6","desc":"Poradenstvo pri abúze tabaku"},
{"code":"Z71.7","desc":"Poradenstvo súvisiace s vírusom ľudskej imunitnej nedostatočnosti [HIV]"},
{"code":"Z71.8","desc":"Iné bližšie určené poradenstvo"},
{"code":"Z71.9","desc":"Poradenstvo, bližšie neurčené"},
{"code":"Z72.-","desc":"Problémy súvisiace so spôsobom života"},
{"code":"Z72.0","desc":"Problémy v súvislosti s užívaním tabaku"},
{"code":"Z72.1","desc":"Problémy v súvislosti s užívaním alkoholu"},
{"code":"Z72.2","desc":"Problémy v súvislosti s užívaním návykových látok"},
{"code":"Z72.3","desc":"Problémy v súvislosti s nedostatkom telesného pohybu"},
{"code":"Z72.4","desc":"Problémy v súvislosti s nevhodnou diétou a stravovacími návykmi"},
{"code":"Z72.5","desc":"Vysoko rizikové pohlavné správanie"},
{"code":"Z72.6","desc":"Hazardné hráčstvo a stávkovanie"},
{"code":"Z72.8","desc":"Iné problémy súvisiace so spôsobom života"},
{"code":"Z72.9","desc":"Problémy súvisiace so spôsobom života, bližšie neurčené"},
{"code":"Z73.-","desc":"Problémy súvisiace s ťažkosťami so zvládnutím života"},
{"code":"Z73.0","desc":"Syndróm vyhorenia [burn-out syndróm]"},
{"code":"Z73.1","desc":"Zvýraznenie osobnostných čŕt"},
{"code":"Z73.2","desc":"Nedostatok odpočinku a voľného času"},
{"code":"Z73.3","desc":"Stres, nezatriedený inde"},
{"code":"Z73.4","desc":"Nedostatočná sociálna zručnosť, nezatriedená inde"},
{"code":"Z73.5","desc":"Konflikt sociálnej úlohy, nezatriedený inde"},
{"code":"Z73.6","desc":"Obmedzenie aktivít zapríčinené neschopnosťou (disability)"},
{"code":"Z73.8","desc":"Iné problémy súvisiace s ťažkosťami so zvládnutím života"},
{"code":"Z73.9","desc":"Problémy súvisiace s ťažkosťami so zvládnutím života, bližšie neurčené"},
{"code":"Z74.-","desc":"Problémy súvisiace so závislosťou od opatrovateľa"},
{"code":"Z74.0","desc":"Potreba pomoci zapríčinená zníženou pohyblivosťou"},
{"code":"Z74.1","desc":"Potreba pomoci inej osoby"},
{"code":"Z74.2","desc":"Potreba pomoci doma, pričom nikto v domácnosti nie je schopný starostlivosť poskytnúť"},
{"code":"Z74.3","desc":"Potreba stáleho dohľadu"},
{"code":"Z74.8","desc":"Iné problémy súvisiace so závislosťou od opatrovateľa"},
{"code":"Z74.9","desc":"Problém súvisiaci so závislosťou od opatrovateľa, bližšie neurčený"},
{"code":"Z75.-","desc":"Problémy súvisiace so zdravotníckymi zariadeniami a inou zdravotnou starostlivosťou"},
{"code":"Z75.0","desc":"Zdravotné služby nedostupné v domácom prostredí"},
{"code":"Z75.1","desc":"Osoba čakajúca inde na prijatie do vhodného zariadenia"},
{"code":"Z75.2","desc":"Čakacia doba na vyšetrenie a liečbu"},
{"code":"Z75.3","desc":"Nedostupnosť a neprístupnosť zdravotníckych služieb"},
{"code":"Z75.4","desc":"Nedostupnosť a neprístupnosť iných pomocných služieb"},
{"code":"Z75.5","desc":"Starostlivosť počas dovolenky"},
{"code":"Z75.6-","desc":"Vykonaná registrácia na orgánovú transplantáciu bez vysokého stupňa naliehavosti"},
{"code":"Z75.60","desc":"Vykonaná registrácia na orgánovú transplantáciu bez vysokého stupňa naliehavosti: oblička"},
{"code":"Z75.64","desc":"Vykonaná registrácia na orgánovú transplantáciu bez vysokého stupňa naliehavosti: oblička – pankreas"},
{"code":"Z75.65","desc":"Vykonaná registrácia na orgánovú transplantáciu bez vysokého stupňa naliehavosti: pankreas"},
{"code":"Z75.66","desc":"Vykonaná registrácia na orgánovú transplantáciu bez vysokého stupňa naliehavosti: tenké črevo"},
{"code":"Z75.67","desc":"Vykonaná registrácia na orgánovú transplantáciu bez vysokého stupňa naliehavosti: pečeň"},
{"code":"Z75.68","desc":"Vykonaná registrácia na orgánovú transplantáciu bez vysokého stupňa naliehavosti: iný orgán"},
{"code":"Z75.69","desc":"Vykonaná registrácia na orgánovú transplantáciu bez vysokého stupňa naliehavosti: bližšie neurčený orgán"},
{"code":"Z75.7-","desc":"Vykonaná registrácia na orgánovú transplantáciu s vysokým stupňom naliehavosti"},
{"code":"Z75.70","desc":"Vykonaná registrácia na orgánovú transplantáciu s vysokým stupňom naliehavosti: oblička"},
{"code":"Z75.74","desc":"Vykonaná registrácia na orgánovú transplantáciu s vysokým stupňom naliehavosti: oblička – pankreas"},
{"code":"Z75.75","desc":"Vykonaná registrácia na orgánovú transplantáciu s vysokým stupňom naliehavosti: pankreas"},
{"code":"Z75.76","desc":"Vykonaná registrácia na orgánovú transplantáciu s vysokým stupňom naliehavosti: tenké črevo"},
{"code":"Z75.77","desc":"Vykonaná registrácia na orgánovú transplantáciu s vysokým stupňom naliehavosti: pečeň"},
{"code":"Z75.78","desc":"Vykonaná registrácia na orgánovú transplantáciu s vysokým stupňom naliehavosti: iný orgán"},
{"code":"Z75.79","desc":"Vykonaná registrácia na orgánovú transplantáciu s vysokým stupňom naliehavosti: bližšie neurčený orgán"},
{"code":"Z75.8","desc":"Iné problémy súvisiace s možnosťami zdravotnej starostlivosti alebo s inou zdravotnou opaterou"},
{"code":"Z75.9","desc":"Bližšie neurčený problém súvisiaci s možnosťami zdravotnej starostlivosti alebo s inou zdravotnou opaterou"},
{"code":"Z76.-","desc":"Osoba, ktorá navštívila zdravotnícke služby za iných okolností"},
{"code":"Z76.0","desc":"Vydanie opakovaného receptu"},
{"code":"Z76.1","desc":"Lekársky dohľad a starostlivosť o najdúcha"},
{"code":"Z76.2","desc":"Lekársky dohľad a starostlivosť o iné zdravé dojča a dieťa"},
{"code":"Z76.3","desc":"Zdravá osoba sprevádzajúca chorého"},
{"code":"Z76.4","desc":"Iný obyvateľ zariadenia opatrovateľskej starostlivosti"},
{"code":"Z76.5","desc":"Simulant (vedomá simulácia)"},
{"code":"Z76.8","desc":"Osoba, ktorá vyhľadala zdravotnícke služby za iných, bližšie určených okolností"},
{"code":"Z76.9","desc":"Osoba, ktorá vyhľadala zdravotnícke služby za bližšie neurčených okolností"},
{"code":"Z80-Z99","desc":"Osoby s potenciálnym ohrozením zdravia v súvislosti s rodinnou a osobnou anamnézou a s určitými okolnosťami, ovplyvňujúcimi zdravotný stav"},
{"code":"Z80.-","desc":"Zhubný nádor v rodinnej anamnéze"},
{"code":"Z80.0","desc":"Zhubný nádor tráviacich orgánov v rodinnej anamnéze"},
{"code":"Z80.1","desc":"Zhubný nádor priedušnice, priedušiek a pľúc v rodinnej anamnéze"},
{"code":"Z80.2","desc":"Zhubný nádor iných dýchacích a vnútrohrudníkových orgánov v rodinnej anamnéze"},
{"code":"Z80.3","desc":"Zhubný nádor prsníka v rodinnej anamnéze"},
{"code":"Z80.4","desc":"Zhubný nádor pohlavných orgánov v rodinnej anamnéze"},
{"code":"Z80.5","desc":"Zhubný nádor močovej sústavy v rodinnej anamnéze"},
{"code":"Z80.6","desc":"Leukémia v rodinnej anamnéze"},
{"code":"Z80.7","desc":"Zhubné nádory lymfatického, krvotvorného a príbuzného tkaniva v rodinnej anamnéze"},
{"code":"Z80.8","desc":"Zhubný nádor iných orgánov alebo systémov v rodinnej anamnéze"},
{"code":"Z80.9","desc":"Zhubný nádor v rodinnej anamnéze, bližšie neurčený"},
{"code":"Z81.-","desc":"Poruchy psychiky a správania v rodinnej anamnéze"},
{"code":"Z81.0","desc":"Duševná zaostalosť v rodinnej anamnéze"},
{"code":"Z81.1","desc":"Abúzus alkoholu v rodinnej anamnéze"},
{"code":"Z81.2","desc":"Abúzus nikotínu v rodinnej anamnéze"},
{"code":"Z81.3","desc":"Abúzus iných psychoaktívnych látok v rodinnej anamnéze"},
{"code":"Z81.4","desc":"Abúzus iných látok v rodinnej anamnéze"},
{"code":"Z81.8","desc":"Iné poruchy psychiky a správania v rodinnej anamnéze"},
{"code":"Z82.-","desc":"Niektoré postihnutia a chronické choroby vedúce k invalidite v rodinnej anamnéze"},
{"code":"Z82.0","desc":"Epilepsia a iné choroby nervovej sústavy v rodinnej anamnéze"},
{"code":"Z82.1","desc":"Slepota a strata zraku v rodinnej anamnéze"},
{"code":"Z82.2","desc":"Hluchota a strata sluchu v rodinnej anamnéze"},
{"code":"Z82.3","desc":"Porážka v rodinnej anamnéze"},
{"code":"Z82.4","desc":"Ischemická choroba srdca a iné choroby obehovej sústavy v rodinnej anamnéze"},
{"code":"Z82.5","desc":"Astma a iné chronické choroby dolných dýchacích ciest v rodinnej anamnéze"},
{"code":"Z82.6","desc":"Artritída a iné choroby svalovej a kostrovej sústavy a spojivového tkaniva v rodinnej anamnéze"},
{"code":"Z82.7","desc":"Vrodené chyby, deformácie a chromozómové anomálie v rodinnej anamnéze"},
{"code":"Z82.8","desc":"Iné postihnutia a chronické choroby vedúce k invalidite v rodinnej anamnéze"},
{"code":"Z83.-","desc":"Iné určené choroby v rodinnej anamnéze"},
{"code":"Z83.0","desc":"Choroba zapríčinená vírusom ľudskej imunitnej nedostatočnosti [HIV] v rodinnej anamnéze"},
{"code":"Z83.1","desc":"Iné infekčné alebo parazitové choroby v rodinnej anamnéze"},
{"code":"Z83.2","desc":"Choroby krvi a krvotvorných orgánov a niektoré poruchy, týkajúce sa imunitných mechanizmov v rodinnej anamnéze"},
{"code":"Z83.3","desc":"Diabetes mellitus v rodinnej anamnéze"},
{"code":"Z83.4","desc":"Iné endokrinné, nutričné a metabolické choroby v rodinnej anamnéze"},
{"code":"Z83.5","desc":"Očné a ušné choroby v rodinnej anamnéze"},
{"code":"Z83.6","desc":"Choroby dýchacej sústavy v rodinnej anamnéze"},
{"code":"Z83.7","desc":"Choroby tráviacej sústavy v rodinnej anamnéze"},
{"code":"Z84.-","desc":"Iné choroby v rodinnej anamnéze"},
{"code":"Z84.0","desc":"Choroby kože a podkožného tkaniva v rodinnej anamnéze"},
{"code":"Z84.1","desc":"Choroby obličiek a močovodov v rodinnej anamnéze"},
{"code":"Z84.2","desc":"Iné choroby močovopohlavnej sústavy v rodinnej anamnéze"},
{"code":"Z84.3","desc":"Pokrvnosť v rodinnej anamnéze"},
{"code":"Z84.8","desc":"Iné bližšie určené choroby v rodinnej anamnéze"},
{"code":"Z85.-","desc":"Zhubný nádor v osobnej anamnéze"},
{"code":"Z85.0","desc":"Zhubný nádor tráviacich orgánov v osobnej anamnéze"},
{"code":"Z85.1","desc":"Zhubný nádor priedušnice, priedušiek a pľúc v osobnej anamnéze"},
{"code":"Z85.2","desc":"Zhubný nádor iných dýchacích a vnútrohrudníkových orgánov v osobnej anamnéze"},
{"code":"Z85.3","desc":"Zhubný nádor prsníka v osobnej anamnéze"},
{"code":"Z85.4","desc":"Zhubný nádor pohlavných orgánov v osobnej anamnéze"},
{"code":"Z85.5","desc":"Zhubný nádor močovej sústavy v osobnej anamnéze"},
{"code":"Z85.6","desc":"Leukémia v osobnej anamnéze"},
{"code":"Z85.7","desc":"Iné zhubné nádory lymfatického, krvotvorného a príbuzného tkaniva v osobnej anamnéze"},
{"code":"Z85.8","desc":"Zhubný nádor iných orgánov alebo systémov v osobnej anamnéze"},
{"code":"Z85.9","desc":"Zhubný nádor v osobnej anamnéze, bližšie neurčený"},
{"code":"Z86.-","desc":"Niektoré iné choroby v osobnej anamnéze"},
{"code":"Z86.0","desc":"Iné nádory v osobnej anamnéze"},
{"code":"Z86.1","desc":"Infekčné a parazitové choroby v osobnej anamnéze"},
{"code":"Z86.2","desc":"Choroby krvi a krvotvorných orgánov a niektoré poruchy, týkajúce sa imunitných mechanizmov v osobnej anamnéze"},
{"code":"Z86.3","desc":"Endokrinné, nutričné a metabolické choroby v osobnej anamnéze"},
{"code":"Z86.4","desc":"Abúzus psychoaktívnych látok v osobnej anamnéze"},
{"code":"Z86.5","desc":"Iné poruchy psychiky a správania v osobnej anamnéze"},
{"code":"Z86.6","desc":"Choroby nervovej sústavy a zmyslových orgánov v osobnej anamnéze"},
{"code":"Z86.7","desc":"Choroby obehovej sústavy v osobnej anamnéze"},
{"code":"Z87.-","desc":"Iné choroby a stavy v osobnej anamnéze"},
{"code":"Z87.0","desc":"Choroby dýchacej sústavy v osobnej anamnéze"},
{"code":"Z87.1","desc":"Choroby tráviacich orgánov v osobnej anamnéze"},
{"code":"Z87.2","desc":"Choroby kože a podkožného tkaniva v osobnej anamnéze"},
{"code":"Z87.3","desc":"Choroby svalovej a kostrovej sústavy a spojivového tkaniva v osobnej anamnéze"},
{"code":"Z87.4","desc":"Choroby močovopohlavnej sústavy v osobnej anamnéze"},
{"code":"Z87.5","desc":"Komplikácie gravidity, pôrodu a šestonedelia v osobnej anamnéze"},
{"code":"Z87.6","desc":"Niektoré patologické stavy v perinatálnom období v osobnej anamnéze"},
{"code":"Z87.7","desc":"Vrodené chyby, deformácie a chromozómové anomálie v osobnej anamnéze"},
{"code":"Z87.8","desc":"Iné bližšie určené choroby v osobnej anamnéze"},
{"code":"Z88.-","desc":"Alergia na liečivá, lieky a biologické látky v osobnej anamnéze"},
{"code":"Z88.0","desc":"Alergia na penicilín v osobnej anamnéze"},
{"code":"Z88.1","desc":"Alergia na iné antibiotiká v osobnej anamnéze"},
{"code":"Z88.2","desc":"Alergia na sulfonamidy v osobnej anamnéze"},
{"code":"Z88.3","desc":"Alergia na iné protiinfekčné lieky v osobnej anamnéze"},
{"code":"Z88.4","desc":"Alergia na anestetiká v osobnej anamnéze"},
{"code":"Z88.5","desc":"Alergia na opiáty v osobnej anamnéze"},
{"code":"Z88.6","desc":"Alergia na analgetiká v osobnej anamnéze"},
{"code":"Z88.7","desc":"Alergia na sérum a vakcínu v osobnej anamnéze"},
{"code":"Z88.8","desc":"Alergia na iné lieky, liečivá a biologicky aktívne látky v osobnej anamnéze"},
{"code":"Z88.9","desc":"Alergia na bližšie neurčené lieky, liečivá a biologicky aktívne látky v osobnej anamnéze"},
{"code":"Z89.-","desc":"Získané chýbanie končatiny"},
{"code":"Z89.0","desc":"Získané chýbanie prsta (prstov, vrátane palca), jednostranné"},
{"code":"Z89.1","desc":"Získané chýbanie ruky a zápästia"},
{"code":"Z89.2","desc":"Získané chýbanie hornej končatiny nad zápästím"},
{"code":"Z89.3","desc":"Získané chýbanie oboch horných končatín na akejkoľvek úrovni"},
{"code":"Z89.4","desc":"Získané chýbanie nohy a členka"},
{"code":"Z89.5","desc":"Získané chýbanie dolnej končatiny v úrovni kolena alebo pod kolenom, jednostranné"},
{"code":"Z89.6","desc":"Získané chýbanie dolnej končatiny nad kolenom, jednostranné"},
{"code":"Z89.7","desc":"Získané chýbanie oboch dolných končatín (ktorákoľvek úroveň, okrem samých prstov)"},
{"code":"Z89.8","desc":"Získané chýbanie horných a dolných končatín (ktorákoľvek úroveň)"},
{"code":"Z89.9","desc":"Získané chýbanie končatiny, bližšie neurčené"},
{"code":"Z90.-","desc":"Získané chýbanie orgánov, nezatriedené inde"},
{"code":"Z90.0","desc":"Získané chýbanie časti hlavy a krku"},
{"code":"Z90.1","desc":"Získané chýbanie prsníka (prsníkov)"},
{"code":"Z90.2","desc":"Získané chýbanie (časti) pľúc"},
{"code":"Z90.3","desc":"Získané chýbanie časti žalúdka"},
{"code":"Z90.4","desc":"Získané chýbanie iných častí tráviacej sústavy"},
{"code":"Z90.5","desc":"Získané chýbanie obličky"},
{"code":"Z90.6","desc":"Získané chýbanie iných orgánov močovej sústavy"},
{"code":"Z90.7","desc":"Získané chýbanie pohlavného orgánu (orgánov)"},
{"code":"Z90.8","desc":"Získané chýbanie iných orgánov"},
{"code":"Z91.-","desc":"Rizikové faktory v osobnej anamnéze, nezatriedené inde"},
{"code":"Z91.0","desc":"Alergia na iné látky ako lieky a biologické látky v osobnej anamnéze"},
{"code":"Z91.1","desc":"Nedodržiavanie liečby a liečebného režimu v osobnej anamnéze"},
{"code":"Z91.2","desc":"Nedostatočná osobná hygiena v osobnej anamnéze"},
{"code":"Z91.3","desc":"Nezdravý spánkový cyklus v osobnej anamnéze"},
{"code":"Z91.4","desc":"Psychická trauma v osobnej anamnéze, nezatriedená inde"},
{"code":"Z91.5","desc":"Samopoškodenie v osobnej anamnéze"},
{"code":"Z91.6","desc":"Iná fyzická trauma v osobnej anamnéze"},
{"code":"Z91.8","desc":"Iné bližšie určené rizikové faktory, nezatriedené inde"},
{"code":"Z92.-","desc":"Predchádzajúca liečba v osobnej anamnéze"},
{"code":"Z92.0","desc":"Antikoncepcia v osobnej anamnéze"},
{"code":"Z92.1","desc":"Dlhodobé (súčasné) užívanie antikoagulancií v osobnej anamnéze"},
{"code":"Z92.2","desc":"Dlhodobé (súčasné) užívanie iných liekov v osobnej anamnéze"},
{"code":"Z92.3","desc":"Rádioterapia v osobnej anamnéze"},
{"code":"Z92.5","desc":"Rehabilitačné opatrenia v osobnej anamnéze"},
{"code":"Z92.4","desc":"Veľký chirurgický výkon v osobnej anamnéze, nezatriedený inde"},
{"code":"Z92.6","desc":"Chemoterapia pre zhubný nádor v osobnej anamnéze"},
{"code":"Z92.8","desc":"Iná liečba v osobnej anamnéze"},
{"code":"Z92.9","desc":"Liečba v osobnej anamnéze, bližšie neurčená"},
{"code":"Z93.-","desc":"Stav s predtým umelo vytvoreným vyústením (stómiou)"},
{"code":"Z93.0","desc":"Stav s predtým vytvorenou tracheostómiou"},
{"code":"Z93.1","desc":"Stav s predtým vytvorenou gastrostómiou"},
{"code":"Z93.2","desc":"Stav s predtým vytvorenou ilestómiou"},
{"code":"Z93.3","desc":"Stav s predtým vytvorenou kolostómiou"},
{"code":"Z93.4","desc":"Stav s predtým umelo vytvoreným vyústením gastrointestinálnej sústavy"},
{"code":"Z93.5","desc":"Stav s predtým vytvorenou cystostómiou"},
{"code":"Z93.6","desc":"Stav s predtým vytvoreným iným umelým vyústením močovej sústavy"},
{"code":"Z93.8-","desc":"Stav s predtým umelo vytvoreným iným vyústením"},
{"code":"Z93.80","desc":"Stav s predtým vytvorenou hrudníkovou stómiou"},
{"code":"Z93.88","desc":"Stav s predtým umelo vytvoreným iným vyústením"},
{"code":"Z93.9","desc":"Stav s predtým umelo vytvoreným vyústením, bližšie neurčeným"},
{"code":"Z94.-","desc":"Stav po transplantácii orgánu a tkaniva"},
{"code":"Z94.0","desc":"Stav po transplantácii obličky"},
{"code":"Z94.1","desc":"Stav po transplantácii srdca"},
{"code":"Z94.2","desc":"Stav po transplantácii pľúc"},
{"code":"Z94.3","desc":"Stav po transplantácii srdca a pľúc"},
{"code":"Z94.4","desc":"Stav po transplantácii pečene"},
{"code":"Z94.5","desc":"Stav po transplantácii kože"},
{"code":"Z94.6","desc":"Stav po transplantácii kosti"},
{"code":"Z94.7-","desc":"Stav po transplantácii rohovky"},
{"code":"Z94.70","desc":"Rejekcia transplantátu rohovky"},
{"code":"Z94.79","desc":"Stav po transplantácii rohovky"},
{"code":"Z94.8-","desc":"Stav po transplantácii iného orgánu alebo tkaniva"},
{"code":"Z94.80","desc":"Stav po transplantácii krvotvorných (kmeňových) buniek, bez súčasnej imunosupresie"},
{"code":"Z94.81","desc":"Stav po transplantácii krvotvorných (kmeňových) buniek, so súčasnou imunosupresiou"},
{"code":"Z94.82","desc":"Stav po transplantácii pankreasu"},
{"code":"Z94.83","desc":"Stav po transplantácii obličky a pankreasu"},
{"code":"Z94.88","desc":"Stav po transplantácii iného orgánu alebo tkaniva"},
{"code":"Z94.9","desc":"Stav po transplantácii orgánu alebo tkaniva, bližšie neurčeného"},
{"code":"Z95.-","desc":"Stav po implantácii srdcového alebo cievneho implantátu alebo štepu"},
{"code":"Z95.0","desc":"Stav po implantácii kardiostimulátora"},
{"code":"Z95.1","desc":"Stav po implantácii aortokoronárneho bajpasu pomocou štepu"},
{"code":"Z95.2","desc":"Stav po implantácii protetickej srdcovej chlopne"},
{"code":"Z95.3","desc":"Stav po implantácii xenogénnej srdcovej chlopne"},
{"code":"Z95.4","desc":"Stav po implantácii inej náhrady srdcovej chlopne"},
{"code":"Z95.5","desc":"Stav po implantácii koronárneho angioplastického implantátu a štepu"},
{"code":"Z95.8-","desc":"Stav po implantácii iných srdcových alebo cievnych implantátov alebo štepov"},
{"code":"Z95.80","desc":"Stav po implantácii systému podporujúceho srdce"},
{"code":"Z95.81","desc":"Stav po implantácii cievneho permanentného katétra"},
{"code":"Z95.88","desc":"Stav po implantácii iných srdcových alebo cievnych implantátov alebo transplantátov"},
{"code":"Z95.9","desc":"Stav po implantácii srdcového alebo cievneho implantátu alebo štepu, bližšie neurčený"},
{"code":"Z96.-","desc":"Stav po implantácii iných funkčných implantátov"},
{"code":"Z96.0","desc":"Stav po implantácii močovopohlavných implantátov"},
{"code":"Z96.1","desc":"Stav po implantácii vnútroočnej šošovky"},
{"code":"Z96.2","desc":"Stav po implantácii ušných a audiologických implantátov"},
{"code":"Z96.3","desc":"Stav po implantácii umelého hrtana"},
{"code":"Z96.4","desc":"Stav po implantácii endokrinného implantátu"},
{"code":"Z96.5","desc":"Stav po implantácii implantátov zubného koreňa alebo sánkových implantátov"},
{"code":"Z96.6","desc":"Stav po implantácii ortopedických kĺbových implantátov"},
{"code":"Z96.7","desc":"Stav po implantácii iného kostného a šľachového implantátu"},
{"code":"Z96.8-","desc":"Stav po implantácii iných bližšie určených funkčných implantátov"},
{"code":"Z96.80","desc":"Stav po implantácii bronchového stentu"},
{"code":"Z96.81","desc":"Stav po implantácii tracheového stentu"},
{"code":"Z96.88","desc":"Stav po implantácii iných bližšie určených funkčných implantátov"},
{"code":"Z96.9","desc":"Stav po implantácii funkčných implantátov, bližšie neurčených"},
{"code":"Z97.-","desc":"Stav po implantácii iných zdravotných pomôcok"},
{"code":"Z97.0","desc":"Stav po implantácii umelého oka"},
{"code":"Z97.1","desc":"Stav po rekonštrukcii umelej končatiny (kompletnej alebo čiastočnej)"},
{"code":"Z97.2","desc":"Stav po implantácii zubnej protetickej pomôcky (kompletnej alebo čiastočnej)"},
{"code":"Z97.3","desc":"Prítomnosť okuliarov a kontaktných šošoviek"},
{"code":"Z97.4","desc":"Prítomnosť vonkajšej načúvacej pomôcky"},
{"code":"Z97.5","desc":"Stav po implantácii (vnútromaternicového) antikoncepčného telieska"},
{"code":"Z97.8","desc":"Stav po implantácii iných bližšie určených pomôcok"},
{"code":"Z98.-","desc":"Iné pooperačné stavy"},
{"code":"Z98.0","desc":"Stav po vytvorení črevného bajpasu alebo anastomózy"},
{"code":"Z98.1","desc":"Artrodéza"},
{"code":"Z98.2","desc":"Stav po implantácii pomôcky na drenáž mozgovomiechového moku"},
{"code":"Z98.8","desc":"Iné bližšie určené pooperačné stavy"},
{"code":"Z99.-","desc":"Dlhodobá závislosť od pomocných prístrojov alebo pomôcok, nezatriedená inde"},
{"code":"Z99.0","desc":"Závislosť od aspirátora"},
{"code":"Z99.1","desc":"Závislosť od respirátora"},
{"code":"Z99.2","desc":"Závislosť od obličkovej dialýzy"},
{"code":"Z99.3","desc":"Závislosť od invalidného vozíka"},
{"code":"Z99.8","desc":"Závislosť od iných pomocných prístrojov alebo pomôcok"},
{"code":"Z99.9","desc":"Závislosť od pomocného prístroja alebo pomôcky, bližšie neurčená"},
{"code":"U00-U99","desc":"XXII. Kapitola - Kódy na osobitné účely (U00-U99)"},
{"code":"U00-U49","desc":"Prechodné zaradenie novej choroby nejasného pôvodu"},
{"code":"U04.-","desc":"Ťažký akútny respiračný syndróm [SARS]"},
{"code":"U04.9","desc":"Ťažký akútny respiračný syndróm [SARS], bližšie neurčený"},
{"code":"U06.-","desc":"Choroba zapríčinená vírusom Zika"},
{"code":"U06.0","desc":"Nepokrytý kód U06.0"},
{"code":"U06.1","desc":"Nepokrytý kód U06.1"},
{"code":"U06.2","desc":"Nepokrytý kód U06.2"},
{"code":"U06.3","desc":"Nepokrytý kód U06.3"},
{"code":"U06.4","desc":"Nepokrytý kód U06.4"},
{"code":"U06.5","desc":"Nepokrytý kód U06.5"},
{"code":"U06.6","desc":"Nepokrytý kód U06.6"},
{"code":"U06.7","desc":"Nepokrytý kód U06.7"},
{"code":"U06.8","desc":"Nepokrytý kód U06.8"},
{"code":"U06.9","desc":"Choroba zapríčinená vírusom Zika, bližšie neurčená"},
{"code":"U07.-","desc":"Nepokryté kódy U07"},
{"code":"U07.0","desc":"Nepokrytý kód U07.0"},
{"code":"U07.1","desc":"Potvrdená infekcia COVID-19"},
{"code":"U07.2","desc":"Podozrenie z infekcie COVID-19"},
{"code":"U07.3","desc":"Potvrdená infekcia COVID-19 PCR testom"},
{"code":"U07.4","desc":"Potvrdená infekcia COVID-19 Ag testom"},
{"code":"U07.5","desc":"Nepokrytý kód U07.5"},
{"code":"U07.6","desc":"Nepokrytý kód U07.6"},
{"code":"U07.7","desc":"Nepokrytý kód U07.7"},
{"code":"U07.8","desc":"Nepokrytý kód U07.8"},
{"code":"U07.9","desc":"Nepokrytý kód U07.9"},
{"code":"U08.-","desc":"Osobná anamnéza COVID-19"},
{"code":"U08.0","desc":"Osobná anamnéza COVID-19"},
{"code":"U08.1","desc":"Nepokrytý kód U08.1"},
{"code":"U08.2","desc":"Nepokrytý kód U08.2"},
{"code":"U08.3","desc":"Nepokrytý kód U08.3"},
{"code":"U08.4","desc":"Nepokrytý kód U08.4"},
{"code":"U08.5","desc":"Nepokrytý kód U08.5"},
{"code":"U08.6","desc":"Nepokrytý kód U08.6"},
{"code":"U08.7","desc":"Nepokrytý kód U08.7"},
{"code":"U08.8","desc":"Nepokrytý kód U08.8"},
{"code":"U08.9","desc":"Osobná anamnéza COVID-19, bližšie neurčená"},
{"code":"U09.-","desc":"Stav po COVID-19"},
{"code":"U09.0","desc":"Stav po COVID-19"},
{"code":"U09.1","desc":"Vedľajšie príznaky v súvislosti s podaním vakcíny proti COVID-19"},
{"code":"U09.2","desc":"Nepokrytý kód U09.2"},
{"code":"U09.3","desc":"Nepokrytý kód U09.3"},
{"code":"U09.4","desc":"Nepokrytý kód U09.4"},
{"code":"U09.5","desc":"Nepokrytý kód U09.5"},
{"code":"U09.6","desc":"Nepokrytý kód U09.6"},
{"code":"U09.7","desc":"Nepokrytý kód U09.7"},
{"code":"U09.8","desc":"Nepokrytý kód U09.8"},
{"code":"U09.9","desc":"Stav po COVID-19, bližšie neurčený"},
{"code":"U10.-","desc":"Multisystémový zápalový syndróm v časovom vzťahu ku COVID-19"},
{"code":"U10.0","desc":"Multisystémový zápalový syndróm v časovom vzťahu ku COVID-19"},
{"code":"U10.1","desc":"Nepokrytý kód U10.1"},
{"code":"U10.2","desc":"Nepokrytý kód U10.2"},
{"code":"U10.3","desc":"Nepokrytý kód U10.3"},
{"code":"U10.4","desc":"Nepokrytý kód U10.4"},
{"code":"U10.5","desc":"Nepokrytý kód U10.5"},
{"code":"U10.6","desc":"Nepokrytý kód U10.6"},
{"code":"U10.7","desc":"Nepokrytý kód U10.7"},
{"code":"U10.8","desc":"Nepokrytý kód U10.8"},
{"code":"U10.9","desc":"Multisystémový zápalový syndróm v časovom vzťahu ku COVID-19, bližšie neurčený"},
{"code":"U50-U52","desc":"Funkčné obmedzenie"},
{"code":"U50.-","desc":"Funkčné obmedzenie pohybu"},
{"code":"U50.0-","desc":"Nijaké alebo minimálne obmedzenie pohybových funkcií"},
{"code":"U50.00","desc":"Nijaké alebo minimálne obmedzenie pohybových funkcií, Barthelovej index 100 bodov"},
{"code":"U50.01","desc":"Nijaké alebo minimálne obmedzenie pohybových funkcií, motorický FIM 85 – 91 bodov"},
{"code":"U50.1-","desc":"Ľahké obmedzenie pohybových funkcií"},
{"code":"U50.10","desc":"Ľahké obmedzenie pohybových funkcií, Barthelovej index 80 – 95 bodov"},
{"code":"U50.11","desc":"Ľahké obmedzenie pohybových funkcií, motorický FIM 69 – 84 bodov"},
{"code":"U50.2-","desc":"Stredné obmedzenie pohybových funkcií"},
{"code":"U50.20","desc":"Stredné obmedzenie pohybových funkcií, Barthelovej index 60 – 75 bodov"},
{"code":"U50.21","desc":"Stredné obmedzenie pohybových funkcií, motorický FIM 59 – 68 bodov"},
{"code":"U50.3-","desc":"Stredne ťažké obmedzenie pohybových funkcií"},
{"code":"U50.30","desc":"Stredne ťažké obmedzenie pohybových funkcií, Barthelovej index 40 – 55 bodov"},
{"code":"U50.31","desc":"Stredne ťažké obmedzenie pohybových funkcií, motorický FIM 43 – 58 bodov"},
{"code":"U50.4-","desc":"Ťažké obmedzenie pohybových funkcií"},
{"code":"U50.40","desc":"Ťažké obmedzenie pohybových funkcií, Barthelovej index 20 – 35 bodov"},
{"code":"U50.41","desc":"Ťažké obmedzenie pohybových funkcií, motorický FIM 31 – 42 bodov"},
{"code":"U50.5-","desc":"Veľmi ťažké obmedzenie pohybových funkcií"},
{"code":"U50.50","desc":"Veľmi ťažké obmedzenie pohybových funkcií, Barthelovej index 0 – 15 bodov"},
{"code":"U50.51","desc":"Veľmi ťažké obmedzenie pohybových funkcií, motorický FIM 13 – 30 bodov"},
{"code":"U51.-","desc":"Kognitívne funkčné obmedzenie"},
{"code":"U51.0-","desc":"Nijaké alebo minimálne obmedzenie kognitívnych funkcií"},
{"code":"U51.00","desc":"Nijaké alebo minimálne obmedzenie kognitívnych funkcií, rozšírený Barthelovej index 70 – 90 bodov"},
{"code":"U51.01","desc":"Nijaké alebo minimálne obmedzenie kognitívnych funkcií, kognitívny FIM 30 – 35 bodov"},
{"code":"U51.02","desc":"Nijaké alebo minimálne obmedzenie kognitívnych funkcií, MMSE 24 – 30 bodov"},
{"code":"U51.1-","desc":"Stredné obmedzenie kognitívnych funkcií"},
{"code":"U51.10","desc":"Stredné obmedzenie kognitívnych funkcií, rozšírený Barthelovej index 20 – 65 bodov"},
{"code":"U51.11","desc":"Stredné obmedzenie kognitívnych funkcií, kognitívny FIM 11 – 29 bodov"},
{"code":"U51.12","desc":"Stredné obmedzenie kognitívnych funkcií, MMSE 17 – 23 bodov"},
{"code":"U51.2-","desc":"Ťažké obmedzenie kognitívnych funkcií"},
{"code":"U51.20","desc":"Ťažké obmedzenie kognitívnych funkcií, rozšírený Barthelovej index 0 – 15 bodov"},
{"code":"U51.21","desc":"Ťažké obmedzenie kognitívnych funkcií, kognitívny FIM 5 – 10 bodov"},
{"code":"U51.22","desc":"Ťažké obmedzenie kognitívnych funkcií, MMSE 0 – 16 bodov"},
{"code":"U52.-","desc":"Včasná rehabilitácia - Barthelovej index [FRB]"},
{"code":"U52.0","desc":"Včasná rehabilitácia - Barthelovej index: 31 a viac bodov"},
{"code":"U52.1","desc":"Včasná rehabilitácia - Barthelovej index: -75 až 30 bodov"},
{"code":"U52.2","desc":"Včasná rehabilitácia - Barthelovej index: -200 až -76 bodov"},
{"code":"U52.3","desc":"Včasná rehabilitácia - Barthelovej index: menej ako -200 bodov"},
{"code":"U55-U55","desc":"Vykonaný zápis do transplantačného registra"},
{"code":"U55.-","desc":"Vykonaný zápis do transplantačného registra"},
{"code":"U55.1-","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu srdca"},
{"code":"U55.10","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu srdca, bez označenia stupňom naliehavé U [Urgency], alebo vysoko naliehavé HU [High Urgency]"},
{"code":"U55.11","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu srdca, označenie stupňom naliehavé U [Urgency]"},
{"code":"U55.12","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu srdca, označenie stupňom vysoko naliehavé HU [High Urgency]"},
{"code":"U55.2-","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu pľúc"},
{"code":"U55.20","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu pľúc, bez označenia stupňom naliehavé U [Urgency], alebo vysoko naliehavé HU [High Urgency]"},
{"code":"U55.21","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu pľúc, označenie stupňom naliehavé U [Urgency]"},
{"code":"U55.22","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu pľúc, označenie stupňom vysoko naliehavé HU [High Urgency]"},
{"code":"U55.3-","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu srdca a pľúc"},
{"code":"U55.30","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu srdca a pľúc, bez označenia stupňom naliehavé U [Urgency] alebo vysoko naliehavé HU [High Urgency]"},
{"code":"U55.31","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu srdca a pľúc, označenie stupňom naliehavé U [Urgency]"},
{"code":"U55.32","desc":"Vykonaný zápis do zoznamu čakateľov na transplantáciu srdca a pľúc, označenie stupňom vysoko naliehavé HU [High Urgency]"},
{"code":"U60-U61","desc":"Rozdelenie štádií infekcie HIV"},
{"code":"U60.-!","desc":"Klinické kategórie choroby HIV"},
{"code":"U60.1!","desc":"Klinické kategórie choroby HIV: kategória A"},
{"code":"U60.2!","desc":"Klinické kategórie choroby HIV: kategória B"},
{"code":"U60.3!","desc":"Klinické kategórie choroby HIV: kategória C"},
{"code":"U60.9!","desc":"Klinické kategórie choroby HIV, bližšie neurčené"},
{"code":"U61.-!","desc":"Počet pomocných T-buniek pri chorobe HIV"},
{"code":"U61.1!","desc":"Počet pomocných T-buniek pri chorobe HIV: kategória 1"},
{"code":"U61.2!","desc":"Počet pomocných T-buniek pri chorobe HIV: kategória 2"},
{"code":"U61.3!","desc":"Počet pomocných T-buniek pri chorobe HIV: kategória 3"},
{"code":"U61.9!","desc":"Počet (CD4+-) pomocných T-buniek pri chorobe HIV bližšie neurčený"},
{"code":"U69-U69","desc":"Ostatné sekundárne kódy na osobitné účely"},
{"code":"U69.-!","desc":"Ostatné sekundárne kódy na osobitné účely"},
{"code":"U69.0-!","desc":"Sekundárna klasifikácia za účelom externej kontroly kvality"},
{"code":"U69.00!","desc":"Sekundárna klasifikácia za účelom externej kontroly kvality: zápal pľúc u dospelých (18-ročných a starších), zatriedený inde, získaný v nemocnici"},
{"code":"U69.1-!","desc":"Sekundárna klasifikácia na osobitné administratívne účely"},
{"code":"U69.10!","desc":"Sekundárna klasifikácia na osobitné administratívne účely: choroba zatriedená inde, u ktorej je podozrenie, že je dôsledkom medicínsky neindikovanej estetickej operácie, tetováže alebo piercingu"},
{"code":"U69.2-!","desc":"Sekundárna klasifikácia na osobitné epidemiologické účely"},
{"code":"U69.20!","desc":"Sekundárna klasifikácia na osobitné epidemiologické účely: chrípka A/H1N1, pandémia 2009 [prasacia chrípka]"},
{"code":"U69.21!","desc":"Sekundárna klasifikácia na osobitné epidemiologické účely: chrípka A/H5N1, epidémia [vtáčia chrípka]"},
{"code":"U80-U85","desc":"Pôvodcovia rezistentní na antibiotiká a chemoterapeutiká"},
{"code":"U80.-!","desc":"Pôvodca s potvrdenou rezistenciou na antibiotiká, vyžadujúci špeciálne terapeutické alebo hygienické opatrenia"},
{"code":"U80.0!","desc":"Staphylococcus aureus rezistentný na oxacilín, glykopeptidové antibiotiká, chinolón, streptogramín alebo oxazolidinón"},
{"code":"U80.1!","desc":"Streptococcus pneumoniae rezistentný na penicilín, oxacilín, makrolidové antibiotiká, oxazolidinón alebo streptogramín"},
{"code":"U80.2!","desc":"Enterococcus faecalis rezistentný na glykopeptidové antibiotiká, oxazolidín, streptogramín alebo s vysokou úrovňou aminoglykozidovej rezistencie"},
{"code":"U80.3!","desc":"Enterococcus faecium rezistentný na glykopeptidové antibiotiká, oxazolidinón, streptogramín alebo s vysokou úrovňou aminoglykozidovej rezistencie"},
{"code":"U80.4!","desc":"Escherichia, klebsiella a proteus rezistentný na chinolón, karbapeném, amikacín, alebo s dokázateľnou rezistenciou na všetky betalaktámové antibiotiká [ESBL- rezistencia]"},
{"code":"U80.5!","desc":"Enterobacter, citrobacter a serratia rezistentná na karbapenén, chinolón alebo amikacín"},
{"code":"U80.6!","desc":"Pseudomonas aeruginosa a iné nonfermentujúce paličky s rezistenciou na karbapeném, chinolón, amikacín, ceftazidím alebo piperacillín/tazobaktam"},
{"code":"U80.7!","desc":"Burkholderia a Stenotrophomonas s rezistenciou na chinolón, amikacín, ceftazidím, piperacillín/tazobaktam alebo kotrimoxazol"},
{"code":"U81!","desc":"Pôvodca s mutirezistenciou na antibiotiká"},
{"code":"U82.-!","desc":"Mykobaktérie rezistentné na antituberkulotiká"},
{"code":"U82.0!","desc":"Mycobacterium tuberculosis s rezistenciou na jedno alebo viaceré antituberkulotiká prvého radu"},
{"code":"U82.1!","desc":"Multirezistentná tuberkulóza [MDR-TB]"},
{"code":"U82.2!","desc":"Atypické mykobaktérie alebo nocardia s rezistenciou na jeden alebo viacero liekov prvého radu"},
{"code":"U83!","desc":"Kandida s rezistenciou na flukonazol alebo vorikonazol"},
{"code":"U84!","desc":"Herpesvírusy s rezistenciou na virostatiká"},
{"code":"U85!","desc":"Vírus HIV s rezistenciou na virostatiká alebo inhibítory proteináz"},
{"code":"U99-U99","desc":"Nepokryté kódy pre osobitné účely U99"},
{"code":"U99.-","desc":"Nepokryté kódy pre osobitné účely"},
{"code":"U99.0-","desc":"Inkontinencia moču a stolice, klasifikovaná pre osobitné administratívne účely zdravotnej poisťovne"},
{"code":"U99.01","desc":"Inkontinencia moču, 2. stupeň"},
{"code":"U99.02","desc":"Inkontinencia moču a stolice, 3. stupeň, trvalá"},
{"code":"U99.03","desc":"Inkontinencia moču a stolice, 3. stupeň, trvalá a nezvratná"},
{"code":"U99.04","desc":"Trvalá a nezvratná kolostómia"},
{"code":"U99.05","desc":"Trvalá a nezvratná ileostómia"},
{"code":"U99.06","desc":"Trvalá a nezvratná urostómia"},
{"code":"U99.07","desc":"Intenzifikovaný inzulínový režim"},
{"code":"U99.08","desc":"Konvenčný inzulínový režim"},
{"code":"U99.09","desc":"Liečba perorálnymi antidiabetikami a diétou"},
{"code":"U99.10","desc":"Liečba inkretínovými injekčnými mimetikami"},
{"code":"U99.1","desc":"Nepokrytý kód U99.1"},
{"code":"U99.2","desc":"Nepokrytý kód U99.2"},
{"code":"U99.3","desc":"Nepokrytý kód U99.3"},
{"code":"U99.4","desc":"Nepokrytý kód U99.4"},
{"code":"U99.5","desc":"Nepokrytý kód U99.5"},
{"code":"U99.6","desc":"Nepokrytý kód U99.6"},
{"code":"U99.7","desc":"Nepokrytý kód U99.7"},
{"code":"U99.8","desc":"Nepokrytý kód U99.8"},
{"code":"U99.9","desc":"Nepokrytý kód U99.9"},
{"code":"W11.1","desc":"Pád na rebrík a z rebríka - MU: Kolektívne ústavné zariadenia"},
{"code":"W11.2","desc":"Pád na rebrík a z rebríka - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W11.3","desc":"Pád na rebrík a z rebríka - MU: Športové a atletické priestranstvo"},
{"code":"W11.4","desc":"Pád na rebrík a z rebríka - MU: Ulica a cesta"},
{"code":"W11.5","desc":"Pád na rebrík a z rebríka - MU: Obchod a služby"},
{"code":"W11.6","desc":"Pád na rebrík a z rebríka - MU: Priemyselné objekty"},
{"code":"W11.7","desc":"Pád na rebrík a z rebríka - MU: Poľnohospodárske"},
{"code":"W11.8","desc":"Pád na rebrík a z rebríka - MU: Iné špecifikované miesta"},
{"code":"W11.9","desc":"Pád na rebrík a z rebríka - MU: Nešpecifikované miesto"},
{"code":"W12.0","desc":"Pád na lešenie a z lešenia - MU: Domov"},
{"code":"W12.1","desc":"Pád na lešenie a z lešenia - MU: Kolektívne ústavné zariadenia"},
{"code":"W12.2","desc":"Pád na lešenie a z lešenia - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W12.3","desc":"Pád na lešenie a z lešenia - MU: Športové a atletické priestranstvo"},
{"code":"W12.4","desc":"Pád na lešenie a z lešenia - MU: Ulica a cesta"},
{"code":"W12.5","desc":"Pád na lešenie a z lešenia - MU: Obchod a služby"},
{"code":"W12.6","desc":"Pád na lešenie a z lešenia - MU: Priemyselné objekty"},
{"code":"W12.7","desc":"Pád na lešenie a z lešenia - MU: Poľnohospodárske"},
{"code":"W12.8","desc":"Pád na lešenie a z lešenia - MU: Iné špecifikované miesta"},
{"code":"W12.9","desc":"Pád na lešenie a z lešenia - MU: Nešpecifikované miesto"},
{"code":"W13.0","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Domov"},
{"code":"W13.1","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Kolektívne ústavné zariadenia"},
{"code":"W13.2","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W13.3","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Športové a atletické priestranstvo"},
{"code":"W13.4","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Ulica a cesta"},
{"code":"W13.5","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Obchod a služby"},
{"code":"W13.6","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Priemyselné objekty"},
{"code":"W13.7","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Poľnohospodárske"},
{"code":"W13.8","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Iné špecifikované miesta"},
{"code":"W13.9","desc":"Pád z budovy, v budove alebo inej stavbe - MU: Nešpecifikované miesto"},
{"code":"W14.0","desc":"Pád zo stromu - MU: Domov"},
{"code":"W14.1","desc":"Pád zo stromu - MU: Kolektívne ústavné zariadenia"},
{"code":"W14.2","desc":"Pád zo stromu - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W14.3","desc":"Pád zo stromu - MU: Športové a atletické priestranstvo"},
{"code":"W14.4","desc":"Pád zo stromu - MU: Ulica a cesta"},
{"code":"W14.5","desc":"Pád zo stromu - MU: Obchod a služby"},
{"code":"W14.6","desc":"Pád zo stromu - MU: Priemyselné objekty"},
{"code":"W14.7","desc":"Pád zo stromu - MU: Poľnohospodárske"},
{"code":"W14.8","desc":"Pád zo stromu - MU: Iné špecifikované miesta"},
{"code":"W14.9","desc":"Pád zo stromu - MU: Nešpecifikované miesto"},
{"code":"W15.0","desc":"Pád z brala - MU: Domov"},
{"code":"W15.1","desc":"Pád z brala - MU: Kolektívne ústavné zariadenia"},
{"code":"W15.2","desc":"Pád z brala - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W15.3","desc":"Pád z brala - MU: Športové a atletické priestranstvo"},
{"code":"W15.4","desc":"Pád z brala - MU: Ulica a cesta"},
{"code":"W15.5","desc":"Pád z brala - MU: Obchod a služby"},
{"code":"W15.6","desc":"Pád z brala - MU: Priemyselné objekty"},
{"code":"W15.7","desc":"Pád z brala - MU: Poľnohospodárske"},
{"code":"W15.8","desc":"Pád z brala - MU: Iné špecifikované miesta"},
{"code":"W15.9","desc":"Pád z brala - MU: Nešpecifikované miesto"},
{"code":"W16.0","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Domov"},
{"code":"W16.1","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Kolektívne ústavné zariadenia"},
{"code":"W16.2","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W16.3","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Športové a atletické priestranstvo"},
{"code":"W16.4","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Ulica a cesta"},
{"code":"W16.5","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Obchod a služby"},
{"code":"W16.6","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Priemyselné objekty"},
{"code":"W16.7","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Poľnohospodárske"},
{"code":"W16.8","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Iné špecifikované miesta"},
{"code":"W16.9","desc":"Ponáranie alebo skákanie do vody zapríčiňujúce iný úraz ako utopenie alebo topenie - MU: Nešpecifikované miesto"},
{"code":"W17.0","desc":"Iný pád z jednej úrovne na druhú - MU: Domov"},
{"code":"W17.1","desc":"Iný pád z jednej úrovne na druhú - MU: Kolektívne ústavné zariadenia"},
{"code":"W17.2","desc":"Iný pád z jednej úrovne na druhú - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W17.3","desc":"Iný pád z jednej úrovne na druhú - MU: Športové a atletické priestranstvo"},
{"code":"W17.4","desc":"Iný pád z jednej úrovne na druhú - MU: Ulica a cesta"},
{"code":"W17.5","desc":"Iný pád z jednej úrovne na druhú - MU: Obchod a služby"},
{"code":"W17.6","desc":"Iný pád z jednej úrovne na druhú - MU: Priemyselné objekty"},
{"code":"W17.7","desc":"Iný pád z jednej úrovne na druhú - MU: Poľnohospodárske"},
{"code":"W17.8","desc":"Iný pád z jednej úrovne na druhú - MU: Iné špecifikované miesta"},
{"code":"W17.9","desc":"Iný pád z jednej úrovne na druhú - MU: Nešpecifikované miesto"},
{"code":"W18.0","desc":"Iný pád na tej istej rovine - MU: Domov"},
{"code":"W18.1","desc":"Iný pád na tej istej rovine - MU: Kolektívne ústavné zariadenia"},
{"code":"W18.2","desc":"Iný pád na tej istej rovine - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W18.3","desc":"Iný pád na tej istej rovine - MU: Športové a atletické priestranstvo"},
{"code":"W18.4","desc":"Iný pád na tej istej rovine - MU: Ulica a cesta"},
{"code":"W18.5","desc":"Iný pád na tej istej rovine - MU: Obchod a služby"},
{"code":"W18.6","desc":"Iný pád na tej istej rovine - MU: Priemyselné objekty"},
{"code":"W18.7","desc":"Iný pád na tej istej rovine - MU: Poľnohospodárske"},
{"code":"W18.8","desc":"Iný pád na tej istej rovine - MU: Iné špecifikované miesta"},
{"code":"W18.9","desc":"Iný pád na tej istej rovine - MU: Nešpecifikované miesto"},
{"code":"W19.0","desc":"Bližšie neurčený pád - MU: Domov"},
{"code":"W19.1","desc":"Bližšie neurčený pád - MU: Kolektívne ústavné zariadenia"},
{"code":"W19.2","desc":"Bližšie neurčený pád - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W19.3","desc":"Bližšie neurčený pád - MU: Športové a atletické priestranstvo"},
{"code":"W19.4","desc":"Bližšie neurčený pád - MU: Ulica a cesta"},
{"code":"W19.5","desc":"Bližšie neurčený pád - MU: Obchod a služby"},
{"code":"W19.6","desc":"Bližšie neurčený pád - MU: Priemyselné objekty"},
{"code":"W19.7","desc":"Bližšie neurčený pád - MU: Poľnohospodárske"},
{"code":"W19.8","desc":"Bližšie neurčený pád - MU: Iné špecifikované miesta"},
{"code":"W19.9","desc":"Bližšie neurčený pád - MU: Nešpecifikované miesto"},
{"code":"W20.0","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Domov"},
{"code":"W20.1","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Kolektívne ústavné zariadenia"},
{"code":"W20.2","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W20.3","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Športové a atletické priestranstvo"},
{"code":"W20.4","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Ulica a cesta"},
{"code":"W20.5","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Obchod a služby"},
{"code":"W20.6","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Priemyselné objekty"},
{"code":"W20.7","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Poľnohospodárske"},
{"code":"W20.8","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Iné špecifikované miesta"},
{"code":"W20.9","desc":"Zasiahnutie hodeným, vrhnutým alebo padajúcim telesom - MU: Nešpecifikované miesto"},
{"code":"W21.0","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Domov"},
{"code":"W21.1","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Kolektívne ústavné zariadenia"},
{"code":"W21.2","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W21.3","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Športové a atletické priestranstvo"},
{"code":"W21.4","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Ulica a cesta"},
{"code":"W21.5","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Obchod a služby"},
{"code":"W21.6","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Priemyselné objekty"},
{"code":"W21.7","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Poľnohospodárske"},
{"code":"W21.8","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Iné špecifikované miesta"},
{"code":"W21.9","desc":"Narazenie do športového náradia alebo zasiahnutie náradím - MU: Nešpecifikované miesto"},
{"code":"W22.0","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Domov"},
{"code":"W22.1","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Kolektívne ústavné zariadenia"},
{"code":"W22.2","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W22.3","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Športové a atletické priestranstvo"},
{"code":"W22.4","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Ulica a cesta"},
{"code":"W22.5","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Obchod a služby"},
{"code":"W22.6","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Priemyselné objekty"},
{"code":"W22.7","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Poľnohospodárske"},
{"code":"W22.8","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Iné špecifikované miesta"},
{"code":"W22.9","desc":"Narazenie do iných predmetov alebo zasiahnutie týmito predmetmi - MU: Nešpecifikované miesto"},
{"code":"W23.0","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Domov"},
{"code":"W23.1","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Kolektívne ústavné zariadenia"},
{"code":"W23.2","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W23.3","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Športové a atletické priestranstvo"},
{"code":"W23.4","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Ulica a cesta"},
{"code":"W23.5","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Obchod a služby"},
{"code":"W23.6","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Priemyselné objekty"},
{"code":"W23.7","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Poľnohospodárske"},
{"code":"W23.8","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Iné špecifikované miesta"},
{"code":"W23.9","desc":"Zachytenie, zmliaždenie, zaklieštenie alebo privrznutie medzi predmetmi alebo v nich - MU: Nešpecifikované miesto"},
{"code":"W24.0","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Domov"},
{"code":"W24.1","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Kolektívne ústavné zariadenia"},
{"code":"W24.2","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W24.3","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Športové a atletické priestranstvo"},
{"code":"W24.4","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Ulica a cesta"},
{"code":"W24.5","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Obchod a služby"},
{"code":"W24.6","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Priemyselné objekty"},
{"code":"W24.7","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Poľnohospodárske"},
{"code":"W24.8","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Iné špecifikované miesta"},
{"code":"W24.9","desc":"Zachytenie zdvíhacími a prevodovými zariadeniami nezatriedené inde - MU: Nešpecifikované miesto"},
{"code":"W25.0","desc":"Poranenie ostrým sklom - MU: Domov"},
{"code":"W25.1","desc":"Poranenie ostrým sklom - MU: Kolektívne ústavné zariadenia"},
{"code":"W25.2","desc":"Poranenie ostrým sklom - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W25.3","desc":"Poranenie ostrým sklom - MU: Športové a atletické priestranstvo"},
{"code":"W25.4","desc":"Poranenie ostrým sklom - MU: Ulica a cesta"},
{"code":"W25.5","desc":"Poranenie ostrým sklom - MU: Obchod a služby"},
{"code":"W25.6","desc":"Poranenie ostrým sklom - MU: Priemyselné objekty"},
{"code":"W25.7","desc":"Poranenie ostrým sklom - MU: Poľnohospodárske"},
{"code":"W25.8","desc":"Poranenie ostrým sklom - MU: Iné špecifikované miesta"},
{"code":"W25.9","desc":"Poranenie ostrým sklom - MU: Nešpecifikované miesto"},
{"code":"W26.0","desc":"Poranenie nožom, mečom alebo dýkou - MU: Domov"},
{"code":"W26.1","desc":"Poranenie nožom, mečom alebo dýkou - MU: Kolektívne ústavné zariadenia"},
{"code":"W26.2","desc":"Poranenie nožom, mečom alebo dýkou - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W26.3","desc":"Poranenie nožom, mečom alebo dýkou - MU: Športové a atletické priestranstvo"},
{"code":"W26.4","desc":"Poranenie nožom, mečom alebo dýkou - MU: Ulica a cesta"},
{"code":"W26.5","desc":"Poranenie nožom, mečom alebo dýkou - MU: Obchod a služby"},
{"code":"W26.6","desc":"Poranenie nožom, mečom alebo dýkou - MU: Priemyselné objekty"},
{"code":"W26.7","desc":"Poranenie nožom, mečom alebo dýkou - MU: Poľnohospodárske"},
{"code":"W26.8","desc":"Poranenie nožom, mečom alebo dýkou - MU: Iné špecifikované miesta"},
{"code":"W26.9","desc":"Poranenie nožom, mečom alebo dýkou - MU: Nešpecifikované miesto"},
{"code":"W27.0","desc":"Poranenie ručným náradím bez pohonu - MU: Domov"},
{"code":"W27.1","desc":"Poranenie ručným náradím bez pohonu - MU: Kolektívne ústavné zariadenia"},
{"code":"W27.2","desc":"Poranenie ručným náradím bez pohonu - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W27.3","desc":"Poranenie ručným náradím bez pohonu - MU: Športové a atletické priestranstvo"},
{"code":"W27.4","desc":"Poranenie ručným náradím bez pohonu - MU: Ulica a cesta"},
{"code":"W27.5","desc":"Poranenie ručným náradím bez pohonu - MU: Obchod a služby"},
{"code":"W27.6","desc":"Poranenie ručným náradím bez pohonu - MU: Priemyselné objekty"},
{"code":"W27.7","desc":"Poranenie ručným náradím bez pohonu - MU: Poľnohospodárske"},
{"code":"W27.8","desc":"Poranenie ručným náradím bez pohonu - MU: Iné špecifikované miesta"},
{"code":"W27.9","desc":"Poranenie ručným náradím bez pohonu - MU: Nešpecifikované miesto"},
{"code":"W28.0","desc":"Poranenie motorovým strihačom trávnika - MU: Domov"},
{"code":"W28.1","desc":"Poranenie motorovým strihačom trávnika - MU: Kolektívne ústavné zariadenia"},
{"code":"W28.2","desc":"Poranenie motorovým strihačom trávnika - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W28.3","desc":"Poranenie motorovým strihačom trávnika - MU: Športové a atletické priestranstvo"},
{"code":"W28.4","desc":"Poranenie motorovým strihačom trávnika - MU: Ulica a cesta"},
{"code":"W28.5","desc":"Poranenie motorovým strihačom trávnika - MU: Obchod a služby"},
{"code":"W28.6","desc":"Poranenie motorovým strihačom trávnika - MU: Priemyselné objekty"},
{"code":"W28.7","desc":"Poranenie motorovým strihačom trávnika - MU: Poľnohospodárske"},
{"code":"W28.8","desc":"Poranenie motorovým strihačom trávnika - MU: Iné špecifikované miesta"},
{"code":"W28.9","desc":"Poranenie motorovým strihačom trávnika - MU: Nešpecifikované miesto"},
{"code":"W29.0","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Domov"},
{"code":"W29.1","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Kolektívne ústavné zariadenia"},
{"code":"W29.2","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W29.3","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Športové a atletické priestranstvo"},
{"code":"W29.4","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Ulica a cesta"},
{"code":"W29.5","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Obchod a služby"},
{"code":"W29.6","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Priemyselné objekty"},
{"code":"W29.7","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Poľnohospodárske"},
{"code":"W29.8","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Iné špecifikované miesta"},
{"code":"W29.9","desc":"Poranenie iným motorovým náradím a prístrojmi pre domácnosť - MU: Nešpecifikované miesto"},
{"code":"W30.0","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Domov"},
{"code":"W30.1","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Kolektívne ústavné zariadenia"},
{"code":"W30.2","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W30.3","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Športové a atletické priestranstvo"},
{"code":"W30.4","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Ulica a cesta"},
{"code":"W30.5","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Obchod a služby"},
{"code":"W30.6","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Priemyselné objekty"},
{"code":"W30.7","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Poľnohospodárske"},
{"code":"W30.8","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Iné špecifikované miesta"},
{"code":"W30.9","desc":"Poranenie poľnohospodárskymi mechanizmami - MU: Nešpecifikované miesto"},
{"code":"W31.0","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Domov"},
{"code":"W31.1","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Kolektívne ústavné zariadenia"},
{"code":"W31.2","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W31.3","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Športové a atletické priestranstvo"},
{"code":"W31.4","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Ulica a cesta"},
{"code":"W31.5","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Obchod a služby"},
{"code":"W31.6","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Priemyselné objekty"},
{"code":"W31.7","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Poľnohospodárske"},
{"code":"W31.8","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Iné špecifikované miesta"},
{"code":"W31.9","desc":"Poranenie inými a bližšie neurčenými mechanizmami - MU: Nešpecifikované miesto"},
{"code":"W32.0","desc":"Poranenie výstrelom z ručnej zbrane - MU: Domov"},
{"code":"W32.1","desc":"Poranenie výstrelom z ručnej zbrane - MU: Kolektívne ústavné zariadenia"},
{"code":"W32.2","desc":"Poranenie výstrelom z ručnej zbrane - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W32.3","desc":"Poranenie výstrelom z ručnej zbrane - MU: Športové a atletické priestranstvo"},
{"code":"W32.4","desc":"Poranenie výstrelom z ručnej zbrane - MU: Ulica a cesta"},
{"code":"W32.5","desc":"Poranenie výstrelom z ručnej zbrane - MU: Obchod a služby"},
{"code":"W32.6","desc":"Poranenie výstrelom z ručnej zbrane - MU: Priemyselné objekty"},
{"code":"W32.7","desc":"Poranenie výstrelom z ručnej zbrane - MU: Poľnohospodárske"},
{"code":"W32.8","desc":"Poranenie výstrelom z ručnej zbrane - MU: Iné špecifikované miesta"},
{"code":"W32.9","desc":"Poranenie výstrelom z ručnej zbrane - MU: Nešpecifikované miesto"},
{"code":"W33.0","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Domov"},
{"code":"W33.1","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Kolektívne ústavné zariadenia"},
{"code":"W33.2","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W33.3","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Športové a atletické priestranstvo"},
{"code":"W33.4","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Ulica a cesta"},
{"code":"W33.5","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Obchod a služby"},
{"code":"W33.6","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Priemyselné objekty"},
{"code":"W33.7","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Poľnohospodárske"},
{"code":"W33.8","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Iné špecifikované miesta"},
{"code":"W33.9","desc":"Poranenie výstrelom z guľovnice, brokovnice alebo dlhej strelnej zbrane - MU: Nešpecifikované miesto"},
{"code":"W34.0","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Domov"},
{"code":"W34.1","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Kolektívne ústavné zariadenia"},
{"code":"W34.2","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W34.3","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Športové a atletické priestranstvo"},
{"code":"W34.4","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Ulica a cesta"},
{"code":"W34.5","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Obchod a služby"},
{"code":"W34.6","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Priemyselné objekty"},
{"code":"W34.7","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Poľnohospodárske"},
{"code":"W34.8","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Iné špecifikované miesta"},
{"code":"W34.9","desc":"Poranenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Nešpecifikované miesto"},
{"code":"W35.0","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Domov"},
{"code":"W35.1","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Kolektívne ústavné zariadenia"},
{"code":"W35.2","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W35.3","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Športové a atletické priestranstvo"},
{"code":"W35.4","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Ulica a cesta"},
{"code":"W35.5","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Obchod a služby"},
{"code":"W35.6","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Priemyselné objekty"},
{"code":"W35.7","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Poľnohospodárske"},
{"code":"W35.8","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Iné špecifikované miesta"},
{"code":"W35.9","desc":"Poranenie následkom výbuchu a puknutia nádrže bojlera - MU: Nešpecifikované miesto"},
{"code":"W36.0","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Domov"},
{"code":"W36.1","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Kolektívne ústavné zariadenia"},
{"code":"W36.2","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W36.3","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Športové a atletické priestranstvo"},
{"code":"W36.4","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Ulica a cesta"},
{"code":"W36.5","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Obchod a služby"},
{"code":"W36.6","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Priemyselné objekty"},
{"code":"W36.7","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Poľnohospodárske"},
{"code":"W36.8","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Iné špecifikované miesta"},
{"code":"W36.9","desc":"Poranenie následkom výbuchu a puknutia plynového valca - MU: Nešpecifikované miesto"},
{"code":"W37.0","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Domov"},
{"code":"W37.1","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Kolektívne ústavné zariadenia"},
{"code":"W37.2","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W37.3","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Športové a atletické priestranstvo"},
{"code":"W37.4","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Ulica a cesta"},
{"code":"W37.5","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Obchod a služby"},
{"code":"W37.6","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Priemyselné objekty"},
{"code":"W37.7","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Poľnohospodárske"},
{"code":"W37.8","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Iné špecifikované miesta"},
{"code":"W37.9","desc":"Poranenie následkom výbuchu a puknutia nafúkanej pneumatiky, rúry alebo hadice - MU: Nešpecifikované miesto"},
{"code":"W38.0","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Domov"},
{"code":"W38.1","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Kolektívne ústavné zariadenia"},
{"code":"W38.2","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W38.3","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Športové a atletické priestranstvo"},
{"code":"W38.4","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Ulica a cesta"},
{"code":"W38.5","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Obchod a služby"},
{"code":"W38.6","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Priemyselné objekty"},
{"code":"W38.7","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Poľnohospodárske"},
{"code":"W38.8","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Iné špecifikované miesta"},
{"code":"W38.9","desc":"Poranenie následkom výbuchu a puknutia iných bližšie určených zariadení pod tlakom - MU: Nešpecifikované miesto"},
{"code":"W39.0","desc":"Poranenie výstrelom z ohňostroja - MU: Domov"},
{"code":"W39.1","desc":"Poranenie výstrelom z ohňostroja - MU: Kolektívne ústavné zariadenia"},
{"code":"W39.2","desc":"Poranenie výstrelom z ohňostroja - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W39.3","desc":"Poranenie výstrelom z ohňostroja - MU: Športové a atletické priestranstvo"},
{"code":"W39.4","desc":"Poranenie výstrelom z ohňostroja - MU: Ulica a cesta"},
{"code":"W39.5","desc":"Poranenie výstrelom z ohňostroja - MU: Obchod a služby"},
{"code":"W39.6","desc":"Poranenie výstrelom z ohňostroja - MU: Priemyselné objekty"},
{"code":"W39.7","desc":"Poranenie výstrelom z ohňostroja - MU: Poľnohospodárske"},
{"code":"W39.8","desc":"Poranenie výstrelom z ohňostroja - MU: Iné špecifikované miesta"},
{"code":"W39.9","desc":"Poranenie výstrelom z ohňostroja - MU: Nešpecifikované miesto"},
{"code":"W40.0","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Domov"},
{"code":"W40.1","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Kolektívne ústavné zariadenia"},
{"code":"W40.2","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W40.3","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Športové a atletické priestranstvo"},
{"code":"W40.4","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Ulica a cesta"},
{"code":"W40.5","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Obchod a služby"},
{"code":"W40.6","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Priemyselné objekty"},
{"code":"W40.7","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Poľnohospodárske"},
{"code":"W40.8","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Iné špecifikované miesta"},
{"code":"W40.9","desc":"Poranenie účinkom výbuchu iných materiálov - MU: Nešpecifikované miesto"},
{"code":"W41.0","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Domov"},
{"code":"W41.1","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Kolektívne ústavné zariadenia"},
{"code":"W41.2","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W41.3","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Športové a atletické priestranstvo"},
{"code":"W41.4","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Ulica a cesta"},
{"code":"W41.5","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Obchod a služby"},
{"code":"W41.6","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Priemyselné objekty"},
{"code":"W41.7","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Poľnohospodárske"},
{"code":"W41.8","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Iné špecifikované miesta"},
{"code":"W41.9","desc":"Poranenie účinkom prúdenia pod vysokým tlakom - MU: Nešpecifikované miesto"},
{"code":"W42.0","desc":"Poranenie účinkom hluku - MU: Domov"},
{"code":"W42.1","desc":"Poranenie účinkom hluku - MU: Kolektívne ústavné zariadenia"},
{"code":"W42.2","desc":"Poranenie účinkom hluku - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W42.3","desc":"Poranenie účinkom hluku - MU: Športové a atletické priestranstvo"},
{"code":"W42.4","desc":"Poranenie účinkom hluku - MU: Ulica a cesta"},
{"code":"W42.5","desc":"Poranenie účinkom hluku - MU: Obchod a služby"},
{"code":"W42.6","desc":"Poranenie účinkom hluku - MU: Priemyselné objekty"},
{"code":"W42.7","desc":"Poranenie účinkom hluku - MU: Poľnohospodárske"},
{"code":"W42.8","desc":"Poranenie účinkom hluku - MU: Iné špecifikované miesta"},
{"code":"W42.9","desc":"Poranenie účinkom hluku - MU: Nešpecifikované miesto"},
{"code":"W43.0","desc":"Poškodenie účinkom vibrácií - MU: Domov"},
{"code":"W43.1","desc":"Poškodenie účinkom vibrácií - MU: Kolektívne ústavné zariadenia"},
{"code":"W43.2","desc":"Poškodenie účinkom vibrácií - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W43.3","desc":"Poškodenie účinkom vibrácií - MU: Športové a atletické priestranstvo"},
{"code":"W43.4","desc":"Poškodenie účinkom vibrácií - MU: Ulica a cesta"},
{"code":"W43.5","desc":"Poškodenie účinkom vibrácií - MU: Obchod a služby"},
{"code":"W43.6","desc":"Poškodenie účinkom vibrácií - MU: Priemyselné objekty"},
{"code":"W43.7","desc":"Poškodenie účinkom vibrácií - MU: Poľnohospodárske"},
{"code":"W43.8","desc":"Poškodenie účinkom vibrácií - MU: Iné špecifikované miesta"},
{"code":"W43.9","desc":"Poškodenie účinkom vibrácií - MU: Nešpecifikované miesto"},
{"code":"W44.0","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Domov"},
{"code":"W44.1","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Kolektívne ústavné zariadenia"},
{"code":"W44.2","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W44.3","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Športové a atletické priestranstvo"},
{"code":"W44.4","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Ulica a cesta"},
{"code":"W44.5","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Obchod a služby"},
{"code":"W44.6","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Priemyselné objekty"},
{"code":"W44.7","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Poľnohospodárske"},
{"code":"W44.8","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Iné špecifikované miesta"},
{"code":"W44.9","desc":"Poranenie cudzím telesom vnikajúcim do oka, cez oko alebo iný prirodzený otvor tela - MU: Nešpecifikované miesto"},
{"code":"W45.0","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Domov"},
{"code":"W45.1","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Kolektívne ústavné zariadenia"},
{"code":"W45.2","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W45.3","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Športové a atletické priestranstvo"},
{"code":"W45.4","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Ulica a cesta"},
{"code":"W45.5","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Obchod a služby"},
{"code":"W45.6","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Priemyselné objekty"},
{"code":"W45.7","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Poľnohospodárske"},
{"code":"W45.8","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Iné špecifikované miesta"},
{"code":"W45.9","desc":"Poranenie cudzím telesom alebo predmetom prenikajúcim cez kožu - MU: Nešpecifikované miesto"},
{"code":"W49.0","desc":"Poranenie účinkom iných a bližšie neurčených neživých mechanických síl - MU: Domov"},
{"code":"W49.1","desc":"Poranenie účinkom iných a bližšie neurčených neživých mechanických síl - MU: Kolektívne ústavné zariadenia"},
{"code":"W49.2","desc":"Poranenie účinkom iných a bližšie neurčených neživých mechanických síl - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W49.3","desc":"Poranenie účinkom iných a bližšie neurčených neživých mechanických síl - MU: Športové a atletické priestranstvo"},
{"code":"W49.4","desc":"Poranenie účinkom iných a bližšie neurčených neživých mechanických síl - MU: Ulica a cesta"},
{"code":"W49.5","desc":"Poranenie účinkom iných a bližšie neurčených neživých mechanických síl - MU: Obchod a služby"},
{"code":"W49.6","desc":"Poranenie účinkom iných a bližšie neurčených neživých mechanických síl - MU: Priemyselné objekty"},
{"code":"W49.7","desc":"Poranenie účinkom iných a bližšie neurčených neživých mechanických síl - MU: Poľnohospodárske"},
{"code":"W49.8","desc":"Poranenie účinkom iných a bližšie neurčených neživých mechanických síl - MU: Iné špecifikované miesta"},
{"code":"W50.0","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou"},
{"code":"W50.1","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou - MU: Kolektívne ústavné zariadenia"},
{"code":"W50.2","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W50.3","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou - MU: Športové a atletické priestranstvo"},
{"code":"W50.4","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou - MU: Ulica a cesta"},
{"code":"W50.5","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou - MU: Obchod a služby"},
{"code":"W50.6","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou - MU: Priemyselné objekty"},
{"code":"W50.7","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou - MU: Poľnohospodárske"},
{"code":"W50.8","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou - MU: Iné špecifikované miesta"},
{"code":"W50.9","desc":"Udretie, bitie, kopnutie, vykrútnutie, pohryzenie, alebo poškriabanie inou osobou - MU: Nešpecifikované miesto"},
{"code":"W51.0","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Domov"},
{"code":"W51.1","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Kolektívne ústavné zariadenia"},
{"code":"W51.2","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W51.3","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Športové a atletické priestranstvo"},
{"code":"W51.4","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Ulica a cesta"},
{"code":"W51.5","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Obchod a služby"},
{"code":"W51.6","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Priemyselné objekty"},
{"code":"W51.7","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Poľnohospodárske"},
{"code":"W51.8","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Iné špecifikované miesta"},
{"code":"W51.9","desc":"Strčenie alebo narazenie postrčením do inej osoby - MU: Nešpecifikované miesto"},
{"code":"W52.0","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Domov"},
{"code":"W52.1","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Kolektívne ústavné zariadenia"},
{"code":"W52.2","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W52.3","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Športové a atletické priestranstvo"},
{"code":"W52.4","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Ulica a cesta"},
{"code":"W52.5","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Obchod a služby"},
{"code":"W52.6","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Priemyselné objekty"},
{"code":"W52.7","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Poľnohospodárske"},
{"code":"W52.8","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Iné špecifikované miesta"},
{"code":"W52.9","desc":"Zmliaždenie, pritisnutie alebo pošliapanie v dave alebo pri panike - MU: Nešpecifikované miesto"},
{"code":"W53.0","desc":"Uhryznutie potkanom - MU: Domov"},
{"code":"W53.1","desc":"Uhryznutie potkanom - MU: Kolektívne ústavné zariadenia"},
{"code":"W53.2","desc":"Uhryznutie potkanom - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W53.3","desc":"Uhryznutie potkanom - MU: Športové a atletické priestranstvo"},
{"code":"W53.4","desc":"Uhryznutie potkanom - MU: Ulica a cesta"},
{"code":"W53.5","desc":"Uhryznutie potkanom - MU: Obchod a služby"},
{"code":"W53.6","desc":"Uhryznutie potkanom - MU: Priemyselné objekty"},
{"code":"W53.7","desc":"Uhryznutie potkanom - MU: Poľnohospodárske"},
{"code":"W53.8","desc":"Uhryznutie potkanom - MU: Iné špecifikované miesta"},
{"code":"W53.9","desc":"Uhryznutie potkanom - MU: Nešpecifikované miesto"},
{"code":"W54.0","desc":"Uhryznutie alebo udretie psom - MU: Domov"},
{"code":"W54.1","desc":"Uhryznutie alebo udretie psom - MU: Kolektívne ústavné zariadenia"},
{"code":"W54.2","desc":"Uhryznutie alebo udretie psom - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W54.3","desc":"Uhryznutie alebo udretie psom - MU: Športové a atletické priestranstvo"},
{"code":"W54.4","desc":"Uhryznutie alebo udretie psom - MU: Ulica a cesta"},
{"code":"W54.5","desc":"Uhryznutie alebo udretie psom - MU: Obchod a služby"},
{"code":"W54.6","desc":"Uhryznutie alebo udretie psom - MU: Priemyselné objekty"},
{"code":"W54.7","desc":"Uhryznutie alebo udretie psom - MU: Poľnohospodárske"},
{"code":"W54.8","desc":"Uhryznutie alebo udretie psom - MU: Iné špecifikované miesta"},
{"code":"W54.9","desc":"Uhryznutie alebo udretie psom - MU: Nešpecifikované miesto"},
{"code":"W55.0","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Domov"},
{"code":"W55.1","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Kolektívne ústavné zariadenia"},
{"code":"W55.2","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W55.3","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Športové a atletické priestranstvo"},
{"code":"W55.4","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Ulica a cesta"},
{"code":"W55.5","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Obchod a služby"},
{"code":"W55.6","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Priemyselné objekty"},
{"code":"W55.7","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Poľnohospodárske"},
{"code":"W55.8","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Iné špecifikované miesta"},
{"code":"W55.9","desc":"Uhryznutie alebo udretie inými cicavcami - MU: Nešpecifikované miesto"},
{"code":"W56.0","desc":"Poranenie morskými zvieratami - MU: Domov"},
{"code":"W56.1","desc":"Poranenie morskými zvieratami - MU: Kolektívne ústavné zariadenia"},
{"code":"W56.2","desc":"Poranenie morskými zvieratami - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W56.3","desc":"Poranenie morskými zvieratami - MU: Športové a atletické priestranstvo"},
{"code":"W56.4","desc":"Poranenie morskými zvieratami - MU: Ulica a cesta"},
{"code":"W56.5","desc":"Poranenie morskými zvieratami - MU: Obchod a služby"},
{"code":"W56.6","desc":"Poranenie morskými zvieratami - MU: Priemyselné objekty"},
{"code":"W56.7","desc":"Poranenie morskými zvieratami - MU: Poľnohospodárske"},
{"code":"W56.8","desc":"Poranenie morskými zvieratami - MU: Iné špecifikované miesta"},
{"code":"W56.9","desc":"Poranenie morskými zvieratami - MU: Nešpecifikované miesto"},
{"code":"W57.0","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Domov"},
{"code":"W57.1","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Kolektívne ústavné zariadenia"},
{"code":"W57.2","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W57.3","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Športové a atletické priestranstvo"},
{"code":"W57.4","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Ulica a cesta"},
{"code":"W57.5","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Obchod a služby"},
{"code":"W57.6","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Priemyselné objekty"},
{"code":"W57.7","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Poľnohospodárske"},
{"code":"W57.8","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Iné špecifikované miesta"},
{"code":"W57.9","desc":"Uhryznutie alebo uštipnutie nejedovatým hmyzom a inými nejedovatými článkonožcami - MU: Nešpecifikované miesto"},
{"code":"W58.0","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Domov"},
{"code":"W58.1","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Kolektívne ústavné zariadenia"},
{"code":"W58.2","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W58.3","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Športové a atletické priestranstvo"},
{"code":"W58.4","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Ulica a cesta"},
{"code":"W58.5","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Obchod a služby"},
{"code":"W58.6","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Priemyselné objekty"},
{"code":"W58.7","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Poľnohospodárske"},
{"code":"W58.8","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Iné špecifikované miesta"},
{"code":"W58.9","desc":"Uhryznutie alebo udretie krokodílom alebo aligátorom - MU: Nešpecifikované miesto"},
{"code":"W59.0","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Domov"},
{"code":"W59.1","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Kolektívne ústavné zariadenia"},
{"code":"W59.2","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W59.3","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Športové a atletické priestranstvo"},
{"code":"W59.4","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Ulica a cesta"},
{"code":"W59.5","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Obchod a služby"},
{"code":"W59.6","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Priemyselné objekty"},
{"code":"W59.7","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Poľnohospodárske"},
{"code":"W59.8","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Iné špecifikované miesta"},
{"code":"W59.9","desc":"Uhryznutie alebo zmliaždenie inými plazmi - MU: Nešpecifikované miesto"},
{"code":"W60.0","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Domov"},
{"code":"W60.1","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Kolektívne ústavné zariadenia"},
{"code":"W60.2","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W60.3","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Športové a atletické priestranstvo"},
{"code":"W60.4","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Ulica a cesta"},
{"code":"W60.5","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Obchod a služby"},
{"code":"W60.6","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Priemyselné objekty"},
{"code":"W60.7","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Poľnohospodárske"},
{"code":"W60.8","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Iné špecifikované miesta"},
{"code":"W60.9","desc":"Styk s rastlinnými tŕňmi, pichliačmi a ostrými listami - MU: Nešpecifikované miesto"},
{"code":"W64.0","desc":"Poranenie inými bližšie neurčenými živými mechanickými silami - MU: Domov"},
{"code":"W64.1","desc":"Poranenie inými bližšie neurčenými živými mechanickými silami - MU: Kolektívne ústavné zariadenia"},
{"code":"W64.2","desc":"Poranenie inými bližšie neurčenými živými mechanickými silami - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W64.3","desc":"Poranenie inými bližšie neurčenými živými mechanickými silami - MU: Športové a atletické priestranstvo"},
{"code":"W64.4","desc":"Poranenie inými bližšie neurčenými živými mechanickými silami - MU: Ulica a cesta"},
{"code":"W64.5","desc":"Poranenie inými bližšie neurčenými živými mechanickými silami - MU: Obchod a služby"},
{"code":"W64.6","desc":"Poranenie inými bližšie neurčenými živými mechanickými silami - MU: Priemyselné objekty"},
{"code":"W64.7","desc":"Poranenie inými bližšie neurčenými živými mechanickými silami - MU: Poľnohospodárske"},
{"code":"W64.8","desc":"Poranenie inými bližšie neurčenými živými mechanickými silami - MU: Iné špecifikované miesta"},
{"code":"W65.0","desc":"Utopenie a topenie vo vani - MU: Domov"},
{"code":"W65.1","desc":"Utopenie a topenie vo vani - MU: Kolektívne ústavné zariadenia"},
{"code":"W65.2","desc":"Utopenie a topenie vo vani - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W65.3","desc":"Utopenie a topenie vo vani - MU: Športové a atletické priestranstvo"},
{"code":"W65.4","desc":"Utopenie a topenie vo vani - MU: Ulica a cesta"},
{"code":"W65.5","desc":"Utopenie a topenie vo vani - MU: Obchod a služby"},
{"code":"W65.6","desc":"Utopenie a topenie vo vani - MU: Priemyselné objekty"},
{"code":"W65.7","desc":"Utopenie a topenie vo vani - MU: Poľnohospodárske"},
{"code":"W65.8","desc":"Utopenie a topenie vo vani - MU: Iné špecifikované miesta"},
{"code":"W65.9","desc":"Utopenie a topenie vo vani - MU: Nešpecifikované miesto"},
{"code":"W66.0","desc":"Utopenie a topenie po páde do vane - MU: Domov"},
{"code":"W66.1","desc":"Utopenie a topenie po páde do vane - MU: Kolektívne ústavné zariadenia"},
{"code":"W66.2","desc":"Utopenie a topenie po páde do vane - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W66.3","desc":"Utopenie a topenie po páde do vane - MU: Športové a atletické priestranstvo"},
{"code":"W66.4","desc":"Utopenie a topenie po páde do vane - MU: Ulica a cesta"},
{"code":"W66.5","desc":"Utopenie a topenie po páde do vane - MU: Obchod a služby"},
{"code":"W66.6","desc":"Utopenie a topenie po páde do vane - MU: Priemyselné objekty"},
{"code":"W66.7","desc":"Utopenie a topenie po páde do vane - MU: Poľnohospodárske"},
{"code":"W66.8","desc":"Utopenie a topenie po páde do vane - MU: Iné špecifikované miesta"},
{"code":"W66.9","desc":"Utopenie a topenie po páde do vane - MU: Nešpecifikované miesto"},
{"code":"W67.0","desc":"Utopenie a topenie v plaveckom bazéne - MU: Domov"},
{"code":"W67.1","desc":"Utopenie a topenie v plaveckom bazéne - MU: Kolektívne ústavné zariadenia"},
{"code":"W67.2","desc":"Utopenie a topenie v plaveckom bazéne - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W67.3","desc":"Utopenie a topenie v plaveckom bazéne - MU: Športové a atletické priestranstvo"},
{"code":"W67.4","desc":"Utopenie a topenie v plaveckom bazéne - MU: Ulica a cesta"},
{"code":"W67.5","desc":"Utopenie a topenie v plaveckom bazéne - MU: Obchod a služby"},
{"code":"W67.6","desc":"Utopenie a topenie v plaveckom bazéne - MU: Priemyselné objekty"},
{"code":"W67.7","desc":"Utopenie a topenie v plaveckom bazéne - MU: Poľnohospodárske"},
{"code":"W67.8","desc":"Utopenie a topenie v plaveckom bazéne - MU: Iné špecifikované miesta"},
{"code":"W67.9","desc":"Utopenie a topenie v plaveckom bazéne - MU: Nešpecifikované miesto"},
{"code":"W68.0","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Domov"},
{"code":"W68.1","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Kolektívne ústavné zariadenia"},
{"code":"W68.2","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W68.3","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Športové a atletické priestranstvo"},
{"code":"W68.4","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Ulica a cesta"},
{"code":"W68.5","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Obchod a služby"},
{"code":"W68.6","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Priemyselné objekty"},
{"code":"W68.7","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Poľnohospodárske"},
{"code":"W68.8","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Iné špecifikované miesta"},
{"code":"W68.9","desc":"Utopenie a topenie po páde do plaveckého bazéna - MU: Nešpecifikované miesto"},
{"code":"W69.0","desc":"Utopenie a topenie v prírodných vodách - MU: Domov"},
{"code":"W69.1","desc":"Utopenie a topenie v prírodných vodách - MU: Kolektívne ústavné zariadenia"},
{"code":"W69.2","desc":"Utopenie a topenie v prírodných vodách - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W69.3","desc":"Utopenie a topenie v prírodných vodách - MU: Športové a atletické priestranstvo"},
{"code":"W69.4","desc":"Utopenie a topenie v prírodných vodách - MU: Ulica a cesta"},
{"code":"W69.5","desc":"Utopenie a topenie v prírodných vodách - MU: Obchod a služby"},
{"code":"W69.6","desc":"Utopenie a topenie v prírodných vodách - MU: Priemyselné objekty"},
{"code":"W69.7","desc":"Utopenie a topenie v prírodných vodách - MU: Poľnohospodárske"},
{"code":"W69.8","desc":"Utopenie a topenie v prírodných vodách - MU: Iné špecifikované miesta"},
{"code":"W69.9","desc":"Utopenie a topenie v prírodných vodách - MU: Nešpecifikované miesto"},
{"code":"W70.0","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Domov"},
{"code":"W70.1","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Kolektívne ústavné zariadenia"},
{"code":"W70.2","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W70.3","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Športové a atletické priestranstvo"},
{"code":"W70.4","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Ulica a cesta"},
{"code":"W70.5","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Obchod a služby"},
{"code":"W70.6","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Priemyselné objekty"},
{"code":"W70.7","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Poľnohospodárske"},
{"code":"W70.8","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Iné špecifikované miesta"},
{"code":"W70.9","desc":"Utopenie a topenie pri páde do vody v prírodných vodách - MU: Nešpecifikované miesto"},
{"code":"W73.0","desc":"Iné bližšie určené utopenie a topenie - MU: Domov"},
{"code":"W73.1","desc":"Iné bližšie určené utopenie a topenie - MU: Kolektívne ústavné zariadenia"},
{"code":"W73.2","desc":"Iné bližšie určené utopenie a topenie - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W73.3","desc":"Iné bližšie určené utopenie a topenie - MU: Športové a atletické priestranstvo"},
{"code":"W73.4","desc":"Iné bližšie určené utopenie a topenie - MU: Ulica a cesta"},
{"code":"W73.5","desc":"Iné bližšie určené utopenie a topenie - MU: Obchod a služby"},
{"code":"W73.6","desc":"Iné bližšie určené utopenie a topenie - MU: Priemyselné objekty"},
{"code":"W73.7","desc":"Iné bližšie určené utopenie a topenie - MU: Poľnohospodárske"},
{"code":"W73.8","desc":"Iné bližšie určené utopenie a topenie - MU: Iné špecifikované miesta"},
{"code":"W73.9","desc":"Iné bližšie určené utopenie a topenie - MU: Nešpecifikované miesto"},
{"code":"W74.0","desc":"Bližšie neurčené utopenie a topenie - MU: Domov"},
{"code":"W74.1","desc":"Bližšie neurčené utopenie a topenie - MU: Kolektívne ústavné zariadenia"},
{"code":"W74.2","desc":"Bližšie neurčené utopenie a topenie - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W74.3","desc":"Bližšie neurčené utopenie a topenie - MU: Športové a atletické priestranstvo"},
{"code":"W74.4","desc":"Bližšie neurčené utopenie a topenie - MU: Ulica a cesta"},
{"code":"W74.5","desc":"Bližšie neurčené utopenie a topenie - MU: Obchod a služby"},
{"code":"W74.6","desc":"Bližšie neurčené utopenie a topenie - MU: Priemyselné objekty"},
{"code":"W74.7","desc":"Bližšie neurčené utopenie a topenie - MU: Poľnohospodárske"},
{"code":"W74.8","desc":"Bližšie neurčené utopenie a topenie - MU: Iné špecifikované miesta"},
{"code":"W74.9","desc":"Bližšie neurčené utopenie a topenie - MU: Nešpecifikované miesto"},
{"code":"W75.0","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Domov"},
{"code":"W75.1","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Kolektívne ústavné zariadenia"},
{"code":"W75.2","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W75.3","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Športové a atletické priestranstvo"},
{"code":"W75.4","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Ulica a cesta"},
{"code":"W75.5","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Obchod a služby"},
{"code":"W75.6","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Priemyselné objekty"},
{"code":"W75.7","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Poľnohospodárske"},
{"code":"W75.8","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Iné špecifikované miesta"},
{"code":"W75.9","desc":"Náhodné udusenie a zaškrtenie v posteli - MU: Nešpecifikované miesto"},
{"code":"W76.0","desc":"Iné náhodné obesenie a zaškrtenie - MU: Domov"},
{"code":"W76.1","desc":"Iné náhodné obesenie a zaškrtenie - MU: Kolektívne ústavné zariadenia"},
{"code":"W76.2","desc":"Iné náhodné obesenie a zaškrtenie - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W76.3","desc":"Iné náhodné obesenie a zaškrtenie - MU: Športové a atletické priestranstvo"},
{"code":"W76.4","desc":"Iné náhodné obesenie a zaškrtenie - MU: Ulica a cesta"},
{"code":"W76.5","desc":"Iné náhodné obesenie a zaškrtenie - MU: Obchod a služby"},
{"code":"W76.6","desc":"Iné náhodné obesenie a zaškrtenie - MU: Priemyselné objekty"},
{"code":"W76.7","desc":"Iné náhodné obesenie a zaškrtenie - MU: Poľnohospodárske"},
{"code":"W76.8","desc":"Iné náhodné obesenie a zaškrtenie - MU: Iné špecifikované miesta"},
{"code":"W76.9","desc":"Iné náhodné obesenie a zaškrtenie - MU: Nešpecifikované miesto"},
{"code":"W77.0","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Domov"},
{"code":"W77.1","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Kolektívne ústavné zariadenia"},
{"code":"W77.2","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W77.3","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Športové a atletické priestranstvo"},
{"code":"W77.4","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Ulica a cesta"},
{"code":"W77.5","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Obchod a služby"},
{"code":"W77.6","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Priemyselné objekty"},
{"code":"W77.7","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Poľnohospodárske"},
{"code":"W77.8","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Iné špecifikované miesta"},
{"code":"W77.9","desc":"Udusenie zapríčinené prepadnutím zeme, zavalením zemou a inými materiálmi - MU: Nešpecifikované miesto"},
{"code":"W78.0","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Domov"},
{"code":"W78.1","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Kolektívne ústavné zariadenia"},
{"code":"W78.2","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W78.3","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Športové a atletické priestranstvo"},
{"code":"W78.4","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Ulica a cesta"},
{"code":"W78.5","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Obchod a služby"},
{"code":"W78.6","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Priemyselné objekty"},
{"code":"W78.7","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Poľnohospodárske"},
{"code":"W78.8","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Iné špecifikované miesta"},
{"code":"W78.9","desc":"Vdýchnutie obsahu žalúdka (dáveniny) - MU: Nešpecifikované miesto"},
{"code":"W79.0","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Domov"},
{"code":"W79.1","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Kolektívne ústavné zariadenia"},
{"code":"W79.2","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W79.3","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Športové a atletické priestranstvo"},
{"code":"W79.4","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Ulica a cesta"},
{"code":"W79.5","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Obchod a služby"},
{"code":"W79.6","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Priemyselné objekty"},
{"code":"W79.7","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Poľnohospodárske"},
{"code":"W79.8","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Iné špecifikované miesta"},
{"code":"W79.9","desc":"Vdýchnutie a prehltnutie potravy zapríčiňujúce zapchatie dýchacích ciest - MU: Nešpecifikované miesto"},
{"code":"W80.0","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Domov"},
{"code":"W80.1","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Kolektívne ústavné zariadenia"},
{"code":"W80.2","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Školy, iné inštit. a admin. budovy"},
{"code":"W80.3","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Športové a atletické priestranstvo"},
{"code":"W80.4","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Ulica a cesta"},
{"code":"W80.5","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Obchod a služby"},
{"code":"W80.6","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Priemyselné objekty"},
{"code":"W80.7","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Poľnohospodárske"},
{"code":"W80.8","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Iné špecifikované miesta"},
{"code":"W80.9","desc":"Vdýchnutie a prehltnutie iných predmetov zapríčiňujúce zapchatie dýchacích ciest - MU: Nešpecifikované miesto"},
{"code":"W81.0","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Domov"},
{"code":"W81.1","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Kolektívne ústavné zariadenia"},
{"code":"W81.2","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W81.3","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Športové a atletické priestranstvo"},
{"code":"W81.4","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Ulica a cesta"},
{"code":"W81.5","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Obchod a služby"},
{"code":"W81.6","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Priemyselné objekty"},
{"code":"W81.7","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Poľnohospodárske"},
{"code":"W81.8","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Iné špecifikované miesta"},
{"code":"W81.9","desc":"Zatvorenie alebo uviaznutie v prostredí s nedostatkom kyslíka - MU: Nešpecifikované miesto"},
{"code":"W83.0","desc":"Iné bližšie určené ohrozenie dýchania - MU: Domov"},
{"code":"W83.1","desc":"Iné bližšie určené ohrozenie dýchania - MU: Kolektívne ústavné zariadenia"},
{"code":"W83.2","desc":"Iné bližšie určené ohrozenie dýchania - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W83.3","desc":"Iné bližšie určené ohrozenie dýchania - MU: Športové a atletické priestranstvo"},
{"code":"W83.4","desc":"Iné bližšie určené ohrozenie dýchania - MU: Ulica a cesta"},
{"code":"W83.5","desc":"Iné bližšie určené ohrozenie dýchania - MU: Obchod a služby"},
{"code":"W83.6","desc":"Iné bližšie určené ohrozenie dýchania - MU: Priemyselné objekty"},
{"code":"W83.7","desc":"Iné bližšie určené ohrozenie dýchania - MU: Poľnohospodárske"},
{"code":"W83.8","desc":"Iné bližšie určené ohrozenie dýchania - MU: Iné špecifikované miesta"},
{"code":"W83.9","desc":"Iné bližšie určené ohrozenie dýchania - MU: Nešpecifikované miesto"},
{"code":"W84.0","desc":"Bližšie neurčené ohrozenie dýchania - MU: Domov"},
{"code":"W84.1","desc":"Bližšie neurčené ohrozenie dýchania - MU: Kolektívne ústavné zariadenia"},
{"code":"W84.2","desc":"Bližšie neurčené ohrozenie dýchania - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W84.3","desc":"Bližšie neurčené ohrozenie dýchania - MU: Športové a atletické priestranstvo"},
{"code":"W84.4","desc":"Bližšie neurčené ohrozenie dýchania - MU: Ulica a cesta"},
{"code":"W84.5","desc":"Bližšie neurčené ohrozenie dýchania - MU: Obchod a služby"},
{"code":"W84.6","desc":"Bližšie neurčené ohrozenie dýchania - MU: Priemyselné objekty"},
{"code":"W84.7","desc":"Bližšie neurčené ohrozenie dýchania - MU: Poľnohospodárske"},
{"code":"W84.8","desc":"Bližšie neurčené ohrozenie dýchania - MU: Iné špecifikované miesta"},
{"code":"W84.9","desc":"Bližšie neurčené ohrozenie dýchania - MU: Nešpecifikované miesto"},
{"code":"W85.0","desc":"Poškodenie elektrickým vedením - MU: Domov"},
{"code":"W85.1","desc":"Poškodenie elektrickým vedením - MU: Kolektívne ústavné zariadenia"},
{"code":"W85.2","desc":"Poškodenie elektrickým vedením - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W85.3","desc":"Poškodenie elektrickým vedením - MU: Športové a atletické priestranstvo"},
{"code":"W85.4","desc":"Poškodenie elektrickým vedením - MU: Ulica a cesta"},
{"code":"W85.5","desc":"Poškodenie elektrickým vedením - MU: Obchod a služby"},
{"code":"W85.6","desc":"Poškodenie elektrickým vedením - MU: Priemyselné objekty"},
{"code":"W85.7","desc":"Poškodenie elektrickým vedením - MU: Poľnohospodárske"},
{"code":"W85.8","desc":"Poškodenie elektrickým vedením - MU: Iné špecifikované miesta"},
{"code":"W85.9","desc":"Poškodenie elektrickým vedením - MU: Nešpecifikované miesto"},
{"code":"W86.0","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Domov"},
{"code":"W86.1","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Kolektívne ústavné zariadenia"},
{"code":"W86.2","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W86.3","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Športové a atletické priestranstvo"},
{"code":"W86.4","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Ulica a cesta"},
{"code":"W86.5","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Obchod a služby"},
{"code":"W86.6","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Priemyselné objekty"},
{"code":"W86.7","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Poľnohospodárske"},
{"code":"W86.8","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Iné špecifikované miesta"},
{"code":"W86.9","desc":"Poškodenie iným bližšie určeným elektrickým prúdom - MU: Nešpecifikované miesto"},
{"code":"W87.0","desc":"Poškodenie bližšie neurčeným elektrickým prúdom - MU: Domov"},
{"code":"W87.1","desc":"Poškodenie bližšie neurčeným elektrickým prúdom - MU: Kolektívne ústavné zariadenia"},
{"code":"W87.2","desc":"Poškodenie bližšie neurčeným elektrickým prúdom - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W87.3","desc":"Poškodenie bližšie neurčeným elektrickým prúdom - MU: Športové a atletické priestranstvo"},
{"code":"W87.4","desc":"Poškodenie bližšie neurčeným elektrickým prúdom - MU: Ulica a cesta"},
{"code":"W87.5","desc":"Poškodenie bližšie neurčeným elektrickým prúdom - MU: Obchod a služby"},
{"code":"W87.6","desc":"Poškodenie bližšie neurčeným elektrickým prúdom - MU: Priemyselné objekty"},
{"code":"W87.7","desc":"Poškodenie bližšie neurčeným elektrickým prúdom - MU: Poľnohospodárske"},
{"code":"W87.8","desc":"Poškodenie bližšie neurčeným elektrickým prúdom - MU: Iné špecifikované miesta"},
{"code":"W88.0","desc":"Poškodenie ionizujúcim žiarením - MU: Domov"},
{"code":"W88.1","desc":"Poškodenie ionizujúcim žiarením - MU: Kolektívne ústavné zariadenia"},
{"code":"W88.2","desc":"Poškodenie ionizujúcim žiarením - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W88.3","desc":"Poškodenie ionizujúcim žiarením - MU: Športové a atletické priestranstvo"},
{"code":"W88.4","desc":"Poškodenie ionizujúcim žiarením - MU: Ulica a cesta"},
{"code":"W88.5","desc":"Poškodenie ionizujúcim žiarením - MU: Obchod a služby"},
{"code":"W88.6","desc":"Poškodenie ionizujúcim žiarením - MU: Priemyselné objekty"},
{"code":"W88.7","desc":"Poškodenie ionizujúcim žiarením - MU: Poľnohospodárske"},
{"code":"W88.8","desc":"Poškodenie ionizujúcim žiarením - MU: Iné špecifikované miesta"},
{"code":"W88.9","desc":"Poškodenie ionizujúcim žiarením - MU: Nešpecifikované miesto"},
{"code":"W89.0","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Domov"},
{"code":"W89.1","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Kolektívne ústavné zariadenia"},
{"code":"W89.2","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W89.3","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Športové a atletické priestranstvo"},
{"code":"W89.4","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Ulica a cesta"},
{"code":"W89.5","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Obchod a služby"},
{"code":"W89.6","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Priemyselné objekty"},
{"code":"W89.7","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Poľnohospodárske"},
{"code":"W89.8","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Iné špecifikované miesta"},
{"code":"W89.9","desc":"Poškodenie umelým viditeľným a ultrafialovým svetlom - MU: Nešpecifikované miesto"},
{"code":"W90.0","desc":"Poškodenie iným neionizujúcim žiarením - MU: Domov"},
{"code":"W90.1","desc":"Poškodenie iným neionizujúcim žiarením - MU: Kolektívne ústavné zariadenia"},
{"code":"W90.2","desc":"Poškodenie iným neionizujúcim žiarením - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W90.3","desc":"Poškodenie iným neionizujúcim žiarením - MU: Športové a atletické priestranstvo"},
{"code":"W90.4","desc":"Poškodenie iným neionizujúcim žiarením - MU: Ulica a cesta"},
{"code":"W90.5","desc":"Poškodenie iným neionizujúcim žiarením - MU: Obchod a služby"},
{"code":"W90.6","desc":"Poškodenie iným neionizujúcim žiarením - MU: Priemyselné objekty"},
{"code":"W90.7","desc":"Poškodenie iným neionizujúcim žiarením - MU: Poľnohospodárske"},
{"code":"W90.8","desc":"Poškodenie iným neionizujúcim žiarením - MU: Iné špecifikované miesta"},
{"code":"W90.9","desc":"Poškodenie iným neionizujúcim žiarením - MU: Nešpecifikované miesto"},
{"code":"W91.0","desc":"Poškodenie bližšie neurčeným typom žiarenia - MU: Domov"},
{"code":"W91.1","desc":"Poškodenie bližšie neurčeným typom žiarenia - MU: Kolektívne ústavné zariadenia"},
{"code":"W91.2","desc":"Poškodenie bližšie neurčeným typom žiarenia - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W91.3","desc":"Poškodenie bližšie neurčeným typom žiarenia - MU: Športové a atletické priestranstvo"},
{"code":"W91.4","desc":"Poškodenie bližšie neurčeným typom žiarenia - MU: Ulica a cesta"},
{"code":"W91.5","desc":"Poškodenie bližšie neurčeným typom žiarenia - MU: Obchod a služby"},
{"code":"W91.6","desc":"Poškodenie bližšie neurčeným typom žiarenia - MU: Priemyselné objekty"},
{"code":"W91.7","desc":"Poškodenie bližšie neurčeným typom žiarenia - MU: Poľnohospodárske"},
{"code":"W91.8","desc":"Poškodenie bližšie neurčeným typom žiarenia - MU: Iné špecifikované miesta"},
{"code":"W92.0","desc":"Poškodenie nadmernou horúčavou umelého pôvodu - MU: Domov"},
{"code":"W92.1","desc":"Poškodenie nadmernou horúčavou umelého pôvodu - MU: Kolektívne ústavné zariadenia"},
{"code":"W92.2","desc":"Poškodenie nadmernou horúčavou umelého pôvodu - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W92.3","desc":"Poškodenie nadmernou horúčavou umelého pôvodu - MU: Športové a atletické priestranstvo"},
{"code":"W92.4","desc":"Poškodenie nadmernou horúčavou umelého pôvodu - MU: Ulica a cesta"},
{"code":"W92.5","desc":"Poškodenie nadmernou horúčavou umelého pôvodu - MU: Obchod a služby"},
{"code":"W92.6","desc":"Poškodenie nadmernou horúčavou umelého pôvodu - MU: Priemyselné objekty"},
{"code":"W92.7","desc":"Poškodenie nadmernou horúčavou umelého pôvodu - MU: Poľnohospodárske"},
{"code":"W92.8","desc":"Poškodenie nadmernou horúčavou umelého pôvodu - MU: Iné špecifikované miesta"},
{"code":"W93.0","desc":"Poškodenie nadmerným chladom umelého pôvodu - MU: Domov"},
{"code":"W93.1","desc":"Poškodenie nadmerným chladom umelého pôvodu - MU: Kolektívne ústavné zariadenia"},
{"code":"W93.2","desc":"Poškodenie nadmerným chladom umelého pôvodu - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W93.3","desc":"Poškodenie nadmerným chladom umelého pôvodu - MU: Športové a atletické priestranstvo"},
{"code":"W93.4","desc":"Poškodenie nadmerným chladom umelého pôvodu - MU: Ulica a cesta"},
{"code":"W93.5","desc":"Poškodenie nadmerným chladom umelého pôvodu - MU: Obchod a služby"},
{"code":"W93.6","desc":"Poškodenie nadmerným chladom umelého pôvodu - MU: Priemyselné objekty"},
{"code":"W93.7","desc":"Poškodenie nadmerným chladom umelého pôvodu - MU: Poľnohospodárske"},
{"code":"W93.8","desc":"Poškodenie nadmerným chladom umelého pôvodu - MU: Iné špecifikované miesta"},
{"code":"W94.0","desc":"Poškodenie vysokým a nízkym tlakom vzduchu a zmenami tlaku vzduchu - MU: Domov"},
{"code":"W94.1","desc":"Poškodenie vysokým a nízkym tlakom vzduchu a zmenami tlaku vzduchu - MU: Kolektívne ústavné zariadenia"},
{"code":"W94.2","desc":"Poškodenie vysokým a nízkym tlakom vzduchu a zmenami tlaku vzduchu - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W94.3","desc":"Poškodenie vysokým a nízkym tlakom vzduchu a zmenami tlaku vzduchu - MU: Športové a atletické priestranstvo"},
{"code":"W94.4","desc":"Poškodenie vysokým a nízkym tlakom vzduchu a zmenami tlaku vzduchu - MU: Ulica a cesta"},
{"code":"W94.5","desc":"Poškodenie vysokým a nízkym tlakom vzduchu a zmenami tlaku vzduchu - MU: Obchod a služby"},
{"code":"W94.6","desc":"Poškodenie vysokým a nízkym tlakom vzduchu a zmenami tlaku vzduchu - MU: Priemyselné objekty"},
{"code":"W94.7","desc":"Poškodenie vysokým a nízkym tlakom vzduchu a zmenami tlaku vzduchu - MU: Poľnohospodárske"},
{"code":"W94.8","desc":"Poškodenie vysokým a nízkym tlakom vzduchu a zmenami tlaku vzduchu - MU: Iné špecifikované miesta"},
{"code":"W99.0","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Domov"},
{"code":"W99.1","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Kolektívne ústavné zariadenia"},
{"code":"W99.2","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Školy, iné inštitúcie a verejné administratívne budovy"},
{"code":"W99.3","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Športové a atletické priestranstvo"},
{"code":"W99.4","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Ulica a cesta"},
{"code":"W99.5","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Obchod a služby"},
{"code":"W99.6","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Priemyselné objekty"},
{"code":"W99.7","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Poľnohospodárske"},
{"code":"W99.8","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Iné špecifikované miesta"},
{"code":"W99.9","desc":"Poškodenie inými a bližšie neurčenými umelými činiteľmi prostredia - MU: Nešpecifikované miesto"},
{"code":"X00.0","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Domov"},
{"code":"X00.1","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Kolektívne ústavné zariadenia"},
{"code":"X00.2","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X00.3","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Športové a atletické priestranstvo"},
{"code":"X00.4","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Ulica a cesta"},
{"code":"X00.5","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Obchod a služby"},
{"code":"X00.6","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Priemyselné objekty"},
{"code":"X00.7","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Poľnohospodárske"},
{"code":"X00.8","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Iné špecifikované miesta"},
{"code":"X00.9","desc":"Poškodenie nekontrolovaným ohňom v budove alebo stavbe - MU: Nešpecifikované miesto"},
{"code":"X01.0","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Domov"},
{"code":"X01.1","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Kolektívne ústavné zariadenia"},
{"code":"X01.2","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X01.3","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Športové a atletické priestranstvo"},
{"code":"X01.4","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Ulica a cesta"},
{"code":"X01.5","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Obchod a služby"},
{"code":"X01.6","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Priemyselné objekty"},
{"code":"X01.7","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Poľnohospodárske"},
{"code":"X01.8","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Iné špecifikované miesta"},
{"code":"X01.9","desc":"Poškodenie nekontrolovaným ohňom mimo budovy alebo stavby - MU: Nešpecifikované miesto"},
{"code":"X02.0","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Domov"},
{"code":"X02.1","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Kolektívne ústavné zariadenia"},
{"code":"X02.2","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X02.3","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Športové a atletické priestranstvo"},
{"code":"X02.4","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Ulica a cesta"},
{"code":"X02.5","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Obchod a služby"},
{"code":"X02.6","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Priemyselné objekty"},
{"code":"X02.7","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Poľnohospodárske"},
{"code":"X02.8","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Iné špecifikované miesta"},
{"code":"X02.9","desc":"Poškodenie kontrolovaným ohňom v budove alebo stavbe - MU: Nešpecifikované miesto"},
{"code":"X03.0","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Domov"},
{"code":"X03.1","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Kolektívne ústavné zariadenia"},
{"code":"X03.2","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X03.3","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Športové a atletické priestranstvo"},
{"code":"X03.4","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Ulica a cesta"},
{"code":"X03.5","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Obchod a služby"},
{"code":"X03.6","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Priemyselné objekty"},
{"code":"X03.7","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Poľnohospodárske"},
{"code":"X03.8","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Iné špecifikované miesta"},
{"code":"X03.9","desc":"Poškodenie kontrolovaným ohňom mimo budovy alebo stavby - MU: Nešpecifikované miesto"},
{"code":"X04.0","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Domov"},
{"code":"X04.1","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Kolektívne ústavné zariadenia"},
{"code":"X04.2","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X04.3","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Športové a atletické priestranstvo"},
{"code":"X04.4","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Ulica a cesta"},
{"code":"X04.5","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Obchod a služby"},
{"code":"X04.6","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Priemyselné objekty"},
{"code":"X04.7","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Poľnohospodárske"},
{"code":"X04.8","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Iné špecifikované miesta"},
{"code":"X04.9","desc":"Poškodenie zapálením veľmi horľavého materiálu - MU: Nešpecifikované miesto"},
{"code":"X05.0","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Domov"},
{"code":"X05.1","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Kolektívne ústavné zariadenia"},
{"code":"X05.2","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X05.3","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Športové a atletické priestranstvo"},
{"code":"X05.4","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Ulica a cesta"},
{"code":"X05.5","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Obchod a služby"},
{"code":"X05.6","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Priemyselné objekty"},
{"code":"X05.7","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Poľnohospodárske"},
{"code":"X05.8","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Iné špecifikované miesta"},
{"code":"X05.9","desc":"Poškodenie zapálením alebo roztopením nočného oblečenia - MU: Nešpecifikované miesto"},
{"code":"X06.0","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Domov"},
{"code":"X06.1","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Kolektívne ústavné zariadenia"},
{"code":"X06.2","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X06.3","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Športové a atletické priestranstvo"},
{"code":"X06.4","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Ulica a cesta"},
{"code":"X06.5","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Obchod a služby"},
{"code":"X06.6","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Priemyselné objekty"},
{"code":"X06.7","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Poľnohospodárske"},
{"code":"X06.8","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Iné špecifikované miesta"},
{"code":"X06.9","desc":"Poškodenie zapálením alebo roztopením iného oblečenia a šiat - MU: Nešpecifikované miesto"},
{"code":"X08.0","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Domov"},
{"code":"X08.1","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X08.2","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X08.3","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Športové a atletické priestranstvo"},
{"code":"X08.4","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Ulica a cesta"},
{"code":"X08.5","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Obchod a služby"},
{"code":"X08.6","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Priemyselné objekty"},
{"code":"X08.7","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Poľnohospodárske"},
{"code":"X08.8","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Iné špecifikované miesta"},
{"code":"X08.9","desc":"Poškodenie iným bližšie určeným dymom, požiarom a plameňmi - MU: Nešpecifikované miesto"},
{"code":"X09.0","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Domov"},
{"code":"X09.1","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X09.2","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X09.3","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Športové a atletické priestranstvo"},
{"code":"X09.4","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Ulica a cesta"},
{"code":"X09.5","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Obchod a služby"},
{"code":"X09.6","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Priemyselné objekty"},
{"code":"X09.7","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Poľnohospodárske"},
{"code":"X09.8","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Iné špecifikované miesta"},
{"code":"X09.9","desc":"Poškodenie bližšie neurčeným dymom, požiarom a plameňmi - MU: Nešpecifikované miesto"},
{"code":"X10.0","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Domov"},
{"code":"X10.1","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Kolektívne ústavné zariadenia"},
{"code":"X10.2","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X10.3","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Športové a atletické priestranstvo"},
{"code":"X10.4","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Ulica a cesta"},
{"code":"X10.5","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Obchod a služby"},
{"code":"X10.6","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Priemyselné objekty"},
{"code":"X10.7","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Poľnohospodárske"},
{"code":"X10.8","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Iné špecifikované miesta"},
{"code":"X10.9","desc":"Poškodenie horúcimi nápojmi, potravinami, tukmi a olejmi na varenie - MU: Nešpecifikované miesto"},
{"code":"X11.0","desc":"Obarenie horúcou vodovodnou vodou - MU: Domov"},
{"code":"X11.1","desc":"Obarenie horúcou vodovodnou vodou - MU: Kolektívne ústavné zariadenia"},
{"code":"X11.2","desc":"Obarenie horúcou vodovodnou vodou - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X11.3","desc":"Obarenie horúcou vodovodnou vodou - MU: Športové a atletické priestranstvo"},
{"code":"X11.4","desc":"Obarenie horúcou vodovodnou vodou - MU: Ulica a cesta"},
{"code":"X11.5","desc":"Obarenie horúcou vodovodnou vodou - MU: Obchod a služby"},
{"code":"X11.6","desc":"Obarenie horúcou vodovodnou vodou - MU: Priemyselné objekty"},
{"code":"X11.7","desc":"Obarenie horúcou vodovodnou vodou - MU: Poľnohospodárske"},
{"code":"X11.8","desc":"Obarenie horúcou vodovodnou vodou - MU: Iné špecifikované miesta"},
{"code":"X11.9","desc":"Obarenie horúcou vodovodnou vodou - MU: Nešpecifikované miesto"},
{"code":"X12.0","desc":"Obarenie inými horúcimi tekutinami - MU: Domov"},
{"code":"X12.1","desc":"Obarenie inými horúcimi tekutinami - MU: Kolektívne ústavné zariadenia"},
{"code":"X12.2","desc":"Obarenie inými horúcimi tekutinami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X12.3","desc":"Obarenie inými horúcimi tekutinami - MU: Športové a atletické priestranstvo"},
{"code":"X12.4","desc":"Obarenie inými horúcimi tekutinami - MU: Ulica a cesta"},
{"code":"X12.5","desc":"Obarenie inými horúcimi tekutinami - MU: Obchod a služby"},
{"code":"X12.6","desc":"Obarenie inými horúcimi tekutinami - MU: Priemyselné objekty"},
{"code":"X12.7","desc":"Obarenie inými horúcimi tekutinami - MU: Poľnohospodárske"},
{"code":"X12.8","desc":"Obarenie inými horúcimi tekutinami - MU: Iné špecifikované miesta"},
{"code":"X12.9","desc":"Obarenie inými horúcimi tekutinami - MU: Nešpecifikované miesto"},
{"code":"X13.0","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Domov"},
{"code":"X13.1","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X13.2","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X13.3","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Športové a atletické priestranstvo"},
{"code":"X13.4","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Ulica a cesta"},
{"code":"X13.5","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Obchod a služby"},
{"code":"X13.6","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Priemyselné objekty"},
{"code":"X13.7","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Poľnohospodárske"},
{"code":"X13.8","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Iné špecifikované miesta"},
{"code":"X13.9","desc":"Obarenie vodnou parou a horúcimi výparmi - MU: Nešpecifikované miesto"},
{"code":"X14.0","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Domov"},
{"code":"X14.1","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X14.2","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X14.3","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Športové a atletické priestranstvo"},
{"code":"X14.4","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Ulica a cesta"},
{"code":"X14.5","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Obchod a služby"},
{"code":"X14.6","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Priemyselné objekty"},
{"code":"X14.7","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Poľnohospodárske"},
{"code":"X14.8","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Iné špecifikované miesta"},
{"code":"X14.9","desc":"Popálenie horúcim vzduchom a horúcimi plynmi - MU: Nešpecifikované miesto"},
{"code":"X15.0","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Domov"},
{"code":"X15.1","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Kolektívne ústavné zariadenia"},
{"code":"X15.2","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X15.3","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Športové a atletické priestranstvo"},
{"code":"X15.4","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Ulica a cesta"},
{"code":"X15.5","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Obchod a služby"},
{"code":"X15.6","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Priemyselné objekty"},
{"code":"X15.7","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Poľnohospodárske"},
{"code":"X15.8","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Iné špecifikované miesta"},
{"code":"X15.9","desc":"Popálenie horúcimi nádobami a prístrojmi v domácnostii - MU: Nešpecifikované miesto"},
{"code":"X16.0","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Domov"},
{"code":"X16.1","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Kolektívne ústavné zariadenia"},
{"code":"X16.2","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X16.3","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Športové a atletické priestranstvo"},
{"code":"X16.4","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Ulica a cesta"},
{"code":"X16.5","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Obchod a služby"},
{"code":"X16.6","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Priemyselné objekty"},
{"code":"X16.7","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Poľnohospodárske"},
{"code":"X16.8","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Iné špecifikované miesta"},
{"code":"X16.9","desc":"Popálenie horúcimi varičmi, radiátormi a rúramii - MU: Nešpecifikované miesto"},
{"code":"X17.0","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Domov"},
{"code":"X17.1","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X17.2","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X17.3","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Športové a atletické priestranstvo"},
{"code":"X17.4","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Ulica a cesta"},
{"code":"X17.5","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Obchod a služby"},
{"code":"X17.6","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Priemyselné objekty"},
{"code":"X17.7","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Poľnohospodárske"},
{"code":"X17.8","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Iné špecifikované miesta"},
{"code":"X17.9","desc":"Popálenie horúcimi motormi, strojovým zariadením a nástrojmi - MU: Nešpecifikované miesto"},
{"code":"X18.0","desc":"Popálenie inými horúcimi kovmi - MU: Domov"},
{"code":"X18.1","desc":"Popálenie inými horúcimi kovmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X18.2","desc":"Popálenie inými horúcimi kovmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X18.3","desc":"Popálenie inými horúcimi kovmi - MU: Športové a atletické priestranstvo"},
{"code":"X18.4","desc":"Popálenie inými horúcimi kovmi - MU: Ulica a cesta"},
{"code":"X18.5","desc":"Popálenie inými horúcimi kovmi - MU: Obchod a služby"},
{"code":"X18.6","desc":"Popálenie inými horúcimi kovmi - MU: Priemyselné objekty"},
{"code":"X18.7","desc":"Popálenie inými horúcimi kovmi - MU: Poľnohospodárske"},
{"code":"X18.8","desc":"Popálenie inými horúcimi kovmi - MU: Iné špecifikované miesta"},
{"code":"X18.9","desc":"Popálenie inými horúcimi kovmi - MU: Nešpecifikované miesto"},
{"code":"X19.0","desc":"Poškodenie inou a bližšie neurčenou horúčavou a horúcimi látkami - MU: Domov"},
{"code":"X19.1","desc":"Poškodenie inou a bližšie neurčenou horúčavou a horúcimi látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X19.2","desc":"Poškodenie inou a bližšie neurčenou horúčavou a horúcimi látkami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X19.3","desc":"Poškodenie inou a bližšie neurčenou horúčavou a horúcimi látkami - MU: Športové a atletické priestranstvo"},
{"code":"X19.4","desc":"Poškodenie inou a bližšie neurčenou horúčavou a horúcimi látkami - MU: Ulica a cesta"},
{"code":"X19.5","desc":"Poškodenie inou a bližšie neurčenou horúčavou a horúcimi látkami - MU: Obchod a služby"},
{"code":"X19.6","desc":"Poškodenie inou a bližšie neurčenou horúčavou a horúcimi látkami - MU: Priemyselné objekty"},
{"code":"X19.7","desc":"Poškodenie inou a bližšie neurčenou horúčavou a horúcimi látkami - MU: Poľnohospodárske"},
{"code":"X19.8","desc":"Poškodenie inou a bližšie neurčenou horúčavou a horúcimi látkami - MU: Iné špecifikované miesta"},
{"code":"X20.0","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Domov"},
{"code":"X20.1","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Kolektívne ústavné zariadenia"},
{"code":"X20.2","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X20.3","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Športové a atletické priestranstvo"},
{"code":"X20.4","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Ulica a cesta"},
{"code":"X20.5","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Obchod a služby"},
{"code":"X20.6","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Priemyselné objekty"},
{"code":"X20.7","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Poľnohospodárske"},
{"code":"X20.8","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Iné špecifikované miesta"},
{"code":"X20.9","desc":"Kontakt s jedovatými hadmi a jaštericami - MU: Nešpecifikované miesto"},
{"code":"X21.0","desc":"Kontakt s jedovatými pavúkmi - MU: Domov"},
{"code":"X21.1","desc":"Kontakt s jedovatými pavúkmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X21.2","desc":"Kontakt s jedovatými pavúkmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X21.3","desc":"Kontakt s jedovatými pavúkmi - MU: Športové a atletické priestranstvo"},
{"code":"X21.4","desc":"Kontakt s jedovatými pavúkmi - MU: Ulica a cesta"},
{"code":"X21.5","desc":"Kontakt s jedovatými pavúkmi - MU: Obchod a služby"},
{"code":"X21.6","desc":"Kontakt s jedovatými pavúkmi - MU: Priemyselné objekty"},
{"code":"X21.7","desc":"Kontakt s jedovatými pavúkmi - MU: Poľnohospodárske"},
{"code":"X21.8","desc":"Kontakt s jedovatými pavúkmi - MU: Iné špecifikované miesta"},
{"code":"X21.9","desc":"Kontakt s jedovatými pavúkmi - MU: Nešpecifikované miesto"},
{"code":"X22.0","desc":"Kontakt so škorpiónmi - MU: Domov"},
{"code":"X22.1","desc":"Kontakt so škorpiónmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X22.2","desc":"Kontakt so škorpiónmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X22.3","desc":"Kontakt so škorpiónmi - MU: Športové a atletické priestranstvo"},
{"code":"X22.4","desc":"Kontakt so škorpiónmi - MU: Ulica a cesta"},
{"code":"X22.5","desc":"Kontakt so škorpiónmi - MU: Obchod a služby"},
{"code":"X22.6","desc":"Kontakt so škorpiónmi - MU: Priemyselné objekty"},
{"code":"X22.7","desc":"Kontakt so škorpiónmi - MU: Poľnohospodárske"},
{"code":"X22.8","desc":"Kontakt so škorpiónmi - MU: Iné špecifikované miesta"},
{"code":"X22.9","desc":"Kontakt so škorpiónmi - MU: Nešpecifikované miesto"},
{"code":"X23.0","desc":"Kontakt so sršňami, osami a včelami - MU: Domov"},
{"code":"X23.1","desc":"Kontakt so sršňami, osami a včelami - MU: Kolektívne ústavné zariadenia"},
{"code":"X23.2","desc":"Kontakt so sršňami, osami a včelami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X23.3","desc":"Kontakt so sršňami, osami a včelami - MU: Športové a atletické priestranstvo"},
{"code":"X23.4","desc":"Kontakt so sršňami, osami a včelami - MU: Ulica a cesta"},
{"code":"X23.5","desc":"Kontakt so sršňami, osami a včelami - MU: Obchod a služby"},
{"code":"X23.6","desc":"Kontakt so sršňami, osami a včelami - MU: Priemyselné objekty"},
{"code":"X23.7","desc":"Kontakt so sršňami, osami a včelami - MU: Poľnohospodárske"},
{"code":"X23.8","desc":"Kontakt so sršňami, osami a včelami - MU: Iné špecifikované miesta"},
{"code":"X23.9","desc":"Kontakt so sršňami, osami a včelami - MU: Nešpecifikované miesto"},
{"code":"X24.0","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Domov"},
{"code":"X24.1","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X24.2","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X24.3","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Športové a atletické priestranstvo"},
{"code":"X24.4","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Ulica a cesta"},
{"code":"X24.5","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Obchod a služby"},
{"code":"X24.6","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Priemyselné objekty"},
{"code":"X24.7","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Poľnohospodárske"},
{"code":"X24.8","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Iné špecifikované miesta"},
{"code":"X24.9","desc":"Kontakt so stonožkami a jedovatými tropickými mnohonožkami - MU: Nešpecifikované miesto"},
{"code":"X25.0","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Domov"},
{"code":"X25.1","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Kolektívne ústavné zariadenia"},
{"code":"X25.2","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X25.3","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Športové a atletické priestranstvo"},
{"code":"X25.4","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Ulica a cesta"},
{"code":"X25.5","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Obchod a služby"},
{"code":"X25.6","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Priemyselné objekty"},
{"code":"X25.7","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Poľnohospodárske"},
{"code":"X25.8","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Iné špecifikované miesta"},
{"code":"X25.9","desc":"Kontakt s inými bližšie určenými jedovatými článkonožcami - MU: Nešpecifikované miesto"},
{"code":"X26.0","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Domov"},
{"code":"X26.1","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Kolektívne ústavné zariadenia"},
{"code":"X26.2","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X26.3","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Športové a atletické priestranstvo"},
{"code":"X26.4","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Ulica a cesta"},
{"code":"X26.5","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Obchod a služby"},
{"code":"X26.6","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Priemyselné objekty"},
{"code":"X26.7","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Poľnohospodárske"},
{"code":"X26.8","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Iné špecifikované miesta"},
{"code":"X26.9","desc":"Kontakt s jedovatými morskými zvieratami a rastlinami - MU: Nešpecifikované miesto"},
{"code":"X27.0","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Domov"},
{"code":"X27.1","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Kolektívne ústavné zariadenia"},
{"code":"X27.2","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X27.3","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Športové a atletické priestranstvo"},
{"code":"X27.4","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Ulica a cesta"},
{"code":"X27.5","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Obchod a služby"},
{"code":"X27.6","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Priemyselné objekty"},
{"code":"X27.7","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Poľnohospodárske"},
{"code":"X27.8","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Iné špecifikované miesta"},
{"code":"X27.9","desc":"Kontakt s inými bližšie určenými jedovatými zvieratami - MU: Nešpecifikované miesto"},
{"code":"X28.0","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Domov"},
{"code":"X28.1","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Kolektívne ústavné zariadenia"},
{"code":"X28.2","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X28.3","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Športové a atletické priestranstvo"},
{"code":"X28.4","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Ulica a cesta"},
{"code":"X28.5","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Obchod a služby"},
{"code":"X28.6","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Priemyselné objekty"},
{"code":"X28.7","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Poľnohospodárske"},
{"code":"X28.8","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Iné špecifikované miesta"},
{"code":"X28.9","desc":"Kontakt s inými bližšie určenými jedovatými rastlinami - MU: Nešpecifikované miesto"},
{"code":"X29.0","desc":"Kontakt s bližšie neurčenými jedovatými zvieratami a rastlinami - MU: Domov"},
{"code":"X29.1","desc":"Kontakt s bližšie neurčenými jedovatými zvieratami a rastlinami - MU: Kolektívne ústavné zariadenia"},
{"code":"X29.2","desc":"Kontakt s bližšie neurčenými jedovatými zvieratami a rastlinami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X29.3","desc":"Kontakt s bližšie neurčenými jedovatými zvieratami a rastlinami - MU: Športové a atletické priestranstvo"},
{"code":"X29.4","desc":"Kontakt s bližšie neurčenými jedovatými zvieratami a rastlinami - MU: Ulica a cesta"},
{"code":"X29.5","desc":"Kontakt s bližšie neurčenými jedovatými zvieratami a rastlinami - MU: Obchod a služby"},
{"code":"X29.6","desc":"Kontakt s bližšie neurčenými jedovatými zvieratami a rastlinami - MU: Priemyselné objekty"},
{"code":"X29.7","desc":"Kontakt s bližšie neurčenými jedovatými zvieratami a rastlinami - MU: Poľnohospodárske"},
{"code":"X29.8","desc":"Kontakt s bližšie neurčenými jedovatými zvieratami a rastlinami - MU: Iné špecifikované miesta"},
{"code":"X30.0","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Domov"},
{"code":"X30.1","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Kolektívne ústavné zariadenia"},
{"code":"X30.2","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X30.3","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Športové a atletické priestranstvo"},
{"code":"X30.4","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Ulica a cesta"},
{"code":"X30.5","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Obchod a služby"},
{"code":"X30.6","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Priemyselné objekty"},
{"code":"X30.7","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Poľnohospodárske"},
{"code":"X30.8","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Iné špecifikované miesta"},
{"code":"X30.9","desc":"Poškodenie nadmernou prirodzenou horúčavou - MU: Nešpecifikované miesto"},
{"code":"X31.0","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Domov"},
{"code":"X31.1","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Kolektívne ústavné zariadenia"},
{"code":"X31.2","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X31.3","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Športové a atletické priestranstvo"},
{"code":"X31.4","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Ulica a cesta"},
{"code":"X31.5","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Obchod a služby"},
{"code":"X31.6","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Priemyselné objekty"},
{"code":"X31.7","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Poľnohospodárske"},
{"code":"X31.8","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Iné špecifikované miesta"},
{"code":"X31.9","desc":"Poškodenie nadmerným prirodzeným chladom - MU: Nešpecifikované miesto"},
{"code":"X32.0","desc":"Popálenie slnečným svetlom - MU: Domov"},
{"code":"X32.1","desc":"Popálenie slnečným svetlom - MU: Kolektívne ústavné zariadenia"},
{"code":"X32.2","desc":"Popálenie slnečným svetlom - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X32.3","desc":"Popálenie slnečným svetlom - MU: Športové a atletické priestranstvo"},
{"code":"X32.4","desc":"Popálenie slnečným svetlom - MU: Ulica a cesta"},
{"code":"X32.5","desc":"Popálenie slnečným svetlom - MU: Obchod a služby"},
{"code":"X32.6","desc":"Popálenie slnečným svetlom - MU: Priemyselné objekty"},
{"code":"X32.7","desc":"Popálenie slnečným svetlom - MU: Poľnohospodárske"},
{"code":"X32.8","desc":"Popálenie slnečným svetlom - MU: Iné špecifikované miesta"},
{"code":"X32.9","desc":"Popálenie slnečným svetlom - MU: Nešpecifikované miesto"},
{"code":"X33.0","desc":"Obeť blesku - MU: Domov"},
{"code":"X33.1","desc":"Obeť blesku - MU: Kolektívne ústavné zariadenia"},
{"code":"X33.2","desc":"Obeť blesku - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X33.3","desc":"Obeť blesku - MU: Športové a atletické priestranstvo"},
{"code":"X33.4","desc":"Obeť blesku - MU: Ulica a cesta"},
{"code":"X33.5","desc":"Obeť blesku - MU: Obchod a služby"},
{"code":"X33.6","desc":"Obeť blesku - MU: Priemyselné objekty"},
{"code":"X33.7","desc":"Obeť blesku - MU: Poľnohospodárske"},
{"code":"X33.8","desc":"Obeť blesku - MU: Iné špecifikované miesta"},
{"code":"X33.9","desc":"Obeť blesku - MU: Nešpecifikované miesto"},
{"code":"X34.2","desc":"Obeť zemetrasenia - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X34.3","desc":"Obeť zemetrasenia - MU: Športové a atletické priestranstvo"},
{"code":"X34.4","desc":"Obeť zemetrasenia - MU: Ulica a cesta"},
{"code":"X34.5","desc":"Obeť zemetrasenia - MU: Obchod a služby"},
{"code":"X34.6","desc":"Obeť zemetrasenia - MU: Priemyselné objekty"},
{"code":"X34.7","desc":"Obeť zemetrasenia - MU: Poľnohospodárske"},
{"code":"X35.0","desc":"Obeť výbuchu sopky - MU: Domov"},
{"code":"X35.1","desc":"Obeť výbuchu sopky - MU: Kolektívne ústavné zariadenia"},
{"code":"X35.2","desc":"Obeť výbuchu sopky - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X35.3","desc":"Obeť výbuchu sopky - MU: Športové a atletické priestranstvo"},
{"code":"X35.4","desc":"Obeť výbuchu sopky - MU: Ulica a cesta"},
{"code":"X35.5","desc":"Obeť výbuchu sopky - MU: Obchod a služby"},
{"code":"X35.6","desc":"Obeť výbuchu sopky - MU: Priemyselné objekty"},
{"code":"X35.7","desc":"Obeť výbuchu sopky - MU: Poľnohospodárske"},
{"code":"X35.8","desc":"Obeť výbuchu sopky - MU: Iné špecifikované miesta"},
{"code":"X35.9","desc":"Obeť výbuchu sopky - MU: Nešpecifikované miesto"},
{"code":"X36.0","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Domov"},
{"code":"X36.1","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Kolektívne ústavné zariadenia"},
{"code":"X36.2","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X36.3","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Športové a atletické priestranstvo"},
{"code":"X36.4","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Ulica a cesta"},
{"code":"X36.5","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Obchod a služby"},
{"code":"X36.6","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Priemyselné objekty"},
{"code":"X36.7","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Poľnohospodárske"},
{"code":"X36.8","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Iné špecifikované miesta"},
{"code":"X36.9","desc":"Obeť lavíny, zosuvu pôdy a iných pohybov zeme - MU: Nešpecifikované miesto"},
{"code":"X37.0","desc":"Obeť ničivej víchrice - MU: Domov"},
{"code":"X37.1","desc":"Obeť ničivej víchrice - MU: Kolektívne ústavné zariadenia"},
{"code":"X37.2","desc":"Obeť ničivej víchrice - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X37.3","desc":"Obeť ničivej víchrice - MU: Športové a atletické priestranstvo"},
{"code":"X37.4","desc":"Obeť ničivej víchrice - MU: Ulica a cesta"},
{"code":"X37.5","desc":"Obeť ničivej víchrice - MU: Obchod a služby"},
{"code":"X37.6","desc":"Obeť ničivej víchrice - MU: Priemyselné objekty"},
{"code":"X37.7","desc":"Obeť ničivej víchrice - MU: Poľnohospodárske"},
{"code":"X37.8","desc":"Obeť ničivej víchrice - MU: Iné špecifikované miesta"},
{"code":"X37.9","desc":"Obeť ničivej víchrice - MU: Nešpecifikované miesto"},
{"code":"X38.0","desc":"Obeť záplavy - MU: Domov"},
{"code":"X38.1","desc":"Obeť záplavy - MU: Kolektívne ústavné zariadenia"},
{"code":"X38.2","desc":"Obeť záplavy - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X38.3","desc":"Obeť záplavy - MU: Športové a atletické priestranstvo"},
{"code":"X38.4","desc":"Obeť záplavy - MU: Ulica a cesta"},
{"code":"X38.5","desc":"Obeť záplavy - MU: Obchod a služby"},
{"code":"X38.6","desc":"Obeť záplavy - MU: Priemyselné objekty"},
{"code":"X38.7","desc":"Obeť záplavy - MU: Poľnohospodárske"},
{"code":"X38.8","desc":"Obeť záplavy - MU: Iné špecifikované miesta"},
{"code":"X38.9","desc":"Obeť záplavy - MU: Nešpecifikované miesto"},
{"code":"X39.0","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Domov"},
{"code":"X39.1","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Kolektívne ústavné zariadenia"},
{"code":"X39.2","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X39.3","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Športové a atletické priestranstvo"},
{"code":"X39.4","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Ulica a cesta"},
{"code":"X39.5","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Obchod a služby"},
{"code":"X39.6","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Priemyselné objekty"},
{"code":"X39.7","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Poľnohospodárske"},
{"code":"X39.8","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Iné špecifikované miesta"},
{"code":"X39.9","desc":"Poškodenie účinkami iných a bližšie neurčených síl prírody - MU: Nešpecifikované miesto"},
{"code":"X40.0","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Domov"},
{"code":"X40.1","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Kolektívne ústavné zariadenia"},
{"code":"X40.2","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X40.3","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Športové a atletické priestranstvo"},
{"code":"X40.4","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Ulica a cesta"},
{"code":"X40.5","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Obchod a služby"},
{"code":"X40.6","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Priemyselné objekty"},
{"code":"X40.7","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Poľnohospodárske"},
{"code":"X40.8","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Iné špecifikované miesta"},
{"code":"X40.9","desc":"Náhodná otrava a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Nešpecifikované miesto"},
{"code":"X41.0","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Domov"},
{"code":"X41.1","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Kolektívne ústavné zariadenia"},
{"code":"X41.2","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X41.3","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Športové a atletické priestranstvo"},
{"code":"X41.4","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Ulica a cesta"},
{"code":"X41.5","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Obchod a služby"},
{"code":"X41.6","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Priemyselné objekty"},
{"code":"X41.7","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Poľnohospodárske"},
{"code":"X41.8","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Iné špecifikované miesta"},
{"code":"X41.9","desc":"Náhodná otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Nešpecifikované miesto"},
{"code":"X42.0","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Domov"},
{"code":"X42.1","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Kolektívne ústavné zariadenia"},
{"code":"X42.2","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X42.3","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Športové a atletické priestranstvo"},
{"code":"X42.4","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Ulica a cesta"},
{"code":"X42.5","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Obchod a služby"},
{"code":"X42.6","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Priemyselné objekty"},
{"code":"X42.7","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Poľnohospodárske"},
{"code":"X42.8","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Iné špecifikované miesta"},
{"code":"X42.9","desc":"Náhodná otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Nešpecifikované miesto"},
{"code":"X43.0","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Domov"},
{"code":"X43.1","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Kolektívne ústavné zariadenia"},
{"code":"X43.2","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X43.3","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Športové a atletické priestranstvo"},
{"code":"X43.4","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Ulica a cesta"},
{"code":"X43.5","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Obchod a služby"},
{"code":"X43.6","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Priemyselné objekty"},
{"code":"X43.7","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Poľnohospodárske"},
{"code":"X43.8","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Iné špecifikované miesta"},
{"code":"X43.9","desc":"Náhodná otrava a priotrávenie inými liečivami účinkujúcimi na autonómny nervový systém - MU: Nešpecifikované miesto"},
{"code":"X44.0","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Domov"},
{"code":"X44.1","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X44.2","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X44.3","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Športové a atletické priestranstvo"},
{"code":"X44.4","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Ulica a cesta"},
{"code":"X44.5","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Obchod a služby"},
{"code":"X44.6","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Priemyselné objekty"},
{"code":"X44.7","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Poľnohospodárske"},
{"code":"X44.8","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Iné špecifikované miesta"},
{"code":"X44.9","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Nešpecifikované miesto"},
{"code":"X45.0","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Domov"},
{"code":"X45.1","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Kolektívne ústavné zariadenia"},
{"code":"X45.2","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X45.3","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Športové a atletické priestranstvo"},
{"code":"X45.4","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Ulica a cesta"},
{"code":"X45.5","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Obchod a služby"},
{"code":"X45.6","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Priemyselné objekty"},
{"code":"X45.7","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Poľnohospodárske"},
{"code":"X45.8","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Iné špecifikované miesta"},
{"code":"X45.9","desc":"Náhodná otrava a priotrávenie alkoholom - MU: Nešpecifikované miesto"},
{"code":"X46.0","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Domov"},
{"code":"X46.1","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X46.2","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X46.3","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Športové a atletické priestranstvo"},
{"code":"X46.4","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Ulica a cesta"},
{"code":"X46.5","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Obchod a služby"},
{"code":"X46.6","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Priemyselné objekty"},
{"code":"X46.7","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Poľnohospodárske"},
{"code":"X46.8","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Iné špecifikované miesta"},
{"code":"X46.9","desc":"Náhodná otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Nešpecifikované miesto"},
{"code":"X47.0","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Domov"},
{"code":"X47.1","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X47.2","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X47.3","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Športové a atletické priestranstvo"},
{"code":"X47.4","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Ulica a cesta"},
{"code":"X47.5","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Obchod a služby"},
{"code":"X47.6","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Priemyselné objekty"},
{"code":"X47.7","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Poľnohospodárske"},
{"code":"X47.8","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Iné špecifikované miesta"},
{"code":"X47.9","desc":"Náhodná otrava a priotrávenie inými plynmi a výparmi - MU: Nešpecifikované miesto"},
{"code":"X48.0","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Domov"},
{"code":"X48.1","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Kolektívne ústavné zariadenia"},
{"code":"X48.2","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X48.3","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Športové a atletické priestranstvo"},
{"code":"X48.4","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Ulica a cesta"},
{"code":"X48.5","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Obchod a služby"},
{"code":"X48.6","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Priemyselné objekty"},
{"code":"X48.7","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Poľnohospodárske"},
{"code":"X48.8","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Iné špecifikované miesta"},
{"code":"X48.9","desc":"Náhodná otrava a priotrávenie pesticídmi - miesto udalosti - MU: Nešpecifikované miesto"},
{"code":"X49.0","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Domov"},
{"code":"X49.1","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X49.2","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X49.3","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Športové a atletické priestranstvo"},
{"code":"X49.4","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Ulica a cesta"},
{"code":"X49.5","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Obchod a služby"},
{"code":"X49.6","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Priemyselné objekty"},
{"code":"X49.7","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Poľnohospodárske"},
{"code":"X49.8","desc":"Náhodná otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Iné špecifikované miesta"},
{"code":"X50.0","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Domov"},
{"code":"X50.1","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Kolektívne ústavné zariadenia"},
{"code":"X50.2","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X50.3","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Športové a atletické priestranstvo"},
{"code":"X50.4","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Ulica a cesta"},
{"code":"X50.5","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Obchod a služby"},
{"code":"X50.6","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Priemyselné objekty"},
{"code":"X50.7","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Poľnohospodárske"},
{"code":"X50.8","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Iné špecifikované miesta"},
{"code":"X50.9","desc":"Preťaženie a namáhavé alebo opakované pohyby - MU: Nešpecifikované miesto"},
{"code":"X51.0","desc":"Cestovanie a pohyb - MU: Domov"},
{"code":"X51.1","desc":"Cestovanie a pohyb - MU: Kolektívne ústavné zariadenia"},
{"code":"X51.2","desc":"Cestovanie a pohyb - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X51.3","desc":"Cestovanie a pohyb - MU: Športové a atletické priestranstvo"},
{"code":"X51.4","desc":"Cestovanie a pohyb - MU: Ulica a cesta"},
{"code":"X51.5","desc":"Cestovanie a pohyb - MU: Obchod a služby"},
{"code":"X51.6","desc":"Cestovanie a pohyb - MU: Priemyselné objekty"},
{"code":"X51.7","desc":"Cestovanie a pohyb - MU: Poľnohospodárske"},
{"code":"X51.8","desc":"Cestovanie a pohyb - MU: Iné špecifikované miesta"},
{"code":"X51.9","desc":"Cestovanie a pohyb - MU: Nešpecifikované miesto"},
{"code":"X52.0","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Domov"},
{"code":"X52.1","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Kolektívne ústavné zariadenia"},
{"code":"X52.2","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X52.3","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Športové a atletické priestranstvo"},
{"code":"X52.4","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Ulica a cesta"},
{"code":"X52.5","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Obchod a služby"},
{"code":"X52.6","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Priemyselné objekty"},
{"code":"X52.7","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Poľnohospodárske"},
{"code":"X52.8","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Iné špecifikované miesta"},
{"code":"X52.9","desc":"Dlhší pobyt v beztiažovom prostredí - MU: Nešpecifikované miesto"},
{"code":"X53.0","desc":"Nedostatok potravy - MU: Domov"},
{"code":"X53.1","desc":"Nedostatok potravy - MU: Kolektívne ústavné zariadenia"},
{"code":"X53.2","desc":"Nedostatok potravy - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X53.3","desc":"Nedostatok potravy - MU: Športové a atletické priestranstvo"},
{"code":"X53.4","desc":"Nedostatok potravy - MU: Ulica a cesta"},
{"code":"X53.5","desc":"Nedostatok potravy - MU: Obchod a služby"},
{"code":"X53.6","desc":"Nedostatok potravy - MU: Priemyselné objekty"},
{"code":"X53.7","desc":"Nedostatok potravy - MU: Poľnohospodárske"},
{"code":"X53.8","desc":"Nedostatok potravy - MU: Iné špecifikované miesta"},
{"code":"X53.9","desc":"Nedostatok potravy - MU: Nešpecifikované miesto"},
{"code":"X54.0","desc":"Nedostatok vody - MU: Domov"},
{"code":"X54.1","desc":"Nedostatok vody - MU: Kolektívne ústavné zariadenia"},
{"code":"X54.2","desc":"Nedostatok vody - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X54.3","desc":"Nedostatok vody - MU: Športové a atletické priestranstvo"},
{"code":"X54.4","desc":"Nedostatok vody - MU: Ulica a cesta"},
{"code":"X54.5","desc":"Nedostatok vody - MU: Obchod a služby"},
{"code":"X54.6","desc":"Nedostatok vody - MU: Priemyselné objekty"},
{"code":"X54.7","desc":"Nedostatok vody - MU: Poľnohospodárske"},
{"code":"X54.8","desc":"Nedostatok vody - MU: Iné špecifikované miesta"},
{"code":"X54.9","desc":"Nedostatok vody - MU: Nešpecifikované miesto"},
{"code":"X57.0","desc":"Bližšie neurčený nedostatok - MU: Domov"},
{"code":"X57.1","desc":"Bližšie neurčený nedostatok - MU: Kolektívne ústavné zariadenia"},
{"code":"X57.2","desc":"Bližšie neurčený nedostatok - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X57.3","desc":"Bližšie neurčený nedostatok - MU: Športové a atletické priestranstvo"},
{"code":"X57.4","desc":"Bližšie neurčený nedostatok - MU: Ulica a cesta"},
{"code":"X57.5","desc":"Bližšie neurčený nedostatok - MU: Obchod a služby"},
{"code":"X57.6","desc":"Bližšie neurčený nedostatok - MU: Priemyselné objekty"},
{"code":"X57.7","desc":"Bližšie neurčený nedostatok - MU: Poľnohospodárske"},
{"code":"X57.8","desc":"Bližšie neurčený nedostatok - MU: Iné špecifikované miesta"},
{"code":"X57.9","desc":"Bližšie neurčený nedostatok - MU: Nešpecifikované miesto"},
{"code":"X58.0","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Domov"},
{"code":"X58.1","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X58.2","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X58.3","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Športové a atletické priestranstvo"},
{"code":"X58.4","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Ulica a cesta"},
{"code":"X58.5","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Obchod a služby"},
{"code":"X58.6","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Priemyselné objekty"},
{"code":"X58.7","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Poľnohospodárske"},
{"code":"X58.8","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Iné špecifikované miesta"},
{"code":"X58.9","desc":"Poškodenie inými bližšie určenými vplyvmi - MU: Nešpecifikované miesto"},
{"code":"X59.0","desc":"Poškodenie bližšie neurčeným vplyvom"},
{"code":"X59.1","desc":"Poškodenie bližšie neurčeným vplyvom - MU: Kolektívne ústavné zariadenia"},
{"code":"X59.2","desc":"Poškodenie bližšie neurčeným vplyvom - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X59.3","desc":"Poškodenie bližšie neurčeným vplyvom - MU: Športové a atletické priestranstvo"},
{"code":"X59.4","desc":"Poškodenie bližšie neurčeným vplyvom - MU: Ulica a cesta"},
{"code":"X59.5","desc":"Poškodenie bližšie neurčeným vplyvom - MU: Obchod a služby"},
{"code":"X59.6","desc":"Poškodenie bližšie neurčeným vplyvom - MU: Priemyselné objekty"},
{"code":"X59.7","desc":"Poškodenie bližšie neurčeným vplyvom - MU: Poľnohospodárske"},
{"code":"X59.8","desc":"Poškodenie bližšie neurčeným vplyvom - MU: Iné špecifikované miesta"},
{"code":"X60.0","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Domov"},
{"code":"X60.1","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Kolektívne ústavné zariadenia"},
{"code":"X60.2","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X60.3","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Športové a atletické priestranstvo"},
{"code":"X60.4","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Ulica a cesta"},
{"code":"X60.5","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Obchod a služby"},
{"code":"X60.6","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Priemyselné objekty"},
{"code":"X60.7","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Poľnohospodárske"},
{"code":"X60.8","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Iné špecifikované miesta"},
{"code":"X60.9","desc":"Úmysel. samopoškod. otravou a priotr. neopiát. analgetik;antipyretik;antireumatik. - MU: Nešpecifikované miesto"},
{"code":"X61.0","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Domov"},
{"code":"X61.1","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Kolektívne ústavné zariadenia"},
{"code":"X61.2","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X61.3","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Športové a atletické priestranstvo"},
{"code":"X61.4","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Ulica a cesta"},
{"code":"X61.5","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Obchod a služby"},
{"code":"X61.6","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Priemyselné objekty"},
{"code":"X61.7","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Poľnohospodárske"},
{"code":"X61.8","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Iné špecifikované miesta"},
{"code":"X61.9","desc":"Úm. samopoš. otravou antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Nešpecifikované miesto"},
{"code":"X62.0","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Domov"},
{"code":"X62.1","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Kolektívne ústavné zariadenia"},
{"code":"X62.2","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X62.3","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Športové a atletické priestranstvo"},
{"code":"X62.4","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Ulica a cesta"},
{"code":"X62.5","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Obchod a služby"},
{"code":"X62.6","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Priemyselné objekty"},
{"code":"X62.7","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Poľnohospodárske"},
{"code":"X62.8","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Iné špecifikované miesta"},
{"code":"X62.9","desc":"Úmysel. samopoškod. otravou opiát. analgetik. a psychodysleptik.nezatried. inde - MU: Nešpecifikované miesto"},
{"code":"X63.0","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Domov"},
{"code":"X63.1","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Kolektívne ústavné zariadenia"},
{"code":"X63.2","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X63.3","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Športové a atletické priestranstvo"},
{"code":"X63.4","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Ulica a cesta"},
{"code":"X63.5","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Obchod a služby"},
{"code":"X63.6","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Priemyselné objekty"},
{"code":"X63.7","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Poľnohospodárske"},
{"code":"X63.8","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Iné špecifikované miesta"},
{"code":"X63.9","desc":"Úmysel. samopoškod. otravou a priotr. liekmi účinkujúcimi na autonómny nervový systém - MU: Nešpecifikované miesto"},
{"code":"X64.0","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Domov"},
{"code":"X64.1","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X64.2","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X64.3","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Športové a atletické priestranstvo"},
{"code":"X64.4","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Ulica a cesta"},
{"code":"X64.5","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Obchod a služby"},
{"code":"X64.6","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Priemyselné objekty"},
{"code":"X64.7","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Poľnohospodárske"},
{"code":"X64.8","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Iné špecifikované miesta"},
{"code":"X64.9","desc":"Úmysel. samopoš. otravou inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Nešpecifikované miesto"},
{"code":"X65.0","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Domov"},
{"code":"X65.1","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Kolektívne ústavné zariadenia"},
{"code":"X65.2","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X65.3","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Športové a atletické priestranstvo"},
{"code":"X65.4","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Ulica a cesta"},
{"code":"X65.5","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Obchod a služby"},
{"code":"X65.6","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Priemyselné objekty"},
{"code":"X65.7","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Poľnohospodárske"},
{"code":"X65.8","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Iné špecifikované miesta"},
{"code":"X65.9","desc":"Úmyselné samopoškodenie otravou a priotrávením alkoholomi - MU: Nešpecifikované miesto"},
{"code":"X66.0","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Domov"},
{"code":"X66.1","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X66.2","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X66.3","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Športové a atletické priestranstvo"},
{"code":"X66.4","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Ulica a cesta"},
{"code":"X66.5","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Obchod a služby"},
{"code":"X66.6","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Priemyselné objekty"},
{"code":"X66.7","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Poľnohospodárske"},
{"code":"X66.8","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Iné špecifikované miesta"},
{"code":"X66.9","desc":"Úmysel. samopoš. otravou organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Nešpecifikované miesto"},
{"code":"X67.0","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Domov"},
{"code":"X67.1","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X67.2","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X67.3","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Športové a atletické priestranstvo"},
{"code":"X67.4","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Ulica a cesta"},
{"code":"X67.5","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Obchod a služby"},
{"code":"X67.6","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Priemyselné objekty"},
{"code":"X67.7","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Poľnohospodárske"},
{"code":"X67.8","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Iné špecifikované miesta"},
{"code":"X67.9","desc":"Úmyselné samopoškodenie otravou a priotrávením inými plynmi a výparmi - MU: Nešpecifikované miesto"},
{"code":"X68.0","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Domov"},
{"code":"X68.1","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X68.2","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X68.3","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Športové a atletické priestranstvo"},
{"code":"X68.4","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Ulica a cesta"},
{"code":"X68.5","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Obchod a služby"},
{"code":"X68.6","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Priemyselné objekty"},
{"code":"X68.7","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Poľnohospodárske"},
{"code":"X68.8","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Iné špecifikované miesta"},
{"code":"X68.9","desc":"Úmyselné samopoškodenie otravou a priotrávením pesticídmi - MU: Nešpecifikované miesto"},
{"code":"X69.0","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Domov"},
{"code":"X69.1","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X69.2","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X69.3","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Športové a atletické priestranstvo"},
{"code":"X69.4","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Ulica a cesta"},
{"code":"X69.5","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Obchod a služby"},
{"code":"X69.6","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Priemyselné objekty"},
{"code":"X69.7","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Poľnohospodárske"},
{"code":"X69.8","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Iné špecifikované miesta"},
{"code":"X69.9","desc":"Úmysel. samopoškod. otravou inými a bližšie neurčenými chemick. a škodliv. látkami - MU: Nešpecifikované miesto"},
{"code":"X70.0","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Domov"},
{"code":"X70.1","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Kolektívne ústavné zariadenia"},
{"code":"X70.2","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X70.3","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Športové a atletické priestranstvo"},
{"code":"X70.4","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Ulica a cesta"},
{"code":"X70.5","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Obchod a služby"},
{"code":"X70.6","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Priemyselné objekty"},
{"code":"X70.7","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Poľnohospodárske"},
{"code":"X70.8","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Iné špecifikované miesta"},
{"code":"X70.9","desc":"Úmyselné samopoškodenie obesením, zaškrtením a zadusením - MU: Nešpecifikované miesto"},
{"code":"X71.0","desc":"Úmyselné samopoškodenie utopením a topením - MU: Domov"},
{"code":"X71.1","desc":"Úmyselné samopoškodenie utopením a topením - MU: Kolektívne ústavné zariadenia"},
{"code":"X71.2","desc":"Úmyselné samopoškodenie utopením a topením - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X71.3","desc":"Úmyselné samopoškodenie utopením a topením - MU: Športové a atletické priestranstvo"},
{"code":"X71.4","desc":"Úmyselné samopoškodenie utopením a topením - MU: Ulica a cesta"},
{"code":"X71.5","desc":"Úmyselné samopoškodenie utopením a topením - MU: Obchod a služby"},
{"code":"X71.6","desc":"Úmyselné samopoškodenie utopením a topením - MU: Priemyselné objekty"},
{"code":"X71.7","desc":"Úmyselné samopoškodenie utopením a topením - MU: Poľnohospodárske"},
{"code":"X71.8","desc":"Úmyselné samopoškodenie utopením a topením - MU: Iné špecifikované miesta"},
{"code":"X71.9","desc":"Úmyselné samopoškodenie utopením a topením - MU: Nešpecifikované miesto"},
{"code":"X72.0","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Domov"},
{"code":"X72.1","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Kolektívne ústavné zariadenia"},
{"code":"X72.2","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X72.3","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Športové a atletické priestranstvo"},
{"code":"X72.4","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Ulica a cesta"},
{"code":"X72.5","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Obchod a služby"},
{"code":"X72.6","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Priemyselné objekty"},
{"code":"X72.7","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Poľnohospodárske"},
{"code":"X72.8","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Iné špecifikované miesta"},
{"code":"X72.9","desc":"Úmyselné samopoškodenie výstrelom z ručnej zbrane - MU: Nešpecifikované miesto"},
{"code":"X73.0","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Domov"},
{"code":"X73.1","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Kolektívne ústavné zariadenia"},
{"code":"X73.2","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X73.3","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Športové a atletické priestranstvo"},
{"code":"X73.4","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Ulica a cesta"},
{"code":"X73.5","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Obchod a služby"},
{"code":"X73.6","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Priemyselné objekty"},
{"code":"X73.7","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Poľnohospodárske"},
{"code":"X73.8","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Iné špecifikované miesta"},
{"code":"X73.9","desc":"Úmyselné samopoškodenie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Nešpecifikované miesto"},
{"code":"X74.0","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Domov"},
{"code":"X74.1","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Kolektívne ústavné zariadenia"},
{"code":"X74.2","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X74.3","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Športové a atletické priestranstvo"},
{"code":"X74.4","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Ulica a cesta"},
{"code":"X74.5","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Obchod a služby"},
{"code":"X74.6","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Priemyselné objekty"},
{"code":"X74.7","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Poľnohospodárske"},
{"code":"X74.8","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Iné špecifikované miesta"},
{"code":"X74.9","desc":"Úmyselné samopoškodenie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Nešpecifikované miesto"},
{"code":"X75.0","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Domov"},
{"code":"X75.1","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Kolektívne ústavné zariadenia"},
{"code":"X75.2","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X75.3","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Športové a atletické priestranstvo"},
{"code":"X75.4","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Ulica a cesta"},
{"code":"X75.5","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Obchod a služby"},
{"code":"X75.6","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Priemyselné objekty"},
{"code":"X75.7","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Poľnohospodárske"},
{"code":"X75.8","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Iné špecifikované miesta"},
{"code":"X75.9","desc":"Úmyselné samopoškodenie výbušnou látkou - MU: Nešpecifikované miesto"},
{"code":"X76.0","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Domov"},
{"code":"X76.1","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X76.2","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X76.3","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Športové a atletické priestranstvo"},
{"code":"X76.4","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Ulica a cesta"},
{"code":"X76.5","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Obchod a služby"},
{"code":"X76.6","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Priemyselné objekty"},
{"code":"X76.7","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Poľnohospodárske"},
{"code":"X76.8","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Iné špecifikované miesta"},
{"code":"X76.9","desc":"Úmyselné samopoškodenie dymom, požiarom a plameňmi - MU: Nešpecifikované miesto"},
{"code":"X77.0","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Domov"},
{"code":"X77.1","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X77.2","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X77.3","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Športové a atletické priestranstvo"},
{"code":"X77.4","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Ulica a cesta"},
{"code":"X77.5","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Obchod a služby"},
{"code":"X77.6","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Priemyselné objekty"},
{"code":"X77.7","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Poľnohospodárske"},
{"code":"X77.8","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Iné špecifikované miesta"},
{"code":"X77.9","desc":"Úmysel. samopoškod. parou, horúcimi výparmi a horúcimi predmetmi - MU: Nešpecifikované miesto"},
{"code":"X78.0","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Domov"},
{"code":"X78.1","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Kolektívne ústavné zariadenia"},
{"code":"X78.2","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X78.3","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Športové a atletické priestranstvo"},
{"code":"X78.4","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Ulica a cesta"},
{"code":"X78.5","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Obchod a služby"},
{"code":"X78.6","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Priemyselné objekty"},
{"code":"X78.7","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Poľnohospodárske"},
{"code":"X78.8","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Iné špecifikované miesta"},
{"code":"X78.9","desc":"Úmyselné samopoškodenie ostrým predmetom - MU: Nešpecifikované miesto"},
{"code":"X79.0","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Domov"},
{"code":"X79.1","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Kolektívne ústavné zariadenia"},
{"code":"X79.2","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X79.3","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Športové a atletické priestranstvo"},
{"code":"X79.4","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Ulica a cesta"},
{"code":"X79.5","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Obchod a služby"},
{"code":"X79.6","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Priemyselné objekty"},
{"code":"X79.7","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Poľnohospodárske"},
{"code":"X79.8","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Iné špecifikované miesta"},
{"code":"X79.9","desc":"Úmyselné samopoškodenie tupým predmetom - MU: Nešpecifikované miesto"},
{"code":"X80.0","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Domov"},
{"code":"X80.1","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Kolektívne ústavné zariadenia"},
{"code":"X80.2","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X80.3","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Športové a atletické priestranstvo"},
{"code":"X80.4","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Ulica a cesta"},
{"code":"X80.5","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Obchod a služby"},
{"code":"X80.6","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Priemyselné objekty"},
{"code":"X80.7","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Poľnohospodárske"},
{"code":"X80.8","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Iné špecifikované miesta"},
{"code":"X80.9","desc":"Úmyselné samopoškodenie skokom z výšky - MU: Nešpecifikované miesto"},
{"code":"X81.0","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Domov"},
{"code":"X81.1","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Kolektívne ústavné zariadenia"},
{"code":"X81.2","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Školy, iné inštit. a admin. budovy"},
{"code":"X81.3","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Športové a atletické priestranstvo"},
{"code":"X81.4","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Ulica a cesta"},
{"code":"X81.5","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Obchod a služby"},
{"code":"X81.6","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Priemyselné objekty"},
{"code":"X81.7","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Poľnohospodárske"},
{"code":"X81.8","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Iné špecifikované miesta"},
{"code":"X81.9","desc":"Úmyselné samopoškodenie skočením alebo ľahnutím pred pohybujúci sa predmet - MU: Nešpecifikované miesto"},
{"code":"X82.0","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Domov"},
{"code":"X82.1","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Kolektívne ústavné zariadenia"},
{"code":"X82.2","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X82.3","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Športové a atletické priestranstvo"},
{"code":"X82.4","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Ulica a cesta"},
{"code":"X82.5","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Obchod a služby"},
{"code":"X82.6","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Priemyselné objekty"},
{"code":"X82.7","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Poľnohospodárske"},
{"code":"X82.8","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Iné špecifikované miesta"},
{"code":"X82.9","desc":"Úmyselné samopoškodenie havarovaním s motorovým vozidlom - MU: Nešpecifikované miesto"},
{"code":"X83.0","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Domov"},
{"code":"X83.1","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X83.2","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X83.3","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Športové a atletické priestranstvo"},
{"code":"X83.4","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Ulica a cesta"},
{"code":"X83.5","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Obchod a služby"},
{"code":"X83.6","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Priemyselné objekty"},
{"code":"X83.7","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Poľnohospodárske"},
{"code":"X83.8","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Iné špecifikované miesta"},
{"code":"X83.9","desc":"Úmyselné samopoškodenie inými bližšie určenými prostriedkami - MU: Nešpecifikované miesto"},
{"code":"X84.0","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami - MU: Domov"},
{"code":"X84.1","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X84.2","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X84.3","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami - MU: Športové a atletické priestranstvo"},
{"code":"X84.4","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami - MU: Ulica a cesta"},
{"code":"X84.5","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami - MU: Obchod a služby"},
{"code":"X84.6","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami - MU: Priemyselné objekty"},
{"code":"X84.7","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami - MU: Poľnohospodárske"},
{"code":"X84.8","desc":"Úmyselné samopoškodenie bližšie neurčenými prostriedkami - MU: Iné špecifikované miesta"},
{"code":"X85.0","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Domov"},
{"code":"X85.1","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X85.2","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X85.3","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Športové a atletické priestranstvo"},
{"code":"X85.4","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Ulica a cesta"},
{"code":"X85.5","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Obchod a služby"},
{"code":"X85.6","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Priemyselné objekty"},
{"code":"X85.7","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Poľnohospodárske"},
{"code":"X85.8","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Iné špecifikované miesta"},
{"code":"X85.9","desc":"Napadnutie liečivami, liekmi a biologickými látkami - MU: Nešpecifikované miesto"},
{"code":"X86.0","desc":"Napadnutie žieravinami - MU: Domov"},
{"code":"X86.1","desc":"Napadnutie žieravinami - MU: Kolektívne ústavné zariadenia"},
{"code":"X86.2","desc":"Napadnutie žieravinami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X86.3","desc":"Napadnutie žieravinami - MU: Športové a atletické priestranstvo"},
{"code":"X86.4","desc":"Napadnutie žieravinami - MU: Ulica a cesta"},
{"code":"X86.5","desc":"Napadnutie žieravinami - MU: Obchod a služby"},
{"code":"X86.6","desc":"Napadnutie žieravinami - MU: Priemyselné objekty"},
{"code":"X86.7","desc":"Napadnutie žieravinami - MU: Poľnohospodárske"},
{"code":"X86.8","desc":"Napadnutie žieravinami - MU: Iné špecifikované miesta"},
{"code":"X86.9","desc":"Napadnutie žieravinami - MU: Nešpecifikované miesto"},
{"code":"X87.0","desc":"Napadnutie pesticídmi - MU: Domov"},
{"code":"X87.1","desc":"Napadnutie pesticídmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X87.2","desc":"Napadnutie pesticídmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X87.3","desc":"Napadnutie pesticídmi - MU: Športové a atletické priestranstvo"},
{"code":"X87.4","desc":"Napadnutie pesticídmi - MU: Ulica a cesta"},
{"code":"X87.5","desc":"Napadnutie pesticídmi - MU: Obchod a služby"},
{"code":"X87.6","desc":"Napadnutie pesticídmi - MU: Priemyselné objekty"},
{"code":"X87.7","desc":"Napadnutie pesticídmi - MU: Poľnohospodárske"},
{"code":"X87.8","desc":"Napadnutie pesticídmi - MU: Iné špecifikované miesta"},
{"code":"X87.9","desc":"Napadnutie pesticídmi - MU: Nešpecifikované miesto"},
{"code":"X88.0","desc":"Napadnutie plynmi a výparmi - MU: Domov"},
{"code":"X88.1","desc":"Napadnutie plynmi a výparmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X88.2","desc":"Napadnutie plynmi a výparmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X88.3","desc":"Napadnutie plynmi a výparmi - MU: Športové a atletické priestranstvo"},
{"code":"X88.4","desc":"Napadnutie plynmi a výparmi - MU: Ulica a cesta"},
{"code":"X88.5","desc":"Napadnutie plynmi a výparmi - MU: Obchod a služby"},
{"code":"X88.6","desc":"Napadnutie plynmi a výparmi - MU: Priemyselné objekty"},
{"code":"X88.7","desc":"Napadnutie plynmi a výparmi - MU: Poľnohospodárske"},
{"code":"X88.8","desc":"Napadnutie plynmi a výparmi - MU: Iné špecifikované miesta"},
{"code":"X88.9","desc":"Napadnutie plynmi a výparmi - MU: Nešpecifikované miesto"},
{"code":"X89.0","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Domov"},
{"code":"X89.1","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X89.2","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X89.3","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Športové a atletické priestranstvo"},
{"code":"X89.4","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Ulica a cesta"},
{"code":"X89.5","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Obchod a služby"},
{"code":"X89.6","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Priemyselné objekty"},
{"code":"X89.7","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Poľnohospodárske"},
{"code":"X89.8","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Iné špecifikované miesta"},
{"code":"X89.9","desc":"Napadnutie inými bližšie určenými chemikáliami a škodlivými látkami - MU: Nešpecifikované miesto"},
{"code":"X90.0","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Domov"},
{"code":"X90.1","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X90.2","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X90.3","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Športové a atletické priestranstvo"},
{"code":"X90.4","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Ulica a cesta"},
{"code":"X90.5","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Obchod a služby"},
{"code":"X90.6","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Priemyselné objekty"},
{"code":"X90.7","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Poľnohospodárske"},
{"code":"X90.8","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Iné špecifikované miesta"},
{"code":"X90.9","desc":"Napadnutie bližšie neurčenými chemikáliami alebo škodlivými látkami - MU: Nešpecifikované miesto"},
{"code":"X91.0","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Domov"},
{"code":"X91.1","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Kolektívne ústavné zariadenia"},
{"code":"X91.2","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X91.3","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Športové a atletické priestranstvo"},
{"code":"X91.4","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Ulica a cesta"},
{"code":"X91.5","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Obchod a služby"},
{"code":"X91.6","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Priemyselné objekty"},
{"code":"X91.7","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Poľnohospodárske"},
{"code":"X91.8","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Iné špecifikované miesta"},
{"code":"X91.9","desc":"Napadnutie obesením, zaškrtením a zahrdúsením - MU: Nešpecifikované miesto"},
{"code":"X92.0","desc":"Napadnutie utopením a topením - MU: Domov"},
{"code":"X92.1","desc":"Napadnutie utopením a topením - MU: Kolektívne ústavné zariadenia"},
{"code":"X92.2","desc":"Napadnutie utopením a topením - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X92.3","desc":"Napadnutie utopením a topením - MU: Športové a atletické priestranstvo"},
{"code":"X92.4","desc":"Napadnutie utopením a topením - MU: Ulica a cesta"},
{"code":"X92.5","desc":"Napadnutie utopením a topením - MU: Obchod a služby"},
{"code":"X92.6","desc":"Napadnutie utopením a topením - MU: Priemyselné objekty"},
{"code":"X92.7","desc":"Napadnutie utopením a topením - MU: Poľnohospodárske"},
{"code":"X92.8","desc":"Napadnutie utopením a topením - MU: Iné špecifikované miesta"},
{"code":"X92.9","desc":"Napadnutie utopením a topením - MU: Nešpecifikované miesto"},
{"code":"X93.0","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Domov"},
{"code":"X93.1","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Kolektívne ústavné zariadenia"},
{"code":"X93.2","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X93.3","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Športové a atletické priestranstvo"},
{"code":"X93.4","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Ulica a cesta"},
{"code":"X93.5","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Obchod a služby"},
{"code":"X93.6","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Priemyselné objekty"},
{"code":"X93.7","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Poľnohospodárske"},
{"code":"X93.8","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Iné špecifikované miesta"},
{"code":"X93.9","desc":"Napadnutie výstrelom z ručnej strelnej zbrane - MU: Nešpecifikované miesto"},
{"code":"X94.0","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Domov"},
{"code":"X94.1","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Kolektívne ústavné zariadenia"},
{"code":"X94.2","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X94.3","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Športové a atletické priestranstvo"},
{"code":"X94.4","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Ulica a cesta"},
{"code":"X94.5","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Obchod a služby"},
{"code":"X94.6","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Priemyselné objekty"},
{"code":"X94.7","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Poľnohospodárske"},
{"code":"X94.8","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Iné špecifikované miesta"},
{"code":"X94.9","desc":"Napadnutie výstrelom z guľovnice, brokovnice a dlhej strelnej zbrane - MU: Nešpecifikované miesto"},
{"code":"X95.0","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Domov"},
{"code":"X95.1","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Kolektívne ústavné zariadenia"},
{"code":"X95.2","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X95.3","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Športové a atletické priestranstvo"},
{"code":"X95.4","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Ulica a cesta"},
{"code":"X95.5","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Obchod a služby"},
{"code":"X95.6","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Priemyselné objekty"},
{"code":"X95.7","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Poľnohospodárske"},
{"code":"X95.8","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Iné špecifikované miesta"},
{"code":"X95.9","desc":"Napadnutie výstrelom z inej a bližšie neurčenej strelnej zbrane - MU: Nešpecifikované miesto"},
{"code":"X96.0","desc":"Napadnutie výbušnými látkami - MU: Domov"},
{"code":"X96.1","desc":"Napadnutie výbušnými látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"X96.2","desc":"Napadnutie výbušnými látkami - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X96.3","desc":"Napadnutie výbušnými látkami - MU: Športové a atletické priestranstvo"},
{"code":"X96.4","desc":"Napadnutie výbušnými látkami - MU: Ulica a cesta"},
{"code":"X96.5","desc":"Napadnutie výbušnými látkami - MU: Obchod a služby"},
{"code":"X96.6","desc":"Napadnutie výbušnými látkami - MU: Priemyselné objekty"},
{"code":"X96.7","desc":"Napadnutie výbušnými látkami - MU: Poľnohospodárske"},
{"code":"X96.8","desc":"Napadnutie výbušnými látkami - MU: Iné špecifikované miesta"},
{"code":"X96.9","desc":"Napadnutie výbušnými látkami - MU: Nešpecifikované miesto"},
{"code":"X97.0","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Domov"},
{"code":"X97.1","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X97.2","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X97.3","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Športové a atletické priestranstvo"},
{"code":"X97.4","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Ulica a cesta"},
{"code":"X97.5","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Obchod a služby"},
{"code":"X97.6","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Priemyselné objekty"},
{"code":"X97.7","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Poľnohospodárske"},
{"code":"X97.8","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Iné špecifikované miesta"},
{"code":"X97.9","desc":"Napadnutie dymom, požiarom a plameňmi - MU: Nešpecifikované miesto"},
{"code":"X98.0","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Domov"},
{"code":"X98.1","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Kolektívne ústavné zariadenia"},
{"code":"X98.2","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X98.3","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Športové a atletické priestranstvo"},
{"code":"X98.4","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Ulica a cesta"},
{"code":"X98.5","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Obchod a služby"},
{"code":"X98.6","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Priemyselné objekty"},
{"code":"X98.7","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Poľnohospodárske"},
{"code":"X98.8","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Iné špecifikované miesta"},
{"code":"X98.9","desc":"Napadnutie parou, horúcimi výparmi a horúcimi predmetmi - MU: Nešpecifikované miesto"},
{"code":"X99.0","desc":"Napadnutie ostrým predmetom - MU: Domov"},
{"code":"X99.1","desc":"Napadnutie ostrým predmetom - MU: Kolektívne ústavné zariadenia"},
{"code":"X99.2","desc":"Napadnutie ostrým predmetom - MU: Školy, iné inštitúcie a verejné administrat. budovy"},
{"code":"X99.3","desc":"Napadnutie ostrým predmetom - MU: Športové a atletické priestranstvo"},
{"code":"X99.4","desc":"Napadnutie ostrým predmetom - MU: Ulica a cesta"},
{"code":"X99.5","desc":"Napadnutie ostrým predmetom - MU: Obchod a služby"},
{"code":"X99.6","desc":"Napadnutie ostrým predmetom - MU: Priemyselné objekty"},
{"code":"X99.7","desc":"Napadnutie ostrým predmetom - MU: Poľnohospodárske"},
{"code":"X99.8","desc":"Napadnutie ostrým predmetom - MU: Iné špecifikované miesta"},
{"code":"X99.9","desc":"Napadnutie ostrým predmetom - MU: Nešpecifikované miesto"},
{"code":"Y00.0","desc":"Napadnutie tupým predmetom - MU: Domov"},
{"code":"Y00.1","desc":"Napadnutie tupým predmetom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y00.2","desc":"Napadnutie tupým predmetom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y00.3","desc":"Napadnutie tupým predmetom - MU: Športové a atletické priestranstvo"},
{"code":"Y00.4","desc":"Napadnutie tupým predmetom - MU: Ulica a cesta"},
{"code":"Y00.5","desc":"Napadnutie tupým predmetom - MU: Obchod a služby"},
{"code":"Y00.6","desc":"Napadnutie tupým predmetom - MU: Priemyselné objekty"},
{"code":"Y00.7","desc":"Napadnutie tupým predmetom - MU: Poľnohospodárske"},
{"code":"Y00.8","desc":"Napadnutie tupým predmetom - MU: Iné špecifikované miesta"},
{"code":"Y00.9","desc":"Napadnutie tupým predmetom - MU: Nešpecifikované miesto"},
{"code":"Y01.0","desc":"Napadnutie zhodením z výšky - MU: Domov"},
{"code":"Y01.1","desc":"Napadnutie zhodením z výšky - MU: Kolektívne ústavné zariadenia"},
{"code":"Y01.2","desc":"Napadnutie zhodením z výšky - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y01.3","desc":"Napadnutie zhodením z výšky - MU: Športové a atletické priestranstvo"},
{"code":"Y01.4","desc":"Napadnutie zhodením z výšky - MU: Ulica a cesta"},
{"code":"Y01.5","desc":"Napadnutie zhodením z výšky - MU: Obchod a služby"},
{"code":"Y01.6","desc":"Napadnutie zhodením z výšky - MU: Priemyselné objekty"},
{"code":"Y01.7","desc":"Napadnutie zhodením z výšky - MU: Poľnohospodárske"},
{"code":"Y01.8","desc":"Napadnutie zhodením z výšky - MU: Iné špecifikované miesta"},
{"code":"Y01.9","desc":"Napadnutie zhodením z výšky - MU: Nešpecifikované miesto"},
{"code":"Y02.0","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Domov"},
{"code":"Y02.1","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Kolektívne ústavné zariadenia"},
{"code":"Y02.2","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y02.3","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Športové a atletické priestranstvo"},
{"code":"Y02.4","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Ulica a cesta"},
{"code":"Y02.5","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Obchod a služby"},
{"code":"Y02.6","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Priemyselné objekty"},
{"code":"Y02.7","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Poľnohospodárske"},
{"code":"Y02.8","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Iné špecifikované miesta"},
{"code":"Y02.9","desc":"Napadnutie sotením alebo položením obete pred pohybujúci sa predmet - MU: Nešpecifikované miesto"},
{"code":"Y03.0","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Domov"},
{"code":"Y03.1","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y03.2","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y03.3","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Športové a atletické priestranstvo"},
{"code":"Y03.4","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Ulica a cesta"},
{"code":"Y03.5","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Obchod a služby"},
{"code":"Y03.6","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Priemyselné objekty"},
{"code":"Y03.7","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Poľnohospodárske"},
{"code":"Y03.8","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Iné špecifikované miesta"},
{"code":"Y03.9","desc":"Napadnutie zámerným havarovaním s motorovým vozidlom - MU: Nešpecifikované miesto"},
{"code":"Y04.0","desc":"Napadnutie s použitím fyzickej sily - MU: Domov"},
{"code":"Y04.1","desc":"Napadnutie s použitím fyzickej sily - MU: Kolektívne ústavné zariadenia"},
{"code":"Y04.2","desc":"Napadnutie s použitím fyzickej sily - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y04.3","desc":"Napadnutie s použitím fyzickej sily - MU: Športové a atletické priestranstvo"},
{"code":"Y04.4","desc":"Napadnutie s použitím fyzickej sily - MU: Ulica a cesta"},
{"code":"Y04.5","desc":"Napadnutie s použitím fyzickej sily - MU: Obchod a služby"},
{"code":"Y04.6","desc":"Napadnutie s použitím fyzickej sily - MU: Priemyselné objekty"},
{"code":"Y04.7","desc":"Napadnutie s použitím fyzickej sily - MU: Poľnohospodárske"},
{"code":"Y04.8","desc":"Napadnutie s použitím fyzickej sily - MU: Iné špecifikované miesta"},
{"code":"Y04.9","desc":"Napadnutie s použitím fyzickej sily - MU: Nešpecifikované miesto"},
{"code":"Y05.0","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Domov"},
{"code":"Y05.1","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Kolektívne ústavné zariadenia"},
{"code":"Y05.2","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y05.3","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Športové a atletické priestranstvo"},
{"code":"Y05.4","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Ulica a cesta"},
{"code":"Y05.5","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Obchod a služby"},
{"code":"Y05.6","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Priemyselné objekty"},
{"code":"Y05.7","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Poľnohospodárske"},
{"code":"Y05.8","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Iné špecifikované miesta"},
{"code":"Y05.9","desc":"Sexuálne napadnutie s použitím fyzickej sily - MU: Nešpecifikované miesto"},
{"code":"Y10.0","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Domov"},
{"code":"Y10.1","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Kolektívne ústavné zariadenia"},
{"code":"Y10.2","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y10.3","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Športové a atletické priestranstvo"},
{"code":"Y10.4","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Ulica a cesta"},
{"code":"Y10.5","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Obchod a služby"},
{"code":"Y10.6","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Priemyselné objekty"},
{"code":"Y10.7","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Poľnohospodárske"},
{"code":"Y10.8","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Iné špecifikované miesta"},
{"code":"Y10.9","desc":"Otrava a priotr. neopiát. analgetik;antipyretik;antireumatik.-neurčit.úmysel - MU: Nešpecifikované miesto"},
{"code":"Y11.0","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Domov"},
{"code":"Y11.1","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Kolektívne ústavné zariadenia"},
{"code":"Y11.2","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y11.3","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Športové a atletické priestranstvo"},
{"code":"Y11.4","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Ulica a cesta"},
{"code":"Y11.5","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Obchod a služby"},
{"code":"Y11.6","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Priemyselné objekty"},
{"code":"Y11.7","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Poľnohospodárske"},
{"code":"Y11.8","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Iné špecifikované miesta"},
{"code":"Y11.9","desc":"Otrava antiepilep;sedatív;hypnotik;antiparkin;psychotrop.liek.nezatr.inde - MU: Nešpecifikované miesto"},
{"code":"Y12.0","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neurčit. úmysel - MU: Domov"},
{"code":"Y12.1","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neurčit. úmysel - MU: Kolektívne ústavné zariadenia"},
{"code":"Y12.2","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neur. úmys.- MU: Školy, iné inštit. a verejné admin. budovy"},
{"code":"Y12.3","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neurčit. úmysel - MU: Športové a atletické priestranstvo"},
{"code":"Y12.4","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neurčit. úmysel - MU: Ulica a cesta"},
{"code":"Y12.5","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neurčit. úmysel - MU: Obchod a služby"},
{"code":"Y12.6","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neurčit. úmysel - MU: Priemyselné objekty"},
{"code":"Y12.7","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neurčit. úmysel - MU: Poľnohospodárske"},
{"code":"Y12.8","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neurčit. úmysel - MU: Iné špecifikované miesta"},
{"code":"Y12.9","desc":"Otrava a priotr. opiát. analgetik. a psychodysleptik.nezatried. inde-neurčit. úmysel - MU: Nešpecifikované miesto"},
{"code":"Y13.0","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Domov"},
{"code":"Y13.1","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Kolektívne ústavné zariadenia"},
{"code":"Y13.2","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y13.3","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Športové a atletické priestranstvo"},
{"code":"Y13.4","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Ulica a cesta"},
{"code":"Y13.5","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Obchod a služby"},
{"code":"Y13.6","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Priemyselné objekty"},
{"code":"Y13.7","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Poľnohospodárske"},
{"code":"Y13.8","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Iné špecifikované miesta"},
{"code":"Y13.9","desc":"Otrava a priotr. inými liečivami účinkujúcimi na autonómny nervový systém - MU: Nešpecifikované miesto"},
{"code":"Y14.0","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Domov"},
{"code":"Y14.1","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"Y14.2","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Školy, iné inštit. a verejné admin. budovy"},
{"code":"Y14.3","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Športové a atletické priestranstvo"},
{"code":"Y14.4","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Ulica a cesta"},
{"code":"Y14.5","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Obchod a služby"},
{"code":"Y14.6","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Priemyselné objekty"},
{"code":"Y14.7","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Poľnohospodárske"},
{"code":"Y14.8","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Iné špecifikované miesta"},
{"code":"Y14.9","desc":"Otrava a priotr. inými a bližšie neurčenými liečivami, liekmi a biolog. látkami - MU: Nešpecifikované miesto"},
{"code":"Y15.0","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Domov"},
{"code":"Y15.1","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y15.2","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y15.3","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y15.4","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y15.5","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y15.6","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y15.7","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y15.8","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y15.9","desc":"Otrava a priotrávenie alkoholom s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y16.0","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Domov"},
{"code":"Y16.1","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Kolektívne ústavné zariadenia"},
{"code":"Y16.2","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Školy, iné inštit. a verejné admin. budovy"},
{"code":"Y16.3","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Športové a atletické priestranstvo"},
{"code":"Y16.4","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Ulica a cesta"},
{"code":"Y16.5","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Obchod a služby"},
{"code":"Y16.6","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Priemyselné objekty"},
{"code":"Y16.7","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Poľnohospodárske"},
{"code":"Y16.8","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Iné špecifikované miesta"},
{"code":"Y16.9","desc":"Otrava a priotr. organic. rozpúšťadl., halogenovanými uhľovodíkmi a ich výparmi - MU: Nešpecifikované miesto"},
{"code":"Y17.0","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Domov"},
{"code":"Y17.1","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y17.2","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y17.3","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y17.4","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y17.5","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y17.6","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y17.7","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y17.8","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y17.9","desc":"Otrava a priotrávenie inými plynmi a výparmi s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y18.0","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Domov"},
{"code":"Y18.1","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y18.2","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y18.3","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y18.4","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y18.5","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y18.6","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y18.7","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y18.8","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y18.9","desc":"Otrava a priotrávenie pesticídmi s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y19.0","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Domov"},
{"code":"Y19.1","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Kolektívne ústavné zariadenia"},
{"code":"Y19.2","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Školy, iné inštit. a verejné admin. budovy"},
{"code":"Y19.3","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Športové a atletické priestranstvo"},
{"code":"Y19.4","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Ulica a cesta"},
{"code":"Y19.5","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Obchod a služby"},
{"code":"Y19.6","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Priemyselné objekty"},
{"code":"Y19.7","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Poľnohospodárske"},
{"code":"Y19.8","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Iné špecifikované miesta"},
{"code":"Y19.9","desc":"Otrava a priotr. inými a bližšie neurčenými chemikáliami a škodlivými látkami - MU: Nešpecifikované miesto"},
{"code":"Y20.0","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Domov"},
{"code":"Y20.1","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y20.2","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y20.3","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y20.4","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y20.5","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y20.6","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y20.7","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y20.8","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y20.9","desc":"Obesenie, zaškrtenie a zadusenie s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y21.0","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Domov"},
{"code":"Y21.1","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y21.2","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y21.3","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y21.4","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y21.5","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y21.6","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y21.7","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y21.8","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y21.9","desc":"Utopenie a topenie s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y22.0","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Domov"},
{"code":"Y22.1","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y22.2","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y22.3","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y22.4","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y22.5","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y22.6","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y22.7","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y22.8","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y22.9","desc":"Výstrel z ručnej zbrane s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y23.0","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Domov"},
{"code":"Y23.1","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y23.2","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y23.3","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y23.4","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y23.5","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y23.6","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y23.7","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y23.8","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y23.9","desc":"Výstrel z guľovnice, brokovnice a dlhej strelnej zbrane s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y24.0","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Domov"},
{"code":"Y24.1","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y24.2","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y24.3","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y24.4","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y24.5","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y24.6","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y24.7","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y24.8","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y24.9","desc":"Výstrel z inej a bližšie neurčenej strelnej zbrane s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y25.0","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Domov"},
{"code":"Y25.1","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y25.2","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y25.3","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y25.4","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y25.5","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y25.6","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y25.7","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y25.8","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y25.9","desc":"Účinok výbušnej látky s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y26.0","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Domov"},
{"code":"Y26.1","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y26.2","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y26.3","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y26.4","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y26.5","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y26.6","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y26.7","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y26.8","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y26.9","desc":"Poškodenie dymom, požiarom a plameňmi s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y27.0","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Domov"},
{"code":"Y27.1","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y27.2","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y27.3","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y27.4","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y27.5","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y27.6","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y27.7","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y27.8","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y27.9","desc":"Popálenie parou, horúcimi výparmi a horúcimi predmetmi s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y28.0","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Domov"},
{"code":"Y28.1","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y28.2","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y28.3","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y28.4","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y28.5","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y28.6","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y28.7","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y28.8","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y28.9","desc":"Poranenie ostrým predmetom s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y29.0","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Domov"},
{"code":"Y29.1","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y29.2","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y29.3","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y29.4","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y29.5","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y29.6","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y29.7","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y29.8","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y29.9","desc":"Poranenie tupým predmetom s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y30.0","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Domov"},
{"code":"Y30.1","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y30.2","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y30.3","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y30.4","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y30.5","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y30.6","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y30.7","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y30.8","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y30.9","desc":"Pád, skok alebo sotenie z výšky s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y31.0","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Domov"},
{"code":"Y31.1","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y31.2","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y31.3","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y31.4","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y31.5","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y31.6","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y31.7","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y31.8","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y31.9","desc":"Pád, ľahnutie alebo vbehnutie pred pohybujúci sa predmet s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y32.0","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Domov"},
{"code":"Y32.1","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y32.2","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y32.3","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y32.4","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y32.5","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y32.6","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y32.7","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y32.8","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y32.9","desc":"Havarovanie s motorovým vozidlom s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y33.0","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Domov"},
{"code":"Y33.1","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y33.2","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y33.3","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y33.4","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y33.5","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y33.6","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y33.7","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y33.8","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Iné špecifikované miesta"},
{"code":"Y33.9","desc":"Iné bližšie určené udalosti s neurčeným úmyslom - MU: Nešpecifikované miesto"},
{"code":"Y34.0","desc":"Bližšie neurčená udalosť s neurčeným úmyslom - MU: Domov"},
{"code":"Y34.1","desc":"Bližšie neurčená udalosť s neurčeným úmyslom - MU: Kolektívne ústavné zariadenia"},
{"code":"Y34.2","desc":"Bližšie neurčená udalosť s neurčeným úmyslom - MU: Školy, iné inštit. a verejné administ. budovy"},
{"code":"Y34.3","desc":"Bližšie neurčená udalosť s neurčeným úmyslom - MU: Športové a atletické priestranstvo"},
{"code":"Y34.4","desc":"Bližšie neurčená udalosť s neurčeným úmyslom - MU: Ulica a cesta"},
{"code":"Y34.5","desc":"Bližšie neurčená udalosť s neurčeným úmyslom - MU: Obchod a služby"},
{"code":"Y34.6","desc":"Bližšie neurčená udalosť s neurčeným úmyslom - MU: Priemyselné objekty"},
{"code":"Y34.7","desc":"Bližšie neurčená udalosť s neurčeným úmyslom - MU: Poľnohospodárske"},
{"code":"Y34.8","desc":"Bližšie neurčená udalosť s neurčeným úmyslom - MU: Iné špecifikované miesta"}
]

export default mkch10;