import React, { useEffect, useState } from 'react';
import Reacttooltip from 'react-tooltip';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/sk';
import './form-styles/input.css'

import serverApi from '../services/serveApi'
import completeData from '../services/completeData'
import actionConstants from '../actionConstants';
import formatter from '../utils/formatter'

import { deepClone } from '../utils/cloneObj'
import * as stepValidators from '../validators/index'
import { validators } from '../validators/vzorky';
import { poistovne } from '../validators/pacient';
import validateOnEnum from '../validators/validateOnEnum';
import VzorkyModal from './Components/VzorkyModal';

const mapStateToProps = (state) => {
    return ({
        stepValid: state.validatedSteps._4,
        lekar: state.lekar,
        pacient: state.pacient,
        ziadanka: state.data_ziadanky,
        diagnozy: state.diagnozy,
        dt_odberu: state.dt_odberu,
        step4_state: state.step4_state,
        dasta: state.dasta,
        idZiadanky: state.ID_ZIADANKA,
        tlac_state: state.tlac_state,
        state,
    })
};

function Vzorky(props) {
    let idZiadanky = props.idZiadanky;
    const dispatch = props.dispatch;
    const [materialIndexToRemove, setMaterialIndexToRemove] = useState();
    const [showModalRemoveMaterial, setShowModalRemoveMaterial] = useState();

    // id must exists and be empty na vzorkach
    // nastav imutable
    useEffect(() => {
        window.scrollTo(0, 0)

        dispatch({ type: 'VZORKYEMPTYID' })
        //dispatch({ type: 'SET_VZORKY_STATE' })
    }, [dispatch]);

    const touched = {
        vzorky: !(props.step4_state.errors['vzorky'] === null),
        udaje: !(props.step4_state.errors['udaje'] === null),
        allerrs: !(props.step4_state.errors['allerrs'] === null),
    }

    //@colltype String [material,udaje]
    //pouzijem validate v change eventoch, blur nefunguje jak by sme chceli
    const validate = (colltype, collection = []) => {
        const target = colltype
        let retVal = { stepValid: props.stepValid, errVal: { ...props.step4_state.errors } };
        // var stepValid = props.stepValid;
        // const tval = target==="vzorky" ?  {vzorky: props.ziadanka.vzorky} : (target==="udaje" ? { udaje: props.ziadanka.udaje } : null)
        const tval = collection

        if (validators.hasOwnProperty(target)) {
            retVal.errVal[target] = [];
            validators[target].filter(val => val.validateOn <= validateOnEnum.CHANGE).forEach(validator => {
                if (validator.validate(tval) === false) retVal.errVal[target].push(validator.invalidMsg);
            });
            if (retVal.errVal[target].length > 0) retVal.stepValid = false;

            // props.dispatch({ type : 'SET_VZORKY_STATE', payload : { ...props,validatedSteps:{_4:stepValid} , step4_state:{...props.step4_state, errors:{...props.step4_state.errors,[target]:errVal[target]} }}} );
        }
        return retVal;
    }

    const validateAll = () => {
        let stepErrors = { ...props.step4_state.errors };
        let stepValid = true;
        let ziadanka = { ...{ vzorky: [] }, ...props.ziadanka };
        stepValid = stepValidators.validators.vzorkyValidator(ziadanka, stepErrors, stepValidators.validators.validateOnEnum.FORM);
        stepErrors.allerrs = [];
        //3
        //1.dtodberu
        let err = props.dt_odberu.errors;
        stepValid = stepValidators.validators.dtOdberuValidator(props.dt_odberu, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
        addErrorsFromObj(stepErrors.allerrs, err);
        //2.diagnozy
        err = props.diagnozy.errors;
        stepValid = stepValidators.validators.diagnozyValidator(props.diagnozy, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
        addErrorsFromObj(stepErrors.allerrs, err);
        //3.ziadanka
        err = props.ziadankaErrors;
        stepValid = stepValidators.validators.ziadankaValidator(ziadanka, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
        addErrorsFromObj(stepErrors.allerrs, err);
        //2
        err = props.pacient.errors;
        stepValid = stepValidators.validators.pacientValidator(props.pacient, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
        addErrorsFromObj(stepErrors.allerrs, err);
        //1
        err = props.lekar.errors;
        stepValid = stepValidators.validators.lekarValidator(props.lekar, err, stepValidators.validators.validateOnEnum.FORM) && stepValid;
        addErrorsFromObj(stepErrors.allerrs, err);

        function addErrorsFromObj(addTo, obj = {}) {
            for (const prop in obj) {
                if (obj.hasOwnProperty(prop) && obj[prop] instanceof Array) {
                    obj[prop].forEach(element => {
                        addTo.push(element);
                    });
                }
            }
        }

        //if( stepValid===false) nech zapise aktulany stav vzdy
        props.dispatch({ type: actionConstants.SET_VALIDATION_ERRORS, payload: stepErrors });
        return stepValid;
    }

    const zmazOznacenieVzoriek = () => {
        props.ziadanka && (props.ziadanka['vzorky'] instanceof Array) && props.ziadanka.vzorky.forEach((x, index) => {
            x.id = '';
        })
    }

    const getIdZiadanky = async () => {
        try {
            const id = await serverApi.getNextZiadankaId();
            return `EMDX${new Date().getFullYear().toString().slice(-2).padStart(2, '0')}${(new Date().getMonth() + 1).toString().padStart(2, '0')}${(new Date().getDate()).toString().padStart(2, '0')}${id.toString().padStart(4, '0')}`;
        } catch (err) {
            props.dispatch({
                type: actionConstants.ERROR,
                payload: { ...props.dasta, message: 'Nepodarilo sa získať unikátne ID žiadanky, skúste neskôr prosím' },
            });
        }
    }

    const getIdVzorky = async (byIndex = 0) => {
        idZiadanky = props.idZiadanky;
        if (idZiadanky === null) {
            idZiadanky = await getIdZiadanky();
            return `${idZiadanky}-${byIndex + 1}`;
        } else return `${idZiadanky}-${byIndex + 1}`;
    }

    const precislujVzorky = async (vzorky = []) => {
        props.step4_state.bez_oznacenia === false && vzorky.forEach(async (x, index) => {
            const vzorkaId = await getIdVzorky(index);
            x.id = x.id.length > 0 ? `${vzorkaId}` : '';
        });
    }

    const handleCheckBezOznacenia = (event) => {
        const target = event.target;
        const val = target.type === 'checkbox' ? target.checked : target.value;
        const out = val > 0 ? true : false;
        zmazOznacenieVzoriek();
        idZiadanky = null;
        props.dispatch({
            type: 'SET_VZORKY_STATE',
            payload: { ...props, ID_ZIADANKA: idZiadanky, step4_state: { ...props.step4_state, bez_oznacenia: out } },
        });
    }

    const handleChange = async (event, index) => {
        const value = event.target.type === 'checkbox' ? (event.target.checked ? `${await getIdVzorky(index)}` : '') : event.target.value
        if (props.step4_state.bez_oznacenia && value !== null && value.length > 0) {
            // PaB @210623 #335 -->
            var kc = 0;
            value.substr(0, 7).split('').forEach((itm, idx) => {
                let cd = itm.charCodeAt(0);
                kc += ((48 <= cd && cd <= 57) ? itm : cd) // 0[48] 1[49] .. 9[57]
                    * ((idx % 2) === 1 ? 1 : 3);
            });
            kc = (kc % 10).toString();
            idZiadanky = value.toString().replace(/.$/, kc + '0');
            // PaB @210623 #335 <--
        }
        let cloneVzorky = props.ziadanka.vzorky.slice()
        cloneVzorky = cloneVzorky.map((x, i) => i !== index ? x : { ...x, id: value });
        let valid = validate('vzorky', cloneVzorky);
        props.dispatch({
            type: 'SETVZORKAID',
            payload: {
                ID_ZIADANKA: idZiadanky,
                vzorky: cloneVzorky,
                validatedSteps: { _4: valid.stepValid },
                errors: { ...props.step4_state.errors, vzorky: valid.errVal.vzorky },
            },
        });
    }

    const handleChangeUdaje = (event, index) => {
        const value = event.target.type === 'checkbox' ? (event.target.checked ? '0' : '') : event.target.value
        let cloneUdaje = props.ziadanka.udaje.slice()
        cloneUdaje = cloneUdaje.map((x, i) => i !== index ? x : { ...x, cislo: value });
        let valid = validate('udaje', cloneUdaje);
        props.dispatch({
            type: 'SETUDAJE',
            payload: {
                udaje: cloneUdaje,
                validatedSteps: { _4: valid.stepValid },
                errors: { ...props.step4_state.errors, udaje: valid.errVal.udaje },
            },
        });
    }

    const handlePridaj = async (event, index) => {
        event.preventDefault();

        let cloneVzorky = props.ziadanka.vzorky.slice(0, index + 1)
        cloneVzorky.push({
            id: '',
            material: { ...props.ziadanka.vzorky[index].material, imutable: false, isNew: true },
        })
        cloneVzorky = cloneVzorky.concat(props.ziadanka.vzorky.slice(index + 1, props.ziadanka.vzorky.length + 1))
        await precislujVzorky(cloneVzorky);
        //aby mohla prejst validacia a v reduceri nebola logika
        let valid = validate('vzorky', cloneVzorky);
        props.dispatch({
            type: 'VZORKYPRIDAJ',
            payload: {
                ID_ZIADANKA: idZiadanky,
                vzorky: cloneVzorky,
                validatedSteps: { _4: valid.stepValid },
                errors: { ...props.step4_state.errors, vzorky: valid.errVal.vzorky },
            },
        })
        dispatch({ type: 'VZORKYEMPTYID' })
    }

    const handleKeyPress = (event) => {
        let thisNum = event.target.getAttribute('name').split('-');
        let targetItem = parseInt(thisNum[0]) + 1;
        let items = document.querySelectorAll('.material-input');

        if (event.key === 'Enter') {
            event.preventDefault();

            for (let elem of items) {
                let thisItemName = elem.getAttribute('name');

                if (thisItemName === targetItem + '-id') {
                    elem.focus();
                }
            }
        }
    }

    const closeModalMaterialToRemove = (event) => {
        if (event) {
            event.preventDefault();
        }

        setShowModalRemoveMaterial(false);
    }

    const confirmUber = (event, index) => {
        event.preventDefault();

        if (typeof index !== 'number' || index === -1) {
            return;
        }

        const { isNew } = props.ziadanka.vzorky[index]?.material;

        if (isNew) {
            handleUber(event, index)

            return;
        }

        setShowModalRemoveMaterial(true);
        setMaterialIndexToRemove(index);
    }

    const handleUber = (event, index) => {
        event.preventDefault();
        let cloneVzorky = props.ziadanka.vzorky.slice(0, index)
        cloneVzorky = cloneVzorky.concat(props.ziadanka.vzorky.slice(index + 1, props.ziadanka.vzorky.length))
        precislujVzorky(cloneVzorky);

        let valid = validate('vzorky', cloneVzorky);

        props.dispatch({
            type: 'VZORKYUBER',
            payload: {
                vzorky: cloneVzorky,
                validatedSteps: {
                    _4: valid.stepValid,
                },
                errors: {
                    ...props.step4_state.errors,
                    vzorky: valid.errVal.vzorky,
                },
            },
        })
        dispatch({ type: 'VZORKYEMPTYID' })
        closeModalMaterialToRemove();
    }

    const handleGetPdf = (typ) => async (event) => {
        event.preventDefault();
        if (!validateAll()) return;
        try {
            let cpdata = completeData.getPdfData(deepClone(props));
            cpdata.printBackground = false
            if (typ === '1') cpdata.printBackground = true
            let wnd = window.open('tlac.html'); // PaB @210527 #241 ..window.open("about:blank", "window")
            await serverApi.topdf(cpdata, wnd);
            wnd.document.title = props.pacient.priezvisko + ' ' + props.pacient.meno +
                ' (žiadanka ' + props.idZiadanky + ')';

            let pl = { ...props, tlac_state: { vytlacil: true, errors: { vytlacil: [] } } };
            props.dispatch({ type: 'SET_TLAC_STATE', payload: pl });
            //odstrani chybu ze nevytlacil z allerrs
            validateAll();
            props.dispatch(success('OK'));
        } catch (error) {
            // alert("Nepodarilo sa vytvoriť tlačovú zostavu.\r\n[ " + error.toString() + " ]")
            props.dispatch(success('Nepodarilo sa vytvoriť tlačovú zostavu.\r\n[ ' + error.toString() + ' ]'));
            console.log(error.toString());
        }

        function success(message) {
            return { type: actionConstants.ERROR, payload: { ...props.dasta, message: message } }
        }
    }

    const dt_odberu = formatter.formatDT_SK(moment(props.dt_odberu.dt))
    const unique = props.ziadanka && (props.ziadanka.poziadavky instanceof Array) ? [...new Set(props.ziadanka.poziadavky.map(y => y.odbornost))] : []

    return (
        <div className="box">
            <form>
                <h3>Tlač žiadanky a priradenie vzoriek</h3>

                <div className="form-group">
                    <div className="row">
                        <div className="col-12">
                            <label htmlFor="props.pacient">
                                <span className="validation-icon"></span>
                                <b>Pacient&nbsp;*</b>
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="priezvisko">
                                <span className="validation-icon"></span>
                                Priezvisko&nbsp;*
                            </label>
                            <div className="input-box">
                                <input readOnly className="input" name="props.pacient.priezvisko" type="textbox"
                                       value={props.pacient.priezvisko}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="meno">
                                <span className="validation-icon"></span>
                                Meno&nbsp;*
                            </label>
                            <div className="input-box">
                                <input readOnly className="input" name="props.pacient.meno" type="textbox"
                                       value={props.pacient.meno}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="rc">
                                <span className="validation-icon"></span>
                                Rodné číslo&nbsp;*
                            </label>
                            <div className="input-box">
                                <input readOnly className="input" name="props.pacient.rc" type="textbox"
                                       value={formatter.formatRC(props.pacient.rc)}/>
                            </div>
                        </div>


                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="poisKod">
                                <span className="validation-icon"></span>
                                Zdravotná poisťovňa&nbsp;*
                            </label>
                            <div className="input-box">
                                <input readOnly className="input" name="props.pacient.poisKod" type="textbox"
                                       value={props.pacient.poisKod && (poistovne.find(x => x.value === props.pacient.poisKod))['label']}/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="space-30"></div>

                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="Vysetrenia"><b>Vyšetrenia</b></label>
                        </div>
                    </div>

                    <div className="row">
                        {unique.map((id, index) =>
                            <React.Fragment key={'unique-fragment-' + index}>
                                <div className="space-10"></div>
                                <div key={'unique-' + index} className="col-sm-6 col-xs-12">
                                    <label className="xxs"><b>{id}</b></label>
                                </div>
                                <div className="space-10"></div>
                                <div className="container">
                                    <div className="row">
                                        {props.ziadanka && (props.ziadanka['poziadavky'] instanceof Array) && props.ziadanka.poziadavky.filter(y => y.odbornost === id).map((x, index) =>
                                            <div key={'poziadavky' + index} className="col-sm-4 col-xs-12">
                                                <input key={'poziadavka' + index} readOnly className="input cBlk"
                                                       value={x.nclpKod + ' - ' + x.nazov}></input>
                                            </div>,
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>,
                        )}
                    </div>

                    <div className="row">
                        <div className="space-10"></div>

                        {props.ziadanka && props.ziadanka.textLekara && props.ziadanka.textLekara.length > 0 &&
                            <div className="col-sm-12 col-xs-12">
                                <label htmlFor="textLekara">
                                    <span className="validation-icon"></span>
                                    Poznámka lekára&nbsp;*
                                </label>
                                <div className="input-box">
                                    <textarea readOnly className="textarea bgcTB" name="props.ziadanka.textlekara"
                                              value={props.ziadanka ? props.ziadanka.textLekara : ''}/>
                                </div>
                            </div>
                        }

                        <div className="col-sm-3 col-xs-6">
                            <label htmlFor="dt_odberu">
                                Dátum a čas odberu vzoriek&nbsp;*
                            </label>
                            <div>
                                <input readOnly className="input" name="props.dt_odberu.dt" type="textbox"
                                       value={dt_odberu}/>
                                {'\n'}
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-sm-12 col-xs-12">
                            <label htmlFor="bez_oznacenia">
                                Vyberte si spôsob tlače žiadanky
                            </label>
                            <label className="custom-radio">
                                <input name="bez_oznacenia" type="radio" value="1"
                                       checked={props.step4_state.bez_oznacenia} onChange={handleCheckBezOznacenia}/>
                                <span className="pl15px">Tlač na predtlačenú žiadanku od Medirexu</span>
                            </label>
                        </div>

                        <div className="col-sm-12 col-xs-12">
                            <label className="custom-radio">
                                <input name="bez_oznacenia" type="radio" value="0"
                                       checked={!props.step4_state.bez_oznacenia} onChange={handleCheckBezOznacenia}/>
                                <span className="pl15px">Tlač na prázdny papier A4</span>
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="space-30"></div>
                        <div className="col-sm-12 col-xs-12">
                            <div>
                                <p className="oznac-vzor">
                                    OZNAČOVANIE VZORIEK</p>
                            </div>
                            <div>
                                <p className="oznac-vzor-just">Skúmavky s biologickými vzorkami označte pred odoslaním
                                    do laboratórií štítkom.
                                    Ak máte našu UNI žiadanku, použite štítky z jej spodnej časti. Vyplňte prosím meno,
                                    priezvisko a rodné číslo pacienta a nalepte na skúmavku.
                                    Ak nemáte našu žiadanku, prosím, napíšte meno, priezvisko a rodné číslo na čistý
                                    štítok a nalepte na skúmavku.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {props.ziadanka && (props.ziadanka['vzorky'] instanceof Array) && props.ziadanka.vzorky.length > 0 &&
                            <div className="col-12">
                                <label htmlFor="Material">
                                    <span
                                        className={'validation-icon ' + (touched.vzorky && props.step4_state.errors['vzorky']?.length > 0 ? 'is-invalid' : touched.vzorky ? 'valid' : '')}></span>
                                    <b>Materiál</b></label>
                            </div>}
                        <div className="col-sm-12">
                            <ul className="pl-0">
                                {props.ziadanka && (props.ziadanka['vzorky'] instanceof Array) && props.ziadanka.vzorky.map((x, index) =>
                                    <li key={index + ' - vzorka'} className="row">
                                        <div hidden={true}>
                                            <input hidden={true} readOnly className="input" name={index + '-nclp'}
                                                   type="textbox" value={x.material.nclpKod}/>
                                        </div>
                                        <div className="col-sm-3">
                                            <input readOnly className="input" name={index + '-nazov'} type="textbox"
                                                   value={x.material.nazov}/>
                                        </div>
                                        <div className="col-sm-3">
                                            <input readOnly data-tip data-for={x.material.id}
                                                   className="input input-vysetrenia" name={index + '-vysetrenia'}
                                                   type="textbox"
                                                   value={props.ziadanka.poziadavky.filter(y => y.material && y.material.toString() === x.material.id).map((y, i) => y.nazov).join(', ')}/>
                                            {/* <input readOnly data-tip data-for={x.material.id} className="input" name={index + "-vysetrenia"} type="textbox" styl={{textAlign:"center"}}
                                                value={ (t =>  t > 1 ?
                                                                    (t > 4 ? `${t} vyšetrení` : `${t} vyšetrenia` )
                                                                    : '1 vyšetrenie'
                                                    )(props.ziadanka.poziadavky.filter(y=> y.material && y.material.toString() === x.material.id).length)
                                                } /> */}
                                        </div>
                                        <Reacttooltip id={x.material.id}>
                                            {props.ziadanka.poziadavky.filter(y => y.material && y.material.toString() === x.material.id).map((y, i) =>
                                                <p key={i}>{y.nazov}</p>)}
                                        </Reacttooltip>

                                        {(props.step4_state.bez_oznacenia) &&
                                            <div className="col-sm-3">
                                                <input className="input material-input" name={index + '-id'}
                                                       type="textbox" value={x.id} placeholder="zadajte číslo skúmavky"
                                                       onKeyPress={handleKeyPress}
                                                       onChange={event => handleChange(event, index)}/>
                                            </div>
                                        }
                                        {!(props.step4_state.bez_oznacenia) &&

                                            <div className="col-1">
                                                {/*}   <label className="inline-checkbox" styl={ {position: "absolute", top: 0, bottom: 0, left: "25px", right: 0,marginBottom: "0px",margin: 0}}>  */}
                                                <label className="inline-checkbox">
                                                    {/* <input  name={index + "-check"} type="checkbox" checked={(x.id!==null&&x.id?.length>0 ? true : false)} onChange={event => handleChange(event, index)} onBlur={validate("vzorky")} /> */}
                                                    <input name={index + '-check'} type="checkbox"
                                                           checked={(x.id && x.id !== null && x.id.length > 0 ? true : false)}
                                                           onChange={event => handleChange(event, index)}/>
                                                    <span className="checkmark"></span>
                                                    <span className="text"></span>
                                                </label>
                                            </div>
                                        }
                                        <div className="col-sm-2 mb-1">
                                            <button className="button--dark"
                                                    color="primary"
                                                    onClick={event => handlePridaj(event, index)}
                                            > +
                                            </button>
                                        </div>

                                        {!x.material.imutable &&
                                            <div className="col-sm-1 mb-1">
                                                <button className="button--dark"
                                                        color="primary"
                                                        onClick={event => confirmUber(event, index)}
                                                > -
                                                </button>
                                            </div>
                                        }
                                    </li>,
                                )}
                            </ul>
                            <div className="row-dummy"></div>
                            <div className="form-error">
                                {touched.vzorky && props.step4_state.errors['vzorky'].length > 0 && props.step4_state.errors['vzorky'].map((e, i) =>
                                    <span key={i}>{e}</span>)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="space-10"></div>
                        {props.ziadanka && (props.ziadanka['udaje'] instanceof Array) && props.ziadanka.udaje.length > 0 &&
                            <div className="col-12">
                                <label htmlFor="Klinicke udaje">
                                    <span
                                        className={'validation-icon ' + (touched.udaje && props.step4_state.errors['udaje'].length > 0 ? 'is-invalid' : touched.udaje ? 'valid' : '')}></span>
                                    <b>Klinické údaje</b>
                                </label>
                            </div>}
                        <div className="col-sm-12 col-xs-12">
                            <ul className="pl-0">
                                {props.ziadanka && (props.ziadanka['udaje'] instanceof Array) && props.ziadanka.udaje.map((x, index) =>
                                    <li key={index + ' - vzorka'} className="row">
                                        <div hidden={true}>
                                            <input hidden={true} readOnly className="input" name={index + '-nclp'}
                                                   type="textbox" value={x.nclpKod}/>
                                        </div>
                                        <div className="col-sm-3 col-xs-6 test">
                                            <div className="input-name-readonly">{x.nazov}</div>
                                            <input readOnly className="input input-hidden-parameter"
                                                   name={index + '-nazov'} type="textbox" value={x.nazov}/>
                                        </div>
                                        <div className="col-sm-3 col-xs-4">
                                            {(x.mj === 'vľavo/vpravo') &&
                                                <select className="input input-select" name={index + '-id'}
                                                        onChange={event => handleChangeUdaje(event, index)}>
                                                    <option value="">Vyberte hodnotu</option>
                                                    <option value="vlavo">Vľavo</option>
                                                    <option value="vpravo">Vpravo</option>
                                                    <option value="vlavo/vpravo">Vľavo/Vpravo</option>
                                                </select>
                                            }

                                            {
                                                (x.mj === 'áno/nie') &&
                                                <select className="input input-select" name={index + '-id'}
                                                        onChange={event => handleChangeUdaje(event, index)}>
                                                    <option value="">Vyberte hodnotu</option>
                                                    <option value="ano">Áno</option>
                                                    <option value="nie">Nie</option>
                                                </select>
                                            }

                                            {
                                                (x.mj !== 'vľavo/vpravo' && x.mj !== 'áno/nie') &&
                                                <input className="input" name={index + '-id'} placeholder={x.mj}
                                                       type="textbox" value={x.cislo || ''}
                                                       onChange={event => handleChangeUdaje(event, index)}
                                                />
                                            }
                                        </div>
                                        <div className="col-sm-2 col-xs-4">
                                            <input readOnly className="input" name={index + '-nazov'} type="textbox"
                                                   value={x.mj}/>
                                        </div>

                                        <div className="col-sm-3 col-xs-6 mb-1">
                                            <input readOnly data-tip data-for={x.nclpKod}
                                                   className="input input-vysetrenia" name={index + '-vysetrenia'}
                                                   type="textbox"
                                                   value={props.ziadanka.poziadavky.filter(y => x.vysetrenia.indexOf(y.nclpKod) >= 0).map((y, i) => y.nazov).join(', ')}/>
                                            {/* <input readOnly data-tip data-for={x.nclpKod} className="input" name={index + "-vysetrenia"} type="textbox" styl={{textAlign:"center"}}
                                                value={ x.vysetrenia.length > 1 ?
                                                            (x.vysetrenia.length  > 4 ? `${x.vysetrenia.length} vyšetrení` : `${x.vysetrenia.length} vyšetrenia` )
                                                            : '1 vyšetrenie'} /> */}
                                        </div>
                                        <Reacttooltip id={x.nclpKod}>
                                            {props.ziadanka.poziadavky.filter(y => x.vysetrenia.indexOf(y.nclpKod) >= 0).map((y, i) =>
                                                <p key={i}>{y.nazov}</p>)}
                                        </Reacttooltip>

                                    </li>,
                                )}
                            </ul>
                            <div className="form-error">
                                {touched.udaje && props.step4_state.errors['udaje']?.length > 0 && props.step4_state.errors['udaje'].map((e, i) =>
                                    <span key={i}>{e}</span>)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {(props.dasta.message !== 'OK') && (props.activeStep === 5) &&
                            <div className="form-error">
                                <center><h4>{props.dasta.message}</h4></center>
                                <p>&nbsp;</p></div>
                        }
                        <div className="form-error col-sm-12 col-xs-12">
                            {props.step4_state.errors && props.step4_state.errors['allerrs']?.length > 0 && props.step4_state.errors['allerrs'].map((e, i) =>
                                <span key={i}>{e}</span>)}
                        </div>
                        <div className="col-lg-12">
                            <button className="button--white mb-2"
                                    onClick={handleGetPdf(props.step4_state.bez_oznacenia ? '0' : '1')}>Vytlačiť
                                žiadanku
                            </button>
                            <p className="text-center p10px s">Ak už máte vytlačenú žiadanku, pokračujte na ďalší
                                krok</p>
                        </div>
                        <div className="form-error align-left col-sm-12 col-xs-12">
                            <span>{(!props.stepValid && ((touched.vzorky && props.step4_state.errors.vzorky.length > 0) || (touched.udaje && props.step4_state.errors.udaje.length > 0)) ? 'Niektoré polia nie sú vyplnené alebo sú vyplnené chybným spôsobom. Prosím, skontrolujte si ich.' : '')}</span>
                        </div>
                    </div>
                </div>
            </form>

            <VzorkyModal
                model={showModalRemoveMaterial}
                close={closeModalMaterialToRemove}
            >
                <div className="text-center">
                    <h2 className="vzorky-modal__title sf-mb-4">
                        <b>Upozornenie</b>
                    </h2>

                    <div className="vzorky-modal__content sf-mb-4">
                        <div className="mb-4">
                            Skontrolujte si prosím množstvo odobratého materiálu
                            na požadovaný počet vyšetrení.<br/> V prípade nedostatočného
                            množstva materiálu nebude možné vyšetrenia v
                            laboratóriu zrealizovať.
                        </div>

                        <div>
                            Naozaj chcete odberový materiál odstrániť?
                        </div>
                    </div>

                    <div className="d-flex align-center justify-content-center">
                        <button
                            className="button--border sf-mr-2"
                            onClick={event => handleUber(event, materialIndexToRemove)}
                        >
                            Ano
                        </button>
                        <button
                            className="button--dark"
                            onClick={closeModalMaterialToRemove}
                        >
                            Nie
                        </button>
                    </div>
                </div>
            </VzorkyModal>
        </div>
    );
}

export default connect(mapStateToProps)(Vzorky);
