import React, { useState } from 'react';
//import ReactTags from 'react-tag-autocomplete';
import { connect } from 'react-redux';
import actionConstants from '../actionConstants';
// import apiService from '../services/serveApi';

const mapStateToProps = (state) => {
  return ({ user: state.user, lekar: state.lekar, loggedIn: state.loggedIn })
};


function ActiveUser(props) {

  const [is_visible, setVisible] = useState(false)
  //const dispatch = props.dispatch;

  const handleLogOut = () => {
    let msg = `Lekár ${props.lekar.meno} bol úspešne odhlásený`;
    props.dispatch({ type: actionConstants.TOKEN_FAILURE, payload: { ...props.user, password: '', errors: { ...props.user.errors, common: msg } } });
    setVisible(false);
    // vraciam spat verziu bez update profilu->vytvorene sablony su ukladane k profilu automaticky
    // let msg = `Lekár ${props.lekar.meno} bol úspešne odhlásený`;
    // apiService.updateProfilData(props.user.accessToken, props.lekar).then(()=>{
    //     props.dispatch(failure(msg));
    // }
    // ).catch((error)=> {
    //     props.dispatch(failure(msg));  //error.toString()
    // });
    // setVisible(false);
    // function failure(error) { return { type: actionConstants.TOKEN_FAILURE, payload: { ...props.user, password: '', errors: { ...props.user.errors, common: error } } } }
  }

  return (
    <div>
    <div className="header--menu--right">
        <nav className="nav-main">
            <ul className="ul-main ul-menu-actions">
                <li>                                  
                    {/* <div className="logged-in-user-info" styl={ props.loggedIn ? {} : {"display":"none"}} onMouseDown = {  () => setVisible(true)}> */}
                    <div className={`logged-in-user-info${props.loggedIn ? '' : ' skry'}`} onMouseDown = {  () => setVisible(true)}>
                        <div className="avatar">
                        </div>
                        <div className="user-data">
                            <p className="name">
                                {props.lekar.meno}
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
   <div id="logged-in-user" className={is_visible ? "logged-in-user isActive" : "logged-in-user"}>
        <div className="hide-section">        
        </div> 

        <div className="logged-in-actions">
            <button aria-label="sidecart-close" className="sidecart--close" onClick = { () => setVisible(false) }>
                <span className="icon medirex-close-big">
                </span>
            </button> 
            <div className="user-info">
                <div className="avatar">
                    <a href="https://www.medirex.sk/pacientska-zona/profil">
                        <img src="/assets/images/avatar-muz.svg" width="58" height="58" alt="Logged in user avatar"/>
                        Logged in user avatar
                    </a>
                </div> 
                <p className="name">{props.lekar.meno}</p>
                <p className="email">{props.lekar.email}</p>
            </div> 
            {/*
            <div className="block-title">
                Môj účet
            </div> 
            */}
            <div className="user-actions">
                {/*
                <a href="https://www.medirex.sk/pacientska-zona/dashboard" className="action">
                    <span className="sub-header-icon icon-0"></span>
                    Prehľad
                </a> 
                <a href="https://www.medirex.sk/pacientska-zona/prehad-vysledkov-z-objednanych-produktov" className="action">
                    <span className="sub-header-icon icon-2"></span>
                    Výsledky vyšetrení
                </a> 
                <a href="https://www.medirex.sk/pacientska-zona/objednavky" className="action">
                    <span className="sub-header-icon icon-1"></span>
                    Moje objednávky
                </a> 
                <a href="https://www.medirex.sk/pacientska-zona/profil" className="action">
                    <span className="sub-header-icon icon-3"></span>
                    Správa profilu
                </a> 
                */}
                <button type="button" aria-label="logout-btn" className="button--dark"
                    onClick={handleLogOut }>
                    Odhlásiť sa
                </button>
            </div>
        </div>
    </div> 
  </div>
 );
}


export default connect(mapStateToProps)(ActiveUser);


