import React, { useEffect, useState } from 'react';

export default function TableComponent(props) {
    const [isEmpty, setIsEmpty] = useState(false);

    useEffect(() => {
        setIsEmpty(props.data?.length === 0)
    }, [props.data])
    return (
        <div className="table-component">
            <table className="table">
                <thead>
                <tr>
                    {props.headerData.map((item, index) => {
                        return (
                            <th
                                key={`header-item-${index}`}
                                scope="col"
                                className={item.classes}
                            >{item.label}</th>
                        )
                    })}
                </tr>
                </thead>
                <tbody>
                {(!isEmpty || !props.data) ? <>
                        {props.data?.map((row, index) => {
                            return (
                                <tr
                                    key={`row-${index}`}
                                    className={row.classes}
                                >
                                    {row.cols.map((cell, index) => {
                                        return (
                                            <td
                                                key={`cell-item-${index}`}
                                                className={cell.classes}
                                            >
                                                {props[cell.code]
                                                    ? props[cell.code](row)
                                                    : cell.label}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </> :
                    <tr>
                        <td colSpan={props.headerData?.length}>
                            <div className="d-flex justify-content-center p-2">
                                <h5>Neboli nájdené žiadne žiadanky</h5>
                            </div>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    )
}
