import validateOn from './validateOnEnum'
import moment from 'moment';

export const validators = {
	dt: [{ validateOn: validateOn.CHANGE, validate: (value) => { return ((value!=null)&&(moment.isMoment(value) || value.trim().length > 0)) }, invalidMsg: "Pole dátum a čas odberu je prázdne" }
		, {
		validateOn: validateOn.CHANGE, validate: (value) => {let m= moment.isMoment(value)?value:moment(value); return moment.isMoment(m);}, invalidMsg: "Vyplňte dátum a čas odberu vo formáte DD.MM.YYYY HH:mm"
	}, {
		validateOn: validateOn.CHANGE, validate: (value) => {
			let m= moment.isMoment(value)?value:moment(value);
			return !moment.isMoment(m) || (moment.isMoment(m)&&!(m.isAfter(moment())))
		}, invalidMsg: "Dátum odberu nesmie byť v budúcnosti"
	}]
}

export function isValid(state, stateErrors = {}, validationEvent = validateOn.FORM){
	var valid = true;
	for (const property in state) {
		 if(!validators.hasOwnProperty(property)) continue;
		 stateErrors[property] = [];
		 // eslint-disable-next-line no-loop-func
		 validators[property].filter(val => val.validateOn <= validationEvent).forEach(validator => {
			  if (validator.validate(state[property]) === false) {
					stateErrors[property].push(validator.invalidMsg);
					valid = false;
			  }
		 });
	}
	return valid;
}
