import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import userService from '../services/serveApi'
import userConstants from '../actionConstants'
import { isValid } from '../validators/user'
import { isValid as lekarValid } from '../validators/lekar'

import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
	return ({ user: state.user, validatedSteps: state.validatedSteps });
};

function Login(props) {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	const [showPwd,setShowPwd] = useState(false)

	const toggleShowPwd = () => {
		setShowPwd((!showPwd));
	}
	const formReg = () => {
		props.dispatch({ type: 'SETACTIVESTEP', payload: { activeStep: 0 } });
		props.dispatch({ type: 'SETFORM', payload: { activeForm: 'R' } });
	}
	const handleInputChange = (event) => {
		var valErr = props.user.errors
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		// valErr[name] = [];
		// validators[name].filter(val => val.validateOnChange === true).forEach(validator => { if (validator.validate(value) === false) valErr[name].push(validator.invalidMsg); }
		// )
		props.dispatch({ type: userConstants.USER_SETPROPS, payload: { ...props.user, [name]: value, errors: { ...props.user.errors, ...valErr } } });
	}

	const handleLogIn = async (event) => {
		event.preventDefault();
		var formErrors = props.user.errors;
		if (isValid(props.user, formErrors) === false) {
			props.dispatch({ type: userConstants.USER_SETPROPS, payload: { ...props.user, errors: { ...props.user.errors, ...formErrors } } })
			return;
		};
		if (props.user.email && props.user.password) {
			try {
				let user = await userService.login(props.user.email, props.user.password);
				let data = await userService.getProfil(user.accessToken);
				data.lekar.errors = {};//TODO: treba doriesit, ci sa budu zapisovat do DB aj errors
				data.validatedSteps=[];
				data.validatedSteps['_0'] = true;
				data.validatedSteps['_1'] = lekarValid(data.lekar, data.lekar.errors);
				props.dispatch(success({ user, data }));
			} catch (error) {
				props.dispatch(failure('Chyba: ' + error.message)); // PaB @210526 #233.. props.dispatch(failure(error.toString()));
			}
		}
		// function loadProfil(data) {return {type:userConstants.LAOD_DATA,payload:data}}
		function success(data) { return { type: userConstants.LOGIN_SUCCESS, payload: { logged: { ...props.user, ...data.user }, loaded: data.data, validatedSteps: data.data.validatedSteps } } }
		function failure(error) { return { type: userConstants.LOGIN_FAILURE, payload: { ...props.user, password: '', errors: { ...props.user.errors, common: error } } } }
	}
	const handleNext = () => {
		props.dispatch({ type: 'SETACTIVESTEP', payload: { activeStep: 1, validatedSteps: { ...props.validatedSteps, _0: true } } });
	}
	return (

		<div className="row">
			<div className="column col-md-6 white-bg space-right">
				<div className="box">
					<h2 className="box-headline">Prihláste sa</h2>
					<form noValidate>
						<div className="form-group">

							<div className="row">
								<div className="col-12">
									<div className="error"> <span>{props.user.errors.common ? props.user.errors.common : ''}</span> </div>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12 col-xs-12">
									<label htmlFor="email">
										<span className={"validation-icon " + (props.user.errors.email.length > 0 ? "is-invalid" : "")}></span>
											E-mail&nbsp;*
									</label>
									<div className="input-box">
										<input autoFocus={true} className={"input " + (props.user.errors.email.length > 0 ? "is-invalid" : "")} id="email" name="email" type="email" value={props.user.email} onChange={handleInputChange} />
										<div className="form-error">
											{props.user.errors['email'].map((value, i) =>
												<span key={i}>{value}</span>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12 col-xs-12">
									<label htmlFor="password">
										<span className={"validation-icon " + (props.user.errors.password.length > 0 ? "is-invalid" : "")}></span>
											Heslo&nbsp;*
										</label>
									<div className="input-box">
										<input className={"input " + (props.user.errors.password.lenth > 0 ? "is-invalid" : "")} id="password" name="password" 
										type={showPwd===false?"password":"text"} value={props.user.password} onChange={handleInputChange} />
										<span className={"icon input-action" + (showPwd===false?" medirex-eye":" medirex-eye-slash")} onClick={toggleShowPwd}></span>
										<div className="form-error">
											{props.user.errors['password'].map((value, i) =>
												<span key={i}>{value}</span>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12 text-right">
									<Link to="/forgotpsw" className="form-link">Zabudli ste heslo?</Link>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12 button-box">
									<button
										className="button--dark"
										color="primary"
										onClick={handleLogIn}>
										Prihlásiť sa
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className="col-md-6 column form-info space-left">
				<div className="box">
					<h2 className="box-headline">Zaregistrujte sa</h2> 
					<p>Potrebujete vytvárať viac žiadaniek? Nezdržujte sa opakovaným vypĺňaním tých istých údajov. Vytvorenie profilu v našom online žiadankovom systéme vám umožní:</p>
					<ul className="benefits">
						<li> rýchlejšie spracovanie online žiadaniek,</li>
						<li> uložiť vaše identifikačné údaje, ktoré sa automaticky dotiahnu do každej novej žiadanky,</li>
						<li> vytvoriť rôzne vzory žiadaniek, ktoré môžete kedykoľvek prepoužiť a upraviť podľa potreby.</li>
					</ul>
					<div className="row">
						<div className="col-sm-12 button-box">
							<button
								className="button--dark"
								color="primary"
								onClick={formReg}
							>
								Zaregistrovať sa
									</button>
						</div>
					</div>

					<div className="row">
						<div className="space-10"></div>
						<div className="col-12 text-center">- alebo -</div>
						<div className="space-10"></div>
					</div>

					<div className="row">
						<div className="col-sm-12 button-box">
							<button
								className="button--border"
								onClick={handleNext}
							>
								Pokračovať bez registrácie						</button>
						</div>
					</div>
				</div>
			</div>

		</div>

	)
}

export default connect(mapStateToProps)(Login);
