import validateOn from './validateOnEnum'

export const validators = {
    suhlas_A: [{ validateOn: validateOn.CHANGE, validate: (value) => { return  value===true; }, invalidMsg: "Vyplňte prosím súhlas o správnosti vyplnených údajov a uzatvorení zmluvy so zdravotnou poisťovňou pacienta" }],
    suhlas_B: [{ validateOn: validateOn.CHANGE, validate: (value) => { return value===true; }, invalidMsg: "Vyplňte prosím súhlas s podmienkami prepravy odobratých vzoriek" }]
}

export function isValid(state, stateErrors = {}, validationEvent = validateOn.FORM){
    var valid = true;
    for (const property in state) {
        if(!validators.hasOwnProperty(property) || state[property] === null) continue;
        stateErrors[property] = [];
        // eslint-disable-next-line no-loop-func
        validators[property].filter(val => val.validateOn <= validationEvent).forEach(validator => {
            if (validator.validate(state[property]) === false) {
                stateErrors[property].push(validator.invalidMsg);
                valid = false;
            }
        });
    }
    return valid;
}
