import validateOn from './validateOnEnum'

export const validators = {
	meno: [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole meno lekára je prázdne" }],
	email: [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole e-mail je prázdne" }, {
		validateOn: validateOn.CHANGE, validate: (value) => {
			return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
		}, invalidMsg: "E-mail nie je platný"
	}],
	pzs:[{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole názov poskytovateľa je prázdne" },
	     { validateOn:validateOn.CHANGE, validate: (value) => {
	 	return value.length>=2;
	 },invalidMsg : 'Pole názov PZS musí obsahovať minimálne 2 znaky.'}],

	kod_lekara:[{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole kód lekára je prázdne" },
	{ validateOn:validateOn.CHANGE, validate: (value) => { return /^[A-Z]{1}[0-9]{8}$/.test(value) }, invalidMsg : 'Pole musí obsahovať korektný kód lekára.' }
	],
	kod_pzs:[{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole kód PZS je prázdne" },
	{ validateOn:validateOn.CHANGE, validate: (value) => {return /^[A-Z]{1}[0-9]{11}$/.test(value);}, invalidMsg :'Pole musí obsahovať korektný kód PZS.' }
	],
	telefon:[{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole telefón je prázdne" },
	{ validateOn:validateOn.CHANGE, validate: (value) => {return /^[+]{1}[0-9]{12}$/.test(value);}, invalidMsg :'Telefónnne číslo musí byť vo formáte +421#######' }
	],
	ico:[{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole IČO je prázdne" },
	{validateOn:validateOn.CHANGE, validate: (ico) => {
		var crc = 0;
		crc = crc
			+ 8 * ico.substr(0,1)
			+ 7 * ico.substr(1,1)
			+ 6 * ico.substr(2,1)
			+ 5 * ico.substr(3,1)
			+ 4 * ico.substr(4,1)
			+ 3 * ico.substr(5,1)
			+ 2 * ico.substr(6,1)
		;
		crc = (Math.trunc(crc / 11) + 1) * 11 - crc;
		crc = crc % 10;
		return (crc.toString() === ico.substr(7,1));
	}, invalidMsg:'Prosím zadajte korektné IČO.'}],
	adresa_sidlo_ulica_cislo : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole ulica a číslo je prázdne" }],
	//adresa_sidlo_ulica : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole je prázdne" }],
	//adresa_sidlo_cislo : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole je prázdne" }],
	adresa_sidlo_mesto : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole mesto je prázdne" }],
	adresa_sidlo_psc : [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole PSČ je prázdne" }],
	adresa_vysledky_ulica_cislo : [{ validateOn: validateOn.CHANGE, validate: (value,value2) => { return !(value2===true&&(!value || value.trim().length === 0)) }, invalidMsg: "Pole ulica a číslo adresy doručenia výsledku je prázdne" }],
	adresa_vysledky_mesto : [{ validateOn: validateOn.CHANGE, validate: (value,value2) => { return !(value2===true&&(!value || value.trim().length === 0)) }, invalidMsg: "Pole mesto adresy doručenia výsledku je prázdne" }],
	adresa_vysledky_psc : [{ validateOn: validateOn.CHANGE, validate: (value,value2) => { return !(value2===true&&(!value || value.trim().length === 0)) }, invalidMsg: "Pole PSČ adresy doručenia výsledku je prázdne" }],

	suhlas_vop: [{ validateOn: validateOn.CHANGE, validate: (value) => { 
		return value===true; 
	}, invalidMsg: "S podmienkami musíte súhlasiť" }],

}

export function isValid(state, stateErrors = {}, validationEvent = validateOn.FORM) {
	var valid = true;
	for (const property in state) {
		if (!validators.hasOwnProperty(property)) continue;
		stateErrors[property] = [];
		// eslint-disable-next-line no-loop-func
		validators[property].filter(val => val.validateOn <= validationEvent).forEach(validator => {
			if (validator.validate(state[property],state.adresa_vysledky_ina) === false) {
				stateErrors[property].push(validator.invalidMsg);
				valid = false;
			}
		});
	}
	return valid;
}
