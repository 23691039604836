import validateOn from './validateOnEnum'

export const validators = {
	password: [{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole Heslo je prázdne" },
		{
			validateOn: validateOn.CHANGE, validate: (value) => {
				return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*\d]{8,}$/.test(value);
			}, invalidMsg: "Heslo musí obsahovať minimálne 8 znakov, aspoň 1 veľké písmeno, 1 malé písmeno a 1 číslo"
		}
	],
	confPassword:[{ validateOn: validateOn.CHANGE, validate: (value) => { return !(!value || value.trim().length === 0) }, invalidMsg: "Pole Potvrdenie hesla je prázdne" },
		{ validateOn:validateOn.CHANGE, validate: (value ,record={}) =>{
			return (record.password.length > 0 && value.length > 0 && record.password === value);
		} , invalidMsg:'Zadané heslá sa nezhodujú.'}
	]
}

export function isValid(state, stateErrors = {}, validationEvent = validateOn.FORM){
	var valid = true;
	for (const property in state) {
		if(!validators.hasOwnProperty(property)) continue;
		stateErrors[property] = [];
		// eslint-disable-next-line no-loop-func
		validators[property].filter(val => val.validateOn <= validationEvent).forEach(validator => {
			if (validator.validate(state[property],state) === false) {
				stateErrors[property].push(validator.invalidMsg);
				valid = false;
			}
		});
	}
	return valid;
}
