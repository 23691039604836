import React from 'react';
import { connect } from 'react-redux';
import { validators } from '../validators/resetPsw';
import validateOnEnum from '../validators/validateOnEnum'

import { useLocation, useHistory } from 'react-router-dom';

import userService from '../services/serveApi'
import actionConstants from '../actionConstants'
import Header from './Header';
import Footer from './Footer';

const mapStateToProps = (state) => {
	return ({ resetPsw: state.resetPsw })
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const ResetPsw = (props) => {
	const history = useHistory();
	const query = useQuery();
	const token = query.get('id');
	if (token && props.resetPsw.resetprocess === 'idle') {
		props.dispatch({ type: actionConstants.RESETPSW, payload: { resetPsw: { ...props.resetPsw, resetprocess: 'start', token: token, email: '', password_new: '' } } });
		// query.delete('id');
		history.replace({ search: "" })
	}
	if (props.resetPsw.token && props.resetPsw.resetprocess === 'start') {
		props.resetPsw.resetprocess = 'run';
		props.dispatch({ type: actionConstants.RESETPSW, payload: { resetPsw: { ...props.resetPsw, resetprocess: 'run', email: '', password_new: '' } } });
	}

	async function sendReset(event) {
		event.preventDefault();
		if (touched.password_new && touched.confPassword && props.resetPsw.errors.password_new.length === 0 && props.resetPsw.errors.confPassword.length === 0) {
			try {
				const retData = await userService.resetPsw(props.resetPsw.token, props.resetPsw.password_new);
				console.log(retData);
				props.dispatch({ type: actionConstants.RESETPSW, payload: { ...props, resetPsw: { ...props.resetPsw, msg: 'RESETOK', resetprocess: 'end' } } });
			} catch (err) {
				props.dispatch({ type: actionConstants.RESETPSW, payload: { ...props, resetPsw: { ...props.resetPsw, msg: err, resetprocess: 'end' } } });
				console.log(err)
			}
		}
	}

	const touched = {
		password_new: !(props.resetPsw.errors.password_new === null),
		confPassword: !(props.resetPsw.errors.confPassword === null)
	}
	function onChange(event) {
		const propValue = event.target['value'];
		const propName = event.target['name'];
		var errVal = props.resetPsw.errors;	//existujuca hodnota 'errors' v props
		if (validators[propName].find(v => { return v.validateOn === validateOnEnum.CHANGE })) {
			errVal[propName] = [];
			validators[propName].filter(val => val.validateOn === validateOnEnum.CHANGE).forEach(validator => {
				if (validator.validate(propValue, props.resetPsw) === false) errVal[propName].push(validator.invalidMsg);
			});
		}
		props.dispatch({ type: actionConstants.RESETPSW, payload: { ...props, resetPsw: { ...props.resetPsw, msg: '', [propName]: propValue, errors: { ...props.resetPsw.errors, [propName]: errVal[propName] } } } });
	}

	return (

		<div className="App1">
			<Header />
			<main id="cart-content"  >
				<div gift-codes-raw="[]">
					<header className="header header-cart">
						<div className="header-cart--menu">
							{props.resetPsw.msg === 'RESETOK' && props.resetPsw.resetprocess === "end" && (
								<div className="header--menu--back">
									<a href="/"><i className="icon medirex-long-arrow-left"></i>
                              			Späť na Prihlásenie</a>
								</div>
							)}
						</div>
					</header>

					<div className="col-sm-12 col-md-12 col-lg-12 column">
						{props.resetPsw.resetprocess === "run" ? (
							<div className="password-reset__form-container">
								<h2 className="password-reset__title">Nastavenie nového hesla</h2>
								<p className="password-reset__sub-title">
									Zadajte prosím nové heslo
            					</p>
								<form>
									<div className="form-group">
										<label htmlFor="password_new">
											<span className={"validation-icon " + (touched.password_new && props.resetPsw.errors.password_new.length > 0 ? "is-invalid" : (touched.password_new ? "valid" : ""))}></span>
                  				Nové heslo&nbsp;*
                				</label>
										<div className="input-box">
											<input className={"input " + (touched.password_new && props.resetPsw.errors.password_new.length > 0 ? "is-invalid" : "")} id="password_new" name="password_new" type="password" value={props.resetPsw.password_new} onChange={onChange} />
											<div className="form-error">
												{touched.password_new && props.resetPsw.errors['password_new'].map((value, i) =>
													<span key={i}>{value}</span>
												)}
											</div>
										</div>
										<label htmlFor="confPassword">
											<span className={"validation-icon " + (touched.confPassword && props.resetPsw.errors.confPassword.length > 0 ? "is-invalid" : (touched.confPassword ? "valid" : ""))}></span>
                  				Potvrdenie hesla&nbsp;*
		                		</label>
										<div className="input-box">
											<input id="confPassword" name="confPassword" type="password" className={"input " + (touched.confPassword && props.resetPsw.errors.confPassword.length > 0 ? "is-invalid" : "")} value={props.confPassword} onChange={onChange} />
											<div className="form-error">
												{touched.confPassword && props.resetPsw.errors['confPassword'].map((value, i) =>
													<span key={i}>{value}</span>
												)}
											</div>
										</div>
									</div>
									<div className="password-reset__submit">
										<button aria-label="send" className="button--dark" onClick={sendReset}>
											<span>Odoslať</span>
										</button>
									</div>

									<div className="col-12">
										<div className="form-error align-left"> <span>{props.resetPsw.msg && props.resetPsw.msg !== "RESETOK" ? props.resetPsw.msg : ''}</span> </div>
									</div>
								</form>
							</div>) : ''}
						{props.resetPsw.msg === 'RESETOK' && props.resetPsw.resetprocess === "end" && (
							<div className="thank-you">
								<div className="inner">
									<h1 className="headline">Zmena hesla</h1>
									<p className="password-reset__sub-title">
										Nové heslo pre užívateľa bolo úspešne nastavené!<br/>
										Môžete sa <a href="/">prihlásiť</a>.
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</main>

			<Footer />
		</div>
	)

}

export default connect(mapStateToProps)(ResetPsw);