import React from "react";
import ScrollTopArrow from './Components/ScrollTopArrow'
function Footer() {
    var jlink = 'http://10.100.0.82:8080/buildStatus/icon?job=medirex-eziadanka+TEST&build=last&subject=Build($'
    jlink += '{displayName})'
    return (

        <footer>
            <div className="footer--links">
                <div className="footer--in">
                {
                        process.env.REACT_APP_ENV_NAME!=="prod"&&
                        <a className="pt30" href='http://10.100.0.82:8080/job/medirex-eziadanka%20TEST/lastBuild/'  target="_blank" rel="noreferrer">
                        <img alt="" src={jlink}></img>
                    </a>
                    }
                    <div className="footer--col--left footer-logos">
                        <a href="https://www.medirex.sk/lekar/o-nas" className="logo">
                            <img src="https://static.medirex.sk/themes/medirex-portal/assets/images/logos/medirex-white.svg" alt="medirex group" width="52" height="40"/>
                            Medirex
                        </a>
                        <a href="https://www.medirex.sk/lekar/o-nas/medicyt" className="logo">
                            <img src="https://static.medirex.sk/themes/medirex-portal/assets/images/logos/medicyt-white.svg" alt="medirex group" width="71" height="40"/>
                            Medicyt
                        </a>
                        <a href="https://www.medirex.sk/lekar/o-nas/medirex-group-acedemy" className="logo">
                            <img src="https://static.medirex.sk/themes/medirex-portal/assets/images/logos/medirex-academy-white.svg" alt="medirex group" width="73" height="40"/>
                            Medirex Academy
                        </a>
                    </div>
                    <div className="footer--col footer--col--right footer-logos flex-end">
                        <a href="https://www.medirex.sk/" className="logo medirex-group-logo">
                            <img src="https://static.medirex.sk/themes/medirex-portal/assets/images/logos/medirex-group-clen-white.svg" alt="medirex group" width="214" height="46"/>
                            Medirex Group
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer--bottom">
                <div className="footer--bottom--in">
                    <p className="footer__copyright">© 2021 MEDIREX GROUP. Všetky práva vyhradené.</p>
                    <p className="footer__created">
                        {/* <a href="https://www.scrtechnologies.sk/" target="_blank" rel="noreferrer" className="scr-logo">
                    SCR® technologies | Ecommerce riešenia na mieru | IT outsourcing | Softvér na mieru | Vývoj webových a
                    portálových riešení | Mobilné aplikácie
                        </a> */}
                        <a href="http://www.software-foundation.sk/" target="_blank" rel="noreferrer" className="scr-logo">
                            <span>eŽiadanka:{process.env.REACT_APP_VERSION} { process.env.REACT_APP_ENV_NAME!=="prod"&&"(" + process.env.REACT_APP_ENV_NAME + ")"}</span>
                        </a>
                    </p>
                </div>
            </div>

            <ScrollTopArrow />
        </footer>


)}


export default Footer;
