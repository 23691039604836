import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './reducers'
import Header from './form/Header';
import Footer from './form/Footer';
import Form from './form/Form';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import $ from 'jquery';
import Verify from './form/Verify'
import Thanks from './form/Podakovanie'
import ForgotPsw from './form/ForgotPsw'
import ResetPsw from './form/ResetPsw.js'
import Bridge from './form/Components/Bridge'
import ErrorPage from './form/ErrorPage'
import ListView from './views/ListView';
import SaveLekarData from './form/Components/SaveLekarData'
import './sass/styles.scss'
import axios from 'axios';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
global.$curoJq = $ ;
window.$curoJq = $ ;

//browser history.back - Spýta sa či chces odist zo stranky predtym  ako sa vrati na predchadzajucu
//pozn. ten text sa ignoruje
// window.onbeforeunload = () => { return "Chete odísť z tohto webu? Zmeny, ktoré ste vykonali, sa nemusia uložiť."; };

const store = createStore(rootReducer)

store.dispatch( { type : 'LOADSTATE' } );

axios.defaults.headers = {
    ...axios.defaults.headers,
    'Content-Type': 'application/json',
    'accept': 'application/json',
    'x-fs-access-token': store.getState().user.accessToken,
}

const App_default = ()=>{
  return(
      <div className="App1">
          <Header/>
              <div id="vue-app-wrapper">
                <Form/>
              </div>
          <Footer/>
      </div>
  )
}
const About=()=>{
  return(
      <div className="about">
          <h2>Medirex ziadanka powered by Software foundation s.r.o. (2021)</h2>
      </div>
  )
}



function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <BrowserRouter>
            <Switch>
              <Route exact path="/" component={App_default}></Route>
              <Route exect path="/verify" component={Verify}></Route>
              <Route exect path="/forgotpsw" component={ForgotPsw}></Route>
              <Route exect path="/pswreset" component={ResetPsw}></Route>
              <Route exact path="/about" component={About}></Route>
              <Route exact path="/thanks" component={Thanks}></Route>
              <Route exact path="/bridge" component={Bridge}></Route>
              <Route exact path="/createdata" component={SaveLekarData}></Route>
              <Route exact path="/error" component={ErrorPage}></Route>
              <Route exact path="/list" component={ListView}></Route>
            </Switch>
          </BrowserRouter>

      </div>
    </Provider>
  );
}

export default App;
